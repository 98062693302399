import { Box, BoxProps, SxProps, SvgIcon } from "@mui/material";
import { AppTheme, SimpleMap } from "utils/types";
import { WarningIcon } from "assets";
import { FC } from "react";

export interface WarningBoxProps extends BoxProps {
  warningIcon?: typeof WarningIcon;
  showIcon?: boolean;
}

const WarningBox: FC<WarningBoxProps> = ({
  warningIcon = WarningIcon,
  showIcon = true,
  children,
  ...rest
}: WarningBoxProps) => (
  <Box {...rest}>
    <Box sx={styles.root}>
      {showIcon && (<SvgIcon component={warningIcon} sx={styles.warningIcon} />)}
      {children}
    </Box>
  </Box>
)

const styles: SimpleMap<SxProps<AppTheme>> = {
  root: {
    width: "100%",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    borderRadius: "16px",
    padding: "20px 16px",
    display: "flex",
    alignItems: "center",
  },
  warningIcon: {
    marginRight: "14px",
    marginBottom: "1.5px",
  },
}

export default WarningBox;