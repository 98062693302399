import {
  Box, Button,
  Checkbox, CircularProgress, Divider, Link, Pagination, styled, SxProps, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { ArrowForwardIcon, CheckCircleIcon, DiscordWhite, Huny, LockedIcon, UncheckedIcon, WarningRed } from 'assets';
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import BigNumber from 'bignumber.js';
import { DEFAULT_SRC } from "components/Guild/components/GuildConstants";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import dayjs from "dayjs";
import { MutableRefObject, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Guild, Profile, Tax, TaxByWeek, TaxStatus } from 'store/types';
import { useRedux } from 'utils';
import { BIG_ZERO, Decimals } from 'utils/constants';
import { getExplorerLink } from "utils/strings";
import { bnOrZero, formatIntegerNumber } from "utils/strings/strings";
import { combineStyles } from 'utils/themeUtilities';
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from "utils/types";
import useIsOverflow from "utils/useIsOverflow";
import useNetwork from "utils/useNetwork";
import { Tag } from '../../GuildBankTab/components';

interface TaxBalanceAccordionProps {
  guild?: Guild;
  hunyTaxes: TaxByWeek[];
  onUpdateMemberTaxes?: (value: MemberTax[]) => void;
  parentRef: MutableRefObject<HTMLDivElement | undefined>;
}
export interface MemberTax extends Profile {
  hunyLeft: BigNumber;
  datesToPay: Tax[];
  collectedTaxes?: Tax[];
  memberLeft?: boolean;
}

const TaxBalanceAccordion: React.FC<TaxBalanceAccordionProps> = (props: TaxBalanceAccordionProps) => {
  const { guild, hunyTaxes, onUpdateMemberTaxes, parentRef } = props;
  const rowCountLimit = 5;
  const [expanded, setExpanded] = useState<string | false>('panel0');
  const [memberTax, setMemberTax] = useState<MemberTax[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const ref = useRef() as RefObject<HTMLDivElement>;
  const isOverflow = useIsOverflow(ref);
  const network = useNetwork();
  const allowances = useRedux((state) => state.guild.allowances);
  const exMembers = useRedux((state) => state.guild.transactions.exMembers);

  //TODO: Should refactor out of useEffect into saga
  useEffect(() => {
    if (!guild || !guild.members || !hunyTaxes) return;
    const taxes = hunyTaxes;
    const init: MemberTax[] = [];
    let count = 0;

    taxes.forEach((tax, i) => {
      if (tax.taxesOwed) {
        const taxesOwed = tax.taxesOwed;
        Object.keys(taxesOwed).forEach((key) => {
          const existingIdx = init.findIndex(i => i.address.toLowerCase() === key.toLowerCase());
          if (existingIdx > -1) {
            if (!init[existingIdx].memberLeft) {
              const hunyToCollect = bnOrZero(taxesOwed[key]).shiftedBy(-Decimals.HUNY);
              const diff = init[existingIdx].hunyLeft.minus(hunyToCollect);
              const canAllowanceAfford = canMemberAllowanceAfford(init[existingIdx].address, tax);
              const approvedTax = memberApproveTax(init[existingIdx].address);
              if (diff.gte(0) && canAllowanceAfford && approvedTax && count < 4) {
                init[existingIdx].hunyLeft = diff;
                init[existingIdx].datesToPay.push({ date: tax.date, status: 0, membersPayingAmt: bnOrZero(taxesOwed[key]), epochNumber: tax.epochNumber })
                count = count + 1;
              }
            }
          } else {
            let member;
            let memberLeft = false
            member = guild.members.find(m => m.address.toLowerCase() === key.toLowerCase());
            if (!member) {
              member = exMembers.find(m => m.address.toLowerCase() === key.toLowerCase())
              memberLeft = true;
            }

            if (!!member && !memberLeft) {
              const hunyToCollect = bnOrZero(taxesOwed[key]).shiftedBy(-Decimals.HUNY);
              const diff = member?.totalHunyHolding.minus(hunyToCollect) ?? BIG_ZERO;
              const canAllowanceAfford = canMemberAllowanceAfford(member.address, tax);
              const approvedTax = memberApproveTax(member.address);
              if (diff.gte(0) && canAllowanceAfford && approvedTax && count < 4) {
                init.push({
                  ...member,
                  hunyLeft: member.totalHunyHolding,
                  datesToPay: [{ date: tax.date, status: 0, membersPayingAmt: bnOrZero(taxesOwed[key]), epochNumber: tax.epochNumber }]
                })
                count = count + 1;
              } else {
                init.push({ ...member, hunyLeft: member.totalHunyHolding, datesToPay: [] })
              }
            } else {
              init.push({ ...member, hunyLeft: 0, datesToPay: [], memberLeft: true, totalHunyHolding: BIG_ZERO })
            }
          }
        })
      }

      if (tax.taxesCollected) {
        const taxesCollected = tax.taxesCollected;
        Object.keys(taxesCollected).forEach((key) => {
          const existingIdx = init.findIndex(i => i.address.toLowerCase() === key.toLowerCase());
          if (existingIdx > -1) {
            const updated = init[existingIdx].collectedTaxes ?? []
            updated.push({ date: tax.date, status: 0, membersPayingAmt: bnOrZero(taxesCollected[key]), epochNumber: tax.epochNumber })
            init[existingIdx].collectedTaxes = updated;
          } else {
            const member = guild.members.find(m => m.address.toLowerCase() === key.toLowerCase());
            if (!!member) {
              init.push({
                ...member,
                hunyLeft: member.totalHunyHolding,
                datesToPay: [],
                collectedTaxes: [{ date: tax.date, status: 0, membersPayingAmt: bnOrZero(taxesCollected[key]), epochNumber: tax.epochNumber }]
              })
            }
          }
        })
      }
    })

    const { commanderAddresses, leaderAddress } = guild;

    const sorted = init.sort((a: MemberTax, b: MemberTax) => (b.totalHunyHolding ?? 0).toNumber() - (a.totalHunyHolding ?? 0).toNumber())
      .sort((a: Profile, b: Profile) => {
        if (commanderAddresses.includes(a.address) && !commanderAddresses.includes(b.address)) return -1;
        if (!commanderAddresses.includes(a.address) && commanderAddresses.includes(b.address)) return 1;
        return 0;
      })
      .sort((a: Profile) => (a.address === leaderAddress) ? -1 : 0)

    updateMemberTax(sorted);

    //eslint-disable-next-line
  }, [guild, hunyTaxes])

  const selectedCount = useMemo(() => {
    let count = 0;
    memberTax.forEach((m) => {
      if (m.datesToPay) count = count + m.datesToPay.length
    })
    return count;
  }, [memberTax])

  const members = useMemo(() => {
    if (!guild || !guild.members) return null;
    return guild.members;
  }, [guild]);

  const updateMemberTax = (updated: MemberTax[]) => {
    setMemberTax(updated);
    if (typeof onUpdateMemberTaxes === "function") onUpdateMemberTaxes(updated);
  }

  const handlePanelChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleMemberTaxChange = (memberAddress: string, tax: Tax) => {
    const index = memberTax.findIndex(m => m.address === memberAddress);
    const updated = memberTax.map((m, i) => {
      if (index === i) {
        const updatedDatesToPay = memberTax[index].datesToPay;
        const taxDateIndex = m.datesToPay.findIndex(d => d.date.getTime() === tax.date.getTime());
        if (taxDateIndex > -1) {
          updatedDatesToPay.splice(taxDateIndex, 1);
          m.hunyLeft = m.hunyLeft.plus(tax.membersPayingAmt);
        } else {
          updatedDatesToPay.push(tax);
          m.hunyLeft = m.hunyLeft.minus(tax.membersPayingAmt);
        }

        m.datesToPay = updatedDatesToPay;
        return m;
      } else return m;
    });

    updateMemberTax(updated);
  }

  const generateMemberRank = (member: Profile) => {
    if (member.address === guild?.leaderAddress) return <Typography sx={styles.captainRankText}>Captain</Typography>
    if (guild?.commanderAddresses.includes(member.address)) return <Typography sx={styles.officerRankText}>Officer</Typography>
    return <Typography sx={styles.unrankedText}>Level {member.level ?? 1}</Typography>
  }

  const isMemberPaying = (memberTax: MemberTax, taxDate: Date) => {
    return !!(Array.isArray(memberTax.datesToPay) && memberTax.datesToPay.length && memberTax.datesToPay.find(d => d.date.getTime() === taxDate.getTime()));
  }

  const canMemberAfford = (memberTax: MemberTax, tax: Tax) => {
    return (bnOrZero(memberTax.hunyLeft).minus(tax.membersPayingAmt)).gte(0) || isMemberPaying(memberTax, tax.date);
  }

  const memberApproveTax = (memberAddress: string) => {
    const membersApproved = guild?.guildBank?.membersApprovedTax;
    if (!membersApproved) return false;
    const memberDidApprove = membersApproved.find((m) => m === memberAddress)
    if (!memberDidApprove) return false;
    return true;
  }

  const canMemberAllowanceAfford = (memberAddress: string, tax: Tax) => {
    if (allowances) {
      const allowance = allowances[memberAddress.toLowerCase()]?.[guild?.guildBank?.address.toLowerCase() ?? ""] ?? BIG_ZERO;
      const allowanceAmt = bnOrZero(allowance).shiftedBy(-Decimals.HUNY);
      return bnOrZero(allowanceAmt).minus(tax.membersPayingAmt).gte(0);
    }
    return false;
  }

  const isDateCollected = (memberTax: MemberTax, taxDate: Date) => {
    return memberTax.collectedTaxes && !!memberTax.collectedTaxes.find(d => d.date.getTime() === taxDate.getTime());
  }

  const handleNextPage = () => {
    if (!hunyTaxes) return;
    parentRef.current?.scrollIntoView()
    if (rowCount + rowCountLimit < hunyTaxes?.length) setRowCount(rowCount + rowCountLimit);
  }

  const handlePrevPage = () => {
    parentRef.current?.scrollIntoView()
    if (rowCount - rowCountLimit > -1) setRowCount(rowCount - rowCountLimit);
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    parentRef.current?.scrollIntoView()
    setRowCount((value - 1) * rowCountLimit);
  };

  // const handleSelectAllClick = (tax: Tax) => {
  //   let count = selectedCount;
  //   const updated = memberTax.map((m, i) => {
  //     const updatedDates = m.datesToPay;
  //     if (canMemberAfford(m, tax) && !isMemberPaying(m, tax.date) && !isDateCollected(m, tax.date) && canMemberAllowanceAfford(m.address, tax) && count <= 4) {
  //       updatedDates.push(tax);
  //       count += 1;
  //       m.hunyLeft = m.hunyLeft.minus(tax.membersPayingAmt);
  //     }
  //     m.datesToPay = updatedDates;
  //     return m;
  //   });

  //   updateMemberTax(updated);
  // }

  // const handleUnselectAll = (tax: Tax) => {
  //   const updated = memberTax.map((m, i) => {
  //     const updatedDates = m.datesToPay;
  //     const taxDateIndex = m.datesToPay.findIndex(d => d.date.getTime() === tax.date.getTime());
  //     if (canMemberAfford(m, tax) && taxDateIndex > -1) {
  //       updatedDates.splice(taxDateIndex, 1);
  //       m.hunyLeft = m.hunyLeft.plus(tax.membersPayingAmt);
  //     }
  //     m.datesToPay = updatedDates;
  //     return m;
  //   });

  //   updateMemberTax(updated);
  // }

  // const isAllSelected = (tax: Tax) => {
  //   let res = true;
  //   for (const m of memberTax) {
  //     if (canMemberAfford(m, tax) && !isMemberPaying(m, tax.date) && !isDateCollected(m, tax.date) && canMemberAllowanceAfford(m.address, tax)) {
  //       res = false;
  //       break;
  //     }
  //   }
  //   return res;
  // }

  // const handleAllChange = (tax: Tax) => {
  //   if (isAllSelected(tax)) handleUnselectAll(tax)
  //   else handleSelectAllClick(tax);
  // }

  const calculateTotalTax = (tax: TaxByWeek) => {
    const weeklyTax = tax.membersPayingAmt;
    const taxOwedCount = memberTax.filter(m => tax.taxesOwed && tax.taxesOwed[m.address.toLowerCase()]).length;
    const taxCollectedCount = memberTax.filter(m => tax.taxesCollected && tax.taxesCollected[m.address.toLowerCase()]).length;
    return (bnOrZero(taxOwedCount + taxCollectedCount).multipliedBy(weeklyTax));
  }

  const calculateCurrentTax = (tax: Tax) => {
    const weeklyTax = tax.membersPayingAmt;
    const toCollect = bnOrZero(memberTax.filter(m => !!m.datesToPay.find(d => d.date.getTime() === tax.date.getTime())).length).multipliedBy(weeklyTax);
    const collected = bnOrZero(memberTax.filter(m => m.collectedTaxes && !!m.collectedTaxes.find(d => d.date.getTime() === tax.date.getTime())).length).multipliedBy(weeklyTax);
    return collected.plus(toCollect);
  }
  return (
    <Box sx={styles.container}>
      {memberTax && hunyTaxes.sort((a, b) => { return b.epochNumber - a.epochNumber }).slice(rowCount, rowCount + rowCountLimit).map((hunyTax, index) => {
        const totalTaxAmt = calculateTotalTax(hunyTax);
        const isPendingCollection = hunyTax.status === TaxStatus.PendingCollection;
        const isCollected = hunyTax.status === TaxStatus.Collected;

        return (
          <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handlePanelChange(`panel${index}`)} className={isCollected ? "collected" : ""}>
            <AccordionSummary className={isCollected ? "collected" : ""}>
              <SummaryDivider textAlign="left">
                <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>{dayjs(hunyTax.date).format("MMMM D, YYYY")}</Typography>
              </SummaryDivider>
              <Box sx={styles.summaryRightContainer}>
                <Typography variant="h2" color="primary" component="span" sx={styles.summaryHunyText}>
                  {isPendingCollection && formatIntegerNumber(calculateCurrentTax(hunyTax))}
                  {isCollected && formatIntegerNumber(totalTaxAmt ?? 0)}
                  /{formatIntegerNumber(totalTaxAmt ?? 0)} &nbsp;
                  <Huny width="40px" height="40px" />
                </Typography>
                {/* {isPendingCollection &&
                  <TaxCheckbox
                    onClick={e => e.stopPropagation()}
                    onChange={() => handleAllChange(hunyTax)}
                    checked={isAllSelected(hunyTax)}
                    icon={<UncheckedIcon width="28px" height="28px" />}
                    checkedIcon={<CheckCircleIcon width="28px" height="28px" />}
                    sx={styles.checkboxButton}
                  />} */}
                <Box display="block">
                  {isCollected && <Typography sx={styles.collected}>Collected</Typography>}
                </Box>
              </Box>
            </AccordionSummary>
            <Box>
              <AccordionDetails className={isCollected ? "collected-accordion-details" : ""}
                sx={!isOverflow ? {
                  '.masked-overflow': {
                    maskImage: 'none',
                    WebkitMaskImage: 'none',
                    overflowY: 'hidden'
                  }
                } : {}}>
                <TableContainer ref={ref} sx={styles.tableContainer} className="masked-overflow">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" sx={styles.tableCellHeader}>
                          <Typography variant="body1" >Member</Typography>
                        </TableCell>
                        <TableCell align="left" sx={styles.tableCellHeader}>
                          <Typography variant="body1">Rank</Typography>
                        </TableCell>
                        <TableCell align="left" sx={styles.tableCellHeader}>
                          <Typography variant="body1">Discord</Typography>
                        </TableCell>
                        <TableCell align="center" sx={styles.tableCellHeader}>
                          <Typography variant="body1">HUNY Holding</Typography>
                        </TableCell>
                        <TableCell align="left" sx={styles.tableCellHeader}>
                          <Typography variant="body1">Status</Typography>
                        </TableCell>
                        <TableCell align="center" sx={styles.tableCellButtonHeader}>
                          <Button>&nbsp;</Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={styles.tableBody}>
                      {guild && members && memberTax && memberTax.map((member, memberIndex) => {
                        const dateCollected = isDateCollected(member, hunyTax.date);
                        const canAfford = canMemberAfford(member, hunyTax);
                        const canAllowanceAfford = canMemberAllowanceAfford(member.address, hunyTax);
                        const memberOwesTax = !!hunyTax.taxesOwed && hunyTax.taxesOwed[member.address.toLowerCase()];
                        const memberPaying = isMemberPaying(member, hunyTax.date);
                        const didMemberApprove = memberApproveTax(member.address);

                        if (!memberOwesTax && !dateCollected) return ("");
                        return (
                          <TableRow key={memberIndex} sx={combineStyles({ '& >td': { borderBottom: 'none', borderTop: '1px solid rgba(224, 224, 224, 1)' } }, { ...(member.memberLeft) && { opacity: '0.5' } })}>
                            {/* Member */}
                            <TableCell>
                              <Box sx={{ display: "flex", placeItems: "center:", alignItems: 'center' }}>
                                <Box sx={styles.memberListImgFrame}>
                                  <Box component="img" src={member.avatarUrl ?? DefaultUserProfile} sx={styles.memberListImg} onError={DEFAULT_SRC} />
                                </Box>
                                <Box sx={{ marginLeft: '20px' }}>
                                  <Typography color="secondary" sx={styles.usernameText}>{member.username ?? '-'}</Typography>
                                  <Link target="_blank" href={getExplorerLink("address", member.address, network)} sx={{ textDecoration: "none" }}>
                                    <Typography color="primary" sx={styles.addressText}> {truncate(toBech32Address(member.address))}</Typography>
                                  </Link>
                                </Box>
                              </Box>
                            </TableCell>

                            {/* Rank */}
                            <TableCell>
                              {generateMemberRank(member)}
                            </TableCell>

                            {/* Discord */}
                            <TableCell>
                              {member.discordUrl && <Link target="_blank" href={`https://discord.gg/zilswap`} sx={{ textDecoration: "none" }}>
                                <Typography variant="body1" color="primary" sx={styles.discordText}>
                                  <DiscordWhite width="24px" height="24px" style={{ marginRight: 6 }} /> {member.discordUrl}</Typography>
                              </Link>}
                              {!member.discordUrl && <Typography color="primary" sx={styles.noDiscordText}>
                                <DiscordWhite style={{ marginRight: 6 }} />-</Typography>}
                            </TableCell>

                            {/* HUNY Holding */}
                            <TableCell>
                              <Typography color="primary" sx={combineStyles(styles.hunyHoldingText, !canAfford && !dateCollected && !member.memberLeft && !didMemberApprove ? { color: '#F65E5E !important' } : {})}>
                                {!member.memberLeft && formatIntegerNumber(member.totalHunyHolding)}
                                {member.memberLeft && "-"} &nbsp;
                                <Huny width="24px" height="24px" />
                              </Typography>
                            </TableCell>

                            {/* Status */}
                            <TableCell>
                              {isPendingCollection && !dateCollected && canAllowanceAfford && didMemberApprove &&
                                (canAfford || member.memberLeft ? <PendingCollectionTag /> : <InsufficientBalanceTag />)}
                              {(isCollected || dateCollected) && <CollectedTag />}
                              {!(isCollected || dateCollected) && !canAllowanceAfford && <InsufficientAllowanceTag />}
                              {!(isCollected || dateCollected) && canAllowanceAfford && !didMemberApprove && <TaxNotApproved />}
                            </TableCell>

                            {/* Checkboxes */}
                            <TableCell sx={{ textAlign: "center" }}>

                              {/* pending collection */}
                              {!dateCollected && isPendingCollection && ((canAfford && canAllowanceAfford && !!memberOwesTax && didMemberApprove) || member.memberLeft) && (
                                selectedCount === 4 && !memberPaying && !member.memberLeft ?
                                  <TooltipZolar titleIcon={false} topPlacement
                                    sx={{ padding: "20px", height: "68px" }}>
                                    You may only select 4 members to collect from in one go.
                                  </TooltipZolar>
                                  :
                                  <TaxCheckbox
                                    onChange={(e) => handleMemberTaxChange(member.address, hunyTax)}
                                    value={member.address}
                                    checked={(memberPaying && didMemberApprove)}
                                    icon={<UncheckedIcon width="28px" height="28px" />}
                                    checkedIcon={<CheckCircleIcon width="28px" height="28px" />}
                                    disabled={!canAfford || member.memberLeft || !didMemberApprove}
                                    sx={styles.checkboxButton}
                                  />)}

                              {/* collected */}
                              {((dateCollected || isCollected)) &&
                                <TooltipZolar titleIcon={false} topPlacement
                                  sx={{ padding: "20px", height: "68px" }}
                                  buttonIcon={<LockedIcon width="28px" height="28px" />}>
                                  Tax collected this week.
                                </TooltipZolar>}

                              {/* insufficient balance */}
                              {!dateCollected && isPendingCollection && !canAfford && canAllowanceAfford && !member.memberLeft && didMemberApprove &&
                                <TooltipZolar titleIcon={false} topPlacement noHoverStyling
                                  sx={{ padding: "20px", height: "124px", width: "263px", ".redIcon": { fill: "red!important" } }}
                                  buttonIcon={<WarningRed width="28px" height="28px" className='redIcon' />}>
                                  This member does not have enough HUNY balance for weekly tax.
                                </TooltipZolar>}

                              {/* insufficient allowance */}
                              {!(dateCollected || isCollected) && !canAllowanceAfford && !member.memberLeft && didMemberApprove &&
                                <TooltipZolar titleIcon={false} topPlacement noHoverStyling
                                  sx={{ padding: "20px", maxHeight: "150px", width: "263px" }}
                                  buttonIcon={<WarningRed width="28px" height="28px" />}>
                                  This member does not have enough HUNY allowance for weekly tax.
                                </TooltipZolar>}

                              {/* did not approve tax */}
                              {!(dateCollected || isCollected) && !member.memberLeft && !didMemberApprove &&
                                <TooltipZolar titleIcon={false} topPlacement noHoverStyling
                                  sx={{ padding: "20px", maxHeight: "150px", width: "263px" }}
                                  buttonIcon={<WarningRed width="28px" height="28px" />}>
                                  This member did not approve the weekly tax.
                                </TooltipZolar>}
                            </TableCell>
                          </TableRow>)
                      })}
                    </TableBody>
                  </Table>
                  {(!guild || !members || !memberTax) &&
                    <Box display="flex" alignItems="center" justifyContent="center" mt="80px" width='100%'>
                      <CircularProgress size={36} />
                    </Box>}
                </TableContainer>
              </AccordionDetails>
            </Box>
          </Accordion>)
      })}
      {(hunyTaxes?.length ?? 0) > rowCountLimit && (
        <Box sx={styles.taxBalancePagination}>
          <Button
            size="medium"
            variant="outlined"
            color="secondary"
            sx={styles.paginationButton}
            onClick={handlePrevPage}
            disabled={Math.floor(rowCount / rowCountLimit) <= 0}
          >
            <Typography variant="button" sx={styles.gradientText}>
              Prev
            </Typography>
          </Button>
          <Pagination sx={styles.pagination}
            count={Math.ceil(((hunyTaxes?.length ?? 0) / rowCountLimit))}
            page={Math.ceil(rowCount / rowCountLimit) + 1}
            onChange={handleChange} hidePrevButton hideNextButton />
          <Button
            size="medium"
            variant="outlined"
            color="secondary"
            sx={styles.paginationButton}
            onClick={handleNextPage}
            disabled={(hunyTaxes?.length ?? 0) < rowCount + rowCountLimit}
          >
            <Typography variant="button" sx={styles.gradientText}>
              Next
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  tableCellText: {
    fontSize: '14px'
  },
  overlineSmall: {
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: "0.2em"
  },
  summaryHunyText: {
    fontSize: '24px',
    margin: '0 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  hunyHoldingText: {
    fontSize: '14px',
    margin: '0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontWeight: 600
  },
  summaryRightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableContainer: {
    fontSize: "14px",
    overflowX: "auto",
    overflowY: "auto",
    maxHeight: "545px",
    paddingLeft: '20px',
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "transparent",
      top: "",
      left: "",
    },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '::-webkit-scrollbar': {
      display: 'none'
    },
  },
  tableCellHeader: {
    padding: "8px 20px",
    borderColor: "rgba(174, 241, 238, 0.1)",
    whiteSpace: "nowrap",
    background: "linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256) 100%)",
    backgroundClip: "text",
    textFillColor: "transparent"
  },
  tableCellButtonHeader: {
    padding: "8px 20px",
    borderColor: "rgba(174, 241, 238, 0.1)",
    whiteSpace: "nowrap",
    "& .MuiButton-text": {
      fontSize: "18px",
      backgroundClip: "text !important",
      textFillColor: "transparent",
      background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)"
    },
    "& .MuiButton-text.Mui-disabled": {
      fontSize: "18px",
      backgroundClip: "text !important",
      background: "linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256) 100%)",
      textFillColor: "transparent"
    }
  },
  tableBody: {
    fontSize: "14px !important",
    "& .MuiTableCell-root": {
      padding: "8px 20px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  tableHeadCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  usernameText: {
    fontSize: '14px',
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 600,
    lineHeight: '24px'
  },
  addressText: {
    fontSize: '12px',
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 600,
    textDecoration: 'underline',
    lineHeight: '18px'
  },
  discordText: {
    fontSize: '14px',
    textDecoration: 'underline',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    "&:hover": {
      background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
      backgroundClip: "text",
      textFillColor: "transparent",
      textDecorationColor: '#AEF1EE',
    },
    "&:hover svg path": {
      fill: "url(#taxCollectorHover)"
    },
  },
  noDiscordText: {
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  captainRankText: {
    fontSize: '14px',
    color: "#2FF583"
  },
  officerRankText: {
    fontSize: '14px',
    color: "#AEF1EE"
  },
  unrankedText: {
    fontSize: '14px',
    background: "linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256) 100%)",
    backgroundClip: "text",
    textFillColor: "transparent"
  },
  checkboxButton: {
    color: '#fff',
  },
  container: {
    "& .Mui-disabled": {
      background: "none",
      backgroundColor: "transparent !important",
      border: "none"
    },
    "& .collected": {
      background: "none",
      backgroundColor: "transparent !important",
      border: "none"
    },
    "& .collected-accordion-details": {
      background: "none",
    },
    "& .orangeIcon": {
      "& path:first-of-type": {
        fill: "#FF8952"
      }
    }
  },
  collected: {
    fontSize: '12px',
    color: "#2FF583",
    lineHeight: '18px'
  },
  waived: {
    fontSize: '12px',
    color: "#FF8952",
    lineHeight: '18px'
  },
  memberListImgFrame: {
    position: 'relative',
    height: '40px',
    width: "40px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "160% 140%",
    backgroundPosition: "center",
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
  memberListImg: {
    height: '100%',
    width: '100%',
    borderRadius: "16px",
    objectFit: "contain",
    boxShadow: "0px 0px 12px 0px #00F0FF",
  },
  gradient: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    "&::before": {
      backgroundImage: "-webkit-linear-gradient( top, rgba( 20, 25, 43, 0 ) 0%, rgba( 20, 25, 43, 1 ) 100% )",
      content: "'\\00a0'",
      height: "20%",
      position: "absolute",
      width: "100%",
      // zIndex: '999',
      bottom: 0,
      pointerEvents: "none",
    }
  },
  tagText: {
    fontSize: '12px',
    padding: '2px 12px'
  },
  pagination: {
    "& .MuiButtonBase-root.MuiPaginationItem-root, .MuiPaginationItem-root.MuiPaginationItem-ellipsis": {
      background: "-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: '18px'
    },
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
      background: "-webkit-linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    }
  },
  taxBalancePagination: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
    marginTop: '32px',
    width: '100%',
    justifyContent: 'space-between'
  },
  paginationButton: {
    fontSize: '18px',
    height: '48px',
    minWidth: '180px',
    padding: "14px 25px",
    "@media (max-width:900px)": {
      marginRight: "10px",
      minWidth: '140px',
    },
    '&.Mui-disabled': {
      opacity: '0.4',
      boxShadow: 'none!important',
      border: '1px solid #FEFEFE',
      color: '#FEFEFE',
      background: 'unset !important',
      "& span": {
        background: 'unset !important',
        WebkitTextFillColor: "unset",
      }
    },
  },
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: "24px",
  backgroundColor: "transparent",
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    borderRadius: "16px 16px 0 0",
    transition: "border-radius margin",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIcon />}
    {...props}
  />
))(({ theme }) => ({
  color: "#FFF",
  borderRadius: "16px",
  background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    alignItems: "center",
    placeItems: "center"
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
  padding: theme.spacing(2),
  color: "#FFF",
  borderRadius: "0 0 16px 16px",
  paddingTop: 0
}));

const SummaryDivider = styled(Divider)({
  flexGrow: 1,
  '&.MuiDivider-root::after': {
    borderTopColor: '#aef1ee',
    width: '100%',
    top: 0
  },
  '&.MuiDivider-root::before': {
    borderTopColor: '#aef1ee',
    width: '0%',
  },
});

const PendingCollectionTag = () => (<Tag label="Pending Collection" background="linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)" />)
const InsufficientBalanceTag = () => (<Tag label="Insufficient Balance" background="#F65E5E" labelColor="#FEFEFE" />);
const InsufficientAllowanceTag = () => (<Tag label="Insufficient Allowance" background="#F65E5E" labelColor="#FEFEFE" />);
const TaxNotApproved = () => (<Tag label="Tax Not Approved" background="#F65E5E" labelColor="#FEFEFE" />);
const CollectedTag = () => (<Tag label="Collected" background="linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)" />)


const TaxCheckbox = styled(Checkbox)({
  height: '100%',
  '&.Mui-checked': {
    color: '#27ED82',
  },
  '&.Mui-disabled': {
    color: '#ffffff40'
  }
})

export default TaxBalanceAccordion;
