import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, Radio, RadioGroup, SxProps, TextField, Typography } from "@mui/material";
import { AddIcon, Huny, RemoveIcon, RoundedCheckedIcon, RoundedUncheckedIcon, WarningRed } from "assets";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { GuildBreadcrumb } from "components/Guild/components";
import { convertBpsAllocation, GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import TooltipZolar from "components/Tooltip";
import { logger } from "core/utilities";
import { FC, ReactFragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { ControlMode, Guild, GuildBankSetting } from "store/types";
import { TBMConnector } from "tbm";
import { computeFinalPrice, useAsyncTask, useRedux } from "utils";
import { Decimals, ITEM_ID } from "utils/constants";
import { bnOrZero, formatIntegerNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { NumberFormat, PostUpdateDialog } from "./components";
export interface GuildBankInputs extends GuildBankSetting {
  joinFeeInput: string;
  joinCaptainAllocationInput: string;
  joinOfficerAllocationInput: string;
  taxFeeInput: string;
  taxCaptainAllocationInput: string;
  taxOfficerAllocationInput: string;
}

const initialFormState: GuildBankInputs = {
  joinFee: new BigNumber(20000),
  joinCaptainAllocation: 10,
  joinOfficerAllocation: 5,
  taxFee: new BigNumber(20000),
  taxCaptainAllocation: 10,
  taxOfficerAllocation: 5,

  joinFeeInput: "20000",
  joinCaptainAllocationInput: "10",
  joinOfficerAllocationInput: "5",
  taxFeeInput: "20000",
  taxCaptainAllocationInput: "10",
  taxOfficerAllocationInput: "5",

  controlMode: ControlMode.CaptainOnly,

  updateReason: '',
}

let currentFormState: GuildBankInputs = initialFormState;

export type Errors = {
  joinFee: string;
  taxFee: string;

  joinCaptainAllocation: string;
  joinOfficerAllocation: string;
  taxCaptainAllocation: string;
  taxOfficerAllocation: string;

  updateReason: string;
}

export type FormGroup = {
  groupTitle: string;
  groupSubtitle: string;
  fields: {
    name: string;
    tooltip: boolean;
    tooltipContent?: string | ReactFragment;
    tooltiplink?: string;
    fieldType: string;
    fieldName: string;
    placeholderText?: string;
    radioOptions?: {
      label: string,
      value: string | BigNumber | number,
    }[]
  }[];
};

const formGroups: FormGroup[] = [
  {
    groupTitle: "Joining Fee Settings",
    groupSubtitle: "Set up a one-time joining fee for newcomers and weekly tax that guild members must forage for and donate to the guild bank.",
    fields: [
      {
        name: "Joining Fee",
        tooltip: false,
        fieldType: "hunyAmount",
        fieldName: "joinFee",
      },
      {
        name: "Allocated to Captain",
        tooltip: false,
        fieldType: "allocationPercentage",
        fieldName: "joinCaptainAllocation",
      },
      {
        name: "Allocated to Each Officer",
        tooltip: false,
        fieldType: "allocationPercentage",
        fieldName: "joinOfficerAllocation",
      },
    ]
  },
  {
    groupTitle: "Weekly Tax Settings",
    groupSubtitle: "Set up weekly tax that guild members must forage for and allocate some to the leaders of your guild!",
    fields: [
      {
        name: "Weekly Tax",
        tooltip: false,
        fieldType: "hunyAmount",
        fieldName: "taxFee",
      },
      {
        name: "Allocated to Captain",
        tooltip: false,
        fieldType: "allocationPercentage",
        fieldName: "taxCaptainAllocation",
      },
      {
        name: "Allocated to Each Officer",
        tooltip: false,
        fieldType: "allocationPercentage",
        fieldName: "taxOfficerAllocation",
      },
    ]
  },
  {
    groupTitle: "Multisig Settings",
    groupSubtitle: "Require 1 or more signatures for withdrawals and guild management.",
    fields: [
      {
        name: "Multisig Control",
        tooltip: true,
        tooltipContent: (<>Multisig control will be applied to:<br />
          <li>Deposits to / Withdrawals from Magic Hive</li>
          <li>Withdrawals</li>
          <li>Guild Bank Settings</li>
        </>),
        tooltiplink: "https://docs.zolar.io/stations/guilds/guild-bank-new#step-3-configure-guild-bank-settings-for-captains-only",
        fieldType: "radioOption",
        fieldName: "controlMode",
        radioOptions: [
          { label: 'Only captain’s signature required', value: ControlMode.CaptainOnly },
          { label: 'Captain + 1 Officer’s signatures required', value: ControlMode.CaptainAndOneOfficer },
          { label: 'Captain + 2 Officer’s signatures required', value: ControlMode.CaptainAndTwoOfficers },
        ],
      },
    ]
  },
  {
    groupTitle: "Update Reason",
    groupSubtitle: "We encourage you to include a reason for changes made. This will be visible to all of Zolar.",
    fields: [
      {
        name: "Reason",
        tooltip: false,
        placeholderText: 'Up to 70 characters',
        fieldType: "text",
        fieldName: "updateReason",
        radioOptions: [
          { label: 'Only Captain’s signature required', value: ControlMode.CaptainOnly },
          { label: 'Captain + 1 Officer’s signatures required', value: ControlMode.CaptainAndOneOfficer },
          { label: 'Captain + 2 Officer’s signatures required', value: ControlMode.CaptainAndTwoOfficers },
        ],
      },
    ]
  },
];


const optionalFields: string[] = ["updateReason"];


const BankSettings: FC = () => {
  const { guildId } = useParams();

  const network = useNetwork();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const metazoaProfileState = useSelector(getProfile);
  const { profile } = metazoaProfileState;
  const wallet = useRedux((state) => state.wallet.wallet);
  const guildStore = useRedux((state) => state.guild.allGuilds);
  const guildBankStore = useRedux((state) => state.guild.bank);

  // STATES / HOOKS -----------------

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [formState, setFormState] = useState<GuildBankInputs>(currentFormState);
  const [errors, setErrors] = useState<Errors>({
    joinFee: "",
    taxFee: "",

    joinCaptainAllocation: "",
    joinOfficerAllocation: "",
    taxCaptainAllocation: "",
    taxOfficerAllocation: "",

    updateReason: "",
  })
  const [runQueryBankPrice, loadingQueryBankPrice] = useAsyncTask("queryGuildPrice");
  const totalHunySupply = useRedux((state) => state.token.TotalHunySupply);
  const [bankInitiationPrice, setBankInitiationPrice] = useState<number>(0);
  const [bankUpdatePrice, setBankUpdatePrice] = useState<number>(0);
  const [currentPrice, setCurrentPrice] = useState<number>(0);

  const guild = useMemo(() => {
    if (!guildStore || !guildId) return;

    //INFO: Locate guild from store by guildId, redirects out if it's invalid
    const currentGuild = guildStore.find(guild => guild.id === parseInt(guildId))
    if (!currentGuild) {
      navigate('/guilds');
      return;
    }

    if (currentGuild.guildBank && (!guildBankStore || !currentGuild.guildBank.address)) dispatch(actions.Guild.reloadBank(currentGuild!));

    return currentGuild;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guildStore, guildId, guildBankStore])

  // Get current bank from store 
  const bank = useMemo(() => {
    if (!guildBankStore || !guild?.guildBank) return;
    const currentBank = guildBankStore;
    if (currentBank.id! !== guild.guildBank.id!) dispatch(actions.Guild.reloadBank(guild!));

    //Check for guild bank, set initial form state to current settings
    if (!!currentBank) {
      const currentSetting = {
        joinFee: bnOrZero(currentBank?.joiningFee?.initialAmt ?? 0).shiftedBy(-Decimals.HUNY),
        joinCaptainAllocation: Number(convertBpsAllocation(currentBank?.joiningFee?.captainAllocation ?? 0)),
        joinOfficerAllocation: Number(convertBpsAllocation(currentBank?.joiningFee?.officerAllocation ?? 0)),
        taxFee: bnOrZero(currentBank?.weeklyTax?.initialAmt ?? 0).shiftedBy(-Decimals.HUNY),
        taxCaptainAllocation: Number(convertBpsAllocation(currentBank?.weeklyTax?.captainAllocation ?? 0)),
        taxOfficerAllocation: Number(convertBpsAllocation(currentBank?.weeklyTax?.officerAllocation ?? 0)),
      }

      currentFormState = {
        ...currentSetting,

        joinFeeInput: currentSetting.joinFee.toString(),
        joinCaptainAllocationInput: currentSetting.joinCaptainAllocation.toString(),
        joinOfficerAllocationInput: currentSetting.joinOfficerAllocation.toString(),
        taxFeeInput: currentSetting.taxFee.toString(),
        taxCaptainAllocationInput: currentSetting.taxCaptainAllocation.toString(),
        taxOfficerAllocationInput: currentSetting.taxOfficerAllocation.toString(),


        controlMode: ControlMode[currentBank.controlMode],

        updateReason: initialFormState.updateReason,
      };
      setFormState(currentFormState);
    }

    return currentBank;
    // eslint-disable-next-line
  }, [guildBankStore, guild])

  const walletHolder = useMemo(() => {
    if (!guild || !profile || !wallet) return;
    const addr = wallet.addressInfo.byte20!.toLocaleLowerCase()!
    const hasGuild: boolean = profile.guildId ? true : false
    if (!hasGuild) {
      navigate('/guilds/create');
      return;
    }

    const isLeader: boolean = guild.leaderAddress === addr
    const isOfficer: boolean = guild.commanderAddresses.includes(addr)
    const canUpdate: boolean = isLeader || isOfficer;

    if (!canUpdate) {
      currentFormState = initialFormState;
      // setFormState(currentFormState);
      navigate(`/guilds/${guildId}/bank`);
      return
    }
    if (!bank && !canUpdate) {
      navigate(`/guilds/${guildId}/bank`);
      return;
    }

    return { addr, canUpdate }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, guild, profile, bank])

  useEffect(() => {
    const abortController = new AbortController();
    runQueryBankPrice(async () => {
      if (currentPrice > 0) return;

      const serviceFee: BigNumber = await TBMConnector.fetchUpdateBankPrice();
      setBankUpdatePrice(bnOrZero(serviceFee).toNumber())

      const crestItem = await TBMConnector.fetchStallItem(ITEM_ID[network].TreasurersDeed);
      const purchaseCount = await TBMConnector.fetchStallPurchaseCount(ITEM_ID[network].TreasurersDeed);
      const price = await computeFinalPrice(crestItem.arguments[1], bnOrZero(totalHunySupply), purchaseCount);
      setBankInitiationPrice(bnOrZero(price.finalPrice).toNumber());

      if (!bank) {
        setCurrentPrice(bnOrZero(price.finalPrice).toNumber());
        logger("debug-bank", "unlockBank", {
          hasBank: !!bank,
          price: bnOrZero(price.finalPrice).toNumber()
        })
      }
      else {
        setCurrentPrice(bnOrZero(serviceFee).toNumber())
        logger("debug-bank", "updateBank", {
          hasBank: !!bank,
          price: bnOrZero(serviceFee).toNumber()
        })
      }
    })
    return abortController.abort();
    // eslint-disable-next-line
  }, [totalHunySupply, bank])

  // FUNCTIONS ----------------------

  const validateInput = (field: string, input: string) => {
    const amt = bnOrZero(input);

    switch (field) {
      case "joinFee": case "taxFee":
        //TODO: Fetch 5% limit to replace static value
        if (amt.isGreaterThanOrEqualTo(999000)) return "This amount exceeds 5% of the current HUNY supply";
        return ""
      case "joinCaptainAllocation": case "taxCaptainAllocation":
        const totalOfficerAllocation = bnOrZero(formState[`${field.includes("join") ? "join" : "tax"}OfficerAllocation`]).multipliedBy(2);
        if ((amt.plus(totalOfficerAllocation)).isGreaterThan(100)) return "Total allocation cannot exceed 100%";
        return ""
      case "joinOfficerAllocation": case "taxOfficerAllocation":
        if (((amt.multipliedBy(2)).plus(bnOrZero(formState[`${field.includes("join") ? "join" : "tax"}CaptainAllocation`]))).isGreaterThan(100)) return "Total allocation cannot exceed 100%";
        return ""
      case "updateReason":
        if (input.length && input.length > 70) return "Enter a reason of up to 70 characters.";
        return ""
      default: return "";
    }
  }

  const convertInputs = (inputs: GuildBankInputs, excludeKeys: string[] = []): GuildBankSetting => {
    //INFO: Helper functions
    const flatten = (a) => Array.isArray(a) ? [].concat(...a.map(flatten)) : a
    const subset = (obj: Object, keys: string[], excludeKeys: boolean = false) => Object.keys(obj)
      .filter(key => excludeKeys ? keys.indexOf(key) < 0 : keys.indexOf(key) >= 0)
      .reduce((obj2, key) => { return (obj2[key] = obj[key], obj2) }, {});

    //INFO: Extract all field names
    const bankAttributes: string[] = flatten(formGroups.map(({ fields }) => [...(fields.map(({ fieldName }) => fieldName))]));
    const optionalAttributes: string[] = excludeKeys;

    return subset(inputs, (bankAttributes.filter(attr => !optionalAttributes.includes(attr)))) as GuildBankSetting
  }

  const isSubmitEnabled = useMemo(() => {
    // Check if wallet holder can initiate
    if (!walletHolder || !walletHolder.canUpdate) return false;

    // Check if there are any errors
    if (Object.values(errors).some(err => !!err)) {
      const errorFields: string[] = (Object.entries(errors).map(([key, value]) => !!value ? key : '')).filter(Boolean);
      if (!!errorFields && errorFields.length > 0) {
        const errorElement = document.getElementById(errorFields[0]);
        errorElement!.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }
      return false;
    }

    // Check if there are a/ny changes
    if (!!bank && !bank?.address && JSON.stringify(convertInputs(formState, optionalFields)) === JSON.stringify(convertInputs(currentFormState, optionalFields))) return false;

    return true;
  }, [bank, errors, formState, walletHolder])

  // EVENT HANDLERS -----------------

  //INFO: Universal Increment/Decrement Input handler
  const inputHandler = (inputAmt: string, attrName: string) => {
    const amt = (formState[attrName] instanceof BigNumber) ? bnOrZero(inputAmt) : Number(inputAmt);

    // Catch possible errs 
    if (typeof inputAmt === "string") {
      const errorText = validateInput(attrName, inputAmt);
      setErrors((prev) => ({ ...prev, [attrName]: errorText }));
    }

    setFormState({
      ...formState,
      [attrName]: amt,
      [`${attrName}Input`]: inputAmt,
    })
  };

  const handleAdd = (attrName: string) => {
    if (formState[attrName] instanceof BigNumber) {
      inputHandler(formState[attrName].plus(1).toString(10), attrName);
    }
    else {
      inputHandler((formState[attrName] + 1).toString(), attrName);
    }
  };

  const handleSubtract = (attrName: string) => {
    if (formState[attrName] instanceof BigNumber) {
      if (formState[attrName].isLessThanOrEqualTo(0)) return;
      inputHandler(formState[attrName].minus(1).toString(10), attrName);
    }
    else {
      if (formState[attrName] <= 0) return;
      inputHandler((formState[attrName] - 1).toString(), attrName);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputHandler(event.target.value, event.target.name);
  };

  const handleSingleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const attrName = event.target.name;
    const attrVal = event.target.value;
    // Catch possible errs 
    if (typeof attrVal === "string") {
      const errorText = validateInput(attrName, attrVal);
      setErrors((prev) => ({ ...prev, [attrName]: errorText }));
    }
    setFormState({
      ...formState,
      [attrName]: attrVal,
    })
  }

  const handleOnBack = () => {
    handleOnClose();
    navigate(`/guilds/${guildId}/bank`);
  }

  const handleShowDialog = () => {
    setShowDialog(true);
  }

  const handleOnReload = (currentGuild?: Guild) => {
    if (!guild) return;
    dispatch(actions.Guild.reloadBank(currentGuild ?? guild!));
  }

  const handleOnClose = () => {
    handleOnReload();
    setShowDialog(false);

    // setFormState(currentFormState);
  }

  const handleSubmit = () => {
    if (!isSubmitEnabled || !walletHolder?.canUpdate || !guild) return;

    handleShowDialog();
  }

  // --------------------------------

  const breadcrumbs = [
    {
      path: `/guilds`,
      value: "Guild Overview",
    },
    {
      path: `/guilds/${guildId}`,
      value: guild?.name!!,
    },
    {
      path: `/guilds/${guildId}/bank`,
      value: "Guild Bank",
    },
    {
      path: `/guilds/${guildId}/bank/settings`,
      value: "Settings",
    },
  ];

  const isLoading: boolean = loadingQueryBankPrice;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.refineryWrapper}>
        {guild && (<GuildBreadcrumb linkPath={breadcrumbs} />)}

        <Box sx={styles.root}>
          <Typography variant="h2" color="primary" sx={{
            width: '70%',
            fontSize: '40px',
            minWidth: '500px',
            ...GUILD_GRAY_GRADIENT,
            "@media (max-width:1024px)": {
              minWidth: 'unset',
              width: '100%',
            },
          }}>Guild Bank Settings</Typography>

          {!guild && (<CircularProgress sx={{ mt: '4rem' }} />)}

          {guild && (<>
            {/* //INFO: Joining Fee Settings */}
            {formGroups
              .filter(({ fields }) => {
                return !bank ? (Object.values(Object.assign({}, fields.map(f => f.fieldName))).filter(attr => !optionalFields.includes(attr))).length > 0 : fields;
              })
              .map((group, idx) => (
                <Box key={group.groupTitle} sx={styles.contentBox}>
                  <Box sx={styles.sectionHeaderText}>
                    <Typography variant="body2" color="primary" className="groupTitle">
                      {group.groupTitle}
                    </Typography>
                    <Typography variant="body2" color="primary" className="groupSubtitle">
                      {group.groupSubtitle}
                    </Typography>
                  </Box>
                  <Box sx={styles.guildInformationBox}>

                    {group.fields.map((field) => (

                      <Box key={field.name} sx={styles.fieldBox}>
                        <InputLabel htmlFor="name">
                          <Typography variant="body1" color="primary" sx={styles.inputLabel}>
                            {field.name}

                            {/* //TODO: Link to where */}
                            {field.tooltip && field.tooltipContent && (
                              <TooltipZolar link={field.tooltiplink ?? ""} sx={styles.tooltip}>
                                {field.tooltipContent}
                              </TooltipZolar>
                            )}
                          </Typography>
                        </InputLabel>

                        {field.fieldType !== "radioOption" && field.fieldType !== "text" && (
                          <FormControl fullWidth
                            sx={styles.formField}
                          >
                            <Box sx={combineStyles(styles.input, styles.numInputBox,
                              { ...(errors[field.fieldName]) && styles.errorInput }
                            )}>

                              <IconButton onClick={() => handleSubtract(field.fieldName)}>
                                <RemoveIcon style={{ height: 16, width: 16 }} />
                              </IconButton>

                              <Box sx={styles.numInput}>
                                {field.fieldType === "hunyAmount" && <Huny width="24px" height="24px" />}
                                <TextField
                                  id={field.fieldName}
                                  name={field.fieldName}
                                  onChange={handleChange}
                                  value={formState[field.fieldName].toString()}
                                  InputProps={{ inputComponent: (field.fieldType === "allocationPercentage" ? NumberFormat.NumberFormatPercent : NumberFormat.NumberFormatSeparator) as any }}
                                  sx={combineStyles(styles.input, styles.settingNumInput, {
                                    flex: field.fieldType === "hunyAmount" ? 0.5 : 1,
                                  }, {
                                    ...field.fieldType === "hunyAmount" && Number(formState[field.fieldName].toString()) >= 100000 && ({
                                      flex: `calc(0.6 + ${(Number(formState[field.fieldName].toString().length) - 5) / 10})`,
                                      "& input": { textAlign: "start" },
                                    })
                                  })} />
                              </Box>

                              <IconButton onClick={() => handleAdd(field.fieldName)}>
                                <AddIcon style={{ height: 16, width: 16 }} />
                              </IconButton>
                            </Box>

                            {errors[field.fieldName] &&
                              <Box sx={styles.errorTextContainer}>
                                <WarningRed width={15} />
                                <FormHelperText sx={styles.errorText}>{errors[field.fieldName]}</FormHelperText>
                              </Box>}

                          </FormControl>
                        )}

                        {field.fieldType === "radioOption" && field.radioOptions && (
                          <RadioGroup
                            name={field.fieldName}
                            defaultValue={formState[field.fieldName]}
                            onChange={handleSingleChange}
                            sx={styles.settingsRadio}>
                            {field.radioOptions.map((option, idx: number) => (
                              <FormControlLabel
                                key={idx}
                                control={
                                  <Radio
                                    value={option.value}
                                    icon={<RoundedUncheckedIcon width="24px" height="24px" />}
                                    checkedIcon={<RoundedCheckedIcon width="24px" height="24px" />}
                                  />
                                }
                                label={option.label} sx={styles.optionLabel} />
                            ))}
                          </RadioGroup>
                        )}

                        {field.fieldType === "text" && (
                          <FormControl fullWidth
                            sx={styles.formField}
                          >
                            <TextField
                              sx={combineStyles(styles.input, { ...(errors[field.fieldName]) && styles.errorInput })}
                              fullWidth
                              id={field.fieldName}
                              name={field.fieldName}
                              placeholder={field.placeholderText}
                              value={formState.updateReason}
                              onChange={handleSingleChange}
                            />
                            {errors[field.fieldName] &&
                              <Box sx={styles.errorTextContainer}>
                                <WarningRed width={15} />
                                <FormHelperText sx={styles.errorText}>{errors[field.fieldName]}</FormHelperText>
                              </Box>}
                          </FormControl>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}

            {/* //TODO: Fee TBC */}
            <Box display="flex" justifyContent="space-between" mt="60px" sx={combineStyles(styles.contentBox, {
              flexDirection: "row",
            })}>
              <Typography variant="body1" color="primary">
                Fee
                <TooltipZolar link="https://docs.zolar.io/stations/guilds#guild-pricing" sx={styles.tooltip}>
                  Unlock Fee: {formatIntegerNumber(bnOrZero(bankInitiationPrice).shiftedBy(-Decimals.HUNY).toNumber())} HUNY<br />
                  Changes after: {formatIntegerNumber(bnOrZero(bankUpdatePrice).shiftedBy(-Decimals.HUNY).toNumber())} HUNY
                </TooltipZolar>
              </Typography>
              <Box sx={styles.price}>
                <Typography
                  variant="h3"
                  color="success.main"
                  sx={{ fontWeight: 600 }}
                >
                  {formatIntegerNumber(bnOrZero(currentPrice).shiftedBy(-Decimals.HUNY).toNumber())} HUNY&nbsp;
                </Typography>{" "}
                <Huny />
              </Box>
            </Box>


            {/* //TODO: Control buttons: go back, set up */}
            {/* button box */}
            <Box display="flex" justifyContent="space-between" mt="60px" sx={combineStyles(styles.contentBox, {
              flexDirection: "row",
              columnGap: '25%',

              "button": {
                minWidth: '150px',
                whiteSpace: 'nowrap',
              },
              "@media (max-width:1024px)": {
                flexDirection: "column-reverse",
                rowGap: '1em',
                width: '70%',
              },
            })}>
              {/* back button */}
              <Button
                size="medium"
                variant="outlined"
                color="secondary"
                onClick={handleOnBack}
              >
                <Typography variant="button" sx={styles.gradientText}>
                  GO BACK
                </Typography>
              </Button>

              {/* create button */}
              <ContainedButton
                // fullWidth
                onClick={handleSubmit}
                disabled={!isSubmitEnabled || showDialog}
              >
                {isLoading
                  ? <CircularProgress size={18} />
                  : !bank ? 'Unlock Guild Bank' : 'Submit Changes'
                }
              </ContainedButton>
            </Box>

            {showDialog && (
              <PostUpdateDialog
                open={showDialog}
                onClose={handleOnClose}
                onBack={handleOnBack}
                onReload={handleOnReload}

                guild={guild}
                bankSettings={convertInputs(formState)}
                hasGuildBank={!!bank}
                price={currentPrice}
              />
            )}
          </>)}
        </Box>
      </Box>

    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    position: "relative",
    padding: "0px 40px 100px",
    minHeight: "1200px",
    width: '100%',
    overflowX: 'auto',

    "@media (max-width:1024px)": {
      padding: '0',
    },
  },
  refineryWrapper: {
    marginTop: '100px',
    padding: '40px',
    minHeight: '500px',

    "@media (min-width:1024px)": {
      minWidth: '900px'
    },
  },

  //INFO: Initiation
  root: {
    marginTop: "60px",
    paddingLeft: "24px",
    paddingRight: "24px",

    display: "flex",
    flexDirection: "column",
    justifyContent: 'start',
    placeItems: 'center',
  },

  contentBox: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    "@media (min-width:600px)": {
      minWidth: "500px",
    },
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  sectionHeaderText: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 0.8)",
    marginTop: "60px",
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    ".groupTitle": {
      ...GUILD_GRAY_GRADIENT,
      lineHeight: "1.75rem",
    },
    ".groupSubtitle": {
      fontSize: '12px',
      ...GUILD_GRAY_GRADIENT,
    },
  },
  guildInformationBox: {
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    borderRadius: "16px",
    marginTop: "12px",
    padding: "24px",
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  fieldBox: {
    display: 'flex',
    placeContent: 'space-between',
    alignItems: 'center',

    "@media (max-width:1024px)": {
      flexDirection: 'column',
      placeItems: 'start',
      gap: '0.5em',

      "> div": {
        maxWidth: 'unset',

        padding: 0,
        margin: 0,

        width: '100%',
        placeContent: 'start',
        placeItems: 'start',
      }
    },
  },
  input: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },

    "&:hover, &:active, &:focus, &:focus-within": {
      borderColor: '#AEF1EE',
    },

    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      paddingY: "12px",

      "&:active, &:focus": {
        color: "#AEF1EE",
        "& input": {
          color: "#AEF1EE",
        },
      }
    },
  },
  multilineInput: {
    minHeight: "48px",
    padding: "6px 24px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    fontSize: "14px",
    lineHeight: "28px",
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: 600,
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },
  },
  inputLabel: {
    fontSize: "14px",
    lineHeight: "28px",
    width: "250px",
    ...GUILD_GRAY_GRADIENT,
  },
  tooltip: {
    padding: "20px",
    width: "520px",
    "& .MuiTypography-root": {
      fontSize: "18px",
      lineHeight: "28px",
    },
    "@media (max-width:600px)": {
      padding: "20px",
      width: "250px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },


  // Numerical Input
  formField: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'end',
    placeItems: 'center',
    maxWidth: "360px",
  },
  numInputBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    width: "100%",
    gap: '24px',
  },
  errorInput: { borderColor: "#F75E5E!important", },
  numInput: {
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    gap: '8px',
    flex: 1,
  },
  settingNumInput: {
    background: 'transparent',
    border: 0,
    flex: 0.4,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
    "& input": {
      paddingX: '0',
      width: "100%",
      textAlign: "center",
    },
  },
  settingsRadio: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: "end",
  },
  optionLabel: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeContent: "start",
    placeItems: 'center',
    padding: '0 5%',
    gap: '8px',

    "& span": {
      fontSize: "14px",
      lineHeight: "24px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      whiteSpace: 'nowrap',
    }
  },


  qtyInput: {
    // width: '400px',
    height: '48px',
    // marginTop: '10px',
    border: '1px solid rgba(174, 241, 238, 0.1)',
    borderRadius: '16px',
    color: '#FEFEFE',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    "& input": {
      height: '30px',
      fontSize: '14px',
      fontWeight: 600,
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": 'none',
        margin: 0,
      },
      "&:[type=number]": {
        "-moz-appearance": 'textfield',
      },
    },
    "&.Mui-focused": {
      caretColor: '#FEFEFE',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 'none',
    },
    "@media (max-width:1024px)": {
      width: '320px',
    },
  },
  qtyInputInvalid: {
    // width: '400px',
    height: '48px',
    // marginTop: '10px',
    border: '1px solid rgba(174, 241, 238, 0.1)',
    borderRadius: '16px',
    color: '#FF2F2F',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    "& input": {
      height: '30px',
      fontSize: '24px',
      fontWeight: 600,
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": 'none',
        margin: 0,
      },
      "&:[type=number]": {
        "-moz-appearance": 'textfield',
      },
    },
    "&.Mui-focused": {
      caretColor: '#FEFEFE',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 'none',
    },
    "@media (max-width:1024px)": {
      width: '320px',
    },
  },


  //INFO: Price
  price: {
    display: "flex",
    placeItems: 'center',
    whiteSpace: "nowrap",
    "& svg": {
      verticalAlign: "text-top",
      marginTop: "2px",
      height: 22,
      width: 22,
    },
    "@media (max-width:600px)": {
      "& svg": {
        marginTop: 0,
        height: "20px!important",
        width: "20px!important",
      }
    },
  },


}

export default BankSettings