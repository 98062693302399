import { Box, BoxProps, Collapse, Divider, Grow, Link, Slide, styled, SxProps, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ElderberryJuice, EquipTabIcon, MetazoaLocationIcon, MinoTabIcon, UrsaTabIcon } from "assets";
import LevelUpIcon from "assets/icons/LevelUpIcon.png";
import MetazoaPreview from "assets/MetazoaPreview.gif";
import MetazoaXPBar from "assets/MetazoaXPBar.svg";
import PanelFrame from "assets/PanelFrame.svg";
import StatPanelBottomFrame from "assets/StatPanelBottomFrame.svg";
import StatPanelFrame from "assets/StatPanelFrame.svg";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DEFAULT_SRC, GUILD_LIGHTGRAY_GRADIENT, GUILD_LIGHTYELLOW_GRADIENT } from "components/Guild/components/GuildConstants";
import { LOCATIONS, MAP_LOCATIONS } from "components/Quest/QuestConstants";
import { logger } from "core/utilities";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Equipment, Guild, NftMetadata, NftStats } from "store/types";
import { BIG_ZERO } from "utils/constants";
import { bnOrZero } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import { getLocationTag } from "utils/zoaUtils";
import { EquipType, GEMS, GemTierStats } from "../ResourceConstants";
import JuiceConsumption from "./JuiceConsumption";
import { LOCATION_TAGS, METAZOA_TRAITS, PROFESSIONS, STATS, STAT_PROFESSION } from "./MetazoaConstants";
import ProfessionAssignment from "./ProfessionAssignment";
import { EquipDND, ExtendedStatPanelContent } from "./StatPanelContent";
import PointAssignment from "./StatPanelContent/PointAssignment";
import { computeProfessionBuffs } from "./utils/ProfessionBuffs";

enum TabModes {
  Stats,
  Equip,
}

export interface MetazoaViewProps {
  token: NftMetadata;
  guild?: Guild;
  hunyInWallet?: BigNumber;
  hunyInHive?: BigNumber;
}

export const StatPanel = (props: BoxProps) => {
  const { children, ...rest } = props;
  return (
    <Box sx={combineStyles(
      styles.frameBorder,
      styles.scrollableFrame
    )} {...rest}>
      {children}
    </Box >
  );
}


const MetazoaView: React.FC<MetazoaViewProps> = (props: MetazoaViewProps) => {
  const { token, guild, hunyInHive, hunyInWallet, ...rest } = props;

  const navigate = useNavigate();

  // Visibiility switch
  const isEquipEnabled: boolean = true;

  const [panelContent, setPanelContent] = useState<string>(Object.entries(token.stats!)[0][0] ?? 'STR');
  const [professionSelection, setProfessionSelection] = useState<string>('');
  const [extendedPanelContent, setExtendedPanelContent] = useState<string>('');
  const [consumeJuice, showJuiceConsume] = useState<boolean>(false);

  const [tabMode, setTabMode] = useState<number>(TabModes.Stats);

  const switchTabMode = (modeIdx: number) => {
    const maxModeIdx: number = (Object.values(TabModes).length / 2);
    if (modeIdx < maxModeIdx) setTabMode(modeIdx);

    // Collapse child subpanels
    setExtendedPanelContent('');
    setPanelContent('');

    // Preset specific tab defaults
    switch (modeIdx) {
      case TabModes.Stats: {
        setPanelContent(Object.entries(token.stats!)[0][0] ?? 'STR');
        break;
      }

      case TabModes.Equip: {
        setExtendedPanelContent('Equip');
        break;
      }
    }

    logger("debug-component", "handleTabMode", {
      modeIdx,
      maxModeIdx,
      currentMode: TabModes[modeIdx]
    })
  }
  const handleTabMode = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const modeIdx: number = parseInt(event.currentTarget.getAttribute("data-index") ?? "0");
    switchTabMode(modeIdx)
  }

  const zoaToken = useMemo(() => {
    /// INJECT EQUIPMENT STATS
    const tokenEquipments: Equipment[] = token?.equipments ?? [];
    if (!tokenEquipments.length) return token;

    let zToken: NftMetadata = JSON.parse(JSON.stringify((token))) as NftMetadata;

    // Compile gem stats from equipped items
    const gemBonusStats: [string, string, GemTierStats][] = tokenEquipments.reduce((list: [string, string, GemTierStats][], { tokenTraits }) => {
      const equipType: string = tokenTraits.Type;
      if (equipType !== EquipType[EquipType.Gem]) return list ?? [];
      const { Tier, Affinity } = tokenTraits;
      const currentGem: GemTierStats = GEMS[Tier][Affinity];
      if (!!currentGem?.type && currentGem.statBonus > 0) list.push([currentGem.type, Tier, currentGem]);
      return list ?? [];
    }, []);
    if (!gemBonusStats.length) return token;

    // Deepcopy of token stats
    const tokenStats: NftStats = JSON.parse(JSON.stringify((zToken.stats))) as NftStats;

    // Iterate gem bonus and append to token stats
    const updatedStats: NftStats = gemBonusStats
      .reduce((
        statMap: NftStats = tokenStats,
        [stat, tier, gemStats],
      ) => {
        const hasStat: boolean = Object.keys(statMap).includes(stat);
        if (!hasStat) return statMap;

        const gemName: string = `${gemStats.name ?? ''} (Class ${tier})`;
        const gemEquipment = {
          equipment: 'gem',
          name: gemName,
          stat: gemStats.stat,
          type: 'normal',
        };

        statMap[stat].total = (statMap[stat]?.total || 0) + gemStats.statBonus;
        statMap[stat].equipment = Array.from([
          ...statMap[stat].equipment,
          gemEquipment,
        ]);

        logger("debug-bonus", "EquipPanel/inventory-updatedStats", stat, {
          gemStats,
          gemEquipment,
          tStat: token.stats?.[stat]?.total ?? 0,
          fStat: statMap[stat]?.total,
        })

        return statMap;
      }, (tokenStats ?? {} as NftStats));

    // Overwrite with updated stats
    zToken.stats = updatedStats;
    logger("debug-bonus", "EquipPanel/inventory-updatedStats", {
      zToken,
      tStat: token.stats ?? {},
      fStat: updatedStats,
    });

    return zToken;
  }, [token]);

  const handleChange = (event: React.MouseEvent<HTMLElement>, content: any) => {
    logger("debug-component", "handleChange")
    setPanelContent(content);
    setExtendedPanelContent('');
  };
  const handleExtendedChange = (event: React.MouseEvent<HTMLElement>, content: any) => {
    setExtendedPanelContent(content);
  };

  const statBuffs = ["LUK", "SPD", "END", "ACC"]

  const getAttribute = (traitType: string, field: string) => {
    if (!token.attributes?.length)
      return "?";

    const index = token.attributes.findIndex(attribute => attribute.trait_type === traitType);

    if (index === -1)
      return "?";

    if (field === "value") {
      return token.attributes[index].value;
    } else {
      const rarity = token.attributes[index].rarity;
      return bnOrZero(rarity).shiftedBy(2).toFixed(1);
    }
  }

  const location: string = token.location ?? 'Unknown';
  const locationTag: string = getLocationTag(location);

  const buffs = computeProfessionBuffs(token, (location !== 'Unknown' && location !== 'At Base') ? location : "");
  const levellingBonus = bnOrZero(buffs?.levellingBonus.total?.bonusAmt).plus(100).dividedBy(100);
  const baseMspAmount = bnOrZero(5);
  const totalMspPerDay = bnOrZero(baseMspAmount).times(levellingBonus);

  const missionGround: string = Object.keys(LOCATIONS).find(key => LOCATIONS[key].name === location) ?? "";
  const isValidLocation: boolean = MAP_LOCATIONS.includes(missionGround) ?? false;
  const canAssignPoints: boolean = !!token.unassignedStatPoints ?? false;

  const getStatContent = () => {
    if (!panelContent) return;

    if (panelContent === "GenesisInfo") {
      return (
        <Box>
          <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Genesis Stats</Typography>
          <Box>
            <Box sx={styles.attributeBox}>
              {METAZOA_TRAITS.slice(0, 5).map((trait, index) => (getAttribute(METAZOA_TRAITS[index + 5], "value") !== "?") && (
                <Box sx={styles.attributesRow} key={index}>
                  <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>{trait}</Typography>
                  <Typography color="primary" sx={styles.value}>{getAttribute(trait, "value")}</Typography>
                </Box>
              ))}
              <Divider sx={styles.cardDivider} />

              <Box>
                {METAZOA_TRAITS.slice(0, 5).map((trait, index) => (getAttribute(METAZOA_TRAITS[index + 5], "value") !== "?") && (
                  <Box sx={styles.genesisTraits} key={index}>
                    <Typography color="primary" variant="subtitle1" sx={styles.traitType}>
                      {METAZOA_TRAITS[index + 5]}
                      <Typography color="primary" sx={combineStyles(styles.value, {

                      })} paddingX={0}>{getAttribute(METAZOA_TRAITS[index + 5], "value")}</Typography>
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
    else if (panelContent === "Profession") {
      if (token.profession) {
        const baseGatheringAmt = PROFESSIONS[STAT_PROFESSION[token.profession]].baseGatheringAmt;
        const totalGatheringBonus = bnOrZero(buffs?.gatheringBonus.total?.bonusAmt).plus(100).dividedBy(100);
        const totalGatheringAmt = bnOrZero(baseGatheringAmt).times(totalGatheringBonus);
        return (
          <Box>
            <Box sx={styles.professionBox}>
              <Box
                component="img"
                src={PROFESSIONS[STAT_PROFESSION[token.profession]].icon ?? MetazoaPreview}
                height="54px"
                width="54px"
                onError={DEFAULT_SRC}
              />
              <Box sx={{ "> *": { margin: 0, } }}>
                <Typography variant="subtitle1" color="secondary.main" sx={combineStyles(styles.statDetailsHeader, {
                  margin: 0,
                  ...LOCATION_TAGS[STAT_PROFESSION[token.profession]],
                })}>{STAT_PROFESSION[token.profession]}</Typography>
                <Typography variant="body1" color="primary" sx={styles.statTextSize}>{PROFESSIONS[STAT_PROFESSION[token.profession]].description}</Typography>
              </Box>
            </Box>

            <Box>
              <Box sx={styles.attributeBox}>
                <ToggleButtonGroup
                  orientation="vertical"
                  value={extendedPanelContent}
                  exclusive
                  onChange={handleExtendedChange}
                  sx={styles.toggleGroupContainer}
                >
                  <Box sx={styles.attributesRow}>
                    <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Mastery</Typography>
                    <Typography color="primary" sx={styles.value}>{netMasteryLevel}</Typography>
                  </Box>
                  <Box sx={styles.attributesRow}>
                    <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Mastery Points to Next Level</Typography>
                    <Typography color="primary" sx={styles.value}>{xpGained.toFixed(0)}/{xpRequired.toFixed(0)}</Typography>
                  </Box>
                  <ExtendedStatToggleButton value={"Mastery Points per Day"}>
                    <Box sx={styles.attributesRow}>
                      <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Mastery Points per Day</Typography>
                      <Typography color="primary" sx={styles.value}>{totalMspPerDay.toFixed(2)}</Typography>
                    </Box>
                  </ExtendedStatToggleButton>
                  <ExtendedStatToggleButton value={"Resource Gathered per Day"}>
                    <Box sx={styles.attributesRow}>
                      <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Resource Gathered per Day</Typography>
                      <Typography color="primary" sx={styles.value}>{totalGatheringAmt.toFixed(2)}</Typography>
                    </Box>
                  </ExtendedStatToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>

            {isValidLocation && (
              <Typography variant="body1" color="error.main" sx={{ ...styles.statTextSize, mt: '27px', }}>
                Metazoa needs to be unstaked to show updated Mastery Points
              </Typography>
            )}

            {/* //TODO: Retrieve profession battleground dynamically upon API availability */}
            <ContainedButton
              sx={{
                ...styles.confirmButton,
                m: 0,
                mt: 2,
              }}
              onClick={() => navigate(`/map/${PROFESSIONS[STAT_PROFESSION[token.profession ?? '']].missionGround}`)}
            >
              Gather Resources
              {/* Quests - Coming Soon */}
            </ContainedButton>
          </Box>
        );
      } else return (
        <ProfessionAssignment
          token={token}
          professionSelection={professionSelection}
          setProfessionSelection={setProfessionSelection}
        />
      )
    }
    else if (panelContent === "Level") {
      let totalLevellingAmt = BIG_ZERO;
      if (token.profession) {
        const baseLevellingAmt = 1;
        const totalLevellingBonus = bnOrZero(buffs?.metazoaLevelingBonus.total?.bonusAmt).plus(100).dividedBy(100);
        totalLevellingAmt = bnOrZero(baseLevellingAmt).times(totalLevellingBonus);
      }

      logger("debug-level", {
        bonus: token?.bonuses,
        metazoaLevelingBonus: token?.bonuses?.metazoaLevelingBonus?.total ?? '0',
        exp: token.exp,
        juiceNeeded: Math.ceil((token.exp?.xpRequired ?? 0) / 10)
      })
      return (
        <Box>
          <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Level Details</Typography>

          <Box>
            <Box sx={styles.attributeBox}>

              <ToggleButtonGroup
                orientation="vertical"
                value={extendedPanelContent}
                exclusive
                onChange={handleExtendedChange}
                sx={styles.toggleGroupContainer}
              >
                <Box sx={styles.attributesRow}>
                  <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Metazoa Level</Typography>
                  <Typography color="primary" sx={styles.value}>{token.exp?.level ?? 1}</Typography>
                </Box>
                <ExtendedStatToggleButton value={"Metazoa Levelling Speed"} disabled={!token.profession}>
                  <Box sx={styles.attributesRow}>
                    <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Metazoa Levelling Speed</Typography>
                    <Typography color="primary" sx={styles.value}>
                      {totalLevellingAmt.toFixed(2)}%
                    </Typography>
                  </Box>
                </ExtendedStatToggleButton>
                <Box sx={styles.attributesRow}>
                  <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Metazoa Level XP</Typography>
                  <Typography color="primary" sx={styles.value}>{token.exp?.xpGained ?? 1}</Typography>
                </Box>

              </ToggleButtonGroup>
            </Box>
          </Box>

          <Divider sx={styles.cardDivider} />

          <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Upgrade this Metazoa</Typography>
          <Typography variant="body1" color="primary" sx={styles.statTextSize} mb="8px">
            Give Elderberry Juice to this Metazoa to upgrade its Metazoa Level. Each Elderberry Juice consumed will boost the Metazoa level by 10XP.&nbsp;
            <Link
              target="_blank"
              href="https://docs.zolar.io/overview/the-inhabitants/collection-ii-metazoa/professions-new"
            >
              Learn More
            </Link>
          </Typography>

          <Box>
            <Box sx={styles.attributeBox}>
              {/* //TODO: Filled dyanmically upon API availability */}
              <Box sx={styles.attributesRow}>
                <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, { paddingX: 0, ...GUILD_LIGHTGRAY_GRADIENT })}>Elderberry Juice needed</Typography>
                <Typography color="success.main" fontSize={12} sx={{ verticalAlign: 'middle', }}>
                  {Math.max(Math.ceil((token?.exp?.xpRequired ?? 10) / 10), 0)}
                  <ElderberryJuice style={{ verticalAlign: 'middle' }} />
                </Typography>
              </Box>
            </Box>
          </Box>


          <Box sx={{
            my: '27px',
          }}>
            {isValidLocation && (
              <Typography variant="body1" color="error.main" sx={styles.statTextSize}>
                Metazoa needs to be unstaked to update XP and consume elderberry juice
              </Typography>
            )}
            {!token.exp && (
              <Typography variant="body1" color="error.main" sx={styles.statTextSize}>
                Metazoa needs to have an assigned profession to consume elderberry juice.
              </Typography>
            )}
            <ContainedButton
              disabled={!token?.exp || isValidLocation}
              onClick={() => { showJuiceConsume(true) }}
              sx={combineStyles(styles.confirmButton, {
                m: 0,
                mt: 2,
                "&.MuiButton-root": {
                  fontWeight: 700,
                  fontSize: '16px',
                  whiteSpace: "nowrap",
                },
              })}
            >
              Consume Elderberry Juice
            </ContainedButton>
          </Box>


          <JuiceConsumption token={token} open={consumeJuice} onClose={() => { showJuiceConsume(false) }} />
        </Box >
      )
    }
    else {
      const title = STATS[panelContent.toUpperCase()];
      const stat = zoaToken.stats![panelContent]
      const classAttribute = token.attributes!.find(attribute => attribute.trait_type === "Class")
      const factionAttribute = token.attributes!.find(attribute => attribute.trait_type === "Faction")
      const genAttribute = token.attributes!.find(attribute => attribute.trait_type === "Gen")
      const affinityAttribute = token.attributes!.find(attribute => attribute.trait_type === "Affinity")

      return (
        <>
          {canAssignPoints && (<PointAssignment token={token} attributeAbbr={title.type} isStaked={isValidLocation} />)}

          <Box>
            <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>{title?.fullLength} Components</Typography>
            {/* <Typography variant="body1" color="primary" sx={styles.statTextSize}>{title?.description}</Typography> */}

            {/* Class base + class power + gen*/}
            {!!(stat.classBonus + stat.classPower + stat.genPower) && (
              <Box sx={styles.attributesRow}>
                <Box sx={styles.attributesLabel}>
                  <Typography variant="subtitle1" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                    {classAttribute?.value}/
                    {factionAttribute?.value}/
                    {genAttribute?.value}&nbsp;
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={combineStyles(styles.statTextSize, styles.traitType)}
                    component="span"
                  >
                    {classAttribute?.trait_type}/
                    {factionAttribute?.trait_type}/
                    {genAttribute?.trait_type}
                  </Typography>
                </Box>
                <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                  +{stat.classBonus + stat.classPower + stat.genPower}
                </Typography>
              </Box>
            )}

            {/* Affinity */}
            {!!stat.affinity && (
              <Box sx={styles.attributesRow}>
                <Box sx={styles.attributesLabel}>
                  <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                    {affinityAttribute?.value}&nbsp;
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={combineStyles(styles.statTextSize, styles.traitType)}
                    component="span"
                  >
                    {affinityAttribute?.trait_type}
                  </Typography>
                </Box>
                <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                  +{stat.affinity}
                </Typography>
              </Box>
            )}
            {/* Equipment */}
            {stat.equipment.map((item, idx) => (
              <Box sx={styles.attributesRow} key={idx}>
                <Box sx={styles.attributesLabel}>
                  <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                    {item.name}&nbsp;
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={combineStyles(styles.statTextSize, styles.traitType)}
                    component="span"
                  >
                    Item
                  </Typography>
                </Box>
                <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                  +{item.stat}
                </Typography>
              </Box>
            ))}
            {/* Total Strength */}
            <Box sx={combineStyles(styles.attributesRow, styles.totalRow)}>
              <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, { fontSize: '14px', ...GUILD_LIGHTGRAY_GRADIENT })}>
                Total {title?.fullLength}
              </Typography>
              <Typography variant="subtitle1" color="success.main" sx={combineStyles(styles.statTextSize, { fontSize: '14px', fontWeight: '400' })}>
                {stat.total}&nbsp;{title?.type?.toUpperCase()}
              </Typography>
            </Box>

            {!!token?.profession && !statBuffs.includes(title?.type ?? "") && !!(token.profession === title?.type) && (<>
              <Divider sx={styles.cardDivider} />
              <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Stats Effect</Typography>
              {/* Profession Buff - Gathering Speed */}
              <Box sx={styles.attributesRow} >
                <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                  {PROFESSIONS[STAT_PROFESSION[token.profession]].resource} Gathering Speed
                </Typography>
                <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                  +{PROFESSIONS[STAT_PROFESSION[token.profession]].levelBonus * 100}%
                </Typography>
              </Box>

              {/* Profession Buff - Mastery Improvement Speed */}
              <Box sx={styles.attributesRow}>
                <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                  Mastery Improvement Speed&nbsp;
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={combineStyles(styles.statTextSize, styles.disabledText)}
                    component="span"
                  >
                    {PROFESSIONS[STAT_PROFESSION[token.profession]].baseGatheringAmt}
                  </Typography>
                </Typography>
                <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                  +{PROFESSIONS[STAT_PROFESSION[token.profession]].levelBonus * 100}%
                </Typography>
              </Box>
            </>)}

            {((title?.type !== "STR" && title?.type !== "INT" && title?.type !== "DEX") && (stat.total !== 0)) && (<>
              <Divider sx={styles.cardDivider} />
              <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Stats Effect</Typography>

              <Box sx={styles.attributesRow} >
                <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                  {title?.professionBonus.attrTitle}
                </Typography>
                <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                  {title?.professionBonus.attrBonus! > 0 && '+'}{title?.professionBonus.attrBonus ? title?.professionBonus.attrBonus * stat.total : 0}{title?.professionBonus.suffix ?? '%'}
                </Typography>
              </Box>
            </>)}
          </Box >
        </>
      );
    }
  }

  // TODO Mission 2 Phase 3 Metazoa xp
  let progressPercentage = 0;
  if (token?.exp) {
    const {
      xpGained,
      xpRequired
    } = token.exp;

    progressPercentage = (xpGained / xpRequired) * 100;
  }

  const containerRefStat = useRef(null);
  const extendedContainerRefStat = useRef(null);

  // TODO Mission 2 Phase 3 Fetch level from API
  // const tokenLuk = zoaToken?.stats?.['LUK']?.total ?? 0;
  let netMasteryLevel = token?.masteryExp?.level ?? 1;

  const xpRequired = token.masteryExp?.xpRequired ?? 0;
  const xpGained = token.masteryExp?.xpGained ?? 0;
  return (
    <Grow
      mountOnEnter
      unmountOnExit
      in={true}
      style={{ transformOrigin: '0 0 0' }}
      {...(true ? { timeout: 1000 } : {})}
    >
      <Box
        sx={{
          ...styles.nftViewWrapper,
          transition: ' transform 0.25s ease',
          marginBottom: (tabMode === TabModes.Equip) ? '100px' : '0px',
        }}
        {...rest}
      >
        <Box sx={combineStyles(styles.nftWrapper, { ...(!panelContent) && styles.nftTransition }, { ...(!!extendedPanelContent.length) && styles.nftTransition2 })} >
          {!!panelContent && (<>
            <Box sx={styles.statDetailsWrapper} ref={containerRefStat}>
              <Slide direction="right" in={true} timeout={1000} container={containerRefStat.current}>
                <Box sx={styles.statDetailsContainer}>
                  <StatPanel>
                    {getStatContent()}
                  </StatPanel>
                </Box>
              </Slide>
            </Box>

            {!!extendedPanelContent && (
              <Box sx={combineStyles(styles.statDetailsWrapper, styles.extendedWrapper)}>
                <Slide direction="right" in={true} timeout={1000} container={extendedContainerRefStat.current}>
                  <Box sx={styles.statDetailsContainer}>
                    <StatPanel sx={combineStyles(styles.frameBorder, { minHeight: '320px' })}>
                      {!!token.profession && (<ExtendedStatPanelContent panelContent={extendedPanelContent} tokenProfessionBuff={buffs} token={token} />)}
                    </StatPanel>
                  </Box>
                </Slide>
              </Box>
            )}
          </>)}
          <Box
            component="section"
            sx={combineStyles(styles.nftImageContainer, {
              ...(canAssignPoints) && {
                boxShadow: '-12px 18px 72px -32px rgba(255, 137, 82,1)',
                borderColor: '#FF8952',
              }
            })}
          >
            {/* TAB ICON - ZOA */}
            <Box sx={styles.tabButtonBg}>
              <Box sx={styles.tabButton}
                data-index={TabModes.Stats}
                onClick={handleTabMode}
              >
                {token.attributes?.[0]?.value === 'Ursa' ? <UrsaTabIcon width="40px" height="40px" /> : <MinoTabIcon width="40px" height="40px" />}
              </Box>
            </Box>

            {/* TAB ICON - EQUIP */}
            <Box sx={combineStyles(
              styles.tabButtonBg,
              {
                top: '60px',
                display: !isEquipEnabled ? 'none' : 'block',
              }
            )}
              data-index={TabModes.Equip}
              onClick={handleTabMode}
            >
              <Box sx={styles.tabButton}>
                {<EquipTabIcon width="40px" height="40px" />}
              </Box>
            </Box>

            <Box width={'100%'} borderRadius={4}>
              <Box sx={combineStyles(styles.levelProfessionRow, { "*": { paddingY: 0, }, ...canAssignPoints && { borderColor: '#FF8952' } })}>
                <Box sx={combineStyles(styles.levelColumn, styles.column)} border={0}>
                  <Typography variant="body1" color="secondary.main" sx={styles.levelProfessionText}>#{token.id}</Typography>
                </Box>

                {/* LOCATION */}
                <Box sx={combineStyles(styles.column, {
                  flexDirection: 'row-reverse',
                  "svg path": {
                    fill: `url(#${locationTag}0)`,
                  },
                  "svg circle": {
                    stroke: `url(#${locationTag}1)`,
                  },
                })}>
                  <Typography variant="body1" color="secondary.main" sx={combineStyles(styles.levelProfessionText, LOCATION_TAGS[locationTag])}>
                    <MetazoaLocationIcon style={{
                      verticalAlign: 'middle',
                      marginRight: '0.5em',
                      width: '22px',
                      height: '22px',
                    }} />
                    <span style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>{(!!token.profession && !true) ? PROFESSIONS[STAT_PROFESSION[token.profession]].missionGround : location}</span>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ ...styles.levelProfessionRow, ...canAssignPoints && { borderColor: '#FF8952' } }}>
                <Box sx={combineStyles(styles.levelColumn, styles.column, { paddingY: '5px' })}>
                  <Typography variant="body1" color={canAssignPoints ? '#FF8952' : "secondary.main"} sx={styles.levelProfessionText}>
                    Level
                    &nbsp;
                    {canAssignPoints && (
                      <Box
                        component="img"
                        src={LevelUpIcon}
                        height="14px"
                        width="14px"
                        sx={{
                          verticalAlign: 'sub',
                        }}
                      />
                    )}
                  </Typography>
                  <Typography variant="body1" color={canAssignPoints ? '#FF8952' : "success.main"} sx={styles.levelProfessionText}>{token.exp?.level ?? 1}</Typography>

                </Box>
                <Box sx={combineStyles(styles.column, styles.professionDetails)}>
                  <Typography variant="body1" color="secondary.main" sx={styles.levelProfessionText}>Profession</Typography>
                  <Box height={18} sx={styles.professionContent}>
                    {token?.profession ? (<>
                      <Box
                        component="img"
                        src={PROFESSIONS[STAT_PROFESSION[token.profession]].icon ?? MetazoaPreview}
                        height="18px"
                        width="18px"
                        onError={DEFAULT_SRC}
                        sx={styles.professionIcon}
                      />
                      <Typography variant="body1" color="success.main" sx={combineStyles(styles.levelProfessionText, {
                        display: 'inline-block',
                        ...LOCATION_TAGS[STAT_PROFESSION[token.profession]],
                      })}>{STAT_PROFESSION[token.profession]}</Typography>
                    </>) : (
                      <Typography variant="body1" color="success.main" sx={combineStyles(styles.levelProfessionText, styles.disabledText)}>Unassigned</Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* XP BAR */}
            <Box sx={styles.tokenImageWrapper}>
              <Box sx={styles.xpWrapper}>
                <Box sx={styles.xpContainer}>
                  <Typography variant="overline" color="secondary.main" sx={styles.xpLabel}>XP</Typography>
                  <Box sx={styles.progressBarWrapper}>
                    <Box sx={styles.xpProgress} width={`${progressPercentage}%`} />
                  </Box>
                  <Typography variant="overline" color="secondary.main" sx={styles.xpLabel}>
                    <Typography component="span" color="success.main" sx={styles.xpLabel}>{token.exp?.xpGained ?? 0}</Typography>
                    /{token.exp?.xpRequired ?? 50}
                  </Typography>
                </Box>
              </Box>
              <Box
                component="img"
                src={token.image}
                width="97%"
                sx={styles.nftImage}
                onError={DEFAULT_SRC}
              />
            </Box>

            {/* STATS BELOW ZOA */}
            <Collapse
              in={tabMode === TabModes.Equip}
            >
              <Box
                sx={{
                  border: '12px double transparent',
                  // backgroundClip: 'padding-box',
                  borderImage: `url(${StatPanelBottomFrame})`,
                  borderImageSlice: '20',
                  borderImageWidth: '20px 20px 20px 20px',
                  borderImageOutset: '0px 0px 0px 0px',
                  borderImageRepeat: 'stretch stretch',
                  padding: '20px 0 5px',
                  background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',

                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                  placeContent: 'center',
                  placeItems: 'center',

                  rowGap: '8px',
                  // columnGap: '12px',
                  zIndex: '-1',

                  ">*:last-child": {
                    gridColumn: '-1 / 1',
                  },
                }}>
                {Object.entries(zoaToken.stats!).map(([stat, statDetail]) => (
                  <Box
                    key={stat}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      placeContent: 'center',
                      placeItems: 'center',
                    }}
                  >
                    <Box
                      component="img"
                      src={(STATS[stat.toUpperCase()])?.icon ?? MetazoaPreview}
                      height="16px"
                      width="16px"
                      onError={DEFAULT_SRC}
                    />

                    <Typography variant="overline" color="primary" sx={styles.statLabel}>{stat}</Typography>

                    <Typography variant="overline" color="success.main" sx={styles.statLabel}>{statDetail.total ?? 0}</Typography>

                  </Box>
                ))}
              </Box>
            </Collapse>
          </Box>

          {(tabMode === TabModes.Stats) && (
            <Box sx={styles.centerCol}>
              {/* STATS PANEL */}
              <Box sx={combineStyles(styles.statPanelFrame, {
                borderBottom: 'none',
                ...(canAssignPoints) && {
                  background: '#FF8952',
                }
              })}>
                <Box sx={styles.statPanelContent}>
                  <Typography variant="overline" color={canAssignPoints ? '#FF8952' : "secondary.main"} sx={combineStyles(styles.statTextSize, { paddingX: '8px', paddingTop: '4px' })}>Stats</Typography>
                  <StatToggleGroup
                    orientation="vertical"
                    value={panelContent}
                    exclusive
                    onChange={handleChange}
                    sx={combineStyles(
                      styles.statPanelContentGroup,
                      {
                        ...(canAssignPoints) && {
                          borderColor: '#FF8952',
                        }
                      }
                    )}
                  >
                    {Object.entries(zoaToken.stats!).map(([stat, statDetail]) => (
                      <StatToggleButton value={stat} key={stat} sx={{
                        ...(canAssignPoints) && {
                          '&.Mui-selected, &:hover': {
                            outline: '1px solid #FF8952',
                          },
                        },


                      }}>
                        <Box sx={combineStyles(
                          styles.statCorner, {
                          ...canAssignPoints && {
                            background: '#FF8952',
                          },
                        })} />
                        <Box sx={{
                          ...styles.statRow,
                          ...canAssignPoints && {
                            background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(255, 182, 0, 0.2) 22.92%, rgba(255, 137, 82, 0.2) 100%)',
                          }
                        }}>
                          <Box sx={styles.statGroup}>
                            <Box
                              // component="img"
                              // src={(STATS[stat.toUpperCase()])?.icon ?? MetazoaPreview}
                              height="16px"
                              width="16px"
                              // onError={DEFAULT_SRC}
                              sx={{
                                position: 'relative',
                                maskImage: `url(${(STATS[stat.toUpperCase()])?.icon ?? MetazoaPreview})`,
                                maskRepeat: 'no-repeat',
                                maskSize: '80%',
                                maskPosition: 'center',
                                background: canAssignPoints ? '#FF8952' : '#AEF1EE',
                              }}
                            />
                            <Typography variant="overline" color={canAssignPoints ? '#FF8952' : "primary"} sx={combineStyles(
                              styles.statLabel,
                              { ...canAssignPoints && GUILD_LIGHTGRAY_GRADIENT, }
                            )}>{stat}</Typography>
                          </Box>
                          <Typography variant="overline" color={canAssignPoints ? '#FCCE58' : "success.main"} sx={combineStyles(
                            styles.statLabel,
                            { ...canAssignPoints && GUILD_LIGHTYELLOW_GRADIENT, }
                          )}>{statDetail.total ?? 0}</Typography>
                        </Box>
                      </StatToggleButton>
                    ))}
                  </StatToggleGroup>
                </Box>
              </Box>

              {/* OTHERS PANEL */}
              <Box sx={combineStyles(styles.statPanelFrame, { maskImage: 'none', })}>
                <Box sx={combineStyles(styles.statPanelContent, { maskImage: 'none', })}>
                  <Typography variant="overline" color="secondary.main" sx={combineStyles(styles.statTextSize, { paddingX: '8px', paddingTop: '4px' })}>Others</Typography>

                  <StatToggleGroup
                    orientation="vertical"
                    value={panelContent}
                    exclusive
                    onChange={handleChange}
                    sx={styles.statPanelContentGroup}
                  >
                    <StatToggleButton value="GenesisInfo" key="GenesisInfo" aria-label="Genesis Info">
                      <Box sx={styles.statCorner} />
                      <Box sx={styles.statRow}>
                        <Typography variant="overline" color="primary" sx={styles.statLabel}>Genesis info</Typography>
                      </Box>
                    </StatToggleButton>
                    <StatToggleButton value="Level" key="Level" aria-label="Level">
                      <Box sx={styles.statCorner} />
                      <Box sx={styles.statRow}>
                        <Typography variant="overline" color="primary" sx={styles.statLabel}>Level</Typography>
                      </Box>
                    </StatToggleButton>
                    <StatToggleButton value="Profession" key="Profession" aria-label="Profession">
                      <Box sx={styles.statCorner} />
                      <Box sx={styles.statRow}>
                        <Typography variant="overline" color="primary" sx={styles.statLabel}>Profession</Typography>
                      </Box>
                    </StatToggleButton>
                  </StatToggleGroup>
                </Box>
              </Box>
            </Box>
          )}

          {(tabMode === TabModes.Equip) && (
            <EquipDND.Panel
              token={token}
              isStaked={isValidLocation}
              isUnassigned={!token?.profession ?? true}
            />
          )}
        </Box>
      </Box>
    </Grow >
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  professionDetails: {
    paddingY: '5px',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'center',
  },
  professionContent: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
  },
  professionIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '0.5em',
  },

  genesisTraits: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',

    "> *": {
      padding: '1px 6px'
    }
  },

  // SIDE PANEL FRAMES
  statPanelFrame: {
    minWidth: '144px',
    maskImage: `url(${StatPanelFrame})`,
    maskRepeat: 'no-repeat',
    maskSize: '100%',
    maskPosition: 'top',

    background: '#AEF1EE',
    // border: '1px solid #AEF1EE',
    borderLeft: 'none',
    borderRadius: '6px',
    padding: '1px',
    paddingLeft: '0px',
  },
  statPanelContent: {
    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    maskImage: `url(${StatPanelFrame})`,
    maskRepeat: 'no-repeat',
    maskSize: '100%',
    maskPosition: 'top',

    display: 'flex',
    flexDirection: 'column',
    placeContent: 'start',
    placeItems: 'start',
    gap: '0.15em',
    borderRadius: '6px',
  },
  statPanelContentGroup: {
    width: '100%',
    padding: '8px',
    borderTop: '1.5px solid #AEF1EE',
    borderRadius: '0',
    height: '100%',

    placeContent: 'start',
    placeItems: 'center',
    gap: '0.4em',

    "button": {
      margin: 0,
    }
  },

  frameBorder: {
    border: '12px double transparent',
    // backgroundClip: 'padding-box',
    borderImage: `url(${PanelFrame})`,
    borderImageSlice: '20',
    borderImageWidth: '20px 20px 20px 20px',
    borderImageOutset: '0px 0px 0px 0px',
    borderImageRepeat: 'stretch stretch',
    padding: '10px 20px 8px 56px',
    background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
  },
  scrollableFrame: {
    maxHeight: '450px',

    msOverflowStyle: 'none',
    overflowY: 'scroll',

    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    scrollSnapType: "both mandatory",
    scrollPaddingTop: '5em',
    scrollPaddingBottom: '5em',
    '::-webkit-scrollbar': {
      display: 'none'
    },

    '> *': {
      scrollSnapAlign: 'start',
    },
  },

  nftViewWrapper: {
    width: '900px',
  },
  tabButtonBg: {
    clipPath: 'polygon(20% 0%, 100% 0, 99% 100%, 20% 100%, 0% 80%, 0% 20%)',
    position: 'absolute',
    top: '-1px',
    left: '-67px',
    padding: '1px',
    background: '#AEF1EE',
    width: '70px',
    zIndex: -1,
    borderRadius: '17px 0px 0px 17px',
  },
  tabButton: {
    clipPath: 'polygon(20% 0%, 100% 0, 100% 100%, 20% 100%, 0% 80%, 0% 20%)',
    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    padding: '10px 16px',
    // width: '80px',
    display: 'flex',
    borderRadius: '17px 0px 0px 17px',
  },
  nftWrapper: {
    display: 'flex',
    position: 'relative',
    transform: 'translate(-5px, 0px)',
    // transform: 'translate(-105px, 0px)',
    // transform: 'translate(-150px, 0px)',
    transitionDuration: '1s',
  },
  nftTransition: {
    transform: 'translate(100px, 0px)',
    transitionDuration: '1s',
  },
  nftTransition2: {
    // transform: 'translate(100px, 0px)',
    transform: 'translate(-150px, 0px)',
    transitionDuration: '1s',
  },
  nftImageContainer: {
    position: 'relative',
    maxHeight: '480px',
    height: '100%',
    width: '430px',
    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    borderRadius: '4px',
    border: '1px solid #AEF1EE',
    boxShadow: '-12px 18px 72px -32px rgba(174,241,238,1)',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  levelProfessionRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #AEF1EE',
  },
  column: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 16px'
  },
  levelColumn: {
    borderRight: '1px solid rgba(174, 241, 238, 0.4)',
  },
  levelProfessionText: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  disabledText: {
    color: 'rgba(174, 241, 238, 0.4)',
  },
  tokenImageWrapper: {
    position: 'relative',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
  floatingTextWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '8px',
  },
  floatingText: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    weight: 500,
  },
  xpWrapper: {
    padding: '1px',
    width: '332px',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    bottom: '-4%',
    textAlign: 'center',
    // background: '#AEF1EE',
    // clipPath: 'polygon(5% 0, 95% 0, 101% 50%, 95% 100%, 5% 100%, -1% 50%)',
    borderRadius: '18px',

    backgroundImage: `url(${MetazoaXPBar})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  },
  xpContainer: {
    width: '100%',
    padding: '4px 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    borderRadius: '14px',
    // clipPath: 'polygon(5% 0, 95% 0, 101% 50%, 95% 100%, 5% 100%, -1% 50%)',
  },
  xpLabel: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.125rem'
  },
  progressBarWrapper: {
    width: '200px',
    height: '18px',
    padding: '3px',
    borderRadius: '4px',
    outline: '1px solid #AEF1EE66'
  },
  xpProgress: {
    height: '12px',
    background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
    borderRadius: '4px'
  },
  nftImage: {
    borderRadius: '4px',
  },
  centerCol: {
    position: 'relative',
    "> *": {
      borderLeft: 'none!important',
    }
  },
  statsMenuHeaderWrapper: {
    background: '#AEF1EE',
    clipPath: 'polygon(0 0, 80% 0%, 97.5% 100%, 0 100%)',
    width: '144px',
    height: '25px',
    padding: '1px',
    paddingLeft: 0,
    paddingBottom: 0,
    borderRadius: '4px 0px 0px 0px',
  },
  statsMenuHeaderContainer: {
    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    clipPath: 'polygon(0 0, 81% 0%, 98% 100%, 0 100%)',
    width: '99%',
    height: '100%',
    padding: '3px',
    borderRadius: '4px 0px 0px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  statTextSize: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
  statsMenuContainer: {
    width: '140.5px',
    borderRadius: '0px 0px 4px 4px',
    border: '1px solid #AEF1EE',
    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    padding: '8px',
    paddingBottom: '0px',
  },

  statRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    borderRadius: '4px',
    padding: '4px 8px',
    clipPath: 'polygon(0 0, 100% 0, 100% 63%, 92% 100%, 0 100%)',
    position: 'relative',
  },
  statCorner: {
    width: '5px',
    height: '5px',
    display: 'block',
    padding: '5px',
    backgroundColor: '#AEF1EE',
    border: '2px solid #000006',
    position: 'absolute',
    bottom: '-2px',
    right: '-2px',
    borderRadius: '4px',
    clipPath: 'polygon(100% 27%, 27% 100%, 100% 100%)',
  },
  selectedOutlined: {
    border: '1px solid #AEF1EE',
  },
  statGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '16px',
  },
  statLabel: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.125rem',
    marginLeft: '5px',
  },
  toggleGroupContainer: {
    width: '100%',
  },
  statDetailsWrapper: {
    overflow: 'hidden',
    position: 'absolute',
    top: '25px',
    left: '536px',
  },
  extendedWrapper: {
    zIndex: '-10',
    top: '47px',
    left: '855px',
  },
  statDetailBg: {
    position: 'absolute',
    top: '2px',
    left: '2px',
    userSelect: 'none',
  },
  statDetailsContainer: {
    position: 'relative',
    minWidth: '319px',
    maxWidth: '370px',
  },
  statDetailsHeader: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    marginBottom: '8px',
  },
  settingsRadio: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: "start",
  },
  optionLabel: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeContent: "start",
    placeItems: 'center',
    // padding: '0 5%',
    gap: '8px',

    "& span": {
      fontSize: "12px",
      lineHeight: "24px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      whiteSpace: 'nowrap',
    }
  },
  topContainerWrapper: {
    padding: '1.5px',
    paddingBottom: '0px',
    background: 'linear-gradient(190deg, #AEF1EE, #000)',
    clipPath: 'polygon(87% 0, 93% 0, 100% 100%, 0 100%, 0 0, 76.5% 0, 78% 30%, 85.5% 30%)',
    backgroundImage: 'linear-gradient(190deg, #AEF1EE, #000)',
  },
  topContainer: {
    height: '24px',
    width: '100%',
    background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
    clipPath: 'polygon(87.5% 0, 93% 0, 100% 100%, 0 100%, 0 0, 76.5% 0, 78% 30%, 86% 30%)',
  },
  statDetailsContent: {
    // width: '100%',
    padding: '0px 20px 8px 56px',
    background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
    border: '1px solid #AEF1EE',
    borderTopStyle: 'none',
    borderBottomStyle: 'none',
    position: 'relative',

    width: '99.8%',
    borderLeft: '1px solid #AEF1EE',
  },
  bottomContainerWrapper: {
    borderLeft: '1px solid #AEF1EE',
    rotate: '180deg',
    padding: '1.5px',
    paddingBottom: '0px',
    background: 'linear-gradient(190deg, #AEF1EE, #000)',
    clipPath: 'polygon(87% 0, 93% 0, 100% 100%, 0 100%, 0 0, 76.5% 0, 78% 30%, 85.5% 30%)',
    backgroundImage: 'linear-gradient(190deg, #AEF1EE, #000)',
  },
  bottomContainer: {
    height: '24px',
    width: '100%',
    background: 'linear-gradient(90deg, #010025 0%, #000000 100%)',
    clipPath: 'polygon(87.8% 0, 93% 0, 100% 100%, 0 100%, 0 0, 76.5% 0, 78% 30%, 86.5% 30%)',
  },
  cardDivider: {
    background: 'rgba(174, 241, 238, 0.1)',
    marginY: '12px',
  },
  attributeBox: {
    width: '100%',
  },
  attributesRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',

    "> *": {
      padding: '1px 6px'
    }
  },
  totalRow: {
    marginTop: '16px',
  },
  attributesLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  value: {
    background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '12px',
    lineHeight: '18px',
  },
  traitType: {
    background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256) 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '12px',
    lineHeight: '18px',
    weight: 600,
  },

  confirmButton: {
    height: 50,
    flexGrow: 1,
    flexBasis: 0,
    width: '100%',
    borderRadius: '16px',
    margin: '27px 0 20px',
    marginLeft: '0px!important',
    // minWidth: 270,

    "&.MuiButton-root": {
      fontWeight: 700,
      fontSize: "18px",
      whiteSpace: "nowrap",
    },

    "@media (max-width:600px)": {
      width: '100%',
    }
  },

  professionBox: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'start',
    placeItems: 'center',
    gap: '0.5em',
    marginBottom: '8px',
  },

}

const StatToggleGroup = styled(ToggleButtonGroup)({
  '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    borderRadius: '4px',
  },
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderRadius: '4px',
  },
})

const StatToggleButton = styled(ToggleButton)({
  '&.Mui-selected, &:hover': {
    // background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
    outline: '1px solid #AEF1EE',
    boxShadow: "0 0px 24px rgb(174 241 238 / 60%)",
  },
  width: '100%',
  padding: '0px',
  background: 'transparent',
  display: 'flex',
  marginBottom: '8px',
})


const ExtendedStatToggleButton = styled(ToggleButton)({
  margin: 0,
  padding: 0,
  textTransform: 'none',
  background: 'transparent',
  marginBottom: '8px',

  "> *": {
    margin: 0,
    border: '1px solid transparent',
  },
  '&:hover > *': {
    borderRadius: '4px',
    borderColor: '#AEF1EE',
    boxShadow: '-12px 18px 72px -32px rgba(174,241,238,1)',
  },

  "&.Mui-selected > *": {
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    borderRadius: '4px',
    borderColor: '#AEF1EE',
    boxShadow: '-12px 18px 72px -32px rgba(174,241,238,1)',
  }
})

export default MetazoaView;