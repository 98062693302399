import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, IconButton, InputAdornment, Link, OutlinedInput, SxProps, Tab, Tabs, Typography } from "@mui/material";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import { AddIcon, CheckedIcon, Huny, RemoveIcon, ZilliqaLogo } from "assets";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import ExchangeRate from "components/ExchangeRate";
import TabPanel from "components/TabPanel";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import { waitForTx } from "core/utilities";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { TBMConnector } from "tbm";
import { strings, useToaster } from "utils";
import { BIG_ZERO, ContractsBech32, Decimals } from "utils/constants";
import { bnOrZero } from "utils/strings/strings";
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from "utils/types";
import { uniswap } from "utils/uniswapMath";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";

const initialFormState = {
  hunyAmount: new BigNumber(0),
  zilAmount: new BigNumber(0),

  hunyAmountInput: "0",
  zilAmountInput: "0",
}

export enum AddLiquidityTab {
  Maximise = 0,
  Custom = 1,
}

const AddLiquidity: React.FC = () => {
  const wallet = useRedux((state) => state.wallet.wallet);
  const hivePool = useRedux((state) => state.token.hivePool);
  const network = useNetwork();
  const dispatch = useDispatch();
  const toaster = useToaster();
  const [tab, setTab] = useState<number>(AddLiquidityTab.Maximise);
  const [check, setCheck] = useState<boolean>(false);
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0));
  const [runQueryHiveAllowance] = useAsyncTask("queryHiveAllowance");
  const [runIncreaseAllowance, loadingIncreaseAllowance] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
  });
  const [runAddLiquidity, loadingAddLiquidity] = useAsyncTask("poolAddLiquidity", (error) => {
    toaster(error?.message ?? "Error Adding Liquidity");
  });
  const [runSwapAndAddLiquidity, loadingSwapAndAddLiquidity] = useAsyncTask("poolSwapAndAddLiquidity", (error) => {
    toaster(error?.message ?? "Error Adding Liquidity");
  });
  const tokenState = useRedux((state) => state.token);
  const hunyBalance = useMemo(() => new BigNumber(tokenState.HunyTokens), [tokenState.HunyTokens]);
  const hunyContract = useMemo(() => ContractsBech32[network].HunyToken, [network]);
  const zilBalance = useMemo(() => new BigNumber(tokenState.ZilTokens), [tokenState.ZilTokens]);
  const gasFee = tokenState.GasFee;
  const zilPrice = tokenState.ExchangeRates.zilPrice;
  const hunyPrice = tokenState.ExchangeRates.hunyPrice;

  const {
    totalValue,
    shouldOptimiseAmount,
    optimalZilAmount, optimalHunyAmount,
    convertZilAmount, convertHunyAmount,
  } = useMemo(() => {
    const zilValue = zilPrice.times(zilBalance.minus(gasFee));
    const hunyValue = hunyPrice.times(hunyBalance);
    const totalValue = zilValue.plus(hunyValue);
    const shouldOptimiseAmount = tab === AddLiquidityTab.Maximise && hunyValue.minus(zilValue).gt(zilPrice.times(100)); // keep 100 zil for gas

    let optimalHunyAmount = hunyBalance;
    let optimalZilAmount = zilBalance;

    let convertZilAmount = BIG_ZERO;
    let convertHunyAmount = BIG_ZERO;
    const hunyReserves = hivePool?.hunyReserves
    let swapAmount = uniswap(zilValue, hunyValue, zilPrice, hunyReserves, hunyPrice);

    if (shouldOptimiseAmount) {
      optimalHunyAmount = zilValue.minus(zilPrice.times(100)).div(hunyPrice).plus(swapAmount).dp(Decimals.HUNY) // get amount of huny == zil + huny to be swapped
      optimalZilAmount = zilValue.minus(zilPrice.times(100)).div(zilPrice).plus(swapAmount.times(hunyPrice).div(zilPrice)).dp(Decimals.ZIL)
      convertZilAmount = swapAmount.times(hunyPrice).div(zilPrice).dp(Decimals.ZIL)
      convertHunyAmount = swapAmount.dp(Decimals.HUNY); // amt of huny swapped
    } else {
      if (zilValue.lt(hunyValue)) {
        optimalZilAmount = zilBalance; // use up all the zil
        optimalHunyAmount = zilValue.div(hunyPrice);
      } else {
        optimalHunyAmount = hunyBalance; // use up all the huny
        optimalZilAmount = hunyValue.div(zilPrice);
      }
    }

    return {
      zilValue, hunyValue, totalValue,
      shouldOptimiseAmount,
      optimalHunyAmount, optimalZilAmount,
      convertZilAmount, convertHunyAmount,
    };
  }, [zilPrice, hunyPrice, zilBalance, hunyBalance, gasFee, tab, hivePool]);

  const {
    newPoolShare,
    newPoolValue,
  } = useMemo(() => {
    let newPoolShare = "-";
    let newPoolValue = "-";
    const rates = tokenState.ExchangeRates;
    const { zilAmount } = formState;
    const zilAmountUnitless = zilAmount.shiftedBy(Decimals.ZIL);
    const newZilReserves = hivePool?.zilReserves.plus(zilAmountUnitless);
    const newUserZilReserves = hivePool?.userZilReserves.plus(zilAmountUnitless);
    if (!newZilReserves?.gt(0)) return { newPoolShare, newPoolValue };
    newPoolShare = newUserZilReserves?.div(newZilReserves).shiftedBy(2).toFormat(2) ?? "-";

    const hunyAmountUnitless = zilAmount.shiftedBy(Decimals.HUNY);
    const newUserHunyReserves = hivePool?.userHunyReserves.plus(hunyAmountUnitless);
    const newUserZilValue = newUserZilReserves?.shiftedBy(-Decimals.ZIL).times(rates.zilPrice);
    const newUserHunyValue = newUserHunyReserves?.shiftedBy(-Decimals.HUNY).times(rates.hunyPrice);
    newPoolValue = newUserZilValue?.plus(newUserHunyValue ?? BIG_ZERO).toFormat(2) ?? "-";

    return { newPoolShare, newPoolValue };
  }, [hivePool, formState, tokenState.ExchangeRates]);

  useEffect(() => {
    if (wallet && !loadingIncreaseAllowance) {
      runQueryHiveAllowance(async () => {
        const allowance = await TBMConnector.checkHiveAllowance(wallet);
        setAllowance(allowance);
      })
    }
    // eslint-disable-next-line
  }, [wallet, network, loadingIncreaseAllowance, hivePool?.userBalance]);

  const increaseAllowanceRequired = useMemo(() => {
    if (!wallet) return false;

    if (tab === AddLiquidityTab.Maximise) return allowance.comparedTo(optimalHunyAmount) < 0;
    return allowance.comparedTo(formState.hunyAmount) < 0;
  }, [wallet, allowance, formState.hunyAmount, optimalHunyAmount, tab]);

  const handleAddQtyHuny = () => {
    hunyInputHandler(formState.hunyAmount.plus(1).toString(10));
  };

  const handleSubtractQtyHuny = () => {
    if (formState.hunyAmount.isLessThanOrEqualTo(0))
      return;

    hunyInputHandler(formState.hunyAmount.minus(1).toString(10));
  };

  const handleAddQtyZil = () => {
    zilInputHandler(formState.zilAmount.plus(1).toString(10));
  };

  const handleSubtractQtyZil = () => {
    if (formState.hunyAmount.isLessThanOrEqualTo(0))
      return;

    zilInputHandler(formState.zilAmount.minus(1).toString(10));
  };

  const onHunyInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    hunyInputHandler(event.target.value);
  };

  const onZilInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    zilInputHandler(event.target.value);
  };

  const hunyInputHandler = (hunyInput: string) => {
    const bnHunyAmount = bnOrZero(hunyInput);

    const rate = zilPrice.gt(0) ? hunyPrice.div(zilPrice) : 1;
    const bnZilAmount = bnHunyAmount.times(rate).decimalPlaces(Decimals.ZIL);
    setFormState({
      ...formState,

      hunyAmount: bnHunyAmount,
      zilAmount: bnZilAmount,

      hunyAmountInput: hunyInput,
      zilAmountInput: bnZilAmount.toString(10),
    })
  };

  const zilInputHandler = (zilInput: string) => {
    const bnZilAmount = bnOrZero(zilInput);

    const rate = hunyPrice.gt(0) ? zilPrice.div(hunyPrice) : 1;
    const bnHunyAmount = bnZilAmount.times(rate).decimalPlaces(Decimals.HUNY);
    setFormState({
      ...formState,
      hunyAmount: bnHunyAmount,
      zilAmount: bnZilAmount,

      hunyAmountInput: bnHunyAmount.toString(10),
      zilAmountInput: zilInput,
    })
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setFormState({
      ...formState,

      hunyAmountInput: "0",
      zilAmountInput: "0",

      hunyAmount: BIG_ZERO,
      zilAmount: BIG_ZERO,
    });
    setTab(newValue);
  };

  const handleCheck = () => {
    setCheck(!check);
  };

  const handleMaxHuny = () => {
    hunyInputHandler(hunyBalance.toString(10));
  };

  const handleMaxZil = () => {
    zilInputHandler(zilBalance.minus(gasFee).toString(10));
  };

  const customTotalValue = useMemo(() => {
    return ((formState.hunyAmount).times(hunyPrice)).plus(formState.zilAmount.times(zilPrice));
  }, [formState, hunyPrice, zilPrice]);

  const handleIncreaseAllowance = () => {
    runIncreaseAllowance(async () => {
      await increaseAllowance();
    })
  };

  const increaseAllowance = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    const approveAmount = new BigNumber(2).pow(128).minus(1).minus(allowance.shiftedBy(Decimals.HUNY)).dp(0);
    const approveTx = await TBMConnector.increaseHiveAllowance(wallet, approveAmount);

    if (approveTx?.id) {
      try {
        await waitForTx(approveTx.id);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  };

  const handleAddLiquidity = () => {
    runAddLiquidity(async () => {
      if (!wallet) throw new Error("Wallet not connected");
      let addHunyAmount = formState.hunyAmount;
      let addZilAmount = formState.zilAmount;
      const zilswap = TBMConnector.getSDK();

      const hunyAddress = ContractsBech32[zilswap.network].HunyToken;

      const approveTx = await zilswap.approveTokenTransferIfRequired(
        hunyAddress,
        convertHunyAmount.negated().shiftedBy(Decimals.HUNY).dp(0).toString(10),
      );

      if (approveTx) {
        // TODO: toaster
        toaster(`Submitted Approval for Swap `, { hash: approveTx.hash, overridePersist: true });

        const tx = await waitForTx(approveTx.hash);

        if (tx.status > 3)
          throw new Error("Approve tx failed");
      }

      // Add Liquidity
      const walletAddress = wallet.addressInfo.byte20.toLowerCase();
      const bech32HunyAddress = fromBech32Address(hunyAddress);
      const newHunyBalanceResult = await zilswap.zilliqa.blockchain.getSmartContractSubState(
        bech32HunyAddress,
        "balances",
        [walletAddress],
      );
      const newHunyBalance = bnOrZero(newHunyBalanceResult.result?.balances?.[walletAddress]);
      const addLiquidityHunyAmount = BigNumber.min(newHunyBalance, addHunyAmount.shiftedBy(Decimals.HUNY)).dp(0);

      if (!hivePool) throw new Error("pool not found");
      let addLiquidityZilAmount = addZilAmount.shiftedBy(Decimals.ZIL).dp(0);
      if (hivePool.hunyReserves.gt(0))
        addLiquidityZilAmount = addLiquidityHunyAmount.div(hivePool.hunyReserves).times(hivePool.zilReserves).dividedBy(1.05).dp(0); // add 5% less as slippage

      const result = await TBMConnector.addHiveLiquidity(
        wallet,
        addLiquidityHunyAmount,
        addLiquidityZilAmount,
      );

      await zilswap.observeTx({
        hash: result.id!,
        deadline: Number.MAX_SAFE_INTEGER,
      });

      // TODO: toaster
      toaster(`Submitted Add Liquidity 0x${truncate(result.id)}`);

      await waitForTx(result.id!);
      dispatch(actions.Token.reloadHivePool());
    });
  }

  // TODO: do proper calculations
  const handleSwapAndAddLiquidity = () => {
    runSwapAndAddLiquidity(async () => {
      if (!wallet) throw new Error("Wallet not connected");

      const addHunyAmount = optimalHunyAmount;
      const zilswap = TBMConnector.getSDK();

      const hunyAddress = ContractsBech32[zilswap.network].HunyToken;

      const walletAddress = wallet.addressInfo.byte20.toLowerCase();
      const bech32HunyAddress = fromBech32Address(hunyAddress);
      const hunyBalanceResult = await zilswap.zilliqa.blockchain.getSmartContractSubState(
        bech32HunyAddress,
        "balances",
        [walletAddress],
      );
      const hunyBalance = bnOrZero(hunyBalanceResult.result?.balances?.[walletAddress]);
      const addLiquidityHunyAmount = BigNumber.min(hunyBalance, addHunyAmount.shiftedBy(Decimals.HUNY)).dp(0);

      if (!hivePool) throw new Error("pool not found");

      let minZilAmount = new BigNumber(0);

      if (hivePool.hunyReserves.gt(0)) {
        const inputAfterFee = convertHunyAmount.shiftedBy(Decimals.HUNY).dp(0).times(zilswap.getAppState().contractState.output_after_fee);
        const expectedOutput = (inputAfterFee.times(hivePool.zilReserves)).dividedToIntegerBy((hivePool.hunyReserves.times(10000)).plus(inputAfterFee));
        minZilAmount = expectedOutput.dividedBy(1.05).dp(0);
      }

      const result = await TBMConnector.swapAndAddLiquidity(
        wallet,
        convertHunyAmount.shiftedBy(Decimals.HUNY).dp(0), // token Amount
        minZilAmount, // min zil
        addLiquidityHunyAmount, //max token number
      );

      await zilswap.observeTx({
        hash: result.id!,
        deadline: Number.MAX_SAFE_INTEGER,
      });

      // TODO: toaster
      toaster(`Submitted Swap + Add Liquidity `, { hash: result.id });

      await waitForTx(result.id!);
      dispatch(actions.Token.reloadHivePool());
    });
  }

  return (
    <Fragment>
      <Typography variant='body1' color='primary' sx={styles.subtitle}>
        Welcome to the Magic Hive! Put your HUNY to work and earn rewards via the options below.
      </Typography>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        sx={styles.tabs}
      >
        <Tab label="MAXIMISE HUNY" sx={styles.tab1}></Tab>
        <Tab label="CUSTOM" sx={styles.tab2}></Tab>
      </Tabs>

      {/* Maximise huny */}
      <TabPanel value={tab} index={0} sx={styles.tabPanel1}>
        <Box sx={styles.maximiseHunyContainer}>
          <Typography variant="body1" color="primary">
            We’ll optimise your stake by calculating the max HUNY and ZIL you can contribute to the pool based on
            your wallet balance. Convert HUNY &amp; ZIL as required and staking into the pool in one click.
          </Typography>
          <Divider sx={styles.divider} />
          <Typography variant="h3" color="primary" sx={styles.maxHunyHeader}>SUGGESTED LP</Typography>
          <Box sx={styles.lpRow}>
            <Box sx={styles.lpCol}>
              <Box sx={styles.lpBox}>
                <Typography variant="body1" color="primary">HUNY</Typography>
                <Typography variant="h2" color="primary" component="span" sx={styles.lpCurrency}>
                  <Huny width="40px" height="40px" />&nbsp;{optimalHunyAmount.toFormat(1)}
                </Typography>
              </Box>
              <Typography variant="body1" color="primary" sx={styles.lpBalance}>
                Balance: {hunyBalance.toFormat(2)}
                {" "}
                HUNY
                <br />
                (≈$
                {hunyPrice.times(hunyBalance).toFormat(2)}
                )
              </Typography>
            </Box>
            <Typography variant="body1" sx={styles.lpPlus}>+</Typography>
            <Box sx={styles.lpCol}>
              <Box sx={styles.lpBox}>
                <Typography variant="body1" color="primary">ZIL</Typography>
                <Typography variant="h2" color="primary" component="span" sx={styles.lpCurrency}>
                  <ZilliqaLogo width="40px" height="40px" />&nbsp;{optimalZilAmount.toFormat(1)}
                </Typography>
              </Box>
              <Typography variant="body1" color="primary" sx={styles.lpBalance}>
                Balance: {zilBalance.toFormat(2)}
                {" "}
                ZIL
                <br />
                (≈$
                {zilPrice.times(zilBalance).toFormat(2)}
                )
              </Typography>
            </Box>
          </Box>
          <Divider sx={styles.divider} />
          <Box sx={styles.lpRow}>
            <Typography variant="body1" color="primary">Total</Typography>
            <Typography variant="h3" color="primary">${totalValue.toFormat(2)}</Typography>
          </Box>
          {!!shouldOptimiseAmount && (
            <Box sx={styles.balanceRow}>
              <Typography variant="body1" color="primary" component="span">
                <TooltipZolar link="https://docs.zolar.io/stations/magic-hive">
                  HUNY and ZIL must be deposited into the Magic Hive in a 50:50 ratio. Selecting the Maximise HUNY
                  feature will allow you to contribute as much HUNY as possible to the HIVE. In the event you have
                  more HUNY than ZIL, some surplus HUNY will be auto-swapped into ZIL to achieve this 50:50 ratio.
                </TooltipZolar>
                Converting Amount
              </Typography>
              <Box sx={styles.balanceBox}>
                <Typography variant="h3" color="success.main"> {convertHunyAmount.toFormat(2)} HUNY → {convertZilAmount.toFormat(2)} ZIL</Typography>
                <Typography variant="body1" color="primary" component="span">on&nbsp;
                  <Link target="_blank" href={strings.getZilswapLink(hunyContract)}>ZilSwap</Link>
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </TabPanel>

      {/* Custom */}
      <TabPanel value={tab} index={1} sx={styles.tabPanel2}>
        <Box sx={styles.customHunyContainer}>
          <Typography variant="body1" color="primary">Enter HUNY and ZIL amount to add liquidity and earn rewards.</Typography>
          <Divider sx={styles.divider} />
          <Box sx={styles.customWrapper}>
            <Box sx={styles.customRow}>
              <Typography variant="h3" color="primary" component="span" sx={styles.customCurrency}>
                <Huny width="40px" height="40px" />
                &nbsp;&nbsp;&nbsp;HUNY
              </Typography>
              <Box sx={styles.balanceBox}>
                <OutlinedInput
                  sx={styles.qtyInput}
                  onChange={onHunyInputChange}
                  value={formState.hunyAmount.toString()}
                  type="number"
                  inputProps={{ min: "1", style: { textAlign: "center" } }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton onClick={handleSubtractQtyHuny}>
                        <RemoveIcon style={{ height: 22, width: 22 }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleAddQtyHuny}>
                        <AddIcon style={{ height: 22, width: 22 }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Typography variant="body1" color="primary" component="span" sx={styles.customBalanceRow}>
                  Balance: {hunyBalance.toFormat(4)} HUNY (≈${hunyPrice.times(hunyBalance).toFormat(2)}) &nbsp;
                  <Button variant="outlined" size="small" color="secondary" onClick={handleMaxHuny} sx={styles.maxButton}>
                    <Typography variant="button" sx={styles.gradientText}>
                      MAX
                    </Typography>
                  </Button>
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.customRow}>
              <Typography variant="h3" color="primary" component="span" sx={styles.customCurrency}>
                <ZilliqaLogo width="40px" height="40px" />
                &nbsp;&nbsp;&nbsp;ZIL
              </Typography>
              <Box sx={styles.balanceBox}>
                <OutlinedInput
                  sx={styles.qtyInput}
                  onChange={onZilInputChange}
                  value={formState.zilAmount.toString()}
                  type="number"
                  inputProps={{ min: "1", style: { textAlign: "center" } }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton onClick={handleSubtractQtyZil}>
                        <RemoveIcon style={{ height: 22, width: 22 }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleAddQtyZil}>
                        <AddIcon style={{ height: 22, width: 22 }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Typography variant="body1" color="primary" component="span" sx={styles.customBalanceRow}>
                  Balance: {zilBalance.toFormat(4)} ZIL (≈${zilPrice.times(zilBalance).toFormat(2)}) &nbsp;
                  <Button variant="outlined" size="small" color="secondary" onClick={handleMaxZil} sx={styles.maxButton}>
                    <Typography variant="button" sx={styles.gradientText}>
                      MAX
                    </Typography>
                  </Button>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider sx={styles.divider} />
          <Box sx={styles.customRow}>
            <Typography variant="body1" color="primary">Total</Typography>
            <Typography variant="h3" color="primary">${customTotalValue.toFormat(2)}</Typography>
          </Box>
        </Box>
      </TabPanel>
      <ExchangeRate my={2} />
      <Box sx={styles.rateRow}>
        <Typography variant="body1" color="primary">New Pool Share</Typography>
        <Typography variant="h3" color="primary">${newPoolValue} ({newPoolShare}%)</Typography>
      </Box>

      <Box sx={styles.readGuideCheck}>
        <FormControlLabel
          sx={styles.checkFormControl}
          control={<Checkbox
            checkedIcon={<CheckedIcon />}
            onClick={handleCheck}
            sx={{ color: '#fff', "& svg": { width: 24, height: 24 } }}
          />}
          label={
            <Typography variant="body1" color="primary" component="span" sx={styles.checkText}>
              I have read the <Link href="https://docs.zolar.io/stations/magic-hive" target="_blank">Zolar Pools Guide</Link> and understand the risks of providing
              liquidity including impermanent loss.
            </Typography>
          }
        />
      </Box>

      <Box sx={styles.buttonRow}>
        <ContainedButton disabled={!increaseAllowanceRequired} sx={styles.button} onClick={handleIncreaseAllowance}>
          {loadingIncreaseAllowance
            ? <CircularProgress size={18} />
            : "approve huny"
          }
        </ContainedButton>
        <ContainedButton
          sx={styles.button}
          disabled={!check || loadingIncreaseAllowance || increaseAllowanceRequired || loadingAddLiquidity || loadingSwapAndAddLiquidity}
          onClick={shouldOptimiseAmount ? handleSwapAndAddLiquidity : handleAddLiquidity}
        >
          {loadingAddLiquidity
            ? <CircularProgress size={18} />
            : (shouldOptimiseAmount ? "swap + add liquidity" : "add liquidity")
          }
        </ContainedButton>
      </Box>
    </Fragment>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  subtitle: {
    fontSize: '1.5rem',
    marginBottom: '30px',
  },
  tabs: {
    "& .MuiTabs-indicator": {
      background: 'transparent',
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: 'space-between',
    }
  },
  tab1: {
    fontFamily: 'syne',
    fontSize: '1.25rem',
    color: '#fff',
    background: 'transparent',
    padding: '24px 40px',
    paddingRight: '50px',
    width: '50%',
    '&.Mui-selected': {
      color: '#AEF1EE',
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
      clipPath: 'polygon(0 0, 90% 0, 100% 100%, 0% 100%)',
      borderRadius: '16px 16px 0px 0px',
    },
  },
  tab2: {
    fontFamily: 'syne',
    fontSize: '1.25rem',
    color: '#fff',
    background: 'transparent',
    padding: '10px 40px',
    paddingLeft: '50px',
    width: '50%',
    '&.Mui-selected': {
      color: '#AEF1EE',
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
      borderRadius: '16px 16px 0px 0px',
      clipPath: 'polygon(10% 0, 100% 0, 100% 100%, 0% 100%)',
    },
  },
  tabPanel1: {
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    borderRadius: '0px 16px 16px 16px',
  },
  tabPanel2: {
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    borderRadius: '16px 0px 16px 16px',
  },
  maximiseHunyContainer: {
    padding: '40px',
    "@media (max-width:900px)": {
      padding: '30px',
    },
  },
  divider: {
    background: 'rgba(174, 241, 238, 0.1)',
    marginY: '20px',
  },
  lpRow: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  lpCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    "@media (max-width:900px)": {
      width: '38%',
    },
  },
  lpBox: {
    width: '320px',
    height: '110px',
    background: 'rgba(174, 241, 238, 0.1)',
    borderRadius: '16px',
    border: '1px solid #AEF1EE',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    "@media (max-width:1400px)": {
      width: '250px',
    },
    "@media (max-width:1200px)": {
      width: '300px',
    },
    "@media (max-width:900px)": {
      width: '200px',
    },
    overflowX: 'scroll',
    '::-webkit-scrollbar': {
      height: '6px',
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      marginX: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
  },
  lpBalance: {
    width: '310px',
    textAlign: 'center',
    "@media (max-width:1400px)": {
      width: '230px',
    },
  },
  lpCurrency: {
    display: 'flex',
    alignItems: 'center',
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  lpPlus: {
    fontSize: '5rem!important',
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    paddingBottom: '40px',
    marginTop: '40px',
  },
  balanceRow: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  balanceBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  customHunyContainer: {
    padding: '40px',
    paddingBottom: '20px',
    "@media (max-width:900px)": {
      padding: '20px',
    },
  },
  customWrapper: {
    marginBottom: '40px',
  },
  customRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  customCurrency: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '40px',
  },
  qtyInput: {
    width: '400px',
    height: 60,
    marginTop: '10px',
    border: '1px solid rgba(174, 241, 238, 0.1)',
    borderRadius: '16px',
    color: '#FEFEFE',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    "& input": {
      height: '30px',
      fontSize: '24px',
      fontWeight: 600,
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": 'none',
        margin: 0,
      },
      "&:[type=number]": {
        "-moz-appearance": 'textfield',
      },
    },
    "&.Mui-focused": {
      caretColor: '#FEFEFE',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 'none',
    },
    "@media (max-width:900px)": {
      width: '320px',
    },
  },
  qtyInputInvalid: {
    width: '400px',
    height: 60,
    marginTop: '10px',
    border: '1px solid rgba(174, 241, 238, 0.1)',
    borderRadius: '16px',
    color: '#FF2F2F',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    "& input": {
      height: '30px',
      fontSize: '24px',
      fontWeight: 600,
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": 'none',
        margin: 0,
      },
      "&:[type=number]": {
        "-moz-appearance": 'textfield',
      },
    },
    "&.Mui-focused": {
      caretColor: '#FEFEFE',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 'none',
    },
    "@media (max-width:900px)": {
      width: '320px',
    },
  },
  customBalanceRow: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rateRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginY: '20px',
    alignItems: 'center',
  },
  readGuideCheck: {
    width: '100%',
    padding: '20px 30px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  checkText: {
    marginLeft: '10px',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px',
    alignItems: 'center',
  },
  button: {
    minWidth: '250px',
    width: '48%',
    height: 60,
  },
  maxButton: {
    borderRadius: '16px',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },
  checkFormControl: {
    marginX: 0,
  },
  checkedIcon: {
    height: 24,
    width: 24,
  },
}


export default AddLiquidity;
