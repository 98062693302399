import {
  Box, Button, Link, Pagination, SvgIcon, SxProps, Table, TableBody, TableCell,
  TableContainer, TableRow, Typography
} from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { AddIcon, ExternalLink, Huny, SpeechBubbleIcon, ZilTokenIcon, ZWAPTokenIcon } from "assets";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import { DEFAULT_SRC, TOKEN_SYMBOLS, TX_TAGS } from "components/Guild/components/GuildConstants";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import dayjs from "dayjs";
import { Fragment, useMemo, useState } from 'react';
import { TransactionToken, TransactionType } from "store/types";
import { useRedux } from "utils";
import { Decimals } from "utils/constants";
import { bnOrZero, formatIntegerNumber, getExplorerLink } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import Tag from "../../Tag";
import { Rocket } from "assets";

export interface TxListProps {
  showOnlyMyTx?: boolean;
  onDialog?: boolean;
}

const tokenIcons = {
  HUNY: <Huny />,
  ZIL: <ZilTokenIcon />,
  ZWAP: <ZWAPTokenIcon />,
}

const TransactionsList: React.FC<TxListProps> = (props: TxListProps) => {
  const { onDialog, showOnlyMyTx } = props;
  const rowCountLimit = 7;
  const network = useNetwork();
  const [rowCount, setRowCount] = useState(0);
  const bankTransactions = useRedux((state) => state.guild.transactions.transactionsList);
  const wallet = useRedux((state) => state.wallet.wallet);

  const transactions = useMemo(() => {
    if (!bankTransactions) return null;

    const filtered = showOnlyMyTx
      ? bankTransactions.filter(t => t.initiatorAddress?.toLowerCase() === wallet?.addressInfo.byte20.toLowerCase())
      : bankTransactions;

    if (!onDialog) {
      return filtered.slice(0, 4);
    }

    return filtered;

    // eslint-disable-next-line
  }, [showOnlyMyTx, bankTransactions])

  const handleNextPage = () => {
    if (!transactions) return;
    if (rowCount + rowCountLimit < transactions?.length) setRowCount(rowCount + rowCountLimit);
  }

  const handlePrevPage = () => {
    if (rowCount - rowCountLimit > -1) setRowCount(rowCount - rowCountLimit);
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setRowCount((value - 1) * rowCountLimit);
  };

  const getTxTokens = (tokens: TransactionToken[]) => {
    if (!tokens) return;
    const lastIdx = tokens.length - 1;
    return <>
      {
        tokens.map((txToken, idx) => {
          const symbol = TOKEN_SYMBOLS[network][toBech32Address(txToken.address)]
          const tokenJsx = <Fragment key={idx}>
            {formatIntegerNumber(bnOrZero(txToken.amount).shiftedBy(-Decimals[symbol]))}
            {tokenIcons[symbol]}
          </Fragment>
          if (lastIdx !== idx) return <Fragment key={idx}>{tokenJsx}
            <AddIcon className="addIcon" />
          </Fragment>
          else return tokenJsx
        })
      }
    </>
  }

  const getUserInfo = (avatarUrl: string, username: string, address: string) => {
    return (
      <Box sx={styles.memberContainer}>
        <Box sx={styles.memberImgFrame}>
          <Box component="img" src={avatarUrl} sx={styles.memberImg} onError={DEFAULT_SRC} />
        </Box>
        <Box sx={styles.memberDetailsContainer}>
          <Typography color="secondary" sx={styles.usernameText}>{username}</Typography>
          <Link target="_blank" href={getExplorerLink("address", address, network)} sx={{ textDecoration: "none" }}>
            <Typography color="primary" sx={styles.addressText}> {truncate(toBech32Address(address))}</Typography>
          </Link>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <TableContainer sx={combineStyles(styles.tableContainer, { maxHeight: onDialog ? "fit-content" : "265px" })}>
        {((!transactions) || (transactions && transactions.length === 0)) && (
          <Box sx={styles.rocketContainer}>
            <Typography color="primary">There are no transactions made.</Typography>
            <Rocket />
          </Box>
        )}
        <Table>
          <TableBody sx={styles.tableBody}>
            {transactions && transactions.length > 0 && transactions.slice(rowCount, rowCount + rowCountLimit).map((tx, idx) => {
              const isWithdrawal = tx.type === TransactionType.Withdraw || tx.type === TransactionType.WithdrawHive;

              return (
                <TableRow key={idx} sx={styles.tableRow}>
                  <TableCell sx={styles.noLeftPadding} width={"160px"}>
                    {tx.initiator && getUserInfo((tx.initiator.avatarUrl ?? DefaultUserProfile), (tx.initiator.username ?? '-'), tx.initiator.address)}
                    {!tx.initiator && tx.initiatorAddress && getUserInfo(DefaultUserProfile, "-", tx.initiatorAddress)}
                  </TableCell>
                  <TableCell width={"150px"}>
                    <Typography sx={styles.txDateTime} color="primary">{tx.timestamp ? dayjs(tx.timestamp).format("MMMM D, YYYY H:mm:ss") : "-"}</Typography>
                  </TableCell>
                  <TableCell width={"150px"}>
                    <Box sx={styles.statusContainer}>
                      <Tag label={TX_TAGS[tx.type].label} background={TX_TAGS[tx.type].background} />
                      {isWithdrawal && <TooltipZolar titleIcon={false} topPlacement
                        sx={styles.withdrawTooltip}
                        buttonIcon={<SpeechBubbleIcon width="24px" height="24px" />}>
                        Reason: {tx.message}
                      </TooltipZolar>}
                    </Box>
                  </TableCell>
                  <TableCell width={"200px"}>
                    <Typography component="span" sx={styles.magicHiveShareSmall}>
                      {getTxTokens(tx.tokens)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Link
                      target="_blank"
                      href={getExplorerLink("tx", `0x${tx.txHash}`, network)}
                      sx={styles.viewTx}>
                      View Tx
                      <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
                    </Link>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {(transactions?.length ?? 0) > rowCountLimit && (
        <Box sx={styles.guildsListPagination}>
          <Button
            size="medium"
            variant="outlined"
            color="secondary"
            sx={styles.backButton}
            onClick={handlePrevPage}
            disabled={Math.floor(rowCount / rowCountLimit) <= 0}
          >
            <Typography variant="button" sx={styles.gradientText}>
              Prev
            </Typography>
          </Button>
          <Pagination sx={styles.pagination}
            count={Math.ceil(((transactions?.length ?? 0) / rowCountLimit))}
            page={Math.ceil(rowCount / rowCountLimit) + 1}
            onChange={handleChange} hidePrevButton hideNextButton />
          <Button
            size="medium"
            variant="outlined"
            color="secondary"
            sx={styles.backButton}
            onClick={handleNextPage}
            disabled={(transactions?.length ?? 0) < rowCount + rowCountLimit}
          >
            <Typography variant="button" sx={styles.gradientText}>
              Next
            </Typography>
          </Button>
        </Box>
      )}
    </>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  tableContainer: {
    width: '100%',
    fontSize: "14px",
    overflowY: "hidden",
    overflowX: "auto",
    paddingLeft: '20px',
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "transparent",
      top: "",
      left: "",
    }
  },
  rocketContainer: {
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    margin: '20px auto',
    flexDirection: 'column'
  },
  tableBody: {
    color: '#FEFEFE',
    fontSize: "14px !important",
    "& .MuiTableCell-root": {
      padding: "12px 10px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  memberImgFrame: {
    position: 'relative',
    height: '40px',
    width: "40px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "160% 140%",
    backgroundPosition: "center",
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
  memberImg: {
    height: '100%',
    width: '100%',
    borderRadius: "16px",
    objectFit: "contain",
    boxShadow: "0px 0px 12px 0px #00F0FF",
  },
  usernameText: {
    fontSize: '14px',
    maxWidth: "100px",
    width: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 600,
    lineHeight: '24px'
  },
  addressText: {
    fontSize: '12px',
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 600,
    textDecoration: 'underline',
    lineHeight: '18px'
  },
  statusContainer: {
    display: 'flex',
    placeItems: 'center',
    "&:hover svg path": {
      fill: "url(#onSpeechBubbleHover)"
    },
  },
  magicHiveShareSmall: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#FEFEFE',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'flex-end',
    marginRight: '20px',
    "svg": {
      width: '24px',
      height: '24px',
    },
    ".addIcon": {
      width: '12px',
      height: '12px',
    }
  },
  viewTx: {
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: 600,
    fontSize: '12px'
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "16px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  memberContainer: {
    display: "flex",
    placeItems: "center:",
    alignItems: 'center'
  },
  withdrawTooltip: {
    padding: "20px",
    width: "263px"
  },
  guildsListPagination: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
    // gap: '25vw',
    marginTop: '32px',
    width: '100%',
    justifyContent: 'space-between'
  },
  backButton: {
    fontSize: '18px',
    height: '48px',
    minWidth: '180px',
    padding: "14px 25px",
    "@media (max-width:900px)": {
      // flex: 1,
      marginRight: "10px",
      minWidth: '140px',
    },
    '&.Mui-disabled': {
      opacity: '0.4',
      boxShadow: 'none!important',
      border: '1px solid #FEFEFE',
      color: '#FEFEFE',
      background: 'unset !important',
      "& span": {
        background: 'unset !important',
        WebkitTextFillColor: "unset",
      }
      // visibility: 'hidden!important',
    },
  },
  gradientText: {
    fontSize: '18px',
    background: "-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  pagination: {
    "& .MuiButtonBase-root.MuiPaginationItem-root, .MuiPaginationItem-root.MuiPaginationItem-ellipsis": {
      background: "-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: '18px'
    },
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
      background: "-webkit-linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    }
  },
  memberDetailsContainer: { marginLeft: '20px' },
  txDateTime: { fontSize: '12px' },
  noLeftPadding: { paddingLeft: '0 !important' },
  tableRow: {
    "td:last-of-type": {
      paddingRight: '0 !important',
      width: '85px'
    }
  }
}

export default TransactionsList;