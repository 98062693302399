import { Box, Button, CircularProgress, styled, SxProps, Typography } from "@mui/material";
import { BaseButton } from "assets";
import BankBannerPreview from "assets/BankBannerPreview.svg";
import RefineryBanner from "assets/RefineryBanner.png";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import StripeHeader from "components/Game/components/StripeHeader";
import { logger } from "core/utilities";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { useRedux } from "utils";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { GuildBreadcrumb } from "..";
import GuildBankMain from "./components/GuildBankMain";

const GuildBank: React.FC = () => {
  const { guildId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const network = useNetwork();

  const wallet = useRedux((state) => state.wallet.wallet);
  const guildStore = useRedux((state) => state.guild.allGuilds);
  const guildBankStore = useRedux((state) => state.guild.bank);
  const metazoaProfileState = useSelector(getProfile);
  const { profile } = metazoaProfileState;

  const guild = useMemo(() => {
    if (!guildStore || !guildId) return;

    //INFO: Locate guild from store by guildId, redirects out if it's invalid
    const currentGuild = guildStore.find(guild => guild.id === parseInt(guildId))
    if (!currentGuild) {
      navigate('/guilds');
      return;
    }
    //INFO: Retrieve bank from store if guild has bank
    if (currentGuild.guildBank && (!guildBankStore || !currentGuild.guildBank.address)) dispatch(actions.Guild.reloadBank(currentGuild!));

    return currentGuild
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guildStore, guildId, guildBankStore, network])

  // Get current bank from store 
  const bank = useMemo(() => {
    if (!guildBankStore || !guild?.guildBank) return;
    const currentBank = guildBankStore;
    logger("debug-bank", "currentBank - GuildBank.tsx", currentBank, {
      notMatch: (currentBank.id! !== guild.guildBank.id!),
    });
    //INFO: Refresh the bank store if there's a mismatch
    if (currentBank.id! !== guild.guildBank.id!) dispatch(actions.Guild.reloadBank(guild!));

    return currentBank;
    // eslint-disable-next-line
  }, [guildBankStore, guild])

  useEffect(() => {
    if (!guild || !guild.guildBank) return;
    const abortController = new AbortController();
    dispatch(actions.Guild.loadGuildTax(guild));
    dispatch(actions.Guild.startLoadingBankTransactions(guild));
    return () => {
      dispatch(actions.Guild.cancelLoadingBankTransactions());
      return abortController.abort();
    };
    // eslint-disable-next-line
  }, [guild])

  // Get current wallet holder's guild membership status
  const walletHolder = useMemo(() => {
    if (!guild || !profile || !wallet) return;
    const addr = wallet.addressInfo.byte20!.toLocaleLowerCase()!
    const isOfficer: boolean = guild.commanderAddresses.includes(addr)
    const isLeader: boolean = guild.leaderAddress === addr
    const isMember: boolean = profile.guildId === guild.id
    const hasGuild: boolean = profile.guildId ? true : false

    const canInitiate: boolean = isLeader;
    const canCollectTax: boolean = isLeader || isOfficer

    return { addr, hasGuild, isOfficer, isLeader, isMember, canInitiate, canCollectTax }
  }, [wallet, guild, profile])

  const handleInitiate = () => {
    navigate(`/guilds/${guildId}/bank/settings`);
  }

  const handleRedirect = () => {
    navigate('/guilds/create');
    return;
  }

  const breadcrumbs = [
    {
      path: `/guilds`,
      value: "Guild Overview",
    },
    {
      path: `/guilds/${guildId}`,
      value: guild?.name!!,
    },
    {
      path: `/guilds/${guildId}/bank`,
      value: "Guild Bank",
    },
  ];

  return (
    <Box sx={styles.container}>
      <Box sx={styles.refineryWrapper}>
        {guild && (<GuildBreadcrumb linkPath={breadcrumbs} />)}

        {!bank
          ? (<Box sx={styles.bankWrapper}>
            <StripeHeader sx={styles.bankHeader}>Guild Bank</StripeHeader>
            <Box sx={styles.overviewWrapper}>
              <Box sx={styles.previewBox}>
                <Typography variant="body1" color="primary" sx={styles.previewText}>
                  Pool together assets for a common goal with the guild bank - this will be used to calculate airdrops and participate in future missions.
                </Typography>
                {!wallet || !walletHolder
                  ? <ConnectWalletButtonCTA sx={styles.connectButton} />
                  : <Button
                    variant="contained"
                    color="secondary"
                    sx={styles.buyCrestButton}
                    disabled={!walletHolder.canInitiate || !!guild?.guildBank}
                    onClick={(!walletHolder.canInitiate) ? handleRedirect : handleInitiate}
                  >
                    <BaseButtonBox><BaseButton height="100%" /></BaseButtonBox>
                    {!guild
                      ? (<CircularProgress size={18} />)
                      : "Unlock guild bank"
                    }
                  </Button>
                }
              </Box>
            </Box>
          </Box>)
          : (!guild || !bank
            ? (<CircularProgress size={18} />)
            : (<GuildBankMain
              guild={guild}
              bank={bank}
              canCollectTax={!!walletHolder?.canCollectTax}
              hasGuild={!!walletHolder?.hasGuild}
            />)
          )}

      </Box>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  //INFO: Multisig dialog
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  joinInfo: {
    textAlign: 'center',
    fontSize: '24px',
    lineHeight: '150%',

    ".hunyAmt": {
      display: 'inline-flex',
      placeContent: 'center',
      placeItems: 'center',
      verticalAlign: 'middle',
      gap: '8px',
      flexWrap: 'nowrap',

      color: '#2FF583',
      fontWeight: 700,
    },

    ".warningText": {
      color: "#ff8952",
    },

    "button": {
      padding: '0 4px',
    }
  },
  tooltip: {
    padding: '20px',
    width: '420px',

    "svg": {
      width: '24px',
      height: '24px',
    }
  },
  guildButton: {
    marginTop: "40px",
  },



  container: {
    position: "relative",
    padding: "0 0 100px",
    minHeight: "1200px",
    width: '100%',
    overflowX: 'auto',
  },
  refineryWrapper: {
    marginTop: '120px',
    padding: '40px 0',
    minHeight: '500px',
    minWidth: '900px',
  },
  bankWrapper: {
    background: "linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, #000010 100%)",
    borderRadius: '16px',
    marginTop: '30px',
    padding: '20px 40px',
  },
  bannerWrapper: {
    marginTop: '30px',
    width: '100%',
    height: '320px',
    backgroundImage: `url(${RefineryBanner})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '16px',
    border: '1px solid #AEF1EE33',
  },

  bankHeader: {
    textAlign: 'center',
    paddingY: '5px',
    marginY: '25px',
    transform: 'skewX(-25deg)',
    background: 'repeating-linear-gradient(90deg, #FFB60033, #FFB60033 36px, transparent 36px, transparent 44px)',
  },
  overviewWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  previewBox: {
    backgroundImage: `url(${BankBannerPreview})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "center",
    gap: "32px",
    minHeight: "746.5px",
    paddingX: "80px",
    height: "100%",
  },
  previewText: {
    textAlign: "center",
    maxWidth: '628px',
  },
  connectButton: {
    "& #wrapperBox": {
      "@media (max-width:900px)": {
        width: "100%",
        display: "initial",
      },
    },

    "& .MuiBox-root": {
      padding: 0,
    },

    "& .MuiButton-root": {
      height: 64,
      minWidth: 300,
      marginTop: "40px",
      "@media (max-width:600px)": {
        width: "100%",
        minWidth: "",
      },
    }
  },
  buyCrestButton: {
    height: 64,
    minWidth: 300,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
      textAlign: "center",
    },
  },
}


const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    height: "100%",
  },
});

export default GuildBank;