import React, { Fragment } from "react";
import { Box, SxProps, Tab, Tabs } from "@mui/material";
import TabPanel from "components/TabPanel";
import { AppTheme, SimpleMap } from "utils/types";
import AddLiquidity from "./AddLiquidity/AddLiquidity";
import Withdraw from "./Withdraw";


const HivePanels:React.FC = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={styles.container}>
      <Fragment>
        <Tabs 
          TabIndicatorProps={{style: {background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)"}}} 
          value={value} 
          onChange={handleChange} 
          centered 
          sx={styles.tabs}
          >
          <Tab label="ADD LIQUIDITY" sx={styles.tab}/>
          <Tab label="WITHDRAW" sx={styles.tab}/>
        </Tabs>
      </Fragment>
      <TabPanel value={value} index={0}>
        <AddLiquidity />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Withdraw/>
      </TabPanel>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    width: '100%',
    minHeight: '800px',
    borderRadius: '16px',
    border: '1px solid #AEF1EE33',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    padding: '40px',
    "@media (max-width:900px)": {
      padding: '20px',
    },
  },
  tabs: {
    marginBottom: '36px',
  },
  tab: {
    fontFamily: 'syne',
    fontSize: '1.25rem',
    fontWeight: 700,
    color: '#FFFFFF62',
    '&.Mui-selected': {
      color: '#fff',
    },
  }
}


export default HivePanels;