import { ArrowDropDown } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button, ClickAwayListener,
  Container, Paper, Popper, styled, SxProps, Typography
} from "@mui/material";
import { MusicOffIcon, MusicOnIcon } from "assets";
import Logo from "assets/icons/Logo.png";
import { ConnectWalletButtonTopBar } from "components/ConnectWalletButton";
import { NAVIGATION, NavRoute } from "core/constants";
import * as React from "react";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { actions } from "store";
import { useRedux } from "utils";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "../../utils/types";

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popperContent, setPopperContent] = React.useState<NavRoute[]>([]);
  const dispatch = useDispatch();
  const onToggleDrawer = () => {
    dispatch(actions.Layout.toggleShowNavDrawer());
  };

  const isPlayingAudio: boolean = useRedux((state) => state.layout.playAudio);
  const onToggleAudio = () => {
    dispatch(actions.Layout.toggleAudio(!isPlayingAudio));
  };

  const currentRoute = useLocation().pathname;

  return (
    <Box sx={styles.headerBG} component="nav">
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Container maxWidth="xl" sx={styles.container}>
            <Button component={NavLink} to="/" sx={styles.button}>
              <img src={Logo} alt="logo" />
            </Button>
            <NavBar1>
              {NAVIGATION.map(({ label, route, subMenu, isExternal }, idx) => {
                const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
                  if (!subMenu) return;
                  setAnchorEl(anchorEl ? null : event?.currentTarget ?? null);
                  setPopperContent(subMenu);
                };
                const open = Boolean(anchorEl);

                return (
                  <React.Fragment key={idx}>
                    <Button
                      key={idx}
                      sx={combineStyles(styles.button, { ...(currentRoute === route) && styles.underlined })}
                      {...(!!subMenu && { onClick: handleClick })}
                      {...(!!isExternal && { target: "_blank", href: route })}
                      {...(!subMenu && !isExternal && { component: NavLink, to: route })}
                    >
                      <Typography variant="subtitle1" color="primary" sx={combineStyles(
                        { ...(!!subMenu) && styles.dropdownText },
                      )}>
                        {label ?? route.split("/").pop()}
                        &nbsp;
                        {(!!subMenu) && (<ArrowDropDown sx={combineStyles({ ...(!!anchorEl) && styles.chevron })} />)}
                      </Typography>
                    </Button>

                    {!!subMenu && (
                      <Popper open={open} placement="bottom-start" anchorEl={anchorEl} style={{ zIndex: 100000 }}>
                        <Paper sx={styles.popperContainer}>
                          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                            <Box>
                              {popperContent.map((menu, i) => (
                                <Button component={NavLink} to={menu.route} sx={styles.popperLink} key={i}>
                                  <Typography variant="subtitle1" color="primary" >{menu.label}</Typography>
                                </Button>
                              ))}
                            </Box>
                          </ClickAwayListener>
                        </Paper>
                      </Popper>
                    )}
                  </React.Fragment>
                )
              })}
              <ConnectWalletButtonTopBar />
              <Button
                onClick={onToggleAudio}
              >
                {!isPlayingAudio ? <MusicOffIcon /> : <MusicOnIcon />}
              </Button>
            </NavBar1>
            <NavBar2>
              <Button onClick={onToggleDrawer}>
                <MenuIcon />
              </Button>
            </NavBar2>
          </Container>
        </React.Fragment>
      ))
      }
    </Box >
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    position: 'relative',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:1000px)": {
      paddingY: '12px',
    },
  },
  button: {
    margin: '0px 5px',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px',
    textTransform: 'capitalize',
    fontStyle: 'normal',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  underlined: {
    position: 'relative',
    "&::after": {
      content: "''",
      height: '4px',
      transition: ' transform 0.25s ease',
      transformOrigin: 'left',
      left: ' 0',
      bottom: ' 0',
      width: ' 85%',
      display: ' block',
      position: ' absolute',
      transform: 'scaleX(1)',
      background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
      borderRadius: '16px',
    },
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },
  headerBG: {
    background:
      "linear-gradient(180deg, rgba(1, 0, 37, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%)",
    boxShadow: "inset 0px -1px 0px rgba(174, 241, 238, 0.1)",
    backdropFilter: "blur(24px)",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 100000,
  },
  headerCTA: {
    borderRadius: "30px",
    textTransform: "uppercase",
    marginLeft: "20px",
  },
  NavLinkDisabled: {
    background: 'transparent',
    color: '#FEFEFE',
    opacity: '0.4',
  },
  popperContainer: {
    width: '180px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)',
    boxShadow: '0px 4px 24px #000000',
    borderRadius: '0px 0px 16px 16px',
  },
  popperLink: {
    width: '100%',
    textDecoration: 'none',
    padding: '10px',
    textTransform: 'capitalize',
    fontStyle: 'normal',
    textAlign: 'left',
  },
  chevron: {
    background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  dropdownText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
};

const NavBar1 = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  "@media (max-width:1000px)": {
    display: "none",
  },
});

const NavBar2 = styled(Box)({
  "@media (min-width:1000px)": {
    display: "none",
  },
});


export default Header;
