export const typography = {
  h1: {
    fontFamily: "Syne",
    fontWeight: 700,
    fontSize: "4rem",
    lineHeight: "4rem",
    "@media (max-width:900px)": {
      fontSize: "2.5rem",
      lineHeight: "2.25rem",
    },
    "@media (max-width:600px)": {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  h2: {
    fontFamily: "Syne",
    fontWeight: 700,
    fontSize: "2.5rem",
    lineHeight: "2.5rem",
    textShadow: "0 0 120px #0057FF",
    "@media (max-width:900px)": {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
    "@media (max-width:600px)": {
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
    },
  },
  h3: {
    fontFamily: "Syne",
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
    textShadow: "0 0 120px #0057FF",
    "@media (max-width:600px)": {
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
    },
  },
  subtitle1: {
    fontFamily: "Prompt",
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
  },
  body1: {
    fontFamily: "Prompt",
    fontWeight: 400,
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
    "@media (max-width:600px)": {
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
    },
  },
  // HyperLink
  body2: {
    fontFamily: "Prompt",
    fontWeight: 600,
    fontSize: "1.5rem",
    lineHeight: "1.375rem",
    "@media (max-width:600px)": {
      fontSize: "0.875rem",
      lineHeight: "1.125rem",
    },
  },
  button: {
    fontFamily: "Prompt",
    fontWeight: 600,
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
    "@media (max-width:600px)": {
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
    },
  },
  overline: {
    fontFamily: "Prompt",
    fontWeight: 400,
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
    "@media (max-width:600px)": {
      fontSize: "0.75rem",
      lineHeight: "1.125rem",
    },
  }
};
