import { logger } from "core/utilities";
import { call, delay, fork, put, race, select, take } from "redux-saga/effects";
import { waitForConnectorInit, waitForNetworkChange } from "saga/helpers";
import { getWallet } from "saga/selectors";
import { actions } from "store";
import { HunyPot, RefineryConfig, RefineryHunyStats } from "store/types";
import { TBMConnector } from "tbm";
import { bnOrZero } from "utils/strings/strings";
import { SimpleMap } from "utils/types";

function* watchRefineryConfig() {
  while (true) {
    try {
      yield waitForConnectorInit();
      const network = TBMConnector.network();
      const config: RefineryConfig = yield call(TBMConnector.getRefineryConfigs);
      logger("fetch refinery config", config);

      yield put(actions.Token.updateRefineryConfig(config));

      yield race({
        network: waitForNetworkChange(network),
      });
    } catch (error) {
      console.error("failed to load huny pots");
      console.error(error);
      yield delay(3000);
    } finally {
    }
  }
}

function* watchRefineryPots() {
  while (true) {
    try {
      yield waitForConnectorInit();
      const { wallet } = getWallet(yield select());
      const network = TBMConnector.network();
      if (wallet) {
        const rawHunyPots: SimpleMap = yield call(TBMConnector.getHunyPots, wallet);
        logger("fetch huny pots", rawHunyPots);

        const hunyPots: SimpleMap<HunyPot> = {};
        for (const startBlock in rawHunyPots) {
          const startBlkNum = bnOrZero(startBlock).toNumber()
          const startTimestamp: number = yield TBMConnector.getBlkTimestamp(startBlkNum)
          hunyPots[startBlock] = new HunyPot(
            startBlkNum,
            startTimestamp,
            bnOrZero(rawHunyPots[startBlock]),
          );
        }

        yield put(actions.Token.updateHunyPots(hunyPots));
      }

      yield race({
        delay: delay(300000),
        reload: take(actions.Token.TokenActionTypes.RELOAD_HUNY_POTS),
        network: waitForNetworkChange(network),
        wallet: take(actions.Wallet.WalletActionTypes.WALLET_UPDATE),
      });
    } catch (error) {
      console.error("failed to load huny pots");
      console.error(error);
      yield delay(3000);
    } finally {
    }
  }
}

function* watchRefineryHunyStats() {
  while (true) {
    try {
      yield waitForConnectorInit();
      const { wallet } = getWallet(yield select());
      const network = TBMConnector.network();
      const stats: RefineryHunyStats = yield call(TBMConnector.getRefineryHunyStats, wallet ?? undefined);
      logger("fetch huny stats", stats);

      yield put(actions.Token.updateRefineryHunyStats(stats));

      yield race({
        delay: delay(300000),
        reload: take(actions.Token.TokenActionTypes.RELOAD_REFINERY_HUNY_STATS),
        network: waitForNetworkChange(network),
        wallet: take(actions.Wallet.WalletActionTypes.WALLET_UPDATE),
      });
    } catch (error) {
      console.error("failed to load huny pots");
      console.error(error);
      yield delay(3000);
    } finally {
    }
  }
}

export default function* refinerySaga() {
  logger("init refinery saga");
  yield fork(watchRefineryConfig);
  yield fork(watchRefineryHunyStats);
  yield fork(watchRefineryPots);
}
