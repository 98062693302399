import { toBech32Address } from "@zilliqa-js/zilliqa";
import BigNumber from "bignumber.js";
import { logger } from "core/utilities";
import { MetazoaClient } from "core/utilities/metazoa";
import { all, call, cancelled, delay, fork, put, race, select, take, takeLatest } from "redux-saga/effects";
import { waitForConnectorInit } from "saga/helpers";
import { getBlockchain, getGuild, getToken } from "saga/selectors";
import { actions } from "store";
import { Action, Guild, GuildBankInfo, GuildTokens, GuildTokensValuation, HivePoolStats, PendingRequest, Profile, ProfileInfo, TaxByWeek, TaxStatus, Transaction } from "store/types";
import { TBMConnector } from "tbm";
import { BIG_ZERO, Decimals } from "utils/constants";
import { sortedGuilds, sortedMembers } from "utils/guildSorter";
import { computeGuildStrength } from "utils/guildStrength";
import { bnOrZero } from "utils/strings";
import { SimpleMap } from "utils/types";

// DATA LOADERS ---------------------------------------------------------

function* loadBankData(bank: GuildBankInfo, guild: Guild) {
  try {
    yield waitForConnectorInit();
    const { network } = getBlockchain(yield select());
    const { ExchangeRates } = getToken(yield select());

    const metazoaClient = new MetazoaClient(network);
    const model: Guild = (yield call(metazoaClient.guildDetail, guild.id.toString()))?.result?.model;

    if (!bank.address) {
      if (!!model.guildBank?.address) {
        bank = {
          ...bank,
          address: model.guildBank.address,
        }
      }
      else return model.guildBank;
    }

    const { guildBank } = model;
    if (!guildBank) return;

    // Net worth
    const tokens: GuildTokens = (yield call(TBMConnector.getBankTokens, toBech32Address(guildBank.address)))
    let netWorth: GuildTokensValuation = {
      zilToken: BIG_ZERO,
      zilTokenAmt: 0,
      hunyToken: BIG_ZERO,
      hunyTokenAmt: 0,
      valuation: 0,
    }
    Object.entries(tokens).forEach(
      ([key, value]: [string, BigNumber]) => {
        const tokenType: string = key.split(/(?=[A-Z])/)[0];
        const tokenPrice: BigNumber = bnOrZero(ExchangeRates[`${tokenType}Price`]);
        const tokenValue: BigNumber = bnOrZero(value).times(tokenPrice).shiftedBy(-Decimals.HUNY)
        const tokenAmount: number = bnOrZero(value).shiftedBy(-Decimals.HUNY).toNumber();

        netWorth[key] = tokenValue;
        netWorth[`${key}Amt`] = tokenAmount;
        netWorth.valuation = netWorth.valuation + tokenValue.toNumber()
      }
    );

    // Pending Tx
    const { members } = guild;
    if (!members) guild = yield loadGuildDetail(guild);

    const pendingRequest: PendingRequest = (yield call(TBMConnector.getGuildBankPendingTx, guildBank.address));

    // -----------------------------------------

    const taxApproved = (yield call(TBMConnector.fetchTaxApproved, guildBank.address))
    const approvedMembers = Object.keys(taxApproved.tax_approved)
    const bankHiveStats: HivePoolStats = yield call(TBMConnector.getBankHiveTokens, toBech32Address(guildBank.address))
    logger("debug-saga", "loadBankData > taxApproved", approvedMembers);
    let currentBank: GuildBankInfo = {
      ...bank,
      ...model.guildBank,
      tokens,
      netWorth,
      taxes: [],
      pendingRequest: pendingRequest,
      membersApprovedTax: approvedMembers,
      guildHiveStats: bankHiveStats
      // exMembers: profiles
    }
    logger("debug-saga", "loadBankData > pendingRequest", currentBank);

    return currentBank;

  } catch (error) {
    console.error("ERROR: Unable to load guild bank data.\n", error);
    yield delay(3000);
  }
}

function* loadGuildDetail(guild: Guild) {
  try {
    yield waitForConnectorInit();
    const { network } = getBlockchain(yield select());

    const metazoaClient = new MetazoaClient(network);
    const model: Guild = (yield call(metazoaClient.guildDetail, guild.id.toString()))?.result?.model;
    if (!model) return;

    // Members
    const members: Profile[] = (yield call(metazoaClient.listGuildMembers, guild.id))?.result?.members;
    if (!members) return;

    // Huny Holding
    const allHunyHolding = (yield call(TBMConnector.getTotalHunyBalance));
    let totalHunyHolding: BigNumber = BIG_ZERO;
    Object.entries(allHunyHolding).forEach(([addr, huny]) => {
      let memberIdx = members.findIndex(m => m.address === addr);
      if (memberIdx > -1) {
        members[memberIdx] = { ...members[memberIdx], totalHunyHolding: bnOrZero(huny as BigNumber) };
        totalHunyHolding = BigNumber.sum(totalHunyHolding, members[memberIdx].totalHunyHolding ?? BIG_ZERO);
      }
    });

    // -----------------------------------------

    let currentGuild: Guild = {
      ...guild,
      ...model,
      members: sortedMembers(members, guild.leaderAddress, guild.commanderAddresses),
      totalHunyHolding: totalHunyHolding,
      strength: 0,
      guildBank: guild.guildBank ?? null,
    }

    // -----------------------------------------

    return currentGuild;
  }
  catch (error) {
    console.error("ERROR: Unable to load guild stats.\n", error);
    yield delay(3000);
  }
}

function* loadGuildData(guild: Guild) {
  try {
    yield waitForConnectorInit();
    const { ExchangeRates } = getToken(yield select());
    const { members, totalHunyHolding } = guild;
    if (!members || !totalHunyHolding) guild = yield loadGuildDetail(guild);

    // + Liquidity 
    const memberAddresses: string[] = members.map(({ address }) => address);
    const tbmIds: string[] = (yield call(TBMConnector.getGuildOwnedBear, memberAddresses));
    const memberLiquidity = (yield call(TBMConnector.fetchGuildMemberLp, memberAddresses));
    let rTotalLp: BigNumber = BIG_ZERO;
    Object.values(memberLiquidity).forEach((values) => {
      const hiveStats: HivePoolStats = values as HivePoolStats;
      rTotalLp = BigNumber.sum(rTotalLp, hiveStats.userHunyReserves ?? BIG_ZERO)
    });
    const guildTotalLiquidity = rTotalLp.shiftedBy(-Decimals.HUNY)
    const membersTotalLiquidity = members.map((member) => {
      if (!!memberLiquidity[member.address]) {
        member = {
          ...member,
          hivePool: memberLiquidity[member.address]
        }
        return member
      }
      return member
    })

    // -----------------------------------------
    let currentGuild: Guild = {
      ...guild,
      members: membersTotalLiquidity,
      membersTotalLp: guildTotalLiquidity,
      totalHunyHolding: totalHunyHolding.plus(guildTotalLiquidity),
      totalBear: tbmIds.length,
      strength: 0
    };
    // -----------------------------------------

    const { members: currentMembers } = currentGuild;

    // + Metazoas
    const guildMetazoas: SimpleMap<SimpleMap<string[]>> = (yield call(TBMConnector.getGuildMetazoas, memberAddresses));

    const unstakedMetazoaCommanders = guildMetazoas.unstakedMetazoaCommanders
    const stakedMetazoaCommanders = guildMetazoas.stakedMetazoaCommanders
    const metazoaMetadata = (yield call(TBMConnector.getOwnedMetazoaMetadata, guildMetazoas.tokenIds.unstakedMetazoaIds))
    const stakedMetazoaMetadata = (yield call(TBMConnector.getOwnedMetazoaMetadata, guildMetazoas.tokenIds.stakedMetazoaIds))
    /// Assignment of Unstaked/Staked metazoa metadata for each member
    Object.entries(stakedMetazoaCommanders).forEach(([key, value]) => {
      if (value) {
        let metazoaIds: string[] = value as unknown as string[];
        const ownerIdx: number = currentMembers.findIndex(mem => mem.address === key)
        currentMembers[ownerIdx] = {
          ...currentMembers[ownerIdx],
          stakedMetazoa: stakedMetazoaMetadata.filter(m => metazoaIds.find(i => i === m.id)) ?? []
        }
      }
    });
    Object.entries(unstakedMetazoaCommanders).forEach(([key, value]) => {
      if (value) {
        let metazoaIds: string[] = value as unknown as string[];
        const ownerIdx: number = currentMembers.findIndex(mem => mem.address === key)
        currentMembers[ownerIdx] = {
          ...currentMembers[ownerIdx],
          metadata: metazoaMetadata.filter(m => metazoaIds.find(i => i === m.id)) ?? []
        }
      }
    });

    /// Metazoa faction counter 
    let [ursa, mino] = [0, 0];
    [...metazoaMetadata, ...stakedMetazoaMetadata].forEach((token, idx) => {
      const faction = token?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value;
      if (faction === "Ursa") ursa++;
      else if (faction === "Mino") mino++;
    })
    const allMetazoasMetadata = {
      "totalUrsa": ursa,
      "totalMino": mino,
      "totalSummon": (ursa + mino)
    }

    // -----------------------------------------

    currentGuild = {
      ...currentGuild,
      ...allMetazoasMetadata,
      members: currentMembers,
    };

    // Bank (optional)
    if (!!currentGuild.guildBank) {
      const currentBank: GuildBankInfo = yield loadBankData(currentGuild.guildBank, currentGuild);
      currentGuild.guildBank = currentBank;
    }

    // -----------------------------------------

    return {
      ...currentGuild,
      strength: computeGuildStrength(currentGuild, ExchangeRates),
    };
  }
  catch (error) {
    console.error("ERROR: Unable to load guild stats.\n", error);
    yield delay(3000);
  }
}

// STORE LOADERS --------------------------------------------------------

function* loadGuilds() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading("loadGuilds", "METAZOA:LOAD_GUILDS"));
    const { network } = getBlockchain(yield select());
    const { guild } = getGuild(yield select());

    // All Guilds (initial load)
    const metazoaClient = new MetazoaClient(network);
    const { result: { models } } = (yield call(metazoaClient.listGuilds))
    if (!models) return;

    // + Previous Guild Data (from priority load)
    let currentGuilds: Guild[] = models!;
    if (!!guild) {
      const guildIdx: number = currentGuilds.findIndex(g => g.id === guild.id);
      if (guildIdx > -1) currentGuilds[guildIdx] = guild;
      logger("debug-saga", "loadGuilds + guild", currentGuilds, guild);
    }
    yield put(actions.Guild.updateGuilds(currentGuilds));
    logger("debug-saga", "loadGuilds", currentGuilds);

    // + Guild Detail (preliminary load)
    currentGuilds = yield all(currentGuilds.map(guild => call(loadGuildDetail, guild)));
    yield put(actions.Guild.updateGuilds(sortedGuilds(currentGuilds!)));
    logger("debug-saga", "loadGuilds > loadGuildDetail", sortedGuilds(currentGuilds!));

    // + Guild Data
    currentGuilds = yield all(currentGuilds.map(guild => call(loadGuildData, guild)));
    yield put(actions.Guild.updateGuilds(sortedGuilds(currentGuilds!)));
    logger("debug-saga", "loadGuilds > loadGuildData", sortedGuilds(currentGuilds!));

  } catch (error) {
    console.error("ERROR: Unable to load all guilds.\n", error);
    yield delay(3000);
  } finally {
    yield put(actions.Layout.removeBackgroundLoading("METAZOA:LOAD_GUILDS"));
  }
}

function* loadGuild() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading("loadGuild", "METAZOA:RELOAD_GUILD"));
    const { guild } = getGuild(yield select());
    if (!guild) return;
    logger("debug-saga", "loadGuild", guild);

    let currentGuild: Guild = yield loadGuildData(guild);
    yield put(actions.Guild.updateGuild(currentGuild!));
    logger("debug-saga", "loadGuild > loadGuildData", currentGuild);

    if (!!currentGuild.guildBank) {
      const currentBank: GuildBankInfo = yield loadBankData(currentGuild.guildBank, currentGuild);
      currentGuild = {
        ...currentGuild,
        guildBank: currentBank,
      };
      yield put(actions.Guild.updateGuild(currentGuild!));
      logger("debug-saga", "loadGuild > loadGuildData > loadBankData", currentGuild);
    }

    // Refresh all guilds (overwrite)
    yield put(actions.Guild.loadGuilds());
    logger("debug-saga", "loadGuilds [OVERWRITE]");
  } catch (error) {
    console.error("ERROR: Unable to load guild.\n", error);
    yield delay(3000);
  } finally {
    yield put(actions.Layout.removeBackgroundLoading("METAZOA:RELOAD_GUILD"));
  }
}

function* loadBank() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading("loadBank", "METAZOA:RELOAD_BANK"));
    const { guild } = getGuild(yield select());
    if (!guild || !guild.guildBank) return;
    logger("debug-saga", "loadBank", guild, guild.guildBank);

    const currentBank: GuildBankInfo = yield loadBankData(guild.guildBank, guild) ?? guild.guildBank;

    const currentGuild: Guild = {
      ...guild,
      guildBank: currentBank,
    };
    yield put(actions.Guild.updateBank(currentBank!));
    yield put(actions.Guild.updateGuild(currentGuild!));
    logger("debug-saga", "loadGuild > loadBankData", currentGuild, currentBank);

    // Refresh all guilds (overwrite)
    yield put(actions.Guild.loadGuilds());
    logger("debug-saga", "loadGuilds [OVERWRITE]");
  } catch (error) {
    console.error("ERROR: Unable to load bank.\n", error);
    yield delay(3000);
  } finally {
    yield put(actions.Layout.removeBackgroundLoading("METAZOA:RELOAD_BANK"));
  }
}

function* loadGuildTax(action: Action<Guild>) {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading("loadGuildTax", "METAZOA:LOAD_GUILD_TAX"));

    const { network } = getBlockchain(yield select());
    const { transactions } = getGuild(yield select());

    const metazoaClient = new MetazoaClient(network);

    let guild = action.payload;
    if (!guild || !guild.guildBank || !guild.guildBank?.address) {
      yield put(actions.Guild.updateTax([]));
      return;
    }
    const { address, weeklyTax, currentEpoch } = guild.guildBank;
    const guildTaxInfo = (yield call(TBMConnector.fetchGuildTaxInfo, address));

    const { taxOwed, taxCollected, members, lastUpdatedEpoch } = guildTaxInfo;
    console.log(lastUpdatedEpoch, 'last updated epoch')
    if (currentEpoch > lastUpdatedEpoch) {
      for (let i = (lastUpdatedEpoch + 1); i <= currentEpoch; i++) {
        Object.keys(members).forEach(key => {
          const joinedEpoch = members[key];
          if (i > joinedEpoch && (!taxCollected[i] || !taxCollected[i][key])) {
            if (!taxOwed[i] || !taxOwed[i][key]) {
              const hunyOwed = bnOrZero(weeklyTax.initialAmt);
              taxOwed[i] = { ...taxOwed[i], [key]: hunyOwed };
            }
          }
        })
      }
    }

    const taxes: TaxByWeek[] = [];

    const getDate = (key: string) => {
      //TODO: update initDate from api or change constant to actual initDate when mainnet contracts are deployed
      const initDate = '2022-08-23 06:30:00 GMT+0'
      const date = new Date(initDate);
      date.setDate(date.getDate() + (7 * (parseInt(key) - 1)))
      return date;
    }

    Object.keys(taxOwed)
      .forEach((key) => {
        const membersPayingAmt = bnOrZero(weeklyTax.initialAmt)
        taxes.push({ epochNumber: parseInt(key), date: getDate(key), taxesOwed: taxOwed[key], membersPayingAmt: membersPayingAmt.shiftedBy(-Decimals.HUNY), status: TaxStatus.PendingCollection })
      })

    Object.keys(taxCollected)
      .forEach((key) => {
        const index = taxes.findIndex(r => r.epochNumber === parseInt(key))
        if (index > -1) {
          if (Object.keys(taxes[index].taxesOwed ?? {}).length !== 0) taxes[index] = { ...taxes[index], taxesCollected: taxCollected[key] }
          else taxes[index] = { ...taxes[index], status: TaxStatus.Collected, taxesCollected: taxCollected[key] }
        } else {
          const membersPayingAmt = bnOrZero(taxCollected[key][Object.keys(taxOwed[key])[0]]);
          taxes.push({ epochNumber: parseInt(key), date: getDate(key), taxesCollected: taxCollected[key], membersPayingAmt: membersPayingAmt.shiftedBy(-Decimals.HUNY), status: TaxStatus.Collected })
        }
      })

    // Find and get ex-members data
    const exMembers: string[] = []
    let profiles: ProfileInfo[] = []
    taxes.forEach(tax => {
      if (tax.taxesOwed) {
        Object.keys(tax.taxesOwed).forEach(key => {
          const idx = guild.members.findIndex(m => m.address.toLowerCase() === key.toLowerCase());
          const exMemberIdx = transactions.exMembers && transactions.exMembers.findIndex(m => m.address.toLowerCase() === key.toLowerCase());
          if (idx === -1 && !exMembers.includes(key) && exMemberIdx === -1) exMembers.push(key)
        })
      }
      if (tax.taxesCollected) {
        Object.keys(tax.taxesCollected).forEach(key => {
          const idx = guild.members.findIndex(m => m.address.toLowerCase() === key.toLowerCase());
          const exMemberIdx = transactions.exMembers && transactions.exMembers.findIndex(m => m.address.toLowerCase() === key.toLowerCase());
          if (idx === -1 && !exMembers.includes(key) && exMemberIdx === -1) exMembers.push(key)
        })
      }
    })

    if (exMembers.length > 0) {
      const exMemberAddresses = exMembers.join(',')
      const res = (yield call(metazoaClient.checkProfile, { addresses: exMemberAddresses }))
      profiles = res.result.users;
      yield put(actions.Guild.updateBankTransactions({
        transactionsList: transactions.transactionsList,
        exMembers: [...transactions.exMembers, ...profiles]
      }))
    }

    yield put(actions.Guild.updateTax(taxes))

  } catch (error) {
    console.error("loading guild tax failed, Error:");
    console.error(error);
    yield delay(3000);
  } finally {

    yield put(actions.Layout.removeBackgroundLoading("METAZOA:LOAD_GUILD_TAX"));
  }
}

function* reloadBankTransactions(action: Action<Guild>) {
  while (true) {
    try {
      yield waitForConnectorInit();
      const { network } = getBlockchain(yield select());
      const { transactions } = getGuild(yield select());
      const guild = action.payload;
      if (!guild || !guild.guildBank || !guild.members) continue;

      const metazoaClient = new MetazoaClient(network);
      const exMembers: string[] = [];
      const { result } = (yield call(metazoaClient.getGuildBankTransactions, guild.id))
      const res: Transaction[] = result.transactions.map(t => {
        const member = guild.members.find(m => m.address.toLowerCase() === t.initiator.toLowerCase());

        if (!!member) {
          return { ...t, initiator: member, initiatorAddress: t.initiator }
        } else {
          const exMember = transactions.exMembers.length > 0 && transactions.exMembers.find(m => m.address.toLowerCase() === t.initiator.toLowerCase());
          if (!!exMember) {
            return { ...t, initiator: exMember, initiatorAddress: t.initiator }
          } else {
            exMembers.push(t.initiator)
            return { ...t, initiator: null, initiatorAddress: t.initiator }
          }
        }
      })

      // Find and get ex-members data
      if (exMembers.length > 0) {
        const exMemberAddresses = exMembers.join(',')
        const result = (yield call(metazoaClient.checkProfile, { addresses: exMemberAddresses }))
        const profiles: ProfileInfo[] = result.result.users;

        const updated: Transaction[] = res.map(t => {
          if (t.initiator) return t;
          const exMember = profiles.find(m => m.address.toLowerCase() === t.initiatorAddress.toLowerCase());
          if (!exMember) return t;
          return { ...t, initiator: exMember }
        })
        logger("guild bank", "transactions", updated);

        yield put(actions.Guild.updateBankTransactions({ transactionsList: updated, exMembers: [...transactions.exMembers, ...profiles] }));

      } else {
        logger("guild bank", "transactions", res);
        yield put(actions.Guild.updateBankTransactions({ transactionsList: res, exMembers: transactions.exMembers }));
      }

    } catch (error) {
      console.error("failed to load guild bank transactions");
      console.error(error);
      yield delay(3000);
    } finally {
      if (yield cancelled()) {
        const { transactions } = getGuild(yield select());
        yield put(actions.Guild.updateBankTransactions({ transactionsList: [], exMembers: transactions.exMembers }));
      }

      yield race({
        delay: delay(30000),
        cancel: take(actions.Guild.MetazoaGuildActionTypes.CANCEL_LOADING_BANK_TRANSACTIONS)
      })
    }
  }
}

// WATCHERS -------------------------------------------------------------

function* watchGuilds() {
  yield takeLatest([
    actions.Guild.MetazoaGuildActionTypes.LOAD_GUILDS,
    actions.Blockchain.BlockchainActionTypes.READY,
    actions.Blockchain.BlockchainActionTypes.SET_NETWORK,
    actions.Token.TokenActionTypes.FETCH_EXCHANGE_RATES,
  ], loadGuilds);
}

function* watchGuild() {
  yield takeLatest([
    actions.Guild.MetazoaGuildActionTypes.RELOAD_GUILD,
    actions.Token.TokenActionTypes.FETCH_EXCHANGE_RATES,
  ], loadGuild);
}

function* watchBank() {
  yield takeLatest([
    actions.Guild.MetazoaGuildActionTypes.RELOAD_BANK,
  ], loadBank);
}


function* watchReloadBankTransactions() {
  yield takeLatest([
    actions.Guild.MetazoaGuildActionTypes.START_LOADING_BANK_TRANSACTIONS,
  ], reloadBankTransactions);
}

function* watchGuildTax() {
  yield takeLatest([
    actions.Guild.MetazoaGuildActionTypes.LOAD_GUILD_TAX
  ], loadGuildTax)
}

// ----------------------------------------------------------------------

export default function* guildSaga() {
  logger("debug-saga", "INIT GUILD SAGA");
  yield fork(watchGuilds);
  yield fork(watchGuild);
  yield fork(watchBank);
  yield fork(watchReloadBankTransactions);
  yield fork(watchGuildTax);
}
