import { Box, Button, CircularProgress, Link, SvgIcon, SxProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { ExternalLink, Huny } from "assets";
import BigNumber from 'bignumber.js';
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import WarningBox from "components/WarningBox";
import { waitForTx } from "core/utilities";
import { useMemo, useState } from 'react';
import { GuildBankInfo, HivePoolStats } from 'store/types';
import { TBMConnector } from "tbm";
import { useAsyncTask, useBlockTime, useRedux, useToaster } from 'utils';
import { BIG_ZERO, BLOCKS_PER_MINUTE, Decimals } from 'utils/constants';
import { bnOrZero, getExplorerLink } from 'utils/strings';
import { combineStyles } from 'utils/themeUtilities';
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";

export interface ModalProps {
  bankHive?: HivePoolStats;
  open: boolean;
  onClose: () => void;

  guildBank: GuildBankInfo | null;
  tabController: React.Dispatch<React.SetStateAction<number>>
}

const ClaimLiquidityDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, bankHive, guildBank, tabController } = props;
  const network = useNetwork();
  const hiveInfo = useRedux((state) => state.token.hiveInfo);
  const [claimSuccess, setClaimSuccess] = useState<boolean>(false);
  const [claimGuildHiveTxHash, setClaimGuildHiveTxHash] = useState<string>("");
  const [, currentBlock] = useBlockTime();
  const toaster = useToaster();
  const [runClaimGuildHive, loadingClaimGuildHive] = useAsyncTask("claimGuildHive", (error) => {
    toaster(error?.message ?? "Error claiming from hive");
  });

  const hiveRewardsPercentage = useMemo(() => {
    if (!bankHive?.lastSignificantDeposit) return 0;
    const blocksStaked = currentBlock - bankHive?.lastSignificantDeposit
    const daysElapsed = Math.floor(blocksStaked / (BLOCKS_PER_MINUTE * 60 * 24));
    if (daysElapsed >= 240) return 0;
    else return 80 - Math.floor(daysElapsed / 3);
  }, [currentBlock, bankHive])

  const { rewardHunyAmt } = useMemo(() => {
    if (!hiveInfo?.hunyRewardsPerShare || !bankHive?.userBalance) return {};

    let pendingRewardsPerShare = new BigNumber(0)
    if (currentBlock !== 0) {
      const pendingBlocks = currentBlock - hiveInfo.lastRewardBlock
      const pendingKickbacks = bnOrZero(hiveInfo?.incomingKickbacks)
      const pendingRewards = new BigNumber(36750000).dividedBy(2300 * 180).times(pendingBlocks).shiftedBy(Decimals.HUNY).plus(pendingKickbacks)
      pendingRewardsPerShare = pendingRewards.times(1e12).dividedToIntegerBy(hiveInfo.totalShare)
    }

    // reward per share * share / 1e12 - debt
    const userBalance = bankHive.userBalance.shiftedBy(-12);
    const userShare = userBalance.times(hiveInfo!.hunyRewardsPerShare.plus(pendingRewardsPerShare));
    const rewards = BigNumber.max(userShare.minus(bankHive.userDebt), BIG_ZERO).dp(0).shiftedBy(-Decimals.HUNY);

    return {
      rewardHunyAmt: rewards,
      // rewardValue: rewards.times(rates.hunyPrice)
    }
  }, [currentBlock, bankHive, hiveInfo]);

  const handleOnClose = () => {
    onClose();

    setTimeout(() => {
      setClaimSuccess(false);
    }, 1000)
  }

  const getHeader = () => {
    return "Claim HUNY"
  }

  const handleConfirm = () => {
    runClaimGuildHive(async () => {
      await claimHive();
    })
  }

  const claimHive = async () => {
    if (!guildBank || !guildBank.address) return;
    const claimHiveTx = await TBMConnector.claimGuildBankHive(toBech32Address(guildBank.address));
    toaster(`Claim Hive Txn Sent`, { hash: claimHiveTx.id! })
    if (claimHiveTx?.id) {
      try {
        await waitForTx(claimHiveTx.id);
        toaster(`Claim Hive Success `, { hash: claimHiveTx.id! })
        setClaimGuildHiveTxHash(claimHiveTx.id)
        setClaimSuccess(true);
      } catch (e) {
        console.error(e);
        setClaimSuccess(false);
        throw e;
      }
    }
  }

  const getContent = () => {
    if (claimSuccess) {
      return (
        <>
          <Box sx={combineStyles(styles.contentContainer, { gap: "48px" })}>
            <Typography color="primary" sx={styles.contentText}>You have successfully claimed your guild’s HUNY rewards!</Typography>
            <Link
              target="_blank"
              href={getExplorerLink("tx", claimGuildHiveTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} className="linkIcon" />
            </Link>
            <Box sx={styles.buttonGroup}>
              <ContainedButton
                variant="contained"
                sx={styles.button}
                onClick={() => { tabController(1) }}
                disableFocusRipple>
                Visit Refinery
              </ContainedButton>



              <Button
                variant="outlined"
                color="secondary"
                sx={styles.gradientButton}
                onClick={handleOnClose}
              >
                <Typography variant="button" sx={styles.gradientText}>
                  Back to Guild Bank
                </Typography>
              </Button>
            </Box>
          </Box>
        </>
      )
    }

    return (
      <Box sx={styles.contentContainer}>
        <WarningBox sx={styles.warningBox}>
          <Typography variant="body1" color="primary" align="left">
            Whenever you claim HUNY from the Magic Hive, it only yields you some of the accrued HUNY immediately,
            and the remaining needs to be sent to the Refinery for further processing.
            {" "}
            <Link
              target="_blank"
              href="https://docs.zolar.io/stations/magic-hive#claiming-usdhuny-rewards"
            >
              Learn More
            </Link>
          </Typography>
        </WarningBox>
        <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" sx={styles.row}>
          <Box sx={styles.textGroup}>
            <Typography variant="body1" color="primary">Total HUNY Rewards (100%)</Typography>
          </Box>
          <Box sx={styles.textGroup}>
            <Typography variant="h3" color="primary" sx={styles.hunyAmount}>{rewardHunyAmt?.toNumber().toFixed(4) ?? 0}</Typography>
            <Huny height="24px" width="24px" />
          </Box>
        </Box>
        <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" sx={styles.row}>
          <Box sx={styles.textGroup}>
            <Typography variant="body1" color="primary">HUNY to Refinery ({hiveRewardsPercentage}%)</Typography>
          </Box>
          <Box sx={styles.textGroup}>
            <Typography variant="h3" color="primary" sx={styles.hunyAmount}>- {rewardHunyAmt?.times(hiveRewardsPercentage / 100).toNumber().toFixed(4) ?? 0}</Typography>
            <Huny height="24px" width="24px" />
          </Box>
        </Box>
        <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" sx={styles.row}>
          <Box sx={styles.textGroup}>
            <Typography variant="body1" color="primary">Huny to Guild Bank ({100 - hiveRewardsPercentage}%)</Typography>
          </Box>
          <Box sx={styles.textGroup}>
            <Typography variant="h3" color="success.main" sx={styles.hunyAmount}>{rewardHunyAmt?.times((100 - hiveRewardsPercentage) / 100).toNumber().toFixed(4) ?? 0}</Typography>
            <Huny height="24px" width="24px" />
          </Box>
        </Box>
        <ContainedButton
          sx={styles.button}
          disabled={loadingClaimGuildHive || bankHive?.userShare.eq(0)}
          onClick={handleConfirm}
        >{
            loadingClaimGuildHive
              ? <CircularProgress size={18} />
              : "Confirm & Claim"
          }
        </ContainedButton>
      </Box>
    );
  }
  return (
    <DialogModal header={getHeader()} open={open && !!guildBank} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true}>
      <Box>
        {getContent()}
      </Box>
    </DialogModal>

  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  warningBox: {
    marginBottom: '24px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
  },
  textGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hunyAmount: {
    marginRight: '10px',
  },
  button: {
    minWidth: '250px',
  },
  viewTx: {
    fontWeight: 600,

    ".linkIcon": {
      marginLeft: "8px",
      verticalAlign: "sub",
      fontSize: "20px",
      marginBottom: "1px",
      "@media (max-width:600px)": {
        fontSize: "18px",
        verticalAlign: "text-top",
        marginBottom: 0,
      },
    },
  },
  contentText: {
    textAlign: 'center',
    fontSize: '24px',
    lineHeight: '36px',
    marginTop: '20px'
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column'
  },

  gradientButton: {
    fontSize: '24px',
    marginTop: '16px',
    width: '360px',
    // width: '100%',
    padding: '14px 40px',
    fontWeight: 700,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },

  gradientText: {
    background: "-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}

export default ClaimLiquidityDialog;