import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, Box, Dialog, DialogProps, Grow, IconButton, styled, SxProps, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, ReactElement, Ref } from "react";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";

export interface DialogModalProps extends DialogProps {
  header?: string | JSX.Element;
  hideCloseButton?: boolean;
  dialogOverwrites?: SxProps<AppTheme>
}

export const GrowTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Grow ref={ref}
    style={{ transformOrigin: '0 0 0' }}
    {...(true ? { timeout: 1000 } : {})}
    {...props} />;
});

const DialogModal: React.FC<DialogModalProps> = (props: DialogModalProps) => {
  const {
    children,
    header,
    hideCloseButton,
    onClose,
    dialogOverwrites,
    ...rest
  } = props;

  const onCloseButton = (e: any) => {
    if (typeof onClose === "function") onClose(e, "backdropClick");
  };

  return (
    <StyledDialog
      maxWidth={"md"}
      onClose={onClose}
      closeAfterTransition
      TransitionComponent={GrowTransition}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      PaperProps={{
        sx: styles.paperProps,
      }}
      {...rest}
    >
      <Box sx={combineStyles(styles.container, dialogOverwrites)}>
        <Box sx={styles.dialogHeader}>
          <Typography variant="h2" color="primary">
            {header}
          </Typography>
          {!hideCloseButton && (
            <IconButton
              aria-label="close"
              sx={styles.closeButton}
              onClick={onCloseButton}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
        {children}
      </Box>
    </StyledDialog>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  root: {},
  container: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)',
    position: 'relative',
    borderRadius: '16px',
    boxShadow: 24,
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    placeItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    "@media (max-width:600px)": {
      padding: '40px 20px 40px',
    },
  },
  paperProps: {
    borderRadius: '16px',
  },
  dialogHeader: {
    width: '100%',
    backgroundColor: 'transparent',
    borderRadius: '16px 16px 0 0',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    '& .MuiSvgIcon-root': {
      color: '#fff',
      "@media (min-width:900px)": {
        height: '30px',
        width: '30px',
      },
      "@media (max-width: 600px)": {
        height: '20px',
        width: '20px',
      }
    }
  },
}

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
  }
})

export default DialogModal;