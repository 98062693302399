import { Box, CircularProgress, SxProps } from "@mui/material";
import { logger } from "core/utilities";
import React, { Fragment, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { actions } from "store";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import useRedux from "utils/useRedux";
import GuildBreadcrumb from "../GuildBreadcrumb";
import { GuildBankPreview, MemberList, RankPodium } from "./components";
import GuildOverview from "./components/GuildOverview";

const GuildView: React.FC = () => {
  const { guildId } = useParams();

  const network = useNetwork();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const guildStore = useRedux((state) => state.guild.allGuilds);
  const guildBankStore = useRedux((state) => state.guild.bank);

  // STATES / HOOKS -----------------

  const guild = useMemo(() => {
    if (!guildStore) return;
    if (!guildId) {
      navigate('/guilds');
      return;
    }

    //INFO: Locate guild from store by guildId, redirects out if it's invalid
    const currentGuild = guildStore.find(guild => guild.id === parseInt(guildId))
    if (!currentGuild) {
      navigate('/guilds');
      return;
    }

    //INFO: Retrieve bank from store if guild has bank
    if (currentGuild.guildBank && !guildBankStore) dispatch(actions.Guild.reloadBank(currentGuild!));

    return currentGuild
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guildStore, guildId, guildBankStore, network])

  // Get current bank from store 
  const bank = useMemo(() => {
    if (!guildBankStore || !guild?.guildBank) return;

    const currentBank = guildBankStore;
    logger("debug-bank", "currentBank - GuildView.tsx", currentBank, {
      notMatch: (currentBank.id! !== guild.guildBank.id!),
    });

    return currentBank;
    // eslint-disable-next-line
  }, [guildBankStore, guild])

  // FUNCTIONS ----------------------

  // EVENT HANDLERS -----------------

  // --------------------------------

  const breadcrumbs = [
    {
      path: "/guilds",
      value: "Guild Overview",
    },
    {
      path: `/guilds/${guildId}`,
      value: guild?.name!!,
    },
  ];

  return (
    <Box sx={styles.viewRoot}>
      {!guild ? (
        <Box display="flex" alignItems="center" justifyContent="center" mt="80px">
          <CircularProgress size={36} />
        </Box>
      ) : (
        <Fragment>
          <GuildBreadcrumb linkPath={breadcrumbs} />
          <Box sx={styles.guildView}>
            <GuildOverview guild={guild} />
            <Box sx={styles.viewRight}>
              <RankPodium guild={guild} />
              <GuildBankPreview guild={guild} bank={bank} />
              <MemberList guild={guild} />
            </Box>
          </Box>
        </Fragment>
      )}
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  viewRoot: {
    marginTop: "160px",
    width: "100%",
    height: "100%",
    position: 'relative',
    overflow: "hidden",
    overflowX: "auto",

    '::-webkit-scrollbar': {
      height: "6px",
      width: "6px",
    },

    '::-webkit-scrollbar-track': {
      marginX: "24px",
    },

    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },

    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },

  guildView: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    placeContent: "start",
    placeItems: "start",
    gap: "5vw",
  },

  viewRight: {
    flex: '1 1 0%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    placeContent: 'start',
    placeItems: 'start',
  },
}

export default GuildView;