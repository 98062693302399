import { ControlMode, Guild } from "store/types";


const whoseSignature = (walletAddress: string, guild: Guild) => {
  if (guild.guildBank === null) return;

  const isLeader:boolean = walletAddress === guild.leaderAddress;
  const controlMode = guild.guildBank.controlMode;
  const defaultSignaturePrompt:string = isLeader ? "1 officer’s signature is" : "Captain's signature is";
  
  switch (controlMode){
    case ControlMode.CaptainOnly:
      return !isLeader ? defaultSignaturePrompt : "";
    case ControlMode.CaptainAndOneOfficer:
      return defaultSignaturePrompt;
    case ControlMode.CaptainAndTwoOfficers:
      return !isLeader ? "1 officer’s + 1 captain’s signatures are" : defaultSignaturePrompt;
  }
}

export default whoseSignature;