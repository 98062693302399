import { Box, CircularProgress, SvgIcon, SxProps, Typography } from "@mui/material";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import { WarningIcon } from "assets";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { waitForTx } from "core/utilities";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { TBMConnector } from "tbm";
import { useToaster } from "utils";
import { ContractsBech32, Decimals } from "utils/constants";
import { bnOrZero } from "utils/strings/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useRedux from "utils/useRedux";
import { MigrateDialog } from "./components";
const Migration: React.FC = () => {
  const wallet = useRedux((state) => state.wallet.wallet);
  const dispatch = useDispatch();
  const toaster = useToaster();
  const tokenState = useRedux((state) => state.token);
  const [showMigrateDialog, setShowMigrateDialog] = useState<boolean>(false);
  const [currentTx, setCurrentTx] = useState<number>(1);
  const zilPrice = tokenState.ExchangeRates.zilPrice;
  const hunyPrice = tokenState.ExchangeRates.hunyPrice;
  const [runMigrate, loadingMigrate] = useAsyncTask("poolMigrate", (error) => {
    setShowMigrateDialog(false);
    setCurrentTx(1);
    toaster(error?.message ?? "Error Migrating");
  });

  const handleOnClose = () => {
    if (loadingMigrate) return;

    setShowMigrateDialog(false);
    setCurrentTx(1);
  }

  const handleMigrateHuny = () => {
    runMigrate(async () => {
      if (!wallet) throw new Error("Wallet not connected");
      setShowMigrateDialog(true);
      let hunyAmountUnitless = "";

      // increase allowance for hive v2
      const approveAmount = new BigNumber(2).pow(128).minus(1).dp(0);
      const approveTx = await TBMConnector.approveHiveAllowanceIfRequired(wallet, approveAmount);

      if (approveTx?.id) {
        toaster(`Submitted Approve `, { hash: approveTx.id, overridePersist: true });

        const tx = await waitForTx(approveTx.id);

        if (tx.status >= 3)
          throw new Error("Approve tx failed");
      }

      // claim rewards
      setCurrentTx(2);
      const claimTx = await TBMConnector.claimOldHive(wallet);

      if (claimTx.isRejected()) {
        throw new Error("Submitted transaction was rejected.");
      }

      if (claimTx?.id) {
        toaster(`Submitted Claim `, { hash: claimTx.id, overridePersist: true });

        const tx = await waitForTx(claimTx.id);

        if (tx.status >= 3)
          throw new Error("Claim tx failed");
      }

      // emergency withdraw
      setCurrentTx(3);
      const withdrawTx = await TBMConnector.emergencyWithdrawOldHive(wallet);

      if (withdrawTx.isRejected()) {
        throw new Error("Submitted transaction was rejected.");
      }

      if (withdrawTx?.id) {
        toaster(`Submitted Withdraw `, { hash: withdrawTx.id, overridePersist: true });

        const tx = await waitForTx(withdrawTx.id);

        if (tx.status >= 3)
          throw new Error("Withdraw tx failed");

        const receipt = tx.getReceipt();

        if (receipt) {
          receipt.event_logs?.forEach((event) => {
            if (event._eventname === "TransferSuccess") {
              hunyAmountUnitless = event.params.find((param) => param.vname === "amount")?.value;
            }
          });
        }
      }

      // add liquidity to hive v2
      setCurrentTx(4);
      if (hunyAmountUnitless) {
        const zilswap = TBMConnector.getSDK();
        const walletAddress = wallet.addressInfo.byte20.toLowerCase();
        const hunyAddress = ContractsBech32[zilswap.network].HunyToken;
        const addHunyAmount = bnOrZero(hunyAmountUnitless).shiftedBy(-Decimals.HUNY).decimalPlaces(Decimals.HUNY);

        const bech32HunyAddress = fromBech32Address(hunyAddress);
        const newHunyBalanceResult = await zilswap.zilliqa.blockchain.getSmartContractSubState(
          bech32HunyAddress,
          "balances",
          [walletAddress],
        );
        const newHunyBalance = bnOrZero(newHunyBalanceResult.result?.balances?.[walletAddress]);
        const addLiquidityHunyAmount = BigNumber.min(newHunyBalance, addHunyAmount.shiftedBy(Decimals.HUNY)).dp(0);
        const pool = zilswap.getPool(bech32HunyAddress);

        if (!pool) throw new Error("pool not found");
        const rate = zilPrice.gt(0) ? hunyPrice.div(zilPrice) : 1;
        const addZilAmount = addHunyAmount.times(rate).decimalPlaces(Decimals.ZIL);

        let addLiquidityZilAmount = addZilAmount.shiftedBy(Decimals.ZIL).dp(0);
        if (pool.tokenReserve.gt(0))
          addLiquidityZilAmount = addLiquidityHunyAmount.div(pool.tokenReserve).times(pool.zilReserve).dividedBy(1.02).dp(0);

        const result = await TBMConnector.addHiveLiquidity(
          wallet,
          addLiquidityHunyAmount,
          addLiquidityZilAmount,
        );

        await zilswap.observeTx({
          hash: result.id!,
          deadline: Number.MAX_SAFE_INTEGER,
        });

        toaster(`Submitted Add Liquidity `, { hash: result.id, overridePersist: true });

        await waitForTx(result.id!);
        dispatch(actions.Token.reloadHivePool());
        setShowMigrateDialog(false);
      }
    });
  }

  return (
    <Fragment>
      <Box sx={styles.migrateBanner} justifyContent="space-between">
        <Box display="flex" alignItems="center" flex={0.9}>
          <SvgIcon component={WarningIcon} sx={styles.warningIcon} />
          <Typography variant="body1" color="primary" align="left">
            <strong>Can't see your Magic Hive balance?</strong>
            {" "}
            Migrate your HUNY to the new Magic Hive(v2) to view your liquidity and start earning HUNY rewards!
          </Typography>
        </Box>
        <ContainedButton sx={styles.migrateButton} onClick={handleMigrateHuny}>
          {loadingMigrate
            ? <CircularProgress size={18} />
            : "Migrate HUNY"
          }
        </ContainedButton>
      </Box>
      <MigrateDialog
        open={showMigrateDialog}
        onClose={handleOnClose}
        currentTx={currentTx}
      />
    </Fragment>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  migrateBanner: {
    minWidth: '720px',
    marginTop: '120px',
    width: "100%",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    borderRadius: "16px",
    padding: "20px 16px",
    display: "flex",
    alignItems: "center",
  },
  warningIcon: {
    marginRight: "14px",
    marginBottom: "1.5px",
  },
  migrateButton: {
    height: 60,
    minWidth: 300,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
}

export default Migration;
