import { useMemo, useState } from "react";
import { Box, Button, SxProps, Typography } from "@mui/material";
import { Huny } from "assets";
import BoxWithHeader from "components/BoxWithHeader";
import { BIG_ONE, BIG_ZERO, ContractsBech32, Decimals } from "utils/constants";
import { getZilswapLink } from "utils/strings";
import { formatIntegerNumber } from "utils/strings/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useBlockTime from "utils/useBlockTime";
import useRedux from "utils/useRedux";

const HunyOverview: React.FC = () => {
  const [, currentBlock] = useBlockTime();
  const network = useRedux((state) => state.blockchain.network);
  const hunyPrice = useRedux((state) => state.token.ExchangeRates.hunyPrice)
  const refineryConfig = useRedux((state) => state.token.refineryConfig)
  const refineryHunyStats = useRedux((state) => state.token.refineryHunyStats)
  const hunyPots = useRedux((state) => state.token.hunyPots)
  const [showDialog, setShowDialog] = useState<boolean>(false); // eslint-disable-line @typescript-eslint/no-unused-vars

  const hunyContract = useMemo(() => {
    return ContractsBech32[network].HunyToken;
  }, [network]);

  const {
    totalHunyClaimable,
    totalHunyRefining,
  } = useMemo(() => {
    let claimable = BIG_ZERO;
    let refining = BIG_ZERO;
    if (!hunyPots) return {
      totalHunyClaimable: claimable,
      totalHunyRefining: refining,
    };

    let refineryBoost = BIG_ONE;
    if (refineryHunyStats?.totalShare?.gt(0) && refineryHunyStats?.totalSupply) {
      // boost = total supply / total share
      refineryBoost = refineryHunyStats.totalSupply.div(refineryHunyStats.totalShare);
    }

    for (const [, hunyPot] of Object.entries(hunyPots)) {
      const progress = hunyPot.progress(currentBlock, refineryConfig?.immediateRefine);
      const totalRefining = hunyPot.amount.shiftedBy(-Decimals.HUNY).times(refineryBoost)
      refining = refining.plus(totalRefining);

      const totalClaimable = totalRefining.times(progress);
      claimable = claimable.plus(totalClaimable);
    }

    return {
      totalHunyClaimable: claimable,
      totalHunyRefining: refining,
    };
  }, [currentBlock, hunyPots, refineryHunyStats, refineryConfig])

  // const handleClaimHuny = () => {
  //   setShowDialog(true);
  // }
  return (
    <Box sx={styles.overviewWrapper}>
      <BoxWithHeader
        headerText={<Typography variant="h3" color="primary">TOTAL HUNY BALANCE</Typography>}
        sx={styles.boxWrapper}
      >
        <Typography variant="h2" color="primary" component="span" sx={styles.TextRow}>
          <Huny width="40px" height="40px" />
          &nbsp; {formatIntegerNumber(totalHunyRefining)}
        </Typography>
      </BoxWithHeader>
      <BoxWithHeader
        headerText={<Typography variant="h3" color="primary">HUNY  PRICE</Typography>}
        sx={styles.boxWrapper}
      >
        <Box sx={styles.boxRow}>
          <Typography variant="h2" color="primary" component="span" sx={styles.TextRow}>
            ${hunyPrice?.toFormat(4) ?? "-"}
            {/* <Typography variant="body1" color="success.main">+1.25%</Typography> */}
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            sx={styles.getHunyButton}
            target="_blank"
            href={getZilswapLink(hunyContract)}>
            <Typography variant="button" sx={styles.gradientText}>Get HUNY</Typography>
          </Button>
        </Box>
      </BoxWithHeader>
      <BoxWithHeader
        headerText={<Typography variant="h3" color="primary">TOTAL HUNY CLAIMED</Typography>}
        sx={styles.boxWrapper}
      >
        <Typography variant="h2" color="primary" component="span" sx={styles.TextRow}>
          <Huny width="40px" height="40px" />
          &nbsp; ???
        </Typography>
      </BoxWithHeader>
      <BoxWithHeader
        headerText={<Typography variant="h3" color="primary">TOTAL CLAIMABLE HUNY</Typography>}
        sx={styles.boxWrapper}
      >
        <Box sx={styles.boxRow}>
          <Typography variant="h2" color="primary" component="span" sx={styles.TextRow}>
            <Huny width="40px" height="40px" />
            &nbsp; {formatIntegerNumber(totalHunyClaimable)}
          </Typography>
          {/* <ContainedButtonSmall sx={styles.claimButton} onClick={() => handleClaimHuny()}>Claim All</ContainedButtonSmall> */}
        </Box>
      </BoxWithHeader>
      {/* <ClaimHunyDialog
        totalHuny={totalHuny}
        percentageComplete={harvestProgress}
        open={showDialog}
        onClose={() => setShowDialog(false)}
      /> */}
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  overviewWrapper: {
    marginY: '10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    "@media (max-width:1200px)": {
      flexDirection: 'column',
    },
  },
  TextRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingLeft: '20px',
    paddingBottom: '10px',
    minHeight: '62px',
  },
  boxWrapper: {
    marginBottom: '10px',
    width: '49.5%',
    "@media (max-width:1200px)": {
      width: '100%',
    },
  },
  boxRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  getHunyButton: {
    paddingX: '20px',
    minWidth: '180px',
    height: '42px',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '1.125rem',
  },
  claimButton: {
    height: '42px',
    paddingX: '20px',
    minWidth: '180px',
    fontSize: '1.125rem',
  }
}

export default HunyOverview;
