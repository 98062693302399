import { Box, Container, Grid, styled, SxProps, Typography } from "@mui/material";
import {
  BlueLine, OrangeLine, Smoke3a
} from "assets";
import { StatTabIcon, ZoaIcon } from "assets/homepage";
import LoreTitleFrame from 'assets/homepage/LoreTitleFrame.svg';
import PhaseFrame from "assets/homepage/PhaseFrame.svg";
import PhasesFrame from "assets/homepage/PhasesFrame.svg";
import StrokeDash from "assets/homepage/StrokeDash.svg";
import Phase1Faded from "assets/homepage/Phase1Faded.svg";
import Phase2Faded from "assets/homepage/Phase2Faded.svg";
import Phase3Faded from "assets/homepage/Phase3Faded.svg";
import Phase4Faded from "assets/homepage/Phase4Faded.svg";
import LiveTag from "assets/icons/LiveTag.svg";
import PanelFrame from "assets/PanelFrame.svg";
import ZOrdH2 from 'assets/quests/Locations/ZOMGStore/Z-Ordnance/ZOrd-H2.svg';
import ZOrdOH1 from 'assets/quests/Locations/ZOMGStore/Z-Ordnance/ZOrd-OH1.svg';
import ZOrdW1 from 'assets/quests/Locations/ZOMGStore/Z-Ordnance/ZOrd-W1.svg';
import ContainedButton from "components/ContainedButton";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { PROFESSIONS_ICONS } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { RESOURCES } from "components/Quest/QuestConstants";
import { logger } from "core/utilities";
import React from "react";
import { theme } from "theme";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";

const MissionPhases: React.FC = () => {

  const phases = [
    {
      title: 'Inspect your Metazoa Stats',
      description: 'Metazoas will have their intrinsic strengths revealed and this will affect upcoming missions.',
      endArtifacts: [
        <ZoaIcon key="zoa-icon" />,
        <StatTabIcon key="stat-tab" />,
      ],
      fadedImage: Phase1Faded,
    },
    {
      title: 'Choose your Metazoa Professions',
      description: "A new journey begins with profession quests! Choose your Metazoa's profession specialization based on each Metazoa’s stats.",
      endArtifacts: Object.values(PROFESSIONS_ICONS).map((iconPath, idx) => (
        <Box
          key={idx}
          component="img"
          src={iconPath}
        />
      )),
      fadedImage: Phase2Faded
    },
    {
      title: 'Embark on Quests',
      // status: PhaseStatus.Upcoming,
      description: 'Your Metazoa will embark on profession quests, where these raw resources can be further refined into weapons for combat, juice for levelling power and gemstones for stat bonuses!',
      endArtifacts: Object.values(RESOURCES).map((resource) => (
        <Box
          key={resource.key}
          component="img"
          src={resource.iconSrc}
        />
      )),
      fadedImage: Phase3Faded
    },
    {
      title: 'ZOMG Store',
      description: 'The Zolar Ordnance, Merchandise and Goods (ZOMG) Store is your one-stop shop where you can purchase the resources to help your hardworking Metazoas.',
      endArtifacts: [
        <Box
          key="zOrdH2"
          component="img"
          src={ZOrdH2}
        />,
        <Box
          key="zOrdW1"
          component="img"
          src={ZOrdW1}
        />,
        <Box
          key="zOrdH1"
          component="img"
          src={ZOrdOH1}
        />,
      ],
      fadedImage: Phase4Faded
    },
  ]

  logger('debug-homepageV2', {

  })

  return (
    <Container maxWidth="xl" sx={styles.container} component="section">
      <SvgBox component="aside" id="SVG-Phases">
        <Smoke3aBox>
          <Smoke3a width="100%" />
        </Smoke3aBox>
        <BlueLineBox1>
          <BlueLine />
        </BlueLineBox1>
        <BlueLineBox2>
          <BlueLine />
        </BlueLineBox2>
        <OrangeLineBox>
          <OrangeLine width="100%" />
        </OrangeLineBox>
      </SvgBox>
      <Box maxWidth="xl" sx={combineStyles(
        styles.content, {
        display: 'flex',
        flexDirection: 'column',
        placeContent: 'center',
        placeItems: 'center',
        gap: 2,
      }
      )} component="main">
        <Box position="relative" sx={{
          border: '1px solid transparent',
          borderImage: `url(${PhasesFrame})`,
          borderImageSlice: '300',
          borderImageWidth: '200px',
          borderImageOutset: '0px',
          borderImageRepeat: 'stretch stretch',
          padding: { xs: '135px 60px 100px', md: '110px 60px 100px' },
          maxWidth: '100%',
        }}>
          <Box sx={{
            border: '1px solid transparent',
            backgroundImage: `url(${LoreTitleFrame})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            paddingX: '20px',
            paddingTop: { xs: '30px', md: '40px', lg: '46px' },
            paddingBottom: { xs: '5px', md: '5px', lg: '10px' },
            textAlign: 'center',
            margin: '0 auto',
            width: { xs: '100%', md: '923px', lg: '923px' },
            position: 'absolute',
            top: { xs: '-4em', sm: '-4em', },
            left: 0,
            right: 0,
          }}>
            <Typography sx={{
              fontFamily: 'Syne',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: { xs: '20px', sm: '24px', lg: '40px', },
              lineHeight: '100%',
              textAlign: 'center',
              ...GUILD_LIGHTGRAY_GRADIENT,
            }}>
              Mission II: The Right to Bear Arms
            </Typography>
            <Box
              component="img"
              src={LiveTag}
              alt="live-tag"
              sx={{
                marginTop: { xs: '8px', md: '12px', lg: '16px' },
                width: '63px',
                height: '20px'
              }}
            />
            <Box sx={{
              position: 'absolute',
              left: '2em',
              right: '2em',
              top: { xs: '6em', md: '4.5em', },
              background: 'red',
              display: 'none',
              placeContent: 'space-between',
              placeItems: 'center',
            }}>
              <Box sx={{
                background: 'green',
                backgroundImage: `url(${StrokeDash})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '1.5em',
                backgroundClip: 'content-box',
                width: '40%',

              }} />
              <Box sx={{
                background: 'green',
                backgroundImage: `url(${StrokeDash})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '1.5em',
                backgroundClip: 'content-box',
                width: '40%',

                display: { xs: 'none', md: 'initial' },
              }} />
            </Box>
          </Box>

          <Grid
            container
            columns={{ xs: 1, md: 2, lg: 4, }}
            sx={{
              rowGap: '1.5em',
              columnGap: '1em',
              placeContent: { xs: 'center', lg: 'space-between', },

            }}
          >

            {phases.map((phase, idx) => (
              <Grid item
                key={phase.title}
                xs={0.94}
                sx={{
                  background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
                  backgroundClip: 'border-box',

                  border: '1px solid transparent',
                  borderImage: `url(${PanelFrame})`,
                  borderImageSlice: '10',
                  borderImageWidth: '10px',
                  borderImageOutset: '0px 0px 0px 0px',
                  borderImageRepeat: 'stretch stretch',
                  flex: '1 0 auto',
                  padding: '45px 20px 25px',

                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  placeContent: 'start',
                  placeItems: 'start',
                  gap: 4,
                }}>
                <Box sx={{
                  position: 'absolute',
                  top: '-15px',
                  left: '0',
                  right: '0',

                  textAlign: 'center',
                  margin: '0 auto',

                  backgroundImage: `url(${PhaseFrame})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '70%',
                }}>

                  <Typography
                    sx={{
                      fontFamily: 'Prompt',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '18px',
                      lineHeight: '28px',
                    }}
                  >
                    Phase {idx}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    component="img"
                    src={phase.fadedImage}
                    alt="faded-bg"
                    sx={{
                      width: '90%',
                      opacity: '15%',
                      position: 'absolute',
                      bottom: '0px',
                      right: '-50px',
                      pointerEvent: 'none',
                    }}
                  />
                </Box>

                <Typography color="secondary"
                  sx={{
                    fontFamily: 'Prompt',
                    fontStyle: 'normal',
                    fontWeight: '300',
                    fontSize: '24px',
                    lineHeight: '150%',
                    letterSpacing: ' 0.24em',
                    textTransform: 'uppercase',

                    display: 'flex',
                    flexDirection: 'column',
                    placeContent: 'center',
                    placeItems: 'start',
                    gap: 2,

                  }}>
                  {phase.title}
                </Typography>

                <Typography variant="body1" sx={{
                  ...GUILD_LIGHTGRAY_GRADIENT,
                  zIndex: 1,
                }}>
                  {phase.description}
                </Typography>

                <Box
                  sx={{
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    placeItems: 'center',
                    gap: 2,
                    mt: 'auto',

                    ">*": {
                      height: '38px',
                      width: 'fit-content',

                      [theme.breakpoints.down('md')]: {
                        height: '48px',
                        width: 'fit-content',
                      },
                    }
                  }}>
                  {phase.endArtifacts.map((node) => node)}
                </Box>

              </Grid>

            ))}

          </Grid>
        </Box>

        <ContainedButton isRoute href="/map">
          Enter Zolar 🚀
        </ContainedButton>

      </Box>
    </Container>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    padding: "0px",
    position: 'relative',

    // //DEBUG: Remove this
    // border: '1px solid yellow',
    // "*": {
    //   border: '1px solid red',
    // }

    // borderBottom: "1px solid rgba(174, 241, 238, 0.1)",
    // position: "relative",
    // "@media (max-width:900px)": {
    //   borderBottom: "0px",
    // },
  },
  content: {
    padding: "40px 0px 260px",
    "@media (max-width:900px)": {
      padding: "40px 0px 260px",
      borderBottom: "0px",
    },
    "@media (max-width:600px)": {
      padding: "100px 0px 220px",
      borderBottom: "0px",
    },
  },
  contentcol: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    placeContent: "center",
  },
  textbody: {
    textAlign: "center",
    zIndex: 1,
  },
};

const SvgBox = styled(Box)({
  pointerEvents: 'none',
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
  height: "100%",
});

const Smoke3aBox = styled(Box)({
  width: "300px",
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(358.98deg, #FFFFFF 0.71%, #C1C1C1 77.66%, #999999 101.96%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "-50px",
  left: "350px",
  "@media (max-width:600px)": {
    top: "-50px",
    left: "20px",
    width: "300px",
  },
});

const BlueLineBox1 = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "150px",
  left: "0px",
  "@media (max-width:900px)": {
    top: "420px",
  },
});
const BlueLineBox2 = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "0px",
  left: "1100px",
  "@media (max-width:900px)": {
    display: "none",
  },
});

const OrangeLineBox = styled(Box)({
  pointerEvents: 'none',
  width: "10%",
  position: "absolute",
  top: "420px",
  right: "120px",
  "@media (max-width:900px)": {
    top: "600px",
  },
  "@media (max-width:600px)": {
    top: "450px",
    right: "80px",
  },
});



export default MissionPhases;
