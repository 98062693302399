import { Box, BoxProps, Divider, Grow, Slide, styled, SxProps, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { MinoTabIcon, UrsaTabIcon } from "assets";
import MetazoaPreview from "assets/MetazoaPreview.gif";
import MetazoaXPBar from "assets/MetazoaXPBar.svg";
import PanelFrame from "assets/PanelFrame.svg";
import StatPanelFrame from "assets/StatPanelFrame.svg";
import BigNumber from "bignumber.js";
import { DEFAULT_SRC, GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { LOCATION_TAGS, METAZOA_TRAITS, PROFESSIONS, STATS, STAT_PROFESSION } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { logger } from "core/utilities";
import { useRef, useState } from "react";
import { Guild, NftMetadata } from "store/types";
import { bnOrZero } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";


export interface MetazoaViewProps {
  token: NftMetadata;
  guild?: Guild;
  hunyInWallet?: BigNumber;
  hunyInHive?: BigNumber;
}


const StatPanel = (props: BoxProps) => {
  const { children, ...rest } = props;
  return (
    <Box sx={styles.frameBorder} {...rest}>
      {children}
    </Box >
  );
}

const MetazoaView: React.FC<MetazoaViewProps> = (props: MetazoaViewProps) => {
  const { token, guild, hunyInHive, hunyInWallet, ...rest } = props;

  const [panelContent, setPanelContent] = useState<string>(Object.entries(token.stats!)[0][0] ?? 'STR');
  const [extendedPanelContent, setExtendedPanelContent] = useState<string>('');

  const handleChange = (event: React.MouseEvent<HTMLElement>, content: any) => {
    logger("debug-component", "handleChange")
    setPanelContent(content);
    setExtendedPanelContent('');
  };

  const statBuffs = ["LUK", "SPD", "END", "ACC"]

  const getAttribute = (traitType: string, field: string) => {
    if (!token.attributes?.length)
      return "?";

    const index = token.attributes.findIndex(attribute => attribute.trait_type === traitType);

    if (index === -1)
      return "?";

    if (field === "value") {
      return token.attributes[index].value;
    } else {
      const rarity = token.attributes[index].rarity;
      return bnOrZero(rarity).shiftedBy(2).toFixed(1);
    }
  }

  const getStatContent = () => {
    if (!panelContent) return;
    if (panelContent === "GenesisInfo") {
      return (
        <Box>
          <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Genesis Stats</Typography>
          <Box>
            <Box sx={styles.attributeBox}>
              {METAZOA_TRAITS.slice(0, 5).map((trait, index) => (getAttribute(METAZOA_TRAITS[index + 5], "value") !== "?") && (
                <Box sx={styles.attributesRow} key={index}>
                  <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>{trait}</Typography>
                  <Typography color="primary" sx={styles.value}>{getAttribute(trait, "value")}</Typography>
                </Box>
              ))}
              <Divider sx={styles.cardDivider} />

              <Box>
                {METAZOA_TRAITS.slice(0, 5).map((trait, index) => (getAttribute(METAZOA_TRAITS[index + 5], "value") !== "?") && (
                  <Box sx={styles.genesisTraits} key={index}>
                    <Typography color="primary" variant="subtitle1" sx={styles.traitType}>
                      {METAZOA_TRAITS[index + 5]}
                      <Typography color="primary" sx={combineStyles(styles.value, {

                      })} paddingX={0}>{getAttribute(METAZOA_TRAITS[index + 5], "value")}</Typography>
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    else {
      const title = STATS[panelContent.toUpperCase()];
      const stat = token.stats![panelContent]
      const classAttribute = token.attributes!.find(attribute => attribute.trait_type === "Class")
      const factionAttribute = token.attributes!.find(attribute => attribute.trait_type === "Faction")
      const genAttribute = token.attributes!.find(attribute => attribute.trait_type === "Gen")
      const affinityAttribute = token.attributes!.find(attribute => attribute.trait_type === "Affinity")
      return (
        <Box>
          <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>{title?.fullLength} Components</Typography>
          {/* <Typography variant="body1" color="primary" sx={styles.statTextSize}>{title?.description}</Typography> */}

          {/* Class base + class power + gen*/}
          {!!(stat.classBonus + stat.classPower + stat.genPower) && (
            <Box sx={styles.attributesRow}>
              <Box sx={styles.attributesLabel}>
                <Typography variant="subtitle1" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                  {classAttribute?.value}/
                  {factionAttribute?.value}/
                  {genAttribute?.value}&nbsp;
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={combineStyles(styles.statTextSize, styles.traitType)}
                  component="span"
                >
                  {classAttribute?.trait_type}/
                  {factionAttribute?.trait_type}/
                  {genAttribute?.trait_type}
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                +{stat.classBonus + stat.classPower + stat.genPower}
              </Typography>
            </Box>
          )}

          {/* Affinity */}
          {!!stat.affinity && (
            <Box sx={styles.attributesRow}>
              <Box sx={styles.attributesLabel}>
                <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                  {affinityAttribute?.value}&nbsp;
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={combineStyles(styles.statTextSize, styles.traitType)}
                  component="span"
                >
                  {affinityAttribute?.trait_type}
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                +{stat.affinity}
              </Typography>
            </Box>
          )}
          {/* Equipment */}
          {stat.equipment.map((item, idx) => (
            <Box sx={styles.attributesRow} key={idx}>
              <Box sx={styles.attributesLabel}>
                <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                  {item.name}&nbsp;
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={combineStyles(styles.statTextSize, styles.traitType)}
                  component="span"
                >
                  Item
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                +{item.stat}
              </Typography>
            </Box>
          ))}
          {/* Total Strength */}
          <Box sx={combineStyles(styles.attributesRow, styles.totalRow)}>
            <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, { fontSize: '14px', ...GUILD_LIGHTGRAY_GRADIENT })}>
              Total {title?.fullLength}
            </Typography>
            <Typography variant="subtitle1" color="success.main" sx={combineStyles(styles.statTextSize, { fontSize: '14px', fontWeight: '400' })}>
              {stat.total}&nbsp;{title?.type?.toUpperCase()}
            </Typography>
          </Box>

          {!!token?.profession && !statBuffs.includes(title?.type ?? "") && !!(token.profession === title?.type) && (<>
            <Divider sx={styles.cardDivider} />
            <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Stats Effect</Typography>
            {/* Profession Buff - Gathering Speed */}
            <Box sx={styles.attributesRow} >
              <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                {PROFESSIONS[STAT_PROFESSION[token.profession]].resource} Gathering Speed
              </Typography>
              <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                +{PROFESSIONS[STAT_PROFESSION[token.profession]].levelBonus * 100}%
              </Typography>
            </Box>

            {/* Profession Buff - Mastery Improvement Speed */}
            <Box sx={styles.attributesRow}>
              <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                Mastery Improvement Speed&nbsp;
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={combineStyles(styles.statTextSize, styles.disabledText)}
                  component="span"
                >
                  {PROFESSIONS[STAT_PROFESSION[token.profession]].baseGatheringAmt}
                </Typography>
              </Typography>
              <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                +{PROFESSIONS[STAT_PROFESSION[token.profession]].levelBonus * 100}%
              </Typography>
            </Box>
          </>)}

          {((title?.type !== "STR" && title?.type !== "INT" && title?.type !== "DEX") && (stat.total !== 0)) && (<>
            <Divider sx={styles.cardDivider} />
            <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Stats Effect</Typography>

            <Box sx={styles.attributesRow} >
              <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                {title?.professionBonus.attrTitle}
              </Typography>
              <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                {title?.professionBonus.attrBonus! > 0 && '+'}{title?.professionBonus.attrBonus ? title?.professionBonus.attrBonus * stat.total : 0}{title?.professionBonus.suffix ?? '%'}
              </Typography>
            </Box>
          </>)}
        </Box >
      );
    }
  }


  // TODO Mission 2 Phase 3 Metazoa xp
  let progressPercentage = 0;
  if (!!token?.exp) {
    const {
      xpGained,
      xpRequired
    } = token.exp;

    progressPercentage = (xpGained / xpRequired) * 100;
  }

  const containerRefStat = useRef(null);

  return (
    <Grow
      mountOnEnter
      unmountOnExit
      in={true}
      style={{ transformOrigin: '0 0 0' }}
      {...(true ? { timeout: 1000 } : {})}
    >
      <Box
        sx={styles.nftViewWrapper}
        {...rest}
      >
        <Box sx={combineStyles(styles.nftWrapper, { ...(!panelContent) && styles.nftTransition }, { ...(extendedPanelContent && !!token.profession) && styles.nftTransition2 })} >
          {!!panelContent && (<>
            <Box sx={styles.statDetailsWrapper} ref={containerRefStat}>
              <Slide direction="right" in={true} timeout={1000} container={containerRefStat.current}>
                <Box sx={styles.statDetailsContainer}>
                  <StatPanel>
                    {getStatContent()}
                  </StatPanel>
                </Box>
              </Slide>
            </Box>
          </>)}
          <Box sx={styles.nftImageContainer}>
            <Box sx={styles.tabButtonBg}>
              <Box sx={styles.tabButton}>
                {token.attributes?.[0]?.value === 'Ursa' ? <UrsaTabIcon width="40px" height="40px" /> : <MinoTabIcon width="40px" height="40px" />}
              </Box>
            </Box>

            <Box width={'100%'} borderRadius={4}>
              <Box sx={combineStyles(styles.levelProfessionRow, { "*": { paddingY: 0, } })}>
                <Box sx={combineStyles(styles.levelColumn, styles.column)} border={0}>
                  <Typography variant="body1" color="secondary.main" sx={styles.levelProfessionText}>#{token.id}</Typography>
                </Box>

                {/* LOCATION */}

              </Box>
              <Box sx={styles.levelProfessionRow}>
                <Box sx={combineStyles(styles.levelColumn, styles.column, { paddingY: '5px' })}>
                  <Typography variant="body1" color="secondary.main" sx={styles.levelProfessionText}>Metazoa Level</Typography>
                  <Typography variant="body1" color="success.main" sx={styles.levelProfessionText}>{1}</Typography>
                </Box>
                <Box sx={combineStyles(styles.column, styles.professionDetails)}>
                  <Typography variant="body1" color="secondary.main" sx={styles.levelProfessionText}>Profession</Typography>
                  <Box height={18} sx={styles.professionContent}>
                    {!!token?.profession ? (<>
                      <Box
                        component="img"
                        src={PROFESSIONS[STAT_PROFESSION[token.profession]].icon ?? MetazoaPreview}
                        height="18px"
                        width="18px"
                        onError={DEFAULT_SRC}
                        sx={styles.professionIcon}
                      />
                      <Typography variant="body1" color="success.main" sx={combineStyles(styles.levelProfessionText, {
                        display: 'inline-block',
                        ...LOCATION_TAGS[STAT_PROFESSION[token.profession]],
                      })}>{STAT_PROFESSION[token.profession]}</Typography>
                    </>) : (
                      <Typography variant="body1" color="success.main" sx={combineStyles(styles.levelProfessionText, styles.disabledText)}>Unassigned</Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* XP BAR */}
            <Box sx={styles.tokenImageWrapper}>
              <Box sx={styles.xpWrapper}>
                <Box sx={styles.xpContainer}>
                  <Typography variant="overline" color="secondary.main" sx={styles.xpLabel}>XP</Typography>
                  <Box sx={styles.progressBarWrapper}>
                    <Box sx={styles.xpProgress} width={`${progressPercentage}%`} />
                  </Box>
                  <Typography variant="overline" color="secondary.main" sx={styles.xpLabel}>
                    <Typography component="span" color="success.main" sx={styles.xpLabel}>{token.exp?.xpGained ?? 0}</Typography>
                    /{token.exp?.xpRequired ?? 50}
                  </Typography>
                </Box>
              </Box>
              <Box
                component="img"
                src={token.image}
                width="97%"
                height="97%"
                sx={styles.nftImage}
                onError={DEFAULT_SRC} />
            </Box>
          </Box>

          <Box sx={styles.centerCol}>
            {/* STATS PANEL */}
            <Box sx={combineStyles(styles.statPanelFrame, { borderBottom: 'none', })}>
              <Box sx={styles.statPanelContent}>
                <Typography variant="overline" color="secondary.main" sx={combineStyles(styles.statTextSize, { paddingX: '8px', paddingTop: '4px' })}>Stats</Typography>
                <StatToggleGroup
                  orientation="vertical"
                  value={panelContent}
                  exclusive
                  onChange={handleChange}
                  sx={styles.statPanelContentGroup}
                >
                  {Object.entries(token.stats!).map(([stat, statDetail]) => (
                    <StatToggleButton value={stat} key={stat}>
                      <Box sx={styles.statCorner} />
                      <Box sx={styles.statRow}>
                        <Box sx={styles.statGroup}>
                          <Box
                            component="img"
                            src={(STATS[stat.toUpperCase()])?.icon ?? MetazoaPreview}
                            height="16px"
                            width="16px"
                            onError={DEFAULT_SRC}
                          />
                          <Typography variant="overline" color="primary" sx={styles.statLabel}>{stat}</Typography>
                        </Box>
                        <Typography variant="overline" color="success.main" sx={styles.statLabel}>{statDetail.total}</Typography>
                      </Box>
                    </StatToggleButton>
                  ))}
                </StatToggleGroup>
              </Box>
            </Box>

            {/* OTHERS PANEL */}
            <Box sx={combineStyles(styles.statPanelFrame, { maskImage: 'none', })}>
              <Box sx={combineStyles(styles.statPanelContent, { maskImage: 'none', })}>
                <Typography variant="overline" color="secondary.main" sx={combineStyles(styles.statTextSize, { paddingX: '8px', paddingTop: '4px' })}>Others</Typography>

                <StatToggleGroup
                  orientation="vertical"
                  value={panelContent}
                  exclusive
                  onChange={handleChange}
                  sx={styles.statPanelContentGroup}
                >
                  <StatToggleButton value="GenesisInfo" key="GenesisInfo" aria-label="Genesis Info">
                    <Box sx={styles.statCorner} />
                    <Box sx={styles.statRow}>
                      <Typography variant="overline" color="primary" sx={styles.statLabel}>Genesis info</Typography>
                    </Box>
                  </StatToggleButton>
                </StatToggleGroup>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grow >
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  professionDetails: {
    paddingY: '5px',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'center',
  },
  professionContent: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
  },
  professionIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '0.5em',
  },

  genesisTraits: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',

    "> *": {
      padding: '1px 6px'
    }
  },

  // SIDE PANEL FRAMES
  statPanelFrame: {
    minWidth: '144px',
    maskImage: `url(${StatPanelFrame})`,
    maskRepeat: 'no-repeat',
    maskSize: '100%',
    maskPosition: 'top',

    background: '#AEF1EE',
    // border: '1px solid #AEF1EE',
    borderLeft: 'none',
    borderRadius: '6px',
    padding: '1px',
    paddingLeft: '0px',
  },
  statPanelContent: {
    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    maskImage: `url(${StatPanelFrame})`,
    maskRepeat: 'no-repeat',
    maskSize: '100%',
    maskPosition: 'top',

    display: 'flex',
    flexDirection: 'column',
    placeContent: 'start',
    placeItems: 'start',
    gap: '0.15em',
    borderRadius: '6px',
  },
  statPanelContentGroup: {
    width: '100%',
    padding: '8px',
    borderTop: '1.5px solid #AEF1EE',
    borderRadius: '0',
    height: '100%',

    placeContent: 'start',
    placeItems: 'center',
    gap: '0.4em',

    "button": {
      margin: 0,
    }
  },

  frameBorder: {
    border: '12px double transparent',
    // backgroundClip: 'padding-box',
    borderImage: `url(${PanelFrame})`,
    borderImageSlice: '20',
    borderImageWidth: '20px 20px 20px 20px',
    borderImageOutset: '0px 0px 0px 0px',
    borderImageRepeat: 'stretch stretch',
    padding: '10px 20px 8px 56px',
    background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
  },

  nftViewWrapper: {
    width: '900px',
  },
  tabButtonBg: {
    clipPath: 'polygon(20% 0%, 100% 0, 99% 100%, 20% 100%, 0% 80%, 0% 20%)',
    position: 'absolute',
    top: '-1px',
    left: '-67px',
    padding: '1px',
    background: '#AEF1EE',
    width: '70px',
    zIndex: -1,
    borderRadius: '17px 0px 0px 17px',
  },
  tabButton: {
    clipPath: 'polygon(20% 0%, 100% 0, 100% 100%, 20% 100%, 0% 80%, 0% 20%)',
    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    padding: '10px 16px',
    // width: '80px',
    display: 'flex',
    borderRadius: '17px 0px 0px 17px',
  },
  nftWrapper: {
    display: 'flex',
    position: 'relative',
    transform: 'translate(-5px, 0px)',
    // transform: 'translate(-105px, 0px)',
    // transform: 'translate(-150px, 0px)',
    transitionDuration: '1s',
  },
  nftTransition: {
    transform: 'translate(100px, 0px)',
    transitionDuration: '1s',
  },
  nftTransition2: {
    // transform: 'translate(100px, 0px)',
    transform: 'translate(-150px, 0px)',
    transitionDuration: '1s',
  },
  nftImageContainer: {
    position: 'relative',
    height: '480px',
    width: '430px',
    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    borderRadius: '4px',
    border: '1px solid #AEF1EE',
    boxShadow: '-12px 18px 72px -32px rgba(174,241,238,1)',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  levelProfessionRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #AEF1EE',
  },
  column: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 16px'
  },
  levelColumn: {
    borderRight: '1px solid rgba(174, 241, 238, 0.4)',
  },
  levelProfessionText: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  disabledText: {
    color: 'rgba(174, 241, 238, 0.4)',
  },
  tokenImageWrapper: {
    position: 'relative',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
  floatingTextWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '8px',
  },
  floatingText: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    weight: 500,
  },
  xpWrapper: {
    padding: '1px',
    width: '332px',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    bottom: '-4%',
    textAlign: 'center',
    // background: '#AEF1EE',
    // clipPath: 'polygon(5% 0, 95% 0, 101% 50%, 95% 100%, 5% 100%, -1% 50%)',
    borderRadius: '18px',

    backgroundImage: `url(${MetazoaXPBar})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  },
  xpContainer: {
    width: '100%',
    padding: '4px 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    borderRadius: '14px',
    // clipPath: 'polygon(5% 0, 95% 0, 101% 50%, 95% 100%, 5% 100%, -1% 50%)',
  },
  xpLabel: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.125rem'
  },
  progressBarWrapper: {
    width: '200px',
    height: '18px',
    padding: '3px',
    borderRadius: '4px',
    outline: '1px solid #AEF1EE66'
  },
  xpProgress: {
    height: '12px',
    background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
    borderRadius: '4px'
  },
  nftImage: {
    borderRadius: '4px',
  },
  centerCol: {
    position: 'relative',
    "> *": {
      borderLeft: 'none!important',
    }
  },
  statsMenuHeaderWrapper: {
    background: '#AEF1EE',
    clipPath: 'polygon(0 0, 80% 0%, 97.5% 100%, 0 100%)',
    width: '144px',
    height: '25px',
    padding: '1px',
    paddingLeft: 0,
    paddingBottom: 0,
    borderRadius: '4px 0px 0px 0px',
  },
  statsMenuHeaderContainer: {
    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    clipPath: 'polygon(0 0, 81% 0%, 98% 100%, 0 100%)',
    width: '99%',
    height: '100%',
    padding: '3px',
    borderRadius: '4px 0px 0px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  statTextSize: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
  statsMenuContainer: {
    width: '140.5px',
    borderRadius: '0px 0px 4px 4px',
    border: '1px solid #AEF1EE',
    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    padding: '8px',
    paddingBottom: '0px',
  },

  statRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    borderRadius: '4px',
    padding: '4px 8px',
    clipPath: 'polygon(0 0, 100% 0, 100% 63%, 92% 100%, 0 100%)',
    position: 'relative',
  },
  statCorner: {
    width: '5px',
    height: '5px',
    display: 'block',
    padding: '5px',
    backgroundColor: '#AEF1EE',
    border: '2px solid #000006',
    position: 'absolute',
    bottom: '-2px',
    right: '-2px',
    borderRadius: '4px',
    clipPath: 'polygon(100% 27%, 27% 100%, 100% 100%)',
  },
  selectedOutlined: {
    border: '1px solid #AEF1EE',
  },
  statGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '16px',
  },
  statLabel: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.125rem',
    marginLeft: '5px',
  },
  toggleGroupContainer: {
    width: '100%',
  },
  statDetailsWrapper: {
    overflow: 'hidden',
    position: 'absolute',
    top: '25px',
    left: '536px',
  },
  extendedWrapper: {
    zIndex: '-10',
    top: '47px',
    left: '855px',
  },
  statDetailBg: {
    position: 'absolute',
    top: '2px',
    left: '2px',
    userSelect: 'none',
  },
  statDetailsContainer: {
    position: 'relative',
    minWidth: '319px',
    maxWidth: '370px',
  },
  statDetailsHeader: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    marginBottom: '8px',
  },
  settingsRadio: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: "start",
  },
  optionLabel: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeContent: "start",
    placeItems: 'center',
    // padding: '0 5%',
    gap: '8px',

    "& span": {
      fontSize: "12px",
      lineHeight: "24px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      whiteSpace: 'nowrap',
    }
  },
  topContainerWrapper: {
    padding: '1.5px',
    paddingBottom: '0px',
    background: 'linear-gradient(190deg, #AEF1EE, #000)',
    clipPath: 'polygon(87% 0, 93% 0, 100% 100%, 0 100%, 0 0, 76.5% 0, 78% 30%, 85.5% 30%)',
    backgroundImage: 'linear-gradient(190deg, #AEF1EE, #000)',
  },
  topContainer: {
    height: '24px',
    width: '100%',
    background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
    clipPath: 'polygon(87.5% 0, 93% 0, 100% 100%, 0 100%, 0 0, 76.5% 0, 78% 30%, 86% 30%)',
  },
  statDetailsContent: {
    // width: '100%',
    padding: '0px 20px 8px 56px',
    background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
    border: '1px solid #AEF1EE',
    borderTopStyle: 'none',
    borderBottomStyle: 'none',
    position: 'relative',

    width: '99.8%',
    borderLeft: '1px solid #AEF1EE',
  },
  bottomContainerWrapper: {
    borderLeft: '1px solid #AEF1EE',
    rotate: '180deg',
    padding: '1.5px',
    paddingBottom: '0px',
    background: 'linear-gradient(190deg, #AEF1EE, #000)',
    clipPath: 'polygon(87% 0, 93% 0, 100% 100%, 0 100%, 0 0, 76.5% 0, 78% 30%, 85.5% 30%)',
    backgroundImage: 'linear-gradient(190deg, #AEF1EE, #000)',
  },
  bottomContainer: {
    height: '24px',
    width: '100%',
    background: 'linear-gradient(90deg, #010025 0%, #000000 100%)',
    clipPath: 'polygon(87.8% 0, 93% 0, 100% 100%, 0 100%, 0 0, 76.5% 0, 78% 30%, 86.5% 30%)',
  },
  cardDivider: {
    background: 'rgba(174, 241, 238, 0.1)',
    marginY: '12px',
  },
  attributeBox: {
    width: '100%',
  },
  attributesRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',

    "> *": {
      padding: '1px 6px'
    }
  },
  totalRow: {
    marginTop: '16px',
  },
  attributesLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  value: {
    background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '12px',
    lineHeight: '18px',
  },
  traitType: {
    background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256) 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '12px',
    lineHeight: '18px',
    weight: 600,
  },

  confirmButton: {
    height: 50,
    flexGrow: 1,
    flexBasis: 0,
    width: '100%',
    borderRadius: '16px',
    margin: '27px 0 20px',

    // minWidth: 270,

    "&.MuiButton-root": {
      fontWeight: 700,
      fontSize: "18px",
      whiteSpace: "nowrap",
    },

    "@media (max-width:600px)": {
      width: '100%',
    }
  },

  professionBox: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'start',
    placeItems: 'center',
    gap: '0.5em',
    marginBottom: '8px',
  },

}

const StatToggleGroup = styled(ToggleButtonGroup)({
  '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    borderRadius: '4px',
  },
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderRadius: '4px',
  },
})

const StatToggleButton = styled(ToggleButton)({
  '&.Mui-selected, &:hover': {
    // background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
    outline: '1px solid #AEF1EE',
    boxShadow: "0 0px 24px rgb(174 241 238 / 60%)",
  },
  width: '100%',
  padding: '0px',
  background: 'transparent',
  display: 'flex',
  marginBottom: '8px',
})

export default MetazoaView;