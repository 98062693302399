import React, { useRef } from "react";
import { Box, styled } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useDispatch } from "react-redux";
import { actions } from "store";
import useRedux from "utils/useRedux";
import Texture from "assets/Texture.png";
import BetaWarning from "components/BetaWarning";
import {
  BlueEllipse, BlueLine, BlueShootingStar, MissionBlue,
  MissionRed, OrangeLine, PinkShootingStar, Smoke1a,
  Smoke1b,
  Smoke1c,
  Smoke1d
} from "../assets";
import BluePlanet from "../assets/BluePlanet.png";
import PinkPlanet from "../assets/PinkPlanet.png";
import { Footer, Header } from "./components";
import NavDrawer from "./components/NavDrawer";
import SnackbarContentItem from "./components/SnackbarContentItem";

const Main = styled("main")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  background: "linear-gradient(180deg, #010025 0%, #000000 100%)",
  display: "flex",
  flexDirection: "column",
  overflowX: "hidden",
  overflowY: "hidden",
  position: "relative",
  maxWidth: "100%",
}));

const SvgTextureBox = styled(Box)({
  position: "absolute",
  top: "0%",
  left: "0%",
  height: "100%",
  width: "100%",
  backgroundImage: `url(${Texture})`,
  backgroundRepeat: 'repeat',
  opacity: "60%",
  pointerEvents: 'none',
});

const PageLayout: React.FC = (props: any) => {
  const { children, ...rest } = props;
  const dispatch = useDispatch();
  const showNavDrawer = useRedux((state) => state.layout.showNavDrawer);
  const ref = useRef() as React.MutableRefObject<SnackbarProvider>;
  const onCloseDrawer = () => {
    dispatch(actions.Layout.toggleShowNavDrawer("close"));
  };
  return (
    <Main {...rest}>
      <SvgTextureBox />
      <SvgBox>
        <EllipseBox>
          <BlueEllipse />
        </EllipseBox>
        <Smoke1aBox>
          <Smoke1a width="250px" />
        </Smoke1aBox>
        <Smoke1bBox>
          <Smoke1b />
        </Smoke1bBox>
        <Smoke1cBox>
          <Smoke1c />
        </Smoke1cBox>
        <Smoke1dBox>
          <Smoke1d />
        </Smoke1dBox>
        <BlueShootingBox>
          <BlueShootingStar />
        </BlueShootingBox>
        <PinkShootingBox>
          <PinkShootingStar width="100%" />
        </PinkShootingBox>
        <BlueBox>
          <BlueLine />
        </BlueBox>
        <PinkPlanetBox>
          <img src={PinkPlanet} alt="pink-planet" width="100%" />
        </PinkPlanetBox>
        <BlueEllipseBox>
          <MissionBlue />
        </BlueEllipseBox>
        <RedEllipseBox>
          <MissionRed />
        </RedEllipseBox>
        <BluePlanetBox>
          <img src={BluePlanet} alt="blue-planet" />
        </BluePlanetBox>
        <OrangeBox>
          <OrangeLine />
        </OrangeBox>
      </SvgBox>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        ref={ref}
        content={(key, message) => {
          if (!message) return
          let msgContent = JSON.parse(message!.toString());
          const { hash, content } = msgContent;
          return (<SnackbarContentItem snackKey={key} message={content} hash={hash} providerRef={ref} />)
        }}
        maxSnack={3}
      >
        <Header />
        <BetaWarning />
        {children}
        <Footer />
        <NavDrawer open={showNavDrawer} onClose={onCloseDrawer} />
      </SnackbarProvider>
    </Main>
  );
};
const SvgBox = styled(Box)({
  pointerEvents: 'none',
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
});

const EllipseBox = styled(Box)({
  position: "absolute",
  pointerEvents: 'none',
});

const Smoke1aBox = styled(Box)({
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(0deg, #FFFFFF -0.13%, #C1C1C1 96.36%, #999999 100%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "221px",
  left: "71px",
  "@media (max-width:900px)": {
    top: "600px",
    left: "10px",
  },
});

const Smoke1bBox = styled(Box)({
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(0deg, #FFFFFF -0.13%, #C1C1C1 96.36%, #999999 100%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "-50px",
  left: "-4px",
  "@media (max-width:900px)": {
    display: "none",
  },
});

const Smoke1cBox = styled(Box)({
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(0deg, #FFFFFF -0.13%, #C1C1C1 96.36%, #999999 100%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "-90px",
  right: "-5px",
  "@media (max-width:600px)": {
    top: "-300px",
    right: "-150px",
  },
});

const Smoke1dBox = styled(Box)({
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(0deg, #FFFFFF -0.13%, #C1C1C1 96.36%, #999999 100%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "-100px",
  left: "450px",
  "@media (max-width:900px)": {
    top: "-100px",
    left: "20px",
  },
});

const BlueShootingBox = styled(Box)({
  pointerEvents: 'none',
  width: '79.46px',
  height: '55.46px',
  position: "absolute",
  top: "84px",
  left: "150px",
  "@media (max-width:900px)": {
    top: "100px",
    left: "40px",
    width: "40px",
  },
  "@media (max-width:600px)": {
    top: "50px",
    left: "20px",
    width: "40px",
  },
});

const PinkShootingBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  width: '140.85px',
  height: '94.34px',
  left: '1245.16px',
  top: '948px',
  "@media (max-width:600px)": {
    top: "270px",
    left: "280px",
    width: "70px",
  },
});

const BlueBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  left: '1311.48px',
  top: '541px',
  width: '80px',
  height: '80px',
  "@media (max-width:600px)": {
    top: "80px",
    left: "280px",
  },
});

const PinkPlanetBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  width: '109.79px',
  height: '110px',
  left: '1277px',
  top: '208px',
  zIndex: 0,
  "@media (max-width:600px)": {
    top: "80px",
    left: "300px",
    width: '50.79px',
  },
});

const BlueEllipseBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "600px",
  left: "-927px",
  width: "1496px",
  opacity: "40%",
  background: "radial-gradient(42.7% 52.61% at 58.59% 47.39%, #FFFFFF 1.56%, #469BFF 15.29%, #0445EB 35.94%, rgba(0, 0, 0, 0) 96.45%)",
  "@media (max-width:600px)": {
    left: '-120px',
    top: '408.94px',
    width: '423.44px',
    height: '423.15px',
  },
});

const RedEllipseBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "800px",
  right: "-927px",
  width: "1496px",
  opacity: "40%",
  background: "radial-gradient(42.41% 40.03% at 44.15% 51.87%, rgba(255, 255, 255, 0.734375) 0%, rgba(255, 122, 0, 0.734375) 16.67%, rgba(255, 2, 2, 0.479167) 56.9%, rgba(255, 0, 0, 0) 100%)",
  "@media (max-width:600px)": {
    right: '-300px',
    top: '308.94px',
    width: '423.44px',
    height: '423.15px',
  },
});

const BluePlanetBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "1500px",
  left: "60px",
  "@media (max-width:900px)": {
    display: "none",
  },
});

const OrangeBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "1220px",
  left: "130px",
  "@media (max-width:900px)": {
    display: "none",
  },
});
export default PageLayout;
