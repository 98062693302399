import { Box, BoxProps, IconButton, SxProps, Typography } from "@mui/material";
import { SwapIcon } from "assets";
import { useMemo, useState } from "react";
import { useRedux } from 'utils';
import { BIG_ONE } from 'utils/constants';
import { combineStyles } from 'utils/themeUtilities';
import { AppTheme, SimpleMap } from "utils/types";

const ExchangeRate: React.FC<BoxProps> = (props: BoxProps) => {
  const { sx, ...rest } = props;
  const exchangeRates = useRedux((state) => state.token.ExchangeRates);
  const rate = useMemo(() => exchangeRates.hunyPrice.gt(0) ? exchangeRates.zilPrice.div(exchangeRates.hunyPrice) : BIG_ONE, [exchangeRates]);
  const [isReverseRates, setIsReverseRates] = useState(false);
  return (
    <Box {...rest} sx={combineStyles(styles.root, sx)}>
      <Typography variant="body1" color="primary">Rate</Typography>
      <Typography variant="h3" color="primary" component="span">
        1 {isReverseRates ? "HUNY" : "ZIL"} = {(isReverseRates ? rate.pow(-1) : rate).toFixed(3)} {isReverseRates ? "ZIL" : "HUNY"}
        <IconButton color="primary" onClick={() => setIsReverseRates(!isReverseRates)}>
          <SwapIcon />
        </IconButton>
      </Typography>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}

export default ExchangeRate;
