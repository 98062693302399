import IconACC from "assets/icons/IconACC.svg";
import IconDEX from "assets/icons/IconDEX.svg";
import IconEND from "assets/icons/IconEND.svg";
import IconINT from "assets/icons/IconINT.svg";
import IconLUK from "assets/icons/IconLUK.svg";
import IconSPD from "assets/icons/IconSPD.svg";
import IconSTR from "assets/icons/IconSTR.svg";
import ProfessionAstrominerIcon from "assets/ProfessionAstrominerIcon.svg";
import ProfessionMarauderIcon from "assets/ProfessionMarauderIcon.svg";
import ProfessionPsionicIcon from "assets/ProfessionPsionicIcon.svg";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { MissionGroundLocation } from "utils/constants";

export enum StatType {
  STR = "Strength",
  INT = "Intellect",
  DEX = "Dexterity",
  LUK = "Luck",
  SPD = "Speed",
  END = "Endurance",
  ACC = "Accuracy",
}

export enum ProfessionTypes {
  "Marauder" = "Marauder",
  "Astrominer" = "Astrominer",
  "Psionic" = "Psionic",
}

export const STATS_ICONS = {
  STR: IconSTR,
  INT: IconINT,
  DEX: IconDEX,
  SPD: IconSPD,
  LUK: IconLUK,
  ACC: IconACC,
  END: IconEND,
}

export const PROFESSIONS_ICONS = {
  STR: ProfessionMarauderIcon,
  DEX: ProfessionAstrominerIcon,
  INT: ProfessionPsionicIcon,
}


export type STAT_TYPES = (keyof typeof StatType);

export type Stat = {
  type: STAT_TYPES | string;
  fullLength: StatType[keyof StatType];
  icon: string;
  galaxyGem: string;
  profession: ProfessionTypes | undefined;
  professionBonus: {
    attrTitle: string;
    attrBonus: number; // [x/100] in UI
    suffix?: string;
  }
}

export const STAT_PROFESSION: {
  [key in STAT_TYPES]?: ProfessionTypes
} = {
  STR: ProfessionTypes.Marauder,
  DEX: ProfessionTypes.Astrominer,
  INT: ProfessionTypes.Psionic,
}

export const STATS: {
  [key in STAT_TYPES]: Stat
} = {
  STR: {
    type: "STR",
    fullLength: StatType.STR,
    icon: IconSTR,
    galaxyGem: "Morganite",
    profession: STAT_PROFESSION.STR,
    professionBonus: {
      attrTitle: "Marauder Bonus",
      attrBonus: 3,
    }
  },
  INT: {
    type: "INT",
    fullLength: StatType.INT,
    icon: IconINT,
    galaxyGem: "Zolar",
    profession: STAT_PROFESSION.INT,
    professionBonus: {
      attrTitle: "Psionic Bonus",
      attrBonus: 3,
    }
  },
  DEX: {
    type: "DEX",
    fullLength: StatType.DEX,
    icon: IconDEX,
    galaxyGem: "Adamantite",
    profession: STAT_PROFESSION.DEX,
    professionBonus: {
      attrTitle: "Astrominer Bonus",
      attrBonus: 3,
    }
  },
  LUK: {
    type: "LUK",
    fullLength: StatType.LUK,
    icon: IconLUK,
    galaxyGem: "Peridot",
    profession: STAT_PROFESSION.LUK,
    professionBonus: {
      attrTitle: "Current Mastery",
      attrBonus: 1,
      suffix: ' Mastery',
    }
  },
  SPD: {
    type: "SPD",
    fullLength: StatType.SPD,
    icon: IconSPD,
    galaxyGem: "Axinite",
    profession: STAT_PROFESSION.SPD,
    professionBonus: {
      attrTitle: "Mastery Points Gained",
      attrBonus: 5,
    }
  },
  END: {
    type: "END",
    fullLength: StatType.END,
    icon: IconEND,
    galaxyGem: "Meteorite",
    profession: STAT_PROFESSION.END,
    professionBonus: {
      attrTitle: "Metazoa XP Gained",
      attrBonus: 5,
    }
  },
  ACC: {
    type: "ACC",
    fullLength: StatType.ACC,
    icon: IconACC,
    galaxyGem: "Tanzanite",
    profession: STAT_PROFESSION.ACC,
    professionBonus: {
      attrTitle: "Resource Delivery Fee",
      attrBonus: -0.5,
    }
  }
}

export const METAZOA_TRAITS = [
  "Gen",
  "Faction",
  "Class",
  "Berserker Level",
  "Medal of Valor",
  "Genesis HeadGear",
  "Genesis Armor",
  "Genesis Weapon",
  "Genesis Off-Hand",
  "Affinity",
];

export const PROFESSIONS = {
  "Marauder": {
    resource: "Zolranium Scraps",
    levelBonus: 0.03,
    baseGatheringAmt: 28,
    stat: "STR",
    missionGround: MissionGroundLocation.MoonBattleground,
    icon: ProfessionMarauderIcon,
    description: 'Thrives in Moon Battlegrounds. Best at gathering Zolranium Scraps.',
  },
  "Astrominer": {
    resource: "Geodes",
    levelBonus: 0.03,
    baseGatheringAmt: 28,
    stat: "DEX",
    missionGround: MissionGroundLocation.ZolarAsteroidBelt,
    icon: ProfessionAstrominerIcon,
    description: 'Thrives in Zolar Asteroid Belt. Best at mining Galaxy Geodes.',
  },
  "Psionic": {
    resource: "Elderberries",
    levelBonus: 0.03,
    stat: "INT",
    baseGatheringAmt: 10,
    missionGround: MissionGroundLocation.ElderWoodlands,
    icon: ProfessionPsionicIcon,
    description: 'Thrives in Elder Woodlands. Best at harvesting Elderberries.',
  },
}

export const TEXT_GRADIENT = {
  color: "transparent!important",
  backgroundClip: 'text!important',
  textFillColor: 'transparent!important',
}

export const PROFESSION_GRADIENT = {
  "Marauder": {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(98.34% 1474.26% at 1.66% 56.41%, #F7D9CC 0%, #CEA07F 84.38%, #97908D 100%)',
  },
  "Astrominer": {
    background: 'linear-gradient(146.53deg, #406CCA 16.62%, #A2BCF3 89.63%)',
  },
  "Psionic": {
    background: 'radial-gradient(135.02% 137.62% at 79.31% -25.15%, #FFC7FC 6%, #F471E8 48%, #7105C6 100%)',
  },
}

export const LOCATION_TAGS = {
  "Marauder": {
    ...TEXT_GRADIENT,
    ...PROFESSION_GRADIENT.Marauder,
  },
  "Astrominer": {
    ...TEXT_GRADIENT,
    ...PROFESSION_GRADIENT.Astrominer,
  },
  "Psionic": {
    ...TEXT_GRADIENT,
    ...PROFESSION_GRADIENT.Psionic,
  },
  "Base": {
    ...TEXT_GRADIENT,
    background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
  },
  "Mission": {
    ...TEXT_GRADIENT,
    background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)',
  },
  "Default": {
    ...GUILD_LIGHTGRAY_GRADIENT,
  }
}