import createSagaMiddleware from "redux-saga";
import blockchainSaga from "./app/blockchainSaga";
import guildSaga from "./app/guildSaga";
import hiveSaga from "./app/hiveSaga";
import metazoaSaga from "./app/metazoaSaga";
import refinerySaga from "./app/refinerySaga";
import zomgSaga from "./app/zomgSaga";

const sagaMiddleware = createSagaMiddleware();

export function startSagas() {
  sagaMiddleware.run(blockchainSaga)
  sagaMiddleware.run(hiveSaga)
  sagaMiddleware.run(refinerySaga)
  sagaMiddleware.run(metazoaSaga)
  sagaMiddleware.run(guildSaga)
  sagaMiddleware.run(zomgSaga)
};

export default sagaMiddleware;
