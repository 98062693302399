import { Box, Grid, Grow, SxProps } from "@mui/material";
import BigNumber from "bignumber.js";
import StripeHeader from "components/Game/components/StripeHeader";
import { GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { Guild, GuildBankInfo } from "store/types";
import { useRedux } from "utils";
import { Decimals } from "utils/constants";
import { bnOrZero } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import { BankInfo, DonateBank, GuildHive, IncreaseAllowance, RecentTransactions, WithdrawalRequest } from "./components";
import ApproveTaxCollection from "./components/ApproveTaxCollection";

export interface GuildProps {
  guild: Guild;
  bank: GuildBankInfo;
  canCollectTax: boolean;
  hasGuild: boolean;
  tabController: React.Dispatch<React.SetStateAction<number>>
}

const GuildBankTab: React.FC<GuildProps> = (props: GuildProps) => {
  const { guild, bank, canCollectTax, hasGuild, tabController } = props;

  const dispatch = useDispatch();
  const wallet = useRedux((state) => state.wallet.wallet);
  const profile = useRedux((state) => state.profile.profile);
  const allowanceStore = useRedux((state) => state.guild.allowances);

  const walletHolder = useMemo(() => {
    if (!guild || !wallet || !profile || !bank) return;
    if (!allowanceStore) {
      dispatch(actions.Guild.loadAllowances());
      return;
    }

    const addr = wallet.addressInfo.byte20!.toLocaleLowerCase()!;
    const { weeklyTax, membersApprovedTax } = bank;
    const isMember: boolean = profile.guildId === guild.id;

    const currentTax: BigNumber = bnOrZero(weeklyTax.initialAmt)
    let canAllowanceAfford: boolean = false;
    const bankAllowance = allowanceStore[addr]?.[bank.address];
    if (!!bankAllowance) {
      const allowance: BigNumber = bnOrZero(bankAllowance).shiftedBy(-Decimals.HUNY)
      canAllowanceAfford = allowance.gte(currentTax);
    }
    const memberApprovedTax: boolean = !!(membersApprovedTax && membersApprovedTax.find(t => t === addr))

    return { addr, isMember, canAllowanceAfford, memberApprovedTax }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, guild, bank, allowanceStore, profile])

  if (!guild || !bank) return null;
  return (
    <Box sx={styles.tabWrapper}>
      <StripeHeader sx={styles.bankHeader}>Guild Bank</StripeHeader>
      <Grid container spacing={2}>
        {/* increase allowance */}
        {walletHolder?.isMember && !walletHolder?.canAllowanceAfford && (
          <Grow
            unmountOnExit
            in={walletHolder?.isMember && !walletHolder?.canAllowanceAfford}
            style={{ transformOrigin: '0 0 0' }}
            {...(!!(walletHolder?.isMember && !walletHolder?.canAllowanceAfford) ? { timeout: 1000 } : {})}
          >
            <Grid item xs={12}>
              {<IncreaseAllowance guild={guild} bank={bank} />}
            </Grid>
          </Grow>
        )}

        {/* Approve tax collection */}
        <Grow
          unmountOnExit
          in={walletHolder?.isMember && walletHolder?.canAllowanceAfford && !walletHolder.memberApprovedTax}
          style={{ transformOrigin: '0 0 0' }}
          {...(!!(walletHolder?.isMember && walletHolder?.canAllowanceAfford && !walletHolder.memberApprovedTax) ? { timeout: 1000 } : {})}
        >
          <Grid item xs={12}>
            {walletHolder?.isMember && walletHolder?.canAllowanceAfford && !walletHolder.memberApprovedTax && <ApproveTaxCollection guild={guild} bank={bank} />}
          </Grid>
        </Grow>

        {/* withdrawal request */}
        {canCollectTax && !!bank?.pendingRequest && (
          <Grow
            unmountOnExit
            in={canCollectTax && !!bank.pendingRequest}
            style={{ transformOrigin: '0 0 0' }}
            {...(!!bank.pendingRequest ? { timeout: 1000 } : {})}
          >
            <Grid item xs={12}>
              <WithdrawalRequest guild={guild} bank={bank} />
            </Grid>
          </Grow>
        )}

        {/* guild items */}
        <Grid item xs={12}>
          <DonateBank guild={guild} bank={bank} canCollectTax={canCollectTax} />
        </Grid>

        {/* {walletHolder?.isMember && !!walletHolder?.canAllowanceAfford && (
          <Grow
            unmountOnExit
            in={walletHolder?.isMember && !!walletHolder?.canAllowanceAfford}
            style={{ transformOrigin: '0 0 0' }}
            {...(!!(walletHolder?.isMember && !!walletHolder?.canAllowanceAfford) ? { timeout: 1000 } : {})}
          >
            <Grid item xs={12}>
              <IncreaseAllowance guild={guild} bank={bank} />
            </Grid>
          </Grow>
        )} */}
        {/* guild magic hive share */}
        <Grid item xs={12}>
          <GuildHive guild={guild} canCollectTax={canCollectTax} tabController={tabController} />
        </Grid>

        {/* recent transactions */}
        <Grid item xs={12} lg={9}>
          <RecentTransactions />
        </Grid>

        {/* bank info */}
        <Grid item xs={12} lg={3}>
          <BankInfo guild={guild} canCollectTax={canCollectTax} hasGuild={hasGuild} />
        </Grid>
        {walletHolder?.isMember && !!walletHolder?.canAllowanceAfford && (
          <Grow
            unmountOnExit
            in={walletHolder?.isMember && !!walletHolder?.canAllowanceAfford}
            style={{ transformOrigin: '0 0 0' }}
            {...(!!(walletHolder?.isMember && !!walletHolder?.canAllowanceAfford) ? { timeout: 1000 } : {})}
          >
            <Grid item xs={12}>
              {<IncreaseAllowance guild={guild} bank={bank} decreaseAllowance={true} />}
            </Grid>
          </Grow>
        )}
      </Grid>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  tabWrapper: {
    background: 'linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, #000010 100%)',
    borderRadius: '16px',
    padding: '40px',
    border: '1px solid #AEF1EE33'
  },
  bankHeader: {
    marginBottom: '25px',
    textAlign: 'center',
    paddingY: '5px',
    transform: 'skewX(-25deg)',
    background: 'repeating-linear-gradient(90deg, #FFB60033, #FFB60033 36px, transparent 36px, transparent 44px)',
    "& h2": {
      ...GUILD_GRAY_GRADIENT,
      background: "linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)"
    }
  },
}

export default GuildBankTab;
