import { Box, CircularProgress, SxProps, Typography } from "@mui/material";
import MetazoaPreview from "assets/MetazoaPreview.gif";
import { Link } from "react-router-dom";
import { NftMetadata } from "store/types";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";
import useTaskSubscriber from "utils/useTaskSubscriber";

export interface Props {
  staked?: boolean;
  metazoaTokens: NftMetadata[];
}

const DisplayStaked: React.FC<Props> = (props: Props) => {
  const { staked, metazoaTokens, ...rest } = props;
  const [loadingTokens] = useTaskSubscriber("updateTokens");
  const stakedMetazoa = useRedux((state) => state.token.stakedMetazoa);


  const addDefaultSrc = (metadata: NftMetadata) => (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = MetazoaPreview;
  }

  return (
    <Box {...rest} sx={styles.container}>
      {loadingTokens
        ? <Box sx={styles.progressContainer}>
          <CircularProgress size={50} />
        </Box>
        : !!metazoaTokens.length
          ? <Box sx={styles.displayGrid}>
            {metazoaTokens.map((token) => {
              return (
                <Box key={token.id} sx={styles.metazoaWrapper}>
                  <Box
                    component="img"
                    src={token.image}
                    alt={`ZOA #${token.id}`}
                    sx={styles.zoaImage}
                    onError={addDefaultSrc(token)}
                  />
                  <Typography variant="body1" color="primary" sx={styles.tokenID}>{token.id}</Typography>
                </Box>
              );
            })}
          </Box>
          : <Box sx={styles.emptyState}>
            <Typography variant="body1" color="primary" mb="10px">{Object.keys(stakedMetazoa).length === 0 ? "No Metazoa found. Summon some to send for battle." : "Your Metazoa army has been sent out for battle."}</Typography>
            <Link to="/summon" style={{ textDecoration: "none" }}>
              <Typography variant="body1" color="primary" sx={styles.gradientText}>{Object.keys(stakedMetazoa).length === 0 ? "SUMMON NOW" : "SUMMON MORE"}</Typography>
            </Link>
          </Box>
      }
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    width: '100%',
    height: '160px',
    marginTop: '20px',
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  displayGrid: {
    maxHeight: '160px',
    paddingX: '20px',
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: 'repeat(8, 1fr)',
    rowGap: '20px',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  metazoaWrapper: {
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  zoaImage: {
    height: '45px',
    width: '45px',
    margin: '2.5px 2.5px 0 2.5px',
    borderRadius: '14px 14px 0px 0px',
  },
  tokenID: {
    borderTop: '2px solid #AEF1EE33',
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    textAlign: 'center',
    marginBottom: '1px',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 700,
  },
}

export default DisplayStaked;