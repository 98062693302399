import { useMemo } from "react";
import { Box, SxProps } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import BigNumber from "bignumber.js";
import { BlacklistAddresses, ContractsBech32 } from "utils/constants";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";
import { CONTRACTS } from "zilswap-sdk/lib/constants";
import StripeHeader from "../StripeHeader";
import RenderLeaderboard from "./RenderLeaderboard";
import { LeaderboardItem } from "./RenderLeaderboard/RenderLeaderboard";


const Leaderboard: React.FC = () => {
  const network = useRedux((state) => state.blockchain.network);
  const walletAddress = useRedux((state) => state.wallet.wallet?.addressInfo.bech32);
  const commanders = useRedux((state) => state.game.commanders);
  const tokenTraits = useRedux((state) => state.game.tokenTraits);
  const hunyHolders = useRedux((state) => state.game.hunyHolders);

  const omitHunyBalanceAddresses = useMemo(() => {
    return [
      CONTRACTS[network],
      ...BlacklistAddresses,
      ContractsBech32[network].Hive,
      ContractsBech32[network].HunyToken,
      ContractsBech32[network].MoonBattle,
      ContractsBech32[network].Refinery,
    ]
  }, [network])

  // compute huny leaderboard
  const {
    hunyRows,
    hunySelfRow,
    hunySelfRank,
  } = useMemo(() => {
    const hunyRows: LeaderboardItem[] = [];
    let hunySelfRow: LeaderboardItem | null = null
    for (const holder in hunyHolders) {
      const bech32Address = toBech32Address(holder);
      if (omitHunyBalanceAddresses.includes(bech32Address))
        continue;
      const entry = hunyHolders[holder];
      const row = {
        address: bech32Address,
        amount: entry,
      };
      hunyRows.push(row);

      if (toBech32Address(holder) === walletAddress)
        hunySelfRow = row;
    }

    hunyRows.sort((lhs, rhs) => rhs.amount.comparedTo(lhs.amount));
    const hunySelfRank = hunyRows.findIndex(row => row.address === walletAddress) + 1;

    return { hunyRows, hunySelfRow, hunySelfRank };
  }, [hunyHolders, walletAddress, omitHunyBalanceAddresses]);

  // compute ursa/mino leaderboard
  const {
    ursaRows,
    ursaSelfRow,
    ursaSelfRank,

    minoRows,
    minoSelfRow,
    minoSelfRank,
  } = useMemo(() => {
    const ursaRows: LeaderboardItem[] = [];
    const minoRows: LeaderboardItem[] = [];
    let ursaSelfRow: LeaderboardItem | null = null
    let minoSelfRow: LeaderboardItem | null = null

    if (!commanders || !tokenTraits) return {
      ursaRows, ursaSelfRow, ursaSelfRank: 0,
      minoRows, minoSelfRow, minoSelfRank: 0,
    }

    const ursaHolders: SimpleMap<number> = {};
    const minoHolders: SimpleMap<number> = {};

    for (const holder in commanders) {
      for (const tokenId of commanders[holder]) {
        const traits = tokenTraits[tokenId];

        if (traits?.faction_a === "mino") {
          minoHolders[holder] = (minoHolders[holder] ?? 0) + 1;
        } else {
          ursaHolders[holder] = (ursaHolders[holder] ?? 0) + 1;
        }
      }
    }

    for (const holder in ursaHolders) {
      const entry = ursaHolders[holder];
      const row = {
        address: toBech32Address(holder),
        amount: new BigNumber(entry),
      };
      ursaRows.push(row);

      if (toBech32Address(holder) === walletAddress)
        ursaSelfRow = row;
    }

    for (const holder in minoHolders) {
      const entry = minoHolders[holder];
      const row = {
        address: toBech32Address(holder),
        amount: new BigNumber(entry),
      };
      minoRows.push(row);

      if (toBech32Address(holder) === walletAddress)
        minoSelfRow = row;
    }

    ursaRows.sort((lhs, rhs) => rhs.amount.comparedTo(lhs.amount));
    minoRows.sort((lhs, rhs) => rhs.amount.comparedTo(lhs.amount));
    const ursaSelfRank = ursaRows.findIndex(row => row.address === walletAddress) + 1;
    const minoSelfRank = minoRows.findIndex(row => row.address === walletAddress) + 1;

    return {
      ursaRows, ursaSelfRow, ursaSelfRank,
      minoRows, minoSelfRow, minoSelfRank,
    };
  }, [tokenTraits, commanders, walletAddress]);

  return (
    <Box sx={styles.leaderboardWrapper}>
      <Box sx={styles.leaderboardContainer}>
        <StripeHeader>LEADERBOARD</StripeHeader>
        <Box sx={styles.leaderboardRow}>
          <RenderLeaderboard rows={hunyRows} selfRank={hunySelfRank} selfRow={hunySelfRow} headerText={"HUNY HOLDERS"} />
          <RenderLeaderboard rows={ursaRows} selfRank={ursaSelfRank} selfRow={ursaSelfRow} headerText={"URSA COMMANDERS"} />
          <RenderLeaderboard rows={minoRows} selfRank={minoSelfRank} selfRow={minoSelfRow} headerText={"MINO COMMANDERS"} />
        </Box>
      </Box>
    </Box>
  );
}


const styles: SimpleMap<SxProps<AppTheme>> = {
  leaderboardWrapper: {
    minWidth: '720px',
    marginTop: '40px',
    position: 'relative',
    zIndex: 2,
    "@media (max-width:1200px)": {
      marginTop: '20px',
    },
  },
  leaderboardContainer: {
    width: '100%',
    borderRadius: '16px',
    border: '1px solid #AEF1EE33',
    background: 'linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, #000010 100%)',
    padding: "40px",
    minHeight: '400px',

  },
  leaderboardRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: '20px',
    overflow: 'auto',
  },
}
export default Leaderboard;
