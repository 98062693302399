import { Box, Button, Container, styled, SxProps, Typography } from "@mui/material";
import { MissionBlue, MissionLine, MissionRed } from "assets";
import ElderberryBadgeIcon from "assets/ElderberryBadgeIcon.svg";
import GeodeBadgeIcon from "assets/GeodeBadgeIcon.svg";
import BadgesComb from 'assets/homepage/BadgesComb.svg';
import ProfessionAstrominerIcon from "assets/ProfessionAstrominerIcon.svg";
import ProfessionMarauderIcon from "assets/ProfessionMarauderIcon.svg";
import ProfessionPsionicIcon from "assets/ProfessionPsionicIcon.svg";
import ScrapsBadgeIcon from "assets/ScrapsBadgeIcon.svg";
import React from "react";
import { theme } from "theme";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";

const CurrentMission: React.FC = () => {

  return (
    <Container maxWidth="xl" sx={styles.container} component="section">
      <SvgBox component="aside" id="SVG-Mission-Current">
        <StarBox2>
          <MissionRed />
        </StarBox2>
        <StarBox3>
          <MissionBlue />
        </StarBox3>
        <StarBox>
          <MissionBlue />
        </StarBox>
        {/* <BackgroundStarBox>
          <MissionStars />
        </BackgroundStarBox>
        <Smoke2bBox>
          <Smoke2b />
        </Smoke2bBox>
        <Smoke2aBox>
          <Smoke2a />
        </Smoke2aBox> */}
      </SvgBox>
      <Box maxWidth="xl" sx={styles.contentBox} component="main">
        <Box
          width={"100%"}
          sx={styles.textColumn}
          textAlign="center"
        >
          <Typography
            variant="overline"
            color="secondary.main"
            sx={styles.subheader}
          >
            Current Mission
          </Typography>
          <Typography variant="h1" color="primary">
            The Right to Bear Arms
          </Typography>
          <MissionLineBox>
            <MissionLine width="100%" />
          </MissionLineBox>
          <Typography width={{ xs: "100%", sm: "90%", md: "80%", }} component={"span"} variant="body1" color="primary" textAlign="center">
            Build your own unstoppable team of Metazoas and embark on Profession Quests! Gather and refine resources to gain a huge bear-vantage in the upcoming battle. Level-up your Metazoas and form the strongest battalion in Zolar!
          </Typography>
          <Typography variant="h2" color="primary" sx={styles.header2}>
            Preparation is key before battle
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            target="_blank"
            href="https://docs.zolar.io/missions/mission-ii-the-right-to-bear-arms-new"
            sx={{ zIndex: 100 }}
          >
            <Typography variant="button" sx={styles.gradientText}>Whitepaper</Typography>
          </Button>
        </Box>
        <Box width="100%" sx={combineStyles(styles.missionCol, {
          padding: "16px 0px!important",
        })}>
          <FrameBox>
            <img src={ProfessionAstrominerIcon} alt="profession-icon" style={{ top: '1.5em' }} />
            <img src={ProfessionMarauderIcon} alt="profession-icon" />
            <img src={ProfessionPsionicIcon} alt="profession-icon" style={{ bottom: '1.5em' }} />
          </FrameBox>
          <FrameBox sx={{
            transform: 'scale(-1, 1)',
          }}>
            <img src={GeodeBadgeIcon} alt="profession-icon" style={{ top: '1.5em' }} />
            <img src={ScrapsBadgeIcon} alt="profession-icon" />
            <img src={ElderberryBadgeIcon} alt="profession-icon" style={{ bottom: '1.5em' }} />
          </FrameBox>

        </Box>
      </Box >
    </Container >
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    padding: "0px!important",

    // //DEBUG: Remove this
    // border: '1px solid yellow',
    // "*": {
    //   border: '1px solid red',
    // }
    "*": {
      zIndex: 1,
    }
  },
  contentBox: {
    position: 'relative',
    padding: "76px 0px 100px",
    "@media (min-width:600px)": {
      padding: "40px 0px 80px",
    },
  },
  textColumn: {
    mx: 'auto',
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    gap: 4,
  },
  subheader: {
    textTransform: "uppercase",
    letterSpacing: "0.24em",
  },
  missionCol: {
    // background:'green',
    position: 'absolute',
    top: '30%',
    left: '-8%',
    width: '115%',

    display: "flex",
    flexDirection: "row",
    placeItems: "center",
    placeContent: "space-between",
    padding: "16px 0px",
    "@media (min-width:600px)": {
      padding: "120px 0px 80px",
    },

    [theme.breakpoints.down('lg')]: {
      // display: 'none',
      // background: 'red',
      position: 'relative',

      height: '40vw',
      minHeight: '300px',

      backgroundImage: `url(${BadgesComb})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      marginY: '3em !important',

      "> *": {
        display: 'none',
      }
    },
  },
  buttonGradient: {
    WebkitTextFillColor: '#000',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
};

const SvgBox = styled(Box)({
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
  height: "100%",
  // pointerEvents: 'none',
});

const StarBox = styled(Box)({
  pointerEvents: 'none',
  background:
    "radial-gradient(52.57% 52.61% at 58.59% 47.39%, rgba(255, 237, 225, 0.7) 2.08%, rgba(204, 157, 126, 0.7) 17.19%, rgba(188, 127, 104, 0.5) 31.77%, rgba(0, 0, 0, 0) 96.45%)",
  position: "absolute",
  top: "-100px",
  left: "-400px",
  width: "1000px",
  height: "1000px",
  opacity: "20%",
  "@media (max-width:600px)": {
    display: "none",
  },
  "@media (max-width:900px)": {
    left: "-800px",
  },
});

const StarBox2 = styled(Box)({
  pointerEvents: 'none',
  background:
    " radial-gradient(52.57% 52.61% at 58.59% 47.39%, #FFE0FC 1.56%, #F597EC 13.02%, #9F30CE 43.75%, rgba(0, 0, 0, 0) 96.45%)",
  position: "absolute",
  top: "-200px",
  left: "500px",
  width: "1000px",
  height: "1000px",
  opacity: "20%",
  "@media (max-width:600px)": {
    display: "none",
  },
  "@media (max-width:900px)": {
    right: "-800px",
  },
});

const StarBox3 = styled(Box)({
  pointerEvents: 'none',
  background: 'radial-gradient(42.7% 52.61% at 58.59% 47.39%, #FFFFFF 1.56%, #469BFF 15.29%, #0445EB 35.94%, rgba(0, 0, 0, 0) 96.45%)',
  position: "absolute",
  top: "0px",
  left: "100px",
  width: "1000px",
  height: "1000px",
  opacity: "20%",
  "@media (max-width:600px)": {
    display: "none",
  },
  "@media (max-width:900px)": {
    right: "-800px",
  },
});


const MissionLineBox = styled(Box)({
  maxWidth: '320px',
  width: "30%",
  "@media (max-width:600px)": {
    width: "50%",
  },
});

const FrameBox = styled(Box)({
  pointerEvents: 'none',
  // position: 'absolute',
  width: "15%",

  "> *": {
    height: '130px',
    "&:nth-of-type(odd)": {
      position: 'relative',
      left: '3rem',
    },
  },

  "@media (max-width:600px)": {
    display: "none",
  },
});




export default CurrentMission;
