import BigNumber from "bignumber.js";
import { Profile, ProfileInfo, HivePoolStats } from "store/types";

export interface GuildState {
  guild?: Guild;
  allGuilds?: Guild[],
  bank?: GuildBankInfo,
  allowances?: Allowance,
  transactions: GuildTransactions,
  bankTaxes: TaxByWeek[]
}

export interface AssetData {
  design: number;
  colour: string;
  name?: string;
}

export interface PaginationInfo {
  offset?: number;
  count?: number;
  limit?: number;
}

export enum ControlMode {
  CaptainOnly = "CaptainOnly",
  CaptainAndOneOfficer = "CaptainAndOneOfficer",
  CaptainAndTwoOfficers = "CaptainAndTwoOfficers"
}

export interface GuildBankSetting {
  joinFee: BigNumber;
  joinCaptainAllocation: number;
  joinOfficerAllocation: number;
  taxFee: BigNumber;
  taxCaptainAllocation: number;
  taxOfficerAllocation: number;
  controlMode: ControlMode;

  updateReason: string;
}
export interface GuildBank {
  joiningFee: {
    initialAmt: BigNumber,
    captainAllocation: number,
    officerAllocation: number
  },
  weeklyTax: {
    initialAmt: BigNumber,
    captainAllocation: number,
    officerAllocation: number,
  },
  controlMode: string;
  currentEpoch: number;
  initialEpoch: number;
}

export interface PendingRequest {
  validSigners: string[];
  initiator: string;
  type: string;
  msg: string;
  params: any;
}
export interface GuildBankInfo extends GuildBank {
  guildHiveStats?: HivePoolStats;
  id?: number;
  balance: BigNumber;
  tokens: GuildTokens;
  address: string;
  initialEpoch: number;
  currentEpoch: number;
  netWorth?: GuildTokensValuation;
  pendingRequest?: PendingRequest;
  taxes?: TaxByWeek[];
  exMembers?: ProfileInfo[];
  membersApprovedTax?: string[];
}

export interface GuildTokens {
  zilToken: BigNumber;
  hunyToken: BigNumber;
}

export interface GuildTokensValuation extends GuildTokens {
  zilTokenAmt: number;
  hunyTokenAmt: number;
  valuation: number;
}

export interface Guild {
  id: number;
  name: string;
  slug: string;
  description: string;
  leaderAddress: string;
  commanderAddresses: string[];
  guildLevel: number;
  guildCrestId: number;
  guildCrest: AssetData;
  guildSigil: AssetData;
  guildBanner: AssetData;
  maxCapacity: number;
  currentSize: number;
  crestImageUrl: string | null;
  crestImageId: number | null;
  members: Profile[];
  membersTotalLp?: BigNumber;
  totalHunyHarvested?: number;
  totalHunyHolding: BigNumber;
  totalUrsa: number;
  totalMino: number;
  totalUrsaKidnap?: number;
  totalMinoKidnap?: number;
  totalSummon?: number;
  meta: PaginationInfo;
  totalBear: number;
  strength: number;
  guildBank: GuildBankInfo | null;
}

export enum Status {
  Created = "created",
  Accepted = "accepted",
  Rejected = "rejected",
}

export interface Request {
  sender: string;
  guildId: number;
  status: Status;
  user: Profile;
}

export interface TaxToCollect {
  memberAddress: string;
  epochNumber: number;
}

export interface Tax {
  epochNumber: number;
  date: Date;
  membersPayingAmt: BigNumber;
  status: TaxStatus;
}

export interface TaxByWeek extends Tax {
  taxesOwed?: { [memberAddress: string]: number };
  taxesCollected?: { [memberAddress: string]: number };
}

export interface Action<T> {
  payload: T;
  type: string;
}

export enum TaxStatus {
  PendingCollection = 0,
  Collected = 1
}
export interface Allowance {
  [from: string]: { [to: string]: string }
}

export interface Transaction {
  id: number;
  txHash: string;
  bankAddress: string;
  type: TransactionType;
  initiator?: ProfileInfo;
  initiatorAddress: string;
  signature: string;
  status: string;
  tokens: TransactionToken[];
  message: string;
  timestamp: string | null;
}

export interface GuildTransactions {
  transactionsList: Transaction[],
  exMembers: ProfileInfo[]
}

export enum TransactionType {
  PaidJoiningFee = "PaidJoiningFee",
  CollectTax = "CollectTax",
  ClaimHive = "ClaimHive",
  ClaimRefinery = "ClaimRefinery",
  MakeDonation = "MakeDonation",
  UpdateBankSettings = "UpdateBankSettings",
  Withdraw = "Withdraw",
  DepositHive = "DepositHive",
  WithdrawHive = "WithdrawHive",
  ToRefinery = "ToRefinery",
}

export interface TransactionToken {
  amount: number;
  address: string;
}