import { MissionGroundLocation } from "utils/constants";

export type NavRoute = {
  label?: string;
  route: string;
  isExternal?: boolean;
};

export const NAVIGATION: ({
  subMenu?: NavRoute[]
} & NavRoute)[] = [
    {
      label: 'Home',
      route: '/',
    },
    {
      route: '/explore',
    },
    {
      label: 'Game 🔥',
      route: '/map',
    },
    {
      label: 'Stations',
      route: '',
      subMenu: [
        {
          label: 'Z.O.M.G. Store',
          route: `/map/${MissionGroundLocation.ZOMGStore}`,
        },
        {
          label: 'Magic Hive',
          route: '/hive',
        },
        {
          label: 'Refinery',
          route: '/refinery',
        },
        {
          label: 'Guild Hall',
          route: '/guilds',
        },
      ],
    },
    {
      label: 'Archives',
      route: '',
      subMenu: [
        {
          label: 'Summon Portal',
          route: '/summon',
        },
        {
          label: 'Moonbattle',
          route: '/game',
        },
      ],
    },
    {
      label: 'Whitepaper',
      route: 'https://docs.zolar.io/',
      isExternal: true,
    }
  ];

export const DISCORD_INVITE = "https://discord.gg/73SDdgF6gY";
