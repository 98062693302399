import { Box, Button, Link, styled, SvgIcon, SxProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { BaseButton, ExternalLink, Huny, WarningRed } from "assets";
import HunyLoading from "assets/HunyLoading.gif";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { waitForTx } from "core/utilities";
import { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { actions } from "store";
import { Guild } from "store/types";
import { TBMConnector } from "tbm";
import { useToaster } from "utils";
import { BIG_ONE } from "utils/constants";
import { getExplorerLink } from "utils/strings/links";
import { bnOrZero, toHumanNumber } from "utils/strings/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from "utils/useNetwork";
import useRedux from "utils/useRedux";

export interface ModalProps {
  totalHuny: number;
  percentageComplete: number;
  block: number;
  open: boolean;
  onClose: () => void;
  guild: Guild
}

const ClaimHunyDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, totalHuny, percentageComplete, block, guild } = props;
  const { guildBank } = guild;

  const wallet = useRedux((state) => state.wallet.wallet);
  const refineryHunyStats = useRedux((state) => state.token.refineryHunyStats);
  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();
  const [claimTxHash, setClaimTxHash] = useState<string>("");
  const [claimHunyPotComplete, setClaimHunyPotComplete] = useState<boolean>(false);
  const [runClaim, loadingClaim] = useAsyncTask("claim", (error) => {
    toaster(error?.message ?? "Error Claiming");
  });

  const claimCompletion = Math.min(percentageComplete, 1);
  const refineryBoost = useMemo(() => {
    let boost = BIG_ONE;
    if (refineryHunyStats?.totalShare?.gt(0) && refineryHunyStats?.totalSupply) {
      boost = refineryHunyStats.totalSupply.div(refineryHunyStats.totalShare);
    };

    return boost;
  }, [refineryHunyStats])

  const totalPotBalance = bnOrZero(totalHuny).times(refineryBoost).shiftedBy(-12).toNumber();
  const totalLoss = Math.abs((100 - claimCompletion * 100) * totalPotBalance / 100);
  const totalClaim = totalPotBalance - totalLoss;

  const handleClaim = () => {
    runClaim(async () => {
      await claim();
    })
  }

  const claim = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!guildBank || !guildBank?.address) return;

    if (block) {
      try {
        const claimTx = await TBMConnector.claimGuildRefinery(toBech32Address(guildBank.address!), block);
        toaster(`Submitted Guild Refinery Claim `, { hash: claimTx.id! });
        if (claimTx.isRejected() || !claimTx.id) throw new Error("Submitted transaction was rejected.");

        const tx = await waitForTx(claimTx.id);
        const tbmConnector = TBMConnector.getSDK();
        const txn = await tbmConnector.zilliqa.blockchain.getTransaction(claimTx.id);
        const receipt = txn.getReceipt();
        if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

        setClaimTxHash(claimTx.id);
        setClaimHunyPotComplete(true);
        dispatch(actions.Token.reloadHivePool());
        dispatch(actions.Token.reloadRefineryHunyStats)
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const handleOnClose = () => {
    if (loadingClaim) return;

    setClaimTxHash("");
    setClaimHunyPotComplete(false);
    onClose();
  }

  const getHeader = () => {
    if (claimHunyPotComplete && claimTxHash) {
      return "Claim Huny Pot Success!";
    } else if (loadingClaim) {
      return "Claiming HUNY Pot...";
    } else {
      return "Harvest HUNY";
    }
  }

  const modalContent = () => {
    if (claimHunyPotComplete && claimTxHash) {
      return (
        <Fragment>
          <Box sx={styles.contentBox} style={{ marginTop: 0 }}>
            {/* Qty */}
            <Box display="flex" alignItems="center" mt="20px">
              <Typography color="primary" variant="body1" sx={{ whiteSpace: 'nowrap' }}>You have successfully claimed {toHumanNumber(totalClaim)} HUNY!</Typography>
            </Box>

            {/* Tx hash */}
            <Link
              target="_blank"
              href={getExplorerLink("tx", claimTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>

            {/* Visit hive */}
            <Button
              component={RouterLink}
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={styles.hiveButton}
              to="/hive"
            >
              <BaseButtonBox>
                <BaseButton height="100%" />
              </BaseButtonBox>
              Visit Hive
            </Button>

            {/* Visit refinery */}
            <Button
              component={RouterLink}
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={styles.refineryButton}
              to="/refinery"
            >
              <BaseButtonBox>
                <BaseButton height="100%" />
              </BaseButtonBox>
              Visit Refinery
            </Button>
          </Box>
        </Fragment>
      );
    }

    if (loadingClaim) {
      return (
        <Fragment>
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={HunyLoading}
            alt={"loading gif"}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Box sx={styles.warningBox}>
          <WarningRed height="30px" width="30px" />
          <Typography variant="body1" color="primary" component="span" sx={styles.warningTextWrapper}>
            <Typography variant="body1" sx={{ fontWeight: 600 }} component="span">BEWARE: </Typography>
            Selected pot contains unrefined HUNY. Confirm claiming will
            result in lost of unrefined HUNY and send this amount back to Magic Hive.
            <br /><Link target="_blank" href="https://docs.zolar.io/overview/refinery">Learn More</Link>
          </Typography>
        </Box>
        <Box sx={styles.hunyBalanceWrapper}>
          <Box sx={styles.hunyBalanceRow}>
            <Typography variant="body1" color="primary">Total Pot Balance (100%)</Typography>
            <Typography variant="h3" color="primary" component="span" sx={styles.balanceGroup}>{toHumanNumber(totalPotBalance)}&nbsp;&nbsp;<Huny width="28px" height="28px" /></Typography>
          </Box>
          <Box sx={styles.hunyBalanceRow}>
            <Typography variant="body1" color="primary">Total Unrefined HUNY (Loss)</Typography>
            <Typography variant="h3" color="primary" component="span" sx={styles.balanceGroup}>-{toHumanNumber(totalLoss)}&nbsp;&nbsp;<Huny width="28px" height="28px" /></Typography>
          </Box>
          <Box sx={styles.hunyBalanceRow}>
            <Typography variant="body1" color="primary">Total HUNY Claiming ({(claimCompletion * 100).toFixed(2)}%)</Typography>
            <Typography variant="h3" color="success.main" component="span" sx={styles.balanceGroup}>{toHumanNumber(totalClaim)}&nbsp;&nbsp;<Huny width="28px" height="28px" /></Typography>
          </Box>
        </Box>
        <ContainedButton onClick={() => handleClaim()}>Confirm Claim</ContainedButton>
      </Fragment>
    );
  }
  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal}>
      {modalContent()}
    </DialogModal>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  warningBox: {
    width: '100%',
    padding: '20px',
    paddingRight: '40px',
    borderRadius: '16px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  warningTextWrapper: {
    width: '93%',
  },
  hunyBalanceWrapper: {
    marginY: '40px',
    width: '100%',
  },
  hunyBalanceRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  balanceGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  hiveButton: {
    marginTop: 0,
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  refineryButton: {
    marginTop: "10px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "40px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
}

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    display: "none",
  },
});

export default ClaimHunyDialog;
