import { Box, SxProps } from "@mui/material";
import RefineryBanner from "assets/RefineryBanner.png";
import StripeHeader from "components/Game/components/StripeHeader";
import { GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { Guild } from "store/types";
import { AppTheme, SimpleMap } from "utils/types";
import HunyOverview from "./components/HunyOverview";
import HunyPots from "./components/HunyPots";

export interface GuildProps {
  guild: Guild
}

const RefineryTab: React.FC<GuildProps> = (props: GuildProps) => {
  const { guild } = props;

  return (
    <Box sx={styles.tabWrapper}>
      <StripeHeader>Refinery</StripeHeader>
      <Box sx={styles.bannerWrapper} />
      <HunyOverview guild={guild} />
      <HunyPots guild={guild} />
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  tabWrapper: {
    background: 'linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, #000010 100%)',
    borderRadius: '16px',
    padding: '40px',
    border: '1px solid #AEF1EE33',
    "& h2": {
      ...GUILD_GRAY_GRADIENT,
      background: "linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)"
    }
  },
  bankHeader: {
    marginBottom: '25px',
    textAlign: 'center',
    paddingY: '5px',
    transform: 'skewX(-25deg)',
    background: 'repeating-linear-gradient(90deg, #FFB60033, #FFB60033 36px, transparent 36px, transparent 44px)',
  },

  bannerWrapper: {
    marginTop: '30px',
    width: '100%',
    height: '320px',
    backgroundImage: `url(${RefineryBanner})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '16px',
    border: '1px solid #AEF1EE33',
  }
}

export default RefineryTab;
