import { Box, Button, CircularProgress, Divider, FormHelperText, IconButton, Link, SvgIcon, SxProps, TextField, Typography } from "@mui/material";
import { AddIcon, ExternalLink, Huny, RemoveIcon, WarningRed } from "assets";
import ItemFrame from 'assets/ItemFrame.svg';
import ElderberryJuiceGlow from "assets/quests/Locations/ElderberryJuiceGlow.svg";
import ElderberryResource from "assets/quests/Locations/ElderberryResource.svg";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { logger, waitForTx } from "core/utilities";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { TBMConnector } from "tbm";
import { theme } from "theme";
import { useAsyncTask, useRedux, useTaskSubscriber, useToaster } from "utils";
import { BIG_ZERO, Decimals, MissionGroundResource } from "utils/constants";
import { bnOrZero, formatIntegerNumber, getExplorerLink, toHumanNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

interface ResourceModalProps extends ModalProps {
  resourceType: keyof typeof MissionGroundResource;
}

const initialFormState = {
  juice: BIG_ZERO,
  craftCost: BIG_ZERO,
}

const RefineElderberryDialog: React.FC<ResourceModalProps> = ({
  open,
  onClose,
  resourceType = 'Elderberries',
}: ResourceModalProps) => {

  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();

  const wallet = useRedux((state) => state.wallet.wallet)
  const tokenState = useRedux((state) => state.token);
  const elderberryJuice = useRedux((state) => state.quest.refinementFees.elderberryJuice);

  const {
    resources,
    HunyTokens,
  } = tokenState;
  const {
    [MissionGroundResource[resourceType]]: resourceOwned,
  } = resources;

  // STATES / HOOKS -----------------

  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [errors, setErrors] = useState<string>("");

  /// ALLOWANCE
  const [needAllowance, setNeedAllowance] = useState<boolean>(true);
  const [fetchAllowance, loadingAllowance] = useAsyncTask("fetch allowance", (error) => {
    toaster(error?.message ?? "Error Fetching Allowance");
    setNeedAllowance(true);
  });
  const [runApprove, loadingApprove] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
    setNeedAllowance(true);
  });

  /// REFINEMENT
  const [refineElderberriesTxHash, setRefineElderberriesTxHash] = useState<string>("");
  const [refineElderberriesComplete, setRefineElderberriesComplete] = useState<boolean>(false);
  const [runRefineElderberries, loadingRefineElderberries] = useAsyncTask("refiningElderberries", (error) => {
    toaster(error?.message ?? "Error Refining Elderberries");
  });

  const [loadingFetchResources] = useTaskSubscriber('refetchResources');

  const isLoading: boolean = loadingAllowance || loadingApprove || loadingRefineElderberries || loadingFetchResources;

  useEffect(() => {
    if (!wallet) return;
    logger("debug-resources", {
      elderberryJuice,
      refinementFee: toHumanNumber(elderberryJuice.refinementFee)
    })

    fetchAllowance(async () => {
      const result = await TBMConnector.checkResourceAllowance(wallet, resourceType);
      setNeedAllowance(result);
    });
    // eslint-disable-next-line
  }, [wallet]);

  const isSubmitEnabled = useMemo(() => {
    if (!wallet || isLoading || needAllowance) return false;

    setErrors("");

    if (bnOrZero(formState.juice).lte(0)) return false;

    if (bnOrZero(formState.juice).lte(0) || bnOrZero(formState.juice).times(10).gt(resourceOwned.shiftedBy(-2))) {
      setErrors("Insufficient Elderberries")
      return false
    }

    if (bnOrZero(formState.craftCost).gt(HunyTokens)) {
      setErrors("Insufficient HUNY")
      return false;
    }

    if (bnOrZero(formState.juice).gt(2500)) {
      setErrors("Max refinement is 250");
      return false
    }
    setErrors("")
    return true
  }, [wallet, isLoading, needAllowance, formState, resourceOwned, HunyTokens])

  // FUNCTIONS ----------------------

  const approve = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const approveAmount = new BigNumber(2).pow(128).minus(1).dp(0);
    const approveTx = await TBMConnector.increaseResourceAllowance(wallet, approveAmount, resourceType);
    toaster(`Submitted Approve Increase Allowance `, { hash: approveTx.id! })
    if (approveTx.isRejected() || !approveTx.id) throw new Error("Submitted transaction was rejected.");

    try {
      const tx = await waitForTx(approveTx.id);
      if (tx.status >= 3) throw new Error("Error increasing allowance");
      toaster(`Increase Allowance Success`, { hash: approveTx.id! })
      setNeedAllowance(false);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  const refiningElderberries = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!isSubmitEnabled) throw new Error(`Requirements not met`);

    const refineElderberryTx = await TBMConnector.refineElderberry(wallet, formState.craftCost.shiftedBy(Decimals.HUNY), formState.juice.times(10), elderberryJuice.id);
    toaster(`Submitted Elderberry Refinement`, { hash: refineElderberryTx.id! });
    if (refineElderberryTx.isRejected() || !refineElderberryTx.id) throw new Error("Submitted transaction was rejected");

    try {
      await waitForTx(refineElderberryTx.id);

      const tbmConnector = TBMConnector.getSDK();
      const txn = await tbmConnector.zilliqa.blockchain.getTransaction(refineElderberryTx.id);
      const receipt = txn.getReceipt();
      if (!receipt || !receipt?.success) throw new Error("Submitted transaction was unsuccessful");

      dispatch(actions.Token.refetchResource())
      setRefineElderberriesComplete(true);
      setRefineElderberriesTxHash(refineElderberryTx.id);
    } catch (e) {
      console.error(e);
      throw e
    }
  }

  const revertToInitialState = () => {
    // if (refineElderberriesComplete) dispatch(actions.Token.reloadTokens());
    setFormState(initialFormState);
    setErrors('');
    setRefineElderberriesTxHash('');
    setRefineElderberriesComplete(false);
  };

  // EVENT HANDLERS -----------------

  const handleApprove = () => {
    runApprove(async () => {
      await approve();
    })
  }

  const handleRefineElderberries = () => {
    runRefineElderberries(async () => {
      await refiningElderberries();
    })
  }

  const handleOnClose = () => {
    if (isLoading) return;
    revertToInitialState();
    onClose();
  }

  const onElderberryInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    juiceInputHandler(event.target.value);
  };

  const handleAddQty = () => {
    juiceInputHandler(formState.juice.plus(1).toString(10));
  };

  const handleSubtractQty = () => {
    if (formState.juice.lte(0))
      return;

    juiceInputHandler(formState.juice.plus(-1).toString(10));
  };

  const handleMaxQty = () => {
    juiceInputHandler(resourceOwned.shiftedBy(-2).dividedToIntegerBy(10).toString(10));
  }

  const juiceInputHandler = (juiceInput: string) => {
    const bnJuice = bnOrZero(juiceInput);

    const baseCraftCost = elderberryJuice.refinementFee.shiftedBy(-Decimals.HUNY);
    const finalCraftCost = baseCraftCost.multipliedBy(bnJuice);

    logger("debug-component", "RefineElderberryDialog/juiceInputHandler", {
      finalCraftCost: toHumanNumber(finalCraftCost),
      elderberryJuice,
      baseCraftCost: toHumanNumber(baseCraftCost),
    })

    setFormState({
      juice: bnJuice,
      craftCost: finalCraftCost,
    })
  };

  // --------------------------------

  const getHeader = () => {
    if (refineElderberriesComplete) return "Elderberry Juice Received!"
    else return "Refine Elderberry"
  }


  const getContent = () => {
    if (refineElderberriesComplete && refineElderberriesTxHash) {
      return (
        <Box sx={styles.contentBox}>
          <Typography variant="body1" color="primary" sx={styles.dialogFont} px={6}>
            You have successfully refined your Elderberries into
            Elderberry Juice!
          </Typography>
          <Box sx={combineStyles(styles.emptyItemBox)}>
            <Box component="img" src={ElderberryJuiceGlow} alt="Elderberry Juice" height="50px" />
            {/* Update image with gem design / empty geode */}
            <Box sx={styles.gemAmtTag}>
              <Typography sx={styles.gemAmt} color="primary">
                {formatIntegerNumber(Math.floor(bnOrZero(formState.juice).toNumber()))}
              </Typography>
            </Box>
          </Box>
          <Link
            target="_blank"
            href={getExplorerLink("tx", refineElderberriesTxHash, network)}
            sx={styles.viewTx}
          >
            View Transaction
            <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
          </Link>
          <ContainedButton
            onClick={handleOnClose}
            sx={{ marginTop: '10px' }}
          >
            Level-up Metazoa
          </ContainedButton>
        </Box>
      );
    }
    else
      return (
        <Box sx={styles.contentBox}>
          <Typography variant="body1" color="primary" sx={styles.dialogFont}>
            Juice up 10 Elderberries for one bottle of Elderberry Juice to level-up your Metazoas!
          </Typography>
          <Typography variant="h2" color="success.main" component="span" sx={styles.rowBerry}>
            <Box
              component="img"
              src={ElderberryResource}
              alt="Elderberry"
              height="50px"
            />
            &nbsp;10
            <Typography variant="h2" color="primary" component="span">&nbsp;=&nbsp;</Typography>
            <Box
              component="img"
              src={ElderberryJuiceGlow}
              alt="Elderberry Juice"
              height="50px"
            />
            &nbsp;1</Typography>
          <Divider sx={styles.divider} />
          <Box sx={styles.row}>
            <Typography variant="subtitle1" color="primary" sx={styles.biggerText}>Refine</Typography>
            <Box width="50%">
              <Box sx={combineStyles(styles.input, styles.numInputBox,
                { ...(errors) && styles.errorInput }
              )}>

                <IconButton disabled={loadingRefineElderberries} onClick={handleSubtractQty}>
                  <RemoveIcon style={{ height: 16, width: 16 }} />
                </IconButton>

                <Box sx={styles.numInput}>

                  <TextField
                    id="elderberry"
                    name="elderberry"
                    onChange={onElderberryInputChange}
                    disabled={loadingRefineElderberries}
                    value={bnOrZero(formState.juice).toString(10)}
                    sx={combineStyles(styles.input, styles.settingNumInput, {
                      flex: 0.5,
                      '.Mui-disabled': {
                        color: '#fff',
                        'WebkitTextFillColor': '#fff !important',
                      }
                    }, {
                      ...(formState.juice.gte(10000)) && ({
                        flex: `calc(0.6 + ${(Number(formState.juice.toString().length) - 5) / 10})`,
                        "& input": { textAlign: "start" },
                      })
                    })} />
                  <Box
                    component="img"
                    src={ElderberryJuiceGlow}
                    alt="Elderberry"
                    height="24px"
                  />
                </Box>

                <IconButton disabled={loadingRefineElderberries} onClick={handleAddQty}>
                  <AddIcon style={{ height: 16, width: 16 }} />
                </IconButton>
              </Box>
              {!!errors &&
                <Box sx={styles.errorTextContainer}>
                  <WarningRed width={15} />
                  <FormHelperText sx={styles.errorText}>{errors}</FormHelperText>
                </Box>}
            </Box>
          </Box>
          <Box sx={combineStyles(styles.row, { justifyContent: 'flex-end', marginTop: '10px' })}>
            <Typography variant="body1" color="primary">Max: {bnOrZero(resourceOwned.shiftedBy(-2).dividedBy(10)).toFixed(0)}</Typography>
            <Button variant="outlined" color="secondary" sx={styles.button} onClick={handleMaxQty}>
              <Typography sx={styles.gradientText}>Max</Typography>
            </Button>
          </Box>
          <Divider sx={styles.divider} />
          <Box sx={styles.feeRow}>
            <Typography variant="body1" color="primary" component="span">
              Elderberry Refining Fee
            </Typography>
            <Typography variant="body1" color="success.main" component="span" fontWeight={600}>
              {formState.craftCost.toFixed(0)}&nbsp; HUNY &nbsp;
              <SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" />
            </Typography>
          </Box>


          <Typography variant="body1" color="primary" sx={combineStyles(styles.dialogFont, { marginTop: '10px' })} component="span">
            You will receive&nbsp;
            <Typography color="success.main" sx={styles.dialogFont} component="span" fontWeight={600}>
              {formState.juice.toString(10)}
            </Typography>
            &nbsp;Elderberry Juice(s).
          </Typography>


          <Box display="flex" sx={styles.buttonBox}>
            {(needAllowance) &&
              <ContainedButton
                sx={styles.approveButton}
                disabled={loadingApprove}
                onClick={handleApprove}
              >
                {isLoading
                  ? <CircularProgress size={24} />
                  : <span style={{ lineHeight: '2rem' }}>Approve</span>
                }
              </ContainedButton>
            }
            {/* Confirm */}
            <ContainedButton
              onClick={handleRefineElderberries}
              disabled={!isSubmitEnabled}
              sx={styles.confirmButton}
            >
              {loadingRefineElderberries
                ? <CircularProgress size={24} />
                : "CONFIRM & REFINE"
              }
            </ContainedButton>
          </Box>


          {/* <Box sx={styles.buttonRow}>
            {needAllowance &&
              <ContainedButton
                disabled={loadingApprove}
                sx={styles.submitButton}
                onClick={handleApprove}
              >
                {loadingApprove ? <CircularProgress size={18} /> : "Approve"}
              </ContainedButton>
            }

            <ContainedButton
              disabled={!isSubmitEnabled}
              sx={{ marginTop: '32px' }}
              onClick={handleRefineElderberries}
            >
              {isLoading ? <CircularProgress size={18} /> : "CONFIRM & REFINE"}
            </ContainedButton>
          </Box> */}

        </Box>
      )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true} dialogOverwrites={styles.dialogOverwrites}>
      {getContent()}
    </DialogModal>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    },
  },
  dialogOverwrites: {
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  dialogFont: {
    fontSize: '1.25rem',
    textAlign: 'center',
  },
  divider: {
    background: 'rgba(174, 241, 238, 0.1)',
    marginY: '10px',
    width: '100%',
  },
  rowBerry: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    width: '100%',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  biggerText: {
    fontSize: '1.3rem',
    fontWeight: 600,
  },
  input: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },

    "&:hover, &:active, &:focus, &:focus-within": {
      borderColor: '#AEF1EE',
    },

    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      paddingY: "12px",

      "&:active, &:focus": {
        color: "#AEF1EE",
        "& input": {
          color: "#AEF1EE",
        },
      }
    },
  },
  settingNumInput: {
    background: 'transparent',
    border: 0,
    flex: 0.4,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
    "& input": {
      paddingX: '0',
      width: "100%",
      textAlign: "center",
    },
  },
  numInputBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'space-between',
    padding: '0 5%',
    width: "100%",
    // gap: '24px',
  },
  errorInput: { borderColor: "#F75E5E!important", },
  numInput: {
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    gap: '8px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      minWidth: '50px',
      "svg": {
        display: 'none',
      },
    },
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },
  button: {
    radius: '16px',
    padding: '7px 15px',
    fontSize: '1.125rem',
    marginLeft: '20px',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },
  emptyItemBox: {
    position: 'relative',
    backgroundImage: `url(${ItemFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "inherit",

    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    width: '120px',
    height: '120px',
    minWidth: '100px',
    minHeight: '100px',
    // height: '100%',
    margin: '30px auto',
    paddingY: 0,
    borderRadius: '12px',

    "svg": {
      width: '40px',
      height: '40px',
    },
  },
  gemAmt: {
    fontWeight: 600,
    fontSize: '14px',
    padding: '0px 6px'
  },
  gemAmtTag: {
    position: 'absolute',
    bottom: '-7px',
    border: "1px solid transparent",
    background: 'linear-gradient(180deg, #01001E -13.81%, #000010 100%) padding-box, radial-gradient(#F3FFFE, #AEF1EE, #00C2FF) border-box',
    borderRadius: '8px'
  },
  viewTx: {
    marginY: '20px',
  },

  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },

  buttonBox: {
    marginTop: "20px",
    "> *": {
      padding: '17px 24px !important',
    },
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  approveButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    marginRight: '20px',
    width: 270,
    "@media (max-width:600px)": {
      marginRight: 0,
      marginBottom: '10px',
      width: '100%',
    }
  },
  confirmButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    width: 270,
    whiteSpace: 'nowrap',
    "@media (max-width:600px)": {
      width: '100%',
    }
  },

  feeRow: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    mt: '5px',
  },
  hunyIcon: {
    verticalAlign: "top",
  },
}

export default RefineElderberryDialog;
