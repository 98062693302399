import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, Divider, IconButton, InputAdornment, Link, OutlinedInput, styled, SxProps, Typography } from "@mui/material";
import { AddIcon, BaseButton, CompleteTag, Huny, LinesPhase4, LiveTag, RemoveIcon } from "assets";
import Background from "assets/bear-bg.svg";
import PhaseImage from "assets/icons/PhaseImage.png";
import PhaseImageGray from "assets/icons/PhaseImageGray.png";
import Upcoming from "assets/icons/Upcoming.png";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import ContainedButton from "components/ContainedButton";
import WarningBox from "components/WarningBox";
import dayjs, { Dayjs } from "dayjs";
import { NavLink } from "react-router-dom";
import { TBMConnector } from "tbm";
import { useAsyncTask } from "utils";
import { ContractsBech32, LoadingKeys, MAX_ZOA, PHASE_FOUR_GEN_SUPPLY, PHASE_FOUR_MAX_SUPPLY, PHASE_FOUR_SUMMON_PRICE } from "utils/constants";
import { getZilswapLink } from "utils/strings";
import { bnOrZero } from "utils/strings/strings";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";
import useTaskSubscriber from "utils/useTaskSubscriber";
import { AppTheme, SimpleMap } from "../../../utils/types";
import ProgressBar from "../components/ProgressBar";
import { SummonDialog } from "./components";

const PhaseFour: React.FC = () => {
  const network = useNetwork();
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const [burntBearsMap, setBurntBearsMap] = useState<SimpleMap<string>>({});
  const [runQueryTakersBurntCount, loadingBurntCount] = useAsyncTask("queryTakersBurntCount");
  const [isLoading] = useTaskSubscriber(...LoadingKeys.connectWallet);
  const [loadingTokens] = useTaskSubscriber("updateTokens");
  const [summonQty, setSummonQty] = useState<number>(1);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { moonBattleInfo: { whitelistCount, summonCount: currentSupply } } = tokenState;

  // tbm v1
  const tokens = Object.values(tokenState.tokens);

  useEffect(() => {
    if (wallet) {
      runQueryTakersBurntCount(async () => {
        const burntCount: SimpleMap<string> = await TBMConnector.getTakersBurntCount(wallet);
        if (burntCount) {
          setBurntBearsMap(burntCount);
        }
      })
    }
    // eslint-disable-next-line
  }, [wallet, network, tokens.length]);

  const hunyContract = useMemo(() => {
    return ContractsBech32[network].HunyToken;
  }, [network]);

  const totalSupply = tokenState.totalSupply;

  const amendedCurrentSupply = useMemo(() => {
    if (!currentSupply) return 0;
    return currentSupply + 133;
  }, [currentSupply]);

  const amendedTotalSupply = useMemo(() => {
    if (!totalSupply) return 0;
    return totalSupply;
  }, [totalSupply])

  // TODO: find a cleaner way
  const currentGen = useMemo(() => {
    if (amendedCurrentSupply <= PHASE_FOUR_GEN_SUPPLY["1"]) {
      return "1";
    } else if (amendedCurrentSupply <= PHASE_FOUR_GEN_SUPPLY["1"] + PHASE_FOUR_GEN_SUPPLY["2"]) {
      return "2";
    } else {
      return "3";
    }

    // eslint-disable-next-line
  }, [currentSupply]);

  const bearsAvailableForBurn = useMemo(() => {
    if (currentGen === "1" || currentGen === "2") {
      return whitelistCount - (parseInt(burntBearsMap[currentGen] ?? 0));
    } else {
      return 0;
    }
  }, [whitelistCount, currentGen, burntBearsMap]);

  const summonPrice = useMemo(() => {
    switch (currentGen) {
      case "1": return PHASE_FOUR_SUMMON_PRICE["1"]
      case "2": return PHASE_FOUR_SUMMON_PRICE["2"]
      case "3": return PHASE_FOUR_SUMMON_PRICE["3"]
      default: return 0
    }
  }, [currentGen])

  const burnDiscount = useMemo(() => {
    switch (currentGen) {
      case "1": return 0.5
      case "2": return 0.25
      default: return 0
    }
  }, [currentGen])

  // sale start time
  const startTime = dayjs.unix(new Date("2022/04/18 08:00:00 GMT+0").getTime() / 1000);
  const [currentTime, setCurrentTime] = useState<Dayjs>(dayjs());
  const [saleStartTime, setSaleStartTime] = useState<Dayjs>(startTime);

  useEffect(() => {
    setSaleStartTime(dayjs(startTime));

    const timer = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [network]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const qty = event.target.valueAsNumber;
    setSummonQty(qty);
  };

  const onInputBlur = () => {
    if (isNaN(summonQty) || summonQty === 0) {
      setSummonQty(1);
    }
  }

  const handleAddQty = () => {
    setSummonQty(summonQty + 1);
  };

  const handleSubtractQty = () => {
    if (summonQty === 1) return;
    setSummonQty(summonQty - 1);
  };

  const handleShowDialog = () => {
    setShowDialog(true);
  }

  const isDisabled = useMemo(() => {
    return isLoading || loadingTokens
      || summonQty > MAX_ZOA.SummonReinforcements || summonPrice === 0 || saleStartTime.isAfter(currentTime);

    // eslint-disable-next-line
  }, [isLoading, loadingTokens, summonQty, summonPrice, currentTime])

  // TODO: amend
  const phaseEnded = useMemo(() => {
    return currentSupply > 0 && totalSupply === 2888;

    // eslint-disable-next-line
  }, [currentSupply, totalSupply])

  function Countdown() {
    if (phaseEnded) return (
      <Typography variant="subtitle1" sx={styles.countdownText}>
        Phase 4 has ended
      </Typography>
    )

    if (saleStartTime.isAfter(currentTime)) {
      // sale not started
      const timeDiffSeconds = saleStartTime.diff(currentTime, 'second');

      const days = Math.floor(timeDiffSeconds / 86400);
      const hours = Math.floor((timeDiffSeconds - (days * 86400)) / 3600);

      return (
        <Typography variant="subtitle1" sx={styles.countdownText}>
          Starts in {
            days !== 0 && `${days.toLocaleString('en-US', { minimumIntegerDigits: 2 })}d :`} {
            hours.toLocaleString('en-US', { minimumIntegerDigits: 2 })}h : {
            (Math.floor(timeDiffSeconds / 60) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}m : {
            (timeDiffSeconds % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })
          }s
        </Typography>
      )
    }

    return null;
  }

  const totalPrice = summonPrice * bnOrZero(summonQty).toNumber();

  const discountedPrice = useMemo(() => {
    if (bearsAvailableForBurn === 0 || tokens.length === 0)
      return totalPrice;

    let bearsLimit = bearsAvailableForBurn > tokens.length ? tokens.length : bearsAvailableForBurn;

    if (summonQty > bearsLimit) {
      return totalPrice - (bearsLimit * (summonPrice * (1 - burnDiscount)));
    } else {
      return totalPrice * (1 - burnDiscount);
    }
  }, [totalPrice, summonQty, bearsAvailableForBurn, tokens.length, burnDiscount, summonPrice]);

  const sufficientBalance = tokenState.HunyTokens >= discountedPrice;

  return (
    <Box>
      <Box maxWidth="xl" sx={styles.phaseContainer}>
        {/* Phase Nav */}
        <Box maxWidth="xl" sx={styles.phaseRow}>
          <Box sx={styles.phaseLinesBG}>
            <LinesPhase4 width="100%" />
          </Box>
          <Box component={NavLink} to="/summon/elders" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 1</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>the elders</Typography>
            <CompleteTag />
          </Box>
          <Box component={NavLink} to="/summon/transcendence" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 2</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>The Transcendence</Typography>
            <CompleteTag />
          </Box>
          <Box component={NavLink} to="/summon/gold-rush" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 3</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>The gold rush</Typography>
            <CompleteTag />
          </Box>
          <Box component={NavLink} to="/summon/takers-of-the-moon" sx={styles.phaseBox}>
            <img src={PhaseImage} alt="phase" />
            <Typography variant="h3" color="primary">Phase 4</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheader}>TAKERS OF THE MOON</Typography>
            {phaseEnded
              ? <CompleteTag />
              : currentTime.isAfter(saleStartTime)
                ? <LiveTag />
                : <img src={Upcoming} alt="upcoming" />
            }
          </Box>
        </Box>
        <ProgressBar phase={4} />
      </Box>
      <Box sx={styles.phaseHeader}>
        <Typography variant="h3" color="primary">
          Phase 4 summoned: {amendedCurrentSupply}/{PHASE_FOUR_MAX_SUPPLY} &nbsp;&nbsp; | &nbsp;&nbsp; Total summoned: {amendedTotalSupply}/2888
        </Typography>
      </Box>
      <Box sx={styles.phaseHeader2}>
        <Typography variant="subtitle1" color="primary">
          Phase 4 summoned: {amendedCurrentSupply}/{PHASE_FOUR_MAX_SUPPLY}<br />
          Total summoned: {amendedTotalSupply}/2888
        </Typography>
      </Box>
      <Box sx={styles.phaseContentBox}>
        <Typography variant="h1" color="primary" sx={styles.phaseContentHeader}>
          Takers of the Moon
        </Typography>
        {!phaseEnded ?
          <Typography variant="body1" color="primary" sx={styles.phaseContentBody}>
            <strong>Metazoa, draw your weapons and let the HUNY war begin!</strong>
            <br />
            The first battle of the Moon will be unforgiving, but those who emerge from the crossfires will triumph in abounding glory.
            Victors shall savor the first drop of HUNY’s sweet, golden goodness, kindling the birth of a new Metazoa generation equipped
            with heightened senses and enhanced abilities.
          </Typography> :
          <Fragment>
            <Typography variant="body1" color="primary" sx={styles.phaseContentBody}>
              The portal to Zolar has closed.<br />
              Get a Metazoa to join the game!
            </Typography>
            <Link
              sx={styles.learnMore}
              target="_blank"
              href="https://docs.zolar.io/overview/creatures/collection-ii-metazoa/summoning#phase-2-or-the-transcendence"
            >
              Check out the collection on ARKY.
            </Link>
          </Fragment>
        }

        {/* Countdown */}
        <Countdown />

        {!phaseEnded && (
          <Fragment>
            <Divider sx={styles.divider} />

            <Typography variant="h2" color="primary" mt="10px" mb="20px">
              Summon Gen {currentGen} Metazoa Now!
            </Typography>

            <Box display="flex" flexDirection="column" width="100%" maxWidth="770px">
              {/* Burn bear box */}
              {/* Don't show if no whitelist/wallet not connected */}
              {(wallet && whitelistCount !== 0) &&
                <Box display="flex" flexDirection="column" sx={styles.burnBearBox}>
                  <Typography variant="body1" color="primary" sx={styles.burnBearText}>
                    Held
                    {" "}
                    <Link
                      fontWeight={600}
                      target="_blank"
                      href="https://zilswap.io/arky/collections/zil167flx79fykulp57ykmh9gnf3curcnyux6dcj5e"
                    >
                      TBM OG Bear
                    </Link>
                    {" "}
                    during genesis whitelisting? Burn more OG bears to summon Metazoas at a discount!
                    {" "}
                    <Link
                      fontWeight={600}
                      target="_blank"
                      href="https://docs.zolar.io/missions/mission-i-takers-of-the-moon#og-tbm-holder-phase-4-boosts"
                    >
                      Learn More
                    </Link>
                  </Typography>

                  <Divider sx={styles.divider} />

                  <Box sx={styles.statBox}>
                    {/* bears owned */}
                    <Box sx={styles.statRow}>
                      <Typography variant="body1" color="primary">TBM OG bears owned</Typography>
                      {loadingTokens
                        ? <CircularProgress size={16} />
                        : <Typography variant="body1" color="primary" sx={styles.statNumber}>{tokens.length}</Typography>
                      }
                    </Box>

                    {/* bear available for burn - whitelist */}
                    <Box sx={styles.statRow}>
                      <Typography variant="body1" color="primary" textAlign="initial">Whitelisted to burn</Typography>
                      {loadingBurntCount
                        ? <CircularProgress size={16} />
                        : <Typography variant="body1" color="primary" sx={styles.statNumber}>{bearsAvailableForBurn}</Typography>
                      }
                    </Box>

                    {/* summon cost */}
                    <Box sx={styles.statRow}>
                      <Typography variant="body1" color="primary" align="left">Summon discount per burn</Typography>
                      <Box sx={styles.summonDiscount}>
                        <Typography color="primary" variant="body1" style={{ textDecoration: 'line-through', whiteSpace: 'nowrap' }} align="right">
                          U.P. {summonPrice.toLocaleString()}
                        </Typography>
                        <Box sx={styles.cost}>
                          &nbsp;
                          <Typography variant="body1" color="success.main" style={{ fontWeight: 600 }}>
                            {(summonPrice * (1 - burnDiscount)).toLocaleString()}
                            {" "}
                            HUNY&nbsp;
                          </Typography>
                          {" "}
                          <Huny />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              }

              {/* Summon Container */}
              <Box sx={styles.summonContainer}>
                {/* Qty box */}
                <Box display="flex" flexDirection="column" alignItems="flex-start" sx={styles.qtyBox}>
                  <Typography variant="body1" color="primary">
                    How many Metazoa would you like to summon?
                  </Typography>
                  <OutlinedInput
                    sx={styles.qtyInput}
                    placeholder={"1"}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    value={summonQty.toString()}
                    type="number"
                    inputProps={{ min: "1", style: { textAlign: "center" } }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton onClick={handleSubtractQty}>
                          <RemoveIcon style={{ height: 22, width: 22 }} />
                        </IconButton>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleAddQty}>
                          <AddIcon style={{ height: 22, width: 22 }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  {/* total cost */}
                  <Box display="flex" alignItems="center" mt="10px" style={{ whiteSpace: 'nowrap' }}>
                    <Typography variant="body1" color="primary" mr="8px">
                      Total Cost:
                    </Typography>
                    <Typography variant="body1" color="primary">
                      {!wallet
                        ? '-'
                        : loadingBurntCount || loadingTokens
                          ? <CircularProgress size={14} sx={styles.spinner} />
                          : <Typography variant="body1" color="primary" sx={styles.cost}>
                            {discountedPrice.toLocaleString()}
                            {" "}
                            HUNY&nbsp;
                            <Huny />
                          </Typography>
                      }
                    </Typography>
                  </Box>
                </Box>

                {/* Summon button box */}
                <Box sx={styles.summonButtonBox}>
                  {!wallet
                    ? <ConnectWalletButtonCTA sx={styles.connectButton} />
                    :
                    sufficientBalance ?
                      <ContainedButton
                        sx={styles.summonButton}
                        onClick={handleShowDialog}
                        disabled={isDisabled}
                      >
                        Summon
                      </ContainedButton>
                      :
                      <Button
                        component={Link}
                        variant="contained"
                        color="secondary"
                        sx={styles.summonButton}
                        href={getZilswapLink(hunyContract)}
                        target="_blank"
                      >
                        <BaseButtonBox>
                          <BaseButton height="100%" />
                        </BaseButtonBox>
                        Get Huny
                      </Button>

                  }

                  {/* what is huny */}
                  <Typography variant="body1" color="primary" mt="10px">
                    <Link
                      fontWeight={600}
                      target="_blank"
                      href="https://docs.zolar.io/huny/hunynomics"
                    >
                      What is $HUNY?
                    </Link>
                  </Typography>
                </Box>
              </Box>

              {/* Error message */}
              {summonQty > MAX_ZOA.SummonReinforcements &&
                <Box display="flex" justifyContent="flex-start" mt="10px">
                  <Typography variant="body1" color="primary" sx={styles.errorMessage}>
                    You may only summon up to {MAX_ZOA.SummonReinforcements} Metazoa at a time.
                  </Typography>
                </Box>
              }

              {/* Warning box */}
              <WarningBox sx={styles.warningBox}>
                <Typography variant="body1" color="primary" align="left">
                  <strong>BEWARE: </strong>
                  Minos are on the prowl and have a 5% chance of stealing your summoned Metazoa!
                  {" "}
                  <Link
                    target="_blank"
                    href="https://docs.zolar.io/overview/creatures/collection-ii-metazoa/summoning#phase-2-or-the-transcendence"
                  >
                    Learn More
                  </Link>
                </Typography>
              </WarningBox>
            </Box>
          </Fragment>
        )}
      </Box>

      {/* Summon Dialog */}
      <SummonDialog
        open={showDialog}
        tokens={tokens}
        discount={burnDiscount}
        limit={bearsAvailableForBurn < summonQty ? bearsAvailableForBurn : summonQty}
        quantity={summonQty}
        price={summonPrice}
        onClose={() => setShowDialog(false)}
      />
    </Box>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  subheader: {
    fontSize: "0.75rem",
    letterSpacing: "0.2rem",
    lineHeight: "1.125rem",
    marginBottom: "5px",
  },
  subheaderInactive: {
    fontSize: "0.75rem",
    letterSpacing: "0.2rem",
    lineHeight: "1.125rem",
    marginBottom: "5px",
    opacity: "60%",
  },
  phaseContainer: {
    padding: '0px 90px',
    position: 'relative',
    margin: '0px 20px 20px',
    overflow: 'hidden',
    "@media (max-width:1200px)": {
      padding: '0px 4px',
      overflowX: 'scroll',
      marginX: '100px',
    },
    "@media (max-width:900px)": {
      marginX: '40px',
    },
  },
  phaseRow: {
    minWidth: '1000px',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: "60px",
  },
  phaseLinesBG: {
    pointerEvents: 'none',
    minWidth: '1000px',
    position: 'absolute',
    width: '90%',
    left: '90px',
    top: '33px',
    "@media (max-width:1200px)": {
      left: '20px',
    },
  },
  phaseBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
  },
  phaseNavLinkInactive: {
    opacity: "60%",
  },
  phaseHeader: {
    textAlign: 'center',
    marginX: "24px",
    marginBottom: "20px",
    "@media (max-width:900px)": {
      display: 'none'
    },
  },
  phaseHeader2: {
    textAlign: 'center',
    marginX: "24px",
    marginBottom: "20px",
    "@media (min-width:900px)": {
      display: "none",
    },
  },
  phaseContentBox: {
    position: "relative",
    borderRadius: "16px",
    margin: "0px 110px",
    padding: "40px 80px",
    background:
      "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    minHeight: "350px",
    "@media (max-width:900px)": {
      margin: "0px 24px",
      padding: "24px",
    },
  },
  phaseContentHeader: {
    zIndex: 1,
    opacity: "100%",
    "@media (max-width:600px)": {
      fontSize: '2rem',
      textAlign: 'center',
    },
    marginBottom: '10px',
  },
  phaseContentBody: {
    zIndex: 1,
    textAlign: 'center',
    marginY: '30px',
  },
  countdownText: {
    color: "#FF8952",
    marginBottom: '30px',
  },
  learnMore: {
    marginBottom: '30px',
  },
  divider: {
    width: '100%',
    color: 'rgba(174, 241, 238, 0.1)',
    backgroundColor: 'rgba(174, 241, 238, 0.1)',
    height: '1.5px',
    marginBottom: '20px',
  },
  summonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    "@media (max-width:900px)": {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  qtyBox: {
    "@media (min-width:900px)": {
      marginRight: '30px',
    },
  },
  qtyInput: {
    height: 60,
    marginTop: '10px',
    border: '1px solid rgba(174, 241, 238, 0.1)',
    borderRadius: '16px',
    color: '#FEFEFE',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    "& input": {
      height: '30px',
      fontSize: '24px',
      fontWeight: 600,
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": 'none',
        margin: 0,
      },
      "&:[type=number]": {
        "-moz-appearance": 'textfield',
      },
    },
    "&.Mui-focused": {
      caretColor: '#FEFEFE',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 'none',
    },
  },
  summonButtonBox: {
    "@media (max-width:900px)": {
      display: 'initial',
      width: '100%',
    },
  },
  summonButton: {
    height: 60,
    minWidth: 300,
    "@media (max-width:900px)": {
      width: '442.5px',
      minWidth: '',
      marginTop: '10px',
    },
    "@media (max-width:560px)": {
      width: '100%',
    },
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
  connectButton: {
    "& #wrapperBox": {
      "@media (max-width:900px)": {
        width: '100%',
        display: 'initial',
      },
    },
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .MuiButton-root": {
      minHeight: "",
      height: 60,
      minWidth: 310,
      "@media (max-width:900px)": {
        width: '100%',
        minWidth: '',
        marginTop: '10px',
      },
    }
  },
  errorMessage: {
    color: '#F65E5E',
  },
  warningBox: {
    marginTop: '40px',
    "@media (max-width:900px)": {
      width: '100%',
      marginTop: '20px',
    },
  },
  burnBearBox: {
    width: '100%',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    backgroundImage: `url(${Background}), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    padding: '32px',
    borderRadius: '12px',
    marginBottom: '30px',
    marginTop: '10px',
  },
  statBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    "@media (min-width:900px)": {
      paddingLeft: '60px',
      paddingRight: '60px',
    },
  },
  statRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '4px',
  },
  cost: {
    display: 'flex',
    whiteSpace: 'nowrap',
    "& svg": {
      verticalAlign: 'text-top',
      marginTop: '2px',
      height: 22,
      width: 22,
    },
    "@media (max-width:600px)": {
      "& svg": {
        marginTop: 0,
        height: '20px!important',
        width: '20px!important',
      }
    },
  },
  statNumber: {
    fontWeight: 600,
  },
  burnBearText: {
    marginBottom: '20px',
  },
  summonDiscount: {
    display: 'flex',
    "@media (max-width:1200px)": {
      flexDirection: 'column',
    },
  },
};

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    height: "100%",
  },
});

export default PhaseFour;
