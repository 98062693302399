import { Box, Link, SvgIcon, SxProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { ExternalLink, Huny, WarningRed } from "assets";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { waitForTx } from "core/utilities";
import dayjs from "dayjs";
import { Fragment, useMemo, useState } from "react";
import { Guild, TaxByWeek, TaxToCollect } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { BIG_ZERO } from "utils/constants";
import { formatIntegerNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from "utils/types";
import { MemberTax } from "./TaxBalanceAccordion";
import { getExplorerLink } from "utils/strings/links";
import useNetwork from "utils/useNetwork";
import { convertBpsAllocation } from "components/Guild/components/GuildConstants";
import { useDispatch } from "react-redux";
import { actions } from "store";


export interface ModalProps {
  open: boolean;
  onClose: () => void;
  memberTaxList: MemberTax[];
  allTaxes: TaxByWeek[];
  guild: Guild;
}
export interface TaxSummary {
  membersPayingAmt: BigNumber;
  membersPayingCount: number;
  date: Date;
  totalAmt: BigNumber;
}

const CollectTaxDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, memberTaxList, allTaxes, guild } = props;
  const toaster = useToaster();
  const network = useNetwork();
  const dispatch = useDispatch();
  const [collectionComplete, setCollectionComplete] = useState<boolean>(false);
  const [collectTxHash, setCollectTxHash] = useState<string>("");
  const [runCollectTax, loadingCollectTax] = useAsyncTask("collectTax", (error) => {
    toaster(error?.message)
  });
  const wallet = useRedux((state) => state.wallet.wallet);

  const taxSummaries = useMemo(() => {
    if (!allTaxes || !memberTaxList) return [];
    //membersPayingAmt, numberOfPplPaying, epochNumber, date
    // pass in memberTax to dialog to send to contract
    const res: TaxSummary[] = [];
    allTaxes.forEach((tax) => {
      let count = 0;
      memberTaxList.forEach(m => {
        const i = m.datesToPay.findIndex(d => d.date.getTime() === tax.date.getTime());
        if (i > -1) count += 1;
      })
      const totalAmt = tax.membersPayingAmt.multipliedBy(count);

      if (count > 0) res.push({ membersPayingAmt: tax.membersPayingAmt, membersPayingCount: count, date: tax.date, totalAmt: totalAmt })
    })

    return res;

    // eslint-disable-next-line
  }, [memberTaxList, allTaxes])

  const totalTax = useMemo(() => {
    let amt = BIG_ZERO;
    if (taxSummaries && taxSummaries.length > 0) {
      taxSummaries.forEach(t => {
        amt = amt.plus(t.totalAmt)
      })
    }

    return amt;

    // eslint-disable-next-line
  }, [taxSummaries, memberTaxList, allTaxes])

  const getContent = () => {
    if (collectionComplete && collectTxHash) {
      return (
        <Box sx={combineStyles(styles.dialogContent)}>
          <Box>
            <Typography sx={combineStyles(styles.successMsg)}>You've successfully collected&nbsp;
              <Typography component="span" sx={combineStyles(styles.taxSummaryTotal, styles.greenText, { display: 'inline-flex' })}>
                <Huny width="40px" height="40px" />&nbsp;
                {formatIntegerNumber(totalTax)}
              </Typography>&nbsp;
              for your guild!
            </Typography>
          </Box>
          <Link
            target="_blank"
            href={getExplorerLink("tx", collectTxHash, network)}
            sx={styles.viewTx}
          >
            View Transaction
            <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
          </Link>
          <ContainedButton sx={styles.closeButton} onClick={() => handleClose()}>
            Close
          </ContainedButton>
        </Box>
      )
    }

    if (loadingCollectTax) {
      return (
        <Fragment>
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Fragment>
      )
    }

    return (<Box sx={styles.dialogContent}>
      {
        guild.guildBank?.weeklyTax && guild.members && taxSummaries.map((summary, idx) => {
          const { membersPayingAmt, membersPayingCount, date, totalAmt } = summary;
          const captain = guild.members.find(m => m.address === guild.leaderAddress);
          const captainAllocation = convertBpsAllocation(Number(guild.guildBank?.weeklyTax.captainAllocation));
          const officerAllocation = convertBpsAllocation(Number(guild.guildBank?.weeklyTax.officerAllocation));
          const guildLeadersCut = captainAllocation + (officerAllocation * guild.commanderAddresses.length);
          const guildPercentage = 100 - guildLeadersCut;
          return (
            <Fragment key={idx}>
              <Box sx={combineStyles(styles.textRow)} display="flex">
                <Typography>Week Summary ({dayjs(date).format("MMMM D, YYYY")})</Typography>
                <Box sx={styles.horizontallyAligned}>
                  <Typography sx={styles.taxSummaryEqn}>{formatIntegerNumber(membersPayingAmt)} x {membersPayingCount} pax = </Typography>&nbsp;
                  <Typography sx={combineStyles(styles.taxSummaryTotal, styles.greenText, styles.horizontallyAligned)} component="span">
                    {formatIntegerNumber(totalAmt)}&nbsp;<Huny width="24px" height="24px" />
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.summaryContainer}>
                {/* guild percentage */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: "10px" }}>
                  <Box sx={combineStyles(styles.summaryLabel, styles.horizontallyAligned)}>
                    <WarningRed width="20px" height="20px" className="warningWhite" />
                    <Typography>{guildPercentage}% to Guild Bank</Typography>
                  </Box>
                  <Typography sx={styles.greenText}>{formatIntegerNumber(totalAmt.multipliedBy(guildPercentage / 100))}</Typography>
                </Box>

                {/* captain percentage */}
                {captain && <Box sx={{ display: 'flex', justifyContent: 'space-between', my: "10px" }}>
                  <Box sx={combineStyles(styles.summaryLabel, styles.horizontallyAligned)}>
                    <WarningRed width="20px" height="20px" className="warningWhite" />
                    <Typography>{convertBpsAllocation(guild.guildBank?.weeklyTax.captainAllocation ?? 0)}% to </Typography>&nbsp;
                    <Typography sx={{ textDecoration: "underline" }}>{captain?.username ?? truncate(toBech32Address(captain.address))}</Typography>&nbsp;
                    <Typography>(Captain)</Typography>
                  </Box>
                  <Typography>{formatIntegerNumber(totalAmt.multipliedBy((convertBpsAllocation(guild.guildBank?.weeklyTax.captainAllocation ?? 0) / 100)))}</Typography>
                </Box>}

                {/* officers percentage */}
                {
                  guild.commanderAddresses.map((address, i) => {
                    const officer = guild.members.find(m => m.address === address);

                    if (officer) {
                      return (
                        <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between', my: "10px" }}>
                          <Box sx={combineStyles(styles.summaryLabel, styles.horizontallyAligned)}>
                            <WarningRed width="20px" height="20px" className="warningWhite" />
                            <Typography>{convertBpsAllocation(guild.guildBank?.weeklyTax.officerAllocation ?? 0)}% to </Typography>&nbsp;
                            <Typography sx={{ textDecoration: "underline" }}>{officer?.username ?? truncate(toBech32Address(officer.address))}</Typography>&nbsp;
                            <Typography>(Officer)</Typography>
                          </Box>
                          <Typography>{formatIntegerNumber(totalAmt.multipliedBy(convertBpsAllocation(guild.guildBank?.weeklyTax.officerAllocation ?? 0) / 100))}</Typography>
                        </Box>
                      )
                    } else return "";
                  })
                }
              </Box>
            </Fragment>
          )
        })
      }

      <Box sx={styles.textRow} display="flex">
        <Typography>Total Tax</Typography>
        <Typography sx={combineStyles(styles.taxSummaryTotal, styles.greenText, styles.horizontallyAligned)} component="span">
          {formatIntegerNumber(totalTax)}&nbsp;<Huny width="24px" height="24px" />
        </Typography>
      </Box>
      <Box sx={styles.textRow} display="flex">
        <Typography>Tax Collection Date</Typography>
        <Typography>{dayjs().format("MMMM D, YYYY")}</Typography>
      </Box>
      <ContainedButton sx={styles.confirmButton} onClick={() => onConfirmCollect()}>
        Confirm &amp; Collect
      </ContainedButton>
    </Box>)
  }

  const getHeader = () => {
    if (collectionComplete && collectTxHash) return "Collection Success!";
    else if (loadingCollectTax) return "Collecting taxes...";
    else return "Collect Tax";
  }

  const onConfirmCollect = () => {
    runCollectTax(async () => {
      if (!memberTaxList) return;
      if (!wallet) throw new Error("Wallet not connected")

      const toCollect: TaxToCollect[] = [];

      memberTaxList.forEach(m => {
        m.datesToPay && m.datesToPay.forEach(t => {
          toCollect.push({ memberAddress: m.address.toLowerCase(), epochNumber: t.epochNumber })
        })
      })

      if (guild.guildBank?.address) {
        const collectTx = await TBMConnector.collectTaxes(guild.guildBank?.address, toCollect)
        toaster(`Submitted Collect Tax `, { hash: `${(collectTx.id)}` })
        if (collectTx.isRejected() || !collectTx.id) throw new Error("Submitted transaction was rejected.");

        const tx = await waitForTx(collectTx.id);
        const tbmConnector = TBMConnector.getSDK();
        const txn = await tbmConnector.zilliqa.blockchain.getTransaction(collectTx.id);
        const receipt = txn.getReceipt();
        if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

        setCollectionComplete(true)
        setCollectTxHash(collectTx.id)
      }
    })
  }

  const handleClose = () => {
    onClose();
    setCollectionComplete(false);
    dispatch(actions.Guild.loadGuildTax(guild));
  }

  return (
    <DialogModal header={getHeader()}
      open={open} onClose={handleClose}
      sx={combineStyles(styles.dialogModal, { padding: collectionComplete ? '40px 68.5px' : '40px' })}
      disableScrollLock={true} dialogOverwrites={styles.dialogOverwrite}>
      <Box sx={styles.contentWrapper}>
        {getContent()}
      </Box>
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  contentWrapper: {
    maxWidth: 700,
    width: "100%",
    textAlign: 'center',
  },
  textRow: {
    color: '#FEFEFE',
    justifyContent: 'space-between',
    marginTop: '24px'
  },
  summaryContainer: {
    marginTop: '12px',
    background: 'rgba(174, 241, 238, 0.1)',
    borderRadius: "16px",
    border: '1px solid rgba(174, 241, 238, 0.1)',
    color: '#FEFEFE',
    padding: '6px 34px',
    "& .warningWhite": {
      marginRight: '10px',
      "& path:first-of-type": {
        fill: "#FFF"
      },
      "& path:not(:first-of-type)": {
        fill: "black"
      }
    }
  },
  summaryLabel: {
    fontSize: "18px",
  },
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    },
  },
  dialogOverwrite: {
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  taxSummaryEqn: {
    fontSize: '14px',
    fontWeight: 600,
  },
  taxSummaryTotal: {
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: 'Syne',
    lineHeight: "36px",
  },
  greenText: {
    color: '#2FF583'
  },
  horizontallyAligned: {
    placeItems: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  confirmButton: {
    height: 60,
    minWidth: 360,
    width: "450px",
    margin: "32px 0 20px 0",
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  closeButton: {
    display: 'block',
    height: 60,
    width: "300px",
    margin: "40px auto 20px auto",
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  successMsg: {
    fontSize: "24px",
    color: '#FEFEFE',
    display: 'flex',
    margin: "12px 0 32px 0",
    alignItems: 'center'
  },
  viewTx: {
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: 600,
    // display: "block"
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
}

export default CollectTaxDialog;
