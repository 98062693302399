import { Box, CircularProgress, Divider, FormHelperText, Grid, OutlinedInput, SxProps, Typography } from '@mui/material';
import { WarningRed } from 'assets';
import BigNumber from 'bignumber.js';
import MetazoaCard from 'components/Metazoa/MetazoaCollection/MetazoaCards';
import { logger } from 'core/utilities';
import { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NftMetadata } from 'store/types';
import { TBMConnector } from 'tbm';
import { useRedux } from 'utils';
import { formatIntegerNumber } from 'utils/strings';
import { combineStyles } from 'utils/themeUtilities';
import { AppTheme, SimpleMap } from 'utils/types';
import useIsOverflow from 'utils/useIsOverflow';
import ExploreMetazoaView from './MetazoaView';

const MetazoaSearch: FC = () => {
  const { zoaId } = useParams();

  const commanders = useRedux(state => state.game.commanders);
  const tokenTraits = useRedux(state => state.game.tokenTraits);
  const [metazoaArray, setMetazoaArray] = useState<NftMetadata[]>([]);
  const [searchVal, setSearchVal] = useState<String>();
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showStats, setShowStats] = useState<NftMetadata>();
  const [errorText, setErrorText] = useState("");
  const ref = useRef() as RefObject<HTMLDivElement>;
  const isOverflow = useIsOverflow(ref);
  const listInnerRef = useRef() as RefObject<HTMLDivElement>;
  // const [sortMode, setSortMode] = useState('Unsorted');

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  const metazoaIds = useMemo(() => {
    if (!tokenTraits) return;
    const idList = Object.keys(tokenTraits!).map((key) => parseInt(key));
    // if (sortMode === "Id") {
    //   return idList
    // }
    shuffle(idList);
    return idList
  }, [tokenTraits])

  useEffect(() => {
    if (!!zoaId) {
      logger("debug-zoaId", zoaId);
      setSearchVal(zoaId);
      searchMetazoa(zoaId);
    }

    if (!tokenTraits) return;

    paginatedFetchData();
    window.addEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [tokenTraits, zoaId])

  const handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight ||
      isFetching
    )
      return;
    setIsFetching(true);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        return;
      }
      setIsFetching(true);
    }
  };

  const fetchData = async (zoaIds: string[]) => {
    if (!zoaIds.length) return;
    setTimeout(async () => {
      let zoaTokenData: NftMetadata[] = await TBMConnector.getMetazoaMetadata(zoaIds) ?? [];
      if (zoaTokenData.length > 0) zoaTokenData = await TBMConnector.getStatsAndProfession(zoaTokenData, Object.values(zoaTokenData).map((m) => m.id));

      logger("debug-data", {
        zoaTokenData
      })

      setMetazoaArray(metazoaArray.concat(zoaTokenData));
      setIsFetching(false);
    }, 1000);
  };

  const paginatedFetchData = async () => {
    if (!metazoaIds) return;
    const paginationLimit = 20;
    const listZoaIds = (metazoaIds.slice(0, paginationLimit)).map((id) => id.toString());

    await fetchData(listZoaIds);
    if (!isFetching) metazoaIds.splice(0, paginationLimit);
  }

  const fetchSingleData = async (tokenId: string) => {
    if (!tokenId) return;
    setLoading(true);
    setTimeout(async () => {

      // Fetch token
      let zoaTokenData: NftMetadata[] = await TBMConnector.getMetazoaMetadata([tokenId]) ?? [];
      if (zoaTokenData.length > 0) zoaTokenData = await TBMConnector.getStatsAndProfession(zoaTokenData, Object.values(zoaTokenData).map((m) => m.id));
      const token: NftMetadata = zoaTokenData[0];

      // Append token to head
      metazoaArray.unshift(token);

      setMetazoaArray(metazoaArray)
      setShowStats(token);
      setIsFetching(false);
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    if (!isFetching) return;
    paginatedFetchData()
    // eslint-disable-next-line
  }, [isFetching])

  const {
    totalCount,
  } = useMemo(() => {

    if (!tokenTraits || !commanders) return {};

    return {
      totalCount: new BigNumber(Object.entries(tokenTraits).length)
    }
  }, [tokenTraits, commanders]);

  const searchMetazoa = (input: string) => {
    setSearchVal(input);
    setErrorText('');
    if (!metazoaIds || !input) return;
    const id = parseInt(input);
    if (!id) return setErrorText('Positive Whole Numbers Only');

    const exists = Object.keys(tokenTraits!).includes(id.toString())
    if (!exists) return setErrorText('ID Does Not Exist');

    const findToken = metazoaArray.find((token) => token.id === id.toString())
    if (!!findToken) {
      setShowStats(findToken);
    } else {
      const index = metazoaIds.indexOf(id);
      if (index > -1) { // only splice array when item is found
        metazoaIds.splice(index, 1); // 2nd parameter means remove one item only
      }
      fetchSingleData(id.toString())
    }
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  // const handleSortChange = (value: string) => {
  //   setSortMode(value);
  //   setMetazoaArray([]);
  // };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant='overline' color='secondary'>All Metazoa ({formatIntegerNumber(totalCount)})</Typography>
        <Divider sx={styles.divider} />
        {/* <Box sx={styles.sortGroup}>
          <FormControl sx={styles.filterFormGroup}>
            <Box sx={styles.filterGroup}>
              <Typography sx={styles.filterLabel}>
                Sort By:
              </Typography>
              <Dropdown options={sortModes}
                defaultValueSelected={sortMode}
                onSelected={handleSortChange}
                sx={styles.dropdownFilter}
                paperStyles={styles.dropdownFilterPaper}
              />
            </Box>
          </FormControl>
        </Box> */}
      </Box>
      <OutlinedInput
        sx={combineStyles(styles.input)}
        placeholder='Search by ID'
        value={searchVal}
        onChange={(e) => searchMetazoa(e.target.value)}
        // onBlur={(e) => searchMetazoa(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e)}
      />
      {errorText &&
        <Box sx={styles.errorTextContainer}>
          <WarningRed width={15} />
          <FormHelperText sx={styles.errorText}>{errorText}</FormHelperText>
        </Box>
      }
      <Box sx={combineStyles(styles.statsViewRow, { display: (!!showStats) ? 'flex' : 'none' })}>
        {!!showStats && (
          loading
            ? <CircularProgress size={48} />
            : <ExploreMetazoaView token={showStats} />
        )}
      </Box>
      <Box sx={styles.collectionContainer}>
        <Grid component="div" container columns={7} gap={2}
          ref={listInnerRef}
          onScroll={onScroll}
          sx={combineStyles(styles.itemContainer, { ...(!isOverflow) && styles.unmaskedContent })}
          className={"masked-overflow"}
        >
          {Object.values(metazoaArray).map((token, i) => (
            <Grid
              item
              xs={1}
              ref={ref}
              columns={{ lg: 7 }}
              onScroll={onScroll}
              key={i}
              sx={combineStyles(styles.metazoaWrapper,)}>
              {!token
                ? (<CircularProgress size={24} />)
                : (
                  <Box
                    onClick={() => {
                      setShowStats(token);
                      window.scrollTo({ top: 700, left: 0, behavior: 'smooth' });
                    }}
                    sx={{
                      opacity: (!!showStats && showStats.id !== token.id) ? 0.6 : 1,
                      cursor: 'pointer'
                    }}>
                    <MetazoaCard key={token.id} token={token} showLocation={false} />
                  </Box>
                )}
            </Grid>
          ))}
          <Grid
            sx={styles.loadingWrapper}>
            {isFetching && <CircularProgress size={50} />}
          </Grid>
        </Grid>

      </Box>
    </Box >
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    width: '100%',
    paddingX: '40px',
    zIndex: 1,
    "@media (max-width:1200px)": {
      paddingX: '0px',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'center',
    gap: '20px',
    marginY: '40px',
    flexWrap: 'wrap',
  },

  divider: {
    borderColor: '#AEF1EE',
    background: '#AEF1EE',
    flex: '1 1 0%',
  },

  input: {
    height: '48px',
    width: '600px',
    borderColor: 'transparent',
    borderRadius: '16px',
    border: '1px solid rgba(174, 241, 238, 0.1)',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    '& input': {
      fontSize: '14px',
      lineHeight: '28px',
      color: 'rgba(255, 255, 255, 0.8)',
      fontWeight: 600,
      padding: '12px 24px',
    },
    '&.Mui-focused': {
      borderColor: '#AEF1EE',
      caretColor: '#AEF1EE',
    },
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  statsViewRow: {
    marginTop: '60px',
    marginBottom: '88px',
    paddingLeft: '60px',
    // display: 'flex',
    justifyContent: 'center',
    "@media (max-width:1200px)": {
      overflow: 'scroll',
      padding: '60px',
      paddingLeft: '200px',
    },
  },
  collectionContainer: {
    marginTop: '40px',
  },
  itemContainer: {
    justifyContent: 'start',
    maxHeight: "590px",
    width: '100%',
    overflowY: "auto",
    paddingY: '5.5%',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    "@media (max-width:1200px)": {
      marginTop: '24px'
    },
  },
  unmaskedContent: {
    '.masked-overflow': {
      maskImage: 'none',
      WebkitMaskImage: 'none',
      overflowY: 'hidden'
    }
  },
  metazoaWrapper: {
    // background: 'pink',
    userSelect: 'none',
    position: 'relative',
    height: '100%',
    maxWidth: '150px!important',
    padding: '0 !important',

    background: 'transparent!important',
    boxShadow: 'none!important',
    '&.Mui-disabled': {
      opacity: 0.5,
    },

    "*": {
      // background: 'transparent',
      boxShadow: 'none',
      webkitBoxShadow: 'none',
    }
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px',
    height: '200px',
  }
}

export default MetazoaSearch;
