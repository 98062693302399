import { Box, Button, CircularProgress, Divider, FormControl, FormHelperText, IconButton, InputLabel, Link, OutlinedInput, SvgIcon, SxProps, TextField, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { AddIcon, ExternalLink, Huny, RemoveIcon, WarningRed, ZilTokenIcon } from "assets";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { GUILD_GRAY_GRADIENT, GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import WarningBox from "components/WarningBox";
import { waitForTx } from "core/utilities";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { ControlMode, Guild } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { Decimals, WALLET_REGEX } from "utils/constants";
import { bnOrZero, formatGuildHuny, getExplorerLink } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import whoseSignature from "utils/whoseSignature";
import { NumberFormat } from "../../../../../../BankSettings/components";
import { Dropdown } from "../../../../TaxCollectorTab/components";
import { DropdownOptions } from "../../../../TaxCollectorTab/components/Dropdown";

export interface Props {
  guild: Guild,
  bankTokens: {
    huny: BigNumber,
    zil: BigNumber,
  }
  open: boolean;
  onClose: () => void;
}

export type BankWithdrawalInputs = {
  withdrawalType: string;
  withdrawalAmount: BigNumber;
  withdrawToAddress: string;
  withdrawalReason: string;
}

const WithdrawDialog: React.FC<Props> = (props: Props) => {
  const { open, onClose, guild, bankTokens } = props
  const { guildBank } = guild;

  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const toaster = useToaster(false);
  const dispatch = useDispatch();
  const network = useNetwork();
  const [requestTx, setRequestTx] = useState<string>("");
  const [runWithdraw, loadingWithdraw] = useAsyncTask("withdraw", (error) => {
    toaster(error?.message ?? "Error Withdrawing");
  });

  // Get current token balance
  const hunyBalance = bnOrZero(bankTokens.huny).shiftedBy(-Decimals.HUNY);
  const zilBalance = bnOrZero(bankTokens.zil).shiftedBy(-Decimals.ZIL);

  const tokens = useMemo(() => ({
    huny: {
      balance: hunyBalance,
      price: tokenState.ExchangeRates.hunyPrice
    },
    zil: {
      balance: zilBalance,
      price: tokenState.ExchangeRates.zilPrice
    }
  }), [tokenState, hunyBalance, zilBalance])

  const tokenList: DropdownOptions[] = [
    { value: "HUNY", label: "huny", icon: <Huny /> },
    { value: 'ZIL', label: "zil", icon: <ZilTokenIcon /> },
  ]

  const initialFormState: BankWithdrawalInputs = {
    withdrawalType: tokenList[0].value.toLowerCase(),
    withdrawalAmount: new BigNumber(0),
    withdrawToAddress: wallet?.addressInfo.bech32!.toLocaleLowerCase() ?? "",
    withdrawalReason: ""
  }

  const [formState, setFormState] = useState<BankWithdrawalInputs>(initialFormState);
  const [errors, setErrors] = useState({
    withdrawalType: "",
    withdrawalAmount: "",
    withdrawToAddress: "",
    withdrawalReason: ""
  })

  const validateInput = (field: string, input: string) => {
    const amt = bnOrZero(input);
    const withdrawalType: string = formState.withdrawalType.toLowerCase()
    const currentBalance: BigNumber = (tokens[withdrawalType]) ? tokens[formState.withdrawalType.toLowerCase()].balance : 0;

    switch (field) {
      case "withdrawToAddress":
        if (!WALLET_REGEX.test(input)) return "Enter a valid wallet address";
        return ""
      case "withdrawalReason":
        if (input.length && input.length > 70) return "Enter a reason of up to 70 characters.";
        return ""
      case "withdrawalAmount":
        if (amt.isGreaterThan(new BigNumber(currentBalance))) return "The amount exceeds the bank balance.";
        return ""
      default: return "";
    }
  }

  //INFO: Input handlers
  const handleMaxInput = () => {
    switch (formState.withdrawalType.toLowerCase()) {
      case "huny":
        inputHandler(hunyBalance.toString(10), "withdrawalAmount");
        return;
      case "zil":
        inputHandler(zilBalance.toString(10), "withdrawalAmount");
        return
    }
  };

  const inputHandler = (inputValue: string, attrName: string) => {
    const amt = (formState[attrName] instanceof BigNumber) ? bnOrZero(inputValue) : inputValue;

    // Catch possible errs 
    if (typeof inputValue === "string") {
      const errorText = validateInput(attrName, inputValue);
      setErrors((prev) => ({ ...prev, [attrName]: errorText }));
    }

    setFormState({
      ...formState,
      [attrName]: amt,
    })
  };

  const handleAdd = (attrName: string) => {
    if (formState[attrName] instanceof BigNumber) {
      inputHandler(formState[attrName].plus(1).toString(10), attrName);
    }
    else {
      inputHandler((formState[attrName] + 1).toString(), attrName);
    }
  };

  const handleSubtract = (attrName: string) => {
    if (formState[attrName] instanceof BigNumber) {
      if (formState[attrName].isLessThanOrEqualTo(0)) return;
      inputHandler(formState[attrName].minus(1).toString(10), attrName);
    }
    else {
      if (formState[attrName] <= 0) return;
      inputHandler((formState[attrName] - 1).toString(), attrName);
    }

  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputHandler(event.target.value, event.target.name);
  };

  const handleTypeChange = (value: string) => {
    const currentBalance: BigNumber = tokens[value.toLowerCase()].balance;
    setFormState({
      ...formState,
      withdrawalAmount: currentBalance.multipliedBy(.1),
      withdrawalType: value.toLowerCase(),
    })
  };

  const getTypeBalance = useMemo(() => {
    if (!formState.withdrawalType) return;

    const withdrawalType: string = formState.withdrawalType.toLowerCase()

    if (tokens[withdrawalType]) {
      const currentBalance: BigNumber = tokens[formState.withdrawalType.toLowerCase()].balance;

      return `${currentBalance.toFormat(4)} ${withdrawalType.toUpperCase()} (≈${(tokens[withdrawalType].price).times(currentBalance).toFormat(2)})`;
    }
    else return `∞ ${withdrawalType.toUpperCase()}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.withdrawalType, tokens])

  const isInitiateEnabled = useMemo(() => {

    // Check if errors are present
    if (errors.withdrawalReason || errors.withdrawToAddress || errors.withdrawalAmount) return false;

    // Check if donation amount is not more than zero
    if (!formState.withdrawalAmount.isGreaterThan(0)) return false;

    return true;

    // eslint-disable-next-line
  }, [formState, errors])



  //DEBUG: Sudo state mgmt, to be replaced with RPC calls
  const [withdrawalInitiated, setWithdrawalInitiated] = useState<boolean>(false);

  const handleInitiateWithdrawal = () => {
    runWithdraw(async () => {
      await withdraw();
    })
  }

  const withdraw = async () => {
    if (!wallet || !formState || !guildBank || !guild) return;
    if (!guildBank.address) dispatch(actions.Guild.reloadBank(guild!));

    if (!!guildBank.pendingRequest) {
      toaster(`Please approve or cancel current multisig transaction first!`);
      return;
    }

    const amount = formState.withdrawalAmount.shiftedBy(Decimals.HUNY).integerValue(BigNumber.ROUND_DOWN);

    const withdrawTx = await TBMConnector.withdrawGuildBank(toBech32Address(guildBank.address), formState.withdrawToAddress, formState.withdrawalType, amount, formState.withdrawalReason);
    toaster(`Initiated Withdrawal `, { hash: withdrawTx.id! })
    if (withdrawTx.isRejected() || !withdrawTx.id) throw new Error("Submitted transaction was rejected.");

    const tx = await waitForTx(withdrawTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(withdrawTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) {
      setWithdrawalInitiated(false);
      throw new Error("Submitted transaction was unsuccessful");
    }

    setRequestTx(withdrawTx.id);
    setWithdrawalInitiated(true);
    toaster(`Successfully withdrawn `, { hash: withdrawTx.id!, overridePersist: true });
    dispatch(actions.Guild.reloadBank(guild));
  }

  const handleOnClose = () => {
    onClose()
    setWithdrawalInitiated(false)
    setFormState(initialFormState)
  }


  const getHeader = () => {
    if (withdrawalInitiated && requestTx) return "Withdrawal Initiated!";
    else return "Withdraw";
  }

  const getContent = () => {
    if (!guild || !wallet || !guildBank) return;


    if (withdrawalInitiated && requestTx) {
      return (
        <Box sx={combineStyles(styles.dialogContent, styles.multisigContent)}>

          {(guildBank.controlMode === ControlMode.CaptainOnly) && (wallet.addressInfo.byte20.toLowerCase() === guild.leaderAddress)
            ? (<>
              <Typography variant="body1" color="primary" className="joinInfo">
                You’ve successfully&nbsp;<span className="warningText">withdrawn</span>&nbsp;

                {/* //TODO: Dyanmically via withdraw input */}
                <span className="hunyAmount">
                  <Huny width={40} height={40} />
                  {formatGuildHuny(formState.withdrawalAmount)} !
                </span>
              </Typography>

              {requestTx && (
                <Link
                  target="_blank"
                  href={getExplorerLink("tx", requestTx, network)}
                  sx={styles.viewTx}
                >
                  View Transaction
                  <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
                </Link>
              )}
              <ContainedButton
                variant="contained"
                color="secondary"
                onClick={handleOnClose}
                sx={{
                  marginBottom: '10px',
                }}
              >
                Back to Guild Bank
              </ContainedButton>

            </>)
            : (<>
              <Typography sx={styles.subText} color="primary" mt="20px" component="span">
                Your withdrawal request has been sent.&nbsp;
                <Typography sx={styles.signaturesRequiredText}>{whoseSignature(wallet.addressInfo.byte20.toLowerCase(), guild)}</Typography>
                &nbsp;required for this transaction to go through.
              </Typography>

              <Box sx={styles.buttonRow}>
                <ContainedButton
                  sx={styles.button}
                  onClick={handleOnClose}
                >
                  Back to guild bank
                </ContainedButton>


              </Box>
            </>)
          }


        </Box>
      )
    }

    return (
      <Box sx={styles.dialogContent}>
        <WarningBox sx={styles.warningBox}>
          <Typography variant="body1" color="primary" align="left" sx={styles.modalTextResize}>
            Multisig control is activated. Assets entered below will only be withdrawn
            if all required parties approve your request. <br />
            {" "}
            <Link
              target="_blank"
              href="#"
            >
              Learn More
            </Link>
          </Typography>
        </WarningBox>

        <Box display="flex" alignItems="center" width="100%">
          <InputLabel htmlFor="address">
            <Typography variant="body1" color="primary" sx={styles.inputLabel}>Withdraw To Address</Typography>
          </InputLabel>
          <FormControl fullWidth>
            <OutlinedInput
              sx={combineStyles(styles.smallInput, { ...(errors.withdrawToAddress) && { borderColor: "#F75E5E!important", } })}
              id="withdrawToAddress"
              name="withdrawToAddress"
              placeholder={wallet?.addressInfo.bech32!.toLocaleLowerCase()}
              value={formState.withdrawToAddress}
              onChange={handleChange}
            />
            {!!errors.withdrawToAddress && (
              <Box sx={styles.helperTextContainer}>
                <WarningRed width={15} />
                <FormHelperText sx={combineStyles(styles.helperText, styles.errorText)}>{errors.withdrawToAddress}</FormHelperText>
              </Box>
            )}
            {!errors.withdrawToAddress && (
              <Box sx={styles.helperTextContainer}>
                <WarningRed className="infoIcon" width={15} />
                <FormHelperText sx={combineStyles(styles.helperText, styles.infoText)}>Make sure that this is the correct wallet address.</FormHelperText>
              </Box>
            )}
          </FormControl>
        </Box>
        <Box display="flex" alignItems="center" width="100%">
          <InputLabel htmlFor="address">
            <Typography variant="body1" color="primary" sx={styles.inputLabel}>Reason</Typography>
          </InputLabel>
          <FormControl fullWidth>
            <OutlinedInput
              sx={combineStyles(styles.smallInput, { ...(errors.withdrawalReason) && { borderColor: "#F75E5E!important", } })}
              id="withdrawalReason"
              name="withdrawalReason"
              placeholder="Up to 70 characters"
              value={formState.withdrawalReason}
              onChange={handleChange}
            />
            {!!errors.withdrawalReason && (
              <Box sx={styles.helperTextContainer}>
                <WarningRed width={15} />
                <FormHelperText sx={combineStyles(styles.helperText, styles.errorText)}>{errors.withdrawalReason}</FormHelperText>
              </Box>
            )}
          </FormControl>
        </Box>

        <Divider sx={styles.dialogDivider} />

        <Box sx={styles.inputRow}>
          <Dropdown options={tokenList.reverse()}
            defaultValueSelected={formState.withdrawalType.toUpperCase()}
            onSelected={handleTypeChange}
            sx={styles.tokenDropdown}
            paperStyles={styles.tokenDropdownPortal}
          />
          {/* <Box> */}
          <FormControl fullWidth sx={styles.formField}>
            <Box sx={combineStyles(styles.input, styles.numInputBox)}>
              <IconButton onClick={() => handleSubtract('withdrawalAmount')}>
                <RemoveIcon height={24} width={24} />
              </IconButton>

              <Box sx={styles.numInput}>
                <TextField
                  name={'withdrawalAmount'}
                  onChange={handleChange}
                  value={formState['withdrawalAmount'].toString()}
                  InputProps={{ inputComponent: (NumberFormat.NumberFormatSeparator) as any }}
                  sx={combineStyles(styles.input, styles.settingNumInput)} />
              </Box>

              <IconButton onClick={() => handleAdd('withdrawalAmount')}>
                <AddIcon height={24} width={24} />
              </IconButton>
            </Box>
            {!!errors.withdrawalAmount && (
              <Box sx={styles.helperTextContainer}>
                <WarningRed width={15} />
                <FormHelperText sx={styles.errorText}>{errors.withdrawalAmount}</FormHelperText>
              </Box>
            )}
          </FormControl>

          {/* </Box> */}
        </Box>


        <Typography variant="body1" color="primary" component="span" sx={styles.customBalanceRow}>
          Bank Balance: {getTypeBalance} &nbsp;
          <Button variant="outlined" size="small" color="secondary" onClick={handleMaxInput} sx={styles.maxButton}>
            <Typography variant="button" sx={styles.maxGradientText}>
              MAX
            </Typography>
          </Button>
        </Typography>


        <Divider sx={styles.dialogDivider} />
        <Box sx={styles.rateRow}>
          <Typography variant="body1" color="primary">Total</Typography>
          <Typography variant="h3" color="primary">${(formState.withdrawalAmount).toFormat(2)}&nbsp;{(tokenList.find((t) => t.value === formState.withdrawalType.toUpperCase()))?.icon}</Typography>
        </Box>


        <Box sx={styles.buttonRow}>
          <ContainedButton
            sx={combineStyles(styles.button, { flex: 0.6 })}
            onClick={handleInitiateWithdrawal}
            disabled={!isInitiateEnabled}
          >
            {
              loadingWithdraw
                ? <CircularProgress size={18} />
                : "initiate withdrawal"
            }
          </ContainedButton>
        </Box>



      </Box>
    )
  }


  if (!guild) return null;
  return (
    <DialogModal
      header={getHeader()}
      open={open}
      onClose={handleOnClose}
      sx={styles.dialogModal}
      disableScrollLock={true}
      dialogOverwrites={styles.dialogOverwrite}>
      {getContent()}
    </DialogModal>
  )
}



const styles: SimpleMap<SxProps<AppTheme>> = {
  // Numerical Input
  formField: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'end',
    placeItems: 'center',
    flex: 1,

    "*": {
      height: '100%',
    }
  },
  numInputBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    width: "100%",
    gap: '24px',
  },
  numInput: {
    flex: 1,
    width: '100%',
  },
  settingNumInput: {
    background: 'transparent',
    border: 0,
    flex: 0.4,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',

    "& input": {
      paddingX: '0',
      width: "100%",
      textAlign: "center",
    },
  },
  input: {
    minHeight: "90px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",

    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },

    "&:hover, &:active, &:focus, &:focus-within": {
      borderColor: '#AEF1EE',
    },

    "& input": {
      fontFamily: "Prompt",
      fontSize: "24px",
      lineHeight: "36px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      paddingY: "12px",

      "&:active, &:focus": {
        color: "#AEF1EE",
        "& input": {
          color: "#AEF1EE",
        },
      }
    },

  },

  //INFO: Balance row
  customBalanceRow: {
    marginLeft: 'auto',
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    fontSize: '18px',
    lineHeight: '28px',
  },
  maxButton: {
    borderRadius: '16px',
  },
  maxGradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },

  dialogDivider: {
    background: 'rgba(174, 241, 238, 0.1)',
  },
  rateRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    "h3": {
      display: 'flex',
      flexDirection: 'row',
      placeItems: 'center',
    },

    "svg": {
      width: '24px',
      height: '24px',
    }
  },

  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px',
    gap: '32px',
    alignItems: 'center',
    placeContent: 'center'
  },
  button: {
    minWidth: '250px',
    flex: 1,
    height: 64,
  },


  //INFO: Dialog Modal
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        background: 'green',
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "center",
    padding: "0 40px",
    gap: '32px',

    "> *": {
      width: '100%',
    },
  },
  multisigContent: {
    "> *": {
      width: 'auto',
    },

    ".joinInfo": {
      textAlign: 'center',
      fontSize: '24px',
      lineHeight: '150%',

      ".hunyAmount": {
        display: 'inline-flex',
        placeContent: 'center',
        placeItems: 'center',
        verticalAlign: 'middle',
        gap: '8px',
        flexWrap: 'nowrap',

        fontWeight: 700,
        ...GUILD_LIGHTGRAY_GRADIENT,
      },

      ".warningText": {
        color: "#ff8952",
      },

      ".highlightText": {
        color: '#2FF583',
      },

      "button": {
        padding: '0 4px',
      }
    },
  },
  subText: {
    fontSize: "24px",
    textAlign: "center",
    lineHeight: "36px"
  },
  signaturesRequiredText: {
    fontSize: "24px",
    textAlign: "center",
    lineHeight: "36px",
    color: "#FF8952",
    display: 'inline-block'
  },

  inputRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'flex-start',
    gap: '24px',
  },
  tokenDropdown: {
    height: '90px',
    ".MuiInputBase-root": {
      width: '220px',
      height: '100%',
    },
    ".MuiSelect-select": {
      width: '100%',
      height: '100%!important',
      fontSize: '24px!important',
      fontWeight: 700,
      fontFamily: 'Syne',
      textTransform: 'uppercase',
      paddingY: '0 !important',
      "svg": {
        width: '40px',
        height: '40px',
      },
    }
  },
  tokenDropdownPortal: {
    ".MuiMenuItem-root": {
      "svg": {
        width: '24px',
        height: '24px',
      },
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '28px',
      fontFamily: 'Prompt',
      textTransform: 'uppercase',
      paddingY: 0,
      margin: 'revert',

      ...GUILD_GRAY_GRADIENT,
    }
  },

  viewTx: {
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: 600,
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  warningBox: {
    marginBottom: '0',
  },
  inputLabel: {
    fontSize: "14px",
    lineHeight: "28px",
    width: "250px",
  },
  smallInput: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    color: "rgba(255,255,255,0.8)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      fontWeight: 600,
      padding: "12px 24px",
    },
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
      color: "#fff",
    },
    "&.MuiInputAdornment-positionStart": {
      color: "#fff",
    }
  },
  dialogOverwrite: {
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  helperTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
    ".infoIcon": {
      "path:first-of-type": {
        fill: "#fff"
      },
      "path:not(:first-of-type)": {
        fill: "#000"
      }
    }
  },
  helperText: {
    fontFamily: "Prompt",
    marginX: 0,
    marginLeft: "8px",
  },
  errorText: {
    color: "#F65E5E",
  },
  infoText: {
    color: "rgba(255, 255, 255, 0.8)",
  },
}

export default WithdrawDialog;