import { Box, CircularProgress, Divider, IconButton, InputAdornment, OutlinedInput, Slider, SxProps, Typography } from "@mui/material";
import { AddIcon, RemoveIcon } from "assets";
import ContainedButton from "components/ContainedButton";
import ExchangeRate from 'components/ExchangeRate';
import { waitForTx } from "core/utilities";
import React, { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { TBMConnector } from "tbm";
import { useBlockTime, useToaster } from "utils";
import { BIG_ZERO, BLOCKS_PER_MINUTE, Decimals } from "utils/constants";
import { bnOrZero } from "utils/strings/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useRedux from "utils/useRedux";

const Withdraw: React.FC = () => {
  const dispatch = useDispatch();
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const hivePool = useRedux((state) => state.token.hivePool);
  const lastSignificantDeposit = useRedux((state) => state.token.hivePool?.lastSignificantDeposit)

  const rates = tokenState.ExchangeRates;
  const toaster = useToaster();
  const [sliderAmount, setSliderAmount] = useState<number>(0);
  const [runWithdraw, loadingWithdraw] = useAsyncTask("withdraw", (error) => {
    toaster(error?.message ?? "Error Withdrawing");
  });

  const userHunyReserves = bnOrZero(hivePool?.userHunyReserves);
  const userZilReserves = bnOrZero(hivePool?.userZilReserves);
  const userBalance = bnOrZero(hivePool?.userBalance);
  const [, currentBlock] = useBlockTime();

  const { hunyAmount, zilAmount, removeContribution, removePercent, withdrawalPercentage } = useMemo(() => {
    const removePercent = bnOrZero(sliderAmount).shiftedBy(-2)
    const removeContribution = removePercent.times(userBalance);

    const hunyAmount = userHunyReserves.times(removePercent).dp(0).shiftedBy(-Decimals.HUNY);
    const zilAmount = userZilReserves.times(removePercent).dp(0).shiftedBy(-Decimals.ZIL);

    let withdrawalPercentage: number = 0.05
    if (!!lastSignificantDeposit) {
      const blockElapsed = currentBlock - lastSignificantDeposit;
      const daysElapsed = Math.floor(blockElapsed / (BLOCKS_PER_MINUTE * 60 * 24));
      withdrawalPercentage = withdrawalPercentage = Math.max(0.05 - daysElapsed * 0.001, 0);
    }

    return { hunyAmount, zilAmount, removeContribution, removePercent, withdrawalPercentage };
  }, [userHunyReserves, userZilReserves, userBalance, sliderAmount, currentBlock, lastSignificantDeposit]);

  const {
    newPoolShare,
    newPoolValue,
  } = useMemo(() => {
    let newPoolShare = "-";
    let newPoolValue = "-";
    const zilAmountUnitless = zilAmount.shiftedBy(Decimals.ZIL);
    const newZilReserves = hivePool?.zilReserves.minus(zilAmountUnitless);
    const newUserZilReserves = hivePool?.userZilReserves.minus(zilAmountUnitless);
    if (!newZilReserves?.gt(0)) return { newPoolShare, newPoolValue };
    newPoolShare = newUserZilReserves?.div(newZilReserves).shiftedBy(2).toFormat(2) ?? "-";

    const hunyAmountUnitless = hunyAmount.shiftedBy(Decimals.HUNY);
    const newUserHunyReserves = hivePool?.userHunyReserves.minus(hunyAmountUnitless);
    const newUserZilValue = newUserZilReserves?.shiftedBy(-Decimals.ZIL).times(rates.zilPrice);
    const newUserHunyValue = newUserHunyReserves?.shiftedBy(-Decimals.HUNY).times(rates.hunyPrice);
    newPoolValue = newUserZilValue?.plus(newUserHunyValue ?? BIG_ZERO).toFormat(2) ?? "-";

    return { newPoolShare, newPoolValue };
  }, [hivePool, zilAmount, hunyAmount, rates]);

  const totalValue = useMemo(() => {
    const zilValue = hivePool?.userZilReserves.shiftedBy(-Decimals.ZIL).times(rates.zilPrice);
    const hunyValue = hivePool?.userHunyReserves.shiftedBy(-Decimals.HUNY).times(rates.hunyPrice);
    return hunyValue?.plus(zilValue ?? BIG_ZERO);

    // eslint-disable-next-line
  }, [rates, hivePool]);

  const removeValue = useMemo(() => {
    const hunyValue = hunyAmount.times(rates.hunyPrice);
    const zilValue = zilAmount.times(rates.zilPrice);
    return hunyValue?.plus(zilValue ?? BIG_ZERO);

    // eslint-disable-next-line
  }, [hunyAmount, zilAmount]);

  const handleChange = (_: Event, newValue: number | number[]) => {
    setSliderAmount(newValue as number);
  };

  const handleAddQty = () => {
    if (sliderAmount === 100) return;
    setSliderAmount(sliderAmount + 1);
  };

  const handleSubtractQty = () => {
    if (sliderAmount === 0) return;
    setSliderAmount(sliderAmount - 1);
  };

  const handleWithdraw = () => {
    runWithdraw(async () => {
      await withdraw();
    })
  }

  const withdraw = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    const expectedZilAmount = userZilReserves.times(removePercent);
    const expectedTokenAmount = userHunyReserves.times(removePercent);

    // TODO: remove slippage hardcode
    const minZilAmount = expectedZilAmount.dividedToIntegerBy(1.5);
    const minTokenAmount = expectedTokenAmount.dividedToIntegerBy(1.5);

    const zilswap = TBMConnector.getSDK();

    const result = await TBMConnector.removeHiveLiquidity(
      wallet,
      removeContribution.decimalPlaces(0),
      minZilAmount.decimalPlaces(0),
      minTokenAmount.decimalPlaces(0),
    );

    zilswap.observeTx({
      hash: result.id!,
      deadline: Number.MAX_SAFE_INTEGER,
    });

    // TODO: toaster
    toaster(`Submitted Remove Liquidity `, { hash: result.id, overridePersist: true });

    await waitForTx(result.id!);
    dispatch(actions.Token.reloadHivePool());
  }

  return (
    <Fragment>
      <Typography variant="body1" color="primary" sx={styles.subheader}>
        Welcome to the Magic Hive! You may withdraw your HUNY below with a 5% sticky fee.
      </Typography>
      <Box sx={styles.withdrawContainer}>
        <Box sx={styles.amountRow}>
          <Typography variant="h3" color="primary" sx={styles.amountText}>AMOUNT</Typography>
          <Box sx={styles.amountBox}>
            <OutlinedInput
              sx={styles.qtyInput}
              value={sliderAmount.toString() + "%"}
              type="text"
              inputProps={{ min: "1", style: { textAlign: "center" } }}
              disabled
              startAdornment={
                <InputAdornment position="start">
                  <IconButton onClick={handleSubtractQty}>
                    <RemoveIcon style={{ height: 22, width: 22 }} />
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleAddQty}>
                    <AddIcon style={{ height: 22, width: 22 }} />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Typography variant="body1" color="primary">
              Balance: {" "}
              {userHunyReserves.shiftedBy(-Decimals.HUNY).toFixed(4)} HUNY
              {" + "}
              {userZilReserves.shiftedBy(-Decimals.ZIL).toFixed(4)} ZIL
              {" "}
              (≈${totalValue?.toFormat(2)})
            </Typography>
          </Box>
        </Box>
        <Slider
          aria-label="Default"
          valueLabelDisplay="auto"
          min={0}
          max={100}
          value={sliderAmount}
          onChange={handleChange}
          sx={styles.slider}
        />
        <Divider sx={styles.divider} />
        <Box sx={styles.amountRow}>
          <Typography variant="body1" color="primary">Withdraw Fee ({withdrawalPercentage * 100}%)</Typography>
          <Typography variant="h3" color="primary">≈${removeValue.times(withdrawalPercentage).toFormat(2)}</Typography>
          {/* Withdrawal percentage */}
        </Box>
        <Box sx={styles.balanceRow}>
          <Typography variant="body1" color="primary">You Will Receive</Typography>
          <Box sx={styles.amountBox}>
            <Typography variant="h3" color="primary">{hunyAmount.times(1 - withdrawalPercentage).toFormat(4)} HUNY + {zilAmount.times(1 - withdrawalPercentage).toFormat(4)} ZIL</Typography>
            <Typography variant="h3" color="primary">(≈${removeValue.times(1 - withdrawalPercentage).toFormat(2)})</Typography>
          </Box >
        </Box >
      </Box >
      <ExchangeRate my={2} />
      <Box sx={styles.withdrawRow}>
        <Typography variant="body1" color="primary">New Pool Share</Typography>
        <Typography variant="h3" color="primary" component="span">${newPoolValue} ({newPoolShare}%)</Typography>
      </Box>
      <Box sx={styles.buttonWrapper}>
        <ContainedButton sx={styles.button} disabled={sliderAmount === 0 || removePercent.isEqualTo(0) || userZilReserves.eq(0) || userHunyReserves.eq(0)} onClick={handleWithdraw}>
          {loadingWithdraw
            ? <CircularProgress size={18} />
            : "withdraw"
          }
        </ContainedButton>
      </Box>
    </Fragment >
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  subheader: {
    fontSize: '1.5rem',
    marginBottom: '20px',
  },
  withdrawContainer: {
    minHeight: '200px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    border: '1px solid #AEF1EE33',
    borderRadius: '16px',
    padding: '40px',
    marginBottom: '20px',
  },
  amountRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  amountText: {
    marginBottom: '40px',
  },
  qtyInput: {
    marginBottom: '20px',
    width: '400px',
    height: 60,
    border: '1px solid rgba(174, 241, 238, 0.1)',
    borderRadius: '16px',
    color: '#FEFEFE',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    "& input": {
      height: '30px',
      fontSize: '24px',
      fontWeight: 600,
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": 'none',
        margin: 0,
      },
      "&:[type=number]": {
        "-moz-appearance": 'textfield',
      },
    },
    "&.Mui-focused": {
      caretColor: '#FEFEFE',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 'none',
    },
    "@media (max-width:900px)": {
      width: '320px',
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: '#FEFEFE',
      "-webkit-text-fill-color": '#FEFEFE !important',
    },
  },
  amountBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  slider: {
    color: '#52af77',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
      background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
  },
  divider: {
    background: 'rgba(174, 241, 238, 0.1)',
    height: '2px',
    marginY: '10px',
  },
  balanceRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '20px',
  },
  withdrawRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    minWidth: 250,
    height: 60,
  }
}

export default Withdraw;
