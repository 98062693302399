import { Guild, Profile } from "store/types";

export const sortedMembers = (members: Profile[], leaderAddress: string, commanderAddresses: string[]): Profile[] =>
  members
    .sort((a: Profile, b: Profile) => (b.totalHunyHolding ?? 0).toNumber() - (a.totalHunyHolding ?? 0).toNumber())
    .sort((a: Profile, b: Profile) => {
      if (commanderAddresses.includes(a.address) && !commanderAddresses.includes(b.address)) return -1;
      if (!commanderAddresses.includes(a.address) && commanderAddresses.includes(b.address)) return 1;
      return 0;
    })
    .sort((a: Profile) => (a.address === leaderAddress) ? -1 : 0);

export const sortedGuilds = (guilds: Guild[]): Guild[] =>
  guilds
    .map(guild => ({
      ...guild,
      members: sortedMembers(guild.members, guild.leaderAddress, guild.commanderAddresses),
    }))
    .sort((a: Guild, b: Guild) => b.strength - a.strength);
