import { Box, CircularProgress, SxProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { waitForTx } from "core/utilities";
import { MetazoaClient } from "core/utilities/metazoa";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { Guild, OAuth, Profile } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  guild: Guild;
  reload: () => void;
  member: Profile;
}

const PromoteRequestDialog: FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, guild, member, reload } = props;
  const { guildBank, leaderAddress, commanderAddresses } = guild;
  const canPromote = ![leaderAddress, ...commanderAddresses].includes(member.address)
  const hasGuildBank: boolean = !!guildBank ?? false;

  const toaster = useToaster();
  const network = useNetwork();
  const dispatch = useDispatch();
  const wallet = useRedux((state) => state.wallet.wallet);
  const metazoaProfileState = useSelector(getProfile);

  // STATES / HOOKS -----------------

  const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
  const [runProcessRequest, loadingProcessRequest] = useAsyncTask("requestRankChange", (error) => {
    onClose();
    toaster(error?.message ?? "Error Processing Request");
  });

  // FUNCTIONS ----------------------

  const requestRankChange = async (memberAddr: string, isPromote: boolean) => {
    if (!wallet) throw new Error("Wallet not connected");
    if (hasGuildBank && !!guildBank?.address) {
      if (!guildBank) throw new Error("Guild bank not initialized");
      const requestTx = await TBMConnector.handleGuildCommanders(toBech32Address(guildBank.address), memberAddr, isPromote);
      toaster(`Submitted Member Rank Change `, { hash: requestTx.id! });
      if (requestTx.isRejected() || !requestTx.id) {
        onClose();
        throw new Error("Submitted transaction was rejected.");
      }

      const tx = await waitForTx(requestTx.id);
      const tbmConnector = TBMConnector.getSDK();
      const txn = await tbmConnector.zilliqa.blockchain.getTransaction(requestTx.id);
      const receipt = txn.getReceipt();
      if (!receipt || !receipt?.success || tx.status >= 3) {
        onClose();
        throw new Error(`Error ${canPromote ? 'Promoting' : 'Demoting'} Member`);
      }

      setRequestCompleted(true);
      toaster(`${canPromote ? 'Promoted' : 'Demoted'} Member `, { hash: requestTx.id! })
      handleOnClose();
      reload();
    }
    else {
      const metazoaClient = new MetazoaClient(network);
      const { oAuth } = metazoaProfileState;

      let checkedOAuth: OAuth | undefined = oAuth;
      if (!oAuth?.access_token || oAuth.address !== wallet.addressInfo.bech32 || (oAuth && dayjs(oAuth?.expires_at * 1000).isBefore(dayjs()))) {
        const { result } = await metazoaClient.metazoaLogin(wallet, window.location.hostname);
        dispatch(actions.Profile.updateAccessToken(result));
        checkedOAuth = result;
      }

      await metazoaClient.handleGuildCommanders(guild.id, { commander: memberAddr, guildId: guild.id, isPromote }, checkedOAuth!);
      setRequestCompleted(true);
      toaster(`${canPromote ? 'Promoted' : 'Demoted'} Member `)
      handleOnClose();
      reload();
    }
  }

  // EVENT HANDLERS -----------------

  const handleOnClose = () => {
    if (isLoading) return;
    setRequestCompleted(false);
    onClose();
  }

  const handleRankChange = (memberAddr: string, isPromote: boolean) => {
    runProcessRequest(async () => {
      await requestRankChange(memberAddr, isPromote);
    });
  }

  // --------------------------------

  const isLoading: boolean = loadingProcessRequest && !requestCompleted;

  if (!member) return null;
  return (
    <DialogModal header={canPromote ? "Promote to Officer?" : "Demote Officer?"} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true}>
      <Box sx={styles.dialogContent}>
        {!isLoading && (<>
          <Typography sx={styles.warningText}>
            {canPromote ? "Upon confirmation, this user will be promoted to Officer and have full admin access on your Guild’s Discord channel." : "Upon confirmation, this user will be demoted to Member and their admin access to your Guild’s Discord channel will be revoked."}
          </Typography>
          <ContainedButton
            disabled={isLoading}
            onClick={() => handleRankChange(member.address, canPromote)}
            sx={styles.confirmButton}
          >
            {isLoading ? <CircularProgress size={18} /> : `Confirm & ${canPromote ? "Promote" : "Demote"}`}
          </ContainedButton>
        </>)}

        {isLoading && (<>
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </>)}
      </Box>
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },

  dialogContent: {
    maxWidth: "600px",
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "center",
  },

  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
    margin: "40px 0",
  },

  loadingImage: {
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },

  confirmButton: {
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
}

export default PromoteRequestDialog;
