import { Box, Container, Fade, styled, SxProps, Typography } from "@mui/material";
import LineBlue from "assets/Blue.svg";
import LineOrange from "assets/Orange.svg";
import PanelFrame from "assets/PanelFrame.svg";
import CornerPlanet from "assets/quests/CornerPlanet.png";
import ExplodingPlanet from "assets/quests/explodingPlanet.png";
import PlanetCluster from "assets/quests/planetCluster.png";
import TwoPlanet from "assets/quests/twoPlanet.png";
import ContainedButton from "components/ContainedButton";
import { FC, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions } from "store";
import { theme } from "theme";
import { MissionGroundLocation } from "utils/constants";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import { LOCATIONS } from "../QuestConstants";

const MapView: FC = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mapLocations: MissionGroundLocation[] = Object.values(MissionGroundLocation);

  // STATES / HOOKS -----------------

  const [hoverLocation, setHoverLocation] = useState<MissionGroundLocation>();
  useMemo(() => {
    // if (!hoverLocation) return;
    dispatch(actions.Layout.updateMapBackground(""));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // FUNCTIONS ----------------------

  // EVENT HANDLERS -----------------

  // --------------------------------

  return (
    <Container sx={styles.root}>
      <SvgBox>
        <Box component="img" src={PlanetCluster} alt="" sx={styles.planetClusterBg} />
        <Box component="img" src={ExplodingPlanet} alt="" sx={styles.explodingPlanetBg} />
        <Box component="img" src={TwoPlanet} alt="" sx={styles.twoPlanetBg} />
        <Box component="img" src={CornerPlanet} alt="" sx={styles.cornerPlanetBg} />
        <Box component="img" src={LineOrange} alt="" sx={styles.lineOrangeBg} />
        <Box component="img" src={LineOrange} alt="" sx={styles.lineOrangeBg2} />
        <Box component="img" src={LineBlue} alt="" sx={styles.lineBlueBg} />
        <Box component="img" src={LineBlue} alt="" sx={styles.lineBlueBg2} />
      </SvgBox>
      <Box sx={styles.islandsWrapper}>
        {mapLocations.map((location, idx) => (
          <Fade
            key={location}
            in={true}
            style={{ transformOrigin: '0 0 0', transitionDelay: `${200 * idx}ms` }}
            {...(true ? { timeout: 1000 } : {})}
          >
            <Box sx={combineStyles(
              styles.islandWrapper,
              {
                zIndex: (hoverLocation === location) ? 10000 : ((20 * mapLocations.length) - (10 * (idx + 1))),
                animation: (hoverLocation === location) ? 'none' : `lethargy ${10 + idx}s infinite`,

                [theme.breakpoints.up('md')]: {
                  position: 'absolute',
                  ...LOCATIONS[location].assets.vector.jsx,
                }
              }
            )}
              onMouseOver={() => setHoverLocation(location)}
              onMouseOut={() => setHoverLocation(undefined)}
            >
              <Box
                component="img"
                src={hoverLocation === location ? LOCATIONS[location].assets.vector.glowImageSrc : LOCATIONS[location].assets.vector.imageSrc}
                className="vectorImg"
                sx={{
                  position: 'relative',
                  width: '95%',
                  transition: 'all linear 0.7s',
                  transform: (hoverLocation === location) ? 'scale(1.2)' : 'scale(1.1)',
                  opacity: (!hoverLocation || hoverLocation === location) ? 1 : 0.5,
                }}
              />

              <Box
                component="main"
                sx={combineStyles(
                  styles.locationWrapper,
                  {
                    visibility: hoverLocation === location ? 'visible' : 'hidden',
                  },
                )}>
                <Box
                  sx={combineStyles(
                    styles.frameWrapper, {
                    ...(!!LOCATIONS[location].assets.frame) && {
                      borderImage: `url(${LOCATIONS[location].assets.frame})`,
                      backgroundImage: `url(${LOCATIONS[location].assets.frame})`,
                    },
                  }

                  )}>
                  <Typography color="primary"
                    sx={{
                      fontSize: '14px',
                    }}>
                    {LOCATIONS[location].assets.ctaText}</Typography>
                  <ContainedButton
                    sx={{
                      background: LOCATIONS[location].assets.gradient,
                      whiteSpace: 'nowrap',
                      padding: '6px 10px',
                      width: '100%',
                    }}
                    onClick={() => navigate(`/map/${location}`)}
                  >
                    <Typography sx={{
                      fontWeight: '600',
                      fontSize: '14px',
                    }}>To&nbsp;{LOCATIONS[location].name}</Typography>

                  </ContainedButton>
                </Box>
              </Box>
            </Box>
          </Fade>
        ))}
      </Box>

    </Container>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  root: {
    position: "relative",
    padding: "0 0 100px!important",
    width: '100%',
    height: '100%',
    // //DEBUG:Remove this
    // "*": {
    //   border: '1px solid red',
    // },
  },

  islandsWrapper: {
    // background: 'green',
    height: '100%',
    marginTop: "160px",
    padding: '24px 0',
    minHeight: '900px',
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    placeItems: 'center',
    placeContent: 'start',
    gap: '6em',
  },

  islandWrapper: {
    maxWidth: '90%',
    margin: '0 auto',
    display: 'flex',
    placeItems: 'center',
    placeContent: 'center',
    position: 'relative',
  },

  locationWrapper: {
    // background: 'orange',
    position: 'absolute',
    inset: 0,
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    margin: '0 auto',
    maxWidth: '320px',
    width: '90%',
  },

  frameWrapper: {
    // background: 'pink',
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    placeItems: 'center',
    textAlign: 'center',
    gap: 2,
    border: '12px double transparent',
    borderImage: `url(${PanelFrame})`,
    borderImageSlice: '20',
    borderImageWidth: '20px',
    borderImageOutset: '0px',
    borderImageRepeat: 'stretch stretch',
    padding: '10px 20px',
    position: 'relative',
    background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
    backgroundImage: `url(${PanelFrame})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1000%',
    backgroundPosition: 'center',
    backgroundClip: 'padding-box',
    maxWidth: '100%',
  },

  planetClusterBg: {
    position: 'absolute',
    top: '150px',
    left: '30%',
  },
  explodingPlanetBg: {
    position: 'absolute',
    top: '120px',
    left: '100%',
  },
  twoPlanetBg: {
    position: 'absolute',
    top: '400px',
    left: '100%',
  },
  cornerPlanetBg: {
    position: 'absolute',
    top: '600px',
    left: '-10%',
    mixBlendMode: 'color-dodge',
  },

  lineOrangeBg: {
    position: 'absolute',
    top: '400px',
    left: '-10%',
  },

  lineOrangeBg2: {
    position: 'absolute',
    top: '300px',
    left: '100%',
  },

  lineBlueBg: {
    position: 'absolute',
    left: '50%',
  },

  lineBlueBg2: {
    position: 'absolute',
    left: '60%',
    top: '800px',
  }
};

const SvgBox = styled(Box)({
  pointerEvents: 'none',
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
});

export default MapView;
