import { toBech32Address } from "@zilliqa-js/zilliqa";
import { logger } from "core/utilities";
import { MetazoaClient } from "core/utilities/metazoa";
import dayjs from "dayjs";
import { call, delay, fork, put, race, select, take, takeLatest } from "redux-saga/effects";
import { waitForConnectorInit, waitForNetworkChange } from "saga/helpers";
import { getBlockchain, getWallet } from "saga/selectors";
import { actions } from "store";
import { Allowance, GameEvent, GameStats, Guild, Profile } from "store/types";
import { TBMConnector, ZolarEventSubscriber } from "tbm";
import { bnOrZero } from "utils/strings/strings";

function* loadProfile() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading("loadProfile", "METAZOA:LOAD_PROFILE"));
    const { wallet } = getWallet(yield select());
    const { network } = getBlockchain(yield select());

    const metazoaClient = new MetazoaClient(network);
    if (!wallet) throw new Error("invalid wallet");
    const { result: { model } } = (yield call(metazoaClient.getProfile, wallet.addressInfo.byte20.toLowerCase()));
    if (model.guildId) {
      const guild = (yield call(metazoaClient.guildDetail, model.guildId)).result.model as Guild;
      model.guildName = guild.name;
    }

    // Populdate guild details
    if (model.guildId) {
      const guild = (yield call(metazoaClient.guildDetail, model.guildId)).result.model as Guild;
      model.guildName = guild.name;
    }

    // Populate exp details
    model.level = model.level ?? {
      level: 1,
      xpGained: 0,
      xpRequired: 50,
    };

    yield put(actions.Profile.updateProfile(model as Profile));

  } catch (error) {
    console.error("loading profile failed, Error:");
    console.error(error);
    yield put(actions.Profile.updateProfile(undefined));
    yield delay(3000);
  } finally {

    yield put(actions.Layout.removeBackgroundLoading("METAZOA:LOAD_PROFILE"));
  }
}

function* watchProfile() {
  yield takeLatest([
    actions.Profile.MetazoaProfileActionTypes.LOAD_PROFILE,
    actions.Wallet.WalletActionTypes.WALLET_UPDATE,
  ], loadProfile);
}

function* watchLeaderboard() {
  while (true) {
    try {
      yield waitForConnectorInit();
      const network = TBMConnector.network();
      const info: GameStats = yield TBMConnector.fetchGameStats();
      const commanders = info.commanders;
      const tokenTraits = info.tokenTraits;
      const lastMetazoaId = info.lastMetazoaId;
      const hunyHolders = info.hunyHolders;
      const totalHuny = info.totalHuny;

      logger("leaderboard", "load metazoa", commanders);
      logger("leaderboard", "token traits", tokenTraits);
      logger("leaderboard", "last metazoa ID", lastMetazoaId);

      yield put(actions.Game.update({ commanders, hunyHolders, totalHuny, tokenTraits, lastMetazoaId }));

      yield race({
        delay: delay(30000),
        network: waitForNetworkChange(network),
        reload: take(actions.Game.GameActionTypes.RELOAD_LEADERBOARD),
      });
    } catch (error) {
      console.error("failed to load leaderboard");
      console.error(error);
      yield delay(3000);
    } finally {
    }
  }
}

function* watchIncomingEvents(): Generator<any> {
  while (true) {
    try {
      const action: any = yield take(actions.Game.GameActionTypes.PROCESS_EVENT);
      const events: ZolarEventSubscriber.ZolarEvent[] = action.payload;

      const concludeActionEvent = events.find(event => event.type === ZolarEventSubscriber.ZolarEventType.ActionConcluded);
      const [actionId, actionOwnerAddress] = concludeActionEvent?.params.action.arguments ?? []

      for (const event of events) {
        switch (event.type) {
          case ZolarEventSubscriber.ZolarEventType.HunyStolen: {
            const from = toBech32Address(actionOwnerAddress);
            const amount = bnOrZero(event.params.amount);
            const gameEvent: GameEvent = {
              type: event.type,
              timestamp: dayjs(),
              actionId,
              from,
              amount,
            }
            yield put(actions.Game.addNewEvent(gameEvent));
            break;
          }
          case ZolarEventSubscriber.ZolarEventType.MetazoaKidnapped: {
            const from = toBech32Address(actionOwnerAddress);
            const to = event.params.new_commander ? toBech32Address(event.params.new_commander) : "-";
            const gameEvent: GameEvent = {
              type: event.type,
              timestamp: dayjs(),
              actionId,
              from,
              tokenId: event.params.token_id,
              to,
            }
            yield put(actions.Game.addNewEvent(gameEvent));
            break;
          }
          case ZolarEventSubscriber.ZolarEventType.HunyCaptured:
            break;
        }
      }

    } catch (error) {
      console.error("failed to process incoming event");
      console.error(error);
      yield delay(3000);
    } finally {
    }
  }
}

function* loadAllowances() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading("loadAllowances", "METAZOA:LOAD_ALLOWANCES"));

    const allowances: Allowance = (yield call(TBMConnector.queryAllowances));
    yield put(actions.Guild.updateAllowances(allowances));
    logger("debug-saga", "loadAllowances", allowances);

  } catch (error) {
    console.error("ERROR: Unable to load allowances.\n", error);
    yield delay(3000);
  } finally {
    yield put(actions.Layout.removeBackgroundLoading("METAZOA:LOAD_ALLOWANCES"));
  }
}

function* watchAllowances() {
  yield takeLatest([
    actions.Guild.MetazoaGuildActionTypes.LOAD_ALLOWANCES,
    actions.Blockchain.BlockchainActionTypes.SET_NETWORK,
  ], loadAllowances)
}

export default function* metazoaSaga() {
  logger("init metazoa profile saga");
  yield fork(watchLeaderboard);
  yield fork(watchProfile);
  yield fork(watchIncomingEvents);
  yield fork(watchAllowances);
}
