import React, { useMemo } from "react";
import {
  Box,
  Button, Link, styled, SxProps, Typography
} from "@mui/material";
import Elderberry from "assets/Elderberry.png";
import PhaseImage from "assets/icons/PhaseImage.png";
import PhaseImageGray from "assets/icons/PhaseImageGray.png";
import Upcoming from "assets/icons/Upcoming.png";
import { NavLink } from "react-router-dom";
import { CompleteTag, DiscordDark, LinesPhase1, LiveTag, LiveTagDated } from "assets";
import { AppTheme, SimpleMap } from "utils/types";
import dayjs from "dayjs";
import useRedux from "utils/useRedux";
import { SaleTime } from "utils/constants";
import useNetwork from 'utils/useNetwork';
import ProgressBar from "../components/ProgressBar";

const PhaseOne: React.FC = () => {
  const network = useNetwork();
  const tokenState = useRedux((state) => state.token);
  const totalSupply = tokenState.totalSupply;
  const goldRushCurrentSupply = tokenState.goldRushCurrentSupply;

  // gold rush sale start & end times
  const saleStartTime = dayjs.unix(SaleTime[network].start / 1000);
  const saleEndTime = dayjs.unix(SaleTime[network].end / 1000);

  const currentTime = dayjs();

  const phaseEnded = useMemo(() => {
    return saleEndTime.isBefore(currentTime) || (totalSupply > 0 && totalSupply === goldRushCurrentSupply);

    // eslint-disable-next-line
  }, [currentTime, goldRushCurrentSupply, totalSupply])

  return (
    <Box>
      <Box maxWidth="xl" sx={styles.phaseContainer}>
      {/* Nav between phases */}
        <Box sx={styles.phaseRow}>
          <Box sx={styles.phaseLinesBG}>
            <LinesPhase1 width="100%"/>
          </Box>
          <Box component={NavLink} to="/summon/elders" sx={styles.phaseBox}>
            <img src={PhaseImage} alt="phase" />
            <Typography variant="h3" color="primary">Phase 1</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheader}>the elders</Typography>
            <CompleteTag/>
          </Box>
          <Box component={NavLink} to="/summon/transcendence" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="/phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 2</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>The Transcendence</Typography>
            <CompleteTag/>
          </Box>
          <Box component={NavLink} to="/summon/gold-rush" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 3</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>The gold rush</Typography>
            {phaseEnded
              ? <CompleteTag/>
              : currentTime.isAfter(saleStartTime)
                ? <LiveTagDated/>
                : <img src={Upcoming} alt="upcoming"/>
            }
          </Box>
          <Box component={NavLink} to="/summon/takers-of-the-moon" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 4</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>TAKERS OF THE MOON</Typography>
            <LiveTag/>
          </Box>
        </Box>
        <ProgressBar phase={1}/>
      </Box>
      <Box sx={styles.phaseHeader}>
        <Typography variant="h3" color="primary">
          Phase 1 summoned: 100/100 &nbsp;&nbsp; | &nbsp;&nbsp; Total Gen-0 summoned: {totalSupply}/1288
        </Typography>
      </Box>
      <Box sx={styles.phaseHeader2}>
        <Typography variant="subtitle1" color="primary">
          Phase 1 summoned: 100/100<br/>
          Total Gen-0 summoned: {goldRushCurrentSupply + 1100}/1288
        </Typography>
      </Box>
      <Box sx={styles.phaseContentBox}>
        <Typography variant="h1" color="primary" sx={styles.phaseContentHeader}>
          The Elders
        </Typography>
        <Typography variant="body1" color="primary" sx={styles.phaseContentBody}>
          The first secrets of Zolar are to be unveiled by those deemed most worthy, 
          chosen through a rite of passage that will be undertaken by many and survived 
          by few. One hundred elders will be bestowed as wielders of knowledge, 
          and make the inaugural ascent to Zolar. Through them, the course to 
          Transcendence will be set ablaze.
        </Typography>
        
        <Link sx={styles.learnMore} target="_blank" href="https://docs.zolar.io/">Learn More</Link>

        <Typography variant="subtitle1" sx={styles.countdownText}>
          Phase 1 has ended
        </Typography>
        <Button variant="contained" color="secondary" target="_blank" href="https://discord.gg/zilswap" disabled={true} sx={styles.buttonDisabled}>
          <Typography variant="button" sx={styles.disabledText}>PLAY ON DISCORD &nbsp;</Typography>
          <DiscordDark height="30px" width="30px" />
        </Button>
        <BearHandBox>
          <img src={Elderberry} alt="bear-hand" width="100%"/>
        </BearHandBox>
      </Box>
    </Box>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  subheader: {
    fontSize: "0.75rem",
    letterSpacing: "0.2rem",
    lineHeight: "1.125rem",
    marginBottom: "5px",
  },
  subheaderInactive: {
    fontSize: "0.75rem",
    letterSpacing: "0.2rem",
    lineHeight: "1.125rem",
    marginBottom: "5px",
    opacity: "60%",
  },
  // Phase Nav
  phaseContainer: {
    padding: '0px 90px',
    position: 'relative',
    margin: '0px 20px 20px',
    overflow: 'hidden',
    "@media (max-width:1200px)": {
      padding: '0px 4px',
      overflowX: 'scroll',
      marginX: '100px',
    },
    "@media (max-width:900px)": {
      marginX: '40px',
    },
  },
  phaseLinesBG: {
    pointerEvents: 'none',
    minWidth: '1000px',
    position: 'absolute',
    width: '90%',
    left: '90px',
    top: '33px',
    "@media (max-width:1200px)": {
      left: '20px',
    },
  },
  phaseRow: {
    minWidth: '1000px',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: "60px",
  },
  phaseBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
  },
  phaseNavLinkInactive: {
    opacity: "60%",
  },
 
  // Phase content
  phaseHeader: {
    textAlign: 'center',
    marginX: "24px",
    marginBottom: "20px",
    "@media (max-width:900px)": {
      display: 'none'
    },
  },
  phaseHeader2: {
    textAlign: 'center',
    marginX: "24px",
    marginBottom: "20px",
    "@media (min-width:900px)": {
      display: "none",
    }
  },
  phaseContentBox: {
    position: "relative",
    borderRadius: "16px",
    margin: "0px 110px",
    padding: "40px 120px",
    background:
      "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    minHeight: "500px",
    "@media (max-width:1200px)": {
      padding: "40px 80px",
    },
    "@media (max-width:900px)": {
      margin: "0px 24px",
      padding: "24px",
    },
  },
  phaseContentHeader: {
    zIndex: 1,
    opacity: "100%",
    "@media (max-width:600px)": {
      fontSize: '2.5rem',
      textAlign: 'center',
    },
    marginBottom: '10px',
  },
  phaseContentBody: {
    zIndex: 1,
    textAlign: "center", 
    marginY: '30px',
  },
  countdownText: {
    color: "#FF8952",
    marginBottom: '30px',
  },
  buttonDisabled: {
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
  },
  disabledText: {
    color: '#000',
  },
  learnMore: {
    marginBottom: '30px',
  }
};

const BearHandBox = styled(Box)({
  position: "absolute",
  top: "0",
  right: "0",
  opacity: "30%",
  "@media (max-width:900px)": {
    width: "40%",
  },
});

export default PhaseOne;
