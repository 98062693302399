import { Box, Button, Container, styled, SxProps, Typography } from "@mui/material";
import { BlueLine, BlueShootingStar, MissionBlue, Moon, PinkShootingStar, Smoke1a, Smoke1b, Smoke1c, Smoke1d, Stars } from "assets";
import BaseButton from "assets/Base.svg";
import PinkPlanet from "assets/PinkPlanet.png";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { AppTheme, SimpleMap } from "utils/types";

const Hero: FC = () => {
  return (
    <Container maxWidth="xl" sx={styles.container} component="section">
      <SvgBox component="aside" id="SVG-Hero">
        <Smoke1aBox>
          <Smoke1a width="500px" />
        </Smoke1aBox>
        <Smoke1bBox>
          <Smoke1b />
        </Smoke1bBox>
        <Smoke1cBox>
          <Smoke1c />
        </Smoke1cBox>
        <Smoke1dBox>
          <Smoke1d />
        </Smoke1dBox>
        <BlueShootingBox>
          <BlueShootingStar width="100%" />
        </BlueShootingBox>
        <PinkShootingBox>
          <PinkShootingStar />
        </PinkShootingBox>
        <BlueBox>
          <BlueLine />
        </BlueBox>
        <PinkPlanetBox>
          <img src={PinkPlanet} alt="pink-planet" width="100%" />
        </PinkPlanetBox>
        <StarsBox>
          <Stars />
        </StarsBox>
        <MoonBox>
          <Moon width="100%" />
        </MoonBox>

        <Box
          sx={{
            pointerEvents: 'none',
            background: "radial-gradient(44.15% 41.29% at 44.15% 51.87%, #FFFFFF 0%, #806BFF 20.48%, rgba(53, 2, 255, 0.67) 56.9%, rgba(20, 0, 255, 0) 100%)",
            position: "absolute",
            top: "12em",
            right: "-700px",
            width: "1496px",
            opacity: "40%",
            "@media (max-width:600px)": {
              display: "none",
            },
            "@media (max-width:900px)": {
              right: "-800px",
            },
          }}>
          <MissionBlue />
        </Box>
      </SvgBox>
      <Box sx={styles.content} component="main">
        <Box width="100%">
          <Typography variant="h1" color="primary" sx={styles.heroHeader}>
            Welcome to Zolar
          </Typography>
        </Box>
        <Box sx={styles.subheader}>
          <Typography variant="body1" color="primary" sx={styles.subheaderText}>
            Zilliqa's leading Play-to-Own NFT Game set to take the metaverse by storm!!
          </Typography>
        </Box>
        <Button component={NavLink} to="/map" variant="contained" color="secondary" sx={styles.buttonWrapper}>
          <BaseButtonBox>
            <img src={BaseButton} alt="base-button" height="100%" />
          </BaseButtonBox>
          <Typography variant="button" color="secondary.contrastText">Enter Zolar 🚀</Typography>
        </Button>
      </Box>
    </Container>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    padding: "0px",

    // //DEBUG: Remove this
    // border: '1px solid yellow',
    // "*": {
    //   border: '1px solid red',
    // }
  },
  heroHeader: {
    zIndex: 1,
    fontSize: "90px",
    lineHeight: "100%",
    textShadow: "0 0 120px #0057FF",
    "@media (max-width:900px)": {
      fontSize: "5.5rem",
      lineHeight: "5.5rem",
    },
    "@media (max-width:600px)": {
      fontSize: "2.5rem",
      lineHeight: "1",
      textShadow: "0 0 24px #0057FF",
    },
  },
  content: {
    padding: "280px 24px 40px",
    "@media (min-width:600px)": {
      padding: "360px 48px 80px",
    },
    "@media (min-width:900px)": {
      padding: "360px 96px 240px",
    },
  },
  subheader: {
    marginTop: "16px",
    marginBottom: "32px",
    "@media (min-width:600px)": {
      marginBottom: "40px",
      paddingLeft: '10px',
    },
    "@media (min-width:900px)": {
      margin: "24px 0px 42px",
    },
  },
  subheaderText: {
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
  },
  buttonWrapper: {
    "@media (min-width:600px)": {
      marginLeft: '10px',
    },
  },
};

const SvgBox = styled(Box)({
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
  pointerEvents: 'none',
});

const Smoke1aBox = styled(Box)({
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(0deg, #FFFFFF -0.13%, #C1C1C1 96.36%, #999999 100%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "256px",
  left: "-65px",
  "@media (max-width:900px)": {
    top: "600px",
    left: "10px",
  },
});

const Smoke1bBox = styled(Box)({
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(0deg, #FFFFFF -0.13%, #C1C1C1 96.36%, #999999 100%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "-50px",
  left: "-4px",
  "@media (max-width:900px)": {
    display: "none",
  },
});

const Smoke1cBox = styled(Box)({
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(0deg, #FFFFFF -0.13%, #C1C1C1 96.36%, #999999 100%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "-90px",
  right: "-5px",
  "@media (max-width:600px)": {
    top: "-300px",
    right: "-150px",
  },
});

const Smoke1dBox = styled(Box)({
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(0deg, #FFFFFF -0.13%, #C1C1C1 96.36%, #999999 100%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "-100px",
  left: "450px",
  "@media (max-width:900px)": {
    top: "-100px",
    left: "20px",
  },
});

const BlueShootingBox = styled(Box)({
  width: "60px",
  pointerEvents: 'none',
  position: "absolute",
  top: "170px",
  left: "120px",
  "@media (max-width:900px)": {
    top: "100px",
    left: "40px",
    width: "40px",
  },
});

const PinkShootingBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "550px",
  left: "600px",
  "@media (max-width:1200px)": {
    top: "550px",
    left: "800px",
  },
});

const BlueBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "80px",
  left: "1050px",
});

const PinkPlanetBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "120px",
  right: "5%",
  "@media (max-width:900px)": {
    width: "70px",
  },
});
const StarsBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "200px",
  left: "800px",
  width: "550px",
});
const MoonBox = styled(Box)({
  width: '100%',
  pointerEvents: 'none',
  position: "absolute",
  top: "-80px",
  "@media (max-width:900px)": {
    top: "-150px",
  },
  "@media (max-width:600px)": {
    top: "-150px",
  },
});

const BaseButtonBox = styled(Box)({
  height: '100%',
  pointerEvents: 'none',
  position: "absolute",
  left: 0,
  top: "2%",
  "@media (max-width:600px)": {
    left: "0px",
  },
});

export default Hero;
