import {
  Box,
  CircularProgress,
  FormHelperText,
  Link,
  SvgIcon,
  SxProps,
  Typography
} from "@mui/material";
import { ExternalLink, WarningRed } from "assets";
import DragPlaceholder from "assets/DragPlaceholder.svg";
import DragSectionalAreaSelected from "assets/DragSectionalArea-Selected.svg";
import DragSectionalArea from "assets/DragSectionalArea.svg";
import ItemFrame from "assets/ItemFrame.svg";
import ItemFrameLined from "assets/ItemFrameLined.svg";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import ZaggedArrow from "assets/ZaggedArrow.svg";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import StripeHeader from "components/Game/components/StripeHeader";
import {
  DEFAULT_SRC,
  GUILD_GRAY_GRADIENT,
  GUILD_LIGHTGRAY_GRADIENT
} from "components/Guild/components/GuildConstants";
import { logger, waitForTx } from "core/utilities";
import { DND } from "layout/components";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { Resources } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useTaskSubscriber, useToaster } from "utils";
import { MissionGroundResource } from "utils/constants";
import { getExplorerLink } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { invalidDragType } from "../MetazoaCollection/StatPanelContent/EquipDND/EquipDraggable";
import {
  EquipCategory,
  EquipType,
  EQUIP_CATEGORIES,
  Gem,
  GEMS,
  GemTier,
  GemTierType
} from "../ResourceConstants";
import { IDroppable } from "./components/GemsDND/Droppable";

export interface IGemItem {
  item: Gem;
  ids: number[];
  keyName: string;
  imgSrc: string;
  type: EquipCategory;
}

/// DRAGGABLE
interface GemDraggableProps extends IGemItem {
  options?: SimpleMap<boolean>;
}

const GemDraggable: FC<GemDraggableProps> = ({
  options = {
    showCount: true,
  },
  ...item
}: GemDraggableProps) => {
  options = {
    showCount: true,
    ...options,
  };

  const { ids, type } = item;
  const isValidItem: boolean = ids.length > 0 && !!Object.keys(item).length;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: !isValidItem ? invalidDragType.type : type.type,
      item: item,
      canDrag: isValidItem,
      end: (item, monitor) => {
        // const dropResult = monitor.getDropResult<IDroppable>()
        // if (dropResult) logger(`Acceptable: ${JSON.stringify(dropResult?.acceptableTypes ?? [])}`)
        // if (item && dropResult) {
        //   logger(`Dropped ${type.type} into ${dropResult.id}\n`)
        // }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [isValidItem]
  );

  return (
    <Box
      ref={drag}
      sx={combineStyles(styles.dragBox, {
        backgroundImage: "none",
        borderRadius: "8px",
        border: `1px solid ${isDragging ? "#AEF1EE" : "#AEF1EE1A"}`,
        ...(ids.length < 1 && styles.greyscale),
      })}
    >
      <Box
        component="img"
        src={item.imgSrc}
        alt={item.keyName}
        onError={DEFAULT_SRC}
        sx={styles.icons}
      />
      <Typography sx={styles.itemCount} color="primary">
        {ids.length}
      </Typography>
    </Box>
  );
};

/// DROPPABLE
interface GemDroppableProps extends Omit<IDroppable, "limit"> {
  onDrop: (item: IGemItem) => void;
  item: IGemItem;
  type?: typeof EQUIP_CATEGORIES[keyof typeof EQUIP_CATEGORIES];
  sx?: SxProps<AppTheme>;
  showMiniTypeIcon?: boolean;
}

const GemDroppable: FC<GemDroppableProps> = ({
  id,
  item,
  onDrop,
  type = invalidDragType,
  acceptableTypes,
  sx = {},
  showMiniTypeIcon = false,
}: GemDroppableProps) => {
  const isValidItem: boolean = !!Object.keys(item).length;

  const [, drop] = useDrop(
    () => ({
      accept: acceptableTypes,
      canDrop: (itm: IGemItem) => {
        let dropability = !!Object.keys(itm).length;
        if (isValidItem) dropability = false;
        return dropability;
      },
      drop(itm: IGemItem) {
        onDrop(itm);
        return { id };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop]
  );

  type = EQUIP_CATEGORIES[acceptableTypes[0]];

  return (
    <Box ref={drop} sx={combineStyles(styles.dragBox)}>
      {isValidItem && (
        <GemDraggable
          {...item}
          options={{
            showCount: false,
          }}
        />
      )}
    </Box>
  );
};

interface GemDroppableGroupProps
  extends Omit<GemDroppableProps, "item" | "type">,
  Omit<IDroppable, "id"> {
  items: IGemItem[];
}

const GemDroppableGroup: FC<GemDroppableGroupProps> = ({
  id,
  items,
  onDrop,
  acceptableTypes,
  limit,
  options = {
    hasPlaceholder: false,
    isRow: true,
    hasUniqueTypes: false,
    hasMiniTypeIcon: false,
  },
  sx = {},
}: GemDroppableGroupProps) => {
  items = items.filter(({ type }) => acceptableTypes.includes(type.type));
  options = {
    hasPlaceholder: false,
    isRow: true,
    hasUniqueTypes: false,
    hasMiniTypeIcon: false,
    ...options,
  };

  if (options.hasUniqueTypes && acceptableTypes.length < 2)
    options.hasUniqueTypes = false;

  return (
    <Box
      id={id}
      component="section"
      sx={combineStyles(
        styles.dragSelectional,
        styles.dropSectional,
        { ...(options.hasPlaceholder && styles.dragSelectionalSelected) },
        sx
      )}
    >
      {(!options.hasUniqueTypes
        ? [...items, ...new Array(limit - items.length).fill(false)]
        : new Array(acceptableTypes.length).fill(false).map((itm, idx) => {
          const expectedType =
            idx > acceptableTypes.length
              ? acceptableTypes[idx - acceptableTypes.length]
              : acceptableTypes[idx];
          const typeItemIdx = items.findIndex(
            ({ type }) => type.type === expectedType
          );
          if (typeItemIdx < 0) return itm;

          const typeItem = items[typeItemIdx];
          return typeItem;
        })
      )
        .slice(0, limit)
        .map((itm, idx) => {
          const equipCategoryIdx =
            idx > acceptableTypes.length ? idx - acceptableTypes.length : idx;
          const equipCategory = Object.values(EQUIP_CATEGORIES).filter(
            (category) => acceptableTypes.includes(category.type)
          )[equipCategoryIdx];

          return (
            <GemDroppable
              key={idx}
              id={idx.toString()}
              item={itm ?? {}}
              {...(!!itm && { type: itm.type })}
              acceptableTypes={
                !options.hasUniqueTypes ? acceptableTypes : [equipCategory.type]
              }
              onDrop={onDrop}
              showMiniTypeIcon={options.hasMiniTypeIcon}
              sx={{
                ...(!options.isRow && {
                  minWidth: "104px",
                  minHeight: "104px",

                  ...(idx === 3 && {
                    left: "5.5em",
                    top: "-15em",
                  }),
                  ...(idx === 2 && {
                    top: "-5.5em",
                  }),
                  ...(idx === 1 && {
                    left: "-5.5em",
                    top: "-3.5em",
                  }),
                }),
              }}
            />
          );
        })}
    </Box>
  );
};

interface GemsDroppableProps extends IDroppable {
  onDrop: (item: IGemItem) => void;
  items: IGemItem[];
}

const GemsDroppable: FC<GemsDroppableProps> = ({
  id,
  items,
  onDrop,
  acceptableTypes,
}: GemsDroppableProps) => {
  const [, drop] = useDrop(
    () => ({
      accept: acceptableTypes,
      canDrop: (itm: IGemItem) => {
        // let dropability = (!!Object.keys(itm).length);
        // if (isValidItem) dropability = false;
        return true;
      },
      drop(itm: IGemItem) {
        onDrop(itm);
        return { id };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop]
  );

  return (
    <Box
      ref={drop}
      component="section"
      sx={combineStyles(styles.dragSelectional, styles.dropSectional)}
    >
      {items.map((item, idx) => (
        <GemDraggable key={idx} {...item} />
      ))}
    </Box>
  );
};

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export type RefineGemProps = {
  gemId: number;
  tier: GemTierType;
  gem: Gem;
  gems: SimpleMap<number[]>;
};

interface RefineGemsDialogProps extends ModalProps, RefineGemProps {
  resourceType?: keyof typeof MissionGroundResource;
}

export const RefineGemsDialog: FC<RefineGemsDialogProps> = ({
  gemId,
  tier,
  gem,
  gems,
  open,
  onClose,
  resourceType = "Geodes",
}: RefineGemsDialogProps) => {
  const currentTierName = GemTier[tier];
  const nextTier: string =
    Object.keys(GemTier).at(
      Object.keys(GemTier).findIndex((t) => t === tier) + 1
    ) ?? tier;
  // const nextTierName = GemTier[nextTier];
  const maxSelectable: number = gem.reqMaterialCount;
  const isUpgradable: boolean = maxSelectable > 0 && tier !== nextTier;
  const nextTierGem: Gem = isUpgradable ? GEMS[nextTier][gem.type] : gem;

  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();

  const wallet = useRedux((state) => state.wallet.wallet);
  const resources: Resources = useRedux((state) => state.token.resources);

  // STATES / HOOKS -----------------

  const [selectableItems, setSelectableItems] = useState<IGemItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<IGemItem[]>([]);
  const [errors, setErrors] = useState<string>("");
  const [refineGemsCracked, setRefineGemsCracked] = useState<boolean>(false);
  const [refineGemsFailed, setRefineGemsFailed] = useState<boolean>(false);
  const [refineGemsTxHash, setRefineGemsTxHash] = useState<string>("");
  const [needAllowance, setNeedAllowance] = useState<boolean>(true);
  const [fetchAllowance, loadingAllowance] = useAsyncTask(
    "fetch allowance",
    (error) => {
      toaster(error?.message ?? "Error Fetching Allowance");
    }
  );
  const [runApprove, loadingApprove] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
  });
  const [runRefineGems, loadingRefineGems] = useAsyncTask(
    "refine gems",
    (error) => {
      toaster(error?.message ?? "Error Refining Gems");
    }
  );

  const [loadingFetchResources] = useTaskSubscriber('refetchResources');

  const isLoading: boolean = loadingRefineGems || loadingFetchResources;

  useEffect(() => {
    fetchAllowance(async () => {
      if (!wallet) return;
      const result = await TBMConnector.checkUserGemAllowance(
        wallet,
        resourceType
      );
      setNeedAllowance(result);
    });
    // eslint-disable-next-line
  }, [wallet]);

  const gemItems: IGemItem[] = useMemo(() => {
    let gemItemList: IGemItem[] = [];

    if (!gems || !Object.keys(gems).length) {
      // Reset memomized item states
      setSelectableItems(gemItemList);
      setSelectedItems([]);
      return gemItemList;
    }

    // Store in memomized item states
    const excludedIds: number[] = Array.from([gemId]);
    const gemItems: IGemItem[] = Object.entries(gems).reduce(
      (list: IGemItem[], [gem, gemIdArray]) => {
        const currentGem: Gem = GEMS[tier][gem];
        const iconSrc: string = currentGem.icon;
        const keyName: string = tier + " " + currentGem.name;
        const equipType = EquipType[EquipType.Gem];
        const type = EQUIP_CATEGORIES[equipType];

        const gemItem: IGemItem = {
          item: currentGem,
          ids: Array.from(gemIdArray).filter((id) => !excludedIds.includes(id)),
          keyName,
          imgSrc: iconSrc,
          type,
        };

        return [...list, gemItem];
      },
      []
    );

    gemItemList.push(...gemItems);

    // Store in memomized item states
    setSelectableItems(gemItemList);
    setSelectedItems([]);

    return gemItemList;
  }, [gemId, gems, tier]);

  const isSubmitEnabled = useMemo(() => {
    if (!wallet || !network || isLoading) return false;
    setErrors("");

    // Insufficient Gems Selected
    if (selectedItems.length < 1) return false;

    // Insufficient
    if (selectedItems.length < maxSelectable) {
      setErrors("Insufficient supply of material gems!");
      return false;
    }

    // Overshot
    if (selectedItems.length > maxSelectable) {
      setErrors("Oversupply of material gems!");
      return false;
    }

    // Gem is Maxed out: !isUpgradable
    if (!isUpgradable) {
      setErrors("Gem can't be refined any further");
      return false;
    }

    setErrors("");
    return true;
  }, [
    isLoading,
    isUpgradable,
    maxSelectable,
    network,
    selectedItems.length,
    wallet,
  ]);

  // FUNCTIONS ----------------------

  const getGemsByIds = (
    selectedItems: IGemItem[],
    tier: GemTierType,
    gems: SimpleMap<number[]>
  ): Gem[] => {
    if (!selectedItems.length) return [];

    // Set selection
    const selectedGemIds: number[] = selectedItems
      .filter((i) => i.ids.length > 0)
      .reduce(
        (accum: number[], next: IGemItem) => accum.concat([...next.ids]),
        []
      );

    return selectedGemIds.reduce((idGems: Gem[], id: number) => {
      Object.entries(gems)
        .filter(([, gemIdArray]) => gemIdArray.includes(id))
        .forEach(([gem]) => {
          idGems.push(GEMS[tier][gem]);
        });
      return idGems;
    }, []);
  };

  const transferGemItem = (
    dItem: IGemItem,
    srcList: IGemItem[],
    dstList: IGemItem[],
    options: SimpleMap<boolean> = {
      stacking: false,
      inversed: false,
      keepEmpty: false,
    }
  ): SimpleMap<IGemItem[]> => {
    options = {
      stacking: false,
      inversed: false,
      keepEmpty: false,
      ...options,
    };

    let transferResult: SimpleMap<IGemItem[]> = {
      src: Array.from(srcList),
      dst: Array.from(dstList),
    };
    if (!srcList.length || !dItem.ids.length) {
      logger(
        "debug-component",
        "EquipPanel/handleEquipDrop",
        "CHECK 1: FAILED",
        {
          count: dItem.ids.length,
          length: srcList.length,
        }
      );
      return transferResult;
    }

    // Retrieve srcItemIdx
    const srcItemIdx: number = transferResult.src.findIndex(
      ({ keyName }) => keyName === dItem.keyName
    );
    if (srcItemIdx < 0) {
      logger(
        "debug-component",
        "EquipPanel/handleEquipDrop",
        "CHECK 2: FAILED",
        {
          srcItemIdx,
        }
      );
      return transferResult;
    }

    let srcItem: IGemItem = transferResult.src[srcItemIdx];
    // Check if srcItem mismatch dItem
    if (dItem.keyName !== srcItem.keyName) {
      logger(
        "debug-component",
        "EquipPanel/handleEquipDrop",
        "CHECK 3: FAILED",
        {
          dKeyName: dItem.keyName,
          srcKeyName: srcItem.keyName,
        }
      );
      return transferResult;
    }

    const swapList: number[] = Array.from(srcItem.ids ?? []);
    if (!swapList.length) {
      logger(
        "debug-component",
        "EquipPanel/handleEquipDrop",
        "CHECK 4: FAILED",
        {
          swapList,
          srcItem,
        }
      );
      return transferResult;
    }

    // Pop swapItem from swapList head
    const swapItem: number = swapList.pop() ?? -1;
    if (swapItem < 0) {
      logger(
        "debug-component",
        "EquipPanel/handleEquipDrop",
        "CHECK 5: FAILED",
        {
          swapList,
          srcItem,
          swapItem,
        }
      );
      return transferResult;
    }

    // Retrieve dstItemIdx
    const dstItemIdx: number = transferResult.dst.findIndex(
      ({ keyName }) => keyName === srcItem.keyName
    );

    // Increment dst
    const dstSwapList = [swapItem];
    if (dstItemIdx > -1 && options.stacking) {
      let dstItem: IGemItem = transferResult.dst[dstItemIdx];
      dstSwapList.push(...(dstItem.ids ?? []));

      transferResult.dst[dstItemIdx] = {
        ...dstItem,
        ids: dstSwapList,
      };
    } else {
      transferResult.dst.push({
        ...srcItem,
        ids: dstSwapList,
      });
    }

    // Decrement src
    transferResult.src[srcItemIdx] = {
      ...srcItem,
      ids: swapList,
    };

    logger("debug-component", "EquipPanel/handleEquipDrop", "GEM SWAPPED", {
      swapList,
      swapItem,
      srcItem,
      transferResult,
    });

    if (!options.keepEmpty && transferResult.dst.length)
      transferResult.dst = transferResult.dst.filter(
        ({ ids }) => ids.length > 0
      );

    logger("debug-component", "EquipPanel/handleEquipDrop", "SWAPPED", {
      dItem,
      options,
      transferResult,
    });

    return transferResult;
  };

  const approve = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const approveAmount = new BigNumber(2).pow(128).minus(1).dp(0);
    const approveTx = await TBMConnector.increaseGemAllowance(
      wallet,
      approveAmount,
      resourceType
    );
    toaster(`Submitted Approve Increase Allowance `, { hash: approveTx.id! });
    if (approveTx.isRejected() || !approveTx.id)
      throw new Error("Submitted transaction was rejected.");

    const tx = await waitForTx(approveTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(
      approveTx.id
    );
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3)
      throw new Error("Submitted transaction was unsuccessful");

    setNeedAllowance(false);
    toaster(`Increase Allowance Success`, { hash: approveTx.id! });
  };

  const refineGems = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!isSubmitEnabled) throw new Error("Requirements are not met");

    // Set selection
    const selectedGemIds: number[] = selectedItems
      .filter((i) => i.ids.length > 0)
      .reduce(
        (accum: number[], next: IGemItem) => accum.concat([...next.ids]),
        []
      );

    const refineGemsTx = await TBMConnector.refineGems(
      nextTier as GemTierType,
      gemId,
      selectedGemIds
    );
    toaster(`Submitted Gem Refinement`, { hash: refineGemsTx.id! });
    if (refineGemsTx.isRejected() || !refineGemsTx.id)
      throw new Error("Submitted transaction was rejected.");

    try {
      const tx = await waitForTx(refineGemsTx.id);
      const tbmConnector = TBMConnector.getSDK();
      const txn = await tbmConnector.zilliqa.blockchain.getTransaction(
        refineGemsTx.id
      );
      const receipt = txn.getReceipt();
      if (!receipt || !receipt?.success || tx.status >= 3)
        throw new Error("Submitted transaction was unsuccessful");

      //Retrieve refinementId
      let refinementId = "";
      receipt!.event_logs.forEach((event) => {
        if (event._eventname === "RefinementStarted") {
          refinementId = event.params.find(
            (param) => param.vname === "refinement_id"
          )?.value;
        }
      });
      if (!refinementId.length)
        throw new Error("Unable to complete refinement");
      logger("debug-component", "refineGems/receipt", receipt, refinementId);

      let isRefinementSuccessful: boolean = true;

      const events = await TBMConnector.waitForConcludeRefinement(refinementId);
      const paramSuccess = events.map((event) => event.params?.success) ?? [];
      logger("debug-component", "refineGems/event", {
        refinementId,
        events,
        paramSuccess,
      });

      // Check if refinement was successful
      if (!paramSuccess.length)
        throw new Error("Unable to complete refinement");
      const isSuccess: boolean = paramSuccess[0].constructor.toLowerCase() === "true" ?? false;
      isRefinementSuccessful = isSuccess;
      setRefineGemsCracked(!isSuccess);

      // Check if resultant gem is the same tier = failed (NOT TESTED)
      const expectedGemTier: string = `Tier${nextTier}`;
      const paramGems = events.map((event) => event.params?.gems) ?? [];
      const paramGemTier: string = (
        paramGems?.[0]?.[0]?.arguments?.[1]?.constructor ?? ""
      ).split(".")?.[1] ?? "";
      logger("debug-component", "refineGems/event/checkTier", {
        refinementId,
        events,
        paramSuccess,
        paramGems,
        gem: paramGemTier,
        expectedGemTier,
      });
      if (paramGems.length && !!paramGemTier) {
        const isExpectedTier: boolean = paramGemTier === expectedGemTier;
        setRefineGemsFailed(!isExpectedTier);
        isRefinementSuccessful = isExpectedTier;
      }

      if (isRefinementSuccessful) {
        // Update based on cracked status
        let tierGems: SimpleMap<number[]> = resources.gems[nextTier] || {};
        let currentTypeTierGemIds: number[] = tierGems[gem.type] || [];
        const nextTypeTierGemIds: number[] = tierGems[nextTierGem.type] || [];

        // Remove from current tier
        currentTypeTierGemIds = currentTypeTierGemIds.filter(
          (id) => id !== gemId
        );
        // Append to next tier
        nextTypeTierGemIds.push(gemId);

        tierGems = {
          ...tierGems,
          [gem.type]: currentTypeTierGemIds,
          [nextTierGem.type]: nextTypeTierGemIds,
        };
        resources.gems[nextTier] = tierGems;
      }

      dispatch(actions.Token.updateResources(resources));
      setRefineGemsTxHash(refineGemsTx.id);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const revertToInitialState = () => {
    if (refineGemsTxHash) dispatch(actions.Token.refetchResource());
    setRefineGemsTxHash("");
    setRefineGemsCracked(false);
    setRefineGemsFailed(false);
    setErrors("");
    setSelectedItems([]);
  };

  // EVENT HANDLERS -----------------

  const handleOnClose = () => {
    if (isLoading) return;
    revertToInitialState();

    onClose();
  };

  const handleSelectedDrop = useCallback(
    (dItem: IGemItem) => {
      logger("debug-component", "EquipPanel/handleEquipDrop", {
        dItem,
        src: selectableItems,
        dst: selectedItems,
      });

      /// TRANSFER
      // let options: SimpleMap<boolean> = {
      //   stacking: false,
      //   inversed: false,
      //   keepEmpty: false,
      // };

      const transferResult: SimpleMap<IGemItem[]> = transferGemItem(
        dItem,
        selectableItems,
        selectedItems
      );

      /// TRANSFER

      setSelectedItems(transferResult.dst.filter(({ ids }) => ids.length > 0));
      setSelectableItems(transferResult.src);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectableItems, selectedItems]
  );

  const handleSelectableDrop = useCallback(
    (dItem: IGemItem) => {
      logger("debug-component", "EquipPanel/handleEquipDrop", {
        dItem,
        src: selectedItems,
        dst: selectableItems,
      });

      /// TRANSFER
      let options: SimpleMap<boolean> = {
        stacking: true,
        inversed: false,
        keepEmpty: true,
      };

      const transferResult: SimpleMap<IGemItem[]> = transferGemItem(
        dItem,
        selectedItems,
        selectableItems,
        options
      );

      /// TRANSFER

      setSelectedItems(transferResult.src.filter(({ ids }) => ids.length > 0));
      setSelectableItems(transferResult.dst);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectableItems, selectedItems]
  );

  const handleApprove = () => {
    runApprove(async () => {
      await approve();
    });
  };

  const handleRefineGems = () => {
    runRefineGems(async () => {
      await refineGems();
    });
  };

  // --------------------------------

  const enhancementFailed: boolean = refineGemsCracked || refineGemsFailed;

  const getHeader = () => {
    if (isLoading) return "Enhancing Galaxy Gems ...";
    else if (refineGemsTxHash.length)
      return `Gem Enhancement ${enhancementFailed ? "Failed" : "Success!"}`;
    return "Enhance Galaxy Gems";
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Box>
      );
    }

    // Post-Refinement
    else if (refineGemsTxHash.length) {
      // Backtracking target gems from id
      const materialGems: Gem[] = getGemsByIds(selectedItems, tier, gems);
      logger("debug-gems-post", materialGems, gem, nextTierGem);

      return (
        <Box sx={styles.contentBox}>
          <Typography variant="body1" color="primary" sx={styles.dialogFont}>
            {enhancementFailed ? (
              refineGemsCracked ? (
                `Your Target Gem cracked during the enhancement process.`
              ) : (
                `Your Target Gem failed to upgrade during the enhancement process`
              )
            ) : (
              <>
                You have successfully evolved your Target Gem!
                <br />
                Check out your new shiny Galaxy Gem:
              </>
            )}
          </Typography>

          <Box
            component="main"
            flexDirection="row"
            sx={{
              ...styles.flexedCenter,
              placeContent: "space-between",

              width: "100%",
              pt: 3,
            }}
          >
            <Box
              component="section"
              className="preRefinedInfo"
              flexDirection="row"
              sx={{
                opacity: "0.6",
                ...styles.flexedCenter,
              }}
            >
              {/* //PREV GEM */}
              <Box
                component="article"
                className="prevGem"
                height="170px"
                sx={styles.emptyItemBox}
              >
                <Box
                  component="img"
                  src={gem.icon}
                  alt={gem.name}
                  onError={DEFAULT_SRC}
                  width="100%"
                  height="100%"
                />
                <Box sx={styles.tokenAmtTag}>
                  <Typography sx={styles.tokenAmt} color="primary">
                    {gem.name}
                  </Typography>
                </Box>
                <Typography sx={styles.tokenValue}>Class {tier}</Typography>
              </Box>

              {/* // MATERIAL GEMS */}
              <Box
                component="aside"
                flexDirection="column"
                sx={{
                  ...styles.flexedCenter,
                  placeContent: "space-between",
                }}
              >
                {/* {materialGems.map((id: number) => { */}
                {materialGems.map((gem: Gem, idx: number) => (
                  <Box
                    key={gem.name + idx}
                    className="materialGem"
                    sx={styles.dragBox}
                  >
                    <Box
                      component="img"
                      src={gem.icon}
                      alt={gem.name}
                      sx={styles.icons}
                      onError={DEFAULT_SRC}
                    />
                  </Box>
                ))}
              </Box>
            </Box>

            <Box component="img" src={ZaggedArrow} alt="Arrow" />

            <Box component="section" className="postRefinedInfo">
              {/* //CURRENT GEM */}
              <Box
                component="article"
                className="currGem"
                sx={combineStyles(styles.emptyItemBox, styles.gemBox, {
                  ...(enhancementFailed && {
                    backgroundImage: `url(${ItemFrameLined})`,
                  }),
                })}
              >
                <Box
                  component="img"
                  src={
                    enhancementFailed
                      ? refineGemsCracked
                        ? gem.iconCracked
                        : gem.icon
                      : nextTierGem.icon
                  }
                  alt={nextTierGem.name}
                  width="100%"
                  height="100%"
                  onError={DEFAULT_SRC}
                />
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                  position: "relative",
                  bottom: "13px",
                }}
              >
                <Typography variant="h2" sx={GUILD_LIGHTGRAY_GRADIENT}>
                  {nextTierGem.name}
                </Typography>
                <Typography
                  component="span"
                  color={enhancementFailed ? "error.main" : "success.main"}
                >
                  {enhancementFailed
                    ? refineGemsCracked
                      ? `Cracked`
                      : `Class ${tier}`
                    : `Tier ${nextTier}`}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Link
            target="_blank"
            href={getExplorerLink("tx", refineGemsTxHash, network)}
            sx={styles.viewTx}
          >
            View Transaction
            <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
          </Link>

          <ContainedButton sx={{ marginTop: "32px" }} onClick={handleOnClose}>
            EQUIP METAZOA
          </ContainedButton>
        </Box>
      );
    }

    // DEFAULT
    return (
      <>
        <Box
          component="main"
          sx={{
            border: "1px solid rgba(174, 241, 238, 0.4)",
            borderRadius: "16px",
            my: "20px",

            "> * > *": {
              paddingX: "24px",
            },
          }}
        >
          <Box component="header" flexDirection="row" sx={styles.flexedCenter}>
            <Box height="170px">
              <Box
                component="img"
                src={gem.icon}
                alt={gem.name}
                width="100%"
                height="100%"
                onError={DEFAULT_SRC}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                height: "100%",
                borderLeft: "1px solid rgba(174, 241, 238, 0.4)",
                paddingX: 0,

                "> *": {
                  paddingX: "24px",
                },
              }}
            >
              <Typography
                color="primary"
                variant="body1"
                sx={{
                  height: "100%",
                  py: "16px",
                }}
              >
                Target Gem:&nbsp;
                <Typography component="span" color="success.main">
                  {currentTierName}&nbsp;{gem.name}
                </Typography>
              </Typography>

              <Typography
                variant="body1"
                color="primary"
                align="left"
                sx={{
                  ...GUILD_LIGHTGRAY_GRADIENT,
                  borderTop: "1px solid rgba(174, 241, 238, 0.4)",
                  py: "16px",
                }}
              >
                There is a chance of failure where the gem will not be upgraded,
                and a chance of cracking the target gem where the gem can not
                longer be enhanced. Enhancemet gems will be consumed and
                destroyed regardless.{" "}
                <Link
                  target="_blank"
                  href="https://docs.zolar.io/metazoa/professions-new/gem-enhancement-new"
                >
                  Learn More
                </Link>
              </Typography>
            </Box>
          </Box>

          <Box component="section">
            <Typography
              color="primary"
              variant="body1"
              sx={{
                height: "100%",

                py: 4,
                borderTop: "1px solid rgba(174, 241, 238, 0.4)",
                borderBottom: "1px solid rgba(174, 241, 238, 0.4)",
              }}
            >
              Enhancement Gems:&nbsp;
              <Typography
                component="span"
                color={errors ? "error.main" : "success.main"}
              >
                {selectedItems.length}
              </Typography>
              /{maxSelectable ?? 3} Selected
            </Typography>

            <Box component="section">
              <StripeHeader sx={styles.stripeHeader}>
                Enhancement Portal
              </StripeHeader>
              {/* <DND.Provider>
              <GemsDND.Zone
                tier={tier}
                gems={gems}
                targetGemId={gemId}
                limit={maxSelectable}
                setSelection={setSelectedIds}
              />
            </DND.Provider> */}

              {/* DND */}
              <DND.Provider>
                <Box component="main" sx={styles.container}>
                  {/* SELECTABLE */}
                  <Box component="section">
                    <GemsDroppable
                      id={`droppable-selectable-gems`}
                      items={selectableItems}
                      acceptableTypes={["Gem"]}
                      limit={gemItems.length}
                      onDrop={handleSelectableDrop}
                    />
                  </Box>

                  {/* SELECTED */}
                  <GemDroppableGroup
                    id="droppable-selected-gems"
                    items={selectedItems}
                    limit={maxSelectable}
                    acceptableTypes={["Gem"]}
                    onDrop={handleSelectedDrop}
                    options={{
                      hasPlaceholder: true,
                      isRow: true,
                    }}
                  />
                </Box>
              </DND.Provider>
            </Box>
          </Box>
        </Box>

        <Box sx={styles.buttonRow}>
          {!!needAllowance && (
            <ContainedButton
              disabled={!!loadingApprove}
              sx={styles.submitButton}
              onClick={handleApprove}
            >
              {!!loadingApprove ? <CircularProgress size={18} /> : "Approve"}
            </ContainedButton>
          )}
          <ContainedButton
            disabled={!isSubmitEnabled || !!loadingAllowance || !!needAllowance}
            sx={styles.submitButton}
            onClick={handleRefineGems}
          >
            {loadingRefineGems ? (
              <CircularProgress size={18} />
            ) : (
              "Confirm & Enhance"
            )}
          </ContainedButton>
        </Box>
        {!!errors && (
          <Box sx={styles.errorTextContainer}>
            <WarningRed width={15} />
            <FormHelperText sx={styles.errorText}>{errors}</FormHelperText>
          </Box>
        )}
      </>
    );
  };

  return (
    <DialogModal
      open={open}
      onClose={handleOnClose}
      header={getHeader()}
      sx={styles.dialogModal}
      disableScrollLock={true}
      dialogOverwrites={styles.dialogOverwrite}
    >
      {getContent()}
    </DialogModal>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  /// UTILS (future: tw)
  flexedCenter: {
    display: "flex",
    placeContent: "center",
    placeItems: "center",
  },

  /// DIALOG
  dialogModal: {
    py: "60px",
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      },
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      },
    },
  },
  dialogOverwrite: {
    overflowY: "scroll",
    "::-webkit-scrollbar": {
      width: "6px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "20px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },

  /// LOADING
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },

  // PRE-DND
  stripeHeader: {
    textAlign: "center",
    background:
      "repeating-linear-gradient(90deg, #AEF1EE33, #AEF1EE33 36px, transparent 36px, transparent 44px)",
    marginY: "25px",
    transform: "skewX(-25deg) !important",

    "& h2": {
      ...GUILD_GRAY_GRADIENT,
      fontSize: "18px",
      transform: "skewX(25deg)",
      background:
        "linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)",
    },

    ".MuiButtonBase-root": {
      padding: 0,
      marginX: 2,
    },
  },

  // FEE
  feeRow: {
    py: "30px",

    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    mt: "5px",
  },
  hunyIcon: {
    verticalAlign: "top",
  },

  // SUBMISSION
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "center",
    placeContent: "center",
    mx: "auto",
    mt: 2,
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    placeContent: "space-evenly",
    placeItems: "center",
    flexWrap: "wrap",
    gap: "1em",
  },
  submitButton: {
    margin: "0px auto !important",
    marginTop: "16px",
    minWidth: "300px",
  },

  /// POST-TX
  tokenAmt: {
    fontWeight: 600,
    fontSize: "14px",
    padding: "0px 6px",
  },
  tokenAmtTag: {
    position: "absolute",
    bottom: "8px",
    border: "1px solid transparent",
    background:
      "linear-gradient(180deg, #01001E -13.81%, #000010 100%) padding-box,radial-gradient(#00C2FF, #AEF1EE) border-box",
    borderRadius: "8px",
  },
  tokenValue: {
    fontSize: "12px",
    bottom: "-22px",
    position: "absolute",
    ...GUILD_GRAY_GRADIENT,
    background:
      "linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256) 100%)",
  },
  emptyItemBox: {
    position: "relative",
    backgroundImage: `url(${ItemFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "inherit",

    display: "flex",
    placeContent: "center",
    placeItems: "center",
    width: "216px",
    height: "216px",
    minWidth: "216px",
    minHeight: "216px",
    // height: '100%',
    margin: "0 auto",
    paddingY: 0,
    borderRadius: "12px",

    img: {
      width: "150px",
      height: "150px",
    },
  },

  gemBox: {
    height: "272px",
    width: "272px",

    img: {
      width: "190px",
      height: "190px",
    },
  },

  viewTx: {
    marginY: "10px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },

  ///DRAGGABLE
  /// DND
  icons: {
    height: "40px",
    width: "40px",
    scale: "1.5",
  },
  itemCount: {
    position: "absolute",
    bottom: "0%",
    right: "10%",

    lineHeight: "normal",
    fontSize: "12px",
    fontWeight: 600,
    ...GUILD_LIGHTGRAY_GRADIENT,
  },
  greyscale: {
    filter: "grayscale(100%)",
    opacity: "0.5",
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  dragBox: {
    width: "64px",
    height: "64px",

    background:
      "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    backgroundImage: `url(${DragPlaceholder})`,
    backgroundSize: "120%",
    backgroundPosition: "center",

    display: "flex",
    placeContent: "center",
    placeItems: "center",
    position: "relative",
  },

  ///DROPPABLE SECTION
  dragSelectional: {
    height: "215px",
    minWidth: "340px",
    maxWidth: "min-content",

    flexWrap: "wrap",
    position: "relative",
    padding: 0,

    "&::before": {
      content: '""',
      display: "block",
      background: `url(${DragSectionalArea})`,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundClip: "border-box",

      position: "absolute",
      inset: 0,

      height: "215px",
      width: "340px",
    },
  },
  dropSectional: {
    display: "flex",
    placeContent: "center",
    placeItems: "center",
    gap: 2,
    paddingY: 2,
    flexDirection: "row",
  },
  dragSelectionalSelected: {
    "&::before": {
      content: '""',
      display: "block",
      background: `url(${DragSectionalAreaSelected})`,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundClip: "border-box",

      position: "absolute",
      inset: 0,

      height: "215px",
      width: "340px",
    },
  },

  //DND
  container: {
    display: "flex",
    placeItems: "center",
    placeContent: "space-around",
    paddingY: 2,
  },
};

export default RefineGemsDialog;
