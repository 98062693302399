import { Box, Checkbox, CircularProgress, Link, SvgIcon, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CheckedIcon, DisabledIcon, ExternalLink, Huny, UncheckedIcon } from "assets";
import MinoIcon from "assets/icons/Minos.svg";
import UrsaIcon from "assets/icons/Ursas.svg";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import MetazoaPreview from "assets/MetazoaPreview.gif";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import WarningBox from "components/WarningBox";
import { waitForTx } from "core/utilities";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { NftMetadata } from "store/types";
import { TBMConnector } from "tbm";
import { useToaster } from "utils";
import { HUNY_GATHERED_PER_DAY } from "utils/constants";
import { getExplorerLink } from "utils/strings/links";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const SendDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose } = props;
  const toaster = useToaster();
  const dispatch = useDispatch();
  const network = useNetwork();
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const metazoaTokens = Object.values(tokenState.metazoaTokens);
  const [moonbattleApproved, setMoonBattleApproved] = useState<boolean>(false);
  const [selectedMetazoa, setSelectedMetazoa] = useState<string[]>([]);
  const [sendBattleComplete, setSendBattleComplete] = useState<boolean>(false);
  const [sendBattleTxHash, setSendBattleTxHash] = useState<string>("");
  const [runQueryMoonBattleApproved] = useAsyncTask("queryMoonBattleApproved");
  const [runApprove, loadingApprove] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
  });
  const [runSendBattle, loadingSendBattle] = useAsyncTask("sendBattle", (error) => {
    toaster(error?.message ?? "Error Sending to Battle");
  });

  useEffect(() => {
    if (wallet) {
      runQueryMoonBattleApproved(async () => {
        const approved = await TBMConnector.checkMoonBattleApproved(wallet);
        setMoonBattleApproved(approved);
      })
    }
    // eslint-disable-next-line
  }, [wallet, network]);

  const handleSelect = (tokenId: string) => (_event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedMetazoaCopy = selectedMetazoa.slice();
    const index = selectedMetazoaCopy.findIndex((id) => id === tokenId);
    if (index === -1) {
      selectedMetazoaCopy.push(tokenId);
      setSelectedMetazoa(selectedMetazoaCopy);
    } else {
      selectedMetazoaCopy.splice(index, 1);
      setSelectedMetazoa(selectedMetazoaCopy);
    }
  }

  const selectedUrsaCount = useMemo(() => {
    if (!selectedMetazoa.length) return 0;

    let count = 0;

    selectedMetazoa.forEach(id => {
      const faction = metazoaTokens.find(token => token.id === id)?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value;

      if (faction === "Ursa")
        count++;
    })

    return count;

    // eslint-disable-next-line
  }, [selectedMetazoa])

  // const handleSelectAll = () => {

  //   if (selectedMetazoa.length >= Math.min(metazoaTokens.length, MAX_ZOA.EnterBattle)) {
  //     setSelectedMetazoa([]);
  //   } else {
  //     setSelectedMetazoa(metazoaTokens.map(token => token.id).slice(0, MAX_ZOA.EnterBattle));
  //   }
  // }

  const isMetazoaSelected = (tokenId: string) => {
    return selectedMetazoa.includes(tokenId);
  }

  const handleApprove = () => {
    runApprove(async () => {
      await approve();
    })
  }

  const handleSendBattle = () => {
    runSendBattle(async () => {
      await sendBattle();
    })
  }

  const approve = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const approveTx = await TBMConnector.addMoonBattleOperator(wallet);

    if (approveTx?.id) {
      try {
        await waitForTx(approveTx.id);
        setMoonBattleApproved(true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const sendBattle = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const sendBattleTx = await TBMConnector.enterBattle(wallet, selectedMetazoa);

    if (sendBattleTx.isRejected()) {
      throw new Error("Submitted transaction was rejected.");
    }

    if (sendBattleTx?.id) {
      setSendBattleTxHash(sendBattleTx.id);
      try {
        await waitForTx(sendBattleTx.id);
        dispatch(actions.Token.updateImage());
        dispatch(actions.Game.reloadLeaderboard());
        setSendBattleComplete(true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const handleOnClose = () => {
    if (loadingSendBattle) return;

    onClose();
    setSendBattleTxHash("");
    setSelectedMetazoa([]);
    setSendBattleComplete(false);
  }

  const handleSendMore = () => {
    if (loadingSendBattle) return;

    setSendBattleTxHash("");
    setSelectedMetazoa([]);
    setSendBattleComplete(false);
  }

  const getHeader = () => {
    if (sendBattleComplete) {
      return "Send Metazoa Success!";
    } else if (loadingSendBattle) {
      return "Sending to battle...";
    } else {
      return "Send Metazoa to battle";
    }
  }

  const addDefaultSrc = (metadata: NftMetadata) => (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = MetazoaPreview;
  }

  const getHunyPerDay = (gen: string | undefined, faction: string | undefined): string => {
    if (!gen || !faction)
      return "-";

    if (faction === "Mino") {
      return "22% Steal";
    } else {
      return HUNY_GATHERED_PER_DAY[gen] ? HUNY_GATHERED_PER_DAY[gen].toLocaleString() : "-";
    }
  }

  const getContent = () => {
    if (sendBattleComplete) {
      return (
        <Fragment>
          <Typography variant="body1" color="primary" textAlign="center">
            You have successfully sent your Metazoa into battle!
          </Typography>

          <Box sx={styles.contentBox} style={{ marginTop: 0 }}>
            {/* Qty */}
            <Box display="flex" alignItems="center" mt="30px">
              <Box component="img" src={MinoIcon} mr="10px" sx={styles.placeholderImage} />
              <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }}>x {selectedMetazoa.length - selectedUrsaCount}</Typography>
            </Box>

            <Box display="flex" alignItems="center" mt="20px">
              <Box component="img" src={UrsaIcon} mr="10px" sx={styles.placeholderImage} />
              <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }}>x {selectedUrsaCount}</Typography>
            </Box>

            {/* Tx hash */}
            <Link
              target="_blank"
              href={getExplorerLink("tx", sendBattleTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>

            {/* Send more */}
            <ContainedButton
              onClick={handleSendMore}
              sx={styles.sendMoreButton}
            >
              Send More
            </ContainedButton>
          </Box>
        </Fragment>
      )
    }

    if (loadingSendBattle) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Box>
      )
    }

    return (
      <Fragment>
        {/* Warning box */}
        <WarningBox>
          <Typography variant="body1" color="primary" align="left">
            Only Metazoa who have harvested ≥ 2 days’ worth of HUNY have
            enough fuel to return to base.
            {" "}
            <Link
              target="_blank"
              href="https://docs.zolar.io/overview/creatures/collection-ii-metazoa/summoning#phase-2-or-the-transcendence"
            >
              Learn More
            </Link>
          </Typography>
        </WarningBox>

        <Box sx={styles.contentBox}>
          {/* Metazoa */}
          <TableContainer sx={styles.tableContainer}>
            <Table>
              <TableHead sx={styles.tableHead}>
                <TableRow>
                  {/* Gen */}
                  <TableCell align="left" width="10%">
                    <Typography variant="body1" color="primary">Gen</Typography>
                  </TableCell>

                  {/* Metazoa */}
                  <TableCell align="left">
                    <Typography variant="body1" color="primary">Metazoa</Typography>
                  </TableCell>

                  {/* Huny */}
                  <TableCell align="center">
                    <Typography variant="body1" color="primary">
                      HUNY / Day
                      {" "}
                      <SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" />
                    </Typography>
                  </TableCell>

                  {/* Select all */}
                  <TableCell align="center">
                    <Typography variant="body1" color="primary"
                    // sx={styles.selectAll}
                    // onClick={handleSelectAll}
                    >SELECT</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={styles.tableBody}>
                {metazoaTokens.map((token) => {
                  const attributes: any = {};
                  token?.attributes?.forEach(attribute => {
                    attributes[attribute.trait_type] = attribute.value;
                  })

                  return (
                    <TableRow key={token.id}>
                      {/* Gen */}
                      <TableCell align="left" sx={{ paddingLeft: "10px!important" }}>
                        <Typography variant="body1" color="primary">{attributes?.["Gen"] === "Unknown" ? "-" : attributes?.["Gen"]}</Typography>
                      </TableCell>

                      {/* Metazoa */}
                      <TableCell align="left">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box component="img" width="40px" src={token.image} alt={`ZOA #${token.id}`} sx={styles.zoaImage} onError={addDefaultSrc(token)} />
                          <Typography variant="body1" color="primary">
                            ZOA #{token.id}
                          </Typography>
                        </Box>
                      </TableCell>

                      {/* Huny */}
                      <TableCell align="center">
                        <Typography variant="body1" color="primary">{getHunyPerDay(attributes?.["Gen"], attributes?.["Faction"])}</Typography>
                      </TableCell>

                      {/* Checkbox */}
                      <TableCell align="center">
                        <Checkbox
                          icon={(!!token.attributes && token.attributes[0].value === "Ursa") ? <DisabledIcon /> : <UncheckedIcon />}
                          checkedIcon={<CheckedIcon />}
                          disabled={!!token.attributes && token.attributes[0].value === "Ursa"}
                          checked={isMetazoaSelected(token.id) && (!!token.attributes && token.attributes[0].value !== "Ursa")}
                          onChange={handleSelect(token.id)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Ursa/Mino count */}
          <Typography color="primary" sx={{ marginTop: "10px", display: "flex", alignItems: "center" }} component="span">
            <Typography sx={styles.qtySelected}>
              {selectedUrsaCount} Ursas - {selectedMetazoa.length - selectedUrsaCount} Mino
            </Typography>
            &nbsp;selected
          </Typography>

          <Box display="flex" sx={styles.buttonBox}>
            {!moonbattleApproved &&
              <ContainedButton sx={styles.approveButton} onClick={handleApprove}>
                {loadingApprove
                  ? <CircularProgress size={24} />
                  : <span style={{ lineHeight: '2rem' }}>Authorize</span>
                }
              </ContainedButton>
            }
            {/* Confirm */}
            <ContainedButton
              onClick={handleSendBattle}
              disabled={!selectedMetazoa.length || loadingSendBattle || loadingApprove || !moonbattleApproved || !!selectedUrsaCount}
              sx={styles.confirmButton}
            >
              {loadingSendBattle
                ? <CircularProgress size={24} />
                : "Confirm"
              }
            </ContainedButton>
          </Box>
        </Box>
      </Fragment>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true} dialogOverwrites={{ placeItems: 'center' }}>
      <Box sx={{ maxWidth: 700, }}>
        {getContent()}
      </Box>
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  tableContainer: {
    overflowY: "auto",
    maxHeight: "25vh",
    maxWidth: "700px",
    paddingRight: "10px",
    paddingBottom: "10px",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "transparent",
      top: "",
      left: "",
    },
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: "10px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },
  tableHead: {
    "& th.MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  hunyIcon: {
    verticalAlign: "top",
  },
  selectAll: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
    }
  },
  zoaImage: {
    marginRight: "10px",
    borderRadius: "16px",
  },
  qtySelected: {
    "@media (min-width:900px)": {
      marginBottom: "1px",
      fontWeight: 700,
      fontSize: "24px",
    },
    background: "-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    marginX: 'auto',
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "20px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  sendMoreButton: {
    marginTop: "20px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  placeholderImage: {
    height: "60px",
    width: "60px",
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  approveButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    marginRight: '10px',
    width: 270,
    "@media (max-width:600px)": {
      marginRight: 0,
      marginBottom: '10px',
      width: '100%',
    }
  },
  confirmButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    width: 270,
    "@media (max-width:600px)": {
      width: '100%',
    }
  },
  buttonBox: {
    marginTop: "20px",
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
}

export default SendDialog;
