import { Box, Container, SxProps, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import { PageLayout } from "layout";
import MiniGame from "../../assets/Minigame.png";
import { AppTheme, SimpleMap } from "../../utils/types";

const MintingPage: React.FC = () => {
    return (
      <PageLayout>
        <Container maxWidth="xl" sx={styles.container}>
          <Box sx={styles.contentRow}>
            <Box width={{ xs: "100%", sm: "100%", md: "70%"}}>
              <Box width={{ xs: "100%", md: "100%", lg: "80%" }}>
                <Typography variant="h2" color="primary" sx={styles.header}>
                  Welcome aboard
                </Typography>
              </Box>
              <Box sx={styles.textbody}>
                <Typography variant="body1" color="primary">
                  Brave warriors! Take flight into the Zolarverse and dive into the heart 
                  of an epic war for the galaxy’s most coveted treasure, $HUNY! Make the 
                  ascent with battle-ready Metazoa by your side, summoned from the divided 
                  factions of Ursa &amp; Mino. But be warned, the passage to Zolar is 
                  unpredictable and defection is possible.  <br />
                  <br />
                  Which path shall befall you?
                </Typography>
              </Box>
            </Box>
            <Box width={{ xs: "100%", sm: "100%", md: "25%" }} sx={styles.minigameBox}>
              <img src={MiniGame} alt="mini-game" width="100%" height="100%" />
            </Box>
          </Box>
          <Outlet/>
        </Container>
      </PageLayout>
    );
  };

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    position: "relative",
    padding: "0px 0px 100px",
    minHeight: '1600px',
  },
  contentRow: {
    padding: "160px 120px 0px",
    marginBottom: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    zIndex: 1,
    "@media (max-width:900px)": {
      padding: "160px 60px 80px",
      flexDirection: 'column',
    },
    "@media (max-width:600px)": {
      padding: "130px 24px 40px",
    },
  },
  header: {
    "@media (max-width:900px)": {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  textbody: {
    margin: "48px 0px",
    "@media (max-width:900px)": {
      margin: "24px 0px 48px",
    },
  },
  minigameBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: '20px',
    "@media (max-width:900px)": {
      marginLeft: '0px',
      width: '100%',
    },
  },
}

export default MintingPage;