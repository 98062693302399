import { MainLayout } from "layout";
import ScrollToTop from "utils/ScrollToTop";
import { FAQ, Hero, Huny, Intro, Mission, MissionPhases } from ".";

function Homepage() {
  return (
    <MainLayout>
      <ScrollToTop />
      <Hero />
      <Intro />
      <MissionPhases />
      <Mission />
      <Huny />
      <FAQ />
    </MainLayout>
  );
}

export default Homepage;
