import { Box, Button, ButtonProps, styled, SxProps } from "@mui/material";
import { BaseButton } from "assets";
import { AppTheme, SimpleMap } from "utils/types";

const ContainedButtonSmall:React.FC<ButtonProps> = (props:ButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button
      variant="contained"
      color="secondary"
      sx={styles.button}
      {...rest}
    >
      <BaseButtonBox>
        <BaseButton height="100%" width="100%"/>
      </BaseButtonBox>
      {children}
    </Button>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  button: {
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
}

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  height: '42px',
  "@media (max-width:600px)": {
    display: "none",
  },
});

export default ContainedButtonSmall;