import { toBech32Address } from "@zilliqa-js/zilliqa";
import BigNumber from "bignumber.js";
import dayjs, { Dayjs } from "dayjs";
import { Network } from "zilswap-sdk/lib/constants";

dayjs.extend(require('dayjs/plugin/relativeTime'))

type DateType = string | number | Date | Dayjs

export const DefaultDateFormat = "D MMM YYYY";

declare module 'dayjs' {
  interface Dayjs {
    fromNow(withoutSuffix?: boolean): string
    from(compared: DateType, withoutSuffix?: boolean): string
    toNow(withoutSuffix?: boolean): string
    to(compared: DateType, withoutSuffix?: boolean): string
  }
}

export const DefaultFallbackNetwork = Network.MainNet;

export const Decimals = {
  HUNY: 12,
  ZWAP: 12,
  ZIL: 12,
  RESOURCE: 2,
}

export const LoadingKeys = {
  connectWallet: [
    "connectWallet",
    "connectWalletZilPay",
    "connectWalletPrivateKey",
    "initWallet",
    "initChain",
  ],
};

export const LocalStorageKeys = {
  PrivateKey: "zilswap:private-key",
  ZilPayConnected: "zilswap:zilpay-connected",
  ZeevesConnected: "zilswap:zeeves-connected",
  RevealedToken: "tbm:revealed-tokens",
  TranscendedTokens: "tbm:transcended-tokens",
  MetazoaAccessToken: "zilswap:metazoa-access-token",
  MetazoaMetadata: "tbm:metazoa-metadata",
  TheBearMarketMetadata: "tbm:thebearmarket-metadata",
};

export const ZilPayNetworkMap = {
  mainnet: Network.MainNet,
  testnet: Network.TestNet,
} as { [index: string]: Network };

export const RPCEndpoints: { [key in Network]: string } = {
  [Network.MainNet]: 'https://api.zilliqa.com',
  [Network.TestNet]: 'https://dev-api.zilliqa.com',
};

export const BIG_ZERO = new BigNumber(0);
export const BIG_ONE = new BigNumber(1);

export const BLOCKS_REFINE_HUNY = 2300 * 365;
export const BLOCKS_PER_HOUR = 105;
export const BLOCKS_PER_MINUTE = BLOCKS_PER_HOUR / 60;

export const MAX_ZOA = {
  EnterBattle: 6,
  HarvestHuny: 6,
  ReturnToBase: 4,
  SummonReinforcements: 3,
};

// Game
export const HUNY_GATHERED_PER_DAY = {
  0: 250,
  1: 1000,
  2: 2000,
  3: 4000,
} as { [index: string]: number };

export const RETURN_BLOCKS_REQUIRED: { [key in Network]: number } = {
  [Network.MainNet]: 5040,
  [Network.TestNet]: 2,
};

// Phase 3
export const SaleTime = {
  [Network.MainNet]: {
    start: new Date("2022/03/28 08:00:00 GMT+0").getTime(),
    end: new Date("2022/04/08 08:00:00 GMT+0").getTime(),
    reveal: undefined,
  } as const,
  [Network.TestNet]: {
    start: new Date("2022/03/26 06:00:00 GMT+0").getTime(),
    end: new Date("2022/03/28 08:00:00 GMT+0").getTime(),
    reveal: undefined,
  } as const,
} as const;

// Phase 4
export const PHASE_FOUR_GEN_SUPPLY = {
  1: 700,
  2: 500,
} as { [index: string]: number };

export const PHASE_FOUR_MAX_SUPPLY = 1600;

export const PHASE_FOUR_SUMMON_PRICE = {
  1: 12000,
  2: 18000,
  3: 27000,
} as { [index: string]: number };

// Guild
export const ITEM_ID = {
  [Network.MainNet]: {
    GuildCrest: 0,
    RefigurationSeal: 1,
    HeraldryScroll: 2,
    TreasurersDeed: 4,
    Geodes: 12,
    Elderberries: 13,
    ZolraniumScraps: 14,
  },
  [Network.TestNet]: {
    GuildCrest: 0,
    RefigurationSeal: 1,
    HeraldryScroll: 2,
    TreasurersDeed: 3,
    Geodes: 25,
    Elderberries: 26,
    ZolraniumScraps: 27,
  }
};

export const ContractsBech32 = {
  [Network.MainNet]: {
    Nft: "zil167flx79fykulp57ykmh9gnf3curcnyux6dcj5e", // TBM
    NftV2: "zil177dy26j6l4qj6wysug3j7cs97ejtaz2h06vfwa", // Metazoa
    TranscendenceMinter: "zil1f9qhqy2t96ldarv5nsswz6l4dzsu3mshc9twpy", // Transcendence
    CommunityMinter: "zil13wfx73keuwhycn2xeml0xdqmnflttn99vgsl7m", // Gold Rush Minter
    MoonBattle: "zil1w6tynx7zxt3dcn92dzwl8m4x85dr9rjja37c68", // Moon Battle
    HunyToken: "zil1m3m5jqqcaemtefnlk795qpw59daukra8prc43e", // Huny Token
    Refinery: "zil1l7j6hradh9t07dxsrn6zd49tfgad6mlny9xz6y", // Refinery
    Hug3Recovery: "zil1q7thlny5h5aetask2mfak5whm7p67j0sxeg9yy", // HUG3Recovery
    Hive: "zil10mmqxduremmhyz2j89qptk3x8f2srw8rqukf8y", // Hive v2
    LostPlanet: "zil1pzhv7z7ytejsfsgu8fy5rzqsxhjcfe6d25fz3s", // Old Moon Battle
    OldHive: "zil1328u4vxdsguz09qxlzp2e8ayt3p0mt33glpcl6", // Old Hive
    ZGrandEmporium: "zil1quwn4dqhjpeaalsd4leveldrdth074slg0ryty", // Marketplace
    Stall: "zil1643p5cd8r3ntgz2d7zzkwj8j2agvs2lvn3ncf3", // Stall
    BankAuthority: "zil1tzfwh6vha4m94tlyuq234psg3jvp4ek45hdj7h", // Bank Authority
    Professions: "zil1mf7ayag3k8hwe9raml96ms00qt4wkhynva05cp", // Professions

    Geode: toBech32Address("0xdfa2b93c99ad4f270191308bce0186de0eb6b981"),// Geode
    Berry: toBech32Address("0x7b58a5ff93b23a9ef9b6b9fb961f9ee0649dd2d8"),// Berry
    Scrap: toBech32Address("0x635c12e9ef60a03d1e45fc661926b866665c182b"),// Scrap
    Items: toBech32Address("0xbd801eddb155da249758d0805856bd7ec2b70098"),// Items
    QuestScrap: toBech32Address("0xeda6eda9dc8e97723186b154480706b198d9a609"), // QuestScrap
    QuestBerry: toBech32Address("0xfae34bf4da6d241b1725842db2b7a21c6b164dde"),// QuestBerry
    QuestGeode: toBech32Address("0xb75c69421254c3df2a79863b5914e350b39b2083"),//QuestGeode
    GemRefinery: toBech32Address("0xe84b33b4812dceac19172968f67327dce4bbe4e0"),// GemRefinery
    ZOMG: toBech32Address("0xb56988320c80e119ccfc743300704502a1bc691c"),// ZOMG ZOrdnances
    Resource: toBech32Address("0x3e443e78b9df44e6e1d03d58486e05c71d9266e3"), // ZOMG Resources
  },
  [Network.TestNet]: {
    Nft: "zil1lxsegd7qphnnm8rtg630348s6em2nk8zenwnrw", // TBM
    NftV2: "zil1fqtpw596kumzpv3u9pujt0fzjzfa3dewkc23cz", // Metazoa
    TranscendenceMinter: "zil1kptekvemmwyp0wvt6wz74avsnef495x2pn5x8f", // Transcendence
    CommunityMinter: "zil120j09e4lfsxvsq8aft7vqhzzuk098v9mpspvhr", // Gold Rush minter
    MoonBattle: "zil1c6zzuu4m0thns506fq3aha4xrwgrswfkssdl8t", // Moon Battle
    HunyToken: "zil1luksetpk2r5pql6fcjecgepzwzh9q0p5u4pt5y", // Huny Token
    Refinery: "zil12z2w2accx52cpte5xf8shgc4p0mf255qkjnpsv", // Refinery
    Hug3Recovery: "zil13edkyuuess3k3eq8dp9sf2zq574khk828wwqm5", // HUG3Recovery
    Hive: "zil1zswdemy363ew2g2wdwz7au2mah5fqy7v8tmpu8", // Hive v2
    LostPlanet: "zil1c6zzuu4m0thns506fq3aha4xrwgrswfkssdl8t", // Old Moon Battle
    OldHive: "zil1vvyj68rrslg0lrthgma4efs2yd52sf0m9f74dc", // Old Hive
    ZGrandEmporium: "zil173lfrkj25vsutne5jzknxppg4ewtyc96plstpn", // Marketplace
    Stall: "zil1avs75zxqsc79mkwxlv67wet85362lts0lsre56", // Stall,
    BankAuthority: "zil1z4454zvmy5wdtrl5u5vfgyj47f0mq7ma7zx82c", // Bank Authority
    Professions: toBech32Address("0xda7dd27511b1eeec947ddfcbadc1ef02eaeb5c93"),// Professions

    Geode: toBech32Address("0xd43510334189a3e86c46738f8405ae86d0cb6218"),// Geode
    Berry: toBech32Address("0x0e2476d8ab1f4030952e36390936484177bcd8a3"),// Berry
    Scrap: toBech32Address("0x36904224b3fea899087733d85cb92d3b661acbeb"),// Scrap
    Items: toBech32Address("0xf628d335bed123b2b2356c5929dc84f2580802bf"),// Items
    QuestScrap: toBech32Address("0x46e6bc4e356f182263bd31c406a1e963ada6990e"), // QuestScrap
    QuestBerry: toBech32Address("0xddd89fc08f4cb2f28477a26a347391ff8d1e87fc"),// QuestBerry
    QuestGeode: toBech32Address("0xd9bff5e9d507ad6f8e3d6e9abca64a758ddd726e"),//QuestGeode
    GemRefinery: toBech32Address("0xb9725f1e92cdf1abdead2adc59775ff9d9c9f7a5"),// GemRefinery
    ZOMG: toBech32Address("0x3d8dde067b8fc1f71750c3f0cbcbbe78fa42a739"),// ZOMG ZOrdnances
    Resource: toBech32Address("0x3ebe68a839c576669ac1d361aa7d89fee0aa24bc"), // ZOMG Resources
  }
}

export const BlacklistAddresses = [
  "zil16wvq927hzl2zfu3rhaekesdzgy2vvwj970202x",
  "zil1v2g8tcz2kt74499syghyfxjexyppnzxpjf4w0a",
  "zil164yn7442ta0tnwwzl5tngk0c5de2m0utdvvesa",
  "zil10q75lk4zuhjr3kkczeykdhznm28dfqkx0vjxnz",
];

export const MetazoaBaseUri = {
  [Network.MainNet]: "https://api.zolar.io/metazoa/metadata/",
  [Network.TestNet]: "https://test-api.zolar.io/metazoa/metadata/",
}

export const WsUri = {
  [Network.MainNet]: 'wss://api-ws.zilliqa.com',
  [Network.TestNet]: 'wss://dev-ws.zilliqa.com',
}

export const CHAIN_IDS = {
  [Network.TestNet]: 333, // chainId of the developer testnet
  [Network.MainNet]: 1, // chainId of the mainnet
}

export const MSG_VERSION = 1; // current msgVersion

export const USERNAME_REGEX = /^[A-Za-z0-9_]{1,19}$/
export const TWITTER_REGEX = /^[A-Za-z0-9_]{1,14}$/
export const DISCORD_REGEX = /^.{3,32}#[0-9]{4}$/
export const WALLET_REGEX = /^zil\w{39}$/g

export const METADATA_VERSION = 2;

export const TokenAddressesBech32 = {
  [Network.MainNet]: {
    ZIL: "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz",
    ZWAP: "zil1p5suryq6q647usxczale29cu3336hhp376c627"
  },
  [Network.TestNet]: {
    ZIL: "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz",
    ZWAP: "zil1k2c3ncjfduj9jrhlgx03t2smd6p25ur56cfzgz"
  }
}

export const GuildExemptions: { [key in Network]: number[] } = {
  [Network.MainNet]: [5],
  [Network.TestNet]: [],
}

export const RESOURCE_PER_EPOCH = {
  DEX: 28,
  STR: 28,
  INT: 10,
}

export const QUEST_EPOCH = {
  [Network.TestNet]: 2300,
  [Network.MainNet]: 2300, // Blocks per day
}

export enum MissionGroundLocation {
  MoonBattleground = "moon-battleground",
  ZolarAsteroidBelt = "zolar-asteroid-belt",
  ElderWoodlands = "elder-woodlands",
  ZOMGStore = "zomg-store",
};

export enum MissionGroundResource {
  Elderberries = "elderberries",
  Geodes = "geodes",
  ZolraniumScraps = "zolranium-scraps",
}

export const RescueShipStart = {
  [Network.MainNet]: 2443532 + 2300 * 21,
  [Network.TestNet]: 4852355 + 21 * BLOCKS_PER_HOUR * 24,
}
