import { Box, BoxProps, SxProps, Tab, Tabs } from "@mui/material";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import TabPanel from 'components/TabPanel';
import { logger } from "core/utilities";
import { FC, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import ResourcesView from "./ResourcesView";
import ZOrdnancesView from "./ZOrdnancesView";

interface ZOMGViewProps extends BoxProps {

}

const ZOMGView: FC<ZOMGViewProps> = ({
  ...rest
}: ZOMGViewProps) => {

  const tabs = [
    {
      title: 'Resources',
      content: <ResourcesView />
    },
    {
      title: 'Z-Ordnances',
      content: <ZOrdnancesView />
    },
  ];

  const dispatch = useDispatch();

  // STATES / HOOKS -----------------

  const [currentTab, setCurrentTab] = useState(0);

  logger("debug-map-zomg", {
    tabIdx: currentTab,
    tab: tabs[currentTab],
  });

  useMemo(() => {
    dispatch(actions.Layout.updateMapBackground(""));
    // eslint-disable-next-line
  }, [])

  // FUNCTIONS ----------------------

  // EVENT HANDLERS -----------------

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    logger("debug-tabs-zomg", {
      currentTab,
      newValue,
      newTab: tabs[newValue],
    })
    setCurrentTab(newValue);
  };

  // --------------------------------


  return (
    <Box
      component="main"
      sx={combineStyles(styles.viewRoot, rest.sx)}>

      <Tabs
        centered
        // defaultValue={0}
        value={currentTab}
        onChange={handleChange}
        sx={styles.tabs}
      >
        {tabs.map((tab, idx) => (
          <Tab
            disableRipple
            key={idx}
            label={tab.title}
            // value={idx}
            sx={styles.tab}

          />
        ))}
      </Tabs>

      {tabs.map((tab, idx) => (
        <TabPanel key={idx} value={idx} index={idx}
          hidden={currentTab !== idx}
          sx={styles.tabPanelContent}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  viewRoot: {
    // minWidth: '720px',
    minWidth: '100%',

    // //DEBUG: Remove this
    // border: '1px solid red',
    // "*": {
    //   border: '1px solid red',
    // }
  },

  /// Tabs
  tabs: {
    paddingBottom: '1em',

    '& .MuiTabs-flexContainer': {
      gap: 4,
    },

    '& .MuiTabs-indicator': {
      backgroundColor: '#AEF1EE',
      background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
      paddingY: '2px',
    },
  },
  tab: {
    // color: "rgba(254, 254, 254, 0.4)",
    fontFamily: ' Syne',
    fontStyle: ' normal',
    fontWeight: ' 700',
    fontSize: ' 24px',
    lineHeight: ' 150%',
    ...GUILD_LIGHTGRAY_GRADIENT,
  },
  tabPanelContent: {
    width: '100%',
  },

};



export default ZOMGView;
