import { Theme } from "@mui/material";

const MuiDrawer = (theme: Theme) => ({
  styleOverrides: {
    paper: {
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)',
      boxShadow: 'inset 1px -1px 0px rgba(174, 241, 238, 0.1)',
      backdropFilter: 'blur(24px)',
    },
  },
});

export default MuiDrawer;
