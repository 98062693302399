import React, { FC, PropsWithChildren } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from "react-dnd-touch-backend";
import { MultiBackend, TouchTransition, MouseTransition } from "react-dnd-multi-backend";

const config = {
  backends: [
    {
      backend: HTML5Backend,
      transition: MouseTransition
      // by default, will dispatch a duplicate `mousedown` event when this backend is activated
    },
    {
      backend: TouchBackend,
      // Note that you can call your backends with options
      options: { enableMouseEvents: true },
      transition: TouchTransition,
      // will not dispatch a duplicate `touchstart` event when this backend is activated
      skipDispatchOnTransition: true
    }
  ]
};

type ProviderProps = PropsWithChildren<{}>

const Provider: FC<ProviderProps> = ({
  children,
}: ProviderProps) => {
  return (
    <DndProvider backend={MultiBackend} options={config}>
      {children}
    </DndProvider>
  )
}

export default Provider;