import { Allowance, Guild, GuildBankInfo, GuildTransactions, TaxByWeek } from "store/types";

export const MetazoaGuildActionTypes = {
  LOAD_GUILDS: "METAZOA:LOAD_GUILDS",
  RELOAD_GUILD: "METAZOA:RELOAD_GUILD",
  LOAD_GUILD_TAX: "METAZOA:LOAD_GUILD_TAX",
  LOAD_ALLOWANCES: "METAZOA:LOAD_ALLOWANCES",
  RELOAD_BANK: "METAZOA:RELOAD_BANK",
  RELOAD_BANK_TRANSACTIONS: "RELOAD_BANK_TRANSACTIONS",
  START_LOADING_BANK_TRANSACTIONS: "METAZOA:START_LOADING_BANK_TRANSACTIONS",
  CANCEL_LOADING_BANK_TRANSACTIONS: "METAZOA:CANCEL_LOADING_BANK_TRANSACTIONS"
}

export const GuildActionTypes = {
  UPDATE_GUILD: "UPDATE_GUILD",
  UPDATE_GUILDS: "UPDATE_GUILDS",
  UPDATE_ALLOWANCES: "UPDATE_ALLOWANCES",
  UPDATE_BANK: "UPDATE_BANK",
  UPDATE_BANK_TRANSACTIONS: "UPDATE_BANK_TRANSACTIONS",
  UPDATE_BANK_TAXES: "UPDATE_BANK_TAXES",
}

export function loadGuilds() {
  return { type: MetazoaGuildActionTypes.LOAD_GUILDS }
}

export function reloadGuild(payload: Guild) {
  return {
    type: MetazoaGuildActionTypes.RELOAD_GUILD,
    payload
  }
}

export function reloadBank(payload: Guild) {
  return {
    type: MetazoaGuildActionTypes.RELOAD_BANK,
    payload
  }
}

export function loadGuildTax(payload: Guild) {
  return {
    type: MetazoaGuildActionTypes.LOAD_GUILD_TAX,
    payload
  }
}

export function loadAllowances() {
  return { type: MetazoaGuildActionTypes.LOAD_ALLOWANCES }
}

export function updateAllowances(payload: Allowance) {
  return { 
    type: GuildActionTypes.UPDATE_ALLOWANCES,
    payload
  }
}

export function updateGuild(payload: Guild) {
  return {
    type: GuildActionTypes.UPDATE_GUILD,
    payload
  }
}

export function updateBank(payload: GuildBankInfo) {
  return {
    type: GuildActionTypes.UPDATE_BANK,
    payload
  }
}


export function updateGuilds(payload: Guild[]) {
  return {
    type: GuildActionTypes.UPDATE_GUILDS,
    payload,
  }
}

export function updateTax(payload: TaxByWeek[]) {
  return {
    type: GuildActionTypes.UPDATE_BANK_TAXES,
    payload,
  }
}

export function updateBankTransactions(payload: GuildTransactions) {
  return {
    type: GuildActionTypes.UPDATE_BANK_TRANSACTIONS,
    payload,
  }
}

export function startLoadingBankTransactions(payload: Guild) {
  return { 
    type: MetazoaGuildActionTypes.START_LOADING_BANK_TRANSACTIONS, 
    payload 
  }
}

export function cancelLoadingBankTransactions() {
  return { type: MetazoaGuildActionTypes.CANCEL_LOADING_BANK_TRANSACTIONS }
}