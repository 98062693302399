import React, { Fragment, useMemo, useState } from "react";
import { Box, Button, Divider, styled, SxProps, Typography } from "@mui/material";
import { BaseButton, Huny, ZilliqaLogo } from "assets";
import HunyStatsImg from "assets/HunyStats.png";
import BigNumber from "bignumber.js";
import BoxWithHeader from "components/BoxWithHeader";
import ContainedButton from "components/ContainedButton";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import { Link } from "react-router-dom";
import { BIG_ONE, BIG_ZERO, BLOCKS_PER_MINUTE, Decimals, HUNY_GATHERED_PER_DAY } from "utils/constants";
import { bnOrZero, formatIntegerNumber } from "utils/strings/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";
import { HarvestDialog } from "./components";

const HunyStats: React.FC = () => {
  const tokenState = useRedux((state) => state.token);
  const { metazoaBlocksStaked, stakedMetazoa, moonBattleInfo: { capturedHunyDebt, capturedHunyPerShare } } = tokenState
  const hivePool = useRedux((state) => state.token.hivePool);
  const hunyPots = useRedux((state) => state.token.hunyPots);
  const refineryHunyStats = useRedux((state) => state.token.refineryHunyStats)
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const totalHunyGathered = useMemo(() => {
    if (!Object.values(metazoaBlocksStaked).length) return 0;

    let hunyGathered = 0;

    Object.entries(stakedMetazoa).forEach(entry => {
      const id = entry[0];
      const attributes = entry[1].attributes;
      const gen = attributes?.find(attribute => attribute.trait_type === "Gen")?.value;
      const faction = attributes?.find(attribute => attribute.trait_type === "Faction")?.value;
      const level = attributes?.find(attribute => attribute.trait_type === "Berserker Level")?.value;

      if (faction === "Ursa") {
        hunyGathered += ((metazoaBlocksStaked[id] || 0) / BLOCKS_PER_MINUTE / 60 / 24) * HUNY_GATHERED_PER_DAY[gen!];
      }

      if (faction === "Mino") {
        hunyGathered += capturedHunyPerShare.times(parseInt(level || '1', 10))
          .minus(capturedHunyDebt[id] || 0).shiftedBy(-12).toNumber()
      }
    })

    return hunyGathered;
  }, [metazoaBlocksStaked, stakedMetazoa, capturedHunyDebt, capturedHunyPerShare]);

  const totalHunyInRefinery = useMemo(() => {
    if (!hunyPots) return BIG_ZERO;

    return BigNumber.sum(...Object.values(hunyPots).map(pot => pot.amount)).shiftedBy(-Decimals.HUNY);
  }, [hunyPots,]);

  let refineryBoost = BIG_ONE;
  if (refineryHunyStats?.totalShare?.gt(0) && refineryHunyStats?.totalSupply) {
    // boost = total supply / total share
    refineryBoost = refineryHunyStats.totalSupply.div(refineryHunyStats.totalShare);
  }

  const handleShowDialog = () => {
    setShowDialog(true);
  }

  return (
    <Fragment>
      <BoxWithHeader headerText={<Typography variant="h3" color="primary">HUNY STATS</Typography>}>
        <Box sx={styles.hunyContainer}>
          <Box sx={styles.hunyFrame}>
            <img src={HunyStatsImg} alt="huny-stats" width="400px" />
          </Box>

          <Box sx={styles.hunyStatsBox}>
            {/* Total HUNY Gathered */}
            <Box sx={styles.hunyStatsRow}>
              <Box>
                <Typography variant="body1" color="primary">
                  Total Gathered HUNY
                  {/* TODO: margin left + vert align */}
                  <TooltipZolar link={"https://docs.zolar.io/usdhuny/hunynomics#lockingmodel"}>
                    You will receive HUNY in this proportion:
                    <br />&bull;&nbsp;<strong>URSA:</strong> 78% of HUNY you’ve gathered
                    <br />&bull;&nbsp;<strong>MINO:</strong> 100% of HUNY you’ve stolen
                  </TooltipZolar>
                </Typography>
                <Typography variant="h1" color="primary" sx={styles.qty}>
                  <Huny width="50px" height="50px" />
                  &nbsp;{totalHunyGathered.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </Typography>
              </Box>
              <ContainedButton sx={styles.button} onClick={handleShowDialog}>
                harvest
              </ContainedButton>
            </Box>

            <Divider sx={styles.divider} />

            {/* Total HUNY in Refinery */}
            <Box sx={styles.hunyStatsRow}>
              <Box>
                <Typography variant="body1" color="primary">
                  Total HUNY in Refinery
                  <TooltipZolar link="https://docs.zolar.io/stations/refinery">
                    HUNY that has just been harvested is highly unstable and requires further refinement to make it usable.
                  </TooltipZolar>
                </Typography>
                <Typography variant="h1" color="primary" sx={styles.qty}>
                  <Huny width="50px" height="50px" />
                  <Typography variant="h3" color="primary">
                    &nbsp;{bnOrZero(totalHunyInRefinery).toNumber().toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    {" "}+ {bnOrZero(totalHunyInRefinery).times(refineryBoost.minus(1)).toNumber().toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    &nbsp;
                    (bonus
                    <TooltipZolar link="https://docs.zolar.io/stations/refinery#usdhuny-early-claim-fee">
                      Bonus huny in the refinery comes from early claimers and is immediately awarded.
                      The bonus amount is variable, and goes up when other players do early claims,
                      but goes down when other players complete their refinement or enter the pool.
                    </TooltipZolar>
                    )
                  </Typography>
                </Typography>
              </Box>
              <Button
                component={Link}
                variant="contained"
                color="secondary"
                sx={styles.button}
                to="/refinery"
              >
                <BaseButtonBox>
                  <BaseButton height="100%" />
                </BaseButtonBox>
                Visit Refinery
              </Button>
            </Box>

            <Divider sx={styles.divider} />

            {/* Total HUNY in Magic Hive */}
            <Box sx={styles.hunyStatsRow}>
              <Box>
                <Typography variant="body1" color="primary">
                  Total HUNY in Magic Hive
                  <TooltipZolar link="https://docs.zolar.io/stations/magic-hive">
                    Refined HUNY can be be put to work to help you earn even more HUNY.
                  </TooltipZolar>
                </Typography>
                <Typography variant="h1" color="primary" sx={styles.qty}>
                  <Huny width="50px" height="50px" />
                  &nbsp;{formatIntegerNumber(hivePool?.userHunyReserves.shiftedBy(-Decimals.HUNY))}
                  <span style={{ marginLeft: "10px", marginRight: "10px" }}>+</span>
                  <ZilliqaLogo width="50px" height="50px" />
                  &nbsp;{formatIntegerNumber(hivePool?.userZilReserves.shiftedBy(-Decimals.ZIL))}
                </Typography>
              </Box>
              <Button
                component={Link}
                variant="contained"
                color="secondary"
                sx={styles.button}
                to="/hive"
              >
                <BaseButtonBox>
                  <BaseButton height="100%" />
                </BaseButtonBox>
                Visit Hive
              </Button>
            </Box>
          </Box>
        </Box>
      </BoxWithHeader>

      <HarvestDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
      />
    </Fragment>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  hunyContainer: {
    paddingX: '20px',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '400px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    }
  },
  hunyFrame: {
    width: '280px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  hunyStatsBox: {
    height: '100%',
    width: '70%',
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    '@media (max-width: 1200px)': {
      width: '100%',
      marginLeft: '0px',
    }
  },
  hunyStatsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
  button: {
    marginTop: '28px',
    marginLeft: '20px',
    minWidth: '270px',
    height: '64px',
    whiteSpace:'nowrap',
  },

  divider: {
    background: 'rgba(174, 241, 238, 0.1)',
  },
  qty: {
    fontSize: '1.8rem',
    lineHeight: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '8px',
  },
}

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    height: "100%",
  },
});

export default HunyStats;
