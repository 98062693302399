import { Theme } from "@mui/material";

const MuiButton = (theme: Theme) => ({
  styleOverrides: {
    outlinedSizeSmall: {
      padding: theme.spacing(0.5, 2.5),
      textTransform: "none",
    },
    outlinedSizeMedium: {
      padding: theme.spacing(1.5, 7.5),
      borderRadius: "16px",
      fontFamily: "Prompt",
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
      textTransform: "uppercase",
    },
    contained: {
      padding: theme.spacing(2, 5),
      fontFamily: "Prompt",
      fontWeight: 600,
      borderRadius: "16px",
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
      background:
        "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
      boxShadow: "none",
      textTransform: "uppercase",
      "&:hover": {
        boxShadow: "none",
      },
      "@media (max-width:600px)": {
        padding: theme.spacing(0.8, 3),
        fontSize: "1.125rem",
      },
      transition: "none",
      WebkitTransition: "none",
      "&.Mui-disabled": {
        color: "rgba(254, 254, 254, 0.4)",
        background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
        "&:hover": {
          background: 'transparent',
        }
      },
    },
    containedSizeLarge: {
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    },
  },
});

export default MuiButton;
