import { Box, BoxProps, Divider, Link, SxProps, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import BoxWithHeader from "components/BoxWithHeader";
import { getExplorerLink } from "utils/strings/links";
import { formatIntegerNumber } from "utils/strings/strings";
import { combineStyles } from "utils/themeUtilities";
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";

export interface LeaderboardItem {
  address: string;
  amount: BigNumber;
}

export interface RenderLeaderboardProps extends BoxProps {
  headerText?: string | JSX.Element;
  rows: LeaderboardItem[];
  selfRow: LeaderboardItem | null;
  selfRank: number;
}


const RenderLeaderboard: React.FC<RenderLeaderboardProps> = (props: RenderLeaderboardProps) => {
  const { headerText, rows, selfRank, selfRow, ...rest } = props;
  const network = useRedux((state) => state.blockchain.network);
  const walletAddress = useRedux((state) => state.wallet.wallet?.addressInfo.bech32);
  const connectedWallet = useRedux((state) => state.wallet.wallet);

  const getAddressLink = (address: string) => {
    return (
      <Link target="_blank" href={getExplorerLink("address", address, network)}>
        {truncate(address)}
      </Link>
    )
  };

  return (
    <BoxWithHeader
      headerText={<Typography variant="h3" color="primary" sx={styles.headerText}>{headerText}</Typography>}
      sx={styles.leaderboardBox}
      {...rest}
    >
        <Box height="180px">
          {rows.slice(0, 5).map((row, index) => (
            <Box key={row.address} sx={combineStyles(styles.leaderboardRows, { ...(row.address === walletAddress) && styles.leaderboardRowYou })}>
              <Typography variant="body1" color="primary" sx={styles.ranking}>{index + 1}</Typography>
              <Typography variant="body1" color="primary" sx={styles.address}>{getAddressLink(row.address)} {row.address === walletAddress ? "(You)" : ""}</Typography>
              <Typography variant="body1" color="primary" sx={styles.count}>{formatIntegerNumber(row.amount)}</Typography>
            </Box>
          ))}
        </Box>
        {
          !!connectedWallet
          ? 
          <>
            <Divider sx={styles.divider} />
            <Box sx={styles.yourRank}>
              <Typography variant="body1" color="secondary" sx={styles.ranking}>{selfRank || "-"}</Typography>
              {!!selfRow && (
                <>
                  <Typography variant="body1" color="secondary" sx={styles.address}>{getAddressLink(selfRow.address)} (You)</Typography>
                  <Typography variant="body1" color="secondary" sx={styles.count}>{formatIntegerNumber(selfRow.amount)}</Typography>
                </>
              )}
            </Box>
          </>
          : <></>
        }
    </BoxWithHeader>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  leaderboardBox: {
    width: '32.5%',
    minWidth: '390px',
  },
  headerText: {
    "@media (max-width: 1200px)": {
      fontSize: '1.25rem',
    }
  },
  leaderboardRows: {
    width: '100%',
    padding: '5px 10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leaderboardRowYou: {
    alignItems: 'center',
    borderRadius: '16px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
  },
  ranking: {
    width: '50px',
  },
  address: {
    width: '165px',
  },
  count: {
    width: '105px',
    textAlign: 'right',
  },
  divider: {
    background: 'rgba(174, 241, 238, 0.1)',
    marginY: '10px',
    marginTop: '30px',
  },
  yourRank: {
    padding: '10px',
    width: '100%',
    paddingY: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}
export default RenderLeaderboard;
