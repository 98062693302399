import { Box, Button, CircularProgress, SxProps, Typography } from "@mui/material";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import { ConnectWalletResult, connectWalletZilPay } from "core/wallet";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { actions } from "store";
import { useToaster } from "utils";
import { LoadingKeys, LocalStorageKeys } from "utils/constants";
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from 'utils/types';
import useAsyncTask from "utils/useAsyncTask";
import useRedux from "utils/useRedux";
import useTaskSubscriber from "utils/useTaskSubscriber";

const ConnectWalletButtonDrawer: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = () => {
  const wallet = useRedux((state) => state.wallet.wallet);
  const walletAddr = truncate(wallet?.addressInfo.bech32);
  const profile = useRedux((state) => state.profile.profile)
  const username = profile?.username;
  const profileAvatar = profile?.avatarUrl;

  const toaster = useToaster();

  const [runConnectTask] = useAsyncTask<void>("connectWalletZilPay", (error) => {
    toaster("Error connecting to ZilPay");
  });
  const [isLoading] = useTaskSubscriber(...LoadingKeys.connectWallet);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem(LocalStorageKeys.ZilPayConnected) === "true" && !wallet) {
      setTimeout(handleConnectWallet, 500); //wait for zilPay initialization
    }

    //eslint-disable-next-line
  }, []);

  const connectZilPay = (ignoreError: boolean) => {
    runConnectTask(async () => {
      if (!!wallet) return;

      const zilPay = (window as any).zilPay;
      if (typeof zilPay === "undefined") {
        if (!ignoreError) throw new Error("ZilPay extension not installed");
        return;
      }

      const result = await zilPay.wallet.connect();
      if (result !== zilPay.wallet.isConnect) {
        if (!ignoreError)
          throw new Error("ZilPay could not be connected to");
        return;
      }

      const walletResult: ConnectWalletResult = await connectWalletZilPay(
        zilPay
      );
      if (walletResult.error) throw walletResult.error;

      if (walletResult.wallet) {
        const { wallet } = walletResult;
        const { network } = wallet;
        dispatch(actions.Blockchain.initialize({ network, wallet }));
        dispatch(actions.Wallet.update({ wallet }));
        return;
      }
    });
  };

  const handleConnectWallet = (event?: React.MouseEvent<HTMLElement>) => {
    connectZilPay(!event);
  }

  return (
    <Box>
      {isLoading ? (
        <Box sx={styles.buttonContainer}>
          <Button size="small" variant="outlined" color="secondary"
            sx={styles.headerCTA}
            onClick={handleConnectWallet}
            disableFocusRipple
          >
            <CircularProgress size={18} />
          </Button>
        </Box>
      ) : (
        <Box sx={styles.connectedWalletWrapper}>
          {/* If wallet connected, render address etc. Else show connect wallet button */}
          {!!wallet ? (
            <Button component={NavLink} to="/metazoa" sx={styles.walletButton}>
              <Box sx={styles.connectedWallet}>
                {
                  !!profileAvatar
                    ? <Box component="img" sx={styles.profilePic} src={profileAvatar} alt="User Profile Pic" width="50px" height="100%" />
                    : <Box component="img" src={DefaultUserProfile} sx={styles.profilePic} width="50px" height="100%" />
                }
                <Box sx={styles.connectedWalletContent}>
                  <Typography variant="h3" color="primary" sx={styles.username}>
                    {!!username
                      ? username.length > 15
                        ? truncate(username)
                        : username
                      : walletAddr}
                  </Typography>
                  <Typography variant='body1' color="success.main" sx={styles.userLevel}>Level&nbsp;{profile?.level?.level ?? 1}</Typography>
                </Box>
              </Box>
            </Button>
          ) : (
            <Box sx={styles.buttonContainer}>
              <Button size="small" variant="outlined" color="secondary"
                sx={styles.headerCTA}
                onClick={handleConnectWallet}
                disableFocusRipple
              >
                <Typography variant="button" sx={styles.gradientText}>connect wallet</Typography>
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  buttonContainer: {
    paddingY: '10px',
  },
  headerCTA: {
    borderRadius: "30px",
    textTransform: "uppercase",
    minWidth: '210px',
    minHeight: '40px'
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },
  // Connected Wallet
  walletButton: {
    textTransform: 'none',
  },
  connectedWalletWrapper: {
    paddingY: '2px',
    width: '210px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  connectedWallet: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  connectedWalletContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  profilePic: {
    borderRadius: '25px',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  username: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
  },
  userLevel: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
}

export default ConnectWalletButtonDrawer;