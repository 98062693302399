import { Container, SxProps } from "@mui/material";
import { QuestLayout } from "layout";
import { Outlet } from "react-router-dom";
import ScrollToTop from "utils/ScrollToTop";
import { AppTheme, SimpleMap } from "utils/types";

const QuestPage: React.FC = () => {
  return (
    <QuestLayout>
      <Container maxWidth="xl" sx={styles.container}>
        <ScrollToTop />
        <Outlet />
      </Container>
    </QuestLayout>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    position: "relative",
    padding: "0px 0px 100px",
    minHeight: "1200px",
    width: "100%",
    // overflowX: 'auto',
  },
};

export default QuestPage;