import { Theme } from "@mui/material";

const MuiAccordion = (theme: Theme) => ({
  styleOverrides: {
    root: {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
});

export default MuiAccordion;
