import React from "react";
import { Box, BoxProps, FormControl, FormHelperText, InputLabel, OutlinedInput, styled, SxProps, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import GuildBannerPreview from "assets/GuildBannerPreview.svg";
import { AppTheme, SimpleMap } from "utils/types";
import { combineStyles } from "utils/themeUtilities";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import ContainedButton from "components/ContainedButton";
import { SIGIL_ICONS, SIGIL_COLOURS, BANNERS, BANNER_COLOURS, SIGILS, BANNER_ICONS } from "components/Guild/components/GuildConstants";
import { Errors, GuildFormInputs } from "../../CreateGuild";
import { CheckCircleIcon, WarningRed } from "assets";

export interface Props extends BoxProps {
  formInputs: GuildFormInputs;
  setFormInputs: React.Dispatch<React.SetStateAction<GuildFormInputs>>;
  handleNext: () => void;
  errors: Errors;
  setErrors: React.Dispatch<React.SetStateAction<Errors>>;
}

const PageOne: React.FC<Props> = (props: Props) => {
  const { formInputs, setFormInputs, handleNext, errors, setErrors } = props;

  const validateInput = (field: string, input: string) => {
    switch (field) {
      // 16 characters, alphanumeric and hyphen
      case "guildName":
        if (input.length && input.length < 2) return "Enter a guild name of at least 2 characters.";
        if (input.length > 16 || input.length === 0) return "Enter a guild name of up to 16 characters.";
        if (input.length && !/^[A-Za-z0-9- ]{1,16}$/.test(input)) return "Enter a guild name only containing alphanumeric characters or hyphen.";
        return ""
      // 8 characters, lower alphanumeric and hyphen
      case "guildSlug":
        if (input.length && input.length < 2) return "Enter a guild handle of at least 2 characters.";
        if (input.length > 8 || input.length === 0) return "Enter a guild handle of up to 8 characters.";
        if (input.length && !/^[a-z0-9-]{3,8}$/.test(input)) return "Must only contain alphanumeric or hyphen characters.";
        return ""
      // max 100 words
      case "description":
        if (input.length && input.length < 2) return "Enter a guild description of at least 2 characters.";
        if (input.length > 140 || input.length === 0) return "Enter a guild description of up to 140 characters.";
        return ""
      default: return "";
    }
  }

  const updateInputs = (field: string) => {
    return (newInput: string | number) => {
      setFormInputs({ ...formInputs, [field]: newInput });

      if (typeof newInput === "string") {
        const errorText = validateInput(field, newInput);
        setErrors((prev) => ({ ...prev, [field]: errorText }));
      }
    }
  }

  const handleClick = () => { 
    let isEmpty = false;
    const toCheck = ['guildName','guildSlug','description'];
    toCheck.forEach(value => {
      if (formInputs[value].trim() === "") {
        updateInputs(value)("");
        isEmpty = true;
      }
    });
    
    if(isEmpty) window.scrollTo({ top: 0, behavior: 'smooth' });
    else handleNext();
  }

  return (
    <Box sx={styles.contentBox}>
      {/* guild information */}
      <Typography variant="body2" color="primary" sx={styles.sectionHeaderText}>Guild Information</Typography>
      <Box sx={styles.guildInformationBox}>
        {/* guild name */}
        <Box display="flex" alignItems="center" width="100%">
          <InputLabel htmlFor="name" sx={errors["guildName"] ? styles.removeErrorMargin : undefined}>
            <Typography variant="body1" color="primary" sx={styles.inputLabel}>Guild Name</Typography>
          </InputLabel>
          <FormControl fullWidth>
            <OutlinedInput
              sx={combineStyles(styles.input, errors["guildName"] ? styles.errorInput : undefined)}
              id="name"
              placeholder="Zolarians"
              value={formInputs.guildName}
              onChange={(e) => updateInputs("guildName")(e.target.value)}
            />
            {errors["guildName"] &&
              <Box sx={styles.errorTextContainer}>
                <WarningRed width={15} />
                <FormHelperText sx={styles.errorText}>{errors["guildName"]}</FormHelperText>
              </Box>
            }
          </FormControl>
        </Box>

        {/* guild slug */}
        <Box display="flex" alignItems="center" width="100%">
          <InputLabel htmlFor="name" sx={errors["guildSlug"] ? styles.removeErrorMargin : undefined}>
            <Typography variant="body1" color="primary" sx={styles.inputLabel}>Guild Handle</Typography>
          </InputLabel>
          <FormControl fullWidth>
            <OutlinedInput
              sx={combineStyles(styles.input, errors["guildSlug"] ? styles.errorInput : undefined)}
              id="name"
              placeholder="Up to 8 characters"
              value={formInputs.guildSlug}
              onChange={(e) => updateInputs("guildSlug")(e.target.value)}
            />
          {errors["guildSlug"] &&
              <Box sx={styles.errorTextContainer}>
                <WarningRed width={15} />
                <FormHelperText sx={styles.errorText}>{errors["guildSlug"]}</FormHelperText>
              </Box>
          }
          </FormControl>
        </Box>

        {/* description */}
        <Box display="flex" alignItems="center" width="100%">
          <InputLabel htmlFor="description" sx={errors["description"] ? styles.removeErrorMargin : undefined}>
            <Typography variant="body1" color="primary" sx={styles.inputLabel}>Description</Typography>
          </InputLabel>
          <FormControl fullWidth>
            <OutlinedInput
              sx={combineStyles(styles.multilineInput, errors["description"] ? styles.errorInput : undefined)}
              id="description"
              placeholder="Up to 140 characters"
              value={formInputs.description}
              onChange={(e) => updateInputs("description")(e.target.value)}
              multiline={true}
            />
            {errors["description"] &&
              <Box sx={styles.errorTextContainer}>
                <WarningRed width={15} />
                <FormHelperText sx={styles.errorText}>{errors["description"]}</FormHelperText>
              </Box>
            }
          </FormControl>
        </Box>

        {/* sigil */}
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
          <Typography variant="body1" color="primary" fontWeight={600} sx={styles.inputLabel} mr="-40px">
            Guild Sigil
            <TooltipZolar link="https://docs.zolar.io/stations/guilds#guild-banner-and-sigils" sx={styles.tooltip}>
              Guild Sigil represents the playstyle of your guild.
              It appears on your customizable Guild Banner and is visible to all of Zolar.
            </TooltipZolar>
          </Typography>

          {/* selections */}
          <Box display="flex" flexDirection="column" sx={styles.selectionBox}>
            <SigilButtonGroup
              value={formInputs.guildSigilDesign}
              onChange={(_, newSelection) => { newSelection !== null && updateInputs("guildSigilDesign")(newSelection) }}
              exclusive
            >
              {SIGIL_ICONS.map((sigil, index) => {
                return (
                  <SigilWrapper key={index} value={index + 1} sx={styles.sigilWrapper} disableRipple>
                    <Box sx={{ position: 'relative' }}>
                      <Box component="img" sx={styles.sigilImage} src={sigil.image} alt={sigil.name} id="sigilImage" />
                      <CheckCircleIcon id="sigilCheckedIcon" />
                    </Box>
                    <Box display="flex" flexDirection="column" mt="12px">
                      <Typography variant="body1" color="primary" sx={styles.sigilName}>{sigil.name}</Typography>
                      <Typography variant="body1" color="primary" sx={styles.sigilValues}>{sigil.values}</Typography>
                    </Box>
                  </SigilWrapper>
                )
              })}
            </SigilButtonGroup>

            <ColourButtonGroup
              value={formInputs.guildSigilColour}
              onChange={(_, newSelection) => { newSelection !== null && updateInputs("guildSigilColour")(newSelection) }}
              exclusive
            >
              {SIGIL_COLOURS.map((colourObj, index) => {
                return (
                  <ColourWrapper key={index} value={colourObj.colour} sx={styles.colourWrapper} disableRipple>
                    <Box sx={styles.colourBox} id="colour" style={{ background: colourObj.background }} />
                  </ColourWrapper>
                )
              })}
            </ColourButtonGroup>
          </Box>
        </Box>
      </Box>

      {/* guild banner preview */}
      <Typography variant="body2" color="primary" sx={styles.sectionHeaderText}>Guild Banner Preview</Typography>

      <Box sx={styles.previewBox}>
        <Box position="relative">
          <Box
            component="img"
            src={
              BANNERS[formInputs.guildBannerDesign - 1][
              BANNER_COLOURS.findIndex(
                (curr) => curr.colour === formInputs.guildBannerColour
              )
              ]
            }
            height="250px"
            width="250px"
          />
          <Box
            component="img"
            src={
              SIGILS[formInputs.guildSigilDesign - 1][
              SIGIL_COLOURS.findIndex(
                (curr) => curr.colour === formInputs.guildSigilColour
              )
              ]
            }
            height="90px"
            width="90px"
            sx={styles.sigil}
          />
        </Box>
      </Box>

      {/* guild banner */}
      <Box sx={styles.guildBannerBox}>
        <Typography variant="body1" color="primary" sx={styles.subheaderText}>Guild Banner</Typography>

        <BannerButtonGroup
          value={formInputs.guildBannerDesign}
          onChange={(_, newSelection) => { newSelection !== null && updateInputs("guildBannerDesign")(newSelection) }}
          exclusive
        >
          {BANNER_ICONS.map((banner, index) => {
            return (
              <BannerWrapper key={index} value={index + 1} disableRipple>
                <Box sx={{ position: 'relative' }}>
                  <Box component="img" src={banner.image} alt={banner.name} id="bannerImage" />
                  <CheckCircleIcon id="sigilCheckedIcon" />
                </Box>
              </BannerWrapper>
            )
          })}
        </BannerButtonGroup>

        <ColourButtonGroup
          value={formInputs.guildBannerColour}
          onChange={(_, newSelection) => { newSelection !== null && updateInputs("guildBannerColour")(newSelection) }}
          exclusive
        >
          {BANNER_COLOURS.map((colourObj, index) => {
            return (
              <ColourWrapper key={index} value={colourObj.colour} sx={styles.colourWrapper} disableRipple>
                <Box sx={styles.colourBox} id="colour" style={{ background: colourObj.background }} />
              </ColourWrapper>
            )
          })}
        </ColourButtonGroup>
      </Box>

      {/* next button */}
      <Box display="flex" mt="60px">
      <ContainedButton sx={styles.nextButton} onClick={handleClick}>
          NEXT
        </ContainedButton>
      </Box>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  contentBox: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    "@media (min-width:600px)": {
      minWidth: "500px",
    },
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  sectionHeaderText: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 0.8)",
    marginTop: "60px",
  },
  guildInformationBox: {
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    borderRadius: "16px",
    marginTop: "12px",
    padding: "24px",
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  input: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      padding: "12px 24px",
    },
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },
  },
  multilineInput: {
    minHeight: "48px",
    padding: "6px 24px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    fontSize: "14px",
    lineHeight: "28px",
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: 600,
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },
  },
  inputLabel: {
    fontSize: "14px",
    lineHeight: "28px",
    width: "250px",
  },
  tooltip: {
    padding: "20px",
    width: "420px",
    "& .MuiTypography-root": {
      fontSize: "18px",
      lineHeight: "28px",
    },
    "@media (max-width:600px)": {
      padding: "20px",
      width: "250px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  guildBannerBox: {
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    borderRadius: "16px",
    marginTop: "60px",
    padding: "24px",
  },
  subheaderText: {
    fontSize: "14px",
    lineHeight: "28px",
    fontWeight: 600,
  },
  nextButton: {
    minWidth: "280px",
    height: 64,
    "@media (max-width:600px)": {
      minWidth: "",
      flex: 1,
    },
  },
  previewBox: {
    backgroundImage: `url(${GuildBannerPreview})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    minHeight: "340px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sigilName: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#AEF1EE",
    whiteSpace: "nowrap",
    textAlign: "left",
    fontWeight: 600,
  },
  sigilValues: {
    fontSize: "12px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    textAlign: "left",
  },
  sigilImage: {
    height: "80px",
    width: "80px",
  },
  // TODO: improve responsiveness of sigil stuff
  selectionBox: {
    overflowX: "scroll",
    "::-webkit-scrollbar": {
      width: '6px',
      height: "6px"
    },
    "::-webkit-scrollbar-track": {
      marginTop: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '16px',
    },
    "@media (max-width:400px)": {
      maxWidth: "150px",
    }
  },
  colourBox: {
    height: "42px",
    width: "42px",
    borderRadius: "50%",
    boxShadow: '0px 0px 16px rgba(239, 57, 255, 0.4)',
  },
  sigil: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -70%)",
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },
  removeErrorMargin: {
    marginTop: "-22px",
  },
  errorInput: {
    border: "1px solid #F65E5E",
    "&.Mui-focused": {
      borderColor: "#F65E5E",
    },
  },
}

const SigilButtonGroup = styled(ToggleButtonGroup)({
  display: "grid",
  width: "100%",
  gridTemplateColumns: "repeat(4, 1fr)",
  columnGap: "20px",
  rowGap: "20px",
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "16px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "16px",
  },
  "@media (max-width:1100px)": {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const SigilWrapper = styled(ToggleButton)({
  display: "flex",
  borderRadius: "16px",
  flexDirection: "column",
  alignItems: "flex-start",
  background: "transparent",
  textTransform: "none",
  paddingLeft: "2px",
  paddingTop: "2px",
  paddingBottom: 0,
  paddingRight: 0,
  "&:hover": {
    background: "transparent",
  },
  "#sigilCheckedIcon": {
    position: 'absolute',
    bottom: 0,
    right: '-10px',
    visibility: 'hidden',
  },
  "&.Mui-selected": {
    background: "transparent",
    "& #sigilImage": {
      borderRadius: "16px",
      outline: "2px solid #27ED82",
    },
    "#sigilCheckedIcon": {
      visibility: 'visible',
    },
    "&:hover": {
      background: "transparent",
    },
  },
});

const ColourButtonGroup = styled(ToggleButtonGroup)({
  display: "flex",
  flexWrap: "wrap",
  columnGap: "30px",
  rowGap: "20px",
  marginTop: "25px",
  marginBottom: "10px",
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "50%",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "50%",
  },
});

const ColourWrapper = styled(ToggleButton)({
  display: "flex",
  justifyContent: "center",
  height: "54px",
  width: "54px",
  borderRadius: "50%",
  background: "transparent",
  paddingLeft: 0,
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 0,
  "&:hover": {
    background: "transparent",
  },
  "&.Mui-selected": {
    background: "transparent",
    borderRadius: "50%",
    border: "2px solid #27ED82",
    borderLeft: "2px solid #27ED82!important",
    "&:hover": {
      background: "transparent",
    },
  },
});

const BannerButtonGroup = styled(ToggleButtonGroup)({
  display: "flex",
  flexWrap: "wrap",
  columnGap: "40px",
  rowGap: "20px",
  marginTop: "25px",
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "16px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "16px",
  },
});

const BannerWrapper = styled(ToggleButton)({
  borderRadius: "16px",
  background: "transparent",
  paddingLeft: "2px",
  paddingTop: "2px",
  paddingBottom: 0,
  paddingRight: 0,
  justifyContent: "flex-start",
  "&:hover": {
    background: "transparent",
  },
  "#sigilCheckedIcon": {
    position: 'absolute',
    bottom: 0,
    right: '-10px',
    visibility: 'hidden',
  },
  "&.Mui-selected": {
    background: "transparent",
    "& #bannerImage": {
      borderRadius: "16px",
      outline: "2px solid #27ED82",
    },
    "#sigilCheckedIcon": {
      visibility: 'visible',
    },
    "& #crestImage": {
      borderRadius: "16px",
      outline: "2px solid #27ED82",
    },
    "&:hover": {
      background: "transparent",
    },
  },
});

export default PageOne;