import { Box, BoxProps, CircularProgress, Divider, SxProps, Typography } from "@mui/material";
import PanelFrame from "assets/PanelFrame.svg";
import ContainedButton from "components/ContainedButton";
import StripeHeader from "components/Game/components/StripeHeader";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { TEXT_GRADIENT } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { QuestConstants } from "components/Quest";
import { LocationMetadata, LOCATIONS } from "components/Quest/QuestConstants";
import TooltipZolar from "components/Tooltip";
import { logger } from "core/utilities";
import { FC, ReactNode, useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions } from "store";
import { NftMetadata } from "store/types";
import { TBMConnector } from "tbm";
import { theme } from "theme";
import { useAsyncTask, useBlockTime, useRedux, useToaster } from "utils";
import { MissionGroundLocation, QUEST_EPOCH, RESOURCE_PER_EPOCH } from "utils/constants";
import { bnOrZero, formatIntegerNumber, toHumanNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { ReturnToBaseDialog, SendResourceToBase, SendToQuestDialog } from "../../QuestDialogs";
import TokenView from "./TokenView";

interface ISubsectionProps extends BoxProps {
  title: string;
  currentLocation: LocationMetadata;
  headerTooltip?: ReactNode;
}

const Subsection: FC<ISubsectionProps> = ({
  title,
  currentLocation,
  headerTooltip,
  children,
  ...rest
}: ISubsectionProps) => {
  return (
    <Box
      component="section"
      sx={combineStyles(styles.tokenViewContainer, rest.sx)}
    >
      <StripeHeader sx={combineStyles(styles.viewHeader, {
        background: `repeating-linear-gradient(90deg, ${currentLocation.assets.color}65, ${currentLocation.assets.color}30 36px, transparent 36px, transparent 44px)`,
      })}>
        {title}

        {headerTooltip}
      </StripeHeader>
      {children}
    </Box>
  );
}

interface LocationWithTokens extends LocationMetadata {
  tokens: {
    unstaked: NftMetadata[],
    staked: NftMetadata[],
  }
}

interface MissionGroundViewProps extends BoxProps {
  locationKey: string;
}

const MissionGroundView: FC<MissionGroundViewProps> = ({
  locationKey,
  ...rest
}: MissionGroundViewProps) => {
  const [, currentBlock] = useBlockTime();
  const dispatch = useDispatch();
  const network = useNetwork();
  const navigate = useNavigate();
  const toaster = useToaster();

  const wallet = useRedux((state) => state.wallet.wallet)
  const unstakedTokens = useRedux((state) => state.token.metazoaTokens);
  const stakedTokensBerry = useRedux((state) => state.token.stakedMetazoaBerry);
  const stakedTokensGeode = useRedux((state) => state.token.stakedMetazoaGeode);
  const stakedTokensScrap = useRedux((state) => state.token.stakedMetazoaScrap);
  const [showSendDialog, setShowSendDialog] = useState<boolean>(false);
  const [showSendResourceDialog, setShowSendResourceDialog] = useState<boolean>(false);
  const [showReturnDialog, setShowReturnDialog] = useState<boolean>(false);
  const [runFetchLastHarvestBlock, loadingFetchLastHarvestBlock] = useAsyncTask("fetchLastHarvestBlock", (error) => {
    toaster(error?.message ?? "Error Fetching Last Harvest Block");
  });

  // STATES / HOOKS -----------------

  const currentLocation: LocationWithTokens = useMemo(() => {
    const locationMetadata = LOCATIONS[locationKey];
    if (!locationMetadata) return;

    dispatch(actions.Layout.updateMapBackground(locationMetadata.assets.background));

    const locationInfo = {
      ...locationMetadata,
      tokens: {
        unstaked: [],
        staked: [],
      }
    };

    if (!wallet) return locationInfo;

    let stakedTokens: SimpleMap<NftMetadata> = {}
    if (unstakedTokens || stakedTokensBerry || stakedTokensGeode || stakedTokensScrap) {
      switch (locationKey) {
        case MissionGroundLocation.ElderWoodlands: stakedTokens = stakedTokensBerry;
          break;
        case MissionGroundLocation.ZolarAsteroidBelt: stakedTokens = stakedTokensGeode;
          break;
        case MissionGroundLocation.MoonBattleground: stakedTokens = stakedTokensScrap;
          break;
      }
    }

    locationInfo.tokens = {
      unstaked: Object.values(unstakedTokens),
      staked: Object.values(stakedTokens),
    };

    logger("debug-map", "missionGroundView", locationInfo)

    return locationInfo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationKey, unstakedTokens, stakedTokensBerry, stakedTokensGeode, stakedTokensScrap, wallet]);

  const stakedTokens: SimpleMap<NftMetadata> = useMemo(() => {
    let tokenArray: SimpleMap<NftMetadata> = {}
    switch (currentLocation.key) {
      case MissionGroundLocation.ElderWoodlands: tokenArray = stakedTokensBerry;
        break;
      case MissionGroundLocation.ZolarAsteroidBelt: tokenArray = stakedTokensGeode;
        break;
      case MissionGroundLocation.MoonBattleground: tokenArray = stakedTokensScrap;
        break;
    }
    return tokenArray;
  }, [stakedTokensBerry, stakedTokensGeode, stakedTokensScrap, currentLocation]);

  const getResourceGatheredDaily = useCallback((token: NftMetadata) => {
    if (!token.lastHarvested || !currentLocation || !token || !token.bonuses) return 0;
    const locationType = currentLocation.keyStat?.type
    if (!locationType) return 0;
    const bonusMultiplier = bnOrZero(token.contractBonus?.resourceGatheringBonus).dividedBy(100).plus(1).toNumber();
    const resourceGathered = RESOURCE_PER_EPOCH[locationType] * bonusMultiplier;
    return resourceGathered;
  }, [currentLocation]);

  const getResourceGathered = useCallback((token: NftMetadata) => {
    if (!token.lastHarvested || !currentLocation || !token || !token.bonuses) return 0;
    const locationType = currentLocation.keyStat?.type
    if (!locationType) return 0;
    const blockDifference = currentBlock - token.lastHarvested;
    const numberOfEpochs = Math.floor(blockDifference / QUEST_EPOCH[network]);
    const bonusMultiplier = bnOrZero(token.contractBonus?.resourceGatheringBonus).dividedBy(100).plus(1).toNumber();
    let resourceGathered = numberOfEpochs * RESOURCE_PER_EPOCH[locationType] * bonusMultiplier;
    if (resourceGathered < 0) resourceGathered = 0;
    return resourceGathered;
  }, [currentBlock, currentLocation, network])


  const resourceGathered = useMemo(() => {
    const gatheredInfo = {
      total: 0,
      daily: 0,
    }
    if (!currentLocation?.keyStat || !wallet || !stakedTokens) return gatheredInfo;
    // const locationStat = currentLocation.keyStat.type
    const stakedTokensArray: NftMetadata[] = Object.values(stakedTokens);

    // for (const token of stakedTokensArray) {
    //   // if (!token || !token.bonuses) continue;
    //   const blockDifference = currentBlock - token.lastHarvested!;
    //   const numberOfEpochs = blockDifference / QUEST_EPOCH[network];
    //   const bonusMultiplier = bnOrZero(token?.contractBonus?.resourceGatheringBonus).dividedBy(100).plus(1).toNumber();
    //   const gatheredResource = Math.floor(numberOfEpochs) * RESOURCE_PER_EPOCH[locationStat] * bonusMultiplier;
    //   // gatheredInfo.total += gatheredResource;
    //   /// Daily Gather
    //   // gatheredInfo.daily += bnOrZero(RESOURCE_PER_EPOCH[locationStat]).times(bnOrZero(token?.contractBonus?.resourceGatheringBonus).plus(100)).dividedBy(100).toNumber();
    // }

    /// Summation of Gathered Resource
    gatheredInfo.total = stakedTokensArray.reduce((total, token) => total + getResourceGathered(token), 0) ?? 0;

    /// Summation of Gathered Resource Daily
    gatheredInfo.daily = stakedTokensArray.reduce((total, token) => total + getResourceGatheredDaily(token), 0) ?? 0;


    logger("debug-view", "MissionGroundView", {
      currentLocation,
      gatheredInfo,
    })
    return gatheredInfo;
  }, [currentLocation, wallet, stakedTokens, getResourceGathered, getResourceGatheredDaily])

  const hasNoUnstaked: boolean = currentLocation.tokens.unstaked.length === 0;
  const hasNoStaked: boolean = currentLocation.tokens.staked.length === 0;

  useEffect(() => {
    if (!wallet || hasNoStaked) return;

    runFetchLastHarvestBlock(async () => {
      const stakedTokenIds = Object.keys(stakedTokens);
      logger("debug-component", "MissionGroundView", {
        stakedTokenIds
      })
      if (!stakedTokenIds.length) return;
      const lastHarvestBlocks = await TBMConnector.getLastHarvestedBlock(stakedTokenIds, currentLocation.name);

      for (const [id, block] of Object.entries(lastHarvestBlocks)) {
        if (stakedTokens[id]) {
          stakedTokens[id] = {
            ...stakedTokens[id],
            lastHarvested: block
          }
        }
      }
    });

    // eslint-disable-next-line
  }, [wallet, stakedTokens]);

  // FUNCTIONS ----------------------

  const handleShowSendDialog = () => {
    setShowSendDialog(true);
  }

  const handleShowReturnDialog = () => {
    setShowReturnDialog(true);
  }

  // EVENT HANDLERS -----------------

  // --------------------------------
  return (
    <Box
      component="main"
      sx={combineStyles({
        ...(!!currentLocation.assets.frame) && {
          ...styles.frameBorder,
          borderImage: `url(${currentLocation.assets.frame})`,
        },
      }, rest.sx)}>

      <Box
        component="main"
        sx={styles.dividedSide}
      >
        <Subsection
          title={currentLocation.name}
          currentLocation={currentLocation}
          headerTooltip={
            <TooltipZolar link={`https://docs.zolar.io/metazoa/professions-new#${currentLocation?.keyStat?.profession}`} sx={styles.tooltip}>
              Sent Metazoas can only be sent to other quests after they’ve returned to Base.
            </TooltipZolar>
          }
        >
          {hasNoStaked
            ? (
              <Box sx={styles.emptyState}>
                <Typography variant="body1" color="primary" mb="10px" textAlign={'center'}>You don’t have any Metazoas in {currentLocation.name}.</Typography>
              </Box>
            )
            : (<TokenView
              tokens={currentLocation.tokens.staked}
              modalProps={{
                header: currentLocation.name,
              }}
            />)
          }

          <ContainedButton
            fullWidth
            disabled={hasNoStaked}
            onClick={handleShowReturnDialog}
            sx={combineStyles(styles.tokenViewButton, {
              background: currentLocation.assets.gradient,
            })}
          >
            {hasNoStaked ? 'Send Metazoas below' : 'Return to Base'}
          </ContainedButton>
        </Subsection>

        <Subsection
          title="In Base"
          currentLocation={currentLocation}
        >
          {hasNoUnstaked
            ? (
              <Box sx={styles.emptyState}>
                <Typography variant="body1" color="primary" mb="10px" textAlign={'center'}>You don’t have any Metazoas in Base.<br />Buy more now!</Typography>
              </Box>
            )
            : (<TokenView
              tokens={currentLocation.tokens.unstaked}
              modalProps={{
                header: "In Base",
              }}
            />)
          }

          <ContainedButton
            fullWidth
            disabled={hasNoUnstaked}
            onClick={handleShowSendDialog}
            sx={combineStyles(styles.tokenViewButton, {
              background: currentLocation.assets.gradient,
            })}
          >
            {hasNoUnstaked ? 'Buy Metazoa' : `Send to ${currentLocation.name}`}
          </ContainedButton>
        </Subsection>
      </Box>

      <Divider sx={combineStyles(styles.divider, {
        background: currentLocation.assets.gradient,
      })} />

      <Box
        component="main"
        sx={combineStyles(styles.dividedSide, {
          placeContent: 'space-between',
          placeItems: 'center',
          gap: 4,
        })}
      >
        {/* RESOURCE GATHERED */}
        <Subsection
          title="Resources Gathered"
          currentLocation={currentLocation}
          sx={{
            minHeight: 'unset',
            placeContent: 'start',
            width: '100%',
          }}
        >
          <Box sx={{
            display: 'inline-flex',
            flexDirection: 'row',
            placeContent: 'space-between',
            placeItems: 'center',
            gap: '0.5em',
            marginBottom: '20px',
          }}>
            <Typography variant="body1" color="primary" >
              Total {currentLocation.resource?.name} Gathered
            </Typography>
            <Divider sx={{
              background: "#FFF",
              flex: '0.8 0 auto',
            }} />
            <Typography variant="body1" color="primary" fontSize="14px" sx={styles.inLineIcon}>
              Gathering {toHumanNumber(bnOrZero(resourceGathered.daily).times(2300 / QUEST_EPOCH[network]), 1)}&nbsp;
              <Box
                component="img"
                src={currentLocation.resource?.iconSrc}
                sx={styles.resourceIconMini}
              />
              &nbsp;per day
            </Typography>
          </Box>

          <Box sx={styles.rowBetween}>
            <Box sx={styles.resourceGatheredInfo}>
              <Box
                component="img"
                src={currentLocation.resource?.iconSrc}
                height="56px"
                width="56px"
                onError={QuestConstants.DEFAULT_SRC}
              />
              <Typography variant="h2" sx={GUILD_LIGHTGRAY_GRADIENT}>
                {loadingFetchLastHarvestBlock ? <CircularProgress size={18} /> : formatIntegerNumber(resourceGathered.total)}
              </Typography>
            </Box>

            <ContainedButton sx={combineStyles(styles.baseViewButton, {
              background: currentLocation.assets.gradient,
            })}
              onClick={() => setShowSendResourceDialog(true)}
              disabled={hasNoStaked}
            >
              Send to Base
            </ContainedButton>
          </Box>
        </Subsection>

        {/* RESOURCE FRAME */}
        <Box>
          <Box
            component="img"
            src={currentLocation.resource?.resourceFrameSrc}
            className="resourceFrameImg"
          />
        </Box>

        {/* REFINE RESOURCES */}
        <Subsection
          title="Refine Resources"
          currentLocation={currentLocation}
          sx={{
            minHeight: 'unset',
            placeContent: 'start',
            width: '100%',
          }}
        >
          <Box sx={styles.rowBetween}>
            <Typography variant="body1" color="primary" sx={{
              width: '50%',
              flex: '1 0 auto',
            }}>
              {currentLocation.refinementTagline}
            </Typography>

            <ContainedButton sx={combineStyles(
              styles.baseViewButton, {
              background: currentLocation.assets.gradient,
            })}
              onClick={() => navigate(`/metazoa`)}
            >
              View in Base
            </ContainedButton>
          </Box>
        </Subsection>
      </Box>
      <SendToQuestDialog
        location={currentLocation}
        open={showSendDialog}
        onClose={() => setShowSendDialog(false)}
      />
      {!!currentLocation.keyStat?.type && (
        <ReturnToBaseDialog
          location={currentLocation}
          open={showReturnDialog}
          onClose={() => setShowReturnDialog(false)}
          keyStat={currentLocation.keyStat.type}
        />
      )}
      <SendResourceToBase
        location={currentLocation}
        open={showSendResourceDialog}
        onClose={() => setShowSendResourceDialog(false)}
      />
    </Box >
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  frameBorder: {
    border: '12px double transparent',
    borderImage: `url(${PanelFrame})`,
    borderImageSlice: '20',
    borderImageWidth: '50px',
    borderImageOutset: '0px 0px 0px 0px',
    borderImageRepeat: 'stretch stretch',
    padding: '20px 30px',

    position: 'relative',
    height: '100%',
    background: 'linear-gradient(270deg, #010025 20%, #000000 100%)',
    // background: '#000000',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: '1000%',
    // backgroundPosition: 'center',
    backgroundClip: 'padding-box',

    display: 'flex',
    flexDirection: 'row',
    gap: '2em',
    flexWrap: 'wrap',

    "> *": {
      // flexBasis: '50%',
      width: '45%',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
      flex: '1 0 auto',
    },
  },

  divider: {
    marginY: '20px',
    maxWidth: '1px',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    }
  },

  dividedSide: {
    display: 'flex',
    flexDirection: 'column',
  },

  /// SECTION: Token View (Staked / Unstaked)
  tokenViewContainer: {
    minHeight: '350px',
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'space-between',
    placeItems: 'center',
    "> *": {
      width: '100%',
    },
  },
  tooltip: {
    padding: "20px",
    width: '420px',
  },
  viewHeader: {
    textAlign: 'center',
    // paddingY: '5px',
    marginY: '25px',
    transform: 'skewX(-25deg) !important',
    "h2": {
      fontSize: '24px!important',
      lineHeight: 'normal!important',
      transform: 'skewX(25deg)',
    },

    ".MuiButtonBase-root": {
      padding: 0,
      marginX: 2,
    }
  },
  tokenViewButton: {
    fontSize: '18px',
    whiteSpace: 'nowrap',
    padding: '8px 20px',
    marginTop: '20px',
  },
  baseViewButton: {
    fontSize: '18px',
    whiteSpace: 'nowrap',
    padding: '8px 20px',
    flex: '1 0 auto',
    marginLeft: '0px !important',
  },

  /// SECTION: Resource Gathered
  resourceGatheredInfo: {
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    flexWrap: 'wrap',
    gap: '1.5em',

    width: '50%',
    flex: '1 0 auto',
  },

  inLineIcon: {
    display: 'flex',
    alignItems: 'center'
  },

  resourceIconMini: {
    height: 24,
    verticalAlign: 'sub',
  },

  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  gradientText: {
    ...TEXT_GRADIENT,
    fontWeight: 700,
  },

  /// UTILITIES
  rowBetween: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'center',
    // flexWrap: 'wrap',
    gap: '1em',
  },


};

export default MissionGroundView;
