import { Box, SxProps } from "@mui/material";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";

const InnerProgress: React.FC = () => {
  const tokenState = useRedux((state) => state.token);
  const currentSupply = tokenState.totalSupply;
  const progressPercentage = (currentSupply / 2888) * 100;
  return (
    <Box width={`${progressPercentage}%`} height="34px" sx={styles.progress}></Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  progress: {
    position: 'absolute',
    background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
  }
}

export default InnerProgress;