import * as Blockchain from "./blockchain/actions";
import * as Layout from "./layout/actions";
import * as Profile from "./profile/actions";
import * as Token from "./token/actions";
import * as Wallet from "./wallet/actions";
import * as Game from "./game/actions";
import * as Guild from "./guild/actions";
import * as ZOMG from "./zomg/actions";
import * as Quest from "./quest/actions";

const actions = { Blockchain, Token, Wallet, Game, Layout, Profile, Guild, ZOMG, Quest };

export default actions;
