import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Box, Divider, IconButton, InputAdornment, Link, OutlinedInput, SxProps, Typography } from "@mui/material";
import { AddIcon, CompleteTag, LinesPhase3, LiveTag, LiveTagDated, RemoveIcon, ZilliqaLogo } from "assets";
import PhaseImage from "assets/icons/PhaseImage.png";
import PhaseImageGray from "assets/icons/PhaseImageGray.png";
import Upcoming from "assets/icons/Upcoming.png";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import ContainedButton from "components/ContainedButton";
import dayjs, { Dayjs } from "dayjs";
import { NavLink } from "react-router-dom";
import { strings } from "utils";
import { ContractsBech32, SaleTime } from "utils/constants";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";
import { AppTheme, SimpleMap } from "../../../utils/types";
import ProgressBar from "../components/ProgressBar";
import { SummonDialog } from "./components";

const PhaseThree: React.FC = () => {
  const network = useNetwork();
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const [summonQty, setSummonQty] = useState<number>(1);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const goldRushTotalSupply = tokenState.goldRushTotalSupply;
  const goldRushCurrentSupply = tokenState.goldRushCurrentSupply;
  const totalSupply = tokenState.totalSupply;
  const summonPrice = tokenState.goldRushSalePrice;
  const saleActive = tokenState.goldRushSaleActive;

  // sale start & end times
  const startTime = dayjs.unix(SaleTime[network].start / 1000);
  const endTime = dayjs.unix(SaleTime[network].end / 1000);

  const [currentTime, setCurrentTime] = useState<Dayjs>(dayjs());
  const [saleStartTime, setSaleStartTime] = useState<Dayjs>(startTime);
  const [saleEndTime, setSaleEndTime] = useState<Dayjs>(endTime);

  const metazoaContract = useMemo(() => ContractsBech32[network].NftV2, [network]);

  useEffect(() => {
    setSaleStartTime(dayjs(startTime));
    setSaleEndTime(dayjs(endTime));

    const timer = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [network]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const qty = event.target.valueAsNumber;
    setSummonQty(qty);
  };

  const onInputBlur = () => {
    if (isNaN(summonQty) || summonQty === 0) {
      setSummonQty(1);
    }
  }

  const handleAddQty = () => {
    setSummonQty(summonQty + 1);
  };

  const handleSubtractQty = () => {
    if (summonQty === 1) return;
    setSummonQty(summonQty - 1);
  };

  const handleShowDialog = () => {
    setShowDialog(true);
  }

  const isDisabled = useMemo(() => {
    return !saleActive || summonQty > 10 || summonPrice === 0 || saleStartTime.isAfter(currentTime);

    // eslint-disable-next-line
  }, [saleActive, summonQty, summonPrice, currentTime])

  // past end time or sold out
  const phaseEnded = useMemo(() => {
    return saleEndTime.isBefore(currentTime) || (goldRushTotalSupply > 0 && goldRushTotalSupply === goldRushCurrentSupply);

    // eslint-disable-next-line
  }, [currentTime, goldRushCurrentSupply, goldRushTotalSupply])

  function Countdown() {
    if (phaseEnded) return (
      <Fragment>
        <Link
          sx={styles.viewListing}
          target="_blank"
          href={strings.getArkyLink(metazoaContract)}
        >
          Check out our ARKY listing to get your Gen 0 Metazoa.
        </Link>
        <Typography variant="subtitle1" sx={styles.countdownText}>
          Phase 3 has ended
        </Typography>
      </Fragment>
    )

    let timeDiffSeconds = 0;

    if (saleStartTime.isAfter(currentTime)) {
      // sale not started
      timeDiffSeconds = saleStartTime.diff(currentTime, 'second');
    } else {
      // to sale end
      timeDiffSeconds = saleEndTime.diff(currentTime, 'second');
    }

    const days = Math.floor(timeDiffSeconds / 86400);
    const hours = Math.floor((timeDiffSeconds - (days * 86400)) / 3600);

    return (
      <Typography variant="subtitle1" sx={styles.countdownText}>
        {saleStartTime.isAfter(currentTime) ? "Starts" : "Ends"} in {
          days !== 0 && `${days.toLocaleString('en-US', { minimumIntegerDigits: 2 })}d :`} {
          hours.toLocaleString('en-US', { minimumIntegerDigits: 2 })}h : {
          (Math.floor(timeDiffSeconds / 60) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}m : {
          (timeDiffSeconds % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })
        }s
      </Typography>
    )
  }

  const totalPrice = summonPrice * summonQty;

  return (
    <Box>
      <Box maxWidth="xl" sx={styles.phaseContainer}>
        {/* Phase Nav */}
        <Box maxWidth="xl" sx={styles.phaseRow}>
          <Box sx={styles.phaseLinesBG}>
            <LinesPhase3 width="100%"/>
          </Box>
          <Box component={NavLink} to="/summon/elders" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 1</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>the elders</Typography>
            <CompleteTag/>
          </Box>
          <Box component={NavLink} to="/summon/transcendence" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 2</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>The Transcendence</Typography>
            <CompleteTag/>
          </Box>
          <Box component={NavLink} to="/summon/gold-rush" sx={styles.phaseBox}>
            <img src={PhaseImage} alt="phase" />
            <Typography variant="h3" color="primary" >Phase 3</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheader}>The gold rush</Typography>
            {phaseEnded
              ? <CompleteTag/>
              : currentTime.isAfter(saleStartTime)
                ? <LiveTagDated/>
                : <img src={Upcoming} alt="upcoming"/>
            }
          </Box>
          <Box component={NavLink} to="/summon/takers-of-the-moon" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 4</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>TAKERS OF THE MOON</Typography>
            <LiveTag />
          </Box>
        </Box>
        <ProgressBar phase={3}/>
      </Box>
      <Box sx={styles.phaseHeader}>
        <Typography variant="h3" color="primary">
          Phase 3 summoned: {goldRushCurrentSupply}/188 &nbsp;&nbsp; | &nbsp;&nbsp; Total Gen-0 summoned: {totalSupply}/1288 
        </Typography>
      </Box>
      <Box sx={styles.phaseHeader2}>
        <Typography variant="subtitle1" color="primary">
          Phase 3 summoned: {goldRushCurrentSupply}/188<br/>
          Total Gen-0 summoned: {totalSupply}/1288 
        </Typography>
      </Box>
      <Box sx={styles.phaseContentBox}>
        <Typography variant="h1" color="primary" sx={styles.phaseContentHeader}>
          The Gold Rush
        </Typography>
        <Typography variant="body1" color="primary" sx={styles.phaseContentBody}>
          <strong>Explorers, a golden opportunity has arisen!</strong>
          <br/>
          The portal to Zolar has diverged across all realms, revealing the concealed Zolarverse and its hidden $HUNY treasures to all!
          Once sealed off from the outside world, the stairway to Zolar now stretches far and wide, drawing treasure-seekers young and old to the heart of the Gold Rush.
          An abundance of $HUNY awaits for those who choose to embark on this epic battle.
        </Typography>
        <Link
          sx={styles.learnMore}
          target="_blank"
          href="https://docs.zolar.io/overview/creatures/collection-ii-metazoa/summoning#phase-2-or-the-transcendence"
        >
          Learn More
        </Link>
        
        {/* Countdown */}
        <Countdown/>

        {phaseEnded && (
          <ContainedButton
            sx={styles.summonButton}
            disabled={true}
          >
            Phase Ended
          </ContainedButton>
        )}
        
        {!phaseEnded && (
          <Fragment>
            <Divider sx={styles.divider}/>
            
            <Box display="flex" flexDirection="column">
              {/* Summon Container */}
              <Box sx={styles.summonContainer}>
                {/* Qty box */}
                <Box display="flex" flexDirection="column" alignItems="flex-start" sx={styles.qtyBox}>
                  <Typography variant="body1" color="primary">
                    How many Metazoa would you like to summon?
                  </Typography>
                  <OutlinedInput
                    sx={styles.qtyInput}
                    placeholder={"1"}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    value={summonQty.toString()}
                    type="number"
                    inputProps={{ min: "1", style: { textAlign: "center" } }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton onClick={handleSubtractQty}>
                          <RemoveIcon style={{ height: 22, width: 22 }}/>
                        </IconButton>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleAddQty}>
                          <AddIcon style={{ height: 22, width: 22 }}/>
                        </IconButton>
                      </InputAdornment>
                      }
                    />
                </Box>

                {/* Summon button box */}
                <Box sx={styles.summonButtonBox}>
                  {!wallet 
                    ? <ConnectWalletButtonCTA sx={styles.connectButton}/>
                    : <ContainedButton
                        sx={styles.summonButton}
                        onClick={handleShowDialog}
                        disabled={isDisabled}
                      >
                        Summon
                      </ContainedButton>
                  }
                </Box>
              </Box>

              {/* Error message */}
              {summonQty > 10 &&
                <Box display="flex" justifyContent="flex-start" mt="10px">
                  <Typography variant="body1" color="primary" sx={styles.errorMessage}>
                    You may only summon up to 10 Metazoa at a time.
                  </Typography>
                </Box>
              }
            
              <Box display="flex" justifyContent="flex-start" mt="10px">
                <Typography variant="body1" color="primary" sx={styles.cost}>
                  Total Cost:
                  {" "}
                  {summonPrice === 0
                    ? '-'
                    : <Fragment>
                      {totalPrice.toLocaleString()}{" "}
                      <ZilliqaLogo />
                    </Fragment>
                  }
                  </Typography>
              </Box>
            </Box>
          </Fragment>
        )}
      </Box>

      {/* Summon Dialog */}
      <SummonDialog
        open={showDialog}
        quantity={summonQty}
        price={summonPrice}
        onClose={() => setShowDialog(false)}
      />
    </Box>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  subheader: {
    fontSize: "0.75rem",
    letterSpacing: "0.2rem",
    lineHeight: "1.125rem",
    marginBottom: "5px",
  },
  subheaderInactive: {
    fontSize: "0.75rem",
    letterSpacing: "0.2rem",
    lineHeight: "1.125rem",
    marginBottom: "5px",
    opacity: "60%",
  },
  phaseContainer: {
    padding: '0px 90px',
    position: 'relative',
    margin: '0px 20px 20px',
    overflow: 'hidden',
    "@media (max-width:1200px)": {
      padding: '0px 4px',
      overflowX: 'scroll',
      marginX: '100px',
    },
    "@media (max-width:900px)": {
      marginX: '40px',
    },
  },
  phaseRow: {
    minWidth: '1000px',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: "60px",
  },
  phaseLinesBG: {
    pointerEvents: 'none',
    minWidth: '1000px',
    position: 'absolute',
    width: '90%',
    left: '90px',
    top: '33px',
    "@media (max-width:1200px)": {
      left: '20px',
    },
  },
  phaseBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
  },
  phaseNavLinkInactive: {
    opacity: "60%",
  },
  phaseHeader: {
    textAlign: 'center',
    marginX: "24px",
    marginBottom: "20px",
    "@media (max-width:900px)": {
      display: 'none'
    },
  },
  phaseHeader2: {
    textAlign: 'center',
    marginX: "24px",
    marginBottom: "20px",
    "@media (min-width:900px)": {
      display: "none",
    },
  },
  phaseContentBox: {
    position: "relative",
    borderRadius: "16px",
    margin: "0px 110px",
    padding: "40px 80px",
    background:
      "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    minHeight: "350px",
    "@media (max-width:900px)": {
      margin: "0px 24px",
      padding: "24px",
    },
  },
  countdownText: {
    color: "#FF8952",
    marginBottom: '30px',
  },
  phaseLink: {
    color: '#fff',
    "@media (max-width:600px)": {
      display: 'block',
    },
  },
  phaseContentHeader: {
    zIndex: 1,
    opacity: "100%",
    "@media (max-width:600px)": {
      fontSize: '2rem',
      textAlign: 'center',
    },
    marginBottom: '10px',
  },
  phaseContentBody: {
    zIndex: 1,
    textAlign: 'center',
    marginY: '30px',
  },
  learnMore: {
    marginBottom: '30px',
  },
  divider: {
    width: '100%',
    color: 'rgba(174, 241, 238, 0.1)',
    backgroundColor: 'rgba(174, 241, 238, 0.1)',
    height: '1.5px',
    marginBottom: '20px',
  },
  summonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    "@media (max-width:900px)": {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  qtyBox: {
    "@media (min-width:900px)": {
      marginRight: '30px',
    },
  },
  qtyInput: {
    height: 60,
    marginTop: '10px',
    border: '1px solid rgba(174, 241, 238, 0.1)',
    borderRadius: '16px',
    color: '#FEFEFE',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    "& input": {
      height: '30px',
      fontSize: '24px',
      fontWeight: 600,
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": 'none',
        margin: 0,
      },
      "&:[type=number]": {
        "-moz-appearance": 'textfield',
      },
    },
    "&.Mui-focused": {
      caretColor: '#FEFEFE',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 'none',
    },
  },
  summonButtonBox: {
    "@media (max-width:900px)": {
      display: 'initial',
      width: '100%',
    },
  },
  summonButton: {
    height: 60,
    minWidth: 300,
    "@media (max-width:900px)": {
      width: '100%',
      minWidth: '',
      marginTop: '10px',
    },
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
  cost: {
    "& svg": {
      verticalAlign: 'text-top',
      marginTop: '2px',
      height: 22,
      width: 22,
    },
    "@media (max-width:600px)": {
      "& svg": {
        marginTop: 0,
        height: '20px!important',
        width: '20px!important',
      }
    },
  },
  connectButton: {
    "& #wrapperBox": {
      "@media (max-width:900px)": {
        width: '100%',
        display: 'initial',
      },
    },
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .MuiButton-root": {
      minHeight: "",
      height: 60,
      minWidth: 300,
      "@media (max-width:900px)": {
        width: '100%',
        minWidth: '',
        marginTop: '10px',
      },
    }
  },
  errorMessage: {
    color: '#FF2F2F',
  },
  viewListing: {
    fontWeight: 600,
    marginBottom: '30px',
  }
};

export default PhaseThree;
