import { Box, CircularProgress, Divider, Link, SvgIcon, SxProps, Typography } from "@mui/material";
import { ExternalLink, Huny } from "assets";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { GUILD_GRAY_GRADIENT, MultisigSetting } from "components/Guild/components/GuildConstants";
import { logger, waitForTx } from "core/utilities";
import { Fragment, useEffect, useState } from "react";
import { Guild, GuildBankSetting } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { BIG_ZERO, Decimals, ITEM_ID } from "utils/constants";
import { bnOrZero, formatIntegerNumber, getExplorerLink } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import whoseSignature from "utils/whoseSignature";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  onReload: (guild?: Guild) => void;
  guild: Guild;
  bankSettings: GuildBankSetting;
  hasGuildBank: boolean;
  price: number;
}

const PostUpdateDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, guild, bankSettings, price, hasGuildBank, onReload, onBack } = props;

  const network = useNetwork();
  const toaster = useToaster();
  const wallet = useRedux((state) => state.wallet.wallet);
  const hunyTokens = useRedux((state) => state.token.HunyTokens);
  const bankHuny = guild.guildBank?.netWorth?.hunyTokenAmt ?? BIG_ZERO;

  // STATES / HOOKS -----------------

  const [approved, setApproved] = useState<boolean>(false);
  const [purchaseTxHash, setPurchaseTxHash] = useState<string>("");
  const [updateRequestComplete, setUpdateRequestComplete] = useState<boolean>(false);
  const [runQueryApproved, loadingQueryApproved] = useAsyncTask("queryApproved");
  const [runApprove, loadingApprove] = useAsyncTask("requestApproval", (error) => {
    onClose();
    toaster(error?.message ?? "Error Approving");
  });
  const [runBankAction, loadingBankAction] = useAsyncTask("update", (error) => {
    onClose();
    toaster(error?.message ?? `Error ${hasGuildBank ? 'Updating' : 'Unlocking'} Guild Bank`);
  });

  useEffect(() => {
    if (!wallet) return;
    const abortController = new AbortController();

    runQueryApproved(async () => {
      const approved = await TBMConnector.checkEmporiumApproved(wallet);
      setApproved(approved);
    })

    logger("debug-component/PostUpdateDialog", "bankSettings", {
      bankSettings,
    })

    return abortController.abort();
    // eslint-disable-next-line
  }, [wallet, network]);

  // FUNCTIONS ----------------------

  const guildWithBankSetting = (guild: Guild, setting: GuildBankSetting) => {
    const currentGuild: Guild = {
      ...guild,
      guildBank: {
        joiningFee: {
          initialAmt: bnOrZero((setting.joinFee.shiftedBy(Decimals.HUNY))),
          captainAllocation: setting.joinCaptainAllocation,
          officerAllocation: setting.joinOfficerAllocation,
        },
        weeklyTax: {
          initialAmt: (setting.taxFee.shiftedBy(Decimals.HUNY)),
          captainAllocation: setting.taxCaptainAllocation,
          officerAllocation: setting.taxCaptainAllocation,
        },
        controlMode: setting.controlMode,
        balance: bnOrZero(guild.guildBank?.balance ?? 0),
        tokens: guild.guildBank?.tokens ?? {
          zilToken: bnOrZero(0),
          hunyToken: bnOrZero(0),
        },
        address: guild.guildBank?.address ?? '',
        initialEpoch: guild.guildBank?.initialEpoch ?? 0,
        currentEpoch: guild.guildBank?.currentEpoch ?? 0,
      },
    };
    return currentGuild;
  }

  const requestApproval = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const approveTx = await TBMConnector.increaseEmporiumAllowance(wallet, new BigNumber(2).pow(128).minus(1).dp(0));
    if (approveTx?.id) {
      try {
        await waitForTx(approveTx.id);
        setApproved(true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const requestUnlockBank = async () => {
    if (hasGuildBank || !guild) return;
    if (!wallet) throw new Error("Wallet not connected")
    if (hunyTokens < bnOrZero(price).shiftedBy(-Decimals.HUNY).toNumber()) throw new Error("Insufficient HUNY")

    const maxPrice = bnOrZero(price).times(1.05).dp(0);

    //INFO: 10000 bps = 100%
    // input: 0.5
    // (0.5 * 100) / 10,000 * 100% = 0.5%
    const guildBankParams = {
      guildId: guild.id,
      initialJoiningFee: [
        (bankSettings.joinFee.shiftedBy(Decimals.HUNY)).toString(10), // initial amount
        (bankSettings.joinCaptainAllocation * 100).toString(), // captain allocation bps (10000 bps = 100%)
        (bankSettings.joinOfficerAllocation * 100).toString(), // officer allocation bps (10000 bps = 100%)
      ],
      initialWeeklyTax: [
        (bankSettings.taxFee.shiftedBy(Decimals.HUNY)).toString(10), // initial amount
        (bankSettings.taxCaptainAllocation * 100).toString(), // captain allocation bps (10000 bps = 100%)
        (bankSettings.taxOfficerAllocation * 100).toString(), // officer allocation bps (10000 bps = 100%)
      ],
      initialControlMode: bankSettings.controlMode,
    }

    logger("debug-component/BankSettings", "requestUnlockBank", guildBankParams);

    const params = JSON.stringify({ guildBankParams: guildBankParams });
    const purchaseTx = await TBMConnector.purchaseItem(wallet, ITEM_ID[network].TreasurersDeed, maxPrice, params);
    if (purchaseTx.isRejected() || !purchaseTx.id) {
      onClose();
      throw new Error("Submitted transaction was rejected.");
    }

    const tx = await waitForTx(purchaseTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(purchaseTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    setPurchaseTxHash(purchaseTx.id);
    setUpdateRequestComplete(true);
    toaster(`Guild Bank Unlocked `, { hash: purchaseTx.id!, overridePersist: true });
    const currentGuild: Guild = guildWithBankSetting(guild, bankSettings);
    onReload(currentGuild);
  }

  const requestUpdateBank = async () => {
    if (!guild.guildBank || !hasGuildBank) return;
    if (!wallet) throw new Error("Wallet not connected")
    if (bankHuny < bnOrZero(price).shiftedBy(-Decimals.HUNY).toNumber()) throw new Error("Insufficient Guild Bank Balance")

    if (!!guild.guildBank.pendingRequest) {
      toaster(`Please approve or cancel current multisig transaction first!`);
      return;
    }

    logger("debug-component/BankSettings", "requestUnlockBank", bankSettings);
    const purchaseTx = await TBMConnector.updateBankSettings(guild.guildBank, bankSettings)
    if (purchaseTx.isRejected() || !purchaseTx.id) {
      onClose();
      throw new Error("Submitted transaction was rejected.");
    }

    const tx = await waitForTx(purchaseTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(purchaseTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    setPurchaseTxHash(purchaseTx.id);
    setUpdateRequestComplete(true);
    toaster(`Guild Bank Updated `, { hash: purchaseTx.id!, overridePersist: true });

    const currentGuild: Guild = guildWithBankSetting(guild, bankSettings);
    onReload(currentGuild);
  }

  // EVENT HANDLERS -----------------

  const handleOnClose = () => {
    if (isLoading) return;
    onClose();
  }

  const handleOnBack = () => {
    if (isLoading) return;
    onBack();
  }

  const handleApprove = () => {
    runApprove(async () => {
      await requestApproval();
    });
  }

  const handleRequestAction = () => {
    runBankAction(async () => {
      hasGuildBank ? await requestUpdateBank() : await requestUnlockBank();
    });
  }

  // --------------------------------

  const isLoading: boolean = loadingQueryApproved || loadingApprove || loadingBankAction;

  const getHeader = () => {
    if (updateRequestComplete && purchaseTxHash) {
      return (!hasGuildBank) ? "Unlock Guild Bank success!" : "Head to Guild Bank";
    } else if (isLoading) {
      return (!hasGuildBank) ? "Unlocking Guild Bank..." : "Updating Guild Bank";
    } else {
      return (!hasGuildBank) ? "Unlock Guild Bank" : "Update Guild Bank Settings";
    }
  }

  const getContent = () => {
    if (!wallet) return;
    if (updateRequestComplete && purchaseTxHash) {
      return (
        <>
          {((guild.guildBank?.controlMode === "CaptainOnly") && (wallet?.addressInfo.byte20.toLowerCase() === guild.leaderAddress)) || (!hasGuildBank || !guild.guildBank?.address)
            ? <Box sx={styles.contentBox}>
              {/* tx hash */}
              {purchaseTxHash && (
                <Link
                  target="_blank"
                  href={getExplorerLink("tx", purchaseTxHash, network)}
                  sx={styles.viewTx}
                >
                  View Transaction
                  <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
                </Link>
              )}


              <ContainedButton
                variant="contained"
                color="secondary"
                onClick={handleOnBack}
                sx={styles.profileButton}
              >
                {hasGuildBank ? 'Back to Guild Bank' : 'View Guild Bank'}
              </ContainedButton>
            </Box>
            : (<Box sx={styles.contentBox}>
              <Typography variant="body1" color="primary" className="joinInfo">
                <Typography component="span" sx={styles.signaturesRequiredText}>{whoseSignature(wallet!.addressInfo.byte20.toLowerCase(), guild)}</Typography>&nbsp;required for this transaction to
                go through.
              </Typography>

              {/* tx hash */}
              <Link
                target="_blank"
                href={getExplorerLink("tx", purchaseTxHash, network)}
                sx={styles.viewTx}
              >
                View Transaction
                <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
              </Link>

              <ContainedButton
                variant="contained"
                color="secondary"
                onClick={handleOnBack}
                sx={styles.profileButton}
              >
                {hasGuildBank ? 'Back to Guild Bank' : 'View Guild Bank'}
              </ContainedButton>
            </Box>)
          }
        </>
      )
    }

    if (isLoading) {
      return (
        <Fragment>
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Fragment>
      )
    }

    return (
      <Fragment>
        <Box mt="20px" width="100%">
          <Box sx={styles.updateContent}>
            <Box sx={styles.updateContentSection}>
              <Typography color="primary" variant="body1" >
                Join Fee: <span>{formatIntegerNumber(bankSettings.joinFee)} <Huny width={24} height={24} /></span>
              </Typography>
              <Typography color="primary" variant="body1" >
                Join Captain Allocation: <span>{bankSettings.joinCaptainAllocation}%</span>
              </Typography>
              <Typography color="primary" variant="body1" >
                Join Officer Allocation: <span>{bankSettings.joinOfficerAllocation}%</span>
              </Typography>

              <Divider sx={styles.dialogDivider} />
            </Box>

            <Box sx={styles.updateContentSection}>
              <Typography color="primary" variant="body1" >
                Weekly Tax Fee: <span>{formatIntegerNumber(bankSettings.taxFee)} <Huny width={24} height={24} /></span>
              </Typography>
              <Typography color="primary" variant="body1" >
                Tax Captain Allocation: <span>{bankSettings.taxCaptainAllocation}%</span>
              </Typography>
              <Typography color="primary" variant="body1" >
                Tax Officer Allocation: <span>{bankSettings.taxOfficerAllocation}%</span>
              </Typography>

              <Divider sx={styles.dialogDivider} />

              <Typography color="primary" variant="body1" >
                Control Model: <span>{MultisigSetting[bankSettings.controlMode].substring(0, MultisigSetting[bankSettings.controlMode].lastIndexOf(" "))}</span>
              </Typography>
            </Box>
          </Box>

        </Box>

        <Box display="flex" sx={styles.buttonBox}>
          {!approved &&
            <ContainedButton sx={styles.approveButton} onClick={handleApprove} disabled={isLoading}>
              {isLoading
                ? <CircularProgress size={24} />
                : <span style={{ lineHeight: '2rem' }}>Approve</span>
              }
            </ContainedButton>
          }

          <ContainedButton
            disabled={!approved || isLoading}
            sx={styles.updateButton}
            onClick={handleRequestAction}
          >
            Confirm &amp;&nbsp;{hasGuildBank ? 'Update' : 'Unlock'}
          </ContainedButton>
        </Box>
      </Fragment>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} hideCloseButton={isLoading} disableScrollLock={true}>
      {getContent()}
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  updateContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'start',
    placeItems: 'center',
    gap: '24px',
  },
  updateContentSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'start',
    placeItems: 'center',
    gap: '24px',

    "> *": {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      placeContent: 'space-between',
      placeItems: 'center',
      ...GUILD_GRAY_GRADIENT,
    },
    "span": {
      display: 'flex',
      flexDirection: 'row',
      placeContent: 'end',
      placeItems: 'center',
      gap: '0.5em',
    }
  },
  dialogDivider: {
    background: 'rgba(174, 241, 238, 0.1)',
    borderColor: 'rgba(174, 241, 238, 0.1)',
  },
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 700,
        width: '60%',
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    },
  },
  profileButton: {
    marginTop: "10px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  signaturesRequiredText: {
    color: "#ff8952",
  },
  loadingImage: {
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  viewTx: {
    marginTop: "20px",
    marginBottom: "40px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  buttonBox: {
    marginTop: "40px",
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  approveButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    marginRight: '10px',
    width: 270,
    "@media (max-width:600px)": {
      marginRight: 0,
      marginBottom: '10px',
      width: '100%',
    }
  },
  updateButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    minWidth: 270,
    "@media (max-width:600px)": {
      width: '100%',
    }
  },
};


export default PostUpdateDialog;