import { Box, BoxProps, SxProps } from "@mui/material";
import { AppTheme, SimpleMap } from "utils/types";
import Background from "assets/empty-state-bg.svg";
import { combineStyles } from "utils/themeUtilities";

export interface BoxWithHeaderProps extends BoxProps{
  headerText?: string | JSX.Element;
  componentsRight?: string | JSX.Element;
  showBackground?: boolean;
  boxContentSx?: SxProps<AppTheme>;
  boxHeaderSx?: SxProps<AppTheme>;
}

const BoxWithHeader : React.FC<BoxWithHeaderProps> = (props: BoxWithHeaderProps) => {
  const {
    children,
    headerText,
    componentsRight,
    showBackground,
    boxContentSx,
    boxHeaderSx,
    ...rest
  } = props;

  return (
    <Box {...rest}>
      <Box sx={!!showBackground ? styles.boxContainerBg : styles.boxContainer}>
        <Box sx={combineStyles(styles.boxHeader, boxHeaderSx)}>
          {headerText}
          {componentsRight}
        </Box>
        <Box sx={combineStyles(styles.boxContent, boxContentSx)}>
          {children}
        </Box>
      </Box>
    </Box>
    )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  boxContainerBg: {
    backgroundColor: '#fff',
    background: 'rgba(174, 241, 238, 0.1)',
    height: '100%',
    border: '1px solid #AEF1EE33',
    borderRadius: '16px',
    backgroundImage: `url(${Background})`,
  },
  boxContainer: {
    backgroundColor: '#fff',
    background: 'rgba(174, 241, 238, 0.1)',
    height: '100%',
    border: '1px solid #AEF1EE33',
    borderRadius: '16px',
  },
  boxHeader: {
    padding: '5px 40px',
    width: '100%',
    height: '46px',
    borderRadius: '16px 16px 0px 0px',
    border: '1px solid #AEF1EE33',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  boxContent: {
    padding: '20px',
    borderRadius: '0px 0px 16px 16px',
  }
}

export default BoxWithHeader;