import {
  Box, Button, Divider, Grid, styled, SxProps, Typography
} from "@mui/material";
import { Huny } from "assets";
import BoxWithHeader from "components/BoxWithHeader";
import StripeHeader from "components/Game/components/StripeHeader";
import { convertBpsAllocation, GUILD_GRAY_GRADIENT, MultisigSetting } from "components/Guild/components/GuildConstants";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import dayjs from "dayjs";
import React, { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Guild, TaxByWeek, TaxStatus } from "store/types";
import { useRedux } from "utils";
import { Decimals } from "utils/constants";
import { bnOrZero, formatIntegerNumber } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import { CollectTaxDialog, Dropdown, Switch, TaxBalanceAccordion } from "./components";
import { DropdownOptions } from "./components/Dropdown";
import { MemberTax } from "./components/TaxBalanceAccordion";

export interface Props {
  guild: Guild;
}

const TaxCollectorTab: React.FC<Props> = (props: Props) => {
  const { guild } = props;
  const { guildBank } = guild;
  const controlMode: string | boolean = guildBank ? MultisigSetting[guildBank.controlMode] : false

  const navigate = useNavigate();

  const yearOptions: DropdownOptions[] = useMemo(() => {
    const taxes = guild.guildBank?.taxes;
    const years = Array.from(new Set(taxes?.map(t => t.date.getFullYear())));
    const res = years.map(year => {
      return { value: year.toString(), label: year.toString() }
    })

    if (res.length === 0) return [{ value: `${dayjs().year()}`, label: `${dayjs().year()}` }]
    return res;

    //eslint-disable-next-line
  }, [guild.guildBank?.taxes])

  const [currentYear, setCurrentYear] = useState<string>(`${dayjs().year()}`);
  const [showCollected, setShowCollected] = useState<boolean>(true);
  const [showCollectTax, setShowCollectTax] = useState<boolean>(false);
  const [memberTax, setMemberTax] = useState<MemberTax[]>([]);
  const parentRef = useRef<HTMLDivElement>();
  const taxes = useRedux((state) => state.guild.bankTaxes);

  const hunyTaxes: TaxByWeek[] = useMemo(() => {
    if (!guild || !taxes) return [];
    const filteredByYear = taxes.filter((tax) => tax.date.getFullYear() === parseInt(currentYear));

    if (!showCollected) return filteredByYear.filter((hunyTax) => hunyTax.status === TaxStatus.PendingCollection)
    return filteredByYear;

    // eslint-disable-next-line
  }, [showCollected, currentYear, guild.guildBank, taxes])

  if (!guild || !guildBank) return null;
  return (
    <Box sx={styles.tabWrapper} ref={parentRef}>
      <StripeHeader sx={styles.taxCollectorHeader}>
        TAX COLLECTOR
      </StripeHeader>
      <BoxWithHeader
        headerText={<Typography variant="h3" color="primary">TAX BALANCE</Typography>}
        sx={styles.boxWrapper}>
        <Box sx={{ px: "20px" }}>
          <Box display="flex" sx={{ justifyContent: 'space-between', placeItems: 'center', alignItems: 'center', mb: "24px" }}>
            <Box display="flex">
              <Dropdown options={yearOptions}
                defaultValueSelected={currentYear}
                onSelected={setCurrentYear}
                menuStyles={{ maxWidth: 0, maxHeight: 300, position: 'absolute', }}
                disableScrollLock isNormalHeight />
              <Switch label="Show Collected" onCheck={setShowCollected} defaultChecked={showCollected} />
            </Box>
            <Box display="flex">
              <Button
                variant="contained"
                color="secondary"
                sx={styles.headerButton}
                disabled={memberTax.findIndex(m => m.datesToPay.length > 0) === -1}
                onClick={() => setShowCollectTax(true)}>
                Collect
              </Button>
            </Box>
          </Box>
          <TaxBalanceAccordion guild={guild} hunyTaxes={hunyTaxes} onUpdateMemberTaxes={setMemberTax} parentRef={parentRef} />
        </Box>
      </BoxWithHeader>
      <BoxWithHeader
        headerText={<Typography variant="h3" color="primary">FEE INFORMATION</Typography>}
        sx={styles.boxWrapper}>
        <Grid sx={{ width: "100%", px: "10px" }} container columns={15} spacing={{ sm: 2, md: '32px' }}>
          <Grid item xs={15} sm={15} md={4}>
            <FeeInfoDivider textAlign="left">
              <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>Joining Fee</Typography>
              <TooltipZolar link={"https://docs.zolar.io/stations/guilds/guild-bank-new"} width="16px" height="16px">
                Joining Fee collected will be distributed as such:
                <li>{100 - convertBpsAllocation(guildBank.joiningFee.captainAllocation) - convertBpsAllocation((guildBank.joiningFee.officerAllocation * guild.commanderAddresses.length))}% to Guild Bank Vault</li>
                <li>{convertBpsAllocation(guildBank.joiningFee.captainAllocation)}% to Captain</li>
                <li>{convertBpsAllocation((guildBank.joiningFee.officerAllocation))}% to Each Officer</li>
              </TooltipZolar>
            </FeeInfoDivider>
            <Box sx={{ px: "10px" }}>
              <Typography variant="h2" color="primary" component="span" sx={styles.TextRow}>
                <Huny width="40px" height="40px" />
                &nbsp; {formatIntegerNumber(bnOrZero(guildBank.joiningFee.initialAmt).shiftedBy(-Decimals.HUNY))}
              </Typography>
              <Typography color="primary" sx={styles.smallText}>
                A one-time joining fee for newcomers.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={15} sm={15} md={4}>
            <FeeInfoDivider textAlign="left">
              <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>Weekly Tax</Typography>
              <TooltipZolar link={"https://docs.zolar.io/stations/guilds/guild-bank-new"} width="16px" height="16px">
                Weekly Tax collected will be distributed as such:
                <li>{100 - convertBpsAllocation(guildBank.weeklyTax.captainAllocation) - convertBpsAllocation((guildBank.weeklyTax.officerAllocation * guild.commanderAddresses.length))}% to Guild Bank Vault</li>
                <li>{convertBpsAllocation(guildBank.weeklyTax.captainAllocation)}% to Captain</li>
                <li>{convertBpsAllocation((guildBank.weeklyTax.officerAllocation))}% to Each Officer</li>
                Weekly increments may be applied depending on the guild.
              </TooltipZolar>
            </FeeInfoDivider>
            <Box sx={{ px: "10px" }}>
              <Typography variant="h2" color="primary" component="span" sx={styles.TextRow}>
                <Huny width="40px" height="40px" />
                &nbsp; {formatIntegerNumber(bnOrZero(guildBank.weeklyTax.initialAmt).shiftedBy(-Decimals.HUNY))}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={15} sm={15} md={4}>
            <FeeInfoDivider textAlign="left">
              <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>Multisig Control</Typography>
              <TooltipZolar link="#" width="16px" height="16px">
                Multisig control will be applied to:
                <li>Deposits to / Withdrawals from Magic Hive</li>
                <li>Withdrawals</li>
                <li>Guild Bank Settings</li>
              </TooltipZolar>
            </FeeInfoDivider>
            <Box sx={{ px: "10px" }}>
              <Typography variant="h2" color="primary" component="span" sx={styles.TextRow}>MultiSig {controlMode === MultisigSetting.CaptainOnly ? 'Deactivated' : 'Activated'}</Typography>
              <Typography color="primary" sx={styles.smallText}>
                {!!controlMode && `${controlMode} required for withdrawals and guild settings.`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={15} sm={15} md={3} sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              variant="contained"
              color="secondary"
              sx={styles.headerButton}
              onClick={() => navigate(`/guilds/${guild.id}/bank/settings`)}
              disableFocusRipple
            >
              Settings
            </Button>
          </Grid>
        </Grid>
      </BoxWithHeader>
      <CollectTaxDialog guild={guild} open={showCollectTax} onClose={() => setShowCollectTax(false)} memberTaxList={memberTax} allTaxes={hunyTaxes} />
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  tabWrapper: {
    background: 'linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, #000010 100%)',
    borderRadius: '16px',
    padding: '40px',
    border: '1px solid #AEF1EE33'
  },
  bankHeader: {
    textAlign: 'center',
    paddingY: '5px',
    marginY: '25px',
    transform: 'skewX(-25deg)',
    background: 'repeating-linear-gradient(90deg, #FFB60033, #FFB60033 36px, transparent 36px, transparent 44px)',
  },
  viewRoot: {
    marginTop: "160px",
    width: "100%",
    height: "100%",
    position: 'relative',
    overflow: "hidden",
    overflowX: "auto",

    '::-webkit-scrollbar': {
      height: "6px",
      width: "6px",
    },

    '::-webkit-scrollbar-track': {
      marginX: "24px",
    },

    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },

    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },
  taxCollectorWrapper: {
    marginTop: '20px',
    padding: '40px',
    minHeight: '500px',
    minWidth: '720px',
    border: '1px solid #AEF1EE33',
    borderRadius: '16px',
    background: 'linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, #000010 100%)',
  },
  taxCollectorHeader: {
    textAlign: 'center',
    paddingY: '5px',
    marginBottom: '25px',
    transform: 'skewX(-25deg)',
    background: 'repeating-linear-gradient(90deg, #AEF1EE33, #AEF1EE33 36px, transparent 36px, transparent 44px)',
    "& h2": {
      ...GUILD_GRAY_GRADIENT,
      background: "linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)"
    }
  },
  hunyRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    "@media (max-width:1200px)": {
      flexDirection: 'column',
    },
  },
  guildView: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    placeContent: "start",
    placeItems: "start",
    gap: "5rem",
    color: "white"
  },
  viewRight: {
    flex: '1 1 0%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    placeContent: 'start',
    placeItems: 'start',
  },
  boxWrapper: {
    marginBottom: '24px',
    color: '#FFF',
  },
  headerButton: {
    height: "48px",
    minWidth: "216px",
    fontSize: "18px",
    fontWeight: 700,
    "@media (max-width:1400px)": {
      width: "100%",
      minWidth: "",
      textAlign: "center",
    },
  },
  overlineSmall: {
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: "0.2em"
  },
  TextRow: {
    fontSize: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  smallText: {
    fontSize: '12px',
    flexDirection: 'row',
    // paddingLeft: '20px',
    paddingBottom: '10px',
    lineHeight: '18px',
    marginTop: "6px",
    alignItems: 'center',
    display: 'flex'
  },
}

const FeeInfoDivider = styled(Divider)({
  '&.MuiDivider-root::after': {
    borderTopColor: 'rgba(174, 241, 238, 0.1)',
  },
  '&.MuiDivider-root::before': {
    borderTopColor: '#aef1ee',
    width: '0%',
  },
});

export default TaxCollectorTab;
