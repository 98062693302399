import { Box, CircularProgress, Collapse, Divider, FormHelperText, IconButton, SxProps, TextField, Typography } from '@mui/material'
import { AddIcon, ArrowDropdownIcon, ArrowDropupIcon, RemoveIcon, WarningRed } from 'assets'
import ContainedButton from 'components/ContainedButton'
import { logger } from 'core/utilities'
import { MetazoaClient } from 'core/utilities/metazoa'
import dayjs from 'dayjs'
import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile } from 'saga/selectors'
import { actions } from 'store'
import { NftMetadata, OAuth } from 'store/types'
import { TBMConnector } from 'tbm'
import { theme } from 'theme'
import { useAsyncTask, useRedux, useTaskSubscriber, useToaster } from 'utils'
import { bnOrZero } from 'utils/strings'
import { combineStyles } from 'utils/themeUtilities'
import { AppTheme, SimpleMap } from 'utils/types'
import useNetwork from 'utils/useNetwork'
import { STAT_TYPES } from '../MetazoaConstants'

const initialFormState = {
  points: 1,
}

type PointAssignmentProps = {
  token: NftMetadata,
  attributeAbbr: STAT_TYPES,
  isStaked: boolean,
};

const PointAssignment: FC<PointAssignmentProps> = ({
  token,
  attributeAbbr,
  isStaked,
}: PointAssignmentProps) => {

  const toaster = useToaster();
  const network = useNetwork();
  const dispatch = useDispatch();

  const wallet = useRedux((state) => state.wallet.wallet)
  const metazoaProfileState = useSelector(getProfile);
  const { oAuth } = metazoaProfileState;

  const [collapseContent, setCollapsedContent] = useState<boolean>(true);
  const [loadingTokens] = useTaskSubscriber("updateTokens");

  const assignablePoints: number = token.unassignedStatPoints ?? 0;

  // STATES / HOOKS -----------------

  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [errors, setErrors] = useState<string>("");

  const [runPointsAssignment, loadingPointsAssignment] = useAsyncTask("assigningPoints", (error) => {
    toaster(error?.message ?? "Error Assigning Points");
  });

  const isLoading: boolean = loadingPointsAssignment || loadingTokens;

  const revertToInitialState = () => {
    setFormState(initialFormState);
    setErrors('');
  };

  useMemo(() => {
    if (!token) return;

    // Revert on token change
    revertToInitialState();
  }, [token])

  const isSubmitEnabled = useMemo(() => {
    if (!wallet || isLoading || !assignablePoints || !token) return false;

    setErrors("");

    if (isStaked) {
      setErrors("Metazoa needs to be unstaked before assigning stats")
      return false
    }

    if (formState.points <= 0) return false;

    if (formState.points > assignablePoints) {
      setErrors("Insufficient assignable points")
      return false
    }

    setErrors("");
    return true
  }, [wallet, isLoading, assignablePoints, isStaked, formState.points, token])

  // FUNCTIONS ----------------------

  const onPointInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    pointInputHandler(+(event.target.value));
  };

  const assigningPoints = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!isSubmitEnabled) throw new Error(`Requirements not met`);

    const metazoaClient = new MetazoaClient(network);
    let checkedOAuth: OAuth | undefined = oAuth;
    if (!oAuth?.access_token || oAuth.address !== wallet.addressInfo.bech32 || (oAuth && dayjs(oAuth?.expires_at * 1000).isBefore(dayjs()))) {
      const { result } = await metazoaClient.metazoaLogin(wallet, window.location.hostname);
      dispatch(actions.Profile.updateAccessToken(result));
      checkedOAuth = result;
    }

    logger("debug-component", "PointAssignment", {
      token,
      attributeAbbr,
      isStaked,
      formState,
    })

    //TODO: Update req.body for point param when available
    await metazoaClient.assignZoaBonusPoint(wallet.addressInfo.byte20, {
      metazoaId: +token.id,
      attribute: attributeAbbr,
      amount: formState.points,
    }, checkedOAuth!);

    // Update cache
    await TBMConnector.reloadMetazoaMetadata([token.id]);
    dispatch(actions.Token.reloadTokens());

    // Revert
    revertToInitialState();
  }

  // EVENT HANDLERS -----------------

  const pointInputHandler = (pointInput: number) => {
    setFormState({
      points: pointInput
    })
  };

  const handleAddPt = () => {
    pointInputHandler(formState.points + 1);
  };

  const handleSubtractPt = () => {
    if (formState.points <= 0)
      return;

    pointInputHandler(formState.points - 1);
  };

  const handlePointsAssignment = () => {
    runPointsAssignment(async () => {
      await assigningPoints();
    })
  }

  const handleCollapsible = () => {
    setCollapsedContent(!collapseContent);
  }

  // --------------------------------

  if (!token || !assignablePoints) return null;
  return (
    <Box component="aside">

      <Box
        component="header"
        onClick={handleCollapsible}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          placeContent: 'space-between',
          placeItems: 'center',
          gap: 1,

          marginBottom: '8px',
        }}
      >
        <Typography
          variant="subtitle1"
          color="#FF8952"
          sx={combineStyles(
            styles.statDetailsHeader,
            {
              margin: '0',
            }
          )}
        >Assign Bonus Point(s)
        </Typography>

        <Box
          component="span"
          sx={{
            display: 'inline-flex',
            flexDirection: 'row',
            placeContent: 'center',
            placeItems: 'center',

            width: 'fit-content',
          }}
        >
          {collapseContent ? < ArrowDropupIcon /> : < ArrowDropdownIcon />}
        </Box>
      </Box>


      <Collapse in={collapseContent}>
        <>
          <Box sx={{
            width: '95%',
            display: 'flex',
            placeContent: 'space-between',
            placeItems: 'center',
            mb: 2,
          }}>
            <Typography variant="subtitle1" color="primary" sx={styles.statTextSize}>
              Points Collected:
            </Typography>
            <Typography
              component="span"
              variant="subtitle1"
              color="primary"
              sx={combineStyles(styles.statTextSize, styles.traitType)}
            >
              {assignablePoints}
            </Typography>
          </Box>

          <Box width="100%">
            <Box sx={combineStyles(styles.input, styles.numInputBox,
              { ...(errors) && styles.errorInput }
            )}>

              <IconButton disabled={isLoading} onClick={handleSubtractPt}>
                <RemoveIcon style={{ height: 16, width: 16 }} />
              </IconButton>

              <Box sx={combineStyles(
                styles.numInput,
                // styles.input,
              )}>

                <TextField
                  id="elderberry"
                  name="elderberry"
                  onChange={onPointInputChange}
                  disabled={isLoading}
                  value={bnOrZero(formState.points).toString(10)}
                  sx={combineStyles(
                    // styles.input,
                    styles.settingNumInput, {
                    // textAlign: 'center',
                    flex: 0.5,
                    '.Mui-disabled': {
                      color: '#fff',
                      'WebkitTextFillColor': '#fff !important',
                    }
                  }, {
                    ...(formState.points >= (10000)) && ({
                      flex: `calc(0.6 + ${(Number(formState.points.toString().length) - 5) / 10})`,
                      "& input": { textAlign: "center!important" },
                    })
                  })} />
              </Box>

              <IconButton disabled={isLoading} onClick={handleAddPt}>
                <AddIcon style={{ height: 16, width: 16 }} />
              </IconButton>
            </Box>
            {!!errors &&
              <Box sx={styles.errorTextContainer}>
                <WarningRed width={15} />
                <FormHelperText sx={styles.errorText}>{errors}</FormHelperText>
              </Box>}


            <Box display="flex" sx={styles.buttonBox}>
              {/* Confirm */}
              <ContainedButton
                onClick={handlePointsAssignment}
                disabled={!isSubmitEnabled}
                sx={styles.confirmButton}
              >
                {isLoading
                  ? <CircularProgress size={24} />
                  : "CONFIRM & ASSIGN"
                }
              </ContainedButton>
            </Box>

          </Box>
        </>
      </Collapse>

      <Divider sx={styles.divider} />
    </Box >
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  statDetailsHeader: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    marginBottom: '8px',
  },

  divider: {
    background: 'rgba(174, 241, 238, 0.1)',
    marginY: '10px',
    width: '100%',
  },

  settingNumInput: {
    background: 'transparent',
    border: 0,
    flex: 0.4,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
    "& input": {
      paddingX: '0',
      width: "100%",
      textAlign: "center",
    },
  },
  input: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },

    "&:hover, &:active, &:focus, &:focus-within": {
      borderColor: '#AEF1EE',
    },

    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      paddingY: "12px",

      "&:active, &:focus": {
        color: "#AEF1EE",
        "& input": {
          color: "#AEF1EE",
        },
      }
    },
  },
  numInputBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'space-between',
    padding: '0 5%',
    width: "100%",
    // gap: '24px',
  },
  errorInput: { borderColor: "#F75E5E!important", },
  numInput: {
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    gap: '8px',
    flex: 1,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      minWidth: '50px',
      "svg": {
        display: 'none',
      },
    },
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },

  buttonBox: {
    // marginTop: "20px",
    // "> *": {
    //   padding: '17px 24px !important',
    // },
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '80px',
    }
  },
  confirmButton: {
    height: 50,
    flexGrow: 1,
    flexBasis: 0,
    width: '100%',
    borderRadius: '16px',
    my: '16px',
    marginLeft: '0px!important',
    // minWidth: 270,

    "&.MuiButton-root": {
      fontWeight: 700,
      fontSize: "18px",
      whiteSpace: "nowrap",
    },

    "@media (max-width:600px)": {
      width: '100%',
    }
  },
  statTextSize: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
}

export default PointAssignment