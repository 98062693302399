import { Box, Container, SxProps, Tab, Tabs } from "@mui/material";
import CurrentMissionTab from "assets/homepage/CurrentMissionTab.svg";
import NextMissionTab from "assets/homepage/NextMissionTab.svg";
import PrevMissionTab from "assets/homepage/PrevMissionTab.svg";
import TabPanel from "components/TabPanel";
import React, { ReactNode, useState } from "react";
import { theme } from "theme";
import { AppTheme, SimpleMap } from "utils/types";
import { Missions } from ".";


const Mission: React.FC = () => {

  const [value, setValue] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabs: {
    label: string,
    content: ReactNode,
  }[] = [
      {
        label: PrevMissionTab,
        content: <Missions.Prev />,
      },
      {
        label: CurrentMissionTab,
        content: <Missions.Current />,
      },
      {
        label: NextMissionTab,
        content: <Missions.Next />,
      },
    ]

  return (
    <Container maxWidth="xl" sx={styles.container} component="section">
      <Box maxWidth="xl" sx={styles.contentBox} component="main">
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Box sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '100%',
          }}>
            <Tabs
              variant="scrollable"
              component="ul"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
              sx={{
                margin: 0,
                padding: 0,

                ".MuiTabs-flexContainer": {
                  placeContent: 'space-between',
                  padding: 0,
                  overflowY: 'auto',
                  overscrollBehaviorX: 'contain',
                  scrollSnapType: 'x mandatory',
                },

                ".MuiTab-root": {
                  minWidth: 'unset',
                  padding: 0,
                  width: `calc(100% / ${tabs.length})`,
                  scrollSnapAlign: 'center',
                  [theme.breakpoints.down('md')]: {
                    width: 'auto',
                  }
                },
              }}
            >

              {tabs.map((tab, idx) => (
                <Tab
                  key={idx}
                  value={idx}
                  label={(
                    <Box
                      component="img"
                      src={tab.label}
                      width={"100%"}
                      height={"100%"}
                      className="tabImg"
                    />
                  )}

                  sx={{
                    ".tabImg": {
                      opacity: (value === idx) ? 1 : 0.5,
                    },
                  }}
                />
              ))}
            </Tabs>
          </Box>

          {tabs.map((tab, idx) => (
            <TabPanel
              value={value}
              index={idx}
              key={idx}
              hidden={value !== idx}
            >
              {tab.content}
            </TabPanel>
          ))}
        </Box>
      </Box >
    </Container >
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    padding: "0px",

    "*": {
      zIndex: 1,
    },

    // //DEBUG: Remove this
    // border: '1px solid yellow',
    // "*": {
    //   border: '1px solid red',
    // }
  },
  contentBox: {
    position: 'relative',
    padding: "0px 5% 60px",
    "@media (min-width:600px)": {
      padding: "0px 10% 80px",
    },
  },
  textColumn: {
    mx: 'auto',
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subheader: {
    textTransform: "uppercase",
    letterSpacing: "0.24em",
  },
  header2: {
  },
  textbody: {
    textAlign: "center",
  },
  missionCol: {
    // background:'green',
    position: 'absolute',
    top: '40%',
    left: 0,
    width: '100%',

    display: "flex",
    flexDirection: "row",
    placeItems: "center",
    placeContent: "space-between",
    padding: "16px 0px",
    "@media (min-width:600px)": {
      padding: "120px 0px 80px",
    },

    [theme.breakpoints.down('lg')]: {
      // display: 'none',
      width: '115%',
      left: '-8%',
    }
  },
  buttonGradient: {
    WebkitTextFillColor: '#000',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
};



export default Mission;
