import { Box, CircularProgress, SxProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { Huny } from "assets";
import BoxWithHeader from "components/BoxWithHeader";
import { useEffect, useMemo, useState } from "react";
import { Guild, HunyPot, RefineryHunyStats } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useToaster } from "utils";
import { BIG_ONE, BIG_ZERO, Decimals } from "utils/constants";
import { bnOrZero, formatIntegerNumber } from "utils/strings/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useBlockTime from "utils/useBlockTime";
import useRedux from "utils/useRedux";

export interface Props {
  guild: Guild
}

const HunyOverview: React.FC<Props> = (props: Props) => {
  const { guild } = props;
  const { guildBank } = guild;

  const [, currentBlock] = useBlockTime();
  const refineryConfig = useRedux((state) => state.token.refineryConfig)
  const [refineryHunyStats, setRefineryHunyStats] = useState<RefineryHunyStats>();
  const [hunyPots, setHunyPots] = useState<SimpleMap<HunyPot>>();
  const toaster = useToaster();
  const [runFetchRefineryStats, loadingFetchRefineryStats] = useAsyncTask("fetchGuildHunyPots", (error) => {
    toaster(error?.message ?? "Error fetching huny pots");
  });

  useEffect(() => {
    if (!guildBank || !guildBank.address) return;
    runFetchRefineryStats(async () => {
      const stats: RefineryHunyStats = await TBMConnector.getGuildBankRefineryStats(toBech32Address(guildBank.address));
      const result: SimpleMap = await TBMConnector.getGuildBankHunyPots(toBech32Address(guildBank.address));
      const hunyPots: SimpleMap<HunyPot> = {};
      for (const startBlock in result) {
        const startBlkNum = bnOrZero(startBlock).toNumber()
        const startTimestamp: number = await TBMConnector.getBlkTimestamp(startBlkNum)
        hunyPots[startBlock] = new HunyPot(
          startBlkNum,
          startTimestamp,
          bnOrZero(result[startBlock]),
        );
      }
      setHunyPots(hunyPots);
      setRefineryHunyStats(stats)
    })
    // eslint-disable-next-line
  }, [guildBank])

  const {
    totalHunyClaimable,
    totalHunyRefining,
  } = useMemo(() => {
    let claimable = BIG_ZERO;
    let refining = BIG_ZERO;
    if (!hunyPots) return {
      totalHunyClaimable: claimable,
      totalHunyRefining: refining,
    };

    let refineryBoost = BIG_ONE;
    if (refineryHunyStats?.totalShare?.gt(0) && refineryHunyStats?.totalSupply) {
      // boost = total supply / total share
      refineryBoost = refineryHunyStats.totalSupply.div(refineryHunyStats.totalShare);
    }

    for (const [, hunyPot] of Object.entries(hunyPots)) {
      const progress = hunyPot.progress(currentBlock, refineryConfig?.immediateRefine);
      const totalRefining = hunyPot.amount.shiftedBy(-Decimals.HUNY).times(refineryBoost)
      refining = refining.plus(totalRefining);

      const totalClaimable = totalRefining.times(progress);
      claimable = claimable.plus(totalClaimable);
    }

    return {
      totalHunyClaimable: claimable,
      totalHunyRefining: refining,
    };
  }, [currentBlock, hunyPots, refineryHunyStats, refineryConfig])

  return (
    <Box sx={styles.overviewWrapper}>
      <BoxWithHeader
        headerText={<Typography variant="h3" color="primary">TOTAL HUNY BALANCE</Typography>}
        sx={styles.boxWrapper}
      >
        {loadingFetchRefineryStats
          ? <CircularProgress size={24} />
          : <Typography variant="h2" color="primary" component="span" sx={styles.TextRow}>
            <Huny width="40px" height="40px" />
            &nbsp; {formatIntegerNumber(totalHunyRefining)}
          </Typography>
        }
      </BoxWithHeader>

      <BoxWithHeader
        headerText={<Typography variant="h3" color="primary">TOTAL CLAIMABLE HUNY</Typography>}
        sx={styles.boxWrapper}
      >
        {
          loadingFetchRefineryStats
            ? <CircularProgress size={24} />
            : <Typography variant="h2" color="primary" component="span" sx={styles.TextRow}>
              <Huny width="40px" height="40px" />
              &nbsp; {formatIntegerNumber(totalHunyClaimable)}
            </Typography>
        }
      </BoxWithHeader>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  overviewWrapper: {
    marginY: '10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    "@media (max-width:1200px)": {
      flexDirection: 'column',
    },
  },
  TextRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingLeft: '20px',
    paddingBottom: '10px',
    minHeight: '62px',
  },
  boxWrapper: {
    marginBottom: '10px',
    width: '49.5%',
    "@media (max-width:1200px)": {
      width: '100%',
    },
  },
  getHunyButton: {
    paddingX: '20px',
    minWidth: '180px',
    height: '42px',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '1.125rem',
  },
  claimButton: {
    height: '42px',
    paddingX: '20px',
    minWidth: '180px',
    fontSize: '1.125rem',
  }
}

export default HunyOverview;
