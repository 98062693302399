import React, { useRef, useState } from "react";
import { Box, BoxProps, Link, Slide, styled } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useDispatch } from "react-redux";
import { actions } from "store";
import useRedux from "utils/useRedux";
import Texture from "assets/Texture.png";
import BetaWarning from "components/BetaWarning";
import { FooterMain, Header } from "./components";
import NavDrawer from "./components/NavDrawer";
import SnackbarContentItem from "./components/SnackbarContentItem";
import { DISCORD_INVITE } from "core/constants";
import { JoinDiscordCTA } from "assets/homepage";

const MainLayout: React.FC<BoxProps> = (props: BoxProps) => {
  const { children, ...rest } = props;
  const dispatch = useDispatch();
  const [slideEnabled, setSlideEnabled] = useState<boolean>(true);
  const showNavDrawer = useRedux((state) => state.layout.showNavDrawer);
  const ref = useRef() as React.MutableRefObject<SnackbarProvider>;
  const onCloseDrawer = () => {
    dispatch(actions.Layout.toggleShowNavDrawer("close"));
  };

  window.addEventListener('scroll', function () {
    if (window.scrollY > 4500) setSlideEnabled(false);
    else setSlideEnabled(true);
  });

  return (
    <Main component="main" {...rest}>
      <SvgTextureBox />
      <SnackbarProvider
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        ref={ref}
        content={(key, message) => {
          if (!message) return
          let msgContent = JSON.parse(message!.toString());
          const { hash, content } = msgContent;
          return (<SnackbarContentItem snackKey={key} message={content} hash={hash} providerRef={ref} />)
        }}
        maxSnack={3}
      >
        <Slide
          in={slideEnabled}
          timeout={700}
          direction="left"
          mountOnEnter

          style={{
            position: "fixed",
            bottom: "2em",
            right: "2em",
            zIndex: 100,
          }}
        >
          <Box
            component={Link}
            target="_blank"
            href={DISCORD_INVITE}
            sx={{
              width: "fit-content",
              "> *": {
                width: '120px',
              },
              "@media (max-width:768px)": {
                display: "none",
              },
            }}>
            <JoinDiscordCTA />
          </Box>
        </Slide>
        <Header />
        <BetaWarning />
        {children}
        <FooterMain />
        <NavDrawer open={showNavDrawer} onClose={onCloseDrawer} />
      </SnackbarProvider>
    </Main>
  );
};

const Main = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  background: "linear-gradient(180deg, #010025 0%, #000000 100%)",
  display: "flex",
  flexDirection: "column",
  overflowX: "hidden",
  overflowY: "hidden",
  position: "relative",
  maxWidth: "100%",
  "@media (max-width:600px)": {
    background: "linear-gradient(180deg, #010025 0%, #000000 100%)",
  },
}));

const SvgTextureBox = styled(Box)({
  position: "absolute",
  top: "0%",
  left: "0%",
  height: "100%",
  width: "100%",
  backgroundImage: `url(${Texture})`,
  backgroundRepeat: 'repeat',
  opacity: "60%",
  pointerEvents: 'none',
});

export default MainLayout;
