import { Theme } from "@mui/material";

const MuiToggleButton = (theme: Theme) => ({
  styleOverrides: {
    root: {
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
      border: 'none',
    },
    selected: {
      background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
    },
  },
});

export default MuiToggleButton;