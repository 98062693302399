import { createTheme, Theme } from "@mui/material";
import themeOverrides from "./overrides";
import { palette } from "./palette";
import { typography } from "./typography";

const applyFlavour = (overrides: any, theme: Theme) => {
  const result: any = {};
  for (const key in overrides) result[key] = overrides[key](theme);
  return result;
};

const loadTheme = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    palette,
    typography,
  });
  theme.components = applyFlavour(themeOverrides, theme);
  return theme;
};

const theme = loadTheme();

export default theme;
