import { Box, CircularProgress, SvgIcon, SxProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { Huny, WarningIcon } from "assets";
import GuildMemberFrame from "assets/GuildMemberFrame.svg";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { waitForTx } from "core/utilities";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { Guild, GuildBankInfo } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { BIG_ZERO, Decimals } from "utils/constants";
import { bnOrZero, formatIntegerNumber } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";

export interface GuildProps {
  guild: Guild;
  bank: GuildBankInfo;
}

export interface WithdrawTxParams {
  recipient: string;
  token: string;
  amount: BigNumber;
}

export interface UpdateConfigTxParams {
  joining_fee: BigNumber;
  weekly_tax: BigNumber;
  multisigMode: string;
}

export interface HiveTxParams {
  zilAmount: BigNumber;
  hunyAmount: BigNumber;
}

const ApproveTaxCollection: React.FC<GuildProps> = (props: GuildProps) => {
  const { bank, guild } = props;
  const weeklyTax = bank.weeklyTax.initialAmt ?? BIG_ZERO;
  const dispatch = useDispatch();
  const toaster = useToaster();
  const wallet = useRedux((state) => state.wallet.wallet)
  const allowances = useRedux((state) => state.guild.allowances)

  // STATES / HOOKS -----------------

  const [runApprove, loadingApprove] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
  });

  const approve = async () => {
    if (!bank || !allowances) return;
    if (!wallet) throw new Error("Wallet not connected");

    const approveTx = await TBMConnector.approveWeeklyTax(wallet, toBech32Address(bank.address));
    toaster(`Submitted Approve Weekly Tax `, { hash: approveTx.id! })
    if (approveTx.isRejected() || !approveTx.id) throw new Error("Submitted transaction was rejected.");

    const tx = await waitForTx(approveTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(approveTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    toaster(`Approve Weekly Tax Success`, { hash: approveTx.id! })
    dispatch(actions.Guild.loadAllowances());
    dispatch(actions.Guild.reloadBank(guild));
  }

  const handleApprove = () => {
    runApprove(async () => {
      await approve();
    })
  }


  return (
    <Box sx={styles.withdrawalRequest}>
      <Box sx={styles.content}>
        <Box display="flex" alignItems="center" flex={0.9}>
          <SvgIcon component={WarningIcon} sx={styles.warningIcon} />
          <Typography variant="body1" color="primary" align="left" fontWeight={700} sx={styles.alignText}>
            Approve weekly taxes at&nbsp;<Huny height="24px" width="24px" />&nbsp;{formatIntegerNumber(bnOrZero(weeklyTax).shiftedBy(-Decimals.HUNY))}
            &nbsp;(re-approval is required if this tax amount is changed)
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.buttonGroup}>
        <ContainedButton
          variant="contained"
          sx={styles.button}
          onClick={handleApprove}
          disableFocusRipple
        >
          {
            loadingApprove
              ? <CircularProgress size={16} />
              : "Approve"
          }
        </ContainedButton>
      </Box>
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  content: {
    marginRight: "30px",
    flex: 1
  },
  withdrawalRequest: {
    borderRadius: '16px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    padding: '27px 40px',
    display: 'flex',
    placeItems: 'center'
  },
  button: {
    fontSize: '18px',
    height: 48,
    minWidth: '216px',
    padding: '14px 30px',
    fontWeight: 700,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
    "& div:first-of-type": {
      height: "inherit",
      // left: '-18px'
    }
  },
  gradientButton: {
    fontSize: '18px',
    height: 48,
    marginTop: '16px',
    width: '216px',
    padding: '14px 30px',
    fontWeight: 700,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column'
  },
  withdrawRequestImgFrame: {
    position: 'relative',
    backgroundImage: `url(${GuildMemberFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "160% 140%",
    backgroundPosition: "center",
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    width: '100%',
    height: '150px',
    maxWidth: '150px',
    margin: '10px 26px 10px 0px',
  },
  withdrawRequestImg: {
    height: '90%',
    width: '86%',
    borderRadius: "16px",
    objectFit: "contain",
    boxShadow: "0px 0px 12px 0px #00F0FF",
  },
  requestDateText: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 400,
    ...GUILD_GRAY_GRADIENT,
    marginLeft: '8px',
    background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256) 100%)'
  },
  withdrawalAmt: {
    display: 'flex',
    placeItems: 'center',
    gap: '10px'
  },
  requestorAddress: {
    fontWeight: 700,
    textDecoration: 'underline'
  },
  requestorAddressLink: {
    display: 'inline-block',
    textDecoration: 'none'
  },
  requestDetails: {
    placeItems: 'center',
    gap: '16px',
    fontSize: '18px',
    my: '11px'
  },
  warningIcon: {
    marginRight: "14px",
    marginBottom: "1.5px",
  },
  alignText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  purposeText: { fontSize: '18px' }
}

export default ApproveTaxCollection;