import { Dayjs } from "dayjs";

export interface LoadingTask {
  [index: string]: Dayjs;
};
export interface LoadingTasks {
  [index: string]: LoadingTask;
};

export enum ViewMode {
  Carousel = "Carousel",
  Grid = "Grid",
}
export type ViewModeType = (keyof typeof ViewMode);

export enum SortMode {
  Unsorted = "Unsorted",
  Gen = "Gen",
  "Base Rarity" = "Base Rarity",
  Profession = "Profession",
  Faction = "Faction",
  Affinity = "Affinity",
}
export type SortModeType = (keyof typeof SortMode);

export type CollectionMode = {
  view: ViewModeType,
  sort: SortModeType,
};
export interface LayoutState {
  loadingTasks: LoadingTasks,
  tasksRegistry: any,
  showNavDrawer: boolean,
  backgroundImgSrc: string,
  warningVisibility: boolean,
  collectionMode: CollectionMode,
  playAudio: boolean
};

export type OpenCloseState = "open" | "close";

