import { Box, Button, CircularProgress, Grow, Slide, SxProps, Tab, Tabs, Typography } from "@mui/material";
import { WarningRed } from "assets";
import PanelFrame from "assets/PanelFrame.svg";
import ContainedButton from "components/ContainedButton";
import StripeHeader from "components/Game/components/StripeHeader";
import { GUILD_GRAY_GRADIENT, GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { EquipCategory, EquipType, EQUIP_CATEGORIES, Gem, GEMS, ZOrdnanceClasses } from "components/Metazoa/ResourceConstants";
import TabPanel from "components/TabPanel";
import WarningBox from "components/WarningBox";
import { logger, waitForTx } from "core/utilities";
import { DND } from "layout/components";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { Equipment, NftMetadata, OwnedOrdnance, Resources, ZOMGCraftItem } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useTaskSubscriber, useToaster } from "utils";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import { EquipDND } from "..";
import { StatPanel } from "../../MetazoaView";
import { EquipProps } from "./EquipDialog";
import { getIdsFromItems, transferDropItem } from "./utils";

export interface IInventoryItem {
  item: SimpleMap<any>;
  keyName: string;
  count: number;
  imgSrc: string;
  type: EquipCategory;
}

type CategorizedInventory = {
  categorizedItems: SimpleMap<{
    type: EquipCategory,
    items: IInventoryItem[],
  }>,
  equippedItems: IInventoryItem[],
};

export type EquipPanelProps = {
  token: NftMetadata;
  isStaked: boolean;
  isUnassigned: boolean;
}

/// UTIL
const getCraftableInfo = (
  itemName: string,
  storeCraftables: SimpleMap<ZOMGCraftItem[]>
): ZOMGCraftItem => {
  let craftInfo: ZOMGCraftItem = {} as ZOMGCraftItem;
  if (!Object.keys(storeCraftables).length || !itemName.length) return craftInfo;
  const { All } = storeCraftables;
  if (!All.length) return craftInfo;

  const craftableIdx: number = All.findIndex(({ name }) => name === itemName);
  if (craftableIdx < 0) return craftInfo;

  craftInfo = All[craftableIdx];
  return craftInfo;
}

/// UTIL
const getResourceInventory = (
  resources: Resources,
  storeCraftables: SimpleMap<ZOMGCraftItem[]>,
  collatedInventory: CategorizedInventory,
): CategorizedInventory => {
  // INVENTORY RESOURCES
  const {
    gems,
    ordnances,
    crackedGems,
  } = resources;

  // /// EXIT 1 - CAN'T LOAD RESOURCES
  // if (!Object.keys(gems).length || !Object.keys(ordnances).length) {
  //   // // Reset memomized states to default
  //   // setSelectedItems(currentSelected);
  //   // setSelectableItems(currentSelectables);

  //   logger("debug-equip", "EquipPanel/inventory", "EXIT 1 - CAN'T LOAD RESOURCES", {
  //     gemCount: Object.keys(gems).length,
  //     ordnanceCount: Object.keys(ordnances).length,
  //   })

  //   return collatedInventory;
  // }

  // Convert gem to IInventoryItems, append to categorizedInventory
  const gemItems: IInventoryItem[] = Object.entries(gems ?? {}).reduce((list: IInventoryItem[], [tier, affinityGems]) => ([
    ...list,
    ...Object.entries(affinityGems).map(([affinity, gemIds]) => {
      const currentGem: Gem = GEMS[tier][affinity];
      const iconSrc: string = currentGem.icon;
      const keyName: string = tier + "-" + currentGem.name;
      const equipType = EquipType[EquipType.Gem];
      const type = EQUIP_CATEGORIES[equipType];

      const gemItem: IInventoryItem = {
        item: {
          currentGem,
          gem: affinity,
          tier,
          ids: gemIds,
        },
        keyName,
        count: gemIds.length,
        imgSrc: iconSrc,
        type,
      };
      collatedInventory.categorizedItems[equipType].items = [...(collatedInventory.categorizedItems[equipType]?.items ?? []), gemItem];
      return gemItem;
    })
  ]), []);

  const crackedGemItems: IInventoryItem[] = Object.entries(crackedGems ?? {}).reduce((list: IInventoryItem[], [tier, affinityGems]) => ([
    ...list,
    ...Object.entries(affinityGems).map(([affinity, gemIds]) => {
      const currentGem: Gem = GEMS[tier][affinity];
      const iconSrc: string = currentGem.iconCracked ?? currentGem.icon;
      const keyName: string = tier + "-cracked-" + currentGem.name;
      const equipType = EquipType[EquipType.Gem];
      const type = EQUIP_CATEGORIES[equipType];

      const gemItem: IInventoryItem = {
        item: {
          currentGem,
          gem: affinity,
          tier,
          ids: gemIds,
        },
        keyName,
        count: gemIds.length,
        imgSrc: iconSrc,
        type,
      };
      collatedInventory.categorizedItems[equipType].items = [...(collatedInventory.categorizedItems[equipType]?.items ?? []), gemItem];
      return gemItem;
    })
  ]), []);

  // Convert ordnances to IInventoryItems, append to categorizedInventory
  const ordnanceItems: IInventoryItem[] = Object.values(ordnances ?? {}).reduce((list: IInventoryItem[], ordnance: OwnedOrdnance) => {
    const equipType = EquipType[EquipType[ordnance.attributes.Type]];

    const ordnanceName: string = ordnance.attributes.Name;
    const keyName: string = ordnanceName;
    const craftableInfo: ZOMGCraftItem = getCraftableInfo(ordnanceName, storeCraftables);

    const ordnanceItem: IInventoryItem = {
      item: {
        ids: ordnance.ids,
        ordnance: {
          ...ordnance,
          craftableInfo,
        },
      },
      keyName,
      count: ordnance.ids.length,
      imgSrc: ordnance.src,
      type: EQUIP_CATEGORIES[equipType],
    };

    collatedInventory.categorizedItems[equipType].items = [...(collatedInventory.categorizedItems[equipType]?.items ?? []), ordnanceItem];
    return [
      ...list,
      ordnanceItem,
    ];
  }, []);

  // Combine both gems and ordnances in the All category
  collatedInventory.categorizedItems['All'].items = Array.from([...gemItems, ...crackedGemItems, ...ordnanceItems]);
  return collatedInventory;
}
















export const equipTypes: string[] = Object.keys(EquipType).filter(t => isNaN(Number(t)));
export const ordnanceEquipTypes: string[] = equipTypes.filter(t => t !== EquipType[EquipType.Gem]);
export const equipLimits: SimpleMap<number> = {
  'Gems': 5,
  'Ordnances': 4,
};


const EquipPanel: FC<EquipPanelProps> = ({
  token,
  isStaked,
  isUnassigned,
}: EquipPanelProps) => {

  const toaster = useToaster();
  const dispatch = useDispatch();

  const wallet = useRedux((state) => state.wallet.wallet);
  const zomgStoreCraftables: SimpleMap<ZOMGCraftItem[]> = useRedux((state) => state.zomg.store.craftables);
  const resources: Resources = useRedux((state) => state.token.resources);

  const extendedContainerRefStat = useRef(null);

  // STATES / HOOKS -----------------

  const [zoaId, setZoaId] = useState<string>(token.id ?? '');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [loadingInventory, setLoadingInventory] = useState<boolean>(false);
  const [inventoryCategoryTab, setInventoryCategoryTab] = useState<string>('All');
  const [selectedItems, setSelectedItems] = useState<IInventoryItem[]>([]);
  const [selectableItems, setSelectableItems] = useState<IInventoryItem[]>([]);
  const [toEquipItems, setToEquipItems] = useState<IInventoryItem[]>([]);
  const [showItemDialog, setShowItemDialog] = useState<EquipProps>({} as EquipProps);
  const [runZoaEquip, loadingZoaEquip] = useAsyncTask("zoa equip", (error) => {
    toaster(error?.message ?? "Error Equipping Metazoa");
  });

  const [loadingFetchResources] = useTaskSubscriber('refetchResources');

  const isLoading: boolean = loadingZoaEquip || loadingFetchResources;
  const isSingleEquipOnly: boolean = false;
  const isUsingDialog: boolean = isSingleEquipOnly || true;
  const cannotEquip: boolean = isStaked || isUnassigned;

  const isInventoryCompleted = async () => {
    logger("loadingInventory/isInventoryCompleted/START", loadingInventory)
    const delay = 2000;
    runZoaEquip(async () => {
      await new Promise((resolve) => setTimeout(resolve, delay));
      setLoadingInventory(false);
    })
    logger("loadingInventory/isInventoryCompleted/END", loadingInventory)
  }

  // useMemo(() => {
  //   if (!token || !wallet) return;
  //   setInventoryCategoryTab('All');
  //   setSelectedItems([]);
  //   setSelectableItems([]);
  //   setToEquipItems([]);
  // }, [token, wallet]);

  const inventory: CategorizedInventory = useMemo(() => {
    // DEFAULT VALUES
    let collatedInventory: CategorizedInventory = {
      equippedItems: [],
      categorizedItems: {
        'All': {
          type: {
            type: 'All',
            icon: '',
          },
          items: [],
        },
        ...Object.values(EQUIP_CATEGORIES)
          .reduce((accum, type) => {
            accum[type.type] = {
              type,
              items: [],
            };
            return accum;
          }, {}),
      },
    };
    logger("debug-equip", "EquipPanel/inventory-START", token.id, collatedInventory, {
      zomgStoreCraftables,
      token,
      resources,
    });
    if (!token || !resources || !zomgStoreCraftables) {
      return collatedInventory;
    }

    /// RESOURCES
    collatedInventory = getResourceInventory(resources, zomgStoreCraftables, collatedInventory);

    /// TOKEN
    // Parse token equipped items into IInventoryItems
    const tokenItems: IInventoryItem[] = ((token?.equipments ?? []) as Equipment[])
      .reduce((list: IInventoryItem[], { tokenTraits, ...equipment }) => {
        const equipType: string = tokenTraits.Type;
        const type = EQUIP_CATEGORIES[equipType];
        const ids: number[] = [+(equipment.id)];

        let equippedItem: IInventoryItem = {} as IInventoryItem;
        if (equipType === EquipType[EquipType.Gem]) {
          const { Tier, Affinity } = tokenTraits
          const currentGem: Gem = GEMS[Tier][Affinity];
          const iconSrc: string = currentGem.icon;
          const keyName: string = Tier + "-" + currentGem.name;

          equippedItem = {
            item: {
              currentGem,
              gem: Affinity,
              tier: Tier,
              ids: ids,
            },
            keyName,
            count: ids.length,
            imgSrc: iconSrc,
            type,
          };
        }
        else {
          const ordnanceName: string = tokenTraits.Name;
          const ordnanceCode = ordnanceName.split('-')[0];
          const keyName: string = ordnanceName;
          const craftableInfo: ZOMGCraftItem = getCraftableInfo(ordnanceName, zomgStoreCraftables);
          const currentOrdnance: OwnedOrdnance = {
            address: equipment.address,
            name: ordnanceName,
            src: ZOrdnanceClasses[ordnanceCode],
            attributes: craftableInfo?.attributes ?? {},
            ids: ids,
          };

          equippedItem = {
            item: {
              ids,
              ordnance: {
                ...currentOrdnance,
                craftableInfo,
              },
            },
            keyName,
            count: ids.length,
            imgSrc: currentOrdnance.src,
            type: type,
          };
        }

        if (!!Object.keys(equippedItem).length) {
          // Check selectableItemIdx and only append if it doesn't already exist (w/ count = 0, ids = [])
          const selectableItemIdx: number = collatedInventory.categorizedItems[equipType].items.findIndex(({ keyName: kN }) => kN === equippedItem.keyName);
          if (selectableItemIdx < 0) {
            const emptySelectableItem: IInventoryItem = {
              ...equippedItem,
              count: 0,
              item: {
                ...equippedItem.item,
                ids: [],
              }
            };
            collatedInventory.categorizedItems[equipType].items.push(emptySelectableItem);
            collatedInventory.categorizedItems['All'].items.push(emptySelectableItem);
          }

          // Append to list
          list.push(equippedItem);
        }
        return list ?? [];
      }, []);

    // Set to memomize values
    collatedInventory.equippedItems = tokenItems;
    setSelectedItems(tokenItems.concat(toEquipItems));
    setSelectableItems(collatedInventory.categorizedItems['All'].items);
    // setToEquipItems([]);

    logger("debug-equip", "EquipPanel/inventory-END", token.id, {
      collatedInventory,
      selectedItems,
      toEquipItems,
      tokenItems,
    });

    return collatedInventory;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources, token, zomgStoreCraftables]);

  const equipChanges = useMemo(() => {
    const selectedIds: number[] = getIdsFromItems(selectedItems);
    const equippedIds: number[] = getIdsFromItems(inventory.equippedItems);

    const toEquip: number[] = selectedIds.filter(i => !equippedIds.includes(i));
    const toDequip: number[] = equippedIds.filter(i => !selectedIds.includes(i));

    // DEQUIP PRIORITY: If there's at least 1 dequip, memomize the toEquips
    if (toDequip.length && toEquip.length) {
      const toEquipItems: IInventoryItem[] = selectedItems.filter(({ item }) => item.ids.every(id => toEquip.includes(id))) ?? [];
      logger("debug-equip", "EquipPanel/equipChanges-toEquipItems", {
        selectedItems,
        toEquipItems,
      });
      setToEquipItems(toEquipItems);
    }

    return {
      hasChanges: !!(toEquip.length || toDequip.length),
      ids: {
        toEquip,
        toDequip,
      },
    };
  }, [inventory.equippedItems, selectedItems]);

  const isSubmitEnabled: boolean = useMemo(() => {
    if (!Object.keys(inventory.categorizedItems["All"].items).length || !wallet || !resources || cannotEquip) return false;

    // DIFFERENT TOKEN
    if (token.id !== zoaId) {
      logger("debug-equip", "EquipPanel/isSubmitEnabled/tokenChange", {
        zoaId,
        tokenId: token.id,
      });
      setZoaId(token.id);

      // Revert in memomized item states
      setSelectedItems(Array.from(inventory.equippedItems));
      setSelectableItems(Array.from(inventory.categorizedItems['All'].items));
      setToEquipItems([]);
      return false;
    }
    // NO CHANGE
    if (!equipChanges.hasChanges) return false;

    return true;
  }, [equipChanges.hasChanges, inventory.categorizedItems, inventory.equippedItems, cannotEquip, resources, token.id, wallet, zoaId]);

  // FUNCTIONS ----------------------

  const revertSelection = () => {
    if (!isSubmitEnabled || isLoading) return;

    // Revert in memomized item states
    setSelectedItems(Array.from(inventory.equippedItems));
    setSelectableItems(Array.from(inventory.categorizedItems['All'].items));
    setToEquipItems([]);
  }

  const reloadToken = async () => {
    if (!isSubmitEnabled || isLoading) return;

    setLoadingInventory(true);
    dispatch(actions.Token.refetchResource());
    dispatch(actions.Token.reloadTokens());
    await TBMConnector.reloadMetazoaMetadata([token.id]);
    logger("debug-component", "EquipPanel/reloadToken", "RELOADED");

    if (toEquipItems.length) {
      setSelectedItems(toEquipItems);
      setToEquipItems([]);
    } else {
      revertSelection();
    }
    isInventoryCompleted();
  }

  const equipZoa = async (
    toParent: boolean = true,
  ) => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!isSubmitEnabled || isLoading) throw new Error("Requirements are not met");

    const transferEquipment = async (
      itemIds: number[],
      toParent: boolean = true,
    ) => {
      const zoaEquipTx = await TBMConnector.transferEquipment(+(token.id), itemIds, toParent);
      toaster(`Submitted Equipments`, { hash: zoaEquipTx.id! });
      if (zoaEquipTx.isRejected() || !zoaEquipTx.id) {
        revertSelection();
        throw new Error("Submitted transaction was rejected.");
      }

      const tx = await waitForTx(zoaEquipTx.id);
      const tbmConnector = TBMConnector.getSDK();
      const txn = await tbmConnector.zilliqa.blockchain.getTransaction(zoaEquipTx.id);
      const receipt = txn.getReceipt();
      if (!receipt || !receipt?.success || tx.status >= 3) {
        revertSelection();
        throw new Error("Submitted transaction was unsuccessful");
      }
      toaster(`Items Successfully ${toParent ? 'Equipped' : 'Dequipped'}`, { hash: zoaEquipTx.id!, overridePersist: true })
    }

    const changedIdListKey: string = toParent ? 'toEquip' : 'toDequip';
    const itemIds: number[] = Array.from(equipChanges.ids?.[changedIdListKey] ?? []);
    if (!itemIds.length) throw new Error("Requirements are not met");

    await transferEquipment(itemIds, toParent);
    if (toParent) setToEquipItems([]);

    /// SUCCESS
    await reloadToken();
  }

  // EVENT HANDLERS -----------------

  const handleOnRevert = () => {
    if (!isSubmitEnabled || isLoading) return;
    revertSelection();
  }

  const handleOnSave = (toParent: boolean = true) => {
    logger("debug-dialog", "EquipPanel/handleOnSave", `token: ${token.id}`, {
      toParent,
      isSubmitEnabled,
      equipChanges,
    });

    if (!isSubmitEnabled || isLoading) return;
    runZoaEquip(async () => {
      await equipZoa(toParent);
      if (isUsingDialog && showDialog) handleDialogClose();
    })
  }

  const handleDialogClose = () => {
    if (isLoading) return;
    // revertSelection();
    setShowItemDialog({} as EquipProps);
    setShowDialog(false);

    if (isSingleEquipOnly) revertSelection();
  }

  const handleDialogConfirm = () => {
    if (!isSubmitEnabled || !Object.keys(showItemDialog).length) return;
    logger("debug-dialog", "EquipDND.Dialog/handleDialogConfirm", showItemDialog)
    handleOnSave(showItemDialog.toEquip);
  }

  const handleDialogShow = (
    toParent: boolean = true,
  ) => {
    if (!isUsingDialog) {
      handleOnSave(toParent);
      return;
    }

    if (!isSingleEquipOnly) {
      const currItems: IInventoryItem[] = toParent
        ? selectedItems.filter(({ item }) => item.ids.every(id => equipChanges.ids.toEquip.includes(id))) ?? []
        : inventory.equippedItems.filter(({ item }) => item.ids.every(id => equipChanges.ids.toDequip.includes(id))) ?? [];
      setShowItemDialog({
        ...showItemDialog,
        currItems,
        toEquip: toParent,
      })
    }
    setShowDialog(true);
  }

  const handleTabChange = (event: React.SyntheticEvent, tabCategory: string) => {
    if (!Object.keys(inventory.categorizedItems).includes(tabCategory)) return;
    setInventoryCategoryTab(tabCategory);
  };

  const handleEquipDrop = useCallback(
    (dItem: IInventoryItem) => {
      if (!Object.keys(dItem).length || isLoading) return;
      const transferResult: SimpleMap<IInventoryItem[]> = transferDropItem(
        dItem,
        selectableItems,
        selectedItems,
      );

      logger("debug-equip", "EquipPanel/handleInventoryDrop", {
        selectableItems,
        selectedItems,
        transferResult: {
          ...transferResult,
          dst: transferResult.dst.filter(({ count }) => count > 0)
        },
      });

      /// TRANSFER
      setSelectedItems(transferResult.dst.filter(({ count }) => count > 0));
      setSelectableItems(transferResult.src);

      setShowItemDialog({
        currItem: dItem,
        currItems: [],
        toEquip: true,
      })
    }, [isLoading, selectableItems, selectedItems]);

  const handleInventoryDrop = useCallback(
    (dItem: IInventoryItem) => {
      if (!Object.keys(dItem).length || isLoading) return;
      let options: SimpleMap<boolean> = {
        stacking: true,
      };
      const transferResult: SimpleMap<IInventoryItem[]> = transferDropItem(
        dItem,
        selectedItems,
        selectableItems,
        options,
      );

      logger("debug-equip", "EquipPanel/handleInventoryDrop", {
        selectableItems,
        selectedItems,
        transferResult: {
          ...transferResult,
          src: transferResult.src.filter(({ count }) => count > 0)
        },
      });

      /// TRANSFER
      setSelectedItems(transferResult.src.filter(({ count }) => count > 0));
      setSelectableItems(transferResult.dst);

      setShowItemDialog({
        currItem: dItem,
        currItems: [],
        toEquip: false,
      })
    }, [isLoading, selectableItems, selectedItems]);

  // --------------------------------

  useMemo(() => {
    logger("loadingInventory", loadingInventory)
  }, [loadingInventory])

  if (!token) return null;

  return (
    <DND.Provider>
      <Box sx={styles.providerArea}>
        <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Equipped items</Typography>

        <Box component="section">
          {/* EQUIP */}
          <Box component="main">
            <Box component="section">
              <StripeHeader sx={styles.stripeTitle}>
                Z-Ordnance
              </StripeHeader>

              <EquipDND.DroppableGroup
                id='droppable-equip-ordnances'
                items={selectedItems}
                limit={equipLimits.Ordnances}
                acceptableTypes={ordnanceEquipTypes}
                onDrop={handleEquipDrop}
                options={{
                  hasPlaceholder: false,
                  isRow: false,
                  hasUniqueTypes: true,
                  hasMiniTypeIcon: true,
                  lockDrop: cannotEquip || isLoading,
                  isLoading: loadingInventory,
                }}
                sx={styles.ordnanceDroppableGroup}
              />
            </Box>

            <Box component="section">
              <StripeHeader sx={styles.stripeTitle}>
                Gems
              </StripeHeader>

              <EquipDND.DroppableGroup
                id='droppable-equip-gems'
                items={selectedItems}
                limit={equipLimits.Gems}
                acceptableTypes={['Gem']}
                onDrop={handleEquipDrop}
                options={{
                  lockDrop: cannotEquip || isLoading,
                  isLoading: loadingInventory,
                }}
              />
            </Box>
          </Box >

          <Grow
            unmountOnExit
            in={(loadingInventory)}
            style={{ transformOrigin: '0 0 0' }}
            {...(isSubmitEnabled && { timeout: 1000 })}
          >
            <Box>
              <WarningBox sx={styles.warningBox} showIcon={false}>
                <CircularProgress size={18} />&nbsp;
                <Typography variant="body1" color="primary" align="left" sx={styles.equipWarningBox}>
                  Fetching the latest inventory items
                </Typography>
              </WarningBox>
            </Box>
          </Grow>

          <Grow
            unmountOnExit
            in={((isSubmitEnabled || isLoading) && !loadingInventory)}
            style={{ transformOrigin: '0 0 0' }}
            {...(isSubmitEnabled && { timeout: 1000 })}
          >
            <Box>
              <WarningBox sx={styles.warningBox}>
                <Typography variant="body1" color="primary" align="left" sx={styles.equipWarningBox}>
                  When the Metazoa is sold, the equipped items will be transfered alongside it.
                </Typography>
              </WarningBox>

              {!isSingleEquipOnly && (
                <Box
                  sx={combineStyles(
                    styles.buttonBox,
                    styles.batchButtonBox,
                  )}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={styles.cancelButton}
                    onClick={handleOnRevert}
                    disabled={!isSubmitEnabled || isLoading}
                    disableFocusRipple
                  >
                    Revert
                  </Button>

                  {!!equipChanges.ids.toEquip.length
                    &&
                    !equipChanges.ids.toDequip.length
                    && (
                      < ContainedButton
                        sx={styles.confirmButton}
                        disabled={!isSubmitEnabled || isLoading}
                        // onClick={() => handleOnSave()}
                        onClick={() => handleDialogShow()}
                      // onClick={handleDialogConfirm}
                      >
                        {isLoading
                          ? <CircularProgress size={18} />
                          : `Equip (${equipChanges.ids.toEquip.length})`
                        }
                      </ContainedButton>
                    )}

                  {!!equipChanges.ids.toDequip.length && (
                    <ContainedButton
                      sx={styles.confirmButton}
                      disabled={!isSubmitEnabled || isLoading}
                      // onClick={() => handleOnSave(false)}
                      onClick={() => handleDialogShow(false)}
                    // onClick={handleDialogConfirm}
                    >
                      {isLoading
                        ? <CircularProgress size={18} />
                        : `Unequip (${equipChanges.ids.toDequip.length})`
                      }
                    </ContainedButton>
                  )}
                </Box>
              )}
            </Box>
          </Grow>

          {/* INVENTORY */}
          <Box sx={combineStyles(styles.statDetailsWrapper, styles.extendedWrapper, {
            top: '-1px',
            left: '415px',
          })}>
            <Slide direction="right" in={true} timeout={1000} container={extendedContainerRefStat.current}>
              <Box sx={styles.statDetailsContainer}>
                <StatPanel sx={combineStyles(styles.frameBorder, {
                  minHeight: '320px',
                  display: 'flex',
                  flexDirection: 'column',
                })}>
                  <Tabs
                    value={inventoryCategoryTab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    sx={combineStyles(
                      styles.inventoryTabs,
                    )}
                  >
                    {Object.entries(inventory.categorizedItems)
                      .map(([label, category], idx) => (
                        <Tab
                          disableRipple
                          key={idx}
                          aria-label={label}
                          {...!!category.type.icon.length
                            ? {
                              icon: (
                                <Box
                                  component="img"
                                  src={category.type.icon}
                                />
                              )
                            }
                            : {
                              label: label,
                            }
                          }
                          value={label}
                          sx={{
                            color: "rgba(254, 254, 254, 0.4)",
                            fontSize: "12px",
                            padding: '2px',
                          }}
                        />
                      ))}
                  </Tabs>

                  <Box sx={{
                    height: '100%',
                    flex: '1',
                    ...styles.scrollableFrame,
                  }}>
                    <TabPanel height="100%" index={0} value={0}>
                      {Object.keys(inventory.categorizedItems).includes(inventoryCategoryTab) && (
                        <EquipDND.DroppableArea
                          id={`droppable-inventory-${inventoryCategoryTab}`}
                          items={selectableItems}
                          acceptableTypes={inventoryCategoryTab === 'All' ? Object.keys(inventory.categorizedItems) : [inventory.categorizedItems[inventoryCategoryTab].type.type]}
                          limit={inventory.categorizedItems[inventoryCategoryTab].items.length + inventory.equippedItems.length}
                          onDrop={handleInventoryDrop}
                          options={{
                            lockDrop: cannotEquip || isLoading,
                          }}
                        />
                      )}
                    </TabPanel>
                  </Box>

                  {cannotEquip && (
                    <>
                      {isStaked && (
                        <Box sx={styles.stakedBox}>
                          <WarningRed width={15} />
                          <Typography variant="body1" color="error.main" className='stakedWarningText'>
                            Metazoa needs to be unstaked to equip/dequip items
                          </Typography>
                        </Box>
                      )}
                      {isUnassigned && (
                        <Box sx={styles.stakedBox}>
                          <WarningRed width={15} />
                          <Typography variant="body1" color="error.main" className='stakedWarningText'>
                            Metazoa needs to be assigned to a profession to equip/dequip items
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </StatPanel>
              </Box>
            </Slide>
          </Box>

          {/* DIALOG */}
          {isSingleEquipOnly && isSubmitEnabled && !!Object.keys(showItemDialog).length && (
            <EquipDND.Dialog
              token={token}
              toEquip={showItemDialog.toEquip}
              currItem={showItemDialog.currItem}
              open={!!Object.keys(showItemDialog).length}
              onClose={handleDialogClose}
              onConfirm={handleDialogConfirm}
              isLoading={isLoading}
            />
          )}
          {!isSingleEquipOnly && isSubmitEnabled && !!Object.keys(showItemDialog).length && (
            <EquipDND.BatchDialog
              token={token}
              toEquip={showItemDialog.toEquip}
              currItems={showItemDialog.currItems}
              open={showDialog}
              onClose={handleDialogClose}
              onConfirm={handleDialogConfirm}
              isLoading={isLoading}
            />
          )}
        </Box>
      </Box >
    </DND.Provider >
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  providerArea: {
    position: 'relative',
    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    border: '1px solid #AEF1EE',
    borderLeft: 'none',

    padding: '16px 20px',
    width: '416px',
  },
  stripeTitle: {
    textAlign: 'center',
    // paddingY: '5px',
    // marginY: '25px',
    transform: 'skewX(-25deg)',
    background: 'repeating-linear-gradient(90deg, #AEF1EE33, #AEF1EE33 36px, transparent 36px, transparent 44px)',
    // margin: '0 auto',
    mx: 'auto',
    width: '100%',
    "& h2": {
      ...GUILD_GRAY_GRADIENT,
      fontSize: '18px',
      lineHeight: 'initial',
      background: "linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)"
    }
  },

  statDetailsHeader: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    marginBottom: '8px',
  },

  frameBorder: {
    border: '12px double transparent',
    // backgroundClip: 'padding-box',
    borderImage: `url(${PanelFrame})`,
    borderImageSlice: '20',
    borderImageWidth: '20px 20px 20px 20px',
    borderImageOutset: '0px 0px 0px 0px',
    borderImageRepeat: 'stretch stretch',
    padding: '10px 10px 8px 10px',
    background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
  },

  scrollableFrame: {
    maxHeight: '350px',

    msOverflowStyle: 'none',
    overflowY: 'scroll',

    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    scrollSnapType: "y mandatory",
    scrollSnapPointsY: "repeat(3rem)",
    scrollPaddingTop: '5em',
    scrollPaddingBottom: '5em',
    '::-webkit-scrollbar': {
      display: 'none'
    },

    '> *': {
      scrollSnapAlign: 'start',
    },
  },

  ordnanceDroppableGroup: {
    flexDirection: 'column',
    placeContent: 'start',
    maxHeight: '230px',
    padding: 0,
  },

  /// WARNING BOX
  warningBox: {
    transition: "opacity 1s ease-out",
    width: '100%',
    // marginBottom: '44px',
    marginTop: '20px',
    "> div": {
      padding: "16px 40px",
    }
  },
  equipWarningBox: {
    fontSize: '12px',
    lineHeight: '18px',
    ...GUILD_LIGHTGRAY_GRADIENT,
  },

  /// ACTION BTNS
  batchButtonBox: {
    display: 'flex',
    width: '100%',
    placeContent: 'space-between',
  },
  buttonBox: {
    marginTop: "16px",
    "> *": {
      height: 40,
      fontSize: '1rem!important',
    },

    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      rowGap: 2,

    }
  },
  confirmButton: {
    whiteSpace: 'nowrap',
  },
  cancelButton: {
    borderRadius: "16px",
    textTransform: "uppercase",
    paddingX: '16px',
  },


  // INVENTORY
  statDetailsWrapper: {
    overflow: 'hidden',
    position: 'absolute',
    top: '25px',
    left: '536px',
  },
  extendedWrapper: {
    zIndex: '-10',
    top: '47px',
    left: '855px',
  },
  statDetailsContainer: {
    position: 'relative',
    minWidth: '325px',
    maxWidth: '370px',
  },
  inventoryTabs: {
    paddingBottom: '1em',
    maxWidth: '100%',

    '.MuiTab-root': {
      minWidth: 'fit-content',
    },

    '& .MuiTabs-indicator': {
      backgroundColor: '#AEF1EE',
      background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
      paddingY: '2px',
    },
  },

  stakedBox: {
    display: "flex",
    flexDirection: "row",
    placeContent: 'start',
    placeItems: 'center',
    gap: 2,
    pt: '27px',
    mt: 'auto',

    '.stakedWarningText': {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
    },
  },
};

export default EquipPanel;