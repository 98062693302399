import { Box, ClickAwayListener, Paper, Popper, SxProps, Typography } from "@mui/material";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { EquipCategory, EquipRequirements, EquipType, GemTier } from "components/Metazoa/ResourceConstants";
import { IDroppable } from "components/Metazoa/ResourcesDialogs/components/GemsDND/Droppable";
import { getBonusRequirement, getProfession } from "components/Quest/Map/Location/ZOMG/CraftZOrdnanceDialog";
import { DEFAULT_SRC } from "components/Quest/QuestConstants";
import { logger } from "core/utilities";
import { FC, useState } from "react";
import { useDrag } from "react-dnd";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import { STATS, StatType } from "../../MetazoaConstants";
import { IInventoryItem } from "./EquipPanel";

/// DRAGGABLE
interface EquipDraggableProps extends IInventoryItem {
  options?: SimpleMap<boolean>;
};

export const invalidDragType: EquipCategory = {
  type: 'Invalid',
  icon: '',
}

const EquipDraggable: FC<EquipDraggableProps> = ({
  item = {},
  keyName = '',
  count = 0,
  imgSrc = '',
  type = invalidDragType,
  options = {
    showCount: true,
    lockDrop: false,
    showCopyIcon: true,
  }
}: EquipDraggableProps) => {

  options = {
    showCount: true,
    lockDrop: false,
    showCopyIcon: true,
    ...options,
  }
  const isValidItem: boolean = count > 0 && (!!Object.keys(item).length);

  const [{ isDragging, }, drag] = useDrag(() => ({
    type: !isValidItem ? invalidDragType.type : type.type,
    item: {
      item,
      keyName,
      count,
      imgSrc,
      type,
    },
    canDrag: isValidItem && !options.lockDrop,
    options: {
      dropEffect: options.showCopyIcon ? 'copy' : 'move',
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<IDroppable>()
      if (dropResult) logger(`Acceptable: ${JSON.stringify(dropResult?.acceptableTypes ?? [])}`)
      if (item && dropResult) {
        logger(`Dropped ${type.type} into ${dropResult.id}\n`)
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }), [isValidItem, options.showCopyIcon]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    if (!isValidItem) return;
    setAnchorEl(anchorEl ? null : event?.currentTarget ?? null);
  };

  const getPopperContent = () => {
    if (!isValidItem) return null;
    const isOrdnance: boolean = type.type !== EquipType[EquipType.Gem];
    const itemName: string = keyName.split('-')[1];

    if (isOrdnance && !!Object.keys(item?.ordnance?.craftableInfo ?? {}).length)
      return (
        <Box sx={styles.textContainer}>
          <Box sx={styles.textColumn}>
            {/* NAME */}
            <Box sx={styles.textBox}>
              {!!itemName &&
                <Box sx={styles.textBox}>
                  <Typography variant="body1" color="success.main" sx={combineStyles(
                    styles.textWrapper,
                    {
                      fontWeight: '600',
                      fontSize: '14px',
                      color: '#AEF1EE',
                    }
                  )}>
                    {itemName}
                  </Typography>
                </Box>
              }
            </Box>

            {/* EQUIP REQUIREMENTS */}
            {false && !!Object.keys(item?.ordnance?.craftableInfo?.requirement ?? {}).length && (
              <Box sx={styles.textBox}>
                <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                  Equip Requirement
                </Typography>
                {Object.entries((item?.ordnance?.craftableInfo?.requirement ?? {}) as SimpleMap)
                  .map(([requirement, level]) => (
                    <Typography variant="body1" color="success.main" sx={styles.textWrapper} key={requirement}>
                      {(requirement.toLowerCase() === 'Profession'.toLowerCase())
                        ? (`${EquipRequirements[requirement]} ${getProfession(level)}`)
                        : (`${EquipRequirements[requirement]} ${level}`)
                      }
                    </Typography>
                  ))}
              </Box>
            )}

            {/* DAMAGE */}
            <Box sx={styles.textBox}>
              {!!item?.ordnance?.attributes?.Damage &&
                <Box sx={styles.textBox}>
                  <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                    Damage
                  </Typography>
                  <Typography variant="body1" color="success.main" sx={styles.textWrapper}>
                    +{item?.ordnance?.attributes?.Damage}&nbsp;Melee
                  </Typography>
                </Box>
              }
            </Box>


            {/* BONUS STAT */}
            <Box sx={styles.textBox}>
              {!!Object.keys(item?.ordnance?.craftableInfo?.stats?.default ?? {}).length && (
                <Box sx={styles.textBox}>
                  <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                    Bonus Stat
                  </Typography>
                  <Box sx={styles.statGroup}>
                    {Object.entries((item?.ordnance?.craftableInfo?.stats?.default ?? {}) as SimpleMap<number>)
                      .filter(([, point]) => !!point)
                      .map(([stat, point]) => (
                        <Typography
                          variant="body1"
                          color="success.main"
                          sx={styles.textWrapper}
                          component="span"
                          key={stat}
                        >
                          <Box component="img" src={STATS[stat].icon ?? ''} alt={stat} />&nbsp;<Typography component="span" variant="body1" sx={combineStyles(GUILD_LIGHTGRAY_GRADIENT, styles.textWrapper)}>{stat}</Typography>&nbsp;+{point}
                        </Typography>
                      ))}
                  </Box>
                </Box>
              )}
            </Box>

            {/* ADDITIONAL STATS */}
            {Object.entries((item?.ordnance?.craftableInfo?.stats ?? {}) as SimpleMap<SimpleMap<number>>)
              .filter(([type,]) => (type !== 'default'))
              .map(([type, stats]) => (
                <Box sx={styles.textBox} key={type}>
                  <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                    Additional Stats for {getBonusRequirement(type)}
                  </Typography>
                  <Box sx={styles.statGroup}>
                    {Object.entries(stats ?? {})
                      .filter(([stat, point]) => !(!point || point - item?.ordnance?.craftableInfo?.stats?.default?.[stat] <= 0))
                      .map(([stat, point]) => (
                        <Typography variant="body1" color="success.main" sx={styles.textWrapper} component="span" key={stat}>
                          <Box component="img" src={STATS[stat].icon} alt={stat} />&nbsp;<Typography component="span" variant="body1" sx={combineStyles(GUILD_LIGHTGRAY_GRADIENT, styles.textWrapper)}>{stat}</Typography>&nbsp;+{point - item?.ordnance?.craftableInfo?.stats?.default?.[stat]}
                        </Typography>
                      ))}
                  </Box>
                </Box>
              ))
            }
          </Box>
        </Box>
      )
    else return (
      <Box sx={styles.textContainer}>
        <Box sx={styles.textColumn}>
          {/* NAME */}
          <Box sx={styles.textBox}>
            {!!itemName &&
              <Box sx={styles.textBox}>
                <Typography variant="body1" color="success.main" sx={combineStyles(
                  styles.textWrapper,
                  {
                    fontWeight: '600',
                    fontSize: '14px',
                    color: '#AEF1EE',
                  }
                )}>
                  {itemName}
                </Typography>
              </Box>
            }
          </Box>

          {/* GEM TYPE */}
          {!!Object.keys(item?.currentGem ?? {}).length && (
            <Box sx={styles.textBox}>
              <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                Type
              </Typography>
              <Typography variant="body1" color="success.main" sx={styles.textWrapper}>
                {StatType[item?.currentGem?.type ?? ""]}
              </Typography>
            </Box>
          )}
          {/* GEM TYPE */}
          {!!(item?.tier ?? "").length && (
            <Box sx={styles.textBox}>
              <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                Tier
              </Typography>
              <Typography variant="body1" color="success.main" sx={styles.textWrapper}>
                {GemTier[item?.tier ?? ""]}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }


  return (
    <Box
      ref={drag}
      onClick={handleClick}
      className="draggable"
      data-type={type?.type ?? invalidDragType.type}
      data-dragability={isDragging}
      sx={combineStyles(styles.inventoryItemBox, {
        ...(count < 1) && styles.greyscale,
        ...(isDragging) && {
          opacity: '0.5',
          border: '1px solid #AEF1EE',
        },
      })}
    >
      <Box
        component="img"
        src={imgSrc}
        alt={keyName}
        onError={DEFAULT_SRC}
        className="inventoryItem"
        sx={{
          ...(!isValidItem || !imgSrc.length) && {
            width: '38px!important',
          },
        }}
      />

      {isValidItem && options.showCount && (
        <Typography sx={styles.inventoryItemCount}>
          {!isDragging ? count : count - 1}
        </Typography>
      )}

      {/* PREVIEW */}
      <Popper open={isValidItem && isOpen} placement="top" anchorEl={anchorEl} style={{ zIndex: 100001 }}>
        <Paper sx={styles.popperContainer}>
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Box sx={styles.popperContent}>
              {getPopperContent()}
            </Box>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  greyscale: {
    filter: 'grayscale(100%)!important',
    opacity: '0.4',
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  inventoryItemBox: {
    cursor: 'move',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    borderRadius: '8px',

    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',

    position: 'relative',
    height: '64px',
    width: '64px',

    "img.inventoryItem": {
      width: '48px',
    }
  },
  inventoryItemCount: {
    position: 'absolute',
    bottom: '0%',
    right: '10%',

    lineHeight: 'normal',
    fontSize: '12px',
    fontWeight: 600,
    ...GUILD_LIGHTGRAY_GRADIENT,
  },


  /// POPPER
  popperContainer: {
    top: '-10px',
    width: '270px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)',
    boxShadow: '0px 4px 20px 3px #00000070',
    borderRadius: '16px',
  },
  popperContent: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    placeItems: 'center',
  },

  /// POPPER CONTENT
  textContainer: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    minHeight: '150px',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginY: "20px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
    '.masked-overflow': {
      maskImage: 'none',
      WebkitMaskImage: 'none',
      overflow: 'hidden'
    },
    "@media(max-width:768px)": {
      flexDirection: 'column-reverse',
      gap: '1.5em',
      padding: '20px',
    }
  },
  textColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    "@media(max-width:768px)": {
      width: '100%',
    }
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: 2,
  },
  statGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: '1.5em',
    rowGap: '0.5em',
  },
}

export default EquipDraggable;