import { Box, Button, CircularProgress, Divider, FormHelperText, IconButton, Link, SvgIcon, SxProps, TextField, Typography } from "@mui/material";
import { AddIcon, ExternalLink, RemoveIcon, WarningRed } from "assets";
import XPIcon from "assets/icons/XpIcon.svg";
import ItemFrame from 'assets/ItemFrame.svg';
import ElderberryJuiceGlow from "assets/quests/Locations/ElderberryJuiceGlow.svg";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { LOCATIONS, MAP_LOCATIONS } from "components/Quest/QuestConstants";
import WarningBox from "components/WarningBox";
import { logger, waitForTx } from "core/utilities";
import { FC, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { NftMetadata } from "store/types";
import { TBMConnector } from "tbm";
import { theme } from "theme";
import { useAsyncTask, useRedux, useTaskSubscriber, useToaster } from "utils";
import { BIG_ZERO } from "utils/constants";
import { bnOrZero, formatIntegerNumber, getExplorerLink } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

interface JuiceConsumptionProps extends ModalProps {
  token: NftMetadata
}

const initialFormState = {
  juice: BIG_ZERO,
  exp: BIG_ZERO
}

export const perJuiceExp = 10;

const JuiceConsumptionDialog: FC<JuiceConsumptionProps> = ({
  open,
  onClose,
  token
}: JuiceConsumptionProps) => {

  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();

  const wallet = useRedux((state) => state.wallet.wallet)
  const harvestZoas = useRedux((state) => state.quest.harvestZoas)
  const tokenState = useRedux((state) => state.token);

  const location: string = (token.location ?? 'Unknown')
  const missionGround: string = Object.keys(LOCATIONS).find(key => LOCATIONS[key].name === location) ?? "";
  const isValidLocation: boolean = MAP_LOCATIONS.includes(missionGround) ?? false;
  const isHarvestZoa: boolean = harvestZoas.includes(token.id);

  const {
    resources,
    // HunyTokens,
  } = tokenState;
  const {
    consumables,
  } = resources;

  const juiceIds: number[] = useMemo(() => {
    let juices: number[] = [];
    if (!Object.keys(consumables) || !consumables) return juices;
    juices = Object.entries(consumables).find(([k,]) => k === "Elderberry Juice")?.[1] ?? [];

    return juices;
  }, [consumables]);

  logger("debug-juice", {
    location: location,
    isValidLocation,
    missionGround,
    harvestZoas,
    isHarvestZoa,
    toBeAdded: !isHarvestZoa && isValidLocation
  })

  // STATES / HOOKS -----------------

  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [errors, setErrors] = useState<string>("");


  const [consumeTxHash, setConsumeTxHash] = useState<string>("");
  const [consumeJuiceComplete, setConsumeJuiceComplete] = useState<boolean>(false);
  const [runConsumeJuice, loadingConsumeJuice] = useAsyncTask("consumingJuice", (error) => {
    toaster(error?.message ?? "Error Consuming Juice");
  });
  const [loadingFetchResources] = useTaskSubscriber('refetchResources');

  const isLoading: boolean = loadingConsumeJuice || loadingFetchResources;

  const isSubmitEnabled = useMemo(() => {
    if (!wallet || isLoading || !consumables || !juiceIds.length) return false;

    setErrors("");

    if (bnOrZero(formState.juice).lte(0)) return false;
    if (bnOrZero(formState.juice).lte(0) || bnOrZero(formState.juice).gt(bnOrZero(juiceIds.length))) {
      setErrors("Insufficient Elderberry Juice")
      return false
    }

    setErrors("")
    return true
  }, [consumables, formState.juice, isLoading, juiceIds, wallet])

  // FUNCTIONS ----------------------

  const revertToInitialState = () => {
    if (consumeJuiceComplete) dispatch(actions.Token.refetchResource());
    setFormState(initialFormState);
    setErrors('');
    setConsumeTxHash('');
    setConsumeJuiceComplete(false);
  };

  const consumingJuice = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!isSubmitEnabled) throw new Error(`Requirements not met`);

    const juiceQty: number = formState.juice.toNumber();
    const consumeJuiceIds: string[] = (juiceIds.slice(0, juiceQty)).map((i) => i.toString());

    const consumeJuiceTx = await TBMConnector.consumeElderberryJuice(Array(juiceQty).fill(token.id), consumeJuiceIds);
    toaster(`Submitted Juice Consumption`, { hash: consumeJuiceTx.id! });
    if (consumeJuiceTx.isRejected() || !consumeJuiceTx.id) throw new Error("Submitted transaction was rejected");

    try {
      const tx = await waitForTx(consumeJuiceTx.id);
      const tbmConnector = TBMConnector.getSDK();
      const txn = await tbmConnector.zilliqa.blockchain.getTransaction(consumeJuiceTx.id);
      const receipt = txn.getReceipt();
      if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

      setConsumeTxHash(consumeJuiceTx.id);
      dispatch(actions.Token.refetchResource());
      await TBMConnector.reloadMetazoaMetadata([token.id]);
      dispatch(actions.Token.reloadTokens());
      if (!isHarvestZoa && isValidLocation) dispatch(actions.Quest.setHarvestZoas([...harvestZoas, token.id]));
      setConsumeJuiceComplete(true);
    } catch (e) {
      console.error(e);
      throw e
    }
  }

  // EVENT HANDLERS -----------------

  const handleOnClose = () => {
    if (isLoading) return;
    revertToInitialState();
    onClose();
  }

  const juiceInputHandler = (juiceInput: string) => {
    const bnJuice = bnOrZero(juiceInput);
    const exp = bnJuice.times(perJuiceExp);

    setFormState({
      juice: bnJuice,
      exp: exp,
    })
  };

  const onJuiceInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    juiceInputHandler(event.target.value);
  };

  const handleMaxQty = () => {
    juiceInputHandler(bnOrZero(juiceIds.length).toNumber().toFixed(0))
  }

  const handleSubtractQty = () => {
    if (formState.juice.isLessThanOrEqualTo(0))
      return;

    let resourceQty: BigNumber = formState.juice.minus(1);
    juiceInputHandler(resourceQty.toString(10));
  };

  const handleAddQty = () => {
    let resourceQty: BigNumber = formState.juice.plus(1);
    juiceInputHandler(resourceQty.toString(10));
  };

  const handleConsumeJuice = () => {
    runConsumeJuice(async () => {
      await consumingJuice();
    })
  }

  // --------------------------------

  const getHeader = () => {
    if (consumeJuiceComplete && consumeTxHash && !isLoading) return "Consumption Success!"
    else return "Consume Elderberry Juice"
  }

  const getContent = () => {
    if (consumeJuiceComplete && consumeTxHash && !isLoading) {
      return (
        <>
          {/* Warning box */}
          <WarningBox>
            <Typography variant="body1" color="primary" align="left">
              Your Metazoa’s Boosts will only be applied after you’ve harvested all
              existing resources once.
              {" "}
              {/* //TODO: Update docs link */}
              <Link
                target="_blank"
                href="https://docs.zolar.io/overview/creatures/collection-ii-metazoa/summoning#phase-2-or-the-transcendence"
              >
                Learn More
              </Link>
            </Typography>
          </WarningBox>

          <Box sx={styles.contentBox}>
            <Typography variant="body1" color="primary" sx={styles.dialogFont} px={6}>
              Your Metazoa has gained the following boost:
            </Typography>
            <Box sx={combineStyles(styles.emptyItemBox)}>
              <Box component="img" src={XPIcon} alt="XP" height="50px" />
              {/* Update image with gem design / empty geode */}
              <Box sx={styles.gemAmtTag}>
                <Typography sx={styles.gemAmt} color="primary">
                  {formatIntegerNumber(bnOrZero(formState.exp).toNumber())}
                </Typography>
              </Box>
            </Box>
            <Link
              target="_blank"
              href={getExplorerLink("tx", consumeTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>
            <ContainedButton
              onClick={handleOnClose}
              sx={{ marginTop: '10px' }}
            >
              Back to profile
            </ContainedButton>
          </Box>
        </>
      );
    }
    return (
      <>
        {/* Warning box */}
        <WarningBox>
          <Typography variant="body1" color="primary" align="left">
            Your Metazoa’s Boosts will only be applied after you’ve harvested all
            existing resources once.
            {" "}
            {/* //TODO: Update docs link */}
            <Link
              target="_blank"
              href="https://docs.zolar.io/overview/creatures/collection-ii-metazoa/summoning#phase-2-or-the-transcendence"
            >
              Learn More
            </Link>
          </Typography>
        </WarningBox>

        <Box sx={styles.contentBox}>
          <Typography variant="body1" color="primary" sx={styles.dialogFont}>
            Juice up 10 Elderberries for one bottle of Elderberry Juice to level-up your Metazoas!
          </Typography>

          <Typography variant="h2" color="success.main" component="span" sx={styles.rowBerry}>
            <Box
              component="img"
              src={ElderberryJuiceGlow}
              alt="Elderberry Juice"
              height="50px"
            />
            &nbsp;1
            <Typography variant="h2" color="primary" component="span">&nbsp;=&nbsp;</Typography>
            {perJuiceExp}&nbsp;
            <Box
              component="img"
              src={XPIcon}
              alt="XP"
              height="50px"
            />
          </Typography>

          <Divider sx={styles.divider} />

          <Box sx={styles.row}>
            <Typography variant="subtitle1" color="primary" sx={styles.biggerText}>Refine</Typography>
            <Box width="50%">
              <Box sx={combineStyles(styles.input, styles.numInputBox,
                { ...(errors) && styles.errorInput }
              )}>

                <IconButton disabled={loadingConsumeJuice} onClick={handleSubtractQty}>
                  <RemoveIcon style={{ height: 16, width: 16 }} />
                </IconButton>

                <Box sx={styles.numInput}>

                  <TextField
                    id="elderberry"
                    name="elderberry"
                    onChange={onJuiceInputChange}
                    disabled={loadingConsumeJuice}
                    value={bnOrZero(formState.juice.toFixed(0)).toString()}
                    sx={combineStyles(styles.input, styles.settingNumInput, {
                      flex: 0.5,
                      '.Mui-disabled': {
                        color: '#fff',
                        'WebkitTextFillColor': '#fff !important',
                      }
                    }, {
                      ...(formState.juice.gte(10000)) && ({
                        flex: `calc(0.6 + ${(Number(formState.juice.toString().length) - 5) / 10})`,
                        "& input": { textAlign: "start" },
                      })
                    })} />
                  <Box
                    component="img"
                    src={ElderberryJuiceGlow}
                    alt="Elderberry Juice"
                    height="24px"
                  />
                </Box>

                <IconButton disabled={loadingConsumeJuice} onClick={handleAddQty}>
                  <AddIcon style={{ height: 16, width: 16 }} />
                </IconButton>
              </Box>
              {!!errors &&
                <Box sx={styles.errorTextContainer}>
                  <WarningRed width={15} />
                  <FormHelperText sx={styles.errorText}>{errors}</FormHelperText>
                </Box>}
            </Box>
          </Box>

          <Box sx={combineStyles(styles.row, { justifyContent: 'flex-end', marginTop: '10px' })}>
            <Typography variant="body1" color="primary">Max: {juiceIds.length}</Typography>
            <Button variant="outlined" color="secondary" sx={styles.button} onClick={handleMaxQty}>
              <Typography sx={styles.gradientText}>Max</Typography>
            </Button>
          </Box>

          <Divider sx={styles.divider} />

          {/* <Box sx={styles.feeRow}>
            <Typography variant="body1" color="primary" component="span">
              Elderberry Refining Fee
            </Typography>
            <Typography variant="body1" color="success.main" component="span" fontWeight={600}>
              {formState.craftCost.toFixed(0)}&nbsp; HUNY &nbsp;
              <SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" />
            </Typography>
          </Box> */}

          <Typography variant="body1" color="primary" sx={combineStyles(styles.dialogFont, { marginTop: '10px' })} component="span">
            You will receive&nbsp;
            <Typography color="success.main" sx={styles.dialogFont} component="span" fontWeight={600}>
              {Math.floor(bnOrZero(formState.exp).toNumber())}
            </Typography>
            &nbsp;XP.
          </Typography>

          <Box display="flex" sx={styles.buttonBox}>
            {/* {(needAllowance) &&
              <ContainedButton
                sx={styles.approveButton}
                disabled={loadingApprove}
                onClick={handleApprove}
              >
                {isLoading
                  ? <CircularProgress size={24} />
                  : <span style={{ lineHeight: '2rem' }}>Approve</span>
                }
              </ContainedButton>
            } */}
            {/* Confirm */}
            <ContainedButton
              onClick={handleConsumeJuice}
              disabled={!isSubmitEnabled}
              sx={styles.confirmButton}
            >
              {isLoading
                ? <CircularProgress size={24} />
                : "CONFIRM & CONSUME"
              }
            </ContainedButton>
          </Box>

        </Box>
      </>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true} dialogOverwrites={styles.dialogOverwrites}>
      {getContent()}
    </DialogModal>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    },
  },
  dialogOverwrites: {
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  dialogFont: {
    fontSize: '1.25rem',
    textAlign: 'center',
  },
  divider: {
    background: 'rgba(174, 241, 238, 0.1)',
    marginY: '10px',
    width: '100%',
  },
  rowBerry: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    width: '100%',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  biggerText: {
    fontSize: '1.3rem',
    fontWeight: 600,
  },
  input: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },

    "&:hover, &:active, &:focus, &:focus-within": {
      borderColor: '#AEF1EE',
    },

    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      paddingY: "12px",

      "&:active, &:focus": {
        color: "#AEF1EE",
        "& input": {
          color: "#AEF1EE",
        },
      }
    },
  },
  settingNumInput: {
    background: 'transparent',
    border: 0,
    flex: 0.4,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
    "& input": {
      paddingX: '0',
      width: "100%",
      textAlign: "center",
    },
  },
  numInputBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'space-between',
    padding: '0 5%',
    width: "100%",
    // gap: '24px',
  },
  errorInput: { borderColor: "#F75E5E!important", },
  numInput: {
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    gap: '8px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      minWidth: '50px',
      "svg": {
        display: 'none',
      },
    },
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },
  button: {
    radius: '16px',
    padding: '7px 15px',
    fontSize: '1.125rem',
    marginLeft: '20px',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },
  emptyItemBox: {
    position: 'relative',
    backgroundImage: `url(${ItemFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "inherit",

    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    width: '120px',
    height: '120px',
    minWidth: '100px',
    minHeight: '100px',
    // height: '100%',
    margin: '30px auto',
    paddingY: 0,
    borderRadius: '12px',

    "svg": {
      width: '40px',
      height: '40px',
    },
  },
  gemAmt: {
    fontWeight: 600,
    fontSize: '14px',
    padding: '0px 6px'
  },
  gemAmtTag: {
    position: 'absolute',
    bottom: '-7px',
    border: "1px solid transparent",
    background: 'linear-gradient(180deg, #01001E -13.81%, #000010 100%) padding-box, radial-gradient(#F3FFFE, #AEF1EE, #00C2FF) border-box',
    borderRadius: '8px'
  },
  viewTx: {
    marginY: '20px',
  },

  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },

  buttonBox: {
    marginTop: "20px",
    "> *": {
      padding: '17px 24px !important',
    },
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  approveButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    marginRight: '20px',
    width: 270,
    "@media (max-width:600px)": {
      marginRight: 0,
      marginBottom: '10px',
      width: '100%',
    }
  },
  confirmButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    width: '40%',
    whiteSpace: 'nowrap',
    "@media (max-width:600px)": {
      width: '100%',
    }
  },

  feeRow: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    mt: '5px',
  },
  hunyIcon: {
    verticalAlign: "top",
  },
}

export default JuiceConsumptionDialog;
