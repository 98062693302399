import { LOCATION_TAGS } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { NftMetadata } from "store/types";

/*
* @description Converts location string to tags corresponding to `LOCATION_TAGS`
*/
export function getLocationTag(locationStr: string) {
  const n = locationStr.trim().split(" ");
  const tag: string = n[n.length - 1];

  const validTags: string[] = Object.keys(LOCATION_TAGS);

  return validTags.includes(tag) ? tag : 'Default';
}

/*
* @description Sort metazoas by their trait rarity
*/
export const traitRaritySorter = (traitType: string, metazoas: [string, NftMetadata][], byDesc: boolean = false) => metazoas.sort(([, a], [, b]) => {
  const aRarity = a?.attributes?.find(attr => attr.trait_type === traitType)?.rarity ?? 0;
  const bRarity = b?.attributes?.find(attr => attr.trait_type === traitType)?.rarity ?? 0;

  return byDesc ? bRarity - aRarity : aRarity - bRarity;
});
