import { Box, Button, CircularProgress, Link, styled, SvgIcon, SxProps, Typography } from "@mui/material";
import { BaseButton, ExternalLink, Huny } from "assets";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { logger, waitForTx } from "core/utilities";
import { MetazoaClient } from "core/utilities/metazoa";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { Guild, OAuth } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { Decimals, ITEM_ID } from "utils/constants";
import { bnOrZero, getExplorerLink, toHumanNumber } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { SIGIL_TITLE } from "../../GuildConstants";
import { UpdateGuildFormInputs } from "../UpdateGuild";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  formInputs: Partial<UpdateGuildFormInputs>;
  uploadedImage: File | null;
  price: number;
  guild: Guild;
}

const PostUpdateDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, formInputs, uploadedImage, price, guild } = props;

  if (open) {
    logger("test", "PostUpdateDialog", {
      guildId: guild.id,
      formInputs,
      uploadedImage
    })
  }

  const dispatch = useDispatch();
  const [createGuildComplete, setCreateGuildComplete] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);
  const [guildId, setGuildId] = useState<number>();
  const [purchaseTxHash, setPurchaseTxHash] = useState<string>("");
  const metazoaProfileState = useSelector(getProfile);
  const tokenState = useRedux((state) => state.token);
  const wallet = useRedux((state) => state.wallet.wallet);
  const toaster = useToaster();
  const network = useNetwork();
  const [runCreateGuild, loadingCreateGuild] = useAsyncTask("update", (error) => {
    onClose();
    toaster(error?.message ?? "Error Updating Guild");
  });
  const [runUploadImage] = useAsyncTask("uploadImage");
  const [runQueryApproved, loadingQueryApproved] = useAsyncTask("queryApproved");
  const [runApprove, loadingApprove] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
  });

  useEffect(() => {
    if (wallet) {
      runQueryApproved(async () => {
        const approved = await TBMConnector.checkEmporiumApproved(wallet);
        setApproved(approved);
      })
    }
    // eslint-disable-next-line
  }, [wallet, network]);

  const imageUpload = (guildId: number, uploadFile: File, accessToken: string, metazoaClient: MetazoaClient) => {
    runUploadImage(async () => {
      const requestResult = await metazoaClient.requestGuildImageUploadUrl(guildId, accessToken);

      const blobData = new Blob([uploadFile], { type: uploadFile.type });

      await metazoaClient.putImageUpload(requestResult.result.uploadUrl, blobData);
      await metazoaClient.notifyGuildImageUpload(guildId, accessToken);
    })
  }

  const handleCreateGuild = () => {
    runCreateGuild(async () => {
      if (!wallet) throw new Error("Wallet not connected");

      if (!formInputs.guildName)
        throw new Error("Please enter a guild name");

      // if (!uploadedImage)
      //   throw new Error("No crest image uploaded");

      if (tokenState.HunyTokens < bnOrZero(price).shiftedBy(-Decimals.HUNY).toNumber())
        throw new Error("Insufficient HUNY")

      // check if guild name is taken
      const metazoaClient = new MetazoaClient(network);
      const maxPrice = bnOrZero(price).times(1.05).dp(0);
      const { oAuth } = metazoaProfileState;

      let checkedOAuth: OAuth | undefined = oAuth;
      if (!oAuth?.access_token || oAuth.address !== wallet.addressInfo.bech32 || (oAuth && dayjs(oAuth?.expires_at * 1000).isBefore(dayjs()))) {
        const { result } = await metazoaClient.metazoaLogin(wallet, window.location.hostname);
        dispatch(actions.Profile.updateAccessToken(result));
        checkedOAuth = result;
      }

      // purchase item
      const guildParams = {
        guildName: formInputs.guildName,
        guildCrest: {
          design: formInputs.guildCrestDesign,
          colour: formInputs.guildCrestColour,
        },
        guildSigil: {
          name: SIGIL_TITLE[formInputs.guildSigilDesign ?? guild.guildSigil.design],
          design: formInputs.guildSigilDesign,
          colour: formInputs.guildSigilColour,
        },
        guildBanner: {
          design: formInputs.guildBannerDesign,
          colour: formInputs.guildBannerColour,
        },
      }

      const params = JSON.stringify({ guildParams: guildParams });

      const purchaseTx = await TBMConnector.purchaseItem(wallet, ITEM_ID[network].HeraldryScroll, maxPrice, params);

      if (purchaseTx?.id) {
        setPurchaseTxHash(purchaseTx.id);
        const tx = await waitForTx(purchaseTx.id);

        if (tx.status >= 3) {
          throw new Error("Error Updating Guild");
        }
      }

      // check if guild entry added to backend
      while (true) {
        try {
          const { result } = await metazoaClient.checkGuild({ slug: guild.slug });

          if (!result.guild) throw Error("guild not found");

          setGuildId(result?.guild?.id);

          // upload crest image
          if (result?.guild?.id && uploadedImage) {
            logger("test", "PostUpdateDialog-uploading image", {
              guild: result.guild.id,
              uploadedImage,
            })
            imageUpload(result.guild.id, uploadedImage, checkedOAuth!.access_token, metazoaClient);
          }

          break;
        } catch (err) {
          logger(err)
          console.error(err)
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
      }

      dispatch(actions.Profile.loadProfile());
      setCreateGuildComplete(true);
      logger("guild updated!");
    })
  }

  const handleApprove = () => {
    runApprove(async () => {
      if (!wallet) throw new Error("Wallet not connected");

      const approveTx = await TBMConnector.increaseEmporiumAllowance(wallet, new BigNumber(2).pow(128).minus(1).dp(0));

      if (approveTx?.id) {
        try {
          await waitForTx(approveTx.id);
          setApproved(true);
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    })
  }

  const handleOnClose = () => {
    if (loadingCreateGuild) return;

    onClose();
  }

  const getHeader = () => {
    if (createGuildComplete) {
      return "Guild update success!";
    } else if (loadingCreateGuild) {
      return "Updating Guild...";
    } else {
      return "Update Guild";
    }
  }

  const getContent = () => {
    if (createGuildComplete) {
      return (
        <Fragment>
          <Box sx={styles.contentBox}>
            {/* tx hash */}
            <Link
              target="_blank"
              href={getExplorerLink("tx", purchaseTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>

            {/* link to guild page */}
            <Button
              component={RouterLink}
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={styles.guildButton}
              to={`/guilds/${guildId}`}
            >
              <BaseButtonBox>
                <BaseButton height="100%" />
              </BaseButtonBox>
              View My Guild
            </Button>
          </Box>
        </Fragment>
      )
    }

    if (loadingCreateGuild) {
      return (
        <Fragment>
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Fragment>
      )
    }

    return (
      <Fragment>
        {/* guild name */}
        <Box mt="20px">
          <Typography color="primary" variant="body1" sx={styles.cost}>
            Guild Name: {formInputs.guildName}
          </Typography>
        </Box>

        {/* total cost */}
        <Box mt="20px">
          <Typography color="primary" variant="body1" sx={styles.cost}>
            Total Cost: {toHumanNumber(bnOrZero(price).shiftedBy(-Decimals.HUNY).toNumber())} HUNY <Huny />
          </Typography>
        </Box>

        <Box display="flex" sx={styles.buttonBox}>
          {!approved &&
            <ContainedButton sx={styles.approveButton} onClick={handleApprove} disabled={loadingApprove || loadingQueryApproved}>
              {loadingApprove || loadingQueryApproved
                ? <CircularProgress size={24} />
                : <span style={{ lineHeight: '2rem' }}>Approve</span>
              }
            </ContainedButton>
          }

          <ContainedButton
            disabled={!approved || loadingApprove || loadingQueryApproved}
            sx={styles.createButton}
            onClick={handleCreateGuild}
          >
            Update
          </ContainedButton>
        </Box>
      </Fragment>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} hideCloseButton={loadingCreateGuild} disableScrollLock={true}>
      {getContent()}
    </DialogModal>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 700,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  cost: {
    "& svg": {
      verticalAlign: "text-top",
      marginTop: "2px",
      height: 22,
      width: 22,
    },
    "@media (max-width:600px)": {
      "& svg": {
        marginTop: 0,
        height: "20px!important",
        width: "20px!important",
      }
    },
  },
  guildButton: {
    marginTop: "10px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  loadingImage: {
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  viewTx: {
    marginTop: "20px",
    marginBottom: "40px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  buttonBox: {
    marginTop: "40px",
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  approveButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    marginRight: '10px',
    width: 270,
    "@media (max-width:600px)": {
      marginRight: 0,
      marginBottom: '10px',
      width: '100%',
    }
  },
  createButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    width: 270,
    "@media (max-width:600px)": {
      width: '100%',
    }
  },
};

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    height: "100%",
  },
});


export default PostUpdateDialog;