import BigNumber from "bignumber.js";
import { BIG_ZERO } from "./constants";

// Remainder Huny to be swapped for Zil
export const uniswap = (zilValue: BigNumber, hunyValue: BigNumber, zilPrice: BigNumber, hunyReserves: BigNumber | undefined, hunyPrice: BigNumber) => {
  const hunyAmtForSwap = hunyValue.minus(zilValue).minus(zilPrice.times(100)).div(hunyPrice); // Remaining HUNY (amtA)
  const swapFee = 0.003;
  if (!!hunyReserves) {
    const aVar = 1 - swapFee
    const bVar = hunyReserves.times(2 - swapFee)
    const cVar = hunyAmtForSwap.times(hunyReserves)
    const discriminant = bVar.exponentiatedBy(2).plus(cVar.times(4 * aVar)) // b2 + 4ac
    const discriminantSqrt = discriminant.squareRoot()
    const numerator = discriminantSqrt.minus(bVar)
    const denominator = 2 * aVar
    const swapAmt = numerator.dividedBy(denominator)
    return swapAmt;
  } else return BIG_ZERO;
}