import { Theme } from "@mui/material";

const MuiOutlinedInput = (theme: Theme) => ({
  styleOverrides: {
    notchedOutline: {
      border: "none"
    },
  },
});

export default MuiOutlinedInput;