import { forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, BoxProps, IconButton, Link, SvgIcon, SxProps, Typography } from "@mui/material";
import { SnackbarKey, SnackbarMessage, SnackbarProvider } from "notistack";
import { AppTheme, SimpleMap } from 'utils/types';
import ErrorIcon from '@mui/icons-material/Error';
import truncate from 'utils/truncate';
import { getExplorerLink } from 'utils/strings';
import useNetwork from 'utils/useNetwork';
import { ExternalLink } from 'assets';

interface Props extends BoxProps {
  message: SnackbarMessage,
  providerRef: React.MutableRefObject<SnackbarProvider>,
  snackKey: SnackbarKey,
  hash?:string,
}

const { SnackbarContent } = require('notistack');

const SnackbarContentItem = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, message, providerRef, snackKey, hash, ...rest } = props;

  const network = useNetwork();

  const onClickDismiss = () => {
    return () => {
      if (providerRef.current) {
        providerRef.current.closeSnackbar(snackKey);
      }
    };
  };
  return (
    <SnackbarContent {...rest} ref={ref} sx={styles.snackbarStyle}>
      <SvgIcon component={ErrorIcon} sx={styles.errorIcon} />
      <Typography color="primary" variant="body1">&nbsp;&nbsp;{message}&nbsp;
      {hash &&
          <Link
            // className={classes.link}
            underline="hover"
            rel="noopener noreferrer"
            target="_blank"
            href={getExplorerLink("tx", hash, network)}>
            {"0x"}{truncate(hash)}                                                          
            <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
          </Link>
      }
      </Typography>
      <Box flexGrow={1} />
      <IconButton onClick={onClickDismiss()} sx={styles.closeIcon}>
        <CloseIcon />
      </IconButton>
    </SnackbarContent>
  );
});

const styles: SimpleMap<SxProps<AppTheme>> = {
  snackbarStyle: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)',
    color: '#fff',
    padding: '20px',
    paddingRight: '60px',
    position: 'relative',
    borderRadius: '16px',

    display:'flex',
    justifyContent:'center',
    placeItems:'center',
  },
  errorIcon: {
    color: '#FF2F2F',
    margin:'auto',
  },
  closeIcon: {
    position: 'absolute',
    top: 14,
    right: 14,
    color: '#fff',
    width: '13px',
    height: '13px',
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
}

export default SnackbarContentItem;