import { Container, SxProps } from "@mui/material";
import ExploreLayout from "layout/ExploreLayout";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "utils/ScrollToTop";
import { AppTheme, SimpleMap } from "utils/types";

const ExplorePage: FC = () => {
  return (
    <ExploreLayout>
      <Container maxWidth="xl" sx={styles.container}>
        <ScrollToTop />
        <Outlet />
      </Container>
    </ExploreLayout>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    position: "relative",
    padding: "0px 0px 40px",
    minHeight: "1200px",
    width: '100%',
    "@media (max-width:768px)": {
      overflowX: 'auto',
    },
  },
};

export default ExplorePage;