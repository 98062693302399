import { Box,
  Button,
  Link,
  styled,
  SxProps,
  SvgIcon,
  Typography,
} from "@mui/material";
import { BaseButton, ExternalLink } from "assets";
import BurnLoading from "assets/icons/BurnLoading.gif";
import { AppTheme, SimpleMap } from "utils/types";
import { DialogModal } from "components/DialogModal";
import { Network } from "zilswap-sdk/lib/constants";
import MetazoaPreview from "assets/MetazoaPreview.gif";
import { Link as RouterLink } from "react-router-dom";
import ContainedButton from "components/ContainedButton";
import { getExplorerLink } from "utils/strings/links";

export interface ModalProps {
  loadingOpen: boolean;
  successOpen: boolean;
  onClose: () => void;
  complete: boolean;
  transcendTxHash: string;
  network: Network;
  zoaTokens: string[];
}

const BurnStatusModal: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    loadingOpen,
    successOpen,
    onClose,
    complete,
    transcendTxHash,
    network,
    zoaTokens,
  } = props;

  return complete ? (
    <DialogModal header={"Transcendence Portal"} open={successOpen} onClose={onClose} sx={styles.successDialog}>
      <Typography variant="body1" color="primary" textAlign="center">
        Your bears have Transcended.
        <br />
        Prepare for the Mission ahead.
      </Typography>
      <Box sx={styles.contentBox}>
        <Box sx={styles.zoaTokensContainer}>
          {zoaTokens.map((tokenId) => {
            return (
              <ZoaWrapper key={tokenId} sx={styles.zoaWrapper}>
                <Box
                  component="img"
                  sx={styles.zoaImage}
                  src={MetazoaPreview}
                  alt={`ZOA ${tokenId}`}
                />
                <Box sx={styles.zoaIdBox}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={styles.zoaId}
                  >
                    ZOA #{tokenId}
                  </Typography>
                </Box>
              </ZoaWrapper>
            );
          })}
        </Box>
        <Link
          target="_blank"
          href={getExplorerLink("tx", transcendTxHash, network)}
          sx={styles.viewTx}
        >
          View Transaction
          <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
        </Link>
        <ContainedButton
          onClick={onClose}
          sx={styles.transcendButton}
        >
          Transcend More
        </ContainedButton>
        <Button
          component={RouterLink}
          variant="contained"
          color="secondary"
          onClick={onClose}
          sx={styles.profileButton}
          to="/metazoa"
        >
        <BaseButtonBox>
          <BaseButton height="100%" />
        </BaseButtonBox>
        View My Profile
      </Button>
    </Box>
  </DialogModal>
  ) : (
    <DialogModal
      header={"Transcendence Portal"}
      open={loadingOpen}
      hideCloseButton={true}
    >
      <Box sx={styles.contentBoxLoading}>
        <Typography
          variant="overline"
          sx={{ color: "#ff8952", textAlign: "center" }}
          mt="40px"
        >
          Undergoing Enlightenment, <br /> Bear with us...
        </Typography>
        <img src={BurnLoading} alt="burning.." width="240px" />
      </Box>
    </DialogModal>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 400,
    "@media (min-width:900px)": {
      minWidth: 820,
      minHeight: 380,
    },
    "@media (max-width:900px)": {
      minWidth: "",
      width: "100%",
    },
  },
  contentBoxLoading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 400,
    "@media (min-width:900px)": {
      minWidth: 820,
      minHeight: 410,
    },
    "@media (max-width:900px)": {
      minWidth: 800,
      minHeight: 420,
    },
    "@media (max-width:800px)": {
      minHeight: 450,
    },
    "@media (max-width:420px)": {
      minHeight: 500,
    },
  },
  burntBears: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "20px",
    "@media (max-width:600px)": {
      marginTop: "20px",
    },
  },
  burntBearsUnique: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  transcendButton: {
    marginBottom: "10px",
    minWidth: "360px",
    height: 64,
    "@media (max-width:600px)": {
      minWidth: "",
      width: "100%",
    },
  },
  profileButton: {
    height: 64,
    minWidth: "360px",
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  viewTx: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  zoaTokensContainer: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "repeat(5, 1fr)",
    columnGap: "20px",
    rowGap: "20px",
    maxHeight: "170px",
    marginTop: "10px",
    paddingTop: "10px",
    overflowY: "auto",
    paddingRight: "10px",
    "@media (max-width:900px)": {
      gridTemplateColumns: "repeat(4, 1fr)",
      maxHeight: "150px",
    },
    "@media (max-width:600px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media (max-width:480px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  zoaWrapper: {
    position: "relative",
    borderRadius: "16px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "visible",
  },
  zoaImage: {
    height: "100px",
    width: "100px",
    "@media (max-width:900px)": {
      height: "80px",
      width: "80px",
    },
  },
  zoaIdBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  zoaId: {
    marginTop: "6px",
    fontSize: "16px",
    "@media (max-width:900px)": {
      fontSize: "14px",
    },
  },
  successDialog: {
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  }
};

const ZoaWrapper = styled(Box)({
  background:
    "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
  border: "none",
});

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    height: "100%",
  },
});

export default BurnStatusModal;
