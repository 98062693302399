import { useEffect, useState } from "react";
import useRedux from "./useRedux";


const useTaskSubscriber = (...tasks: string[]) => {
    const loadingTasks = useRedux(store => store.layout.loadingTasks);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        for (const key of tasks)
            if (loadingTasks[key]) return setLoading(true);
        setLoading(false);
    }, [loadingTasks, tasks]);
    return [loading];
};

export default useTaskSubscriber;