import ElderberryJuiceGlow from "assets/quests/Locations/ElderberryJuiceGlow.svg";
import { ZOMGItem } from "store/types";
import { importAll } from "utils/themeUtilities";
import { SimpleMap } from "utils/types";
import { Stat, STATS, STAT_TYPES } from "./MetazoaCollection/MetazoaConstants";

export const GemAssets = importAll(
  require.context("assets/quests/Locations/Gems", false, /\.(png|jpe?g|svg)$/)
);
export const EquipCategoryAssets = importAll(
  require.context(
    "assets/quests/Locations/ZOMGStore/EquipCategories",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
export const ZOrdnanceAssets = importAll(
  require.context(
    "assets/quests/Locations/ZOMGStore/Z-Ordnance",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

export type Resource = {
  name: string;
  icon: string;
};

export enum CraftTypes {
  "Weapon" = "Weapons",
  "Off-Hand" = "Off-Hand",
  "Armor" = "Armors",
  "Headgear" = "Head Gears",
}

export enum EquipRequirements {
  masteryLevel = "Mastery Level",
  metazoaLevel = "Metazoa Level",
  profession = "Profession",
}

export const Craftables: (string | CraftTypes)[] = Object.keys(
  CraftTypes
).filter((value) => typeof value === "string");

export type CraftType = keyof typeof CraftTypes;

export type CraftItem = ZOMGItem & {
  craftType: CraftType;
};

export enum ConsumableType {
  "ElderberryJuice",
}

export type Consumable = Omit<Resource, "name">;

export const CONSUMABLES: {
  [key in ConsumableType]: Consumable;
} = {
  [ConsumableType.ElderberryJuice]: {
    icon: ElderberryJuiceGlow,
  },
};

export type Gem = Resource & {
  type: STAT_TYPES;
  stat: number;
  reqMaterialCount: number;
  iconCracked?: string;
};

export enum GemTier {
  C = "Cut",
  B = "Brilliant",
  A = "Ace",
  S = "Stellar",
  SS = "Super Stellar",
}

export const GemTierStat: {
  [key in GemTierType]: number;
} = {
  C: 1,
  B: 3,
  A: 5,
  S: 8,
  SS: 10,
};

export const GemTierMaterial: {
  [key in GemTierType]: number;
} = {
  C: 3,
  B: 2,
  A: 1,
  S: 1,
  SS: 0,
};

export type GemTierType = keyof typeof GemTier;
export type GemTierStats = Partial<Gem> & {
  statBonus: number;
};

const statGems: GemTierStats[] = Object.values(STATS).reduce(
  (gems: GemTierStats[], stat: Stat) => {
    const { type, galaxyGem } = stat;

    gems.push({
      type: type as STAT_TYPES,
      name: galaxyGem,
      statBonus: 1,
    });
    return gems;
  },
  []
);

export const GEMS: { [key in GemTierType]: SimpleMap<Gem> } = Object.keys(
  GemTier
).reduce((tiers, tier) => {
  // Flesh out stat gems
  const tierStatGems: SimpleMap<Gem> = statGems.reduce(
    (stats: SimpleMap<Gem>, gem: Partial<Gem>) => {
      const statType = gem.type;
      if (!statType) return stats;

      const iconNameSrc = `${tier}-${gem.name}-full`.toLowerCase();
      const iconNameSrcCracked = `${tier}-${gem.name}-cracked`.toLowerCase();
      const iconSrc = `${iconNameSrc}.png`;
      const iconSrcCracked = `${iconNameSrcCracked}.png`;
      stats[statType] = {
        ...gem,
        stat: GemTierStat[tier],
        reqMaterialCount: GemTierMaterial[tier],
        icon: GemAssets[iconSrc]?.default ?? iconSrc,
        iconCracked: GemAssets[iconSrcCracked]?.default ?? iconSrc,
      } as Gem;
      return stats;
    },
    {}
  );

  tiers[tier] = tierStatGems;
  return tiers;
}, {}) as { [key in GemTierType]: SimpleMap<Gem> };

export enum EquipType {
  "Gem",
  "Headgear",
  "Off-Hand",
  "Armor",
  "Weapon",
}

export const EQUIP_CATEGORIES = Object.fromEntries(
  Object.keys(EquipType)
    .filter((v) => isNaN(Number(v)))
    .map((category) => [
      category,
      {
        type: category,
        icon: (EquipCategoryAssets[`equip-${category}.svg`]?.default ??
          "") as string,
      },
    ])
);

export type EquipCategory =
  typeof EQUIP_CATEGORIES[keyof typeof EQUIP_CATEGORIES];

const MaxZOrdnanceClasses: SimpleMap<number> = {
  A: 6,
  H: 3,
  OH: 7,
  W: 4,
};

export const ZOrdnanceClasses: SimpleMap = Object.entries(
  MaxZOrdnanceClasses
).reduce((zMap, [zClass, zClassMax]) => {
  const zClassMin = 1;

  for (let classIdx = zClassMin; classIdx <= zClassMax; classIdx++) {
    const classKey = `${zClass}${classIdx}`;
    zMap[classKey] = (ZOrdnanceAssets[`ZOrd-${classKey}.svg`]?.default ??
      "") as string;
  }
  return zMap;
}, {});
