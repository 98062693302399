import { Box, BoxProps } from "@mui/material";

interface TabPanelProps extends BoxProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...rest } = props;

  return (
    <Box {...rest}>
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </Box>
  );
}

export default TabPanel;