import { Box, BoxProps, FormControl, FormHelperText, InputLabel, OutlinedInput, SxProps, Typography } from "@mui/material";
import { GuildBannerPreview, WarningRed } from "assets";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { MetazoaClient } from "core/utilities/metazoa";
import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { Guild, OAuth } from "store/types";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { Errors, UpdateGuildFormInputs } from "../UpdateGuild";

export interface Props extends BoxProps {
  formInputs: Partial<UpdateGuildFormInputs>;
  setFormInputs: React.Dispatch<React.SetStateAction<Partial<UpdateGuildFormInputs>>>;
  errors: Partial<Errors>;
  setErrors: React.Dispatch<React.SetStateAction<Partial<Errors>>>;
  isCreateEnabled: boolean;
  handleBack: () => void;
  guild: Guild;
}

const FreeUpdates: React.FC<Props> = (props: Props) => {
  const { guild, formInputs, setFormInputs, errors, setErrors, isCreateEnabled, handleBack } = props;

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const validateInput = (field: string, input: string) => {
    switch (field) {
      // max 100 words
      case "description":
        if (input.length && input.length < 2) return "Enter a guild description of at least 2 characters.";
        if (input.length > 140 || input.length === 0) return "Enter a guild description of up to 140 characters.";
        return ""
      default: return "";
    }
  }

  const updateInputs = (field: string) => {
    return (newInput: string | number) => {
      setFormInputs({ ...formInputs, [field]: newInput });

      if (typeof newInput === "string") {
        const errorText = validateInput(field, newInput);
        setErrors((prev) => ({ ...prev, [field]: errorText }));
      }
    }
  }

  const network = useNetwork();
  const wallet = useRedux((state) => state.wallet.wallet);
  const metazoaProfileState = useSelector(getProfile);

  const [runFreeGuildUpdates, loadingFreeGuildUpdates] = useAsyncTask("freeGuildUpdates");
  const toaster = useToaster();
  const dispatch = useDispatch();
  const freeGuildUpdate = () => {
    runFreeGuildUpdates(async () => {
      if (!formInputs || !wallet) return;

      try {
        const metazoaClient = new MetazoaClient(network);
        const { oAuth } = metazoaProfileState;
        let checkedOAuth: OAuth | undefined = oAuth;
        if (!oAuth?.access_token || oAuth.address !== wallet?.addressInfo.bech32 || (oAuth && dayjs(oAuth?.expires_at * 1000).isBefore(dayjs()))) {
          const { result } = await metazoaClient.metazoaLogin(wallet!, window.location.hostname);
          dispatch(actions.Profile.updateAccessToken(result));
          checkedOAuth = result;
        }

        if (!!formInputs) {
          await metazoaClient.updateGuildDetails(guild.id, { ...formInputs, guildId: guild.id } as any, checkedOAuth!);
          toaster(`Guild Updated`);
          dispatch(actions.Guild.reloadGuild(guild));
        }
      } catch (error: any) {
        toaster(`Error updating guild: ${error.message}`);
      }

      setShowDialog(true);
    });
  }

  const handleUpdate = () => {
    let isEmpty = false;
    const toCheck = ['description'];
    toCheck.forEach(value => {
      if (formInputs[value].trim() === "") {
        updateInputs(value)("");
        isEmpty = true;
      }
    });

    if (isEmpty) window.scrollTo({ top: 0, behavior: 'smooth' });
    else freeGuildUpdate();
  }

  return (
    <Box sx={styles.contentBox}>
      {/* guild information */}
      <Typography variant="body2" color="primary" sx={styles.sectionHeaderText}>Guild Information</Typography>

      {/* free changes */}
      <Box sx={styles.guildInformationBox}>
        {/* description */}
        <Box display="flex" alignItems="center" width="100%">
          <InputLabel htmlFor="description" sx={errors["description"] ? styles.removeErrorMargin : undefined}>
            <Typography variant="body1" color="primary" sx={styles.inputLabel}>Description</Typography>
          </InputLabel>
          <FormControl fullWidth>
            <OutlinedInput
              sx={combineStyles(styles.multilineInput, errors["description"] ? styles.errorInput : undefined)}
              id="description"
              placeholder={guild.description}
              value={formInputs.description}
              onChange={(e) => updateInputs("description")(e.target.value)}
              multiline={true}
            />
            {errors["description"] &&
              <Box sx={styles.errorTextContainer}>
                <WarningRed width={15} />
                <FormHelperText sx={styles.errorText}>{errors["description"]}</FormHelperText>
              </Box>
            }
          </FormControl>
        </Box>
      </Box>
      {/* button box */}
      <Box display="flex" justifyContent="end" mt="30px">
        {/* create button */}
        <ContainedButton
          sx={styles.createButton}
          onClick={handleUpdate}
          disabled={!isCreateEnabled || loadingFreeGuildUpdates}
        >
          Update Info
        </ContainedButton>
      </Box>


      <DialogModal
        header={"Sit tight!"}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        sx={styles.dialogModal}
        disableScrollLock={true}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          placeContent: 'center',
          placeItems: 'center',
        }}>
          <Typography sx={styles.warningText}>
            Your Guild Description will be updated shortly.<br />
            Check in after a few minutes!
          </Typography>

          {/* button box */}
          <Box display="flex" justifyContent="end" mt="30px">
            {/* create button */}
            <ContainedButton
              sx={styles.createButton}
              onClick={handleBack}>
              My Guild
            </ContainedButton>
          </Box>
        </Box>
      </DialogModal>


    </Box>
  )
}


const styles: SimpleMap<SxProps<AppTheme>> = {
  contentBox: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    "@media (min-width:600px)": {
      minWidth: "500px",
    },
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  sectionHeaderText: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 0.8)",
    marginTop: "60px",
  },
  guildInformationBox: {
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    borderRadius: "16px",
    marginTop: "12px",
    padding: "24px",
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  input: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      padding: "12px 24px",
    },
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },
  },
  multilineInput: {
    minHeight: "48px",
    padding: "6px 24px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    fontSize: "14px",
    lineHeight: "28px",
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: 600,
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },
  },
  inputLabel: {
    fontSize: "14px",
    lineHeight: "28px",
    width: "250px",
  },
  tooltip: {
    padding: "20px",
    width: "360px",
    "& .MuiTypography-root": {
      fontSize: "18px",
      lineHeight: "28px",
    },
    "@media (max-width:600px)": {
      padding: "20px",
      width: "250px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  subheaderText: {
    fontSize: "14px",
    lineHeight: "28px",
    fontWeight: 600,
  },
  nextButton: {
    minWidth: "280px",
    height: 64,
    "@media (max-width:600px)": {
      minWidth: "",
      flex: 1,
    },
  },
  previewBox: {
    backgroundImage: `url(${GuildBannerPreview})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    minHeight: "340px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sigilName: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#AEF1EE",
    whiteSpace: "nowrap",
    textAlign: "left",
    fontWeight: 600,
  },
  sigilValues: {
    fontSize: "12px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    textAlign: "left",
  },
  sigilImage: {
    height: "80px",
    width: "80px",
  },
  // TODO: improve responsiveness of sigil stuff
  selectionBox: {
    overflowX: "scroll",
    "::-webkit-scrollbar": {
      width: '6px',
      height: "6px"
    },
    "::-webkit-scrollbar-track": {
      marginTop: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '16px',
    },
    "@media (max-width:400px)": {
      maxWidth: "150px",
    }
  },
  colourBox: {
    height: "42px",
    width: "42px",
    borderRadius: "50%",
    boxShadow: '0px 0px 16px rgba(239, 57, 255, 0.4)',
  },
  sigil: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -70%)",
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },
  removeErrorMargin: {
    marginTop: "-22px",
  },
  errorInput: {
    border: "1px solid #F65E5E",
    "&.Mui-focused": {
      borderColor: "#F65E5E",
    },
  },
  createButton: {
    minWidth: "280px",
    height: 64,
    "@media (max-width:900px)": {
      minWidth: "",
      flex: 1,
    },
  },



  //INFO: Loading dialog
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 700,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    },
  },
  loadingImage: {
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "20px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
}

export default FreeUpdates;