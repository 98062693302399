
import { channel, Channel, EventChannel, eventChannel } from "redux-saga";
import { call, delay, fork, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { waitForConnectorInit } from "saga/helpers";
import AppStore from "store";
import { ObservedTx, TxReceipt, TxStatus, Zilswap } from "zilswap-sdk";
import { Network } from "zilswap-sdk/lib/constants";
import { logger } from "../../core/utilities";
import { getConnectedZilPay } from "../../core/utilities/zilpay";
import { ConnectedWallet, connectWalletZilPay, WalletConnectType } from "../../core/wallet";
import actions from "../../store/actions";
import { ChainInitAction } from "../../store/blockchain/actions";
import { ExchangeRateProps, MoonBattleInfo, NftMetadata, Quest, RefinementFees, UpdatedTokens } from "../../store/types";
import { WalletAction, WalletActionTypes } from "../../store/wallet/actions";
import { TBMConnector, ZolarEventSubscriber } from "../../tbm";
import { LocalStorageKeys, RPCEndpoints } from "../../utils/constants";
import { SimpleMap } from "../../utils/types";
import { getBlockchain, getToken, getWallet } from '../selectors';
// import { Subscription, StatusType, MessageType } from "@zilliqa-js/subscriptions";

const getProviderOrKeyFromWallet = (wallet: ConnectedWallet | null) => {
  if (!wallet) return null;

  switch (wallet.type) {
    case WalletConnectType.PrivateKey:
      return wallet.addressInfo.privateKey
    case WalletConnectType.Zeeves:
    case WalletConnectType.ZilPay:
      return wallet.provider;
    case WalletConnectType.Moonlet:
      throw new Error("moonlet support under development");
    default:
      throw new Error("unknown wallet connector");
  }
}

const zilPayObserver = (zilPay: any) => {
  return eventChannel<ConnectedWallet>(emitter => {
    const accountObserver = zilPay.wallet.observableAccount();
    const networkObserver = zilPay.wallet.observableNetwork();

    accountObserver.subscribe(async (account: any) => {
      logger(`Zilpay account changed to: ${account.bech32}`)
      const walletResult = await connectWalletZilPay(zilPay);
      if (walletResult?.wallet) {
        emitter(walletResult.wallet)
      }
    });

    networkObserver.subscribe(async (net: string) => {
      logger(`Zilpay network changed to: ${net}`)
      const walletResult = await connectWalletZilPay(zilPay);
      if (walletResult?.wallet) {
        emitter(walletResult.wallet)
      }
    });

    logger('registered zilpay observer')

    return () => {
      logger('deregistered zilpay observer')
      accountObserver.unsubscribe()
      networkObserver.unsubscribe()
    }
  })
}

function* initialize(action: ChainInitAction, txChannel: Channel<TxObservedPayload>) {
  let sdk: Zilswap | null = null;
  try {
    yield put(actions.Layout.addBackgroundLoading('initChain', 'INIT_CHAIN'))
    yield put(actions.Wallet.update({ wallet: null }))
    yield put(actions.Token.clearTokens())

    const { network, wallet } = action.payload
    const providerOrKey = getProviderOrKeyFromWallet(wallet)
    const { network: prevNetwork } = getBlockchain(yield select());

    sdk = new Zilswap(network, providerOrKey ?? undefined, { rpcEndpoint: RPCEndpoints[network], deadlineBuffer: 1000 });
    logger('zilswap sdk initialized', sdk.network)

    yield call([sdk, sdk.initialize], txObserver(txChannel))
    TBMConnector.setSDK(sdk)

    yield put(actions.Wallet.update({ wallet }))
    if (network !== prevNetwork) yield put(actions.Blockchain.setNetwork(network))

    yield put(actions.Blockchain.initialized());
    yield put(actions.Token.updateTranscendenceInitState());
  } catch (err) {
    console.error(err)
    sdk = yield call(teardown, sdk)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('INIT_CHAIN'))
    yield put(actions.Token.updateImage());
  }
  return sdk
}

function* updateTokens() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading('updateTokens', 'UPDATE_TOKENS'))
    const { wallet } = getWallet(yield select());
    if (wallet) {

      const info: UpdatedTokens = yield TBMConnector.fetchUpdatedTokens(wallet);
      logger("update token id", info.tokenIds);
      logger("update metazoa id", info.metazoaIds);
      logger("update staked metazoa id", info.stakedMetazoaIds);
      logger("update staked berry id", info.questBerryIds);
      logger("update staked geode id", info.questGeodeIds);
      logger("update staked scrap id", info.questScrapIds);
      logger("update minted tokens count", info.mintedTokensCount);
      logger("update huny balance", info.hunyBalance);
      logger("update zil balance", info.zilBalance);
      logger("update huny token supply", info.totalHunySupply);
      logger("update resources", info.resources);
      yield put(actions.Token.setTokens(info.tokenIds));
      yield put(actions.Token.setMetazoa(info.metazoaIds));
      yield put(actions.Token.setStakedMetazoa(info.stakedMetazoaIds));
      yield put(actions.Token.setQuestBerryMetazoa(info.questBerryIds));
      yield put(actions.Token.setQuestGeodeMetazoa(info.questGeodeIds));
      yield put(actions.Token.setQuestScrapMetazoa(info.questScrapIds));
      yield put(actions.Token.setTrappedMetazoa(info.trappedMetazoaIds));
      yield put(actions.Token.setMintedTokensCount(info.mintedTokensCount));
      yield put(actions.Token.updateHunyBalance(info.hunyBalance.toNumber()));
      yield put(actions.Token.updateZilToken(info.zilBalance));
      yield put(actions.Token.updateHunyTokenSupply(info.totalHunySupply));
      yield put(actions.Token.updateResources(info.resources));
    }
    const { tokens, metazoaTokens, stakedMetazoa, stakedMetazoaBerry, stakedMetazoaGeode, stakedMetazoaScrap, trappedMetazoa } = getToken(yield select());
    const tokenData: NftMetadata[] = yield TBMConnector.getOwnedTokensImage(Object.keys(tokens));
    const metazoaTokenData: NftMetadata[] = yield TBMConnector.getOwnedMetazoaMetadata(Object.keys(metazoaTokens).concat(Object.keys(stakedMetazoa)).concat(Object.keys(stakedMetazoaBerry)).concat(Object.keys(stakedMetazoaGeode)).concat(Object.keys(stakedMetazoaScrap)).concat(Object.keys(trappedMetazoa)));

    const tokenStatsProfession: NftMetadata[] = yield TBMConnector.getStatsAndProfession(
      metazoaTokenData,
      Object.values(metazoaTokenData).map((m) => m.id)
    );

    const tokenQuestBonus = yield TBMConnector.getTokenContractBonuses(
      metazoaTokenData,
    );

    logger("update token images", tokenData);
    logger("update metazoa metadata", metazoaTokenData);
    logger("update token profession and stats", tokenStatsProfession);
    logger("update token quest bonus", tokenQuestBonus);

    yield put(actions.Token.updateTokens(tokenData));
    yield put(actions.Token.updateMetazoa(tokenQuestBonus));
  } catch (error) {
    console.error("update token error")
    console.error(error)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('UPDATE_TOKENS'))
  }
}

function* fetchQuestState() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading('setQuest', 'SET_QUEST_STATE'))
    const questInfo: SimpleMap<Quest> = yield TBMConnector.fetchQuests();
    logger("fetch quest info", questInfo)
    yield put(actions.Quest.setQuestState(questInfo))
  } catch (error) {
    console.error("set quest state error")
    console.error(error)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('SET_QUEST_STATE'))
  }
}

function* fetchRefineFeeState() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading('setRefineFees', 'SET_REFINEMENT_FEES'))
    const refineFees: RefinementFees = yield TBMConnector.fetchRefinementFees();
    logger("fetch refinement fees", refineFees)
    yield put(actions.Quest.setRefinementFeeState(refineFees))
  } catch (error) {
    console.error("set refinement fees error")
    console.error(error)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('SET_REFINEMENT_FEES'))
  }
}

function* updateResources() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading('refetchResources', 'REFETCH_RESOURCES'))
    const { wallet } = getWallet(yield select());
    if (wallet) {
      yield call(fetchQuestState);
      yield call(fetchRefineFeeState);

      const info: UpdatedTokens = yield TBMConnector.fetchUpdatedTokens(wallet);
      const items = yield TBMConnector.fetchItems(wallet);
      logger("update resources", info.resources, items);
      yield put(actions.Token.updateResources(info.resources));
    }
  } catch (error) {
    console.error("update resources error")
    console.error(error)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('REFETCH_RESOURCES'))
  }
}

function* fetchHunyState() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading('updateHunyState', 'UPDATE_HUNY_STATE'))
    const { wallet } = getWallet(yield select());
    const { stakedMetazoa } = getToken(yield select());
    if (wallet) {
      const metazoaBlocksStaked: SimpleMap<number> = yield TBMConnector.getMetazoaBlocksStaked(wallet, Object.keys(stakedMetazoa));
      logger("update huny gathered per metazoa", metazoaBlocksStaked);
      yield put(actions.Token.updateMetazoaBlocksStaked(metazoaBlocksStaked));
    }
  } catch (error) {
    console.error("update huny state error")
    console.error(error)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('UPDATE_HUNY_STATE'))
  }
}

function* fetchWhitelistCount() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading('setWhitelistCount', 'SET_WHITELIST_COUNT'))
    const { wallet } = getWallet(yield select());
    if (wallet) {
      const whitelistCount: number = yield TBMConnector.getWhitelistCount(wallet);
      logger("update whitelist count", whitelistCount);
      yield put(actions.Token.setWhitelistCount(whitelistCount));
    }
  } catch (error) {
    console.error("fetch initial values error")
    console.error(error)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('SET_WHITELIST_COUNT'))
  }
}

function* fetchTranscendenceApproval() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading('updateTranscendenceApproval', 'UPDATE_TRANSCENDENCE_APPROVAL'))
    const { wallet } = getWallet(yield select());
    if (wallet) {
      const transcendenceApproved: boolean = yield TBMConnector.checkTranscendenceApproved(wallet);
      logger("update transcendence approval", transcendenceApproved);
      yield put(actions.Token.updateTranscendenceApproval(transcendenceApproved));
    }
  } catch (error) {
    console.error("fetch initial values error")
    console.error(error)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('UPDATE_TRANSCENDENCE_APPROVAL'))
  }
}

function* fetchSupply() {
  try {
    yield waitForConnectorInit();
    const [totalSupply]: Array<number> = yield TBMConnector.getSupply();
    yield put(actions.Token.updateSupply({ totalSupply }));
  } catch (error) {
    console.error("update supply error");
    console.error(error);
    yield put(actions.Token.updateSupply({ totalSupply: 0 }));
  }
}

function* getTranscendenceStartBlock() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading('setTranscendenceStartBlock', 'SET_TRANSCENDENCE_START_BLOCK'));
    const transcendenceStartBlock: number = yield TBMConnector.getTranscendenceStartBlock();
    yield put(actions.Blockchain.setTranscendenceStartBlock(transcendenceStartBlock));
  } catch (error) {
    console.error("fetch transcendence start block error");
    console.error(error);
    yield put(actions.Blockchain.setTranscendenceStartBlock(0));
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('SET_TRANSCENDENCE_START_BLOCK'))
  }
}

function* getMinimumGasPrice() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading('fetchGasFee', 'FETCH_GAS_FEE'));
    const gasFee: number = yield TBMConnector.fetchGasFee();
    logger("fetch gas fee", gasFee);
    yield put(actions.Token.fetchGasFee(gasFee));
  } catch (error) {
    console.error("fetch gas fee error");
    console.error(error);
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('FETCH_GAS_FEE'))
  }
}

function* getExchangeRates() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading('fetchExchangeRate', 'FETCH_EXCHANGE_RATES'));
    const rates: ExchangeRateProps = yield TBMConnector.fetchExchangeRate();
    logger("fetch exchange rates", rates, " zil: ", rates.zilliqa?.toString(10), " huny: ", rates.huny?.toString(10));
    yield put(actions.Token.fetchExchangeRate(rates));
  } catch (error) {
    console.error("fetch exchange rates error");
    console.error(error);
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('FETCH_EXCHANGE_RATES'))
  }
}

function* fetchGoldRushSalePrice() {
  try {
    yield waitForConnectorInit();
    const price: number = yield TBMConnector.getGoldRushSalePrice();
    yield put(actions.Token.setGoldRushSalePrice(price));
  } catch (error) {
    console.error("update gold rush price error");
    console.error(error);
  }
}

function* fetchGoldRushSaleActive() {
  try {
    yield waitForConnectorInit();
    const isActive: boolean = yield TBMConnector.checkGoldRushSaleActive();
    yield put(actions.Token.updateGoldRushSaleActive(isActive));
  } catch (error) {
    console.error("update gold rush sales error");
    console.error(error);
    yield put(actions.Token.updateGoldRushSaleActive(false));
  }
}

function* fetchGoldRushSupply() {
  try {
    yield waitForConnectorInit();
    const [goldRushTotalSupply, goldRushCurrentSupply]: Array<number> = yield TBMConnector.getGoldRushSupply();
    yield put(actions.Token.updateGoldRushSupply({ goldRushTotalSupply, goldRushCurrentSupply }));
  } catch (error) {
    console.error("update gold rush supply error");
    console.error(error);
    yield put(actions.Token.updateGoldRushSupply({ goldRushTotalSupply: 0, goldRushCurrentSupply: 0 }));
  }
}

function* fetchMoonBattleInfo() {
  try {
    yield waitForConnectorInit();
    const { wallet } = getWallet(yield select());
    yield put(actions.Layout.addBackgroundLoading('updateMoonBattleInfo', 'UPDATE_MOON_BATTLE_INFO'));
    const info: MoonBattleInfo = yield TBMConnector.getMoonBattleInfo(wallet);
    yield put(actions.Token.updateMoonBattleInfo(info));
  } catch (error) {
    console.error("update moon battle info error");
    console.error(error);
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('UPDATE_MOON_BATTLE_INFO'));
  }
}

function* teardown(sdk: Zilswap | null) {
  if (sdk) {
    yield call([sdk, sdk.teardown])
    TBMConnector.setSDK(null)
  }
  return null
}

type TxObservedPayload = { tx: ObservedTx, status: TxStatus, receipt?: TxReceipt }
const txObserver = (channel: Channel<TxObservedPayload>) => {
  return (tx: ObservedTx, status: TxStatus, receipt?: TxReceipt) => {
    logger('tx observed', tx)
    channel.put({ tx, status, receipt })
  }
}

function* txObserved(payload: TxObservedPayload) {
  logger('tx observed action', payload)
  const { tx, status, receipt } = payload
  const { currentMinting } = getToken(yield select());
  if (currentMinting?.hash === tx.hash && receipt && status === "confirmed") {
    const tokenIds: string[] = [];
    receipt.event_logs.forEach((event) => {
      if (event._eventname === "Mint") {
        tokenIds.push(
          event.params.find((param) => param.vname === "token_id")?.value
        );
      }
    });
    yield put(actions.Token.updateCurrentMinting({ ...tx, status, receipt }));
    yield put({ type: actions.Token.TokenActionTypes.UPDATE_TOKEN_IMAGES });
  }
}

let subscription: any = null
async function resubscribeEvents(network: Network) {
  if (!subscription) {
    subscription = ZolarEventSubscriber.subscribe(async (txEvents) => {
      AppStore.dispatch(actions.Game.reloadLeaderboard());
      // subscription for live event updates
      for (const event of txEvents) {
        switch (event.type) {
          case ZolarEventSubscriber.ZolarEventType.HunyStolen:
          case ZolarEventSubscriber.ZolarEventType.MetazoaKidnapped:
            AppStore.dispatch(actions.Game.processEvent(txEvents));
            return;
          case ZolarEventSubscriber.ZolarEventType.ActionStarted:
          case ZolarEventSubscriber.ZolarEventType.ActionConcluded:
          case ZolarEventSubscriber.ZolarEventType.HunyCaptured:
          default:
            // do something
            break;
        }
      }
    });
  }

  await ZolarEventSubscriber.initialize(network);
}

function* watchNetworkChange() {
  yield takeEvery(actions.Blockchain.BlockchainActionTypes.SET_NETWORK, function* (action: any) {
    logger("network changed", action.network);
    yield resubscribeEvents(action.network);
    yield getExchangeRates();
    yield fetchQuestState();
  })
}

function* watchInitialize() {
  const txChannel: Channel<TxObservedPayload> = channel()
  let sdk: Zilswap | null = null;
  try {
    yield takeEvery(txChannel, txObserved)
    while (true) {
      const action: ChainInitAction = yield take(actions.Blockchain.BlockchainActionTypes.CHAIN_INIT)
      sdk = yield call(teardown, sdk)
      sdk = yield call(initialize, action, txChannel)
    }
  } finally {
  }
}

function* watchZilPay() {
  let channel
  while (true) {
    try {
      const action: WalletAction = yield take(WalletActionTypes.WALLET_UPDATE)
      if (action.payload.wallet?.type === WalletConnectType.ZilPay) {
        logger('starting to watch zilpay')
        const zilPay = (yield call(getConnectedZilPay)) as unknown as any;
        channel = (yield call(zilPayObserver, zilPay)) as EventChannel<ConnectedWallet>;

        break
      }
    } catch (e) {
      console.warn('Watch Zilpay failed, will automatically retry on reconnect. Error:')
      console.warn(e)
    }
  }

  try {
    while (true) {
      const newWallet = (yield take(channel)) as ConnectedWallet
      const { wallet: oldWallet } = getWallet(yield select())
      if (oldWallet?.type !== WalletConnectType.ZilPay) continue
      if (newWallet.addressInfo.bech32 === oldWallet?.addressInfo.bech32 &&
        newWallet.network === oldWallet.network) continue
      yield put(actions.Blockchain.initialize({ wallet: newWallet, network: newWallet.network }))
    }
  } finally {
    logger("channel closed")
    channel.close()
  }
}

function* watchTokens() {
  yield takeLatest([
    actions.Token.TokenActionTypes.RELOAD_TOKENS,
    actions.Token.TokenActionTypes.UPDATE_TOKEN_IMAGES,
    actions.Wallet.WalletActionTypes.WALLET_UPDATE,
  ], updateTokens);
}


function* watchMetazoa() {
  yield takeLatest([
    actions.Token.TokenActionTypes.SET_METAZOA,
    actions.Token.TokenActionTypes.SET_STAKED_METAZOA,
    actions.Token.TokenActionTypes.SET_STAKED_METAZOA_BERRY,
    actions.Token.TokenActionTypes.SET_STAKED_METAZOA_GEODE,
    actions.Token.TokenActionTypes.SET_STAKED_METAZOA_SCRAP,
  ], fetchHunyState);
}

function* watchResource() {
  yield takeLatest([
    actions.Token.TokenActionTypes.REFETCH_RESOURCES,
    actions.Blockchain.BlockchainActionTypes.SET_NETWORK,
  ], updateResources);
}

function* watchTranscendenceInitState() {
  try {
    while (true) {
      yield take(actions.Token.TokenActionTypes.UPDATE_TRANSCENDENCE_INIT_STATE);
      yield call(fetchWhitelistCount);
      yield call(fetchTranscendenceApproval);
    }
  } catch (err) {
    console.warn(err);
  }
}

function* watchSupply() {
  yield takeLatest([
    actions.Wallet.WalletActionTypes.WALLET_UPDATE,
    actions.Blockchain.BlockchainActionTypes.UPDATE_SALE_STATE,
  ], fetchSupply);
}

function* watchWalletUpdate() {
  while (true) {
    try {
      yield take(actions.Wallet.WalletActionTypes.WALLET_UPDATE);
      yield call(fetchGoldRushSalePrice);
    } catch (err) {
      console.warn(err);
      yield delay(3000);
    }
  }
}

function* watchGoldRushSaleState() {
  while (true) {
    try {
      yield waitForConnectorInit();
      yield call(fetchGoldRushSaleActive);
      yield call(fetchGoldRushSupply);
    } catch (err) {
      console.error("Watch gold rush sale state error, Error:")
      console.error(err)
    } finally {
      yield race({
        delay: delay(30000),
        updateSaleState: take(actions.Blockchain.BlockchainActionTypes.UPDATE_SALE_STATE),
        updateWallet: take(actions.Wallet.WalletActionTypes.WALLET_UPDATE),
      })
    }
  }
}


function* watchMoonBattleInfo() {
  while (true) {
    try {
      yield waitForConnectorInit();
      yield call(fetchMoonBattleInfo);
    } catch (err) {
      console.error("Watch takers sale state error, Error:")
      console.error(err)
    } finally {
      yield race({
        delay: delay(15000),
        updateSaleState: take(actions.Blockchain.BlockchainActionTypes.UPDATE_SALE_STATE),
        updateWallet: take(actions.Wallet.WalletActionTypes.WALLET_UPDATE),
      })
    }
  }
}

function* init() {
  try {
    const network = getBlockchain(yield select()).network

    const sdk = new Zilswap(network, undefined, { rpcEndpoint: RPCEndpoints[network] });
    logger('zilswap sdk initialized', sdk.network)

    yield call([sdk, sdk.initialize])
    TBMConnector.setSDK(sdk)

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (!key) return;
      if (key.startsWith(`${LocalStorageKeys.MetazoaMetadata}:MainNet`)) {
        localStorage.removeItem(key);
      }
      else if (key.startsWith(`${LocalStorageKeys.MetazoaMetadata}:TestNet`)) {
        localStorage.removeItem(key);
      }
    }

    yield put(actions.Blockchain.ready());
    yield put(actions.Blockchain.updateSaleState());
    yield call(fetchGoldRushSalePrice);
    yield call(getTranscendenceStartBlock);
    yield call(getMinimumGasPrice);
    yield call(getExchangeRates);
    yield call(fetchQuestState);
    yield call(fetchRefineFeeState);
    if (!ZolarEventSubscriber.subscription) // wallet not connected
      yield call(resubscribeEvents, sdk.network);
  } catch (err) {
    console.warn("init error, Error:")
    console.warn(err)
  }
}

export default function* blockchainSaga() {
  logger("init blockchain saga");
  yield fork(watchInitialize);
  yield fork(watchNetworkChange);
  yield fork(watchZilPay);
  yield fork(watchSupply);
  yield fork(watchTranscendenceInitState);
  yield fork(watchWalletUpdate);
  yield fork(watchGoldRushSaleState);
  yield fork(watchMoonBattleInfo);
  yield fork(watchTokens);
  yield fork(watchMetazoa);
  yield fork(watchResource);
  yield init();
}
