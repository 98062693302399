import { Box, Button, Container, styled, SxProps, Typography } from "@mui/material";
import {
  BearFrameMini, BullFrameMini, MissionBlue, MissionLine, MissionRed,
  MissionStars
} from "assets";
import BearClaws from "assets/BearClaws.png";
import BearFrame from "assets/BearFrame.png";
import BullClaws from "assets/BullClaws.png";
import BullFrame from "assets/BullFrame.png";
import React from "react";
import { NavLink } from "react-router-dom";
import { AppTheme, SimpleMap } from "../../../utils/types";

const FirstMission: React.FC = () => {
  return (
    <Container maxWidth="xl" sx={styles.container}>
      <SvgBox>
        <StarBox>
          <MissionBlue />
        </StarBox>
        <StarBox2>
          <MissionRed />
        </StarBox2>
        <BackgroundStarBox>
          <MissionStars />
        </BackgroundStarBox>
      </SvgBox>
      <Box maxWidth="xl" sx={styles.contentBox} component="main">

        <Box
          width={"100%"}
          sx={styles.textColumn}
          textAlign="center"
        >
          <Typography
            variant="overline"
            color="secondary.main"
            sx={styles.subheader}
          >
            Mission I
          </Typography>
          <Typography variant="h1" color="primary">
            Takers of the Moon
          </Typography>
          <MissionLineBox>
            <MissionLine width="100%" />
          </MissionLineBox>
          <Typography width={{ xs: "100%", sm: "90%", md: "80%", }} component={"span"} variant="body1" color="primary" textAlign="center">

            The mission is simple. Harvest $HUNY and build your Metazoa army!
            <br />
            <br />
            Zolar’s cosmic balance hangs by a thread as the war of the reigning
            Metazoa factions races to a start. The strength of the Ursas lie in
            their numbers, multiplying loots of $HUNY at breakneck speed. But
            beware thieving Minos who lurk in the shadows. Masters of deception,
            they will stop at nothing to win.
            <br />
            <br />
            The fate of Zolar lies in your hands.

          </Typography>
          <Typography variant="h2" color="primary" sx={styles.header2}>
            Do you have what it takes?
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            target="_blank"
            href="https://docs.zolar.io/missions/takers-of-the-moon"
            sx={{ zIndex: 100 }}
          >
            <Typography variant="button" sx={styles.gradientText}>Whitepaper</Typography>
          </Button>
        </Box>


        <Box width="100%" sx={styles.missionCol}>
          <MissionContainer
            height={{
              sm: "600px",
              md: "724px",
            }}
            width="100%"
          >
            <FrameBox>
              <img src={BearFrame} alt="team-bears" width="100%" height="100%" />
              <ClawBox>
                <img src={BearClaws} alt="bear-claws" width="100%" height="100%" />
              </ClawBox>
            </FrameBox>
            <FrameBoxMini>
              <BearFrameMini width="100%" height="100%" />
              <ClawBox>
                <img src={BearClaws} alt="bear-claws" width="100%" height="100%" />
              </ClawBox>
            </FrameBoxMini>
            <FrameBox>
              <img src={BullFrame} alt="team-bulls" width="100%" height="100%" />
              <BullClawBox>
                <img
                  src={BullClaws}
                  alt="bull-claws"
                  width="100%"
                  height="100%"
                />
              </BullClawBox>
            </FrameBox>
            <FrameBoxMini>
              <BullFrameMini height="100%" width="100%" />
              <BullClawBox>
                <img src={BullClaws} alt="bull-claws" width="100%" height="100%" />
              </BullClawBox>


            </FrameBoxMini>
          </MissionContainer>
          <Button component={NavLink} to="/summon" size="medium" variant="outlined" color="secondary">
            <Typography variant="button" sx={styles.gradientText}>Summon now&nbsp;</Typography>
            <Box component="span" sx={styles.buttonGradient}>🔥</Box>
          </Button>
        </Box>


      </Box>
    </Container>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    padding: "0px!important",

    "*": {
      zIndex: 1,
    },

    // //DEBUG: Remove this
    // border: '1px solid yellow',
    // "*": {
    //   border: '1px solid red',
    // }
  },
  contentBox: {
    position: 'relative',
    padding: "76px 0px 100px",
    "@media (min-width:600px)": {
      padding: "40px 0px 80px",
    },
  },
  textColumn: {
    mx: 'auto',
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    gap: 4,
  },
  subheader: {
    textTransform: "uppercase",
    letterSpacing: "0.24em",
  },
  missionCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 0px",
    "@media (min-width:600px)": {
      padding: "120px 0px 80px",
    },
  },
  buttonGradient: {
    WebkitTextFillColor: '#000',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
};

const SvgBox = styled(Box)({
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
  height: "100%",
  pointerEvents: 'none',
});

const StarBox = styled(Box)({
  pointerEvents: 'none',
  background:
    "radial-gradient(42.7% 52.61% at 58.59% 47.39%, #FFFFFF 1.56%, #469BFF 15.29%, #0445EB 35.94%, rgba(0, 0, 0, 0) 96.45%)",
  position: "absolute",
  top: "150px",
  left: "-700px",
  width: "1496px",
  opacity: "40%",
  "@media (max-width:600px)": {
    display: "none",
  },
  "@media (max-width:900px)": {
    left: "-800px",
  },
});

const StarBox2 = styled(Box)({
  pointerEvents: 'none',
  background:
    "radial-gradient(42.41% 40.03% at 44.15% 51.87%, rgba(255, 255, 255, 0.734375) 0%, rgba(255, 122, 0, 0.734375) 16.67%, rgba(255, 2, 2, 0.479167) 56.9%, rgba(255, 0, 0, 0) 100%)",
  position: "absolute",
  top: "150px",
  right: "-700px",
  width: "1496px",
  opacity: "40%",
  "@media (max-width:600px)": {
    display: "none",
  },
  "@media (max-width:900px)": {
    right: "-800px",
  },
});
const BackgroundStarBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "900px",
  width: "100%",
  "@media (max-width:600px)": {
    display: "none",
  },
});
const MissionLineBox = styled(Box)({
  maxWidth: '320px',
  width: "30%",
  "@media (max-width:600px)": {
    width: "50%",
  },
});

const MissionContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  pointerEvents: 'none',
  "@media (max-width:600px)": {
    flexDirection: "column",
    marginTop: "50px",
  },
});

const FrameBox = styled(Box)({
  pointerEvents: 'none',
  position: "relative",
  width: "50%",
  "@media (max-width:600px)": {
    display: "none",
  },
});

const FrameBoxMini = styled(Box)({
  pointerEvents: 'none',
  position: "relative",
  width: "100%",
  padding: "48px 32px",
  "@media (max-width:400px)": {
    padding: "36px 24px",
  },
  "@media (min-width:600px)": {
    display: "none",
  },
});

const ClawBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "-200px",
  left: "0px",
  width: "60%",
  "@media (max-width:1200px)": {
    top: "-150px",
  },
  "@media (max-width:900px)": {
    top: "-100px",
  },
  "@media (max-width:600px)": {
    top: "-60px",
    left: "10px",
  },
});

const BullClawBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "-120px",
  right: "0px",
  width: "60%",
  "@media (max-width:1200px)": {
    top: "-100px",
  },
  "@media (max-width:900px)": {
    top: "-70px",
  },
  "@media (max-width:600px)": {
    top: "-50px",
  },
});

export default FirstMission;
