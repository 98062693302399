import { LocalStorageKeys } from "utils/constants"
import { MetazoaProfileActionTypes } from "./actions";
import { MetazoaProfileState } from "./types";


const loadSavedAccessToken = () => {
  try {
    let saved = localStorage.getItem(LocalStorageKeys.MetazoaAccessToken)
    return JSON.parse(saved!);
  } catch (error) {
    return undefined;
  }
}

const savedAccessToken = loadSavedAccessToken();

const initial_state: MetazoaProfileState = {
  profile: undefined,
  oAuth: savedAccessToken,
}

const reducer = (state: MetazoaProfileState = initial_state, action: any): MetazoaProfileState => {
  const { payload, type } = action;
  switch (type) {
    case MetazoaProfileActionTypes.UPDATE_PROFILE:
      return {
        ...state,
        profile: {
          ...payload
        }
      }
    
    case MetazoaProfileActionTypes.UPDATE_ACCESS_TOKEN: 
      localStorage.setItem(LocalStorageKeys.ZilPayConnected, JSON.stringify(payload));
      return {
        ...state,
        oAuth: payload,
      }

    default:
      return state;
  }
}

export default reducer;