import { Box, SxProps, Typography } from "@mui/material";
import BoxWithHeader from "components/BoxWithHeader";
import ContainedButton from "components/ContainedButton";
import { MultisigSetting } from "components/Guild/components/GuildConstants";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import { useNavigate } from "react-router-dom";
import { Guild } from "store/types";
import { AppTheme, SimpleMap } from "utils/types";

export interface BankInfoProps {
  guild: Guild,
  canCollectTax: boolean;
  hasGuild: boolean;
}

const BankInfo: React.FC<BankInfoProps> = (props: BankInfoProps) => {
  const { guild, canCollectTax, hasGuild } = props;
  const controlMode: string | boolean = guild.guildBank ? MultisigSetting[guild.guildBank.controlMode] : false

  const navigate = useNavigate();

  return (
    <BoxWithHeader
      headerText={<Typography variant="h3" color="primary" sx={styles.headerText}>Bank Info</Typography>}
      boxHeaderSx={styles.boxHeaderOverride} sx={styles.fullHeight}>
      <Box sx={styles.content}>
        <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>MultiSig Control</Typography>
        <TooltipZolar link={"#"} width="16px" height="16px">
          Multisig control will be applied to:
          <li>Deposits to / Withdrawals from Magic Hive</li>
          <li>Withdrawals</li>
          <li>Guild Bank Settings</li>
        </TooltipZolar>
        <Typography variant="h3" color="primary" sx={styles.multiSigOption}>MultiSig {controlMode === MultisigSetting.CaptainOnly ? 'Deactivated' : 'Activated'}</Typography>
        <Typography color="primary" sx={styles.description}>
          {!!controlMode && `${controlMode} required for withdrawals and guild settings.`}
        </Typography>

        {canCollectTax && hasGuild && <ContainedButton
          variant="contained"
          sx={styles.button}
          onClick={() => navigate(`/guilds/${guild.id}/bank/settings`)}
          disabled={!controlMode}
          disableFocusRipple>
          Settings
        </ContainedButton>}

        {/* {!hasGuild && <ContainedButton
          variant="contained"
          sx={styles.button}
          disabled={!controlMode}
          disableFocusRipple>
          Request To Join
        </ContainedButton>} */}
      </Box>
    </BoxWithHeader>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  content: {
    padding: '0 20px 4px 20px'
  },
  description: {
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '95px'
  },
  multiSigOption: {
    marginBottom: '12px'
  },
  headerText: {
    fontSize: '24px',
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  boxHeaderOverride: {
    "@media (max-width:1300px)": {
      height: 'auto !important'
    },
  },
  fullHeight: {
    height: '100%'
  },
  button: {
    fontSize: '18px',
    height: 48,
    width: '100%',
    padding: '14px 30px',
    fontWeight: 700,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
    "& div:first-of-type": {
      height: "inherit",
      // left: '-18px'
    }
  },
  overlineSmall: {
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: "0.2em"
  },
}

export default BankInfo;
