import React, { useMemo } from "react";
import { Box, Link, SxProps, Typography } from "@mui/material";
import { CompleteTag, LinesPhase2, LiveTag, LiveTagDated } from "assets";
import AlienBadge from "assets/icons/AlienBadge.png";
import GummyBadge from "assets/icons/GummyBadge.png";
import PhaseImage from "assets/icons/PhaseImage.png";
import PhaseImageGray from "assets/icons/PhaseImageGray.png";
import Upcoming from "assets/icons/Upcoming.png";
import ZombieBadge from "assets/icons/ZombieBadge.png";
import { NavLink } from "react-router-dom";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";
import dayjs from "dayjs";
import { SaleTime } from "utils/constants";
import useNetwork from 'utils/useNetwork';
import ProgressBar from "../components/ProgressBar";
import BurnBear from "./components/BurnBearModal";


const PhaseTwo: React.FC = () => {
  // TODO: Refactor NavLinks to be 1 component
  const network = useNetwork();
  const tokenState = useRedux((state) => state.token);
  const totalSupply = tokenState.totalSupply;
  const goldRushCurrentSupply = tokenState.goldRushCurrentSupply;

  // gold rush sale start & end times
  const saleStartTime = dayjs.unix(SaleTime[network].start / 1000);
  const saleEndTime = dayjs.unix(SaleTime[network].end / 1000);

  const currentTime = dayjs();

  const phaseEnded = useMemo(() => {
    return saleEndTime.isBefore(currentTime) || (totalSupply > 0 && totalSupply === goldRushCurrentSupply);

    // eslint-disable-next-line
  }, [currentTime, goldRushCurrentSupply, totalSupply])

  return (
    <Box>
      <Box maxWidth="xl" sx={styles.phaseContainer}>
        {/* Phase Nav */}
        <Box maxWidth="xl" sx={styles.phaseRow}>
          <Box sx={styles.phaseLinesBG}>
            <LinesPhase2 width="100%" />
          </Box>
          <Box component={NavLink} to="/summon/elders" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 1</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>the elders</Typography>
            <CompleteTag />
          </Box>
          <Box component={NavLink} to="/summon/transcendence" sx={styles.phaseBox}>
            <img src={PhaseImage} alt="phase" />
            <Typography variant="h3" color="primary" >Phase 2</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheader}>The Transcendence</Typography>
            <CompleteTag/>
          </Box>
          <Box component={NavLink} to="/summon/gold-rush" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 3</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>The gold rush</Typography>
            {phaseEnded
              ? <CompleteTag/>
              : currentTime.isAfter(saleStartTime)
                ? <LiveTagDated/>
                : <img src={Upcoming} alt="upcoming"/>
            }
          </Box>
          <Box component={NavLink} to="/summon/takers-of-the-moon" sx={styles.phaseBox}>
            <img src={PhaseImageGray} alt="phase" />
            <Typography variant="h3" color="primary" sx={styles.phaseNavLinkInactive}>Phase 4</Typography>
            <Typography variant="overline" color="primary" sx={styles.subheaderInactive}>TAKERS OF THE MOON</Typography>
            <LiveTag />
          </Box>
        </Box>
        <ProgressBar phase={2}/>
      </Box>
      <Box sx={styles.phaseHeader}>
        <Typography variant="h3" color="primary">
          Phase 2 summoned: 1000/1000 &nbsp;&nbsp; | &nbsp;&nbsp; Total Gen-0 summoned: {totalSupply}/1288
        </Typography>
      </Box>
      <Box sx={styles.phaseHeader2}>
        <Typography variant="subtitle1" color="primary">
          Phase 2 summoned: 1000/1000<br />
          Total Gen-0 summoned: {totalSupply}/1288
        </Typography>
      </Box>
      <Box sx={styles.phaseContentBox}>
        <Typography variant="h1" color="primary" sx={styles.phaseContentHeader}>
          The Transcendence
        </Typography>
        <Typography variant="body1" color="primary" sx={styles.phaseContentBody}>
          Brave warriors, a fork has appeared in your journey and the time to choose has arrived.
          <br />
          <br />
          There are two paths, each promising unique rewards and treasures while demanding different
          sacrifices. Will you stay behind as the final defenders and stoic stewards of ARKY? If you
          choose this path of honoring tradition, you will continue to reap any rewards that are
          still to come.
          <br />
          <br />
          Or, will you abandon the motherland and journey to Zolar to face the unknown? Should you
          choose the path of Transcendence, brace yourself for adventure and discovery while you
          battle over Huny for ultimate glory!
        </Typography>
        <Link
          sx={styles.learnMore}
          target="_blank"
          href="https://docs.zolar.io/overview/creatures/collection-ii-metazoa/summoning#phase-2-or-the-transcendence"
        >
          Learn More
        </Link>
        <Typography variant="subtitle1" sx={styles.countdownText}>
          Phase 2 has ended
        </Typography>
        {
          phaseEnded
          ? null
          : <BurnBear />
        }
        <Box sx={styles.badgeContainer}>
          <Box sx={styles.badge}>
            <img src={AlienBadge} alt="alien-badge" width="70px" height="70px" />
            <img src={ZombieBadge} alt="zombie-badge" width="70px" height="70px" />
            <img src={GummyBadge} alt="gummy-badge" width="70px" height="70px" />
          </Box>
          <Typography variant="subtitle1" color="primary">
            Rare bears — Aliens, Zombies, and Gummies — your legacy lives on! Transcend and be awarded a bespoke medallion when you emerge as a new Metazoa.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};



const styles: SimpleMap<SxProps<AppTheme>> = {
  subheader: {
    fontSize: "0.75rem",
    letterSpacing: "0.2rem",
    lineHeight: "1.125rem",
    marginBottom: "5px",
  },
  subheaderInactive: {
    fontSize: "0.75rem",
    letterSpacing: "0.2rem",
    lineHeight: "1.125rem",
    marginBottom: "5px",
    opacity: "60%",
  },
  // Phase Nav
  phaseContainer: {
    padding: '0px 90px',
    position: 'relative',
    margin: '0px 20px 20px',
    overflow: 'hidden',
    "@media (max-width:1200px)": {
      padding: '0px 4px',
      overflowX: 'scroll',
      marginX: '100px',
    },
    "@media (max-width:900px)": {
      marginX: '40px',
    },
  },
  phaseLinesBG: {
    pointerEvents: 'none',
    minWidth: '1000px',
    position: 'absolute',
    width: '90%',
    left: '90px',
    top: '33px',
    "@media (max-width:1200px)": {
      left: '20px',
    },
  },
  phaseRow: {
    minWidth: '1000px',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: "60px",
  },
  phaseBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
  },
  phaseNavLinkInactive: {
    opacity: "60%",
  },
  // Phase content
  phaseHeader: {
    textAlign: 'center',
    marginX: "24px",
    marginBottom: "20px",
    "@media (max-width:900px)": {
      display: 'none'
    },
  },
  phaseHeader2: {
    textAlign: 'center',
    marginX: "24px",
    marginBottom: "20px",
    "@media (min-width:900px)": {
      display: "none",
    },
  },
  phaseContentBox: {
    position: "relative",
    borderRadius: "16px",
    margin: "0px 110px",
    padding: "40px 115px",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    minHeight: "780px",
    "@media (max-width:900px)": {
      margin: "0px 24px",
      padding: "24px",
    },
  },
  phaseContentHeader: {
    zIndex: 1,
    opacity: "100%",
    "@media (max-width:600px)": {
      fontSize: '2rem',
      textAlign: 'center',
    },
    marginBottom: '10px',
  },
  phaseContentBody: {
    zIndex: 1,
    textAlign: 'center',
    marginY: '30px',
  },
  phaseLink: {
    color: '#fff',
    "@media (max-width:600px)": {
      display: 'block',
    },
  },
  badgeContainer: {
    position: "relative",
    borderRadius: "16px",
    padding: "20px",
    marginY: '15px',
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: 'left',
    minHeight: "135px",
    width: '100%',
    "@media (max-width:900px)": {
      flexDirection: "column",
      margin: "24px",
      padding: "24px",
    },
  },
  badges: {
    display: 'flex',
    flexDirection: 'row'
  },
  badge: {
    minWidth: '245px',
    padding: '5px 20px',
    marginRight: '50px',
    background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    "@media (max-width:1200px)": {
      marginRight: '30px',
    },
    "@media (max-width:900px)": {
      margin: "24px",
      padding: "24px",
    },
  },
  //* Wallet Connected
  statsContainer: {
    position: "relative",
    borderRadius: "16px",
    marginY: '15px',
    padding: "30px 200px 20px",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    width: '100%',
    minHeight: '200px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    "@media (max-width:1200px)": {
      paddingX: "100px",
    },
    "@media (max-width:900px)": {
      margin: "24px",
      padding: "24px",
    },
  },
  statsContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    minHeight: "280px",

  },
  statRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  learnMore: {
    marginBottom: '20px',
  },
  countdownText: {
    color: "#FF8952",
    marginBottom: '10px',
    maxWidth: 700,
  },
};

export default PhaseTwo;
