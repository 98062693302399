import { Box, Button, Checkbox, CircularProgress, Divider, Grow, IconButton, Link, styled, SxProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { CheckedIcon, GuildSettingActive, GuildSettingInactive, Huny, LockedIcon, UncheckedIcon } from "assets";
import GuildCaptainFrame from "assets/GuildCaptainFrame.svg";
import GuildCommanderFrame from "assets/GuildCommanderFrame.svg";
import GuildCommanderPromoteFrame from "assets/GuildCommanderPromoteFrame.svg";
import GuildFrame from "assets/GuildFrame.svg";
import GuildMemberFrame from "assets/GuildMemberFrame.svg";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import BigNumber from "bignumber.js";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import ContainedButton from "components/ContainedButton";
import { DEFAULT_SRC } from "components/Guild/components/GuildConstants";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import WarningBox from "components/WarningBox";
import { logger, waitForTx } from "core/utilities";
import { MetazoaClient } from "core/utilities/metazoa";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { Guild, OAuth, Profile } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { BIG_ZERO, Decimals } from "utils/constants";
import { formatGuildHuny } from "utils/strings/strings";
import { combineStyles } from "utils/themeUtilities";
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import RequestButton from "../RequestButton";
import LeaveRequestButton from "./LeaveRequestButton";
import PromoteRequestDialog from "./PromoteRequestDialog";
import RenderMemberMetazoa from "./RenderMemberMetazoa";

export interface MemberListProps {
  guild: Guild;
}

const MemberList: React.FC<MemberListProps> = (props: MemberListProps) => {
  const { guild } = props;
  const { id, members, leaderAddress, commanderAddresses, guildBank } = guild;
  const councilAddresses: string[] = [leaderAddress, ...commanderAddresses]
  const initialMemberRemovalState = {
    members: [] as string[],
    guildId: id.toString(),
  }
  const hasGuildBank: boolean = !!guildBank ?? false;

  const network = useNetwork();
  const dispatch = useDispatch();
  const toaster = useToaster();
  const metazoaProfileState = useSelector(getProfile);
  const wallet = useRedux((state) => state.wallet.wallet);

  // STATES / HOOKS -----------------

  const [showRequestDialog, setShowRequestDialog] = useState<boolean>(false);
  const [editMemberActive, setEditMemberActive] = useState<boolean>(false);
  const [currentMember, setCurrentMember] = useState<Profile>({} as Profile);
  const [removeMembers, setRemoveMembers] = useState<typeof initialMemberRemovalState>(initialMemberRemovalState);
  const [runRemoveMember, loadingRemoveMember] = useAsyncTask("handleOnRemoveMembers", (error) => {
    toaster(error?.message ?? "Error Removing Member(s)");
  });
  const [requestInitiated, setRequestInitiated] = useState<boolean>(false);

  const walletHolder = useMemo(() => {
    const addr = wallet?.addressInfo.byte20!.toLocaleLowerCase()!

    if (!members || !leaderAddress || !commanderAddresses) return;

    //INFO: Wallet holder attributes
    const isLeader: boolean = leaderAddress === addr ?? false;
    const isOfficer: boolean = commanderAddresses.includes(addr)
    const canEditMembers: boolean = isLeader || isOfficer;
    const canPromote: boolean = isLeader;
    const unremovableMemberAddresses: string[] = Array.from(new Set([...commanderAddresses, leaderAddress]));
    const removableMembers: Profile[] = canEditMembers ? members.filter(mem => !unremovableMemberAddresses.includes(mem.address)) : [];

    //INFO: Toggle editMemberActive off for members 
    if (!canEditMembers && editMemberActive) setEditMemberActive(false);

    logger("debug-remove", {
      members,
      unremovableMemberAddresses,
      removableMembers
    })

    return { addr, isLeader, isOfficer, canEditMembers, canPromote, removableMembers }
    // eslint-disable-next-line 
  }, [wallet, leaderAddress, commanderAddresses, members, hasGuildBank])

  // FUNCTIONS ----------------------

  const requestRemoveMembers = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!removeMembers) throw new Error("No members selected for removal");

    if (hasGuildBank && guildBank && guildBank.address) {
      //TODO: Bank RPC for guild member bulk removal
      logger("debug-remove", "isLoading", loadingRemoveMember)
      await removeMembers.members.forEach(async (member) => {
        const memberRemovalTx = await TBMConnector.handleMemberRemoval(toBech32Address(guildBank.address), member);
        toaster(`Submitted Guild Member Removal `, { hash: memberRemovalTx.id! });
        if (memberRemovalTx.isRejected() || !memberRemovalTx.id) throw new Error("Submitted transaction was rejected.");

        const tx = await waitForTx(memberRemovalTx.id);
        const tbmConnector = TBMConnector.getSDK();
        const txn = await tbmConnector.zilliqa.blockchain.getTransaction(memberRemovalTx.id);
        const receipt = txn.getReceipt();
        if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

        setRequestInitiated(false);
        setRemoveMembers(initialMemberRemovalState);
        dispatch(actions.Guild.reloadGuild(guild));
        toaster(`Guild Member Removed `, { hash: memberRemovalTx.id!, overridePersist: true });
      });
    }
    else {
      const metazoaClient = new MetazoaClient(network);
      const { oAuth } = metazoaProfileState;
      let checkedOAuth: OAuth | undefined = oAuth;
      if (!oAuth?.access_token || oAuth.address !== wallet?.addressInfo.bech32 || (oAuth && dayjs(oAuth?.expires_at * 1000).isBefore(dayjs()))) {
        const { result } = await metazoaClient.metazoaLogin(wallet!, window.location.hostname);
        dispatch(actions.Profile.updateAccessToken(result));
        checkedOAuth = result;
      }

      if (!!removeMembers) {
        await metazoaClient.removeGuildMember(guild.id, { member: removeMembers.members.join(','), guildId: removeMembers.guildId } as MetazoaClient.RemoveGuildMemberData, checkedOAuth!);
        toaster(`${removeMembers.members.length} Member(s) Removed`);
        dispatch(actions.Guild.reloadGuild(guild));
        setRemoveMembers(initialMemberRemovalState);
        setRequestInitiated(false);
      }
    }
  }

  // EVENT HANDLERS -----------------

  const handleOnClose = () => {
    setShowRequestDialog(false);
  }

  const handleOnEditMembers = () => {
    setEditMemberActive(!editMemberActive);
    setRemoveMembers({
      ...removeMembers,
      members: []
    })
  }

  const handleOnReloadPromotion = () => {
    setCurrentMember({} as Profile);
    dispatch(actions.Guild.reloadGuild(guild));
  }

  const handleChangeEditMembers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isCurrentlySelected: boolean = (removeMembers.members.includes(event.target.value));
    const updatedRemovalList: string[] = isCurrentlySelected ? removeMembers.members.filter(m => m !== event.target.value) : Array.from(new Set([...removeMembers.members, event.target.value]));

    setRemoveMembers({
      ...removeMembers,
      members: updatedRemovalList,
    })
  };

  const handleOnRemoveMembers = () => {
    setRequestInitiated(true);
    runRemoveMember(async () => {
      await requestRemoveMembers();
    });
  }

  // --------------------------------

  const isLoading: boolean = loadingRemoveMember || requestInitiated;

  return (
    <>
      <Box sx={styles.memberListRoot}>
        <Box sx={styles.memberListHeader}>
          <Typography variant="h3" color="primary" sx={styles.memberListTitle}>
            Member list ({guild.members?.length ?? guild.currentSize}/{guild.maxCapacity})
          </Typography>

          {/* //INFO: Edit members icon */}
          {walletHolder?.canEditMembers &&
            <IconButton onClick={handleOnEditMembers}>
              {editMemberActive ? <GuildSettingActive /> : <GuildSettingInactive />}
            </IconButton>
          }

          <Divider sx={styles.memberListDivider} />

          {!wallet
            ? <ConnectWalletButtonCTA sx={styles.memberListConnectButton} />
            : <RequestButton guild={guild} />
          }
        </Box>

        <Typography variant="body1" color="primary" sx={styles.memberListDiscord}>
          Captains and Officers are recommended to update your
          {" "}
          <RouterLink to="/update-profile" className="externalLink">Discord handle</RouterLink>
          {" "}
          to access your private Guild discord channel.
          <br />
          <Link
            className="externalLink"
            target="_blank"
            href="https://docs.zolar.io/stations/guilds"
          >
            Learn More
          </Link>
        </Typography>
      </Box>

      {/* Warning box */}
      {editMemberActive && (
        <WarningBox sx={styles.warningBox}>
          <Box sx={{ display: 'flex' }}>
            <Grow
              unmountOnExit
              in={editMemberActive}
              style={{ transformOrigin: '0 0 0' }}
              {...(editMemberActive ? { timeout: 1000 } : {})}
            >
              {/* <WarningBox sx={styles.warningBox}> */}
              <Typography variant="body1" color="primary" align="left">
                You may now remove members from your guild.<br />
                <span style={{ fontWeight: 'bold' }}>Once confirmed, this cannot be changed or undone.</span>
              </Typography>
            </Grow>
          </Box>
        </WarningBox>
      )}

      {/* members */}
      <Box sx={styles.memberListWrapper}>
        {!guild.members
          ? (<Box display="flex" alignItems="center" justifyContent="center" mt="80px" width='100%'>
            <CircularProgress size={36} />
          </Box>
          )
          : (<>
            <Box sx={styles.memberListGrid}>
              {members
                .map((member, index) => {
                  let memberLeader: boolean = leaderAddress === member.address;
                  let memberCommander: boolean = commanderAddresses.includes(member.address);
                  return (
                    <Box key={index} sx={combineStyles(styles.memberListMember, { ...(memberLeader) && styles.memberListLeader }, {
                      ...(editMemberActive) && {
                        background: (!!removeMembers.members.includes(member.address)) ? "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)" : "transparent",
                        "&:hover": { background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)" }
                      }
                    })}
                    >
                      {/* Avatar Image */}
                      <Box sx={styles.memberListImgFrame}>
                        <Box component="img" src={member.avatarUrl ?? DefaultUserProfile} sx={styles.memberListImg} onError={DEFAULT_SRC} />
                      </Box>

                      <Box sx={styles.memberListDetails}>
                        <Box sx={combineStyles(
                          styles.memberListStatusFrame,
                          { ...councilAddresses.includes(member.address) && (memberLeader ? styles.memberListCaptainFrame : styles.memberListCommanderFrame) },
                          { ...(walletHolder?.canPromote && !councilAddresses.includes(member.address) && councilAddresses.length < 3) && styles.memberListCommanderPromoteFrame },
                        )}>
                          {(walletHolder?.canPromote && (commanderAddresses.length < 2 || memberCommander)) && (
                            <Box
                              onClick={() => { if (!editMemberActive) setShowRequestDialog(true); setCurrentMember(member) }}
                              sx={styles.promoteClick}>
                              &nbsp;
                            </Box>
                          )}

                          <Box sx={styles.memberListInfo}>
                            {/* Username */}
                            <Typography variant="body2" color="primary" sx={styles.memberListInfoUsername}>{member.username ?? truncate(toBech32Address(member.address))}</Typography>

                            {/* Level + Huny */}
                            <Box>
                              <Typography variant="body1" color="secondary" sx={styles.memberListInfoLvl}>Level {member.level ?? 1}</Typography>

                              <Typography variant="body1" color="primary" sx={styles.memberListStatValue}>
                                <Huny />
                                {" "}
                                {formatGuildHuny(BigNumber.sum(member.totalHunyHolding, member.hivePool?.userHunyReserves.shiftedBy(-Decimals.HUNY) ?? BIG_ZERO))}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <RenderMemberMetazoa metazoaArray={member.metadata} stakedMetazoa={member.stakedMetazoa} />
                      </Box>

                      {walletHolder && walletHolder?.canEditMembers && editMemberActive && (
                        !walletHolder.removableMembers.includes(member)
                          ? (
                            <TooltipZolar titleIcon={false} topPlacement
                              sx={styles.removeTooltip}
                              buttonIcon={<LockedIcon width="20px" height="20px" className="lockedIcon" />}>
                              {member.address === leaderAddress && ("You cannot remove the guild’s captain.")}
                              {commanderAddresses.includes(member.address) && ("To remove an officer, you must first demote them.")}
                            </TooltipZolar>
                          )
                          : (
                            <GuildRadio
                              onChange={handleChangeEditMembers}
                              value={member.address}
                              checked={removeMembers.members.includes(member.address)}
                              icon={walletHolder.removableMembers.includes(member) ? <UncheckedIcon width="20px" height="20px" /> : <LockedIcon width="20px" height="20px" />}
                              checkedIcon={<CheckedIcon width="20px" height="20px" />}
                              sx={styles.radioButton}
                              disabled={!walletHolder.removableMembers.includes(member)}
                            />
                          )
                      )}

                    </Box>
                  )
                })}

              <PromoteRequestDialog
                open={showRequestDialog}
                onClose={handleOnClose}
                guild={guild}
                reload={handleOnReloadPromotion}
                member={currentMember}
              />

            </Box>
            {/* //INFO: Button row for Officers only  */}
            {!editMemberActive && (walletHolder?.isOfficer) && (
              <Box sx={styles.rankersArea}>
                <LeaveRequestButton guild={guild} />
              </Box>
            )}
          </>)
        }
      </Box>

      {/* //INFO: Button row for Edit Member(s)  */}
      {editMemberActive &&
        <Box sx={styles.row}>
          <Button variant="outlined" color="secondary" onClick={handleOnEditMembers}>
            <Typography variant="button" color="primary" sx={styles.gradientText}>Go Back</Typography>
          </Button>
          <ContainedButton sx={{ minWidth: '200px' }} onClick={handleOnRemoveMembers} disabled={isLoading || removeMembers.members.length < 1}>{isLoading ? <CircularProgress size={18} /> : `Remove ${removeMembers.members.length > 1 ? 'Members' : 'Member'}`}</ContainedButton>
        </Box>
      }
    </>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  // MemberList
  memberListRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    placeContent: "start",
    placeItems: "start",
    padding: "1vh 0",
    gap: "0.2em",
  },

  memberListHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    placeItems: "center",
  },

  memberListTitle: {
    color: "#AEF1EE",
    whiteSpace: "nowrap",
  },

  memberListDivider: {
    background: "#AEF1EE",
    width: "100%",
    flexShrink: "inherit",
    marginX: "20px",
    marginLeft: "16px",
  },

  memberListConnectButton: {
    button: {
      textTransform: "uppercase",
      minWidth: "280px",
      minHeight: "auto",
      height: "48px",
    },

    "& .MuiBox-root": {
      padding: 0,
    },

    " .MuiButton-root": {
      fontSize: "18px",
      lineHeight: "28px",
      whiteSpace: "nowrap",
    },

    "@media (max-width:1100px)": {
      ".MuiButton-root": {
        minWidth: "min-content",
        height: 40,
      },
    },

    "@media (max-width:768px)": {
      ".MuiButton-root": {
        fontSize: "0.8rem",
        lineHeight: "1rem",
        whiteSpace: "break-spaces",
      },
    },
  },

  memberListDiscord: {
    fontSize: "12px!important",
    lineHeight: "18px!important",
    color: "#FEFEFE",
    width: '60%',
    "@media only screen and (max-width: 1100px)": {
      width: '100%',
    },
    ".externalLink": {
      color: "#FEFEFE",
      fontWeight: 700,
    },
  },

  // GuildMemberList
  memberListWrapper: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    overflowX: "hidden",
    "@media only screen and (max-width: 1280px)": {
      display: "flex",
      justifyContent: "center",
      flexFlow: "center",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
    },
  },

  memberListGrid: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    columnGap: "1.25vw",
    rowGap: "2.5vh",
    scrollSnapType: "y mandatory",
    scrollSnapPointsY: "repeat(3rem)",
    width: "100%",
    "@media only screen and (min-width: 1100px)": {
      rowGap: "2vh",
      gridTemplateColumns: "repeat(2, 1fr)",
    },

    "> *": {
      height: "8rem",
    },

    ">:last-child": {
      marginBottom: "5vh",
    },
  },

  memberListMember: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: 'nowrap',
    justifyContent: "center",
    placeItems: "center",
    placeContent: "center",
    gap: "0.5em",
    scrollSnapAlign: "start",
    borderRadius: "16px",
    padding: "8px 10px 10px",
    "@media only screen and (min-width: 1280px)": {
      justifyContent: "start",
      placeItems: "center",
      placeContent: "start",
    },
  },

  memberListLeader: {
    'gridColumnStart': 1,
    'gridRowStart': 1,
  },

  memberListImgFrame: {
    position: 'relative',
    height: '100%',
    width: "110px",
    backgroundImage: `url(${GuildMemberFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "160% 140%",
    backgroundPosition: "center",
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },

  memberListImg: {
    height: '90%',
    width: '90%',
    borderRadius: "16px",
    objectFit: "contain",
    boxShadow: "0px 0px 12px 0px #00F0FF",
  },

  memberListDetails: {
    flex: 1,
    height: "100%",
    display: "inline-flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "start",
    position: "relative",
  },

  memberListStatusFrame: {
    flex: 1,
    width: "100%",
    minWidth: "65vw",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPositionX: "left",
    backgroundImage: `url(${GuildFrame})`,
    position: "relative",
    display: "inline-flex",
    flexDirection: "column",
    placeContent: "start",
    placeItems: "start",
    bottom: "4%",
    top: 0,

    "@media only screen and (min-width: 450px)": {
      backgroundSize: "85% 80%",
      top: '-5%',
    },

    "@media only screen and (min-width: 680px)": {
      backgroundPositionX: "left",
      position: 'relative',
      minWidth: "calc(50vw / 1.2)",
      top: 0,
    },

    "@media only screen and (min-width: 1100px)": {
      backgroundSize: "100% 80%",
      minWidth: "unset",
      position: 'absolute',
    },

    "@media only screen and (min-width: 1280px)": {
      width: "100%",
    },
  },

  memberListCaptainFrame: {
    backgroundImage: `url(${GuildCaptainFrame})`,
  },

  memberListCommanderFrame: {
    backgroundImage: `url(${GuildCommanderFrame})`,
  },
  memberListCommanderPromoteFrame: {
    backgroundImage: `url(${GuildCommanderPromoteFrame})`,
  },

  promoteClick: {
    position: 'absolute',
    right: '20%',
    minWidth: '5em',

    "&:hover": {
      cursor: 'pointer',
    },

    "@media only screen and (min-width: 1100px)": {
      right: 0,
    },
  },

  memberListInfo: {
    flex: 1,
    position: "absolute",
    left: "18%",
    top: "5%",
    maxWidth: "75%",
    width: "inherit",

    "> div": {
      width: "100%",
      display: "inline-flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      whiteSpace: "nowrap",
      gap: "5%",
      justifyContent: "space-between",
    },
    "@media only screen and (max-width: 450px)": {
      top: '10% !important',
      left: '18% !important',
      maxWidth: '80% !important',
    },
    "@media only screen and (min-width: 600px)": {
      top: "15%",
      left: "13%",
      maxWidth: "58%",
      paddingRight: "6%",
    },
    "@media only screen and (max-width: 1000px)": {
      top: "18%",
      left: "15%",
      maxWidth: "65%",
    },
    "@media only screen and (min-width: 1100px)": {
      left: "18%",
      top: "15%",
      maxWidth: "78%",
    },
    "@media only screen and (min-width: 1360px)": {
      top: "15%",
    },

  },

  memberListInfoUsername: {
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    width: '70%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  memberListInfoLvl: {
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
  },

  memberListStatValue: {
    fontSize: "14px!important",
    lineHeight: "16px!important",
    color: "#AEF1EE",

    "& svg": {
      verticalAlign: "bottom",
      marginBottom: "2px",
      height: 14,
      width: 14,
    },
  },

  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  radioButton: {
    color: '#fff',
  },

  rankersArea: {
    display: 'flex',
    flexDirection: 'flex-row',
    placeContent: 'end',
    placeItems: 'end',
    marginTop: '2em',
    width: '100%',
  },

  warningBox: {
    transition: "opacity 1s ease-out",
    width: '100%',
    marginBottom: '44px',
    "> div": {
      padding: "16px 40px",
    }
  },

  debugMember: {
    borderRadius: '16px',
    background: 'radial-gradient(151.44% 152.65% at 49.92% 12.81%, rgba(255, 234, 180, 0.1) 9.64%, rgba(252, 206, 88, 0.1) 43.75%)',
    display: 'flex',
    placeContent: 'center!important',
    placeItems: 'center!important',
  },

  removeTooltip: {
    padding: "20px",
    width: "263px",

    // ".lockedIcon path":{
    //   background:'red',
    // }
  }
};

const GuildRadio = styled(Checkbox)({
  height: '100%',
  borderRadius: '2px',
  '&.Mui-checked': {
    color: '#27ED82',
  },
  '&.Mui-disabled': {
    color: '#ffffff40'
  },
  '&:hover': {
    backgroundColor: 'transparent',
  }
})


export default MemberList;