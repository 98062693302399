import { Box, Link, SxProps, Typography } from "@mui/material";
import { GameEvent } from "store/types";
import { ZolarEventSubscriber } from "tbm";
import { Decimals } from "utils/constants";
import { getExplorerLink } from "utils/strings/links";
import { formatIntegerNumber } from "utils/strings/strings";
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";
import StripeHeader from "../StripeHeader";


const LiveUpdate: React.FC = () => {
  const events = useRedux((state) => state.game.liveEvents);
  const network = useRedux((state) => state.blockchain.network);

  const getAddressLink = (bech32Address: string) => {
    return (
      <Link target="_blank" 
        href={getExplorerLink("address", bech32Address, network)}
      >
        {truncate(bech32Address)}
      </Link>
    )
  }

  const getMessage = (event: GameEvent) => {
    switch (event.type) {
      case ZolarEventSubscriber.ZolarEventType.HunyStolen:
        return (
          <>
            {formatIntegerNumber(event.amount?.shiftedBy(-Decimals.HUNY))} $HUNY got stolen from
            {" "}
            {getAddressLink(event.from)}
          </>
        )
      case ZolarEventSubscriber.ZolarEventType.MetazoaKidnapped:
        return (
          <>
            {getAddressLink(event.to)}
            {" "}
            kidnapped Metazoa {event.tokenId}
            {" "}
            from
            {" "}
            {getAddressLink(event.from)}
          </>
        )
      default: return null;
    }
  }

  return (
    <Box sx={styles.liveUpdateWrapper}>
      <Box sx={styles.liveUpdateContainer}>
        <StripeHeader>LIVE FROM THE BATTLEFIELD</StripeHeader>
        <Box sx={styles.updateRowsWrapper}>
          {events.map(event => (
            <Box sx={styles.liveUpdateRow}>
              <Typography variant="body1" color="primary" sx={styles.liveUpdateText}>
                {getMessage(event)}
              </Typography>
              <Typography variant="body1" color="primary" sx={styles.time}>&nbsp;• {event.timestamp.fromNow()}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  liveUpdateWrapper: {
    minWidth: '720px',
    marginTop: '40px',
    position: 'relative',
    zIndex: 2,
    "@media (max-width: 1200px)": {
      marginTop: '20px',
    }
  },
  liveUpdateContainer: {
    width: '100%',
    borderRadius: '16px',
    border: '1px solid #AEF1EE33',
    background: 'linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, #000010 100%)',
    padding: "40px",
  },
  updateRowsWrapper: {
    marginTop: '30px',
  },
  liveUpdateRow: {
    width: '100%',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    paddingY: '5px',
    borderRadius: '16px',
    border: '1px solid #AEF1EE33',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginY: '10px',
  },
  liveUpdateText: {
    fontSize: '0.9rem',
  },
  time: {
    fontSize: '0.9rem',
    opacity: 0.6,
  },
};

export default LiveUpdate;
