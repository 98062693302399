import { Box, Checkbox, CircularProgress, Link, SvgIcon, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CheckedIcon, ExternalLink, Huny, UncheckedIcon } from "assets";
import MinoDefault from "assets/dp-mino.png";
import UrsaDefault from "assets/dp-ursa.png";
import MinoIcon from "assets/icons/Minos.svg";
import UrsaIcon from "assets/icons/Ursas.svg";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { PROFESSIONS, STAT_PROFESSION } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { QuestConstants } from "components/Quest";
import { LocationMetadata } from "components/Quest/QuestConstants";
import { logger, waitForTx } from "core/utilities";
import React, { FC, Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { NftMetadata } from "store/types";
import { TBMConnector } from "tbm";
import { useBlockTime, useTaskSubscriber, useToaster } from "utils";
import { BIG_ZERO, Decimals, MAX_ZOA, QUEST_EPOCH, RESOURCE_PER_EPOCH } from "utils/constants";
import { bnOrZero } from "utils/strings";
import { getExplorerLink } from "utils/strings/links";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";
import { calculateHarvestFee } from "./helper";

export interface ModalProps {
  location: LocationMetadata;
  open: boolean;
  onClose: () => void;
  keyStat: string;
}

// export const RETURN_COST: number = 200;

const ReturnToBaseDialog: FC<ModalProps> = ({
  open,
  onClose,
  location,
  keyStat
}: ModalProps) => {

  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();

  const [, currentBlock] = useBlockTime();
  const [loadingTokens] = useTaskSubscriber("updateTokens");
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const questState = useRedux((state) => state.quest.quests);

  const returnCost = useMemo(() => {
    if (!network || !questState[keyStat]) return BIG_ZERO;

    logger("debug-quests", {
      attr: keyStat,
      quest: questState,
      fee: questState[keyStat].returnFee
    })

    const returnCost = bnOrZero(questState[keyStat].returnFee ?? 0);

    return returnCost;
  }, [keyStat, network, questState])

  const {
    stakedMetazoaBerry: stakedTokensBerry,
    stakedMetazoaGeode: stakedTokensGeode,
    stakedMetazoaScrap: stakedTokensScrap,
    HunyTokens,
  } = tokenState

  // STATES / HOOKS -----------------

  const [selectedMetazoa, setSelectedMetazoa] = useState<string[]>([]);
  const [canAfford, setCanAfford] = useState<boolean>(true);
  const [returnBaseComplete, setReturnBaseComplete] = useState<boolean>(false);
  const [returnBaseTxHash, setReturnBaseTxHash] = useState<string>("");
  const [runReturnBase, loadingReturnBase] = useAsyncTask("returnBase", (error) => {
    toaster(error?.message ?? "Error Returning to Base");
  });
  const isLoading: boolean = loadingReturnBase;
  const locationType = location.keyStat?.type ?? "";
  const questLocation = useMemo(() => questState[locationType], [questState, locationType]);

  const stakedTokens: SimpleMap<NftMetadata> = useMemo(() => {
    let tokenArray: SimpleMap<NftMetadata> = {}
    switch (location.name) {
      case "Elder Woodlands": tokenArray = stakedTokensBerry;
        break;
      case "Zolar Asteroid Belt": tokenArray = stakedTokensGeode;
        break;
      case "Moon Battlegrounds": tokenArray = stakedTokensScrap;
        break;
    }
    return tokenArray;
  }, [stakedTokensBerry, stakedTokensGeode, stakedTokensScrap, location])

  const { totalHarvestFee } = useMemo(() => {
    let totalHarvestFee = BIG_ZERO;
    for (const id of selectedMetazoa) {
      const harvestFee = calculateHarvestFee(questLocation, currentBlock, stakedTokens[id], network);
      totalHarvestFee = totalHarvestFee.plus(harvestFee)

      logger("debug-quests/calculateHarvestFee", {
        token: stakedTokens[id],
        stats: stakedTokens[id].stats,
        currentBlock,
        lastHarvested: stakedTokens[id].lastHarvested ?? currentBlock,
        totalHarvestFee: totalHarvestFee.toFixed(2),
      })
    }



    return { totalHarvestFee }
  }, [selectedMetazoa, currentBlock, questLocation, stakedTokens, network])

  const selectedUrsaCount = useMemo(() => {
    let count = 0;
    if (!selectedMetazoa.length) return count;

    selectedMetazoa.forEach(id => {
      const faction = stakedTokens[id]?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value;
      if (faction === "Ursa") count++;
    })

    return count;
  }, [selectedMetazoa, stakedTokens]);

  const isSubmitEnabled = useMemo(() => {
    setCanAfford(true);

    if (!wallet || !selectedMetazoa.length || isLoading) return false;

    // Can't afford 
    const totalHunyCost = totalHarvestFee.plus(returnCost.times(selectedMetazoa.length).shiftedBy(-Decimals.HUNY))
    if (totalHunyCost.gt(HunyTokens)) {
      setCanAfford(false);
      return false;
    }

    return true;
  }, [isLoading, selectedMetazoa, totalHarvestFee, returnCost, HunyTokens, wallet])

  // FUNCTIONS ----------------------

  const isMetazoaSelected = (tokenId: string) => selectedMetazoa.includes(tokenId);

  const getResourceGathered = (token: NftMetadata) => {
    if (!token.lastHarvested || !location || !token || !token.bonuses) return 0;
    const locationType = location.keyStat?.type
    if (!locationType) return 0;
    const blockDifference = currentBlock - token.lastHarvested;
    const numberOfEpochs = Math.floor(blockDifference / QUEST_EPOCH[network]);
    const bonusMultiplier = bnOrZero(token.contractBonus?.resourceGatheringBonus).dividedBy(100).plus(1).toNumber();
    let resourceGathered = numberOfEpochs * RESOURCE_PER_EPOCH[locationType] * bonusMultiplier;
    if (resourceGathered < 0) resourceGathered = 0;
    return resourceGathered;
  }

  const returnBase = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!isSubmitEnabled) throw new Error(`Requirements not met`);

    if (selectedMetazoa.length > MAX_ZOA.ReturnToBase) {
      throw new Error(`Please select max ${MAX_ZOA.ReturnToBase} ZOAs only`);
    }

    const returnBaseTx = await TBMConnector.returnToQuestBase(wallet, selectedMetazoa, location.name);
    toaster(`Submitted Return Travel `, { hash: returnBaseTx.id! });
    if (returnBaseTx.isRejected() || !returnBaseTx.id) throw new Error("Submitted transaction was rejected.");

    const tx = await waitForTx(returnBaseTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(returnBaseTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    setReturnBaseTxHash(returnBaseTx.id);
    setReturnBaseComplete(true);
    toaster(`Returned to Base `, { hash: returnBaseTx.id!, overridePersist: true });
  }

  const revertToInitialState = () => {
    if (returnBaseComplete) dispatch(actions.Token.reloadTokens());
    setReturnBaseTxHash("");
    setSelectedMetazoa([]);
    setReturnBaseComplete(false);
    setCanAfford(false);
  };

  // EVENT HANDLERS -----------------

  const handleSelect = (tokenId: string) => (_event: React.ChangeEvent<HTMLInputElement>) => {
    if (isLoading) return;
    const selectedMetazoaCopy = selectedMetazoa.slice();
    const index = selectedMetazoaCopy.findIndex((id) => id === tokenId);
    if (index === -1) {
      selectedMetazoaCopy.push(tokenId);
      setSelectedMetazoa(selectedMetazoaCopy);
      // setTotalHunyHarvesting(totalHunyHarvesting + hunyGatheredPerMetazoa[tokenId]);
    } else {
      selectedMetazoaCopy.splice(index, 1);
      setSelectedMetazoa(selectedMetazoaCopy);
      // setTotalHunyHarvesting(totalHunyHarvesting - hunyGatheredPerMetazoa[tokenId]);
    }
  }

  const handleSelectAll = () => {
    if (isLoading) return;
    const returnableMetazoa = Object.keys(stakedTokens);
    setSelectedMetazoa(Object.keys(stakedTokens).filter(id => returnableMetazoa.includes(id)));
  }

  const handleReturnBase = () => {
    runReturnBase(async () => {
      await returnBase();
    })
  }

  const handleOnClose = () => {
    if (isLoading) return;
    revertToInitialState();
    onClose();
  }

  const handleSendMore = () => {
    if (isLoading) return;
    revertToInitialState();
  }

  // --------------------------------

  const getHeader = () => {
    if (returnBaseComplete && returnBaseTxHash) {
      return "Return Metazoa success!";
    } else if (isLoading) {
      return "Returning to Base...";
    } else {
      return "Return Metazoa to Base";
    }
  }

  const addDefaultSrc = (metadata: NftMetadata) => (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!metadata.attributes?.length)
      return;

    const index = metadata.attributes.findIndex(attribute => attribute.trait_type === "Faction");

    if (index === -1)
      return;

    let faction = metadata.attributes[index].value;

    if (faction === "Ursa") {
      e.currentTarget.onerror = null;
      e.currentTarget.src = UrsaDefault;
    } else if (faction === "Mino") {
      e.currentTarget.onerror = null;
      e.currentTarget.src = MinoDefault;
    }
  }

  const getContent = () => {
    if (returnBaseComplete && returnBaseTxHash) {
      return (
        <Fragment>
          <Typography variant="body1" color="primary" textAlign="center" mx={'auto'}>
            You have successfully returned your Metazoa to base!
          </Typography>


          <Box sx={styles.contentBox} style={{ marginTop: 0 }}>
            {/* Qty */}
            <Box display="flex" alignItems="center" mt="20px">
              <Box component="img" src={MinoIcon} mr="10px" sx={styles.placeholderImage} />
              <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }}>x {selectedMetazoa.length - selectedUrsaCount}</Typography>
            </Box>
            <Box display="flex" alignItems="center" mt="30px">
              <Box component="img" src={UrsaIcon} mr="10px" sx={styles.placeholderImage} />
              <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }}>x
                {" "}
                <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }} component="span">{selectedUrsaCount}</Typography>
              </Typography>
            </Box>

            {/* Tx hash */}
            <Link
              target="_blank"
              href={getExplorerLink("tx", returnBaseTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>

            {/* Send more */}
            <ContainedButton
              onClick={handleSendMore}
              sx={styles.sendMoreButton}
              disabled={(!Object.values(stakedTokens).length) || (Object.values(stakedTokens).length - selectedMetazoa.length) <= 0}
            >
              Return More
            </ContainedButton>
          </Box>
        </Fragment>
      )
    }

    if (isLoading) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" margin={'0 auto'}>
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Box>
      )
    }

    return (
      <Box sx={styles.contentBox}>
        {/* Metazoa */}
        <TableContainer sx={styles.tableContainer}>
          <Table>
            <TableHead sx={styles.tableHead}>
              <TableRow>
                {/* Gen */}
                {/* <TableCell align="left" width="10%">
                  <Typography variant="body1" color="primary">Gen</Typography>
                </TableCell> */}

                {/* Metazoa */}
                <TableCell align="left">
                  <Typography variant="body1" color="primary">Metazoa</Typography>
                </TableCell>

                {/* Profession */}
                <TableCell align="left">
                  <Typography variant="body1" color="primary">Profession</Typography>
                </TableCell>

                {/* Resource */}
                <TableCell align="center">
                  <Typography variant="body1" color="primary" sx={styles.alignResource}>
                    Resource Gathered&nbsp;
                    {!!location.resource && (
                      <Box
                        component="img"
                        src={location.resource.iconSrc}
                        height="24px"
                        width="24px"
                        onError={QuestConstants.DEFAULT_SRC}
                      />
                    )}
                  </Typography>
                </TableCell>

                {/* Select all */}
                <TableCell align="center">
                  <Typography variant="body1" color="primary" sx={styles.selectAll} onClick={handleSelectAll}>SELECT ALL</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={styles.tableBody}>
              {loadingTokens && (
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={2} align="center"><CircularProgress /></TableCell>
                </TableRow>
              )}

              {!loadingTokens && Object.values(stakedTokens).map((token) => {
                const attributes: any = {};
                token?.attributes?.forEach(attribute => {
                  attributes[attribute.trait_type] = attribute.value;
                })

                return (
                  <TableRow key={token.id}>
                    {/* Gen */}
                    {/* <TableCell align="left" sx={{ paddingLeft: "10px!important" }}>
                      <Typography variant="body1" color="primary">{attributes?.["Gen"] ?? "-"}</Typography>
                    </TableCell> */}

                    {/* Metazoa */}
                    <TableCell align="left">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box key={token.id} sx={styles.metazoaWrapper}>
                          {!!token.profession && (
                            <Box
                              component="img"
                              src={PROFESSIONS[STAT_PROFESSION[token.profession]].icon}
                              onError={QuestConstants.DEFAULT_SRC}
                              sx={styles.professionIcon}
                            />
                          )}
                          <Box
                            component="img"
                            src={token.image}
                            alt={`ZOA #${token.id}`}
                            width="40px"
                            sx={styles.zoaImage}
                            onError={addDefaultSrc(token)}
                          />
                        </Box>

                        <Typography variant="body1" color="primary">
                          ZOA #{token.id}
                        </Typography>
                      </Box>
                    </TableCell>

                    {/* Profession */}
                    <TableCell align="left">
                      <Typography variant="body1" color="primary">{token.profession ? STAT_PROFESSION[token.profession] : "-"}</Typography>
                    </TableCell>

                    {/* Resource */}
                    <TableCell align="center">
                      <Typography variant="body1" color="primary">
                        {getResourceGathered(token).toFixed(2)}
                      </Typography>
                    </TableCell>

                    {/* Checkbox */}
                    <TableCell align="center">
                      <Checkbox
                        icon={<UncheckedIcon />}
                        checkedIcon={<CheckedIcon />}
                        checked={isMetazoaSelected(token.id)}
                        onChange={handleSelect(token.id)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={styles.feeRow}>
          <Typography variant="body1" color="primary" component="span">
            Total Resource Delivery Fee
          </Typography>
          <Typography variant="body1" color={canAfford ? "success.main" : "error.main"} component="span" fontWeight={600}>
            {totalHarvestFee.toFixed(2)}&nbsp; HUNY &nbsp;
            <SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" />
          </Typography>
        </Box>
        <Box sx={styles.feeRow}>
          <Typography variant="body1" color="primary" component="span">
            Return To Base Fuel
            {/* <TooltipZolar>
              Lorem ipsum dolor sit amet.
            </TooltipZolar> */}
          </Typography>
          <Typography variant="body1" color={canAfford ? "success.main" : "error.main"} component="span" fontWeight={600}>
            {returnCost.times(selectedMetazoa.length).shiftedBy(-Decimals.HUNY).toNumber()} HUNY&nbsp;
            <SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" />
          </Typography>
        </Box>
        {/* Confirm*/}
        <ContainedButton
          onClick={handleReturnBase}
          disabled={!isSubmitEnabled}
          sx={styles.confirmButton}
        >
          {isLoading
            ? <CircularProgress size={18} />
            : "Confirm"
          }
        </ContainedButton>
      </Box>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true}>
      {getContent()}
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    placeContent: 'start',
    alignItems: "center",
    width: "100%",
    height: "100%",
    flex: 1,
    marginTop: "10px",
  },
  tableContainer: {
    overflowY: "auto",
    maxHeight: "25vh",
    maxWidth: "700px",
    paddingBottom: "10px",
    minHeight: '250px',
    flex: 1,
    height: '100%',
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "transparent",
      top: "",
      left: "",
    },
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: "10px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },
  tableHead: {
    "& th.MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  alignResource: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  confirmButton: {
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  selectAll: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
    }
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    marginTop: "10px",
  },

  metazoaWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    placeItems: 'center',
  },
  zoaImage: {
    width: '40px',
    marginRight: "10px",
    borderRadius: "16px",
    border: '1px solid #AEF1EE',
  },
  professionIcon: {
    position: 'absolute',
    right: '0',
    top: '-15%',
    minHeight: "20px",
    minWidth: "20px",
    maxWidth: '40%',
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "20px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  sendMoreButton: {
    marginTop: "20px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  placeholderImage: {
    height: "60px",
    width: "60px",
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  feeRow: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    marginY: '12px',
  },
  hunyIcon: {
    verticalAlign: "top",
  },
}

export default ReturnToBaseDialog;
