import { Box, Button, CircularProgress, Link, styled, SvgIcon, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { Huny, WarningRed } from "assets";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { waitForTx } from "core/utilities";
import { MetazoaClient } from "core/utilities/metazoa";
import dayjs from "dayjs";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { Guild, OAuth, Request } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { Decimals } from "utils/constants";
import { getExplorerLink } from "utils/strings";
import { bnOrZero, formatIntegerNumber } from "utils/strings/strings";
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
export interface ModalProps {
  open: boolean;
  onClose: () => void;
  requests: Request[];
  guild: Guild;
  reload: () => void;
  loadingJoinRequests: boolean;
}

const RequestDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, requests, guild, reload, loadingJoinRequests } = props;
  const { guildBank } = guild;
  const hasGuildBank: boolean = !!guildBank ?? false;

  const toaster = useToaster();
  const network = useNetwork();
  const dispatch = useDispatch();
  const metazoaProfileState = useSelector(getProfile);
  const wallet = useRedux((state) => state.wallet.wallet);

  // STATES / HOOKS -----------------

  const [runQueryHunyHolding, loadingQueryHunyHolding] = useAsyncTask("queryHunyHolding");
  const [runProcessRequest, loadingProcessRequest] = useAsyncTask("processRequest", (error) => {
    toaster(error?.message ?? "Error Processing Request");
  });

  useEffect(() => {
    if (!requests) return;

    runQueryHunyHolding(async () => {
      const allHunyBalances: SimpleMap<BigNumber> = await TBMConnector.getTotalHunyBalance();

      requests.forEach((request) => {
        const byte20Addr: string = request.sender;
        const senderHunyHolding: BigNumber = bnOrZero(allHunyBalances[byte20Addr]);
        request.user.totalHunyHolding = senderHunyHolding;
      })
    })
    // eslint-disable-next-line
  }, [requests]);

  // FUNCTIONS ----------------------

  const requestJoinConfirmation = async (sender: string, isAccepted: boolean) => {
    if (!wallet) throw new Error("Wallet not connected");

    const metazoaClient = new MetazoaClient(network);

    if (hasGuildBank && guildBank && guildBank.address) {
      const applyMembershipTx = await TBMConnector.handleJoinRequest(toBech32Address(guildBank.address), sender, isAccepted);
      toaster(`Submitted Join Confirmation `, { hash: applyMembershipTx.id! });
      if (applyMembershipTx.isRejected() || !applyMembershipTx.id) throw new Error("Submitted transaction was rejected.");

      const tx = await waitForTx(applyMembershipTx.id);
      const tbmConnector = TBMConnector.getSDK();
      const txn = await tbmConnector.zilliqa.blockchain.getTransaction(applyMembershipTx.id);
      const receipt = txn.getReceipt();
      if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

      toaster(`Join Confirmed `, { hash: applyMembershipTx.id!, overridePersist: true });
    }
    else {
      const { oAuth } = metazoaProfileState;

      let checkedOAuth: OAuth | undefined = oAuth;
      if (!oAuth?.access_token || oAuth.address !== wallet.addressInfo.bech32 || (oAuth && dayjs(oAuth?.expires_at * 1000).isBefore(dayjs()))) {
        const { result } = await metazoaClient.metazoaLogin(wallet, window.location.hostname);
        dispatch(actions.Profile.updateAccessToken(result));
        checkedOAuth = result;
      }

      await metazoaClient.handleJoinGuildRequest(guild.id, { sender, guildId: guild.id, isAccepted }, checkedOAuth!);
    }
    reload();
  }

  const requestAcceptability = (request: Request) => {
    if (hasGuildBank && guild.guildBank) {
      let rejectReason: string = "";
      let acceptability: boolean = true;
      const canPayJoinFee: boolean = request.user.totalHunyHolding.gte(bnOrZero(guild.guildBank.joiningFee.initialAmt).shiftedBy(-Decimals.HUNY));

      // Not enough huny for joining fee
      if (!canPayJoinFee) {
        acceptability = false;
        rejectReason = "This user does not have enough HUNY to join guild.";
      }

      // Not approved huny for guild tx

      // Owes weekly tax

      return { canAccept: acceptability, rejectReason: rejectReason };
    }
    return { canAccept: true };
  }

  // EVENT HANDLERS -----------------

  const handleOnClose = () => {
    onClose();
  }

  const handleRequest = (sender: string, isAccepted: boolean) => {
    runProcessRequest(async () => {
      await requestJoinConfirmation(sender, isAccepted);
    })
  }

  // --------------------------------

  if (!requests || requests.length < 1) return null;
  return (
    <DialogModal header={`Pending Request (${requests.length})`} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true}>
      <Box sx={styles.contentBox}>
        {loadingQueryHunyHolding || loadingJoinRequests
          ? <CircularProgress sx={styles.circularProgress} size={24} />
          : <Fragment>
            <TableContainer sx={styles.tableContainer}>
              <Table>
                <TableHead sx={styles.tableHead}>
                  <TableRow>
                    {/* username */}
                    <TableCell align="left" sx={styles.usernameTableCell}>
                      <Typography variant="body1" color="primary">Username</Typography>
                    </TableCell>

                    {/* discord */}
                    <TableCell align="left" sx={styles.discordTableCell}>
                      <Typography variant="body1" color="primary">Discord</Typography>
                    </TableCell>

                    {/* huny gathered */}
                    <TableCell align="right" sx={styles.hunyTableCell}>
                      <Typography variant="body1" color="primary">
                        HUNY Holding
                        {" "}
                        <SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" />
                      </Typography>
                    </TableCell>

                    {/* Select all */}
                    <TableCell align="right" sx={styles.actionsTableCell}>
                      <Typography variant="body1" color="primary">Actions</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody sx={styles.tableBody}>
                  {requests.map((request, index) => {
                    const requestStatus = requestAcceptability(request);

                    return (
                      <TableRow key={index}>
                        {/* username */}
                        <TableCell align="left">
                          <Box display="flex" alignItems="center" ml="16px">
                            <Box
                              component="img"
                              width="40px"
                              src={request.user.avatarUrl ?? DefaultUserProfile}
                              alt="profile image"
                              sx={styles.profileImage}
                            />
                            <Box ml="20px">
                              <Typography variant="body1" color="primary" sx={styles.usernameText}>
                                {request.user.username ?? '-'}
                              </Typography>

                              <Link target="_blank"
                                href={getExplorerLink("address", request.sender, network)}
                              >
                                <Typography variant="body1" color="primary" sx={styles.underlineText}>
                                  {truncate(toBech32Address(request.sender))}
                                </Typography>
                              </Link>
                            </Box>
                          </Box>
                        </TableCell>

                        {/* discord */}
                        <TableCell align="left">
                          <Typography variant="body1" color="primary" sx={styles.underlineText}>
                            {request.user.discordUrl ?? '-'}
                          </Typography>
                        </TableCell>

                        {/* huny holding */}
                        <TableCell align="right" >
                          <Typography variant="body1" color="primary" sx={{ fontSize: "14px", lineHeight: "24px" }}>
                            {!requestStatus.canAccept && (
                              <BankRequestTooltip
                                title={<>
                                  <Typography variant="body1" color="primary" sx={{ ...GUILD_GRAY_GRADIENT }}>
                                    {requestStatus.rejectReason}
                                  </Typography>
                                </>}
                              >
                                <WarningRed style={{ verticalAlign: 'middle', marginRight: '0.5em' }} />
                              </BankRequestTooltip>
                            )}
                            {formatIntegerNumber(request.user?.totalHunyHolding ?? 0)}
                          </Typography>
                        </TableCell>

                        {/* actions */}
                        <TableCell align="right">
                          <Box display="flex" justifyContent="flex-end">
                            {loadingProcessRequest
                              ? <CircularProgress size={18} />
                              : <Fragment>
                                <Button variant="text" sx={styles.rejectButton} onClick={() => handleRequest(request.sender, false)}>REJECT</Button>
                                <Button variant="text" sx={styles.acceptButton} onClick={() => handleRequest(request.sender, true)} disabled={!requestStatus.canAccept}>ACCEPT</Button>
                              </Fragment>
                            }
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            {/* done */}
            <ContainedButton sx={styles.doneButton} onClick={handleOnClose}>
              Done
            </ContainedButton>
          </Fragment>
        }
      </Box>
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  tableContainer: {
    overflowY: "auto",
    maxHeight: "49vh",
    maxWidth: "700px",
    paddingRight: "10px",
    paddingBottom: "10px",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "transparent",
      top: "",
      left: "",
    },
    '::-webkit-scrollbar': {
      height: '6px',
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: "10px",
      marginBottom: "10px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },
  tableHead: {
    "& th.MuiTableCell-root": {
      padding: "12px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
      "& .MuiTypography-root": {
        fontSize: "14px",
        lineHeight: "24px",
      }
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: "10px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  usernameTableCell: {
    "@media (max-width:900px)": {
      minWidth: "280px",
    }
  },
  usernameText: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 700,
    color: "#AEF1EE",
  },
  discordTableCell: {
    "@media (max-width:900px)": {
      minWidth: "150px",
    }
  },
  underlineText: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 700,
    textDecoration: "underline",
  },
  hunyTableCell: {
    "@media (max-width:900px)": {
      minWidth: "120px",
    }
  },
  actionsTableCell: {
    "@media (max-width:900px)": {
      minWidth: "200px",
    }
  },
  hunyIcon: {
    verticalAlign: "text-top",
    height: "20px",
    width: "20px",
  },
  profileImage: {
    borderRadius: "16px",
    boxShadow: "0px 0px 12px 0px #00F0FF",
  },
  doneButton: {
    marginTop: "20px",
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    width: 270,
    "@media (max-width:600px)": {
      width: '100%',
    }
  },
  rejectButton: {
    color: "#FF8952",
    fontSize: "16px",
    lineHeight: "28px",
    paddingX: 0,
    marginRight: "8px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  acceptButton: {
    background: "-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "16px",
    lineHeight: "28px",
    paddingX: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },

    "&:disabled": {
      opacity: 0.5,
    }
  },
  circularProgress: {
    marginTop: "20px",
    marginBottom: "20px",
  },
}

const BankRequestTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '20px',
    // display: 'flex',
    // placeContent: 'center',
    // placeItems: 'center',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)',
    boxShadow: '0px 4px 24px #000000',
    borderRadius: '16px',
    maxWidth: '263px',
  },
}));

export default RequestDialog;
