
import BigNumber from "bignumber.js";
import dayjs, { Dayjs } from "dayjs";
import { BIG_ONE, BLOCKS_PER_HOUR, BLOCKS_REFINE_HUNY, MissionGroundResource } from "utils/constants";
import { bnOrZero } from "utils/strings/strings";
import { ObservedTx, TxReceipt, TxStatus } from "zilswap-sdk";
import { SimpleMap } from "../../utils/types";

export interface CurrentMintInfo {
  status?: TxStatus;
  receipt?: TxReceipt;
}
export interface TokenState {
  currentMinting: (ObservedTx & CurrentMintInfo) | null;
  tokens: SimpleMap<NftMetadata>;
  metazoaTokens: SimpleMap<NftMetadata>;
  transcendedTokens: SimpleMap<NftMetadata>;
  revealedTokens: SimpleMap<Dayjs>;
  whitelistCount: number;
  transcendenceApproved: boolean;
  mintedTokensCount: number;
  totalSupply: number;
  saleActive: boolean;

  // Phase 3
  goldRushTotalSupply: number;
  goldRushCurrentSupply: number;
  goldRushSalePrice: number;
  goldRushSaleActive: boolean;

  // Phase 4
  moonBattleInfo: MoonBattleInfo;

  // Game
  trappedMetazoa: SimpleMap<NftMetadata>;
  hunyToRecover: Hug3Recovery;
  stakedMetazoa: SimpleMap<NftMetadata>;
  metazoaBlocksStaked: SimpleMap<number>;
  stakedMetazoaBerry: SimpleMap<NftMetadata>;
  stakedMetazoaGeode: SimpleMap<NftMetadata>;
  stakedMetazoaScrap: SimpleMap<NftMetadata>;

  // Hive
  hivePool?: HivePoolStats;
  hiveInfo?: HiveInfo;

  // Huny
  TotalHunySupply: number;
  HunyTokens: number;

  // Zil
  ZilTokens: number;
  GasFee: number;
  ExchangeRates: {
    zilPrice: BigNumber,
    hunyPrice: BigNumber,
  },

  // Refinery
  hunyPots?: SimpleMap<HunyPot>;
  refineryHunyStats?: RefineryHunyStats;
  refineryConfig?: RefineryConfig;

  // Mission 3 resources
  resources: Resources;
}

export interface NftAttribute {
  trait_type: string;
  value: string;
  rarity?: number;
}

export interface NftStats {
  total?: number;
  affinity?: number;
  classPower?: number;
  equipment?: NftGenesisEquiment[];
  genPower?: number;
  kidnapBuff?: number;
}

export interface NftGenesisEquiment {
  name: string;
  stat: number;
}

export interface Exp {
  level: number;
  xpGained: number;
  xpRequired: number;
}

export interface Equipment {
  id: string;
  ownerAddress: string;
  address: string;
  parentAddress: string;
  zoaId: string;
  tokenTraits: SimpleMap;
}

export interface NftMetadata {
  id: string;
  name?: string;
  description?: string;
  image?: string;
  revealedAt?: number;
  attributes?: NftAttribute[];
  stats?: NftStats;
  unassignedStatPoints?: number;
  hash?: string;
  profession?: string;
  location?: string;
  bonuses?: SimpleMap<SimpleMap<SimpleMap>>;
  contractBonus?: SimpleMap<BigNumber>;
  lastHarvested?: number;
  exp?: Exp;
  masteryExp?: Exp;
  equipments?: Equipment[];
}

export interface GoldRushSupplyProps {
  goldRushTotalSupply?: number;
  goldRushCurrentSupply?: number;
}

export interface SupplyProps {
  totalSupply?: number;
  currentSupply?: number;
}

export interface ExchangeRateProps {
  huny: BigNumber;
  zilliqa: BigNumber;
}

export interface MoonBattleInfo {
  summonCount: number,
  whitelistCount: number,
  capturedHunyPerShare: BigNumber,
  totalShares: number,
  capturedHunyDebt: SimpleMap<string>,
};

export interface Hug3Recovery {
  hunyReceiveNow: BigNumber,
  hunyToRefinery: BigNumber,
}

export interface HiveInfo {
  hunyPerBlock: BigNumber;
  hunyRewardsPerShare: BigNumber;
  totalContribution: BigNumber;
  totalShare: BigNumber;
  lastRewardBlock: number;
  incomingKickbacks: BigNumber;
  enabled: boolean;
}

export interface HivePoolStats {
  contribution: BigNumber;
  zilReserves: BigNumber;
  hunyReserves: BigNumber;

  totalZilReserves: BigNumber;
  totalHunyReserves: BigNumber;
  totalContribution: BigNumber;

  userZilReserves: BigNumber;
  userHunyReserves: BigNumber;
  userBalance: BigNumber;
  userShare: BigNumber;
  lastSignificantDeposit?: number;

  userDebt: BigNumber;
  // lastClaimBlock?: number;
}

export interface RefineryConfig {
  immediateRefine?: number;
  refineDuration?: number;
}

export interface RefineryHunyStats {
  totalSupply?: BigNumber;
  totalShare?: BigNumber;
  lastRefinedBlock?: number;
}

export interface UpdatedTokens {
  tokenIds: string[];
  metazoaIds: string[];
  stakedMetazoaIds: string[];
  questBerryIds: string[];
  questScrapIds: string[];
  questGeodeIds: string[];
  trappedMetazoaIds: string[];
  mintedTokensCount: number;
  hunyBalance: BigNumber;
  zilBalance: number;
  totalHunySupply: BigNumber;
  resources: Resources;
}

export interface UpdatedResources {
  resources: Resources;
}

export interface Resources {
  [MissionGroundResource.Elderberries]: BigNumber;
  [MissionGroundResource.Geodes]: BigNumber;
  [MissionGroundResource.ZolraniumScraps]: BigNumber;
  gems: SimpleMap<SimpleMap<number[]>>;
  crackedGems: SimpleMap<SimpleMap<number[]>>;
  consumables: SimpleMap<number[]>;
  ordnances: SimpleMap<OwnedOrdnance>;
}

export interface OwnedOrdnance {
  ids: number[],
  name: string;
  address: string;
  src: string;
  icon?: string;
  attributes: SimpleMap;
}
export interface GameStats {
  commanders: SimpleMap<string[]>;
  tokenTraits: SimpleMap<SimpleMap>;
  lastMetazoaId: number;
  hunyHolders: SimpleMap<BigNumber>;
  totalHuny: BigNumber;
}

export interface RPCSuccessResult {
  [key: string | number]: any;
}

export class HunyPot {
  constructor(
    public readonly startBlock: number,
    public readonly startTimestamp: number,
    public readonly amount: BigNumber,
  ) { }

  getStartDate() {
    return dayjs(this.startTimestamp)
  }

  estStartDate(currentBlock: number, blockTime: Dayjs) {
    if (!currentBlock) return undefined;
    const hoursDifference = (currentBlock - this.startBlock) / BLOCKS_PER_HOUR;
    return dayjs(blockTime).subtract(hoursDifference, 'hour');
  }

  estCompleteDate(currentBlock: number, blockTime: Dayjs, refineDuration: number = BLOCKS_REFINE_HUNY) {
    const hours = bnOrZero(refineDuration).div(BLOCKS_PER_HOUR).toNumber();
    return this.estStartDate(currentBlock, blockTime)?.add(hours, "h");
  }

  estDaysLeft(currentBlock: number, refineDuration: number = BLOCKS_REFINE_HUNY) {
    const blocksLeft = Math.max(0, refineDuration - (currentBlock - this.startBlock));
    return (blocksLeft / BLOCKS_PER_HOUR) / 24;
  }

  progress(currentBlock: number, immediateRefinement: number = 0.1) {
    if (!currentBlock) return new BigNumber(immediateRefinement);
    const elapsedBlocks = currentBlock - this.startBlock;
    const refinementPortion = BIG_ONE.minus(immediateRefinement);
    return bnOrZero(elapsedBlocks / BLOCKS_REFINE_HUNY).times(refinementPortion).plus(immediateRefinement);
  }
}
