import { Box, Checkbox, CircularProgress, Link, SvgIcon, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CheckedIcon, ExternalLink, Huny, LockedIcon, UncheckedIcon } from "assets";
import MinoDefault from "assets/dp-mino.png";
import UrsaDefault from "assets/dp-ursa.png";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { PROFESSIONS, STAT_PROFESSION } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { QuestConstants } from "components/Quest";
import { LocationMetadata } from "components/Quest/QuestConstants";
import { logger, waitForTx } from "core/utilities";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { NftMetadata } from "store/types";
import { TBMConnector } from "tbm";
import { useBlockTime, useTaskSubscriber, useToaster } from "utils";
import { BIG_ZERO, MAX_ZOA, MissionGroundLocation, QUEST_EPOCH, RESOURCE_PER_EPOCH } from "utils/constants";
import { bnOrZero } from "utils/strings";
import { getExplorerLink } from "utils/strings/links";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";
import { calculateHarvestFee } from "./helper";

export interface ModalProps {
  location: LocationMetadata;
  open: boolean;
  onClose: () => void;
}

const SendResourceToBase: React.FC<ModalProps> = ({
  location,
  open,
  onClose,
}: ModalProps) => {

  const dispatch = useDispatch();
  const toaster = useToaster();
  const network = useNetwork();
  const [, currentBlock] = useBlockTime();
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const questState = useRedux((state) => state.quest.quests);
  const hasNoStaked: boolean = location.tokens?.staked.length === 0;

  // STATES / HOOKS -----------------

  const { stakedMetazoaBerry: stakedTokensBerry, stakedMetazoaGeode: stakedTokensGeode, stakedMetazoaScrap: stakedTokensScrap } = tokenState;

  const [selectedMetazoa, setSelectedMetazoa] = useState<string[]>([]);
  const [allowance, setAllowance] = useState<BigNumber>(BIG_ZERO);
  const [needIncreaseAllowance, setNeedIncreaseAllowance] = useState<boolean>(true);
  const [harvestComplete, setHarvestComplete] = useState<boolean>(false);
  const [harvestTxHash, setHarvestTxHash] = useState<string>("");
  const [harvestAmt, setHarvestAmt] = useState<BigNumber>(BIG_ZERO);

  const [runFetchQuestHunyAllowance, loadingFetchQuestHunyAllowance] = useAsyncTask("fetchQuestHunyAllowance", (error) => {
    toaster(error?.message ?? "Error Fetching Quest Huny Allowance");
  });
  // const [runFetchLastHarvestBlock, loadingFetchLastHarvestBlock] = useAsyncTask("fetchLastHarvestBlock", (error) => {
  //   toaster(error?.message ?? "Error Fetching Last Harvest Block");
  // });
  const [runIncreaseAllowance, loadingIncreaseAllowance] = useAsyncTask("increaseAllowance", (error) => {
    toaster(error?.message ?? "Error Increasing Allowance");
  });
  const [runHarvestResource, loadingHarvestResource] = useAsyncTask("harvestResource", (error) => {
    toaster(error?.message ?? "Error Harvesting Resources");
  });

  const [loadingFetchResources] = useTaskSubscriber('refetchResources');
  const isLoading = loadingHarvestResource || loadingFetchResources;

  const getResourceGathered = (token: NftMetadata) => {
    if (!token.lastHarvested || !location || !token || !token.bonuses) return 0;
    const locationType = location.keyStat?.type
    if (!locationType) return 0;
    const blockDifference = currentBlock - token.lastHarvested;
    const numberOfEpochs = Math.floor(blockDifference / QUEST_EPOCH[network]);
    const bonusMultiplier = (bnOrZero(token.contractBonus?.resourceGatheringBonus).dividedBy(100).plus(1)).toNumber();
    let resourceGathered = numberOfEpochs * RESOURCE_PER_EPOCH[locationType] * bonusMultiplier;
    if (resourceGathered < 0) resourceGathered = 0;

    logger("debug-resource", "getResourceGathered", {
      blockDifference,
      numberOfEpochs,
      bonusMultiplier,
      token,
    })
    return resourceGathered;
  }

  const locationType = location.keyStat?.type ?? "";
  const questLocation = useMemo(() => questState[locationType], [questState, locationType]);
  const isExceedMaxHarvestBlock = useMemo(() => questLocation?.maxHarvestBlock && bnOrZero(questLocation.maxHarvestBlock).lt(currentBlock), [questLocation, currentBlock])

  const stakedTokens: SimpleMap<NftMetadata> = useMemo(() => {
    let tokenArray: SimpleMap<NftMetadata> = {}
    switch (location.key) {
      case MissionGroundLocation.ElderWoodlands: tokenArray = stakedTokensBerry;
        break;
      case MissionGroundLocation.ZolarAsteroidBelt: tokenArray = stakedTokensGeode;
        break;
      case MissionGroundLocation.MoonBattleground: tokenArray = stakedTokensScrap;
        break;
    }
    return tokenArray;
  }, [stakedTokensBerry, stakedTokensGeode, stakedTokensScrap, location]);


  const selectedInfo = useMemo(() => {
    const selected = {
      totalDeliveryCost: 0,
      totalResourceGathered: 0,
      totalResourceGatheredSelected: 0,
    };
    if (!selectedMetazoa.length || !stakedTokens) return selected;

    /// Total Delivery Cost
    let totalHarvestFee = BIG_ZERO;
    for (const id of selectedMetazoa) {
      const harvestFee = calculateHarvestFee(questLocation, currentBlock, stakedTokens[id], network);
      totalHarvestFee = totalHarvestFee.plus(harvestFee)
    }
    selected.totalDeliveryCost = totalHarvestFee.toNumber();

    /// Summation of Gathered Resource
    selected.totalResourceGathered = Object.values(stakedTokens).reduce((total, token) => total + getResourceGathered(token), 0) ?? 0;

    /// Summation of Gathered Resource (Selected)
    selected.totalResourceGatheredSelected = selectedMetazoa.reduce((total, zoaId) => total + getResourceGathered(stakedTokens[zoaId]), 0) ?? 0;

    logger("debug-sendResourceToBase", "selectedInfo", selected);

    return selected;
  }, [selectedMetazoa, stakedTokens, currentBlock, network, questLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!wallet || hasNoStaked) return;

    runFetchQuestHunyAllowance(async () => {
      if (!wallet) throw new Error('wallet not connected');
      if (!location) return;
      const result = await TBMConnector.checkQuestHunyAllowance(wallet, location.name);
      setAllowance(result)
      if (result.lt(selectedInfo.totalDeliveryCost) || result.eq(0)) setNeedIncreaseAllowance(true);
      else {
        setNeedIncreaseAllowance(false)
      }
    });

  }, [wallet, location, selectedInfo, hasNoStaked]); // eslint-disable-line react-hooks/exhaustive-deps

  // FUNCTIONS ----------------------

  const isMetazoaSelected = (tokenId: string) => selectedMetazoa.includes(tokenId);

  const getProfession = (profession: string) => {
    switch (profession) {
      case "STR": return "Marauder"
      case "INT": return "Psionic"
      case "DEX": return "Astrominer"
      default: return "-"
    }
  }

  const increaseAllowance = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const approveAmount = new BigNumber(2).pow(128).minus(1).minus(allowance).dp(0);
    const approveTx = await TBMConnector.increaseQuestAllowance(wallet, approveAmount, location.name);
    toaster(`Submitted Approve Increase Allowance `, { hash: approveTx.id! })
    if (approveTx.isRejected() || !approveTx.id) throw new Error("Submitted transaction was rejected.");

    const tx = await waitForTx(approveTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(approveTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    toaster(`Increase Allowance Success`, { hash: approveTx.id! })
    setAllowance(approveAmount);
    setNeedIncreaseAllowance(false);
  }

  const harvestResource = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (isExceedMaxHarvestBlock) throw new Error("Quest Over.")
    if (selectedMetazoa.length > MAX_ZOA.HarvestHuny) throw new Error(`Please select max ${MAX_ZOA.HarvestHuny} ZOAs only`);

    const harvestResourceTx = await TBMConnector.harvestQuestResource(wallet, selectedMetazoa, location.name);
    if (harvestResourceTx.isRejected() || !harvestResourceTx.id) throw new Error("Submitted transaction was rejected");

    const tx = await waitForTx(harvestResourceTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(harvestResourceTx.id);
    const receipt = txn.getReceipt();
    logger("debug-receipt", "SendResourceToBase", receipt)
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    const mintDetails = (receipt.event_logs.find((event) => event._eventname === "Minted"))?.params ?? [];
    logger("debug-receipt", "SendResourceToBase", mintDetails)
    if (mintDetails.length > 0) {
      const mintAmount = bnOrZero(mintDetails.find((param) => param.vname === "amount")?.value ?? "0");
      logger("debug-receipt", "SendResourceToBase", {
        mintAmount,
        amt: mintDetails.find((param) => param.vname === "amount")?.value ?? 0
      })
      setHarvestAmt(mintAmount);
    }

    setHarvestTxHash(harvestResourceTx.id);
    setHarvestComplete(true);
    toaster(`Resource Harvested `, { hash: harvestResourceTx.id!, overridePersist: true });
    dispatch(actions.Token.refetchResource());
  }

  // EVENT HANDLERS -----------------

  const handleIncreaseAllowance = () => {
    runIncreaseAllowance(async () => {
      await increaseAllowance();
    });
  }

  const handleHarvestResource = () => {
    runHarvestResource(async () => {
      await harvestResource();
    })
  }

  const handleSelect = (tokenId: string) => (_event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedMetazoaCopy = selectedMetazoa.slice();
    const index = selectedMetazoaCopy.findIndex((id) => id === tokenId);
    if (index === -1) {
      selectedMetazoaCopy.push(tokenId);
      setSelectedMetazoa(selectedMetazoaCopy);
      // setTotalHunyHarvesting(totalHunyHarvesting + hunyGatheredPerMetazoa[tokenId]);
    } else {
      selectedMetazoaCopy.splice(index, 1);
      setSelectedMetazoa(selectedMetazoaCopy);
      // setTotalHunyHarvesting(totalHunyHarvesting - hunyGatheredPerMetazoa[tokenId]);
    }
  }

  const handleSelectAll = () => {
    const returnableMetazoa = Object.keys(stakedTokens).filter(id => getResourceGathered(stakedTokens[id]) > 0)

    if (selectedMetazoa.length >= Math.min(returnableMetazoa.length, MAX_ZOA.HarvestHuny)) {
      setSelectedMetazoa([]);
    } else {
      setSelectedMetazoa(Object.keys(stakedTokens).filter(id => returnableMetazoa.includes(id)).slice(0, MAX_ZOA.ReturnToBase));
    }
  }

  const handleOnClose = () => {
    if (isLoading) return;
    revertToInitialState();
    onClose();
    setHarvestTxHash("");
    setSelectedMetazoa([]);
    setHarvestComplete(false);
  }

  const handleSendMore = () => {
    if (isLoading) return;
    revertToInitialState();
    setHarvestTxHash("");
    setSelectedMetazoa([]);
    setHarvestComplete(false);
  }

  // --------------------------------

  const addDefaultSrc = (metadata: NftMetadata) => (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!metadata.attributes?.length)
      return;

    const index = metadata.attributes.findIndex(attribute => attribute.trait_type === "Faction");

    if (index === -1)
      return;

    let faction = metadata.attributes[index].value;

    if (faction === "Ursa") {
      e.currentTarget.onerror = null;
      e.currentTarget.src = UrsaDefault;
    } else if (faction === "Mino") {
      e.currentTarget.onerror = null;
      e.currentTarget.src = MinoDefault;
    }
  }

  const revertToInitialState = () => {
    if (harvestComplete) dispatch(actions.Token.reloadTokens());
  };

  const getHeader = () => {
    if (harvestComplete) {
      return "Send to Base Success!";
    } else {
      return "Send Resource to Base";
    }
  }

  const getContent = () => {
    if (harvestComplete) {
      return (
        <Fragment>
          <Box sx={styles.contentBox} style={{ marginTop: 0 }}>
            {/* Qty */}
            <Box display="flex" alignItems="center" mt="20px">
              {/* <Box component="img" src={MinoIcon} mr="10px" sx={styles.placeholderImage} /> */}
              {!!location.resource && (
                <Box
                  component="img"
                  src={location.resource.iconSrc}
                  height="64px"
                  width="64px"
                  onError={QuestConstants.DEFAULT_SRC}
                />
              )}
              <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }}>&nbsp;x&nbsp;{harvestAmt.shiftedBy(-2).toFixed(2)}</Typography>
            </Box>

            {/* Tx hash */}
            <Link
              target="_blank"
              href={getExplorerLink("tx", harvestTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>

            {/* Send more */}
            <ContainedButton
              onClick={handleSendMore}

              sx={styles.sendMoreButton}
            >
              Refine Resource
            </ContainedButton>
          </Box>
        </Fragment>
      )
    }

    if (isLoading) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Box>
      )
    }

    return (
      <Box sx={styles.contentBox}>
        {/* Metazoa */}
        <TableContainer sx={styles.tableContainer}>
          <Table>
            <TableHead sx={styles.tableHead}>
              <TableRow>
                {/* Metazoa */}
                <TableCell align="left">
                  <Typography variant="body1" color="primary">Metazoa</Typography>
                </TableCell>

                {/* Metazoa */}
                <TableCell align="left">
                  <Typography variant="body1" color="primary">Profession</Typography>
                </TableCell>

                {/* Resource */}
                <TableCell align="center">
                  <Typography variant="body1" color="primary" sx={styles.alignResource}>
                    Gathered&nbsp;
                    {!!location.resource && (
                      <Box
                        component="img"
                        src={location.resource.iconSrc}
                        height="24px"
                        width="24px"
                        onError={QuestConstants.DEFAULT_SRC}
                      />
                    )}
                  </Typography>
                </TableCell>

                {/* Fee */}
                <TableCell align="left">
                  <Typography variant="body1" color="primary" component="span">
                    Fee&nbsp;
                    <SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" />
                  </Typography>
                </TableCell>

                {/* Select all */}
                <TableCell align="center">
                  <Typography variant="body1" color="primary" sx={styles.selectAll} onClick={handleSelectAll}>SELECT ALL</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={styles.tableBody}>
              {Object.values(stakedTokens).map((token) => {
                const attributes: any = {};
                token?.attributes?.forEach(attribute => {
                  attributes[attribute.trait_type] = attribute.value;
                })

                return (
                  <TableRow key={token.id}>

                    {/* Metazoa */}
                    <TableCell align="left">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box key={token.id} sx={styles.metazoaWrapper}>
                          {!!token.profession && (
                            <Box
                              component="img"
                              src={PROFESSIONS[STAT_PROFESSION[token.profession]].icon}
                              onError={QuestConstants.DEFAULT_SRC}
                              sx={styles.professionIcon}
                            />
                          )}
                          <Box
                            component="img"
                            src={token.image}
                            alt={`ZOA #${token.id}`}
                            width="40px"
                            sx={styles.zoaImage}
                            onError={addDefaultSrc(token)}
                          />
                        </Box>

                        <Typography variant="body1" color="primary">
                          ZOA #{token.id}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell align="left">
                      <Typography variant="body1" color="primary">{token.profession ? getProfession(token.profession) : "-"}</Typography>
                    </TableCell>

                    {/* Resource */}
                    <TableCell align="center">
                      <Typography variant="body1" color="primary">
                        {getResourceGathered(token).toFixed(2)}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography variant="body1" color="primary">
                        {calculateHarvestFee(questLocation, currentBlock, token, network).toFixed(2)}
                      </Typography>
                    </TableCell>

                    {/* Checkbox */}
                    <TableCell align="center">
                      <Checkbox
                        // icon={<UncheckedIcon />}
                        icon={getResourceGathered(token) <= 0 ? <LockedIcon /> : <UncheckedIcon />}
                        checkedIcon={<CheckedIcon />}
                        disabled={getResourceGathered(token) <= 0}
                        checked={isMetazoaSelected(token.id)}
                        onChange={handleSelect(token.id)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          width: '100%',
          paddingTop: '0px',
        }}>
          <Typography variant="body1" color="primary" component="span">
            Selected Resource
          </Typography>

          <Typography color="primary" variant="h2" sx={{
            whiteSpace: 'nowrap',
            fontSize: '24px',
            // ...TEXT_GRADIENT,
            ...GUILD_GRAY_GRADIENT,
          }}>
            {selectedInfo.totalResourceGatheredSelected.toFixed(0)} / {selectedInfo.totalResourceGathered.toFixed(0)}
            &nbsp;
            {!!location.resource && (
              <Box
                component="img"
                src={location.resource.iconSrc}
                height="24px"
                width="24px"
                onError={QuestConstants.DEFAULT_SRC}
                sx={{
                  verticalAlign: 'middle',
                }}
              />
            )}
          </Typography>
        </Box>
        <Box sx={styles.feeRow}>
          <Typography variant="body1" color="primary" component="span">
            Total Resource Delivery Fee
          </Typography>
          <Typography variant="body1" color="success.main" component="span" fontWeight={600}>
            {selectedInfo.totalDeliveryCost.toFixed(2)}&nbsp; HUNY &nbsp;
            <SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" />
          </Typography>
        </Box>
        <Box sx={styles.buttonRow}>
          {needIncreaseAllowance && !isExceedMaxHarvestBlock && selectedInfo.totalResourceGatheredSelected > 0 && (
            <ContainedButton
              onClick={handleIncreaseAllowance}
              disabled={loadingFetchQuestHunyAllowance || loadingIncreaseAllowance}
              sx={styles.confirmButton}
            >
              {(loadingFetchQuestHunyAllowance || loadingIncreaseAllowance)
                ? <CircularProgress size={18} />
                : "Approve"
              }
            </ContainedButton>
          )}
          <ContainedButton
            onClick={handleHarvestResource}
            disabled={!selectedMetazoa.length || isLoading || (needIncreaseAllowance && !isExceedMaxHarvestBlock && selectedInfo.totalResourceGatheredSelected > 0)}
            sx={styles.confirmButton}
          >
            {isLoading
              ? <CircularProgress size={18} />
              : "Confirm"
            }
          </ContainedButton>
        </Box>
        {/* Confirm*/}

      </Box>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true} dialogOverwrites={{ placeItems: 'center' }}>
      {getContent()}
    </DialogModal>
  )
};


const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    placeContent: 'start',
    alignItems: "center",
    width: "100%",
    height: "100%",
    flex: 1,
    marginTop: "10px",
  },
  tableContainer: {
    overflowY: "auto",
    maxHeight: "25vh",
    maxWidth: "700px",
    paddingBottom: "10px",
    minHeight: '250px',
    flex: 1,
    height: '100%',
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "transparent",
      top: "",
      left: "",
    },
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: "10px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },
  tableHead: {
    "& th.MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  alignResource: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  confirmButton: {
    marginTop: "20px",
    height: 60,
    minWidth: 300,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  selectAll: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
    }
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    marginTop: "10px",
  },

  metazoaWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    placeItems: 'center',
  },
  zoaImage: {
    width: '40px',
    marginRight: "10px",
    borderRadius: "16px",
    border: '1px solid #AEF1EE',
  },
  professionIcon: {
    position: 'absolute',
    right: '0',
    top: '-15%',
    minHeight: "20px",
    minWidth: "20px",
    maxWidth: '40%',
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "20px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  sendMoreButton: {
    marginTop: "20px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  placeholderImage: {
    height: "60px",
    width: "60px",
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  feeRow: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    mt: '8px',
    mb: '8px',
  },
  hunyIcon: {
    verticalAlign: "top",
  },

  buttonRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  }
}

export default SendResourceToBase;
