import { default as MuiButton } from "./MuiButton";
import { default as MuiDialog } from "./MuiDialog";
import { default as MuiDrawer } from "./MuiDrawer";
import { default as MuiOutlinedInput } from "./MuiOutlinedInput";
import { default as MuiTooltip } from "./MuiTooltip";
import { default as MuiToggleButton } from "./MuiToggleButton";
import { default as MuiToggleButtonGroup } from "./MuiToggleButtonGroup";
import { default as MuiAccordion } from './MuiAccordion';

const OverrideStyles = {
  MuiButton,
  MuiDialog,
  MuiDrawer,
  MuiOutlinedInput,
  MuiTooltip,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiAccordion,
}

export default OverrideStyles;
