import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import InBatteMino from "assets/InBatteMino.svg";
import InBattleUrsa from "assets/InBattleUrsa.svg";
import { DEFAULT_SRC } from "components/Guild/components/GuildConstants";
import { NftMetadata } from "store/types";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";

export interface MetazoaArrayProps {
  metazoaArray?: NftMetadata[];
  stakedMetazoa?: NftMetadata[];
}

const RenderMemberMetazoa: React.FC<MetazoaArrayProps> = (props: MetazoaArrayProps) => {
  const { metazoaArray, stakedMetazoa } = props;
  const minoArray = metazoaArray?.filter(token => token?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value === "Mino");
  const ursaArray = metazoaArray?.filter(token => token?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value === "Ursa");
  const stakedMinoArray = stakedMetazoa?.filter(token => token?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value === "Mino");
  const stakedUrsaArray = stakedMetazoa?.filter(token => token?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value === "Ursa");
  const sortedArray = [...(stakedMinoArray || []), ...(stakedUrsaArray || []), ...(minoArray || []), ...(ursaArray || [])]

  return (
    <Box sx={styles.memberListMiniMem}>
      {sortedArray.slice(0, (sortedArray.length < 8 ? sortedArray.length : 8)).map((metazoa) => (
        <Tooltip
          key={metazoa.id}
          title={(stakedMetazoa?.includes(metazoa)) ? "In Battle" : ""}
          sx={styles.inBattleTooltip}>
          <Box
            sx={combineStyles(styles.miniMetazoaWrapper, {
              ...(stakedMetazoa?.includes(metazoa)) && {
                backgroundImage: `url(${metazoa?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value === "Mino" ? InBatteMino : InBattleUrsa})`,
              }
            })}> <Box component="img" src={metazoa.image}
              sx={combineStyles(
                styles.memberListMiniImg,
                (metazoa?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value === "Mino") ? styles.kidnappedMino : styles.kidnappedUrsa,
              )}
              onError={DEFAULT_SRC} />
          </Box>
        </Tooltip>
      ))}
      {sortedArray.length > 8 && <Box sx={styles.miniMetazoaWrapper}>
        <Typography variant="body1" color="secondary" sx={combineStyles(
          styles.memberListMiniImg,
          styles.kidnappedUrsa,
          styles.remainingKidnappedCount,
        )}>+{sortedArray.length - 8}</Typography></Box>}
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  memberListMiniMem: {
    height: "24px",
    position: "absolute",
    bottom: "0.7em",

    right: 0,
    display: "inline-flex",
    flexDirection: "row",
    gap: "7px",
    placeItems: "center",
    paddingTop: "0.3em",
    paddingRight: "0.1em",
    "@media only screen and (min-width: 450px)": {
      right: '15%',
      bottom: '0',
    },
    "@media only screen and (min-width: 1000px)": {
      right: '30%',
      bottom: 0,
    },
    "@media only screen and (min-width: 1100px)": {
      right: 0,
      bottom: '0.5em',
    },
    "@media only screen and (min-width: 1200px)": {
      bottom: 0,
    },
  },
  memberListMiniImg: {
    width: "25px",
    height: "25px",

    borderRadius: '100%',
    border: '1px solid transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  kidnappedMino: {
    background: "linear-gradient(rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87)) padding-box, linear-gradient(to right, rgba(255, 137, 82, 1), rgba(255, 255, 255, 0)) border-box",
  },

  kidnappedUrsa: {
    background: "linear-gradient(rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87)) padding-box, linear-gradient(to right, rgba(73, 255, 244, 1), rgba(255, 255, 255, 0)) border-box",
  },

  remainingKidnappedCount: {
    fontWeight: '500',
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    letterSpacing: '20%',
    textAlign: 'center',
    padding: '0 1%',
  },

  miniMetazoaWrapper: {
    height: '40px',
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'end',
    placeItems: 'center',
    backgroundRepeat: "no-repeat",
    backgroundSize: "130% 100%",
    backgroundPositionX: "center",
  },

  inBattleTooltip: {
    padding: '4px 8px',
    boxShadow: "0px 4px 24px #000000",
    borderRadius: '16px',
  }
}

export default RenderMemberMetazoa;