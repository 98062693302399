import { GuildActionTypes, MetazoaGuildActionTypes } from "./actions";
import { GuildState } from "./types";

const initial_state: GuildState = {
  guild: undefined,
  allGuilds: undefined,
  bank: undefined,
  allowances: undefined,
  transactions: {
    transactionsList: [],
    exMembers: []
  },
  bankTaxes: []
}

const reducer = (state: GuildState = initial_state, action: any): GuildState => {
  const { payload, type } = action;
  switch (type) {
    case GuildActionTypes.UPDATE_GUILD:
      return {
        ...state,
        guild: {
          ...payload
        }
      }
    case GuildActionTypes.UPDATE_BANK:
      return {
        ...state,
        bank: {
          ...payload
        }
      }
    case MetazoaGuildActionTypes.RELOAD_GUILD:
      return {
        ...state,
        guild: {
          ...payload
        }
      }
    case MetazoaGuildActionTypes.RELOAD_BANK:
      return {
        ...state,
        guild: {
          ...payload
        }
      }
    case GuildActionTypes.UPDATE_GUILDS:
      return {
        ...state,
        allGuilds: [
          ...payload
        ]
      }
    case GuildActionTypes.UPDATE_ALLOWANCES:
      return {
        ...state,
        allowances: payload
      }
    case GuildActionTypes.UPDATE_BANK_TRANSACTIONS:
      return {
        ...state,
        transactions: payload
      }
    case GuildActionTypes.UPDATE_BANK_TAXES:
      return {
        ...state,
        bankTaxes: payload
      }
    default:
      return state;
  }
}

export default reducer;
