import { Box, Button, Container, styled, SxProps, Typography } from "@mui/material";
import { MissionLine } from "assets";
import SaturnAnimation from "assets/lotties/Saturn.json";
import { FC } from "react";
import Lottie from "react-lottie";
import { theme } from "theme";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";

const FAQ: FC = () => {
    return (
        <Container maxWidth="xl" sx={styles.container} component="section">
            <Box maxWidth="xl" sx={styles.contentBox} component="main">
                <Box
                    width={{
                        xs: "100%",
                        md: "90%",
                    }}
                    gap={'24px'}
                    sx={combineStyles(
                        styles.textColumn,
                        {
                            position: 'relative',
                        }
                    )}
                    textAlign="center"
                >
                    <Typography
                        variant="overline"
                        color="secondary.main"
                        sx={styles.subheader}
                    >
                        FAQ
                    </Typography>
                    <Typography variant="h1" color="primary">
                        Preparation is everything
                    </Typography>
                    <MissionLineBox>
                        <MissionLine width="100%" />
                    </MissionLineBox>
                    <Typography width={{ xs: "100%", sm: "90%", md: "80%", }} component={"span"} variant="body1" color="primary" sx={styles.textbody}>
                        Answer your burning questions on our FAQ page in our whitepaper.
                    </Typography>

                    <Button size="medium" variant="outlined" color="secondary" target="_blank" href="https://docs.zolar.io/metazoa/faqs">
                        <Typography variant="button" sx={styles.gradientText}>Get Your Answers</Typography>
                    </Button>

                    <MarsBox id="mars-lottie">
                        <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
                    </MarsBox>
                </Box>

            </Box >
        </Container >
    )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
    container: {
        padding: '40px 0px',

        // //DEBUG: Remove this
        // border: '1px solid yellow',
        // "*": {
        //     border: '1px solid red',
        // }
    },
    contentBox: {
        position: 'relative',
        padding: "76px 0px 0px",
        "@media (min-width:600px)": {
            padding: "40px 0px 80px",
        },
    },
    textColumn: {
        mx: 'auto',
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: 1,
    },
    subheader: {
        textTransform: "uppercase",
        letterSpacing: "0.24em",
        zIndex: 1,
    },
}

const MissionLineBox = styled(Box)({
    width: "20%",
    "@media (max-width:600px)": {
        width: "50%",
    },
});

const MarsBox = styled(Box)({
    pointerEvents: 'none',
    position: "absolute",
    zIndex: -100,
    left: '-30%',

    width: '60%',
    maxHeight: '400px',

    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',

    [theme.breakpoints.down('md')]: {
        position: 'relative',
        inset: 0,
        top: '-5%',
        width: '100%',
    },
});

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SaturnAnimation,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


export default FAQ