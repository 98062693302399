import { Box, CircularProgress, Link, SvgIcon, SxProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { ExternalLink, Huny } from "assets";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { waitForTx } from "core/utilities";
import { MetazoaClient } from "core/utilities/metazoa";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { Guild, OAuth } from "store/types";
import { TBMConnector } from "tbm";
import { useRedux, useToaster } from "utils";
import { Decimals } from "utils/constants";
import { bnOrZero, formatGuildHuny, getExplorerLink } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from "utils/useNetwork";

export interface Props {
  guild: Guild;
  canRequest: boolean;
  pendingRequest: boolean;
  setPendingRequest: React.Dispatch<React.SetStateAction<boolean>>;
  loadingPendingRequest: boolean;
}

const JoinRequestButton: React.FC<Props> = (props: Props) => {
  const { guild, pendingRequest, setPendingRequest, loadingPendingRequest, canRequest } = props;
  const { guildBank } = guild;
  const hasGuildBank: boolean = !!guildBank ?? false;

  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();
  const metazoaProfileState = useSelector(getProfile);
  const { oAuth } = metazoaProfileState;
  const wallet = useRedux((state) => state.wallet.wallet);
  const allowanceStore = useRedux((state) => state.guild.allowances);

  // STATES / HOOKS -----------------
  const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0));
  const [showJoinRequestDialog, setShowJoinRequestDialog] = useState<boolean>(false);
  const [joinRequestApproved, setJoinRequestApproved] = useState<boolean>(false);
  const [requestTxHash, setRequestTxHash] = useState<string>("");
  const [runRequestJoinGuild, loadingRequestJoinGuild] = useAsyncTask("requestJoinGuild", (error) => {
    toaster(error?.message ?? "Error Requesting Join Guild");
  });
  const [runCancelRequest, loadingCancelRequest] = useAsyncTask("requestCancelJoinGuild", (error) => {
    toaster(error?.message ?? "Error Cancelling Join Request");
  });
  const [runApproveAllowance, loadingApproveAllowance] = useAsyncTask("requestApproveAllowance", (error) => {
    toaster(error?.message ?? "Error Approving");
  });

  useEffect(() => {
    if (!guildBank || !wallet) return;
    if (!allowanceStore) {
      dispatch(actions.Guild.loadAllowances());
      return;
    }
    const abortController = new AbortController();

    runApproveAllowance(async () => {
      if (joinRequestApproved || !guildBank.address) return;
      const walletAddress = wallet.addressInfo.byte20.toLowerCase();

      const bankAllowance = allowanceStore[walletAddress]?.[guildBank.address];
      if (!!bankAllowance) {
        const allowance: BigNumber = bnOrZero(bankAllowance).shiftedBy(-Decimals.HUNY)
        setAllowance(allowance);
        setJoinRequestApproved(!!bankAllowance);
      }

      // const approved = await TBMConnector.checkEmporiumApproved(wallet);
      // setJoinRequestApproved(approved);
    })
    return abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, wallet, guildBank, allowanceStore])

  // FUNCTIONS ----------------------

  const resetDialogStates = () => {
    setTimeout(() => {
      setRequestTxHash("")
    }, 500)
  }

  const requestApproveAllowance = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!guildBank || !guildBank.address) return;

    const approveAmount = new BigNumber(2).pow(128).minus(1).minus(allowance.shiftedBy(Decimals.HUNY)).dp(0);
    const approveTx = await TBMConnector.increaseGuildBankAllowance(wallet, toBech32Address(guildBank.address), approveAmount);
    toaster(`Submitted Approve Increase Allowance`, { hash: approveTx.id! });

    if (approveTx?.id) {
      try {
        await waitForTx(approveTx.id);
        toaster(`Successful Approve Increase Allowance`, { hash: approveTx.id! });
        setJoinRequestApproved(true);
        dispatch(actions.Guild.loadAllowances());
      } catch (e) {
        console.error(e);
        setJoinRequestApproved(false);
        throw e;
      }
    }
  }

  const requestJoinGuild = async () => {
    if (pendingRequest) return;

    if (!wallet) throw new Error("Wallet not connected");
    if (pendingRequest || !guild) return;

    if (hasGuildBank && guildBank && guildBank.address) {
      const applyMembershipTx = await TBMConnector.applyForMembership(wallet, toBech32Address(guildBank.address));
      toaster(`Submitted Join Request`, { hash: applyMembershipTx.id! });

      if (applyMembershipTx?.id) {
        try {
          await waitForTx(applyMembershipTx.id);
          setRequestTxHash(applyMembershipTx.id);
          setPendingRequest(true);
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    }
    else {
      const metazoaClient = new MetazoaClient(network);

      let checkedOAuth: OAuth | undefined = oAuth;
      if (!oAuth?.access_token || oAuth.address !== wallet.addressInfo.bech32 || (oAuth && dayjs(oAuth?.expires_at * 1000).isBefore(dayjs()))) {
        const { result } = await metazoaClient.metazoaLogin(wallet, window.location.hostname);
        dispatch(actions.Profile.updateAccessToken(result));
        checkedOAuth = result;
      }

      await metazoaClient.joinGuild(guild.id, checkedOAuth!);
      toaster(`Submitted Join Request`)
      setPendingRequest(true);
    }
  }

  const requestCancelJoinGuild = () => {
    runCancelRequest(async () => {
      if (!wallet) throw new Error("Wallet not connected");
      if (!pendingRequest || !guildBank || !guildBank.address) return;

      const applyMembershipTx = await TBMConnector.cancelJoinRequest(wallet, toBech32Address(guildBank.address));
      toaster(`Submitted Cancel Request`, { hash: applyMembershipTx.id! })

      if (applyMembershipTx?.id) {
        try {
          await waitForTx(applyMembershipTx.id);
          toaster(`Successful Cancel Request`, { hash: applyMembershipTx.id! })
          setPendingRequest(false)
          resetDialogStates()
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    })
  }

  // EVENT HANDLERS -----------------

  const handleOnRequest = () => {
    if (hasGuildBank) {
      if (pendingRequest) requestCancelJoinGuild();
      else {
        resetDialogStates()
        setShowJoinRequestDialog(true);
      }
    }
    else requestJoinGuild()
  }

  const handleOnClose = () => {
    setShowJoinRequestDialog(false)
    resetDialogStates()
  }

  const handleApproveAllowance = () => {
    runApproveAllowance(async () => {
      await requestApproveAllowance();
    })
  }

  const handleConfirmation = () => {
    runRequestJoinGuild(async () => {
      await requestJoinGuild();
    })
  }

  // --------------------------------

  const isLoading: boolean = loadingPendingRequest || loadingApproveAllowance || loadingRequestJoinGuild || loadingCancelRequest;
  const isDisabled: boolean = !canRequest || isLoading;

  return (
    <>
      <ContainedButton sx={styles.requestButton}
        onClick={handleOnRequest}
        disabled={(pendingRequest && !hasGuildBank) || isLoading || !canRequest}
      >
        {isLoading
          ? <CircularProgress size={18} />
          : pendingRequest
            ? hasGuildBank ? "Cancel Request" : "Request Sent"
            : "Request to Join"
        }
      </ContainedButton>

      {showJoinRequestDialog && (
        <DialogModal
          header={!requestTxHash ? `Request to Join` : `Request Sent`}
          open={showJoinRequestDialog}
          onClose={handleOnClose}
          sx={styles.dialogModal}
          disableScrollLock={true}>
          <Box sx={styles.contentBox}>
            {!requestTxHash && guildBank && (
              <>
                <Typography variant="body1" color="primary" sx={styles.joinInfo}>
                  To join this guild, you will need to pay a&nbsp;
                  <span className="hunyAmt">
                    <Huny width={40} height={40} />
                    {formatGuildHuny(bnOrZero(guildBank.joiningFee.initialAmt).shiftedBy(-Decimals.HUNY))}
                  </span>
                  &nbsp;joining fee which will be deducted upon membership approval.
                </Typography>
                <Typography variant="body1" color="primary" sx={styles.joinInfo} mt="16px">
                  Once you are a guild member, a&nbsp;
                  <span className="hunyAmt">
                    <Huny width={40} height={40} />
                    {formatGuildHuny(bnOrZero(guildBank.weeklyTax.initialAmt).shiftedBy(-Decimals.HUNY))}
                  </span>
                  &nbsp;weekly tax will be incurred thereafter.
                </Typography>


                <Box sx={styles.guildRequestInfo}>
                  <Typography variant="body1" color="primary">
                    Guild
                  </Typography>
                  <Typography variant="h3" color="primary">
                    {guild.name}
                  </Typography>
                </Box>


                <Box sx={styles.buttonRow}>
                  <ContainedButton fullWidth sx={styles.button}
                    disabled={isDisabled || joinRequestApproved}
                    onClick={handleApproveAllowance}
                  >
                    {loadingApproveAllowance
                      ? <CircularProgress size={18} />
                      : "approve"
                    }
                  </ContainedButton>
                  <ContainedButton
                    sx={styles.button}
                    disabled={isDisabled || !joinRequestApproved}
                    onClick={handleConfirmation}
                  >
                    {loadingRequestJoinGuild
                      ? <CircularProgress size={18} />
                      : "send join request"
                    }
                  </ContainedButton>
                </Box>

              </>
            )}

            {requestTxHash && (
              <>
                <Typography variant="body1" color="primary" sx={styles.joinInfo}>
                  You will see your name on the Guild’s Member List once your request has been approved. The joining fee will also be deducted from your wallet upon membership approval.
                </Typography>

                <Link
                  target="_blank"
                  href={getExplorerLink("tx", requestTxHash, network)}
                  sx={styles.viewTx}
                >
                  View Transaction
                  <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
                </Link>

                <ContainedButton
                  variant="contained"
                  color="secondary"
                  onClick={handleOnClose}
                >
                  back to guild
                </ContainedButton>
              </>
            )}
          </Box>
        </DialogModal>
      )}

    </>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  requestButton: {
    height: 48,
    minWidth: 280,
    "&.MuiButton-root": {
      fontSize: "18px",
      lineHeight: "28px",
      whiteSpace: "nowrap",
    },
    "& .MuiBox-root": {
      display: "none",
    },
    "@media (max-width:600px)": {
      height: 40,
      "&.MuiButton-root": {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },

  // DIALOG -------------------------
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  joinInfo: {
    textAlign: 'center',
    fontSize: '24px',
    lineHeight: '150%',

    ".hunyAmt": {
      display: 'inline-flex',
      placeContent: 'center',
      placeItems: 'center',
      verticalAlign: 'middle',
      gap: '8px',
      flexWrap: 'nowrap',

      color: '#2FF583',
      fontWeight: 700,
    },

    "button": {
      padding: '0 4px',
    }
  },
  tooltip: {
    padding: '20px',
    width: '420px',

    "svg": {
      width: '24px',
      height: '24px',
    }
  },
  guildRequestInfo: {
    display: 'flex',
    placeContent: 'space-between',
    placeItems: 'center',
    flexDirection: 'row',

    marginY: '32px',
    width: '100%',

    "h3": {
      textTransform: 'uppercase',
    }
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px',
    gap: '32px',
    alignItems: 'center',
    width: '100%',

    "button:first-of-type": { flex: 0.6 },
  },
  button: {
    minWidth: '250px',
    flex: 1,
    height: 64,
  },
  viewTx: {
    marginTop: "20px",
    marginBottom: "40px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
}

export default JoinRequestButton;
