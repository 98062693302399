import { Box, Container, SxProps } from "@mui/material";
// import { RefineryBanner } from "assets";
import RefineryBanner from "assets/RefineryBanner.png";
import StripeHeader from "components/Game/components/StripeHeader";
import { MetazoaLayout } from "layout";
import { AppTheme, SimpleMap } from "utils/types";
import HunyOverview from "./components/HunyOverview";
import HunyPots from "./components/HunyPots";

const Refinery:React.FC = () => {
  return (
    <MetazoaLayout>
      <Container maxWidth="xl" sx={styles.container}>
        <Box sx={styles.refineryWrapper}>
          <StripeHeader >Refinery</StripeHeader>
          <Box sx={styles.bannerWrapper} />
          <HunyOverview />
          <HunyPots />
        </Box>
      </Container>
    </MetazoaLayout>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    position: "relative",
    padding: "0px 40px 100px",
    minHeight: "1200px",
    width: '100%',
    overflowX: 'auto',
  },
  refineryWrapper: {
    marginTop: '100px',
    padding: '40px',
    minHeight: '500px',
    minWidth: '720px',
    border: '1px solid #AEF1EE33',
    borderRadius: '16px',
    background: 'linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, #000010 100%)',
  },
  bannerWrapper: {
    marginTop: '30px',
    width: '100%',
    height: '320px',
    backgroundImage: `url(${RefineryBanner})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '16px',
    border: '1px solid #AEF1EE33',
  }
}

export default Refinery;