import { Box, Link, SxProps, Typography } from "@mui/material"
import { GuildBankBannerFrame, GuildBankBannerFrameMobile, GuildBankBannerLines, Huny } from "assets"
import ContainedButton from "components/ContainedButton"
import { convertBpsAllocation, GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants"
import TooltipZolar from "components/Tooltip"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getProfile } from "saga/selectors"
import { Guild, GuildBankInfo } from "store/types"
import { Decimals } from "utils/constants"
import { bnOrZero, formatIntegerNumber } from "utils/strings"
import { combineStyles } from "utils/themeUtilities"
import { AppTheme, SimpleMap } from "utils/types"

export interface Props {
  guild: Guild;
  bank?: GuildBankInfo;
}

const GuildBankPreview: React.FC<Props> = (props: Props) => {
  const { guild, bank } = props;
  const hasGuildBank: boolean = !!bank ?? false;

  const navigate = useNavigate();
  const metazoaProfileState = useSelector(getProfile);
  const { profile } = metazoaProfileState;

  // STATES / HOOKS -----------------

  const currentProfile = useMemo(() => {
    if (!profile || !guild) return;

    const addr: string = profile.address

    //INFO: Current profile attributes
    const isLeader: boolean = guild.leaderAddress === addr
    const canInitiate: boolean = isLeader;

    return { addr, isLeader, canInitiate }
    // eslint-disable-next-line 
  }, [guild, profile])

  // --------------------------------

  return (
    <Box sx={combineStyles(styles.bankBannerPreivew,
      { ...(!hasGuildBank) ? styles.singleBankBannerPreview : {} }
    )}>
      <GuildBankBannerFrame className="frame" />
      {hasGuildBank && (<GuildBankBannerFrameMobile className="frameMobile" />)}

      <Box sx={combineStyles(styles.bankBannerPreviewContent,
        { ...(!hasGuildBank) ? styles.noBankPreview : {} }
      )}>

        {/* //INFO: Leader w/o guild bank */}
        {!hasGuildBank && currentProfile?.canInitiate && (
          <>
            <Box sx={styles.bankPreviewSection}>
              <Typography variant="body1" color="primary" sx={styles.bankPreviewSectionText}>
                Unlock guild bank to pool your guild’s assets and
                participate in future airdrops and missions!
              </Typography>
            </Box>

            <Box sx={combineStyles(styles.bankPreviewSection, styles.bankAmtPreview, { placeItems: 'end', })}>
              <ContainedButton sx={styles.bankCTA} onClick={() => navigate(`/guilds/${guild.id}/bank/settings`)}>
                UNLOCK GUILD BANK
              </ContainedButton>
            </Box>
          </>
        )}

        {!hasGuildBank && !currentProfile?.canInitiate && (
          <>
            <Box sx={combineStyles(styles.bankPreviewSection, {
              ...(!currentProfile?.canInitiate) && { marginRight: 'auto', }
            })}>

              <Typography variant="body1" color="primary" sx={styles.bankPreviewSectionText}>
                Guild bank hasn’t been unlocked yet.
              </Typography>
              <Link
                sx={styles.learnMore}
                target="_blank"
                href="https://docs.zolar.io/stations/guilds/guild-bank-new"
              >
                Learn More
              </Link>
            </Box>
          </>
        )}


        {hasGuildBank && bank && (
          <>
            <Box sx={styles.bankPreviewSection}>
              <Box sx={styles.previewSectionTitle}>
                <Typography variant="overline" color="primary" className="title">
                  Joining Fee
                </Typography>
                {/* //TODO: Link TBC */}
                <TooltipZolar link={"https://docs.zolar.io/stations/guilds/guild-bank-new"} sx={styles.tooltip}>
                  Joining Fee collected will be distributed as such:
                  <li>{100 - convertBpsAllocation(bank?.joiningFee?.captainAllocation ?? 0) - convertBpsAllocation((bank?.joiningFee?.officerAllocation * guild?.commanderAddresses?.length))}% to Guild Bank Vault</li>
                  <li>{convertBpsAllocation(bank?.joiningFee?.captainAllocation ?? 0)}% to Captain</li>
                  <li>{convertBpsAllocation((bank?.joiningFee?.officerAllocation ?? 0))}% to Each Officer</li>
                </TooltipZolar>
              </Box>

              <Box sx={styles.bankPreviewSectionHuny}>
                <Huny width={16} height={16} />
                <Typography variant="h3" color="primary" className="amt">
                  {formatIntegerNumber(bnOrZero(bank?.joiningFee?.initialAmt ?? 0).shiftedBy(-Decimals.HUNY))}
                </Typography>
              </Box>

              <Typography variant="body1" color="primary" sx={styles.bankPreviewSectionSubtext}>
                A one-time joining fee for newcomers.
              </Typography>
            </Box>


            <Box sx={styles.bankPreviewSection}>
              <Box sx={styles.previewSectionTitle}>
                <Typography variant="overline" color="primary" className="title">
                  Weekly tax
                </Typography>
                {/* //TODO: Link TBC */}
                <TooltipZolar link={"https://docs.zolar.io/stations/guilds/guild-bank-new"} sx={styles.tooltip}>
                  Weekly Tax collected will be distributed as such:
                  <li>{100 - convertBpsAllocation(bank?.weeklyTax?.captainAllocation ?? 0) - convertBpsAllocation((bank?.weeklyTax?.officerAllocation * guild.commanderAddresses.length))}% to Guild Bank Vault</li>
                  <li>{convertBpsAllocation(bank?.weeklyTax?.captainAllocation ?? 0)}% to Captain</li>
                  <li>{convertBpsAllocation((bank?.weeklyTax?.officerAllocation ?? 0))}% to Each Officer</li>
                  Weekly increments may be applied depending on the guild.
                </TooltipZolar>
              </Box>

              <Box sx={styles.bankPreviewSectionHuny}>
                <Huny width={16} height={16} />
                <Typography variant="h3" color="primary" className="amt">
                  {formatIntegerNumber(bnOrZero(bank?.weeklyTax?.initialAmt ?? 0).shiftedBy(-Decimals.HUNY))}
                </Typography>
              </Box>
            </Box>

            <Box sx={combineStyles(styles.bankPreviewSection, styles.bankAmtPreview)}>
              <Typography variant="h2" color="primary">
                ${(bank?.netWorth?.valuation ?? 0).toFixed(2)}
              </Typography>

              <Box className="dividers">
                <hr />
                <hr />
              </Box>

              <ContainedButton
                sx={combineStyles(styles.bankCTA, styles.guildBankNav)}
                onClick={() => navigate(`/guilds/${guild.id}/bank`)}>
                Guild Bank
              </ContainedButton>
            </Box>
          </>)}


      </Box>
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  //INFO: Bank banner preview:
  bankBannerPreivew: {
    width: '100%',
    position: 'relative',
    margin: '5% 0',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    overflowX: 'clip',

    ".frame": {
      position: 'absolute',
      bottom: "-20%",
      width: '100%',
      transform: 'scaleX(1.05)',

      "@media (max-width:1130px)": {
        display: 'none',
      },
    },

    ".frameMobile": {
      position: 'absolute',
      width: '100%',

      "@media (min-width:1130px)": {

        display: 'none',
      },
      "@media (max-width:1030px)": {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        transform: 'scaleY(1.1)',
        transformOrigin: '50% 110%',
      },
      "@media (max-width:600px)": {

        width: '100%',
        transform: 'scaleY(1.2)',
        transformOrigin: '50% 85%',
      },
    }
  },
  singleBankBannerPreview: {
    "> svg": {
      bottom: 'unset!important',
      display: 'unset!important',
    }
  },
  bankBannerPreviewContent: {
    width: '100%',
    display: 'flex',
    placeContent: 'center',
    flexDirection: 'row',
    placeItems: 'center',
    margin: '1% 2% 2%',
    padding: '1% 2% 2%',
    gap: '4%',

    position: 'relative',
    zIndex: 10,
    // background: 'radial-gradient(151.44% 152.65% at 49.92% 12.81%, rgba(255, 234, 180, 0.1) 9.64%, rgba(252, 206, 88, 0.1) 43.75%)',
    borderRadius: '16px',

    "@media (max-width:1130px)": {

      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      padding: '4% 2% 1%',

      "> div": {
        maxWidth: "unset",
        height: "100%",
        width: "100%",
        placeContent: "start",
      }
    },
  },
  noBankPreview: {
    display: 'flex!important',

    "> div": {
      display: 'flex!important',
      padding: '2%!important',
    }
  },
  bankPreviewContent: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'start',
    placeItems: 'center',
    gap: '8px',

    "@media (max-width:900px)": {
      width: "85%",
    },
  },
  bankPreviewSection: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'start',
    placeItems: 'start',
    gap: '8px',

    ".title": {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '150%',

      textAlign: 'center',
      letterSpacing: '0.2em',
      textYransform: 'uppercase',

      color: '#FF8952',
    },
    "button": {
      padding: 0,
      "svg": {
        width: 16,
        height: 16,
      }
    }
  },
  previewSectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'start',
    placeItems: 'start',
    gap: '6px',
  },
  bankAmtPreview: {
    gap: 0,
    placeContent: 'center',
    placeItems: 'end',
    maxWidth: 'unset',
    flex: 1,

    backgroundImage: `url(${GuildBankBannerLines})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 135%",
    backgroundPosition: "center",
    backgroundPositionX: '110%',

    "@media (max-width:1130px)": {
      gridColumn: '1/-1',
      width: '100%',

    },

    ".dividers": {
      width: "100%",

      display: 'flex',
      placeContent: 'center',
      placeItems: 'end',
      flexDirection: 'column',
      gap: '0.05em',

      "hr": {
        height: "1.33px",
        background: 'radial-gradient(151.44% 152.65% at 49.92% 12.81%, #FFEAB4 9.64%, #FCCE58 43.75%)',
        width: "100%",
      },

      "hr:last-of-type": {
        width: '60%',
        marginRight: 0,
        marginTop: "-8px",
      }
    },

  },
  bankCTA: {
    height: 48,
    width: 200,
    background: 'radial-gradient(93.75% 198.35% at 93.75% 18.18%, #FFFFFF 0%, #FFB600 32.81%, #FF8952 100%)',

    "&.MuiButton-root": {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "28px",
      whiteSpace: "nowrap",
      padding: '0 7.5rem',
    },

    "> div": {
      overflow: 'hidden',

      "> svg": {
        width: '100%',
        height: '100%',
      }
    },
  },
  guildBankNav: {
    "> div": {
      width: '100%',
      height: '100%',
      top: 0,
      left: '-28%',
      overflow: 'hidden',

      "> svg": {
        width: '100%',
        height: '100%',
      }
    },
  },
  bankPreviewSectionHuny: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'start',
    placeItems: 'center',
    gap: '8px',

    ".HunyIcon": {
      width: 20,
      height: 20,
    },
    ".amt": {
      fontSize: '18px',
      lineHeight: '150%',
      ...GUILD_GRAY_GRADIENT,
    }
  },
  bankPreviewSectionSubtext: {
    fontSize: '12px',
    lineHeight: '18px',
    ...GUILD_GRAY_GRADIENT,

    ".hunyAmt": {
      display: 'inline-flex',
      flexDirection: 'row',
      placeContent: 'center',
      placeItems: 'center',
      gap: '4px',
      verticalAlign: 'middle',
    },
  },
  bankPreviewSectionText: {
    fontSize: '18px',
    lineHeight: '28px',
    ...GUILD_GRAY_GRADIENT,

    "@media (max-width:1130px)": {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  learnMore: {
    background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 600,

    "@media (max-width:1130px)": {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  tooltip: {
    padding: '20px',
    width: '420px',

    "svg": {
      width: '24px',
      height: '24px',
    }
  },
}

export default GuildBankPreview;