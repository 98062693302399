import { Box, SxProps, Typography } from "@mui/material";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";

export interface TagProps {
  label: string;
  background?: string;
  labelColor?: string;
}

const Tag: React.FC<TagProps> = (props: TagProps) => {
  const { label, background, labelColor } = props;

  const defaultTagBackground = "radial-gradient(93.75% 198.35% at 93.75% 18.18%, #FFFFFF 0%, #FFB600 32.81%, #FF8952 100%)"
  const defaultLabelColor = "rgba(0, 0, 0, 0.87)"

  return (
    <Box sx={combineStyles(styles.tag, { background: background ?? defaultTagBackground })}>
      <Typography sx={combineStyles(styles.tagText, { color: labelColor ?? defaultLabelColor })}>
        {label}
      </Typography>
    </Box>);
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  tagText: {
    fontSize: '12px',
    padding: '2px 12px',
    lineHeight: "18px",
    whiteSpace: 'nowrap'
  },
  tag: {
    borderRadius: '16px',
    width: 'min-content',
    height: 'min-content',
  }
}

export default Tag;