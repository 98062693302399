import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Link, styled, SxProps, Typography } from "@mui/material";
import { BaseButton } from "assets";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import { DialogModal } from "components/DialogModal";
import dayjs, { Dayjs } from "dayjs";
import { TBMConnector } from "tbm";
import { LoadingKeys } from "utils/constants";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";
import useTaskSubscriber from "utils/useTaskSubscriber";
import { Network } from "zilswap-sdk/lib/constants";
import BearSelection from "./BearSelection/BearSelection";

const BLOCKS_PER_MINUTE = 1.75;

const useBlockTime = () => {
  const wallet = useRedux((state) => state.wallet.wallet);
  const network = wallet?.network || Network.MainNet;
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const [blockTime, setBlockTime] = useState<Dayjs>(dayjs());
  const [currentTime, setCurrentTime] = useState<Dayjs>(dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        const newBlock = TBMConnector.getCurrentBlock();
        if (newBlock !== currentBlock) {
          setCurrentBlock(newBlock)
          setBlockTime(dayjs())
        }
      } catch (e) {
        console.warn('Failed to get current block. Will try again in 1s. Error:')
        console.warn(e)
      }
      setCurrentTime(dayjs())
    }, 1000);
    return () => clearInterval(interval)
  }, [network, currentBlock])

  return [blockTime, currentBlock, currentTime] as [Dayjs, number, Dayjs]
}

const BurnBear: React.FC = () => {
  const [isLoading] = useTaskSubscriber(...LoadingKeys.connectWallet);
  const [loadingTokens] = useTaskSubscriber("updateTokens");
  const [blockTime, currentBlock, currentTime] = useBlockTime();
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const blockchainState = useRedux((state) => state.blockchain)
  const metazoaState = tokenState.metazoaTokens;
  const mintedCount = tokenState.mintedTokensCount;

  const metazoaCount = Object.keys(metazoaState).length;
  const whitelistCount = tokenState.whitelistCount;
  const startBlock = blockchainState.transcendenceStartBlock;
  
  // tbm v1
  const tokens = Object.values(tokenState.tokens);

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function Countdown() {
    if (startBlock === 0) return null;

    const blocksLeft = startBlock - currentBlock
    if (blocksLeft <= 0) return null;

    const startTime = blockTime.add(blocksLeft / BLOCKS_PER_MINUTE, 'minute')
    const secondsLeft = startTime.diff(currentTime, 'second')

    if (blocksLeft > 0 || startBlock === 0) {
      return <Box mt={2}>
        <Typography variant="subtitle1" sx={styles.countdownText}>
          Transcendence Portal Opening In {
            Math.floor(secondsLeft / 3600).toLocaleString('en-US', { minimumIntegerDigits: 2 })}h : {
            (Math.floor(secondsLeft / 60) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}m : {
            (secondsLeft % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })
          }s ({blocksLeft} blocks)
        </Typography>
      </Box>
    } 
    return null;
  }

  function DisplayStats() {
    if (!wallet) {
      return <ConnectWalletButtonCTA/>
    }
    if (!startBlock || startBlock > currentBlock) {
      return null
    }

    if (!!wallet) {
      return(
        <Box sx={styles.statsContainer}>
          {isLoading ? (
            <Box>
              <CircularProgress size={18}/>
            </Box>
          ) : (
            <Box sx={styles.statsContent}>
              <Typography variant="h3" color="secondary">Your Stats</Typography>
              <Box width="100%">
                <Box sx={styles.statRow}>
                  <Typography variant="body1" color="primary">Bears You Own</Typography>
                  {loadingTokens
                    ? <CircularProgress size={16}/>
                    : <Typography variant="body1" color="primary" sx={styles.statNumber}>{tokens.length}</Typography>
                  }
                </Box>
                <Box sx={styles.statRow}>
                  <Typography variant="body1" color="primary" textAlign="initial">Bears Eligible for Transcendence</Typography>
                  {loadingTokens
                    ? <CircularProgress size={16}/>
                    : <Typography variant="body1" color="primary" sx={styles.statNumber}>{whitelistCount - mintedCount}</Typography>
                  }
                </Box>
                <Box sx={styles.statRow}>
                  <Typography variant="body1" color="primary">Metazoa Summoned</Typography>
                  {loadingTokens
                    ? <CircularProgress size={16}/>
                    : <Typography variant="body1" color="success.main" sx={styles.statNumber}>{metazoaCount}</Typography>
                  }
                </Box>
              </Box>
              <Button variant="contained" color="secondary" sx={styles.selectBearButton} onClick={handleOpen} disabled={true}>
                <BaseButtonBox>
                  <BaseButton height="100%"/>
                </BaseButtonBox>
                SELECT BEAR
              </Button>
            </Box>
          )}
        </Box>
      );
    } 
    return (
      <ConnectWalletButtonCTA/>
    )
  }

  return (
    <Box width="100%">
      <Countdown/>
      <DisplayStats/>
      <DialogModal
        open={open}
        onClose={handleClose}
        header={"Transcendence Portal"}
      >
        <Typography variant="body1" color="primary" component="span" sx={styles.dialogText}>
          Only the most worthy of Bears may embark on the path of Transcendence.<br/>
          <span>⚠️ </span><strong>CAUTION: Your OG Bear will be&nbsp;</strong>
          <Link
            sx={styles.learnMore}
            target="_blank"
            href="https://docs.zolar.io/overview/creatures/collection-ii-metazoa/summoning#phase-2-or-the-transcendence"
          >
            burnt forever
          </Link><strong>, so choose wisely.</strong>
        </Typography>
        <BearSelection/>
      </DialogModal>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  statsContainer: {
    position: "relative",
    borderRadius: "16px",
    marginY: '15px',
    padding: "32px 200px",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    width: '100%',
    minHeight: '200px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    "@media (max-width:1200px)": {
      paddingX: "100px",
    },
    "@media (max-width:900px)": {
      padding: "24px",
    },
  },
  statsContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
  statRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '4px',
  },
  selectBearButton: {
    width:'100%',
    marginTop: '30px',
    "&.Mui-disabled": {
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
  statNumber: {
    fontWeight: 600,
  },
  countdownText: {
    color: "#FF8952",
    marginBottom: '10px',
  },
  learnMore: {
    color: '#fff',
    fontWeight: 700,
  },
  dialogText: {
    textAlign: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    "@media (max-width:600px)": {
      lineHeight: '1.25rem',
    },
  }
}

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    height:"100%",
  },
});

export default BurnBear;
