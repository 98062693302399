import { OAuth, Profile } from "./types"

export const MetazoaProfileActionTypes = {
  LOAD_PROFILE: "METAZOA:LOAD_PROFILE",
  UPDATE_PROFILE: "METAZOA:UPDATE_PROFILE",
  UPDATE_ACCESS_TOKEN: "METAZOA:UPDATE_ACCESS_TOKEN",
  REFRESH_ACCESS_TOKEN: "METAZOA:REFRESH_ACCESS_TOKEN",
}

export function loadProfile() {
  return { type: MetazoaProfileActionTypes.LOAD_PROFILE }
}

export function updateProfile(payload: Profile | undefined) {
  return {
    type: MetazoaProfileActionTypes.UPDATE_PROFILE,
    payload  
  }
}

export function refreshAccessToken() {
  return { type: MetazoaProfileActionTypes.REFRESH_ACCESS_TOKEN }
}

export function updateAccessToken(payload: OAuth) {
  return {
    type: MetazoaProfileActionTypes.UPDATE_ACCESS_TOKEN,
    payload
  }
}