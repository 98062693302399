import { Box, Button, CircularProgress, Link, styled, SvgIcon, SxProps, Typography } from "@mui/material";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import { BaseButton, ExternalLink, Huny, InfoTooltip } from "assets";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import RenderBearCollection from "components/Minting/PhaseTwo/components/BearSelection/RenderBearCollection/RenderBearCollection";
import { waitForTx } from "core/utilities";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { actions } from "store";
import { NftMetadata } from "store/types";
import { TBMConnector, ZolarEventSubscriber } from "tbm";
import { useToaster } from "utils";
import { ContractsBech32 } from "utils/constants";
import { getExplorerLink } from "utils/strings/links";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";

export interface ModalProps {
  open: boolean;
  discount: number;
  tokens: NftMetadata[];
  limit: number;
  quantity: number;
  price: number;
  onClose: () => void;
}

const SummonDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, discount, tokens, quantity, price, onClose, limit } = props;
  const [showBurnBear, setShowBurnBear] = useState<boolean>(true);
  const [summonComplete, setSummonComplete] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);
  const [summonTxHash, setSummonTxHash] = useState<string>("");
  const [kidnappedIds, setKidnappedIds] = useState<string[]>([]);
  const [burnTokens, setBurnTokens] = useState<string[]>([]);
  const wallet = useRedux((state) => state.wallet.wallet);
  const toaster = useToaster();
  const dispatch = useDispatch();
  const network = useNetwork();
  const [runSummon, loadingSummon] = useAsyncTask("summon", (error) => {
    toaster(error?.message ?? "Error Summoning");
  });
  const [runQueryApproved, loadingQueryApproved] = useAsyncTask("queryApproved");
  const [runApprove, loadingApprove] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
  });

  useEffect(() => {
    if (wallet) {
      runQueryApproved(async () => {
        const approved = await TBMConnector.checkMoonBattleApprovedTbm(wallet);
        setApproved(approved);
      })
    }
    // eslint-disable-next-line
  }, [wallet, network]);

  const contractAddresses = ContractsBech32[network];
  const tbmAddress = fromBech32Address(contractAddresses.Nft);
  const moonBattleAddress = fromBech32Address(contractAddresses.MoonBattle);

  const handleSummon = () => {
    runSummon(async () => {
      await summon();
    })
  }

  const handleBurnAndSummon = () => {
    setShowBurnBear(false);
  }

  const handleToggleTokens = (
    event: React.MouseEvent<HTMLElement>,
    newTokens: string[],
  ) => {
    if (newTokens.length > limit) return;

    setBurnTokens(newTokens);
  }

  const summon = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const summonTx = await TBMConnector.summonReinforcements(wallet, quantity, burnTokens);

    if (summonTx.isRejected()) {
      throw new Error("Submitted transaction was rejected.");
    }

    if (summonTx?.id) {
      setSummonTxHash(summonTx.id);
      try {
        await waitForTx(summonTx.id);

        const tbmConnector = TBMConnector.getSDK();

        const txn = await tbmConnector.zilliqa.blockchain.getTransaction(summonTx.id);
        const receipt = txn.getReceipt();
        let actionId: string = "";

        receipt!.event_logs.forEach((event) => {
          if (event._eventname === "ActionStarted") {
            actionId = event.params.find((param) => param.vname === "action_id")?.value;
          }
        });

        if (!!actionId) {
          const events = await TBMConnector.waitForConcludeAction(actionId);
          // check events for MetazoaKidnapped events, if none, none are kidnapped.
          const kidnappedEvents = events.filter(event => event.type === ZolarEventSubscriber.ZolarEventType.MetazoaKidnapped)

          const kidnappedTokenIds = kidnappedEvents.map(event => event.params.token_id)
          setKidnappedIds(kidnappedTokenIds)
        }
        dispatch(actions.Blockchain.updateSaleState());
        dispatch(actions.Token.updateImage());
        setSummonComplete(true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const handleApprove = () => {
    runApprove(async () => {
      await approve();
    })
  }

  const approve = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const approveTx = await TBMConnector.approveAllowance(tbmAddress, moonBattleAddress);

    if (approveTx?.id) {
      try {
        await waitForTx(approveTx.id);
        setApproved(true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const handleOnClose = () => {
    if (loadingSummon) return;

    onClose();
    setSummonTxHash("");
    setKidnappedIds([]);
    setShowBurnBear(true);
    setBurnTokens([]);
    setSummonComplete(false);
  }

  const getHeader = () => {
    if (summonComplete) {
      return "Summon success!";
    } else if (loadingSummon) {
      return "Summoning in progress";
    } else if (limit !== 0 && tokens.length && showBurnBear) {
      return "Burn & Summon Metazoa";
    } else {
      return "Summon Metazoa";
    }
  }

  const getContent = () => {
    if (summonComplete) {
      return (
        <Fragment>

          {kidnappedIds.length > 0 && (
            <>
              <Typography variant="body1" color="primary" textAlign="center">
                Oh no! Your Metazoa got kidnapped!
              </Typography>
              <Typography sx={styles.warningText}>
                You’ve just summoned {quantity} Metazoa.
                <br />
                Unfortunately, {kidnappedIds.length} Metazoa got kidnapped by Minos.
              </Typography>
            </>
          )}

          {kidnappedIds.length === 0 && (
            <Typography variant="body1" color="primary" textAlign="center">
              You have now summoned your Metazoa.<br />
              Prepare yourself for the battle ahead.
            </Typography>
          )}

          <Box sx={styles.contentBox}>

            {/* Qty */}
            <Box display="flex" width="100%" justifyContent="center" alignItems="center" mt="40px">
              <Box component="img" src={DefaultUserProfile} width="77px" height="77px" mr="10px" sx={{ borderRadius: '100%' }} />
              <Typography color="primary" variant="h2">
                x
                {" "}
                {kidnappedIds.length > 0 && (
                  <>
                    <Typography component="span" color="primary" variant="h2" style={{ whiteSpace: 'nowrap', textDecoration: 'line-through' }}>{quantity}</Typography>
                    <Typography component="span" color="success" variant="h2" style={{ marginRight: '1rem', color: '#2FF583' }}>{quantity - kidnappedIds.length}</Typography>
                  </>
                )}

                {kidnappedIds.length === 0 && (
                  <>
                    <Typography component="span" color="primary" variant="h2" style={{ whiteSpace: 'nowrap' }}>{quantity}</Typography>
                  </>
                )}
              </Typography>
            </Box>


            {/* Tx hash */}
            <Link
              target="_blank"
              href={getExplorerLink("tx", summonTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>

            {/* Summon more */}
            <ContainedButton
              onClick={handleOnClose}
              sx={styles.summonMoreButton}
              style={{ marginTop: 0 }}
            >
              Summon More
            </ContainedButton>

            {/* View profile */}
            <Button
              component={RouterLink}
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={styles.profileButton}
              to="/metazoa"
            >
              <BaseButtonBox>
                <BaseButton height="100%" />
              </BaseButtonBox>
              View My Army
            </Button>
          </Box>
        </Fragment >
      )
    }

    if (loadingSummon) {
      return (
        <Fragment>
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Fragment>
      )
    }

    if (limit !== 0 && tokens.length && showBurnBear) {
      return (
        <Fragment>
          {/* Info */}
          <Box display="flex" alignItems="center" mb="20px">
            <InfoTooltip style={{ marginRight: '16px' }} />
            <Typography color="primary" variant="body1" textAlign="center">You have a 10% chance of summoning Minos.</Typography>
          </Box>

          <RenderBearCollection tokens={tokens} transcendTokens={burnTokens} handleToggleTokens={handleToggleTokens} />

          <Box sx={styles.infoBox}>
            {/* selected bears */}
            <Box display="flex" justifyContent="space-between">
              <Typography color="primary" variant="body1">
                Selected Bears
              </Typography>
              <Typography color="primary" variant="body1">
                {burnTokens.length}
                {" / "}
                {limit}
              </Typography>
            </Box>

            {/* total cost */}
            <Box display="flex" justifyContent="space-between" mt="10px">
              <Typography color="primary" variant="body1">
                Total Cost
              </Typography>
              <Typography color="success.main" variant="body1" sx={styles.cost}>
                {((quantity * price) - (burnTokens.length * discount * price)).toLocaleString()} HUNY
                {" "}
                <Huny />
              </Typography>
            </Box>
          </Box>

          {/* Summon button */}
          <ContainedButton
            sx={styles.burnButton}
            onClick={handleBurnAndSummon}
          >
            Burn & Summon
          </ContainedButton>
        </Fragment>
      )
    }

    return (
      <Fragment>
        {/* Info */}
        <Box display="flex" alignItems="center">
          <InfoTooltip style={{ marginRight: '16px' }} />
          <Typography color="primary" variant="body1" textAlign="center">You have a 10% chance of summoning Minos.</Typography>
        </Box>

        {/* Qty */}
        <Box display="flex" alignItems="center" mt="40px">
          <Box component="img" src={DefaultUserProfile} width="77px" height="77px" mr="10px" sx={{ borderRadius: '100%' }} />
          <Typography color="primary" variant="h2" style={{ whiteSpace: 'nowrap' }}>x {quantity}</Typography>
        </Box>

        {/* No. of og bears burnt */}
        <Box mt="40px">
          <Typography color="primary" variant="body1" sx={styles.cost}>
            No. of OG bears burnt: {(burnTokens.length).toLocaleString()}
          </Typography>
        </Box>

        {/* Total cost */}
        <Box mt="20px">
          <Typography color="primary" variant="body1" sx={styles.cost}>
            Total Cost: {((quantity * price) - (burnTokens.length * discount * price)).toLocaleString()} HUNY <Huny />
          </Typography>
        </Box>

        <Box display="flex" sx={styles.buttonBox}>
          {!approved &&
            <ContainedButton sx={styles.approveButton} onClick={handleApprove} disabled={loadingApprove || loadingQueryApproved}>
              {loadingApprove || loadingQueryApproved
                ? <CircularProgress size={24} />
                : <span style={{ lineHeight: '2rem' }}>Authorize Burn</span>
              }
            </ContainedButton>
          }

          {/* Summon button */}
          <ContainedButton
            disabled={loadingApprove || loadingQueryApproved}
            sx={styles.summonButton}
            onClick={handleSummon}
          >
            Summon
          </ContainedButton>
        </Box>
      </Fragment>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} hideCloseButton={loadingSummon} disableScrollLock={true} dialogOverwrites={{ placeItems: 'center' }}>
      {getContent()}
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  cost: {
    "& svg": {
      verticalAlign: "text-top",
      marginTop: "2px",
      height: 22,
      width: 22,
    },
    "@media (max-width:600px)": {
      "& svg": {
        marginTop: 0,
        height: "20px!important",
        width: "20px!important",
      }
    },
  },
  summonMoreButton: {
    marginTop: "40px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  profileButton: {
    marginTop: "10px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  viewTx: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  zoaTokensContainer: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "repeat(5, 1fr)",
    columnGap: "20px",
    rowGap: "20px",
    maxHeight: "170px",
    marginTop: "10px",
    paddingTop: "10px",
    overflowY: "auto",
    paddingRight: "10px",
    "@media (max-width:900px)": {
      gridTemplateColumns: "repeat(4, 1fr)",
      maxHeight: "150px",
    },
    "@media (max-width:600px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media (max-width:480px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    '::-webkit-scrollbar': {
      width: '10px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  zoaImage: {
    height: "100px",
    width: "100px",
    "@media (max-width:900px)": {
      height: "80px",
      width: "80px",
    },
  },
  zoaIdBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  zoaId: {
    marginTop: "6px",
    fontSize: "16px",
    "@media (max-width:900px)": {
      fontSize: "14px",
    },
  },
  infoBox: {
    width: "100%",
    marginTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  buttonBox: {
    marginTop: "20px",
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  approveButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    marginRight: '10px',
    width: 270,
    "@media (max-width:600px)": {
      marginRight: 0,
      marginBottom: '10px',
      width: '100%',
    }
  },
  summonButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    width: 270,
    "@media (max-width:600px)": {
      width: '100%',
    }
  },
};

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    height: "100%",
  },
});

export default SummonDialog;
