import { Box, BoxProps, Button, CircularProgress, SxProps } from "@mui/material";
import ContainedButton from "components/ContainedButton";
import { ConnectWalletResult, connectWalletZilPay } from "core/wallet";
import React from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { useToaster } from "utils";
import { LoadingKeys } from "utils/constants";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useRedux from "utils/useRedux";
import useTaskSubscriber from "utils/useTaskSubscriber";

export interface ConnectButtonProps extends BoxProps {

}

const ConnectWalletButtonCTA: React.FC<ConnectButtonProps> = (props: ConnectButtonProps) => {
  const { ...rest } = props;
  const wallet = useRedux((state) => state.wallet.wallet);
  const toaster = useToaster();
  const [runConnectTask] = useAsyncTask<void>(
    "connectWalletZilPay",
    (error) => {
      toaster("Error connecting to ZilPay");
    }
  );
  const [isLoading] = useTaskSubscriber(...LoadingKeys.connectWallet);
  const dispatch = useDispatch();

  const connectZilPay = (ignoreError: boolean) => {
    runConnectTask(async () => {
      if (!!wallet) return;

      const zilPay = (window as any).zilPay;
      if (typeof zilPay === "undefined") {
        if (!ignoreError) throw new Error("ZilPay extension not installed");
        return;
      }

      const result = await zilPay.wallet.connect();
      if (result !== zilPay.wallet.isConnect) {
        if (!ignoreError) throw new Error("ZilPay could not be connected to");
        return;
      }

      const walletResult: ConnectWalletResult = await connectWalletZilPay(
        zilPay
      );
      if (walletResult.error) throw walletResult.error;

      if (walletResult.wallet) {
        const { wallet } = walletResult;
        const { network } = wallet;
        dispatch(actions.Blockchain.initialize({ network, wallet }));
        dispatch(actions.Wallet.update({ wallet }));
        return;
      }
    });
  };

  const handleConnectWallet = (event?: React.MouseEvent<HTMLElement>) => {
    connectZilPay(!event);
  };

  return (
    <Box {...rest}>
      {isLoading ? (
        <Box sx={styles.buttonContainer}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            sx={styles.buttonCTA}
            onClick={handleConnectWallet}
            disableFocusRipple
          >
            <CircularProgress size={18}/>
          </Button>
        </Box>
      ) : (
        !wallet ? (
          <Box>
            <Box sx={styles.buttonContainer}>
              <ContainedButton
                size="small"
                sx={styles.buttonCTA}
                onClick={handleConnectWallet}
                disableFocusRipple
              >
                connect wallet
              </ContainedButton>
            </Box>
          </Box>
        ) : (
          null
        )
      )}
    </Box>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  buttonContainer: {
    paddingY: "10px",
  },
  buttonCTA: {
    textTransform: "uppercase",
    minWidth: "210px",
    minHeight: "68px",
    borderRadius: "16px",
  },
};

export default ConnectWalletButtonCTA;
