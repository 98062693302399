import { Box, Button, ButtonProps, styled, SxProps } from "@mui/material";
import BaseButton from "assets/Base.svg";
import { NavLink } from "react-router-dom";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";

interface IContainedButtonProps extends ButtonProps {
  isExternal?: boolean;
  isRoute?: boolean;
}

const ContainedButton: React.FC<IContainedButtonProps> = ({
  isExternal = false,
  isRoute = false,
  ...props
}: IContainedButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button
      variant="contained"
      {...rest}
      color="secondary" sx={combineStyles(styles.buttonWrapper, rest.sx)}
      {...(isExternal) && { target: "_blank" }}
      {...(isRoute) && {
        to: rest.href,
        component: NavLink,
      }}
    >
      <BaseButtonBox>
        <img src={BaseButton} alt="base-button" height="100%" />
      </BaseButtonBox>
      {children}
    </Button>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {

  buttonWrapper: {
    // "@media (min-width:600px)": {
    //   marginLeft: '10px',
    // },
    overflow: 'hidden',
  },

  button: {
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
}

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  // top: "2%",
  zIndex: "1",
  height: '100%',
  "@media (max-width:600px)": {
    display: "none",
  },
});

export default ContainedButton;