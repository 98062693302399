import { Box, Button, CircularProgress, Link, SxProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { Huny, ZilTokenIcon } from "assets";
import GuildMemberFrame from "assets/GuildMemberFrame.svg";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DEFAULT_SRC, GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { logger, waitForTx } from "core/utilities";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { Guild, GuildBankInfo, PendingRequest, Profile } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { BIG_ZERO, Decimals } from "utils/constants";
import { bnOrZero, formatIntegerNumber, getExplorerLink, toHumanNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import Tag from "./Tag";

export interface GuildProps {
  guild: Guild;
  bank: GuildBankInfo;
}

export interface WithdrawTxParams {
  recipient: string;
  token: string;
  amount: BigNumber;
}

export interface UpdateConfigTxParams {
  joining_fee: BigNumber;
  weekly_tax: BigNumber;
  multisigMode: string;
}

export interface HiveTxParams {
  zilAmount: BigNumber;
  hunyAmount: BigNumber;
}

type TypeParams = UpdateConfigTxParams | WithdrawTxParams | HiveTxParams;

const WithdrawalRequest: React.FC<GuildProps> = (props: GuildProps) => {
  const { guild, bank } = props;

  const toaster = useToaster();
  const network = useNetwork();
  const dispatch = useDispatch();
  const wallet = useRedux((state) => state.wallet.wallet)

  // STATES / HOOKS -----------------

  const [txInitiatorProfile, setTxInitiatorProfile] = useState<Profile>({} as Profile);
  const [txPending, setTxPending] = useState<PendingRequest>({} as PendingRequest);
  const [txParams, setTxParams] = useState<TypeParams>({} as TypeParams);
  const [signMultisigTx, loadingSignMultisigTx] = useAsyncTask('signMultisigTx', (error) => {
    toaster(error?.message ?? "Error Signing MultisigTx");
  });
  const [cancelMultisigTx, loadingCancelMultisigTx] = useAsyncTask('cancelMultisigTx', (error) => {
    toaster(error?.message ?? "Error Cancelling MultisigTx");
  });
  const [runQueryBankPrice, loadingQueryBankPrice] = useAsyncTask("fetchUpdateBankPrice");
  const [canAffordUpdate, setCanAffordUpdate] = useState<boolean>(false);
  const hunyBalance = bnOrZero(bank.tokens?.hunyToken ?? BIG_ZERO).shiftedBy(-Decimals.HUNY);

  useEffect(() => {
    if (!bank) return;
    const abortController = new AbortController();
    runQueryBankPrice(async () => {
      const serviceFee: BigNumber = await TBMConnector.fetchUpdateBankPrice();
      if (hunyBalance.isGreaterThanOrEqualTo(bnOrZero(serviceFee).shiftedBy(-Decimals.HUNY))) setCanAffordUpdate(true);
      return;
    })
    return abortController.abort();
    // eslint-disable-next-line
  }, [bank])

  // Get current wallet holder's guild membership status
  const walletHolder = useMemo(() => {
    if (!guild || !wallet) return;
    const addr = wallet.addressInfo.byte20!.toLocaleLowerCase()!;
    // const isOfficer: boolean = guild.commanderAddresses.includes(addr);
    const isLeader: boolean = guild.leaderAddress === addr;

    const hasSigned: boolean = (bank.pendingRequest?.validSigners.includes(addr)) ?? false;

    return { addr, isLeader, hasSigned }
  }, [wallet, guild, bank])

  // Get current bank from store 
  const pendingRequest = useMemo(() => {
    if (!bank || !guild) return;

    const { members } = guild;
    const { pendingRequest } = bank;
    if (!members || !pendingRequest) return;

    logger("debug-bank", "component-result", pendingRequest, guild)

    const { type, initiator, params } = pendingRequest;
    setTxPending(pendingRequest);
    const txInitiator = members.find(member => member.address === initiator);
    if (!!txInitiator) setTxInitiatorProfile(txInitiator);

    switch (type) {
      case "WithdrawTxParams": {
        setCanAffordUpdate(true);
        setTxParams({
          recipient: toBech32Address(params[0]),
          token: params[1],
          amount: bnOrZero(params[2]),
        } as WithdrawTxParams);
        break;
      }
      case "UpdateConfigTxParams": {
        const joiningFee = params[0].arguments?.[0].arguments?.[0];
        const weeklyTax = params[0].arguments?.[1].arguments?.[0];
        const multisigModeMatch = params[0].arguments?.[2].constructor.match(/^0x[a-z0-9]{40}.([a-z0-9]*)$/i);
        const multisigMode = multisigModeMatch[1];
        setTxParams({
          joining_fee: new BigNumber(joiningFee),
          weekly_tax: new BigNumber(weeklyTax),
          multisigMode: multisigMode,
        } as UpdateConfigTxParams);
        break;
      }
      case "DepositHiveTxParams": case "WithdrawHiveTxParams": {
        setCanAffordUpdate(true);
        const hunyAmt = new BigNumber(params[2]);
        const zilAmt = new BigNumber(params[1]);
        setTxParams({
          zilAmount: zilAmt,
          hunyAmount: hunyAmt,
        } as HiveTxParams);
        break;
      }
    }

    return {
      ...pendingRequest,
      txInitiator,
    }
    // eslint-disable-next-line
  }, [bank])

  // FUNCTIONS ----------------------

  const signTx = async () => {
    if (!wallet || !bank) return;
    const resultTx = await TBMConnector.signMultisigTx(bank.address);

    toaster(`Submitted Sign MultisigTx `, { hash: resultTx.id! })
    if (resultTx?.id) {
      try {
        await waitForTx(resultTx.id);
        toaster(`Successful Sign MultisigTx `, { hash: resultTx.id! })
        // dispatch(actions.Guild.reloadGuild(guild));
        dispatch(actions.Guild.reloadBank(guild));
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const cancelTx = async () => {
    if (!wallet || !bank) return;
    const resultTx = await TBMConnector.cancelMultisigTx(wallet, bank.address);

    toaster(`Submitted Cancel MultisigTx `, { hash: resultTx.id! })
    if (resultTx?.id) {
      try {
        await waitForTx(resultTx.id);
        toaster(`Successful Cancel MultisigTx `, { hash: resultTx.id! })
        setTxInitiatorProfile({} as Profile)
        setTxParams({} as TypeParams)
        setTxPending({} as PendingRequest)
        dispatch(actions.Guild.reloadBank(guild));
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  // EVENT HANDLERS -----------------

  const handleSignMultisig = () => {
    signMultisigTx(async () => {
      await signTx();
    })
  }

  const handleCancelMultisig = () => {
    cancelMultisigTx(async () => {
      cancelTx();
    })
  }

  // --------------------------------
  if ((!walletHolder || !bank || !pendingRequest) || (!txPending || !txParams || !txInitiatorProfile)) return null;

  const getContent = () => {
    let params = txParams;

    switch (txPending.type) {
      case "WithdrawTxParams": {
        params = (txParams as WithdrawTxParams);
        return (
          <Fragment>
            <Typography component="span" color="primary">
              {(txInitiatorProfile.address.toLowerCase() === guild.leaderAddress.toLowerCase()) ? "Captain" : "Officer"} <b>{txInitiatorProfile.username ?? truncate(txInitiatorProfile.address)!}</b> initiated a withdrawal to&nbsp;
              <Link href={getExplorerLink("address", params.recipient, network)} target="_blank" sx={styles.requestorAddressLink}>
                <Typography color="primary" sx={styles.requestorAddress}>{truncate(params.recipient)}</Typography>
              </Link>.
              {/* <Typography component="span" sx={styles.requestDateText}>{dayjs().format("MMMM D, YYYY H:mm:ss")}</Typography> */}
            </Typography>
            <Box display="flex" sx={styles.requestDetails}>
              <Tag label="Pending Approval" background="#FF8952" />
              <Typography color="primary" component="span" sx={styles.withdrawalAmt}>
                {formatIntegerNumber(bnOrZero(params.amount).shiftedBy(-Decimals.HUNY))}
                {params.token === "0x0000000000000000000000000000000000000000"
                  ? <ZilTokenIcon width="24px" height="24px" />
                  : <Huny width="24px" height="24px" />
                }
              </Typography>
            </Box>
            <Typography sx={styles.purposeText} color="primary">
              <b>Purpose:</b> {txPending.msg ?? "none"}
            </Typography>
          </Fragment>
        )
      }
      case "UpdateConfigTxParams": {
        params = (txParams as UpdateConfigTxParams);
        return (
          <Fragment>
            <Typography component="span" color="primary">
              {(txInitiatorProfile.address.toLowerCase() === guild.leaderAddress.toLowerCase()) ? "Captain" : "Officer"} <b>{txInitiatorProfile.username ?? truncate(txInitiatorProfile.address)!}</b> initiated a change to <b>Guild Settings</b>.&nbsp;
              {/* <Typography component="span" sx={styles.requestDateText}>{dayjs().format("MMMM D, YYYY H:mm:ss")}</Typography> */}
            </Typography>
            <Box display="flex" sx={styles.requestDetails}>
              <Tag label="Pending Approval" background="#FF8952" />
              <Typography color="primary" component="span" sx={styles.withdrawalAmt}>
                Joining Fee:&nbsp;
                {formatIntegerNumber(bnOrZero(params.joining_fee).shiftedBy(-Decimals.HUNY))}
                <Huny width="24px" height="24px" />
              </Typography>
              <Typography color="primary" component="span" sx={styles.withdrawalAmt}>
                Weekly Tax:&nbsp;
                {formatIntegerNumber(bnOrZero(params.weekly_tax).shiftedBy(-Decimals.HUNY))}
                <Huny width="24px" height="24px" />
              </Typography>
            </Box>
            <Typography sx={styles.purposeText} color="primary">
              <b>Multisig Control:</b> {params.multisigMode.split(/(?=[A-Z])/).join(" ")}
            </Typography>
            <Typography sx={styles.purposeText} color="primary">
              <b>Purpose:</b> {txPending.msg ?? "none"}
            </Typography>
          </Fragment>
        )
      }
      case "DepositHiveTxParams": case "WithdrawHiveTxParams": {
        const isDepositHiveTx = txPending.type === "DepositHiveTxParams";
        params = (txParams as HiveTxParams);
        logger("debug-component/MultisigDialog", "params", {
          params,
          zilAmt: toHumanNumber(params.zilAmount.shiftedBy(-Decimals.ZIL)),
          hunAmt: toHumanNumber(params.hunyAmount.shiftedBy(-Decimals.HUNY)),
          zil: formatIntegerNumber(bnOrZero(params.zilAmount).shiftedBy(-Decimals.ZIL)),
          huny: formatIntegerNumber(bnOrZero(params.hunyAmount).shiftedBy(-Decimals.HUNY)),
        })
        return (
          <Fragment>
            <Typography component="span" color="primary">
              {(txInitiatorProfile.address.toLowerCase() === guild.leaderAddress.toLowerCase()) ? "Captain" : "Officer"} <b>{txInitiatorProfile.username ?? truncate(txInitiatorProfile.address)!}</b> initiated <Typography component="span" color="success.main">{isDepositHiveTx ? 'Add' : 'Remove'} Liquidity</Typography> to Magic Hive.&nbsp;
              {/* <Typography component="span" sx={styles.requestDateText}>{dayjs().format("MMMM D, YYYY H:mm:ss")}</Typography> */}
            </Typography>
            <Box display="flex" sx={styles.requestDetails}>
              <Tag label="Pending Approval" background="#FF8952" />

              <Typography color="primary" component="span" sx={styles.withdrawalAmt}>
                {formatIntegerNumber(bnOrZero(params.zilAmount).shiftedBy(-Decimals.ZIL))}
                <ZilTokenIcon width="24px" height="24px" />
              </Typography>
              <Typography color="primary" component="span" sx={styles.withdrawalAmt}>
                {formatIntegerNumber(bnOrZero(params.hunyAmount).shiftedBy(-Decimals.HUNY))}
                <Huny width="24px" height="24px" />
              </Typography>
            </Box>
          </Fragment>
        )
      }
    }

    logger("debug-bank", "content request", {
      walletHolder,
      txPending,
      txParams,
      txInitiatorProfile,
      status: (!txPending || !txParams || !txInitiatorProfile)
    })
  }


  return (
    <Box sx={styles.withdrawalRequest}>
      <Box sx={combineStyles(styles.withdrawRequestImgFrame)}>
        <Box component="img" src={txInitiatorProfile.avatarUrl ?? DefaultUserProfile} sx={styles.withdrawRequestImg} onError={DEFAULT_SRC} />
      </Box>
      <Box sx={styles.content}>
        {getContent()}
      </Box>
      <Box sx={styles.buttonGroup}>
        <ContainedButton
          variant="contained"
          sx={styles.button}
          onClick={handleSignMultisig}
          disabled={walletHolder.hasSigned || !canAffordUpdate}
          disableFocusRipple
        >
          {
            loadingSignMultisigTx || loadingQueryBankPrice
              ? <CircularProgress size={16} />
              : <>{walletHolder.hasSigned ? "Approved" : "Approve"}</>
          }
        </ContainedButton>
        {
          walletHolder.isLeader && <Button
            variant="outlined"
            color="secondary"
            sx={styles.gradientButton}
            onClick={handleCancelMultisig}
          >
            {
              loadingCancelMultisigTx
                ? <CircularProgress size={16} />
                : "Cancel"
            }
          </Button>
        }
      </Box>
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  content: {
    marginRight: "30px",
    flex: 1
  },
  withdrawalRequest: {
    borderRadius: '16px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    padding: '27px 40px',
    display: 'flex',
    placeItems: 'center'
  },
  button: {
    fontSize: '18px',
    height: 48,
    width: '216px',
    padding: '14px 30px',
    fontWeight: 700,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
    "& div:first-of-type": {
      height: "inherit",
      // left: '-18px'
    }
  },
  gradientButton: {
    fontSize: '18px',
    height: 48,
    marginTop: '16px',
    width: '216px',
    padding: '14px 30px',
    fontWeight: 700,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column'
  },
  withdrawRequestImgFrame: {
    position: 'relative',
    backgroundImage: `url(${GuildMemberFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "160% 140%",
    backgroundPosition: "center",
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    width: '100%',
    height: '150px',
    maxWidth: '150px',
    margin: '10px 26px 10px 0px',
  },
  withdrawRequestImg: {
    height: '90%',
    width: '86%',
    borderRadius: "16px",
    objectFit: "contain",
    boxShadow: "0px 0px 12px 0px #00F0FF",
  },
  requestDateText: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 400,
    ...GUILD_GRAY_GRADIENT,
    marginLeft: '8px',
    background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256) 100%)'
  },
  withdrawalAmt: {
    display: 'flex',
    placeItems: 'center',
    gap: '10px',

    "&:not(:last-of-type)": {
      borderRight: '1px solid rgba(243, 255, 254, 0.2)',
      paddingRight: '1em',
    },
  },
  requestorAddress: {
    fontWeight: 700,
    textDecoration: 'underline'
  },
  requestorAddressLink: {
    display: 'inline-block',
    textDecoration: 'none'
  },
  requestDetails: {
    placeItems: 'center',
    gap: '16px',
    fontSize: '18px',
    my: '11px'
  },
  purposeText: { fontSize: '18px' }
}

export default WithdrawalRequest;