import { Box, styled } from "@mui/material";
import Texture from "assets/Texture.png";
import BetaWarning from "components/BetaWarning";
import { SnackbarProvider } from "notistack";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import useRedux from "utils/useRedux";
import { BlueLine, YellowShootingStar } from "../assets";
import BluePlanet from "../assets/BluePlanet.png";
import { Footer, Header } from "./components";
import NavDrawer from "./components/NavDrawer";
import SnackbarContentItem from "./components/SnackbarContentItem";

const Main = styled("main")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  background: "linear-gradient(180deg, #010025 0%, #000000 100%)",
  display: "flex",
  flexDirection: "column",
  overflowY: "hidden",
  overflowX: "hidden",
  position: "relative",
  maxWidth: "100%",
}));

const SvgTextureBox = styled(Box)({
  position: "absolute",
  top: "0%",
  left: "0%",
  height: "100%",
  width: "100%",
  backgroundImage: `url(${Texture})`,
  backgroundRepeat: 'repeat',
  opacity: "60%",
  pointerEvents: 'none',
});

const ExploreLayout: React.FC = (props: any) => {
  const { children, ...rest } = props;
  const dispatch = useDispatch();
  const showNavDrawer = useRedux((state) => state.layout.showNavDrawer);
  const ref = useRef() as React.MutableRefObject<SnackbarProvider>;
  const onCloseDrawer = () => {
    dispatch(actions.Layout.toggleShowNavDrawer("close"));
  };
  return (
    <Main {...rest}>
      <SvgTextureBox />
      <SvgBox>
        <BlueBox>
          <BlueLine />
        </BlueBox>
        <YellowShootingBox>
          <YellowShootingStar />
        </YellowShootingBox>
        <BluePlanetBox>
          <img src={BluePlanet} alt="blue-planet" />
        </BluePlanetBox>

      </SvgBox>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        ref={ref}
        content={(key, message) => {
          if (!message) return
          let msgContent = JSON.parse(message!.toString());
          const { hash, content } = msgContent;
          return (<SnackbarContentItem snackKey={key} message={content} hash={hash} providerRef={ref} />)
        }}
        maxSnack={3}
      >
        <Header />
        <BetaWarning />
        {children}
        <Footer />
        <NavDrawer open={showNavDrawer} onClose={onCloseDrawer} />
      </SnackbarProvider>
    </Main>
  );
};
const SvgBox = styled(Box)({
  pointerEvents: 'none',
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
});

const BlueBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  left: '-60px',
  top: '1600px',
  width: '80px',
  height: '80px',
  "@media (max-width:600px)": {
    left: '-60px',
    top: '1600px',
  },
});

const BluePlanetBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "780px",
  right: "200px",
  "@media (max-width:1000px)": {
    display: "none",
  },
});

const YellowShootingBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "1000px",
  right: "10px",

  "@media (max-width:986px)": {
    display: "none",
  },
});

export default ExploreLayout;
