import { Store } from "./types"

export const StoreActionTypes = {
  LOAD_STORE: "ZOMG:LOAD_STORE",
  UPDATE_STORE: "ZOMG:UPDATE_STORE",
}

export function loadStore() {
  return { type: StoreActionTypes.LOAD_STORE }
}

export function updateStore(payload: Store) {
  return {
    type: StoreActionTypes.UPDATE_STORE,
    payload
  }
}
