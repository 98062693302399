import { BIG_ZERO } from "utils/constants";
import { QuestActionType } from "./actions";
import { QuestState } from "./types";

const initial_state: QuestState = {
  quests: {},
  refinementFees: {
    crackGeodesFee: BIG_ZERO,
    elderberryJuice: {
      id: 0,
      refinementFee: BIG_ZERO,
    }
  },
  harvestZoas: []
}

const reducer = (state: QuestState = initial_state, action: any): QuestState => {
  const { payload, type } = action;
  switch (type) {
    case QuestActionType.SET_HARVEST_ZOAS:
      return {
        ...state,
        harvestZoas: [
          ...payload,
        ]
      }
    case QuestActionType.SET_QUEST_STATE:
      return {
        ...state,
        quests: {
          ...payload,
        }
      }
    case QuestActionType.SET_REFINEMENT_FEES:
      return {
        ...state,
        refinementFees: {
          ...payload,
        }
      }
    default:
      return state;
  }
}

export default reducer;