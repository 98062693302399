import { useMemo } from "react";
import { Box, styled, SxProps, Typography } from "@mui/material";
import { BlueLightningBG, MinoIcon, MinoShip, OrangeLightningBG, UrsaIcon, UrsaShip } from "assets";
import MinosVsUrsasBG from "assets/UrsasVsMinos.png";
import BigNumber from "bignumber.js";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";


const Header: React.FC = () => {
  const commanders = useRedux(state => state.game.commanders);
  const tokenTraits = useRedux(state => state.game.tokenTraits);

  const {
    ursaCount,
    minoCount,
    totalCount,
  } = useMemo(() => {
    const counts = {
      ursa: 0, mino: 0,
    };
    if (!tokenTraits || !commanders) return {};

    const stakedTokens = Object.values(commanders).flat();

    for (const [tokenId, traits] of Object.entries(tokenTraits)) {
      if (!stakedTokens.includes(tokenId)) continue;
      if (traits?.faction_a === "mino")
        counts.mino++;
      else
        counts.ursa++;
    }

    return {
      totalCount: new BigNumber(counts.ursa + counts.mino),
      ursaCount: new BigNumber(counts.ursa),
      minoCount: new BigNumber(counts.mino),
    }
  }, [tokenTraits, commanders]);

  return (
    <Box sx={styles.headerContainer}>
      <UrsaShipSvg />
      <MinoShipSvg />
      <SvgBox>
        <BlueLightningBox>
          <BlueLightningBG width="100%" height="100%" />
        </BlueLightningBox>
        <OrangeLightningBox>
          <OrangeLightningBG width="100%" height="100%" />
        </OrangeLightningBox>
      </SvgBox>
      <Box sx={styles.headerWrapper}>
        <Box sx={styles.headerBox}>
          <Typography variant="h3" color="primary" sx={styles.header}>{totalCount?.toFormat(0) ?? "-"} METAZOA IN BATTLE</Typography>
          <Box sx={styles.headerRow}>
            <Box sx={styles.rowGroup}>
              <UrsaIcon />
              <Box sx={styles.textGroup}>
                <Typography variant="h1" color="secondary">{ursaCount?.toFormat(0) ?? "-"}</Typography>
                <Typography variant="h3" color="secondary">Ursa</Typography>
              </Box>
            </Box>
            <Typography variant="h1" color="primary" sx={styles.headerVS}>VS.</Typography>
            <Box sx={styles.rowGroup}>
              <MinoIcon />
              <Box sx={styles.textGroup}>
                <Typography variant="h1" color="secondary.light">{minoCount?.toFormat(0) ?? "-"}</Typography>
                <Typography variant="h3" color="secondary.light">Mino</Typography>
              </Box>
            </Box>
          </Box>
          <HeaderBgBox>
            <img src={MinosVsUrsasBG} alt="Minos-vs-Ursas" width="100%" height="100%" />
          </HeaderBgBox>
        </Box>
      </Box>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  headerContainer: {
    position: 'relative',
    minWidth: '720px',
    padding: '0px',
  },
  headerWrapper: {
    zIndex: 1,
    width: '100%',
    paddingTop: '150px',
  },
  headerBox: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    height: '230px',
    background: 'linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, rgba(0, 0, 16) 100%)',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #AEF1EE33',
    "@media (max-width:1200px)": {
      height: '180px',
    },
  },
  header: {
    marginBottom: '10px',
    zIndex: 1,
    "@media (max-width:1200px)": {
      marginBottom: '0px',
    },
  },
  headerRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '100px',
  },
  rowGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerVS: {
    zIndex: 1,
  },
  textGroup: {
    marginLeft: '20px',
  }
};

const SvgBox = styled(Box)({
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
  pointerEvents: 'none',
});

const BlueLightningBox = styled(Box)({
  width: '700px',
  height: '300px',
  pointerEvents: 'none',
  position: 'absolute',
  top: '10px',
  left: -60,
  "@media (max-width:1200px)": {
    width: '600px',
    height: '250px',
  },
});
const OrangeLightningBox = styled(Box)({
  width: '800px',
  height: '400px',
  pointerEvents: 'none',
  position: 'absolute',
  top: '10px',
  right: -60,
  "@media (max-width:1200px)": {
    width: '700px',
    height: '350px',
  },
});

const HeaderBgBox = styled(Box)({
  width: '100%',
  height: '100%',
  padding: '20px',
  pointerEvents: 'none',
  position: 'absolute',
  top: '0px',
});

const MinoShipSvg = styled(MinoShip)({
  position: "absolute",
  right: 0,
  zIndex: 3,
  animation: `floating1 5s infinite`,

  "@media (max-width:995px)": {
    width: '300px',
  },
});

const UrsaShipSvg = styled(UrsaShip)({
  position: "absolute",
  left: 0,
  marginTop: "-40px",
  zIndex: 3,
  animation: `floating2 5s infinite`,

  "@media (max-width:995px)": {
    width: '300px',
  },
});


export default Header;
