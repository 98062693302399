import { Box, Button, CircularProgress, IconButton, Link, styled, SvgIcon, SxProps, TextField, Tooltip, tooltipClasses, TooltipProps, Typography, Zoom } from "@mui/material";
import { AddIcon, ExternalLink, Huny, RemoveIcon } from "assets";
import StoreItemFrame from "assets/quests/Locations/ZOMGStore/StoreItemFrame.svg";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { QuestConstants } from "components/Quest";
import { logger, waitForTx } from "core/utilities";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { ZOMGResourceItem } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useTaskSubscriber, useToaster } from "utils";
import { BIG_ZERO, Decimals, ITEM_ID } from "utils/constants";
import { bnOrZero, getExplorerLink, toHumanNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";

export interface ModalProps {
  resource: ZOMGResourceItem;
  open: boolean;
  onClose: () => void;
}

const initialFormState = {
  resources: BIG_ZERO,
  currentCost: BIG_ZERO,
}

const BuyResourceDialog: FC<ModalProps> = ({
  open,
  onClose,
  resource
}: ModalProps) => {

  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();

  const wallet = useRedux((state) => state.wallet.wallet);
  const hunyTokens = useRedux((state) => state.token.HunyTokens);
  const [loadingTokens] = useTaskSubscriber("updateTokens");

  // STATES / HOOKS -----------------

  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [priceChanged, setPriceChanged] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);
  const [purchaseTxHash, setPurchaseTxHash] = useState<string>("");
  const [buyRequestCompleted, setBuyRequestComplete] = useState<boolean>(false);
  const [runQueryApproved, loadingQueryApproved] = useAsyncTask("queryApproved");
  const [runApprove, loadingApprove] = useAsyncTask("requestApproval", (error) => {
    onClose();
    toaster(error?.message ?? "Error Approving");
  });
  const [runBuyAction, loadingBuyAction] = useAsyncTask("requestBuy", (error) => {
    onClose();
    toaster(error?.message ?? `Error Buying ZOMG Resource`);
  });

  const [loadingFetchResources] = useTaskSubscriber('refetchResources');

  const isLoading: boolean = loadingTokens || loadingQueryApproved || loadingBuyAction || loadingFetchResources;

  const affordability = useMemo(() => {
    const {
      // currentCost,
      resources
    } = formState;

    // PRICE (* base price, start price, max price, inflation bps, deflation bps *)
    // const basePrice = resource.buy[0] as BigNumber; // base price
    const startPrice = resource.buy[1] as BigNumber;
    const maxBuyPrice = resource.buy[2] as BigNumber;
    const inflationBps = resource.buy[3] as BigNumber;
    // const deflation = resource.buy[4];
    const currentInflationBps = bnOrZero(resource.transactCount.netBuySideInflation);
    const quantity = resources;
    let currentPrice = startPrice;
    let totalCost = BIG_ZERO;
    // Current inflated price 
    if (!!currentInflationBps) {
      currentPrice = bnOrZero(currentPrice).times(currentInflationBps.shiftedBy(-4).plus(1));
    }

    // Total Inflated Price
    const avgBps = bnOrZero(currentInflationBps).plus((bnOrZero(inflationBps).times(quantity.minus(1)).dividedBy(2)));
    totalCost = startPrice.times(avgBps.shiftedBy(-4).plus(1)).times(quantity);
    if (currentPrice.gt(maxBuyPrice)) {
      totalCost = maxBuyPrice.times(quantity);
    }

    // Calculate quantity allowed before inflated to max price
    // calculate max inflation: (max - start) / start * 100%
    const maxInflation = maxBuyPrice.minus(startPrice).dividedBy(startPrice).shiftedBy(4);
    // calculate inflation allowed before cap is hit
    const maxInflationAllowed = maxInflation.minus(currentInflationBps);
    // calculate possible inflation incurred from current purchase quantity
    const inflationToAdd = quantity.times(inflationBps);
    // check if inflationToAdd > maxInflationAllowed
    if (inflationToAdd.gt(maxInflationAllowed)) {
      const quantityBeforeMax = maxInflationAllowed.dividedBy(inflationBps);
      const exceedQuantity = quantity.minus(quantityBeforeMax);
      const avgBps = bnOrZero(currentInflationBps).plus((bnOrZero(inflationBps).times(quantityBeforeMax.minus(1)).dividedBy(2))).shiftedBy(-4).plus(1);
      const totalWithinMaxCost = startPrice.times(avgBps).times(quantityBeforeMax);
      const totalExceedMaxCost = maxBuyPrice.times(exceedQuantity);
      totalCost = totalWithinMaxCost.plus(totalExceedMaxCost);
    }
    let canSubmit: boolean = true;

    if (!wallet || !approved || resources.isLessThanOrEqualTo(0) || isLoading) canSubmit = false;
    if (bnOrZero(totalCost).shiftedBy(-Decimals.HUNY).gt(hunyTokens)) canSubmit = false;

    return {
      totalCost,
      canSubmit
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approved, formState, hunyTokens, isLoading, resource, wallet]);

  useEffect(() => {
    if (!wallet || !network) return;

    runQueryApproved(async () => {
      const approved = await TBMConnector.checkEmporiumApproved(wallet);
      setApproved(approved);
    })

    // eslint-disable-next-line
  }, [wallet, network]);

  // FUNCTIONS ----------------------

  const requestApproval = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const approveTx = await TBMConnector.increaseEmporiumAllowance(wallet, new BigNumber(2).pow(128).minus(1).dp(0));
    toaster(`Submitted Increase Allowance`, { hash: approveTx.id! });
    if (approveTx.isRejected() || !approveTx.id) throw new Error("Submitted transaction was rejected");
    await waitForTx(approveTx.id);
    setApproved(true);
  }

  const requestBuy = async () => {
    if (!affordability.canSubmit) return;
    if (!wallet || !network) throw new Error("Wallet not connected")

    const maxPrice = bnOrZero(affordability.totalCost).times(1.05).dp(0); // pay 5% extra for slippage

    const params = formState.resources.toString();
    const purchaseTx = await TBMConnector.purchaseItem(wallet, ITEM_ID[network][resource.asset.name.replace(/\s/g, '')], maxPrice, params);
    toaster(`Submitted Buy Resource`, { hash: purchaseTx.id! });
    if (purchaseTx.isRejected() || !purchaseTx.id) throw new Error("Submitted transaction was rejected");

    const tx = await waitForTx(purchaseTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(purchaseTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    setPurchaseTxHash(purchaseTx.id);
    setBuyRequestComplete(true);
    toaster(`Resource Bought `, { hash: purchaseTx.id!, overridePersist: true });
    dispatch(actions.Token.refetchResource());
    dispatch(actions.ZOMG.loadStore());
  }

  const revertToInitialState = () => {
    setFormState(initialFormState);
    setPurchaseTxHash('');
    setBuyRequestComplete(false);
    // setApproved(false);
    setPriceChanged(false);
  }

  // EVENT HANDLERS -----------------

  const handleOnClose = () => {
    if (isLoading) return;
    revertToInitialState();
    onClose();
  }

  const onResourceInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resourceInputHandler(event.target.value);
  };

  const handleAddQtyResources = () => {
    resourceInputHandler(formState.resources.plus(1).toString(10));
  };

  const handleSubtractQtyResources = () => {
    if (formState.resources.isLessThanOrEqualTo(0))
      return;
    resourceInputHandler(formState.resources.minus(1).toString(10));
  };

  const resourceInputHandler = (resourcesInput: string) => {
    const bnresources = bnOrZero(resourcesInput);

    setFormState({
      ...formState,
      resources: bnresources,
    })
  };

  const handleApprove = () => {
    runApprove(async () => {
      await requestApproval();
    });
  }

  const handleRequestBuy = () => {
    logger("debug-req", {
      key: resource.asset.key,
      misKey: ITEM_ID[network][resource.asset.name.replace(/\s/g, '')],
    })
    runBuyAction(async () => {
      await requestBuy();
    });
  }

  // --------------------------------

  const getHeader = () => {
    if (priceChanged && formState.resources.gte(BIG_ZERO)) {
      return `Item Price Changed`;
    }
    else if (buyRequestCompleted) {
      return `${resource.asset.name} Received!`
    } else return `Buy ${resource.asset.name}`
  }

  const getContent = () => {
    if (priceChanged && formState.resources.gte(BIG_ZERO)) {
      return (
        <Box sx={styles.contentBox}>
          <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, { width: '80%', textAlign: 'center' })}>
            Woah - the item you’re buying has shifted in price!
          </Typography>
          <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, { width: '80%', textAlign: 'center' })}>
            You’ll now pay&nbsp;<span style={{
              color: '#FF8952',
              fontWeight: 700,
            }}>{toHumanNumber(affordability.totalCost)}</span>
            &nbsp;
            <Huny width="24px" height="24px" style={{ verticalAlign: "sub" }} />
            &nbsp;
            for {formState.resources.toNumber()} {resource.name}.
          </Typography>

          <Box display="flex" sx={combineStyles(
            styles.buttonBox,
            {
              width: '100%',
              placeContent: 'space-between',
            }
          )}>
            <Button
              variant="outlined"
              color="secondary"
              sx={styles.cancelButton}
              onClick={handleOnClose}
              disabled={isLoading}
              disableFocusRipple
            >
              Cancel
            </Button>

            <ContainedButton
              sx={combineStyles(
                styles.confirmButton,
                {
                  flexGrow: 'initial',
                }
              )}
              disabled={!affordability.canSubmit}
              onClick={handleRequestBuy}
            >
              {isLoading
                ? <CircularProgress size={18} />
                : "Confirm & Proceed"
              }
            </ContainedButton>
          </Box>

        </Box>
      )
    }
    else if (buyRequestCompleted) {
      return (
        <Box sx={styles.contentBox}>
          <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, { width: '80%', textAlign: 'center' })}>
            You have successfully received {resource.asset.name}! Check out the resources at your base.
          </Typography>
          <Typography variant="h2" color="primary" sx={{ display: 'flex', alignItems: 'center', marginTop: '24px' }} component="span">
            <Box
              component="img"
              src={resource.asset.iconSrc}
              alt="resource"
              height="64px"
              width="64px"
            />
            &nbsp;x {formState.resources.toNumber()}
          </Typography>
          <Link
            target="_blank"
            href={getExplorerLink("tx", purchaseTxHash, network)}
            sx={styles.viewTx}
          >
            View Transaction
            <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
          </Link>
          <ContainedButton sx={styles.button} href="/metazoa">View Resource</ContainedButton>
        </Box >
      )
    } else {
      return (
        <Box sx={styles.contentBox}>
          <Box sx={styles.textContainer}>
            <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, styles.textWrapper)} component="span">
              Buy {resource.asset.name} with HUNY! Each resource
              <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, styles.gradientText)} component="span">&nbsp;bought&nbsp;</Typography>
              will
              <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, styles.gradientText)} component="span">&nbsp;increase&nbsp;</Typography>
              the price for the next item.&nbsp;
              <Link
                target="_blank"
                href="https://docs.zolar.io/stations/zomg-store-new#mission-ii"
              >
                Learn More
              </Link>
            </Typography>
            <ItemTooltip
              // open={true}
              title={!!resource.asset.name ? (<Typography variant="body1" sx={{
                lineHeight: 'normal',
                fontSize: '1.5em',
                ...GUILD_LIGHTGRAY_GRADIENT,
              }}>{resource.asset.description}</Typography>) : ''}
              TransitionComponent={Zoom}
              placement="top"
            >
              <Box
                height="150px"
                sx={styles.itemFrame}>
                <Box
                  component="img"
                  src={!!resource ? resource.asset.iconSrc : ''}
                  width="60%"
                  maxWidth="80px"
                  minHeight="100px"
                  onError={QuestConstants.DEFAULT_SRC}
                />
              </Box>
            </ItemTooltip>
          </Box>
          <Box sx={styles.dialogRow}>
            <Typography variant="body1" color="primary" sx={styles.textStyle}>Buy</Typography>
            <Box width="50%">
              <Box sx={combineStyles(styles.input, styles.numInputBox)}>
                <IconButton onClick={handleSubtractQtyResources}>
                  <RemoveIcon style={{ height: 16, width: 16 }} />
                </IconButton>
                <Box sx={styles.numInput}>
                  <Box
                    component="img"
                    alt="resource icon"
                    src={resource.asset.iconSrc}
                    width="24px"
                    height="24px"
                  />
                  <TextField
                    id="resource"
                    name="resource"
                    onChange={onResourceInputChange}
                    value={bnOrZero(formState.resources).toString()}
                    sx={combineStyles(styles.input, styles.settingNumInput, {
                      flex: 0.5
                    }, {
                      ...(formState.resources.gte(10000)) && ({
                        flex: `calc(0.6 + ${(Number(formState.resources.toString().length) - 5) / 10})`,
                        "& input": { textAlign: "start" },
                      })
                    })} />
                </Box>
                <IconButton onClick={handleAddQtyResources}>
                  <AddIcon style={{ height: 16, width: 16 }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.dialogRow}>
            <Typography variant="body1" color="primary" sx={styles.textStyle}>You'll Pay</Typography>
            <Typography variant="h3" color="success.main" component="span" sx={styles.textAlign}>
              {toHumanNumber(affordability.totalCost.shiftedBy(-Decimals.HUNY))}
              &nbsp;<Huny width="24px" height="24px" /></Typography>
          </Box>
          <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, { marginTop: '16px' })} component="span">
            You will receive
            <Typography variant="body1" color="success.main" sx={styles.textStyle}>&nbsp;{formState.resources.toNumber()}&nbsp;</Typography>
            {resource.name}
          </Typography>

          <Box display="flex" sx={styles.buttonBox}>
            {!approved &&
              <ContainedButton sx={styles.approveButton} onClick={handleApprove} disabled={isLoading}>
                {loadingApprove
                  ? <CircularProgress size={24} />
                  : <span style={{ lineHeight: '2rem' }}>Approve</span>
                }
              </ContainedButton>
            }

            <ContainedButton
              sx={styles.confirmButton}
              disabled={!affordability.canSubmit}
              onClick={handleRequestBuy}
            >
              {isLoading
                ? <CircularProgress size={18} />
                : "Confirm & Buy"
              }
            </ContainedButton>
          </Box>
        </Box >
      )
    }
  }
  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={false} dialogOverwrites={{ overflowY: 'scroll' }}>
      {getContent()}
    </DialogModal>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  textContainer: {
    width: '100%',
    padding: '20px 40px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    border: '1px solid #AEF1EE',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textWrapper: {
    marginRight: '20px',
  },
  textStyle: {
    fontSize: '1.125rem',
    lineHeight: '36px',
    fontWeight: 400,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  textAlign: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemFrame: {
    backgroundImage: `url(${StoreItemFrame})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '200px',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
  dialogRow: {
    marginTop: '12px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },

    "&:hover, &:active, &:focus, &:focus-within": {
      borderColor: '#AEF1EE',
    },

    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      paddingY: "12px",

      "&:active, &:focus": {
        color: "#AEF1EE",
        "& input": {
          color: "#AEF1EE",
        },
      }
    },
  },
  settingNumInput: {
    background: 'transparent',
    border: 0,
    flex: 0.4,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
    "& input": {
      paddingX: '0',
      width: "100%",
      textAlign: "center",
    },
  },
  numInputBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    width: "100%",
    gap: '24px',
  },
  numInput: {
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    gap: '8px',
    flex: 1,
  },
  button: {
    marginTop: '20px',
    minWidth: '280px'
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "40px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },

  buttonBox: {
    marginTop: "16px",
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  approveButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    marginRight: '10px',
    width: 270,
    "@media (max-width:600px)": {
      marginRight: 0,
      marginBottom: '10px',
      width: '100%',
    }
  },
  confirmButton: {
    whiteSpace: 'nowrap',
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    minWidth: 270,
    "@media (max-width:600px)": {
      width: '100%',
    }
  },
  cancelButton: {
    borderRadius: "16px",
    textTransform: "uppercase",
  },
}

const ItemTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '10px 13px',
    boxShadow: "0px 4px 24px #000000",
    borderRadius: '16px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)',
    maxWidth: 250,
  },
});

export default BuyResourceDialog;