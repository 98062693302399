import { Box, Checkbox, CircularProgress, Link, SvgIcon, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CheckedIcon, ExternalLink, UncheckedIcon } from "assets";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { DEFAULT_SRC } from "components/Guild/components/GuildConstants";
import { PROFESSIONS, STAT_PROFESSION } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { QuestConstants } from "components/Quest";
import { LocationMetadata } from "components/Quest/QuestConstants";
import { waitForTx } from "core/utilities";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { NftMetadata } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useBlockTime, useRedux, useTaskSubscriber, useToaster } from "utils";
import { QUEST_EPOCH, RESOURCE_PER_EPOCH } from "utils/constants";
import { bnOrZero, getExplorerLink } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";

export interface ModalProps {
  location: LocationMetadata;
  open: boolean;
  onClose: () => void;
}


const SendToQuestDialog: FC<ModalProps> = ({
  open,
  onClose,
  location
}: ModalProps) => {

  const toaster = useToaster();
  const network = useNetwork();
  const dispatch = useDispatch();

  const [loadingTokens] = useTaskSubscriber("updateTokens");
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const [, currentBlock] = useBlockTime();
  const questState = useRedux((state) => state.quest.quests);
  const questLocation = useMemo(() => questState[location.keyStat?.type ?? ''], [questState, location.keyStat?.type]);
  const isExceedMaxHarvestBlock = useMemo(() => !!questLocation?.maxHarvestBlock && bnOrZero(questLocation.maxHarvestBlock).lt(currentBlock), [questLocation, currentBlock])

  const {
    metazoaTokens,
  } = tokenState;

  const sendableTokens: NftMetadata[] = Object.values(metazoaTokens);
  // STATES / HOOKS -----------------

  /// ALLOWANCE
  const [needAddOperator, setNeedAddOperator] = useState<boolean>(true);
  const [runCheck, loadingCheck] = useAsyncTask("check", (error) => {
    toaster(error?.message ?? "Error checking");
    setNeedAddOperator(true);
  });
  const [runApprove, loadingApprove] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
    setNeedAddOperator(true);
  });

  /// QUEST
  const [selectedMetazoa, setSelectedMetazoa] = useState<string[]>([]);
  const [sendQuestTxHash, setSendQuestTxHash] = useState<string>('');
  const [sendQuestComplete, setSendQuestComplete] = useState<boolean>(false);
  const [runSendQuest, loadingSendQuest] = useAsyncTask("sendQuest", (error) => {
    toaster(error?.message ?? "Error Sending To Quest");
  });

  const isLoading: boolean = loadingCheck || loadingApprove || loadingSendQuest;

  useEffect(() => {
    if (!wallet) return;

    runCheck(async () => {
      if (!wallet) throw new Error("Wallet not connected")
      const operatorAdded = await TBMConnector.checkQuestAllowance(wallet, location.name);
      setNeedAddOperator(operatorAdded);
    })
    //eslint-disable-next-line
  }, [wallet, location]);

  const resourcePerDay = (token: NftMetadata) => {
    const locationType = location.keyStat?.type;
    const epochDifference = QUEST_EPOCH[network] / 2300;
    const tokenBonus = token.bonuses ?? {}
    if (Object.keys(tokenBonus).length === 0) {
      return bnOrZero(RESOURCE_PER_EPOCH[location.keyStat!.type]).dividedBy(epochDifference);
    }
    const totalBonusMultiplier = bnOrZero(token.bonuses?.resourceGatheringBonus[locationType!]?.total);
    const hunyBonus = bnOrZero(token.bonuses?.resourceGatheringBonus[locationType!]?.hunyBonus);
    const guildStrengthBonus = bnOrZero(token.bonuses?.resourceGatheringBonus[locationType!]?.guildStrengthBonus)
    if (token?.profession === locationType) {
      return bnOrZero(RESOURCE_PER_EPOCH[location.keyStat!.type])
        .times(totalBonusMultiplier
          .plus(100) // hardcode profession specialisation bonus (api only updated after stake)
          .dividedBy(100))
        .dividedBy(epochDifference);
    }
    return bnOrZero(RESOURCE_PER_EPOCH[location.keyStat!.type])
      .times((hunyBonus.plus(100)
        .plus(guildStrengthBonus))
        .dividedBy(100))
      .dividedBy(epochDifference);
  }

  const totalResourcePerDay = useMemo(() => {
    const strTotalResource = selectedMetazoa.reduce((total, id) => {
      const token = tokenState.metazoaTokens[id];
      total = (bnOrZero(total).plus(resourcePerDay(token))).toFixed(2)
      return total;
    }, "0");

    return bnOrZero(strTotalResource).toNumber() ?? 0;;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMetazoa, tokenState, location.keyStat, network]);

  const isSubmitEnabled = useMemo(() => {
    if (!wallet || !selectedMetazoa.length || isLoading) return false;

    if (needAddOperator && !isExceedMaxHarvestBlock) return false;

    return true;
  }, [isLoading, needAddOperator, selectedMetazoa, wallet, isExceedMaxHarvestBlock])

  // FUNCTIONS ----------------------

  const isMetazoaSelected = (tokenId: string) => selectedMetazoa.includes(tokenId);

  const getProfession = (profession: string) => {
    switch (profession) {
      case "STR": return "Marauder"
      case "INT": return "Psionic"
      case "DEX": return "Astrominer"
      default: return "-"
    }
  }

  const approve = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const approveTx = await TBMConnector.addQuestOperator(wallet, location.name);
    toaster(`Submitted Quest Operator `, { hash: approveTx.id! })
    if (approveTx.isRejected() || !approveTx.id) throw new Error("Submitted transaction was rejected.");

    try {
      await waitForTx(approveTx.id);
      setNeedAddOperator(false);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  const sendQuest = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (isExceedMaxHarvestBlock) throw new Error('Quest Over');
    if (!isSubmitEnabled) throw new Error(`Requirements not met`);

    const sendBattleTx = await TBMConnector.enterQuest(wallet, selectedMetazoa, location.name);
    toaster(`Submitted Quest Travel `, { hash: sendBattleTx.id! });
    if (sendBattleTx.isRejected() || !sendBattleTx.id) throw new Error("Submitted transaction was rejected.");

    const tx = await waitForTx(sendBattleTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(sendBattleTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    setSendQuestTxHash(sendBattleTx.id);
    setSendQuestComplete(true);
    toaster(`Send to Quest `, { hash: sendBattleTx.id!, overridePersist: true });
  }

  const revertToInitialState = () => {
    if (sendQuestComplete) dispatch(actions.Token.reloadTokens());
    setSendQuestTxHash("");
    setSelectedMetazoa([])
    setSendQuestComplete(false);
  };

  // EVENT HANDLERS -----------------

  const handleApprove = () => {
    runApprove(async () => {
      await approve();
    });
  }

  const handleSendQuest = () => {
    runSendQuest(async () => {
      await sendQuest();
    });
  }

  const handleSelectAll = () => {
    if (isLoading) return;

    const selectableMetazoa = Object.keys(tokenState.metazoaTokens);
    setSelectedMetazoa(Object.keys(tokenState.metazoaTokens).filter(id => selectableMetazoa.includes(id)));
  }

  const handleSelect = (tokenId: string) => (_event: React.ChangeEvent<HTMLInputElement>) => {
    if (isLoading) return;
    const selectedMetazoaCopy = selectedMetazoa.slice();
    const index = selectedMetazoaCopy.findIndex((id) => id === tokenId);
    if (index === -1) {
      selectedMetazoaCopy.push(tokenId);
      setSelectedMetazoa(selectedMetazoaCopy);
    } else {
      selectedMetazoaCopy.splice(index, 1);
      setSelectedMetazoa(selectedMetazoaCopy);
    }
  }

  const handleOnClose = () => {
    if (isLoading) return;
    revertToInitialState();
    onClose();
  }

  const handleSendMore = () => {
    if (isLoading) return;
    revertToInitialState();
  }

  // --------------------------------

  const getHeader = () => {
    if (sendQuestComplete && sendQuestTxHash) {
      return (
        <Box>
          Send Metazoa Success!
        </Box>);
    } else
      return (
        <Box sx={{ textAlign: "center", lineHeight: '40px' }}>
          Send Metazoa to
          <br />{location.name}
        </Box>);
  }

  const getContent = () => {
    if (sendQuestComplete && sendQuestTxHash) {
      return (
        <Box sx={styles.contentBox}>
          <Typography variant="body1" color="primary" textAlign="center">
            You have successfully sent your Metazoa into the {location.name}!
          </Typography>
          {/* Qty */}
          <Box display="flex" alignItems="center" mt="30px">
            <Box component="img" src={DefaultUserProfile} mr="10px" sx={styles.placeholderImage} />
            <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }}>x {selectedMetazoa.length}</Typography>
          </Box>

          {/* Tx hash */}
          <Link
            target="_blank"
            href={getExplorerLink("tx", sendQuestTxHash, network)}
            sx={styles.viewTx}
          >
            View Transaction
            <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
          </Link>

          {/* Send more */}
          <ContainedButton
            onClick={handleSendMore}
            sx={styles.sendMoreButton}
            disabled={(!Object.values(metazoaTokens).length) || (Object.values(metazoaTokens).length - selectedMetazoa.length) <= 0}
          >
            Send More
          </ContainedButton>
        </Box>
      )
    }
    return (
      <Box sx={styles.contentBox}>
        {/* Metazoa */}
        <TableContainer sx={styles.tableContainer}>
          <Table>
            <TableHead sx={styles.tableHead}>
              <TableRow>
                {/* Metazoa */}
                <TableCell align="left">
                  <Typography variant="body1" color="primary">Metazoa</Typography>
                </TableCell>

                {/* Profession */}
                <TableCell align="left">
                  <Typography variant="body1" color="primary">Profession</Typography>
                </TableCell>

                {/* Huny */}
                <TableCell align="center">
                  <Typography variant="body1" color="primary" sx={styles.alignResource}>
                    Resource / Day&nbsp;
                    {!!location.resource && (
                      <Box
                        component="img"
                        src={location.resource.iconSrc}
                        height="24px"
                        width="24px"
                        onError={QuestConstants.DEFAULT_SRC}
                      />
                    )}
                  </Typography>
                </TableCell>

                {/* Select all */}
                <TableCell align="center">
                  <Typography variant="body1" color="primary" sx={styles.selectAll} onClick={handleSelectAll}>SELECT ALL</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={styles.tableBody}>
              {loadingTokens && (
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={2} align="center"><CircularProgress /></TableCell>
                </TableRow>
              )}

              {!loadingTokens && sendableTokens.map((token) => {
                const attributes: any = {};
                token?.attributes?.forEach(attribute => {
                  attributes[attribute.trait_type] = attribute.value;
                })

                return (
                  <TableRow key={token.id}>

                    {/* Metazoa */}
                    <TableCell align="left">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box key={token.id} sx={styles.metazoaWrapper}>
                          {!!token.profession && (
                            <Box
                              component="img"
                              src={PROFESSIONS[STAT_PROFESSION[token.profession]].icon}
                              onError={QuestConstants.DEFAULT_SRC}
                              sx={styles.professionIcon}
                            />
                          )}
                          <Box
                            component="img"
                            src={token.image}
                            alt={`ZOA #${token.id}`}
                            width="40px"
                            sx={styles.zoaImage}
                            onError={DEFAULT_SRC}
                          />
                        </Box>

                        <Typography variant="body1" color="primary">
                          ZOA #{token.id}
                        </Typography>
                      </Box>
                    </TableCell>

                    {/* Profession */}
                    <TableCell align="left">
                      <Typography variant="body1" color="primary">{token.profession ? getProfession(token.profession) : "-"}</Typography>
                    </TableCell>

                    {/* Resource per day */}
                    <TableCell align="center">
                      <Typography variant="body1" color="primary">
                        {resourcePerDay(token).toFixed(2)}
                      </Typography>
                    </TableCell>

                    {/* Checkbox */}
                    <TableCell align="center">
                      <Checkbox
                        icon={<UncheckedIcon />}
                        checkedIcon={<CheckedIcon />}
                        checked={isMetazoaSelected(token.id)}
                        onChange={handleSelect(token.id)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="body1" color="primary" component="span" sx={styles.textAlign}>
          You’re sending&nbsp;
          <Typography variant="h3" color={"success.main"}>{selectedMetazoa.length}</Typography>
          <Typography variant="h3" color="primary">&nbsp;/ {Object.keys(tokenState.metazoaTokens).length}</Typography>
          &nbsp;Metazoa.
          {/* <TooltipZolar sx={styles.tooltip}>
            Only a maximum of 6 Metazoas can be sent every time.
          </TooltipZolar> */}
        </Typography>
        {/* {
          (selectedMetazoa.length > MAX_ZOA.EnterBattle) &&
          <Typography variant="body1" color="error">You may only send 6 Metazoa at a time!</Typography>
        } */}
        <Typography variant="body1" color="primary" component="span" sx={styles.textAlign}>
          Your Metazoa will gather&nbsp;
          <Typography variant="h3" color={"success.main"} sx={{
            fontWeight: 400,
            fontSize: 24,
          }}>
            {totalResourcePerDay.toFixed(2)}&nbsp;
            {!!location.resource && (
              <Box
                component="img"
                src={location.resource.iconSrc}
                height="24px"
                width="24px"
                onError={QuestConstants.DEFAULT_SRC}
                sx={{
                  verticalAlign: "middle",
                }}
              />
            )}
          </Typography>
          &nbsp;per day.
        </Typography>


        <Box display="flex" sx={styles.buttonBox}>
          {needAddOperator && !isExceedMaxHarvestBlock &&
            <ContainedButton
              sx={styles.approveButton}
              disabled={isLoading}
              onClick={handleApprove}
            >
              {isLoading
                ? <CircularProgress size={24} />
                : <span style={{ lineHeight: '2rem' }}>Authorize</span>
              }
            </ContainedButton>
          }
          {/* Confirm */}
          <ContainedButton
            onClick={handleSendQuest}
            disabled={!isSubmitEnabled}
            sx={styles.confirmButton}
          >
            {loadingSendQuest
              ? <CircularProgress size={24} />
              : "Confirm"
            }
          </ContainedButton>
        </Box>
      </Box>
    );
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={false} dialogOverwrites={{ overflowY: 'scroll' }}>
      {getContent()}
    </DialogModal>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    placeContent: 'start',
    alignItems: "center",
    width: "100%",
    height: "100%",
    flex: 1,
    marginTop: "10px",
  },
  tableContainer: {
    overflowY: "auto",
    maxHeight: "30vh",
    maxWidth: "700px",
    paddingRight: "10px",
    paddingBottom: "10px",
    minHeight: '250px',
    flex: 1,
    height: '100%',
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "transparent",
      top: "",
      left: "",
    },
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: "10px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },
  tableHead: {
    "& th.MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  alignResource: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  metazoaWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    placeItems: 'center',
  },
  zoaImage: {
    width: '40px',
    marginRight: "10px",
    borderRadius: "16px",
    border: '1px solid #AEF1EE',
  },
  professionIcon: {
    position: 'absolute',
    right: '0',
    top: '-15%',
    minHeight: "20px",
    minWidth: "20px",
    maxWidth: '40%',
  },
  approveButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    marginRight: '20px',
    width: 270,
    "@media (max-width:600px)": {
      marginRight: 0,
      marginBottom: '10px',
      width: '100%',
    }
  },
  confirmButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    width: 270,
    "@media (max-width:600px)": {
      width: '100%',
    }
  },
  buttonBox: {
    marginTop: "40px",
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  placeholderImage: {
    height: "60px",
    width: "60px",
    borderRadius: '30px',
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "20px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  textRow: {
    marginTop: '30px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textAlign: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltip: {
    padding: '20px',
    width: '356px',
  },
  selectAll: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
    }
  },
}

export default SendToQuestDialog
