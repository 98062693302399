import { Box, Card, CardProps, SxProps, Typography } from "@mui/material";
import { LocationIcon } from "assets";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import MetazoaThumbnailFrame from 'assets/MetazoaThumbnailFrame.svg';
import MetazoaThumbnailTag from 'assets/MetazoaThumbnailTag.svg';
import { DEFAULT_SRC, GUILD_DEEPGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { FC } from "react";
import { NftMetadata } from "store/types";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import { PROFESSIONS, STAT_PROFESSION } from "./MetazoaConstants";
import LevelUpIcon from "assets/icons/LevelUpIcon.png";

export interface CardContentProps extends CardProps {
  token: NftMetadata;
  showLocation?: boolean
}

const MetazoaCard: FC<CardContentProps> = ({
  token,
  showLocation = true
}: CardContentProps) => {

  let progressPercentage = 0;
  if (token?.exp) {
    const {
      xpGained,
      xpRequired
    } = token.exp;

    progressPercentage = (xpGained / xpRequired) * 100;
  }

  const location: string = token.location ?? 'Unknown';
  const canAssignPoints: boolean = !!token.unassignedStatPoints ?? false;


  return (
    <>
      <Box
        component="img"
        draggable="false"
        src={MetazoaThumbnailFrame}
        sx={styles.thumbnailFrame}
      />
      <Card sx={combineStyles(
        styles.infoCard,
        { ...(!showLocation) && { minHeight: '225px' } }
      )
      }>
        {/* //INFO: Metazoa Image */}
        <Box sx={styles.tokenImageWrapper}>
          <Typography variant="overline" sx={styles.tokenHash}>
            #{token.id}
          </Typography>
          <Box
            component="img"
            src={token.image ?? DefaultUserProfile}
            sx={combineStyles(
              styles.tokenImage,
              {
                ...(!showLocation) && {
                  height: '105%',
                  objectFit: 'cover',
                }
              }
            )}
            onError={DEFAULT_SRC} />

          {!!token.profession && (
            <Box
              component="img"
              src={PROFESSIONS[STAT_PROFESSION[token.profession]].icon}
              height="24px"
              width="24px"
              onError={DEFAULT_SRC}
              sx={styles.professionIcon}
            />
          )}
        </Box>

        {/* //INFO: Metazoa Info */}
        <Box sx={styles.metazoaInfoGroup}>
          <Box sx={styles.infoRow}>
            <Typography variant="overline" sx={styles.infoLvl}>
              LVL {token?.exp?.level ?? 1}
              &nbsp;
              {canAssignPoints && (
                <Box
                  component="img"
                  src={LevelUpIcon}
                  height="14px"
                  width="14px"
                  sx={{
                    verticalAlign: 'text-top',
                  }}
                />
              )}
            </Typography>
            <Box sx={styles.progressWrapper}>
              <Box sx={styles.progressBar} width={`${progressPercentage}%`} />
            </Box>
          </Box>

          {/* LOCATION */}
          {showLocation && (
            <Typography variant="body1" color="secondary.main" sx={combineStyles(styles.locationInfo, {
              textTransform: 'uppercase',
              ...GUILD_DEEPGRAY_GRADIENT,
            })}>
              <LocationIcon style={{
                verticalAlign: 'middle',
                marginRight: '1em',
              }} />
              <span style={{ verticalAlign: 'middle', whiteSpace: 'nowrap', fontSize: '8.5px', fontWeight: 500 }}>{(!!token.profession && !true) ? PROFESSIONS[STAT_PROFESSION[token.profession]].missionGround : location}</span>
            </Typography>
          )}

        </Box>
      </Card>
    </>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  cardWrapper: {
    padding: '10px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    border: 'none',
    borderRadius: '12px',
    boxShadow: 'none',
    minWidth: '220px',
    "@media (min-width:1200px)": {
      marginBottom: '16px',
    },
    "@media (max-width:1200px)": {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  nftWrapper: {
    position: 'relative',
    background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    borderRadius: '12px',
    padding: '2px',
  },
  selectedNft: {
    background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
  },
  nftTag: {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '66px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 20%, #00C2FF 100%)',
    borderRadius: '10px 0px 0px 0px',
    padding: '5px 20px',
    clipPath: 'polygon(100% 0, 100% 46%, 71% 100%, 0 100%, 0 0)',
  },
  selectedNftTag: {
    background: '#27ED82',
  },
  nftTagContent: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.125rem',
  },
  nftImage: {
    borderRadius: '12px',
  },
  metazoaStats: {
    marginLeft: '10px',
    height: '140px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  statRow: {
    height: '18px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  statIconWrapper: {
    borderRadius: ''
  },
  gradientText: {
    marginLeft: '5px',
    fontSize: '0.75rem',
    fontWeight: 500,
    background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  xpWrapper: {
    marginTop: '6px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  xpLabel: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.125rem'
  },
  progressBarWrapper: {
    width: '100px',
    height: '18px',
    padding: '3px',
    borderRadius: '4px',
    outline: '1px solid #AEF1EE66'
  },
  xpProgress: {
    height: '12px',
    background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
    borderRadius: '4px'
  },
  // contentBox: {
  //   width: '100%',
  //   marginLeft: '50px',
  //   "@media (max-width:1200px)": {
  //     marginLeft: 0,
  //     maxWidth: '300px',
  //     marginTop: '16px',
  //   },
  // },
  // cardTitle: {
  //   marginBottom: '5px',
  // },
  // cardDivider: {
  //   background: 'rgba(174, 241, 238, 0.1)'
  // },
  // attributesBox: {
  //   display: 'flex',
  //   width: '100%',
  //   flexDirection: 'column',
  // },
  // attributeBox: {
  //   paddingX: '10px',
  // },
  // attributesRow: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  // },
  // value: {
  //   background: '-webkit-linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)',
  //   WebkitBackgroundClip: 'text',
  //   WebkitTextFillColor: 'transparent',
  //   fontSize: '14px',
  //   lineHeight: '21px',
  // },
  // rarity: {
  //   background: '-webkit-linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)',
  //   WebkitBackgroundClip: 'text',
  //   WebkitTextFillColor: 'transparent',
  //   fontSize: '14px',
  //   lineHeight: '21px',
  //   whiteSpace: 'nowrap',
  // },
  // traitType: {
  //   background: '-webkit-linear-gradient(225deg, rgba(255, 255, 255, 0.48) 0%, rgba(255, 255, 255, 0.6) 49.48%, rgba(255, 255, 255, 0.384) 100%)',
  //   WebkitBackgroundClip: 'text',
  //   WebkitTextFillColor: 'transparent',
  //   fontSize: '12px',
  // },
  // firstCol: {
  //   flex: 0.35,
  //   marginY: '5px',
  //   marginRight: '30px',
  // },
  // secondCol: {
  //   flex: 0.65,
  //   marginY: '5px',
  // }

  thumbnailFrame: {
    height: '100%',
    width: '100%',
    objectFit: "contain",
  },
  metazoaInfoGroup: {
    marginTop: '.2em',

    display: 'flex',
    flexDirection: 'column',
    placeContent: 'start',
    placeItems: 'center',
    gap: '0.2em',
    paddingBottom: '0.1em',

    width: '100%',
    "> *": {
      width: '100%',
      padding: '0 10px',
    }
  },
  infoCard: {
    position: 'absolute',
    zIndex: 20,
    top: '2px',
    left: 0,
    right: 0,

    minHeight: '185px',
    height: '100%',
    width: '100%',

    background: 'transparent',
  },
  infoRow: {
    // background: 'red',

    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'center',
    gap: '0.5em',
  },

  infoLvl: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.125rem',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    // ...GUILD_DEEPGRAY_GRADIENT,
  },

  progressWrapper: {
    flex: '1 1 0%',
    minWidth: '60%',
    height: '16px',
    padding: '2px',
    borderRadius: '4px',
    outline: '2px solid #AEF1EE66',
  },

  progressBar: {
    height: '14px',
    background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)!important',
    borderRadius: '4px'
  },

  locationInfo: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '18px',
    // ...GUILD_DEEPGRAY_GRADIENT,
  },

  tokenHash: {
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1.125rem',

    position: 'absolute',
    top: -1,
    left: 0,

    minWidth: '66px',

    display: 'flex',
    alignItems: 'center',

    borderRadius: '10px 0px 0px 0px',
    padding: '1px 20px',
    // clipPath: 'polygon(100% 0, 100% 46%, 71% 100%, 0 100%, 0 0)',
    background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 20%, #00C2FF 100%)!important',

    maskImage: `url(${MetazoaThumbnailTag})`,
    maskRepeat: 'no-repeat',
    maskSize: '100%',
  },

  tokenImageWrapper: {
    // background: 'blue',

    position: 'relative',
    // height: '100%',
    width: '100%',
    borderRadius: '12px',

    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',

    maskImage: `url(${MetazoaThumbnailFrame})`,
    maskRepeat: 'no-repeat',
    maskSize: '100%',

    height: '70%',
    padding: '0.1em',
  },
  tokenImage: {
    // height: '100%',
    width: '100%',
    borderRadius: "16px",
    objectFit: "contain",
    userSelect: 'none',
  },
  professionIcon: {
    position: 'absolute',
    bottom: '4px',
    right: '4px',
  },
}

export default MetazoaCard;