import BigNumber from "bignumber.js";
import { logger } from "core/utilities";
import { Guild } from "store/types";
import { BIG_ZERO, Decimals } from "./constants";
import { SimpleMap } from "./types";

export const computeGuildStrength = (guild: Guild, exchangeRates: SimpleMap<BigNumber>) => {
    const totalGuildMino = guild.totalMino;
    const totalGuildUrsa = guild.totalUrsa;
    const guildMembers = guild.members;
    const totalBear = guild.totalBear;

    let [ratingA, ratingB, ratingC, ratingD, ratingE] = [0, 0, 0, 0, 0];

    const {
        hunyPrice,
        zilPrice
    } = exchangeRates;
    const guildType = guild.guildSigil.name;
    let hunyInWallets = guild.totalHunyHolding.minus(guild.membersTotalLp ?? BIG_ZERO);
    let hunyInHive = guild.membersTotalLp ?? BIG_ZERO;
    //sum[(2*($HUNY-in-wallet + 3*$HUNY-in-individual-magic-hive)] of all Guild Members
    let hunyPower = (hunyInWallets.plus(hunyInHive.times(3))).times(2)

    /// BANK (Optional)
    if (!!guild.guildBank?.netWorth && !!guild.guildBank.tokens) {
        let bankToken = BIG_ZERO;
        const { netWorth, tokens, guildHiveStats } = guild.guildBank;
        Object.keys(tokens).forEach((key) => {
            if (!key.toLowerCase().includes("hunyToken".toLowerCase())) bankToken = bankToken.plus(netWorth[`${key}Amt`]);
        })
        const bankTokenToHuny = bankToken.times(zilPrice).dividedBy(hunyPrice); // convert existing bank tokens to huny

        // [$HUNY+(($ZWAP+$XSGD+$SWTH+$ZIL)-converted-to-$HUNY-value/2)
        const bankHunyTokenPower = bankTokenToHuny.dividedBy(2).plus(netWorth.hunyTokenAmt);

        // ($HUNY-in-guild-magic-hive x 3) 
        const bankHivePower = guildHiveStats?.userHunyReserves?.times(3).shiftedBy(-Decimals.HUNY) ?? BIG_ZERO;

        //(Guild Member count) x [$HUNY+(($ZWAP+$XSGD+$SWTH+$ZIL)-converted-to-$HUNY-value/2)+ ($HUNY-in-guild-magic-hive x 3)]
        const bankHunyPower = (bankHunyTokenPower.plus(bankHivePower)).times(guildMembers.length);
        hunyPower = hunyPower.plus(bankHunyPower);

        logger("debug-guildBankStrength", guild.name, {
            bankNonHunyTokenCount: bankToken.toString(10),
            bankNonHunyToHuny: bankTokenToHuny.toString(10),
            bankHunyTokenPower: bankHunyTokenPower.toString(10),
            bankHunyInHiveTimes3: bankHivePower.toString(10),
            bankHunyPower: bankHunyPower.toString(10),
        })
    }

    if (guildType === "Merchant") {
        ratingA = (hunyPower.dividedBy(10000).multipliedBy(3)).toNumber();
    } else {
        ratingA = (hunyPower.dividedBy(10000)).toNumber();
    }

    if (guildType === "Mercenary") {
        ratingB = Math.pow(totalGuildMino, 2) * 3;
    } else {
        ratingB = Math.pow(totalGuildMino, 2);
    }

    let [gen0, gen1, gen2, gen3] = [0, 0, 0, 0];
    guildMembers.forEach((member) => {
        member.metadata?.forEach((metazoa) => {
            if (metazoa.attributes?.find(attribute => attribute.trait_type === "Gen")?.value === '0') gen0++;
            if (metazoa.attributes?.find(attribute => attribute.trait_type === "Gen")?.value === '1') gen1++;
            if (metazoa.attributes?.find(attribute => attribute.trait_type === "Gen")?.value === '2') gen2++;
            if (metazoa.attributes?.find(attribute => attribute.trait_type === "Gen")?.value === '3') gen3++;
        })
        member.stakedMetazoa?.forEach((metazoa) => {
            if (metazoa.attributes?.find(attribute => attribute.trait_type === "Gen")?.value === '0') gen0++;
            if (metazoa.attributes?.find(attribute => attribute.trait_type === "Gen")?.value === '1') gen1++;
            if (metazoa.attributes?.find(attribute => attribute.trait_type === "Gen")?.value === '2') gen2++;
            if (metazoa.attributes?.find(attribute => attribute.trait_type === "Gen")?.value === '3') gen3++;
        })
    })

    if (guildType === "Guardian") {
        ratingC = ((gen0 * Math.pow(4, 0)) + (gen1 * Math.pow(4, 1)) + (gen2 * Math.pow(4, 2)) + (gen3 * Math.pow(4, 3))) * 3
    } else {
        ratingC = (gen0 * Math.pow(4, 0)) + (gen1 * Math.pow(4, 1)) + (gen2 * Math.pow(4, 2)) + (gen3 * Math.pow(4, 3))
    }

    if (guildType === "Warrior") {
        ratingD = (totalGuildMino + totalGuildUrsa) * 3;
    } else {
        ratingD = (totalGuildMino + totalGuildUrsa);
    }

    ratingE = totalBear;
    logger("debug-guildStrength", guild.name, {
        guild: guild.name,
        strengthComputation: {
            guildType,
            hunyInWallets: hunyInWallets.toString(10),
            hunyInHive: hunyInHive.toString(10),
            hunyPower: hunyPower.toString(10),
            tokens: {
                totalGuildMino,
                totalGuildUrsa,
                totalBear,
            },
            gens: {
                gen0,
                gen1,
                gen2,
                gen3,
            },
            ratings: {
                A: ratingA,
                B: ratingB,
                C: ratingC,
                D: ratingD,
                E: ratingE,
                sum: (ratingA + ratingB + ratingC + ratingD + ratingE),
            }
        }
    })

    return ratingA + ratingB + ratingC + ratingD + ratingE
}