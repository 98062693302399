import { Box, Button, IconButton, Link, SxProps, Typography } from "@mui/material";
import { DiscordWhite, GuildOverviewDivider, Huny, MinoIcon, StrengthRatingIcon, UrsaIcon } from "assets";
import GuildOverviewBackground from "assets/GuildOverviewBackground.svg";
import GuildOverviewVector from "assets/GuildOverviewVector.svg";
import { CRESTS, CREST_COLOURS, DISCORD_INVITE, GUILD_GRAY_GRADIENT, SIGILS, SIGIL_COLOURS } from "components/Guild/components/GuildConstants";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getProfile } from "saga/selectors";
import { Guild } from "store/types";
import { Decimals } from "utils/constants";
import { bnOrZero, formatGuildHuny, formatIntegerNumber } from "utils/strings/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";

interface GuildOverviewProps {
  guild: Guild;
}

const GuildOverview: React.FC<GuildOverviewProps> = (props: GuildOverviewProps) => {
  const { guild } = props;

  const metazoaProfileState = useSelector(getProfile);
  const { profile } = metazoaProfileState;

  const currentProfile = useMemo(() => {
    if (!profile || !guild) return;

    const addr: string = profile.address
    const councilAddresses: string[] = [guild.leaderAddress, ...guild.commanderAddresses]

    //INFO: Current profile attributes
    const isOfficer: boolean = councilAddresses.includes(addr)
    const isLeader: boolean = guild.leaderAddress === addr
    const canEdit: boolean = isLeader || isOfficer;

    return { addr, isLeader, isOfficer, canEdit }
    // eslint-disable-next-line 
  }, [guild, profile])

  return (
    <Box sx={styles.overviewBox}>
      {/* bottom sigil */}
      <Box sx={combineStyles(
        styles.guildFlagSigil, {
        backgroundImage: `url(${SIGILS[guild.guildSigil.design - 1][SIGIL_COLOURS.findIndex((curr) => curr.colour === guild.guildSigil.colour)]})`,
      })} />

      {/* crest */}
      <Box position="relative">
        <Box sx={styles.crest}>
          {guild.crestImageUrl && <Box component="img" src={guild.crestImageUrl} sx={styles.crestImage} />}
          <Box sx={combineStyles(styles.crestFrame, {
            backgroundImage: `url(${CRESTS[guild.guildCrest.design - 1][CREST_COLOURS.indexOf(guild.guildCrest.colour)]})`,
          })} />
        </Box>
      </Box>

      {/* name */}
      <Typography variant="h3" color="primary" sx={combineStyles(
        styles.guildName,
        { ...(guild.name.length > 16) && { wordBreak: "break-all", } },
      )}>{guild.name}

        {/* edit link */}
        {currentProfile && currentProfile.canEdit && (
          <Button variant="text" component={NavLink} to={`/guilds/${guild.id}/update`} sx={styles.editButton}>
            <Typography variant="body1" color="success.main">Edit</Typography>
          </Button>
        )}
      </Typography>



      {/* discord */}
      <Box
        component={Link}
        href={DISCORD_INVITE}
        target="_blank"
        sx={styles.discordBox}
      >
        <IconButton sx={styles.discordIcon}>
          <DiscordWhite width="18px" height="18px" />
        </IconButton>
        <Typography variant="body1" color="primary" sx={styles.discordText}>
          #{guild.slug}
        </Typography>
      </Box>

      {/* quote */}
      <Typography variant="body1" color="primary" sx={styles.quote}>"{guild.description}"</Typography>

      {/* playstyle */}
      <Box sx={styles.vectorBox}>
        <Typography variant="body1" color="primary" sx={styles.vectorText}>{guild.guildSigil?.name ?? "playstyle"}</Typography>
      </Box>

      {/* divider */}
      <GuildOverviewDivider />

      {/* other info */}
      <Box sx={styles.statBox}>

        {/* guild chest */}
        <Box sx={styles.statRow}>
          <Typography variant="body1" color="primary" sx={styles.statText}>Guild Bank</Typography>
          <Typography variant="body1" color="primary" sx={styles.comingSoon}>Coming Soon</Typography>
        </Box>

        {/* guild strength */}
        <Box sx={styles.statRow}>
          <Typography variant="body1" color="primary" sx={styles.statText}>Strength Rating</Typography>
          <Typography variant="body1" color="primary" sx={styles.statValue}>
            {formatIntegerNumber(guild.strength)}
            {" "}
            <StrengthRatingIcon style={{ marginBottom: 0 }} />
          </Typography>
        </Box>

        {/* huny holding */}
        <Box sx={styles.statRow}>
          <Typography variant="body1" color="primary" sx={styles.statText}>HUNY Holding</Typography>
          <Typography variant="body1" color="primary" sx={styles.statValue}>
            {formatGuildHuny(guild.totalHunyHolding)}
            {" "}
            <Huny />
          </Typography>
        </Box>

        {/* huny harvested */}
        <Box sx={styles.statRow}>
          <Typography variant="body1" color="primary" sx={styles.statText}>HUNY Harvested</Typography>
          <Typography variant="body1" color="primary" sx={styles.statValue}>
            {formatIntegerNumber(bnOrZero(guild?.totalHunyHarvested).shiftedBy(-Decimals.HUNY))}
            {" "}
            <Huny />
          </Typography>
        </Box>

        {/* summon */}
        <Box sx={styles.statRow}>
          <Typography variant="body1" color="primary" sx={styles.statText}>Summon</Typography>
          <Typography variant="body1" color="primary" sx={styles.statValue}>{guild.totalSummon ?? 0} Metazoa</Typography>
        </Box>

        {/* army */}
        <Box sx={styles.statRow}>
          <Typography variant="body1" color="primary" sx={styles.statText}>Army</Typography>
          <Typography variant="body1" color="primary" sx={styles.statValue}>
            {guild.totalUrsa}
            {" "}
            <UrsaIcon />
            {" - "}
            {guild.totalMino}
            {" "}
            <MinoIcon />
          </Typography>
        </Box>

        {/* kidnap */}
        <Box sx={styles.statRow}>
          <Typography variant="body1" color="primary" sx={styles.statText}>Kidnap</Typography>
          <Typography variant="body1" color="primary" sx={styles.statValue}>
            {guild?.totalUrsaKidnap ?? 0}
            {" "}
            <UrsaIcon />
            {" - "}
            {guild?.totalMinoKidnap ?? 0}
            {" "}
            <MinoIcon />
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  overviewBox: {
    marginTop: "140px",
    backgroundImage: `url(${GuildOverviewBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "140px",
    paddingX: "40px",
    paddingBottom: "140px",
    minWidth: "320px",
    maxWidth: "320px",
    position: "relative",
    minHeight: '720px',
    "@media (max-width:600px)": {
      paddingTop: "100px",
    },
  },

  guildFlagSigil: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    position: "absolute",
    bottom: "-3.5%",
    left: "50%",
    transform: "translate(-50%, -58%)",
    height: '80px',
    width: '80px',
  },

  guildName: {
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: '1.125rem!important',
    minWidth: '260px',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
    gap: '6px',
    ...GUILD_GRAY_GRADIENT,
  },

  editButton: {
    margin: '0 !important',
    padding: '0 !important',
    minWidth: 'unset',
    display: 'inline',

    "> p": {
      fontWeight: 600,
      fontSize: '0.75rem',
      textDecorationLine: 'underline',
    }
  },

  quote: {
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#AEF1EE",
    marginTop: "20px",
    wordBreak: 'break-word',
  },

  statBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    width: "100%",
    marginTop: "20px",
  },

  statRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
  },

  statText: {
    fontSize: "14px!important",
    lineHeight: "16px!important",
    color: "rgba(255, 255, 255, 0.8)",
  },

  statValue: {
    fontSize: "14px!important",
    lineHeight: "16px!important",
    fontWeight: 600,
    color: "#AEF1EE",
    display: "flex",
    flexDirection: "row",
    placeItems: "center",
    gap: "4px",
    "& svg": {
      verticalAlign: 'bottom',
      marginBottom: '2px',
      height: 16,
      width: 16,
    },
  },

  comingSoon: {
    fontSize: "14px!important",
    lineHeight: "16px!important",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 0.4)",
  },

  vectorBox: {
    backgroundImage: `url(${GuildOverviewVector})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 16px",
    marginTop: "10px",
    marginBottom: "20px",
  },

  vectorText: {
    textTransform: "uppercase",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "1px",
    textAlign: "center",
    color: "rgba(255, 255, 255, 0.8)",
  },

  discordBox: {
    display: 'flex',
    padding: "2px 12px",
    borderRadius: "16px",
    backgroundColor: "#5865F2",
    marginTop: "4px",
    textDecoration: "none",
    // TODO: hover styling?
  },

  discordIcon: {
    padding: "0px",
    marginRight: "6px",
  },

  discordText: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
  },

  memberListText: {
    color: "#AEF1EE",
    whiteSpace: "nowrap",
  },

  divider: {
    background: "#AEF1EE",
    width: "100%",
    flexShrink: "inherit",
    marginX: "20px",
  },

  membersBox: {
    paddingLeft: "80px",
    "@media (max-width:600px)": {
      paddingLeft: "40px",
      paddingRight: "24px",
    },
  },

  connectButton: {
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .MuiButton-root": {
      minHeight: "",
      height: 48,
      minWidth: 280,
      fontSize: "18px",
      lineHeight: "28px",
      whiteSpace: "nowrap",
    }
  },

  discordHandleText: {
    fontSize: "14px",
    lineHeight: "18px",
    color: "rgba(255, 255, 255, 0.8)",
  },

  crest: {
    position: "absolute",
    backgroundRepeat: "no-repeat",
    backgroundSize: "165% 165%",
    backgroundPosition: "center",
    height: "250px",
    width: "250px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -105%)",
    display: "flex",
    placeContent: "center",
    placeItems: "center",
    "@media (max-width:900px)": {
      height: "220px",
      width: "220px",
    },
  },

  crestImage: {
    height: "57.5%",
    width: "57.5%",
    clipPath: "circle(50% at center)",
  },

  crestFrame: {
    position: "absolute",
    backgroundRepeat: "no-repeat",
    backgroundSize: "165% 165%",
    backgroundPosition: "center",
    height: "250px",
    width: "250px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    placeContent: "center",
    placeItems: "center",
    "@media (max-width:900px)": {
      height: "220px",
      width: "220px",
    },
  },
}

export default GuildOverview
