import { Network } from "zilswap-sdk/lib/constants";

export const getExplorerLink = (type: "address" | "tx", value: string, network: Network = Network.MainNet) => {
  return `https://viewblock.io/zilliqa/${type}/${value}?network=${network.toLowerCase()}`;
};

export const getZilswapLink = (outCurrency: string, inCurrency = "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz") => {
  return `https://zilswap.io/swap?tokenIn=${inCurrency}&tokenOut=${outCurrency}`
}

export const getArkyLink = (collectionAddress: string) => {
  return `https://zilswap.io/arky/collections/${collectionAddress}`;
}
