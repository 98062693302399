import { Box, SxProps } from '@mui/material'
import { AppTheme, SimpleMap } from 'utils/types'
import ExploreBanner from './Banner'
import ZoaView from './MetazoaSearch'

function ExploreView() {
  return (
    <Box sx={styles.gameWrapper}>
      <ExploreBanner />
      <ZoaView />
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  gameWrapper: {
    marginTop: '100px',
    padding: '40px',
    minHeight: '500px',
    minWidth: '720px',
    // "@media (max-width:1200px)": {
    //   paddingX: '0px',
    // },
  },
};

export default ExploreView