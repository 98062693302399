import { SxProps } from "@mui/material";
import { AppTheme } from "./types";

export const combineStyles = (
  ...styles: (SxProps<AppTheme> | undefined)[]
): SxProps<AppTheme> => {
  return styles.reduce(
    (accum, style) => ({
      ...accum,
      ...style,
    }),
    {} as SxProps<AppTheme>
  )!;
};

export const importAll = (r) => r.keys().reduce((imgs, itm) => {
  imgs[itm.replace('./', '')] = r(itm);
  return imgs
}, {});