import { Box, Container, styled, SxProps, Typography } from "@mui/material";
import { PinkShootingStar } from "assets";
import PinkPlanet from "assets/PinkPlanet.png";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProfile } from "saga/selectors";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";
import { GUILD_GRAY_GRADIENT } from "../GuildConstants";
import { PageOne, PageTwo } from "./components";

export type GuildFormInputs = {
  guildName: string;
  guildSlug: string;
  description: string;
  guildSigilDesign: number;
  guildSigilColour: string;
  guildBannerDesign: number;
  guildBannerColour: string;
  guildCrestDesign: number;
  guildCrestColour: string;
}

export type Errors = {
  guildName: string;
  guildSlug: string;
  description: string;
}

const CreateGuild: React.FC = () => {
  const network = useNetwork();
  const wallet = useRedux((state) => state.wallet.wallet);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [displayImage, setDisplayImage] = useState<string | ArrayBuffer | null>(null);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [formInputs, setFormInputs] = useState<GuildFormInputs>({
    guildName: "",
    guildSlug: "",
    description: "",
    guildSigilDesign: 1,
    guildSigilColour: "grey",
    guildBannerDesign: 1,
    guildBannerColour: "grey",
    guildCrestDesign: 1,
    guildCrestColour: "grey",
  });
  const [errors, setErrors] = useState<Errors>({
    guildName: "",
    guildSlug: "",
    description: "",
  })

  const [runFetchCrestPrice] = useAsyncTask("fetchCrestPrice");

  const navigate = useNavigate();
  const metazoaProfileState = useSelector(getProfile);
  const { profile } = metazoaProfileState;

  //INFO: Redirect guild members to their respective guild instead of the creation form
  useEffect(() => {
    if (!profile) return;
    if (profile.guildId) {
      navigate(`/guilds/${profile.guildId}`);
      return;
    }
    // eslint-disable-next-line
  }, [profile])

  // TODO: fetch crest price from stall contract
  useEffect(() => {
    runFetchCrestPrice(async () => {
      // const result = await TBMConnector.fetchStallItemPrice(ITEM_ID.GuildCrest);
    })

    // eslint-disable-next-line
  }, [network])

  const handleNext = () => {
    if (currentPage === 2) return;

    // errors not empty
    if (Object.values(errors).some(err => !!err)) return;
    // compulsory fields
    if (Object.values(formInputs).some(input => !input)) return;

    setCurrentPage(2);
    window.scrollTo(0, 0);
  }

  const handleBack = () => {
    if (currentPage === 1) return;

    setCurrentPage(1);
    window.scrollTo(0, 0);
  }

  const isCreateEnabled = useMemo(() => {
    // errors not empty
    if (Object.values(errors).some(err => !!err))
      return false;

    // compulsory fields
    if (Object.values(formInputs).some(input => !input))
      return false;

    // no image uploaded
    if (!uploadedImage)
      return false;

    return true;
  }, [errors, uploadedImage, formInputs])

  return (
    <>
      <PinkShootingBox>
        <PinkShootingStar width="100%" />
      </PinkShootingBox>
      <PinkPlanetBox>
        <img src={PinkPlanet} alt="pink-planet" width="100%" />
      </PinkPlanetBox>
      <Container sx={styles.root}>
        <Typography variant="h2" color="primary" sx={{
          width: '70%',
          ...GUILD_GRAY_GRADIENT
        }}>Buy a Guild Crest</Typography>
        {!wallet
          ? <Box display="flex" flexDirection="column" mt="60px" width="70%">
            <Typography variant="body1" color="primary">
              Please connect your wallet to view this page.
            </Typography>
            <ConnectWalletButtonCTA sx={styles.connectButton} />
          </Box>
          : currentPage === 1
            ? <PageOne
              formInputs={formInputs}
              setFormInputs={setFormInputs}
              handleNext={handleNext}
              errors={errors}
              setErrors={setErrors}
            />
            : <PageTwo
              formInputs={formInputs}
              setFormInputs={setFormInputs}
              handleBack={handleBack}
              displayImage={displayImage}
              setDisplayImage={setDisplayImage}
              uploadedImage={uploadedImage}
              setUploadedImage={setUploadedImage}
              isCreateEnabled={isCreateEnabled}
            />
        }
      </Container>
    </>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  root: {
    marginTop: "160px",
    paddingLeft: "24px",
    paddingRight: "24px",

    display: "flex",
    flexDirection: "column",
    justifyContent: 'start',
    placeItems: 'center',
  },
  connectButton: {
    "& #wrapperBox": {
      "@media (max-width:900px)": {
        width: "100%",
        display: "initial",
      },
    },
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .MuiButton-root": {
      height: 64,
      minWidth: 300,
      marginTop: "20px",
      "@media (max-width:600px)": {
        width: "100%",
        minWidth: "",
      },
    }
  },
}

const PinkPlanetBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  width: '109.79px',
  height: '110px',
  right: '80px',
  top: '208px',
  zIndex: 0,
  "@media (max-width:600px)": {
    top: "80px",
    left: "300px",
    width: '50.79px',
  },
});

const PinkShootingBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  width: '140.85px',
  height: '94.34px',
  right: '80px',
  top: '948px',
  zIndex: 0,
  "@media (max-width:600px)": {
    display: 'none',
  },
});

export default CreateGuild;
