import { Box, CircularProgress, SxProps } from "@mui/material";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import { LoadingKeys } from "utils/constants";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";
import useTaskSubscriber from "utils/useTaskSubscriber";
import StripeHeader from "../StripeHeader";
import ArmyStake from "./ArmyStake";
import ArmyStats from "./ArmyStats";
import { DynamicClaim } from "./DynamicClaim";
import HunyStats from "./HunyStats";
import LostPlanet from "./LostPlanet";

const Stats: React.FC = () => {
  const wallet = useRedux((state) => state.wallet.wallet);
  const [isLoading] = useTaskSubscriber(...LoadingKeys.connectWallet);

  return (
    <Box sx={styles.statsContainer}>
      <Box sx={styles.statsWrapper}>
        <StripeHeader>YOUR ARMY</StripeHeader>
        <Box sx={styles.armyStatsWrapper}>
          {!wallet
            ? <ConnectWalletButtonCTA />
            : isLoading
              ? <CircularProgress size={50} />
              : <Box width="100%">
                <ArmyStats />
                <LostPlanet />
                <ArmyStake />
                <DynamicClaim />
                <HunyStats />
              </Box>
          }
        </Box>
      </Box>
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  statsContainer: {
    marginTop: '40px',
    minWidth: '720px',
    position: 'relative',
    zIndex: 2,
    "@media (max-width:1200px)": {
      marginTop: '20px',
    },
  },
  statsWrapper: {
    width: '100%',
    borderRadius: '16px',
    border: '1px solid #AEF1EE33',
    background: 'linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, #000010 100%)',
    padding: "40px",
    minHeight: '400px',
  },
  armyStatsWrapper: {
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
}


export default Stats;