import { Box, Button, Link, styled, SvgIcon, SxProps, Typography } from "@mui/material";
import { BaseButton, ExternalLink, InfoTooltip, ZilliqaLogo } from "assets";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import MetazoaPreview from "assets/MetazoaPreview.gif";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { waitForTx } from "core/utilities";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { actions } from "store";
import { TBMConnector } from "tbm";
import { useToaster } from "utils";
import { BIG_ONE } from "utils/constants";
import { getExplorerLink } from "utils/strings/links";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";

export interface ModalProps {
  open: boolean;
  quantity: number;
  price: number;
  onClose: () => void;
}

const SummonDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, quantity, price, onClose } = props;
  const [summonComplete, setSummonComplete] = useState<boolean>(false);
  const [summonTxHash, setSummonTxHash] = useState<string>("");
  const [summonedZoaTokens, setSummonedZoaTokens] = useState<string[]>([]);
  const wallet = useRedux((state) => state.wallet.wallet);
  const toaster = useToaster();
  const dispatch = useDispatch();
  const network = useNetwork();
  const [runSummon, loadingSummon] = useAsyncTask("summon", (error) => {
    toaster(error?.message ?? "Error Summoning");
  });

  const handleSummon = () => {
    runSummon(async () => {
      await summon();
    })
  }

  const summon = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const summonTx = await TBMConnector.summon(wallet, quantity, BIG_ONE.times(price).shiftedBy(12));

    if (summonTx.isRejected()) {
      throw new Error("Submitted transaction was rejected.");
    }

    if (summonTx?.id) {
      setSummonTxHash(summonTx.id);
      try {
        await waitForTx(summonTx.id);

        const tbmConnector = TBMConnector.getSDK();
        const tokenIds: string[] = [];

        const txn = await tbmConnector.zilliqa.blockchain.getTransaction(summonTx.id);
        const receipt = txn.getReceipt();

        receipt!.event_logs.forEach((event) => {
          if (event._eventname === "Mint") {
            tokenIds.push(
              event.params.find((param) => param.vname === "token_id")?.value
            );
          }
        });

        setSummonedZoaTokens(tokenIds);
        dispatch(actions.Blockchain.updateSaleState());
        dispatch(actions.Token.updateImage());
        setSummonComplete(true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const handleOnClose = () => {
    if (loadingSummon) return;

    onClose();
    setSummonTxHash("");
    setSummonedZoaTokens([]);
    setSummonComplete(false);
  }

  const getHeader = () => {
    if (summonComplete) {
      return "Summon success!";
    } else if (loadingSummon) {
      return "Summoning in progress";
    } else {
      return "Summon Metazoa";
    }
  }

  const getContent = () => {
    if (summonComplete) {
      return (
        <Fragment>
          <Typography variant="body1" color="primary" textAlign="center">
            You have now summoned your Metazoa.<br />
            Prepare yourself for the battle ahead.
          </Typography>

          <Box sx={styles.contentBox}>
            <Box sx={styles.zoaTokensContainer}>
              {summonedZoaTokens.map((tokenId) => {
                return (
                  <ZoaWrapper key={tokenId} sx={styles.zoaWrapper}>
                    <Box
                      component="img"
                      sx={styles.zoaImage}
                      src={MetazoaPreview}
                      alt={`ZOA ${tokenId}`}
                    />
                    <Box sx={styles.zoaIdBox}>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        sx={styles.zoaId}
                      >
                        ZOA #{tokenId}
                      </Typography>
                    </Box>
                  </ZoaWrapper>
                );
              })}
            </Box>

            {/* Tx hash */}
            <Link
              target="_blank"
              href={getExplorerLink("tx", summonTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>

            {/* Summon more */}
            <ContainedButton
              onClick={handleOnClose}
              sx={styles.summonButton}
              style={{ marginTop: 0 }}
            >
              Summon More
            </ContainedButton>

            {/* View profile */}
            <Button
              component={RouterLink}
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={styles.profileButton}
              to="/metazoa"
            >
              <BaseButtonBox>
                <BaseButton height="100%" />
              </BaseButtonBox>
              View My Army
            </Button>
          </Box>
        </Fragment>
      )
    }

    if (loadingSummon) {
      return (
        <Fragment>
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Fragment>
      )
    }

    return (
      <Fragment>
        {/* Info */}
        <Box display="flex" alignItems="center">
          <InfoTooltip style={{ marginRight: '16px' }} />
          <Typography color="primary" variant="body1" textAlign="center">You have a 5% chance of summoning Minos.</Typography>
        </Box>

        {/* Qty */}
        <Box display="flex" alignItems="center" mt="40px">
          <Box component="img" src={DefaultUserProfile} width="100%" height="100%" mr="10px" />
          <Typography color="primary" variant="h2" style={{ whiteSpace: 'nowrap' }}>x {quantity}</Typography>
        </Box>

        {/* Total cost */}
        <Box mt="40px">
          <Typography color="primary" variant="body1" sx={styles.cost}>
            Total Cost: {(quantity * price).toLocaleString()} <ZilliqaLogo />
          </Typography>
        </Box>

        {/* Summon button */}
        <ContainedButton
          sx={styles.summonButton}
          onClick={handleSummon}
        >
          Summon
        </ContainedButton>
      </Fragment>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} hideCloseButton={loadingSummon} disableScrollLock={true} dialogOverwrites={{ placeItems: 'center' }}>
      {getContent()}
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  cost: {
    "& svg": {
      verticalAlign: "text-top",
      marginTop: "2px",
      height: 22,
      width: 22,
    },
    "@media (max-width:600px)": {
      "& svg": {
        marginTop: 0,
        height: "20px!important",
        width: "20px!important",
      }
    },
  },
  summonButton: {
    marginTop: "40px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  profileButton: {
    marginTop: "10px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  viewTx: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  zoaTokensContainer: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "repeat(5, 1fr)",
    columnGap: "20px",
    rowGap: "20px",
    maxHeight: "170px",
    marginTop: "10px",
    paddingTop: "10px",
    overflowY: "auto",
    paddingRight: "10px",
    "@media (max-width:900px)": {
      gridTemplateColumns: "repeat(4, 1fr)",
      maxHeight: "150px",
    },
    "@media (max-width:600px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media (max-width:480px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    '::-webkit-scrollbar': {
      width: '10px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  zoaWrapper: {
    position: "relative",
    borderRadius: "16px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "visible",
  },
  zoaImage: {
    height: "100px",
    width: "100px",
    "@media (max-width:900px)": {
      height: "80px",
      width: "80px",
    },
  },
  zoaIdBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  zoaId: {
    marginTop: "6px",
    fontSize: "16px",
    "@media (max-width:900px)": {
      fontSize: "14px",
    },
  },
};

const ZoaWrapper = styled(Box)({
  background:
    "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
  border: "none",
});

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    height: "100%",
  },
});

export default SummonDialog;
