import { Box, Button, CircularProgress, SxProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { logger, waitForTx } from "core/utilities";
import { MetazoaClient } from "core/utilities/metazoa";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { Guild, OAuth } from "store/types";
import { TBMConnector } from "tbm";
import { useRedux, useToaster } from "utils";
import { getExplorerLink } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from "utils/useNetwork";

export interface Props {
  guild: Guild;
}

const LeaveRequestButton: React.FC<Props> = (props: Props) => {
  const { guild } = props;
  const hasGuildBank: boolean = !!guild?.guildBank ?? false;

  const network = useNetwork();
  const dispatch = useDispatch();
  const toaster = useToaster();
  const metazoaProfileState = useSelector(getProfile);
  const { profile, oAuth } = metazoaProfileState;
  const wallet = useRedux((state) => state.wallet.wallet);

  // STATES / HOOKS -----------------

  const [showLeaveDialog, setShowLeaveDialog] = useState<boolean>(false);
  const [runLeaveGuild, loadingLeaveGuild] = useAsyncTask("requestLeaveGuild", (error) => {
    toaster(error?.message ?? "Error Leaving Guild");
  });

  const walletHolder = useMemo(() => {
    const addr = wallet?.addressInfo.byte20!.toLocaleLowerCase()!
    const isOfficer: boolean = guild.commanderAddresses.includes(addr)
    const isLeader: boolean = guild.leaderAddress === addr

    const canLeaveGuild: boolean = (!isLeader && profile?.guildId === guild.id);

    return { addr, canLeaveGuild, isOfficer, isLeader }
    // eslint-disable-next-line 
  }, [wallet, guild])

  // FUNCTIONS ----------------------

  const requestLeaveGuild = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!guild) return;

    if (hasGuildBank && guild.guildBank && guild.guildBank.address) {
      const leaveRequestTx = await TBMConnector.leaveGuild(wallet, toBech32Address(guild.guildBank.address));

      if (leaveRequestTx?.id) {
        logger('test', 'handleLeaveGuild', {
          tx: getExplorerLink("tx", leaveRequestTx?.id, network),
        })
        try {
          await waitForTx(leaveRequestTx.id);
          toaster(`Successfully Leave Guild request`);
        } catch (e) {
          console.error(e);
          toaster(`Unsuccessful Leave Guild request`);
          throw e;
        }
      }
    }
    else {
      const metazoaClient = new MetazoaClient(network);

      let checkedOAuth: OAuth | undefined = oAuth;
      if (!oAuth?.access_token || oAuth.address !== wallet.addressInfo.bech32 || (oAuth && dayjs(oAuth?.expires_at * 1000).isBefore(dayjs()))) {
        const { result } = await metazoaClient.metazoaLogin(wallet, window.location.hostname);
        dispatch(actions.Profile.updateAccessToken(result));
        checkedOAuth = result;
      }

      await metazoaClient.leaveGuild(guild.id, checkedOAuth!);
    }

    dispatch(actions.Profile.loadProfile());
    dispatch(actions.Guild.reloadGuild(guild));
    setShowLeaveDialog(false)
  }

  // EVENT HANDLERS -----------------

  const handleLeaveGuild = () => {
    if (loadingLeaveGuild) return;

    runLeaveGuild(async () => {
      await requestLeaveGuild();
    })
  }

  // --------------------------------

  if (!walletHolder.canLeaveGuild) return null;

  return (
    <>
      <Button size="small" variant={walletHolder.isOfficer ? "outlined" : "contained"} color="secondary"
        sx={walletHolder.isOfficer ? styles.rankerRequestButton : styles.requestButton}
        onClick={() => setShowLeaveDialog(true)}
        disabled={loadingLeaveGuild}
        disableFocusRipple
      >
        {loadingLeaveGuild
          ? <CircularProgress size={18} />
          : "Leave Guild"
        }
      </Button>
      {/* </ContainedButton> */}
      <DialogModal header={"Leave guild?"} open={showLeaveDialog} onClick={() => setShowLeaveDialog(false)} sx={styles.dialogModal} disableScrollLock={true}>
        <Box sx={styles.dialogContent}>
          <Typography sx={styles.warningText}>
            There&apos;s no turning back once you leave a guild!
            You will have to send a request to join again.
          </Typography>

          <ContainedButton
            onClick={handleLeaveGuild}
            sx={styles.confirmButton}
          >Leave guild
          </ContainedButton>
        </Box>
      </DialogModal>
    </ >
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  dialogContent: {
    maxWidth: "600px",
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "center",
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
    margin: "40px 0",
  },
  confirmButton: {
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },

  rankerRequestButton: {
    borderRadius: "16px",
    textTransform: "uppercase",


    height: 48,
    minWidth: 280,
    "&.MuiButton-root": {
      fontSize: "18px",
      lineHeight: "28px",
      whiteSpace: "nowrap",
    },
    "& .MuiBox-root": {
      display: "none",
    },
    "@media (max-width:600px)": {
      height: 40,
      "&.MuiButton-root": {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },

  requestButton: {
    height: 48,
    minWidth: 280,
    "&.MuiButton-root": {
      fontSize: "18px",
      lineHeight: "28px",
      whiteSpace: "nowrap",
    },
    "& .MuiBox-root": {
      display: "none",
    },
    "@media (max-width:600px)": {
      height: 40,
      "&.MuiButton-root": {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  }
}

export default LeaveRequestButton;
