import React, { Fragment, useState } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { Huny } from "assets";
import BoxWithHeader from "components/BoxWithHeader";
import ContainedButton from "components/ContainedButton";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";
import { DisplayStaked, ReturnDialog } from "./components";

const LostPlanet: React.FC = () => {
  const [showReturnDialog, setShowReturnDialog] = useState<boolean>(false);
  const tokenState = useRedux((state) => state.token);
  const trappedMetazoa = Object.values(tokenState.trappedMetazoa);

  const handleShowReturnDialog = () => {
    setShowReturnDialog(true);
  }

  return(
    trappedMetazoa.length === 0 ? null :
    <Fragment>
      <Box sx={styles.stakeContainer}>
        {/* In battle */}
        <BoxWithHeader
          headerText={
            <Typography variant="h3" color="primary" component="span">
              DOOMED PLANET
              <Typography variant="body1" color="secondary" component="span">&nbsp;&nbsp;&bull;&nbsp;&nbsp;⚠️ Fighting in Imploding Battlefield</Typography>
            </Typography>
          }
          componentsRight={
            <TooltipZolar sx={styles.tooltip}>
              A cataclysmic event on this planet's core will result in its irreversible destruction soon! Minos are currenty too distracted to kidnap or steal.
              Pull out your battling Metazoas immediately and continue the fight elsewhere. HUNY supplies at this location runs out in 24 hours!
            </TooltipZolar>
          }
          sx={styles.stakeBox}
          showBackground={!trappedMetazoa.length}
        >
          <DisplayStaked metazoaTokens={trappedMetazoa}/>
          <ContainedButton sx={styles.button} onClick={handleShowReturnDialog} disabled={!trappedMetazoa.length}>
            <Typography variant="button" component="span">CLAIM <Huny width="24px" height="24px"/> AND RETREAT</Typography>
          </ContainedButton>
        </BoxWithHeader>
      </Box>

      <ReturnDialog
        open={showReturnDialog}
        onClose={() => setShowReturnDialog(false)}
      />
    </Fragment>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  stakeContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
    columnGap: '10px',
    "@media (max-width:1040px)": {
      flexDirection: 'column',
      rowGap: '10px',
    },
  },
  stakeBox: {
    flex: "1 1 0",
  },
  tooltip: {
    padding: '20px',
    width: '420px',
  },
  button: {
    height: 60,
    marginTop: '40px',
    width: '100%',
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  }
}

export default LostPlanet;
