import { EventLogEntry } from "@zilliqa-js/core";
import { fromBech32Address, MessageType, NewTxBlockSubscription, Subscription } from "@zilliqa-js/zilliqa";
import { logger } from "core/utilities";
import { ContractsBech32, WsUri } from "utils/constants";
import { SimpleMap } from "utils/types";
import { Network } from "zilswap-sdk/lib/constants";


type SubscriptionEventLog = Omit<EventLogEntry, "address">;
interface TxEventLogs {
  address: string;
  event_logs: SubscriptionEventLog[];
}

export class ZolarEventSubscriber {
  static subscription: Subscription | null = null;
  private static listeners: SimpleMap<ZolarEventSubscriber.Listener> = {};
  private static listenerId = 0;

  static contractsToWatch(network: Network) {
    const lostPlanet = fromBech32Address(ContractsBech32[network].LostPlanet);
    const moonBattle = fromBech32Address(ContractsBech32[network].MoonBattle);
    const gemRefinery = fromBech32Address(ContractsBech32[network].GemRefinery);

    return [lostPlanet, moonBattle, gemRefinery]
  }

  static parseEvent(event: SubscriptionEventLog): ZolarEventSubscriber.ZolarEvent {
    const params: SimpleMap<any> = {};
    for (const eventParam of event.params) {
      params[eventParam.vname] = eventParam.value;
    }
    return {
      type: event._eventname as ZolarEventSubscriber.ZolarEventType,
      params,
    }
  }

  static subscribe(onTxEvents: ZolarEventSubscriber.ZolarEventHandler) {
    const listenerId = this.addListener({ onTxEvents });
    return () => this.unsubscribe(listenerId);
  }
  static unsubscribe(listenerId: string) {
    delete this.listeners[listenerId];
  }
  static addListener(listener: ZolarEventSubscriber.Listener) {
    logger("zolar event", "new listener");
    const listenerId = (++this.listenerId).toString();
    this.listeners[listenerId] = listener;
    return listenerId;
  }
  static clearListeners() {
    for (const key in this.listeners)
      this.unsubscribe(key);
  }

  private static onEvent(txEventLogs: TxEventLogs) {
    try {
      const events = txEventLogs.event_logs.map(this.parseEvent);
      for (const listener of Object.values(this.listeners)) {
        try {
          logger("zolar event", events);
          listener.onTxEvents(events);
        } catch (error) {
          console.error("listener threw error");
          console.error(error);
        }
      }
    } catch (error) {
      console.error("could not process chain event");
      console.error(txEventLogs);
      console.error(error);
    }
  }

  static initialize = async (network: Network, url?: string) => {
    this.teardown();

    if (!url) url = WsUri[network];

    const subscription = new NewTxBlockSubscription(url);

    const addresses = this.contractsToWatch(network);
    logger("zolar event", "watching addresses", addresses);
    subscription.subscribe({ query: MessageType.EVENT_LOG, addresses });
    subscription.emitter.on(MessageType.EVENT_LOG, (event) => {
      if (!event.value) return; // block event triggering event log, empty body.
      for (const eventLog of event.value)
        this.onEvent(eventLog);
    });

    logger("zolar event subscription initalized", addresses);
    this.subscription = subscription;
  }

  static async teardown() {
    if (this.subscription) {
      logger("zolar event disconnecting subscription");
      await this.subscription.stop();
      this.subscription = null;
    }
  }
}

export namespace ZolarEventSubscriber {
  export enum ZolarEventType {
    MetazoaKidnapped = "MetazoaKidnapped",
    HunyCaptured = "HunyCaptured",
    HunyStolen = "HunyStolen",
    RefineryClaimed = "RefineryClaimed",
    ActionStarted = "ActionStarted",
    ActionConcluded = "ActionConcluded",
    RefinementStarted = "RefinementStarted",
    RefinementConcluded = "RefinementConcluded",
  }
  export interface ZolarEvent {
    type: ZolarEventType;
    params: SimpleMap<any>;
  }
  export interface ZolarEventHandler {
    (txEvents: ZolarEvent[]): void | Promise<void>;
  }
  export interface Listener {
    onTxEvents: ZolarEventHandler;
  }
}
