import { Box, BoxProps, Button, CircularProgress, SxProps, Typography } from "@mui/material";
import { DialogModalProps } from "components/DialogModal/DialogModal";
import { PROFESSIONS, STAT_PROFESSION } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { QuestConstants } from "components/Quest";
import { LocationAsset } from "components/Quest/QuestConstants";
import { FC, useState } from "react";
import { NftMetadata } from "store/types";
import { useTaskSubscriber } from "utils";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import TokenViewDialog from "./TokenViewDialog";

type DisplayTokensProps = {
  tokens: NftMetadata[];
  locationAsset?: LocationAsset;
  modalProps?: Omit<DialogModalProps, "open" | "onClose">;
} & BoxProps;

export type QuestTokens = {
  tokens: NftMetadata[];
  maxViewable?: number;
}

export type QuestTokenViewProps = QuestTokens & BoxProps & {}

export const QuestTokenView: FC<QuestTokenViewProps> = ({
  tokens,
  maxViewable,
  children,
  ...rest
}: QuestTokenViewProps) => {
  return (
    <Box
      {...rest}
      component="section"
      sx={combineStyles(
        styles.tokensWrapper,
        rest.sx
      )}
    >
      {!!tokens.length && tokens.slice(0, maxViewable).map((token: NftMetadata) => (
        <Box key={token.id} sx={styles.metazoaWrapper}>
          {!!token.profession && (
            <Box
              component="img"
              src={PROFESSIONS[STAT_PROFESSION[token.profession]].icon}
              onError={QuestConstants.DEFAULT_SRC}
              sx={styles.professionIcon}
            />
          )}
          <Box
            component="img"
            src={token.image}
            alt={`ZOA #${token.id}`}
            sx={styles.zoaImage}
            onError={QuestConstants.DEFAULT_SRC}
          />
          <Typography variant="body1" color="primary" sx={styles.tokenID}>{token.id}</Typography>
        </Box>
      ))}
      {children}
    </Box>
  )
}


const TokenView = ({
  tokens,
  locationAsset,
  modalProps,
  children,
  ...rest
}: DisplayTokensProps) => {

  const maxViewable = 12;
  const unviewableCount: number = tokens.length > maxViewable ? tokens.length - maxViewable : 0;

  // STATES / HOOKS -----------------

  const [loadingTokens] = useTaskSubscriber("updateTokens");
  const [openDialog, setOpenDialog] = useState<boolean>(false);


  // FUNCTIONS ----------------------

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  // EVENT HANDLERS -----------------

  // --------------------------------

  if (!loadingTokens && tokens.length <= 0) return null;
  return (
    <Box
      {...rest}
      sx={combineStyles(styles.viewContainer, rest.sx,)}>
      {loadingTokens && (
        <Box sx={styles.progressContainer}>
          <CircularProgress size={50} />
        </Box>
      )}

      {!loadingTokens && tokens.length > 0 && (
        <QuestTokenView tokens={tokens} maxViewable={maxViewable} >
          {!!tokens.length && unviewableCount > 0 && (
            <>
              <Button
                variant="outlined"
                color="secondary"
                sx={styles.gradientButton}
                onClick={handleOpenDialog}
              >
                <Typography sx={styles.gradientButtonText}>
                  See all ({unviewableCount})
                </Typography>
              </Button>

              <TokenViewDialog
                tokens={tokens}
                open={openDialog}
                onClose={handleCloseDialog}
                {...modalProps}
              />
            </>
          )}

        </QuestTokenView>

        // <Box component="section" sx={styles.tokensWrapper}>
        //   {tokens.slice(0, maxViewable).map((token: NftMetadata) => (
        //     <Box key={token.id} sx={styles.metazoaWrapper}>
        //       {!!token.profession && (
        //         <Box
        //           component="img"
        //           src={PROFESSIONS[STAT_PROFESSION[token.profession]].icon}
        //           height="20px"
        //           width="20px"
        //           onError={QuestConstants.DEFAULT_SRC}
        //           sx={styles.professionIcon}
        //         />
        //       )}
        //       <Box
        //         component="img"
        //         src={token.image}
        //         alt={`ZOA #${token.id}`}
        //         sx={styles.zoaImage}
        //         onError={QuestConstants.DEFAULT_SRC}
        //       />
        //       <Typography variant="body1" color="primary" sx={styles.tokenID}>{token.id}</Typography>
        //     </Box>
        //   ))}

        //   <Button
        //     variant="outlined"
        //     color="secondary"
        //     sx={styles.gradientButton}
        //   // onClick={handleShowRemove}
        //   >
        //     <Typography sx={styles.gradientButtonText}>
        //       See all ({unviewableCount})
        //     </Typography>
        //   </Button>
        // </Box>
      )}
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  viewContainer: {
    width: '100%',
    height: '100%',
    flex: 1,
  },
  tokensWrapper: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    overflowY: 'auto',

    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    placeContent: 'start',
    placeItems: 'center',
    gap: '0.6em',
    paddingY: '10px',

    "> *": {
      flexBasis: '10.5%',
    },

    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  metazoaWrapper: {
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    placeItems: 'center',
    position: 'relative',
  },
  zoaImage: {
    height: '80%',
    width: '90%',
    minHeight: '45px',
    minWidth: '45px',
    margin: '2.5px 2.5px 0 2.5px',
    borderRadius: '14px 14px 0px 0px',
  },
  professionIcon: {
    position: 'absolute',
    right: '-10%',
    top: '-8%',
    minHeight: "20px",
    minWidth: "20px",
    maxWidth: '40%',
  },
  tokenID: {
    borderTop: '2px solid #AEF1EE33',
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    textAlign: 'center',
    marginBottom: '1px',
  },
  gradientButton: {
    flexBasis: 'fit-content',
    fontSize: '18px',
    height: 48,
    minWidth: '100px',
    padding: '14px 10px',
    fontWeight: 700,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
  gradientButtonText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },
}

export default TokenView;