import { useState, useLayoutEffect, RefObject } from 'react';

const useIsOverflow = (ref: RefObject<any>, isHorizontal: boolean = false, callback?: (hasOverflow: boolean) => void,): boolean => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = !isHorizontal ? current.scrollHeight > current.clientHeight : current.scrollWidth > current.clientWidth;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      if ('ResizeObserver' in window) new ResizeObserver(trigger).observe(current);
      trigger();
    }
    // eslint-disable-next-line
  }, [ref]);

  return isOverflow;
};

export default useIsOverflow;