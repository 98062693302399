import { Box, Container, SxProps, Typography } from "@mui/material";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import { GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { MetazoaLayout } from "layout";
import ScrollToTop from "utils/ScrollToTop";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";
import MiniGame from "../../assets/Minigame.png";
import ConnectedWallet from "./ConnectedWallet";

const Metazoa: React.FC = () => {
  const wallet = useRedux((state) => state.wallet.wallet);
  return (
    <MetazoaLayout>
      <ScrollToTop />
      <Container maxWidth="xl" sx={styles.container}>
        <Box sx={styles.contentRow}>
          <Box width={{ xs: "100%", sm: "100%", md: "70%" }}>
            <Box width={{ xs: "100%", md: "100%", lg: "80%" }}>
              <Typography variant="h2" color="primary" sx={styles.header}>
                Meet your Metazoa!
              </Typography>
            </Box>

            {!wallet && <ConnectWalletButtonCTA />}
          </Box>

          {!wallet && (
            <Box
              width={{ xs: "100%", sm: "100%", md: "25%" }}
              sx={styles.minigameBox}
            >
              <img src={MiniGame} alt="mini-game" width="100%" height="100%" />
            </Box>
          )}
        </Box>
        {!!wallet && <ConnectedWallet />}

      </Container>
    </MetazoaLayout>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    position: "relative",
    padding: "0px 0px 100px",
    minHeight: "1600px",
  },
  contentRow: {
    padding: "160px 100px 0px",
    marginBottom: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    zIndex: 1,
    "@media (max-width:900px)": {
      padding: "160px 60px 80px",
      flexDirection: "column",
    },
    "@media (max-width:600px)": {
      padding: "130px 24px 40px",
    },
  },
  header: {
    ...GUILD_GRAY_GRADIENT,
    "@media (max-width:900px)": {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  textbody: {
    margin: "48px 0px",
    "@media (max-width:900px)": {
      margin: "24px 0px 48px",
    },
  },
  minigameBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "20px",
    "@media (max-width:900px)": {
      marginLeft: "0px",
      width: "100%",
    },
    position: "relative",
  },
};

export default Metazoa;
