import { Box, Button, CircularProgress, Divider, Link, styled, SvgIcon, SxProps, Typography } from "@mui/material";
import { BaseButton, DefaultUserProfile, ExternalLink, Huny } from "assets";
import HunyLoading from "assets/HunyLoading.gif";
import BigNumber from "bignumber.js";
import BoxWithHeader from "components/BoxWithHeader";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import WarningBox from "components/WarningBox";
import { waitForTx } from "core/utilities";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { actions } from "store";
import { TBMConnector } from "tbm";
import { useAsyncTask, useToaster } from "utils";
import { BIG_ZERO, BLOCKS_PER_MINUTE, Decimals } from "utils/constants";
import { getExplorerLink } from "utils/strings/links";
import { bnOrZero, formatIntegerNumber } from "utils/strings/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import useRedux from "utils/useRedux";


const YourShare: React.FC = () => {
  const dispatch = useDispatch();
  const hivePool = useRedux((state) => state.token.hivePool);
  const hiveInfo = useRedux((state) => state.token.hiveInfo);
  const rates = useRedux((state) => state.token.ExchangeRates);
  const wallet = useRedux((state) => state.wallet.wallet);
  const network = useNetwork();
  const toaster = useToaster();
  const [claimTxHash, setClaimTxHash] = useState<string>("");
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [claimHiveComplete, setClaimHiveComplete] = useState<boolean>(false);
  const [runClaimHive, loadingClaim] = useAsyncTask("claimHive", (error) => {
    toaster(error?.message ?? "Error Claiming");
  });

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        const newBlock = TBMConnector.getCurrentBlock();
        if (newBlock !== currentBlock) {
          setCurrentBlock(newBlock)
        }
      } catch (e) {
        console.warn('Failed to get current block. Will try again in 1s. Error:')
        console.warn(e)
      }
    }, 1000);
    return () => clearInterval(interval)
  }, [network, currentBlock])

  const totalValue = useMemo(() => {
    const zilValue = hivePool?.userZilReserves.shiftedBy(-Decimals.ZIL).times(rates.zilPrice);
    const hunyValue = hivePool?.userHunyReserves.shiftedBy(-Decimals.HUNY).times(rates.hunyPrice);
    return hunyValue?.plus(zilValue ?? BIG_ZERO);
  }, [rates, hivePool]);

  const hiveRewardsPercentage = useMemo(() => {
    if (!hivePool?.lastSignificantDeposit) return 0;
    const blocksStaked = currentBlock - hivePool?.lastSignificantDeposit
    const daysElapsed = Math.floor(blocksStaked / (BLOCKS_PER_MINUTE * 60 * 24));
    if (daysElapsed >= 240) return 0;
    else return 80 - Math.floor(daysElapsed / 3);
  }, [currentBlock, hivePool])

  const { rewardHunyAmt, rewardValue } = useMemo(() => {
    if (!hiveInfo?.hunyRewardsPerShare || !hivePool?.userBalance) return {};

    let pendingRewardsPerShare = new BigNumber(0)
    if (currentBlock !== 0) {
      const pendingBlocks = currentBlock - hiveInfo.lastRewardBlock
      const pendingKickbacks = bnOrZero(hiveInfo?.incomingKickbacks)
      const pendingRewards = new BigNumber(36750000).dividedBy(2300 * 180).times(pendingBlocks).shiftedBy(Decimals.HUNY).plus(pendingKickbacks)
      pendingRewardsPerShare = pendingRewards.times(1e12).dividedToIntegerBy(hiveInfo.totalShare)
    }

    // reward per share * share / 1e12 - debt
    const userBalance = hivePool.userBalance.shiftedBy(-12);
    const userShare = userBalance.times(hiveInfo!.hunyRewardsPerShare.plus(pendingRewardsPerShare));
    const rewards = BigNumber.max(userShare.minus(hivePool.userDebt), BIG_ZERO).dp(0).shiftedBy(-Decimals.HUNY);

    return {
      rewardHunyAmt: rewards,
      rewardValue: rewards.times(rates.hunyPrice)
    }
  }, [currentBlock, hivePool, hiveInfo, rates.hunyPrice]);

  const handleClaim = () => {
    runClaimHive(async () => {
      if (!wallet) throw new Error("Wallet not connected");
      try {
        const claim = await TBMConnector.claimHive(wallet);
        if (claim.isRejected()) {
          throw new Error("Submitted transaction was rejected.");
        }
        if (claim?.id) {
          toaster(`Submitted claim `, { hash: claim?.id!, overridePersist: true });
          setClaimTxHash(claim.id);
          try {
            await waitForTx(claim.id);
            setClaimHiveComplete(true);
          } catch (e) {
            console.error(e);
            throw e;
          }
        }

        dispatch(actions.Token.reloadHivePool());
      } catch (e) {
        console.error(e);
        throw e;
      }
    })
  }

  const getHeader = () => {
    if (claimHiveComplete) {
      return "Claim Huny Success!"
    }
    if (loadingClaim) {
      return "Claiming Huny"
    }
    return "Claim Huny";
  }

  const dialogContent = () => {
    if (claimHiveComplete) {
      return (
        <Fragment>
          <Box sx={styles.contentBox} style={{ marginTop: 0 }}>
            {/* Qty */}
            <Box display="flex" alignItems="center" mt="20px">
              <Typography color="primary" variant="body1" sx={{ whiteSpace: 'nowrap' }}>Some of your unrefined huny has been sent to the refinery!</Typography>
            </Box>

            {/* Tx hash */}
            <Link
              target="_blank"
              href={getExplorerLink("tx", claimTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>

            {/* Visit refinery */}
            <Button
              component={RouterLink}
              variant="contained"
              color="secondary"
              sx={styles.refineryButton}
              to="/refinery"
            >
              <BaseButtonBox>
                <BaseButton height="100%" />
              </BaseButtonBox>
              Visit Refinery
            </Button>
          </Box>
        </Fragment>
      );
    }
    if (loadingClaim) {
      return (
        <Fragment>
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={HunyLoading}
            alt={"loading gif"}
          />
        </Fragment>
      );
    }
    return <Box sx={styles.contentContainer}>
      <WarningBox sx={styles.warningBoxModal}>
        <Typography variant="body1" color="primary" align="left">
          Whenever you claim HUNY from the Magic Hive, it only yields you some of the accrued HUNY immediately,
          and the remaining needs to be sent to the Refinery for further processing.
          {" "}
          <Link
            target="_blank"
            href="https://docs.zolar.io/stations/magic-hive#claiming-usdhuny-rewards"
          >
            Learn More
          </Link>
        </Typography>
      </WarningBox>
      <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" sx={styles.row}>
        <Box sx={styles.textGroup}>
          <Typography variant="body1" color="primary">Total HUNY Rewards (100%)</Typography>
        </Box>
        <Box sx={styles.textGroup}>
          <Typography variant="h3" color="primary" sx={styles.hunyAmount}>{rewardHunyAmt?.toNumber().toFixed(4)}</Typography>
          <Huny height="24px" width="24px" />
        </Box>
      </Box>
      <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" sx={styles.row}>
        <Box sx={styles.textGroup}>
          <Typography variant="body1" color="primary">HUNY to Refinery ({hiveRewardsPercentage}%)</Typography>
        </Box>
        <Box sx={styles.textGroup}>
          <Typography variant="h3" color="primary" sx={styles.hunyAmount}>- {rewardHunyAmt?.times(hiveRewardsPercentage / 100).toNumber().toFixed(4)}</Typography>
          <Huny height="24px" width="24px" />
        </Box>
      </Box>
      <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" sx={styles.row}>
        <Box sx={styles.textGroup}>
          <Typography variant="body1" color="primary">Estimated receive now ({100 - hiveRewardsPercentage}%)</Typography>
        </Box>
        <Box sx={styles.textGroup}>
          <Typography variant="h3" color="success.main" sx={styles.hunyAmount}>{rewardHunyAmt?.times((100 - hiveRewardsPercentage) / 100).toNumber().toFixed(4)}</Typography>
          <Huny height="24px" width="24px" />
        </Box>
      </Box>
      <ContainedButton
        sx={styles.button}
        onClick={handleClaim}
        disabled={rewardHunyAmt?.eq(0)}
      >
        Confirm &amp; Claim
      </ContainedButton>
    </Box>
      ;
  }

  const handleOnClose = () => {
    if (loadingClaim) return;

    setClaimTxHash("");
    setClaimHiveComplete(false);
    setOpenDialog(false);
  }

  return (
    <BoxWithHeader
      headerText={<Typography variant="h3" color="primary" sx={styles.headerText}>YOUR SHARE</Typography>}
      componentsRight={<Typography variant="h3" color="secondary">{hivePool?.userShare.shiftedBy(2).toFormat(2)}%</Typography>}
      sx={styles.container}
    >
      <Box sx={styles.shareWrapper}>
        <Box sx={styles.userProfileWrapper}>
          <Box sx={styles.userProfilePic}>
            <DefaultUserProfile width="100%" height="100%" />
          </Box>
        </Box>
        <HiveTVLDivider textAlign="left" sx={styles.hiveTVLDivider}>
          <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>liquidity</Typography>
        </HiveTVLDivider>
        <Typography variant="h2" color="primary" sx={styles.bodyHeader}>${totalValue?.toFormat(0)}</Typography>
        <Typography variant="body1" color="secondary" sx={styles.bodyText}>
          {formatIntegerNumber(hivePool?.userHunyReserves.shiftedBy(-Decimals.HUNY))} HUNY
          {" + "}
          {formatIntegerNumber(hivePool?.userZilReserves.shiftedBy(-Decimals.ZIL))} ZIL
        </Typography>
        <HiveTVLDivider textAlign="left" sx={styles.hiveTVLDivider}>
          <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>Pending Reward</Typography>
        </HiveTVLDivider>
        <Box sx={styles.claimRow}>
          <Box sx={styles.claimTextGroup}>
            <Typography variant="h2" color="primary" sx={styles.bodyHeader}>${formatIntegerNumber(rewardValue)}</Typography>
            <Typography variant="body1" color="secondary" sx={styles.bodyText}>{formatIntegerNumber(rewardHunyAmt)} HUNY</Typography>
          </Box>
          <ContainedButton disabled={!hivePool || rewardHunyAmt?.eq(0)} sx={styles.button} onClick={() => setOpenDialog(true)}>
            {loadingClaim
              ? <CircularProgress size={18} />
              : "claim"
            }
          </ContainedButton>
        </Box>
      </Box>
      <DialogModal header={getHeader()} open={openDialog} onClose={handleOnClose} sx={styles.dialogModal}>
        {dialogContent()}
      </DialogModal>
    </BoxWithHeader>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    marginBottom: '10px',
    "@media (max-width:1200px)": {
      width: '49%',
    },
  },
  headerText: {
    "@media (max-width:1000px)": {
      fontSize: '1rem',
    },
  },
  shareWrapper: {
    padding: '20px',
    "@media (max-width:1000px)": {
      padding: '0px',
    },
  },
  userProfileWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    padding: '20px',
    "@media (max-width:1000px)": {
      display: 'none',
    },
  },
  userProfilePic: {
    width: '180px',
    height: '180px',
  },
  hiveTVLDivider: {
    paddingBottom: '10px',
    "@media (max-width:1000px)": {
      paddingBottom: '5px',
    },
  },
  overlineSmall: {
    fontSize: '0.75rem',
  },
  bodyHeader: {
    paddingLeft: '10px',
    "@media (max-width:1000px)": {
      marginBottom: '10px',
    },
  },
  bodyText: {
    fontSize: '1.5rem',
    marginBottom: '30px',
    paddingLeft: '10px',
    "@media (max-width:1000px)": {
      marginBottom: '10px',
    },
  },
  claimRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    "@media (max-width:900px)": {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  claimTextGroup: {
    display: 'flex',
    flexDirection: 'column',
    "@media (max-width:900px)": {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  button: {
    height: '64px',
    minWidth: '160px',
    marginRight: '5px',
    marginBottom: '20px',
    "@media (max-width:1000px)": {
      marginBottom: '10px',
    },
  },

  //dialog
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
  },

  textGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hunyAmount: {
    marginRight: '10px',
  },

  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },

  warningBoxModal: {
    marginBottom: '24px',
  },

  warningBox: {
    width: '100%',
    padding: '20px',
    paddingRight: '40px',
    borderRadius: '16px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  warningTextWrapper: {
    width: '93%',
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  refineryButton: {
    marginTop: "10px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "40px",
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
};

const HiveTVLDivider = styled(Divider)({
  '&.MuiDivider-root::after': {
    borderTopColor: '#aef1ee',
  },
  '&.MuiDivider-root::before': {
    borderTopColor: '#aef1ee',
    width: '0%',
  },
});

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    display: "none",
  },
});

export default YourShare;
