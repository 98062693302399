import BigNumber from "bignumber.js";
import { logger } from "core/utilities";
import { NftMetadata, Quest } from "store/types";
import { BIG_ONE, BIG_ZERO, Decimals, QUEST_EPOCH } from "utils/constants";
import { bnOrZero, toHumanNumber } from "utils/strings";
import { Network } from "zilswap-sdk/lib/constants";

export const calculateHarvestFee = (questLocation: Quest, currentBlock: number, token: NftMetadata, network: Network) => {
  // if (!token.stats) return BIG_ZERO;
  if (!token.lastHarvested) return BIG_ZERO;
  const waiveHarvestPercentage = bnOrZero(questLocation.percentageWaived).shiftedBy(-4);
  const harvestFeePerEpoch = questLocation.harvestFeePerEpoch;
  const epochToWaive = questLocation.epochsToWaiveHarvest;
  const blockDifference = currentBlock - token.lastHarvested;
  const numberOfEpochs = Math.floor(blockDifference / QUEST_EPOCH[network]);

  const initX = bnOrZero(numberOfEpochs).pow(2);
  const initY = bnOrZero(epochToWaive).pow(2);
  const initFee = bnOrZero(harvestFeePerEpoch).times(numberOfEpochs).shiftedBy(-Decimals.HUNY);

  let finalFeeDiscount: BigNumber = initFee.times(initX.div(initY.multipliedBy(waiveHarvestPercentage)));
  finalFeeDiscount = !finalFeeDiscount.isFinite() ? BIG_ZERO : finalFeeDiscount;

  const finalFeeToCharge = BigNumber.max(initFee.minus(finalFeeDiscount), 0);
  const accDiscountPercentage = bnOrZero(token.contractBonus?.contractAccBonus).shiftedBy(-2);

  logger("debug-calculateHarvestFee", token.id, {
    waiveHarvestPercentage: toHumanNumber(waiveHarvestPercentage),
    accDiscountPercentage: toHumanNumber(BIG_ONE.minus(accDiscountPercentage)),
    finalFeeDiscount: toHumanNumber(finalFeeDiscount),
    finalFeeToCharge: toHumanNumber(finalFeeToCharge),
    numberOfEpochs,
    initY: toHumanNumber(initY),
    initX: toHumanNumber(initX),
    harvestFeePerEpoch: toHumanNumber(harvestFeePerEpoch),
    initFee: toHumanNumber(initFee.times(BIG_ONE.minus(waiveHarvestPercentage))),

    fee: toHumanNumber(new BigNumber(200).times(BIG_ONE.minus(accDiscountPercentage)).times(numberOfEpochs))
  })
  // return BigNumber.max(bnOrZero(finalFeeToCharge).times(BIG_ONE.minus(accDiscountPercentage)), BIG_ZERO);
  return BigNumber.max(bnOrZero(initFee.times(BIG_ONE.minus(waiveHarvestPercentage))), BIG_ZERO);
};
