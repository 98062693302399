import { TBMConnector } from "tbm";
import { Network } from "zilswap-sdk/lib/constants";

export const waitForConnectorInit = async () => {
  while (true) {
    if (TBMConnector.initialized())
      return;
    await new Promise(resolve => setTimeout(resolve, 100));
  }
};


export const waitForNetworkChange = async (network?: Network) => {
  while (true) {
    const currentNetwork = TBMConnector.network();
    if (currentNetwork && currentNetwork !== network)
      return currentNetwork;
    await new Promise(resolve => setTimeout(resolve, 100));
  }
};
