import { Box, Button, styled, SxProps, Typography } from "@mui/material";
import BannerFrame from "assets/BannerFrame.svg";
import MinoDivider from "assets/BannerFrameMinoDivider.svg";
import PriceDivider from "assets/BannerFramePriceDivider.svg";
import UrsaDivider from "assets/BannerFrameUrsaDivider.svg";
import UrsaVanguard from "assets/BannerFrameUrsaVanguard.svg";
import MinoIcon from "assets/icons/Minos.svg";
import UrsaIcon from "assets/icons/Ursas.svg";
import BigNumber from "bignumber.js";
import { useEffect, useMemo, useState } from "react";
import { useRedux } from "utils";
import { bnOrZero, formatIntegerNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import { MissionBlue, MissionRed, PinkShootingStar, Smoke1a } from "../../../assets";

const ExploreBanner: React.FC = () => {
  const commanders = useRedux(state => state.game.commanders);
  const tokenTraits = useRedux(state => state.game.tokenTraits);
  const [floorPrice, setFloorPrice] = useState<BigNumber>(new BigNumber(0));

  useEffect(() => {
    fetch('https://api-ark.zilswap.org/nft/collection/0xf79a456a5afd412d3890e2232f6205f664be8957/detail')
      .then((response) => response.json())
      .then((data) => setFloorPrice(bnOrZero(data.result.model.priceStat.floorPrice)))
  }, [])

  const {
    ursaCount,
    minoCount,
  } = useMemo(() => {
    const counts = {
      ursa: 0, mino: 0,
    };
    if (!tokenTraits || !commanders) return {};

    for (const traits of Object.values(tokenTraits)) {
      if (traits?.faction_a === "mino")
        counts.mino++;
      else
        counts.ursa++;
    }

    return {
      ursaCount: new BigNumber(counts.ursa),
      minoCount: new BigNumber(counts.mino),
    }
  }, [tokenTraits, commanders]);
  return (
    <Box sx={styles.bannerWrapper}>
      <Box sx={styles.container}>
        <SvgBoxWrapper>
          <SvgBox>
            <BlueEllipseBox>
              <MissionBlue />
            </BlueEllipseBox>
            <RedEllipseBox>
              <MissionRed />
            </RedEllipseBox>
            <Smoke1aBox>
              <Smoke1a width="250px" />
            </Smoke1aBox>
            <PinkShootingBox>
              <PinkShootingStar width="100%" />
            </PinkShootingBox>
          </SvgBox>
        </SvgBoxWrapper>
        <Typography variant="h2" color="primary" sx={styles.header}>
          Meet your Metazoa!
        </Typography>
        <Box sx={styles.bannerFrame}>
          <Box sx={styles.bannerRow}>
            <Box width="22%" sx={styles.bannerBox}>
              <Box sx={styles.textRow}>
                <Box component="img" src={UrsaIcon} mr="10px" sx={styles.icon} />
                <Typography variant="h3" color="secondary" sx={styles.textLabel}>Ursa</Typography>
              </Box>
              <Box component="img" src={UrsaDivider} sx={styles.dividers} />
              <Typography variant="h1" sx={styles.gradientText}>{formatIntegerNumber(ursaCount) ?? 0}</Typography>
            </Box>
            <Box width="22%" sx={styles.bannerBox}>
              <Box sx={styles.textRow}>
                <Box component="img" src={MinoIcon} mr="10px" sx={styles.icon} />
                <Typography variant="h3" color="warning.main" sx={styles.textLabel}>Mino</Typography>
              </Box>
              <Box component="img" src={MinoDivider} sx={styles.dividers} />
              <Typography variant="h1" sx={combineStyles(styles.gradientText, styles.minoGradient)}>{formatIntegerNumber(minoCount) ?? 0}</Typography>
            </Box>
            <Box width="26%" sx={styles.bannerBox}>
              <Box sx={combineStyles(styles.textRow, { justifyContent: 'space-between' })}>
                <Typography variant="h3" color="primary" sx={styles.textLabel}>Floor Price</Typography>
                <Button sx={styles.arkyButton} href="https://zilswap.io/arky/collections/zil177dy26j6l4qj6wysug3j7cs97ejtaz2h06vfwa?search=&sortBy=price&sortDir=asc" target="_blank">
                  <Typography variant="body1" sx={styles.buttonGradient}>BUY ON ARKY</Typography>
                </Button>
              </Box>
              <Box component="img" src={PriceDivider} sx={styles.dividers} />
              <Typography variant="h1" color="primary" component="span">
                {formatIntegerNumber(floorPrice.shiftedBy(-12))}&nbsp;
                <Typography variant="h3" component="span" sx={combineStyles(styles.gradientText, styles.zilGradient)}>ZIL</Typography>
              </Typography>
            </Box>
            <Box width="30%" sx={styles.metazoaBox}>
              <Box component="img" src={UrsaVanguard} sx={styles.metazoaImage} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  bannerWrapper: {
    "@media (max-width:1440px)": {
      overflowX: 'auto',
    },
  },
  container: {
    width: '1320px',
    height: '479px',
    position: 'relative',
    paddingTop: '100px',
    paddingX: '40px',
    paddingBottom: '40px',
    overflowY: 'hidden',
  },
  header: {
    fontWeight: 700,
    "@media (max-width:900px)": {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  bannerFrame: {
    marginTop: '40px',
    marginX: 'auto',
    maxWidth: '1200px',
    height: '264px',
    backgroundImage: `url(${BannerFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
  },
  bannerRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  bannerBox: {
    paddingLeft: '30px',
    paddingRight: '40px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  textRow: {
    display: 'flex',
    alignItems: 'center',
  },
  textLabel: {
    fontSize: '18px',
  },
  arkyButton: {
    padding: '0px',
  },
  buttonGradient: {
    background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '14px',
    fontWeight: 700,
  },
  icon: {
    width: '32px',
    height: '32px',
  },
  dividers: {
    marginY: '5px',
    width: '100%',
  },
  gradientText: {
    background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  minoGradient: {
    background: 'linear-gradient(251.66deg, #FFFFFF 6.03%, #FFB600 36.12%, #FF8952 97.74%)',
  },
  zilGradient: {
    background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256)',
  },
  metazoaBox: {
    position: 'relative'
  },
  metazoaImage: {
    width: '416px',
    height: '416px',
    position: 'absolute',
    bottom: '0px',
    right: '-20px',
  },
}

const SvgBoxWrapper = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  bottom: "0%",
  left: "0%",
  width: "100%",
})

const SvgBox = styled(Box)({
  pointerEvents: 'none',
  position: "relative",
  width: "100%",
  height: '300px',
  overflow: 'hidden',
});

const BlueEllipseBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "100px",
  left: "-850px",
  width: "1496px",
  opacity: "40%",
  background: "radial-gradient(42.7% 52.61% at 58.59% 47.39%, #FFFFFF 1.56%, #469BFF 15.29%, #0445EB 35.94%, rgba(0, 0, 0, 0) 96.45%)",
  // "@media (max-width:600px)": {
  //   left: '-120px',
  //   top: '408.94px',
  //   width: '423.44px',
  //   height: '423.15px',
  // },
});

const RedEllipseBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "-450px",
  right: "-580px",
  width: "1496px",
  opacity: "25%",
  background: "radial-gradient(42.41% 40.03% at 44.15% 51.87%, rgba(255, 255, 255, 0.734375) 0%, rgba(255, 122, 0, 0.734375) 16.67%, rgba(255, 2, 2, 0.479167) 56.9%, rgba(255, 0, 0, 0) 100%)",
  // "@media (max-width:600px)": {
  //   right: '-300px',
  //   top: '308.94px',
  //   width: '423.44px',
  //   height: '423.15px',
  // },
});

const Smoke1aBox = styled(Box)({
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(0deg, #FFFFFF -0.13%, #C1C1C1 96.36%, #999999 100%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "250px",
  left: "71px",
  "@media (max-width:900px)": {
    top: "250px",
    left: "10px",
  },
});



const PinkShootingBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  width: '140.85px',
  height: '94.34px',
  right: '0px',
  top: '200px',
  opacity: "45%",
  zIndex: 0,
  "@media (max-width:600px)": {
    display: 'none',
  },
});

export default ExploreBanner;