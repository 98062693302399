import styled from "@emotion/styled";
import { Box, Button, FormControl, FormHelperText, InputLabel, OutlinedInput, SxProps, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Huny, WarningRed } from "assets";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import ContainedButton from "components/ContainedButton";
import { DEFAULT_SRC } from "components/Guild/components/GuildConstants";
import { MetazoaClient } from "core/utilities/metazoa";
import dayjs from "dayjs";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { OAuth } from "store/types";
import { TBMConnector } from "tbm";
import { computeFinalPrice, useAsyncTask, useRedux, useToaster } from "utils";
import { Decimals, DISCORD_REGEX, ITEM_ID, TWITTER_REGEX, USERNAME_REGEX } from "utils/constants";
import { bnOrZero, toHumanNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import UpdateProfileDialog from "../UpdateProfileDialog";

export type ProfileDetailsInputs = {
  avatarUrl: string
  username: string;
}

type ProfileSocialsInputs = {
  discordUrl: string;
  twitterHandle: string;
}

const UpdateForm: React.FC = () => {

  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();
  const wallet = useRedux((state) => state.wallet.wallet);
  const profile = useRedux((state) => state.profile.profile);
  const metazoaTokens = useRedux((state) => state.token.metazoaTokens);
  const stakedMetazoa = useRedux((state) => state.token.stakedMetazoa);
  const totalHunySupply = useRedux((state) => state.token.TotalHunySupply);

  const metazoaProfileState = useSelector(getProfile);

  // STATES / HOOKS -----------------

  const [updateProfilePrice, setUpdateProfilePrice] = useState<number>(0);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string>('');
  const [inputProfileDetails, setInputProfileDetails] = useState<ProfileDetailsInputs>({
    avatarUrl: '',
    username: '',
  })
  const [inputProfileSocials, setInputProfileSocials] = useState<ProfileSocialsInputs>({
    twitterHandle: '',
    discordUrl: '',
  })
  const [errors, setErrors] = useState({
    avatarUrl: "",
    username: "",
    twitterHandle: "",
    discordUrl: "",
  })

  const [runUpdateProfile, loadingUpdateProfile] = useAsyncTask("updateProfile");
  const [runQueryUpdatePrice, loadingUpdatePrice] = useAsyncTask("queryGuildPrice");

  useEffect(() => {
    if(!network) return;
    runQueryUpdatePrice(async () => {
      const result = await TBMConnector.fetchStallItem(ITEM_ID[network].RefigurationSeal);
      const purchaseCount = await TBMConnector.fetchStallPurchaseCount(ITEM_ID[network].RefigurationSeal);
      const price = await computeFinalPrice(result.arguments[1], bnOrZero(totalHunySupply), purchaseCount);
      setUpdateProfilePrice(price.finalPrice.toNumber());
    })
    // eslint-disable-next-line
  }, [totalHunySupply, network])

  // Get current wallet holder's details
  const walletHolder = useMemo(() => {
    if (!wallet || !profile) return;
    const addr = wallet.addressInfo.bech32!.toLocaleLowerCase()!

    // Form state
    setAvatar(profile.avatarUrl ?? '');
    setInputProfileDetails({
      avatarUrl: profile.avatarUrl ?? '',
      username: profile.username ?? '',
    })
    setInputProfileSocials({
      twitterHandle: profile.twitterHandle ?? '',
      discordUrl: profile.discordUrl ?? '',
    })

    return {
      addr,
      profile,
      displayName: profile.username ?? addr,
    }
  }, [wallet, profile])


  const hasError = useMemo(() => {
    const errorString = Object.values(errors).reduce((prev, curr) => prev + curr);
    return !!errorString;
  }, [errors])

  // FUNCTIONS ----------------------

  // EVENT HANDLERS -----------------

  const handleShowDialog = () => {
    setShowDialog(true);
  }

  const handleOnClose = () => {
    setShowDialog(false);
  }


  const hasChangeProfile = useMemo(() => {
    if (!walletHolder) return false;
    let change = false;
    Object.entries(inputProfileDetails).forEach(([key, value]) => {
      const profileValue = walletHolder.profile ? (walletHolder.profile as any)[key] : undefined;
      if (!profileValue && !value) return;
      if (profileValue !== value) {
        change = true;
      }
    })

    return change;
  }, [inputProfileDetails, walletHolder])

  const hasChangeSocials = useMemo(() => {
    if (!walletHolder) return false;
    let change = false;

    Object.entries(inputProfileSocials).forEach(([key, value]) => {
      const profileValue = walletHolder.profile ? (walletHolder.profile as any)[key] : undefined;
      if (!profileValue && !value) return;
      if (profileValue !== value) {
        change = true;
      }
    })
    return change;
  }, [inputProfileSocials, walletHolder])

  // --------------------------------




  const updateProfileSocials = () => {
    runUpdateProfile(async () => {
      if (!hasChangeSocials) return;

      if (!wallet) return;

      try {
        const metazoaClient = new MetazoaClient(network);
        const { oAuth } = metazoaProfileState;
        let checkedOAuth: OAuth | undefined = oAuth;
        if (!oAuth?.access_token || oAuth.address !== wallet.addressInfo.bech32 || (oAuth && dayjs(oAuth?.expires_at * 1000).isBefore(dayjs()))) {
          const { result } = await metazoaClient.metazoaLogin(wallet!, window.location.hostname);
          dispatch(actions.Profile.updateAccessToken(result));
          checkedOAuth = result;
        }

        if (hasChangeSocials) {
          await metazoaClient.updateProfile(wallet.addressInfo.byte20!, inputProfileSocials as any, checkedOAuth!);
          toaster("Profile Updated");
          dispatch(actions.Profile.loadProfile());
          setInputProfileSocials({ discordUrl: "", twitterHandle: "" });
        }
      } catch (error: any) {
        toaster(`Error updating profile: ${error.message}`);
      }
    });
  }

  const validateInput = (type: string, input: string) => {
    switch (type) {
      case "username":
        if (input.length && input.length < 2) return "Enter a username of at least 2 characters.";
        if (input.length > 20) return "Enter a username of up to 20 characters.";
        if (!USERNAME_REGEX.test(input)) return "Must only contain alphanumeric or underscore characters.";
        return ""
      case "twitterHandle":
        if (input.length && input.length < 4) return "Enter a Twitter handle of at least 4 characters.";
        if (input.length > 15) return " Enter a Twitter handle of up to 15 characters.";
        if (input.length && !TWITTER_REGEX.test(input)) return "Must only contain alphanumeric or underscore characters."
        return ""
      case "discordUrl":
        if (input.length && !DISCORD_REGEX.test(input)) return "Must contain 3 to 32 characters followed by #4 numbers."
        return ""
      default: return "";
    }
  }

  const updateProfileDetailsInput = (type: string) => {
    return (newInput: string) => {
      setInputProfileDetails({ ...inputProfileDetails, [type]: newInput })
      if (!newInput) {
        setErrors({ ...errors, [type]: "" })
        return
      }
      const errorText = validateInput(type, newInput)
      setErrors({ ...errors, [type]: errorText })
    }
  }

  const updateProfileSocialsInput = (type: string) => {
    return (newInput: string) => {
      setInputProfileSocials({ ...inputProfileSocials, [type]: newInput })
      if (!newInput) {
        setErrors({ ...errors, [type]: "" })
        return
      }
      const errorText = validateInput(type, newInput)
      setErrors({ ...errors, [type]: errorText })
    }
  }

  const handleToggleAvatar = (
    event: React.MouseEvent<HTMLElement>,
    avatar: string,
  ) => {
    setAvatar(avatar);
    setInputProfileDetails({ ...inputProfileDetails, "avatarUrl": avatar });
  };

  const disableUpdateProfile = hasError || loadingUpdateProfile || loadingUpdatePrice || !inputProfileDetails.username;
  const disableUpdateSocial = hasError || loadingUpdateProfile || loadingUpdatePrice || (!inputProfileSocials.twitterHandle && !inputProfileSocials.discordUrl);

  if (!walletHolder) return null;
  return (
    <Fragment>
      <Box sx={styles.formContainer}>
        <Typography variant="subtitle1" color="primary" sx={styles.header}>Profile</Typography>
        <Box sx={styles.avatarContainer}>
          <AvatarCollectionGroup value={avatar} onChange={handleToggleAvatar} sx={styles.selectBearContainer} exclusive>
            <AvatarWrapper key="default" value={null} sx={styles.bearWrapper}>
              <Box
                component="img"
                src={DefaultUserProfile}
                sx={styles.bearImage}
                width="50px" height="50px"
              />

            </AvatarWrapper>
            {Object.values(stakedMetazoa).map((token) => (
              token.image &&
              <AvatarWrapper key={token.id} value={token.image} sx={styles.bearWrapper}>
                <Box component="img" sx={styles.bearImage} src={token.image} alt={`Metazoa ${token.id}`} onError={DEFAULT_SRC} />
              </AvatarWrapper>
            ))}
            {Object.values(metazoaTokens).map((token) => (
              token.image &&
              <AvatarWrapper key={token.id} value={token.image} sx={styles.bearWrapper}>
                <Box component="img" sx={styles.bearImage} src={token.image} alt={`Metazoa ${token.id}`} onError={DEFAULT_SRC} />
              </AvatarWrapper>
            ))}
          </AvatarCollectionGroup>
          <Typography variant="body1" color="primary" sx={styles.bodyText}>Select your champion avatar</Typography>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box display="flex" alignItems="center" width="100%">
            <InputLabel htmlFor="username">
              <Typography variant="body1" color="primary" sx={styles.inputLabel}>Username</Typography>
            </InputLabel>
            <FormControl fullWidth>
              <OutlinedInput
                sx={styles.input}
                id="username"
                placeholder={walletHolder.displayName}
                error={!!errors.username}
                // helperText={errors.username}
                value={inputProfileDetails.username}
                onChange={(e) => updateProfileDetailsInput("username")(e.target.value)}
              />
              {!!errors.username && (
                <Box sx={styles.errorTextContainer}>
                  <WarningRed width={15} />
                  <FormHelperText sx={styles.errorText}>{errors.username}</FormHelperText>
                </Box>
              )}
            </FormControl>
          </Box>
          <Box display="flex" mt="25px" alignItems="center" width="100%">
            <InputLabel htmlFor="username">
              <Typography variant="body1" color="primary" sx={styles.inputLabel}>Wallet Address</Typography>
            </InputLabel>
            <FormControl fullWidth>
              <Typography variant="body1" color="primary" sx={styles.inputLabel}>{walletHolder.addr}</Typography>
            </FormControl>
          </Box>
        </Box>
        <Box sx={styles.row}>
          <Typography variant="body1" color="primary">Fee</Typography>
          <Typography variant="h3" color="success.main" component="span" sx={styles.hunyAlign}>{toHumanNumber(bnOrZero(updateProfilePrice).shiftedBy(-Decimals.HUNY).toNumber())} Huny <Huny height="30px" width="40px" /></Typography>
        </Box>
        <Box sx={combineStyles(styles.row, styles.rowReverse)}>
          <ContainedButton disabled={!!disableUpdateProfile} onClick={handleShowDialog}>Update Profile</ContainedButton>
        </Box>

        <Typography variant="subtitle1" color="primary" sx={styles.header}>Socials</Typography>
        <Box sx={styles.boxContainer}>
          <Box display="flex" alignItems="center" width="100%">
            <InputLabel htmlFor="discord">
              <Typography variant="body1" color="primary" sx={styles.inputLabel}>Discord Username</Typography>
            </InputLabel>
            <FormControl fullWidth>
              <OutlinedInput
                sx={styles.input}
                id="discord"
                placeholder="example#1234"
                value={inputProfileSocials.discordUrl}
                onChange={(e) => updateProfileSocialsInput("discordUrl")(e.target.value)}
              />
              {!!errors.discordUrl && (
                <Box sx={styles.errorTextContainer}>
                  <WarningRed width={15} />
                  <FormHelperText sx={styles.errorText}>{errors.discordUrl}</FormHelperText>
                </Box>
              )}
            </FormControl>
          </Box>
          <Box display="flex" mt="25px" alignItems="center" width="100%">
            <InputLabel htmlFor="username">
              <Typography variant="body1" color="primary" sx={styles.inputLabel}>Twitter Handle</Typography>
            </InputLabel>
            <FormControl fullWidth>
              <OutlinedInput
                sx={styles.input}
                id="twitter"
                placeholder="example"
                value={inputProfileSocials.twitterHandle}
                onChange={(e) => updateProfileSocialsInput("twitterHandle")(e.target.value)}
                startAdornment={"@"}
              />
              {!!errors.twitterHandle && (
                <Box sx={styles.errorTextContainer}>
                  <WarningRed width={15} />
                  <FormHelperText sx={styles.errorText}>{errors.twitterHandle}</FormHelperText>
                </Box>
              )}
            </FormControl>
          </Box>
          <Box display="flex" mt="25px" alignItems="center" width="100%">
            <InputLabel htmlFor="username">
              <Typography variant="body1" color="primary" sx={styles.inputLabel}>Wallet Address</Typography>
            </InputLabel>
            <FormControl fullWidth>
              <Typography variant="body1" color="primary" sx={styles.inputLabel}>{wallet?.addressInfo.bech32}</Typography>
            </FormControl>
          </Box>
        </Box>

        <Box sx={styles.row}>
          <Button variant="outlined" color="secondary" component={NavLink} to="/metazoa">
            <Typography variant="button" color="primary" sx={styles.gradientText}>Go Back</Typography>
          </Button>
          <ContainedButton disabled={!!disableUpdateSocial} onClick={updateProfileSocials}>Update Socials</ContainedButton>
        </Box>
      </Box>

      <UpdateProfileDialog
        open={showDialog}
        onClose={handleOnClose}
        inputs={inputProfileDetails}
        price={updateProfilePrice}
        hasChangeProfile={hasChangeProfile}
        setInputProfileDetails={setInputProfileDetails}
      />
    </Fragment>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  formContainer: {
    position: 'relative',
    width: '720px',
    "@media (max-width:900px)": {
      flexDirection: "column",
    },
  },
  header: {
    marginTop: '60px',
  },
  avatarContainer: {
    marginTop: '20px',
    padding: '24px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    borderRadius: '16px',
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  selectBearContainer: {
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: 'repeat(8, 1fr)',
    flexShrink: '0',
    rowGap: '20px',
    paddingTop: '10px',
    width: '95%',
    marginBottom: '20px',
    "@media (max-width:900px)": {
      maxHeight: '140px',
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
  },
  displayAvatarGrid: {
    minHeight: '160px',
    paddingX: '20px',
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: 'repeat(5, 1fr)',
    rowGap: '20px',
    flexShrink: '0',
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },

  bearWrapper: {
    position: 'relative',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '30px',
  },

  bearImage: {
    height: '50px',
    width: '50px',
    borderRadius: '25px',
    fontSize: '0.5rem',
  },

  bodyText: {
    fontSize: '0.75rem',
    marginTop: '24px',
  },

  boxContainer: {
    marginTop: '20px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    padding: "24px",
  },
  inputLabel: {
    fontSize: "14px",
    lineHeight: "28px",
    width: "250px",
  },
  input: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    color: "rgba(255,255,255,0.8)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      fontWeight: 600,
      padding: "12px 24px",
    },
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
      color: "#fff",
    },
    "&.MuiInputAdornment-positionStart": {
      color: "#fff",
    }
  },

  row: {
    marginY: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowReverse: {
    flexDirection: 'row-reverse',
  },
  hunyAlign: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 700,
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },
}

const AvatarCollectionGroup = styled(ToggleButtonGroup)({
  '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    borderRadius: '30px',
  },
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderRadius: '30px',
  },
  '& .MuiToggleButtonGroup-root': {
  },
  '::-webkit-scrollbar': {
    width: '10px',
  },
  '::-webkit-scrollbar-track': {
    marginTop: '10px',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '20px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
})

const AvatarWrapper = styled(ToggleButton)({
  '&.Mui-selected': {
    background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
  },
  '&.Mui-disabled': {
    opacity: 0.5,
  },
});

export default UpdateForm;
