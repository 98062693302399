import { ZolarEventSubscriber } from "tbm"
import { GameEvent, GameState } from "./types"

export const GameActionTypes = {
  RELOAD_LEADERBOARD: "GAME:RELOAD_LEADERBOARD",
  UPDATE: "GAME:UPDATE",
  PROCESS_EVENT: "GAME:PROCESS_EVENT",
  NEW_EVENT: "GAME:NEW_EVENT",
}

export function reloadLeaderboard() {
  return { type: GameActionTypes.RELOAD_LEADERBOARD }
}

export function update(payload: Partial<GameState>) {
  return {
    type: GameActionTypes.UPDATE,
    payload  
  }
}

export function addNewEvent(payload: GameEvent) {
  return {
    type: GameActionTypes.NEW_EVENT,
    payload  
  }
}
export function processEvent(payload: ZolarEventSubscriber.ZolarEvent[]) {
  return {
    type: GameActionTypes.PROCESS_EVENT,
    payload  
  }
}
