import { Box, Button, CircularProgress, Divider, FormControl, FormHelperText, IconButton, Link, SvgIcon, SxProps, TextField, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { AddIcon, ExternalLink, Huny, RemoveIcon, WarningRed, ZilTokenIcon } from "assets";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { logger, waitForTx } from "core/utilities";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { Guild, GuildBankInfo } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { BIG_ZERO, Decimals } from "utils/constants";
import { bnOrZero, getExplorerLink } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { NumberFormat } from "../../../../../../BankSettings/components";
import { Dropdown } from "../../../../TaxCollectorTab/components";
import { DropdownOptions } from "../../../../TaxCollectorTab/components/Dropdown";

export type BankDonationInputs = {
  donationType: string;
  donationAmount: BigNumber;
}

export type Errors = {
  donationAmount: string;
}
export interface Props {
  guild: Guild;
  bank: GuildBankInfo;
  open: boolean;
  onClose: () => void;
}

const BankDonationDialog: React.FC<Props> = (props: Props) => {
  const { open, onClose, guild, bank } = props
  const hasGuildBank: boolean = !!bank ?? false;

  const toaster = useToaster();
  const dispatch = useDispatch();
  const tokenList: DropdownOptions[] = [
    { value: "HUNY", label: "huny", icon: <Huny /> },
    { value: 'ZIL', label: "zil", icon: <ZilTokenIcon /> },
  ]

  // Get current token balance
  const tokenState = useRedux((state) => state.token);
  const allowanceStore = useRedux((state) => state.guild.allowances);
  const hunyBalance = useMemo(() => new BigNumber(tokenState.HunyTokens), [tokenState.HunyTokens]);
  const zilBalance = useMemo(() => new BigNumber(tokenState.ZilTokens), [tokenState.ZilTokens]);
  const network = useNetwork();
  const wallet = useRedux((state) => state.wallet.wallet);
  const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0));
  const [donateTxHash, setDonateTxHash] = useState<string>("");
  const [runApprove, loadingApprove] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
  });
  const [runDonate, loadingDonate] = useAsyncTask("donate", (error) => {
    toaster(error?.message ?? "Error Donating");
  });

  useEffect(() => {
    if (!wallet || !bank) return;
    if (!allowanceStore) {
      dispatch(actions.Guild.loadAllowances());
      return;
    }
    const abortController = new AbortController();

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();

    const bankAllowance = allowanceStore[walletAddress]?.[bank.address];
    if (!!bankAllowance) {
      const allowance: BigNumber = bnOrZero(bankAllowance).shiftedBy(-Decimals.HUNY)
      setAllowance(allowance);
      logger("debug-component", "setAllowance", allowance)
    }

    return abortController.abort();
    // eslint-disable-next-line
  }, [wallet, network, bank, allowanceStore]);

  const tokens = useMemo(() => ({
    huny: {
      balance: new BigNumber(tokenState.HunyTokens),
      price: tokenState.ExchangeRates.hunyPrice
    },
    zil: {
      balance: new BigNumber(tokenState.ZilTokens),
      price: tokenState.ExchangeRates.zilPrice
    }
  }), [tokenState])


  const initialFormState: BankDonationInputs = {
    donationType: tokenList[0].value.toLowerCase(),
    donationAmount: new BigNumber(0),
  }
  const [formState, setFormState] = useState<BankDonationInputs>(initialFormState);
  const [errors, setErrors] = useState<Errors>({
    donationAmount: "",
  })

  const validateInput = (field: string, input: string) => {
    const amt = bnOrZero(input);

    const donationType: string = formState.donationType.toLowerCase()

    const currentBalance: BigNumber = (tokens[donationType]) ? tokens[formState.donationType.toLowerCase()].balance : 0;

    switch (field) {
      case "donationAmount":
        if (amt.isGreaterThan(new BigNumber(currentBalance))) return "The amount exceeds your wallet balance.";
        return ""
      default: return "";
    }
  }

  const increaseAllowanceRequired = useMemo(() => {
    if (!bank) return false;
    if (formState.donationType === "zil") return false;

    else return formState.donationAmount.isGreaterThanOrEqualTo(allowance);
    // eslint-disable-next-line
  }, [bank, allowance, formState])

  //INFO: Input handlers
  const handleMaxInput = () => {
    switch (formState.donationType.toLowerCase()) {
      case "huny":
        inputHandler(hunyBalance.toString(10), "donationAmount");
        return;
      case "zil":
        inputHandler(zilBalance.toString(10), "donationAmount");
        return
    }
  };
  const inputHandler = (inputAmt: string, attrName: string) => {
    const amt = bnOrZero(inputAmt);

    // Catch possible errs 
    if (typeof inputAmt === "string") {
      const errorText = validateInput(attrName, inputAmt);
      setErrors((prev) => ({ ...prev, [attrName]: errorText }));
    }

    setFormState({
      ...formState,
      [attrName]: amt,
    })
  };
  const handleAdd = (attrName: string) => {
    if (formState[attrName] instanceof BigNumber) {
      inputHandler(formState[attrName].plus(1).toString(10), attrName);
    }
    else {
      inputHandler((formState[attrName] + 1).toString(), attrName);
    }
  };
  const handleSubtract = (attrName: string) => {
    if (formState[attrName] instanceof BigNumber) {
      if (formState[attrName].isLessThanOrEqualTo(0)) return;
      inputHandler(formState[attrName].minus(1).toString(10), attrName);
    }
    else {
      if (formState[attrName] <= 0) return;
      inputHandler((formState[attrName] - 1).toString(), attrName);
    }

  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputHandler(event.target.value, event.target.name);
  };
  const handleTypeChange = (value: string) => {
    setFormState({
      donationAmount: BIG_ZERO,
      donationType: value.toLowerCase(),
    })
  };

  // const isApproveEnabled = useMemo(() => {
  //   // Check if there are any errors
  //   if (Object.values(errors).some(err => !!err)) return false;

  //   // Check if donation amount is NOT more than zero
  //   if (!formState.donationAmount.isGreaterThan(0) || formState.donationAmount.isGreaterThan(tokens[formState.donationType.toLowerCase()].balance)) return false;

  //   return true;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formState, errors])

  const getTypeBalance = useMemo(() => {
    if (!formState.donationType) return;

    const donationType: string = formState.donationType.toLowerCase()

    if (tokens[donationType]) {
      const currentBalance: BigNumber = tokens[formState.donationType.toLowerCase()].balance;
      return `${currentBalance.toFormat(4)} ${donationType.toUpperCase()} (≈${(tokens[donationType].price).times(currentBalance).toFormat(2)})`;
    }
    else return `∞ ${donationType.toUpperCase()}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.donationType, tokens])

  //DEBUG: Sudo state mgmt, to be replaced with RPC calls
  const [donationConfirmed, setDonationConfirmed] = useState<boolean>(false);
  const [donationCompleted, setDonationCompleted] = useState<boolean>(false);

  const handleApproveDonation = () => {
    runApprove(async () => {
      await approve();
    })
  }

  const approve = async () => {
    if (!bank) return;
    if (!wallet) throw new Error("Wallet not connected");
    const approveAmount = new BigNumber(2).pow(128).minus(1).minus(allowance.shiftedBy(Decimals.HUNY)).dp(0);
    const approveTx = await TBMConnector.increaseGuildBankAllowance(wallet, toBech32Address(bank.address), approveAmount);
    toaster(`Submitted Approve Increase Allowance `, { hash: approveTx.id! })

    if (approveTx?.id) {
      try {
        await waitForTx(approveTx.id);
        dispatch(actions.Guild.loadAllowances());
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const handleConfirmDonation = () => {
    runDonate(async () => {
      await donate();
    })
  }

  const donate = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!formState || !bank) return;
    const donateTx = await TBMConnector.donateToBank(toBech32Address(bank.address), formState.donationType, formState.donationAmount.shiftedBy(Decimals.HUNY).integerValue(BigNumber.ROUND_DOWN));
    toaster(`Submitted Donate Request `, { hash: donateTx.id! })
    if (donateTx.isRejected() || !donateTx.id) throw new Error("Submitted transaction was rejected.");

    const tx = await waitForTx(donateTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(donateTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) {
      setDonationConfirmed(false);
      setDonationCompleted(false);
      throw new Error("Submitted transaction was unsuccessful");
    }

    setDonateTxHash(donateTx.id);
    setDonationConfirmed(true);
    setDonationCompleted(true);
    toaster(`Donated to Guild `, { hash: donateTx.id!, overridePersist: true });
    dispatch(actions.Guild.reloadBank(guild));
  }

  const handleOnClose = () => {
    onClose()
    setDonationConfirmed(false)
    setDonationCompleted(false)
    setFormState(initialFormState)

  }


  const getHeader = () => {
    if (donationCompleted && donateTxHash) return "Donation Success!";
    else return "Donate";
  }

  const getContent = () => {
    if (!guild) return;
    if (donationCompleted && donateTxHash) {
      return (
        <Box sx={combineStyles(styles.dialogContent, {
          "> *": {
            width: 'auto',
          },
        })}>
          <Typography sx={styles.subText} color="primary" mt="40px">
            Thank you for contributing to the strength of our guild.
          </Typography>

          <Typography variant="h2" color="primary" sx={{
            display: 'flex',
            flexDirection: 'row',
            placeContent: 'center',
            placeItems: 'center',
            gap: "16px",
          }}>
            {formState.donationType === "huny" && <Huny width={40} height={40} />}
            {formState.donationType === "zil" && <ZilTokenIcon width={40} height={40} />}
            {formState.donationAmount.toFormat(2)}
          </Typography>

          <Link
            target="_blank"
            href={getExplorerLink("tx", donateTxHash, network)}
            sx={styles.viewTx}
          >
            View Transaction
            <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
          </Link>

          <Box sx={styles.buttonRow}>
            <ContainedButton
              sx={styles.button}
              onClick={handleOnClose}
            >
              Back to guild bank
            </ContainedButton>
          </Box>
        </Box>
      )
    }
    return (
      <Box sx={styles.dialogContent}>
        <Typography sx={styles.subText} color="primary">
          Assets donated to the guild bank will be used to calculate airdrops and participate in future missions. Once confirmed, they cannot be withdrawn.
        </Typography>

        <Box sx={styles.inputRow}>
          <Dropdown options={tokenList.reverse()}
            defaultValueSelected={formState.donationType.toUpperCase()}
            onSelected={handleTypeChange}
            sx={styles.tokenDropdown}
            paperStyles={styles.tokenDropdownPortal}
          />

          <FormControl fullWidth sx={styles.formField}>
            <Box sx={combineStyles(styles.input, styles.numInputBox, { ...(errors['donationAmount']) && styles.errorInput })}>
              <IconButton onClick={() => handleSubtract('donationAmount')}>
                <RemoveIcon height={24} width={24} />
              </IconButton>

              <Box sx={styles.numInput}>
                <TextField
                  name={'donationAmount'}
                  onChange={handleChange}
                  value={formState['donationAmount'].toString()}
                  InputProps={{ inputComponent: (NumberFormat.NumberFormatSeparator) as any }}
                  sx={combineStyles(styles.input, styles.settingNumInput)} />
              </Box>

              <IconButton onClick={() => handleAdd('donationAmount')}>
                <AddIcon height={24} width={24} />
              </IconButton>
            </Box>

            {errors['donationAmount'] &&
              <Box sx={styles.errorTextContainer}>
                <WarningRed width={15} />
                <FormHelperText sx={styles.errorText}>{errors['donationAmount']}</FormHelperText>
              </Box>}
          </FormControl>
        </Box>


        <Typography variant="body1" color="primary" component="span" sx={styles.customBalanceRow}>
          Balance: {getTypeBalance} &nbsp;
          <Button variant="outlined" size="small" color="secondary" onClick={handleMaxInput} sx={styles.maxButton}>
            <Typography variant="button" sx={styles.maxGradientText}>
              MAX
            </Typography>
          </Button>
        </Typography>


        <Divider sx={styles.dialogDivider} />
        <Box sx={styles.rateRow}>
          <Typography variant="body1" color="primary">Total Donation</Typography>
          <Typography variant="h3" color="primary">{(formState.donationAmount).toFormat(2)}&nbsp;{(tokenList.find((t) => t.value === formState.donationType.toUpperCase()))?.icon}</Typography>
        </Box>

        <Box sx={styles.buttonRow}>
          {increaseAllowanceRequired &&
            <ContainedButton
              sx={combineStyles(styles.button, { flex: 0.6 })}
              onClick={handleApproveDonation}
            >
              {loadingApprove
                ? <CircularProgress size={18} />
                : "approve huny"
              }
            </ContainedButton>
          }
          <ContainedButton
            sx={styles.button}
            disabled={donationConfirmed || increaseAllowanceRequired || !!errors.donationAmount}
            onClick={handleConfirmDonation}
          >
            {loadingDonate
              ? <CircularProgress size={18} />
              : "confirm & donate"
            }
          </ContainedButton>
        </Box>
      </Box>
    )
  }


  if (!guild || !hasGuildBank) return null;
  return (
    <DialogModal
      header={getHeader()}
      open={open}
      onClose={handleOnClose}
      sx={styles.dialogModal}
      disableScrollLock={true}>
      {getContent()}
    </DialogModal>
  )
}



const styles: SimpleMap<SxProps<AppTheme>> = {
  // Numerical Input
  formField: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'end',
    placeItems: 'center',
    flex: 1,

    "*": {
      height: '100%',
    }
  },
  numInputBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    width: "100%",
    gap: '24px',
  },
  numInput: {
    flex: 1,
    width: '100%',
  },
  settingNumInput: {
    background: 'transparent',
    border: 0,
    flex: 0.4,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',

    "& input": {
      paddingX: '0',
      width: "100%",
      textAlign: "center",
    },
  },
  input: {
    minHeight: "90px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",

    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },

    "&:hover, &:active, &:focus, &:focus-within": {
      borderColor: '#AEF1EE',
    },

    "& input": {
      fontFamily: "Prompt",
      fontSize: "24px",
      lineHeight: "36px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      paddingY: "12px",

      "&:active, &:focus": {
        color: "#AEF1EE",
        "& input": {
          color: "#AEF1EE",
        },
      }
    },
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },

  //INFO: Balance row
  customBalanceRow: {
    marginLeft: 'auto',
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    fontSize: '18px',
    lineHeight: '28px',
  },
  maxButton: {
    borderRadius: '16px',
  },
  maxGradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },

  dialogDivider: {
    background: 'rgba(174, 241, 238, 0.1)',
  },
  rateRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    "h3": {
      display: 'flex',
      flexDirection: 'row',
      placeItems: 'center',
    },

    "svg": {
      width: '24px',
      height: '24px',
    }
  },

  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: '20px',
    gap: '32px',
    alignItems: 'center',
  },
  button: {
    minWidth: '250px',
    maxWidth: '450px',
    flex: 1,
    height: 64,
  },
  errorInput: { borderColor: "#F75E5E!important", },


  //INFO: Dialog Modal
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "center",
    padding: "0 40px",
    gap: '32px',

    "> *": {
      width: '100%',
    },
  },
  subText: {
    fontSize: "24px",
    textAlign: "center",
  },

  inputRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'flex-start',
    gap: '24px',
  },
  tokenDropdown: {
    height: '90px',
    ".MuiInputBase-root": {
      width: '220px',
      height: '100%',
    },
    ".MuiSelect-select": {
      width: '100%',
      height: '100%!important',
      fontSize: '24px!important',
      fontWeight: 700,
      fontFamily: 'Syne',
      textTransform: 'uppercase',
      paddingY: '0 !important',
      "svg": {
        width: '40px',
        height: '40px',
      },
    }
  },
  tokenDropdownPortal: {
    ".MuiMenuItem-root": {
      "svg": {
        width: '24px',
        height: '24px',
      },
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '28px',
      fontFamily: 'Prompt',
      textTransform: 'uppercase',
      paddingY: 0,
      margin: 'revert',

      ...GUILD_GRAY_GRADIENT,
    }
  },

  viewTx: {
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: 600,
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
}

export default BankDonationDialog;