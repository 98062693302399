import { Box, Container, SxProps } from "@mui/material";
import { GameLayout } from "layout";
import { AppTheme, SimpleMap } from "utils/types";
import Header from "./components/Header";
import Leaderboard from "./components/Leaderboard";
import LiveUpdate from "./components/LiveUpdate";
import Stats from "./components/Stats";
import Summon from "./components/Summon";

const GamePage: React.FC = () => {
  return(
    <GameLayout>
      <Container maxWidth="xl" sx={styles.container}>
        <Box sx={styles.gameWrapper}>
          <Header />
          <Summon />
          <Stats />
          <Leaderboard />
          <LiveUpdate />
        </Box>
      </Container>
    </GameLayout>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    position: "relative",
    padding: "0px 0px 100px",
    minHeight: "2800px",
    width: '100%',
    "@media (max-width:768px)": {
      overflowX: 'auto',
    },
  },
  gameWrapper: {
    marginTop: '100px',
    padding: '40px',
    minHeight: '500px',
    minWidth: '720px',
    "@media (max-width:1200px)": {
      paddingX: '0px',
    },
  },
};

export default GamePage;