import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  SxProps,
  Typography,
} from "@mui/material";
import { PlaceholderItemCube } from "assets";
import Huny from "assets/Huny.svg";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import MinoIcon from "assets/icons/Minos.svg";
import UrsaIcon from "assets/icons/Ursas.svg";
import ItemFrame from "assets/ItemFrame.svg";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import {
  BANNERS,
  BANNER_COLOURS,
  DEFAULT_SRC,
  GUILD_GRAY_GRADIENT,
  SIGILS,
  SIGIL_COLOURS,
} from "components/Guild/components/GuildConstants";
import { RESOURCES } from "components/Quest/QuestConstants";
import React, { Fragment, RefObject, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { actions } from "store";
import { Guild } from "store/types";
import { theme } from "theme";
import {
  LoadingKeys,
  MissionGroundLocation,
  MissionGroundResource,
} from "utils/constants";
import { bnOrZero, formatIntegerNumber } from "utils/strings/strings";
import { combineStyles } from "utils/themeUtilities";
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from "utils/types";
import useIsOverflow from "utils/useIsOverflow";
import useNetwork from "utils/useNetwork";
import useRedux from "utils/useRedux";
import useTaskSubscriber from "utils/useTaskSubscriber";
import MetazoaCollection from "./MetazoaCollection/MetazoaCollection";
import { CONSUMABLES, Gem, GEMS, GemTierType } from "./ResourceConstants";
import { CrackGeodesDialog, RefineElderberryDialog } from "./ResourcesDialogs";
import RefineGemsDialog, {
  RefineGemProps,
} from "./ResourcesDialogs/RefineGemsDialog";

const ConnectedWallet: React.FC = () => {
  const dispatch = useDispatch();
  const network = useNetwork();
  const ref = useRef() as RefObject<HTMLDivElement>;
  const isOverflow = useIsOverflow(ref);
  const listInnerRef = useRef() as RefObject<HTMLDivElement>;

  const tokenIcons = {
    ursas: UrsaIcon,
    minos: MinoIcon,
    huny: Huny,
    ...Object.fromEntries(
      Object.values(MissionGroundResource).map((r) => [
        RESOURCES[r].alias,
        RESOURCES[r].iconSrc,
      ])
    ),
  };

  // STATES / HOOKS -----------------
  const wallet = useRedux((state) => state.wallet.wallet);
  const profile = useRedux((state) => state.profile.profile);
  const tokenState = useRedux((state) => state.token);
  const huny = useRedux((state) => state.token.HunyTokens);
  const guilds = useRedux((state) => state.guild.allGuilds);

  const resources = useRedux((state) => state.token.resources);
  const elderBerry = resources[MissionGroundResource.Elderberries]
    .shiftedBy(-2)
    .integerValue(BigNumber.ROUND_FLOOR);
  const geodes = resources[MissionGroundResource.Geodes]
    .shiftedBy(-2)
    .integerValue(BigNumber.ROUND_FLOOR);
  const zScraps = resources[MissionGroundResource.ZolraniumScraps]
    .shiftedBy(-2)
    .integerValue(BigNumber.ROUND_FLOOR);
  const [isLoading] = useTaskSubscriber(...LoadingKeys.connectWallet);
  // const [loadingTokens] = useTaskSubscriber("updateTokens");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popperType, setPopperType] = useState<string>("");
  const [showGeodeDialog, setShowGeodeDialog] = useState<boolean>(false);
  const [showElderberryDialog, setShowElderberryDialog] =
    useState<boolean>(false);
  const [showGemRefinement, setShowGemRefinementDialog] =
    useState<boolean>(false);
  const [selectedGem, setSelectedGem] = useState<RefineGemProps>(
    {} as RefineGemProps
  );

  // Get current wallet holder's details
  const walletHolder = useMemo(() => {
    if (!network) return;
    if (!wallet || !profile || !tokenState) return;
    const addr = wallet.addressInfo.bech32!.toLocaleLowerCase()!;

    // Tabulate zoas
    let [ursas, minos] = [0, 0];
    const metazoaTokens = Object.values(tokenState.metazoaTokens)
      .concat(Object.values(tokenState.stakedMetazoa))
      .concat(Object.values(tokenState.stakedMetazoaBerry))
      .concat(Object.values(tokenState.stakedMetazoaGeode))
      .concat(Object.values(tokenState.stakedMetazoaScrap));
    metazoaTokens.forEach((token) => {
      const faction = token?.attributes?.find(
        (attribute) => attribute.trait_type === "Faction"
      )?.value;
      if (faction === "Ursa") {
        ursas++;
      } else if (faction === "Mino") {
        minos++;
      }
    });

    return {
      addr,
      profile,
      displayName: profile.username ?? addr,
      metazoaTokens: { ursas, minos },
    };
  }, [wallet, profile, tokenState, network]);

  // Retrieve guild artifacts
  const currentGuild: Guild = useMemo(() => {
    let guild: Guild = {} as Guild;
    if (!walletHolder?.profile || !guilds) return guild;

    const { guildId } = walletHolder?.profile;
    if (!guild) return guild;

    //INFO: Locate guild from store by guildId, redirects out if it's invalid
    guild = guilds.find((guild) => guild.id === guildId) ?? ({} as Guild);

    return guild;
  }, [guilds, walletHolder]);

  // FUNCTIONS ----------------------

  // EVENT HANDLERS -----------------

  const handleDisconnectWallet = () => {
    dispatch(actions.Blockchain.initialize({ wallet: null, network }));
    dispatch(actions.Wallet.update({ wallet: null }));
  };

  const handleOnClose = () => {
    // dispatch(actions.Token.reloadTokens());
  };

  const handleGeodeDialogOnClose = () => {
    dispatch(actions.Token.refetchResource());
    setShowGeodeDialog(false);
    handleOnClose();
  };

  // --------------------------------

  const popperContent = (resource: string) => {
    switch (resource) {
      case "Gem":
        return (
          <>
            <Typography
              variant="body1"
              color="primary">
              Multiply your Metazoa stats by enhancing your Galaxy Gem!
            </Typography>
            <ContainedButton
              sx={combineStyles(styles.popperButton)}
              onClick={() => setShowGemRefinementDialog(true)}>
              ENHANCE GEM
            </ContainedButton>
          </>
        );
      case RESOURCES[MissionGroundResource.Elderberries].alias:
        return (
          <Fragment>
            <Typography
              variant="body1"
              color="primary">
              Elderberries may be refined into Elderberry Juice!
            </Typography>
            <ContainedButton
              sx={combineStyles(styles.popperButton, {
                background:
                  "linear-gradient(194deg, #FFC7FC -92.97%, #F471E8 41.43%, #7105C6 100%)",
              })}
              onClick={() => setShowElderberryDialog(true)}
            // disabled={!hasResource}
            >
              Refine Elderberry
            </ContainedButton>
          </Fragment>
        );
      case RESOURCES[MissionGroundResource.Geodes].alias:
        return (
          <Fragment>
            <Typography
              variant="body1"
              color="primary">
              What lies beneath the surface would bring your Metazoas great
              power...
            </Typography>
            <ContainedButton
              sx={combineStyles(styles.popperButton, {
                background: "linear-gradient(180deg, #00A3FF 0%, #0051FF 100%)",
              })}
              onClick={() => setShowGeodeDialog(true)}>
              Crack Geode
            </ContainedButton>
          </Fragment>
        );
      case RESOURCES[MissionGroundResource.ZolraniumScraps].alias:
        return (
          <Box>
            <Typography
              variant="body1"
              color="primary">
              Make use of your Zolranium Scraps and craft Z-Ordnances to get
              ready for battle!
            </Typography>
            <ContainedButton
              sx={combineStyles(styles.popperButton, {
                background:
                  "linear-gradient(231.29deg, #DACCC6 6.36%, #BE6F4E 40.5%, #4A2A1F 100%)",
              })}
              href={`/map/${MissionGroundLocation.ZOMGStore}`}>
              craft z-ordnance
            </ContainedButton>
          </Box>
        );
    }
  };

  let itemGridCount = 0;
  return (
    <Box sx={styles.metazoaContainer}>
      {isLoading ? (
        <Box sx={styles.userAccContainerLoading}>
          <CircularProgress size={18} />
        </Box>
      ) : (
        !!walletHolder && (
          <>
            <Box sx={styles.userAccContainer}>
              <Box sx={styles.profilePicWrapper}>
                <CircularProgress
                  variant="determinate"
                  value={
                    !!walletHolder?.profile.level
                      ? Math.floor(
                        (walletHolder?.profile?.level?.xpGained /
                          walletHolder?.profile?.level?.xpRequired ?? 0) * 100
                      )
                      : 0
                  }
                  size="184px"
                  thickness={2}
                  sx={styles.levelCurrentXP}
                />
                <CircularProgress
                  variant="determinate"
                  value={100}
                  size="184px"
                  thickness={2}
                  sx={styles.levelXPBar}
                />
                <Box
                  component="img"
                  src={walletHolder?.profile?.avatarUrl ?? DefaultUserProfile}
                  sx={styles.userProfilePic}
                  width="100%"
                  height="100%"
                  onError={DEFAULT_SRC}
                />

                {!!Object.keys(currentGuild).length && (
                  <Box
                    className="guildFlag"
                    sx={{
                      position: "absolute",
                      scale: "0.4",
                      bottom: "-50%",
                      right: "-25%",
                    }}>
                    <Box
                      sx={combineStyles(styles.guildFlagBanner, {
                        backgroundImage: `url(${BANNERS[currentGuild.guildBanner.design - 1][
                          BANNER_COLOURS.findIndex(
                            (curr) =>
                              curr.colour === currentGuild.guildBanner.colour
                          )
                        ]
                          })`,
                      })}>
                      <Box
                        sx={combineStyles(styles.guildFlagSigil, {
                          backgroundImage: `url(${SIGILS[currentGuild.guildSigil.design - 1][
                            SIGIL_COLOURS.findIndex(
                              (curr) =>
                                curr.colour === currentGuild.guildSigil.colour
                            )
                          ]
                            })`,
                        })}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
              <Box sx={styles.userAccDetails}>
                {!!walletHolder.displayName && (
                  <Typography
                    variant="h3"
                    color="secondary"
                    component="span">
                    {walletHolder.displayName.length > 15
                      ? truncate(walletHolder.displayName)
                      : walletHolder.displayName}
                  </Typography>
                )}
                <Button
                  variant="text"
                  component={NavLink}
                  to="/update-profile">
                  <Typography
                    variant="body1"
                    color="success.main"
                    sx={styles.textButton}>
                    Edit
                  </Typography>
                </Button>
                <Box sx={styles.details}>
                  <Divider sx={styles.divider} />
                  <Box sx={styles.contentRow}>
                    <Typography
                      variant="body1"
                      color="primary">
                      Commander Level
                    </Typography>
                    <Typography
                      variant="body1"
                      color="primary">
                      {walletHolder?.profile?.level?.level ?? 1}&nbsp;
                    </Typography>
                  </Box>
                  <Divider sx={styles.divider} />
                  <Box sx={styles.contentRow}>
                    <Typography
                      variant="body1"
                      color="primary">
                      XP
                    </Typography>
                    <Typography
                      variant="body1"
                      color="success.main"
                      component="span">
                      {walletHolder?.profile?.level?.xpGained ?? 0}&nbsp;
                      <Typography
                        variant="body1"
                        color="primary"
                        component="span">
                        /&nbsp;{walletHolder?.profile?.level?.xpRequired ?? 50}
                        &nbsp;
                      </Typography>
                    </Typography>
                  </Box>
                  <Box sx={styles.contentRow}>
                    <Typography
                      variant="body1"
                      color="primary">
                      Guild
                    </Typography>
                    <Link
                      to={
                        walletHolder.profile.guildId
                          ? `/guilds/${walletHolder?.profile?.guildId}`
                          : ""
                      }
                      style={{ textDecoration: "none" }}>
                      <Typography
                        variant="body1"
                        sx={combineStyles(styles.gradientText, {
                          ...(!!Object.keys(currentGuild).length && {
                            background:
                              BANNER_COLOURS.find(
                                (curr) =>
                                  curr.colour ===
                                  currentGuild.guildBanner.colour
                              )?.background ?? "",
                          }),
                        })}>
                        {walletHolder?.profile?.guildName ?? "-"}
                      </Typography>
                    </Link>
                  </Box>
                </Box>
                <Button
                  onClick={handleDisconnectWallet}
                  sx={styles.disconnectButton}>
                  <Typography
                    variant="button"
                    sx={styles.gradientText}>
                    DISCONNECT
                  </Typography>
                </Button>
              </Box>
              <Box sx={styles.container}>
                <Grid
                  container
                  columns={{ xs: 2, sm: 3, md: 6 }}
                  rowSpacing={2}
                  ref={listInnerRef}
                  sx={combineStyles(styles.itemContainer, {
                    ...(!isOverflow && styles.unmaskedContent),
                  })}
                  className={"masked-overflow"}>
                  {[
                    ...Object.values(walletHolder.metazoaTokens),
                    huny,
                    elderBerry,
                    geodes,
                    zScraps,
                  ].map((val, idx) => {
                    const iconSrc: string = Object.values(tokenIcons)[idx];
                    const keyName: string = Object.keys(tokenIcons)[idx];
                    const isClickable: boolean =
                      idx > Object.values(walletHolder.metazoaTokens).length;
                    const isOpen = Boolean(anchorEl);

                    const handleClick = (
                      event?: React.MouseEvent<HTMLElement>
                    ) => {
                      if (!isClickable) return;
                      setAnchorEl(
                        anchorEl ? null : event?.currentTarget ?? null
                      );
                      setPopperType(keyName);
                    };

                    return (
                      <Grid
                        item
                        xs={1}
                        key={idx}>
                        <Box
                          sx={combineStyles(styles.emptyItemBox, {
                            ...(isClickable && styles.clickableBox),
                          })}
                          {...(isClickable && { onClick: handleClick })}>
                          <Box
                            component="img"
                            src={iconSrc}
                            alt={keyName}
                            sx={combineStyles(styles.icons, {
                              ...(bnOrZero(val).lte(0) && styles.greyscale),
                            })}
                          />
                          <Box sx={styles.tokenAmtTag}>
                            <Typography
                              sx={styles.tokenAmt}
                              color="primary">
                              {formatIntegerNumber(val)}
                            </Typography>
                          </Box>
                        </Box>
                        <Popper
                          open={isClickable && isOpen}
                          placement="top"
                          anchorEl={anchorEl}
                          style={{ zIndex: 100001 }}>
                          <Paper sx={styles.popperContainer}>
                            <ClickAwayListener
                              onClickAway={() => setAnchorEl(null)}>
                              <Box sx={styles.popperContent}>
                                {popperContent(popperType)}
                              </Box>
                            </ClickAwayListener>
                          </Paper>
                        </Popper>
                      </Grid>
                    );
                  })}
                  {/* Gems */}
                  {!!Object.keys(resources.gems).length &&
                    Object.entries(resources.gems).map(([tier, affinityGems]) =>
                      Object.entries(affinityGems).map(([affinity, gemIds]) => {
                        itemGridCount++;
                        const amount = gemIds.length;

                        const currentGem: Gem = GEMS[tier][affinity];
                        const iconSrc: string = currentGem.icon;
                        const keyName: string = tier + " " + currentGem.name;
                        const isClickable = true;
                        const isOpen = Boolean(anchorEl);

                        // Pop the first ID as the target
                        const gemId = gemIds[0];

                        const handleClick = (
                          event?: React.MouseEvent<HTMLElement>
                        ) => {
                          if (!isClickable) return;
                          setAnchorEl(
                            anchorEl ? null : event?.currentTarget ?? null
                          );
                          setPopperType("Gem");

                          setSelectedGem({
                            gemId: gemId,
                            tier: tier as GemTierType,
                            gem: currentGem,
                            gems: affinityGems,
                          });
                        };

                        return (
                          <Grid
                            item
                            xs={1}
                            key={keyName}>
                            <Box
                              sx={combineStyles(styles.emptyItemBox, {
                                ...(isClickable && styles.clickableBox),
                              })}
                              {...(isClickable && { onClick: handleClick })}>
                              <Box
                                component="img"
                                src={iconSrc}
                                onError={DEFAULT_SRC}
                                alt={keyName}
                                sx={combineStyles(styles.icons, {
                                  ...(bnOrZero(amount).lte(0) &&
                                    styles.greyscale),
                                })}
                              />
                              <Box sx={styles.tokenAmtTag}>
                                <Typography
                                  sx={styles.tokenAmt}
                                  color="primary">
                                  {formatIntegerNumber(amount)}
                                </Typography>
                              </Box>
                            </Box>

                            <Popper
                              open={isClickable && isOpen}
                              placement="top"
                              anchorEl={anchorEl}
                              style={{ zIndex: 100001 }}>
                              <Paper sx={styles.popperContainer}>
                                <ClickAwayListener
                                  onClickAway={() => setAnchorEl(null)}>
                                  <Box sx={styles.popperContent}>
                                    {popperContent(popperType)}
                                  </Box>
                                </ClickAwayListener>
                              </Paper>
                            </Popper>
                          </Grid>
                        );
                      })
                    )}
                  {/* Cracked Gems */}
                  {!!Object.keys(resources.crackedGems).length &&
                    Object.entries(resources.crackedGems).map(
                      ([tier, affinityGems]) =>
                        Object.entries(affinityGems).map(
                          ([affinity, gemIds]) => {
                            itemGridCount++;
                            const amount = gemIds.length;

                            const currentGem: Gem = GEMS[tier][affinity];
                            const iconSrc: string =
                              currentGem?.iconCracked ?? "";
                            const keyName: string =
                              tier + " " + currentGem.name;

                            if (!iconSrc.length) return null;
                            return (
                              <Grid
                                item
                                xs={1}
                                key={keyName}>
                                <Box sx={combineStyles(styles.emptyItemBox)}>
                                  <Box
                                    component="img"
                                    src={iconSrc}
                                    onError={DEFAULT_SRC}
                                    alt={keyName}
                                    sx={combineStyles(styles.icons, {
                                      ...(bnOrZero(amount).lte(0) &&
                                        styles.greyscale),
                                    })}
                                  />
                                  <Box sx={styles.tokenAmtTag}>
                                    <Typography
                                      sx={styles.tokenAmt}
                                      color="primary">
                                      {formatIntegerNumber(amount)}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          }
                        )
                    )}
                  {/* Consumables */}
                  {!!Object.keys(resources.consumables) &&
                    Object.entries(resources.consumables).map(
                      ([type, consumableIds], idx) => {
                        itemGridCount++;
                        return (
                          <Grid
                            item
                            xs={1}
                            key={`${type}-${idx}`}>
                            <Box sx={styles.emptyItemBox}>
                              <Box
                                component="img"
                                src={CONSUMABLES[idx].icon}
                                alt={type}
                                sx={combineStyles(styles.icons, {
                                  ...(bnOrZero(consumableIds.length).lte(0) &&
                                    styles.greyscale),
                                })}
                                onError={DEFAULT_SRC}
                              />
                              <Box sx={styles.tokenAmtTag}>
                                <Typography
                                  sx={styles.tokenAmt}
                                  color="primary">
                                  {formatIntegerNumber(consumableIds.length)}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      }
                    )}

                  {/* Tabulate total count of each distinct ordnance(s) */}
                  {!!Object.keys(resources.ordnances).length &&
                    Object.entries(resources.ordnances).map(
                      ([name, ordnance]) => {
                        itemGridCount++;
                        return (
                          <Grid
                            item
                            xs={1}
                            key={`${ordnance.ids[0]}`}>
                            <Box sx={styles.emptyItemBox}>
                              <Box
                                component="img"
                                src={ordnance.src}
                                alt={name}
                                sx={{ height: "50px", width: "50px" }}
                                onError={DEFAULT_SRC}
                              />
                              <Box sx={styles.tokenAmtTag}>
                                <Typography
                                  sx={styles.tokenAmt}
                                  color="primary">
                                  {formatIntegerNumber(ordnance.ids.length)}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      }
                    )}
                  {[
                    ...Array(
                      Math.max(
                        12 -
                        itemGridCount -
                        [
                          ...Object.values(walletHolder.metazoaTokens),
                          huny,
                          elderBerry,
                          geodes,
                          zScraps,
                        ].length,
                        0
                      )
                    ),
                  ].map((x, i) => (
                    <Grid
                      item
                      xs={1}
                      key={i}>
                      <Box
                        sx={combineStyles(styles.emptyItemBox, {
                          opacity: 0.5,
                        })}>
                        <PlaceholderItemCube />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
            <CrackGeodesDialog
              open={showGeodeDialog}
              onClose={handleGeodeDialogOnClose}
              resourceType={"Geodes"}
            />
            <RefineElderberryDialog
              open={showElderberryDialog}
              onClose={() => setShowElderberryDialog(false)}
              resourceType={"Elderberries"}
            />
            {showGemRefinement && !!Object.keys(selectedGem) && (
              <RefineGemsDialog
                {...selectedGem}
                open={showGemRefinement}
                onClose={() => {
                  setSelectedGem({} as RefineGemProps);
                  setShowGemRefinementDialog(false);
                }}
              />
            )}
            <MetazoaCollection />
          </>
        )
      )}
    </Box>
  );
};
const styles: SimpleMap<SxProps<AppTheme>> = {
  guildFlagBanner: {
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    maxHeight: "250px",
    minHeight: "150px",
    maxWidth: "117px",
    height: "13vw",
    width: "15vw",
    minWidth: "7rem",
    "@media (min-width: 900px)": {
      height: "20vw",
      minWidth: "9rem",
    },
  },
  guildFlagSigil: {
    height: "90px",
    width: "90px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPositionX: "center",
    position: "absolute",
    top: "42%",
    left: "50%",
    transform: "translate(-50%, -40%)",
    "@media (max-width: 1100px)": {
      height: "7vw",
      width: "7vw",
    },
    "@media (max-width: 900px)": {
      height: "7vh",
      width: "7vh",
    },
  },

  //ITEMS GRID
  tokenAmt: {
    fontWeight: 600,
    fontSize: "14px",
    padding: "0px 6px",
  },
  tokenAmtTag: {
    position: "absolute",
    bottom: "-7px",
    border: "1px solid transparent",
    background:
      "linear-gradient(180deg, #01001E -13.81%, #000010 100%) padding-box,radial-gradient(#00C2FF, #AEF1EE) border-box",
    borderRadius: "8px",
  },
  tokenValue: {
    fontSize: "12px",
    bottom: "-35px",
    position: "absolute",
    ...GUILD_GRAY_GRADIENT,
    background:
      "linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256) 100%)",
  },
  emptyItemBox: {
    position: "relative",
    backgroundImage: `url(${ItemFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "inherit",

    display: "flex",
    placeContent: "center",
    placeItems: "center",
    width: "100px",
    height: "100px",
    minWidth: "100px",
    minHeight: "100px",
    // height: '100%',
    margin: "0 auto",
    paddingY: 0,
    borderRadius: "12px",

    svg: {
      width: "40px",
      height: "40px",
    },
  },
  clickableBox: {
    cursor: "pointer",
    ":hover": {
      boxShadow: "inset 0px 4px 52px #AEF1EE20",
    },
  },
  icons: {
    height: "40px",
    width: "40px",
  },
  greyscale: {
    filter: "grayscale(100%)",
    opacity: "0.5",
  },
  popperContainer: {
    top: "-10px",
    width: "270px",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)",
    boxShadow: "0px 4px 20px 3px #00000070",
    borderRadius: "16px",
  },
  popperContent: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "center",
  },
  popperButton: {
    padding: "7px 14px",
    fontSize: "1rem",
    fontWeight: "700",
    width: "100%",
    marginTop: "12px",
  },
  itemContainer: {
    margin: "0 auto",
    "> *": {
      display: "flex",
      placeContent: "center",
      placeItems: "center",
      scrollSnapAlign: "start",
    },
    maxHeight: "320px",
    overflowY: "auto",
    scrollBehavior: "smooth",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    scrollSnapType: "both mandatory",
    "::-webkit-scrollbar": {
      display: "none",
    },
    "@media (max-width:1200px)": {
      marginTop: "24px",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "unset",
      "> *": {
        minWidth: "100px",
      },
    },
  },
  container: {
    flex: 1,
    height: "100%",
    position: "relative",
    // [theme.breakpoints.up('md')]: {
    //   minWidth: 'calc(100px * 5)',
    // }
  },

  metazoaContainer: {
    position: "relative",
    paddingX: "100px",
    "@media (max-width:1200px)": {
      paddingX: "60px",
      flexDirection: "column",
    },
    "@media (max-width:900px)": {
      paddingX: "60px",
      flexDirection: "column",
    },

    // //DEBUG:Remove this
    // "*":{
    //   border:'1px solid red',
    // }
  },
  userAccContainerLoading: {
    width: "100%",
    padding: "40px",
    height: "293px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    borderRadius: "16px",
    flexWrap: "wrap",
  },
  userAccContainer: {
    width: "100%",
    padding: "30px 40px 20px",
    background:
      "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    display: "flex",
    flexDirection: "row",
    "@media (max-width:1200px)": {
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
    },
    borderRadius: "16px",
    justifyContent: "start",
    flexWrap: "wrap",
  },
  profilePicWrapper: {
    marginRight: "20px",
    width: "190px",
    height: "190px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  levelCurrentXP: {
    position: "absolute",
    color: "#27ED82",
  },
  levelXPBar: {
    position: "absolute",
    color: "#AEF1EE33",
  },
  userProfilePic: {
    width: "150px",
    height: "150px",
    borderRadius: "240px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  userAccDetails: {
    width: "280px",
    padding: "10px",
    margin: "10px",
    "@media (max-width:1200px)": {
      width: "80%",
      paddingRight: "10px",
    },
    "@media (max-width:900px)": {
      width: "100%",
      paddingRight: "10px",
    },
  },
  details: {
    marginY: "15px",
  },
  divider: {
    background: "rgba(174, 241, 238, 0.1)",
  },
  contentRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingX: "16px",
  },
  huny: {
    position: "absolute",
    top: "16px",
    right: "14px",
  },
  iconButton: {
    padding: "0px",
  },
  disconnectButton: {
    marginTop: "20px",
  },
  unmaskedContent: {
    paddingBottom: "2em",

    ".masked-overflow": {
      maskImage: "none",
      WebkitMaskImage: "none",
      overflowY: "hidden",
    },
  },
  gradientText: {
    background:
      "-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },
  metazoaCollection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    "@media (max-width:1200px)": {
      width: "80%",
    },
    "@media (max-width:900px)": {
      width: "100%",
    },
  },
  metazoaCount: {
    width: "140px",
    height: "192px",
    margin: "5px",
    background:
      "linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    position: "relative",
    paddingY: "10px",
  },
  svgWrapper: {
    width: "100%",
    "@media (max-width:1200px)": {
      width: "70%",
    },
  },
};

export default ConnectedWallet;
