import { Box, Container, SxProps, Typography } from "@mui/material";
import StripeHeader from "components/Game/components/StripeHeader";
import { MetazoaLayout } from "layout";
import { AppTheme, SimpleMap } from "utils/types";
import HivePanels from "./components/HivePanels";
import HiveTVL from "./components/HiveTVL";
import YourShare from "./components/YourShare";
import Migration from "./components/Migration";

const Hive: React.FC = () => {

  return (
    <MetazoaLayout>
      <Container maxWidth="xl" sx={styles.container}>
        <Migration />
        <Box sx={styles.hiveWrapper}>
          <StripeHeader sx={styles.hunyHeader}>
            MAGIC HIVE <Typography component="span" variant="h3" color="secondary">&nbsp;&nbsp;&bull;&nbsp;&nbsp;HUNY Vault</Typography>
          </StripeHeader>
          <Box sx={styles.hunyRow}>
            <Box sx={styles.hunyColumnLeft}>
              <HiveTVL/>
              <YourShare />
            </Box>
            <Box sx={styles.hunyColumnRight}>
              <HivePanels />
            </Box>
          </Box>
        </Box>
      </Container>
    </MetazoaLayout>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    position: "relative",
    padding: "0px 40px 100px",
    minHeight: "1200px",
    width: '100%',
    overflowX: 'auto',
  },
  hiveWrapper: {
    marginTop: '20px',
    padding: '40px',
    minHeight: '500px',
    minWidth: '720px',
    border: '1px solid #AEF1EE33',
    borderRadius: '16px',
    background: 'linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, #000010 100%)',
  },
  hunyHeader: {
    textAlign: 'center',
    paddingY: '5px',
    marginBottom: '25px',
    transform: 'skewX(-25deg)',
    background: 'repeating-linear-gradient(90deg, #AEF1EE33, #AEF1EE33 36px, transparent 36px, transparent 44px)',
  },
  hunyRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    "@media (max-width:1200px)": {
      flexDirection: 'column',
    },
  },
  hunyColumnLeft: {
    width: '33%',
    "@media (max-width:1200px)": {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  hunyColumnRight: {
    width: '66%',
    "@media (max-width:1200px)": {
      width: '100%',
    },
  },
};

export default Hive;