import { Box, CircularProgress, SxProps, Typography } from "@mui/material";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import ContainedButton from "components/ContainedButton";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import { waitForTx } from "core/utilities";
import React, { useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import { actions } from "store";
import { NftMetadata } from "store/types";
import { TBMConnector } from "tbm";
import { useToaster } from 'utils';
import { ContractsBech32 } from "utils/constants";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";
import useTaskSubscriber from "utils/useTaskSubscriber";
import BurnStatusModal from "./BurnStatus/BurnStatusModal";
import RenderBearCollection from "./RenderBearCollection/RenderBearCollection";

const TRANSCEND_LIMIT = 5;

const BearSelection: React.FC = () => {
  const [transcendComplete, setTranscendComplete] = useState<boolean>(false);
  const [transcendTxHash, setTranscendTxHash] = useState<string>("");
  const [transcendTokens, setTranscendTokens] = useState<string[]>([]);
  const [mintedZoaTokens, setMintedZoaTokens] = useState<string[]>([]);
  const [loadingTokens] = useTaskSubscriber("updateTokens");
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const transcendenceApproved = tokenState.transcendenceApproved;
  const whitelistCount = tokenState.whitelistCount;
  const mintedCount = tokenState.mintedTokensCount;
  const network = useNetwork();
  const dispatch = useDispatch();
  const toaster = useToaster();
  const [runTranscend, loadingTranscend] = useAsyncTask("transcend", (error) => {
    toaster(error?.message ?? "Error Transcending");
  });
  const [runApprove, loadingApprove] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
  });

  const limit = useMemo(() => {
    return (whitelistCount - mintedCount) < TRANSCEND_LIMIT
      ? whitelistCount - mintedCount
      : TRANSCEND_LIMIT;
  }, [whitelistCount, mintedCount])

  // tbm v1
  const tokens = Object.values(tokenState.tokens);
  const contractAddresses = ContractsBech32[network];
  const tbmAddress = fromBech32Address(contractAddresses.Nft);
  const transcendenceMinterAddress = fromBech32Address(contractAddresses.TranscendenceMinter);

  const handleToggleTokens = (
    event: React.MouseEvent<HTMLElement>,
    newTokens: string[],
  ) => {
    if (newTokens.length > limit) return;

    setTranscendTokens(newTokens);
  };

  const isApproveDisabled = whitelistCount === 0 || transcendTokens.length > limit;

  const isTranscendDisabled = useMemo(() => {
    if (!transcendenceApproved)
      return true;
    if (loadingApprove)
      return true;
    if (transcendTokens.length === 0)
      return true;
    if (transcendTokens.length > limit)
      return true;

    return false;
  }, [transcendenceApproved, loadingApprove, transcendTokens, limit]);

  const handleTranscend = () => {
    runTranscend(async () => {
      await transcend();
    })
  }

  const handleOnClose = () => {
    if (transcendComplete) {
      setTranscendTokens([]);
      setMintedZoaTokens([]);
      setTranscendTxHash("");
      setTranscendComplete(false);
    }
  }

  const handleApprove = () => {
    runApprove(async () => {
      await approve();
    })
  }

  const approve = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const approveTx = await TBMConnector.approveAllowance(tbmAddress, transcendenceMinterAddress);

    if (approveTx?.id) {
      try {
        await waitForTx(approveTx.id);
        dispatch(actions.Token.updateTranscendenceApproval(true));
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const transcend = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();

    const newTranscendTokens: NftMetadata[] = tokens.filter(token => transcendTokens.includes(token.id));

    const transcendTx = await TBMConnector.transcend(transcendTokens, walletAddress, transcendenceMinterAddress);

    if (transcendTx.isRejected()) {
      throw new Error("Submitted transaction was rejected.");
    }

    if (transcendTx?.id) {
      setTranscendTxHash(transcendTx.id);
      try {
        await waitForTx(transcendTx.id);

        const tbmConnector = TBMConnector.getSDK();
        const tokenIds: string[] = [];

        const txn = await tbmConnector.zilliqa.blockchain.getTransaction(transcendTx.id);
        const receipt = txn.getReceipt();

        receipt!.event_logs.forEach((event) => {
          if (event._eventname === "Mint") {
            tokenIds.push(
              event.params.find((param) => param.vname === "token_id")?.value
            );
          }
        });

        setMintedZoaTokens(tokenIds);
        dispatch(actions.Blockchain.updateSaleState());
        dispatch(actions.Token.updateImage());
        dispatch(actions.Token.saveTranscendedTokens(newTranscendTokens));
        setTranscendComplete(true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  return (
    <Box sx={styles.container}>
      {loadingTokens
        ? <Box sx={styles.loadingBox}>
          <CircularProgress size={24} />
        </Box>
        : whitelistCount === 0 || tokens.length === 0
          ? <Box sx={styles.noBearsBox}>
            <Typography variant="h3" color="primary" textAlign="center">{whitelistCount === 0 ? `You did not qualify for Transcendence.` : `You have no bears. :(`}</Typography>
          </Box>
          : <RenderBearCollection tokens={tokens} transcendTokens={transcendTokens} handleToggleTokens={handleToggleTokens} />
      }
      <Box sx={styles.modalSpan}>
        <Typography component="span" variant="h3" color="secondary" sx={{ fontSize: '20px' }}>
          {transcendTokens.length} / {limit}
        </Typography>
        <Typography component="span" variant="body1" color="primary" mr="2px">
          &nbsp; selected
        </Typography>
        <TooltipZolar>You may transcend up to {TRANSCEND_LIMIT} bears per transaction.</TooltipZolar>
      </Box>
      <Box display="flex" sx={styles.buttonBox}>
        {!transcendenceApproved &&
          <ContainedButton sx={styles.approveButton} onClick={handleApprove} disabled={isApproveDisabled}>
            {loadingApprove
              ? <CircularProgress size={24} />
              : <span style={{ lineHeight: '2rem' }}>Authorize Transcendence</span>
            }
          </ContainedButton>
        }
        <ContainedButton sx={styles.transcendButton} onClick={handleTranscend} disabled={isTranscendDisabled}>
          <span style={{ lineHeight: '2rem' }}>Begin Transcendence</span>
        </ContainedButton>
      </Box>
      <BurnStatusModal
        loadingOpen={loadingTranscend}
        successOpen={transcendComplete}
        onClose={handleOnClose}
        complete={transcendComplete}
        transcendTxHash={transcendTxHash}
        network={network}
        zoaTokens={mintedZoaTokens}
      />
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    paddingTop: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    "@media (min-width:900px)": {
      minWidth: 700,
    }
  },
  noBearsBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    "@media (max-width:600px)": {
      marginTop: '10px',
    }
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    "@media (max-width:600px)": {
      marginTop: '10px',
    }
  },
  modalSpan: {
    marginBottom: '20px',
  },
  approveButton: {
    flexGrow: 1,
    flexBasis: 0,
    marginRight: '10px',
    width: 270,
    "@media (max-width:600px)": {
      marginRight: 0,
      marginBottom: '10px',
      width: '100%',
    }
  },
  transcendButton: {
    flexGrow: 1,
    flexBasis: 0,
    width: 270,
    "@media (max-width:600px)": {
      width: '100%',
    }
  },
  buttonBox: {
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
}


export default React.memo(BearSelection);