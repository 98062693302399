import { Box, SxProps } from '@mui/material';
import { DialogModal } from 'components/DialogModal';
import { DialogModalProps } from 'components/DialogModal/DialogModal';
import { FC } from 'react';
import { AppTheme, SimpleMap } from 'utils/types';
import { QuestTokens, QuestTokenView } from './TokenView';

export interface ModalProps {
  onClose: () => void;
}

export interface TokenViewDialogProps extends ModalProps, Omit<DialogModalProps, "onClose">, Omit<QuestTokens, "maxViewable"> {
}

const TokenViewDialog: FC<TokenViewDialogProps> = ({
  open,
  onClose,
  tokens,
  header,
  ...rest
}: TokenViewDialogProps) => {

  // STATES / HOOKS -----------------

  // FUNCTIONS ----------------------

  // EVENT HANDLERS -----------------

  const handleOnClose = () => {
    onClose();
  }

  // --------------------------------

  const getContent = () => {
    return (
      <Box sx={styles.content}>
        {!!tokens.length && (<QuestTokenView tokens={tokens} />)}
      </Box>
    );
  }

  return (
    <DialogModal {...rest} header={header} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true}>
      {getContent()}
    </DialogModal>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  tokensWrapper: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    overflowY: 'auto',

    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    placeContent: 'start',
    placeItems: 'center',
    gap: '0.6em',
    paddingY: '10px',

    "> *": {
      flexBasis: '10.5%',
    },

    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
};

export default TokenViewDialog