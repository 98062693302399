import styled from "@emotion/styled";
import { Box, BoxProps, CircularProgress, SxProps, Tooltip, tooltipClasses, TooltipProps, Typography, Zoom } from "@mui/material";
import Huny from 'assets/Huny.svg';
import StoreItemFrame from "assets/quests/Locations/ZOMGStore/StoreItemFrame.svg";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { TEXT_GRADIENT } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { ZOrdnanceClasses } from "components/Metazoa/ResourceConstants";
import { QuestConstants } from "components/Quest";
import { ResourceAsset } from "components/Quest/QuestConstants";
import { FC } from "react";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";


type ResourceCardProps = {
  resourceAsset?: ResourceAsset;
  ordnanceAsset?: string;
  ordnanceTooltip?: JSX.Element;
  itemFrameSrc?: string;
  resourceInfo?: {
    textNode: JSX.Element,
    handleInfoClick?: () => void;
  };
  footerContent?: JSX.Element;
  isLoading?: boolean;
} & BoxProps;

const ItemTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '10px 13px',
    boxShadow: "0px 4px 24px #000000",
    borderRadius: '16px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)',
    maxWidth: 250,
  },
});

const ResourceCard: FC<ResourceCardProps> = ({
  resourceAsset,
  ordnanceAsset,
  ordnanceTooltip,
  resourceInfo,
  itemFrameSrc,
  footerContent,
  isLoading = false,
  children,
  ...rest
}: ResourceCardProps) => {

  const isEmpty: boolean = !resourceAsset && !ordnanceAsset;

  return (
    <Box sx={combineStyles(styles.resourcesCatalog, rest.sx, {
      ...(isEmpty) && { opacity: 0.4 },
      ...(!footerContent) && { paddingBottom: '8px', },
    })}>

      {/* ITEM */}
      {isLoading && (
        <Box
          height="150px"
          sx={combineStyles(
            styles.itemFrame,
            {
              ...itemFrameSrc && {
                backgroundImage: `url(${itemFrameSrc})`,
              }
            }
          )}
        >
          <CircularProgress size={24} />
        </Box>
      )}
      {!isLoading && (
        <ItemTooltip
          {...(isEmpty) && { open: false }}
          // open={true}
          title={!!resourceAsset ? (<Typography variant="body1" sx={{
            lineHeight: 'normal',
            fontSize: '1.5em',
            ...GUILD_LIGHTGRAY_GRADIENT,
          }}>{resourceAsset.description}</Typography>) : (
            !!ordnanceTooltip && ordnanceTooltip
          )}
          TransitionComponent={Zoom}
          placement="top"
        >
          <Box
            height="150px"
            sx={combineStyles(
              styles.itemFrame,
              {
                ...itemFrameSrc && {
                  backgroundImage: `url(${itemFrameSrc})`,
                }
              }
            )}
          >
            {
              !!ordnanceAsset
                ? <Box
                  component="img"
                  src={ZOrdnanceClasses[ordnanceAsset]}
                  width="120px"
                  height="120px"
                  onError={QuestConstants.DEFAULT_SRC}
                />
                : <Box
                  component="img"
                  src={!!resourceAsset ? resourceAsset.iconSrc : ''}
                  width="60%"
                  maxWidth="80px"
                  minHeight="100px"
                  onError={QuestConstants.DEFAULT_SRC}
                />
            }
          </Box>
        </ItemTooltip>
      )}



      <Typography sx={combineStyles(
        styles.priceLabel,
        {
          ...(!resourceInfo) && {
            visibility: 'hidden',
          },
        }
      )} component="span">
        {!!resourceInfo ? resourceInfo.textNode : <>-&nbsp;
          <Box
            component="img"
            width='20px'
            height='20px'
            src={Huny}
            sx={{ verticalAlign: 'sub' }}
          />
        </>}
      </Typography>

      {children}

      {!!footerContent && (
        <Box
          component="aside"
          sx={{
            display: 'flex',
            placeContent: 'space-between',
            placeItems: 'center',
            background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',

            color: '#FFFFFF',
            fontSize: '12px',
            mx: '0!important',
            mt: 1,
            padding: '2px 14px',
            width: '100%!important',
          }}
        >
          {footerContent}
        </Box>
      )}
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  resourcesCatalog: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'start',
    placeItems: 'center',
    gap: '.3em',
    minWidth: '160px',
    position: 'relative',

    background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
    borderRadius: '20px',
    paddingTop: '8px',
    // px: '8px',

    "> *": {
      width: '90%',
      marginX: 'auto!important',
    },
  },

  itemFrame: {
    backgroundImage: `url(${StoreItemFrame})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
  },

  priceLabel: {
    textAlign: 'center',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
    ...TEXT_GRADIENT,
    ...GUILD_LIGHTGRAY_GRADIENT,
  },


}

export default ResourceCard;