import { Box, Checkbox, CircularProgress, Link, SvgIcon, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CheckedIcon, DisabledIcon, ExternalLink, Huny, UncheckedIcon } from "assets";
import MinoDefault from "assets/dp-mino.png";
import UrsaDefault from "assets/dp-ursa.png";
import MinoIcon from "assets/icons/Minos.svg";
import UrsaIcon from "assets/icons/Ursas.svg";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import TooltipLocked from "components/TooltipLocked";
import WarningBox from "components/WarningBox";
import { logger, waitForTx } from "core/utilities";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { NftMetadata } from "store/types";
import { TBMConnector, ZolarEventSubscriber } from "tbm";
import { useToaster } from "utils";
import { BLOCKS_PER_MINUTE, HUNY_GATHERED_PER_DAY, MAX_ZOA } from "utils/constants";
import { getExplorerLink } from "utils/strings/links";
import { bnOrZero } from "utils/strings/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const ReturnDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose } = props;
  const toaster = useToaster();
  const dispatch = useDispatch();
  const network = useNetwork();
  const [runQueryStakedBocks,] = useAsyncTask("queryTakersBurntCount");
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const tokenTraits = useRedux(state => state.game.tokenTraits);
  const { trappedMetazoa: trappedMetazoaMap } = tokenState
  const trappedMetazoa = Object.values(trappedMetazoaMap);
  const [metazoaBlocksStaked, setMetazoaBlocksStaked] = useState<SimpleMap<number>>({});
  const [totalHunyHarvesting, setTotalHunyHarvesting] = useState<number>(0);
  const [selectedMetazoa, setSelectedMetazoa] = useState<string[]>([]);
  const [returnBaseComplete, setReturnBaseComplete] = useState<boolean>(false);
  const [returnBaseTxHash, setReturnBaseTxHash] = useState<string>("");
  const [runReturnBase, loadingReturnBase] = useAsyncTask("retreatBase", (error) => {
    toaster(error?.message ?? "Error Returning to Base");
  });

  useEffect(() => {
    if (wallet) {
      runQueryStakedBocks(async () => {
        setMetazoaBlocksStaked(await TBMConnector.getMetazoaBlocksStaked(wallet, Object.keys(trappedMetazoaMap), true))
      })
    }
    // eslint-disable-next-line
  }, [wallet, network]);

  const hunyGatheredPerMetazoa: SimpleMap<number> = useMemo(() => {
    const hunyGathered: SimpleMap<number> = {};

    trappedMetazoa.forEach(token => {
      const id = token.id;
      const attributes = token?.attributes;

      const gen = attributes?.find(attribute => attribute.trait_type === "Gen")?.value;
      const faction = attributes?.find(attribute => attribute.trait_type === "Faction")?.value;

      if (faction === "Ursa") { // not flipped yet
        hunyGathered[id] = bnOrZero((metazoaBlocksStaked[id] / BLOCKS_PER_MINUTE / 60 / 24) * HUNY_GATHERED_PER_DAY[gen!]).toNumber() * 0.78;
      } else {
        return bnOrZero(0)
      }
    })

    return hunyGathered;
  }, [metazoaBlocksStaked, trappedMetazoa]);

  const trappedMetazoaSorted = Object.values(trappedMetazoaMap).sort((a, b) =>
    (hunyGatheredPerMetazoa[b.id] || 0) - (hunyGatheredPerMetazoa[a.id] || 0));
  const trappedMetazoaIds = trappedMetazoaSorted.map(m => m.id);

  const needsHarvest = (tokenId: string) => {
    // is MINO or is URSA and trait is still URSA
    const token = trappedMetazoa.find(token => token.id === tokenId);
    const faction = token?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value;

    const onChainFaction = tokenTraits?.[tokenId]?.faction
    if (faction === "Ursa" && (!onChainFaction || onChainFaction === 'ursa')) {
      // is staked ursa but need to await flip
      return true
    }
    return false;
  }

  const stillNeedHarvest = trappedMetazoa.findIndex(m => needsHarvest(m.id)) >= 0
  const max = stillNeedHarvest ? MAX_ZOA.HarvestHuny : MAX_ZOA.ReturnToBase

  const isLocked = (tokenId: string) => {
    return stillNeedHarvest && !needsHarvest(tokenId)
  }

  const handleSelect = (tokenId: string) => (_event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedMetazoaCopy = selectedMetazoa.slice();
    const index = selectedMetazoaCopy.findIndex((id) => id === tokenId);
    if (index === -1) {
      selectedMetazoaCopy.push(tokenId);
      setSelectedMetazoa(selectedMetazoaCopy);
      setTotalHunyHarvesting(totalHunyHarvesting + hunyGatheredPerMetazoa[tokenId]);
    } else {
      selectedMetazoaCopy.splice(index, 1);
      setSelectedMetazoa(selectedMetazoaCopy);
      setTotalHunyHarvesting(totalHunyHarvesting - hunyGatheredPerMetazoa[tokenId]);
    }
  }

  const handleSelectAll = () => {
    const selectableIds = trappedMetazoaIds.filter(id => !isLocked(id))
    if (selectedMetazoa.length >= Math.min(selectableIds.length, max)) {
      setSelectedMetazoa([]);
      setTotalHunyHarvesting(0);
    } else {
      setSelectedMetazoa(selectableIds.slice(0, max));
      setTotalHunyHarvesting(0);
    }
  }

  const selectedUrsaCount = useMemo(() => {
    if (!selectedMetazoa.length) return 0;

    let count = 0;

    selectedMetazoa.forEach(id => {
      const faction = trappedMetazoaMap[id]?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value;

      if (faction === "Ursa")
        count++;
    })

    return count;

  }, [selectedMetazoa, trappedMetazoaMap])

  const isMetazoaSelected = (tokenId: string) => {
    return selectedMetazoa.includes(tokenId);
  }

  const handleAction = () => {
    runReturnBase(async () => {
      if (stillNeedHarvest) {
        await harvestHuny();
      } else {
        await returnBase();
      }
    })
  }

  const harvestHuny = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    if (selectedMetazoa.length > MAX_ZOA.HarvestHuny) {
      throw new Error(`Please select max ${MAX_ZOA.HarvestHuny} ZOAs only`);
    }

    const harvestHunyTx = await TBMConnector.harvestHuny(wallet, selectedMetazoa, true);

    if (harvestHunyTx.isRejected()) {
      throw new Error("Submitted transaction was rejected.");
    }

    if (harvestHunyTx?.id) {
      setReturnBaseTxHash(harvestHunyTx.id);
      try {
        await waitForTx(harvestHunyTx.id);
        dispatch(actions.Token.updateImage());
        dispatch(actions.Token.reloadHivePool());
        setReturnBaseComplete(true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const returnBase = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    if (selectedMetazoa.length > MAX_ZOA.ReturnToBase) {
      throw new Error(`Please select max ${MAX_ZOA.ReturnToBase} ZOAs only`);
    }

    const returnBaseTx = await TBMConnector.returnToBase(wallet, selectedMetazoa, true);

    if (returnBaseTx.isRejected()) {
      throw new Error("Submitted transaction was rejected.");
    }

    if (returnBaseTx?.id) {
      setReturnBaseTxHash(returnBaseTx.id);
      try {
        await waitForTx(returnBaseTx.id);

        const tbmConnector = TBMConnector.getSDK();

        const txn = await tbmConnector.zilliqa.blockchain.getTransaction(returnBaseTx.id);
        const receipt = txn.getReceipt();
        let actionId: string = "";

        receipt!.event_logs.forEach((event) => {
          if (event._eventname === "ActionStarted") {
            actionId = event.params.find((param) => param.vname === "action_id")?.value;
          }
        });

        if (!!actionId) {
          const events = await TBMConnector.waitForConcludeAction(actionId);
          // check events for MetazoaKidnapped events, if none, none are kidnapped.
          const kidnappedEvents = events.filter(event => event.type === ZolarEventSubscriber.ZolarEventType.MetazoaKidnapped)
          // check events for HunyStolen events, if none, none are stolen.
          const hunyStolenEvents = events.filter(event => event.type === ZolarEventSubscriber.ZolarEventType.HunyStolen)
          logger("return to base kidnapped", kidnappedEvents, "stolen", hunyStolenEvents);
        }

        dispatch(actions.Token.updateImage());
        setReturnBaseComplete(true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const handleOnClose = () => {
    if (loadingReturnBase) return;

    onClose();
    setReturnBaseTxHash("");
    setSelectedMetazoa([]);
    setReturnBaseComplete(false);
  }

  const handleSendMore = () => {
    if (loadingReturnBase) return;

    setReturnBaseTxHash("");
    setSelectedMetazoa([]);
    setReturnBaseComplete(false);
  }

  const getHeader = () => {
    if (returnBaseComplete) {
      return stillNeedHarvest ? "Final Harvest success!" : "Return Metazoa success!";
    } else if (loadingReturnBase) {
      return stillNeedHarvest ? "Harvesting...!" : "Returning to base...";
    } else {
      return "Return Metazoa to base";
    }
  }

  const addDefaultSrc = (metadata: NftMetadata) => (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!metadata.attributes?.length)
      return;

    const index = metadata.attributes.findIndex(attribute => attribute.trait_type === "Faction");

    if (index === -1)
      return;

    let faction = metadata.attributes[index].value;

    if (faction === "Ursa") {
      e.currentTarget.onerror = null;
      e.currentTarget.src = UrsaDefault;
    } else if (faction === "Mino") {
      e.currentTarget.onerror = null;
      e.currentTarget.src = MinoDefault;
    }
  }

  const getContent = () => {
    if (returnBaseComplete) {
      return (
        <Fragment>

          <Typography variant="body1" color="primary" textAlign="center">
            {stillNeedHarvest ? 'You have successfully done your final harvest!' : 'You have successfully returned your Metazoa to base!'}
          </Typography>


          <Box sx={styles.contentBox} style={{ marginTop: 0 }}>
            {/* Qty */}

            <Box display="flex" alignItems="center" mt="20px">
              <Box component="img" src={MinoIcon} mr="10px" sx={styles.placeholderImage} />
              <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }}>x {selectedMetazoa.length - selectedUrsaCount}</Typography>
            </Box>
            <Box display="flex" alignItems="center" mt="30px">
              <Box component="img" src={UrsaIcon} mr="10px" sx={styles.placeholderImage} />
              <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }}>x
                {" "}
                <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }} component="span">{selectedUrsaCount}</Typography>
              </Typography>
            </Box>

            {/* Tx hash */}
            <Link
              target="_blank"
              href={getExplorerLink("tx", returnBaseTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>

            {/* Send more */}
            <ContainedButton
              onClick={handleSendMore}
              sx={styles.sendMoreButton}
            >
              Retreat
            </ContainedButton>
          </Box>
        </Fragment>
      )
    }

    if (loadingReturnBase) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Box>
      )
    }

    return (
      <Fragment>
        {/* Warning box */}
        <WarningBox>
          <Typography variant="body1" color="primary" align="left">
            <strong>PANICKING MINOS: </strong>
            After a overly hearthy pillaging, Minos are now panicking and will not steal, kidnap, or harvest any remaining HUNY.
            Report to your local #TeamMino union if your Mino has not harvested yet at all!
            {" "}
            <Link
              target="_blank"
              href="https://discord.gg/y82wBVnGAn"
            >
              Learn More
            </Link>
          </Typography>
        </WarningBox>

        <Box sx={styles.contentBox}>
          {/* Metazoa */}
          <TableContainer sx={styles.tableContainer}>
            <Table>
              <TableHead sx={styles.tableHead}>
                <TableRow>
                  {/* Gen */}
                  <TableCell align="left" width="10%">
                    <Typography variant="body1" color="primary">Gen</Typography>
                  </TableCell>

                  {/* Metazoa */}
                  <TableCell align="left">
                    <Typography variant="body1" color="primary">Metazoa</Typography>
                  </TableCell>

                  {/* Huny */}
                  <TableCell align="center">
                    <Typography variant="body1" color="primary">
                      Total HUNY Gathered
                      {" "}
                      <SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" />
                    </Typography>
                  </TableCell>

                  {/* Select all */}
                  <TableCell align="center">
                    <Typography variant="body1" color="primary" sx={styles.selectAll} onClick={handleSelectAll}>SELECT MAX</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={styles.tableBody}>
                {trappedMetazoaSorted.map((token) => {
                  const attributes: any = {};
                  token?.attributes?.forEach(attribute => {
                    attributes[attribute.trait_type] = attribute.value;
                  })

                  return (
                    <TableRow key={token.id}>
                      {/* Gen */}
                      <TableCell align="left" sx={{ paddingLeft: "10px!important" }}>
                        <Typography variant="body1" color="primary">{attributes?.["Gen"] ?? "-"}</Typography>
                      </TableCell>

                      {/* Metazoa */}
                      <TableCell align="left">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box component="img" width="40px" src={token.image} alt={`ZOA #${token.id}`} sx={styles.zoaImage} onError={addDefaultSrc(token)} />
                          <Typography variant="body1" color="primary">
                            ZOA #{token.id}
                          </Typography>
                        </Box>
                      </TableCell>

                      {/* Huny */}
                      <TableCell align="center">
                        <Typography variant="body1" color="primary">
                          {bnOrZero(hunyGatheredPerMetazoa[token.id]).toNumber().toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </Typography>
                      </TableCell>

                      {/* Checkbox */}
                      <TableCell align="center">
                        {isLocked(token.id)
                          ? <TooltipLocked link="https://docs.zolar.io/missions/mission-i-takers-of-the-moon#ursa-factions-mission-brief">
                            Harvest all remaining Ursas first!
                          </TooltipLocked>
                          : <Checkbox
                            icon={isLocked(token.id) ? <DisabledIcon /> : <UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                            checked={isMetazoaSelected(token.id)}
                            onChange={handleSelect(token.id)}
                            disabled={isLocked(token.id)}
                          />
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Selection info */}
          <Box sx={styles.infoContainer}>
            {/* Returning to base */}
            <Box sx={styles.row}>
              <Typography variant="body1" color="primary">
                Selected Metazoa
              </Typography>
              <Typography variant="body1" color="primary" fontWeight="700" component="span">
                {selectedUrsaCount} Ursas - {selectedMetazoa.length - selectedUrsaCount} Mino <Typography variant="body1" color="success.main" component="span">({trappedMetazoa.length - selectedMetazoa.length} Remaining)</Typography>
              </Typography>
            </Box>
          </Box>

          {/* Confirm*/}
          <ContainedButton
            onClick={handleAction}
            disabled={!selectedMetazoa.length || loadingReturnBase}
            sx={styles.confirmButton}
          >
            {loadingReturnBase
              ? <CircularProgress size={18} />
              : (stillNeedHarvest ? "Harvest Final" : "Retreat Now")
            }
          </ContainedButton>
        </Box>
      </Fragment>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true}>
      <Box sx={{ maxWidth: 700 }}>
        {getContent()}
      </Box>
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  tableContainer: {
    overflowY: "auto",
    maxHeight: "25vh",
    maxWidth: "700px",
    paddingBottom: "10px",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "transparent",
      top: "",
      left: "",
    },
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: "10px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },
  tableHead: {
    "& th.MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  confirmButton: {
    marginTop: "20px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  hunyIcon: {
    verticalAlign: "top",
  },
  selectAll: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
    }
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    marginTop: "10px",
  },
  alertBox: {
    marginTop: "10px",
    width: "100%",
    backgroundColor: "rgba(174, 241, 238, 0.1)",
    borderRadius: "16px",
    padding: "20px 16px",
    display: "flex",
    border: '1px solid #AEF1EE33',
    background: "rgba(174, 241, 238, 0.1)",
    flexDirection: "column",
  },
  alertRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    width: "100%",
    "&:not(:first-child)": {
      marginTop: "4px",
    },
  },
  alertIcon: {
    verticalAlign: "text-top",
    marginRight: "8px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "sub",
    },
  },
  zoaImage: {
    marginRight: "10px",
    borderRadius: "16px",
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "20px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  sendMoreButton: {
    marginTop: "20px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  placeholderImage: {
    height: "60px",
    width: "60px",
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },

  modalTextResize: {
    fontSize: '0.8rem',
    lineHeight: '1rem',
  },

  textGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
}

export default ReturnDialog;
