import { Box, BoxProps, Button, CircularProgress, styled, SxProps, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { CheckCircleIcon, CrestUpgradeArrow, FileUpload, Huny } from "assets";
import GuildBannerPreview from "assets/GuildBannerPreview.svg";
import ContainedButton from "components/ContainedButton";
import { CRESTS, CREST_COLOUR_SELECTOR, CREST_ICONS, CREST_UPGRADES } from "components/Guild/components/GuildConstants";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import React, { Fragment, useEffect, useState } from "react";
import { TBMConnector } from "tbm";
import { computeFinalPrice, useAsyncTask, useRedux } from "utils";
import { Decimals, ITEM_ID } from "utils/constants";
import { bnOrZero, toHumanNumber } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { CreateDialog } from "..";
import { GuildFormInputs } from "../../CreateGuild";

export interface Props extends BoxProps {
  formInputs: GuildFormInputs;
  setFormInputs: React.Dispatch<React.SetStateAction<GuildFormInputs>>;
  handleBack: () => void;
  displayImage: string | ArrayBuffer | null;
  setDisplayImage: React.Dispatch<React.SetStateAction<string | ArrayBuffer | null>>;
  uploadedImage: File | null;
  setUploadedImage: React.Dispatch<React.SetStateAction<File | null>>;
  isCreateEnabled: boolean;
}

const PageTwo: React.FC<Props> = (props: Props) => {
  const { formInputs, setFormInputs, handleBack, displayImage, setDisplayImage, uploadedImage, setUploadedImage, isCreateEnabled } = props;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [runQueryGuildPrice, loadingQueryGuildPrice] = useAsyncTask("queryGuildPrice");
  const totalHunySupply = useRedux((state) => state.token.TotalHunySupply);
  const network = useNetwork();
  const [guildCrestPrice, setGuildCrestPrice] = useState<number>(0);

  const handleShowDialog = () => {
    setShowDialog(true);
  }

  const handleOnClose = () => {
    setShowDialog(false);
  }

  const handleImageUpload = ({ target }) => {
    if (!target.files.length) {
      setDisplayImage(null);
      setUploadedImage(null);
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setDisplayImage(reader.result);
      setUploadedImage(target.files[0]);
    }

    reader.readAsDataURL(target.files[0]);
  }

  const updateInputs = (field: string) => {
    return (newInput: string | number) => {
      setFormInputs({ ...formInputs, [field]: newInput });
    }
  }

  useEffect(() => {
    runQueryGuildPrice(async () => {
      const crestItem = await TBMConnector.fetchStallItem(ITEM_ID[network].GuildCrest);
      const purchaseCount = await TBMConnector.fetchStallPurchaseCount(ITEM_ID[network].GuildCrest);
      const price = await computeFinalPrice(crestItem.arguments[1], bnOrZero(totalHunySupply), purchaseCount);
      setGuildCrestPrice(price.finalPrice.toNumber())
    })
    // eslint-disable-next-line
  }, [totalHunySupply])

  return (
    <Box sx={styles.contentBox}>
      {/* guild crest preview */}
      <Typography variant="body2" color="primary" sx={styles.sectionHeaderText}>
        Guild Crest Preview
      </Typography>

      <Box sx={styles.previewBox}>
        {/* current */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          height="100%"
          gap="10px"
        >
          <Box position="relative">
            {displayImage && (
              <Box component="img" src={displayImage.toString()} sx={styles.crestImage} />
            )}
            <Box
              component="img"
              src={CRESTS[formInputs.guildCrestDesign - 1][
                CREST_COLOUR_SELECTOR.findIndex(
                  (curr) => curr.colour === formInputs.guildCrestColour
                )]}
              sx={styles.crestImageBig}
            />
          </Box>

          <Typography variant="subtitle1" color="primary" fontSize="14px">
            Current Guild Crest
          </Typography>
        </Box>

        <Box sx={styles.upgradeArrowBox}>
          <CrestUpgradeArrow />
        </Box>

        {/* upgrade */}
        <Box sx={styles.crestUpgrade}>
          <Box flex={0.4} />

          <Box position="relative">
            {displayImage && (
              <Box
                component="img"
                src={displayImage.toString()}
                sx={styles.crestImageSmall}
              />
            )}
            <Box
              component="img"
              src={
                CREST_UPGRADES[formInputs.guildCrestDesign - 1][
                CREST_COLOUR_SELECTOR.findIndex(
                  (curr) => curr.colour === formInputs.guildCrestColour
                )
                ]
              }
              height="180px"
              width="180px"
              position="relative"
              sx={styles.opacity}
            />
          </Box>

          <Typography variant="subtitle1" color="primary" mt="-20px" mb="20px" fontSize="14px">
            Next Crest Upgrade
          </Typography>
        </Box>
      </Box>

      {/* guild crest */}
      <Box sx={styles.guildCrestBox}>
        <Typography variant="body1" color="primary" sx={styles.subheaderText}>
          Guild Crest
        </Typography>

        <CrestButtonGroup
          value={formInputs.guildCrestDesign}
          onChange={(_, newSelection) => {
            newSelection !== null &&
              updateInputs("guildCrestDesign")(newSelection);
          }}
          exclusive
        >
          {CREST_ICONS.map((crest, index) => {
            return (
              <CrestWrapper key={index} value={index + 1} disableRipple>
                <Box sx={{ position: 'relative', width: '80px', height: '80px' }}>
                  <Box
                    component="img"
                    src={crest.image}
                    id="crestDesign"
                  />
                  <CheckCircleIcon id="sigilCheckedIcon" />
                </Box>
              </CrestWrapper>
            );
          })}
        </CrestButtonGroup>

        <ColourButtonGroup
          value={formInputs.guildCrestColour}
          onChange={(_, newSelection) => {
            newSelection !== null &&
              updateInputs("guildCrestColour")(newSelection);
          }}
          exclusive
        >
          {CREST_COLOUR_SELECTOR.map((colourObj, index) => {
            return (
              <ColourWrapper
                key={index}
                value={colourObj.colour}
                sx={styles.colourWrapper}
                disableRipple
              >
                <Box
                  sx={styles.colourBox}
                  id="colour"
                  style={{ background: colourObj.background }}
                />
              </ColourWrapper>
            );
          })}
        </ColourButtonGroup>

        {/* upload image */}
        <Box sx={styles.imageRow}>
          <Typography variant="body1" color="primary" sx={styles.uploadText}>
            Upload Image
          </Typography>

          <Button
            variant="outlined"
            component="label"
            sx={styles.uploadButton}
            fullWidth
          >
            {uploadedImage ? (
              <Fragment>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={styles.uploadButtonText}
                >
                  {uploadedImage.name}
                </Typography>
                <FileUpload />
              </Fragment>
            ) : (
              <Fragment>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={styles.uploadButtonText}
                >
                  Only JPEG, JPG, PNG accepted. Max file size 2 mb
                </Typography>
                <FileUpload />
              </Fragment>
            )}
            <input
              accept="image/*"
              type="file"
              onChange={handleImageUpload}
              hidden
            />
          </Button>
        </Box>
      </Box>

      {/* price */}
      <Box sx={styles.priceRow}>
        <Typography variant="body1" color="primary">
          Guild Creation
          <TooltipZolar link="https://docs.zolar.io/stations/guilds#guild-pricing" sx={styles.tooltip}>
            A Guild Crest will cost 3% of the circulating $HUNY supply + 1% per number of guilds already created, or 250,000 HUNY, whichever is higher.
          </TooltipZolar>
        </Typography>
        <Box sx={styles.price}>
          <Typography
            variant="body1"
            color="success.main"
            sx={{ fontWeight: 600 }}
          >
            est. ~ {toHumanNumber(bnOrZero(guildCrestPrice).shiftedBy(-Decimals.HUNY).toNumber())} HUNY&nbsp;
          </Typography>{" "}
          <Huny />
        </Box>
      </Box>

      {/* button box */}
      <Box display="flex" justifyContent="space-between" mt="60px">
        {/* back button */}
        <Button
          size="medium"
          variant="outlined"
          color="secondary"
          sx={styles.backButton}
          onClick={handleBack}
          disabled={showDialog}
        >
          <Typography variant="button" sx={styles.gradientText}>
            GO BACK
          </Typography>
        </Button>

        {/* create button */}
        <ContainedButton
          sx={styles.createButton}
          onClick={handleShowDialog}
          disabled={!isCreateEnabled || showDialog}
        >
          {loadingQueryGuildPrice ? <CircularProgress /> : "CREATE"}
        </ContainedButton>
      </Box>

      <CreateDialog
        open={showDialog}
        onClose={handleOnClose}
        formInputs={formInputs}
        uploadedImage={uploadedImage}
        crestPrice={guildCrestPrice}
      />
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  contentBox: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    "@media (min-width:600px)": {
      minWidth: "500px",
    },
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  tooltip: {
    padding: "20px",
    width: "420px",
    "& .MuiTypography-root": {
      fontSize: "18px",
      lineHeight: "28px",
    },
    "@media (max-width:600px)": {
      padding: "20px",
      width: "250px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  sectionHeaderText: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 0.8)",
    marginTop: "60px",
  },
  subheaderText: {
    fontSize: "14px",
    lineHeight: "28px",
    fontWeight: 600,
  },
  backButton: {
    boxShadow: "0px 0px 24px rgba(174, 241, 238, 0.12)",
    padding: "12px 24px",
    "@media (max-width:900px)": {
      flex: 1,
      marginRight: "10px",
    },
  },
  createButton: {
    minWidth: "280px",
    height: 64,
    "@media (max-width:900px)": {
      minWidth: "",
      flex: 1,
    },
  },
  gradientText: {
    background: "-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  guildCrestBox: {
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    borderRadius: "16px",
    marginTop: "60px",
    padding: "24px",
  },
  previewBox: {
    backgroundImage: `url(${GuildBannerPreview})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    minHeight: "340px",
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    paddingY: "40px",
  },
  priceRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "60px",
  },
  price: {
    display: "flex",
    whiteSpace: "nowrap",
    "& svg": {
      verticalAlign: "text-top",
      marginTop: "2px",
      height: 22,
      width: 22,
    },
    "@media (max-width:600px)": {
      "& svg": {
        marginTop: 0,
        height: "20px!important",
        width: "20px!important",
      }
    },
  },
  imageRow: {
    display: "flex",
    marginTop: "20px",
    alignItems: "center",
  },
  uploadText: {
    fontSize: "14px",
    lineHeight: "28px",
    width: "200px",
    color: "rgba(255, 255, 255, 0.8)",
  },
  uploadButton: {
    height: 48,
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 24px",
    textTransform: "none",
    "&:hover": {
      borderColor: "rgba(174, 241, 238, 0.1)",
    },
  },
  uploadButtonText: {
    fontSize: "12px!important",
    lineHeight: "18px!important",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 0.8)",
  },
  colourBox: {
    height: "42px",
    width: "42px",
    borderRadius: "50%",
  },
  crestImage: {
    height: "111.5px",
    width: "111.5px",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -55%)",
  },
  crestImageBig: {
    maxHeight: '200px',
    objectFit: 'cover',
    height: '320px',
    width: '320px',
    position: 'relative',
  },
  crestImageSmall: {
    height: "62.5px",
    width: "62.5px",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -57%)",
    opacity: "50%",
  },
  upgradeArrowBox: {
    display: "flex",
    alignItems: "center",
    "@media (max-width:1000px)": {
      display: "none",
    },
  },
  crestUpgrade: {
    marginLeft: "20px",
    marginRight: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:1000px)": {
      display: "none",
    },
  },
  opacity: {
    opacity: "50%",
  },
}

const ColourButtonGroup = styled(ToggleButtonGroup)({
  display: "flex",
  flexWrap: "wrap",
  columnGap: "30px",
  rowGap: "20px",
  marginTop: "25px",
  marginBottom: "10px",
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "50%",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "50%",
  },
});

const ColourWrapper = styled(ToggleButton)({
  display: "flex",
  justifyContent: "center",
  height: "54px",
  width: "54px",
  borderRadius: "50%",
  background: "transparent",
  paddingLeft: 0,
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 0,
  "&:hover": {
    background: "transparent",
  },
  "&.Mui-selected": {
    background: "transparent",
    borderRadius: "50%",
    border: "2px solid #27ED82",
    borderLeft: "2px solid #27ED82!important",
    "&:hover": {
      background: "transparent",
    },
  },
});

const CrestButtonGroup = styled(ToggleButtonGroup)({
  display: "flex",
  flexWrap: "wrap",
  columnGap: "40px",
  rowGap: "20px",
  marginTop: "25px",
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "16px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "16px",
  },
});

const CrestWrapper = styled(ToggleButton)({
  borderRadius: "16px",
  background: "transparent",
  paddingLeft: "2px",
  paddingTop: "2px",
  paddingBottom: 0,
  paddingRight: 0,
  justifyContent: "flex-start",
  "&:hover": {
    background: "transparent",
  },
  "#crestDesign": {
    width: '80px',
  },
  "#sigilCheckedIcon": {
    position: 'absolute',
    bottom: '-10px',
    right: '-10px',
    visibility: 'hidden',
  },
  "&.Mui-selected": {
    background: "transparent",
    "& #bannerImage": {
      borderRadius: "16px",
      outline: "2px solid #27ED82",
    },
    "#sigilCheckedIcon": {
      visibility: 'visible',
    },
    "& #crestDesign": {
      borderRadius: "16px",
      outline: "2px solid #27ED82",
    },
    "&:hover": {
      background: "transparent",
    },
  },
});

export default PageTwo;
