// banners
import BannerBlue1 from "assets/Banner1-Blue.svg";
import BannerGreen1 from "assets/Banner1-Green.svg";
import BannerGrey1 from "assets/Banner1-Grey.svg";
import BannerPurple1 from "assets/Banner1-Purple.svg";
import BannerRed1 from "assets/Banner1-Red.svg";
import BannerYellow1 from "assets/Banner1-Yellow.svg";
import BannerBlue2 from "assets/Banner2-Blue.svg";
import BannerGreen2 from "assets/Banner2-Green.svg";
import BannerGrey2 from "assets/Banner2-Grey.svg";
import BannerPurple2 from "assets/Banner2-Purple.svg";
import BannerRed2 from "assets/Banner2-Red.svg";
import BannerYellow2 from "assets/Banner2-Yellow.svg";
import BannerBlue3 from "assets/Banner3-Blue.svg";
import BannerGreen3 from "assets/Banner3-Green.svg";
import BannerGrey3 from "assets/Banner3-Grey.svg";
import BannerPurple3 from "assets/Banner3-Purple.svg";
import BannerRed3 from "assets/Banner3-Red.svg";
import BannerYellow3 from "assets/Banner3-Yellow.svg";
import BannerBlue4 from "assets/Banner4-Blue.svg";
import BannerGreen4 from "assets/Banner4-Green.svg";
import BannerGrey4 from "assets/Banner4-Grey.svg";
import BannerPurple4 from "assets/Banner4-Purple.svg";
import BannerRed4 from "assets/Banner4-Red.svg";
import BannerYellow4 from "assets/Banner4-Yellow.svg";
import BannerBlue5 from "assets/Banner5-Blue.svg";
import BannerGreen5 from "assets/Banner5-Green.svg";
import BannerGrey5 from "assets/Banner5-Grey.svg";
import BannerPurple5 from "assets/Banner5-Purple.svg";
import BannerRed5 from "assets/Banner5-Red.svg";
import BannerYellow5 from "assets/Banner5-Yellow.svg";
import BannerBlue6 from "assets/Banner6-Blue.svg";
import BannerGreen6 from "assets/Banner6-Green.svg";
import BannerGrey6 from "assets/Banner6-Grey.svg";
import BannerPurple6 from "assets/Banner6-Purple.svg";
import BannerRed6 from "assets/Banner6-Red.svg";
import BannerYellow6 from "assets/Banner6-Yellow.svg";
import Banner1Icon from "assets/icons/Banner1Icon.svg";
import Banner2Icon from "assets/icons/Banner2Icon.svg";
import Banner3Icon from "assets/icons/Banner3Icon.svg";
import Banner4Icon from "assets/icons/Banner4Icon.svg";
import Banner5Icon from "assets/icons/Banner5Icon.svg";
import Banner6Icon from "assets/icons/Banner6Icon.svg";
// crests
import GuardianSigilIcon from "assets/icons/Guardian.png";
import MercenarySigilIcon from "assets/icons/Mercenary.png";
import MerchantSigilIcon from "assets/icons/Merchant.png";
import CrestIconSimple1 from "assets/icons/Type1SimpleIcon.png";
import CrestIconSimple2 from "assets/icons/Type2SimpleIcon.png";
import CrestIconSimple3 from "assets/icons/Type3SimpleIcon.png";
import CrestIconSimple4 from "assets/icons/Type4SimpleIcon.png";
import WarriorSigilIcon from "assets/icons/Warrior.png";
// sigils
import GuardianBlue from "assets/Guardian-Blue.png";
import GuardianGreen from "assets/Guardian-Green.png";
import GuardianGrey from "assets/Guardian-Grey.png";
import GuardianPurple from "assets/Guardian-Purple.png";
import GuardianRed from "assets/Guardian-Red.png";
import GuardianYellow from "assets/Guardian-Yellow.png";
import MercenaryBlue from "assets/Mercenary-Blue.png";
import MercenaryGreen from "assets/Mercenary-Green.png";
import MercenaryGrey from "assets/Mercenary-Grey.png";
import MercenaryPurple from "assets/Mercenary-Purple.png";
import MercenaryRed from "assets/Mercenary-Red.png";
import MercenaryYellow from "assets/Mercenary-Yellow.png";
import MerchantBlue from "assets/Merchant-Blue.png";
import MerchantGreen from "assets/Merchant-Green.png";
import MerchantGrey from "assets/Merchant-Grey.png";
import MerchantPurple from "assets/Merchant-Purple.png";
import MerchantRed from "assets/Merchant-Red.png";
import MerchantYellow from "assets/Merchant-Yellow.png";
import MetazoaPreview from "assets/MetazoaPreview.gif";
import BlueComplex1 from "assets/Type1-Blue-Complex.png";
import BlueSimple1 from "assets/Type1-Blue-Simple.png";
import GreenComplex1 from "assets/Type1-Green-Complex.png";
import GreenSimple1 from "assets/Type1-Green-Simple.png";
import GreyComplex1 from "assets/Type1-Grey-Complex.png";
import GreySimple1 from "assets/Type1-Grey-Simple.png";
import PurpleComplex1 from "assets/Type1-Purple-Complex.png";
import PurpleSimple1 from "assets/Type1-Purple-Simple.png";
import RedComplex1 from "assets/Type1-Red-Complex.png";
import RedSimple1 from "assets/Type1-Red-Simple.png";
import YellowComplex1 from "assets/Type1-Yellow-Complex.png";
import YellowSimple1 from "assets/Type1-Yellow-Simple.png";
import BlueComplex2 from "assets/Type2-Blue-Complex.png";
import BlueSimple2 from "assets/Type2-Blue-Simple.png";
import GreenComplex2 from "assets/Type2-Green-Complex.png";
import GreenSimple2 from "assets/Type2-Green-Simple.png";
import GreyComplex2 from "assets/Type2-Grey-Complex.png";
import GreySimple2 from "assets/Type2-Grey-Simple.png";
import PurpleComplex2 from "assets/Type2-Purple-Complex.png";
import PurpleSimple2 from "assets/Type2-Purple-Simple.png";
import RedComplex2 from "assets/Type2-Red-Complex.png";
import RedSimple2 from "assets/Type2-Red-Simple.png";
import YellowComplex2 from "assets/Type2-Yellow-Complex.png";
import YellowSimple2 from "assets/Type2-Yellow-Simple.png";
import BlueComplex3 from "assets/Type3-Blue-Complex.png";
import BlueSimple3 from "assets/Type3-Blue-Simple.png";
import GreenComplex3 from "assets/Type3-Green-Complex.png";
import GreenSimple3 from "assets/Type3-Green-Simple.png";
import GreyComplex3 from "assets/Type3-Grey-Complex.png";
import GreySimple3 from "assets/Type3-Grey-Simple.png";
import PurpleComplex3 from "assets/Type3-Purple-Complex.png";
import PurpleSimple3 from "assets/Type3-Purple-Simple.png";
import RedComplex3 from "assets/Type3-Red-Complex.png";
import RedSimple3 from "assets/Type3-Red-Simple.png";
import YellowComplex3 from "assets/Type3-Yellow-Complex.png";
import YellowSimple3 from "assets/Type3-Yellow-Simple.png";
import BlueComplex4 from "assets/Type4-Blue-Complex.png";
import BlueSimple4 from "assets/Type4-Blue-Simple.png";
import GreenComplex4 from "assets/Type4-Green-Complex.png";
import GreenSimple4 from "assets/Type4-Green-Simple.png";
import GreyComplex4 from "assets/Type4-Grey-Complex.png";
import GreySimple4 from "assets/Type4-Grey-Simple.png";
import PurpleComplex4 from "assets/Type4-Purple-Complex.png";
import PurpleSimple4 from "assets/Type4-Purple-Simple.png";
import RedComplex4 from "assets/Type4-Red-Complex.png";
import RedSimple4 from "assets/Type4-Red-Simple.png";
import YellowComplex4 from "assets/Type4-Yellow-Complex.png";
import YellowSimple4 from "assets/Type4-Yellow-Simple.png";
import WarriorBlue from "assets/Warrior-Blue.png";
import WarriorGreen from "assets/Warrior-Green.png";
import WarriorGrey from "assets/Warrior-Grey.png";
import WarriorPurple from "assets/Warrior-Purple.png";
import WarriorRed from "assets/Warrior-Red.png";
import WarriorYellow from "assets/Warrior-Yellow.png";
import { TransactionType } from "store/types";
import { ContractsBech32, TokenAddressesBech32 } from "utils/constants";
import { Network } from "zilswap-sdk/lib/constants";

export const SIGILS = [
  [WarriorGrey, WarriorBlue, WarriorGreen, WarriorPurple, WarriorRed, WarriorYellow],
  [GuardianGrey, GuardianBlue, GuardianGreen, GuardianPurple, GuardianRed, GuardianYellow],
  [MercenaryGrey, MercenaryBlue, MercenaryGreen, MercenaryPurple, MercenaryRed, MercenaryYellow],
  [MerchantGrey, MerchantBlue, MerchantGreen, MerchantPurple, MerchantRed, MerchantYellow],
]

export const SIGIL_TITLE = {
  1: "Warrior",
  2: "Guardian",
  3: "Mercenary",
  4: "Merchant",
}

export const SIGIL_ICONS = [
  {
    name: "The Warrior",
    values: "Honorable & Mighty",
    image: WarriorSigilIcon,
  },
  {
    name: "The Guardian",
    values: "Steadfast & Loyal",
    image: GuardianSigilIcon,
  },
  {
    name: "The Mercenary",
    values: "Cunning & Ruthless",
    image: MercenarySigilIcon,
  },
  {
    name: "The Merchant",
    values: "Enterprising & Visionary",
    image: MerchantSigilIcon,
  }
];

export const SIGIL_COLOURS = [
  {
    colour: "grey",
    background: "linear-gradient(212.7deg, #FAFEFF 8.44%, #CDDCDE 42.25%, #788E8D 87.6%)",
  },
  {
    colour: "blue",
    background: "linear-gradient(225deg, #F3FFFE 27.08%, #3DFCF3 100%)",
  },
  {
    colour: "green",
    background: "linear-gradient(211.35deg, #E6FFF4 41.76%, #5BFEA6 115.54%)",
  },
  {
    colour: "purple",
    background: "linear-gradient(210.43deg, #F7EDFF 41.87%, #E386FF 88.01%)",
  },
  {
    colour: "red",
    background: "linear-gradient(212.7deg, #FFE9E4 37.3%, #FFB29A 87.6%)",
  },
  {
    colour: "yellow",
    background: "linear-gradient(212.7deg, #FFF4E2 8.44%, #FFDE89 87.6%)",
  },
];

export const BANNERS = [
  [BannerGrey1, BannerBlue1, BannerGreen1, BannerPurple1, BannerRed1, BannerYellow1],
  [BannerGrey2, BannerBlue2, BannerGreen2, BannerPurple2, BannerRed2, BannerYellow2],
  [BannerGrey3, BannerBlue3, BannerGreen3, BannerPurple3, BannerRed3, BannerYellow3],
  [BannerGrey4, BannerBlue4, BannerGreen4, BannerPurple4, BannerRed4, BannerYellow4],
  [BannerGrey5, BannerBlue5, BannerGreen5, BannerPurple5, BannerRed5, BannerYellow5],
  [BannerGrey6, BannerBlue6, BannerGreen6, BannerPurple6, BannerRed6, BannerYellow6],
]

export const BANNER_ICONS = [
  {
    name: "Banner 1",
    image: Banner1Icon,
  },
  {
    name: "Banner 2",
    image: Banner2Icon,
  },
  {
    name: "Banner 3",
    image: Banner3Icon,
  },
  {
    name: "Banner 4",
    image: Banner4Icon,
  },
  {
    name: "Banner 5",
    image: Banner5Icon,
  },
  {
    name: "Banner 6",
    image: Banner6Icon,
  },
];

export const BANNER_COLOURS = [
  {
    colour: "grey",
    background: "linear-gradient(212.7deg, #FAFEFF 8.44%, #CDDCDE 42.25%, #788E8D 87.6%)",
  },
  {
    colour: "blue",
    background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
  },
  {
    colour: "green",
    background: "linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)",
  },
  {
    colour: "purple",
    background: "linear-gradient(210.43deg, #F7EDFF 10.98%, #E386FF 35.49%, #5D1699 88.01%)",
  },
  {
    colour: "red",
    background: "linear-gradient(216.51deg, #FFE9DE 12.79%, #FF8952 51.3%, #FF5252 85.76%)",
  },
  {
    colour: "yellow",
    background: "linear-gradient(212.7deg, #FFF4E2 8.44%, #FFED81 45.55%, #E79D04 87.6%)"
  },
];

export const CRESTS = [
  [GreySimple1, BlueSimple1, GreenSimple1, PurpleSimple1, RedSimple1, YellowSimple1],
  [GreySimple2, BlueSimple2, GreenSimple2, PurpleSimple2, RedSimple2, YellowSimple2],
  [GreySimple3, BlueSimple3, GreenSimple3, PurpleSimple3, RedSimple3, YellowSimple3],
  [GreySimple4, BlueSimple4, GreenSimple4, PurpleSimple4, RedSimple4, YellowSimple4],
]

export const CREST_UPGRADES = [
  [GreyComplex1, BlueComplex1, GreenComplex1, PurpleComplex1, RedComplex1, YellowComplex1],
  [GreyComplex2, BlueComplex2, GreenComplex2, PurpleComplex2, RedComplex2, YellowComplex2],
  [GreyComplex3, BlueComplex3, GreenComplex3, PurpleComplex3, RedComplex3, YellowComplex3],
  [GreyComplex4, BlueComplex4, GreenComplex4, PurpleComplex4, RedComplex4, YellowComplex4],
]

export const CREST_COLOURS = [
  "grey",
  "blue",
  "green",
  "purple",
  "red",
  "yellow",
];

export const CREST_COLOUR_SELECTOR = [
  {
    colour: "grey",
    background: "linear-gradient(212.7deg, #FAFEFF 8.44%, #CDDCDE 42.25%, #788E8D 87.6%)",
  },
  {
    colour: "blue",
    background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
  },
  {
    colour: "green",
    background: "linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)",
  },
  {
    colour: "purple",
    background: "linear-gradient(210.43deg, #F7EDFF 10.98%, #E386FF 35.49%, #5D1699 88.01%)",
  },
  {
    colour: "red",
    background: "linear-gradient(216.51deg, #FFE9DE 12.79%, #FF8952 51.3%, #FF5252 85.76%)",
  },
  {
    colour: "yellow",
    background: "linear-gradient(212.7deg, #FFF4E2 8.44%, #FFED81 45.55%, #E79D04 87.6%)"
  },
];

export const CREST_ICONS = [
  {
    name: "Crest 1",
    image: CrestIconSimple1,
  },
  {
    name: "Crest 2",
    image: CrestIconSimple2,
  },
  {
    name: "Crest 3",
    image: CrestIconSimple3,
  },
  {
    name: "Crest 4",
    image: CrestIconSimple4,
  }
]

export const DEFAULT_SRC = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  e.currentTarget.onerror = null;
  e.currentTarget.src = MetazoaPreview;
}

export const GUILD_GRAY_GRADIENT = {
  color: "transparent!important",
  background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)!important',
  backgroundClip: 'text!important',
  textFillFolor: 'transparent!important',
}

export const GUILD_LIGHTGRAY_GRADIENT = {
  backgroundClip: 'text!important',
  textFillColor: 'transparent!important',
  background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 49.48%, rgba(255, 255, 255, 0.64) 100%)',
}
export const GUILD_LIGHTYELLOW_GRADIENT = {
  backgroundClip: 'text!important',
  textFillColor: 'transparent!important',
  background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.8) 0%, #FCCE58 49.48%, rgba(255, 255, 255, 0.64) 100%)',
}

export const GUILD_GREEN_GRADIENT = {
  color: "transparent!important",
  background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
  backgroundClip: 'text!important',
  textFillColor: 'transparent!important',
}
export const GUILD_DEEPGRAY_GRADIENT = {
  color: "transparent!important",
  background: 'linear-gradient(180deg, #010025 0%, #000000 100%)',
  backgroundClip: 'text!important',
  textFillColor: 'transparent!important',
}


export const DISCORD_INVITE = "https://discord.com/invite/zilswap"

export const TX_TAGS: TransactionTags = {
  [TransactionType.PaidJoiningFee]: {
    label: "Joining Fee",
    background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)"
  },
  [TransactionType.CollectTax]: {
    label: "Weekly Tax",
    background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)"
  },
  [TransactionType.ClaimHive]: {
    label: "Rewards (Hive)",
    background: "linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)"
  },
  [TransactionType.ClaimRefinery]: {
    label: "Rewards (Refinery)",
    background: "radial-gradient(93.75% 198.35% at 93.75% 18.18%, #FFFFFF 0%, #FFB600 32.81%, #FF8952 100%)"
  },
  [TransactionType.MakeDonation]: {
    label: "Donation",
    background: "linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)"
  },
  [TransactionType.UpdateBankSettings]: {
    label: "Bank Changes",
    background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)"
  },
  [TransactionType.Withdraw]: {
    label: "Withdrawal",
    background: "radial-gradient(93.75% 198.35% at 93.75% 18.18%, #FFFFFF 0%, #FFB600 32.81%, #FF8952 100%)"
  },
  [TransactionType.DepositHive]: {
    label: "To Magic Hive",
    background: "radial-gradient(93.75% 198.35% at 93.75% 18.18%, #FFFFFF 0%, #FFB600 32.81%, #FF8952 100%)"
  },
  [TransactionType.WithdrawHive]: {
    label: "Remove from Hive",
    background: "radial-gradient(93.75% 198.35% at 93.75% 18.18%, #FFFFFF 0%, #FFB600 32.81%, #FF8952 100%)"
  },
  [TransactionType.ToRefinery]: {
    label: "To Refinery",
    background: "radial-gradient(93.75% 198.35% at 93.75% 18.18%, #FFFFFF 0%, #FFB600 32.81%, #FF8952 100%)"
  },
}

type TransactionTags = {
  [type in TransactionType]: {
    label: string;
    background: string;
  };
};

export const TOKEN_SYMBOLS = {
  [Network.MainNet]: {
    [ContractsBech32[Network.MainNet].HunyToken]: "HUNY",
    [TokenAddressesBech32[Network.MainNet].ZIL]: "ZIL",
    [TokenAddressesBech32[Network.MainNet].ZWAP]: "ZWAP",
  },
  [Network.TestNet]: {
    [ContractsBech32[Network.TestNet].HunyToken]: "HUNY",
    [TokenAddressesBech32[Network.TestNet].ZIL]: "ZIL",
    [TokenAddressesBech32[Network.TestNet].ZWAP]: "ZWAP",
  },
}

export enum MultisigSetting {
  CaptainOnly = "Only the Captain's signature is",
  CaptainAndOneOfficer = "Captain And 1 Officer's signature are",
  CaptainAndTwoOfficers = "Captain And 2 Officer's signature are",
}

export const convertBpsAllocation = (allocation: number) => allocation / 100;