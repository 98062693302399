import { logger } from "core/utilities";
import { call, delay, fork, put, race, select, take } from "redux-saga/effects";
import { waitForConnectorInit, waitForNetworkChange } from "saga/helpers";
import { getWallet } from "saga/selectors";
import { actions } from "store";
import { HiveInfo, HivePoolStats } from "store/types";
import { TBMConnector } from "tbm";

function* watchHivePool() {
  while (true) {
    try {
      yield waitForConnectorInit();
      const { wallet } = getWallet(yield select());
      const network = TBMConnector.network();
      const hivePool: HivePoolStats = yield call(TBMConnector.fetchHivePool, wallet ?? undefined);
      logger("fetch hive pool", hivePool);

      yield put(actions.Token.updateHivePool(hivePool));

      yield race({
        delay: delay(300000),
        reload: take(actions.Token.TokenActionTypes.RELOAD_HIVE_POOL),
        network: waitForNetworkChange(network),
        wallet: take(actions.Wallet.WalletActionTypes.WALLET_UPDATE),
      });
    } catch (error) {
      console.error("failed to load hive pool");
      console.error(error);
      yield delay(3000);
    } finally {
    }
  }
}

function* watchHiveInfo() {
  while (true) {
    try {
      yield waitForConnectorInit();
      const network = TBMConnector.network();
      const hiveInfo: HiveInfo = yield call(TBMConnector.fetchHiveInfo);
      logger("fetch hive info", hiveInfo);

      yield put(actions.Token.updateHiveInfo(hiveInfo));

      yield race({
        reload: take(actions.Token.TokenActionTypes.RELOAD_HIVE_INFO),
        network: waitForNetworkChange(network),
      });
    } catch (error) {
      console.error("failed to load hive pool");
      console.error(error);
      yield delay(3000);
    } finally {
    }
  }
}

export default function* hiveSaga() {
  logger("init hive saga");
  yield fork(watchHivePool);
  yield fork(watchHiveInfo);
}
