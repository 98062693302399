import dayjs, { Dayjs } from "dayjs";
import BigNumber from "bignumber.js";
import { LocalStorageKeys, MissionGroundResource } from "../../utils/constants";
import { SimpleMap } from "../../utils/types";
import { TokenActionTypes } from "./actions";
import { NftMetadata, TokenState } from "./types";

// revealed tokens
const savedReveal = localStorage.getItem(LocalStorageKeys.RevealedToken);
let storedRevealedToken: SimpleMap<Dayjs> = {}

try {
  if (savedReveal) {
    const storedData = JSON.parse(savedReveal);
    for (const key in storedData) {
      storedRevealedToken[key] = dayjs(storedData[key])
    }
  }
} catch (error) {
  console.error(error);
}

// transcended tokens
const savedTranscendedTokens = localStorage.getItem(LocalStorageKeys.TranscendedTokens);
let storedTranscendedTokens: SimpleMap<NftMetadata> = {};
try {
  if (savedTranscendedTokens) {
    const storedData = JSON.parse(savedTranscendedTokens);
    for (const key in storedData) {
      storedTranscendedTokens[key] = storedData[key];
    }
  }
} catch (error) {
  console.error(error);
}

const initial_state: TokenState = {
  currentMinting: null,
  tokens: {},
  metazoaTokens: {},
  mintedTokensCount: 0,
  transcendedTokens: storedTranscendedTokens ?? {},
  revealedTokens: storedRevealedToken ?? {},
  whitelistCount: 0,
  transcendenceApproved: false,
  totalSupply: 0,
  saleActive: false,

  // Phase 3
  goldRushTotalSupply: 0,
  goldRushCurrentSupply: 0,
  goldRushSaleActive: false,
  goldRushSalePrice: 0,

  // Phase 4
  moonBattleInfo: {
    summonCount: 0,
    whitelistCount: 0,
    capturedHunyPerShare: new BigNumber(0),
    totalShares: 1,
    capturedHunyDebt: {},
  },

  // Game
  trappedMetazoa: {},
  stakedMetazoa: {},
  metazoaBlocksStaked: {},
  stakedMetazoaBerry: {},
  stakedMetazoaGeode: {},
  stakedMetazoaScrap: {},

  // Huny
  TotalHunySupply: 0,
  HunyTokens: 0,
  hunyToRecover: {
    hunyReceiveNow: new BigNumber(0),
    hunyToRefinery: new BigNumber(0),
  },

  // Zil
  ZilTokens: 0,
  GasFee: 0,
  ExchangeRates: {
    zilPrice: new BigNumber(0),
    hunyPrice: new BigNumber(0),
  },

  // Refinery
  hunyPots: {},

  resources: {
    [MissionGroundResource.Elderberries]: new BigNumber(0),
    [MissionGroundResource.Geodes]: new BigNumber(0),
    [MissionGroundResource.ZolraniumScraps]: new BigNumber(0),
    gems: {},
    crackedGems: {},
    consumables: {},
    ordnances: {},
  }
}

const reducer = (state: TokenState = initial_state, action: any): TokenState => {
  const { payload } = action;

  switch (action.type) {
    case TokenActionTypes.SET_TOKENS:
      let newTokens: SimpleMap<NftMetadata> = {};
      payload.forEach((id: string) => {
        newTokens[id] = { id };
      });
      return {
        ...state,
        tokens: {
          ...newTokens,
        }
      }
    case TokenActionTypes.UPDATE_TOKENS:
      let updateTokens: SimpleMap<NftMetadata> = {};
      payload.forEach((token: NftMetadata) => {
        updateTokens[token.id] = token;
      });
      return {
        ...state,
        tokens: {
          ...state.tokens,
          ...updateTokens,
        }
      }
    case TokenActionTypes.CLEAR_TOKENS:
      return {
        ...state,
        tokens: {},
      }
    case TokenActionTypes.REVEAL_TOKENS:
      const newRevealedTokens = {
        ...state.revealedTokens,
        ...payload,
      }
      localStorage.setItem(LocalStorageKeys.RevealedToken, JSON.stringify(newRevealedTokens));
      return {
        ...state,
        revealedTokens: newRevealedTokens
      }
    case TokenActionTypes.UPDATE_CURRENT_MINTING:
      let newMinting = state.currentMinting;
      if (!payload) newMinting = null;
      else newMinting = { ...newMinting, ...payload };
      return {
        ...state,
        currentMinting: newMinting
      }
    case TokenActionTypes.SET_WHITELIST_COUNT:
      return {
        ...state,
        whitelistCount: payload,
      }
    case TokenActionTypes.SET_METAZOA:
      let newMetazoa: SimpleMap<NftMetadata> = {};
      payload.forEach((id: string) => {
        newMetazoa[id] = { id };
      });
      return {
        ...state,
        metazoaTokens: {
          ...newMetazoa,
        }
      }
    case TokenActionTypes.UPDATE_METAZOA:
      let updateMetazoa: SimpleMap<NftMetadata> = {};
      let updateStakedMetazoa: SimpleMap<NftMetadata> = {};
      let updateStakedBerry: SimpleMap<NftMetadata> = {};
      let updateStakedGeode: SimpleMap<NftMetadata> = {};
      let updateStakedScrap: SimpleMap<NftMetadata> = {};
      let updateTrappedMetazoa: SimpleMap<NftMetadata> = {};
      payload.forEach((token: NftMetadata) => {
        if (state.metazoaTokens[token.id]) {
          const tokenWithLocation = { ...token, location: 'At Base' }
          updateMetazoa[token.id] = tokenWithLocation;
        }
      });
      payload.forEach((token: NftMetadata) => {
        if (state.stakedMetazoa[token.id]) {
          const tokenWithLocation = { ...token, location: 'Moonbattle' }
          updateStakedMetazoa[token.id] = tokenWithLocation;
        }
      });
      payload.forEach((token: NftMetadata) => {
        if (state.stakedMetazoaBerry[token.id]) {
          const tokenWithLocation = { ...token, location: 'Elder Woodlands' }
          updateStakedBerry[token.id] = tokenWithLocation;
        }
      });
      payload.forEach((token: NftMetadata) => {
        if (state.stakedMetazoaGeode[token.id]) {
          const tokenWithLocation = { ...token, location: 'Zolar Asteroid Belt' }
          updateStakedGeode[token.id] = tokenWithLocation;
        }
      });
      payload.forEach((token: NftMetadata) => {
        if (state.stakedMetazoaScrap[token.id]) {
          const tokenWithLocation = { ...token, location: 'Moon Battlegrounds' }
          updateStakedScrap[token.id] = tokenWithLocation;
        }
      });
      payload.forEach((token: NftMetadata) => {
        if (state.trappedMetazoa[token.id]) updateTrappedMetazoa[token.id] = token;
      });
      return {
        ...state,
        metazoaTokens: {
          ...updateMetazoa,
        },
        stakedMetazoa: {
          ...updateStakedMetazoa,
        },
        stakedMetazoaBerry: {
          ...updateStakedBerry,
        },
        stakedMetazoaGeode: {
          ...updateStakedGeode,
        },
        stakedMetazoaScrap: {
          ...updateStakedScrap,
        },
        trappedMetazoa: {
          ...updateTrappedMetazoa,
        }
      }
    case TokenActionTypes.UPDATE_METAZOA_PROFESSION: {
      return {
        ...state,
        metazoaTokens: {
          ...payload,
        }
      }
    }
    case TokenActionTypes.UPDATE_STAKED_METAZOA: {
      return {
        ...state,
        stakedMetazoa: {
          ...payload,
        }
      }
    }
    case TokenActionTypes.UPDATE_STAKED_METAZOA_BERRY: {
      return {
        ...state,
        stakedMetazoaBerry: {
          ...payload,
        }
      }
    }
    case TokenActionTypes.UPDATE_STAKED_METAZOA_GEODE: {
      return {
        ...state,
        stakedMetazoaGeode: {
          ...payload,
        }
      }
    }
    case TokenActionTypes.UPDATE_STAKED_METAZOA_SCRAP: {
      return {
        ...state,
        stakedMetazoaScrap: {
          ...payload,
        }
      }
    }
    case TokenActionTypes.SAVE_TRANSCENDED_TOKENS:
      let newTranscendedTokens: SimpleMap<NftMetadata> = {};
      payload.forEach((token: NftMetadata) => {
        newTranscendedTokens[token.id] = token;
      });
      localStorage.setItem(LocalStorageKeys.TranscendedTokens, JSON.stringify(newTranscendedTokens));
      return {
        ...state,
        transcendedTokens: {
          ...state.transcendedTokens,
          ...newTranscendedTokens,
        }
      }
    case TokenActionTypes.UPDATE_TRANSCENDENCE_APPROVAL:
      return {
        ...state,
        transcendenceApproved: payload
      }
    case TokenActionTypes.SET_MINTED_TOKENS_COUNT:
      return {
        ...state,
        mintedTokensCount: payload,
      }
    case TokenActionTypes.UPDATE_SUPPLY:
      return {
        ...state,
        ...payload
      }
    // Phase 3
    case TokenActionTypes.UPDATE_GOLD_RUSH_SUPPLY:
      return {
        ...state,
        ...payload
      }
    case TokenActionTypes.UPDATE_GOLD_RUSH_SALE:
      return {
        ...state,
        goldRushSaleActive: payload
      }
    case TokenActionTypes.SET_GOLD_RUSH_SALE_PRICE:
      return {
        ...state,
        goldRushSalePrice: payload
      }
    case TokenActionTypes.UPDATE_MOON_BATTLE_INFO:
      return {
        ...state,
        moonBattleInfo: payload
      }
    case TokenActionTypes.SET_TRAPPED_METAZOA:
      let trappedMetazoa: SimpleMap<NftMetadata> = {};
      payload.forEach((id: string) => {
        trappedMetazoa[id] = { id };
      });
      return {
        ...state,
        trappedMetazoa: {
          ...trappedMetazoa,
        }
      }
    case TokenActionTypes.SET_STAKED_METAZOA:
      let stakedMetazoa: SimpleMap<NftMetadata> = {};
      payload.forEach((id: string) => {
        stakedMetazoa[id] = { id };
      });
      return {
        ...state,
        stakedMetazoa: {
          ...stakedMetazoa,
        }
      }
    case TokenActionTypes.SET_STAKED_METAZOA_BERRY:
      let stakedMetazoaBerry: SimpleMap<NftMetadata> = {};
      payload.forEach((id: string) => {
        stakedMetazoaBerry[id] = { id };
      });
      return {
        ...state,
        stakedMetazoaBerry: {
          ...stakedMetazoaBerry,
        }
      }
    case TokenActionTypes.SET_STAKED_METAZOA_GEODE:
      let stakedMetazoaGeode: SimpleMap<NftMetadata> = {};
      payload.forEach((id: string) => {
        stakedMetazoaGeode[id] = { id };
      });
      return {
        ...state,
        stakedMetazoaGeode: {
          ...stakedMetazoaGeode,
        }
      }
    case TokenActionTypes.SET_STAKED_METAZOA_SCRAP:
      let stakedMetazoaScrap: SimpleMap<NftMetadata> = {};
      payload.forEach((id: string) => {
        stakedMetazoaScrap[id] = { id };
      });
      return {
        ...state,
        stakedMetazoaScrap: {
          ...stakedMetazoaScrap,
        }
      }
    case TokenActionTypes.UPDATE_METAZOA_BLOCKS_STAKED:
      return {
        ...state,
        metazoaBlocksStaked: {
          ...payload
        }
      }

    case TokenActionTypes.UPDATE_HUNY_TOKEN_SUPPLY: {
      return {
        ...state,
        TotalHunySupply: payload,

      }
    }

    case TokenActionTypes.UPDATE_OWNED_HUNY_TOKEN: {
      return {
        ...state,
        HunyTokens: payload,
      }
    }

    case TokenActionTypes.UPDATE_ZIL_TOKEN: {
      return {
        ...state,
        ZilTokens: payload,
      }
    }

    case TokenActionTypes.FETCH_GAS_FEE: {
      return {
        ...state,
        GasFee: payload,
      }
    }

    case TokenActionTypes.FETCH_EXCHANGE_RATES: {
      return {
        ...state,
        ExchangeRates: {
          zilPrice: payload.zilliqa,
          hunyPrice: payload.huny,
        },
      }
    }

    case TokenActionTypes.UPDATE_HUNY_POTS: {
      return {
        ...state,
        hunyPots: payload
      }
    }

    case TokenActionTypes.UPDATE_REFINERY_CONFIG: {
      return {
        ...state,
        refineryConfig: payload
      }
    }

    case TokenActionTypes.UPDATE_REFINERY_HUNY_STATS: {
      return {
        ...state,
        refineryHunyStats: payload
      }
    }

    case TokenActionTypes.UPDATE_HIVE_INFO: {
      return {
        ...state,
        hiveInfo: payload
      }
    }

    case TokenActionTypes.UPDATE_HIVE_POOL: {
      return {
        ...state,
        hivePool: payload
      }
    }

    case TokenActionTypes.UPDATE_HUNY_TO_RECOVER: {
      return {
        ...state,
        hunyToRecover: payload
      }
    }

    case TokenActionTypes.UPDATE_RESOURCES: {
      return {
        ...state,
        resources: payload
      }
    }

    default:
      return state;
  }
}

export default reducer
