import { GameActionTypes } from "./actions";
import { GameState } from "./types";

const initial_state: GameState = {  
  commanders: undefined,
  tokenTraits: undefined,
  hunyHolders: undefined,
  totalHuny: undefined,
  liveEvents: [],
}

const reducer = (state: GameState = initial_state, action: any): GameState => {
  const { payload, type } = action;
  switch (type) {
    case GameActionTypes.UPDATE:
      return {
        ...state,
        ...payload,
      }
    case GameActionTypes.NEW_EVENT:
      if (!payload) return state;
      return {
        ...state,
        liveEvents: [payload, ...state.liveEvents].slice(0, 10)
      }

    default:
      return state;
  }
}

export default reducer;
