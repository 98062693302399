import { Box, CircularProgress, FormControlLabel, Link, Radio, RadioGroup, SxProps, Typography } from "@mui/material";
import { RoundedCheckedIcon, RoundedUncheckedIcon } from "assets";
import ContainedButton from "components/ContainedButton";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { MetazoaClient } from "core/utilities/metazoa";
import dayjs from "dayjs";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { NftMetadata, OAuth } from "store/types";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { PROFESSIONS, ProfessionTypes } from "./MetazoaConstants";

export interface Props {
  token: NftMetadata;
  professionSelection: string;
  setProfessionSelection: React.Dispatch<React.SetStateAction<string>>;
}

const ProfessionAssignment: FC<Props> = (props: Props) => {
  const { token, professionSelection, setProfessionSelection } = props;

  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();
  const wallet = useRedux((state) => state.wallet.wallet);
  const metazoaList = useRedux((state) => state.token.metazoaTokens);
  const stakedList = useRedux((state) => state.token.stakedMetazoa);
  const stakedBerryList = useRedux((state) => state.token.stakedMetazoaBerry);
  const stakedGeodeList = useRedux((state) => state.token.stakedMetazoaGeode);
  const stakedScrapList = useRedux((state) => state.token.stakedMetazoaScrap);
  const metazoaProfileState = useSelector(getProfile);
  // STATES / HOOKS -----------------

  const [runProfessionAssignment, loadingProfessionAssignment] = useAsyncTask("create", (error) => {
    // onClose();
    toaster(error?.message ?? "Error updating profession");
  });


  // FUNCTIONS ----------------------

  const requestProfessionAssignment = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!token || !professionSelection) return;
    if (!network) throw new Error("Zilswap not intitiated");
    if (!!token.profession) throw new Error("Metazoa already has profession!");

    try {
      const metazoaClient = new MetazoaClient(network);
      const { oAuth } = metazoaProfileState;
      let checkedOAuth: OAuth | undefined = oAuth;
      if (!oAuth?.access_token || oAuth.address !== wallet?.addressInfo.bech32 || (oAuth && dayjs(oAuth?.expires_at * 1000).isBefore(dayjs()))) {
        const { result } = await metazoaClient.metazoaLogin(wallet!, window.location.hostname);
        dispatch(actions.Profile.updateAccessToken(result));
        checkedOAuth = result;
      }

      if (!!professionSelection) {
        const result = await metazoaClient.selectMetazoaProfession(token, professionSelection as any, checkedOAuth!);
        if (!!result.result.model.profession) {
          const metazoaClient = new MetazoaClient(network);
          const result = await metazoaClient.fetchMetazoaProfession(token);
          const professionResult = result.result.metazoa.profession as string;

          let tokenCollection: SimpleMap<NftMetadata> = {} as SimpleMap<NftMetadata>;
          switch (token.location) {
            case "Moonbattle": {
              tokenCollection = stakedList;
              break;
            }
            case "Elder Woodlands": {
              tokenCollection = stakedBerryList;
              break;
            }
            case "Zolar Asteroid Belt": {
              tokenCollection = stakedGeodeList;
              break;
            }
            case "Moon Battlegrounds": {
              tokenCollection = stakedScrapList;
              break;
            }
            // At Base
            default: {
              tokenCollection = metazoaList;
              break;
            }
          }
          tokenCollection[token.id].profession = professionResult;
          dispatch(actions.Token.updateMetazoaProfession(tokenCollection)); // update store
          toaster(`Profession Selected!`);
        }
      }
    } catch (error: any) {
      toaster(`Error selecting profession: ${error.message}`);
    }
    finally {
      setProfessionSelection('');
    }
  }

  // EVENT HANDLERS -----------------

  const handleProfessionSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfessionSelection(event.target.value)
  }

  const handleProfessionAssignment = () => {
    runProfessionAssignment(async () => {
      await requestProfessionAssignment();
    })
  }

  // --------------------------------
  const isLoading: boolean = loadingProfessionAssignment;

  return (
    <Box>
      <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Assign Profession</Typography>
      <Typography variant="body1" color="primary" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
        Assign a profession to your Metazoa!<br />
        Note that the assignment is final and cannot be changed.&nbsp;
        <Link
          target="_blank"
          href="https://docs.zolar.io/metazoa/professions-new"
        >
          Learn More
        </Link>
      </Typography>

      <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader} mt={'1em'}>Profession</Typography>

      <RadioGroup
        name={"ProfessionType"}
        // defaultValue={PROFESSIONS[Object.keys(ProfessionTypes)[0]].stat}
        onChange={handleProfessionSelection}
        sx={styles.settingsRadio}>
        {Object.keys(ProfessionTypes).map((option, idx: number) => (
          <FormControlLabel
            key={idx}
            control={
              <Radio
                checked={professionSelection === PROFESSIONS[option].stat}
                value={PROFESSIONS[option].stat}
                icon={<RoundedUncheckedIcon width="24px" height="24px" />}
                checkedIcon={<RoundedCheckedIcon width="24px" height="24px" />}
              />}
            label={`${option} (Resource: ${PROFESSIONS[option].resource})`} sx={styles.optionLabel} />
        ))}
      </RadioGroup>

      <ContainedButton
        disabled={isLoading || !professionSelection?.length}
        sx={styles.confirmButton}
        onClick={handleProfessionAssignment}
      >
        {isLoading
          ? (<CircularProgress size={18} />)
          : ('Confirm & Assign')}
      </ContainedButton>
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  statTextSize: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
  statDetailsHeader: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    marginBottom: '8px',
  },
  settingsRadio: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: "start",
  },
  optionLabel: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeContent: "start",
    placeItems: 'center',
    // padding: '0 5%',
    gap: '8px',

    "& span": {
      fontSize: "12px",
      lineHeight: "24px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      whiteSpace: 'nowrap',
    }
  },
  confirmButton: {
    height: 50,
    flexGrow: 1,
    flexBasis: 0,
    width: '100%',

    marginTop: '27px',
    // minWidth: 270,

    "&.MuiButton-root": {
      fontWeight: 700,
      fontSize: "18px",
      whiteSpace: "nowrap",
    },

    "@media (max-width:600px)": {
      width: '100%',
    }
  },
}


export default ProfessionAssignment;