import { Network } from "zilswap-sdk/lib/constants";
import { ChainInitProps } from './types';

export const BlockchainActionTypes = {
  READY: "CHAIN_READY",
  INITIALIZED: "CHAIN_INITIALIZED",
  CHAIN_INIT: "CHAIN_INIT",
  SET_NETWORK: "SET_NETWORK",
  UPDATE_SALE_STATE: "CHAIN_UPDATE_SALE_STATE",
  SET_TRANSCENDENCE_START_BLOCK: "SET_TRANSCENDENCE_START_BLOCK",
};

// inform app that blockchain saga has been started
export function ready() {
  return { type: BlockchainActionTypes.READY }
}

// inform other sagas that first blockchain state load has been completed
export function initialized() {
  return { type: BlockchainActionTypes.INITIALIZED }
}

export type ChainInitAction = ReturnType<typeof initialize>
export function initialize(payload: ChainInitProps) {
  return {
    type: BlockchainActionTypes.CHAIN_INIT,
    payload
  }
};

export function setNetwork(network: Network) {
  return {
    type: BlockchainActionTypes.SET_NETWORK,
    network,
  }
};

export function updateSaleState() {
  return {
    type: BlockchainActionTypes.UPDATE_SALE_STATE,
  }
};

export const setTranscendenceStartBlock = (payload: number) => ({
  type: BlockchainActionTypes.SET_TRANSCENDENCE_START_BLOCK,
  payload
})