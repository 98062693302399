import { Box, Divider, Grow, Link, SxProps, Typography } from "@mui/material";
import WarningIcon from "assets/icons/warning.svg";
import Rocket from "assets/RocketIcon.svg";
import ContainedButton from "components/ContainedButton";
import { useBlockTime } from "utils";
import { BLOCKS_PER_MINUTE, RescueShipStart } from "utils/constants";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import useRedux from "utils/useRedux";

const RescueShipBanner: React.FC = () => {
  const network = useNetwork();
  const stakedMetazoa = useRedux((state) => state.token.stakedMetazoa);
  const [blockTime, currentBlock, currentTime] = useBlockTime();

  const Countdown = () => {
    const blocksLeft = RescueShipStart[network] - currentBlock
    if (blocksLeft <= 0) return null;

    const startTime = blockTime.add(blocksLeft / BLOCKS_PER_MINUTE, 'minute')
    const secondsLeft = startTime.diff(currentTime, 'second')

    if (blocksLeft > 0) {
      const minutesLeft = ~~(secondsLeft % 3600 / 60);
      const hoursLeft = ~~((secondsLeft % 86400) / 3600)
      const daysLeft = ~~(secondsLeft / 86400)
      return (
        <Typography sx={styles.fontResize} component="span">
          {daysLeft.toLocaleString('en-US', { minimumIntegerDigits: 2 })} days, {
            hoursLeft.toLocaleString('en-US', { minimumIntegerDigits: 2 })} hours and {
            minutesLeft.toLocaleString('en-US', { minimumIntegerDigits: 2 })} minutes.
        </Typography>
      );
    }
    else return null;
  }

  return (
    <Grow
      mountOnEnter
      unmountOnExit
      in={true}
      style={{ transformOrigin: '0 0 0' }}
      {...(true ? { timeout: 1000 } : {})}
    >
      <Box sx={styles.container}>
        <Box sx={styles.row}>
          <Box sx={styles.leftColumn}>
            <Box
              component="img"
              src={WarningIcon}
              alt="warning icon"
              height="24px"
              width="24px"
              sx={styles.icon}
            />
            <Box>
              <Typography variant="body1" color="primary" component="span" sx={styles.fontResize}>
                <strong>Looks like you have&nbsp;
                  <Typography variant="body1" color="success.main" component="span">
                    {Object.values(stakedMetazoa).length}
                  </Typography>
                  &nbsp;Metazoa fending for themselves in Mission 1.</strong>
              </Typography>
              <br />
              <Typography variant="body1" color="primary" component="span" sx={styles.fontResize}>
                Return them to base now and participate in Mission 2 OR wait for the rescue ship!&nbsp;
              </Typography>
              <Divider sx={styles.divider} />
              <Typography variant="body1" color="primary" component="span" sx={combineStyles(styles.fontResize, { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' })}>
                Rescue ship will arrive in&nbsp;
                <Typography variant="body1" color="success.main" component="span" sx={combineStyles(styles.fontResize, { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5em' })}>
                  <Box
                    component="img"
                    src={Rocket}
                    alt="rocket"
                    width="36px"
                    height="36px"
                  />{Countdown()}
                </Typography>&nbsp;
                <Link href="https://docs.zolar.io/missions/mission-ii-the-right-to-bear-arms-new#for-mission-i-commanders" target="_blank">
                  <strong>Learn more</strong>
                </Link>
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.rightColumn}>
            <ContainedButton sx={styles.button} href="/game">Head to Mission 1</ContainedButton>
          </Box>
        </Box>
      </Box>
    </Grow>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    width: '100%',
    padding: '24px',
    marginBottom: '20px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)',
    border: '1px solid #AEF1EE33',
    borderRadius: '16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    "@media(max-width: 768px)": {
      flexDirection: 'column',
    }
  },
  leftColumn: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    "@media(max-width: 768px)": {
      width: '100%',
    }
  },
  icon: {
    marginRight: '16px',
  },
  fontResize: {
    fontSize: '1rem',
  },

  divider: {
    marginY: '8px',
    borderColor: '#FFFFFF52',
    background: '#FFFFFF52',
    flex: '1 1 0%',
  },
  rightColumn: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "@media(max-width: 768px)": {
      width: '100%',
      justifyContent: 'left',
    }
  },

  button: {
    height: '42px',
    minWidth: '220px',
    fontSize: '1rem',
    padding: '8px 12px',
    marginLeft: '0px!important',
    "@media(max-width: 768px)": {
      marginTop: '20px',
    }
  },
}

export default RescueShipBanner
