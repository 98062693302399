import { Box, SxProps, Tab, Tabs } from "@mui/material";
import TabPanel from "components/TabPanel";
import React, { Fragment, useState } from "react";
import { Guild, GuildBankInfo } from "store/types";
import { AppTheme, SimpleMap } from "utils/types";
import { GuildBankTab, TaxCollectorTab } from "./components";
import RefineryTab from "./components/RefineryTab/RefineryTab";

export interface Props {
  guild: Guild;
  bank: GuildBankInfo;
  canCollectTax: boolean;
  hasGuild: boolean;
}

const GuildBankMain: React.FC<Props> = (props: Props) => {
  const { guild, bank, canCollectTax, hasGuild } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  if (!guild || !bank) return null;
  return (
    <Box sx={styles.wrapper}>
      {canCollectTax
        ? <>
          <Tabs
            TabIndicatorProps={{ style: { background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)" } }}
            value={value}
            onChange={handleChange}
            centered
            sx={styles.tabs}
          >
            <Tab label="GUILD BANK" sx={styles.tab} />
            <Tab label="REFINERY" sx={styles.tab} />
            <Tab label="TAX COLLECTOR" sx={styles.tab} />
          </Tabs>

          <TabPanel value={value} index={0}>
            <GuildBankTab guild={guild} bank={bank} canCollectTax={canCollectTax} hasGuild={hasGuild} tabController={setValue} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RefineryTab guild={guild} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TaxCollectorTab guild={guild} />
          </TabPanel>
        </>
        : <GuildBankTab guild={guild!} bank={bank!} canCollectTax={canCollectTax} hasGuild={hasGuild} tabController={setValue} />
      }
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  wrapper: {
    paddingTop: '20px',
  },
  bankHeader: {
    textAlign: 'center',
    paddingY: '5px',
    marginY: '25px',
    transform: 'skewX(-25deg)',
    background: 'repeating-linear-gradient(90deg, #FFB60033, #FFB60033 36px, transparent 36px, transparent 44px)',
  },
  tabs: {
    marginBottom: '36px',
    "& .MuiTabs-flexContainer": {
      gap: '64px'
    },
  },
  tab: {
    fontFamily: 'syne',
    fontSize: '24px',
    fontWeight: 700,
    color: '#FFFFFF62',
    '&.Mui-selected': {
      color: '#fff',
    },
  }
}

export default GuildBankMain;