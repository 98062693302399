import { Box, Button, CircularProgress, Link, styled, SvgIcon, SxProps, Typography } from "@mui/material";
import { BaseButton, ExternalLink, Huny } from "assets";
import HunyIcon from "assets/Huny.svg";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { waitForTx } from "core/utilities";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { actions } from "store";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { BIG_ZERO, Decimals } from "utils/constants";
import { bnOrZero, getExplorerLink } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";


export interface ModalProps {
  open: boolean;
  onClose: () => void;
  walletAddr?: string;
}

const ClaimDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, walletAddr } = props;
  const hunyReceiveNow = useRedux((state) => state.token.hunyToRecover.hunyReceiveNow)
  const hunyToRefinery = useRedux((state) => state.token.hunyToRecover.hunyToRefinery)
  const network = useNetwork();
  const dispatch = useDispatch();
  const [recoverHunyComplete, setRecoverHunyComplete] = useState<boolean>(false);
  const [recoverHunyTxHash, setRecoverHunyTxHash] = useState<string>("");
  const toaster = useToaster();
  const [runRecoverHuny, loadingRecoverHuny] = useAsyncTask("recoverHuny", (error) => {
    toaster(error?.message ?? "Error Recovering Huny");
  });

  const handleOnClose = () => {
    if (recoverHunyComplete) {
      dispatch(actions.Token.updateHunyToRecover({ hunyReceiveNow: BIG_ZERO, hunyToRefinery: BIG_ZERO }));
    }
    setRecoverHunyComplete(false);
    setRecoverHunyTxHash("");
    onClose();
  }

  const handleRecoverHuny = () => {
    runRecoverHuny(async () => {
      if (!walletAddr) return;

      const zilswap = TBMConnector.getSDK();
      const result = await TBMConnector.recoverHuny(walletAddr);

      await zilswap.observeTx({
        hash: result.id!,
        deadline: Number.MAX_SAFE_INTEGER,
      });

      toaster(`Submitted Recover HUNY `, { hash: result.id!, overridePersist: true });
      await waitForTx(result.id!);
      if (result.id) {
        setRecoverHunyTxHash(result.id);
        setRecoverHunyComplete(true);
      }
    })
  }

  const getHeader = () => {
    if (recoverHunyComplete) {
      return "Recover HUNY Success!";
    } else if (loadingRecoverHuny) {
      return "Recovering HUNY...";
    } else {
      return "Claim Lost HUNY";
    }
  }

  const getContent = () => {
    if (loadingRecoverHuny) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Box>
      )
    }
    return (
      <Box sx={styles.contentContainer}>
        {
          !!recoverHunyComplete
            ? <Fragment>
              <Box display="flex" alignItems="center" mt="20px">
                <Box component="img" src={HunyIcon} mr="10px" sx={styles.placeholderImage} />
                <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }}> x {bnOrZero(hunyReceiveNow).shiftedBy(-Decimals.HUNY).toNumber().toFixed(4)}</Typography>
              </Box>
              <Link
                target="_blank"
                href={getExplorerLink("tx", recoverHunyTxHash, network)}
                sx={styles.viewTx}
              >
                View Transaction
                <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
              </Link>
              <Button
                component={RouterLink}
                variant="contained"
                color="secondary"
                onClick={onClose}
                sx={styles.refineryButton}
                to="/refinery"
              >
                <BaseButtonBox>
                  <BaseButton height="100%" />
                </BaseButtonBox>
                Visit Refinery
              </Button>
            </Fragment>
            : <Fragment>
              <Typography variant="body1" color="primary" sx={styles.subHeader}>Claim your lost HUNY from the H.U.G.E coporation!</Typography>
              <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" sx={styles.row}>
                <Box sx={styles.textGroup}>
                  <Typography variant="body1" color="primary">Total HUNY Recovered</Typography>
                </Box>
                <Box sx={styles.textGroup}>
                  <Typography variant="h3" color="primary" sx={styles.hunyAmount}>{BigNumber.sum(hunyReceiveNow, hunyToRefinery).shiftedBy(-Decimals.HUNY).toNumber().toFixed(4)}</Typography>
                  <Huny height="24px" width="24px" />
                </Box>
              </Box>
              <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" sx={styles.row}>
                <Box sx={styles.textGroup}>
                  <Typography variant="body1" color="primary">HUNY to Refinery</Typography>
                </Box>
                <Box sx={styles.textGroup}>
                  <Typography variant="h3" color="primary" sx={styles.hunyAmount}>{bnOrZero(hunyToRefinery).shiftedBy(-Decimals.HUNY).toNumber().toFixed(4)}</Typography>
                  <Huny height="24px" width="24px" />
                </Box>
              </Box>
              <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" sx={styles.row}>
                <Box sx={styles.textGroup}>
                  <Typography variant="body1" color="primary">Estimated receive now:</Typography>
                </Box>
                <Box sx={styles.textGroup}>
                  <Typography variant="h3" color="success.main" sx={styles.hunyAmount}>{bnOrZero(hunyReceiveNow).shiftedBy(-Decimals.HUNY).toNumber().toFixed(4)}</Typography>
                  <Huny height="24px" width="24px" />
                </Box>
              </Box>
              <ContainedButton onClick={handleRecoverHuny} sx={styles.button}>
                {loadingRecoverHuny
                  ? <CircularProgress size={18} />
                  : "Confirm CLAIM"
                }
              </ContainedButton>
            </Fragment>
        }
      </Box>
    );
  }
  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true}>
      {getContent()}
    </DialogModal>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentContainer: {
    width: '100%',
    padding: '20px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subHeader: {
    fontSize: '1.25em',
    marginBottom: '40px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '30px',
  },
  textGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hunyAmount: {
    marginRight: '10px',
  },
  button: {
    minWidth: '250px',
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "40px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
}

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    display: "none",
  },
});

export default ClaimDialog