import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button,
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
  List,
  ListItem,
  SxProps,
  Typography
} from "@mui/material";
import { ConnectWalletButtonDrawer } from "components/ConnectWalletButton";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { actions } from "store";
import { useState } from "react";
import { AppTheme, SimpleMap } from "utils/types";

const NavDrawer: React.FC<DrawerProps> = (props: DrawerProps) => {
  const { children, onClose, ...rest } = props;
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const handleDrawerClose = () => {
    dispatch(actions.Layout.toggleShowNavDrawer("close"));
  };

  return (
    <Drawer anchor="right" onClose={onClose} {...rest} sx={styles.drawerContainer}>
      <Box width="260px" role="presentation">
        <Box width="100%" sx={styles.closeButtonContainer}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon sx={styles.closeButton} />
          </IconButton>
        </Box>
        <List>
          <ListItem sx={styles.sideDrawerWalletContainer}>
            <ConnectWalletButtonDrawer />
          </ListItem>
          <Divider sx={styles.sideDrawerDivider} />
          <ListItem sx={styles.sideDrawerListContainer}>
            <Button component={NavLink} to="/" onClick={handleDrawerClose}>
              <Typography
                variant="h3"
                color="primary"
                sx={styles.sideDrawerNav}
              >
                Home
              </Typography>
            </Button>
          </ListItem>
          <ListItem sx={styles.sideDrawerListContainer}>
            <Button component={NavLink} to="/explore" onClick={handleDrawerClose}>
              <Typography
                variant="h3"
                color="primary"
                sx={styles.sideDrawerNav}
              >
                Explore
              </Typography>
            </Button>
          </ListItem>
          <ListItem sx={styles.sideDrawerListContainer}>
            <Button
              component={NavLink}
              to="/map"
              onClick={handleDrawerClose}
            >
              <Typography
                variant="h3"
                color="primary"
                sx={styles.sideDrawerNav}
              >
                Game
              </Typography>
            </Button>
          </ListItem>
          <ListItem sx={styles.sideDrawerListContainer}>
            <Accordion sx={styles.accordionContainer} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={styles.accordionSummary}
              >
                <Typography color="primary" variant="h3" >Stations</Typography>
              </AccordionSummary>
              <AccordionDetails sx={styles.accordionDetails}>
                <Button
                  component={NavLink}
                  to="/map/zomg-store"
                  onClick={handleDrawerClose}
                >
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={styles.accordionNav}
                  >
                    Z.O.M.G Store
                  </Typography>
                </Button>
                <Button
                  component={NavLink}
                  to="/hive"
                  onClick={handleDrawerClose}
                >
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={styles.accordionNav}
                  >
                    Hive
                  </Typography>
                </Button>
                <Button
                  component={NavLink}
                  to="/refinery"
                  onClick={handleDrawerClose}
                >
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={styles.accordionNav}
                  >
                    Refinery
                  </Typography>
                </Button>
                <Button component={NavLink} to="/guilds" onClick={handleDrawerClose}>
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={styles.accordionNav}
                  >
                    Guild Hall
                  </Typography>
                </Button>
              </AccordionDetails>
            </Accordion>
          </ListItem>
          <ListItem sx={styles.sideDrawerListContainer}>
            <Accordion sx={styles.accordionContainer} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={styles.accordionSummary}
              >
                <Typography color="primary" variant="h3" >Archives</Typography>
              </AccordionSummary>
              <AccordionDetails sx={styles.accordionDetails}>
                <Button
                  component={NavLink}
                  to="/summon"
                  onClick={handleDrawerClose}
                >
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={styles.accordionNav}
                  >
                    Summon
                  </Typography>
                </Button>
                <Button
                  component={NavLink}
                  to="/game"
                  onClick={handleDrawerClose}
                >
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={styles.accordionNav}
                  >
                    Moonbattle
                  </Typography>
                </Button>
              </AccordionDetails>
            </Accordion>
          </ListItem>
          <ListItem sx={styles.sideDrawerListContainer} onClick={handleDrawerClose}>
            <Button target="_blank" href="https://docs.zolar.io/">
              <Typography
                variant="h3"
                color="primary"
                sx={styles.sideDrawerNav}
              >
                Whitepaper
              </Typography>
            </Button>
          </ListItem>
          <ListItem sx={styles.sideDrawerListContainer}>
            <Button component={NavLink} to="/summon" onClick={handleDrawerClose}>
              <Typography
                variant="h3"
                color="primary"
                sx={styles.sideDrawerNav}
              >
                Summon🔥
              </Typography>
            </Button>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  drawerContainer: {
    marginTop: '50px',
    zIndex: 120000,
    "@media (min-width:900px)": {
      display: 'none'
    }
  },
  sideDrawerNav: {
    textTransform: "none",
  },
  sideDrawerWalletContainer: {
    justifyContent: "center",
  },
  sideDrawerDivider: {
    border: "1px solid rgba(174, 241, 238, 0.1)",
    marginBottom: "20px",
  },
  sideDrawerListContainer: {
    paddingLeft: "40px",
    width: "100%",
  },
  accordionContainer: {
    '.MuiButtonBase-root': {
      padding: '6px 8px',
    }
  },
  accordionSummary: {
    '.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'none',
    },
    '.MuiAccordionSummary-expandIconWrapper': {
      color: '#fff',
    },
    '.MuiAccordionSummary-content': {
      margin: '0px',
    }
  },
  accordionDetails: {
    padding: '0px',
    paddingLeft: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  accordionNav: {
    fontSize: '1.125rem',
  },
  NavLinkDisabled: {
    background: "transparent",
    color: "#FEFEFE",
    opacity: "0.4",
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeButton: {
    color: '#fff',
    marginRight: '20px',
    height: '30px',
    width: '30px'
  },
};

export default NavDrawer;
