import React, { useRef } from "react";
import { Box, styled } from "@mui/material";
import {
  BlueEllipse, MissionBlue,
  MissionRed,
  Moon
} from "assets";
import Texture from "assets/Texture.png";
import BetaWarning from "components/BetaWarning";
import { SnackbarProvider } from "notistack";
import { useDispatch } from "react-redux";
import { actions } from "store";
import useRedux from "utils/useRedux";
import { Footer, Header } from "./components";
import NavDrawer from "./components/NavDrawer";
import SnackbarContentItem from "./components/SnackbarContentItem";

const Main = styled("main")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  background: "linear-gradient(180deg, #010025 0%, #000000 100%)",
  display: "flex",
  flexDirection: "column",
  overflowY: "hidden",
  overflowX: "hidden",
  position: "relative",
  maxWidth: '100%',
}));

const SvgTextureBox = styled(Box)({
  position: "absolute",
  top: "0%",
  left: "0%",
  height: "100%",
  backgroundImage: `url(${Texture})`,
  backgroundRepeat: 'repeat',
  opacity: "60%",
  pointerEvents: 'none',
});

const GameLayout: React.FC = (props: any) => {
  const { children, ...rest } = props;
  const dispatch = useDispatch();
  const showNavDrawer = useRedux((state) => state.layout.showNavDrawer);
  const ref = useRef() as React.MutableRefObject<SnackbarProvider>;
  const onCloseDrawer = () => {
    dispatch(actions.Layout.toggleShowNavDrawer("close"));
  };
  return (
    <Main {...rest}>
      <SvgTextureBox />
      <SvgBox>
        <EllipseBox>
          <BlueEllipse />
        </EllipseBox>
        <MoonBox>
          <Moon width="100%"/>
        </MoonBox>
        <BlueEllipseBox1>
          <MissionBlue width="100%"/>
        </BlueEllipseBox1>
        <RedEllipseBox1>
          <MissionRed width="100%"/>
        </RedEllipseBox1>
        <BlueEllipseBox2>
          <MissionBlue width="100%"/>
        </BlueEllipseBox2>
        <RedEllipseBox2>
          <MissionRed width="100%"/>
        </RedEllipseBox2>
      </SvgBox>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        ref={ref}
        content={(key, message) => {
          if (!message) return
          let msgContent = JSON.parse(message!.toString());
          const { hash, content } = msgContent;
          return (<SnackbarContentItem snackKey={key} message={content} hash={hash} providerRef={ref} />)
        }}
        maxSnack={3}
      >
      <Header />
      <BetaWarning />
        {children}
      <Footer />
      <NavDrawer open={showNavDrawer} onClose={onCloseDrawer} />
      </SnackbarProvider>
    </Main>
  );
};
const SvgBox = styled(Box)({
  pointerEvents: 'none',
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
});

const MoonBox = styled(Box)({
  width: '100%',
  pointerEvents: 'none',
  position: "absolute",
  top: "-80px",
  "@media (max-width:900px)": {
    top: "-150px",
  },
  "@media (max-width:600px)": {
    top: "-150px",
  },
});

const EllipseBox = styled(Box)({
  position: "absolute",
  pointerEvents: 'none',
});
const BlueEllipseBox1 = styled(Box) ({
  pointerEvents: 'none',
  position: "absolute",
  top: "-400px",
  left: "-725px",
  width: "1496px",
  opacity: "40%",
  background: "radial-gradient(42.7% 52.61% at 58.59% 47.39%, #FFFFFF 1.56%, #469BFF 15.29%, #0445EB 35.94%, rgba(0, 0, 0, 0) 96.45%)",
  "@media (max-width:600px)": {
    left: '-120px',
    top: '408.94px',
    width: '423.44px',
    height: '423.15px',
  },
});

const RedEllipseBox1 = styled(Box) ({
  pointerEvents: 'none',
  position: "absolute",
  top: "-480px",
  right: "-820px",
  width: "1496px",
  opacity: "40%",
  background: "radial-gradient(42.41% 40.03% at 44.15% 51.87%, rgba(255, 255, 255, 0.734375) 0%, rgba(255, 122, 0, 0.734375) 16.67%, rgba(255, 2, 2, 0.479167) 56.9%, rgba(255, 0, 0, 0) 100%)",
  "@media (max-width:600px)": {
    right: '-300px',
    top: '308.94px',
    width: '423.44px',
    height: '423.15px',
  },
});

const BlueEllipseBox2 = styled(Box) ({
  pointerEvents: 'none',
  position: "absolute",
  top: "1495px",
  left: "-725px",
  width: "1496px",
  opacity: "40%",
  background: "radial-gradient(42.7% 52.61% at 58.59% 47.39%, #FFFFFF 1.56%, #469BFF 15.29%, #0445EB 35.94%, rgba(0, 0, 0, 0) 96.45%)",
  "@media (max-width:600px)": {
    left: '-120px',
    top: '408.94px',
    width: '423.44px',
    height: '423.15px',
  },
});

const RedEllipseBox2 = styled(Box) ({
  pointerEvents: 'none',
  position: "absolute",
  top: "1495px",
  right: "-790px",
  width: "1496px",
  opacity: "40%",
  background: "radial-gradient(42.41% 40.03% at 44.15% 51.87%, rgba(255, 255, 255, 0.734375) 0%, rgba(255, 122, 0, 0.734375) 16.67%, rgba(255, 2, 2, 0.479167) 56.9%, rgba(255, 0, 0, 0) 100%)",
  "@media (max-width:600px)": {
    right: '-300px',
    top: '308.94px',
    width: '423.44px',
    height: '423.15px',
  },
});

export default GameLayout;
