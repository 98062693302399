import { Box, BoxProps, SxProps, Typography } from "@mui/material";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";

const StripeHeader:React.FC<BoxProps> = (props: BoxProps) => {
  const {children, ...rest} = props;
  return (
    <Box {...rest} sx={combineStyles(styles.header, rest.sx)}>
      <Typography variant="h2" color="primary" sx={styles.headerContent}>{children}</Typography>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  header: {
    textAlign: 'center',
    background: 'repeating-linear-gradient(90deg, #FF1E1E33, #FF1E1E33 36px, transparent 36px, transparent 44px)',
    paddingY: '5px',
    marginBottom: '16px',
    transform: 'skewX(-25deg)',
    marginX: '20px',
  },
  headerContent: {
    transform: 'skewX(25deg)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform:'uppercase',
  },
}

export default StripeHeader;