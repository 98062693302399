import { Box, BoxProps, Button, FormControl, FormHelperText, InputLabel, OutlinedInput, styled, SxProps, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { CheckCircleIcon, CrestUpgradeArrow, FileUpload, Huny, WarningRed } from "assets";
import GuildBannerPreview from 'assets/GuildBannerPreview.svg';
import ContainedButton from "components/ContainedButton";
import TooltipZolar from "components/Tooltip";
import { Fragment, useEffect, useState } from "react";
import { Guild } from "store/types";
import { TBMConnector } from "tbm";
import { computeFinalPrice, useAsyncTask, useRedux } from "utils";
import { Decimals, ITEM_ID } from "utils/constants";
import { bnOrZero, toHumanNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { BANNERS, BANNER_COLOURS, BANNER_ICONS, CRESTS, CREST_COLOUR_SELECTOR, CREST_ICONS, CREST_UPGRADES, SIGILS, SIGIL_COLOURS, SIGIL_ICONS } from "../../GuildConstants";
import { Errors, UpdateGuildFormInputs } from "../UpdateGuild";
import PostUpdateDialog from "./PostUpdateDialog";

export interface Props extends BoxProps {
  formInputs: Partial<UpdateGuildFormInputs>;
  setFormInputs: React.Dispatch<React.SetStateAction<Partial<UpdateGuildFormInputs>>>;
  errors: Partial<Errors>;
  setErrors: React.Dispatch<React.SetStateAction<Partial<Errors>>>;

  displayImage: string | ArrayBuffer | null;
  setDisplayImage: React.Dispatch<React.SetStateAction<string | ArrayBuffer | null>>;
  uploadedImage: File | null;
  setUploadedImage: React.Dispatch<React.SetStateAction<File | null>>;

  isCreateEnabled: boolean;
  handleBack: () => void;
  guild: Guild;
}

const PaidUpdates: React.FC<Props> = (props: Props) => {
  const { guild, formInputs, setFormInputs, errors, setErrors, displayImage, setDisplayImage, uploadedImage, setUploadedImage, isCreateEnabled } = props;

  const network = useNetwork();

  const totalHunySupply = useRedux((state) => state.token.TotalHunySupply);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [updateGuildPrice, setUpdateGuildPrice] = useState<number>(0);
  const [runQueryGuildPrice, loadingQueryGuildPrice] = useAsyncTask("queryGuildPrice");

  useEffect(() => {
    runQueryGuildPrice(async () => {
      const result = await TBMConnector.fetchStallItem(ITEM_ID[network].HeraldryScroll);
      const purchaseCount = await TBMConnector.fetchStallPurchaseCount(ITEM_ID[network].HeraldryScroll);
      const price = await computeFinalPrice(result.arguments[1], bnOrZero(totalHunySupply), purchaseCount);
      setUpdateGuildPrice(price.finalPrice.toNumber());
    })
    // eslint-disable-next-line
  }, [totalHunySupply])

  const validateInput = (field: string, input: string) => {
    switch (field) {
      // 16 characters, alphanumeric and hyphen
      case "guildName":
        if (input.length && input.length < 2) return "Enter a guild name of at least 2 characters.";
        if (input.length > 16 || input.length === 0) return "Enter a guild name of up to 16 characters.";
        if (input.length && !/^[A-Za-z0-9- ]{1,16}$/.test(input)) return "Enter a guild name only containing alphanumeric characters or hyphen.";
        return ""
      default: return "";
    }
  }

  const updateInputs = (field: string) => {
    return (newInput: string | number) => {
      setFormInputs({ ...formInputs, [field]: newInput });

      if (typeof newInput === "string") {
        const errorText = validateInput(field, newInput);
        setErrors((prev) => ({ ...prev, [field]: errorText }));
      }
    }
  }

  const handleImageUpload = ({ target }) => {
    if (!target.files.length) {
      setDisplayImage(null);
      setUploadedImage(null);
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setDisplayImage(reader.result);
      setUploadedImage(target.files[0]);
    }
    reader.readAsDataURL(target.files[0]);
  }


  const handleUpdate = () => {
    let isEmpty = false;
    const toCheck = ['guildName'];
    toCheck.forEach(value => {
      if (formInputs[value].trim() === "") {
        updateInputs(value)("");
        isEmpty = true;
      }
    });

    if (isEmpty) window.scrollTo({ top: 0, behavior: 'smooth' });
    else setShowDialog(true);
  }

  const handleOnClose = () => {
    setShowDialog(false);
  }

  if (!formInputs.guildBannerDesign || !formInputs.guildCrestDesign || !formInputs.guildSigilDesign) return null;
  return (
    <>
      <Box sx={styles.contentBox}>
        {/* guild information */}
        <Typography variant="body2" color="primary" sx={styles.sectionHeaderText}>Guild Profile</Typography>
        <Box sx={styles.guildInformationBox}>
          {/* guild name */}
          <Box display="flex" alignItems="center" width="100%">
            <InputLabel htmlFor="name" sx={errors["guildName"] ? styles.removeErrorMargin : undefined}>
              <Typography variant="body1" color="primary" sx={styles.inputLabel}>Guild Name</Typography>
            </InputLabel>
            <FormControl fullWidth>
              <OutlinedInput
                sx={combineStyles(styles.input, errors["guildName"] ? styles.errorInput : undefined)}
                id="name"
                placeholder={guild.name}
                value={formInputs.guildName}
                onChange={(e) => updateInputs("guildName")(e.target.value)}
              />
              {errors["guildName"] &&
                <Box sx={styles.errorTextContainer}>
                  <WarningRed width={15} />
                  <FormHelperText sx={styles.errorText}>{errors["guildName"]}</FormHelperText>
                </Box>
              }
            </FormControl>
          </Box>

          {/* sigil */}
          <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
            <Typography variant="body1" color="primary" fontWeight={600} sx={styles.inputLabel} mr="-40px">
              Guild Sigil
              {/* //TODO: Link to where? */}
              <TooltipZolar link="https://docs.zolar.io/stations/guilds#guild-banner-and-sigils" sx={styles.tooltip}>
                Sigils cannot be changed as they determine a guild’s playstyle.
              </TooltipZolar>
            </Typography>

            {/* selections */}
            <Box display="flex" flexDirection="column" sx={styles.selectionBox}>
              <SigilButtonGroup
                value={formInputs.guildSigilDesign}
                exclusive
              >
                {SIGIL_ICONS.map((sigil, index) => {
                  return (
                    <SigilWrapper
                      key={index}
                      value={index + 1}
                      sx={combineStyles(styles.sigilWrapper,
                        { ...(index !== guild.guildSigil.design - 1) && { opacity: '0.5' } }
                      )}
                      disabled={index !== guild.guildSigil.design - 1}
                      disableRipple
                    >
                      <Box sx={{ position: 'relative' }}>
                        <Box component="img" sx={styles.sigilImage} src={sigil.image} alt={sigil.name} id="sigilImage" />
                        <CheckCircleIcon id="sigilCheckedIcon" />
                      </Box>
                      <Box display="flex" flexDirection="column" mt="12px">
                        <Typography variant="body1" color="primary" sx={styles.sigilName}>{sigil.name}</Typography>
                        <Typography variant="body1" color="primary" sx={styles.sigilValues}>{sigil.values}</Typography>
                      </Box>
                    </SigilWrapper>
                  )
                })}
              </SigilButtonGroup>

              <ColourButtonGroup
                value={formInputs.guildSigilColour}
                onChange={(_, newSelection) => { newSelection !== null && updateInputs("guildSigilColour")(newSelection) }}
                exclusive
              >
                {SIGIL_COLOURS.map((colourObj, index) => {
                  return (
                    <ColourWrapper key={index} value={colourObj.colour} sx={styles.colourWrapper} disableRipple>
                      <Box sx={styles.colourBox} id="colour" style={{ background: colourObj.background }} />
                    </ColourWrapper>
                  )
                })}
              </ColourButtonGroup>
            </Box>
          </Box>
        </Box>

        {/* guild banner preview */}
        <Typography variant="body2" color="primary" sx={styles.sectionHeaderText}>Guild Banner Preview</Typography>

        <Box sx={styles.previewBox}>
          <Box position="relative">
            <Box
              component="img"
              src={
                BANNERS[formInputs.guildBannerDesign - 1][
                BANNER_COLOURS.findIndex(
                  (curr) => curr.colour === formInputs.guildBannerColour
                )
                ]
              }
              height="250px"
              width="250px"
            />
            <Box
              component="img"
              src={
                SIGILS[formInputs.guildSigilDesign - 1][
                SIGIL_COLOURS.findIndex(
                  (curr) => curr.colour === formInputs.guildSigilColour
                )
                ]
              }
              height="90px"
              width="90px"
              sx={styles.sigil}
            />
          </Box>
        </Box>

        {/* guild banner */}
        <Box sx={styles.guildBannerBox}>
          <Typography variant="body1" color="primary" sx={styles.subheaderText}>Guild Banner</Typography>

          <BannerButtonGroup
            value={formInputs.guildBannerDesign}
            onChange={(_, newSelection) => { newSelection !== null && updateInputs("guildBannerDesign")(newSelection) }}
            exclusive
          >
            {BANNER_ICONS.map((banner, index) => {
              return (
                <BannerWrapper key={index} value={index + 1} disableRipple>
                  <Box sx={{ position: 'relative' }}>
                    <Box component="img" src={banner.image} alt={banner.name} id="bannerImage" />
                    <CheckCircleIcon id="sigilCheckedIcon" />
                  </Box>
                </BannerWrapper>
              )
            })}
          </BannerButtonGroup>

          <ColourButtonGroup
            value={formInputs.guildBannerColour}
            onChange={(_, newSelection) => { newSelection !== null && updateInputs("guildBannerColour")(newSelection) }}
            exclusive
          >
            {BANNER_COLOURS.map((colourObj, index) => {
              return (
                <ColourWrapper key={index} value={colourObj.colour} sx={styles.colourWrapper} disableRipple>
                  <Box sx={styles.colourBox} id="colour" style={{ background: colourObj.background }} />
                </ColourWrapper>
              )
            })}
          </ColourButtonGroup>
        </Box>
      </Box>

      <Box sx={styles.contentBox}>
        {/* guild crest preview */}
        <Typography variant="body2" color="primary" sx={styles.sectionHeaderText}>
          Guild Crest Preview
        </Typography>

        <Box sx={styles.previewBox}>
          {/* current */}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            gap="10px"
          >
            <Box position="relative">
              {displayImage && (
                <Box component="img" src={displayImage.toString()} sx={styles.crestImage} />
              )}
              <Box
                component="img"
                src={CRESTS[formInputs.guildCrestDesign - 1][
                  CREST_COLOUR_SELECTOR.findIndex(
                    (curr) => curr.colour === formInputs.guildCrestColour
                  )]}
                sx={styles.crestImageBig}
              />
            </Box>

            <Typography variant="subtitle1" color="primary" fontSize="14px">
              Current Guild Crest
            </Typography>
          </Box>

          <Box sx={styles.upgradeArrowBox}>
            <CrestUpgradeArrow />
          </Box>

          {/* upgrade */}
          <Box sx={styles.crestUpgrade}>
            <Box flex={0.4} />

            <Box position="relative">
              {displayImage && (
                <Box
                  component="img"
                  src={displayImage.toString()}
                  sx={styles.crestImageSmall}
                />
              )}
              <Box
                component="img"
                src={
                  CREST_UPGRADES[formInputs.guildCrestDesign - 1][
                  CREST_COLOUR_SELECTOR.findIndex(
                    (curr) => curr.colour === formInputs.guildCrestColour
                  )
                  ]
                }
                height="180px"
                width="180px"
                position="relative"
                sx={styles.opacity}
              />
            </Box>

            <Typography variant="subtitle1" color="primary" mt="-20px" mb="20px" fontSize="14px">
              Next Crest Upgrade
            </Typography>
          </Box>
        </Box>

        {/* guild crest */}
        <Box sx={styles.guildCrestBox}>
          <Typography variant="body1" color="primary" sx={styles.subheaderText}>
            Guild Crest
          </Typography>

          <CrestButtonGroup
            value={formInputs.guildCrestDesign}
            onChange={(_, newSelection) => {
              newSelection !== null &&
                updateInputs("guildCrestDesign")(newSelection);
            }}
            exclusive
          >
            {CREST_ICONS.map((crest, index) => {
              return (
                <CrestWrapper key={index} value={index + 1} disableRipple>
                  <Box sx={{ position: 'relative', width: '80px', height: '80px' }}>
                    <Box
                      component="img"
                      src={crest.image}
                      id="crestDesign"
                    />
                    <CheckCircleIcon id="sigilCheckedIcon" />
                  </Box>
                </CrestWrapper>
              );
            })}
          </CrestButtonGroup>

          <ColourButtonGroup
            value={formInputs.guildCrestColour}
            onChange={(_, newSelection) => {
              newSelection !== null &&
                updateInputs("guildCrestColour")(newSelection);
            }}
            exclusive
          >
            {CREST_COLOUR_SELECTOR.map((colourObj, index) => {
              return (
                <ColourWrapper
                  key={index}
                  value={colourObj.colour}
                  sx={styles.colourWrapper}
                  disableRipple
                >
                  <Box
                    sx={styles.colourBox}
                    id="colour"
                    style={{ background: colourObj.background }}
                  />
                </ColourWrapper>
              );
            })}
          </ColourButtonGroup>

          {/* upload image */}
          <Box sx={styles.imageRow}>
            <Typography variant="body1" color="primary" sx={styles.uploadText}>
              Upload Image
            </Typography>

            <Button
              variant="outlined"
              component="label"
              sx={styles.uploadButton}
              fullWidth
            >
              {uploadedImage ? (
                <Fragment>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={styles.uploadButtonText}
                  >
                    {uploadedImage.name}
                  </Typography>
                  <FileUpload />
                </Fragment>
              ) : (
                <Fragment>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={styles.uploadButtonText}
                  >
                    Only JPEG, JPG, PNG accepted. Max file size 2 mb
                  </Typography>
                  <FileUpload />
                </Fragment>
              )}
              <input
                accept="image/*"
                type="file"
                onChange={handleImageUpload}
                hidden
              />
            </Button>
          </Box>
        </Box>

        {/* price */}
        {/* //TODO: Cfm price */}
        <Box sx={styles.priceRow}>
          <Typography variant="body1" color="primary">
            Guild Changes
            {/* //TODO: Link to where? */}
            <TooltipZolar link="https://docs.zolar.io/stations/guilds#guild-pricing" sx={styles.tooltip}>
              A fee will be charged for the following changes made:
              <li>Guild Name</li>
              <li>Guild Crest</li>
              <li>Guild Banner</li>
            </TooltipZolar>
          </Typography>
          <Box sx={styles.price}>
            <Typography
              variant="body1"
              color="success.main"
              sx={{ fontWeight: 600 }}
            >
              {toHumanNumber(bnOrZero(updateGuildPrice).shiftedBy(-Decimals.HUNY).toNumber())} HUNY&nbsp;
            </Typography>{" "}
            <Huny />
          </Box>
        </Box>

        {/* button box */}
        <Box display="flex" justifyContent="end" mt="30px">
          {/* create button */}
          <ContainedButton
            sx={styles.createButton}
            onClick={handleUpdate}
            disabled={!isCreateEnabled || showDialog || loadingQueryGuildPrice}
          >
            Submit Changes
          </ContainedButton>
        </Box>
        <PostUpdateDialog
          guild={guild}
          open={showDialog}
          onClose={handleOnClose}
          formInputs={formInputs}
          uploadedImage={uploadedImage}
          price={updateGuildPrice}
        />
      </Box>
    </>
  )
}


const styles: SimpleMap<SxProps<AppTheme>> = {
  //INFO: Page 1
  contentBox: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    "@media (min-width:600px)": {
      minWidth: "500px",
    },
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  sectionHeaderText: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 0.8)",
    marginTop: "60px",
  },
  guildInformationBox: {
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    borderRadius: "16px",
    marginTop: "12px",
    padding: "24px",
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  input: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      padding: "12px 24px",
    },
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },
  },
  multilineInput: {
    minHeight: "48px",
    padding: "6px 24px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    fontSize: "14px",
    lineHeight: "28px",
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: 600,
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },
  },
  inputLabel: {
    fontSize: "14px",
    lineHeight: "28px",
    width: "250px",
  },
  tooltip: {
    padding: "20px",
    width: "360px",
    "& .MuiTypography-root": {
      fontSize: "18px",
      lineHeight: "28px",
    },
    "@media (max-width:600px)": {
      padding: "20px",
      width: "250px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  guildBannerBox: {
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    borderRadius: "16px",
    marginTop: "60px",
    padding: "24px",
  },
  subheaderText: {
    fontSize: "14px",
    lineHeight: "28px",
    fontWeight: 600,
  },
  nextButton: {
    minWidth: "280px",
    height: 64,
    "@media (max-width:600px)": {
      minWidth: "",
      flex: 1,
    },
  },
  previewBox: {
    backgroundImage: `url(${GuildBannerPreview})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    minHeight: "340px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sigilName: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#AEF1EE",
    whiteSpace: "nowrap",
    textAlign: "left",
    fontWeight: 600,
  },
  sigilValues: {
    fontSize: "12px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    textAlign: "left",
  },
  sigilImage: {
    height: "80px",
    width: "80px",
  },
  // TODO: improve responsiveness of sigil stuff
  selectionBox: {
    overflowX: "scroll",
    "::-webkit-scrollbar": {
      width: '6px',
      height: "6px"
    },
    "::-webkit-scrollbar-track": {
      marginTop: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '16px',
    },
    "@media (max-width:400px)": {
      maxWidth: "150px",
    }
  },
  colourBox: {
    height: "42px",
    width: "42px",
    borderRadius: "50%",
    boxShadow: '0px 0px 16px rgba(239, 57, 255, 0.4)',
  },
  sigil: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -70%)",
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },
  removeErrorMargin: {
    marginTop: "-22px",
  },
  errorInput: {
    border: "1px solid #F65E5E",
    "&.Mui-focused": {
      borderColor: "#F65E5E",
    },
  },
  createButton: {
    minWidth: "280px",
    height: 64,
    "@media (max-width:900px)": {
      minWidth: "",
      flex: 1,
    },
  },
  priceRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "60px",
  },
  price: {
    display: "flex",
    whiteSpace: "nowrap",
    "& svg": {
      verticalAlign: "text-top",
      marginTop: "2px",
      height: 22,
      width: 22,
    },
    "@media (max-width:600px)": {
      "& svg": {
        marginTop: 0,
        height: "20px!important",
        width: "20px!important",
      }
    },
  },

  //INFO: Page Two
  crestContentBox: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    "@media (min-width:600px)": {
      minWidth: "500px",
    },
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  backButton: {
    boxShadow: "0px 0px 24px rgba(174, 241, 238, 0.12)",
    padding: "12px 24px",
    "@media (max-width:900px)": {
      flex: 1,
      marginRight: "10px",
    },
  },

  gradientText: {
    background: "-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  guildCrestBox: {
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    borderRadius: "16px",
    marginTop: "60px",
    padding: "24px",
  },
  imageRow: {
    display: "flex",
    marginTop: "20px",
    alignItems: "center",
  },
  uploadText: {
    fontSize: "14px",
    lineHeight: "28px",
    width: "200px",
    color: "rgba(255, 255, 255, 0.8)",
  },
  uploadButton: {
    height: 48,
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 24px",
    textTransform: "none",
    "&:hover": {
      borderColor: "rgba(174, 241, 238, 0.1)",
    },
  },
  uploadButtonText: {
    fontSize: "12px!important",
    lineHeight: "18px!important",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 0.8)",
  },
  crestImage: {
    height: "111.5px",
    width: "111.5px",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -55%)",
  },
  crestImageBig: {
    maxHeight: '200px',
    objectFit: 'cover',
    height: '320px',
    width: '320px',
    position: 'relative',
  },
  crestImageSmall: {
    height: "62.5px",
    width: "62.5px",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -57%)",
    opacity: "50%",
  },
  upgradeArrowBox: {
    display: "flex",
    alignItems: "center",
    "@media (max-width:1000px)": {
      display: "none",
    },
  },
  crestUpgrade: {
    marginLeft: "20px",
    marginRight: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:1000px)": {
      display: "none",
    },
  },
  opacity: {
    opacity: "50%",
  },
}


//INFO: Page One
const SigilButtonGroup = styled(ToggleButtonGroup)({
  display: "grid",
  width: "100%",
  gridTemplateColumns: "repeat(4, 1fr)",
  columnGap: "20px",
  rowGap: "20px",
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "16px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "16px",
  },
  "@media (max-width:1100px)": {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const SigilWrapper = styled(ToggleButton)({
  display: "flex",
  borderRadius: "16px",
  flexDirection: "column",
  alignItems: "flex-start",
  background: "transparent",
  textTransform: "none",
  paddingLeft: "2px",
  paddingTop: "2px",
  paddingBottom: 0,
  paddingRight: 0,
  "&:hover": {
    background: "transparent",
  },
  "#sigilCheckedIcon": {
    position: 'absolute',
    bottom: 0,
    right: '-10px',
    visibility: 'hidden',
  },
  "&.Mui-selected": {
    background: "transparent",
    "& #sigilImage": {
      borderRadius: "16px",
      outline: "2px solid #27ED82",
    },
    "#sigilCheckedIcon": {
      visibility: 'visible',
    },
    "&:hover": {
      background: "transparent",
    },
  },
});

const ColourButtonGroup = styled(ToggleButtonGroup)({
  display: "flex",
  flexWrap: "wrap",
  columnGap: "30px",
  rowGap: "20px",
  marginTop: "25px",
  marginBottom: "10px",
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "50%",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "50%",
  },
});

const ColourWrapper = styled(ToggleButton)({
  display: "flex",
  justifyContent: "center",
  height: "54px",
  width: "54px",
  borderRadius: "50%",
  background: "transparent",
  paddingLeft: 0,
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 0,
  "&:hover": {
    background: "transparent",
  },
  "&.Mui-selected": {
    background: "transparent",
    borderRadius: "50%",
    border: "2px solid #27ED82",
    borderLeft: "2px solid #27ED82!important",
    "&:hover": {
      background: "transparent",
    },
  },
});

const BannerButtonGroup = styled(ToggleButtonGroup)({
  display: "flex",
  flexWrap: "wrap",
  columnGap: "40px",
  rowGap: "20px",
  marginTop: "25px",
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "16px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "16px",
  },
});

const BannerWrapper = styled(ToggleButton)({
  borderRadius: "16px",
  background: "transparent",
  paddingLeft: "2px",
  paddingTop: "2px",
  paddingBottom: 0,
  paddingRight: 0,
  justifyContent: "flex-start",
  "&:hover": {
    background: "transparent",
  },
  "#sigilCheckedIcon": {
    position: 'absolute',
    bottom: 0,
    right: '-10px',
    visibility: 'hidden',
  },
  "&.Mui-selected": {
    background: "transparent",
    "& #bannerImage": {
      borderRadius: "16px",
      outline: "2px solid #27ED82",
    },
    "#sigilCheckedIcon": {
      visibility: 'visible',
    },
    "& #crestImage": {
      borderRadius: "16px",
      outline: "2px solid #27ED82",
    },
    "&:hover": {
      background: "transparent",
    },
  },
});

//INFO: Page Two

const CrestButtonGroup = styled(ToggleButtonGroup)({
  display: "flex",
  flexWrap: "wrap",
  columnGap: "40px",
  rowGap: "20px",
  marginTop: "25px",
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "16px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "16px",
  },
});

const CrestWrapper = styled(ToggleButton)({
  borderRadius: "16px",
  background: "transparent",
  paddingLeft: "2px",
  paddingTop: "2px",
  paddingBottom: 0,
  paddingRight: 0,
  justifyContent: "flex-start",
  "&:hover": {
    background: "transparent",
  },
  "#crestDesign": {
    width: '80px',
  },
  "#sigilCheckedIcon": {
    position: 'absolute',
    bottom: '-10px',
    right: '-10px',
    visibility: 'hidden',
  },
  "&.Mui-selected": {
    background: "transparent",
    "& #bannerImage": {
      borderRadius: "16px",
      outline: "2px solid #27ED82",
    },
    "#sigilCheckedIcon": {
      visibility: 'visible',
    },
    "& #crestDesign": {
      borderRadius: "16px",
      outline: "2px solid #27ED82",
    },
    "&:hover": {
      background: "transparent",
    },
  },
});

export default PaidUpdates;