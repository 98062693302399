import BigNumber from "bignumber.js";
import { BIG_ONE, BIG_ZERO } from "../constants";

const QUADRILLION = BIG_ONE.shiftedBy(15);
const TRILLION = BIG_ONE.shiftedBy(12);
const BILLION = BIG_ONE.shiftedBy(9);
const MILLION = BIG_ONE.shiftedBy(6);
const THOUSAND = BIG_ONE.shiftedBy(3);

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}

export const parseBN = (input?: string | BigNumber | number | null, defaultValue?: BigNumber) => {
  if (!input && input !== 0) return defaultValue;
  const result = BigNumber.isBigNumber(input) ? input : new BigNumber(input);
  if (!result.isFinite() || result.isNaN())
    return defaultValue;

  return result;
};

export const bnOrZero = (input?: string | BigNumber | number | null, defaultValue: BigNumber = BIG_ZERO) => {
  return parseBN(input, defaultValue)!;
};

export const toHumanNumber = (input?: string | BigNumber | number, dp: number = 5) => {
  const value = bnOrZero(input);

  if (value.lt(THOUSAND))
    return value.decimalPlaces(dp).toFormat();

  if (value.lt(MILLION))
    return `${value.shiftedBy(-3).decimalPlaces(dp).toFormat()}K`

  if (value.lt(BILLION))
    return `${value.shiftedBy(-6).decimalPlaces(dp).toFormat()}M`

  if (value.lt(TRILLION))
    return `${value.shiftedBy(-9).decimalPlaces(dp).toFormat()}B`

  if (value.lt(QUADRILLION))
    return `${value.shiftedBy(-12).decimalPlaces(dp).toFormat()}T`

  return `${value.shiftedBy(-15).decimalPlaces(dp).toFormat()}B`
};

export const formatIntegerNumber = (input?: string | BigNumber | number) => {
  const amount = parseBN(input);
  if (!amount) return "0";
  if (amount.gt(1e15))
    return amount.shiftedBy(-15).toFormat(2) + "T"
  if (amount.gt(1e12))
    return amount.shiftedBy(-12).toFormat(2) + "Q"
  if (amount.gt(1e9))
    return amount.shiftedBy(-9).toFormat(2) + "B"
  if (amount.gt(1e6))
    return amount.shiftedBy(-6).toFormat(2) + "M"
  if (amount.gt(1e3))
    return amount.shiftedBy(-3).toFormat(2) + "K"
  return amount.toFormat(0);
}

export const formatGuildHuny = (input?: string | BigNumber | number) => {
  const amount = parseBN(input);
  if (!amount) return "0";

  return amount.toFormat(0);
}

export const formatZWAPLabel = (input: BigNumber) => {
  const amount = input.shiftedBy(-12);
  if (!amount.isZero() && amount.lt(0.01)) return "<0.01";
  return amount.toFormat(2);
};

export const camelize = (str: String) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}