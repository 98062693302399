import { StoreActionTypes } from "./actions";
import { ZOMGState } from "./types";

const initial_state: ZOMGState = {
  store: {
    craftables: {},
    resources: [],
  },
}

const reducer = (state: ZOMGState = initial_state, action: any): ZOMGState => {
  const { payload, type } = action;
  switch (type) {
    case StoreActionTypes.UPDATE_STORE:
      return {
        ...state,
        store: {
          ...payload
        }
      }
    default:
      return state;
  }
}

export default reducer;
