import BigNumber from "bignumber.js";

export const computeInflation = (inflation: any, hunySupply: BigNumber, purchaseCount: number) => {
  const inflationMatch = inflation.constructor.match(/^0x[a-z0-9]{40}.([a-z0-9]*)$/i);

  if (!inflationMatch)
    throw new Error("invalid inflation constructor");

  const type = inflationMatch[1];
  switch (type) {
    case "SupplyScaledInflation": {
      const inflationBps = new BigNumber(inflation.arguments[0]).times(purchaseCount);
      return inflationBps.times(hunySupply).dividedToIntegerBy(10000);
    }
    case "NumericInflation": {
      const inflationAmt = new BigNumber(inflation.arguments[0]).times(purchaseCount)
      return inflationAmt;
    }
    case "NoInflation": {
      return new BigNumber(0);
    }
    default: throw new Error(`invalid inflation type: ${type}`);
  }
}

export const computePrice = (price: any, hunySupply: BigNumber, purchaseCount: number) => {
  const priceMatch = price.constructor.match(/^0x[a-z0-9]{40}.([a-z0-9]*)$/i);
  if (!priceMatch)
    throw new Error("invalid price constructor");

  const type = priceMatch[1];
  switch (type) {
    case "NumericPrice": {
      const numericPrice = new BigNumber(price.arguments[0]);
      const inflation = computeInflation(price.arguments[1], hunySupply, purchaseCount)
      return numericPrice.plus(inflation);
    }
    case "SupplyScaledPrice": {
      const supplyScaledPrice = new BigNumber(price.arguments[0]).times(hunySupply).dividedToIntegerBy(10000);
      const inflation = computeInflation(price.arguments[1], hunySupply, purchaseCount)
      return supplyScaledPrice.plus(inflation);
    }
    default: throw new Error(`invalid price type: ${type}`);
  }

}

export const computeFinalPrice = (priceScale: any, hunySupply: BigNumber, purchaseCount: number) => {
  const priceScaleMatch = priceScale.constructor.match(/^0x[a-z0-9]{40}.([a-z0-9]*)$/i);
  if (!priceScaleMatch)
    throw new Error("invalid price scale constructor");

  const type = priceScaleMatch[1];

  let finalPrice: BigNumber, parameters;
  switch (type) {
    case "MinPrice": {
      const [priceA, priceB] = priceScale.arguments;
      const priceAValue = computePrice(priceA, hunySupply, purchaseCount);
      const priceBValue = computePrice(priceB, hunySupply, purchaseCount);

      finalPrice = BigNumber.min(priceAValue, priceBValue);
      parameters = [priceAValue, priceBValue];
      break;
    }
    case "MaxPrice": {
      const [priceA, priceB] = priceScale.arguments;
      const priceAValue = computePrice(priceA, hunySupply, purchaseCount);
      const priceBValue = computePrice(priceB, hunySupply, purchaseCount);

      finalPrice = BigNumber.max(priceAValue, priceBValue);
      parameters = [priceAValue, priceBValue];
      break;
    }
    case "BoundedPrice": {
      const [priceTarget, priceMin, priceMax] = priceScale.arguments;
      const priceTargetValue = computePrice(priceTarget, hunySupply, purchaseCount);
      const priceMinValue = computePrice(priceMin, hunySupply, purchaseCount);
      const priceMaxValue = computePrice(priceMax, hunySupply, purchaseCount);

      finalPrice = BigNumber.min(BigNumber.max(priceTargetValue, priceMinValue), priceMaxValue);
      parameters = [priceTargetValue, priceMinValue, priceMaxValue];
      break;
    }
    case "DirectPrice": {
      const [price] = priceScale.arguments;
      const priceValue = computePrice(price, hunySupply, purchaseCount);

      finalPrice = priceValue;
      parameters = [priceValue];
      break;
    }
    default: throw new Error(`invalid price scale: ${type}`);
  }

  return {
    type,
    parameters,
    finalPrice,
    hunySupply,
  }
};
