import { Box, CircularProgress, Link, SvgIcon, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ExternalLink } from "assets";
import Huny from 'assets/Huny.svg';
import ElderberryResource from "assets/quests/Locations/ElderberryResource.svg";
import ZolraniumScrapResource from "assets/quests/Locations/ZolraniumScrapResource.svg";
import StoreItemFrame from "assets/quests/Locations/ZOMGStore/StoreItemFrame.svg";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { STATS } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { EquipRequirements, GEMS, ZOrdnanceClasses } from "components/Metazoa/ResourceConstants";
import { QuestConstants } from "components/Quest";
import { logger, waitForTx } from "core/utilities";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { ZOMGCraftItem } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useTaskSubscriber, useToaster } from "utils";
import { Decimals, MissionGroundResource } from "utils/constants";
import { bnOrZero, formatIntegerNumber, getExplorerLink } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
export interface CraftProps {
  item: ZOMGCraftItem;
  open: boolean;
  onClose: () => void;
}

export const getBonusRequirement = (requirement: string) => {
  const requirementSplit = requirement.split('/');
  const capitalize = requirementSplit[1].charAt(0).toUpperCase() + requirementSplit[1].slice(1);
  if (capitalize === "Max") return "Max Metazoa Level"
  return capitalize;
}


export const getOrdnanceId = (item: ZOMGCraftItem) => {
  const nameSplit = item.name.split('-');
  return nameSplit[0];
}

export const getOrdnanceName = (item: ZOMGCraftItem) => {
  const nameSplit = item.name.split('-');
  if (nameSplit.length > 2) {
    const name = nameSplit[1] + "-" + nameSplit[2]
    return name;
  }
  return nameSplit[1];
}

export const getProfession = (profession: string) => {
  switch (profession) {
    case "STR": return "Marauder"
    case "INT": return "Psionic"
    case "DEX": return "Astrominer"
    default: return "-"
  }
}

const CraftZOrdnanceDialog: React.FC<CraftProps> = (props: CraftProps) => {
  const { item, open, onClose, } = props;
  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const ownedGems = tokenState.resources.gems;
  const [itemCrafted, setItemCrafted] = useState<boolean>(false);
  const [craftTxHash, setCraftTxHash] = useState<string>("");
  const [runCraftItem, loadingCraftItem] = useAsyncTask("returnBase", (error) => {
    toaster(error?.message ?? "Error Crafting Item");
    revertToInitialState();
  });


  const [loadingFetchResources] = useTaskSubscriber('refetchResources');
  const isLoading = loadingCraftItem || loadingFetchResources

  const revertToInitialState = () => {
    setItemCrafted(false);
    setCraftTxHash("")
  }

  const handleOnClose = () => {
    if (isLoading) return;
    revertToInitialState();
    onClose();
  }

  const handleCraft = (item: ZOMGCraftItem) => {
    runCraftItem(async () => {
      await craftItem(item);
    })
  }

  const isCraftDisabled = (item: ZOMGCraftItem) => {
    if (!wallet) return true;
    const ownedHunyTokens = tokenState.HunyTokens;
    const ownedZScraps = tokenState.resources["zolranium-scraps"];
    if (bnOrZero(item.cost.huny).shiftedBy(-Decimals.HUNY).gt(ownedHunyTokens)) return true;
    if (bnOrZero(item.cost.zScrap).gt(ownedZScraps)) return true;
    if (Object.entries(item.cost).length > 2) {
      if (!ownedGems?.C) return true;
      const ownedCGems = ownedGems.C;
      const filteredGemCost = Object.keys(item.cost)
        .filter(key => Object.keys(STATS).includes(key))
        .reduce((obj, key) => {
          obj[key] = item.cost[key];
          return obj;
        }, {});
      let ownedCGemsMap: SimpleMap<number> = {};
      Object.entries(ownedCGems).map(([type, idList]) => {
        return ownedCGemsMap = {
          ...ownedCGemsMap,
          [type]: idList.length
        }
      })
      for (const cost of Object.entries(filteredGemCost)) {
        if (!!ownedCGemsMap[cost[0]] && !!bnOrZero(cost[1] as number).lte(ownedCGemsMap[cost[0]])) continue;
        return true;
      }
    }
    return false;
  }

  const craftItem = async (item: ZOMGCraftItem) => {
    const ownedCGems = ownedGems?.C;
    const craftItemTx = await TBMConnector.craftItem(item, (!!ownedCGems && ownedCGems));
    toaster(`Submitted Craft Item `, { hash: craftItemTx.id! });
    if (craftItemTx.isRejected() || !craftItemTx.id) throw new Error("Submitted transaction was rejected.");

    const tx = await waitForTx(craftItemTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(craftItemTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    setItemCrafted(true);
    setCraftTxHash(craftItemTx.id);
    toaster(`Z-Ordnance Crafted `, { hash: craftItemTx.id!, overridePersist: true });
    dispatch(actions.Token.refetchResource());
  }

  const getOwned = (costLabel: string, cost: number | BigNumber) => {
    const ownedHunyTokens = tokenState.HunyTokens;
    const ownedZScraps = tokenState.resources["zolranium-scraps"];
    const ownedBerry = tokenState.resources[MissionGroundResource.Elderberries];
    if (costLabel === "Zolranium Scraps") {
      const zScrapCost = bnOrZero(cost)
      return (
        <Typography variant="body1" color={zScrapCost.lt(ownedZScraps) ? "success.main" : "error"} sx={combineStyles(styles.tooltipText, styles.costText)} component="span">
          {formatIntegerNumber(bnOrZero(ownedZScraps).shiftedBy(-2))}&nbsp;
          <Box
            component="img"
            src={ZolraniumScrapResource}
            width='20px'
            height='20px'
            sx={{ verticalAlign: 'sub' }}
          />
        </Typography>
      )
    }
    if (costLabel === "Elderberries") {
      const berryCost = bnOrZero(cost)
      if (berryCost.lte(0)) return;
      return (
        <Typography variant="body1" color={berryCost.lt(ownedBerry) ? "success.main" : "error"} sx={combineStyles(styles.tooltipText, styles.costText)} component="span">
          {formatIntegerNumber(bnOrZero(ownedBerry).shiftedBy(-2))}&nbsp;
          <Box
            component="img"
            src={ElderberryResource}
            width='20px'
            height='20px'
            sx={{ verticalAlign: 'sub' }}
          />
        </Typography>
      )
    }
    if (costLabel === "HUNY") {
      const hunyCost = bnOrZero(cost).shiftedBy(-12)
      return (
        <Typography variant="body1" color={hunyCost.lt(ownedHunyTokens) ? "success.main" : "error"} sx={combineStyles(styles.tooltipText, styles.costText)} component="span">
          {formatIntegerNumber(bnOrZero(ownedHunyTokens))}&nbsp;
          <Box
            component="img"
            src={Huny}
            width='20px'
            height='20px'
            sx={{ verticalAlign: 'sub' }}
          />
        </Typography>
      )
    }

    const costLabelSplit = costLabel.split(" - ");
    const gemName = costLabelSplit[0];
    const gemAffinity = Object.values(GEMS.C).find(gem => gem.name === gemName);
    const isZero = !tokenState.resources.gems || !tokenState.resources.gems.C || !tokenState.resources.gems.C[gemAffinity!.type];
    const canAfford = bnOrZero(tokenState.resources.gems?.C?.[gemAffinity!.type].length).gte(cost);
    return (
      <Typography variant="body1" color={(isZero || !canAfford) ? "error" : "success.main"} sx={combineStyles(styles.tooltipText, styles.costText)} component="span">
        {isZero ? 0 : formatIntegerNumber(bnOrZero(tokenState.resources.gems?.C?.[gemAffinity!.type]?.length))}&nbsp;
        <Box
          component="img"
          src={gemAffinity!.icon}
          width='20px'
          height='20px'
          sx={{ verticalAlign: 'sub' }}
        />
      </Typography>
    )
  }

  const getHeader = () => {
    if (itemCrafted && craftTxHash) return "Item Crafted"
    return "Craft Z-Ordnance";
  }

  const getContent = () => {
    if (itemCrafted && craftTxHash)
      return (
        <Box sx={styles.contentBox}>
          <Typography variant="body1" color="primary">You have successfully crafted {getOrdnanceName(item)}!</Typography>
          <Box
            height="150px"
            sx={styles.itemFrame}>
            <Box
              component="img"
              src={ZOrdnanceClasses[getOrdnanceId(item)]}
              width="90%"
              height="90%"
              onError={QuestConstants.DEFAULT_SRC}
            />
          </Box>
          <Link
            target="_blank"
            href={getExplorerLink("tx", craftTxHash, network)}
            sx={styles.viewTx}
          >
            View Transaction
            <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
          </Link>
          <ContainedButton sx={styles.craftButton} href="/metazoa">View Ordnance</ContainedButton>
        </Box>
      )
    return (
      <Box sx={styles.contentBox}>
        <Box sx={styles.textContainer}>
          <Box sx={styles.textColumn}>
            <Box sx={styles.textBox}>
              <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                You're Crafting
              </Typography>
              <Typography variant="body1" color="success.main" sx={styles.textWrapper}>
                {getOrdnanceName(item)}
              </Typography>
            </Box>
            {!!Object.keys(item?.requirement ?? {}).length && (
              <Box sx={styles.textBox}>
                <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                  Equip Requirement
                </Typography>
                {Object.entries(item.requirement).map(([requirement, level]) => (
                  <Typography variant="body1" color="success.main" sx={styles.textWrapper} key={requirement}>
                    {(requirement.toLowerCase() === 'Profession'.toLowerCase())
                      ? (`${EquipRequirements[requirement]} ${getProfession(level)}`)
                      : (`${EquipRequirements[requirement]} ${level}`)
                    }
                  </Typography>
                ))}
              </Box>
            )}
            {!!item?.attributes?.Damage &&
              <Box sx={styles.textBox}>
                <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                  Damage
                </Typography>
                <Typography variant="body1" color="success.main" sx={styles.textWrapper}>
                  {item.attributes.Damage}
                </Typography>
              </Box>
            }
            {!!Object.keys(item.stats.default ?? {}).length && (
              <Box sx={styles.textBox}>
                <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                  Bonus Stat
                </Typography>
                <Box sx={styles.statGroup}>
                  {Object.entries(item.stats.default ?? {}).map(([stat, point]) =>
                    !point
                      ? null
                      : (
                        <Typography variant="body1" color="success.main" sx={styles.textWrapper}
                          component="span" key={stat}>
                          <Box component="img" src={STATS[stat].icon} alt={stat} />&nbsp;{stat}&nbsp;+{point}
                        </Typography>
                      ))}
                </Box>
              </Box>
            )}
            {!!Object.keys(item?.stats ?? {}).length &&
              Object.entries(item.stats)
                .filter(([type,]) => (type !== 'default'))
                .map(([type, stats]) => (
                  <Box sx={styles.textBox} key={type}>
                    <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                      Additional Stats for {getBonusRequirement(type)}
                    </Typography>
                    <Box sx={styles.statGroup}>
                      {!!Object.keys(stats ?? {}).length &&
                        Object.entries(stats)
                          .filter(([stat, point]) => !(!point || point - (item?.stats?.default?.[stat] ?? point) <= 0))
                          .map(([stat, point]) => (
                            <Typography variant="body1" color="success.main" sx={styles.textWrapper} component="span" key={stat}>
                              <Box component="img" src={STATS[stat].icon} alt={stat} />&nbsp;{stat}&nbsp;+{point - item.stats.default[stat]}
                            </Typography>
                          ))}
                    </Box>
                  </Box>
                ))}
          </Box>
          <Box sx={styles.itemImageColumn}>
            <Box
              height="150px"
              sx={styles.itemFrame}>
              <Box
                component="img"
                src={ZOrdnanceClasses[getOrdnanceId(item)]}
                width="90%"
                height="90%"
                onError={QuestConstants.DEFAULT_SRC}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={styles.costRow}>
          <Typography variant="body1" color="primary">You’ll need the following to craft this Z-Ordnance:</Typography>
          <Box sx={styles.costContainer}>
            <TableContainer sx={styles.tableContainer}>
              <Table>
                <TableHead sx={styles.tableHead}>
                  <TableRow>
                    <TableCell align="left" width="50%">
                      <Typography variant="body1" color="primary">Item</Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Typography variant="body1" color="primary">You Need</Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Typography variant="body1" color="primary">You Own</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={styles.tableBody}>
                  {
                    Object.entries(item.cost).map(([label, cost], idx) => {
                      let costLabel = ""
                      let decimals = 0;
                      let iconSrc = "";

                      logger("debug-dialog/CraftZOrd", {
                        label
                      })
                      if (label === 'huny') {
                        costLabel = "HUNY"
                        decimals = -12;
                        iconSrc = Huny
                      };
                      if (label === 'zScrap') {
                        costLabel = "Zolranium Scraps"
                        decimals = -2
                        iconSrc = ZolraniumScrapResource
                      }
                      if (label === 'berry') {
                        costLabel = "Elderberries"
                        decimals = -2
                        iconSrc = ElderberryResource
                      }
                      if (!!GEMS['C'][label]) {
                        costLabel = GEMS['C'][label].name + " - Class C"
                        iconSrc = GEMS['C'][label].icon;
                      }
                      return (
                        <TableRow key={idx}>
                          <TableCell align="left">
                            <Typography variant="body1" color="primary" sx={combineStyles(styles.tooltipText,)}>{costLabel}</Typography>
                          </TableCell>
                          <TableCell align="right" sx={{ justifyContent: "flex-end" }}>
                            <Typography variant="body1" color="primary" sx={combineStyles(styles.tooltipText, styles.costText)} component="span">
                              {formatIntegerNumber(bnOrZero(cost).shiftedBy(decimals))}&nbsp;
                              <Box
                                component="img"
                                src={iconSrc}
                                width='20px'
                                height='20px'
                                sx={{ verticalAlign: 'sub' }}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell align="right" sx={{ justifyContent: "flex-end" }}>
                            {!!costLabel && getOwned(costLabel, cost)}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <Box>
          <Typography variant="body1" color={isCraftDisabled(item) ? "error" : "success.main"}>{isCraftDisabled(item) ? "You’re missing some items." : "You’re all set!"}</Typography>
        </Box>
        <Box>
          <ContainedButton disabled={isCraftDisabled(item) || isLoading} onClick={() => handleCraft(item)} sx={styles.craftButton} >
            {
              isLoading
                ? <CircularProgress size={18} />
                : "Confirm & Craft"
            }
          </ContainedButton>
        </Box>
      </Box>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true}>
      {getContent()}
    </DialogModal>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    width: "100%",
    maxHeight: '50vh',
    marginTop: "10px",
    paddingRight: '10px',
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    gap: '1em',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginY: "10px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
    '.masked-overflow': {
      maskImage: 'none',
      WebkitMaskImage: 'none',
      overflow: 'hidden'
    },
  },
  textContainer: {
    width: '100%',
    padding: '28px 40px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    border: '1px solid #AEF1EE',
    borderRadius: '24px',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    minHeight: '200px',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginY: "20px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
    '.masked-overflow': {
      maskImage: 'none',
      WebkitMaskImage: 'none',
      overflow: 'hidden'
    },
    "@media(max-width:768px)": {
      flexDirection: 'column-reverse',
      gap: '1.5em',
      padding: '20px',
    }
  },
  textColumn: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    "@media(max-width:768px)": {
      width: '100%',
    }
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  itemFrame: {
    backgroundImage: `url(${StoreItemFrame})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
  },

  statGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5em',
  },

  costRow: {
    width: '100%',
  },
  costContainer: {
    width: '100%',
    padding: '18px 34px',
    borderRadius: '16px',
    border: '1px solid #AEF1EE55',
    background: 'rgba(174, 241, 238, 0.1)',
  },

  tableContainer: {
    // overflowY: "auto",
    // maxHeight: "25vh",
    // maxWidth: "700px",
    paddingRight: "10px",
    paddingBottom: "10px",
    // "& .MuiTableCell-stickyHeader": {
    //   backgroundColor: "transparent",
    //   top: "",
    //   left: "",
    // },
    // '::-webkit-scrollbar': {
    //   height: '8px',
    //   width: '8px',
    // },
    // '::-webkit-scrollbar-track': {
    //   marginTop: "10px",
    // },
    // '::-webkit-scrollbar-thumb': {
    //   background: "#888",
    //   borderRadius: "20px",
    // },
    // '::-webkit-scrollbar-thumb:hover': {
    //   background: "#555",
    // },
    // '::-webkit-scrollbar-corner': {
    //   background: "rgba(0,0,0,0)",
    // },
  },
  tableHead: {
    "& th.MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "transparent",
      whiteSpace: "nowrap",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "transparent",
      whiteSpace: "nowrap",
    },
  },

  tooltipText: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    display: 'flex',
    alignItems: 'center',
  },

  costText: {
    justifyContent: 'flex-end'
  },

  craftButton: {
    padding: '14px 60px',
    marginBottom: '10px',
    minWidth: '200px',
    height: 60,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },

  viewTx: {
    marginTop: "30px",
    marginBottom: "20px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
}

export default CraftZOrdnanceDialog;