import { Box, Button, CircularProgress, Link, styled, SvgIcon, SxProps, Typography } from "@mui/material";
import { BaseButton, ExternalLink } from "assets";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { DEFAULT_SRC } from "components/Guild/components/GuildConstants";
import { logger, waitForTx } from "core/utilities";
import { MetazoaClient } from "core/utilities/metazoa";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { actions } from "store";
import { TBMConnector } from "tbm";
import { useToaster } from "utils";
import { Decimals, ITEM_ID } from "utils/constants";
import { bnOrZero } from "utils/strings";
import { getExplorerLink } from "utils/strings/links";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";
import { ProfileDetailsInputs } from "../UpdateForm/UpdateForm";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  inputs: ProfileDetailsInputs;
  price: number;
  hasChangeProfile: boolean;
  setInputProfileDetails: React.Dispatch<React.SetStateAction<ProfileDetailsInputs>>;
}

const UpdateProfileDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, inputs, price, hasChangeProfile, setInputProfileDetails } = props;
  const wallet = useRedux((state) => state.wallet.wallet);
  const hunyTokens = useRedux((state) => state.token.HunyTokens);
  const dispatch = useDispatch();
  const [updateProfileComplete, setUpdateProfileComplete] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);
  const [purchaseTxHash, setPurchaseTxHash] = useState<string>("");
  const toaster = useToaster();
  const network = useNetwork();
  const [runQueryApproved, loadingQueryApproved] = useAsyncTask("queryApproved");
  const [runApprove, loadingApprove] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
  });
  const [runUpdateProfile, loadingUpdateProfile] = useAsyncTask("update", (error) => {
    toaster(error?.message ?? "Error Updating Profile");
  });

  useEffect(() => {
    if (wallet) {
      runQueryApproved(async () => {
        const approved = await TBMConnector.checkEmporiumApproved(wallet);
        setApproved(approved);
      })
    }
    // eslint-disable-next-line
  }, [wallet, network]);

  const handleApprove = () => {
    runApprove(async () => {
      if (!wallet) throw new Error("Wallet not connected");

      const approveTx = await TBMConnector.increaseEmporiumAllowance(wallet, new BigNumber(2).pow(128).minus(1).dp(0));

      if (approveTx?.id) {
        try {
          await waitForTx(approveTx.id);
          setApproved(true);
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    })
  }

  const handleUpdateProfile = () => {
    runUpdateProfile(async () => {
      if (!hasChangeProfile) return;

      if (!wallet) throw new Error("Wallet not connected")

      if (hunyTokens < bnOrZero(price).shiftedBy(-Decimals.HUNY).toNumber()) throw new Error("Insufficient HUNY")

      const metazoaClient = new MetazoaClient(network);
      const maxPrice = bnOrZero(price).times(1.05).dp(0);

      const userParams = {
        avatarUrl: inputs.avatarUrl,
        username: inputs.username
      }

      const params = JSON.stringify({ userParams: userParams });
      const purchaseTx = await TBMConnector.purchaseItem(wallet, ITEM_ID[network].RefigurationSeal, maxPrice, params);

      if (purchaseTx?.id) {
        setPurchaseTxHash(purchaseTx.id);
        const tx = await waitForTx(purchaseTx.id);

        if (tx.status >= 3) {
          throw new Error("Error Updating Profile");
        }
      }

      while (true) {
        try {
          const { result } = await metazoaClient.getProfile(wallet.addressInfo.byte20.toLocaleLowerCase());
          setUpdateProfileComplete(true);
          dispatch(actions.Profile.updateProfile(result.model));
          setInputProfileDetails({ avatarUrl: "", username: "" });

          break;
        } catch (err) {
          logger(err)
          console.error(err)
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
      }
    });
  }

  const handleOnClose = () => {
    if (loadingUpdateProfile) return;

    onClose();
  }

  const getHeader = () => {
    if (updateProfileComplete) {
      return "Update Profile success!";
    } else if (loadingUpdateProfile) {
      return "Updating Profile...";
    } else {
      return "Update Profile";
    }
  }

  const getContent = () => {
    if (updateProfileComplete) {
      return (
        <Fragment>
          <Box sx={styles.contentBox}>
            {/* tx hash */}
            <Link
              target="_blank"
              href={getExplorerLink("tx", purchaseTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>

            {/* link to profile */}
            <Button
              component={RouterLink}
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={styles.profileButton}
              to={`/metazoa`}
            >
              <BaseButtonBox>
                <BaseButton height="100%" />
              </BaseButtonBox>
              My Profile
            </Button>
          </Box>
        </Fragment>
      )
    }

    if (loadingUpdateProfile) {
      return (
        <Fragment>
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Fragment>
      )
    }

    return (
      <Fragment>
        {/* avatar */}
        {!!inputs.avatarUrl &&
          <Box component="img" src={inputs.avatarUrl} width="100px" height="100px" borderRadius={50} mt="20px" onError={DEFAULT_SRC} />
        }

        {/* username */}
        <Box mt="20px">
          <Typography color="primary" variant="body1">
            Username: {inputs.username}
          </Typography>
        </Box>

        <Box display="flex" sx={styles.buttonBox}>
          {!approved &&
            <ContainedButton sx={styles.approveButton} onClick={handleApprove} disabled={loadingApprove || loadingQueryApproved}>
              {loadingApprove || loadingQueryApproved
                ? <CircularProgress size={24} />
                : <span style={{ lineHeight: '2rem' }}>Approve</span>
              }
            </ContainedButton>
          }

          <ContainedButton
            disabled={!approved || loadingApprove || loadingQueryApproved}
            sx={styles.updateButton}
            onClick={handleUpdateProfile}
          >
            Update
          </ContainedButton>
        </Box>
      </Fragment>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} hideCloseButton={loadingUpdateProfile} disableScrollLock={true}>
      {getContent()}
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 700,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  profileButton: {
    marginTop: "10px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  loadingImage: {
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  viewTx: {
    marginTop: "20px",
    marginBottom: "40px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  buttonBox: {
    marginTop: "40px",
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  approveButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    marginRight: '10px',
    width: 270,
    "@media (max-width:600px)": {
      marginRight: 0,
      marginBottom: '10px',
      width: '100%',
    }
  },
  updateButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    width: 270,
    "@media (max-width:600px)": {
      width: '100%',
    }
  },
};

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    height: "100%",
  },
});


export default UpdateProfileDialog;
