import { Box, SxProps, Typography, Zoom } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { GuildTopCommanderFrame } from "assets";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import { BANNERS, BANNER_COLOURS, GUILD_GRAY_GRADIENT, GUILD_GREEN_GRADIENT, SIGILS, SIGIL_COLOURS } from "components/Guild/components/GuildConstants";
import { Guild } from "store/types";
import { combineStyles } from "utils/themeUtilities";
import truncate from "utils/truncate";
import { AppTheme, SimpleMap } from "utils/types";

interface RankPodiumProps {
  guild: Guild;
}

const RankPodium: React.FC<RankPodiumProps> = (props: RankPodiumProps) => {
  const { guild } = props;
  const { members } = guild;

  const councilAddresses: string[] = [guild.leaderAddress, ...guild.commanderAddresses]
  const councilLimit = 3;
  const guildFlagLimit = 2;

  return (
    <Box sx={styles.rankPodiumRoot}>
      <Box sx={styles.rankPodiumSigils}>
        {Array.from(Array(guildFlagLimit)).map((x, i) => (
          <Box key={`guildFlag-${i}`}
            sx={combineStyles(
              styles.guildFlagBanner, {
              backgroundImage: `url(${BANNERS[guild.guildBanner.design - 1][BANNER_COLOURS.findIndex((curr) => curr.colour === guild.guildBanner.colour)]})`,
            })}>
            <Box sx={combineStyles(
              styles.guildFlagSigil, {
              backgroundImage: `url(${SIGILS[guild.guildSigil.design - 1][SIGIL_COLOURS.findIndex((curr) => curr.colour === guild.guildSigil.colour)]})`,
            })}
            />
          </Box>
        ))}
      </Box>

      {[...councilAddresses, ...Array(councilLimit - councilAddresses.length)].slice(0, councilLimit).map((addr, idx) => {
        let member = members ? members.find(o => o.address === addr) : undefined;
        return (
          <Zoom
            key={`${guild.id}-${idx}`}
            in={true}
            style={{
              transitionTimingFunction: 'ease-in',
              transitionDelay: `${300 + (180 * idx)}ms`,
            }}
            unmountOnExit
          >
            <Box
              sx={combineStyles(styles.rankPodiumPosition, {
                order: (idx === 0 ? 1 : (idx === 1) ? 0 : 2),
                bottom: (guild.leaderAddress === addr ? '2.5em' : "auto"),
              })}>
              <Box sx={styles.rankPodiumWrapper}>
                <GuildTopCommanderFrame />
                <Box sx={combineStyles(styles.rankPodiumMember, { backgroundImage: (member) ? `url(${member?.avatarUrl ?? DefaultUserProfile})` : 'none', })}>
                  <Box sx={styles.rankPodiumTitle}>
                    <Typography variant="h3" color="primary" sx={styles.rankPodiumRank}>{(idx === 0) ? 'CAPTAIN' : 'OFFICER'}</Typography>
                    {member && (<Typography variant="body1" sx={combineStyles(styles.rankPodiumMemberName, {
                      ...((member && guild.leaderAddress === member.address) ? GUILD_GREEN_GRADIENT : { color: '#AEF1EE' })
                    })}>{member.username ?? truncate(toBech32Address(member.address))}</Typography>)}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Zoom>
        )
      })}
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  rankPodiumRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    placeContent: 'center',
    placeItems: 'center',
    boxSizing: 'content-box',
    margin: "0 auto",
    marginTop: '2.5em',
    position: 'relative',
  },
  rankPodiumSigils: {
    width: "100%",
    position: 'absolute',
    top: '-2.5em',
    left: 0,
    right: 0,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    placeItems: 'center',

    "> *": {
      maxWidth: '250px',
      maxHeight: '250px',
      height: '15vh',
      width: 'auto',
      "@media (max-width: 900px)": {
        height: '15vw',
      },
    },
  },
  guildFlagBanner: {
    position: 'relative',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    maxHeight: '250px',
    minHeight: '150px',
    maxWidth: '117px',
    height: '13vw',
    width: '15vw',
    minWidth: "7rem",
    "@media (min-width: 900px)": {
      height: '20vw',
      minWidth: "9rem",
    },
  },
  guildFlagSigil: {
    height: "90px",
    width: "90px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPositionX: "center",
    position: "absolute",
    top: "42%",
    left: "50%",
    transform: "translate(-50%, -40%)",
    "@media (max-width: 1100px)": {
      height: "7vw",
      width: "7vw",
    },
    "@media (max-width: 900px)": {
      height: "7vh",
      width: "7vh",
    },
  },
  rankPodiumPosition: {
    position: 'relative',
    maxHeight: '100%',
  },
  rankPodiumWrapper: {
    position: 'relative',
    "svg": {
      maxWidth: '100%',
      height: 'auto',
      "@media (min-width: 1280px)": {
        width: '45vh',
      },
    },
  },
  rankPodiumMember: {
    width: '80%',
    height: '80%',
    position: 'absolute',
    bottom: '11%',
    left: '10%',
    borderRadius: "16px",
    boxShadow: "0px 0px 12px 0px #00F0FF",
    objectFit: 'contain',
    objectPosition: 'bottom',
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPositionX: "center",
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    placeItems: 'center',
    justifyContent: 'end',
    flexBasis: '100%',
    '> *': {
      borderBottomRightRadius: '1rem', /* 16px */
      borderBottomLeftRadius: '1rem', /* 16px */
      textAlign: 'center',
      width: '100%',
    },
  },
  rankPodiumTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    placeItems: 'center',
    justifyContent: 'center',
    background: "linear-gradient(180deg, rgba(1, 0, 37, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%)",
    boxShadow: 'inset 0px -1px 0px rgba(174, 241, 238, 0.1)',
    backdropFilter: 'blur(24px)',
    padding: '0.35rem 0',
  },
  rankPodiumRank: {
    fontWeight: 700,
    fontSize: "1.5rem", /* 24px */
    lineHeight: "2.25rem", /* 36px */
    ...GUILD_GRAY_GRADIENT,
    "@media (max-width: 1280px)": {
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
    },

    "@media (max-width:600px)": {
      fontSize: "0.7rem",
    },
  },
  rankPodiumMemberName: {
    fontWeight: '500',
    fontSize: '0.75rem', /* 12px */
    lineHeight: '1.125rem', /* 18px */
    letterSpacing: '20%',
    textAlign: 'center',

    width: '80%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    textTransform: 'uppercase',

    "@media (max-width:600px)": {
      fontSize: "0.5rem",
      lineHeight: "normal",
    },
  },
}

export default RankPodium;