import { ConnectedWallet } from "core/wallet";
import dayjs from "dayjs";
import { NftMetadata, OAuth, Profile } from "store/types";
import { SimpleMap } from "utils/types";
import { Network } from "zilswap-sdk/lib/constants";
import { logger } from ".";
import { HTTP } from "./http";

const METAZOA_ENDPOINTS: SimpleMap<string> = {
  [Network.MainNet]: "https://api.zolar.io",
  [Network.TestNet]: "https://test-api.zolar.io",
} as const;

const ARK_ENDPOINTS: SimpleMap<string> = {
  [Network.MainNet]: "https://api-ark.zilswap.org",
  [Network.TestNet]: "https://test-api-ark.zilswap.org",
} as const;

const LOCALHOST_ENDPOINT = "http://localhost:8181";

const apiPaths = {
  "oauth": "/oauth/access_token",
  "user/detail": "/user/:address/detail",
  "user/update": "/user/:address/update",
  "user/profile/query": "/user/profile/query",
  "metazoa/data": "/metazoa/data",
  "metazoa/tokenId/data": "/metazoa/:tokenId/data",
  "metazoa/tokenId/profession": "/metazoa/:tokenId/profession",
  "hive/kickback": "/hive/kickback",
  "guild/tracker/create": "/guild/tracker/create",
  "guild/tracker/delete": "/guild/tracker/delete",
  "guild/check": "/guild/slug/check",
  "guild/list": "/guild/list",
  "guild/detail": "/guild/:guildId/detail",
  "guild/guildId/list": "/guild/:guildId/list",
  "guild/request": "/guild/:guildId/request",
  "guild/leave": "/guild/:guildId/leave",
  "guild/checkRequest": "/guild/:guildId/:address/request/check",
  "guild/requestList": "/guild/:guildId/request/list",
  "guild/leaders/request": "/guild/:guildId/leader/member/accept",
  "guild/leaders/commanders": "/guild/:guildId/leader/commanders",
  "guild/leaders/remove": "/guild/:guildId/leader/member/remove",
  "guild/leaders/update": "/guild/:guildId/leader/update",
  "guild/image/request": "/guild/:guildId/upload/request",
  "guild/image/notify": "/guild/:guildId/upload/notify",
  "guild/bank/transactions": "/guild/:guildId/bank/transactions",
  "items/zomg/list": "/items/zomg/list",
  "items/list/address": "/items/list?address=:userAddress&limit=:itemLimit",
  "game/zoa/assign": "/game/:address/assign"
}

const arkApiPaths = {
  "history/floor": "/nft/history/floor",
}

const getHttpClient = (network: Network) => {
  const endpoint = process.env.REACT_APP_ARK_API_LOCALHOST === "true" ? LOCALHOST_ENDPOINT : METAZOA_ENDPOINTS[network];
  return new HTTP(endpoint, apiPaths);
}

const getArkHttpClient = (network: Network) => {
  const endpoint = process.env.REACT_APP_ARK_API_LOCALHOST === "true" ? LOCALHOST_ENDPOINT : ARK_ENDPOINTS[network];
  return new HTTP(endpoint, arkApiPaths);
}

export class MetazoaClient {

  private http: HTTP<typeof apiPaths>;
  private httpArk: HTTP<typeof arkApiPaths>;
  constructor(
    public readonly network: Network,
  ) {
    this.http = getHttpClient(network);
    this.httpArk = getArkHttpClient(network);
  }

  checkError = async (result: any) => {
    if (result.error) {
      const message: string[] = [];
      if (result.error?.code)
        message.push(`[${result.error.code}]`);
      if (result.error?.type)
        message.push(`${result.error.type}:`);
      message.push(result.error.message ?? "unknown error");

      throw new Error(message.join(" "));
    }
  }

  metazoaLogin = async (wallet: ConnectedWallet, hostname: string) => {
    const timestamp = dayjs().format("YYYY/MM/DD HH:mm:ss Z");
    const bech32Address = wallet.addressInfo.bech32;
    const signMessage = `[${timestamp}] Metazoa Authentication\nPlease issues my broser at ${hostname} a Metazoa API key for my address:\n${bech32Address}`;

    const signResult = await (window as any).zilPay.wallet.sign(signMessage);
    const { message, publicKey, signature } = signResult
    const data = {
      grant_type: "signature",
      public_key: publicKey,
      signature,
      message
    }
    const url = this.http.path("oauth");
    const result = await this.http.post({ url, data });
    const output = await result.json();
    output.result.address = bech32Address;
    return output;
  }

  refreshToken = async (refresh_token: string) => {
    const data = {
      grant_type: "refresh_token",
      refresh_token
    }

    const url = this.http.path("oauth");
    const result = await this.http.post({ url, data });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  getProfile = async (address?: string) => {
    const url = this.http.path("user/detail", { address });
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  updateProfile = async (address: string, data: Omit<Profile, "id" | "address">, oAuth: OAuth) => {
    const headers = { "authorization": "Bearer " + oAuth.access_token };
    const url = this.http.path("user/update", { address })
    const result = await this.http.post({ url, data, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  };

  checkProfile = async (params: MetazoaClient.CheckProfile) => {
    const url = this.http.path("user/profile/query", {}, params);
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  getCollectionFloor = async (params: ArkClient.CollectionFloorParams) => {
    const url = this.httpArk.path("history/floor", {}, params);
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  };

  getHunyKickback = async (params: ArkClient.KickbackParams) => {
    const url = this.http.path("hive/kickback", {}, params);
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  };

  createTracker = async (data: MetazoaClient.TrackerParams, oAuth: OAuth) => {
    const headers = { "authorization": "Bearer " + oAuth.access_token };
    const url = this.http.path("guild/tracker/create");
    const result = await this.http.post({ url, data, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  deleteTracker = async (params: MetazoaClient.TrackerParams, oAuth: OAuth) => {
    const headers = { "authorization": "Bearer " + oAuth.access_token };
    const url = this.http.path("guild/tracker/delete", {}, params);
    const result = await this.http.post({ url, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  checkGuild = async (params: MetazoaClient.CheckGuildParams) => {
    const url = this.http.path("guild/check", {}, params);
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  listGuilds = async (limit: number = 10000) => {
    const url = this.http.path("guild/list", {}, { limit: limit });
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  guildDetail = async (guildId: string) => {
    const url = this.http.path("guild/detail", { guildId: guildId });
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  listGuildMembers = async (guildId: number) => {
    const url = this.http.path("guild/guildId/list", { guildId: guildId });
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  joinGuild = async (guildId: number, oAuth: OAuth) => {
    const headers = { "authorization": "Bearer " + oAuth.access_token };
    const url = this.http.path("guild/request", { guildId: guildId });
    const result = await this.http.post({ url, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  leaveGuild = async (guildId: number, oAuth: OAuth) => {
    const headers = { "authorization": "Bearer " + oAuth.access_token };
    const url = this.http.path("guild/leave", { guildId: guildId });
    const result = await this.http.post({ url, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  checkJoinGuildRequest = async (guildId: number, address: string) => {
    const url = this.http.path("guild/checkRequest", { guildId: guildId, address: address });
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  joinGuildRequestList = async (guildId: number) => {
    const url = this.http.path("guild/requestList", { guildId: guildId });
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  handleJoinGuildRequest = async (guildId: number, data: MetazoaClient.HandleJoinGuildRequestData, oAuth: OAuth) => {
    const headers = { "authorization": "Bearer " + oAuth.access_token };
    const url = this.http.path("guild/leaders/request", { guildId: guildId });
    const result = await this.http.post({ url, data, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  assignZoaBonusPoint = async (address: string, data: MetazoaClient.AssignZoaBonusPointData, oAuth: OAuth) => {
    const headers = { "authorization": "Bearer " + oAuth.access_token };
    const url = this.http.path("game/zoa/assign", { address: address });
    logger("debug-connector", "MetazoaClient/assignZoaBonusPoint", { url, data, headers })
    const result = await this.http.post({ url, data, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  handleGuildCommanders = async (guildId: number, data: MetazoaClient.HandleGuildCommandersData, oAuth: OAuth) => {
    const headers = { "authorization": "Bearer " + oAuth.access_token };
    const url = this.http.path("guild/leaders/commanders", { guildId: guildId });
    const result = await this.http.post({ url, data, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  removeGuildMember = async (guildId: number, data: MetazoaClient.RemoveGuildMemberData, oAuth: OAuth) => {
    const headers = { "authorization": "Bearer " + oAuth.access_token };
    const url = this.http.path("guild/leaders/remove", { guildId: guildId });
    const result = await this.http.post({ url, data, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  updateGuildDetails = async (guildId: number, data: MetazoaClient.UpdateGuildDetailsData, oAuth: OAuth) => {
    const headers = { "authorization": "Bearer " + oAuth.access_token };
    const url = this.http.path("guild/leaders/update", { guildId: guildId });
    const result = await this.http.post({ url, data, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  requestGuildImageUploadUrl = async (guildId: number, access_token: string) => {
    const headers = { "authorization": "Bearer " + access_token };
    const url = this.http.path("guild/image/request", { guildId: guildId });
    const result = await this.http.get({ url, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  notifyGuildImageUpload = async (guildId: number, access_token: string) => {
    const headers = { "authorization": "Bearer " + access_token };
    const url = this.http.path("guild/image/notify", { guildId: guildId });
    const result = await this.http.post({ url, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  putImageUpload = async (url: string, data: Blob) => {
    await this.http.put({ url, data });
    return
  }

  getGuildBankTransactions = async (guildId: number) => {
    const url = this.http.path("guild/bank/transactions", { guildId: guildId });
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  bulkMetazoaMetadata = async (tokenIds: number[]) => {
    const url = this.http.path("metazoa/data");
    const result = await this.http.post({ url, data: { tokenIds } });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  selectMetazoaProfession = async (token: NftMetadata, selectedProfession: string, oAuth: OAuth,) => {
    const tokenId = parseInt(token.id);
    const data = {
      'profession': selectedProfession
    }
    const headers = { "authorization": "Bearer " + oAuth.access_token };
    const url = this.http.path("metazoa/tokenId/profession", { tokenId: tokenId });
    const result = await this.http.post({ url, data, headers });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  fetchMetazoaProfession = async (token: NftMetadata) => {
    const tokenId = parseInt(token.id);
    const url = this.http.path("metazoa/tokenId/data", { tokenId: tokenId });
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  fetchStoreItems = async (limit: number = 10000) => {
    const url = this.http.path("items/zomg/list", {}, { limit: limit });
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }

  fetchUserInventory = async (address: string, limit: number = 10000) => {
    const url = this.http.path("items/list/address", { userAddress: address, itemLimit: limit });
    const result = await this.http.get({ url });
    const output = await result.json();
    await this.checkError(output);
    return output;
  }
}

export namespace MetazoaClient {

  export interface SelectProfessionParams {
    profession: string,
  }
  export interface CheckGuildParams {
    slug: string,
  }
  export interface CheckProfile {
    addresses: string,
  }

  export interface TrackerParams {
    slug: string,
    name: string,
  }

  export interface HandleJoinGuildRequestData {
    sender: string,
    guildId: number,
    isAccepted: boolean,
  }

  export interface AssignZoaBonusPointData {
    metazoaId: number,
    attribute: string,
    amount: number,
  }

  export interface HandleGuildCommandersData {
    commander: string,
    guildId: number,
    isPromote: boolean,
  }

  export interface RemoveGuildMemberData {
    member: string,
    guildId: string,
  }

  export interface UpdateGuildDetailsData {
    guildId: number,
    description: string,
  }
}

export namespace ArkClient {
  export interface CollectionFloorParams {
    collection: string,
    interval: string,
  }

  export interface KickbackParams {
    epoch: string,
  }
}