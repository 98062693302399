
import dayjs from "dayjs";
import { WalletProvider } from "zilswap-sdk";
import { ZilPayNetworkMap } from "../../utils/constants";
import { ConnectWalletResult } from "./ConnectedWallet";
import { ZilPayConnectedWallet } from "./ZilPayConnectWallet";

export const connectWalletZilPay = async (zilPay: any): Promise<ConnectWalletResult> => {

  if (!zilPay.wallet.isConnect)
    throw new Error("ZilPay connection failed.");

  const account: any = zilPay.wallet.defaultAccount;
  if (!account)
    throw new Error("Please sign in to your ZilPay account before connecting.");
  const timestamp = dayjs();

  const net = zilPay.wallet.net;
  const network = ZilPayNetworkMap[net];
  if (!network)
    throw new Error(`Unsupported network for ZilPay: ${net}`);

  const wallet = new ZilPayConnectedWallet({
    network, timestamp,
    zilpay: zilPay as WalletProvider,
    bech32: account!.bech32,
    base16: account!.base16,
  });

  return { wallet };
};
