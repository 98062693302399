import { useState } from 'react';
import { styled, FormControlLabel, Switch } from "@mui/material";
import { combineStyles } from 'utils/themeUtilities';

interface Props {
  defaultChecked?: boolean;
  label: string | JSX.Element;
  onCheck?: (check: boolean) => void;
  disabled?: boolean;
}

const SwitchZolar: React.FC<Props> = (props: Props) => {
  const { defaultChecked, label, onCheck, disabled } = props;
  const [checked, setChecked] = useState(!!defaultChecked);

  const handleChange = () => {
    setChecked(!checked);
    if (typeof onCheck === "function") onCheck(!checked)
  }

  return (
    <FormControlLabel
      sx={combineStyles({ marginRight: '0' }, 
      { ...(disabled) && { opacity: 0.4 } })}
      control={<StyledSwitch onChange={() => handleChange()} checked={checked} disabled={disabled}/>}
      label={label}
    />
  );
}

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  marginRight: "8px",
  marginLeft: "24px",
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        background: "linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)",
      },
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    boxSizing: 'border-box',
  },
}));

export default SwitchZolar;

