import { Box, CircularProgress, SvgIcon, SxProps, Typography } from "@mui/material";
import { WarningIcon } from "assets";
import ContainedButton from "components/ContainedButton";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { Hug3Recovery } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { BIG_ZERO, ContractsBech32 } from "utils/constants";
import { bnOrZero } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import { ClaimDialog } from "./components";

const DynamicClaim: React.FC = () => {
  const [showClaimDialog, setShowClaimDialog] = useState<boolean>(false)
  const walletAddr = useRedux((state) => state.wallet.wallet?.addressInfo.byte20.toLowerCase());
  const needRecoverHuny = useRedux((state) => state.token.hunyToRecover.hunyReceiveNow);
  const dispatch = useDispatch();
  const toaster = useToaster();
  const [runFetchHunyPots, loadingFetchHunyPots] = useAsyncTask("fetch huny pots", (error) => {
    // If no need recover
    if (error?.message !== "Cannot read properties of null (reading 'recovery_pots')") {
      toaster(error?.message ?? "Error Fetching Huny Pots");
    }
  });

  useEffect(() => {
    runFetchHunyPots(async () => {
      if (!walletAddr) return;

      const zilswap = TBMConnector.getSDK();
      const hug3RecoveryAddr = ContractsBech32[zilswap.network].Hug3Recovery;
      const recoveryPots = await zilswap.zilliqa.blockchain.getSmartContractSubState(
        hug3RecoveryAddr,
        "recovery_pots",
        [walletAddr],
      );
      const hunyNow = recoveryPots.result?.recovery_pots?.[walletAddr]?.[0]?.arguments?.[2];
      const hunyRefinery = recoveryPots.result?.recovery_pots?.[walletAddr]?.[0]?.arguments?.[3]

      const hunyToRecover: Hug3Recovery = {
        hunyReceiveNow: hunyNow,
        hunyToRefinery: hunyRefinery,
      }
      dispatch(actions.Token.updateHunyToRecover(hunyToRecover));
    })
    // eslint-disable-next-line
  }, [walletAddr])

  const handleOnClose = () => {
    setShowClaimDialog(false);
  }
  const handleDynamicClaim = () => {
    setShowClaimDialog(true);
  }
  return (
    <Fragment>
      {bnOrZero(needRecoverHuny).eq(BIG_ZERO) === false &&
        <Box sx={styles.bannerContainer}>
          <Box display="flex" alignItems="center" flex={0.9}>
            <SvgIcon component={WarningIcon} sx={styles.warningIcon} />
            <Typography variant="body1" color="primary" align="left">
              <strong>Sent your Gen 3 Ursas to battle?</strong>
              {" "}
              H.U.G.E corporation has recovered your lost $HUNY tokensfrom the abyss and transported them to safety - claim them now!
            </Typography>
          </Box>
          <ContainedButton
            sx={styles.migrateButton}
            onClick={handleDynamicClaim}
          >
            {loadingFetchHunyPots
              ? <CircularProgress size={18} />
              : "CLAIM"
            }
          </ContainedButton>
          <ClaimDialog
            open={showClaimDialog}
            onClose={handleOnClose}
            walletAddr={walletAddr}
          />
        </Box>
      }
    </Fragment>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  bannerContainer: {
    marginBottom: '10px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    borderRadius: '16px',
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  warningIcon: {
    marginRight: "14px",
    marginBottom: "1.5px",
  },
  migrateButton: {
    height: 60,
    minWidth: 200,
    width: '20%',
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
}

export default DynamicClaim;
