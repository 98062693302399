import { Box, SxProps, Typography, BoxProps } from "@mui/material";
import { AppTheme, SimpleMap } from "utils/types";
import InnerProgress from "./InnerProgress/InnerProgress";

interface Props extends BoxProps {
  phase: number;
}

const ProgressBar: React.FC<Props> = (props: Props) => {
  const { phase } = props;

  return(
    <Box sx={styles.progressBarContainer}>
      <Box maxWidth='xl'sx={styles.progressBar}>
        <Box width="100%" height="34px" sx={styles.progressTotal}>
          <InnerProgress />
        </Box>
        <Box width="3.5%" height="34px" sx={styles.sectionStart}>
          <Typography variant="overline" color="primary" sx={styles.sectionContentActive}>100</Typography>
        </Box>
        <Box width="34.6%" height="34px" sx={styles.section}>
          <Typography variant="overline" color="primary" sx={styles.sectionContentActive}>1000</Typography>
        </Box>
        <Box width="6.5%" height="34px" sx={styles.section}>
          <Typography variant="overline" color="primary" sx={styles.sectionContent}>188</Typography>
        </Box>
        <Box width="24.2%" height="34px" sx={styles.section}>
          <Typography variant="overline" color="primary" sx={styles.sectionContent}>700</Typography>
        </Box>
        <Box width="17.3%" height="34px" sx={styles.section}>
          <Typography variant="overline" color="primary" sx={styles.sectionContent}>500</Typography>
        </Box>
        <Box width="13.9%" height="34px" sx={styles.sectionEnd}>
          <Typography variant="overline" color="primary" sx={styles.sectionContent}>400</Typography>
        </Box>
        
        <Box sx={styles.progressBarOutline}></Box>
      </Box>
      <Box maxWidth='xl'sx={styles.labelsRow}>
        <Box width="3.5%"  sx={styles.sectionLabel}>
          <Typography variant="overline" color="primary" sx={phase === 1 ? styles.labelActive : styles.label}>GEN 0</Typography>
          <Typography variant="subtitle1" color="primary" sx={phase === 1 ? styles.labelActive : styles.label}>Airdrop</Typography>
        </Box>
        <Box width="34.6%" sx={styles.sectionLabel}>
          <Typography variant="overline" color="primary" sx={phase === 2 ? styles.labelActive : styles.label}>GEN 0</Typography>
          <Typography variant="subtitle1" color="primary" sx={phase === 2 ? styles.labelActive : styles.label}>Burn Bear</Typography>
        </Box>
        <Box width="6.5%" sx={styles.sectionLabel}>
          <Typography variant="overline" color="primary" sx={phase === 3 ? styles.labelActive : styles.label}>GEN 0</Typography>
          <Typography variant="subtitle1" color="primary" sx={phase === 3 ? styles.labelActive : styles.label}>10,000 $ZIL</Typography>
        </Box>
        <Box width="24.2%" sx={styles.sectionLabel}>
          <Typography variant="overline" color="primary" sx={styles.label}>GEN 1</Typography>
          <Typography variant="subtitle1" color="primary" sx={styles.label}>12,000 $HUNY</Typography>
        </Box>
        <Box width="17.3%" sx={styles.sectionLabel}>
          <Typography variant="overline" color="primary" sx={styles.label}>GEN 2</Typography>
          <Typography variant="subtitle1" color="primary" sx={styles.label}>18,000 $HUNY</Typography>
        </Box>
        <Box width="13.9%" sx={styles.sectionLabel}>
          <Typography variant="overline" color="primary" sx={styles.label}>GEN 3</Typography>
          <Typography variant="subtitle1" color="primary" sx={styles.label}>27,000 $HUNY</Typography>
        </Box>
      </Box>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  progressBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
  progressBarOutline: {
    position: 'absolute',
    borderRadius: '16px',
    height: '38px',
    padding: '1.5px',
    width: '100%',
    background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
    WebkitMask:'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite:'source-out'
  },
  progressBar: {
    position: 'relative',
    height: '100%',
    width: '100%',
    minWidth:'1000px',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 1px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    overflow: 'hidden',
  },
  progressTotal: {
    position: 'absolute',
    background: 'linear-gradient(225deg, rgba(171, 255, 251, 0.4) 0%, rgba(39, 237, 130, 0.4) 40.1%, rgba(6, 133, 133, 0.4) 100%)',
  },
  sectionStart: {
    margin: '1px',
    marginLeft: '0px',
    borderRadius: '16px 0px 0px 16px',
    border: '2px solid #070716',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    zIndex: '2',
  },
  sectionEnd: {
    marginY: '1px',
    borderRadius: '0px 16px 16px 0px',
    border: '2px solid #070716',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2',
  },
  section: {
    border: '2px solid #070716',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    zIndex: '2',
  },
  sectionContentActive: {
    fontSize: '0.75rem',
    letterSpacing: '0.2rem',
    lineHeight: '1.125rem',
  },
  sectionContent: {
    fontSize: '0.75rem',
    letterSpacing: '0.2rem',
    lineHeight: '1.125rem',
    opacity: '60%',
  },
  // Labels
  labelsRow: {
    width: '100%',
    minWidth:'1000px',
    display: 'flex',
    flexDirection: 'row',
  },
  sectionLabel: {
    padding: '20px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  },
  sectionLabelActive: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #2FF583',
    whiteSpace: 'nowrap',
    "@media (max-width:900px)": {
      fontSize: "1.125px",
      letterSpacing: "0%",
    },
  },
  labelActive: {
    fontSize: '0.75rem',
    letterSpacing: '0.2rem',
    lineHeight: '1.125rem',
    color: '#2FF583',
  },
  label: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    opacity: '60%',
  },
}


export default ProgressBar;