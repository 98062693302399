import React from "react";
import { Box, SxProps, Typography } from "@mui/material";
import HunyLoading from "assets/HunyLoading.gif";
import { DialogModal } from "components/DialogModal";
import { AppTheme, SimpleMap } from "utils/types";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  currentTx: number;
}

const MigrateDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, currentTx } = props;

  const handleOnClose = () => {
    onClose();
  }

  const getHeader = () => {
    return `Performing tx ${currentTx} out of 4`;
  }

  const getContent = () => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography sx={styles.warningText}>
          Don't close this popup!
        </Typography>

        <Box
          component="img"
          sx={styles.loadingImage}
          src={HunyLoading}
          alt={"loading gif"}
        />
      </Box>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true}>
      <Box sx={{ maxWidth: 700 }}>
        {getContent()}
      </Box>
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  loadingImage: {
    marginTop: "10px",
    height: "200px",
    width: "200px",
    marginBottom: "-10px",
  },
}

export default MigrateDialog;
