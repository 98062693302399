import { useEffect, useState } from "react";
import ReactHowler from "react-howler";
import { Howler } from "howler";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { useRedux } from "utils";
import { logger } from "core/utilities";

function BackgroundAudio() {
  const dispatch = useDispatch();
  const isPlayingAudio: boolean = useRedux((state) => state.layout.playAudio);

  const sources = [['audio/bgm1.wav'], ['audio/bgm2.wav']]

  const [srcIdx, setSrcIdx] = useState<number>(0);
  const handleNext = () => {
    // Just switch back and forth between 0 and 1
    const nextIndex = srcIdx === 0 ? 1 : 0
    setSrcIdx(nextIndex)

    logger("SWAPPING", sources[nextIndex]);
  }

  useEffect(() => {
    logger("SWAPPING-init", sources[srcIdx]);
    setTimeout(() => {
      // detect if audio is played
      // autoplay is disabled if user is not active on browser.
      dispatch(actions.Layout.toggleAudio(Howler.ctx.state === "running"));

    }, 1000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReactHowler
      src={sources[srcIdx]}
      playing={isPlayingAudio}
      loop={true}
      html5={true}
      volume={0.3}
      onEnd={handleNext}
    />
  )
}

export default BackgroundAudio
