import { Box, Skeleton, SxProps } from "@mui/material";
import { GuildCommanderBackground } from "assets";
import DefaultUserProfile from "assets/icons/DefaultProfilePic.svg";
import { DEFAULT_SRC } from "components/Guild/components/GuildConstants";
import React from "react";
import { Guild } from "store/types";
import { AppTheme, SimpleMap } from "utils/types";

export interface MembersPreviewProps {
  guild: Guild;
}

const MembersPreview: React.FC<MembersPreviewProps> = (props: MembersPreviewProps) => {
  const { guild } = props;
  const { members, leaderAddress, commanderAddresses } = guild;

  const previewLimit = 3;
  const councilAddresses: string[] = [leaderAddress, ...commanderAddresses];

  return (
    <Box display="flex" justifyContent="flex-start">
      {[...councilAddresses, ...Array(previewLimit - councilAddresses.length)].slice(0, previewLimit).map((addr, idx) => {
        let member = members ? members.find(o => o.address === addr) : false;

        return (
          <Box
            key={`previewMem-${idx}`}
            sx={{ position: "relative", marginLeft: "-50px" }}>
            <GuildCommanderBackground />

            {/* //INFO: Skeleton loader */}
            {!members && (
              <Skeleton sx={styles.commanderPic} width={48} height={48} />
            )}

            {/* //INFO: Member profile image */}
            {members && member && (<Box
              component="img"
              src={(member.avatarUrl ?? DefaultUserProfile)}
              sx={styles.commanderPic}
              onError={DEFAULT_SRC}
            />)}
          </Box>
        )
      })}
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  commanderPic: {
    boxShadow: "0px 0px 12px 0px #00F0FF",
    width: "48px",
    height: "48px",
    borderRadius: "16px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    marginTop: "-1.5px",
    "@media (max-width:600px)": {
      marginTop: "-2.5px",
    },
  },
}

export default MembersPreview;