import { Box, BoxProps, Fade, IconButton, Link, SxProps, Tooltip, Typography } from "@mui/material";
import { InfoTooltip, InfoTooltipWhite } from "assets";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";


export interface TooltipZolarProps extends BoxProps {
  link?: string;
  height?: string;
  width?: string;
  buttonIcon?: JSX.Element;
  titleIcon?: boolean;
  topPlacement?: boolean;
  noHoverStyling?: boolean;
}

const TooltipZolar: React.FC<TooltipZolarProps> = (props: TooltipZolarProps) => {
  const { link, height, width, titleIcon = true, buttonIcon, topPlacement, noHoverStyling, children, ...rest } = props;
  return (
    <Tooltip placement={topPlacement ? "top" : "right"} color="primary"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      title={
        <Box sx={combineStyles(styles.tooltip, rest.sx)} {...rest}>
          {titleIcon && <InfoTooltip className="titleIcon" />}
          <Typography color="primary" variant="body1">
            {children}
          </Typography>
          {!!link &&  <><br /><Link sx={styles.learnMore} target="_blank" href={link}>Learn More</Link></>}
        </Box>
      }
    >
      <IconButton sx={{ ...(!noHoverStyling && styles.iconButton) }}>
        {!buttonIcon && <InfoTooltipWhite height={height ? height : '24px'} width={width ? width : '24px'} className="titleIcon" />}
        {!!buttonIcon && buttonIcon}
      </IconButton>
    </Tooltip>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  tooltip: {
    padding: '20px',
    minWidth: '400px',
    maxWidth: '450px',
  },
  learnMore: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: "1.75rem",
  },
  iconButton: {
    '&:hover': {
      "svg path": {
        fill: 'url(#blue)',
      },
    }
  }
}

export default TooltipZolar;