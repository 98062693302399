import { Box, Button, Divider, styled, SxProps, Typography } from "@mui/material";
import BoxWithHeader from "components/BoxWithHeader";
import { useState } from "react";
import { useRedux } from "utils";
import { AppTheme, SimpleMap } from "utils/types";
import { Switch } from "../../../TaxCollectorTab/components";
import { RecentTransactionsDialog, TransactionsList } from "./components";


const RecentTransactions: React.FC = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showOnlyMyTx, setShowOnlyMyTx] = useState<boolean>(false);
  const bankTransactions = useRedux((state) => state.guild.transactions);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  return (
    <BoxWithHeader
      headerText={<Typography variant="h3" color="primary" sx={styles.headerText}>Recent Transactions</Typography>}
      componentsRight={<Switch disabled={!bankTransactions} onCheck={setShowOnlyMyTx} label={<Typography variant="body1" color="primary">Show my transactions only</Typography>} />}
      sx={styles.fullHeight} boxHeaderSx={styles.boxHeaderOverride}>
      <Box sx={styles.content}>
        <Box display="flex" sx={styles.topRow}>
          <StyledDivider textAlign="left">
            <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>Transactions</Typography>
          </StyledDivider>
          <Button disabled={!bankTransactions} size="small" variant="text" sx={styles.textButton} onClick={() => handleOpenDialog()}>View All</Button>
        </Box>
        <TransactionsList showOnlyMyTx={showOnlyMyTx} />
      </Box>
      <RecentTransactionsDialog open={openDialog} onClose={handleCloseDialog} />
    </BoxWithHeader>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  content: { 
    padding: '4px 20px',
   },
  overlineSmall: {
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: "0.2em"
  },
  fullHeight: { height: "100%" },
  headerText: {
    fontSize: '24px',
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  boxHeaderOverride: {
    "@media (max-width:1300px)": {
      height: 'auto !important'
    },
  },
  textButton: {
    textDecoration: 'underline',
    letterSpacing: '0.2em',
    color: '#AEF1EE',
    marginLeft: '16px',
    "&:hover": {
      textDecoration: 'underline',
    },
  },
  topRow: {
    ".MuiButton-root.Mui-disabled":{
      color: "rgba(174, 241, 238, 0.2) !important"
    }
  }
}

const StyledDivider = styled(Divider)({
  minWidth: '260px',
  flexGrow: 1,
  '&.MuiDivider-root::after': {
    borderTopColor: 'rgba(174, 241, 238, 0.1)',
    width: '100%',
    top: 0
  },
  '&.MuiDivider-root::before': {
    borderTopColor: 'rgba(174, 241, 238, 0.1)',
    width: '0%',
  },
  '& span': {
    paddingLeft: 0
  }
});

export default RecentTransactions;