import { CollectionMode, OpenCloseState } from "./types";

export const LayoutActionTypes = {
  ADD_BACKGROUND_LOADING: "ADD_BACKGROUND_LOADING",
  REMOVE_BACKGROUND_LOADING: "REMOVE_BACKGROUND_LOADING",
  TOGGLE_SHOW_NAV_DRAWER: "TOGGLE_SHOW_NAV_DRAWER",
  TOGGLE_BETA_VISIBILITY: "TOGGLE_BETA_VISIBILITY",
  TOGGLE_AUDIO: "TOGGLE_AUDIO",
  UPDATE_COLLECTION_VIEW_MODE: "UPDATE_COLLECTION_VIEW_MODE",
};

export const QuestLayoutActionTypes = {
  UPDATE_MAP_BACKGROUND: "UPDATE_MAP_BACKGROUND",
};

export function updateMapBackground(backgroundImgSrc: string) {
  return {
    type: QuestLayoutActionTypes.UPDATE_MAP_BACKGROUND,
    backgroundImgSrc,
  };
};

export function toggleBetaWarning(warningVisibility: boolean) {
  return {
    type: LayoutActionTypes.TOGGLE_BETA_VISIBILITY,
    warningVisibility,
  };
};

export function toggleAudio(playAudio: boolean) {
  return {
    type: LayoutActionTypes.TOGGLE_AUDIO,
    playAudio,
  };
};

export function updateCollectionMode(collectionMode: CollectionMode) {
  return {
    type: LayoutActionTypes.UPDATE_COLLECTION_VIEW_MODE,
    collectionMode: collectionMode,
  };
};



export function addBackgroundLoading(name: string, uuid: string) {
  return {
    type: LayoutActionTypes.ADD_BACKGROUND_LOADING,
    name, uuid,
  };
};
export function removeBackgroundLoading(uuid: string) {
  return {
    type: LayoutActionTypes.REMOVE_BACKGROUND_LOADING,
    uuid,
  };
};
export function toggleShowNavDrawer(override?: OpenCloseState) {
  return {
    type: LayoutActionTypes.TOGGLE_SHOW_NAV_DRAWER,
    override,
  }
}
