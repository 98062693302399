import { Box, Button, Checkbox, CircularProgress, Link, styled, SvgIcon, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { BaseButton, CheckedIcon, ExternalLink, Huny, UncheckedIcon } from "assets";
import MinoDefault from "assets/dp-mino.png";
import UrsaDefault from "assets/dp-ursa.png";
import HunyIcon from "assets/Huny.svg";
import LoadingMetazoa from "assets/LoadingMetazoa.gif";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import WarningBox from "components/WarningBox";
import { logger, waitForTx } from "core/utilities";
import React, { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { actions } from "store";
import { NftMetadata } from "store/types";
import { TBMConnector } from "tbm";
import { useBlockTime, useToaster } from "utils";
import { BLOCKS_PER_MINUTE, HUNY_GATHERED_PER_DAY } from "utils/constants";
import { getExplorerLink } from "utils/strings/links";
import { bnOrZero } from "utils/strings/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const HarvestDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose } = props;
  const toaster = useToaster();
  const dispatch = useDispatch();
  // const currentBlock = 2180713;
  const [, currentBlock] = useBlockTime();
  const network = useNetwork();
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const { stakedMetazoa: stakedMetazoaMap, metazoaBlocksStaked, refineryHunyStats, moonBattleInfo: { capturedHunyDebt, capturedHunyPerShare } } = tokenState;
  const stakedMetazoa = Object.values(stakedMetazoaMap);
  const [selectedMetazoa, setSelectedMetazoa] = useState<string[]>([]);
  const [harvestHunyComplete, setHarvestHunyComplete] = useState<boolean>(false);
  const [harvestHunyTxHash, setHarvestHunyTxHash] = useState<string>("");
  const [runHarvestHuny, loadingHarvestHuny] = useAsyncTask("harvestHuny", (error) => {
    toaster(error?.message ?? "Error Harvesting HUNY");
  });

  const hunyGatheredPerMetazoa: SimpleMap<number> = useMemo(() => {
    // TODO: logic for mino captured huny
    const hunyGathered: SimpleMap<number> = {};

    stakedMetazoa.forEach(token => {
      const id = token.id;
      const attributes = token?.attributes;
      const gen = attributes?.find(attribute => attribute.trait_type === "Gen")?.value;
      const faction = attributes?.find(attribute => attribute.trait_type === "Faction")?.value;
      const level = attributes?.find(attribute => attribute.trait_type === "Berserker Level")?.value;

      if (faction === "Ursa") {
        hunyGathered[id] = bnOrZero((metazoaBlocksStaked[id] / BLOCKS_PER_MINUTE / 60 / 24) * HUNY_GATHERED_PER_DAY[gen!]).toNumber();
      }

      if (faction === "Mino") {
        hunyGathered[id] = capturedHunyPerShare.times(parseInt(level || '1', 10))
          .minus(capturedHunyDebt[id] || 0).shiftedBy(-12).toNumber()
      }
    })

    return hunyGathered;
  }, [metazoaBlocksStaked, stakedMetazoa, capturedHunyDebt, capturedHunyPerShare]);

  const stakedMetazoaSorted = Object.values(stakedMetazoaMap).sort((a, b) =>
    (hunyGatheredPerMetazoa[b.id] || 0) - (hunyGatheredPerMetazoa[a.id] || 0));

  const stakedMetazoaIds = stakedMetazoaSorted.map(m => m.id);

  const totalHunyGathered = useMemo(() => {
    return Object.values(hunyGatheredPerMetazoa).reduce((sum, curr) => {
      return sum + curr;
    }, 0)
  }, [hunyGatheredPerMetazoa])

  const hunyToRefineryPerMetazoa = useMemo(() => {
    const daysWithoutHarvest: SimpleMap<number> = {};
    const hunyToRefinery: SimpleMap<number> = {};

    const lastRefinedBlock = refineryHunyStats?.lastRefinedBlock ?? 0;
    const blockDifference = lastRefinedBlock > 0 ? currentBlock - lastRefinedBlock : 0;
    const elapsedBlocks = blockDifference > 0 ? blockDifference : 0;
    logger("elapsedBlocks", elapsedBlocks, refineryHunyStats?.lastRefinedBlock)

    stakedMetazoa.forEach(token => {
      const id = token.id;
      const attributes = token?.attributes;
      const faction = attributes?.find(attribute => attribute.trait_type === "Faction")?.value;
      daysWithoutHarvest[id] = Math.floor(elapsedBlocks / (BLOCKS_PER_MINUTE * 60 * 24)); // Days without Harvest
      if (faction === "Ursa") {
        hunyToRefinery[id] = daysWithoutHarvest[id] <= 75 ? ((75 - daysWithoutHarvest[id]) / 100) * (hunyGatheredPerMetazoa[id] * 0.78) : 0; // Ursa 22% stolen by Mino
      }
      if (faction === "Mino") {
        hunyToRefinery[id] = daysWithoutHarvest[id] <= 75 ? ((75 - daysWithoutHarvest[id]) / 100) * hunyGatheredPerMetazoa[id] : 0;
      }
    })
    return hunyToRefinery;
  }, [stakedMetazoa, metazoaBlocksStaked, hunyGatheredPerMetazoa, refineryHunyStats?.lastRefinedBlock]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = (tokenId: string) => (_event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedMetazoaCopy = selectedMetazoa.slice();
    const index = selectedMetazoaCopy.findIndex((id) => id === tokenId);
    if (index === -1) {
      selectedMetazoaCopy.push(tokenId);
      setSelectedMetazoa(selectedMetazoaCopy);
    } else {
      selectedMetazoaCopy.splice(index, 1);
      setSelectedMetazoa(selectedMetazoaCopy);
    }
  }

  const handleSelectAll = () => {
    const selectableMetazoa = stakedMetazoaIds.filter(id => hunyGatheredPerMetazoa[id] !== 0);

    if (selectedMetazoa.length >= selectableMetazoa.length) {
      setSelectedMetazoa([]);
    } else {
      setSelectedMetazoa(selectableMetazoa);
    }
  }

  const { totalHarvesting, hunyStolen, hunyRefined } = useMemo(() => {
    const totalHarvesting = selectedMetazoa.reduce((sum, curr) => {
      return sum + hunyGatheredPerMetazoa[curr];
    }, 0);

    // 22% of huny gathered by ursas
    const hunyStolen = selectedMetazoa.reduce((sum, curr) => {
      const token = stakedMetazoaMap[curr];
      const attributes = token?.attributes;
      const faction = attributes?.find(attribute => attribute.trait_type === "Faction")?.value;

      if (faction === "Ursa") {
        return sum + (hunyGatheredPerMetazoa[curr] * 0.22);
      } else {
        return sum;
      }
    }, 0);

    // total - stolen * 75%
    const hunyRefined = selectedMetazoa.reduce((sum, curr) => {
      return sum + (hunyToRefineryPerMetazoa[curr] || 0);
    }, 0);

    return { totalHarvesting, hunyStolen, hunyRefined };
  }, [selectedMetazoa, hunyGatheredPerMetazoa, stakedMetazoaMap, hunyToRefineryPerMetazoa])

  const isMetazoaSelected = (tokenId: string) => {
    return selectedMetazoa.includes(tokenId);
  }

  const handleHarvestHuny = () => {
    runHarvestHuny(async () => {
      await harvestHuny();
    })
  }

  const harvestHuny = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const harvestHunyTx = await TBMConnector.harvestHuny(wallet, selectedMetazoa);

    if (harvestHunyTx.isRejected()) {
      throw new Error("Submitted transaction was rejected.");
    }

    if (harvestHunyTx?.id) {
      setHarvestHunyTxHash(harvestHunyTx.id);
      try {
        await waitForTx(harvestHunyTx.id);
        dispatch(actions.Token.updateImage());
        dispatch(actions.Token.reloadHivePool());
        dispatch(actions.Token.reloadRefineryHunyStats());
        setHarvestHunyComplete(true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  const handleOnClose = () => {
    if (loadingHarvestHuny) return;

    onClose();
    setHarvestHunyTxHash("");
    setSelectedMetazoa([]);
    setHarvestHunyComplete(false);
  }

  const getHeader = () => {
    if (harvestHunyComplete) {
      return "Harvesting success!";
    } else if (loadingHarvestHuny) {
      return "Harvesting HUNY...";
    } else {
      return "Harvest HUNY";
    }
  }

  const addDefaultSrc = (metadata: NftMetadata) => (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!metadata.attributes?.length)
      return;

    const index = metadata.attributes.findIndex(attribute => attribute.trait_type === "Faction");

    if (index === -1)
      return;

    let faction = metadata.attributes[index].value;

    if (faction === "Ursa") {
      e.currentTarget.onerror = null;
      e.currentTarget.src = UrsaDefault;
    } else if (faction === "Mino") {
      e.currentTarget.onerror = null;
      e.currentTarget.src = MinoDefault;
    }
  }

  const getContent = () => {
    if (harvestHunyComplete) {
      return (
        <Fragment>
          <Box sx={styles.contentBox} style={{ marginTop: 0 }}>
            {/* Qty */}
            <Box display="flex" alignItems="center" mt="20px">
              <Box component="img" src={HunyIcon} mr="10px" sx={styles.placeholderImage} />
              <Typography color="primary" variant="h2" sx={{ whiteSpace: 'nowrap' }}></Typography>
            </Box>

            {/* Tx hash */}
            <Link
              target="_blank"
              href={getExplorerLink("tx", harvestHunyTxHash, network)}
              sx={styles.viewTx}
            >
              View Transaction
              <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
            </Link>

            {/* Visit hive */}
            <Button
              component={RouterLink}
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={styles.hiveButton}
              to="/hive"
            >
              <BaseButtonBox>
                <BaseButton height="100%" />
              </BaseButtonBox>
              Visit Hive
            </Button>

            {/* Visit refinery */}
            <Button
              component={RouterLink}
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={styles.refineryButton}
              to="/refinery"
            >
              <BaseButtonBox>
                <BaseButton height="100%" />
              </BaseButtonBox>
              Visit Refinery
            </Button>
          </Box>
        </Fragment>
      )
    }

    if (loadingHarvestHuny) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography sx={styles.warningText}>
            Don't close this page! Bear with us...
          </Typography>

          <Box
            component="img"
            sx={styles.loadingImage}
            src={LoadingMetazoa}
            alt={"loading gif"}
          />
        </Box>
      )
    }

    return (
      <Fragment>
        {/* Warning box */}
        <WarningBox>
          <Typography variant="body1" color="primary" align="left" sx={styles.modalTextResize}>
            <strong>FUEL ALERT: </strong>
            Ursas that harvest HUNY now will need to remain in battle
            for 2 days to gather enough fuel to return to base.
            {" "}
            <Link
              target="_blank"
              href="https://docs.zolar.io/overview/creatures/collection-ii-metazoa/summoning#phase-2-or-the-transcendence"
            >
              Learn More
            </Link>
          </Typography>
        </WarningBox>

        <Box sx={styles.contentBox}>
          {/* Metazoa */}
          <TableContainer sx={styles.tableContainer}>
            <Table>
              <TableHead sx={styles.tableHead}>
                <TableRow>
                  {/* Gen */}
                  <TableCell align="left" width="10%">
                    <Typography variant="body1" color="primary">Gen</Typography>
                  </TableCell>

                  {/* Metazoa */}
                  <TableCell align="left">
                    <Typography variant="body1" color="primary">Metazoa</Typography>
                  </TableCell>

                  {/* Huny */}
                  <TableCell align="center">
                    <Typography variant="body1" color="primary">
                      Total HUNY Gathered
                      {" "}
                      <SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" />
                    </Typography>
                  </TableCell>

                  {/* Select all */}
                  <TableCell align="center">
                    <Typography variant="body1" color="primary" sx={styles.selectAll} onClick={handleSelectAll}>SELECT MAX</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={styles.tableBody}>
                {stakedMetazoaSorted.map((token) => {
                  const attributes: any = {};
                  token?.attributes?.forEach(attribute => {
                    attributes[attribute.trait_type] = attribute.value;
                  })

                  return (
                    <TableRow key={token.id}>
                      {/* Gen */}
                      <TableCell align="left" sx={{ paddingLeft: "10px!important" }}>
                        <Typography variant="body1" color="primary">{attributes?.["Gen"] ?? "-"}</Typography>
                      </TableCell>

                      {/* Metazoa */}
                      <TableCell align="left">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box component="img" width="40px" src={token.image} alt={`ZOA #${token.id}`} sx={styles.zoaImage} onError={addDefaultSrc(token)} />
                          <Typography variant="body1" color="primary">
                            ZOA #{token.id}
                          </Typography>
                        </Box>
                      </TableCell>

                      {/* Huny */}
                      <TableCell align="center">
                        <Typography variant="body1" color="primary">
                          {(hunyGatheredPerMetazoa[token.id] || 0).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </Typography>
                      </TableCell>

                      {/* Checkbox */}
                      <TableCell align="center">
                        <Checkbox
                          icon={<UncheckedIcon />}
                          checkedIcon={<CheckedIcon />}
                          checked={isMetazoaSelected(token.id)}
                          onChange={handleSelect(token.id)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Selection info */}
          <Box sx={styles.alertBox}>
            {/* HUNY stolen */}
            <Box sx={styles.alertRow}>
              <Typography variant="body1" color="primary" sx={styles.modalTextResize}>
                Selected Huny:
              </Typography>
              <Typography variant="body1" color="primary" fontWeight="700" sx={styles.modalTextResize}>
                {totalHarvesting.toLocaleString(undefined, { maximumFractionDigits: 2 })} / {totalHunyGathered.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                {" "}
              </Typography>
            </Box>
            <Box sx={styles.alertRow}>
              <Typography variant="body1" color="primary" sx={styles.modalTextResize}>
                Estimated receive now:
              </Typography>
              <Box sx={styles.textGroup}>
                <Typography variant="body1" color="warning.main" component="span" sx={combineStyles(styles.modalTextResize, styles.textGroup)}>
                  -{hunyStolen.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  <TooltipZolar height="14px" width="14px" link="https://docs.zolar.io/missions/takers-of-the-moon#ursa-factions-mission-brief">
                    22% of your gathered $HUNY is always stolen by the Minos!
                  </TooltipZolar>
                </Typography>
                <Typography variant="body1" color="primary" sx={combineStyles(styles.modalTextResize, styles.textGroup)}>
                  -{hunyRefined.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  <TooltipZolar height="14px" width="14px" link="https://docs.zolar.io/missions/takers-of-the-moon#refining-gathered-or-stolen-usdhuny">
                    Current = {bnOrZero(hunyRefined && totalHarvesting ? (hunyRefined / (totalHarvesting - hunyStolen) * 100) : 75).dp(2).toString()}%
                    <br />A percentage of the harvested Huny is sent to the Refinery for further processing. This ratio decreases at 1% a day if there is no harvesting done.
                  </TooltipZolar>
                </Typography>
                <Typography variant="body1" color="success.main" fontWeight="700" sx={styles.modalTextResize}>
                  = {(totalHarvesting - hunyStolen - hunyRefined).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* Confirm*/}
          <ContainedButton
            onClick={handleHarvestHuny}
            disabled={!selectedMetazoa.length || loadingHarvestHuny}
            sx={styles.confirmButton}
          >
            {loadingHarvestHuny
              ? <CircularProgress size={18} />
              : <Fragment>Confirm &amp; Harvest&nbsp;<SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" /></Fragment>
            }
          </ContainedButton>
        </Box>
      </Fragment>
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true}>
      <Box sx={{ maxWidth: "700px" }}>
        {getContent()}
      </Box>
    </DialogModal>
  )
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  tableContainer: {
    overflowY: "auto",
    maxHeight: "25vh",
    minHeight: '100px',
    maxWidth: "700px",
    paddingBottom: "10px",

    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "transparent",
      top: "",
      left: "",
    },
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: "10px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },
  tableHead: {
    "& th.MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  confirmButton: {
    marginTop: "20px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  hunyIcon: {
    verticalAlign: "top",
  },
  selectAll: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
    }
  },
  alertBox: {
    marginTop: "10px",
    width: "100%",
    backgroundColor: "rgba(174, 241, 238, 0.1)",
    borderRadius: "16px",
    padding: "14px 16px 10px",
    display: "flex",
    border: '1px solid #AEF1EE33',
    background: "rgba(174, 241, 238, 0.1)",
    flexDirection: "column",
  },
  alertRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    width: "100%",
    "&:not(:first-child)": {
      marginTop: "4px",
    },
  },
  alertIcon: {
    verticalAlign: "text-top",
    marginRight: "8px",
    fontSize: '0.8rem',
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
      verticalAlign: "sub",
    },
  },
  zoaImage: {
    marginRight: "10px",
    borderRadius: "16px",
  },
  loadingImage: {
    marginTop: "10px",
    height: "250px",
    width: "250px",
    marginBottom: "-10px",
    "@media (max-width:600px)": {
      height: "200px",
      width: "200px",
    },
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "40px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  sendMoreButton: {
    marginTop: "20px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  placeholderImage: {
    height: "60px",
    width: "60px",
  },
  warningText: {
    fontSize: "24px",
    color: "#ff8952",
    textAlign: "center",
  },
  hiveButton: {
    marginTop: 0,
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },
  refineryButton: {
    marginTop: "10px",
    height: 60,
    minWidth: 360,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
    },
  },

  modalTextResize: {
    fontSize: '0.8rem',
    lineHeight: '1rem',
  },

  textGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
}

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    display: "none",
  },
});

export default HarvestDialog;
