import { useEffect, useMemo, useState } from "react";
import { Box, Button, styled, SxProps, Typography } from "@mui/material";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import { Huny, MissionStars, UrsaMinoIcon } from "assets";
import { MetazoaClient } from "core/utilities/metazoa";
import { NavLink } from "react-router-dom";
import { strings, useAsyncTask, useRedux } from "utils";
import { ContractsBech32 } from "utils/constants";
import { bnOrZero } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";

interface PriceData {
  value: string,
  intervalTime: string
}

const Summon: React.FC = () => {
  const lastMetazoaId = useRedux(state => state.game.lastMetazoaId);
  const network = useRedux(state => state.blockchain.network);
  const hunyPrice = useRedux((state) => state.token.ExchangeRates.hunyPrice);
  const zilPrice = useRedux((state) => state.token.ExchangeRates.zilPrice);
  const [floorPrice, setFloorPrice] = useState<number>(0);
  const currentInterval:string = "hour";
  const [runGetCollectionFloor] = useAsyncTask("getCollectionFloor");
  const hunyContract = useMemo(() => ContractsBech32[network].HunyToken, [network]);
  const collection = fromBech32Address(ContractsBech32.MainNet.NftV2.toLowerCase());

  useEffect(() => {
    getCollectionFloor();
    // eslint-disable-next-line
  }, [currentInterval])

  const availableForSummon = typeof lastMetazoaId === "number" ? strings.bnOrZero(2900 - lastMetazoaId).toFormat(0) : "-"

  const getCollectionFloor = () => {
    runGetCollectionFloor(async () => {
      const metazoaClient = new MetazoaClient(network);
      const result = await metazoaClient.getCollectionFloor({ collection: collection, interval: currentInterval });
      const floors: PriceData[] = result.result;
      if (floors.length === 0) return;
      const recentFloorPrice = floors[floors.length - 1].value;
      setFloorPrice(parseInt(recentFloorPrice));
    })
  }
  
  const hunyPriceInZil = hunyPrice.dividedBy(zilPrice);
  const metazoaAPR = hunyPriceInZil.times(250).times(365).times(100).dividedBy(bnOrZero(floorPrice).shiftedBy(-12));

  return (
    <Box sx={styles.summonContainer}>
      <SvgBox>
        <StarBox>
          <MissionStars width="100%" />
        </StarBox>
      </SvgBox>
      <Box sx={styles.summonWrapper}>
        <Box sx={styles.summonBox}>
          <Box sx={styles.iconContainer}>
            <UrsaMinoIcon width="100%" height="100%" />
          </Box>
          <Box sx={styles.summonGroup}>
            <Box sx={styles.summonTextGroup}>
              <Typography variant="h2" color="primary">{availableForSummon} Metazoa</Typography>
              <Typography variant="h3" color="primary">LEFT FOR SUMMONS</Typography>
            </Box>
            <Button component={NavLink} to="/summon" size="medium" variant="outlined" color="secondary">
              <Typography variant="button" sx={styles.gradientText}>Summon</Typography>
            </Button>
          </Box>
        </Box>
        <Box sx={styles.summonBox}>
          <Box sx={styles.iconContainer}>
            <Huny width="100%" height="100%" />
          </Box>
          <Box sx={styles.summonGroup}>
            <Box sx={styles.summonTextGroup}>
              <Typography variant="h2" color="primary">{!isFinite(metazoaAPR.toNumber()) ? "- " : metazoaAPR.toNumber().toFixed(2)}% APR</Typography>
              <Typography variant="h3" color="primary">FOR HUNY GATHERING</Typography>
            </Box>
            <Button size="medium" variant="outlined" color="secondary" target="_blank" href={strings.getZilswapLink(hunyContract)}>
              <Typography variant="button" sx={styles.gradientText}>get huny</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  summonContainer: {
    padding: '0px',
    minWidth: '720px',
  },
  summonWrapper: {
    zIndex: 1,
    width: '100%',
    paddingTop: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: '40px',
    "@media (max-width:1200px)": {
      columnGap: '20px',
      paddingTop: '20px',
    },
  },
  summonBox: {
    zIndex: 1,
    flex: '1 1 0',
    background: 'linear-gradient(180deg, rgba(1, 0, 30, 0.8) -13.81%, #000010 100%)',
    borderRadius: '16px',
    border: '1px solid #AEF1EE33',
    display: 'flex',
    flexDirection: 'row',
    padding: '50px 40px 40px',
    "@media (max-width:1200px)": {
      flexDirection: 'column',
      padding: '20px 40px',
    },
  },
  iconContainer: {
    position: 'relative',
    height: '150px',
    width: '150px',
    marginRight: '30px',
    "@media (max-width:1200px)": {
      marginBottom: '20px',
      height: '70px',
      width: '70px',
    },
  },
  summonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  summonTextGroup: {
    height: '150px',
    marginBottom: '20px',
    "@media (max-width:1200px)": {
      height: '120px',
      marginBottom: '0px',
    },
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  buttonDisabled: {
    background: 'transparent',
    color: '#FEFEFE',
    opacity: 0.4,
  },
}

const SvgBox = styled(Box)({
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
  pointerEvents: 'none',
});

const StarBox = styled(Box)({
  position: 'absolute',
  top: 80,
})

export default Summon;
