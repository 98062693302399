import { combineReducers } from "redux";
import blockchain from "./blockchain/reducer";
import layout from "./layout/reducer";
import profile from "./profile/reducer";
import token from "./token/reducer";
import wallet from "./wallet/reducer";
import game from "./game/reducer";
import guild from "./guild/reducer";
import zomg from "./zomg/reducer";
import quest from "./quest/reducer"

export default combineReducers({
  blockchain, wallet, layout, token, profile, game, guild, zomg, quest,
})