import { Box, Container, SxProps, Typography } from "@mui/material";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import { GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { MetazoaLayout } from "layout";
import { useRedux } from "utils";
import ScrollToTop from "utils/ScrollToTop";
import { AppTheme, SimpleMap } from "utils/types";
import { UpdateForm } from "./components";

const UpdateProfile: React.FC = () => {
  const wallet = useRedux((state) => state.wallet.wallet);
  return (
    <MetazoaLayout>
      <ScrollToTop />
      <Container maxWidth="xl" sx={styles.container}>
        <Box width={{ xs: "100%", md: "100%", lg: "80%" }} sx={styles.headerWrapper}>
          <Typography variant="h2" color="primary" sx={styles.header}>
            Commander Customization Portal
          </Typography>
        </Box>
        <Box sx={styles.containerWrapper}>
          {!wallet ? <ConnectWalletButtonCTA /> : <UpdateForm />}
        </Box>
      </Container>
    </MetazoaLayout>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    position: "relative",
    padding: "0px 0px 100px",
    minHeight: "1600px",
  },
  headerWrapper: {
    padding: "160px 120px 0px",
    marginBottom: "40px",
    "@media (max-width:900px)": {
      padding: "160px 60px 0px",
    },
    "@media (max-width:600px)": {
      padding: "130px 24px 0px",
    },
  },
  header: {
    ...GUILD_GRAY_GRADIENT,
    "@media (max-width:900px)": {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },

  containerWrapper: {
    paddingLeft: '120px',
    paddingBottom: '50px',
    "@media (max-width:900px)": {
      paddingLeft: '60px'
    },
    "@media (max-width:600px)": {
      paddingLeft: '24px',
      overflowX: 'scroll',
    },
  }
}

export default UpdateProfile;