import { Box, Container, SxProps } from "@mui/material";
import { GuildBreadcrumb } from "components/Guild/components";
import { logger } from "core/utilities";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRedux } from "utils";
import { AppTheme, SimpleMap } from "utils/types";
import { LOCATIONS, MAP_LOCATIONS } from "../../QuestConstants";
import * as MissionGround from "./MissionGround";
import RescueShipBanner from "./RescueShipBanner";
import * as ZOMG from "./ZOMG";

const LocationView: FC = () => {
  const { location } = useParams();
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const stakedMetazoa = useRedux((state) => state.token.stakedMetazoa);

  // STATES / HOOKS -----------------

  const shouldShowRescueShip = useMemo(() => {
    return query.has("showRescueShip") || Object.values(stakedMetazoa).length > 0;
  }, [query, stakedMetazoa])

  const currentLocation = useMemo(() => {
    if (!location) return null;

    const isValidLocation: boolean = MAP_LOCATIONS.includes(location);
    const locationMeta = LOCATIONS[location];
    const isMissionGround: boolean = !!(locationMeta?.resource) ?? false;
    logger("debug-map", "locationView", {
      location,
      locationMeta,
      isValidLocation,
      isMissionGround,
    });

    if (!isValidLocation) return null;

    return {
      meta: locationMeta,
      key: location,
      isMissionGround,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (currentLocation === null) {
      navigate("/map");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation])

  // FUNCTIONS ----------------------

  // EVENT HANDLERS -----------------

  // --------------------------------

  if (!currentLocation) return null;

  const breadcrumbs = [
    {
      path: "/map",
      value: "Zolarverse Map",
    },
    {
      path: `/map/${currentLocation.key}`,
      value: currentLocation.meta.name,
    },
  ];

  return (
    <Container sx={styles.root}>
      <Box sx={styles.contentWrapper}>
        <GuildBreadcrumb linkPath={breadcrumbs} />
        {shouldShowRescueShip && <RescueShipBanner />}
        {currentLocation.isMissionGround && (<MissionGround.View locationKey={currentLocation.key} />)}
        {!currentLocation.isMissionGround && (<ZOMG.View />)}
      </Box>
    </Container >
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  root: {
    position: "relative",
    padding: "0 0 100px!important",
    minHeight: "1200px",
    width: '100%',
    minWidth: '600px',
    height: '100%',
    '@media(max-width: 600px)': {
      padding: "0 20px 100px!important",
    }
    // //DEBUG: Remove this
    // border: '1px solid red',
    // "*": {
    //   border: '1px solid red',
    // }
  },

  contentWrapper: {
    height: '100%',
    marginTop: "160px",
    minHeight: '900px',
    position: 'relative',

    "> .MuiBreadcrumbs-root": {
      marginBottom: '2em',
    },
  },
};

export default LocationView;
