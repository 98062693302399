
import { Dayjs } from "dayjs";
import { ObservedTx } from "zilswap-sdk";
import { SimpleMap } from "../../utils/types";
import { CurrentMintInfo, ExchangeRateProps, GoldRushSupplyProps, HiveInfo, HivePoolStats, Hug3Recovery, HunyPot, MoonBattleInfo, NftMetadata, RefineryConfig, RefineryHunyStats, Resources, SupplyProps } from "./types";

export const TokenActionTypes = {
  SET_TOKENS: "SET_TOKENS",
  UPDATE_TOKENS: "UPDATE_TOKENS",
  UPDATE_TOKEN_IMAGES: "UPDATE_TOKEN_IMAGES",
  RELOAD_TOKENS: "RELOAD_TOKENS",
  REVEAL_TOKENS: "REVEAL_TOKENS",
  CLEAR_TOKENS: "CLEAR_TOKENS",
  UPDATE_SUPPLY: "UPDATE_SUPPLY",
  UPDATE_SALE: "UPDATE_SALE",
  UPDATE_CURRENT_MINTING: "UPDATE_CURRENT_MINTING",
  SET_WHITELIST_COUNT: "SET_WHITELIST_COUNT",
  SET_METAZOA: "SET_METAZOA",
  UPDATE_METAZOA: "UPDATE_METAZOA",
  UPDATE_METAZOA_PROFESSION: "UPDATE_METAZOA_PROFESSION",
  UPDATE_STAKED_METAZOA: "UPDATE_STAKED_METAZOA",
  SAVE_TRANSCENDED_TOKENS: "SAVE_TRANSCENDED_TOKENS",
  UPDATE_TRANSCENDENCE_INIT_STATE: "UPDATE_TRANSCENDENCE_INIT_STATE",
  UPDATE_TRANSCENDENCE_APPROVAL: "UPDATE_TRANSCENDENCE_APPROVAL",
  SET_MINTED_TOKENS_COUNT: "SET_MINTED_TOKENS_COUNT",
  SET_TRANSCENDENCE_START_BLOCK: "SET_TRANSCENDENCE_START_BLOCK",

  // Phase 3
  UPDATE_GOLD_RUSH_SUPPLY: "UPDATE_GOLD_RUSH_SUPPLY",
  UPDATE_GOLD_RUSH_SALE: "UPDATE_GOLD_RUSH_SALE",
  SET_GOLD_RUSH_SALE_PRICE: "SET_GOLD_RUSH_SALE_PRICE",

  // Phase 4
  UPDATE_MOON_BATTLE_INFO: "UPDATE_MOON_BATTLE_INFO",

  // Game
  SET_TRAPPED_METAZOA: "SET_TRAPPED_METAZOA",
  SET_STAKED_METAZOA: "SET_STAKED_METAZOA",
  UPDATE_METAZOA_BLOCKS_STAKED: "UPDATE_METAZOA_BLOCKS_STAKED",

  // Huny
  UPDATE_HUNY_TOKEN_SUPPLY: "UPDATE_HUNY_TOKEN_SUPPLY",
  UPDATE_OWNED_HUNY_TOKEN: "UPDATE_OWNED_HUNY_TOKEN",
  UPDATE_HIVE_INFO: "UPDATE_HIVE_INFO",
  RELOAD_HIVE_INFO: "RELOAD_HIVE_INFO",
  UPDATE_HIVE_POOL: "UPDATE_HIVE_POOL",
  RELOAD_HIVE_POOL: "RELOAD_HIVE_POOL",

  // Zil
  UPDATE_ZIL_TOKEN: "UPDATE_ZIL_TOKEN",
  FETCH_GAS_FEE: "FETCH_GAS_FEE",
  FETCH_EXCHANGE_RATES: "FETCH_EXCHANGE_RATES",

  // Refinery
  UPDATE_HUNY_POTS: "UPDATE_HUNY_POTS",
  RELOAD_HUNY_POTS: "RELOAD_HUNY_POTS",
  UPDATE_REFINERY_HUNY_STATS: "UPDATE_REFINERY_HUNY_STATS",
  RELOAD_REFINERY_HUNY_STATS: "RELOAD_REFINERY_HUNY_STATS",
  UPDATE_REFINERY_CONFIG: "UPDATE_REFINERY_CONFIG",

  //HUG3 HUNY RECOVERY
  UPDATE_HUNY_TO_RECOVER: "UPDATE_HUNY_TO_RECOVER",

  // Mission 3 Fetch Resources
  REFETCH_RESOURCES: "REFETCH_RESOURCES",
  UPDATE_RESOURCES: "UPDATE_RESOURCES",
  SET_STAKED_METAZOA_BERRY: "SET_STAKED_METAZOA_BERRY",
  SET_STAKED_METAZOA_GEODE: "SET_STAKED_METAZOA_GEODE",
  SET_STAKED_METAZOA_SCRAP: "SET_STAKED_METAZOA_SCRAP",
  UPDATE_STAKED_METAZOA_BERRY: "UPDATE_STAKED_METAZOA_BERRY",
  UPDATE_STAKED_METAZOA_GEODE: "UPDATE_STAKED_METAZOA_GEODE",
  UPDATE_STAKED_METAZOA_SCRAP: "UPDATE_STAKED_METAZOA_SCRAP",
}

export const updateTokens = (payload: NftMetadata[]) => ({
  type: TokenActionTypes.UPDATE_TOKENS,
  payload
})

export const setTokens = (payload: string[]) => ({
  type: TokenActionTypes.SET_TOKENS,
  payload
})

export const clearTokens = () => ({
  type: TokenActionTypes.CLEAR_TOKENS
})

export const reloadTokens = () => ({
  type: TokenActionTypes.RELOAD_TOKENS
})

export const updateImage = () => ({
  type: TokenActionTypes.UPDATE_TOKEN_IMAGES
})

export const refetchResource = () => ({
  type: TokenActionTypes.REFETCH_RESOURCES
})

export const revealTokens = (payload: SimpleMap<Dayjs>) => ({
  type: TokenActionTypes.REVEAL_TOKENS,
  payload
})

export const updateSupply = (payload: SupplyProps) => ({
  type: TokenActionTypes.UPDATE_SUPPLY,
  payload
})

export const updateSales = (payload: boolean) => ({
  type: TokenActionTypes.UPDATE_SALE,
  payload
})

export const updateCurrentMinting = (payload: (ObservedTx & CurrentMintInfo) | null) => ({
  type: TokenActionTypes.UPDATE_CURRENT_MINTING,
  payload
})

export const setWhitelistCount = (payload: number) => ({
  type: TokenActionTypes.SET_WHITELIST_COUNT,
  payload
})

export const setMetazoa = (payload: string[]) => ({
  type: TokenActionTypes.SET_METAZOA,
  payload
})

export const updateMetazoa = (payload: NftMetadata[]) => ({
  type: TokenActionTypes.UPDATE_METAZOA,
  payload
})

export const updateMetazoaProfession = (payload: SimpleMap<NftMetadata>) => ({
  type: TokenActionTypes.UPDATE_METAZOA_PROFESSION,
  payload
})

export const updateStakedMetazoa = (payload: SimpleMap<NftMetadata>) => ({
  type: TokenActionTypes.UPDATE_STAKED_METAZOA,
  payload
})

export const updateQuestBerryMetazoa = (payload: SimpleMap<NftMetadata>) => ({
  type: TokenActionTypes.UPDATE_STAKED_METAZOA_BERRY,
  payload
})

export const updateQuestGeodeMetazoa = (payload: SimpleMap<NftMetadata>) => ({
  type: TokenActionTypes.UPDATE_STAKED_METAZOA_GEODE,
  payload
})

export const updateQuestScrapMetazoa = (payload: SimpleMap<NftMetadata>) => ({
  type: TokenActionTypes.UPDATE_STAKED_METAZOA_SCRAP,
  payload
})

export const saveTranscendedTokens = (payload: NftMetadata[]) => ({
  type: TokenActionTypes.SAVE_TRANSCENDED_TOKENS,
  payload
})

export const updateTranscendenceInitState = () => ({
  type: TokenActionTypes.UPDATE_TRANSCENDENCE_INIT_STATE
})

export const updateTranscendenceApproval = (payload: boolean) => ({
  type: TokenActionTypes.UPDATE_TRANSCENDENCE_APPROVAL,
  payload
})

export const setMintedTokensCount = (payload: number) => ({
  type: TokenActionTypes.SET_MINTED_TOKENS_COUNT,
  payload
})

export const updateGoldRushSupply = (payload: GoldRushSupplyProps) => ({
  type: TokenActionTypes.UPDATE_GOLD_RUSH_SUPPLY,
  payload
})

export const updateGoldRushSaleActive = (payload: boolean) => ({
  type: TokenActionTypes.UPDATE_GOLD_RUSH_SALE,
  payload
})

export const setGoldRushSalePrice = (payload: number) => ({
  type: TokenActionTypes.SET_GOLD_RUSH_SALE_PRICE,
  payload
})

export const updateMoonBattleInfo = (payload: MoonBattleInfo) => ({
  type: TokenActionTypes.UPDATE_MOON_BATTLE_INFO,
  payload
})

export const setTrappedMetazoa = (payload: string[]) => ({
  type: TokenActionTypes.SET_TRAPPED_METAZOA,
  payload
})

export const setStakedMetazoa = (payload: string[]) => ({
  type: TokenActionTypes.SET_STAKED_METAZOA,
  payload
})

export const setQuestBerryMetazoa = (payload: string[]) => ({
  type: TokenActionTypes.SET_STAKED_METAZOA_BERRY,
  payload
})

export const setQuestGeodeMetazoa = (payload: string[]) => ({
  type: TokenActionTypes.SET_STAKED_METAZOA_GEODE,
  payload
})

export const setQuestScrapMetazoa = (payload: string[]) => ({
  type: TokenActionTypes.SET_STAKED_METAZOA_SCRAP,
  payload
})

export const updateMetazoaBlocksStaked = (payload: SimpleMap<number>) => ({
  type: TokenActionTypes.UPDATE_METAZOA_BLOCKS_STAKED,
  payload
})

export const updateHunyBalance = (payload: number) => ({
  type: TokenActionTypes.UPDATE_OWNED_HUNY_TOKEN,
  payload
})

export const updateZilToken = (payload: number) => ({
  type: TokenActionTypes.UPDATE_ZIL_TOKEN,
  payload
})

export const fetchGasFee = (payload: number) => ({
  type: TokenActionTypes.FETCH_GAS_FEE,
  payload
})

export const fetchExchangeRate = (payload: ExchangeRateProps) => ({
  type: TokenActionTypes.FETCH_EXCHANGE_RATES,
  payload
})

export const updateHiveInfo = (payload: HiveInfo) => ({
  type: TokenActionTypes.UPDATE_HIVE_INFO,
  payload
})

export const updateHivePool = (payload: HivePoolStats) => ({
  type: TokenActionTypes.UPDATE_HIVE_POOL,
  payload
})
export const reloadHivePool = () => ({
  type: TokenActionTypes.RELOAD_HIVE_POOL,
})

export const reloadRefineryHunyStats = () => ({
  type: TokenActionTypes.RELOAD_REFINERY_HUNY_STATS,
})

export const updateRefineryConfig = (payload: RefineryConfig) => ({
  type: TokenActionTypes.UPDATE_REFINERY_CONFIG,
  payload
})

export const updateRefineryHunyStats = (payload: RefineryHunyStats) => ({
  type: TokenActionTypes.UPDATE_REFINERY_HUNY_STATS,
  payload
})

export const updateHunyPots = (payload: SimpleMap<HunyPot>) => ({
  type: TokenActionTypes.UPDATE_HUNY_POTS,
  payload
})

export const updateHunyTokenSupply = (payload) => ({
  type: TokenActionTypes.UPDATE_HUNY_TOKEN_SUPPLY,
  payload
})

export const updateHunyToRecover = (payload: Hug3Recovery) => ({
  type: TokenActionTypes.UPDATE_HUNY_TO_RECOVER,
  payload
})

export const updateResources = (payload: Resources) => ({
  type: TokenActionTypes.UPDATE_RESOURCES,
  payload
})
