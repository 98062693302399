import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import ArrowLine from 'assets/ArrowLine.svg';
import StoreItemFrame from "assets/quests/Locations/ZOMGStore/StoreItemFrame.svg";
import ContainedButton from 'components/ContainedButton';
import { DialogModal } from 'components/DialogModal';
import { ModalProps } from 'components/Game/components/Stats/ArmyStake/components/SendDialog/SendDialog';
import { EquipRequirements, EquipType, GEMS } from 'components/Metazoa/ResourceConstants';
import { getBonusRequirement, getProfession } from 'components/Quest/Map/Location/ZOMG/CraftZOrdnanceDialog';
import { logger } from 'core/utilities';
import { FC, useMemo, useState } from 'react';
import { NftMetadata } from 'store/types';
import { theme } from 'theme';
import { AppTheme, SimpleMap } from 'utils/types';
import { DroppableGroup } from '.';
import { GemTier } from '../../../ResourceConstants';
import { STATS, StatType } from '../../MetazoaConstants';
import { EquipProps } from './EquipDialog';
import { IInventoryItem, ordnanceEquipTypes } from './EquipPanel';

export interface BatchEquipDialogProps extends ModalProps, Omit<EquipProps, 'currItem'> {
  onConfirm: () => void;
  token: NftMetadata;
  prevItem?: IInventoryItem;
  isLoading: boolean;
}

const EquipDialog: FC<BatchEquipDialogProps> = ({
  open = true,
  onClose,
  onConfirm,
  token,
  currItems,
  toEquip,
  isLoading = false,
}: BatchEquipDialogProps) => {

  const equipAction: string = toEquip ? 'Equip' : 'Unequip';
  const [failedOrdnanceRequirements, setFailedOrdnanceRequirements] = useState<SimpleMap<string[]>>({});

  const canEquip: boolean = useMemo(() => {
    setFailedOrdnanceRequirements({});
    if (!token || !currItems.length) return false;

    // FREE DEQUIP
    if (!toEquip) return true;

    // Equip Requirements - Ordnances
    const ordnanceItems: IInventoryItem[] = currItems.filter(({ type }) => ordnanceEquipTypes.includes(type.type)) ?? [];
    const tokenValues = Object.fromEntries(Object.entries(EquipRequirements).map(([k, v]) => {
      let entry = [k, v];
      switch (v) {
        case EquipRequirements.profession: {
          entry = ([k, (token?.profession ?? "").toLowerCase()])
          break;
        }
        case EquipRequirements.masteryLevel: {
          entry = ([k, (token?.masteryExp?.level ?? 1).toString()])
          break;
        }
        case EquipRequirements.metazoaLevel: {
          entry = ([k, (token?.exp?.level ?? 1).toString()])
          break;
        }
      }
      return entry;
    }));

    const failedOrdnanceRequirements: SimpleMap<string[]> = {};
    const checkOrdnanceRequirements = ordnanceItems.map(ordItm => (
      Object.entries((ordItm.item?.ordnance?.craftableInfo?.requirement ?? {}) as SimpleMap)
        .flatMap(([k, v]) => {
          const numVal: number = parseInt(tokenValues[k]) || -1;
          const hasMet: boolean = (((numVal <= -1) ? (tokenValues[k].toLowerCase() === (v.toString() ?? "").toLowerCase()) : (numVal >= parseInt(v) || -1)) >= 1) ?? false;

          if (!hasMet) failedOrdnanceRequirements[ordItm.keyName] = (failedOrdnanceRequirements[ordItm.keyName] ?? []).concat(k);
          return hasMet;
        })
        .flat(1)
    ));
    const hasOrdnanceRequirements: boolean = checkOrdnanceRequirements.flat(1).every(r => r);

    logger("debug-equip", "BatchEquip/canEquip-FAILED", token.id, {
      currItems,
      tokenValues,
      checkOrdnanceRequirements,
      hasOrdnanceRequirements,
      failedOrdnanceRequirements,
      ordnanceRequirements: ordnanceItems.map(ordItm => (ordItm.item?.ordnance?.craftableInfo?.requirement ?? {})),
      craftableInfoStats: ordnanceItems.map(ordItm => ordItm.item?.ordnance?.craftableInfo?.stats ?? {}),
    }, {
      GEMS,
      STATS,
    });
    if (!hasOrdnanceRequirements) {
      setFailedOrdnanceRequirements(failedOrdnanceRequirements);
      return false;
    }
    return true;
  }, [currItems, toEquip, token]);

  const handleOnConfirm = () => {
    onConfirm();
  }

  const handleOnClose = () => {
    if (isLoading) return;
    onClose();
  }

  const getHeader = () => {
    return `${equipAction} Items`;
  }

  const getContent = () => {
    if (!currItems.length) return null;

    return (
      <Box sx={{
        width: '90%',
        mx: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}>

        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          {currItems.map((currItem, idx) => {
            const isOrdnance: boolean = currItem.type.type !== EquipType[EquipType.Gem];
            const itemName: string = currItem.keyName.split('-')[1];
            const hasFailedRequirements: boolean = Object.keys(failedOrdnanceRequirements).includes(currItem.keyName);

            return (
              <Box key={idx}>
                <Typography variant="body1" color="primary" sx={{
                  mb: 4,
                  textAlign: 'center',
                }}>
                  {equipAction} {itemName} as your {toEquip ? 'new' : ''} {currItem.type.type}
                </Typography>

                {toEquip && (
                  <Box
                    component="section"
                    sx={{
                      display: 'flex',
                      placeContent: 'center',
                      placeItems: 'center',
                      position: 'relative',

                      [theme.breakpoints.down('md')]: {
                        display: 'none',
                      }
                    }}
                  >
                    <Box
                      component="img"
                      src={ArrowLine}
                      sx={{
                        position: 'absolute',
                        zIndex: '10',
                      }}
                    />
                    <DroppableGroup
                      id={`droppable-equip-gems-${idx}`}
                      items={[currItem]}
                      limit={2}
                      acceptableTypes={[currItem.type.type]}
                      onDrop={() => { }}
                      options={{
                        hasMiniTypeIcon: isOrdnance,
                        lockDrop: true,
                      }}
                      sx={{
                        flexDirection: 'row-reverse',
                        columnGap: '50%',

                        ".droppable, .draggable": {
                          minHeight: '140px',
                          minWidth: '140px',
                        },
                        ".inventoryItem": {
                          width: '65%!important',
                          maxWidth: '80px!important',
                        },
                        ".inventoryType": {
                          width: '60px!important',
                          height: 'auto!important',
                        }
                      }}
                    />
                  </Box>
                )}

                {isOrdnance && !!Object.keys(currItem.item?.ordnance?.craftableInfo ?? {}).length
                  ? (
                    <Box sx={styles.textContainer}>
                      <Box sx={styles.textColumn}>
                        {/* EQUIP REQUIREMENTS */}
                        {!!Object.keys(currItem.item?.ordnance?.craftableInfo?.requirement ?? {}).length && (
                          <Box sx={styles.textBox}>
                            <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                              Equip Requirement
                            </Typography>
                            {Object.entries((currItem.item?.ordnance?.craftableInfo?.requirement ?? {}) as SimpleMap)
                              .map(([requirement, level]) => {
                                const hasFailedThisRequirement: boolean = hasFailedRequirements && failedOrdnanceRequirements[currItem.keyName].includes(requirement)
                                return (
                                  <Typography variant="body1"
                                    color={!hasFailedThisRequirement
                                      ? "success.main"
                                      : "error.main"
                                    }
                                    sx={styles.textWrapper} key={requirement}>
                                    {(requirement.toLowerCase() === 'Profession'.toLowerCase())
                                      ? (`${EquipRequirements[requirement]} ${getProfession(level)}`)
                                      : (`${EquipRequirements[requirement]} ${level}`)
                                    }
                                  </Typography>
                                )
                              })}
                          </Box>
                        )}

                        {/* DAMAGE */}
                        <Box sx={styles.textBox}>
                          {!!currItem.item?.ordnance?.attributes?.Damage &&
                            <Box sx={styles.textBox}>
                              <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                                Damage
                              </Typography>
                              <Typography variant="body1" color={toEquip ? "success.main" : "error.main"} sx={styles.textWrapper}>
                                {toEquip ? '+' : '-'}{currItem.item?.ordnance?.attributes?.Damage}&nbsp;Melee
                              </Typography>
                            </Box>
                          }
                        </Box>


                        {/* BONUS STAT */}
                        <Box sx={styles.textBox}>
                          {!!Object.keys(currItem.item?.ordnance?.craftableInfo?.stats?.default ?? {}).length && (
                            <Box sx={styles.textBox}>
                              <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                                Bonus Stat
                              </Typography>
                              <Box sx={styles.statGroup}>
                                {Object.entries((currItem.item?.ordnance?.craftableInfo?.stats?.default ?? {}) as SimpleMap<number>)
                                  .filter(([, point]) => !!point)
                                  .map(([stat, point]) => (
                                    <Typography variant="body1" color={toEquip ? "success.main" : "error.main"} sx={styles.textWrapper}
                                      component="span" key={stat}>
                                      <Box component="img" src={STATS[stat].icon ?? ''} alt={stat} />&nbsp;{stat}&nbsp;{toEquip ? '+' : '-'}{point}
                                    </Typography>
                                  ))}
                              </Box>
                            </Box>
                          )}
                        </Box>

                        {/* ADDITIONAL STATS */}
                        {Object.entries((currItem.item?.ordnance?.craftableInfo?.stats ?? {}) as SimpleMap<SimpleMap<number>>)
                          .filter(([type,]) => (type !== 'default'))
                          .map(([type, stats]) => (
                            <Box sx={styles.textBox} key={type}>
                              <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                                Additional Stats for {getBonusRequirement(type)}
                              </Typography>
                              <Box sx={styles.statGroup}>
                                {Object.entries(stats ?? {})
                                  .filter(([stat, point]) => !(!point || point - currItem.item?.ordnance?.craftableInfo?.stats?.default?.[stat] <= 0))
                                  .map(([stat, point]) => (
                                    <Typography variant="body1" color={toEquip ? "success.main" : "error.main"} sx={styles.textWrapper} component="span" key={stat}>
                                      <Box component="img" src={STATS[stat].icon} alt={stat} />&nbsp;{stat}&nbsp;{toEquip ? '+' : '-'}{point - currItem.item?.ordnance?.craftableInfo?.stats?.default?.[stat]}
                                    </Typography>
                                  ))}
                              </Box>
                            </Box>
                          ))
                        }
                      </Box>
                    </Box>
                  )
                  : (
                    <Box sx={styles.textContainer}>
                      <Box sx={styles.textColumn}>
                        {/* GEM TYPE */}
                        {!!Object.keys(currItem.item?.currentGem ?? {}).length && (
                          <Box sx={styles.textBox}>
                            <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                              Type
                            </Typography>
                            <Typography variant="body1" color="success.main" sx={styles.textWrapper}>
                              {StatType[currItem.item?.currentGem?.type ?? ""]}
                            </Typography>
                          </Box>
                        )}
                        {/* GEM TIER */}
                        {!!(currItem.item?.tier ?? "").length && (
                          <Box sx={styles.textBox}>
                            <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                              Tier
                            </Typography>
                            <Typography variant="body1" color="success.main" sx={styles.textWrapper}>
                              {GemTier[currItem.item?.tier ?? ""]}
                            </Typography>
                          </Box>
                        )}
                        {/* BONUS STAT */}
                        {!!(currItem.item?.tier ?? "").length && (
                          <Box sx={styles.textBox}>
                            <Typography variant="body1" color="primary" sx={styles.textWrapper}>
                              Bonus Stat
                            </Typography>
                            {((currItem.item?.currentGem?.stat ?? 0) > 0) && (
                              <Typography variant="body1" color={toEquip ? "success.main" : "error.main"} sx={styles.textWrapper}
                                component="span">
                                <Box component="img" src={STATS[currItem.item?.currentGem?.type ?? ''].icon ?? ''} alt={currItem.item?.currentGem?.type ?? ''} />&nbsp;
                                <Typography variant="body1" color={toEquip ? "success.main" : "error.main"} sx={styles.textWrapper}>
                                  {currItem.item?.currentGem?.type ?? ''}&nbsp;{toEquip ? '+' : '-'}{currItem.item?.currentGem?.stat ?? 0}
                                </Typography>
                              </Typography>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
              </Box>
            )
          })}
        </Box>

        <Box sx={{
          width: '100%',
          mx: 'auto',
          mt: 6,

          display: 'flex',
          flexDirection: 'column',
          placeContent: 'center',
          placeItems: 'center',
          flexWrap: 'wrap',
          rowGap: 2,
        }}>
          <Typography variant="body1" color={!canEquip ? "error" : "success.main"}>{!canEquip ? "You’re missing some requirements." : "You’re all set!"}</Typography>
          <ContainedButton
            disabled={isLoading || !canEquip}
            onClick={handleOnConfirm}
            sx={styles.craftButton}
          >
            {isLoading
              ? <CircularProgress size={18} />
              : `Confirm & ${equipAction}`
            }
          </ContainedButton>
        </Box>
      </Box>
    )
  }

  return (
    <DialogModal
      open={open}
      onClose={handleOnClose}
      sx={styles.dialogModal}
      disableScrollLock={true}
      header={getHeader()}
      dialogOverwrites={styles.dialogOverwrite}
    >
      {getContent()}
    </DialogModal>
  );
}


const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogOverwrite: {
    overflowY: 'scroll',
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      maxHeight: '85vh',
      marginTop: '11vh',
    },
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    width: "100%",
    maxHeight: '50vh',
    marginTop: "10px",
    paddingRight: '10px',
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    gap: '1em',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginY: "10px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
    '.masked-overflow': {
      maskImage: 'none',
      WebkitMaskImage: 'none',
      overflow: 'hidden'
    },
  },
  textContainer: {
    width: '100%',
    padding: '28px 40px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    border: '1px solid #AEF1EE',
    borderRadius: '24px',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    minHeight: '200px',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginY: "20px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
    '.masked-overflow': {
      maskImage: 'none',
      WebkitMaskImage: 'none',
      overflow: 'hidden'
    },
    "@media(max-width:768px)": {
      flexDirection: 'column-reverse',
      gap: '1.5em',
      padding: '20px',
    }
  },
  textColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    "@media(max-width:768px)": {
      width: '100%',
    }
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  itemFrame: {
    backgroundImage: `url(${StoreItemFrame})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
  },

  statGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: '1.5em',
    rowGap: '0.5em',
  },

  costRow: {
    width: '100%',
  },
  costContainer: {
    width: '100%',
    padding: '18px 34px',
    borderRadius: '16px',
    border: '1px solid #AEF1EE55',
    background: 'rgba(174, 241, 238, 0.1)',
  },

  tableContainer: {
    // overflowY: "auto",
    // maxHeight: "25vh",
    // maxWidth: "700px",
    paddingRight: "10px",
    paddingBottom: "10px",
    // "& .MuiTableCell-stickyHeader": {
    //   backgroundColor: "transparent",
    //   top: "",
    //   left: "",
    // },
    // '::-webkit-scrollbar': {
    //   height: '8px',
    //   width: '8px',
    // },
    // '::-webkit-scrollbar-track': {
    //   marginTop: "10px",
    // },
    // '::-webkit-scrollbar-thumb': {
    //   background: "#888",
    //   borderRadius: "20px",
    // },
    // '::-webkit-scrollbar-thumb:hover': {
    //   background: "#555",
    // },
    // '::-webkit-scrollbar-corner': {
    //   background: "rgba(0,0,0,0)",
    // },
  },
  tableHead: {
    "& th.MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "transparent",
      whiteSpace: "nowrap",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: "8px 0px",
      borderColor: "transparent",
      whiteSpace: "nowrap",
    },
  },

  tooltipText: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    display: 'flex',
    alignItems: 'center',
  },

  costText: {
    justifyContent: 'flex-end'
  },

  craftButton: {
    padding: '14px 60px',
    marginBottom: '10px',
    minWidth: '200px',
    height: 60,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },

  viewTx: {
    marginTop: "30px",
    marginBottom: "20px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
}

export default EquipDialog