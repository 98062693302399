import { Box, SxProps } from "@mui/material";
import { EQUIP_CATEGORIES } from "components/Metazoa/ResourceConstants";
import { IDroppable } from "components/Metazoa/ResourcesDialogs/components/GemsDND/Droppable";
import { FC } from "react";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import { Droppable } from ".";
import { EquipDroppableProps } from "./EquipDroppable";
import { IInventoryItem } from "./EquipPanel";

interface EquipDroppableGroupProps extends Omit<EquipDroppableProps, 'item' | 'type'>, Omit<IDroppable, 'id'> {
  items: IInventoryItem[];
}

const EquipDroppableGroup: FC<EquipDroppableGroupProps> = ({
  id,
  items = [],
  onDrop,
  acceptableTypes = [],
  limit = 0,
  options = {
    hasPlaceholder: false,
    isRow: true,
    hasUniqueTypes: false,
    hasMiniTypeIcon: false,
    lockDrop: false,
    isLoading: false,
  },
  sx,
}: EquipDroppableGroupProps) => {

  if (!!items.length) items = items.filter(({ type }) => acceptableTypes.includes(type.type)) ?? [];

  options = {
    hasPlaceholder: false,
    isRow: true,
    hasUniqueTypes: false,
    hasMiniTypeIcon: false,
    lockDrop: false,
    isLoading: false,
    ...options,
  }
  if (options.hasUniqueTypes && acceptableTypes.length < 2) options.hasUniqueTypes = false;

  if (!limit || !acceptableTypes.length) return null;
  if (items.length > limit) items = items.slice(0, limit);
  return (
    <Box
      id={id ?? 'dropGrp'}
      component="section"
      sx={combineStyles(
        styles.dragGroup,
        sx,
      )}
    >
      {((!!items.length
        ? (!options.hasUniqueTypes
          ? [
            ...items,
            ...new Array(limit - items.length).fill({} as IInventoryItem)
          ]
          : new Array(acceptableTypes.length).fill({} as IInventoryItem)
            .map((itm, idx) => {
              const expectedType = idx > acceptableTypes.length ? acceptableTypes[idx - acceptableTypes.length] : acceptableTypes[idx];
              const typeItemIdx = items.findIndex(({ type }) => type.type === expectedType);
              if (typeItemIdx < 0) return itm;

              return items[typeItemIdx] ?? itm;
            }))
        : new Array(limit - items.length).fill({} as IInventoryItem)
      ) as IInventoryItem[])
        .map((itm, idx) => {

          const equipCategoryIdx = idx > acceptableTypes.length ? idx - acceptableTypes.length : idx;
          const equipCategory = (Object.values(EQUIP_CATEGORIES)
            .filter((category) => acceptableTypes.includes(category.type)))[equipCategoryIdx];

          return (
            <Droppable
              key={idx}
              id={idx.toString()}
              item={itm ?? {} as IInventoryItem}
              {...(!!itm) && { type: itm.type }}
              acceptableTypes={!options.hasUniqueTypes ? acceptableTypes : [equipCategory.type]}
              onDrop={onDrop}
              showMiniTypeIcon={options.hasMiniTypeIcon}
              options={{
                lockDrop: options.lockDrop,
                isLoading: options.isLoading,
              }}
              sx={{
                ...(!options.isRow) && {
                  minWidth: '104px',
                  minHeight: '104px',

                  ...(idx === 3) && {
                    left: '5.5em',
                    top: '-15em',
                  },
                  ...(idx === 2) && {
                    top: '-5.5em',
                  },
                  ...(idx === 1) && {
                    left: '-5.5em',
                    top: '-3.5em',
                  },
                }
              }}
            />
          )
        })}
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dragGroup: {
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    py: '2%',

    position: 'relative',

    "> *": {
      flex: '1',
    }
  },
}

export default EquipDroppableGroup;