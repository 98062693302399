import { CircularProgress, SxProps } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import ContainedButton from "components/ContainedButton";
import { MetazoaClient } from "core/utilities/metazoa";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { Guild, Request, Status } from "store/types";
import { TBMConnector } from "tbm";
import { useRedux } from "utils";
import { AppTheme, SimpleMap } from "utils/types";
import useAsyncTask from "utils/useAsyncTask";
import useNetwork from "utils/useNetwork";
import LeaveRequestButton from "../MemberList/LeaveRequestButton";
import { JoinRequestButton, RequestDialog } from "./components";

export interface Props {
  guild: Guild;
}

const RequestButton: React.FC<Props> = (props: Props) => {
  const { guild } = props;
  const { guildBank } = guild;
  const hasGuildBank: boolean = !!guildBank ?? false;

  const network = useNetwork();
  const dispatch = useDispatch();
  const metazoaProfileState = useSelector(getProfile);
  const { profile } = metazoaProfileState;
  const wallet = useRedux((state) => state.wallet.wallet);

  // STATES / HOOKS -----------------

  const [joinRequests, setJoinRequests] = useState<Request[]>([]);
  const [showManageDialog, setShowManageDialog] = useState<boolean>(false);
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);
  const [reloadRequests, setReloadRequests] = useState<boolean>(false);
  const [runQueryPendingRequest, loadingPendingRequest] = useAsyncTask("queryPendingRequest");

  const walletHolder = useMemo(() => {
    if (!wallet || !guild) return;

    const addr = wallet.addressInfo.byte20!.toLocaleLowerCase()
    const councilAddresses: string[] = [guild.leaderAddress, ...guild.commanderAddresses]

    //INFO: Wallet holder attributes
    const hasGuild: boolean = profile?.guildId ? true : false
    const isMember: boolean = profile?.guildId === guild.id
    const isLeader: boolean = addr === guild.leaderAddress;
    const isCouncilMember: boolean = councilAddresses.includes(addr)
    const canLeaveGuild: boolean = !isCouncilMember && isMember;
    const canRequest: boolean = !isCouncilMember && !isMember && !hasGuild;
    const canManageRequest: boolean = isCouncilMember;

    return { addr, isLeader, canLeaveGuild, canManageRequest, canRequest }
    // eslint-disable-next-line 
  }, [wallet, guild, profile])

  useEffect(() => {
    if (!guild || !wallet || !walletHolder) return;
    const abortController = new AbortController();

    runQueryPendingRequest(async () => {
      //INFO: Check if join request was made for this guild (not applicable to Captain)
      if (!walletHolder.isLeader) {
        if (hasGuildBank && guildBank && !!guildBank.address) {
          const result = await TBMConnector.queryPendingRequest(wallet, toBech32Address(guildBank.address));
          setPendingRequest(!!result)
        }
        else {
          const metazoaClient = new MetazoaClient(network);
          const { result } = await metazoaClient.checkJoinGuildRequest(guild.id, walletHolder.addr!);
          setPendingRequest(result.status);
        }
      }

      //INFO: Check pending requests for this guild
      if (walletHolder.canManageRequest) {
        const metazoaClient = new MetazoaClient(network);
        if (hasGuildBank && guildBank && !!guildBank.address) {
          const { joining_requests } = await TBMConnector.queryPendingRequest(wallet, toBech32Address(guildBank.address), walletHolder.canManageRequest);
          const requests = await Promise.all(
            Object.keys(joining_requests).map(async (req) => {
              const { result: { model } } = await metazoaClient.getProfile(req);
              return {
                sender: req,
                guildId: guild.id,
                status: Status.Created,
                user: model,
              }
            })
          )
          setJoinRequests(requests);
        }
        else {
          const { result: { requests } } = await metazoaClient.joinGuildRequestList(guild.id);
          setJoinRequests(requests);
        }
      }
    });

    return abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guild, wallet, network, pendingRequest, walletHolder, guildBank]);

  // FUNCTIONS ----------------------

  // EVENT HANDLERS -----------------

  const handleOnManage = () => {
    setShowManageDialog(true)
  }

  const handleOnManageClose = () => {
    setShowManageDialog(false);
  }

  const handleOnReloadRequest = () => {
    if (!guild) return;
    dispatch(actions.Guild.reloadGuild(guild));
    setReloadRequests(!reloadRequests);
  }

  // --------------------------------

  const isLoading = loadingPendingRequest;

  if (!guild) return null;
  return (
    <>
      {!walletHolder?.canManageRequest && !walletHolder?.canLeaveGuild && (
        <JoinRequestButton
          guild={guild}
          canRequest={walletHolder?.canRequest ?? false}
          pendingRequest={pendingRequest}
          setPendingRequest={setPendingRequest}
          loadingPendingRequest={loadingPendingRequest}
        />
      )}

      {walletHolder?.canManageRequest && (<>
        <ContainedButton sx={styles.requestButton} onClick={handleOnManage} disabled={isLoading || joinRequests.length === 0}>
          {isLoading
            ? <CircularProgress size={18} />
            : `Pending Requests (${joinRequests.length})`
          }
        </ContainedButton>
        <RequestDialog
          open={showManageDialog}
          onClose={handleOnManageClose}
          requests={joinRequests}
          guild={guild}
          reload={handleOnReloadRequest}
          loadingJoinRequests={loadingPendingRequest}
        />
      </>)}

      {(!walletHolder?.canManageRequest && walletHolder?.canLeaveGuild) && (
        <LeaveRequestButton guild={guild} />
      )}
    </ >
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  requestButton: {
    height: 48,
    minWidth: 280,
    "&.MuiButton-root": {
      fontSize: "18px",
      lineHeight: "28px",
      whiteSpace: "nowrap",
    },
    "& .MuiBox-root": {
      display: "none",
    },
    "@media (max-width:600px)": {
      height: 40,
      "&.MuiButton-root": {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
}


export default RequestButton;
