import { Fragment, useState } from "react";
import { Box, BoxProps, IconButton, SxProps, Typography } from "@mui/material";
import { WarningRed } from "assets";
import { AppTheme, SimpleMap } from "utils/types";
import CloseIcon from "@mui/icons-material/Close";
import { combineStyles } from "utils/themeUtilities";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { useRedux } from "utils";

const BetaWarning: React.FC = (props: BoxProps) => {

  const dispatch = useDispatch();
  const warningVisibility = useRedux((state) => state.layout.warningVisibility);

  const [showContent, setShowContent] = useState<Boolean>(warningVisibility);
  const onCloseButton = () => {
    setShowContent(false);
    dispatch(actions.Layout.toggleBetaWarning(false));
  }
  return (
    <Box {...props} sx={combineStyles(styles.container, props.sx)} component="article" >
      {!!showContent
        && <>
          <Box sx={styles.svgWrapper}>
            <WarningRed width="100%" height="100%" />
          </Box>
          <Typography variant="body1" color="primary" sx={styles.text}>BETA Warning: ZOLAR was only recently discovered and is unaudited. Please explore and join the battle at your own risk.</Typography>
          <IconButton
            aria-label="close"
            sx={styles.closeButton}
            onClick={onCloseButton}
          >
            <CloseIcon />
          </IconButton>
        </>
      }

    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    width: '100%',
    position: 'fixed',
    top: '60px',
    zIndex: 100000,
    background: '#323F5C',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  svgWrapper: {
    width: '24px',
    height: '24px',
  },

  closeButton: {
    alignSelf: 'right',
    color: '#fff',
    width: '10%',
  },

  text: {
    marginY: '10px',
    marginLeft: '10px',
    "@media (max-width:900px)": {
      width: "80%",
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
  }
}

export default BetaWarning;