import { Box, Button, Divider, styled, SxProps, Typography } from "@mui/material";
import { AddIcon, Huny, ZilTokenIcon } from "assets";
import HunyFrame from "assets/HunyFrame.png";
import BigNumber from "bignumber.js";
import BoxWithHeader from "components/BoxWithHeader";
import ContainedButton from "components/ContainedButton";
import { useEffect, useMemo, useState } from "react";
import { Guild } from "store/types";
import { TBMConnector } from "tbm";
import { useRedux } from "utils";
import { BIG_ZERO, Decimals } from "utils/constants";
import { bnOrZero, toHumanNumber } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { AddLiquidityDialog, ClaimLiquidityDialog, RemoveLiquidityDialog } from "./components";

export interface GuildHiveProps {
  guild: Guild;
  canCollectTax: boolean;
  tabController: React.Dispatch<React.SetStateAction<number>>
}

const GuildHive: React.FC<GuildHiveProps> = (props: GuildHiveProps) => {
  const { guild, canCollectTax, tabController } = props
  const { guildBank } = guild;

  const rates = useRedux((state) => state.token.ExchangeRates);
  const hiveInfo = useRedux((state) => state.token.hiveInfo)
  const network = useNetwork();
  const bankHivePool = guildBank?.guildHiveStats;
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [showClaimDialog, setShowClaimDialog] = useState<boolean>(false);

  useEffect(() => {
    if (!guildBank || !guildBank.address) return;
    const interval = setInterval(() => {
      try {
        const newBlock = TBMConnector.getCurrentBlock();
        if (newBlock !== currentBlock) {
          setCurrentBlock(newBlock)
        }
      } catch (e) {
        console.warn('Failed to get current block. Will try again in 1s. Error:')
        console.warn(e)
      }
    }, 1000);
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [guildBank, network, currentBlock]);

  const totalValue = useMemo(() => {
    const zilValue = bankHivePool?.userZilReserves.shiftedBy(-Decimals.ZIL).times(rates.zilPrice);
    const hunyValue = bankHivePool?.userHunyReserves.shiftedBy(-Decimals.HUNY).times(rates.hunyPrice);
    return hunyValue?.plus(zilValue ?? BIG_ZERO);
  }, [rates, bankHivePool]);

  const handleShowAdd = () => {
    setShowAddDialog(true);
  }

  const handleShowRemove = () => {
    setShowRemoveDialog(true);
  }

  const handleShowClaim = () => {
    setShowClaimDialog(true);
  }

  const { rewardHunyAmt, rewardValue } = useMemo(() => {
    if (!hiveInfo?.hunyRewardsPerShare || !bankHivePool?.userBalance) return {};

    let pendingRewardsPerShare = new BigNumber(0)
    if (currentBlock !== 0) {
      const pendingBlocks = currentBlock - hiveInfo.lastRewardBlock
      const pendingKickbacks = bnOrZero(hiveInfo?.incomingKickbacks)
      const pendingRewards = new BigNumber(36750000).dividedBy(2300 * 180).times(pendingBlocks).shiftedBy(Decimals.HUNY).plus(pendingKickbacks)
      pendingRewardsPerShare = pendingRewards.times(1e12).dividedToIntegerBy(hiveInfo.totalShare)
    }

    // reward per share * share / 1e12 - debt
    const userBalance = bankHivePool.userBalance.shiftedBy(-12);
    const userShare = userBalance.times(hiveInfo!.hunyRewardsPerShare.plus(pendingRewardsPerShare));
    const rewards = BigNumber.max(userShare.minus(bankHivePool.userDebt), BIG_ZERO).dp(0).shiftedBy(-Decimals.HUNY);

    return {
      rewardHunyAmt: rewards,
      rewardValue: rewards.times(rates.hunyPrice)
    }
  }, [currentBlock, bankHivePool, hiveInfo, rates.hunyPrice]);

  return (
    <BoxWithHeader
      headerText={<Typography variant="h3" color="primary" sx={styles.headerText}>Guild's Share in Magic Hive</Typography>}
      componentsRight={<Typography variant="h3" color="secondary">{bankHivePool?.userShare.toNumber().toFixed(4)}%</Typography>}
      sx={styles.container} boxContentSx={{ padding: '13px 30px 20px 30px', borderRadius: '0px 0px 16px 16px', }}
      boxHeaderSx={styles.boxHeaderOverride}>

      <Box display="flex" sx={styles.guildShareContainer}>
        <Box sx={styles.magicHiveIcon} />
        <Box display="flex" sx={styles.guildShareRow}>
          <Box display="flex" sx={styles.liquidityContainer}>
            <Box sx={{ flex: 1 }}>
              <StyledDivider textAlign="left">
                <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>Liquidity</Typography>
              </StyledDivider>
              <Typography sx={styles.magicHiveShareBig}>${totalValue?.toFormat(2)}</Typography>
              <Typography component="span" sx={styles.magicHiveShareSmall}>
                {toHumanNumber((bankHivePool?.userZilReserves ?? BIG_ZERO).shiftedBy(-Decimals.ZIL).toNumber().toFixed(2))} <ZilTokenIcon style={{ height: 24, width: 24, marginLeft: '8px' }} />
                <AddIcon style={{ height: 12, width: 12, margin: '0 8px' }} />
                {toHumanNumber((bankHivePool?.userHunyReserves ?? BIG_ZERO).shiftedBy(-Decimals.HUNY).toNumber().toFixed(2))} <Huny style={{ height: 24, width: 24, marginLeft: '8px' }} />
              </Typography>
            </Box>

            {canCollectTax && <Box sx={{ marginLeft: '16px', display: 'flex', flexDirection: 'column' }}>
              <ContainedButton
                variant="contained"
                sx={styles.button}
                disableFocusRipple
                onClick={handleShowAdd}
              >
                Add Liquidity
              </ContainedButton>
              <Button
                variant="outlined"
                color="secondary"
                sx={styles.gradientButton}
                onClick={handleShowRemove}
              >
                <Typography sx={styles.gradientText}>Remove</Typography>
              </Button>
            </Box>}
          </Box>

          <Box display="flex" sx={styles.rewardContainer}>
            <Box sx={{ flex: 1 }}>
              <StyledDivider textAlign="left">
                <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>Reward</Typography>
              </StyledDivider>
              <Typography sx={styles.magicHiveShareBig}>${rewardValue?.toFormat(2)}</Typography>
              <Typography component="span" sx={styles.magicHiveShareSmall}>
                {toHumanNumber(rewardHunyAmt?.toNumber().toFixed(2) ?? 0)} <Huny style={{ height: 24, width: 24, marginLeft: '8px' }} />
              </Typography>
            </Box>
            {canCollectTax && <Box sx={{ marginLeft: '16px', display: 'flex', flexDirection: 'column' }}>
              <ContainedButton
                variant="contained"
                sx={styles.button}
                disableFocusRipple
                onClick={handleShowClaim}
              >
                Claim
              </ContainedButton>
            </Box>}
          </Box>
        </Box>
      </Box>
      <AddLiquidityDialog guild={guild} open={showAddDialog} onClose={() => setShowAddDialog(false)} bankHive={bankHivePool} />
      <RemoveLiquidityDialog guild={guild} open={showRemoveDialog} onClose={() => setShowRemoveDialog(false)} bankHive={bankHivePool} />
      <ClaimLiquidityDialog guildBank={guildBank} open={showClaimDialog} onClose={() => setShowClaimDialog(false)} bankHive={bankHivePool} tabController={tabController} />
    </BoxWithHeader>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  headerText: {
    fontSize: '24px',
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  boxHeaderOverride: {
    "@media (max-width:1300px)": {
      height: 'auto !important'
    },
  },
  guildShareContainer: {
    minHeight: '140px',
    alignItems: 'center',
    "@media (max-width:1400px)": {
      minHeight: 'unset'
    },
  },
  magicHiveIcon: {
    width: '100%',
    maxWidth: '180px',
    minHeight: '140px',
    backgroundImage: `url(${HunyFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "215%",
    backgroundPosition: "center",
    marginRight: '38px',
    "@media (max-width:1400px)": {
      width: 'unset',
      minWidth: '200px',
      minHeight: '240px',
      backgroundSize: "250%",
      marginRight: '28px'
    },
  },
  guildShareRow: {
    flexWrap: 'unset',
    "@media (max-width:1400px)": {
      flexWrap: 'wrap'
    },
    flex: 1
  },
  rewardContainer: {
    width: '100%',
    marginTop: '7px',
    "@media (max-width:1400px)": {
      marginTop: '28px'
    },
  },
  liquidityContainer: {
    marginTop: '7px',
    width: '100%',
    marginRight: '38px',
    "@media (max-width:1400px)": {
      marginRight: 0
    },
  },
  overlineSmall: {
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: "0.2em"
  },
  magicHiveShareBig: {
    fontFamily: 'Syne',
    fontWeight: 700,
    fontSize: '40px',
    color: '#FEFEFE',
    marginBottom: '14px'
  },
  magicHiveShareSmall: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#FEFEFE',
    // marginTop: '14px',
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    fontSize: '18px',
    height: 48,
    width: '216px',
    padding: '14px 30px',
    fontWeight: 700,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
    "& div:first-of-type": {
      height: "inherit",
      // left: '-18px'
    }
  },
  gradientButton: {
    fontSize: '18px',
    height: 48,
    marginTop: '16px',
    width: '216px',
    padding: '14px 30px',
    fontWeight: 700,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },
}

const StyledDivider = styled(Divider)({
  minWidth: '260px',
  flexGrow: 1,
  '&.MuiDivider-root::after': {
    borderTopColor: 'rgba(174, 241, 238, 0.1)',
    width: '100%',
    top: 0
  },
  '&.MuiDivider-root::before': {
    borderTopColor: 'rgba(174, 241, 238, 0.1)',
    width: '0%',
  },
  '& span': {
    paddingLeft: 0
  }
});

export default GuildHive;