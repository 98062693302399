import React from 'react';
import { Box, styled, SxProps, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import PlaceholderBear from "assets/PlaceholderBear.png";
import { NftMetadata } from "store/types";
import { AppTheme, SimpleMap } from "utils/types";

export interface Props {
  handleToggleTokens: (event: React.MouseEvent<HTMLElement>, newTokens: string[]) => void;
  tokens: NftMetadata[];
  transcendTokens: string[];
}

const RenderBearCollection: React.FC<Props> = (props: Props) => {
  const { handleToggleTokens, tokens, transcendTokens } = props;

  // interface NFTBadgeProps {
  //   attributes?: NftAttribute[]
  // }

  // const NFTBadge = (props: NFTBadgeProps) => {
  //   let attribute = props?.attributes?.find(attribute => attribute.trait_type === 'Base');

  //   if (attribute?.value === "Alien") {
  //     return <img src={AlienBadge} alt="alien-badge" width="50px" height="50px"/>
  //   }
  //   if (attribute?.value === "Zombie") {
  //     return <img src={ZombieBadge} alt="alien-badge" width="50px" height="50px"/>
  //   }
  //   if (attribute?.value === "Gummy") {
  //     return <img src={GummyBadge} alt="alien-badge" width="50px" height="50px"/>
  //   }
  //   return null;
  // }

  return (
    <BearCollectionGroup value={transcendTokens} onChange={handleToggleTokens} sx={styles.selectBearContainer}>
      {tokens.map((token) => {
        return (
          <BearWrapper key={token.id} value={token.id} sx={styles.bearWrapper}>
            <Box component="img" sx={styles.bearImage} src={token?.image ? token.image : PlaceholderBear} alt={`TBM Bear ${token.id}`}/>
            <Box sx={styles.bearIdBox}>
              <Typography variant="subtitle1" color="primary" sx={styles.bearId}>TBM #{token.id}</Typography>
            </Box>
            {/* <Box sx={styles.badgeIconWrapper}>
              <NFTBadge attributes={token.attributes}/>
            </Box> */}
          </BearWrapper>
        )
      })}
    </BearCollectionGroup>
  )

};

const styles: SimpleMap<SxProps<AppTheme>> = {
  bearWrapper: {
    position: 'relative',
    borderRadius: '16px',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'visible',
  },
  // badgeIconWrapper: {
  //   position: 'absolute',
  //   top: -20,
  //   right: -10,
  //   pointerEvents: 'none',
  // },
  bearImage: {
    height: '100px',
    width: '100px',
    "@media (max-width:900px)": {
      height: '80px',
      width: '80px',
    },
  },
  bearIdBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bearId: {
    marginTop: '6px',
    fontSize: '16px',
    "@media (max-width:900px)": {
      fontSize: '14px',
    },
  },
  selectBearContainer: {
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: 'repeat(5, 1fr)',
    rowGap: '20px',
    maxHeight: '160px',
    paddingTop: '10px',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '95%',
    marginBottom: '20px',
    "@media (max-width:900px)": {
      maxHeight: '140px',
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    "@media (max-width:600px)": {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    "@media (max-width:480px)": {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
}


const BearCollectionGroup = styled(ToggleButtonGroup) ({
  '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    borderRadius: '16px',
  },
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderRadius: '16px',
  },
  '& .MuiToggleButtonGroup-root' : {
  },
  '::-webkit-scrollbar': {
    width: '10px',
  },
  '::-webkit-scrollbar-track': {
    marginTop: '10px',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '20px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});
  
    
const BearWrapper = styled(ToggleButton)({
  '&.Mui-selected': {
    background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
  },
  '&.Mui-disabled': {
    opacity: 0.5,
  },
});

export default RenderBearCollection;
