import { Box, FormControl, FormControlProps, InputBase, MenuItem, Select, SelectChangeEvent, styled, SxProps } from "@mui/material";
import { ArrowDropdownIcon } from 'assets';
import { ReactNode, useState } from 'react';
import { combineStyles } from 'utils/themeUtilities';
import { AppTheme, SimpleMap } from "utils/types";

interface Props extends FormControlProps {
  options: DropdownOptions[];
  defaultValueSelected: string;
  onSelected?: (selectedValue: string) => void;
  paperStyles?: SxProps<AppTheme>;
  isNormalHeight?: boolean;
  menuStyles?: React.CSSProperties;
  disableScrollLock?: boolean;
}

export interface DropdownOptions {
  value: string;
  label: string;
  icon?: ReactNode;
}

const Dropdown: React.FC<Props> = (props: Props) => {
  const { options, defaultValueSelected, onSelected, paperStyles, isNormalHeight, menuStyles, disableScrollLock, ...rest } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValueSelected);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = event.target.value as string;
    setValue(newValue);
    if (typeof onSelected === "function") onSelected(newValue)
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);

  };

  return (
    <Box className="customFilter">
      <FormControl {...rest}>
        <Select
          IconComponent={RoundedArrowIcon}
          MenuProps={{ PaperProps: { sx: combineStyles(styles.dropdownMenu, paperStyles) }, style: menuStyles ?? {}, disableScrollLock: disableScrollLock }}
          value={value}
          onChange={handleChange}
          input={<StyledInput />}
          sx={combineStyles(
            styles.selectContainer,
            { ...(open) && styles.selectActive },
          )}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          {options.map((option, index) => {
            return (
              <MenuItem key={index} value={option.value}>
                {option.icon}
                {isNormalHeight && option.label}
                {!isNormalHeight && <p className='label'>{option.label}</p>}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dropdownMenu: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)',
    color: 'white',
    marginTop: '8px',
    border: '1px solid rgba(174, 241, 238, 0.1)',
    borderRadius: '16px',

    'svg': {
      width: "24px",
      height: "24px",
      color: 'rgba(255, 255, 255, 0.32)',
    },

    '& .MuiMenuItem-root': {
      paddingLeft: '24px',
      paddingTop: '10px',
      fontSize: 14,
      fontWeight: 600,

      display: 'flex',
      flexDirection: 'row',
      placeContent: 'start',
      placeItems: 'center',
      gap: '0.5em',
    },

    '& .MuiList-root': {
      padding: 0
    },
  },
  selectContainer: {
    width: "180px",
    marginRight: 2,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
    gap: '0.5em',
    border: '1px solid transparent',
    borderRadius: '16px',
    'svg': {
      width: "24px",
      height: "24px",
      color: 'rgba(255, 255, 255, 0.32)',
    },
    ".label": {
      margin: 0,
    },
  },

  selectActive: {
    borderColor: '#AEF1EE!important',

    "> svg path": {
      fill: 'url(#grey)',
      opacity: '1',
      fillOpacity: 1,
    },
  },

}

const StyledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    color: "#FFFFFF",
    fontWeight: 600,
    borderRadius: '16px',
    position: 'relative',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    border: '1px solid rgba(174, 241, 238, 0.1)',
    fontSize: 14,
    padding: '10px 24px 10px 24px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'start',
    placeItems: 'center',
    gap: '0.5em',

    "&:focus, &:focus-within": {
      borderRadius: '16px',
      border: '1px solid #AEF1EE',
    },
  },
}));

const RoundedArrowIcon = (props) => (
  <ArrowDropdownIcon {...props} style={{ top: 'unset', right: '14px' }} />
)

export default Dropdown;

