import { Box, Button, CircularProgress, FormHelperText, IconButton, Link, styled, SvgIcon, SxProps, TextField, Tooltip, tooltipClasses, TooltipProps, Typography, Zoom } from "@mui/material";
import { AddIcon, ExternalLink, RemoveIcon, WarningRed } from "assets";
import Huny from "assets/Huny.svg";
import StoreItemFrame from "assets/quests/Locations/ZOMGStore/StoreItemFrame.svg";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { QuestConstants } from "components/Quest";
import { logger, waitForTx } from "core/utilities";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { ZOMGResourceItem } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useTaskSubscriber, useToaster } from "utils";
import { BIG_ONE, BIG_ZERO, Decimals, ITEM_ID } from "utils/constants";
import { bnOrZero, formatIntegerNumber, getExplorerLink, toHumanNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";

export interface ModalProps {
  resource: ZOMGResourceItem;
  open: boolean;
  onClose: () => void;
}

const initialFormState = {
  resources: BIG_ZERO,
  hunyAmt: BIG_ZERO,
}

const SellResourceDialog: FC<ModalProps> = ({
  open,
  onClose,
  resource
}: ModalProps) => {

  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();

  const wallet = useRedux((state) => state.wallet.wallet)
  const resources = useRedux((state) => state.token.resources)
  const [loadingTokens] = useTaskSubscriber("updateTokens");

  // STATES / HOOKS -----------------

  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [errors, setErrors] = useState<string>("");

  const ownedResource = useMemo(() => bnOrZero(resources[resource.asset.key].shiftedBy(-2)), [resources, resource.asset]);

  const [approved, setApproved] = useState<boolean>(false);
  const [purchaseTxHash, setPurchaseTxHash] = useState<string>("");
  const [sellRequestCompleted, setSellRequestComplete] = useState<boolean>(false);
  const [runQueryApproved, loadingQueryApproved] = useAsyncTask("queryApproved");
  const [runApprove, loadingApprove] = useAsyncTask("requestApproval", (error) => {
    onClose();
    toaster(error?.message ?? "Error Approving");
  });
  const [runSellAction, loadingBuyAction] = useAsyncTask("requestSell", (error) => {
    onClose();
    toaster(error?.message ?? `Error Selling ZOMG Resource`);
  });

  const [loadingFetchResources] = useTaskSubscriber('refetchResources');
  const isLoading: boolean = loadingTokens || loadingQueryApproved || loadingBuyAction || loadingFetchResources;

  useEffect(() => {
    if (!wallet || !network || !resource) return;

    logger("debug-zomg-contract", "resourceContract", {
      key: resource.asset.alias,
    })

    runQueryApproved(async () => {
      const resourceApproved = await TBMConnector.checkZOMGResourceApproved(wallet, resource.asset.alias);
      setApproved((resourceApproved));
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, network, resource]);

  const sellability = useMemo(() => {
    // PRICE (* base price, start price, max price, inflation bps, deflation bps *)
    const basePrice = resource.sell[0];
    const startPrice = resource.sell[1];
    const inflationBps = resource.sell[3];
    const deflationBps = resource.sell[4];
    const quantity = formState.resources;
    const currentNetPurchase = bnOrZero(resource.transactCount.netPurchaseCount);

    const getMaxDeflationBPS = (startPrice: BigNumber, basePrice: BigNumber) => {
      const maxDeflationAmt = basePrice.minus(startPrice);
      return bnOrZero(maxDeflationAmt.times(10000).dividedBy(startPrice).toFixed(0, 1));
    }

    const getSellSideInflation = (purchaseCount: BigNumber, inflationBps: BigNumber, deflationBps: BigNumber) => {
      const absPurchaseCount = bnOrZero(purchaseCount).abs();
      const rootPurchase = absPurchaseCount.sqrt().toFixed(0, 1);
      if (purchaseCount.lt(BIG_ZERO)) return bnOrZero(rootPurchase).times(bnOrZero(deflationBps)).toFixed(0, 1);  // deflating
      else return bnOrZero(rootPurchase).times(bnOrZero(inflationBps)).toFixed(0, 1); // inflating
    }

    const getAvgDeflationBPS = (purchaseCount: BigNumber, qtySold: BigNumber, inflationBps: BigNumber, deflationBps: BigNumber) => {
      const currentInflationBPS = bnOrZero(getSellSideInflation(purchaseCount, inflationBps, deflationBps));
      const newCount = purchaseCount.minus(qtySold).plus(BIG_ONE);
      const newInflationBPS = bnOrZero(getSellSideInflation(newCount, inflationBps, deflationBps));
      const avgDeflationIncrBPS = newInflationBPS.minus(currentInflationBPS).dividedBy(2).toFixed(0, 1);
      const avgDeflationBPS = currentInflationBPS.plus(bnOrZero(avgDeflationIncrBPS));

      return avgDeflationBPS;
    }

    // inflation = root k * bpsRate, where k = netPurchaseCount
    const getMaxNegativeNetPurchase = (startPrice: BigNumber, basePrice: BigNumber, deflationBps: BigNumber) => {
      const maxDeflationBps = getMaxDeflationBPS(startPrice, basePrice);
      const rootNetPurchase = bnOrZero(maxDeflationBps.dividedBy(deflationBps).toFixed(0, 1));
      return rootNetPurchase.pow(2).negated() // negate to get -ve purchase count
    }

    const currentInflationBps = bnOrZero(getSellSideInflation(currentNetPurchase, inflationBps, deflationBps))

    let currentPrice = startPrice;
    let totalSellPrice = BIG_ZERO;
    // Current inflated price 
    if (!!currentNetPurchase) {
      currentPrice = bnOrZero(currentPrice).times(currentInflationBps.shiftedBy(-4).plus(1));
    }

    // Total Inflated Price
    const avgBps = getAvgDeflationBPS(currentNetPurchase, quantity, inflationBps, deflationBps);
    totalSellPrice = startPrice.times(avgBps.shiftedBy(-4).plus(1)).times(quantity);
    if (currentPrice.lt(basePrice)) {
      totalSellPrice = basePrice.times(quantity);
    }

    // Calculate quantity allowed before deflated to base price
    // calculate maxNegativeNetPurchase
    const maxNegativeNetPurchase = getMaxNegativeNetPurchase(startPrice, basePrice, deflationBps)
    // get newNetPurchase
    const newNetPurchase = currentNetPurchase.minus(quantity)
    // check if newNetPurhcase < maxNegativeNetPurchase
    if (newNetPurchase.lt(maxNegativeNetPurchase)) {
      const quantityBeforeMax = maxNegativeNetPurchase.minus(currentNetPurchase).negated() // negate to get +ve value count
      const exceedQuantity = quantity.minus(quantityBeforeMax);
      // quantity before max, uses avg deflation formula
      const avgBps = getAvgDeflationBPS(currentNetPurchase, quantityBeforeMax, inflationBps, deflationBps);
      // quantity after max, uses base price
      const totalWithinMaxCost = startPrice.times(avgBps.shiftedBy(-4).plus(1)).times(quantityBeforeMax);
      const totalExceedMaxCost = basePrice.times(exceedQuantity);
      totalSellPrice = totalWithinMaxCost.plus(totalExceedMaxCost);
    }

    setErrors(``);
    let canSubmit: boolean = true;

    if (!wallet || !approved || formState.resources.isLessThanOrEqualTo(0) || isLoading) canSubmit = false;
    if (ownedResource.lt(formState.resources)) {
      setErrors(`Insufficient ${resource.asset.name}`)
      canSubmit = false;
    }

    return {
      totalSellPrice,
      canSubmit
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approved, formState, isLoading, ownedResource, resource, wallet]);

  // FUNCTIONS ----------------------

  const requestApproval = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const approveTx = await TBMConnector.increaseZOMGResourceItemAllowance(wallet, new BigNumber(2).pow(128).minus(1).dp(0), resource.asset.alias);
    toaster(`Submitted Resource Increase Allowance`, { hash: approveTx.id! });
    if (approveTx.isRejected() || !approveTx.id) throw new Error("Submitted transaction was rejected");
    await waitForTx(approveTx.id);
    setApproved(true);
  }

  const requestSell = async () => {
    if (!sellability.canSubmit) return;
    if (!wallet) throw new Error("Wallet not connected")

    const minPrice = bnOrZero(sellability.totalSellPrice).times(0.95).dp(0);

    const params = formState.resources;
    const purchaseTx = await TBMConnector.sellZOMGResources(wallet, ITEM_ID[network][resource.asset.name.replace(/\s/g, '')], minPrice, params);
    toaster(`Submitted Sell Resource`, { hash: purchaseTx.id! });
    if (purchaseTx.isRejected() || !purchaseTx.id) throw new Error("Submitted transaction was rejected");

    const tx = await waitForTx(purchaseTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(purchaseTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    setPurchaseTxHash(purchaseTx.id);
    setSellRequestComplete(true);
    toaster(`Resource Sold `, { hash: purchaseTx.id!, overridePersist: true });
    dispatch(actions.Token.refetchResource());
    dispatch(actions.ZOMG.loadStore());
  }

  const revertToInitialState = () => {
    setFormState(initialFormState);
    setErrors('');
    setPurchaseTxHash('');
    setSellRequestComplete(false);
    // setApproved(false);
  }

  // EVENT HANDLERS -----------------

  const handleOnClose = () => {
    if (isLoading) return;
    revertToInitialState();
    onClose();
  }

  const handleMaxInput = () => {
    resourceInputHandler((ownedResource).integerValue(BigNumber.ROUND_FLOOR).toString(10))
  };


  const onResourceInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resourceInputHandler(event.target.value);
  };

  const handleAddQtyResources = () => {
    resourceInputHandler(formState.resources.plus(1).toString(10));
  };

  const handleSubtractQtyResources = () => {
    if (formState.resources.isLessThanOrEqualTo(0))
      return;
    resourceInputHandler(formState.resources.minus(1).toString(10));
  };

  const resourceInputHandler = (resourcesInput: string) => {
    const bnresources = bnOrZero(resourcesInput);
    const hunyAmt = sellability.totalSellPrice;

    setFormState({
      resources: bnresources,
      hunyAmt,
    })
  };

  const handleApprove = () => {
    runApprove(async () => {
      await requestApproval();
    });
  }

  const handleRequestSell = () => {
    logger("debug-req", {
      key: resource.asset.key,
      misKey: ITEM_ID[network][resource.asset.name.replace(/\s/g, '')],
    })
    runSellAction(async () => {
      await requestSell();
    });
  }
  // --------------------------------

  const getHeader = () => {
    if (sellRequestCompleted) {
      return `HUNY Received!`
    } else return `Sell ${resource.asset.name}`
  }

  const getContent = () => {
    if (sellRequestCompleted) {
      return (
        <Box sx={styles.contentBox}>
          <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, { width: '80%', textAlign: 'center' })}>
            You have successfully sold your {resource.asset.name} and received HUNY in your wallet!
          </Typography>
          <Typography variant="h2" color="primary" sx={{ display: 'flex', alignItems: 'center', marginTop: '24px' }} component="span">
            <Box
              component="img"
              src={Huny}
              alt="Huny icon"
              height="64px"
              width="64px"
            />
            &nbsp;x {parseFloat(toHumanNumber(sellability.totalSellPrice.shiftedBy(-Decimals.HUNY))) <= 0 ? "< 0.01" : toHumanNumber(sellability.totalSellPrice.shiftedBy(-Decimals.HUNY))}
          </Typography>
          <Link
            target="_blank"
            href={getExplorerLink("tx", purchaseTxHash, network)}
            sx={styles.viewTx}
          >
            View Transaction
            <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
          </Link>
          <ContainedButton sx={styles.button} href="/metazoa">View Resource</ContainedButton>
        </Box >
      )
    } else {
      return (
        <Box sx={styles.contentBox}>
          <Box sx={styles.textContainer}>
            <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, styles.textWrapper)} component="span">
              Sell {resource.asset.name} for HUNY! Each {resource.asset.alias}
              <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, styles.gradientText)} component="span">&nbsp;sold&nbsp;</Typography>
              will
              <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, styles.gradientText)} component="span">&nbsp;decrease&nbsp;</Typography>
              the price for the next item.&nbsp;
              <Link
                target="_blank"
                href="https://docs.zolar.io/stations/zomg-store-new#mission-ii"
              >
                Learn More
              </Link>
            </Typography>
            <ItemTooltip
              // open={true}
              title={!!resource.asset.name ? (<Typography variant="body1" sx={{
                lineHeight: 'normal',
                fontSize: '1.5em',
                ...GUILD_LIGHTGRAY_GRADIENT,
              }}>{resource.asset.description}</Typography>) : ''}
              TransitionComponent={Zoom}
              placement="top"
            >
              <Box
                height="150px"
                sx={styles.itemFrame}>
                <Box
                  component="img"
                  src={!!resource.asset ? resource.asset.iconSrc : ''}
                  width="60%"
                  maxWidth="80px"
                  minHeight="100px"
                  onError={QuestConstants.DEFAULT_SRC}
                />
              </Box>
            </ItemTooltip>
          </Box>
          <Box sx={styles.dialogRow}>
            <Typography variant="body1" color="primary" sx={styles.textStyle}>Sell</Typography>
            <Box width="50%">
              <Box sx={combineStyles(styles.input, styles.numInputBox, { ...(errors) && styles.errorInput })}>
                <IconButton onClick={handleSubtractQtyResources}>
                  <RemoveIcon style={{ height: 16, width: 16 }} />
                </IconButton>
                <Box sx={styles.numInput}>
                  <Box
                    component="img"
                    alt="resource icon"
                    src={resource.asset.iconSrc}
                    width="24px"
                    height="24px"
                  />
                  <TextField
                    id="resource"
                    name="resource"
                    onChange={onResourceInputChange}
                    value={bnOrZero(formState.resources).toString()}
                    sx={combineStyles(styles.input, styles.settingNumInput, {
                      flex: 0.5
                    }, {
                      ...(formState.resources.gte(10000)) && ({
                        flex: `calc(0.6 + ${(Number(formState.resources.toString().length) - 5) / 10})`,
                        "& input": { textAlign: "start" },
                      })
                    })} />
                </Box>
                <IconButton onClick={handleAddQtyResources}>
                  <AddIcon style={{ height: 16, width: 16 }} />
                </IconButton>
              </Box>
              {!!errors &&
                <Box sx={styles.errorTextContainer}>
                  <WarningRed width={15} />
                  <FormHelperText sx={styles.errorText}>{errors}</FormHelperText>
                </Box>}
            </Box>
          </Box>
          <Box sx={combineStyles(styles.dialogRow, styles.maxButtonRow)}>
            <Typography variant="body1" color="primary">{resource.asset.name} owned:   {formatIntegerNumber(ownedResource.integerValue(BigNumber.ROUND_FLOOR))}</Typography>
            <Button variant="outlined" color="secondary" sx={styles.maxButton} onClick={handleMaxInput}>
              <Typography sx={combineStyles(styles.gradientText, styles.maxButtonText)}>Max</Typography>
            </Button>
          </Box>
          <Typography variant="body1" color="primary" sx={combineStyles(styles.textStyle, { marginTop: '12px' })} component="span">
            You will receive
            <Typography variant="body1" color="success.main" sx={styles.textStyle}>
              &nbsp;
              {parseFloat(toHumanNumber(sellability.totalSellPrice.shiftedBy(-Decimals.HUNY))) <= 0 ? "< 0.01" : toHumanNumber(sellability.totalSellPrice.shiftedBy(-Decimals.HUNY))}
              &nbsp;
            </Typography>
            HUNY
          </Typography>

          <Box display="flex" sx={styles.buttonBox}>
            {!approved &&
              <ContainedButton sx={styles.approveButton} onClick={handleApprove} disabled={isLoading}>
                {loadingApprove
                  ? <CircularProgress size={24} />
                  : <span style={{ lineHeight: '2rem' }}>Approve</span>
                }
              </ContainedButton>
            }

            <ContainedButton
              sx={styles.confirmButton}
              disabled={!sellability.canSubmit}
              onClick={handleRequestSell}
            >
              {isLoading
                ? <CircularProgress size={18} />
                : "Confirm & Sell"
              }
            </ContainedButton>
          </Box>
        </Box >
      )
    }
  }
  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={false} dialogOverwrites={{ overflowY: 'scroll' }}>
      {getContent()}
    </DialogModal>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  textContainer: {
    width: '100%',
    padding: '20px 40px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    border: '1px solid #AEF1EE',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  textWrapper: {
    marginRight: '20px',
  },
  textStyle: {
    fontSize: '1.125rem',
    lineHeight: '36px',
    fontWeight: 400,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  textAlign: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemFrame: {
    backgroundImage: `url(${StoreItemFrame})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '200px',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
  dialogRow: {
    marginTop: '12px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },

    "&:hover, &:active, &:focus, &:focus-within": {
      borderColor: '#AEF1EE',
    },

    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      paddingY: "12px",

      "&:active, &:focus": {
        color: "#AEF1EE",
        "& input": {
          color: "#AEF1EE",
        },
      }
    },
  },
  settingNumInput: {
    background: 'transparent',
    border: 0,
    flex: 0.4,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
    "& input": {
      paddingX: '0',
      width: "100%",
      textAlign: "center",
    },
  },
  numInputBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    width: "100%",
    gap: '24px',
  },
  numInput: {
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    gap: '8px',
    flex: 1,
  },
  errorInput: { borderColor: "#F75E5E!important", },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },
  button: {
    marginTop: '20px',
    minWidth: '280px'
  },
  viewTx: {
    marginTop: "30px",
    marginBottom: "40px",
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  maxButtonRow: {
    justifyContent: 'flex-end',
  },
  maxButton: {
    radius: '16px',
    padding: '7px 15px',
    fontSize: '1.125rem',
    marginLeft: '20px',
  },
  maxButtonText: {
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },

  buttonBox: {
    marginTop: "16px",
    "@media (max-width:600px)": {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  approveButton: {
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    marginRight: '10px',
    width: 270,
    "@media (max-width:600px)": {
      marginRight: 0,
      marginBottom: '10px',
      width: '100%',
    }
  },
  confirmButton: {
    whiteSpace: 'nowrap',
    height: 60,
    flexGrow: 1,
    flexBasis: 0,
    minWidth: 270,
    "@media (max-width:600px)": {
      width: '100%',
    }
  },
}

const ItemTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '10px 13px',
    boxShadow: "0px 4px 24px #000000",
    borderRadius: '16px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%), linear-gradient(180deg, #010025 0%, #000000 100%)',
    maxWidth: 250,
  },
});

export default SellResourceDialog;
