import { SimpleMap } from "utils/types"
import { Quest, RefinementFees } from "./types"

export const QuestActionType = {
  SET_QUEST_STATE: "SET_QUEST_STATE",
  SET_REFINEMENT_FEES: "SET_REFINEMENT_FEES",
  SET_HARVEST_ZOAS: "SET_HARVEST_ZOAS",
}

export const setHarvestZoas = (payload: string[]) => ({
  type: QuestActionType.SET_HARVEST_ZOAS,
  payload
})

export const setQuestState = (payload: SimpleMap<Quest>) => ({
  type: QuestActionType.SET_QUEST_STATE,
  payload
})

export const setRefinementFeeState = (payload: RefinementFees) => ({
  type: QuestActionType.SET_REFINEMENT_FEES,
  payload
})