import { Box, BoxProps, Fade, IconButton, Link, SxProps, Tooltip, Typography } from "@mui/material";
import { DisabledIcon, InfoTooltip } from "assets";
import { AppTheme, SimpleMap } from "utils/types";


export interface TooltipZolarProps extends BoxProps{
  link?: string;
}

const TooltipLocked:React.FC<TooltipZolarProps> = (props:TooltipZolarProps) => {
  const { link, children, ...rest } = props;
  return (
    <Tooltip placement="right" color="primary" 
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      title={
        <Box sx={styles.tooltip} {...rest}>
          <InfoTooltip/>
          <Typography color="primary" variant="body1">
            {children}
          </Typography>
          <br/>
          {!!link
            ? <Link sx={styles.learnMore} target="_blank" href={link}>Learn More</Link>
            : null
          }
        </Box>
      }
    >
      <IconButton>
        <DisabledIcon/>
      </IconButton>
    </Tooltip>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  tooltip: {
    padding: '20px',
    minWidth: '400px',
    maxWidth: '450px',
  },
  learnMore: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: "1.75rem",
  },
}

export default TooltipLocked;