import { Box, Button, CircularProgress, Divider, FormHelperText, Grid, IconButton, Link, SvgIcon, SxProps, TextField, Typography } from "@mui/material";
import { AddIcon, ExternalLink, Huny, RemoveIcon, WarningRed } from "assets";
import ItemFrame from 'assets/ItemFrame.svg';
import { EmptyGeode, GeodeResource } from "assets/quests";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import WarningBox from "components/WarningBox";
import { logger, waitForTx } from "core/utilities";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { TBMConnector } from "tbm";
import { theme } from "theme";
import { useAsyncTask, useRedux, useTaskSubscriber, useToaster } from "utils";
import { BIG_ZERO, Decimals, MissionGroundResource } from "utils/constants";
import { bnOrZero, formatIntegerNumber, getExplorerLink, toHumanNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import { STATS } from "../MetazoaCollection/MetazoaConstants";
import { GEMS } from "../ResourceConstants";

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

interface ResourceModalProps extends ModalProps {
  resourceType: keyof typeof MissionGroundResource;
}
const initialFormState = {
  geodes: BIG_ZERO,
  craftCost: BIG_ZERO,
}

const MAX_CRACK_GEODE_AMOUNT = 2000;

const CrackGeodesDialog: React.FC<ResourceModalProps> = ({
  open,
  onClose,
  resourceType = 'Geodes',
}: ResourceModalProps) => {

  const network = useNetwork();
  const toaster = useToaster();
  const dispatch = useDispatch();
  const wallet = useRedux((state) => state.wallet.wallet)
  const geodesOwned = useRedux((state) => state.token.resources[MissionGroundResource.Geodes]);
  const resources = useRedux((state) => state.token.resources);
  const HunyTokens = useRedux((state) => state.token.HunyTokens);
  const crackGeodeCost = useRedux((state) => state.quest.refinementFees.crackGeodesFee);
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [errors, setErrors] = useState<string>("");
  const [crackGeodesTxHash, setCrackGeodesTxHash] = useState<string>("");
  const [crackGeodesComplete, setCrackGeodesComplete] = useState<boolean>(false);
  const [emptyGeodes, setEmptyGeodes] = useState<BigNumber>(BIG_ZERO);
  const [gemsReceived, setGemsReceived] = useState<SimpleMap<number>>({});
  const [needAllowance, setNeedAllowance] = useState<boolean>(true);
  const [fetchAllowance, loadingAllowance] = useAsyncTask("fetch allowance", (error) => {
    toaster(error?.message ?? "Error Fetching Allowance");
  });
  const [runApprove, loadingApprove] = useAsyncTask("approve", (error) => {
    toaster(error?.message ?? "Error Approving");
  });
  const [runCrackGeodes, loadingCrackGeodes] = useAsyncTask("crack geodes", (error) => {
    toaster(error?.message ?? "Error Cracking Geodes");
  });

  const [loadingFetchResources] = useTaskSubscriber('refetchResources');

  const isLoading: boolean = loadingCrackGeodes || loadingFetchResources;

  useEffect(() => {
    fetchAllowance(async () => {
      if (!wallet) return;
      const result = await TBMConnector.checkUserGemAllowance(wallet, resourceType);
      setNeedAllowance(result);
    })
    // eslint-disable-next-line
  }, [wallet])

  const isSubmitEnabled = useMemo(() => {
    if (!wallet) return false;

    const geodesAmount = bnOrZero(formState.geodes)

    if (geodesAmount.lte(0)) return false;

    if (bnOrZero(geodesOwned).shiftedBy(-2).lt(formState.geodes)) {
      setErrors("Insufficient Geodes")
      return false
    }

    if (geodesAmount.gt(MAX_CRACK_GEODE_AMOUNT)) {
      setErrors("Maximum geodes per transaction is " + MAX_CRACK_GEODE_AMOUNT)
      return false
    }

    if (!geodesAmount.isInteger()) {
      setErrors("Whole Number Only")
      return false
    }

    if (bnOrZero(formState.craftCost).gt(HunyTokens)) {
      setErrors("Insufficient HUNY")
      return false;
    }

    setErrors("")
    return true
  }, [wallet, formState, geodesOwned, HunyTokens])

  const handleOnClose = () => {
    setCrackGeodesComplete(false);
    setErrors('');
    onClose();
  }

  const onGeodeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    geodeInputHandler(event.target.value);
  };

  const handleAddQtyGeodes = () => {
    geodeInputHandler(formState.geodes.plus(1).toString(10));
  };

  const handleSubtractQtyGeodes = () => {
    if (formState.geodes.isLessThanOrEqualTo(0))
      return;

    geodeInputHandler(formState.geodes.minus(1).toString(10));
  };

  const geodeInputHandler = (geodesInput: string) => {
    const bnGeodes = bnOrZero(geodesInput);

    setFormState({
      geodes: bnGeodes,
      craftCost: bnGeodes.multipliedBy(crackGeodeCost).shiftedBy(-Decimals.HUNY)
    })
  };

  const handleApprove = () => {
    runApprove(async () => {
      await approve();
    })
  }

  const approve = async () => {
    if (!wallet) throw new Error("Wallet not connected");

    const approveAmount = new BigNumber(2).pow(128).minus(1).dp(0);
    const approveTx = await TBMConnector.increaseGemAllowance(wallet, approveAmount, resourceType);
    toaster(`Submitted Approve Increase Allowance `, { hash: approveTx.id! })
    if (approveTx.isRejected() || !approveTx.id) throw new Error("Submitted transaction was rejected.");

    const tx = await waitForTx(approveTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(approveTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    setNeedAllowance(false);
    toaster(`Increase Allowance Success`, { hash: approveTx.id! })
  }

  const handleCrackGeodes = () => {
    runCrackGeodes(async () => {
      await crackingGeodes();
    })
  }

  const crackingGeodes = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!isSubmitEnabled) throw new Error("Requirements are not met");

    const crackGeodesTx = await TBMConnector.refineGeodes(wallet, formState.geodes);
    toaster(`Submitted Crack Geode`, { hash: crackGeodesTx.id! });
    if (crackGeodesTx.isRejected() || !crackGeodesTx.id) throw new Error("Submitted transaction was rejected");

    try {
      const tx = await waitForTx(crackGeodesTx.id);
      const tbmConnector = TBMConnector.getSDK();
      const txn = await tbmConnector.zilliqa.blockchain.getTransaction(crackGeodesTx.id);
      const receipt = txn.getReceipt();
      if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

      //Retrieve refinementId
      let refinementId: string = "";
      receipt!.event_logs.forEach((event) => {
        if (event._eventname === "RefinementStarted") {
          refinementId = event.params.find((param) => param.vname === "refinement_id")?.value;
        }
      });
      if (!refinementId.length) throw new Error("Unable to complete refinement");
      logger("debug-component", "post-geode-item/receipt", {
        receipt,
        refinementId,
      })

      //Await completion
      const events = await TBMConnector.waitForConcludeRefinement(refinementId);
      logger("debug-component", "post-geode-item/waitForConcludeRefinement", {
        events,
      })

      const gemArray = events.map(event => event.params.gems)
      setEmptyGeodes(bnOrZero(formState.geodes).minus(gemArray[0].length))
      parseGems(gemArray[0]);
      setFormState(initialFormState);
      dispatch(actions.Token.updateResources({
        ...resources,
        [MissionGroundResource.Geodes]: bnOrZero(geodesOwned).minus(bnOrZero(formState.geodes).shiftedBy(2))
      }))


      logger("debug-component", "post-geode-item", {
        emptyGeodes: toHumanNumber(emptyGeodes),
        parsedGems: parseGems(gemArray[0]),
        [MissionGroundResource.Geodes]: toHumanNumber(bnOrZero(geodesOwned).minus(bnOrZero(formState.geodes).shiftedBy(2))),
      })

      dispatch(actions.Token.refetchResource());
      setCrackGeodesComplete(true);
      setCrackGeodesTxHash(crackGeodesTx.id);
    } catch (e) {
      console.error(e);
      throw e
    }

    // if (crackGeodesTx?.id) {
    //   setCrackGeodesTxHash(crackGeodesTx.id);
    //   try {
    //     await waitForTx(crackGeodesTx.id);

    //     const tbmConnector = TBMConnector.getSDK();
    //     const txn = await tbmConnector.zilliqa.blockchain.getTransaction(crackGeodesTx.id);
    //     const receipt = txn.getReceipt();
    //     let refinementId: string = "";
    //     receipt!.event_logs.forEach((event) => {
    //       if (event._eventname === "RefinementStarted") {
    //         refinementId = event.params.find((param) => param.vname === "refinement_id")?.value;
    //       }
    //     });
    //     logger("debug-component", "post-geode-item/receipt", receipt)

    //     if (!!refinementId) {
    //       const events = await TBMConnector.waitForConcludeRefinement(refinementId);
    //       const gemArray = events.map(event => event.params.gems)
    //       setEmptyGeodes(bnOrZero(formState.geodes).minus(gemArray[0].length))
    //       parseGems(gemArray[0]);
    //       setFormState(initialFormState);
    //       dispatch(actions.Token.updateResources({
    //         ...resources,
    //         [MissionGroundResource.Geodes]: bnOrZero(geodesOwned).minus(bnOrZero(formState.geodes).shiftedBy(2))
    //       }))


    //       logger("debug-component", "post-geode-item", {
    //         emptyGeodes: toHumanNumber(emptyGeodes),
    //         parsedGems: parseGems(gemArray[0]),
    //         [MissionGroundResource.Geodes]: toHumanNumber(bnOrZero(geodesOwned).minus(bnOrZero(formState.geodes).shiftedBy(2))),
    //       })
    //     }
    //     setCrackGeodesComplete(true);
    //     dispatch(actions.Token.refetchResource());
    //   } catch (e) {
    //     console.error(e);
    //     throw e
    //   }
    // }
  }

  const parseGems = (gems: any[]) => {
    const map: SimpleMap<number> = {}
    for (const gem of gems) {
      const stat = gem?.arguments?.[0];
      if (map[stat]) map[stat] += 1
      else map[stat] = 1
    }
    setGemsReceived(map);
  }

  const getStatEffect = (stat: string) => {
    let statEffect: string = '';
    if (stat === "LUK") {
      statEffect = "+1 " + stat + " = +" + STATS[stat].professionBonus.attrBonus + " " + STATS[stat].professionBonus.attrTitle;
      return statEffect;
    }
    if (stat === "ACC") {
      statEffect = "+1 " + stat + " = " + STATS[stat].professionBonus.attrBonus + "% " + STATS[stat].professionBonus.attrTitle
      return statEffect;
    }
    statEffect = "+1 " + stat + " = +" + STATS[stat].professionBonus.attrBonus + "% " + STATS[stat].professionBonus.attrTitle;
    return statEffect;
  }

  const getHeader = () => {
    if (crackGeodesComplete) {
      return "Geode(s) Cracked Open"
    }
    return "Crack Open Geode";
  }

  const getContent = () => {
    if (crackGeodesComplete) {
      return (
        <Box sx={styles.contentBox}>
          <Typography variant="body1" color="primary" sx={styles.dialogFont}>
            You have successfully cracked open your Geodes, <br />
            and received the following:
          </Typography>
          <Grid container columns={{ xs: 2, sm: 3, md: 4 }} rowSpacing={2} sx={styles.itemContainer}>
            <Grid item xs={1} key={0} sx={{ display: 'block' }}>
              <Box sx={combineStyles(styles.emptyItemBox)}>
                <EmptyGeode />
                {/* Update image with empty geode */}
                <Box sx={styles.gemAmtTag}>
                  <Typography sx={styles.gemAmt} color="primary">
                    {formatIntegerNumber(emptyGeodes)}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="subtitle1" color="primary" sx={styles.gemFont}>Empty</Typography>
            </Grid>
            {Object.entries(gemsReceived).map(([stat, amount]) =>
              <Grid item xs={1} key={stat} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <Box sx={combineStyles(styles.emptyItemBox)}>
                  <Box
                    component="img"
                    width="70%"
                    height="70%"
                    alt={stat}
                    src={GEMS.C[stat].icon}
                  />
                  {/* Update image with gem design / empty geode */}
                  <Box sx={styles.gemAmtTag}>
                    <Typography sx={styles.gemAmt} color="primary">
                      {formatIntegerNumber(amount)}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="subtitle1" color="primary" sx={styles.gemFont}>{GEMS.C[stat].name}</Typography>
                <Typography variant="body1" color="primary" sx={combineStyles(styles.gemFont, styles.gemDescription)}>
                  {getStatEffect(stat)}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Link
            target="_blank"
            href={getExplorerLink("tx", crackGeodesTxHash, network)}
            sx={styles.viewTx}
          >
            View Transaction
            <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
          </Link>
          {/* <ContainedButton
            sx={{ marginTop: '32px' }}
            onClick={handleOnClose}
          >
            EQUIP METAZOA
          </ContainedButton> */}
        </Box >
      )
    }
    return (
      <Box sx={styles.contentBox}>
        <Typography variant="body1" color="primary" sx={styles.dialogFont}>
          Crack Open your Geodes to reveal a Galaxy Gem within!<br />
          There are 7 types of Galaxy Gems in the Zolarverse - it’s up to fate to decide which ones you’ll receive.
        </Typography>
        <Divider sx={styles.divider} />
        <WarningBox sx={styles.warningBox}>
          <Typography variant="body1" color="primary" component="span">
            There is only a 5% change of getting a Galaxy Gem.<br />
            <Link href="https://docs.zolar.io/metazoa/professions-new/gem-enhancement-new" target="_blank">Learn More</Link>
          </Typography>
        </WarningBox>
        <Box sx={styles.row}>
          <Typography variant="subtitle1" color="primary" sx={styles.dialogFont}>Crack Open</Typography>
          <Box width="50%">
            <Box sx={combineStyles(styles.input, styles.numInputBox,
              { ...(errors) && styles.errorInput }
            )}>

              <IconButton disabled={!!isLoading} onClick={handleSubtractQtyGeodes}>
                <RemoveIcon style={{ height: 16, width: 16 }} />
              </IconButton>

              <Box sx={styles.numInput}>
                <TextField
                  id="geode"
                  name="geode"
                  onChange={onGeodeInputChange}
                  disabled={!!isLoading}
                  value={bnOrZero(formState.geodes).toString()}
                  sx={combineStyles(styles.input, styles.settingNumInput, {
                    flex: 0.5,
                    '.Mui-disabled': {
                      color: '#fff',
                      'WebkitTextFillColor': '#fff !important',
                    }
                  }, {
                    ...(formState.geodes.gte(10000)) && ({
                      flex: `calc(0.6 + ${(Number(formState.geodes.toString().length) - 5) / 10})`,
                      "& input": { textAlign: "start" },
                    })
                  })} />
                <GeodeResource width="24px" height="24px" />
              </Box>

              <IconButton disabled={!!isLoading} onClick={handleAddQtyGeodes}>
                <AddIcon style={{ height: 16, width: 16 }} />
              </IconButton>
            </Box>
            {!!errors &&
              <Box sx={styles.errorTextContainer}>
                <WarningRed width={15} />
                <FormHelperText sx={styles.errorText}>{errors}</FormHelperText>
              </Box>}
          </Box>
        </Box>
        <Box sx={combineStyles(styles.row, { justifyContent: 'flex-end', marginTop: '10px' })}>
          <Typography variant="body1" color="primary">Max: {bnOrZero(geodesOwned.shiftedBy(-2)).toString(10)}</Typography>
          <Button variant="outlined" color="secondary" disabled={!!isLoading} sx={styles.button} onClick={() => setFormState({ geodes: bnOrZero(geodesOwned).shiftedBy(-2).dp(0), craftCost: bnOrZero(geodesOwned).shiftedBy(-2).dp(0).multipliedBy(5) })}>
            <Typography sx={combineStyles(styles.gradientText, { ...(!!isLoading) && { opacity: '0.2' } })}>Max</Typography>
          </Button>
        </Box>
        <Divider sx={styles.divider} />
        <Box sx={styles.feeRow}>
          <Typography variant="body1" color="primary" component="span">
            Geode Refining Fee
          </Typography>
          <Typography variant="body1" color="success.main" component="span" fontWeight={600}>
            {formState.craftCost.toFixed(0)}&nbsp; HUNY &nbsp;
            <SvgIcon sx={styles.hunyIcon} component={Huny} viewBox="0 0 116 118" />
          </Typography>
        </Box>
        <Typography variant="body1" color="primary" sx={combineStyles(styles.dialogFont, { marginTop: '10px' })} component="span">
          You will receive&nbsp;
          <Typography color="success.main" sx={styles.dialogFont} component="span">
            {Math.floor(bnOrZero(formState.geodes).toNumber())} or less
          </Typography>
          &nbsp;Galaxy Gem(s).
        </Typography>

        <Box sx={styles.buttonRow}>
          {
            !!needAllowance && <ContainedButton
              disabled={!!loadingApprove}
              sx={styles.submitButton}
              onClick={handleApprove}
            >
              {
                !!loadingApprove ? <CircularProgress size={18} /> : "Approve"
              }
            </ContainedButton>
          }
          <ContainedButton
            disabled={!isSubmitEnabled || !!isLoading || !!loadingAllowance || !!needAllowance}
            sx={styles.submitButton}
            onClick={handleCrackGeodes}
          >
            {
              !!isLoading ? <CircularProgress size={18} /> : "CONFIRM & CRACK OPEN"
            }
          </ContainedButton>
        </Box>
      </Box >
    )
  }

  return (
    <DialogModal header={getHeader()} open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={false} dialogOverwrites={{ overflowY: 'scroll' }}>
      {getContent()}
    </DialogModal>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  dialogFont: {
    textAlign: 'center',
  },
  divider: {
    background: 'rgba(174, 241, 238, 0.1)',
    marginY: '10px',
    width: '100%',
  },
  warningBox: {
    width: '100%'
  },
  row: {
    width: '100%',
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  biggerText: {
    fontSize: '1.3rem',
    fontWeight: 600,
  },
  input: {
    height: "48px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },

    "&:hover, &:active, &:focus, &:focus-within": {
      borderColor: '#AEF1EE',
    },

    "& input": {
      fontSize: "14px",
      lineHeight: "28px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      paddingY: "12px",

      "&:active, &:focus": {
        color: "#AEF1EE",
        "& input": {
          color: "#AEF1EE",
        },
      }
    },
  },
  settingNumInput: {
    background: 'transparent',
    border: 0,
    flex: 0.4,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
    "& input": {
      paddingX: '0',
      width: "100%",
      textAlign: "center",
    },
  },
  numInputBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'space-between',
    padding: '0 5%',
    width: "100%",
    // gap: '24px',
  },
  errorInput: { borderColor: "#F75E5E!important", },
  numInput: {
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    gap: '8px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      minWidth: '50px',
      "svg": {
        display: 'none',
      },
    },
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },
  button: {
    radius: '16px',
    padding: '7px 15px',
    fontSize: '1.125rem',
    marginLeft: '20px',
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },

  // Success
  itemContainer: {
    margin: '40px auto',
    '> *': {
      display: 'flex',

      placeContent: 'center',
      placeItems: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '80%',
      '> *': {
        minWidth: '100px',
      },
    }
  },
  emptyItemBox: {
    position: 'relative',
    backgroundImage: `url(${ItemFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "inherit",

    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    width: '100px',
    height: '100px',
    minWidth: '100px',
    minHeight: '100px',
    // height: '100%',
    margin: '0 auto',
    paddingY: 0,
    borderRadius: '12px',

    "svg": {
      width: '40px',
      height: '40px',
    },
  },
  gemAmt: {
    fontWeight: 600,
    fontSize: '14px',
    padding: '0px 6px'
  },
  gemAmtTag: {
    position: 'absolute',
    bottom: '-7px',
    border: "1px solid transparent",
    background: 'linear-gradient(180deg, #01001E -13.81%, #000010 100%) padding-box, radial-gradient(#F3FFFE, #AEF1EE, #00C2FF) border-box',
    borderRadius: '8px'
  },
  gemFont: {
    width: '90%',
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontWeight: '600px',
    textAlign: 'center',
    marginTop: '10px',
  },
  gemDescription: {
    marginTop: '0px',
    fontWeight: '400px',
  },
  viewTx: {
    marginY: '20px',
  },

  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  buttonRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },

  feeRow: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    mt: '5px',
  },
  hunyIcon: {
    verticalAlign: "top",
  },

  submitButton: {
    marginTop: '16px',
    minWidth: '300px',
    marginLeft: '0px !important'
  }
}

export default CrackGeodesDialog
