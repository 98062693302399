import { Box, CircularProgress, Container, styled, SxProps, Typography } from "@mui/material";
import { PinkShootingStar, YellowShootingStar } from "assets";
import PinkPlanet from "assets/PinkPlanet.png";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProfile } from "saga/selectors";
import { actions } from "store";
import { Guild } from "store/types";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";
import GuildBreadcrumb from "../GuildBreadcrumb";
import { GUILD_GRAY_GRADIENT } from "../GuildConstants";
import FreeUpdates from "./components/FreeUpdates";
import PaidUpdates from "./components/PaidUpdates";

export type UpdateGuildFormInputs = {
  guildName: string;
  description: string;
  guildSigilDesign: number;
  guildSigilColour: string;
  guildBannerDesign: number;
  guildBannerColour: string;
  guildCrestDesign: number;
  guildCrestColour: string;
}

export type Errors = {
  guildName: string;
  description: string;
}

const UpdateGuild: React.FC = () => {
  const { guildId } = useParams();
  const network = useNetwork();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const metazoaProfileState = useSelector(getProfile);
  const { profile } = metazoaProfileState;
  const wallet = useRedux((state) => state.wallet.wallet);
  const guildStore = useRedux((state) => state.guild.allGuilds);

  const [currentGuild, setCurrentGuild] = useState<Guild>();
  const [freeFormInputs, setFreeFormInputs] = useState<Partial<UpdateGuildFormInputs>>({
    description: "",
  });
  const [freeErrors, setFreeErrors] = useState<Partial<Errors>>({
    description: "",
  })

  const [paidFormInputs, setPaidFormInputs] = useState<Partial<UpdateGuildFormInputs>>({
    guildName: "",
    guildSigilDesign: 1,
    guildSigilColour: "grey",
    guildBannerDesign: 1,
    guildBannerColour: "grey",
    guildCrestDesign: 1,
    guildCrestColour: "grey",
  });
  const [paidErrors, setPaidErrors] = useState<Partial<Errors>>({
    guildName: "",
  })
  const [displayImage, setDisplayImage] = useState<string | ArrayBuffer | null>(null);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);

  const resetFormInputs = (guild: Guild) => {
    setFreeFormInputs({
      description: "",
    })
    setPaidFormInputs({
      guildName: "",
      guildSigilDesign: guild.guildSigil.design,
      guildSigilColour: guild.guildSigil.colour,
      guildBannerDesign: guild.guildBanner.design,
      guildBannerColour: guild.guildBanner.colour,
      guildCrestDesign: guild.guildCrest.design,
      guildCrestColour: guild.guildCrest.colour,
    })
    setDisplayImage(guild.crestImageUrl)
  }

  //INFO: Redirect guild members to their respective guild instead of the creation form
  useEffect(() => {
    if (!guildId) {
      navigate('/guilds');
      return;
    }
    // eslint-disable-next-line
  }, [network, guildId])


  // fetch guild data, redirect to home page if not found
  const guild = useMemo(() => {
    if (!guildStore || !guildId) return;
    const currentGuild = guildStore.find(guild => guild.id === parseInt(guildId))
    if (!currentGuild) {
      navigate('/guilds');
      return;
    }
    setCurrentGuild(currentGuild);
    resetFormInputs(currentGuild);
    return currentGuild
    // eslint-disable-next-line
  }, [guildStore, currentGuild])

  // Get current wallet holder's guild membership status
  const walletHolder = useMemo(() => {
    if (!guild || !profile) return;
    const addr = wallet?.addressInfo.byte20!.toLocaleLowerCase()!
    const isOfficer: boolean = guild.commanderAddresses.includes(addr)
    const isLeader: boolean = guild.leaderAddress === addr
    const isMember: boolean = profile.guildId === guild.id
    const hasGuild: boolean = profile.guildId ? true : false

    const canUpdate: boolean = isLeader || isOfficer;

    return { addr, hasGuild, isOfficer, isLeader, isMember, canUpdate }
  }, [wallet, guild, profile])


  const isFreeUpdateEnabled = useMemo(() => {
    // errors not empty
    if (Object.values(freeErrors).some(err => !!err))
      return false;

    // compulsory fields
    if (Object.values(freeFormInputs).some(input => !input))
      return false;

    return true;
  }, [freeErrors, freeFormInputs])

  const isPaidUpdateEnabled = useMemo(() => {
    // errors not empty
    if (Object.values(paidErrors).some(err => !!err))
      return false;

    // compulsory fields
    if (Object.values(paidFormInputs).some(input => !input))
      return false;

    // no image uploaded and none displayed
    if (!uploadedImage && !displayImage)
      return false;

    return true;
  }, [paidErrors, uploadedImage, displayImage, paidFormInputs])

  const handleBack = () => {
    if (!guild) return;
    navigate(`/guilds/${guildId}`);
    dispatch(actions.Guild.reloadGuild(guild));
    return;
  }

  const breadcrumbs = [
    {
      path: `/guilds`,
      value: "Guild Overview",
    },
    {
      path: `/guilds/${guildId}`,
      value: guild?.name!!,
    },
    {
      path: `/guilds/${guildId}/update`,
      value: "Guild Settings",
    },
  ];


  if (!walletHolder?.canUpdate) {
    navigate('/guilds');
    return null;
  }
  return (
    <>
      {/* //TODO: Add orange shooting */}
      <YellowShootingBox>
        <YellowShootingStar />
      </YellowShootingBox>
      <PinkShootingBox>
        <PinkShootingStar width="100%" />
      </PinkShootingBox>
      <PinkPlanetBox>
        <img src={PinkPlanet} alt="pink-planet" width="100%" />
      </PinkPlanetBox>
      <Container sx={styles.root}>
        {guild && (<GuildBreadcrumb linkPath={breadcrumbs} />)}
        <Typography variant="h2" color="primary" sx={GUILD_GRAY_GRADIENT}>Guild Settings</Typography>
        {!wallet
          ? <Box display="flex" flexDirection="column" mt="60px" width="70%">
            <Typography variant="body1" color="primary">
              Please connect your wallet to view this page.
            </Typography>
            <ConnectWalletButtonCTA sx={styles.connectButton} />
          </Box>

          : !guild
            ? (
              <Box display="flex" alignItems="center" justifyContent="center" mt="80px">
                <CircularProgress size={36} />
              </Box>
            )
            :
            (<>
              <FreeUpdates
                guild={guild}
                formInputs={freeFormInputs}
                setFormInputs={setFreeFormInputs}
                errors={freeErrors}
                setErrors={setFreeErrors}
                isCreateEnabled={isFreeUpdateEnabled}
                handleBack={handleBack}
              />
              <PaidUpdates
                guild={guild}
                formInputs={paidFormInputs}
                setFormInputs={setPaidFormInputs}
                errors={paidErrors}
                setErrors={setPaidErrors}
                displayImage={displayImage}
                setDisplayImage={setDisplayImage}
                uploadedImage={uploadedImage}
                setUploadedImage={setUploadedImage}
                isCreateEnabled={isPaidUpdateEnabled}
                handleBack={handleBack}
              />
            </>)
        }
      </Container>
    </>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  root: {
    marginTop: "160px",
    paddingLeft: "24px",
    paddingRight: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'start',
    placeItems: 'center',
    gap: "24px",

    "> *": {
      width: '70%',
    },
  },
  connectButton: {
    "& #wrapperBox": {
      "@media (max-width:900px)": {
        width: "100%",
        display: "initial",
      },
    },
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .MuiButton-root": {
      height: 64,
      minWidth: 300,
      marginTop: "20px",
      "@media (max-width:600px)": {
        width: "100%",
        minWidth: "",
      },
    }
  },
}


const YellowShootingBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  bottom: "1000px",
  right: "150px",

  "@media (max-width:986px)": {
    display: "none",
  },
});

const PinkPlanetBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  width: '109.79px',
  height: '110px',
  right: '80px',
  top: '208px',
  zIndex: 0,
  "@media (max-width:600px)": {
    top: "80px",
    left: "300px",
    width: '50.79px',
  },
});

const PinkShootingBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  width: '140.85px',
  height: '94.34px',
  right: '80px',
  top: '948px',
  zIndex: 0,
  "@media (max-width:600px)": {
    display: 'none',
  },
});

export default UpdateGuild;
