import { BN, bytes, Long } from '@zilliqa-js/util';
import { fromBech32Address, toBech32Address } from "@zilliqa-js/zilliqa";
import BigNumber from "bignumber.js";
import { STATS } from 'components/Metazoa/MetazoaCollection/MetazoaConstants';
import { Craftables, GemTierType, ZOrdnanceClasses } from 'components/Metazoa/ResourceConstants';
import { RESOURCES, StoreItemType } from 'components/Quest/QuestConstants';
import { logger } from 'core/utilities';
import { MetazoaClient } from 'core/utilities/metazoa';
import queryString from "query-string";
import { ZOMGCraftItem, ZOMGResourceItem } from 'store/zomg/types';
import { batchQuery, SimpleRPCRequest } from 'utils';
import { getCachedItem, offloadCachedItems, putCachedItem } from 'utils/localStorageCache';
import { Zilswap } from "zilswap-sdk";
import { Network, ZIL_HASH } from "zilswap-sdk/lib/constants";
import { contractInitToMap } from "zilswap-sdk/lib/utils";
import { ConnectedWallet } from "../core/wallet/ConnectedWallet";
import { GameStats, GuildBankInfo, GuildBankSetting, HiveInfo, HivePoolStats, MoonBattleInfo, NftMetadata, OwnedOrdnance, Quest, RefinementFees, RPCSuccessResult, TaxToCollect, UpdatedResources, UpdatedTokens } from "../store/types";
import { BIG_ONE, BIG_ZERO, CHAIN_IDS, ContractsBech32, Decimals, LocalStorageKeys, MAX_ZOA, METADATA_VERSION, MetazoaBaseUri, MissionGroundResource, MSG_VERSION } from "../utils/constants";
import { bnOrZero, parseBN } from "../utils/strings/strings";
import { SimpleMap } from "../utils/types";
import { ZolarEventSubscriber } from './events';
let zilswap: Zilswap | null = null;

export class TBMConnector {
  private static zolarEventSubscription: any;
  private static actionListeners: SimpleMap<ZolarEventSubscriber.ZolarEventHandler> = {};
  private static refinementListeners: SimpleMap<ZolarEventSubscriber.ZolarEventHandler> = {};

  static initialized() {
    return !!zilswap;
  }

  static network() {
    return zilswap?.network;
  }

  static setSDK = (sdk: Zilswap | null) => {
    zilswap = sdk
  }

  static getSDK = (): Zilswap => {
    if (!zilswap) throw new Error('not initialized');

    return zilswap
  }

  static getCurrentBlock = () => {
    if (!zilswap) throw new Error('not initialized');
    return zilswap.getCurrentBlock()
  }

  static getSupply = async () => {
    if (!zilswap) throw new Error('not initialized');
    const nftContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].NftV2);
    const totalState = await nftContract.getSubState("total_supply");
    const totalSupply = bnOrZero(totalState.total_supply).toNumber();

    return [totalSupply];
  }

  // TODO: refactor these - seperate function not needed
  // nft v1
  static getOwnedTokens = async (wallet: ConnectedWallet): Promise<string | unknown[]> => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const nftContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Nft);
    const result = await nftContract.getSubState("token_owners");

    const ownedTokenIds = Object.entries(result.token_owners).filter(
      ([tokenId, owner]) => owner === wallet.addressInfo.byte20.toLowerCase()
    );

    return ownedTokenIds.map((entry) => entry[0])
  }

  // nft v2
  static getTranscendenceStartBlock2 = async (wallet: ConnectedWallet): Promise<number> => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const nftContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].TranscendenceMinter);
    const result = await nftContract.getSubState("mint_start_block");

    return parseInt(result.mint_start_block ?? "0")
  }

  static getTranscendenceStartBlock = async () => {
    if (!zilswap) throw new Error('not initialized');
    const nftContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].TranscendenceMinter);
    const result = await nftContract.getSubState("mint_start_block");

    return parseInt(result.mint_start_block ?? "0")
  }

  static getOwnedMetazoa = async (wallet: ConnectedWallet): Promise<string | unknown[]> => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const address = wallet.addressInfo.byte20.toLowerCase();
    const nftContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].NftV2);
    const result = await nftContract.getSubState("token_owners");
    const ownedMetazoaIds = Object.entries(result.token_owners).filter(
      ([tokenId, owner]) => owner === address
    );

    return ownedMetazoaIds.map((entry) => entry[0])
  }

  static getGuildOwnedMetazoa = async (guildMembers: string[]): Promise<string | unknown[]> => {
    if (!zilswap) throw new Error('not initialized');
    const guildOwnedMetazoa = this.getGuildOwnedNft(guildMembers, ContractsBech32[zilswap.network].NftV2);
    return guildOwnedMetazoa;
  }

  static getGuildOwnedBear = async (guildMembers: string[]): Promise<string | unknown[]> => {
    if (!zilswap) throw new Error('not initialized');
    const guildOwnedBear = this.getGuildOwnedNft(guildMembers, ContractsBech32[zilswap.network].Nft);
    return guildOwnedBear;
  }

  static getGuildMemberOwnedMetazoa = async (guildMembers: string[]): Promise<SimpleMap<string[]>> => {
    if (!zilswap) throw new Error('not initialized');

    const nftContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].NftV2);
    const result = await nftContract.getSubState("token_owners");
    const guildOwnedMetazoaIds: SimpleMap<string[]> = {};

    // Filter with every guild member address
    for (let n = 0; n < guildMembers.length; n++) {
      const memberOwnedMetazoa = Object.entries(result.token_owners).filter(
        ([tokenId, owner]) => owner === guildMembers[n]
      );
      guildOwnedMetazoaIds[guildMembers[n]] = memberOwnedMetazoa.map((entry) => entry[0]);
    }
    return guildOwnedMetazoaIds;
  }

  private static mapMetazoaCommanders = (zoaIdMap: SimpleMap<string>): SimpleMap<string[]> => {
    return Object.entries(zoaIdMap ?? {}).reduce((accum, next) => {
      const tokenId = next[0] as string;
      const owner = next[1] as string;
      accum[owner] = (accum[owner] ?? []).concat(tokenId);
      return accum;
    }, {} as SimpleMap<string[]>);
  }

  /**
   * @description Filter object by key
  **/
  private static mapAddressFilter = (addresses: string[], map: SimpleMap<any>): SimpleMap<any> =>
    Object.keys(map)
      .filter(key => addresses.includes(key))
      .reduce((obj, key) => {
        obj[key] = map[key];
        return obj;
      }, {} as SimpleMap<any>);

  static getGuildMetazoas = async (membersAddresses: string[]): Promise<SimpleMap<SimpleMap<string[]>>> => {
    if (!zilswap) throw new Error('not initialized');

    const getAddressHex = (bech32) => fromBech32Address(bech32).toLowerCase().replace(/^0x/i, "")

    const queries: SimpleRPCRequest[] = [
      { substate: "token_owners", address: getAddressHex(ContractsBech32[zilswap.network].NftV2), resultAs: "token_owners" },
      { substate: "metazoa_commanders", address: getAddressHex(ContractsBech32[zilswap.network].MoonBattle), resultAs: "moonbattleStaked" },
      { substate: "metazoa_commanders", address: getAddressHex(ContractsBech32[zilswap.network].QuestBerry), resultAs: "berryStaked" },
      { substate: "metazoa_commanders", address: getAddressHex(ContractsBech32[zilswap.network].QuestGeode), resultAs: "geodeStaked" },
      { substate: "metazoa_commanders", address: getAddressHex(ContractsBech32[zilswap.network].QuestScrap), resultAs: "scrapStaked" },
    ];

    const results: RPCSuccessResult = await batchQuery(queries, (zilswap as any).rpcEndpoint);

    const unstakedMetazoas: SimpleMap<string> = results?.token_owners ?? {};
    const stakedMetazoas: SimpleMap<string> = { ...results?.moonbattleStaked, ...results?.berryStaked, ...results?.geodeStaked, ...results?.scrapStaked } ?? {};

    const unstakedMetazoaCommanders: SimpleMap<string[]> = this.mapAddressFilter(membersAddresses, this.mapMetazoaCommanders(unstakedMetazoas));
    const stakedMetazoaCommanders: SimpleMap<string[]> = this.mapAddressFilter(membersAddresses, this.mapMetazoaCommanders(stakedMetazoas));

    const stakedMetazoaIds: string[] = Object.values(stakedMetazoaCommanders).flat() ?? [];
    const unstakedMetazoaIds: string[] = Object.values(unstakedMetazoaCommanders).flat() ?? [];

    return {
      unstakedMetazoaCommanders,
      stakedMetazoaCommanders,
      tokenIds: {
        stakedMetazoaIds,
        unstakedMetazoaIds,
      },
    }
  }

  // Force update cache
  static reloadMetazoaMetadata = async (tokenIds: string[]) => {
    if (!zilswap) throw new Error('not initialized');
    const tokenList: NftMetadata[] = await this.getMetazoaMetadata(tokenIds);

    // Cache all
    tokenList.forEach((token) => {
      const cacheKey = LocalStorageKeys.MetazoaMetadata + `:${token.id}`;
      putCachedItem(cacheKey, token, METADATA_VERSION);
    })
  }

  static getOwnedMetazoaMetadata = async (tokenIds: string[]): Promise<NftMetadata[]> => {
    if (!zilswap) throw new Error('not initialized');

    // Clear deprecated cache on localStorage
    offloadCachedItems(LocalStorageKeys.MetazoaMetadata, METADATA_VERSION)

    const tokenList: NftMetadata[] = [];
    if (!tokenIds.length) return tokenList;

    for (const tokenId of tokenIds) {
      const cacheKey = LocalStorageKeys.MetazoaMetadata + `:${tokenId}`;
      const cachedMetadata = getCachedItem<NftMetadata>(cacheKey);
      if ((cachedMetadata?.version === METADATA_VERSION) && !!cachedMetadata.value.hash) {
        tokenList.push(cachedMetadata.value);
      } else {
        const response = await fetch(MetazoaBaseUri[zilswap.network] + tokenId);
        const metadata: NftMetadata = await response.json();
        putCachedItem(cacheKey, metadata, METADATA_VERSION);
        tokenList.push(metadata);
      }
    }

    return tokenList;
  }

  static getMetazoaMetadata = async (tokenIds: string[]): Promise<NftMetadata[]> => {
    if (!zilswap) throw new Error('not initialized');
    const tokenList: NftMetadata[] = [];
    for (const tokenId of tokenIds) {
      const response = await fetch(MetazoaBaseUri[zilswap.network] + tokenId);
      const metadata: NftMetadata = await response.json();
      tokenList.push(metadata);
    }
    return tokenList
  }

  static getStatsAndProfession = async (tokens: NftMetadata[], tokenIds: string[]) => {
    if (!zilswap) throw new Error('not initialized');
    const metazoaClient = new MetazoaClient(zilswap.network);
    const batchGenerator = (ids: Array<string | number>): Array<number>[] => {
      const allIds: number[] = ids.map(Number);

      const batches: Array<number>[] = [];
      const batchMax = 20;

      for (let i = 0; i < allIds.length; i += batchMax) {
        const chunk: number[] = allIds.slice(i, i + batchMax);
        batches.push(chunk)
      }
      return batches
    }

    const statsProfessionData = await Promise.all((batchGenerator(tokenIds)).map(batchIds => (metazoaClient.bulkMetazoaMetadata(batchIds))))
      .then((data) => data.reduce((accum, curr) => accum.concat(curr?.result?.entries ?? []), []));

    for (let i = 0; i < statsProfessionData.length; i++) {
      const statAndProfession = statsProfessionData[i];
      const emptyToken = tokens.find((token) => token.id === statAndProfession.metazoa.tokenId.toString())

      if (!!emptyToken) {
        logger("emptyToken", emptyToken)
        emptyToken.profession = statAndProfession.metazoa.profession;
        emptyToken.exp = statAndProfession.metazoa.level;
        emptyToken.stats = statAndProfession.stats;

        emptyToken.bonuses = statAndProfession.bonus;
        if (!!emptyToken?.profession && !!Object.keys(statAndProfession.metazoa?.masteries ?? {}).length) {
          emptyToken.masteryExp = statAndProfession.metazoa?.masteries?.[emptyToken.profession];
          logger("emptyToken/profession", emptyToken, statAndProfession, {
            mastery: emptyToken.masteryExp,
            masteries: statAndProfession.metazoa?.masteries ?? {},
          })
        }

        //TASK
        emptyToken.equipments = statAndProfession.equipment.map(e => ({
          id: e.token_id,
          ownerAddress: e.ownerAddress,
          address: e.collection_address,
          parentAddress: e.itemParentAddress,
          zoaId: e.itemParentTokenId,
          tokenTraits: e.tokenTraits.reduce((traits: SimpleMap, trait) => {
            if (trait.constructor === "Pair") traits[trait.arguments[0]] = trait.arguments[1];
            return traits;
          }, {}),
        })) ?? [];

        if (!!statAndProfession.equipment.length) {
          logger("debug-connector", "TBMConnector/statsProfessionData/Equipment", emptyToken.id, {
            equipments: emptyToken.equipments
          })
        }

        const result = await metazoaClient.fetchMetazoaProfession(emptyToken);
        emptyToken.unassignedStatPoints = (result?.result?.metazoa?.attributes?.unassigned ?? 0) as number;
      }
      logger("debug-connector", "TBMConnector/statsProfessionData", {
        emptyToken,
        statAndProfession,
        statsProfessionData,
      })

    }
    return tokens;
  }

  static getOwnedTokensImage = async (tokenIds: string[]) => {
    if (!zilswap) throw new Error('not initialized');

    const tokenList: NftMetadata[] = [];

    for (const tokenId of tokenIds) {
      const cacheKey = LocalStorageKeys.TheBearMarketMetadata + `:${zilswap.network}:${tokenId}`;
      const cachedMetadata = getCachedItem<NftMetadata>(cacheKey);
      const metadataRoute = MetazoaBaseUri[zilswap.network] + tokenId

      if (cachedMetadata?.version === METADATA_VERSION) {
        tokenList.push(cachedMetadata.value);
      } else {
        const response = await fetch(metadataRoute);
        const metadata: NftMetadata = await response.json();
        putCachedItem(cacheKey, metadata, METADATA_VERSION);
        tokenList.push(metadata);
      }
    }

    return tokenList;
  }

  static getHunyPots = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const refineryContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Refinery);
    const walletAddr = wallet.addressInfo.byte20.toLowerCase();

    const result = await refineryContract.getSubState("refining", [walletAddr]);
    return result?.refining?.[walletAddr] as SimpleMap<string>;
  }

  static getRefineryHunyStats = async (wallet?: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');

    const refineryContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Refinery);

    const totalShareRes = await refineryContract.getSubState("total_share");
    const totalShare = parseBN(totalShareRes?.total_share);
    if (!totalShare) {
      console.warn("refinery contract address might be invalid", refineryContract.address, zilswap.network);
    }

    const totalSupplyRes = await refineryContract.getSubState("total_supply");
    const totalSupply = parseBN(totalSupplyRes?.total_supply);
    if (!totalSupply) {
      console.warn("refinery contract address might be invalid", refineryContract.address, zilswap.network);
    }

    let lastRefinedBlock: number | undefined = undefined
    const moonBattleAddress = fromBech32Address(ContractsBech32[zilswap.network].MoonBattle).toLowerCase();
    const userAddress = wallet?.addressInfo?.byte20?.toLowerCase();
    if (userAddress) {
      const response = await refineryContract.getSubState("last_refined", [moonBattleAddress, userAddress]);
      const lastRefinedBlockRes = bnOrZero(response?.last_refined?.[moonBattleAddress]?.[userAddress]);

      if (lastRefinedBlockRes.gt(0))
        lastRefinedBlock = lastRefinedBlockRes.toNumber();
    }

    return { totalShare, totalSupply, lastRefinedBlock };
  }

  static getRefineryConfigs = async () => {
    if (!zilswap) throw new Error('not initialized');

    const refineryContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Refinery);

    const refineDurationRes = await refineryContract.getSubState("refine_duration");
    const refineDuration = parseBN(refineDurationRes?.refine_duration);
    if (!refineDuration) {
      console.warn("refinery contract address might be invalid", refineryContract.address, zilswap.network);
    }

    const immediateRefineRes = await refineryContract.getSubState("immediate_refinement");
    const immediateRefine = parseBN(immediateRefineRes?.immediate_refinement)?.shiftedBy(-2);
    if (!immediateRefine) {
      console.warn("refinery contract address might be invalid", refineryContract.address, zilswap.network);
    }

    return { refineDuration, immediateRefine }
  }

  static getMintedTokensCount = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const address = wallet.addressInfo.byte20.toLowerCase();
    const nftContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].TranscendenceMinter);
    const result = await nftContract.getSubState("minted");

    return parseInt(result.minted[address] ?? "0");
  }

  static getWhitelistCount = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const address = wallet.addressInfo.byte20.toLowerCase();
    const nftContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].TranscendenceMinter);
    const result = await nftContract.getSubState("whitelist");

    return parseInt(result.whitelist[address] ?? "0");
  }

  static checkTranscendenceApproved = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const tbmAddress = fromBech32Address(ContractsBech32[zilswap.network].Nft).toLowerCase();
    const transcendenceMinterAddress = fromBech32Address(ContractsBech32[zilswap.network].TranscendenceMinter).toLowerCase();
    const walletAddress = wallet.addressInfo.byte20.toLowerCase();

    const response = await zilswap.zilliqa.blockchain.getSmartContractSubState(tbmAddress, "operator_approvals");

    const approved = response.result.operator_approvals[walletAddress]?.[transcendenceMinterAddress];

    return !!approved;
  }

  static checkMoonBattleApprovedTbm = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const tbmAddress = fromBech32Address(ContractsBech32[zilswap.network].Nft).toLowerCase();
    const moonBattleAddress = fromBech32Address(ContractsBech32[zilswap.network].MoonBattle).toLowerCase();
    const walletAddress = wallet.addressInfo.byte20.toLowerCase();

    const response = await zilswap.zilliqa.blockchain.getSmartContractSubState(tbmAddress, "operator_approvals");

    const approved = response.result.operator_approvals[walletAddress]?.[moonBattleAddress];

    return !!approved;
  }

  static approveAllowanceIfRequired = async (tokenAddress: string, ownerAddress: string, operatorAddress: string) => {
    if (!zilswap) throw new Error('not initialized');
    const response = await zilswap.zilliqa.blockchain.getSmartContractSubState(tokenAddress, "operator_approvals");

    const approved = response.result.operator_approvals[ownerAddress.toLowerCase()]?.[operatorAddress.toLowerCase()];

    if (!!approved) return null;

    return await this.approveAllowance(tokenAddress, operatorAddress);
  }

  static approveAllowance = async (tokenAddress: string, operatorAddress: string) => {
    if (!zilswap) throw new Error('not initialized');
    const args = [{
      vname: "to",
      type: "ByStr20",
      value: operatorAddress,
    }];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(tokenAddress),
      "SetApprovalForAll",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static transcend = async (tokenIds: string[], ownerAddress: string, transcendenceMinterAddress: string) => {
    if (!zilswap) throw new Error('not initialized');

    const args = [{
      vname: "to",
      type: "ByStr20",
      value: ownerAddress,
    }, {
      vname: "token_ids",
      type: "List Uint256",
      value: tokenIds,
    }];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(transcendenceMinterAddress),
      "Transcend",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  // Phase 3
  static getGoldRushSalePrice = async () => {
    if (!zilswap) throw new Error('not initialized');
    const minterContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].CommunityMinter);
    const init = await minterContract.getInit();
    const mappedInit = contractInitToMap(init);
    const price = bnOrZero(mappedInit["nft_price"]).shiftedBy(-12).toNumber();

    return price;
  }

  static getGoldRushSupply = async () => {
    if (!zilswap) throw new Error('not initialized');
    const minterContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].CommunityMinter);
    const init = await minterContract.getInit();
    const mappedInit = contractInitToMap(init);
    const totalSupply = bnOrZero(mappedInit["max_supply"]).toNumber();

    const totalState = await minterContract.getSubState("total_supply");
    const currentSupply = bnOrZero(totalState.total_supply).toNumber();

    return [totalSupply, currentSupply];
  }

  static checkGoldRushSaleActive = async () => {
    if (!zilswap) throw new Error('not initialized');
    const minterContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].CommunityMinter);
    const status = await minterContract.getSubState("sale_active");

    return status.sale_active.constructor === "True";
  }

  static fetchGasFee = async () => {
    if (!zilswap) throw new Error('not initialized');
    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const gasFeeHuman = bnOrZero(parseInt(minGasPrice)).shiftedBy(-12).toNumber();
    return gasFeeHuman;
  }

  static fetchPrices = async () => {
    const params = {
      ids: "zilliqa",
      vs_currencies: "usd",
    };
    const query = queryString.stringify(params);
    const url = `https://api.coingecko.com/api/v3/simple/price?${query}`;
    const response = await fetch(url);
    const result = await response.json();

    return {
      zilliqa: new BigNumber(result?.zilliqa?.usd ?? "0.112171"),
    };
  }

  static fetchExchangeRate = async () => {
    const zilswap = this.getSDK();

    const hunyContract = ContractsBech32[zilswap.network].HunyToken;
    const rateHunyZil = await zilswap.getRatesForInput(hunyContract, ZIL_HASH, BIG_ONE.shiftedBy(Decimals.HUNY).toString(10));

    const prices = await this.fetchPrices();

    const zilliqa = prices.zilliqa;
    const huny = zilliqa.times(rateHunyZil.expectedAmount.shiftedBy(-Decimals.ZIL));
    return { zilliqa, huny };
  }

  static summon = async (wallet: ConnectedWallet, summonQty: number, summonPrice: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const communityMinterAddress = fromBech32Address(ContractsBech32[zilswap.network].CommunityMinter).toLowerCase();

    const args = [
      {
        vname: "quantity",
        type: "Uint32",
        value: `${summonQty}`,
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const callParams = {
      amount: new BN(summonPrice.times(summonQty).toString(10)),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(communityMinterAddress),
      "MintForCommunity",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  // Phase 4
  static getTakersBurntCount = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const address = wallet.addressInfo.byte20.toLowerCase();
    const nftContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].MoonBattle);
    const result = await nftContract.getSubState("burnt");

    return result?.burnt?.[address] as SimpleMap<string>;
  }

  static getMoonBattleInfo = async (wallet: ConnectedWallet | null): Promise<MoonBattleInfo> => {
    if (!zilswap) throw new Error('not initialized');

    const address = wallet ? wallet?.addressInfo?.byte20.toLowerCase() : '';
    const contract = fromBech32Address(ContractsBech32[zilswap.network].MoonBattle).toLowerCase().replace(/^0x/i, "");

    const substates = [
      "summon_count",
      "whitelist",
      "berserking_minos",
      "captured_huny_per_share",
      "captured_huny_debt",
    ];

    const results = await batchQuery(
      substates.map(substate => ({ address: contract, substate })),
      (zilswap as any).rpcEndpoint,
    );

    return {
      summonCount: bnOrZero(results.summon_count).toNumber(),
      whitelistCount: parseInt(results.whitelist[address] ?? "0"),
      totalShares: results.berserking_minos.length || 1,
      capturedHunyPerShare: bnOrZero(results.captured_huny_per_share),
      capturedHunyDebt: results.captured_huny_debt as SimpleMap<string>,
    };
  }

  static summonReinforcements = async (wallet: ConnectedWallet, summonQty: number, burnTokenIds: string[]) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    if (summonQty > MAX_ZOA.SummonReinforcements) {
      throw new Error(`Please select max ${MAX_ZOA.SummonReinforcements} only`);
    }

    const moonBattleAddress = fromBech32Address(ContractsBech32[zilswap.network].MoonBattle).toLowerCase();

    const args = [
      {
        vname: "count",
        type: "Uint32",
        value: `${summonQty}`,
      },
      {
        vname: "burn_token_ids",
        type: "List Uint256",
        value: burnTokenIds,
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(moonBattleAddress),
      "SummonReinforcements",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static claimRefinery = async (wallet: ConnectedWallet, claimBlock: number) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const refineryAddress = fromBech32Address(ContractsBech32[zilswap.network].Refinery).toLowerCase();

    const args = [
      {
        vname: "claim_block",
        type: "BNum",
        value: `${claimBlock}`,
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(refineryAddress),
      "Claim",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  // Game
  static enterBattle = async (wallet: ConnectedWallet, tokenIds: string[]) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    if (tokenIds.length > MAX_ZOA.EnterBattle) {
      throw new Error(`Please select max ${MAX_ZOA.EnterBattle} only`);
    }

    const moonBattleAddress = fromBech32Address(ContractsBech32[zilswap.network].MoonBattle).toLowerCase();

    const args = [
      {
        vname: "token_ids",
        type: "List Uint256",
        value: tokenIds,
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(moonBattleAddress),
      "EnterBattle",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static checkMoonBattleApproved = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const metazoaAddress = fromBech32Address(ContractsBech32[zilswap.network].NftV2).toLowerCase();
    const moonBattleAddress = fromBech32Address(ContractsBech32[zilswap.network].MoonBattle).toLowerCase();
    const walletAddress = wallet.addressInfo.byte20.toLowerCase();

    const response = await zilswap.zilliqa.blockchain.getSmartContractSubState(metazoaAddress, "operators");

    const approved = response.result.operators[walletAddress]?.[moonBattleAddress];

    return !!approved;
  }

  static addMoonBattleOperator = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const metazoaAddress = fromBech32Address(ContractsBech32[zilswap.network].NftV2).toLowerCase();
    const moonBattleAddress = fromBech32Address(ContractsBech32[zilswap.network].MoonBattle).toLowerCase();

    const args = [{
      vname: "operator",
      type: "ByStr20",
      value: moonBattleAddress,
    }];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(metazoaAddress),
      "AddOperator",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static getTrappedMetazoa = async () => {
    if (!zilswap) throw new Error('not initialized');

    const lostPlanetAddress = ContractsBech32[zilswap.network].LostPlanet;
    if (lostPlanetAddress === "")
      return {};

    const moonBattleContract = zilswap.zilliqa.contracts.atBech32(lostPlanetAddress);
    const result = await moonBattleContract.getSubState("metazoa_commanders");

    if (!result) {
      console.warn("lost planet contract address might be invalid", lostPlanetAddress, zilswap.network);
    }

    const ids = this.processMetazoaCommanders(result);

    return ids;
  }

  static getStakedMetazoa = async () => {
    if (!zilswap) throw new Error('not initialized');

    const moonBattleAddress = ContractsBech32[zilswap.network].MoonBattle;
    if (moonBattleAddress === "")
      return {};

    const moonBattleContract = zilswap.zilliqa.contracts.atBech32(moonBattleAddress);
    const result = await moonBattleContract.getSubState("metazoa_commanders");

    if (!result) {
      console.warn("moon battle contract address might be invalid", moonBattleAddress, zilswap.network);
    }

    const stakedMetazoaIds = this.processMetazoaCommanders(result);

    return stakedMetazoaIds;
  }

  static getLastMetazoaId = async () => {
    if (!zilswap) throw new Error('not initialized');

    const metazoaAddress = ContractsBech32[zilswap.network].NftV2;

    const metazoaContract = zilswap.zilliqa.contracts.atBech32(metazoaAddress);
    const result = await metazoaContract.getSubState("token_id_count");

    if (!result) {
      console.warn("moon battle contract address might be invalid", metazoaAddress, zilswap.network)
    }

    const id = bnOrZero(result.token_id_count).toNumber();
    return id === 0 ? undefined : id;
  }

  static getTokenTraits = async () => {
    if (!zilswap) throw new Error('not initialized');

    const metazoaAddress = ContractsBech32[zilswap.network].NftV2;
    const contract = zilswap.zilliqa.contracts.atBech32(metazoaAddress);
    const result = await contract.getSubState("traits");

    if (!result) {
      console.warn("metazoa contract address might be invalid", metazoaAddress, zilswap.network);
    }

    const tokenTraits = Object.entries(result?.traits ?? {}).reduce((accum, next) => {
      const tokenId = next[0] as string;
      const traits = next[1] as any;
      accum[tokenId] = traits.reduce((result: SimpleMap, trait: any) => {
        const traitType = trait.arguments[0] as string;
        const traitValue = trait.arguments[1] as string;
        result[traitType] = traitValue;
        return result;
      }, {} as SimpleMap);
      return accum;
    }, {} as SimpleMap<SimpleMap>);

    return tokenTraits;
  }

  static returnToBase = async (wallet: ConnectedWallet, tokenIds: string[], legacyContract = false) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    if (tokenIds.length > MAX_ZOA.ReturnToBase) {
      throw new Error(`Please select max ${MAX_ZOA.ReturnToBase} only`);
    }

    const contract = legacyContract ? ContractsBech32[zilswap.network].LostPlanet : ContractsBech32[zilswap.network].MoonBattle
    const contractAddress = fromBech32Address(contract).toLowerCase();

    const args = [
      {
        vname: "token_ids",
        type: "List Uint256",
        value: tokenIds,
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(contractAddress),
      "ReturnToBase",
      args as any,
      callParams,
      true,
    );

    return result;
  }


  static returnToQuestBase = async (wallet: ConnectedWallet, tokenIds: string[], questLocation: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');
    if (!tokenIds.length) throw new Error('no tokens selected');

    const questBerryAddress = ContractsBech32[zilswap.network].QuestBerry.toLowerCase();
    const questScrapAddress = ContractsBech32[zilswap.network].QuestScrap.toLowerCase();
    const questGeodeAddress = ContractsBech32[zilswap.network].QuestGeode.toLowerCase();

    let questAddress = ""
    switch (questLocation) {
      case "Elder Woodlands":
        questAddress = questBerryAddress
        break;

      case "Zolar Asteroid Belt":
        questAddress = questGeodeAddress
        break;
      case "Moon Battlegrounds":
        questAddress = questScrapAddress
        break;
    }

    const args = [
      {
        vname: "token_ids",
        type: "List Uint256",
        value: tokenIds,
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(questAddress),
      "ReturnToBase",
      args as any,
      callParams,
      true,
    );

    return result;

  }

  static registerActionListener = (actionId: string, handler: ZolarEventSubscriber.ZolarEventHandler) => {
    if (!this.zolarEventSubscription) {
      this.zolarEventSubscription = ZolarEventSubscriber.subscribe((txEvents) => {
        // check if action event
        const actionConcludedEvent = txEvents.find(event => event.type === ZolarEventSubscriber.ZolarEventType.ActionConcluded);
        // notify and remove action listener
        // with the same action id
        if (actionConcludedEvent) {
          const actionId = actionConcludedEvent.params.action_id;

          if (this.actionListeners[actionId]) {
            this.actionListeners[actionId](txEvents);
            delete this.actionListeners[actionId];
          }
        }
      });
    }

    this.actionListeners[actionId] = handler;
  }

  static deregisterActionListener = (actionId: string) => {
    delete this.actionListeners[actionId];
  }

  static waitForConcludeAction = async (actionId: string, maxDuration = 15 * 60000) => {
    return new Promise<ZolarEventSubscriber.ZolarEvent[]>((resolve, reject) => {
      // set timeout to throw error at max duration
      const timeout = setTimeout(() => {
        this.deregisterActionListener(actionId);
        reject("conclude action timeout");
      }, maxDuration);

      this.registerActionListener(actionId, (events: ZolarEventSubscriber.ZolarEvent[]) => {
        clearTimeout(timeout);
        resolve(events);
      });
    })
  }

  static registerRefinementListener = (refinementId: string, handler: ZolarEventSubscriber.ZolarEventHandler) => {
    if (!this.zolarEventSubscription) {
      this.zolarEventSubscription = ZolarEventSubscriber.subscribe((txEvents) => {
        const refinementConcludedEvent = txEvents.find(event => event.type === ZolarEventSubscriber.ZolarEventType.RefinementConcluded);
        logger("debug-gems-refine", "listener", {
          txEvents,
          refinementConcludedEvent,
        })

        if (refinementConcludedEvent) {
          const refinementId = refinementConcludedEvent.params.refinement_id;
          if (this.refinementListeners[refinementId]) {
            this.refinementListeners[refinementId](txEvents);
            delete this.refinementListeners[refinementId];
          }
        }
      })
    }

    this.refinementListeners[refinementId] = handler;
  }

  static deregisterRefinementListener = (refinementId: string,) => {
    delete this.refinementListeners[refinementId]
  }

  static waitForConcludeRefinement = async (refinementId: string, maxDuration = 15 * 60000) => {
    return new Promise<ZolarEventSubscriber.ZolarEvent[]>((resolve, reject) => {
      const timeout = setTimeout(() => {
        this.deregisterRefinementListener(refinementId);
        reject("conclude refinement timeout");

        logger("debug-gems-refine", "listener-deregistered", {
          refinementId,
        })
      }, maxDuration);

      this.registerRefinementListener(refinementId, (events: ZolarEventSubscriber.ZolarEvent[]) => {
        clearTimeout(timeout);
        resolve(events);
      })
    })
  }

  static harvestHuny = async (wallet: ConnectedWallet, tokenIds: string[], legacyContract = false) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const contract = legacyContract ? ContractsBech32[zilswap.network].LostPlanet : ContractsBech32[zilswap.network].MoonBattle
    const moonBattleAddress = fromBech32Address(contract).toLowerCase();

    const args = [
      {
        vname: "token_ids",
        type: "List Uint256",
        value: tokenIds,
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(moonBattleAddress),
      "HarvestHuny",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static getTotalHunyBalance = async () => {
    if (!zilswap) throw new Error('not initialized');

    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const result = await hunyContract.getSubState("balances");
    const hunyBalances = this.processHunyTokenBalances(result);

    return hunyBalances;
  }

  static getZilToken = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const zilTokenBalance = zilswap.zilliqa.blockchain.getBalance(wallet.addressInfo.byte20);
    const zilToken = (await zilTokenBalance).result?.balance;
    const zilTokenHuman = bnOrZero(zilToken).shiftedBy(-12).toNumber();
    return zilTokenHuman;
  }

  static getMetazoaBlocksStaked = async (wallet: ConnectedWallet, tokenIds: string[], legacyContract = false) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const stakedBlocks: SimpleMap<number> = {};
    const contract = legacyContract ? ContractsBech32[zilswap.network].LostPlanet : ContractsBech32[zilswap.network].MoonBattle;
    const moonBattleContract = zilswap.zilliqa.contracts.atBech32(contract);
    const result = await moonBattleContract.getSubState("last_harvested");

    const lastHarvested = Object.entries(result.last_harvested as SimpleMap<string>).filter(
      ([tokenId, block]) => tokenIds.includes(tokenId)
    );

    const currentBlock = 2180713;
    lastHarvested.forEach(entry => {
      const elapsedBlocks = currentBlock - parseInt(entry[1]);
      if (elapsedBlocks <= 0) stakedBlocks[entry[0]] = 0;
      if (elapsedBlocks > 0) stakedBlocks[entry[0]] = elapsedBlocks;
    })

    return stakedBlocks;
  }

  // Hive
  static fetchHivePool = async (wallet?: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');

    const hunyAddressBech = ContractsBech32[zilswap.network].HunyToken;
    const hunyAddressBy20 = fromBech32Address(hunyAddressBech).toLowerCase();
    const hiveAddressBy20 = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();

    const pool = zilswap.getPool(hunyAddressBech);
    if (!pool) {
      throw new Error("huny pool not initialized on zilswap");
    }

    const userAddress = wallet?.addressInfo.byte20.toLowerCase();
    let userShare = BIG_ZERO;
    let userBalance = BIG_ZERO;
    let userDebt = BIG_ZERO;
    let userSignificantDeposit = 0;

    if (userAddress) {
      const hiveContract = zilswap.zilliqa.contracts.at(hiveAddressBy20);
      const result = await hiveContract.getSubState("balances", [userAddress]);
      const totalContributionResult = await hiveContract.getSubState("total_contribution");
      const totalContribution = bnOrZero(totalContributionResult.total_contribution);
      if (totalContribution.gt(0)) {
        userBalance = bnOrZero(result?.balances?.[userAddress]);
        userShare = userBalance.div(totalContribution);
      }

      const userDebtResult = await hiveContract.getSubState("debt", [userAddress]);
      userDebt = bnOrZero(userDebtResult?.debt?.[userAddress]);
      const lastSignificantDeposit = await hiveContract.getSubState("last_significant_deposit");
      userSignificantDeposit = lastSignificantDeposit.last_significant_deposit[userAddress]
    }

    const zilswapContract = zilswap.zilliqa.contracts.atBech32(zilswap.contractAddress);
    const result = await zilswapContract.getSubState("balances", [hunyAddressBy20, hiveAddressBy20]);
    const contribution = bnOrZero(result?.balances?.[hunyAddressBy20]?.[hiveAddressBy20]);

    const contributionRatio = pool.totalContribution.gt(0) ? contribution.div(pool.totalContribution) : BIG_ZERO;
    const hiveZilReserve = contributionRatio.times(pool.zilReserve).dp(0);
    const hiveHunyReserve = contributionRatio.times(pool.tokenReserve).dp(0);

    const hivePool: HivePoolStats = {
      totalContribution: pool.totalContribution,
      totalZilReserves: pool.zilReserve,
      totalHunyReserves: pool.tokenReserve,

      contribution: contribution,
      zilReserves: hiveZilReserve,
      hunyReserves: hiveHunyReserve,

      userShare,
      userZilReserves: userShare.times(hiveZilReserve).dp(0),
      userHunyReserves: userShare.times(hiveHunyReserve).dp(0),
      lastSignificantDeposit: userSignificantDeposit,

      // lastClaimBlock,
      userBalance,
      userDebt,
    }

    return hivePool;
  }

  static fetchGuildMemberLp = async (guildMembers: string[]) => {
    if (!zilswap) throw new Error('not initialized');

    const hunyAddressBech = ContractsBech32[zilswap.network].HunyToken;
    const hiveAddressBy20 = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();
    const hunyAddressBy20 = fromBech32Address(hunyAddressBech).toLowerCase();

    const pool = zilswap.getPool(hunyAddressBech);
    if (!pool) {
      throw new Error("huny pool not initialized on zilswap");
    }
    const hiveContract = zilswap.zilliqa.contracts.at(hiveAddressBy20);
    const balances = await hiveContract.getSubState("balances"); // Balances
    const totalContributionResult = await hiveContract.getSubState("total_contribution"); // Total Contribution
    const totalContribution = bnOrZero(totalContributionResult.total_contribution);
    const userDebtResult = await hiveContract.getSubState("debt");
    const zilswapContract = zilswap.zilliqa.contracts.atBech32(zilswap.contractAddress);
    const result = await zilswapContract.getSubState("balances", [hunyAddressBy20, hiveAddressBy20]);
    const contribution = bnOrZero(result?.balances?.[hunyAddressBy20]?.[hiveAddressBy20]);

    const contributionRatio = pool.totalContribution.gt(0) ? contribution.div(pool.totalContribution) : BIG_ZERO;
    const hiveZilReserve = contributionRatio.times(pool.zilReserve).dp(0);
    const hiveHunyReserve = contributionRatio.times(pool.tokenReserve).dp(0);

    let userShare = BIG_ZERO;
    let userBalance = BIG_ZERO;
    let userDebt = BIG_ZERO;
    const guildMemberLp: SimpleMap<HivePoolStats> = {};

    for (let n = 0; n < guildMembers.length; n++) {

      if (totalContribution.gt(0)) {
        userBalance = bnOrZero(balances?.balances?.[guildMembers[n]]);
        userShare = userBalance.div(totalContribution);
      }
      userDebt = bnOrZero(userDebtResult?.debt?.[guildMembers[n]]);


      const hivePool: HivePoolStats = {
        totalContribution: pool.totalContribution,
        totalZilReserves: pool.zilReserve,
        totalHunyReserves: pool.tokenReserve,

        contribution: contribution,
        zilReserves: hiveZilReserve,
        hunyReserves: hiveHunyReserve,

        userShare,
        userZilReserves: userShare.times(hiveZilReserve).dp(0),
        userHunyReserves: userShare.times(hiveHunyReserve).dp(0),

        // lastClaimBlock,
        userBalance,
        userDebt,
      }
      guildMemberLp[guildMembers[n]] = hivePool;
    }
    return guildMemberLp;
  }

  static fetchHiveInfo = async (): Promise<HiveInfo> => {
    if (!zilswap) throw new Error('not initialized');

    const address = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase().replace(/^0x/i, "");

    const substates = [
      "enabled",
      "huny_per_block",
      "huny_rewards_per_share",
      "last_reward_block",
      "total_contribution",
      "total_supply",
      "incoming_kickbacks"
    ];

    const results = await batchQuery(
      substates.map(substate => ({ address, substate })),
      (zilswap as any).rpcEndpoint,
    );

    return {
      enabled: results.enabled === "True",
      hunyPerBlock: bnOrZero(results.huny_per_block),
      hunyRewardsPerShare: bnOrZero(results.huny_rewards_per_share),
      lastRewardBlock: bnOrZero(results.last_reward_block).toNumber(),
      totalContribution: bnOrZero(results.total_contribution),
      totalShare: bnOrZero(results.total_supply),
      incomingKickbacks: bnOrZero(results.incoming_kickbacks),
    };
  }

  static checkHiveAllowance = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();
    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const response = await hunyContract.getSubState("allowances", [walletAddress, hiveAddress]);

    const allowance = response?.allowances?.[walletAddress]?.[hiveAddress];

    return bnOrZero(allowance).shiftedBy(-Decimals.HUNY);
  }

  static approveHiveAllowanceIfRequired = async (wallet: ConnectedWallet, amount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();
    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const response = await hunyContract.getSubState("allowances", [walletAddress, hiveAddress]);

    const allowance = response?.allowances?.[walletAddress]?.[hiveAddress];

    if (!!allowance) return;

    return this.increaseHiveAllowance(wallet, amount);
  }

  static increaseHiveAllowance = async (wallet: ConnectedWallet, amount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();
    const hunyAddress = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase();

    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: hiveAddress,
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hunyAddress),
      "IncreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static claimHive = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hiveAddress),
      "Claim",
      [],
      callParams,
      true,
    );

    return result;
  }

  // TODO: do proper calculations
  static swapAndAddLiquidity = async (wallet: ConnectedWallet, tokenAmount: BigNumber, minZilAmount: BigNumber, maxTokenAmount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();

    const args = [
      // swap
      {
        vname: "token_amount",
        type: "Uint128",
        value: tokenAmount.toString(),
      },
      {
        vname: "min_zil_amount",
        type: "Uint128",
        value: minZilAmount.toString(),
      },
      // add
      {
        vname: "min_contribution_amount",
        type: "Uint128",
        value: "0",
      },
      {
        vname: "max_token_amount",
        type: "Uint128",
        value: maxTokenAmount.toString(),
      },
      {
        vname: "deadline_block",
        type: "BNum",
        value: zilswap.deadlineBlock().toString(),
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const callParams = {
      amount: new BN(0),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hiveAddress),
      "SwapAndAddLiquidity",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static addHiveLiquidity = async (wallet: ConnectedWallet, hunyAmount: BigNumber, zilAmount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();

    const args = [
      {
        vname: "min_contribution_amount",
        type: "Uint128",
        value: "0",
      },
      {
        vname: "max_token_amount",
        type: "Uint128",
        value: hunyAmount.toString(),
      },
      {
        vname: "deadline_block",
        type: "BNum",
        value: zilswap.deadlineBlock().toString(),
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const callParams = {
      amount: new BN(zilAmount.toString()),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hiveAddress),
      "AddLiquidity",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static removeHiveLiquidity = async (wallet: ConnectedWallet, contributionAmount: BigNumber, minZilAmount: BigNumber, minTokenAmount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();

    const args = [
      {
        vname: "amount",
        type: "Uint128",
        value: contributionAmount.toString(),
      },
      {
        vname: "min_zil_amount",
        type: "Uint128",
        value: minZilAmount.toString(),
      },
      {
        vname: "min_token_amount",
        type: "Uint128",
        value: minTokenAmount.toString(),
      },
      {
        vname: "deadline_block",
        type: "BNum",
        value: zilswap.deadlineBlock().toString(),
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const callParams = {
      amount: new BN(0),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hiveAddress),
      "RemoveLiquidity",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  // Old hive
  static claimOldHive = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].OldHive).toLowerCase();

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hiveAddress),
      "Claim",
      [],
      callParams,
      true,
    );

    return result;
  }

  static emergencyWithdrawOldHive = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].OldHive).toLowerCase();

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hiveAddress),
      "EmergencyWithdraw",
      [],
      callParams,
      true,
    );

    return result;
  }

  static checkOldHiveAllowance = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].OldHive).toLowerCase();
    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const response = await hunyContract.getSubState("allowances", [walletAddress, hiveAddress]);

    const allowance = response?.allowances?.[walletAddress]?.[hiveAddress];

    return bnOrZero(allowance).shiftedBy(-Decimals.HUNY);
  }

  static increaseOldHiveAllowance = async (wallet: ConnectedWallet, amount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].OldHive).toLowerCase();
    const hunyAddress = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase();

    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: hiveAddress,
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hunyAddress),
      "IncreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static recoverHuny = async (walletAddr: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!walletAddr) throw new Error('invalid wallet address');

    const hug3RecoveryAddr = ContractsBech32[zilswap.network].Hug3Recovery.toLowerCase();

    const args = [
      {
        vname: "recipient",
        type: "ByStr20",
        value: walletAddr,
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const callParams = {
      amount: new BN(0),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hug3RecoveryAddr),
      "RecoverHuny",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  // Guild

  static purchaseItem = async (wallet: ConnectedWallet, itemId: number, maxPrice: BigNumber, purchaseData: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const emporiumAddress = fromBech32Address(ContractsBech32[zilswap.network].ZGrandEmporium).toLowerCase();

    const args = [
      {
        vname: "item_id",
        type: "Uint128",
        value: itemId.toString(),
      }, {
        vname: "max_price",
        type: "Uint128",
        value: maxPrice,
      }, {
        vname: "purchase_data",
        type: "String",
        value: purchaseData,
      },
    ];

    logger("debug-connector", "purchaseItem", args)

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(emporiumAddress),
      "PurchaseItem",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  //TASK HERE:
  static updateProfession = async (
    wallet: ConnectedWallet,
    professionAssignmentList: {
      token_id: string;
      profession: string;
    }[],
  ) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('wallet not connected');

    const metazoaProfessionContract = fromBech32Address(ContractsBech32[zilswap.network].Professions).toLowerCase();

    const data = professionAssignmentList.map(t => {
      return {
        constructor: `Pair`,
        argtypes: ['Uint256', 'String'],
        arguments: [t.token_id, t.profession.toString()]
      }
    })

    const args = [
      {
        vname: "params",
        type: "List(Pair Uint256 String)",
        value: data,
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(metazoaProfessionContract),
      "BulkUpdateProfession",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  /// GUILD BANK
  /** 
   * @description [DEBUG] Strictly for sudo RPC integration (pending backend sync, API endpoint) 
  **/
  //TODO: Remove this upon API endpoint availability
  static getBankSettings = async (
    bankAddr: string,
  ) => {
    if (!zilswap) throw new Error('not initialized');

    const guildBankContract = fromBech32Address(bankAddr).toLowerCase().replace(/^0x/i, "")

    const queries = [
      "_balance",
      "weekly_tax",
      "officers",
      "members",
      "joining_requests",
      "joining_fee",
      "current_joining_fee",
      "control_mode",
    ];

    const results: RPCSuccessResult = await batchQuery(
      queries.map(query => ({ address: guildBankContract, substate: query })),
      (zilswap as any).rpcEndpoint,
    );

    const balance = results._balance;
    const [joinFee] = results.joining_fee.arguments
    const [joinCaptainAllocation, joinOfficerAllocation] = results.joining_fee.arguments[3].arguments;
    const [taxFee] = results.weekly_tax.arguments
    const [taxCaptainAllocation, taxOfficerAllocation] = results.weekly_tax.arguments[3].arguments;
    const controlModeMatch = results.control_mode.constructor.match(/^0x[a-z0-9]{40}.([a-z0-9]*)$/i);
    if (!controlModeMatch) throw new Error("invalid control mode constructor");
    const controlMode = controlModeMatch[1];

    return {
      balance: bnOrZero(balance),
      joiningFee: {
        initialAmt: bnOrZero(joinFee),
        captainAllocation: joinCaptainAllocation,
        officerAllocation: joinOfficerAllocation
      },
      weeklyTax: {
        initialAmt: bnOrZero(taxFee),
        captainAllocation: taxCaptainAllocation,
        officerAllocation: taxOfficerAllocation,
      },

      controlMode,
    };
  }

  //TODO: Remove this upon API endpoint availability
  //INFO: Checks if bank's owner is the current guild captain
  static verifyBankOwnership = async (
    bankAddr: string,
    ownerAddress: string,
  ): Promise<boolean> => {
    if (!zilswap) throw new Error('not initialized');
    const guildBankContract = zilswap.zilliqa.contracts.atBech32(bankAddr);
    const owner = await guildBankContract.getSubState("contract_owner");

    return owner.contract_owner.arguments[0] === ownerAddress;
  }

  //TODO: Remove this upon API endpoint availability
  //INFO: Returns guild members listed in the bank
  static queryBankMembers = async (
    bankAddr: string,
    ownerAddress: string,
  ) => {
    if (!zilswap) throw new Error('not initialized');

    const hasValidOwnership: boolean = await this.verifyBankOwnership(bankAddr, ownerAddress)
    if (!hasValidOwnership) throw new Error('Guild does not have active bank contract');

    const guildBankContract = zilswap.zilliqa.contracts.atBech32(bankAddr);
    const result = await guildBankContract.getSubState("members")
    return result;
  }

  static queryPendingRequest = async (wallet: ConnectedWallet, bankAddr: string, listAll: boolean = false) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('wallet not connected');

    const guildBankContract = zilswap.zilliqa.contracts.atBech32(bankAddr);
    const indices = !listAll ? [wallet.addressInfo.byte20.toLowerCase()] : []
    const result = await guildBankContract.getSubState("joining_requests", indices)
    return result;
  }

  static increaseGuildBankAllowance = async (wallet: ConnectedWallet, bankAddr: string, amount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const hunyAddress = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase();

    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: fromBech32Address(bankAddr).toLowerCase(),
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(25000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hunyAddress),
      "IncreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static removeGuildBankAllowance = async (wallet: ConnectedWallet, bankAddr: string, amount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const hunyAddress = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase();

    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: fromBech32Address(bankAddr).toLowerCase(),
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(25000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hunyAddress),
      "DecreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static fetchTaxApproved = async (bankAddr: string) => {
    if (!zilswap) throw new Error('not initialized');
    const bankAddress = toBech32Address(bankAddr).toLowerCase();
    const guildBankContract = zilswap.zilliqa.contracts.atBech32(bankAddress);
    const result = await guildBankContract.getSubState("tax_approved")
    return result;
  }

  static approveWeeklyTax = async (wallet: ConnectedWallet, bankAddr: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('wallet not connected');

    const guildBankAddr = fromBech32Address(bankAddr).toLowerCase();

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const args = [];

    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "ApproveTaxCollection",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static applyForMembership = async (wallet: ConnectedWallet, bankAddr: string,) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('wallet not connected');

    const guildBankAddr = fromBech32Address(bankAddr).toLowerCase();

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const args = [];

    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "ApplyForMembership",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static leaveGuild = async (wallet: ConnectedWallet, bankAddr: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('wallet not connected');

    const guildBankAddr = fromBech32Address(bankAddr).toLowerCase();

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const args = [];

    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "LeaveGuild",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static cancelJoinRequest = async (wallet: ConnectedWallet, bankAddr: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('wallet not connected');

    const guildBankAddr = fromBech32Address(bankAddr).toLowerCase();

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const args = [];

    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "WithdrawMembershipApplication",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static handleGuildCommanders = async (bankAddr: string, memberAddress: string, isPromote: boolean) => {
    if (!zilswap) throw new Error('not initialized');
    if (!memberAddress) throw new Error('invalid member');

    const args = [
      {
        vname: "member",
        type: "ByStr20",
        value: memberAddress,
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(bankAddr),
      isPromote ? "PromoteMember" : "DemoteMember",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static handleJoinRequest = async (bankAddr: string, memberAddress: string, approved: boolean) => {
    if (!zilswap) throw new Error('not initialized');
    if (!memberAddress) throw new Error('invalid member');

    const args = [
      {
        vname: "member",
        type: "ByStr20",
        value: memberAddress,
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(bankAddr),
      approved ? "ApproveAndReceiveJoiningFee" : "RejectJoinRequest",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  // Guild Bank
  static handleMemberRemoval = async (bankAddr: string, memberAddress: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!memberAddress) throw new Error('invalid member');

    const args = [
      {
        vname: "member",
        type: "ByStr20",
        value: memberAddress,
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(bankAddr),
      "RemoveMember",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static checkGuildBankAllowance = async (wallet: ConnectedWallet, guildBankAddress: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const guildBankAddr = fromBech32Address(guildBankAddress).toLowerCase();
    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const response = await hunyContract.getSubState("allowances", [walletAddress, guildBankAddr]);

    const allowance = response?.allowances?.[walletAddress]?.[guildBankAddr];

    return bnOrZero(allowance).shiftedBy(-Decimals.HUNY);
  }

  static checkGuildHiveAllowance = async (guildBankAddress: string) => {
    if (!zilswap) throw new Error('not initialized');

    const guildBankAddr = fromBech32Address(guildBankAddress).toLowerCase();
    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();
    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const response = await hunyContract.getSubState("allowances", [guildBankAddr, hiveAddress]);

    const allowance = response?.allowances?.[guildBankAddr]?.[hiveAddress];

    return bnOrZero(allowance).shiftedBy(-Decimals.HUNY);
  }

  static increaseGuildHiveAllowance = async (guildBankAddress: string, amount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    const guildBankAddr = fromBech32Address(guildBankAddress).toLowerCase();
    const hiveAddress = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();

    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: hiveAddress,
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "IncreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static donateToBank = async (bankAddr: string, donationType: string, amount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    let tokenAddr = "";
    let zilAmountToSend = "";
    if (donationType === "huny") {
      tokenAddr = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase()
    }
    if (donationType === "zil") {
      tokenAddr = "0x0000000000000000000000000000000000000000";
      zilAmountToSend = amount.toString();
    }

    const args = [
      {
        vname: "token",
        type: "ByStr20",
        value: tokenAddr,
      },
      {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: !!zilAmountToSend ? new BN(zilAmountToSend) : new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(bankAddr),
      "MakeDonation",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static withdrawGuildBank = async (guildBankAddress: string, recipient: string, token: string, amount: BigNumber, message: string) => {
    if (!zilswap) throw new Error('not initialized');
    const guildBankAddr = fromBech32Address(guildBankAddress).toLowerCase();
    let tokenAddr = "";
    let zilAmountToSend = "";
    if (token === "huny") {
      tokenAddr = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase()
    }
    if (token === "zil") {
      tokenAddr = "0x0000000000000000000000000000000000000000";
      zilAmountToSend = amount.toString();
    }

    const recipientAddr = fromBech32Address(recipient).toLowerCase();

    const args = [
      {
        vname: "tx_params",
        type: `${guildBankAddr}.TxParams`,
        value: {
          constructor: `${guildBankAddr}.WithdrawTxParams`,
          argtypes: [],
          arguments: [recipientAddr, tokenAddr, amount]
        }
      },
      {
        vname: "message",
        type: "String",
        value: message,
      }
    ]

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: !!zilAmountToSend ? new BN(zilAmountToSend) : new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "InitiateTx",
      args as any,
      callParams,
      true
    );

    return result;
  }

  static addGuildBankHive = async (guildBankAddress: string, hunyAmount: BigNumber, zilAmount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    const guildBankAddr = fromBech32Address(guildBankAddress).toLowerCase();

    const minContribution = "0" // must be BN to string
    const minZilAmount = bnOrZero(zilAmount).shiftedBy(Decimals.ZIL).toString();
    const maxTokenAmount = bnOrZero(hunyAmount).shiftedBy(Decimals.HUNY).toString();
    const deadlineBlock = zilswap.deadlineBlock().toString()

    const args = [
      {
        vname: "tx_params",
        type: `${guildBankAddr}.TxParams`,
        value: {
          constructor: `${guildBankAddr}.DepositHiveTxParams`,
          argtypes: [],
          arguments: [minContribution, minZilAmount, maxTokenAmount, deadlineBlock]
        }
      },
      {
        vname: "message",
        type: "String",
        value: "Add liquidity to Hive from Guild Bank",
      }
    ]

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "InitiateTx",
      args as any,
      callParams,
      true
    );

    return result;
  }
  static removeGuildBankHive = async (guildBankAddress: string, contributionAmount: BigNumber, minZilAmount: BigNumber, minTokenAmount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');

    const guildBankAddr = fromBech32Address(guildBankAddress).toLowerCase();

    const args = [
      {
        vname: "tx_params",
        type: `${guildBankAddr}.TxParams`,
        value: {
          constructor: `${guildBankAddr}.WithdrawHiveTxParams`,
          argtypes: [],
          arguments: [contributionAmount.toString(), minZilAmount.toString(), minTokenAmount.toString(), zilswap.deadlineBlock().toString()]
        }
      },
      {
        vname: "message",
        type: "String",
        value: "Remove liquidity from Hive to Guild Bank",
      }
    ]

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const callParams = {
      amount: new BN(0),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "InitiateTx",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static getBankHiveTokens = async (guildBankAddress: string) => {
    if (!zilswap) throw new Error('not initialized');

    const hunyAddressBech = ContractsBech32[zilswap.network].HunyToken;
    const hunyAddressBy20 = fromBech32Address(hunyAddressBech).toLowerCase();
    const hiveAddressBy20 = fromBech32Address(ContractsBech32[zilswap.network].Hive).toLowerCase();

    const pool = zilswap.getPool(hunyAddressBech);
    if (!pool) {
      throw new Error("huny pool not initialized on zilswap");
    }

    const guildBankAddr = fromBech32Address(guildBankAddress).toLowerCase();
    let guildBankShare = BIG_ZERO;
    let guildBankBalance = BIG_ZERO;
    let guildBankDebt = BIG_ZERO;
    let guildBankSignificantDeposit = 0;

    if (guildBankAddr) {
      const hiveContract = zilswap.zilliqa.contracts.at(hiveAddressBy20);
      const result = await hiveContract.getSubState("balances", [guildBankAddr]);
      const totalContributionResult = await hiveContract.getSubState("total_contribution");
      const totalContribution = bnOrZero(totalContributionResult.total_contribution);
      if (totalContribution.gt(0)) {
        guildBankBalance = bnOrZero(result?.balances?.[guildBankAddr]);
        guildBankShare = guildBankBalance.div(totalContribution);
      }

      const userDebtResult = await hiveContract.getSubState("debt", [guildBankAddr]);
      guildBankDebt = bnOrZero(userDebtResult?.debt?.[guildBankAddr]);
      const lastSignificantDeposit = await hiveContract.getSubState("last_significant_deposit");
      guildBankSignificantDeposit = lastSignificantDeposit.last_significant_deposit[guildBankAddr]
    }

    const zilswapContract = zilswap.zilliqa.contracts.atBech32(zilswap.contractAddress);
    const result = await zilswapContract.getSubState("balances", [hunyAddressBy20, hiveAddressBy20]);
    const contribution = bnOrZero(result?.balances?.[hunyAddressBy20]?.[hiveAddressBy20]);

    const contributionRatio = pool.totalContribution.gt(0) ? contribution.div(pool.totalContribution) : BIG_ZERO;
    const hiveZilReserve = contributionRatio.times(pool.zilReserve).dp(0);
    const hiveHunyReserve = contributionRatio.times(pool.tokenReserve).dp(0);

    const hivePool: HivePoolStats = {
      totalContribution: pool.totalContribution ?? BIG_ZERO,
      totalZilReserves: pool.zilReserve ?? BIG_ZERO,
      totalHunyReserves: pool.tokenReserve ?? BIG_ZERO,

      contribution: contribution ?? BIG_ZERO,
      zilReserves: hiveZilReserve ?? BIG_ZERO,
      hunyReserves: hiveHunyReserve ?? BIG_ZERO,

      userShare: guildBankShare ?? BIG_ZERO,
      userZilReserves: guildBankShare.times(hiveZilReserve).dp(0) ?? BIG_ZERO,
      userHunyReserves: guildBankShare.times(hiveHunyReserve).dp(0) ?? BIG_ZERO,
      lastSignificantDeposit: guildBankSignificantDeposit ?? 0,

      // lastClaimBlock,
      userBalance: guildBankBalance ?? BIG_ZERO,
      userDebt: guildBankDebt ?? BIG_ZERO,
    }
    return hivePool;
  }

  static claimGuildBankHive = async (bankAddr: string) => {
    if (!zilswap) throw new Error('not initialized');

    const guildBankAddr = fromBech32Address(bankAddr).toLowerCase();

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const args = [];

    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "ClaimHive",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static getBankTokens = async (bankAddr: string) => {
    if (!zilswap) throw new Error('not initialized');

    const guildBankAddr = fromBech32Address(bankAddr).toLowerCase()
    logger("debug-connector", "getBankTokens", bankAddr)

    const zilTokenBalance = zilswap.zilliqa.blockchain.getBalance(guildBankAddr);
    const zilToken = (await zilTokenBalance).result.balance;
    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const response = await hunyContract.getSubState("balances", [guildBankAddr]);

    if (!response) {
      return {
        zilToken: BIG_ZERO,
        hunyToken: BIG_ZERO,
      };
    }
    const hunyToken = response.balances[guildBankAddr];

    return {
      zilToken: zilToken,
      hunyToken: hunyToken,
    };
  }

  static fetchUpdateBankPrice = async () => {
    if (!zilswap) throw new Error('not initialized');

    const bankAuthorityContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].BankAuthority);
    const result = await bankAuthorityContract.getSubState("service_fee");
    logger("debug-connector", "serviceFee", result, bnOrZero(result.service_fee))

    return result.service_fee;
  }

  static updateBankSettings = async (guildBank: GuildBankInfo, bankSettings: GuildBankSetting) => {
    if (!zilswap) throw new Error('not initialized');
    const guildBankAddr = (guildBank.address).toLowerCase();

    //INFO: 10000 bps = 100%
    bankSettings.joinCaptainAllocation *= 100;
    bankSettings.joinOfficerAllocation *= 100;
    bankSettings.taxCaptainAllocation *= 100;
    bankSettings.taxOfficerAllocation *= 100;

    const args = [
      {
        vname: "tx_params",
        type: `${guildBankAddr}.TxParams`,
        value: {
          constructor: `${guildBankAddr}.UpdateConfigTxParams`,
          argtypes: [],
          arguments: [{
            constructor: `${guildBankAddr}.GuildBankSettings`,
            argtypes: [],
            arguments: [{
              constructor: `${guildBankAddr}.Fee`,
              argtypes: [],
              arguments: [
                (bankSettings.joinFee.shiftedBy(Decimals.HUNY)).toString(10), // initial amount joining fee
                {
                  constructor: `${guildBankAddr}.FeeAllocation`,
                  argtypes: [],
                  arguments: [bankSettings.joinCaptainAllocation.toString(), bankSettings.joinOfficerAllocation.toString()],
                }, // fee allocation
              ],
            }, {
              constructor: `${guildBankAddr}.Fee`,
              argtypes: [],
              arguments: [
                (bankSettings.taxFee.shiftedBy(Decimals.HUNY)).toString(10), // initial amount
                {
                  constructor: `${guildBankAddr}.FeeAllocation`,
                  argtypes: [],
                  arguments: [bankSettings.taxCaptainAllocation.toString(), bankSettings.taxOfficerAllocation.toString()],
                }, // fee allocation
              ],
            }, {
              constructor: `${guildBankAddr}.${bankSettings.controlMode}`,
              argtypes: [],
              arguments: [],
            },
            ],
          }],
        }
      },
      {
        vname: "message",
        type: "String",
        value: bankSettings.updateReason,
      }
    ]

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "InitiateTx",
      args as any,
      callParams,
      true
    );

    return result;
  }

  static getGuildBankPendingTx = async (guildBankAddress: string) => {
    if (!zilswap) throw new Error('not initialized');
    const guildBankAddr = toBech32Address(guildBankAddress).toLowerCase()
    const guildBankContract = zilswap.zilliqa.contracts.atBech32(guildBankAddr);
    const result = await guildBankContract.getSubState("pending_tx");
    if (!result) return;
    const pendingTx = result.pending_tx;
    if (!pendingTx || pendingTx.constructor === "None") return;
    logger("debug-connector", "getGuildBankPendingTx", pendingTx);

    const txInfo = result.pending_tx.arguments[0];
    const { arguments: info } = txInfo;
    return {
      validSigners: info[1],
      initiator: info[0].arguments[1],
      type: info[0].arguments[2].constructor.match(/^0x[a-z0-9]{40}.([a-z0-9]*)$/i)[1],
      msg: info[0].arguments[0],
      params: info[0].arguments[2].arguments,
    };
  }

  static cancelMultisigTx = async (wallet: ConnectedWallet, bankAddr: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('wallet not connected');

    const guildBankAddr = bankAddr.toLowerCase();
    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const args = [];

    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "CancelTx",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static signMultisigTx = async (bankAddr: string) => {
    if (!zilswap) throw new Error('not initialized');

    const guildBankAddr = bankAddr.toLowerCase();
    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const args = [];

    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "SignTx",
      args as any,
      callParams,
      true,
    );

    return result;
  }


  // Guild Bank Refinery
  static getGuildBankHunyPots = async (guildBankAddress: string) => {
    if (!zilswap) throw new Error('not initialized');

    const refineryContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Refinery);
    const guildBankAddr = fromBech32Address(guildBankAddress).toLowerCase();

    const result = await refineryContract.getSubState("refining", [guildBankAddr]);
    return result?.refining?.[guildBankAddr] as SimpleMap<string>;
  }

  static getGuildBankRefineryStats = async (guildBankAddress: string) => {
    if (!zilswap) throw new Error('not initialized');

    const refineryContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Refinery);

    const totalShareRes = await refineryContract.getSubState("total_share");
    const totalShare = parseBN(totalShareRes?.total_share);
    if (!totalShare) {
      console.warn("refinery contract address might be invalid", refineryContract.address, zilswap.network);
    }

    const totalSupplyRes = await refineryContract.getSubState("total_supply");
    const totalSupply = parseBN(totalSupplyRes?.total_supply);
    if (!totalSupply) {
      console.warn("refinery contract address might be invalid", refineryContract.address, zilswap.network);
    }

    let lastRefinedBlock: number | undefined = undefined
    const moonBattleAddress = fromBech32Address(ContractsBech32[zilswap.network].MoonBattle).toLowerCase();
    const userAddress = fromBech32Address(guildBankAddress).toLowerCase();
    if (userAddress) {
      const response = await refineryContract.getSubState("last_refined", [moonBattleAddress, userAddress]);
      const lastRefinedBlockRes = bnOrZero(response?.last_refined?.[moonBattleAddress]?.[userAddress]);

      if (lastRefinedBlockRes.gt(0))
        lastRefinedBlock = lastRefinedBlockRes.toNumber();
    }
    return { totalShare, totalSupply, lastRefinedBlock };
  }

  static claimGuildRefinery = async (guildBankAddress: string, claimBlock: number) => {
    if (!zilswap) throw new Error('not initialized');

    const guildBankAddr = fromBech32Address(guildBankAddress).toLowerCase();

    const args = [
      {
        vname: "claim_block",
        type: "BNum",
        value: `${claimBlock}`,
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "ClaimRefinery",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static fetchGuildTaxInfo = async (guildBankAddress: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!guildBankAddress) throw new Error('invalid guild bank');

    const bankAddress: string = guildBankAddress.toLowerCase().replace(/^0x/i, "");
    const substates = [
      "tax_owed",
      "tax_collected",
      "members",
      "last_updated_epoch",
    ];

    const results: RPCSuccessResult = await batchQuery(
      substates.map(substate => ({ address: bankAddress, substate: substate })),
      (zilswap as any).rpcEndpoint,
    );

    return {
      taxOwed: results.tax_owed,
      taxCollected: results.tax_collected,
      members: results.members,
      lastUpdatedEpoch: parseInt(results.last_updated_epoch)
    }
  }

  static collectTaxes = async (guildBankAddress: string, taxes: TaxToCollect[]) => {
    if (!zilswap) throw new Error('not initialized');
    if (!guildBankAddress) throw new Error('invalid guild bank');

    const guildBankAddr = guildBankAddress.toLowerCase()
    const taxesToCollect = taxes.map(t => {
      return {
        constructor: `${guildBankAddr}.TaxParam`,
        argtypes: [],
        arguments: [t.memberAddress, t.epochNumber.toString()]
      }
    })

    const args = [
      {
        vname: "params",
        type: `List ${guildBankAddr}.TaxParam`,
        value: taxesToCollect,
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(guildBankAddr),
      "CollectTax",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static checkEmporiumApproved = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const emporiumAddress = fromBech32Address(ContractsBech32[zilswap.network].ZGrandEmporium).toLowerCase();
    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const response = await hunyContract.getSubState("allowances", [walletAddress, emporiumAddress]);

    const allowance = response?.allowances?.[walletAddress]?.[emporiumAddress];

    return !!allowance;
  }

  static queryAllowances = async () => {
    if (!zilswap) throw new Error('not initialized');

    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const response = await hunyContract.getSubState("allowances");
    const allowances = response?.allowances;

    return allowances;
  }

  static approveEmporiumAllowanceIfRequired = async (wallet: ConnectedWallet, amount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const emporiumAddress = fromBech32Address(ContractsBech32[zilswap.network].ZGrandEmporium).toLowerCase();
    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const response = await hunyContract.getSubState("allowances", [walletAddress, emporiumAddress]);

    const allowance = response?.allowances?.[walletAddress]?.[emporiumAddress];

    if (!!allowance) return;

    return this.increaseEmporiumAllowance(wallet, amount);
  }

  static increaseEmporiumAllowance = async (wallet: ConnectedWallet, amount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const emporiumAddress = fromBech32Address(ContractsBech32[zilswap.network].ZGrandEmporium).toLowerCase();
    const hunyAddress = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase();

    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: emporiumAddress,
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hunyAddress),
      "IncreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static fetchStallItem = async (itemId: number) => {
    if (!zilswap) throw new Error('not initialized');

    const stallContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Stall);
    const result = await stallContract.getSubState("items", [itemId.toString()]);
    return result.items[itemId];
  }

  static fetchTotalHunySupply = async () => {
    if (!zilswap) throw new Error('not initialized');

    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const result = await hunyContract.getSubState("total_supply");

    return result;
  }

  static fetchStallPurchaseCount = async (itemId: number) => {
    if (!zilswap) throw new Error('not initialized');

    const stallContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Stall);
    const result = await stallContract.getSubState("purchase_count", [itemId.toString()]);
    const purchaseCount = result?.purchase_count[itemId];

    return parseInt(purchaseCount ?? "0");
  }

  static fetchResourceOwned = async (wallet?: ConnectedWallet, resourceAddressBech32?: string) => {
    if (!resourceAddressBech32) throw new Error('invalid resource address');
    if (!wallet) throw new Error('invalid wallet');
    if (!zilswap) throw new Error('not initialized')

    const address = wallet.addressInfo.byte20.toLowerCase();

    const contract = zilswap.zilliqa.contracts.atBech32(resourceAddressBech32);
    const result = await contract.getSubState("balances", [address]);
    return bnOrZero(result?.balances?.[address]);
  }

  static fetchGeodesOwned = async (wallet: ConnectedWallet) => {
    return TBMConnector.fetchResourceOwned(wallet, ContractsBech32[zilswap?.network ?? Network.MainNet].Berry);
  }

  static fetchBerryOwned = async (wallet?: ConnectedWallet) => {
    return TBMConnector.fetchResourceOwned(wallet, ContractsBech32[zilswap?.network ?? Network.MainNet].Berry);
  }

  static fetchZScrapsOwned = async (wallet: ConnectedWallet) => {
    return TBMConnector.fetchResourceOwned(wallet, ContractsBech32[zilswap?.network ?? Network.MainNet].Berry);
  }

  static refineGeodes = async (wallet: ConnectedWallet, geode: BigNumber) => {
    if (!zilswap) throw new Error('not initialized')
    if (!wallet) throw new Error('invalid wallet');

    const gemContract = fromBech32Address(ContractsBech32[zilswap.network].GemRefinery).toLowerCase();
    const args = [
      {
        vname: "quantity",
        type: "Uint128",
        value: geode.shiftedBy(2).toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(gemContract),
      "BeginGeodeRefinement",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static refineElderberry = async (wallet: ConnectedWallet, amount: BigNumber, resourceQty: BigNumber, itemId) => {
    if (!zilswap) throw new Error('not initialized')
    if (!wallet) throw new Error('invalid wallet');

    const zomgContract = fromBech32Address(ContractsBech32[zilswap.network].ZOMG).toLowerCase();
    const hunyAddress = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase();
    const itemsAddress = fromBech32Address(ContractsBech32[zilswap.network].Berry).toLowerCase();

    const txRepeater: number = resourceQty.dividedToIntegerBy(10).toNumber();
    const txCost: BigNumber = amount.dividedBy(txRepeater);
    const txResourceQty: BigNumber = resourceQty.dividedBy(txRepeater);

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const param = {
      itemId: itemId.toString(),
      paymentItem: [
        {
          constructor: `${zomgContract}.PaymentItem`,
          argtypes: [],
          arguments: [hunyAddress, txCost.toString()]
        },
        {
          constructor: `${zomgContract}.PaymentItem`,
          argtypes: [],
          arguments: [itemsAddress, txResourceQty.toString()]
        },
      ]
    }

    const params = new Array(txRepeater).fill(param)

    const argList = params.map(param => {
      return {
        constructor: "Pair",
        argtypes: ['Uint128', `List ${zomgContract}.PaymentItem`],
        arguments: [param.itemId, param.paymentItem],
      }
    })

    const args = [
      {
        vname: 'item_id_payment_items_pair_list',
        type: `List(Pair Uint128(List ${zomgContract}.PaymentItem))`,
        value: argList,
      }
    ]

    logger("debug-connector", "RPC-refineElderberry", {
      args,
      params
    })

    const result = await zilswap.callContract(
      zilswap.getContract(zomgContract),
      "BatchCraftItem",
      args as any,
      callParams,
      true,
    );

    return result;

  }

  static checkQuestAllowance = async (wallet: ConnectedWallet, questLocation: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const metazoaAddress = ContractsBech32[zilswap.network].NftV2.toLowerCase();
    const questBerryAddress = ContractsBech32[zilswap.network].QuestBerry.toLowerCase();
    const questScrapAddress = ContractsBech32[zilswap.network].QuestScrap.toLowerCase();
    const questGeodeAddress = ContractsBech32[zilswap.network].QuestGeode.toLowerCase();

    let questAddress = ""
    switch (questLocation) {
      case "Elder Woodlands":
        questAddress = questBerryAddress
        break;

      case "Zolar Asteroid Belt":
        questAddress = questGeodeAddress
        break;
      case "Moon Battlegrounds":
        questAddress = questScrapAddress
        break;
    }

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const metazoaContract = zilswap.zilliqa.contracts.atBech32(metazoaAddress);
    const response = await metazoaContract.getSubState("operators", [walletAddress]);
    if (!questAddress.length) return false;
    const allowance = response?.operators?.[walletAddress]?.[fromBech32Address(questAddress).toLowerCase()];
    if (!!allowance) return false;
    return true;
  }

  static checkUserGemAllowance = async (
    wallet: ConnectedWallet,
    resourceType: keyof typeof MissionGroundResource
  ) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');
    if (!Object.keys(MissionGroundResource).includes(resourceType)) throw new Error('invalid resource type');

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const gemRefineryAddress = fromBech32Address(ContractsBech32[zilswap.network].GemRefinery).toLowerCase()

    const resourceAlias = RESOURCES[MissionGroundResource[resourceType]].alias;
    const resourceContract = zilswap.zilliqa.contracts.atBech32((ContractsBech32[zilswap.network][resourceAlias]).toLowerCase());

    const response = await resourceContract.getSubState("allowances", [walletAddress, gemRefineryAddress]);
    const allowance = response?.allowances?.[walletAddress]?.[gemRefineryAddress];
    if (!!allowance) return false;
    return true;
  }

  static checkResourceAllowance = async (
    wallet: ConnectedWallet,
    resourceType: keyof typeof MissionGroundResource
  ) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');
    if (!Object.keys(MissionGroundResource).includes(resourceType)) throw new Error('invalid resource type');

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const zomgAddress = fromBech32Address(ContractsBech32[zilswap.network].ZOMG).toLowerCase()

    const resourceAlias = RESOURCES[MissionGroundResource[resourceType]].alias;
    const resourceContract = zilswap.zilliqa.contracts.atBech32((ContractsBech32[zilswap.network][resourceAlias]).toLowerCase());

    const response = await resourceContract.getSubState("allowances", [walletAddress, zomgAddress]);
    const allowance = response?.allowances?.[walletAddress]?.[zomgAddress];
    if (!!allowance) return false;
    return true;
  }

  static increaseResourceAllowance = async (
    wallet: ConnectedWallet,
    amount: BigNumber,
    resourceType: keyof typeof MissionGroundResource
  ) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');
    if (!Object.keys(MissionGroundResource).includes(resourceType)) throw new Error('invalid resource type');

    const resourceAlias = RESOURCES[MissionGroundResource[resourceType]].alias;
    const resourceContract = fromBech32Address(ContractsBech32[zilswap.network][resourceAlias]).toLowerCase();

    const zomgContract = fromBech32Address(ContractsBech32[zilswap.network].ZOMG).toLowerCase();
    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: zomgContract,
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(resourceContract),
      "IncreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static increaseGemAllowance = async (
    wallet: ConnectedWallet,
    amount: BigNumber,
    resourceType: keyof typeof MissionGroundResource
  ) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');
    if (!Object.keys(MissionGroundResource).includes(resourceType)) throw new Error('invalid resource type');

    const resourceAlias = RESOURCES[MissionGroundResource[resourceType]].alias;
    const resourceContract = fromBech32Address(ContractsBech32[zilswap.network][resourceAlias]).toLowerCase();

    const gemRefineryContract = fromBech32Address(ContractsBech32[zilswap.network].GemRefinery).toLowerCase();
    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: gemRefineryContract,
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(resourceContract),
      "IncreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static addQuestOperator = async (wallet: ConnectedWallet, questLocation: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const metazoaAddress = fromBech32Address(ContractsBech32[zilswap.network].NftV2).toLowerCase();
    const questBerryAddress = fromBech32Address(ContractsBech32[zilswap.network].QuestBerry).toLowerCase();
    const questScrapAddress = fromBech32Address(ContractsBech32[zilswap.network].QuestScrap).toLowerCase();
    const questGeodeAddress = fromBech32Address(ContractsBech32[zilswap.network].QuestGeode).toLowerCase();

    let questAddress = ""
    switch (questLocation) {
      case "Elder Woodlands":
        questAddress = questBerryAddress
        break;

      case "Zolar Asteroid Belt":
        questAddress = questGeodeAddress
        break;
      case "Moon Battlegrounds":
        questAddress = questScrapAddress
        break;
    }

    const args = [{
      vname: "operator",
      type: "ByStr20",
      value: questAddress,
    }];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(metazoaAddress),
      "AddOperator",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static enterQuest = async (wallet: ConnectedWallet, tokenIds: string[], questLocation: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    if (tokenIds.length > MAX_ZOA.EnterBattle) {
      throw new Error(`Please select max ${MAX_ZOA.EnterBattle} only`);
    }

    const questBerryAddress = fromBech32Address(ContractsBech32[zilswap.network].QuestBerry).toLowerCase();
    const questScrapAddress = fromBech32Address(ContractsBech32[zilswap.network].QuestScrap).toLowerCase();
    const questGeodeAddress = fromBech32Address(ContractsBech32[zilswap.network].QuestGeode).toLowerCase();

    let questAddress = ""
    switch (questLocation) {
      case "Elder Woodlands":
        questAddress = questBerryAddress
        break;

      case "Zolar Asteroid Belt":
        questAddress = questGeodeAddress
        break;
      case "Moon Battlegrounds":
        questAddress = questScrapAddress
        break;
    }

    const args = [
      {
        vname: "token_ids",
        type: "List Uint256",
        value: tokenIds,
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(questAddress),
      "EnterQuest",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static getLastHarvestedBlock = async (tokenIds: string[], questLocation: string) => {
    if (!zilswap) throw new Error('not initialized');
    const questBerryAddress = ContractsBech32[zilswap.network].QuestBerry.toLowerCase();
    const questScrapAddress = ContractsBech32[zilswap.network].QuestScrap.toLowerCase();
    const questGeodeAddress = ContractsBech32[zilswap.network].QuestGeode.toLowerCase();

    let questAddress = ""
    switch (questLocation) {
      case "Elder Woodlands":
        questAddress = questBerryAddress
        break;

      case "Zolar Asteroid Belt":
        questAddress = questGeodeAddress
        break;
      case "Moon Battlegrounds":
        questAddress = questScrapAddress
        break;
    }

    const questContract = zilswap.zilliqa.contracts.atBech32(questAddress);
    const response = await questContract.getSubState("last_harvested");
    logger("debug-connector", "getLastHarvestedBlock", {
      response,
      questAddress,
      questLocation,
    })
    let lastHarvested: SimpleMap<number> = {}
    if (!Object.keys(response).length) return lastHarvested;

    for (const [id, block] of Object.entries(response.last_harvested)) {
      let blockHarvested = block as number;
      if (tokenIds.find((idx) => idx === id)) lastHarvested[`${id}`] = blockHarvested;
    }

    return lastHarvested;
  }

  static checkQuestHunyAllowance = async (wallet: ConnectedWallet, questLocation: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const questBerryAddress = ContractsBech32[zilswap.network].QuestBerry.toLowerCase();
    const questScrapAddress = ContractsBech32[zilswap.network].QuestScrap.toLowerCase();
    const questGeodeAddress = ContractsBech32[zilswap.network].QuestGeode.toLowerCase();

    let questAddress = ""
    switch (questLocation) {
      case "Elder Woodlands":
        questAddress = questBerryAddress
        break;

      case "Zolar Asteroid Belt":
        questAddress = questGeodeAddress
        break;
      case "Moon Battlegrounds":
        questAddress = questScrapAddress
        break;
    }
    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    if (!questAddress.length) return BIG_ZERO;
    const response = await hunyContract.getSubState("allowances", [walletAddress, fromBech32Address(questAddress).toLowerCase()]);
    const allowance = response?.allowances?.[walletAddress]?.[fromBech32Address(questAddress).toLowerCase()];
    return bnOrZero(allowance).shiftedBy(-Decimals.HUNY);
  }

  static increaseQuestAllowance = async (wallet: ConnectedWallet, amount: BigNumber, questLocation: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const questBerryAddress = ContractsBech32[zilswap.network].QuestBerry.toLowerCase();
    const questScrapAddress = ContractsBech32[zilswap.network].QuestScrap.toLowerCase();
    const questGeodeAddress = ContractsBech32[zilswap.network].QuestGeode.toLowerCase();

    let questAddress = ""
    switch (questLocation) {
      case "Elder Woodlands":
        questAddress = questBerryAddress
        break;

      case "Zolar Asteroid Belt":
        questAddress = questGeodeAddress
        break;
      case "Moon Battlegrounds":
        questAddress = questScrapAddress
        break;
    }
    const hunyAddress = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase();

    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: fromBech32Address(questAddress).toLowerCase(),
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hunyAddress),
      "IncreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static harvestQuestResource = async (wallet: ConnectedWallet, tokenIds: string[], questLocation: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const questBerryAddress = fromBech32Address(ContractsBech32[zilswap.network].QuestBerry).toLowerCase();
    const questScrapAddress = fromBech32Address(ContractsBech32[zilswap.network].QuestScrap).toLowerCase();
    const questGeodeAddress = fromBech32Address(ContractsBech32[zilswap.network].QuestGeode).toLowerCase();

    let questAddress = ""
    switch (questLocation) {
      case "Elder Woodlands":
        questAddress = questBerryAddress
        break;

      case "Zolar Asteroid Belt":
        questAddress = questGeodeAddress
        break;

      case "Moon Battlegrounds":
        questAddress = questScrapAddress
        break;
    }

    const args = [
      {
        vname: "token_ids",
        type: "List Uint256",
        value: tokenIds,
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;

    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(questAddress),
      "HarvestResource",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static fetchItems = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const address = wallet.addressInfo.byte20.toLowerCase();
    const metazoaClient = new MetazoaClient(zilswap.network);
    const inventoryResult = await metazoaClient.fetchUserInventory(address);

    let gemsMap: SimpleMap<SimpleMap<number[]>> = {};
    let crackedGemsMap: SimpleMap<SimpleMap<number[]>> = {};
    let consumablesMap: SimpleMap<number[]> = {};
    let ordnancesMap: SimpleMap<OwnedOrdnance> = {};

    const inventoryItems = (inventoryResult.result?.items ?? []).map((item) => {
      const { token_id, collection_address, metadata, tokenTraits } = item;
      const id: number = +(token_id);

      const excludedTraitKeys: string[] = ['String'];
      const traits: SimpleMap = Object.values(tokenTraits as SimpleMap<SimpleMap<string[]>>).reduce((tMap: SimpleMap, t) => {
        for (const entry of Object.values(t)) {
          if (Array.isArray(entry) && entry.length === 2) {
            const traitKey: string = entry[0];
            if (!excludedTraitKeys.includes(traitKey)) tMap[traitKey] = entry[1];
          }
        }
        return tMap ?? {};
      }, {});

      logger("debug-connector", "ConnectedWallet/fetchItems/inventoryItems", {
        item,
        traits,
        tokenTraits,
      });

      if (Object.keys(traits).includes("Type")) {
        const name: string = traits["Name"];

        /// GEMS
        if (traits["Type"] === "Gem") {
          const condition: string = traits["Condition"];
          const tier: string = traits["Tier"];
          const affinity: string = traits["Affinity"];

          if (condition === "Normal") {
            gemsMap[tier] = {
              ...(gemsMap?.[tier] ?? {}),
              [affinity]: [
                ...(gemsMap?.[tier]?.[affinity] ?? []),
                id,
              ],
            }
          }
          else {
            logger("debug-connector", "ConnectedWallet/fetchItems/inventoryItems/CRACKED", {
              item,
              traits,
              tokenTraits,
              crackedGemsMap,
            });

            crackedGemsMap[tier] = {
              ...(gemsMap?.[tier] ?? {}),
              [affinity]: [
                ...(gemsMap?.[tier]?.[affinity] ?? []),
                id,
              ],
            }
          }

        }
        /// CONSUMABLE
        else if (traits["Type"] === "Consumable") {
          consumablesMap[name] = [
            ...(consumablesMap?.[name] ?? []),
            id,
          ];
        }
        /// ORDNANCES
        else {
          const ordnanceCode = name.split('-')?.[0] ?? name;
          ordnancesMap[name] = {
            ids: [
              ...(ordnancesMap?.[name]?.ids ?? []),
              id,
            ],
            name: name,
            address: collection_address,
            src: ZOrdnanceClasses[ordnanceCode],
            attributes: traits,
          }
        }
      }


      logger("debug-connector", "ConnectedWallet/fetchItems", {
        inventoryResult,
        gemsMap,
        consumablesMap,
        ordnancesMap,
      });

      return ({
        id: +(token_id),
        metadata,
        traits,
        address: collection_address,
      })
    })

    logger("debug-connector", "ConnectedWallet/fetchItems", {
      gems: gemsMap,
      crackedGems: crackedGemsMap,
      consumables: consumablesMap,
      ordnances: ordnancesMap,
      all: inventoryItems,
    })

    // TODO when got other items then map accordingly
    return {
      gems: gemsMap,
      crackedGems: crackedGemsMap,
      consumables: consumablesMap,
      ordnances: ordnancesMap,
      // all: inventoryItems,
    };
  }

  static fetchUpdatedTokens = async (wallet: ConnectedWallet | null): Promise<UpdatedTokens> => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const address = wallet.addressInfo.byte20.toLowerCase();
    const getAddressHex = (bech32) => fromBech32Address(bech32).toLowerCase().replace(/^0x/i, "")

    const queries: SimpleRPCRequest[] = [
      { substate: "token_owners", address: getAddressHex(ContractsBech32[zilswap.network].Nft) },
      { substate: "metazoa_commanders", address: getAddressHex(ContractsBech32[zilswap.network].MoonBattle) },
      { substate: "minted", address: getAddressHex(ContractsBech32[zilswap.network].TranscendenceMinter) },
      { substate: "balances", address: getAddressHex(ContractsBech32[zilswap.network].HunyToken) },
      { substate: "total_supply", address: getAddressHex(ContractsBech32[zilswap.network].HunyToken) },

      { substate: "metazoa_commanders", address: getAddressHex(ContractsBech32[zilswap.network].QuestBerry), resultAs: "stakedZoaBerry" },
      { substate: "metazoa_commanders", address: getAddressHex(ContractsBech32[zilswap.network].QuestScrap), resultAs: "stakedZoaScrap" },
      { substate: "metazoa_commanders", address: getAddressHex(ContractsBech32[zilswap.network].QuestGeode), resultAs: "stakedZoaGeode" },

      { substate: "balances", address: getAddressHex(ContractsBech32[zilswap.network].Geode), indices: [address], resultAs: "balGeode" },
      { substate: "balances", address: getAddressHex(ContractsBech32[zilswap.network].Scrap), indices: [address], resultAs: "balScrap" },
      { substate: "balances", address: getAddressHex(ContractsBech32[zilswap.network].Berry), indices: [address], resultAs: "balBerry" },
    ];

    const results: RPCSuccessResult = await batchQuery(queries, (zilswap as any).rpcEndpoint);

    const ownedTokenIds = Object.entries(results.token_owners).filter(([tokenId, owner]) => owner === address);
    const metazoaIds: string[] = await this.getOwnedMetazoa(wallet) as string[];

    const stakedMetazoaIds = this.processMetazoaCommanders({ metazoa_commanders: results.metazoa_commanders })[address] ?? [];
    const questBerryIds = this.processMetazoaCommanders({ metazoa_commanders: results.stakedZoaBerry })[address] ?? [];
    const questScrapIds = this.processMetazoaCommanders({ metazoa_commanders: results.stakedZoaScrap })[address] ?? [];
    const questGeodeIds = this.processMetazoaCommanders({ metazoa_commanders: results.stakedZoaGeode })[address] ?? [];

    const berry = bnOrZero(results?.balBerry?.[address]);
    const scrap = bnOrZero(results?.balScrap?.[address]);
    const geode = bnOrZero(results?.balGeode?.[address]);

    const trappedMetazoaIds = await this.getTrappedMetazoa()[address] ?? [];
    const hunyBalances = this.processHunyTokenBalances(results);
    const zilBalance = await this.getZilToken(wallet);
    const items = await this.fetchItems(wallet);

    return {
      tokenIds: ownedTokenIds.map((entry) => entry[0]),
      metazoaIds: metazoaIds,
      stakedMetazoaIds: stakedMetazoaIds,
      questBerryIds: questBerryIds,
      questScrapIds: questScrapIds,
      questGeodeIds: questGeodeIds,
      trappedMetazoaIds: trappedMetazoaIds,
      mintedTokensCount: parseInt(results.minted[address] ?? "0"),
      hunyBalance: (hunyBalances[address] ?? BIG_ZERO),
      zilBalance: zilBalance,
      totalHunySupply: results.total_supply,
      resources: {
        [MissionGroundResource.Elderberries]: berry,
        [MissionGroundResource.Geodes]: geode,
        [MissionGroundResource.ZolraniumScraps]: scrap,
        ...items,
      }
    }
  }

  static fetchQuests = async (): Promise<SimpleMap<Quest>> => {
    if (!zilswap) throw new Error('not initialized');

    const getAddressHex = (bech32) => fromBech32Address(bech32).toLowerCase().replace(/^0x/i, "")
    const queries: SimpleRPCRequest[] = [
      { substate: "harvest_fee_per_epoch", address: getAddressHex(ContractsBech32[zilswap.network].QuestBerry), resultAs: "harvestFeeBerry" },
      { substate: "harvest_fee_per_epoch", address: getAddressHex(ContractsBech32[zilswap.network].QuestScrap), resultAs: "harvestFeeScrap" },
      { substate: "harvest_fee_per_epoch", address: getAddressHex(ContractsBech32[zilswap.network].QuestGeode), resultAs: "harvestFeeGeode" },
      { substate: "return_fee", address: getAddressHex(ContractsBech32[zilswap.network].QuestBerry), resultAs: "returnFeeBerry" },
      { substate: "return_fee", address: getAddressHex(ContractsBech32[zilswap.network].QuestScrap), resultAs: "returnFeeScrap" },
      { substate: "return_fee", address: getAddressHex(ContractsBech32[zilswap.network].QuestGeode), resultAs: "returnFeeGeode" },
      { substate: "blocks_required_to_harvest", address: getAddressHex(ContractsBech32[zilswap.network].QuestBerry), resultAs: "blocksRequiredBerry" },
      { substate: "blocks_required_to_harvest", address: getAddressHex(ContractsBech32[zilswap.network].QuestScrap), resultAs: "blocksRequiredScrap" },
      { substate: "blocks_required_to_harvest", address: getAddressHex(ContractsBech32[zilswap.network].QuestGeode), resultAs: "blocksRequiredGeode" },
      { substate: "num_epochs_waive_harvest", address: getAddressHex(ContractsBech32[zilswap.network].QuestBerry), resultAs: "epochWaiveBerry" },
      { substate: "num_epochs_waive_harvest", address: getAddressHex(ContractsBech32[zilswap.network].QuestScrap), resultAs: "epochWaiveScrap" },
      { substate: "num_epochs_waive_harvest", address: getAddressHex(ContractsBech32[zilswap.network].QuestGeode), resultAs: "epochWaiveGeode" },
      { substate: "waive_harvest_percentage_bps", address: getAddressHex(ContractsBech32[zilswap.network].QuestBerry), resultAs: "percentageWaiveBerry" },
      { substate: "waive_harvest_percentage_bps", address: getAddressHex(ContractsBech32[zilswap.network].QuestScrap), resultAs: "percentageWaiveScrap" },
      { substate: "waive_harvest_percentage_bps", address: getAddressHex(ContractsBech32[zilswap.network].QuestGeode), resultAs: "percentageWaiveGeode" },
      { substate: "max_harvest_block", address: getAddressHex(ContractsBech32[zilswap.network].QuestBerry), resultAs: "maxHarvestBlockBerry" },
      { substate: "max_harvest_block", address: getAddressHex(ContractsBech32[zilswap.network].QuestScrap), resultAs: "maxHarvestBlockScrap" },
      { substate: "max_harvest_block", address: getAddressHex(ContractsBech32[zilswap.network].QuestGeode), resultAs: "maxHarvestBlockGeode" },
    ]

    const results: RPCSuccessResult = await batchQuery(queries, (zilswap as any).rpcEndpoint);
    return {
      INT: {
        harvestFeePerEpoch: results.harvestFeeBerry,
        returnFee: results.returnFeeBerry,
        blocksRequiredToHarvest: results.blocksRequiredBerry,
        epochsToWaiveHarvest: results.epochWaiveBerry,
        percentageWaived: results.percentageWaiveBerry,
        maxHarvestBlock: results.maxHarvestBlockBerry.arguments[0],
      },
      STR: {
        harvestFeePerEpoch: results.harvestFeeScrap,
        returnFee: results.returnFeeScrap,
        blocksRequiredToHarvest: results.blocksRequiredScrap,
        epochsToWaiveHarvest: results.epochWaiveScrap,
        percentageWaived: results.percentageWaiveScrap,
        maxHarvestBlock: results.maxHarvestBlockScrap.arguments[0],
      },
      DEX: {
        harvestFeePerEpoch: results.harvestFeeGeode,
        returnFee: results.returnFeeGeode,
        blocksRequiredToHarvest: results.blocksRequiredGeode,
        epochsToWaiveHarvest: results.epochWaiveGeode,
        percentageWaived: results.percentageWaiveGeode,
        maxHarvestBlock: results.maxHarvestBlockGeode.arguments[0],
      }
    }
  }

  static fetchRefinementFees = async (): Promise<RefinementFees> => {
    if (!zilswap) throw new Error('not initialized');

    const hunyAddressByte20 = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase()
    const getAddressHex = (bech32) => fromBech32Address(bech32).toLowerCase().replace(/^0x/i, "")
    const queries: SimpleRPCRequest[] = [
      { substate: "refinement_fee", address: getAddressHex(ContractsBech32[zilswap.network].GemRefinery), resultAs: "gemRefinementFee" },
    ]
    const results: RPCSuccessResult = await batchQuery(queries, (zilswap as any).rpcEndpoint);
    const metazoaClient = new MetazoaClient(zilswap.network);
    const itemRPC = await metazoaClient.fetchStoreItems();
    const zomgConsumables = itemRPC.result.store.others;
    const elderberryJuice = zomgConsumables.find((item) => item.itemName === "Elderberry Juice")

    const hunyFee = elderberryJuice.cost.find(cost => cost.tokenAddress === hunyAddressByte20) ?? {};
    return {
      crackGeodesFee: bnOrZero(results.gemRefinementFee),
      elderberryJuice: {
        id: elderberryJuice.itemId,
        refinementFee: bnOrZero(hunyFee.quantity),
      }
    }
  }

  static fetchUpdatedResources = async (wallet: ConnectedWallet | null): Promise<UpdatedResources> => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const address = wallet.addressInfo.byte20.toLowerCase();
    const getAddressHex = (bech32) => fromBech32Address(bech32).toLowerCase().replace(/^0x/i, "")

    const queries: SimpleRPCRequest[] = [
      { substate: "balances", address: getAddressHex(ContractsBech32[zilswap.network].Geode), indices: [address], resultAs: "balGeode" },
      { substate: "balances", address: getAddressHex(ContractsBech32[zilswap.network].Scrap), indices: [address], resultAs: "balScrap" },
      { substate: "balances", address: getAddressHex(ContractsBech32[zilswap.network].Berry), indices: [address], resultAs: "balBerry" },
    ];

    const results: RPCSuccessResult = await batchQuery(queries, (zilswap as any).rpcEndpoint);

    const berry = bnOrZero(results?.balBerry?.[address]);
    const scrap = bnOrZero(results?.balScrap?.[address]);
    const geode = bnOrZero(results?.balGeode?.[address]);
    const items = await this.fetchItems(wallet);
    return {
      resources: {
        [MissionGroundResource.Elderberries]: berry,
        [MissionGroundResource.Geodes]: geode,
        [MissionGroundResource.ZolraniumScraps]: scrap,
        ...items,
      }
    }
  }

  static fetchGameStats = async (): Promise<GameStats> => {
    if (!zilswap) throw new Error('not initialized');

    const queries = [
      { substate: "metazoa_commanders", contract: fromBech32Address(ContractsBech32[zilswap.network].MoonBattle).toLowerCase().replace(/^0x/i, "") },
      { substate: "traits", contract: fromBech32Address(ContractsBech32[zilswap.network].NftV2).toLowerCase().replace(/^0x/i, "") },
      { substate: "token_id_count", contract: fromBech32Address(ContractsBech32[zilswap.network].NftV2).toLowerCase().replace(/^0x/i, "") },
      { substate: "balances", contract: fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase().replace(/^0x/i, "") },
    ];

    const results: RPCSuccessResult = await batchQuery(
      queries.map(query => ({ address: query.contract, substate: query.substate })),
      (zilswap as any).rpcEndpoint,
    );

    const commanders = this.processMetazoaCommanders(results);
    const tokenTraits = this.processTokenTraits(results);
    const lastMetazoaId = (bnOrZero(results.token_id_count).toNumber() === 0 ? undefined : bnOrZero(results.token_id_count).toNumber()) as number;
    const hunyHolders = this.processHunyTokenBalances(results);
    const totalHuny = BigNumber.sum(...Object.values(hunyHolders));

    return { commanders, tokenTraits, lastMetazoaId, hunyHolders, totalHuny };
  }

  private static getGuildOwnedNft = async (guildMembers: string[], contractAddress: string): Promise<string | unknown[]> => {
    if (!zilswap) throw new Error('not initialized');

    // const nftContract = isMetazoa ? zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].NftV2) : zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Nft);
    const nftContract = zilswap.zilliqa.contracts.atBech32(contractAddress);
    const result = await nftContract.getSubState("token_owners");
    const guildOwnedNft: string[] = [];

    // Filter with every guild member address
    for (let n = 0; n < guildMembers.length; n++) {
      const memberOwnedMetazoa = Object.entries(result.token_owners).filter(
        ([tokenId, owner]) => owner === guildMembers[n]
      );
      const memberMetazoaArray = memberOwnedMetazoa.map((entry) => entry[0]);
      guildOwnedNft.push(...memberMetazoaArray);
    }
    return guildOwnedNft
  }

  private static processMetazoaCommanders = (result: RPCSuccessResult): SimpleMap<string[]> => {
    return Object.entries(result?.metazoa_commanders ?? {}).reduce((accum, next) => {
      const tokenId = next[0] as string;
      const owner = next[1] as string;
      accum[owner] = (accum[owner] ?? []).concat(tokenId);
      return accum;
    }, {} as SimpleMap<string[]>);
  }

  private static processHunyTokenBalances = (result: RPCSuccessResult): SimpleMap<BigNumber> => {
    return Object.keys(result?.balances ?? {}).reduce((accum, owner: string) => {
      const amount = result.balances[owner];
      accum[owner] = bnOrZero(amount).shiftedBy(-Decimals.HUNY);
      return accum;
    }, {} as SimpleMap<BigNumber>);
  }

  private static processTokenTraits = (result: RPCSuccessResult): SimpleMap<SimpleMap> => {
    return Object.entries(result?.traits ?? {}).reduce((accum, next) => {
      const tokenId = next[0] as string;
      const traits = next[1] as any;
      accum[tokenId] = traits.reduce((result: SimpleMap, trait: any) => {
        const traitType = trait.arguments[0] as string;
        const traitValue = trait.arguments[1] as string;
        result[traitType] = traitValue;
        return result;
      }, {} as SimpleMap);
      return accum;
    }, {} as SimpleMap<SimpleMap>);
  }

  // ZOMG
  static getZOMGResources = async (): Promise<ZOMGResourceItem[]> => {
    if (!zilswap) throw new Error('not initialized');

    const resourceStallContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Resource);
    let result: RPCSuccessResult = await resourceStallContract.getSubState("items") ?? {};
    let transactCountResult: RPCSuccessResult = await resourceStallContract.getSubState("transact_count");
    if (!Object.keys(result).length) return [];

    const { items } = result;
    logger('debug-connector', `getZOMGResources - result`, items);

    const resourceItems: ZOMGResourceItem[] = Object.entries(items).map(([idx, itm]) => {
      const item = Object.values((itm as any).arguments);
      const name: string = item[0] as string;
      const address: string = item[1] as string;
      const buy = ((item[2] as SimpleMap<string[]>).arguments).flatMap((p) => bnOrZero(p));
      const sell = ((item[3] as SimpleMap<string[]>).arguments).flatMap((p) => bnOrZero(p));
      let resourceId = ""
      if (name === "Zolar Geode") resourceId = "Geodes";
      if (name === "Zolar Elderberry") resourceId = 'Elderberries';
      if (name === "Zolranium Scraps") resourceId = "ZolraniumScraps";
      const netBuyInflation = !!transactCountResult?.transact_count[idx] ? new BigNumber(transactCountResult?.transact_count[idx].arguments[0]) : BIG_ZERO;
      const netPurchaseCount = !!transactCountResult?.transact_count[idx] ? new BigNumber(transactCountResult?.transact_count[idx].arguments[1]) : BIG_ZERO;
      return {
        id: +idx,
        name,
        address,
        buy,
        sell,
        type: StoreItemType.Buy,
        asset: RESOURCES[MissionGroundResource[resourceId]],
        transactCount: {
          netBuySideInflation: netBuyInflation,
          netPurchaseCount: netPurchaseCount,
        }
      }
    });

    logger('debug-connector', `getZOMGResources - resourceItems`, resourceItems);
    return resourceItems;
  }

  static fetchResourceTransactCount = async (itemId: number) => {
    if (!zilswap) throw new Error('not initialized');

    const stallContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Resource);
    const result = await stallContract.getSubState("transact_count", [itemId.toString()]);
    const transactCount: number[] = (result?.transact_count[itemId]?.arguments).map(Number) ?? [];
    logger("debug-connector", "transactCount", transactCount)

    return transactCount;
  }

  static checkZOMGStallApproved = async (wallet: ConnectedWallet) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const zomgStallAddress = fromBech32Address(ContractsBech32[zilswap.network].Resource).toLowerCase();
    const hunyContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].HunyToken);
    const response = await hunyContract.getSubState("allowances", [walletAddress, zomgStallAddress]);

    const allowance = response?.allowances?.[walletAddress]?.[zomgStallAddress];

    return !!allowance;
  }

  static checkZOMGResourceApproved = async (wallet: ConnectedWallet, resourceName: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const walletAddress = wallet.addressInfo.byte20.toLowerCase();
    const resourceStallAddress = fromBech32Address(ContractsBech32[zilswap.network].Resource).toLowerCase();
    const resourceItemContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network][resourceName]);
    const response = await resourceItemContract.getSubState("allowances", [walletAddress, resourceStallAddress]);

    const allowance = response?.allowances?.[walletAddress]?.[resourceStallAddress];
    logger("debug-zomg-connector", "checkZOMGResourceApproved", {
      resourceName,
      resourceItemContract,
      allowance
    })
    return !!allowance;
  }

  static increaseZOMGStallAllowance = async (wallet: ConnectedWallet, amount: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const resourceAddress = fromBech32Address(ContractsBech32[zilswap.network].Resource).toLowerCase();
    const hunyAddress = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase();

    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: resourceAddress,
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(hunyAddress),
      "IncreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static increaseZOMGResourceItemAllowance = async (wallet: ConnectedWallet, amount: BigNumber, resourceName: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const resourceAddress = fromBech32Address(ContractsBech32[zilswap.network][resourceName]).toLowerCase();
    const resourceStallAddress = fromBech32Address(ContractsBech32[zilswap.network].Resource).toLowerCase();

    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: resourceStallAddress,
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(resourceAddress),
      "IncreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static increaseZOMGResourceStallAllowance = async (wallet: ConnectedWallet, amount: BigNumber, resourceName: string) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const resourceAddress = fromBech32Address(ContractsBech32[zilswap.network][resourceName]).toLowerCase();
    const resourceStallAddress = fromBech32Address(ContractsBech32[zilswap.network].Resource).toLowerCase();

    const args = [
      {
        vname: "spender",
        type: "ByStr20",
        value: resourceStallAddress,
      }, {
        vname: "amount",
        type: "Uint128",
        value: amount.toString(),
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(resourceAddress),
      "IncreaseAllowance",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static sellZOMGResources = async (wallet: ConnectedWallet, itemId: number, minPrice: BigNumber, quantity: BigNumber) => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const resourceAddress = fromBech32Address(ContractsBech32[zilswap.network].Resource).toLowerCase();

    const args = [
      {
        vname: "item_id",
        type: "Uint128",
        value: itemId.toString(),
      }, {
        vname: "min_price",
        type: "Uint128",
        value: minPrice,
      }, {
        vname: "quantity",
        type: "Uint128",
        value: quantity.decimalPlaces(0, 1).toString(),
      },
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(resourceAddress),
      "SellItem",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static getZOMGCraftables = async (): Promise<SimpleMap<ZOMGCraftItem[]>> => {
    if (!zilswap) throw new Error('not initialized');
    const zomgContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].ZOMG);
    const hunyAddress = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase();
    const zScrapAddress = fromBech32Address(ContractsBech32[zilswap.network].Scrap).toLowerCase();
    const berryAddress = fromBech32Address(ContractsBech32[zilswap.network].Berry).toLowerCase();
    const itemsAddress = fromBech32Address(ContractsBech32[zilswap.network].Items).toLowerCase();
    const result = await zomgContract.getSubState("items");
    logger('debug-connector', `getZOMGCraftables - result`, result);

    let items: RPCSuccessResult = result ?? {} as any;
    if (!Object.keys(items).length) return {};

    if (!!result.length) items = result[0] ?? {};
    if (!Object.entries(items).length) return {};
    items = items.items;

    const metazoaClient = new MetazoaClient(zilswap.network);
    const craftableResult = await metazoaClient.fetchStoreItems();

    const storeCraftables: ZOMGCraftItem[][] = Object.entries(craftableResult.result.store).map(([category, items]) => {
      const itemMap = Object.values(items as any).map((item: any) => {
        const zScrapCost = item.cost.find((cost) => cost.tokenAddress === zScrapAddress) ?? {};
        const hunyCost = item.cost.find((cost) => cost.tokenAddress === hunyAddress) ?? {};
        const berryCost = item.cost.find((cost) => cost.tokenAddress === berryAddress) ?? {};

        let itemCostMap: SimpleMap<BigNumber | number> = {}
        if (Object.keys(zScrapCost).length) itemCostMap['zScrap'] = bnOrZero(zScrapCost.quantity)
        if (Object.keys(hunyCost).length) itemCostMap['huny'] = bnOrZero(hunyCost.quantity)
        if (Object.keys(berryCost).length) itemCostMap['berry'] = bnOrZero(berryCost.quantity)

        for (const indvCost of item.cost) {
          if (indvCost.tokenAddress === itemsAddress) {
            const gemAffinity = indvCost.traits.Affinity;
            if (!itemCostMap[gemAffinity]) {
              itemCostMap = {
                ...itemCostMap,
                [gemAffinity]: 1
              }
            } else {
              const updatedSum = itemCostMap[gemAffinity] as number + 1;
              itemCostMap = {
                ...itemCostMap,
                [gemAffinity]: updatedSum,
              }
            }
          }
        }

        const zomgItem: ZOMGCraftItem = {
          id: item.itemId,
          name: item.itemName,
          address: item.address,
          attributes: item.attributes,
          cost: itemCostMap,
          type: StoreItemType.Craft,
          requirement: item.requirement,
          stats: item.stats,
        }
        return zomgItem;
      })
      return itemMap;
    })
    const combinedCraftables = storeCraftables.flat(1);
    /// Categorize all items
    let store: SimpleMap<ZOMGCraftItem[]> = {}
    combinedCraftables
      .filter((itm: ZOMGCraftItem) => Craftables.includes(itm.attributes.Type))
      .forEach((itm: ZOMGCraftItem) => {
        if ((itm.attributes.Type in store)) store[itm.attributes.Type].push(itm);
        else store[itm.attributes.Type] = [itm];
      });

    store.All = Object.values(store).flat(1)
    logger('debug-connector', `getZOMGCraftables - store`, store);

    return store;
  }

  static craftItem = async (item: ZOMGCraftItem, ownedCGems?: SimpleMap<number[]>) => {
    if (!zilswap) throw new Error('not initialized');

    const zomgContract = fromBech32Address(ContractsBech32[zilswap.network].ZOMG).toLowerCase();
    const hunyAddress = fromBech32Address(ContractsBech32[zilswap.network].HunyToken).toLowerCase();
    const zScrapAddress = fromBech32Address(ContractsBech32[zilswap.network].Scrap).toLowerCase();
    const itemsAddress = fromBech32Address(ContractsBech32[zilswap.network].Items).toLowerCase();
    const itemId = item.id

    const argList = [
      {
        constructor: `${zomgContract}.PaymentItem`,
        argtypes: [],
        arguments: [hunyAddress, "0"]
      },
      {
        constructor: `${zomgContract}.PaymentItem`,
        argtypes: [],
        arguments: [zScrapAddress, "0"]
      }
    ]

    const args = [
      {
        vname: "item_id",
        type: "Uint128",
        value: itemId.toString(),
      },
      {
        vname: "payment_items",
        type: `List ${zomgContract}.PaymentItem`,
        value: argList
      }
    ];

    const filteredGemCost = Object.keys(item.cost)
      .filter(key => Object.keys(STATS).includes(key))
      .reduce((obj, key) => {
        obj[key] = item.cost[key];
        return obj;
      }, {});

    if (Object.keys(filteredGemCost).length !== 0) {
      if (!ownedCGems) throw new Error('No Gems Owned.');
      Object.entries(filteredGemCost).map(([gemType, cost]) => {
        const ownedGemTypeIdList = ownedCGems[gemType];
        const gemsRequired = ownedGemTypeIdList.splice(0, cost as number);
        return gemsRequired.forEach((gemId) => {
          const argItem = {
            constructor: `${zomgContract}.PaymentItem`,
            argtypes: [],
            arguments: [itemsAddress, `${gemId}`]
          }
          argList.push(argItem);
        })
      })
    }

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(zomgContract),
      "CraftItem",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static getTokenContractBonuses = async (tokens: NftMetadata[]) => {
    if (!zilswap) throw new Error('not initialized');

    const getAddressHex = (bech32) => fromBech32Address(bech32).toLowerCase().replace(/^0x/i, "")

    const queries: SimpleRPCRequest[] = [
      { substate: "accuracy_fee_discount", address: getAddressHex(ContractsBech32[zilswap.network].QuestGeode), resultAs: "questGeodeAccBonus" },
      { substate: "accuracy_fee_discount", address: getAddressHex(ContractsBech32[zilswap.network].QuestScrap), resultAs: "questScrapAccBonus" },
      { substate: "accuracy_fee_discount", address: getAddressHex(ContractsBech32[zilswap.network].QuestBerry), resultAs: "questBerryAccBonus" },
      { substate: "mastery_leveling_bonus", address: getAddressHex(ContractsBech32[zilswap.network].QuestGeode), resultAs: "questGeodeMasteryBonus" },
      { substate: "mastery_leveling_bonus", address: getAddressHex(ContractsBech32[zilswap.network].QuestScrap), resultAs: "questScrapMasteryBonus" },
      { substate: "mastery_leveling_bonus", address: getAddressHex(ContractsBech32[zilswap.network].QuestBerry), resultAs: "questBerryMasteryBonus" },
      { substate: "resource_gathering_bonus", address: getAddressHex(ContractsBech32[zilswap.network].QuestGeode), resultAs: "questGeodeGatheringBonus" },
      { substate: "resource_gathering_bonus", address: getAddressHex(ContractsBech32[zilswap.network].QuestScrap), resultAs: "questScrapGatheringBonus" },
      { substate: "resource_gathering_bonus", address: getAddressHex(ContractsBech32[zilswap.network].QuestBerry), resultAs: "questBerryGatheringBonus" },
    ];

    const results: RPCSuccessResult = await batchQuery(queries, (zilswap as any).rpcEndpoint);
    tokens.map((token) => {
      if (Object.keys(results.questGeodeAccBonus).includes(token.id)) {
        const tokenAccBonus = results.questGeodeAccBonus[token.id];
        const tokenMasteryBonus = results.questGeodeMasteryBonus[token.id];
        const tokenGatheringBonus = results.questGeodeGatheringBonus[token.id];
        token.contractBonus = {
          contractAccBonus: bnOrZero(tokenAccBonus).shiftedBy(-1),
          masteryLevelingBonus: bnOrZero(tokenMasteryBonus),
          resourceGatheringBonus: bnOrZero(tokenGatheringBonus),
        }
      }
      if (Object.keys(results.questScrapAccBonus).includes(token.id)) {
        const tokenAccBonus = results.questScrapAccBonus[token.id];
        const tokenMasteryBonus = results.questScrapMasteryBonus[token.id];
        const tokenGatheringBonus = results.questScrapGatheringBonus[token.id];
        token.contractBonus = {
          contractAccBonus: bnOrZero(tokenAccBonus).shiftedBy(-1),
          masteryLevelingBonus: bnOrZero(tokenMasteryBonus),
          resourceGatheringBonus: bnOrZero(tokenGatheringBonus),
        }
      }
      if (Object.keys(results.questBerryAccBonus).includes(token.id)) {
        const tokenAccBonus = results.questBerryAccBonus[token.id];
        const tokenMasteryBonus = results.questBerryMasteryBonus[token.id];
        const tokenGatheringBonus = results.questBerryGatheringBonus[token.id];
        token.contractBonus = {
          contractAccBonus: bnOrZero(tokenAccBonus).shiftedBy(-1),
          masteryLevelingBonus: bnOrZero(tokenMasteryBonus),
          resourceGatheringBonus: bnOrZero(tokenGatheringBonus),
        }
      }
      return token;
    })
    return tokens;
  }

  static consumeElderberryJuice = async (tokenIds: string[], juiceIds: string[]) => {
    if (!zilswap) throw new Error('not initialized');
    const zomgContract = fromBech32Address(ContractsBech32[zilswap.network].ZOMG).toLowerCase();
    const itemsContract = fromBech32Address(ContractsBech32[zilswap.network].Items).toLowerCase();
    const zoaContract = fromBech32Address(ContractsBech32[zilswap.network].NftV2).toLowerCase();

    const data = tokenIds.map((zoaId, idx) => ({
      constructor: `${zomgContract}.ConsumeItem`,
      argtypes: [],
      arguments: [itemsContract, juiceIds[idx], zoaContract, zoaId]
    }));

    const args = [
      {
        vname: "token_consumer_list",
        type: `List ${zomgContract}.ConsumeItem`,
        value: data,
      }
    ];
    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(zomgContract),
      "BatchConsumeItem",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static refineGems = async (outputTier: GemTierType, baseGemId: number, sacrificialGemIds: number[]) => {
    if (!zilswap) throw new Error('not initialized');
    if (!sacrificialGemIds.length) throw new Error('No material gems provided');

    logger("debug-connector", "refineGems-START", {
      outputTier,
      baseGemId,
      sacrificialGemIds
    });

    const gemContract = fromBech32Address(ContractsBech32[zilswap.network].GemRefinery).toLowerCase();
    const materialGemIds: string[] = Array.from(sacrificialGemIds, x => `${x}`)
    const args = [
      {
        vname: `output_tier`,
        type: `${gemContract}.GemTier`,
        value: {
          constructor: `${gemContract}.Tier${outputTier}`,
          argtypes: [],
          arguments: []
        },
      },
      {
        vname: `base_gem_token_id`,
        type: `Uint256`,
        value: baseGemId.toString(),
      },
      {
        vname: `material_gem_token_ids`,
        type: `List Uint256`,
        value: materialGemIds,
      },
    ];

    logger("debug-connector", "refineGems-END", {
      outputTier,
      baseGemId,
      materialGemIds
    }, args);

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(gemContract),
      "BeginGemEnhancement",
      args as any,
      callParams,
      true,
    );

    return result;
  }

  static getBlkTimestamp = async (
    blockNum: number,
  ): Promise<number> => {
    if (!zilswap) throw new Error('not initialized');
    const dsBlock = await zilswap.zilliqa.blockchain.getTxBlock(blockNum);
    const timestamp = dsBlock.result?.header?.Timestamp
    // timestamp is in milliseconds, hence convert to microseconds (standard convention)
    return (bnOrZero(timestamp).div(1000)).toNumber()
  }

  static transferEquipment = async (
    zoaId: number,
    itemsIds: number[],
    toParent: boolean = true,
  ) => {
    if (!zilswap) throw new Error('not initialized');
    if (!itemsIds.length) throw new Error('No items provided');

    const metazoaContract = fromBech32Address(ContractsBech32[zilswap.network].NftV2).toLowerCase();
    const itemsContract = fromBech32Address(ContractsBech32[zilswap.network].Items).toLowerCase();

    const params = itemsIds.map(id => ({
      constructor: "Pair",
      argtypes: [
        "Uint256",
        "Pair Uint256 ByStr20"
      ],
      arguments: [
        id.toString(),
        {
          constructor: "Pair",
          argtypes: [
            "Uint256",
            "ByStr20"
          ],
          arguments: [
            zoaId.toString(),
            metazoaContract,
          ]
        }
      ]
    }));


    const args = [
      {
        vname: toParent ? "item_id_to_token_id_to_contract_list" : "item_id_from_token_id_from_contract_list",
        type: `List (Pair Uint256 (Pair Uint256 ByStr20))`,
        value: params,
      }
    ];

    const minGasPrice = (await zilswap.zilliqa.blockchain.getMinimumGasPrice()).result as string;
    const callParams = {
      amount: new BN("0"),
      gasPrice: new BN(minGasPrice),
      gasLimit: Long.fromNumber(20000),
      version: bytes.pack(CHAIN_IDS[zilswap.network], MSG_VERSION),
    };

    const result = await zilswap.callContract(
      zilswap.getContract(itemsContract),
      toParent ? "BatchTransferToParent" : "BatchTransferFromParent",
      args as any,
      callParams,
      true,
    );

    return result;

  }
}

