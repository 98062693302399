import React, { Fragment, useState } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import BoxWithHeader from "components/BoxWithHeader";
import ContainedButton from "components/ContainedButton";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";
import { DisplayStaked, ReturnDialog, SendDialog } from "./components";

const ArmyStake: React.FC = () => {
  const [showSendDialog, setShowSendDialog] = useState<boolean>(false);
  const [showReturnDialog, setShowReturnDialog] = useState<boolean>(false);
  const tokenState = useRedux((state) => state.token);
  const metazoaTokens = Object.values(tokenState.metazoaTokens);
  const stakedMetazoa = Object.values(tokenState.stakedMetazoa);

  const handleShowSendDialog = () => {
    setShowSendDialog(true);
  }

  const handleShowReturnDialog = () => {
    setShowReturnDialog(true);
  }

  return (
    <Fragment>
      <Box sx={styles.stakeContainer}>
        {/* At base */}
        <BoxWithHeader
          headerText={
            <Typography variant="h3" color="primary" component="span">
              AT BASE
              <Typography variant="body1" color="secondary" component="span">&nbsp;&nbsp;&bull;&nbsp;&nbsp;Unstaked Metazoa</Typography>
            </Typography>
          }
          sx={styles.stakeBox}
          showBackground={!metazoaTokens.length}
        >
          <DisplayStaked metazoaTokens={metazoaTokens} />
          <ContainedButton variant="contained" color="secondary" sx={styles.button} onClick={handleShowSendDialog} disabled={!metazoaTokens.length}>
            SEND FOR BATTLE
          </ContainedButton>
        </BoxWithHeader>

        {/* In battle */}
        <BoxWithHeader
          headerText={
            <Typography variant="h3" color="primary" component="span">
              IN BATTLE
              <Typography variant="body1" color="secondary" component="span">&nbsp;&nbsp;&bull;&nbsp;&nbsp;Staked Metazoa</Typography>
            </Typography>
          }
          componentsRight={
            <TooltipZolar link={"https://docs.zolar.io/usdhuny/hunynomics#lockingmodel"} sx={styles.tooltip}>
              Staked Metazoa can only be unlocked when they have gathered 2 days’ worth of unclaimed $HUNY
            </TooltipZolar>
          }
          sx={styles.stakeBox}
          showBackground={!stakedMetazoa.length}
        >
          <DisplayStaked staked={true} metazoaTokens={stakedMetazoa} />
          <ContainedButton
            sx={styles.button}
            onClick={handleShowReturnDialog}
            disabled={!stakedMetazoa.length}
          >
            <Typography variant="button">RETURN TO BASE</Typography>
          </ContainedButton>
        </BoxWithHeader>
      </Box>

      <SendDialog
        open={showSendDialog}
        onClose={() => setShowSendDialog(false)}
      />
      <ReturnDialog
        open={showReturnDialog}
        onClose={() => setShowReturnDialog(false)}
      />
    </Fragment>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  stakeContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
    columnGap: '10px',
    "@media (max-width:1040px)": {
      flexDirection: 'column',
      rowGap: '10px',
    },
  },
  stakeBox: {
    flex: "1 1 0",
  },
  tooltip: {
    padding: '20px',
    width: '420px',
  },
  button: {
    height: 60,
    marginTop: '40px',
    width: '100%',
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  }
}

export default ArmyStake;
