import { Box, styled } from "@mui/material";
import BaseMapBackground from "assets/quests/BaseMapBackground.png";
import BetaWarning from "components/BetaWarning";
import { SnackbarProvider } from "notistack";
import React, { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import useRedux from "utils/useRedux";
import { Footer, Header } from "./components";
import NavDrawer from "./components/NavDrawer";
import SnackbarContentItem from "./components/SnackbarContentItem";

const Main = styled("main")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  background: "linear-gradient(180deg, #010025 0%, #000000 100%)",
  display: "flex",
  flexDirection: "column",
  overflowY: "hidden",
  overflowX: "hidden",
  position: "relative",
  maxWidth: "100%",
  minHeight: '1200px',
  '@media(max-width: 600px)': {
    overflowX: 'auto',
  }
}));

const SvgTextureBox = styled(Box)({
  position: "absolute",
  top: "0%",
  left: "0%",
  height: "100%",
  width: "100%",
  minWidth: '650px',
  opacity: "60%",
  pointerEvents: 'none',

  // PLACEHOLDER: Pending dedicated exports
  backgroundImage: `url(${BaseMapBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
});

const QuestLayout: FC = (props: any) => {
  const { children, ...rest } = props;
  const dispatch = useDispatch();
  const showNavDrawer = useRedux((state) => state.layout.showNavDrawer);
  const mapBackground = useRedux((state) => state.layout.backgroundImgSrc);
  const ref = useRef() as React.MutableRefObject<SnackbarProvider>;
  const onCloseDrawer = () => {
    dispatch(actions.Layout.toggleShowNavDrawer("close"));
  };
  return (
    <Main {...rest} >
      <SvgTextureBox sx={{
        backgroundImage: `url(${(!!mapBackground.length) ? mapBackground : BaseMapBackground})`,
      }} />
      <SnackbarProvider
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        ref={ref}
        content={(key, message) => {
          if (!message) return
          let msgContent = JSON.parse(message!.toString());
          const { hash, content } = msgContent;
          return (<SnackbarContentItem snackKey={key} message={content} hash={hash} providerRef={ref} />)
        }}
        maxSnack={3}
      >
        <Header />
        <BetaWarning />
        {children}
        <Footer hideFloor />
        <NavDrawer open={showNavDrawer} onClose={onCloseDrawer} />
      </SnackbarProvider>
    </Main>
  );
};

export default QuestLayout;
