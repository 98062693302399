import { Box, CircularProgress, LinearProgress, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import BigNumber from "bignumber.js";
import BoxWithHeader from "components/BoxWithHeader";
import ContainedButtonSmall from "components/ContainedButtonSmall";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import { logger } from "core/utilities";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Guild, HunyPot } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useToaster } from "utils";
import { BIG_ONE, Decimals, DefaultDateFormat } from "utils/constants";
import { bnOrZero } from "utils/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useBlockTime from "utils/useBlockTime";
import useRedux from "utils/useRedux";
import ClaimHunyDialog from "./ClaimHunyDialog";

export interface Props {
  guild: Guild
}

const HunyPots: React.FC<Props> = (props: Props) => {
  const { guild } = props;
  const { guildBank } = guild;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [totalHuny, setTotalHuny] = useState<number>(0);
  const [harvestProgress, setHarvestProgress] = useState<number>(0);
  const [block, setBlock] = useState<number | null>(null);
  const [hunyPots, setHunyPots] = useState<SimpleMap<HunyPot>>();
  const refineryConfig = useRedux((state) => state.token.refineryConfig);
  const refineryHunyStats = useRedux((state) => state.token.refineryHunyStats);
  const [blockTime, currentBlock] = useBlockTime();
  const toaster = useToaster();
  const [runFetchGuildHunyPots, loadingFetchGuildHunyPots] = useAsyncTask("fetchGuildHunyPots", (error) => {
    toaster(error?.message ?? "Error fetching huny pots");
  });

  useEffect(() => {
    if (!guildBank || !guildBank.address) return;
    runFetchGuildHunyPots(async () => {
      const result: SimpleMap = await TBMConnector.getGuildBankHunyPots(toBech32Address(guildBank.address));
      const hunyPots: SimpleMap<HunyPot> = {};
      for (const startBlock in result) {
        const startBlkNum = bnOrZero(startBlock).toNumber()
        const startTimestamp: number = await TBMConnector.getBlkTimestamp(startBlkNum)
        hunyPots[startBlock] = new HunyPot(
          startBlkNum,
          startTimestamp,
          bnOrZero(result[startBlock]),
        );
      }
      setHunyPots(hunyPots);
    })
    // eslint-disable-next-line
  }, [guildBank])

  const sortedHunyPots = useMemo(() => {
    if (!hunyPots) return undefined;
    return Object.values(hunyPots).sort((lhs, rhs) => rhs.startBlock - lhs.startBlock);
  }, [hunyPots]);

  const handleClaimHuny = (hunyPot: HunyPot) => {
    const progressPercentage = hunyPot.progress(currentBlock, refineryConfig?.immediateRefine).toNumber();

    setBlock(hunyPot.startBlock);
    setTotalHuny(hunyPot.amount.toNumber());
    setHarvestProgress(progressPercentage);
    setShowDialog(true);
  }

  return (
    <Fragment>
      <BoxWithHeader
        width="100%"
        headerText={<Typography variant="h3" color="primary">POTS</Typography>}
      // Update this to add filter
      // componentsRight={<Typography variant="h3" color="primary">Show Claimed</Typography>}
      >
        <TableContainer sx={styles.tableContainer}>
          <Table>
            <TableHead sx={styles.tableHead}>
              <TableRow>
                {/* Gen */}
                <TableCell width="20%">
                  <Typography variant="body1" color="primary">Harvest Date</Typography>
                </TableCell>

                {/* Metazoa */}
                <TableCell width="15%">
                  <Typography variant="body1" color="primary">HUNY Balance</Typography>
                </TableCell>

                {/* Huny */}
                <TableCell width="25%" align="right">
                  <Typography variant="body1" color="primary" component="span" sx={styles.tableHeadCell}>
                    <TooltipZolar link="https://docs.zolar.io/stations/refinery#usdhuny-early-claim-fee">
                      HUNY in the Refinery needs to undergo a refinement process over 365 days.
                      Early claiming will result in a HUNY Early Claim Fee.
                    </TooltipZolar>
                    &nbsp;Refine Progress
                  </Typography>
                </TableCell>

                <TableCell width="18%" align="right">
                  <Typography variant="body1" color="primary">
                    Unlock Date
                  </Typography>
                </TableCell>

                <TableCell width="20%" align="center">
                  <Typography variant="body1" color="primary">
                    Action
                  </Typography>
                </TableCell>

              </TableRow>
            </TableHead>

            <TableBody sx={styles.tableBody}>
              {sortedHunyPots?.map((hunyPot) => {
                const startDate = hunyPot.getStartDate()
                const endDate = hunyPot.estCompleteDate(currentBlock, blockTime, refineryConfig?.refineDuration);
                const daysLeft = hunyPot.estDaysLeft(currentBlock, refineryConfig?.refineDuration);
                const progress = hunyPot.progress(currentBlock, refineryConfig?.immediateRefine).shiftedBy(2);
                let hunyBalance = hunyPot.amount;
                if (refineryHunyStats?.totalShare?.gt(0) && refineryHunyStats.totalSupply) {
                  hunyBalance = hunyPot.amount.div(refineryHunyStats.totalShare).times(refineryHunyStats.totalSupply);
                }

                const claimCompletionPercentage: number = Math.min(progress.toNumber(), 100);
                const negligiblity: BigNumber = BIG_ONE.shiftedBy(-4)
                const isNegligible: boolean = (hunyBalance.shiftedBy(-Decimals.HUNY)).lte(negligiblity)

                logger('pots-', {
                  balance: hunyBalance.shiftedBy(-Decimals.HUNY).toNumber(),
                  isNegligible,
                  startDate: startDate?.format(DefaultDateFormat),
                })
                return (
                  <TableRow key={hunyPot.startBlock}>
                    {/* Harvest Date */}
                    <TableCell>
                      <Typography variant="body1" color="primary">
                        {
                          loadingFetchGuildHunyPots
                            ? <CircularProgress size={18} />
                            : startDate?.format(DefaultDateFormat) ?? "-"
                        }
                      </Typography>
                    </TableCell>
                    {/* Huny Balance */}
                    <TableCell>
                      <Typography variant="body1" color="primary">
                        {
                          loadingFetchGuildHunyPots
                            ? <CircularProgress size={18} />
                            : isNegligible ? `<${negligiblity.toString()}` : hunyBalance.shiftedBy(-Decimals.HUNY).toFormat(4)
                        }
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1, color: 'grey.500' }}>
                          {
                            loadingFetchGuildHunyPots
                              ? <CircularProgress size={18} />
                              : <LinearProgress variant="determinate" value={claimCompletionPercentage} color="inherit" sx={styles.refineProgressBar} />
                          }
                        </Box>
                        <Box>
                          <Typography variant="body2" color="success.main">{loadingFetchGuildHunyPots ?? claimCompletionPercentage.toFixed(2)}%</Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {
                        loadingFetchGuildHunyPots
                          ? <CircularProgress />
                          : <>
                            <Typography variant="body1" color="primary">{endDate?.format(DefaultDateFormat) ?? "-"}</Typography>
                            <Typography variant="body1" color="secondary.light">{daysLeft > 0 ? daysLeft.toFixed(0) : "-"} days left</Typography>
                          </>
                      }

                    </TableCell>
                    <TableCell align="center">
                      <ContainedButtonSmall sx={styles.button} onClick={() => handleClaimHuny(hunyPot)}>{progress.lt(100) ? "Early Claim" : "Claim"}</ContainedButtonSmall>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </BoxWithHeader>
      <ClaimHunyDialog
        block={block ?? 0}
        totalHuny={totalHuny}
        percentageComplete={harvestProgress}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        guild={guild}
      />
    </Fragment>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  tableContainer: {
    overflowY: "auto",
    maxHeight: "300px",
    paddingLeft: '20px',
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "transparent",
      top: "",
      left: "",
    },
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: "10px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },
  tableHead: {
    "& th.MuiTableCell-root": {
      padding: "8px 20px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: "8px 20px",
      borderColor: "rgba(174, 241, 238, 0.1)",
      whiteSpace: "nowrap",
    },
  },
  tableHeadCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    width: '180px',
    height: '42px',
    fontSize: '1.125rem',
  },
  refineProgressBar: {
    '& .MuiLinearProgress-root': {
      backgroundColor: '#000',
    },
    '& .MuiLinearProgress-bar': {
      background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
    },
  }
}

export default HunyPots;
