import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, FormHelperText, IconButton, Link, SvgIcon, SxProps, TextField, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { AddIcon, CheckedIcon, ExternalLink, Huny, RemoveIcon, WarningRed, ZilliqaLogo, ZilTokenIcon } from "assets";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import ExchangeRate from "components/ExchangeRate";
import { NumberFormat } from "components/Guild/components/GuildBank/components/BankSettings/components";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { waitForTx } from "core/utilities";
import { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { Guild, HivePoolStats } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useRedux, useToaster } from "utils";
import { BIG_ZERO, Decimals } from "utils/constants";
import { bnOrZero, formatGuildHuny, getExplorerLink } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import whoseSignature from "utils/whoseSignature";

export interface ModalProps {
  bankHive?: HivePoolStats;
  open: boolean;
  onClose: () => void;
  guild: Guild;
}

const initialFormState = {
  hunyAmount: new BigNumber(0),
  zilAmount: new BigNumber(0),
  hunyAmountInput: "0",
  zilAmountInput: "0",
}

export type Errors = {
  hunyAmount: string,
  zilAmount: string,
}

const AddLiquidityDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, bankHive, guild } = props;
  const { guildBank } = guild;
  const dispatch = useDispatch();
  const wallet = useRedux((state) => state.wallet.wallet);
  const toaster = useToaster();
  const network = useNetwork();
  const tokenState = useRedux((state) => state.token);
  const hunyBalance = new BigNumber(guildBank?.netWorth?.hunyTokenAmt ?? BIG_ZERO);
  const zilBalance = new BigNumber(guildBank?.netWorth?.zilTokenAmt ?? BIG_ZERO);
  const gasFee = tokenState.GasFee;
  const zilPrice = tokenState.ExchangeRates.zilPrice;
  const hunyPrice = tokenState.ExchangeRates.hunyPrice;
  const [check, setCheck] = useState<boolean>(false);
  const [requestTx, setRequestTx] = useState<string>("");
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [errors, setErrors] = useState<Errors>({
    hunyAmount: "",
    zilAmount: "",
  })
  const [runAddLiquidity, loadingAddLiquidity] = useAsyncTask("poolAddLiquidity", (error) => {
    toaster(error?.message ?? "Error Adding Liquidity");
  });
  const [addLiquidityCompleted, setAddLiquidityCompleted] = useState<boolean>(false);

  const {
    newPoolShare,
    newPoolValue,
  } = useMemo(() => {
    let newPoolShare = "-";
    let newPoolValue = "-";
    const rates = tokenState.ExchangeRates;
    const { zilAmount } = formState;
    const zilAmountUnitless = zilAmount.shiftedBy(Decimals.ZIL);
    const newZilReserves = bankHive?.zilReserves.plus(zilAmountUnitless);
    const newUserZilReserves = bankHive?.userZilReserves.plus(zilAmountUnitless);
    if (!newZilReserves?.gt(0)) return { newPoolShare, newPoolValue };
    newPoolShare = newUserZilReserves?.div(newZilReserves).shiftedBy(2).toFormat(2) ?? "-";

    const hunyAmountUnitless = zilAmount.shiftedBy(Decimals.HUNY);
    const newUserHunyReserves = bankHive?.userHunyReserves.plus(hunyAmountUnitless);
    const newUserZilValue = newUserZilReserves?.shiftedBy(-Decimals.ZIL).times(rates.zilPrice);
    const newUserHunyValue = newUserHunyReserves?.shiftedBy(-Decimals.HUNY).times(rates.hunyPrice);
    newPoolValue = newUserZilValue?.plus(newUserHunyValue ?? BIG_ZERO).toFormat(2) ?? "-";

    return { newPoolShare, newPoolValue };
  }, [bankHive, formState, tokenState.ExchangeRates]);

  const validateInput = (field: string, input: string) => {
    const amt = bnOrZero(input);

    switch (field) {
      case "hunyAmount":
        if (amt.isGreaterThan(hunyBalance)) return "The amount exceeds your bank balance.";
        return ""
      case "zilAmount":
        if (amt.isGreaterThan(zilBalance)) return "The amount exceeds your bank balance.";
        return ""
      default: return "";
    }
  }

  const handleAddQtyHuny = () => {
    hunyInputHandler(formState.hunyAmount.plus(1).toString(10));
  };

  const handleSubtractQtyHuny = () => {
    if (formState.hunyAmount.isLessThanOrEqualTo(0))
      return;

    hunyInputHandler(formState.hunyAmount.minus(1).toString(10));
  };

  const handleAddQtyZil = () => {
    zilInputHandler(formState.zilAmount.plus(1).toString(10));
  };

  const handleSubtractQtyZil = () => {
    if (formState.hunyAmount.isLessThanOrEqualTo(0))
      return;

    zilInputHandler(formState.zilAmount.minus(1).toString(10));
  };

  const onHunyInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    hunyInputHandler(event.target.value);
  };

  const onZilInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    zilInputHandler(event.target.value);
  };

  const hunyInputHandler = (hunyInput: string) => {
    const bnHunyAmount = bnOrZero(hunyInput);

    if (typeof hunyInput === "string") {
      const errorText = validateInput("hunyAmount", hunyInput);
      setErrors((prev) => ({ ...prev, hunyAmount: errorText }));
    }

    const rate = zilPrice.gt(0) ? hunyPrice.div(zilPrice) : 1;
    const bnZilAmount = bnHunyAmount.times(rate).decimalPlaces(Decimals.ZIL);
    setFormState({
      ...formState,

      hunyAmount: bnHunyAmount,
      zilAmount: bnZilAmount,

      hunyAmountInput: hunyInput,
      zilAmountInput: bnZilAmount.toString(10),
    })
  };

  const zilInputHandler = (zilInput: string) => {
    const bnZilAmount = bnOrZero(zilInput);

    if (typeof zilInput === "string") {
      const errorText = validateInput("zilAmount", zilInput);
      setErrors((prev) => ({ ...prev, zilAmount: errorText }));
    }

    const rate = hunyPrice.gt(0) ? zilPrice.div(hunyPrice) : 1;
    const bnHunyAmount = bnZilAmount.times(rate).decimalPlaces(Decimals.HUNY);
    setFormState({
      ...formState,
      hunyAmount: bnHunyAmount,
      zilAmount: bnZilAmount,

      hunyAmountInput: bnHunyAmount.toString(10),
      zilAmountInput: zilInput,
    })
  };

  const handleCheck = () => {
    setCheck(!check);
  };

  const handleMaxHuny = () => {
    hunyInputHandler(hunyBalance.toString(10));
  };

  const handleMaxZil = () => {
    zilInputHandler(zilBalance.minus(gasFee).toString(10));
  };

  const customTotalValue = useMemo(() => {
    return ((formState.hunyAmount).times(hunyPrice)).plus(formState.zilAmount.times(zilPrice));
  }, [formState, hunyPrice, zilPrice]);

  const handleAddLiquidity = () => {
    if (!guildBank || !guildBank.address) return;

    if (!!guildBank.pendingRequest) {
      toaster(`Please approve or cancel current multisig transaction first!`);
      return;
    }

    runAddLiquidity(async () => {
      const addLiquidityTx = await TBMConnector.addGuildBankHive(toBech32Address(guildBank.address), formState.hunyAmount, formState.zilAmount)
      toaster(`Initiated Add Liquidity `, { hash: addLiquidityTx.id! })
      if (addLiquidityTx.isRejected() || !addLiquidityTx.id) {
        onClose();
        throw new Error("Submitted transaction was rejected.");
      }

      const tx = await waitForTx(addLiquidityTx.id);
      const tbmConnector = TBMConnector.getSDK();
      const txn = await tbmConnector.zilliqa.blockchain.getTransaction(addLiquidityTx.id);
      const receipt = txn.getReceipt();
      if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

      setRequestTx(addLiquidityTx.id!)
      setAddLiquidityCompleted(true);
      toaster(`Liquidity Initiated `, { hash: addLiquidityTx.id!, overridePersist: true })
      dispatch(actions.Guild.reloadGuild(guild));
      dispatch(actions.Token.reloadHivePool());
    });
  }

  const handleOnClose = () => {
    setFormState(initialFormState);
    setCheck(false);
    setRequestTx("")
    setAddLiquidityCompleted(false);
    onClose();
  }

  const getHeader = () => {
    if (addLiquidityCompleted) return "Add Liquidity Success";
    return "Add Liquidity"
  }

  const disableAddLiquidity = (!check || loadingAddLiquidity || addLiquidityCompleted || formState.hunyAmount.eq(0) || !!errors.hunyAmount || !!errors.zilAmount)
  const getContent = () => {
    if (!guild || !guildBank || !wallet) return;

    if (addLiquidityCompleted) {
      return (
        <Box sx={combineStyles(styles.dialogContent, styles.multisigContent)}>
          {
            (guild.guildBank?.controlMode === "CaptainOnly") && (wallet?.addressInfo.byte20.toLowerCase() === guild.leaderAddress)
              ? (<>
                <Typography variant="body1" color="primary" className="joinInfo">
                  You’ve successfully&nbsp;<span className="highlightText">added</span><br />

                  <span style={{ marginTop: '10px', display: 'block' }}>
                    <span className="hunyAmount">
                      <Huny width={40} height={40} />
                      {formatGuildHuny(formState.hunyAmount)}
                    </span>
                    &nbsp;<AddIcon height={12} width={12} />&nbsp;
                    <span className="hunyAmount">
                      <ZilTokenIcon width={40} height={40} />
                      {formatGuildHuny(formState.zilAmount)}
                    </span>
                    &nbsp;to Magic Hive!
                  </span>
                </Typography>

                {requestTx && (
                  <Link
                    target="_blank"
                    href={getExplorerLink("tx", requestTx, network)}
                    sx={styles.viewTx}
                  >
                    View Transaction
                    <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
                  </Link>
                )}
                <ContainedButton
                  variant="contained"
                  color="secondary"
                  onClick={handleOnClose}
                  sx={{ marginBottom: '10px', }}
                >
                  Back to Guild Bank
                </ContainedButton>
              </>)
              : (<>
                <Typography sx={styles.subText} color="primary" mt="20px">
                  <Typography sx={styles.signaturesRequiredText} component="span">{whoseSignature(wallet.addressInfo.byte20.toLowerCase(), guild)}</Typography>
                  &nbsp;required for this transaction to go through.
                </Typography>

                {requestTx && (
                  <Link
                    target="_blank"
                    href={getExplorerLink("tx", requestTx, network)}
                    sx={styles.viewTx}
                  >
                    View Transaction
                    <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
                  </Link>
                )}

                <Box sx={styles.multisigButtonRow}>
                  <ContainedButton
                    sx={styles.multisigButton}
                    onClick={handleOnClose}
                  >
                    Back to guild bank
                  </ContainedButton>
                </Box>
              </>)
          }
        </Box >
      )
    }
    return (
      <Fragment>
        <Typography variant='body1' color='primary' sx={styles.subtitle}>
          Welcome to the Magic Hive! Put your guild’s HUNY to work and earn rewards via the options below.
        </Typography>
        <Box sx={styles.customWrapper}>
          <Box sx={styles.customRow}>
            <Typography variant="h3" color="primary" component="span" sx={styles.customCurrency}>
              <Huny width="40px" height="40px" />
              &nbsp;&nbsp;&nbsp;HUNY
            </Typography>
            <Box sx={styles.balanceBox}>
              <Box sx={combineStyles(styles.input, styles.numInputBox, { ...(errors['hunyAmount']) && styles.errorInput })}>

                <IconButton onClick={handleSubtractQtyHuny}>
                  <RemoveIcon style={{ height: 16, width: 16 }} />
                </IconButton>

                <Box sx={styles.numInput}>
                  <TextField
                    name={'huny'}
                    onChange={onHunyInputChange}
                    value={formState.hunyAmount.toString()}
                    InputProps={{ inputComponent: NumberFormat.NumberFormatSeparator as any }}
                    sx={combineStyles(styles.input, styles.settingNumInput)} />
                </Box>

                <IconButton onClick={handleAddQtyHuny}>
                  <AddIcon style={{ height: 16, width: 16 }} />
                </IconButton>
              </Box>
              {errors.hunyAmount &&
                <Box sx={styles.errorTextContainer}>
                  <WarningRed width={15} />
                  <FormHelperText sx={styles.errorText}>{errors.hunyAmount}</FormHelperText>
                </Box>}
              <Typography variant="body1" color="primary" component="span" sx={styles.customBalanceRow}>
                Balance: {hunyBalance.toFormat(4)} HUNY (≈${hunyPrice.times(hunyBalance).toFormat(2)}) &nbsp;
                <Button variant="outlined" size="small" color="secondary" onClick={handleMaxHuny} sx={styles.maxButton}>
                  <Typography variant="button" sx={styles.gradientText}>
                    MAX
                  </Typography>
                </Button>
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.customRow}>
            <Typography variant="h3" color="primary" component="span" sx={styles.customCurrency}>
              <ZilliqaLogo width="40px" height="40px" />
              &nbsp;&nbsp;&nbsp;ZIL
            </Typography>
            <Box sx={styles.balanceBox}>
              <Box sx={combineStyles(styles.input, styles.numInputBox, { ...(errors['zilAmount']) && styles.errorInput })}>
                <IconButton onClick={handleSubtractQtyZil}>
                  <RemoveIcon style={{ height: 16, width: 16 }} />
                </IconButton>
                <Box sx={styles.numInput}>
                  <TextField
                    name={'zil'}
                    onChange={onZilInputChange}
                    value={formState.zilAmount.toString()}
                    InputProps={{ inputComponent: NumberFormat.NumberFormatSeparator as any }}
                    sx={combineStyles(styles.input, styles.settingNumInput)} />
                </Box>

                <IconButton onClick={handleAddQtyZil}>
                  <AddIcon style={{ height: 16, width: 16 }} />
                </IconButton>
              </Box>
              {errors.zilAmount &&
                <Box sx={styles.errorTextContainer}>
                  <WarningRed width={15} />
                  <FormHelperText sx={styles.errorText}>{errors.zilAmount}</FormHelperText>
                </Box>}
              <Typography variant="body1" color="primary" component="span" sx={styles.customBalanceRow}>
                Balance: {zilBalance.toFormat(4)} ZIL (≈${zilPrice.times(zilBalance).toFormat(2)}) &nbsp;
                <Button variant="outlined" size="small" color="secondary" onClick={handleMaxZil} sx={styles.maxButton}>
                  <Typography variant="button" sx={styles.gradientText}>
                    MAX
                  </Typography>
                </Button>
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.customRow}>
            <Typography variant="body1" color="primary">Total</Typography>
            <Typography variant="h3" color="primary">${customTotalValue.toFormat(2)}</Typography>
          </Box>
          <Divider sx={styles.divider} />
        </Box>
        <ExchangeRate my={2} />
        <Box sx={styles.rateRow}>
          <Typography variant="body1" color="primary">New Pool Share</Typography>
          <Typography variant="h3" color="primary">${newPoolValue} ({newPoolShare}%)</Typography>
        </Box>

        <Box sx={styles.readGuideCheck}>
          <FormControlLabel
            sx={styles.checkFormControl}
            control={<Checkbox
              checkedIcon={<CheckedIcon />}
              onClick={handleCheck}
              sx={{ color: '#fff', "& svg": { width: 24, height: 24 } }}
            />}
            label={
              <Typography variant="body1" color="primary" component="span" sx={styles.checkText}>
                I have read the <Link href="https://docs.zolar.io/stations/magic-hive" target="_blank">Zolar Pools Guide</Link> and understand the risks of providing
                liquidity including impermanent loss.
              </Typography>
            }
          />
        </Box>

        <Box sx={styles.buttonRow}>
          <ContainedButton
            sx={styles.button}
            disabled={disableAddLiquidity}
            onClick={handleAddLiquidity}
          >
            {loadingAddLiquidity
              ? <CircularProgress size={18} />
              : "initiate add liquidity"
            }
          </ContainedButton>
        </Box>
      </Fragment>
    );
  }

  return (
    <DialogModal header={getHeader()} open={open && !!guildBank} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true} dialogOverwrites={styles.dialogOverwrite}>
      <Box>
        {getContent()}
      </Box>
    </DialogModal>

  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "center",
    padding: "0 40px",
    gap: '32px',

    "> *": {
      width: '100%',
    },
  },
  multisigContent: {
    "> *": {
      width: 'auto',
    },

    ".joinInfo": {
      textAlign: 'center',
      fontSize: '24px',
      lineHeight: '150%',

      ".hunyAmount": {
        display: 'inline-flex',
        placeContent: 'center',
        placeItems: 'center',
        verticalAlign: 'middle',
        gap: '8px',
        flexWrap: 'nowrap',

        fontWeight: 700,
        ...GUILD_LIGHTGRAY_GRADIENT,
      },

      ".warningText": {
        color: "#ff8952",
      },

      ".highlightText": {
        color: '#2FF583',
      },

      "button": {
        padding: '0 4px',
      }
    },

  },
  viewTx: {
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: 600,
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  subText: {
    textAlign: 'center',
  },
  signaturesRequiredText: {
    color: "#ff8952",
  },
  multisigButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px',
    gap: '32px',
    alignItems: 'center',
    placeContent: 'center'
  },
  multisigButton: {
    minWidth: '250px',
    flex: 1,
    height: 64,
  },
  dialogOverwrite: {
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  subtitle: {
    fontSize: '1.5rem',
    lineHeight: '2.25rem',
    marginBottom: '30px',
    textAlign: 'center'
  },

  divider: {
    background: 'rgba(174, 241, 238, 0.1)',
    marginY: '20px',
  },

  balanceRow: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  balanceBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  customHunyContainer: {
    padding: '40px',
    paddingBottom: '20px',
    "@media (max-width:900px)": {
      padding: '20px',
    },
  },
  customWrapper: {
    marginBottom: '40px',
  },
  customRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  customCurrency: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '40px',
  },

  customBalanceRow: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rateRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginY: '20px',
    alignItems: 'center',
  },
  readGuideCheck: {
    width: '100%',
    padding: '20px 30px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  checkText: {
    marginLeft: '10px',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: '20px',
    alignItems: 'center',
  },
  button: {
    minWidth: '250px',
    width: '48%',
    height: 60,
  },
  maxButton: {
    borderRadius: '16px',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },
  checkFormControl: {
    marginX: 0,
  },
  checkedIcon: {
    height: 24,
    width: 24,
  },
  errorText: {
    fontFamily: "Prompt",
    color: "#F65E5E",
    marginX: 0,
    marginLeft: "8px",
  },
  errorTextContainer: {
    display: "flex",
    flexDirection: "row",
    placeItems: "flex-start",
    alignItems: "flex-start",
  },
  // Numerical Input
  formField: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'end',
    placeItems: 'center',
    flex: 1,

    "*": {
      height: '100%',
    }
  },
  numInputBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    placeContent: 'center',
    padding: '0 5%',
    width: "100%",
    gap: '24px',
  },
  numInput: {
    flex: 1,
    width: '100%',
  },
  settingNumInput: {
    background: 'transparent',
    border: 0,
    flex: 0.4,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',

    "& input": {
      paddingX: '0',
      width: "100%",
      textAlign: "center",
    },
  },
  input: {
    minHeight: "90px",
    borderColor: "transparent",
    borderRadius: "16px",
    border: "1px solid rgba(174, 241, 238, 0.1)",
    background: "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",

    "&.Mui-focused": {
      borderColor: "#AEF1EE",
      caretColor: "#AEF1EE",
    },

    "&:hover, &:active, &:focus, &:focus-within": {
      borderColor: '#AEF1EE',
    },

    "& input": {
      fontFamily: "Prompt",
      fontSize: "24px",
      lineHeight: "36px",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: 600,
      paddingY: "12px",

      "&:active, &:focus": {
        color: "#AEF1EE",
        "& input": {
          color: "#AEF1EE",
        },
      }
    },
  },
  errorInput: { borderColor: "#F75E5E!important", },
}

export default AddLiquidityDialog;