export const palette = {
  primary: {
    main: "#FEFEFE",
    light: "#B3DCD4",
    contrastText: "#FEFEFE",
  },
  secondary: {
    main: "#AEF1EE",
    light: "#FF8952",
    contrastText: "#010025",
  },
  success: {
    main: "#2FF583",
  },
  error: {
    main: "#FF2F2F",
  },
  warning: {
    main: "#FF8952",
  }
};
