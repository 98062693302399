import { Box, Button, Container, styled, SxProps, Typography } from "@mui/material";
import { MissionStars } from "assets";
import OrbHuny from 'assets/homepage/OrbHuny.svg';
import OrbMetazoa from 'assets/homepage/OrbMetazoa.svg';
import OrbZOrdOH1 from 'assets/homepage/OrbZOrdOH1.svg';
import HunyFrame from "assets/HunyFrame.png";
import HunyAnimation from "assets/lotties/Huny.json";
import { TEXT_GRADIENT } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import React, { useMemo } from "react";
import Lottie from "react-lottie";
import { theme } from "theme";
import { strings } from "utils";
import { ContractsBech32 } from "utils/constants";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";

const Huny: React.FC = () => {
  const network = useRedux(state => state.blockchain.network);
  const hunyContract = useMemo(() => ContractsBech32[network].HunyToken, [network]);
  return (
    <Container maxWidth="xl" sx={styles.container} component="section">
      <SvgBox component="aside" id="SVG-Huny">
        <StarBox>
          <MissionStars width="100%" />
        </StarBox>
      </SvgBox>
      <HunyRow width={{ xs: "100%", }} component="main">
        <Box width={{ xs: "80%", md: "50%" }}>
          <Typography variant="h2" color="primary" component={"span"}>
            The golden goodness
            <br />
            <BigTextBox><Dollar>$</Dollar>HUNY</BigTextBox>
          </Typography>
          <Typography variant="body1" color="primary" width={{ xs: "100%", lg: "100%" }} sx={styles.textbody}>
            $HUNY is exclusively produced on Zolar, and is the most valuable commodity known in the galaxy.
          </Typography>
          <Box
            component="ul"
            sx={{
              // background: 'red',

              listStyleType: 'none',
              padding: 0,
              margin: 0,
              pb: '2em',

              "li": {
                // background: 'green',

                width: '100%',

                display: 'inline-flex',
                flexDirection: 'row',
                placeContent: 'start',
                placeItems: 'center',
                gap: '1em',

                ".icon": {
                  width: '72px !important',
                  maxHeight: '72px !important',
                  minHeight: '72px !important',
                }
              }
            }}
          >
            <Box component="li">

              <Box component="img" src={OrbHuny} className="icon" />

              <Typography color="primary" sx={{
                fontFamily: ' Prompt',
                fontStyle: ' normal',
                fontWeight: ' 600',
                fontSize: ' 18px',
                lineHeight: ' 28px',
              }}>
                Earn liquidity rewards in&nbsp;
                <Typography component="span" sx={{
                  ...TEXT_GRADIENT,
                  background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
                  textTransform: 'uppercase',
                  fontWeight: '700',
                }}>
                  Magic Hive
                </Typography>
              </Typography>
            </Box>

            <Box component="li">
              <Box component="img" src={OrbMetazoa} className="icon" />

              <Typography color="primary" sx={{
                fontFamily: ' Prompt',
                fontStyle: ' normal',
                fontWeight: ' 600',
                fontSize: ' 18px',
                lineHeight: ' 28px',
              }}>
                Power up your Metazoa in
                &nbsp;
                <Typography component="span" sx={{
                  ...TEXT_GRADIENT,
                  background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
                  textTransform: 'uppercase',
                  fontWeight: '700',
                }}>
                  Missions
                </Typography>
              </Typography>
            </Box>

            <Box component="li">
              <Box component="img" src={OrbZOrdOH1} className="icon" />
              <Typography color="primary" sx={{
                fontFamily: ' Prompt',
                fontStyle: ' normal',
                fontWeight: ' 600',
                fontSize: ' 18px',
                lineHeight: ' 28px',
              }}>
                <Typography component="span" sx={{
                  ...TEXT_GRADIENT,
                  background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
                  textTransform: 'uppercase',
                  fontWeight: '700',
                }}>
                  Buy & Sell
                </Typography>
                &nbsp;resources and in-game items
              </Typography>
            </Box>

          </Box>
          <Button size="medium" variant="outlined" color="secondary" sx={styles.button} target="_blank" href={strings.getZilswapLink(hunyContract)}>
            <Typography variant="body2" sx={styles.gradientText}>GET $HUNY</Typography>
          </Button>
        </Box>
        <Box width={{ xs: "100%", sm: "50%" }} sx={{ zIndex: 30 }}>
          <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
        </Box>
      </HunyRow>
    </Container>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    padding: "40px 24px 80px",
    "@media (min-width:600px)": {
      padding: "80px 96px 20px",
    },

    // //DEBUG: Remove this
    // border: '1px solid yellow',
    // "*": {
    //   border: '1px solid red',
    // }
  },
  textbody: {
    textAlign: "left",
    zIndex: 1,
    padding: "40px 0px",
  },
  button: {
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    "@media (max-width:600px)": {
      display: "none",
    },
  },

  buttonDisabled: {
    background: 'transparent',
    color: '#FEFEFE',
    opacity: 0.4,
  },

  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },


  magicHiveIcon: {
    width: '100%',
    // minWidth: '100px',
    // minHeight: '160px',
    backgroundImage: `url(${HunyFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "243%",
    backgroundPosition: "center",

  },

};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: HunyAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  },
};

const SvgBox = styled(Box)({
  pointerEvents: 'none',
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
});

const StarBox = styled(Box)({
  pointerEvents: 'none',
  width: "100%",
  position: "absolute",
  top: "365px",
  "@media (max-width:600px)": {
    top: "620px",
    left: "-150px",
    width: "682px",
  },
});

const HunyRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '2em',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  }
});

const BigTextBox = styled(Box)({
  display: "inline",
  fontWeight: 600,
  fontSize: "4rem",
  lineHeight: "4rem",
  "@media (max-width:900px)": {
    fontSize: "2.5rem",
    lineHeight: "1.75rem",
  },
  "@media (max-width:600px)": {
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
  },
});

const Dollar = styled(Box)({
  display: "inline",
  fontWeight: 600,
  fontSize: "4.625rem",
  lineHeight: "4.625rem",
  "@media (max-width:900px)": {
    fontSize: "2.5rem",
    lineHeight: "1.75rem",
  },
  "@media (max-width:600px)": {
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
  },
});

export default Huny;
