import { Box, Container, styled, SxProps, Typography } from "@mui/material";
import {
  BlueShootingStar, IntroStar, OrangeLine, Smoke2a,
  Smoke2b, YellowShootingStar
} from "assets";
import BluePlanet from "assets/BluePlanet.png";
import { ZoaSummons } from "assets/homepage";
import ContainedButton from "components/ContainedButton";
import React from "react";
import { theme } from "theme";
import { strings } from "utils";
import { ContractsBech32 } from "utils/constants";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";


const Intro: React.FC = () => {
  const network = useNetwork();

  return (
    <Container maxWidth="xl" sx={styles.container} component="section">
      <SvgBox component="aside" id="SVG-Intro">
        <Smoke2aBox>
          <Smoke2a width={"100%"} />
        </Smoke2aBox>
        <Smoke2bBox>
          <Smoke2b />
        </Smoke2bBox>
        <OrangeBox>
          <OrangeLine />
        </OrangeBox>
        <StarBox>
          <IntroStar width="100%" />
        </StarBox>
        <BlueShootingBox>
          <BlueShootingStar />
        </BlueShootingBox>
        <YellowShootingBox>
          <YellowShootingStar width="100%" />
        </YellowShootingBox>
        <BluePlanetBox>
          <img src={BluePlanet} alt="blue-planet" />
        </BluePlanetBox>
        {/* <MarsBox>
          <Lottie options={defaultOptions} height={"100%"} width={"100%"}/>
        </MarsBox> */}
      </SvgBox>
      <Box maxWidth="xl" sx={styles.content} component="main">
        <Box
          width={{
            xs: "100%",
          }}
          sx={styles.contentcol}
        >
          {/* <RocketBox>
            <Rocket width="100%" />
          </RocketBox> */}

          <Typography
            variant="body1"
            color="primary"
            width={{
              xs: "100%",
              sm: "90%",
            }}
            sx={styles.textbody}
            alignSelf="start"
          >
            Take a step into the world of Zolar, a virtual metaverse NFT game brimming with new life, hidden secrets and the ultra-coveted $HUNY, Zolar’s governance token.
          </Typography>


          <Box sx={{
            // background: 'pink',

            position: 'relative',
            display: 'flex',
            placeContent: 'space-between',
            placeItems: 'center',
            gap: '2em',

            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              pt: 10,

              ".zoaSummons": {
                maxHeight: '400px',
                width: '100%',
              }
            },
          }}>
            <ZoaSummons className="zoaSummons" />

            <Box
              component="article"
              width={{
                xs: "100%",
                md: "70%",
              }}
              sx={{
                // background: 'green',

                display: 'flex',
                flexDirection: 'column',
                placeItems: 'start',
                gap: '2em',

              }}
            >
              <Typography
                variant="body1"
                color="primary"
                // width={{
                //   xs: "80%",
                //   lg: "70%",
                // }}
                sx={styles.textbody}
              >
                In the Zolarverse live Metazoa, native inhabitant NFTs who love exploring new planets and hunting for treasure.
                <br />
                <br />
                As their Commander, assemble your own Metazoa army and embark on exciting missions filled with new discoveries, unexpected twists and tad’ of treacherous danger.
                <br />
                <br />
                Start building your Metazoa collection and enter the Zolar metaverse today!
              </Typography>

              <ContainedButton href={strings.getArkyLink(ContractsBech32[network].NftV2)} isExternal sx={{ marginLeft: '0px!important' }}>
                Get Your Metazoa
              </ContainedButton>
            </Box>
          </Box>


        </Box>
      </Box>
    </Container>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    padding: "0px",

    // //DEBUG: Remove this
    // border: '1px solid yellow',
    // "*": {
    //   border: '1px solid red',
    // }
  },
  content: {
    width: '90%',
    mx: 'auto',
    position: 'relative',
    padding: "140px 5% 180px",
    "@media (min-width:600px)": {
      padding: "180px 10% 180px",
    },
  },
  contentcol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  textbody: {
    textAlign: "left",
    zIndex: 1,
  },
};

const SvgBox = styled(Box)({
  pointerEvents: 'none',
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
});

const Smoke2aBox = styled(Box)({
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(358.98deg, #FFFFFF 0.71%, #C1C1C1 77.66%, #999999 101.96%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "300px",
  left: "350px",
  "@media (max-width:600px)": { top: "500px", left: "50px" },
});

const Smoke2bBox = styled(Box)({
  pointerEvents: 'none',
  backgroundColor:
    "linear-gradient(358.98deg, #FFFFFF 0.71%, #C1C1C1 77.66%, #999999 101.96%)",
  mixBlendMode: "overlay",
  opacity: "50%",
  position: "absolute",
  top: "900px",
  left: "200px",
  "@media (max-width:900px)": {
    top: "300px",
    left: "20px",
  },
});

const OrangeBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "-20px",
  left: "330px",
  "@media (max-width:900px)": {
    display: "none",
  },
});

const StarBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "-850px",
  right: "-950px",
  width: "2000px",
  opacity: "40%",
  background: "radial-gradient(44.15% 41.29% at 44.15% 51.87%, #FFFFFF 0%, #806BFF 20.48%, rgba(53, 2, 255, 0.67) 56.9%, rgba(20, 0, 255, 0) 100%)",
  "@media (max-width:900px)": {
    top: "-700px",
    right: "-1500px",
  },
  "@media (max-width:600px)": {
    top: "-700px",
    right: "-1200px",
    width: "1500px",
  },
});

const BluePlanetBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "400px",
  left: "50px",
  "@media (max-width:600px)": {
    top: "50px",
    left: "10px",
  },
});

const BlueShootingBox = styled(Box)({
  pointerEvents: 'none',
  position: "absolute",
  top: "800px",
  left: "900px",
  "@media (max-width:600px)": {
    display: "none",
  },
});
const YellowShootingBox = styled(Box)({
  pointerEvents: 'none',
  width: "20%",
  position: "absolute",
  top: "700px",
  left: "1100px",
  "@media (max-width:600px)": {
    top: "950px",
    left: "300px",
    width: "10%",
  },
  "@media (max-width:400px)": {
    top: "950px",
    left: "300px",
    width: "10%",
  },
});

export default Intro;
