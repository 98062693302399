import { Box, Button, Container, styled, SxProps, Typography } from "@mui/material";
import {
  MissionBlue, MissionLine, MissionRed
} from "assets";
import { BlueIslands, RedIslands } from "assets/homepage";
import React from "react";
import { AppTheme, SimpleMap } from "../../../utils/types";

const FirstMission: React.FC = () => {
  return (
    <Container maxWidth="xl" sx={styles.container} component="section">
      <SvgBox component="aside" id="SVG-Mission-Next">
        <StarBox>
          <MissionBlue />
          <BlueIslands
            className="islands"
          />
        </StarBox>
        <StarBox2>
          <MissionRed />
          <RedIslands
            className="islands"
          />
        </StarBox2>
      </SvgBox>
      <Box maxWidth="xl" sx={styles.contentBox} component="main">
        <Box
          width={{
            xs: "100%",
          }}
          height="650px"
          sx={styles.textColumn}
          textAlign="center"
        >
          <Typography
            variant="overline"
            color="secondary.main"
            sx={styles.subheader}
          >
            Mission III
          </Typography>
          <Typography variant="h1" color="primary">
            Battle of the Commanders
          </Typography>
          <MissionLineBox>
            <MissionLine width="100%" />
          </MissionLineBox>
          <Typography width={{ xs: "100%", sm: "90%", md: "80%", }} component={"span"} variant="body1" color="primary" sx={styles.textbody}>
            It’s time to create your own Fortresses and raid other Commanders! Gain as much Z-Bills as possible and boost your rankings on the leaderboard. Top winners on the leaderboard will win from a H.U.G.E prize pool at every epoch!
          </Typography>
          <Typography variant="h2" color="primary" sx={styles.header2}>
            The battle is on. Are you ready?
          </Typography>
          <Button size="medium" variant="outlined" color="secondary" target="_blank" href="https://docs.zolar.io/">
            <Typography variant="button" sx={styles.gradientText}>Whitepaper</Typography>
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    padding: "0px",
  },
  contentBox: {
    padding: "76px 0px 10px",
    "@media (min-width:600px)": {
      padding: "40px 0px 140px",
    },
  },
  textColumn: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 4,
    zIndex: 1,
  },
  subheader: {
    textTransform: "uppercase",
    letterSpacing: "0.24em",
    zIndex: 1,
  },
  header2: {
    zIndex: 1,
  },
  textbody: {
    textAlign: "center",
    zIndex: 1,
  },
  missionCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 0px",
    "@media (min-width:600px)": {
      padding: "120px 0px 80px",
    },
  },
  buttonGradient: {
    WebkitTextFillColor: '#000',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
};

const SvgBox = styled(Box)({
  position: "relative",
  top: "0%",
  left: "0%",
  width: "100%",
  height: "100%",
  pointerEvents: 'none',
});

const StarBox = styled(Box)({
  pointerEvents: 'none',
  background:
    "radial-gradient(42.7% 52.61% at 58.59% 47.39%, #FFFFFF 1.56%, #469BFF 15.29%, #0445EB 35.94%, rgba(0, 0, 0, 0) 96.45%)",
  position: "absolute",
  top: "-200px",
  left: "-900px",
  width: "1496px",
  height: '1200px',
  opacity: "35%",

  ".islands": {
    position: 'absolute',
    top: '20em',
    left: '40%',
    "@media (max-width:900px)": {
      // left: '35%',
      height: '30%',

    },
  },
  "@media (max-width:600px)": {
    // display: "none",
  },
  "@media (max-width:900px)": {
    left: "-800px",

  },
});

const StarBox2 = styled(Box)({
  pointerEvents: 'none',
  background:
    "radial-gradient(42.41% 40.03% at 44.15% 51.87%, rgba(255, 255, 255, 0.734375) 0%, rgba(255, 122, 0, 0.734375) 16.67%, rgba(255, 2, 2, 0.479167) 56.9%, rgba(255, 0, 0, 0) 100%)",
  position: "absolute",
  top: "-200px",
  right: "-900px",
  width: "1496px",
  height: '1200px',
  opacity: "35%",

  ".islands": {
    position: 'absolute',
    top: '20em',
    right: '40%',
    "@media (max-width:900px)": {
      // right: '35%',
      height: '30%',

    },
  },
  "@media (max-width:600px)": {
    // display: "none",
  },
  "@media (max-width:900px)": {
    right: "-800px",
  },
});

const MissionLineBox = styled(Box)({
  width: "20%",
  "@media (max-width:600px)": {
    width: "50%",
  },
});


export default FirstMission;
