import React, { useEffect, useState } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import BoxWithHeader from "components/BoxWithHeader";
import TooltipZolar from "components/Tooltip/TooltipZolar";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";

const ArmyStats: React.FC = () => {
  const tokenState = useRedux((state) => state.token);
  const metazoaTokens = Object.values(tokenState.metazoaTokens);
  const stakedMetazoa = Object.values(tokenState.stakedMetazoa);
  const [baseUrsas, setBaseUrsas] = useState<number>(0);
  const [baseMinos, setBaseMinos] = useState<number>(0);
  const [battleUrsas, setBattleUrsas] = useState<number>(0);
  const [battleMinos, setBattleMinos] = useState<number>(0);
  const [kidnappedUrsas, setKidnappedUrsas] = useState<number>(0);

  useEffect(() => {
    // TODO: add loading spinner
    let [baseUrsas, baseMinos, battleUrsas, battleMinos] = [0, 0, 0, 0];

    metazoaTokens.forEach(token => {
      const faction = token?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value;
      if (faction === "Ursa") {
        baseUrsas++;
      } else if (faction === "Mino") {
        baseMinos++;
      }
    })

    stakedMetazoa.forEach(token => {
      const faction = token?.attributes?.find(attribute => attribute.trait_type === "Faction")?.value;
      if (faction === "Ursa") {
        battleUrsas++;
      } else if (faction === "Mino") {
        battleMinos++;
      }
    })

    setBaseUrsas(baseUrsas);
    setBaseMinos(baseMinos);
    setBattleUrsas(battleUrsas);
    setBattleMinos(battleMinos);
    setKidnappedUrsas(0);
  }, [metazoaTokens, stakedMetazoa])

  return (
    <Box sx={styles.armyStatsContainer}>
      {/* Base */}
      <BoxWithHeader 
        headerText={<Typography variant="h3" color="primary">AT BASE</Typography>}
        componentsRight={<Typography variant="h3" color="secondary">{metazoaTokens.length}</Typography>}
        sx={styles.statsBox}     
      >
        <Box sx={styles.statsRow}>
          <Typography variant="body1" color="primary">Ursas</Typography>
          <Typography variant="body1" color="primary">{baseUrsas}</Typography>
        </Box>
        <Box sx={styles.statsRow}>
          <Typography variant="body1" color="primary">Minos</Typography>
          <Typography variant="body1" color="primary">{baseMinos}</Typography>
        </Box>
      </BoxWithHeader>

      {/* Battle */}
      <BoxWithHeader 
        headerText={<Typography variant="h3" color="primary">IN BATTLE</Typography>}
        componentsRight={<Typography variant="h3" color="secondary">{stakedMetazoa.length}</Typography>}
        sx={styles.statsBox}
      >
        <Box sx={styles.statsRow}>
          <Typography variant="body1" color="primary">Ursas</Typography>
          <Typography variant="body1" color="primary">{battleUrsas}</Typography>
        </Box>
        <Box sx={styles.statsRow}>
          <Typography variant="body1" color="primary">Minos</Typography>
          <Typography variant="body1" color="primary">{battleMinos}</Typography>
        </Box>
      </BoxWithHeader>

      {/* Kidnapped */}
      <BoxWithHeader
        headerText={<Typography variant="h3" color="primary">KIDNAPPED</Typography>}
        sx={styles.statsBox}  
        componentsRight={
          <Box display="flex">
            <Typography variant="h3" color="error.main" mr="4px">0</Typography>
            <TooltipZolar link={"https://docs.zolar.io/usdhuny/hunynomics#lockingmodel"} sx={styles.tooltip}>
              Only Gen 1 - 3 Ursas can be kidnapped by the Mino Faction.
            </TooltipZolar>
          </Box>
        }   
      >
        <Box sx={styles.statsRow}>
          <Typography variant="body1" color="primary">Ursas</Typography>
          <Typography variant="body1" color="primary">{kidnappedUrsas}</Typography>
        </Box>
        <Box sx={styles.statsRow}>
          <Typography variant="body1" color="primary">Minos</Typography>
          <Typography variant="body1" color="primary">Invincible</Typography>
        </Box>
      </BoxWithHeader>
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  armyStatsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '10px',
    marginBottom: '10px',
    "@media (max-width:920px)": {
      flexDirection: 'column',
      rowGap: '10px',
    },
  },
  statsBox: {
    flex: "1 1 0",
  },
  statsRow: {
    paddingX: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tooltip: {
    padding: '20px',
    width: '420px',
  },
}

export default ArmyStats;