import { logger } from "core/utilities";
import { SimpleMap } from "utils/types";
import { IInventoryItem } from "../EquipPanel";

export function limitOffset<T>(array: T[], limit: number, offset: number): T[] {
  if (!array) return [];

  const length = array.length;

  if (!length) {
    return [];
  }
  if (offset > length - 1) {
    return [];
  }

  const start = Math.min(length - 1, offset);
  const end = Math.min(length, offset + limit);

  return array.slice(start, end);
}

export const transferDropItem = (
  dItem: IInventoryItem,
  srcList: IInventoryItem[],
  dstList: IInventoryItem[],
  options: SimpleMap<boolean> = {
    stacking: false,
    inversed: false,
    keepEmpty: false,
  },
): SimpleMap<IInventoryItem[]> => {
  options = {
    stacking: false,
    inversed: false,
    keepEmpty: false,
    ...options,
  }

  let transferResult: SimpleMap<IInventoryItem[]> = {
    src: Array.from(srcList),
    dst: Array.from(dstList),
  };
  if (!Object.keys(dItem).length || !srcList.length || !dItem?.count) {
    logger("debug-utils", "EquipPanel/transferDropItem", "CHECK 1: FAILED", {
      count: dItem.count,
      length: srcList.length,
    });
    return transferResult;
  }

  // Retrieve srcItemIdx
  const srcItemIdx: number = transferResult.src.findIndex(({ keyName }) => keyName === dItem.keyName);
  if (srcItemIdx < 0) {
    logger("debug-utils", "EquipPanel/transferDropItem", "CHECK 2: FAILED", {
      srcItemIdx,
    });
    return transferResult;
  }

  let srcItem: IInventoryItem = transferResult.src[srcItemIdx];
  // Check if srcItem mismatch dItem
  if (dItem.keyName !== srcItem.keyName) {
    logger("debug-utils", "EquipPanel/transferDropItem", "CHECK 3: FAILED", {
      dKeyName: dItem.keyName,
      srcKeyName: srcItem.keyName,
    });
    return transferResult;
  }

  const swapList: number[] = Array.from(srcItem.item?.ids ?? []);
  if (!swapList.length) {
    logger("debug-utils", "EquipPanel/transferDropItem", "CHECK 4: FAILED", {
      swapList,
      srcItem,
    });
    return transferResult;
  }

  // Pop swapItem from swapList head
  const swapItem: number = swapList.pop() ?? -1;
  if (swapItem < 0) {
    logger("debug-utils", "EquipPanel/transferDropItem", "CHECK 5: FAILED", {
      swapList,
      srcItem,
      swapItem,
    });
    return transferResult;
  }

  // Retrieve dstItemIdx
  const dstItemIdx: number = transferResult.dst.findIndex(({ keyName }) => keyName === srcItem.keyName);

  // Increment dst
  const dstSwapList = [swapItem];
  if (dstItemIdx > -1 && options.stacking) {
    let dstItem: IInventoryItem = transferResult.dst[dstItemIdx];
    dstSwapList.push(...(dstItem.item?.ids ?? []));

    transferResult.dst[dstItemIdx] = {
      ...dstItem,
      count: dstSwapList.length,
      item: {
        ...dstItem.item,
        ids: dstSwapList,
      }
    };
  }
  else {
    transferResult.dst.push({
      ...srcItem,
      count: dstSwapList.length,
      item: {
        ...srcItem.item,
        ids: dstSwapList,
      }
    });
  }

  // Decrement src
  transferResult.src[srcItemIdx] = {
    ...srcItem,
    count: swapList.length,
    item: {
      ...srcItem.item,
      ids: swapList,
    }
  }
  if (!options.keepEmpty && transferResult.dst.length) transferResult.dst = transferResult.dst.filter(({ count }) => count > 0);
  logger("debug-utils", "EquipPanel/transferDropItem", "TRANSFERRED", transferResult);
  return transferResult;
};