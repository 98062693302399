import { Box, Button, CircularProgress, Grid, styled, SxProps, Typography } from "@mui/material";
import { ActiveTab2, BaseButton, GuildItemsFrame, Huny, ZilTokenIcon } from "assets";
import GuildItemEmptyFrame from 'assets/GuildItemEmptyFrame.svg';
import GuildItemFrame from 'assets/GuildItemFrame.svg';
import BigNumber from "bignumber.js";
import { GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { ReactNode, RefObject, useMemo, useRef, useState } from 'react';
import { Guild, GuildBankInfo } from "store/types";
import { BIG_ZERO } from "utils/constants";
import { formatIntegerNumber } from "utils/strings";
import { combineStyles } from 'utils/themeUtilities';
import { AppTheme, SimpleMap } from "utils/types";
import useIsOverflow from "utils/useIsOverflow";
import { BankDonationDialog, WithdrawDialog } from "./components";

export interface GuildProps {
  guild: Guild;
  bank: GuildBankInfo;
  canCollectTax: boolean;
}

export type BankWithdrawalInputs = {
  withdrawalType: string;
  withdrawalAmount: BigNumber;
}

const tokenIcons = {
  huny: <Huny />,
  zil: <ZilTokenIcon />,
}
const maxGridViewable = 24;
const donationTabs = [
  { title: "Items", isViewable: true },
  { title: "???", isViewable: false },
  { title: "???", isViewable: false },
]

export type BankItem = {
  name: string,
  amount: number,
  value: BigNumber,
  icon: ReactNode,
}
const DonateBank: React.FC<GuildProps> = (props: GuildProps) => {
  const { guild, bank, canCollectTax } = props;

  // STATES / HOOKS -----------------

  const ref = useRef() as RefObject<HTMLDivElement>;
  const isOverflow = useIsOverflow(ref);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [showDonateDialog, setShowDonateDialog] = useState<boolean>(false)
  const [showWithdrawDialog, setShowWithdrawDialog] = useState<boolean>(false)
  const [bankStorage, setBankStorage] = useState<{ [key: string]: BankItem[] }>({
    items: [],
  })

  useMemo(() => {
    if (!bank) return;
    const { tokens, netWorth } = bank;
    const itemList: BankItem[] =
      (!!tokens && !!netWorth)
        ? Object.keys(tokens).map((token) => {
          const tokenType: string = token.split(/(?=[A-Z])/)[0];
          return {
            name: tokenType,
            amount: netWorth[`${token}Amt`],
            value: netWorth[token],
            icon: tokenIcons[tokenType],
          }
        }) : [];

    setBankStorage({
      items: itemList,
    })
  }, [bank])

  // FUNCTIONS ----------------------

  // EVENT HANDLERS -----------------

  const handleDonate = () => {
    setShowDonateDialog(true)
  }

  const handleOnClose = () => {
    setShowDonateDialog(false);
  }

  const handleWithdraw = () => {
    setShowWithdrawDialog(true)
  }

  const handleWithdrawOnClose = () => {
    setShowWithdrawDialog(false);
  }

  // --------------------------------

  if (!bank || !bankStorage) return null;
  return (
    <Box sx={styles.container}>
      {currentTab === 0 && (<GuildItemsFrame className="frame" />)}
      {currentTab === 1 && (<ActiveTab2 className="frame" />)}
      <Box sx={styles.tabs}>
        {donationTabs.map((tab: { title: string, isViewable: boolean }, idx: number) => (
          <Button key={`tab-${idx}-${tab.title}`}
            onClick={() => tab.isViewable && setCurrentTab(idx)}
            disabled={!tab.isViewable}
          >
            <Typography variant="h3" color="primary"
              sx={currentTab === idx ? { color: '#FEFEFE' } : styles.emptyTab}
            >{tab.title}</Typography>
          </Button>
        ))}
      </Box>

      {/* //INFO: Tab #1 - Items */}
      <Box sx={combineStyles(styles.content,
        { ...(!isOverflow) && styles.unmaskedContent },
        { ...(currentTab !== 0) && { display: 'none' } }
      )}>
        {canCollectTax && <Button variant="outlined" color="secondary" sx={styles.gradientButton} onClick={handleWithdraw} disabled={!bank?.tokens}>Withdraw</Button>}
        <Typography variant="h2" color="primary" sx={{ fontSize: '64px !important' }}>
          ${(bank?.netWorth?.valuation ?? 0).toFixed(2)}
        </Typography>
        <Grid ref={ref} component="div" container columns={8} sx={styles.itemContainer} className="masked-overflow">
          {bankStorage.items.length > 0 && (bankStorage.items).map((item, index) => {
            return (
              <Grid item xs={2} md={1.5} lg={1} sx={styles.bankTokensGrid} key={index}>
                <Box sx={styles.itemBox}>
                  {!item
                    ? (<CircularProgress size={24} />)
                    : (<>
                      {item.icon}
                      <Box sx={styles.tokenAmtTag}>
                        <Typography sx={styles.tokenAmt} color="primary">
                          {formatIntegerNumber(item.amount)}
                        </Typography>
                      </Box>
                      <Typography sx={styles.tokenValue}>${item.value.toNumber().toFixed(2)}</Typography>
                    </>)}
                </Box>
              </Grid>
            )
          })}
          {[...Array(maxGridViewable - (bankStorage.items).length)].map((x, i) =>
            <Grid item xs={2} md={1.5} lg={1} sx={{ marginBottom: '45px' }} key={i}>
              <Box sx={styles.emptyItemBox}>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      <Button
        variant="contained"
        color="secondary"
        sx={styles.bankBannerButton}
        onClick={handleDonate}
        disabled={!bank}
      >
        Donate
        <BaseButtonBox>
          <BaseButton height="100%" />
        </BaseButtonBox>
      </Button>

      {showDonateDialog && (
        <BankDonationDialog
          guild={guild}
          bank={bank}
          open={showDonateDialog}
          onClose={handleOnClose}
        />
      )}

      {showWithdrawDialog && (
        <WithdrawDialog
          guild={guild}
          open={showWithdrawDialog}
          onClose={handleWithdrawOnClose}
          bankTokens={{
            zil: bank?.tokens?.zilToken ?? BIG_ZERO,
            huny: bank?.tokens?.hunyToken ?? BIG_ZERO,
          }}
        />
      )}
    </Box >
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  content: {
    zIndex: '10',
    position: 'absolute',
    width: '100%',
    padding: '7% 5%',
    left: 0,
    textAlign: 'center',
    "@media (max-width:1100px)": {
      paddingTop: '9%'
    },
    "@media (min-width:1200px)": {
      '.masked-overflow': {
        maskImage: 'none',
        WebkitMaskImage: 'none',
        overflow: 'hidden'
      }
    },
  },
  unmaskedContent: {
    '.masked-overflow': {
      maskImage: 'none',
      WebkitMaskImage: 'none',
      overflowY: 'hidden'
    }
  },
  tabs: {
    zIndex: '50',
    position: 'absolute',
    left: '7%',
    top: '1.5%',
    "& h3": {
      fontSize: '24px',
      textTransform: 'uppercase'
    },
    display: 'flex',
    width: '55%',

    "> *": {
      width: '100%',
      flex: 1,
    },

    "> button:first-of-type h3": {
      paddingRight: '10px',
    },

    justifyContent: 'space-between',
    "@media (max-width:1200px)": {
      left: '5%',
      width: '56%'
    },

  },
  emptyTab: {
    color: 'rgba(255, 255, 255, 0.4)'
  },
  bankTokenGrid: {
    marginBottom: '45px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px',
    height: '150px',
  },
  itemBox: {
    position: 'relative',
    backgroundImage: `url(${GuildItemFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "center",
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    width: '150px',
    height: '150px',
    margin: 'auto',

    "svg": {
      width: '60px',
      height: '60px',
    }
  },

  emptyItemBox: {
    position: 'relative',
    backgroundImage: `url(${GuildItemEmptyFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "center",
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    width: '150px',
    height: '150px',
    margin: 'auto',
  },
  itemContainer: {
    justifyContent: 'center',
    maxHeight: "590px",
    overflowY: "auto",
    paddingTop: '3.5%',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    "@media (max-width:1200px)": {
      marginTop: '24px'
    },
  },
  container: {
    width: '100%',
    position: 'relative',
    margin: '4px 0',
    display: 'flex',
    minHeight: '825px',
    maxHeight: '825px',
    ".frame": {
      position: 'absolute',
      width: '100%',
    },
  },
  bankBannerButton: {
    zIndex: 10,
    position: 'absolute',
    bottom: 0,
    left: "37%",
    width: "324px",
    height: "64px",
    whiteSpace: 'nowrap',
    background: 'radial-gradient(93.75% 198.35% at 93.75% 18.18%, #FFFFFF 0%, #FFB600 32.81%, #FF8952 100%)',
    "@media (max-width:900px)": {
      width: "220px",
      left: '37% !important'
    },
    "@media (max-width:1330px)": {
      left: '34%'
    },
    "&:hover": {
      backgroundColor: 'transparent',
    },
  },
  outlinedButton: {
    fontSize: '14px',
    padding: '5px 21px',
    marginLeft: '16px',
  },
  gradientText: {
    background: '-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "1.75rem",
  },
  divider: {
    height: '2px',
    background: 'radial-gradient(151.44% 152.65% at 49.92% 12.81%, #FFEAB4 9.64%, #FCCE58 43.75%)',
    marginBottom: '2px',
  },
  tokenAmt: {
    fontWeight: 600,
    fontSize: '14px',
    padding: '4px 8px'
  },
  tokenAmtTag: {
    position: 'absolute',
    bottom: '-7px',
    border: "1px solid transparent",
    background: 'linear-gradient(180deg, #01001E -13.81%, #000010 100%) padding-box, radial-gradient(#FFEAB4, #FCCE58) border-box',
    borderRadius: '8px'
  },
  tokenValue: {
    fontSize: '12px',
    bottom: '-35px',
    position: 'absolute',
    ...GUILD_GRAY_GRADIENT,
    background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256) 100%)'
  },
  gradientButton: {
    position: 'absolute',
    right: '7%',
    fontSize: '14px',
    height: 48,
    width: '120px',
    padding: '9px 18px',
    fontWeight: 700,
    letterSpacing: '0.04em',
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },
}

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    height: "100%",
  },
});


export default DonateBank;