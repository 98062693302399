

// DATA LOADERS ---------------------------------------------------------

import { logger } from "core/utilities";
import { call, delay, fork, put, takeLatest } from "redux-saga/effects";
import { waitForConnectorInit } from "saga/helpers";
import { actions } from "store";
import { ZOMGCraftItem, ZOMGResourceItem } from "store/types";
import { TBMConnector } from "tbm";
import { SimpleMap } from "utils/types";

// STORE LOADERS --------------------------------------------------------

function* loadStore() {
  try {
    yield waitForConnectorInit();
    yield put(actions.Layout.addBackgroundLoading("loadStore", "ZOMG:LOAD_STORE"));
    // const { network } = getBlockchain(yield select());

    const craftables: SimpleMap<ZOMGCraftItem[]> = (yield call(TBMConnector.getZOMGCraftables)) ?? {};
    const resources: ZOMGResourceItem[] = (yield call(TBMConnector.getZOMGResources)) ?? [];

    logger("debug-saga/zomg", "loadStore", {
      craftables,
      resources,
    })
    yield put(actions.ZOMG.updateStore({
      craftables,
      resources,
    }));

  } catch (error) {
    console.error("ERROR: Unable to load ZOMG resources.\n", error);
    yield delay(3000);
  } finally {
    yield put(actions.Layout.removeBackgroundLoading("ZOMG:LOAD_STORE"));
  }
}

// WATCHERS -------------------------------------------------------------

function* watchStore() {
  yield takeLatest([
    actions.ZOMG.StoreActionTypes.LOAD_STORE,
    actions.Blockchain.BlockchainActionTypes.READY,
    actions.Blockchain.BlockchainActionTypes.SET_NETWORK,
  ], loadStore)
}

// ----------------------------------------------------------------------

export default function* zomgSaga() {
  logger("debug-saga", "INIT ZOMG SAGA");
  yield fork(watchStore);
}