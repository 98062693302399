import { Box, Link, SvgIcon, SxProps, Typography } from "@mui/material";
import { Huny, Rocket, WarningIcon } from "assets";
import PanelFrame from "assets/PanelFrame.svg";
import { StoreNeonHuny, StoreNeonZOMG } from "assets/quests";
import MiniStoreFrameBlue from "assets/quests/Locations/ZOMGStore/MiniStoreFrameBlue.svg";
import StoreFrameYellow from "assets/quests/Locations/ZOMGStore/StoreFrameYellow.svg";
import StoreItemFrame from "assets/quests/Locations/ZOMGStore/StoreItemFrame.svg";
import StoreItemFrameYellow from "assets/quests/Locations/ZOMGStore/StoreItemFrameYellow.svg";
import BigNumber from "bignumber.js";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import ContainedButton from "components/ContainedButton";
import StripeHeader from "components/Game/components/StripeHeader";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { TEXT_GRADIENT } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { QuestConstants } from "components/Quest";
import { LOCATIONS, RESOURCES } from "components/Quest/QuestConstants";
import { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ZOMGResourceItem } from "store/types";
import { theme } from "theme";
import { strings, useRedux, useTaskSubscriber } from "utils";
import { ContractsBech32, Decimals, MissionGroundResource } from "utils/constants";
import { formatIntegerNumber, toHumanNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import ResourceCard from "./components/ResourceCard";
import { BuyResourceDialog, SellResourceDialog } from "./components/ResourceDialog";

const ResourcesView: FC = () => {
  const { location } = useParams();

  const network = useNetwork();
  const wallet = useRedux((state) => state.wallet.wallet);
  const zomgStore = useRedux((state) => state.zomg.store);
  const resources = useRedux((state) => state.token.resources);
  const [loadingTokens] = useTaskSubscriber("updateTokens");

  // STATES / HOOKS -----------------

  const [showBuyResourceDialog, setShowBuyResourceDialog] = useState<boolean>(false);
  const [showSellResourceDialog, setShowSellResourceDialog] = useState<boolean>(false);
  const [dialogResource, setDialogResource] = useState<ZOMGResourceItem>();

  const resourceItems = useMemo(() => {
    if (!zomgStore) return [];
    const {
      resources: storeResources,
    } = zomgStore;

    return storeResources;
  }, [zomgStore]);

  // FUNCTIONS ----------------------

  // const getSellSideInflation = (purchaseCount: BigNumber, inflationBps: BigNumber, deflationBps: BigNumber) => {
  //   const absPurchaseCount = bnOrZero(purchaseCount).abs();
  //   const rootPurchase = absPurchaseCount.sqrt().toFixed(0, 1);
  //   if (purchaseCount.lt(BIG_ZERO)) return bnOrZero(rootPurchase).times(bnOrZero(deflationBps)).toFixed(0, 1);  // deflating
  //   else return bnOrZero(rootPurchase).times(bnOrZero(inflationBps)).toFixed(0, 1); // inflating
  // }

  // const getMaxDeflationBPS = (startPrice: BigNumber, basePrice: BigNumber) => {
  //   const maxDeflationAmt = basePrice.minus(startPrice);
  //   return bnOrZero(maxDeflationAmt.times(10000).dividedBy(startPrice).toFixed(0, 1));
  // }

  // // inflation = root k * bpsRate, where k = netPurchaseCount
  // const getMaxNegativeNetPurchase = (startPrice: BigNumber, basePrice: BigNumber, deflationBps: BigNumber) => {
  //   const maxDeflationBps = getMaxDeflationBPS(startPrice, basePrice);
  //   const rootNetPurchase = bnOrZero(maxDeflationBps.dividedBy(deflationBps).toFixed(0, 1));
  //   return rootNetPurchase.pow(2).negated() // negate to get -ve purchase count
  // }

  const computeBuyPrice = (resource: ZOMGResourceItem) => {
    // resource.buy = [basePrice, startPrice, maxPrice, inflationBps, deflationBps]
    if (!resource.transactCount.netBuySideInflation) return toHumanNumber(resource.buy[1].shiftedBy(-Decimals.HUNY)); // if no inflation return start price
    const inflatedPrice = resource.buy[1].times(resource.transactCount?.netBuySideInflation?.shiftedBy(-4).plus(1))

    if (inflatedPrice.lte(resource.buy[0])) return toHumanNumber(resource.buy[0].shiftedBy(-Decimals.HUNY)); // if net inflated below base price
    if (inflatedPrice.gte(resource.buy[2])) return toHumanNumber(resource.buy[2].shiftedBy(-Decimals.HUNY)); // if net inflated past max price

    return toHumanNumber(inflatedPrice.shiftedBy(-Decimals.HUNY));
  }

  // const computeSellPrice = (resource: ZOMGResourceItem) => {
  //   // resource.sell = [basePrice, startPrice, maxPrice, inflationBps, deflationBps]
  //   if (!resource.transactCount.netPurchaseCount) return toHumanNumber(resource.sell[1].shiftedBy(-Decimals.HUNY)); // if no net purchase count return start price
  //   const netPurchaseCount = resource?.transactCount?.netPurchaseCount ?? BIG_ZERO;
  //   const sellInflationBps = resource?.sell?.[3] ?? BIG_ZERO
  //   const sellDeflationBps = resource?.sell?.[4] ?? BIG_ZERO
  //   const currentInflationBps = bnOrZero(getSellSideInflation(netPurchaseCount, sellInflationBps, sellDeflationBps));
  //   const sellPrice = resource.sell[1].times(currentInflationBps.shiftedBy(-4).plus(1))
  //   const maxNegativeNetPurchase = getMaxNegativeNetPurchase(resource.sell[1], resource.sell[0], sellDeflationBps)

  //   if (sellPrice.lte(resource.sell[0]) || netPurchaseCount.lte(maxNegativeNetPurchase)) return toHumanNumber(resource.sell[0].shiftedBy(-Decimals.HUNY)); // if net inflated below base price
  //   if (sellPrice.gte(resource.sell[2])) return toHumanNumber(resource.sell[2].shiftedBy(-Decimals.HUNY)); // if net inflated past max price

  //   return toHumanNumber(sellPrice.shiftedBy(-Decimals.HUNY));
  // }
  // EVENT HANDLERS -----------------

  const handleOnBuy = (resource: ZOMGResourceItem) => {
    setDialogResource(resource)
    setShowBuyResourceDialog(true);
  }
  // const handleOnSell = (resource: ZOMGResourceItem) => {
  //   setDialogResource(resource)
  //   setShowSellResourceDialog(true);
  // }

  // --------------------------------

  if (!location) return null;

  const isClosed: boolean = false;

  return (<>
    {isClosed && (
      <Box sx={combineStyles(
        {
          position: 'absolute',
          inset: '0',
          zIndex: '50',
          height: 'fit-content',
          width: 'fit-content',
          margin: 'auto',

          display: 'flex',
          placeContent: 'center',
          placeItems: 'center',
          flexDirection: 'column',
          gap: 2,
        },
        {
          background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
          borderRadius: '16px',
          padding: '20px',
          border: '2px solid #AEF1EE',
        }
      )}>

        <Typography
          variant="h3"
          color="primary"
        >
          ZOMG Store is closed for renovations,<br />revamp will be up soon!
        </Typography>
        <Rocket />
      </Box>
    )}

    {!wallet && !isClosed && (
      <Box sx={combineStyles(
        {
          position: 'absolute',
          inset: '0',
          zIndex: '50',
          height: 'fit-content',
          width: 'fit-content',
          margin: 'auto',

          display: 'flex',
          placeContent: 'center',
          placeItems: 'center',
          flexDirection: 'column',
          gap: 2,
        },

        styles.frameWrapper, {
        ...(!!LOCATIONS[location].assets.frame) && {
          borderImage: `url(${LOCATIONS[location].assets.frame})`,
          backgroundImage: `url(${LOCATIONS[location].assets.frame})`,
        },
      }
      )}>
        <Typography
          variant="h3"
          color="primary"
        >
          Connect your wallet to enter the store
        </Typography>
        <ConnectWalletButtonCTA sx={{
          "button": {
            background: LOCATIONS[location].assets.gradient,
          }
        }} />
      </Box>
    )}

    <Box component="main" sx={combineStyles(
      styles.viewContainer,
      { ...(!wallet || isClosed) && { filter: 'blur(4px)', } }
    )}>
      <Box component="section" sx={styles.storeFrame}>
        <StoreNeonHuny style={{
          position: 'absolute',
          inset: '-15% 0px 0px 2%',
        }}
          className="neonSigns"
        />

        <Box sx={styles.storeFrameContent}>
          <Typography variant="h2" color="secondary" sx={styles.storeFrameTitle}>
            Buy
          </Typography>

          {/* View Window */}
          <Box sx={styles.displayWindow}>
            {[...Array((Object.keys(MissionGroundResource).length) - resourceItems.length)].map((resource, idx) => (
              <ResourceCard
                key={idx}
                isLoading={loadingTokens}
                width={`calc(90% / ${Object.entries(RESOURCES).length})`}
              >
                {/* BUTTON - BUY */}
                <ContainedButton sx={styles.buyButton} disabled>
                  -
                </ContainedButton>
              </ResourceCard>
            ))}
            {Object.values(resourceItems).map((resource, idx) => (
              <ResourceCard
                key={idx}
                resourceAsset={resource.asset}
                resourceInfo={{
                  textNode: (<>
                    {computeBuyPrice(resource)}
                    &nbsp;<Huny width='20px' height='20px' style={{ verticalAlign: 'sub' }} /></>)
                }}

                footerContent={(<>
                  <Typography color="primary" fontSize="12px">
                    You own
                  </Typography>

                  <span>
                    {formatIntegerNumber(resources[resource.asset.key].shiftedBy(-2).integerValue(BigNumber.ROUND_FLOOR))}
                    &nbsp;
                    <Box
                      component="img"
                      src={!!resource ? resource.asset.iconSrc : ''}
                      width="18px"
                      height="18px"
                      onError={QuestConstants.DEFAULT_SRC}
                      sx={{ verticalAlign: 'middle' }}
                    />
                  </span>
                </>)}
                width={`calc(90% / ${Object.entries(RESOURCES).length})`}
              >
                {/* BUTTON - BUY */}
                <ContainedButton sx={styles.buyButton} onClick={() => handleOnBuy(resource)}>
                  Buy
                </ContainedButton>
              </ResourceCard>
            ))}
          </Box>

          {/* Warning Box */}
          <Box sx={styles.warningBox}>
            <SvgIcon component={WarningIcon} sx={styles.warningIcon} />

            <Typography variant="body1" color="primary">
              Resources bought with HUNY increases the Buy price for the next buyer.&nbsp;
              <Link
                target="_blank"
                href="https://docs.zolar.io/stations/zomg-store-new"
              >
                Learn More
              </Link>
            </Typography>
          </Box>
          <StripeHeader sx={styles.endStripe} />
        </Box>
      </Box>

      <Box
        component="section"
        sx={combineStyles(styles.storeFrame, {
          borderImage: `url(${StoreFrameYellow})`,
        })}
      >

        <StoreNeonZOMG style={{
          position: 'absolute',
          inset: '-10% 0px 0px 65%',
        }}
          className="neonSigns"
        />

        <Box sx={styles.storeFrameContent}>
          <Typography variant="h2" color="secondary" sx={combineStyles(styles.storeFrameTitle, {
            background: 'radial-gradient(151.44% 152.65% at 49.92% 12.81%, #FFEAB4 9.64%, #FCCE58 43.75%)',
            ...TEXT_GRADIENT,
          })}>
            Sell
          </Typography>

          {/* View Window */}
          <Box sx={combineStyles(styles.displayWindow, {
            borderColor: '#FCCE58',
          })}>
            {[...Array((Object.keys(MissionGroundResource).length) - resourceItems.length)].map((resource, idx) => (
              <ResourceCard
                key={idx}
                isLoading={loadingTokens}
                width={`calc(90% / ${Object.entries(RESOURCES).length})`}
              >
                {/* BUTTON - SELL */}
                <ContainedButton sx={styles.buyButton} disabled>
                  -
                </ContainedButton>
              </ResourceCard>
            ))}
            {Object.values(resourceItems).map((resource, idx) => (
              <ResourceCard
                key={idx}
                itemFrameSrc={StoreItemFrameYellow}
                resourceAsset={resource.asset}
                footerContent={(<>
                  <Typography color="primary" fontSize="12px">
                    You own
                  </Typography>

                  <span>
                    {formatIntegerNumber(resources[resource.asset.key].shiftedBy(-2).integerValue(BigNumber.ROUND_FLOOR))}
                    &nbsp;
                    <Box
                      component="img"
                      src={!!resource ? resource.asset.iconSrc : ''}
                      width="18px"
                      height="18px"
                      onError={QuestConstants.DEFAULT_SRC}
                      sx={{ verticalAlign: 'middle' }}
                    />
                  </span>
                </>)}
                width={`calc(90% / ${Object.entries(RESOURCES).length})`}
              >
                {/* BUTTON - BUY */}
                <ContainedButton sx={combineStyles(styles.buyButton, {
                  background: 'radial-gradient(151.44% 152.65% at 49.92% 12.81%, #FFEAB4 9.64%, #FCCE58 43.75%)',
                })}
                  // onClick={() => handleOnSell(resource)}
                  href={strings.getArkyLink(ContractsBech32[network].Items)}
                  isExternal
                >
                  Sell
                </ContainedButton>

              </ResourceCard>
            ))}
          </Box>

          {/* Warning Box */}
          <Box sx={styles.warningBox}>
            <SvgIcon component={WarningIcon} sx={styles.warningIcon} />

            <Typography variant="body1" color="primary">
              Craft your resources and sell them on ARKY!&nbsp;
              <Link
                target="_blank"
                href={strings.getArkyLink(ContractsBech32[network].Items)}
              >
                List them here
              </Link>
            </Typography>
          </Box>
          <StripeHeader sx={styles.endStripe} />
        </Box>
      </Box>

      {!!dialogResource && (<>
        <BuyResourceDialog
          resource={dialogResource}
          open={showBuyResourceDialog}
          onClose={() => setShowBuyResourceDialog(false)}
        />
        <SellResourceDialog
          resource={dialogResource}
          open={showSellResourceDialog}
          onClose={() => setShowSellResourceDialog(false)}
        />
      </>)}
    </Box>
  </>)
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  viewContainer: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'start',
    gap: '1em',
    // flexWrap: 'wrap',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },

    "> *": {
      marginTop: '3rem',
      width: '48%',
      minWidth: 'min-content',
      maxWidth: '584px',
      // flex: '1 0 auto',
      [theme.breakpoints.down('lg')]: {
        // width: '100%',
        minWidth: '100%',
        marginX: 'auto',
      },
    },
  },

  /// Connect View
  frameWrapper: {
    // background: 'pink',

    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    placeItems: 'center',
    textAlign: 'center',
    gap: 2,

    border: '12px double transparent',
    borderImage: `url(${PanelFrame})`,
    borderImageSlice: '20',
    borderImageWidth: '20px',
    borderImageOutset: '0px',
    borderImageRepeat: 'stretch stretch',
    padding: '10px 20px',

    // position: 'relative',
    background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
    backgroundImage: `url(${PanelFrame})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1000%',
    backgroundPosition: 'center',
    backgroundClip: 'padding-box',

    maxWidth: '100%',
  },

  /// Item View
  storeFrame: {

    border: '1px solid transparent',
    borderImage: `url(${MiniStoreFrameBlue})`,
    borderImageSlice: '50',
    borderImageWidth: '50px 50px 50px 50px',
    borderImageOutset: '0px 0px 0px 0px',
    borderImageRepeat: 'stretch stretch',
    padding: '45px 20px 0',

    backgroundImage: `url(${MiniStoreFrameBlue})`,
    // background:'linear-gradient(180deg, #010025 0%, #000000 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundClip: 'content-box',

    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    placeItems: 'center',

    position: 'relative',
    ".neonSigns": {
      animation: 'flicker 5s linear infinite',
    },
  },

  storeFrameContent: {
    flex: 1,
    position: 'relative',
    paddingY: '20px',
    width: '100%',

    display: 'inline-flex',
    flexDirection: 'column',
    placeContent: 'start',
    placeItems: 'center',
  },
  storeFrameTitle: {
    textTransform: 'uppercase',
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-7%',
  },
  displayWindow: {
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    width: '100%',
    borderRadius: '24px',
    padding: '15px',
    border: '1px solid #AEF1EE',

    display: 'inline-flex',
    flexDirection: 'row',
    placeContent: 'space-evenly',
    placeItems: 'center',

    flexWrap: 'wrap',
    rowGap: 1,
  },

  resourcesCatalog: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'start',
    placeItems: 'center',
    gap: '.5em',
  },

  itemFrame: {
    backgroundImage: `url(${StoreItemFrame})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center',
    placeItems: 'center',
  },

  priceButton: {
    width: '100%',
    padding: '2px 0',

    ".priceLabel": {
      fontSize: "1.125rem",
      fontWeight: 700,
      lineHeight: "1.75rem",
      ...TEXT_GRADIENT,
      ...GUILD_LIGHTGRAY_GRADIENT,
    },
  },

  buyButton: {
    // paddingY: '5px',
    // paddingX: '50px',
    padding: 0,
    fontSize: '18px',
    borderRadius: '12px',
    // width: '100%',
  },

  gradientButton: {
    border: '1px solid #AEF1EE',
    flexBasis: 'fit-content',
    width: '100%',
    paddingY: '2px',
    fontWeight: 700,
    "&.Mui-disabled": {
      color: "rgba(254, 254, 254, 0.4)",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      "&:hover": {
        background: 'transparent',
      }
    },
  },


  /// Warning Box
  warningBox: {
    marginTop: '24px',
    marginBottom: '6px',
    background: ' linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'start',
    placeItems: 'center',
    gap: '1em',

    padding: '16px 36px',

    borderRadius: '16px',
  },
  warningIcon: {
    width: '24px',
    height: '24px',
  },


  endStripe: {
    textAlign: 'center',
    paddingY: '12px',
    marginY: '8px',
    transform: 'skewX(-25deg)',
    background: 'repeating-linear-gradient(90deg, #AEF1EE33, #AEF1EE33 16px, transparent 20px, transparent 24px)',
    width: '95%',
  },

}

export default ResourcesView;