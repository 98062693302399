import { Box, Divider, SxProps, Typography } from "@mui/material";
import { MetazoaLocationIcon } from "assets";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { FC } from "react";
import { NftMetadata } from "store/types";
import { bnOrZero } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import { getLocationTag } from "utils/zoaUtils";
import { LOCATION_TAGS, PROFESSIONS, STAT_PROFESSION } from "../MetazoaConstants";
import { locationStat, ProfessionBuffs } from "../utils/ProfessionBuffs";

interface ExtendedStatContentProps {
  panelContent: string;
  tokenProfessionBuff: ProfessionBuffs | undefined | null;
  token: NftMetadata;
}

const ExtendedStatPanelContent: FC<ExtendedStatContentProps> = ({
  panelContent,
  tokenProfessionBuff,
  token,
}: ExtendedStatContentProps) => {
  if (!panelContent || !token.profession) return null;

  const buffs = tokenProfessionBuff;
  const professionName = STAT_PROFESSION[token?.profession];
  if (!buffs) return null;

  const baseGatheringAmt = PROFESSIONS[STAT_PROFESSION[token?.profession]].baseGatheringAmt;
  const totalGatheringBonus = bnOrZero(buffs.gatheringBonus.total?.bonusAmt).plus(100).dividedBy(100);
  const totalGatheringAmt = bnOrZero(baseGatheringAmt).times(totalGatheringBonus);

  const baseLevellingAmt = 1;
  const totalLevellingBonus = bnOrZero(buffs.metazoaLevelingBonus.total?.bonusAmt).plus(100).dividedBy(100);
  const totalLevellingAmt = bnOrZero(baseLevellingAmt).times(totalLevellingBonus);

  const stakeIncorrectQuest = () => {
    if (!token.profession) return false;
    if (!token.location) return false
    if (token.profession === locationStat[token.location]) return false;
    else return true;
  }

  const location: string = token.location ?? 'Unknown';
  const locationTag: string = getLocationTag(location);

  if (panelContent === "Mastery Points per Day") {
    return (
      <Box sx={styles.contentContainer}>
        <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Mastery Improvement Speed</Typography>
        {
          Object.entries(buffs.levellingBonus).map(([key, bonus]) => {
            if (bonus.bonusAmt.eq(0) || bonus.label === "Total") return <></>;
            return (
              <Box sx={styles.attributesRow} key={key}>
                <Box sx={styles.attributesLabel}>
                  <Typography variant="subtitle1" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                    {bonus.label} Bonus
                  </Typography>
                  {bonus.label === "Profession" &&
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      sx={combineStyles(styles.statTextSize, styles.traitType)}
                      component="span"
                    >
                      {professionName}
                    </Typography>
                  }
                </Box>
                <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
                  +{bonus.bonusAmt.toFixed(2)}%
                </Typography>
              </Box>
            )
          })
        }
        <Box sx={combineStyles(styles.attributesRow, styles.totalRow)}>
          <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, { fontSize: '14px', ...GUILD_LIGHTGRAY_GRADIENT })}>
            Total Bonus
          </Typography>
          <Typography variant="subtitle1" color="success.main" sx={combineStyles(styles.statTextSize, { fontSize: '14px', fontWeight: '400' })}>
            +{buffs.levellingBonus.total?.bonusAmt.toFixed(2)}%
          </Typography>
        </Box>
        <Divider sx={styles.cardDivider} />
        <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>{panelContent}</Typography>
        <Box sx={styles.attributeBox}>
          <Box sx={styles.attributesRow}>
            <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Base</Typography>
            <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
              5
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.attributeBox}>
          <Box sx={styles.attributesRow}>
            <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Total Bonus</Typography>
            <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
              x {bnOrZero(buffs.levellingBonus.total?.bonusAmt.plus(100).dividedBy(100)).toNumber().toFixed(2)}
            </Typography>
          </Box>
        </Box>

        <Box sx={combineStyles(styles.attributesRow, styles.totalRow)}>
          <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, { fontSize: '14px', ...GUILD_LIGHTGRAY_GRADIENT })}>
            MSP / Day
          </Typography>
          <Typography variant="subtitle1" color="success.main" sx={combineStyles(styles.statTextSize, { fontSize: '14px', fontWeight: '400' })}>
            {bnOrZero(buffs.levellingBonus.total?.bonusAmt.plus(100).dividedBy(100).times(5)).toNumber().toFixed(2)}
          </Typography>
        </Box>
      </Box >
    );
  }
  else if (panelContent === "Metazoa Levelling Speed") {
    return (
      <Box sx={styles.contentContainer}>
        <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Metazoa Levelling Bonus</Typography>

        {Object.entries(buffs.metazoaLevelingBonus).map(([key, bonus]) => {
          if (bonus.bonusAmt.eq(0) || bonus.label === 'Total') return <></>;
          return (
            <Box sx={styles.attributesRow} key={key}>
              <Box sx={styles.attributesLabel}>
                <Typography variant="subtitle1" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT, bonus.label === 'Total' ? { fontsize: '14px' } : null)}>
                  {bonus.label} Bonus
                </Typography>
                {bonus.label === "Profession" &&
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={combineStyles(styles.statTextSize, styles.traitType)}
                    component="span"
                  >
                    {professionName}
                  </Typography>
                }
              </Box>
              <Typography variant="subtitle1" color="success.main" sx={combineStyles(styles.statTextSize, bonus.label === 'Total' ? { fontsize: '14px', fontWeight: '400' } : null)}>
                +{bonus.bonusAmt.toFixed(2)}%
              </Typography>
            </Box>
          )
        })}
        <Box sx={combineStyles(styles.attributesRow, styles.totalRow)}>
          <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, { fontSize: '14px', ...GUILD_LIGHTGRAY_GRADIENT })}>
            Total Bonus
          </Typography>
          <Typography variant="subtitle1" color="success.main" sx={combineStyles(styles.statTextSize, { fontSize: '14px', fontWeight: '400' })}>
            +{buffs.metazoaLevelingBonus.total?.bonusAmt.toFixed(2)}%
          </Typography>
        </Box>
        <Divider sx={styles.cardDivider} />
        <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>{panelContent}</Typography>

        <Box sx={styles.attributeBox}>
          <Box sx={styles.attributesRow}>
            <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Total Bonus</Typography>
            <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
              x{totalLevellingBonus.toFixed(2)}
            </Typography>
          </Box>
        </Box>

        <Box sx={combineStyles(styles.attributesRow, styles.totalRow)}>
          <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, { fontSize: '14px', ...GUILD_LIGHTGRAY_GRADIENT })}>
            Total
          </Typography>
          <Typography variant="subtitle1" color="success.main" sx={combineStyles(styles.statTextSize, { fontSize: '14px', fontWeight: '400' })}>
            {totalLevellingAmt.toFixed(2)}%
          </Typography>
        </Box>
      </Box >
    );
  }
  else if (panelContent === "Resource Gathered per Day") {
    return (
      <Box sx={styles.contentContainer}>
        <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>Resource Gathering Speed</Typography>
        {(stakeIncorrectQuest() && location !== "Moonbattle") &&
          <Box sx={styles.attributesRow}>
            <Box sx={styles.attributesLabel}>
              <Typography variant="subtitle1" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT)}>
                Staked Location
              </Typography>
            </Box>
            <Box sx={{
              flexDirection: 'row',
              "svg path": {
                fill: `url(#${locationTag}0)`,
              },
              "svg circle": {
                stroke: `url(#${locationTag}1)`,
              },
            }}>
              <Typography variant="body1" color="secondary.main" sx={combineStyles(styles.levelProfessionText, LOCATION_TAGS[locationTag])}>
                <MetazoaLocationIcon style={{
                  verticalAlign: 'middle',
                  width: '22px',
                  height: '22px',
                  marginRight: '0.5em',
                }} />
                <span style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>{(!!token.profession && !true) ? PROFESSIONS[STAT_PROFESSION[token.profession]].missionGround : location}</span>
              </Typography>
            </Box>
          </Box>
        }
        {
          Object.entries(buffs.gatheringBonus).map(([key, bonus]) => {
            if (bonus.bonusAmt.eq(0) || bonus.label === 'Total') return <></>;
            return (
              <Box sx={styles.attributesRow} key={key}>
                <Box sx={styles.attributesLabel}>
                  <Typography variant="subtitle1" sx={combineStyles(styles.statTextSize, GUILD_LIGHTGRAY_GRADIENT, bonus.label === 'Total' ? { fontsize: '14px' } : null)}>
                    {bonus.label} Bonus
                  </Typography>
                  {bonus.label === "Profession" &&
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      sx={combineStyles(styles.statTextSize, styles.traitType)}
                      component="span"
                    >
                      {professionName}
                    </Typography>
                  }
                </Box>
                <Typography variant="subtitle1" color="success.main" sx={combineStyles(styles.statTextSize, bonus.label === 'Total' ? { fontsize: '14px', fontWeight: '400' } : null)}>
                  +{bonus.bonusAmt.toFixed(2)}%
                </Typography>
              </Box>
            )
          })
        }
        <Box sx={combineStyles(styles.attributesRow, styles.totalRow)}>
          <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, { fontSize: '14px', ...GUILD_LIGHTGRAY_GRADIENT })}>
            Total Bonus
          </Typography>
          <Typography variant="subtitle1" color="success.main" sx={combineStyles(styles.statTextSize, { fontSize: '14px', fontWeight: '400' })}>
            +{buffs.gatheringBonus.total?.bonusAmt.toFixed(2)}%
          </Typography>
        </Box>
        <Divider sx={styles.cardDivider} />
        <Typography variant="subtitle1" color="secondary.main" sx={styles.statDetailsHeader}>{panelContent}</Typography>
        <Box sx={styles.attributeBox}>
          <Box sx={styles.attributesRow}>
            <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Base</Typography>
            <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
              {baseGatheringAmt}
            </Typography>
          </Box>
        </Box>

        <Box sx={styles.attributeBox}>
          <Box sx={styles.attributesRow}>
            <Typography color="primary" variant="subtitle1" sx={combineStyles(styles.traitType, GUILD_LIGHTGRAY_GRADIENT)}>Total Bonus</Typography>
            <Typography variant="subtitle1" color="success.main" sx={styles.statTextSize}>
              x{totalGatheringBonus.toFixed(2)}
            </Typography>
          </Box>
        </Box>

        <Box sx={combineStyles(styles.attributesRow, styles.totalRow)}>
          <Typography variant="subtitle1" color="primary" sx={combineStyles(styles.statTextSize, { fontSize: '14px', ...GUILD_LIGHTGRAY_GRADIENT })}>
            Resource / Day
          </Typography>
          <Typography variant="subtitle1" color="success.main" sx={combineStyles(styles.statTextSize, { fontSize: '14px', fontWeight: '400' })}>
            {totalGatheringAmt.toFixed(2)}
          </Typography>
        </Box>
      </Box >
    );
  }
  else return null;
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  statTextSize: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
  statDetailsHeader: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    marginBottom: '8px',
  },
  cardDivider: {
    background: 'rgba(174, 241, 238, 0.1)',
    marginY: '12px',
  },
  attributeBox: {
    width: '100%',
  },
  attributesRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',

    "> *": {
      padding: '1px 6px'
    }
  },
  totalRow: {
    marginTop: '8px',
  },
  attributesLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  traitType: {
    background: 'linear-gradient(225deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0.256) 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '12px',
    lineHeight: '18px',
    weight: 600,
  },
  levelProfessionText: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
}

export default ExtendedStatPanelContent;