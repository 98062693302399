import dayjs from "dayjs";

export interface CachedItem<T> {
  value: T;
  version?: number;
  cachedAt: number; // unix timestamp
}

export const getCachedItem = <T>(key: string): CachedItem<T> | null => {
  const cachedItem = localStorage.getItem(key);
  if (cachedItem === null) return null;
  try {
    return JSON.parse(cachedItem);
  } catch (error) { };
  return null;
};

export const putCachedItem = <T>(key: string, value: T, version: number): CachedItem<T> => {
  const cachedItem: CachedItem<T> = {
    version,
    value,
    cachedAt: dayjs().unix(),
  }
  localStorage.setItem(key, JSON.stringify(cachedItem));

  return cachedItem;
};

export const offloadCachedItems = <T>(keyPrefix: string, activeVersion: number) => {
  const storage: Storage = localStorage;

  const prefixedKeys = Object.entries(storage).filter(([k, v]) => k.includes(keyPrefix));
  const offloadedKeys: string[] = Object.keys(Object.entries(prefixedKeys).filter(([k,]) => {
    const cachedMetadata = getCachedItem<T>(k);
    return (cachedMetadata != null) ? ((cachedMetadata.version) !== activeVersion) : false;
  }));

  // Remove offload keys
  if (!!offloadedKeys.length) offloadedKeys.forEach((k) => storage.removeItem(k))
}
