import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Chip, CircularProgress, Container, Pagination, styled, SxProps, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { BaseButton } from "assets";
import GuildBannerPreview from "assets/GuildBannerPreview.svg";
import HunyIcon from "assets/GuildHunyLogo.svg";
import MinoIcon from "assets/icons/Minos.svg";
import StrengthRatingIcon from "assets/icons/StrengthRating.svg";
import UrsaIcon from "assets/icons/Ursas.svg";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import { CRESTS, CREST_COLOURS, GUILD_GRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getProfile } from "saga/selectors";
import { actions } from 'store';
import { Guild } from 'store/types';
import { GuildExemptions } from 'utils/constants';
import { formatIntegerNumber } from "utils/strings/strings";
import { combineStyles } from 'utils/themeUtilities';
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from 'utils/useNetwork';
import useRedux from "utils/useRedux";
import { MembersPreview } from "./components";

const Guilds: React.FC = () => {
  const network = useNetwork();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const metazoaProfileState = useSelector(getProfile);
  const { profile } = metazoaProfileState;
  const rowCountLimit = 6;
  const [rowCount, setRowCount] = useState(0);
  const [currentGuilds, setCurrentGuilds] = useState<Guild[]>([])

  const guildStore = useRedux((state) => state.guild.allGuilds);
  const wallet = useRedux((state) => state.wallet.wallet);

  useEffect(() => {
    if (!guildStore) {
      dispatch(actions.Guild.loadGuilds());
    }
    // eslint-disable-next-line
  }, [network])

  const sortedGuilds = useMemo(() => {
    if (!guildStore || guildStore?.length === 0) return;
    setCurrentGuilds(guildStore);
    return currentGuilds.filter((g: Guild) => !(GuildExemptions[network]).includes(g.id));

    // eslint-disable-next-line
  }, [network, guildStore, currentGuilds])

  const handleNextPage = () => {
    if (!guildStore) return;
    if (rowCount + rowCountLimit < guildStore?.length) setRowCount(rowCount + rowCountLimit);
  }
  const handlePrevPage = () => {
    if (rowCount - rowCountLimit > -1) setRowCount(rowCount - rowCountLimit);
  }
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setRowCount((value - 1) * rowCountLimit);
  };

  return (
    <Container sx={styles.root}>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between" sx={styles.headerBox}>
        <Box>
          <Typography variant="h2" color="primary" sx={GUILD_GRAY_GRADIENT}>Meet the Guilds</Typography>
          <Typography variant="body1" color="primary" mt="32px" sx={GUILD_GRAY_GRADIENT}>"Gentlemen, you can't fight in here. This is the war room."</Typography>
        </Box>
        <Box sx={styles.headerButtonBox}>
          {!wallet
            ? <ConnectWalletButtonCTA sx={styles.connectButton} />
            : profile?.guildId
              ? <Button
                component={Link}
                variant="contained"
                color="secondary"
                sx={styles.headerButton}
                to={`/guilds/${profile.guildId}`}
              >
                <BaseButtonBox>
                  <BaseButton height="100%" />
                </BaseButtonBox>
                My Guild
              </Button>
              : <Button
                component={Link}
                variant="contained"
                color="secondary"
                sx={styles.headerButton}
                to="/guilds/create"
              >
                <BaseButtonBox>
                  <BaseButton height="100%" />
                </BaseButtonBox>
                Buy a Crest
              </Button>
          }
        </Box>
      </Box>
      {!sortedGuilds
        ? <Box display="flex" alignItems="center" justifyContent="center" mt="80px">
          <CircularProgress size={36} />
        </Box>
        : !sortedGuilds?.length
          ? <Box sx={styles.previewBox}>
            <Typography variant="body1" color="primary" sx={styles.previewText}>
              Be the first Guild Master - Buy a Crest and form your proud team to conquer the Moon.
            </Typography>
            {!wallet
              ? <ConnectWalletButtonCTA sx={styles.connectButton} />
              : <Button
                component={Link}
                variant="contained"
                color="secondary"
                sx={styles.buyCrestButton}
                to="/guilds/create"
              >
                <BaseButtonBox>
                  <BaseButton height="100%" />
                </BaseButtonBox>
                Buy a Crest
              </Button>
            }
          </Box>
          : <TableContainer sx={styles.tableContainer}>
            <Table sx={styles.table}>
              <TableBody>
                {sortedGuilds.slice(rowCount, rowCount + rowCountLimit).map((guild: Guild, index: number) => {
                  const guildRank: number = index + 1 + rowCount
                  const guildSize: number = guild.members?.length ?? guild.currentSize
                  return (
                    <TableRow
                      key={`Guild-${guildRank}`}
                      sx={guildRank === 1 ? styles.tableRowYellow : guildRank === 2 || guildRank === 3 ? styles.tableRowGreen : styles.tableRowBlue}
                      onClick={() => navigate(`/guilds/${guild.id}`)}
                    >
                      {/* no. */}
                      <TableCell align="center" sx={{ minWidth: "100px", padding: "25px" }}>
                        <Typography
                          variant="h2"
                          color="primary"
                          sx={guildRank <= 3 ? styles.number : styles.numberSmall}
                          style={{ color: guildRank === 1 ? "" : guildRank === 2 || guildRank === 3 ? "#2FF583" : "#AEF1EE" }}
                        >
                          {guildRank}
                        </Typography>
                      </TableCell>

                      {/* crest & commanders */}
                      <TableCell sx={{ minWidth: "360px" }}>
                        <Box position="relative">
                          <Box position="relative">
                            {guild.crestImageUrl &&
                              <Box
                                component="img"
                                src={guild.crestImageUrl}
                                sx={guildRank <= 3 ? styles.crestImage : styles.crestImageSmall}
                              />
                            }
                            <Box
                              component="img"
                              src={
                                CRESTS[guild.guildCrest.design - 1][
                                CREST_COLOURS.indexOf(guild.guildCrest.colour)
                                ]
                              }
                              sx={guildRank <= 3 ? styles.crest : styles.crestSmall}
                              position="relative"
                            />
                          </Box>

                          <Box sx={guildRank <= 3 ? styles.commanders : styles.commandersSmall}>
                            <MembersPreview guild={guild} />
                          </Box>
                        </Box>
                      </TableCell>

                      {/* guild name */}
                      <TableCell sx={styles.guildTableCell}>
                        <Typography variant="h2" color="primary" sx={guildRank <= 3 ? styles.guildName : styles.guildNameSmall}>{guild.name}</Typography>

                        {/* chips */}
                        <Box display="flex" mt="10px">
                          <RedChip label={<Typography variant="overline">{guild.guildSigil?.name ?? "playstyle"}</Typography>} />
                          {guildSize === guild.maxCapacity
                            ? <BlueChip label={<Typography variant="overline">Max Capacity</Typography>} />
                            : <GreenChip label={<Typography variant="overline">Recruiting ({guildSize}/{guild.maxCapacity})</Typography>} />
                          }
                        </Box>
                      </TableCell>

                      {/* other info */}
                      <TableCell sx={{ minWidth: "150px" }}>
                        <Box display="flex" flexDirection="column" justifyContent="center" sx={styles.infoBox}>
                          {/* strength rating */}
                          <Box display="flex" alignItems="center">
                            <Box component="img" src={StrengthRatingIcon} sx={styles.icon} />
                            <Typography variant="body1" color="primary" sx={styles.infoText}>{formatIntegerNumber(guild.strength)}</Typography>
                          </Box>
                          {guildRank <= 3 && (
                            <Fragment>
                              {/* huny */}
                              <Box display="flex" alignItems="center" mt="6px">
                                <Box component="img" src={HunyIcon} sx={styles.icon} />
                                <Typography variant="body1" color="primary" sx={styles.infoText}>{formatIntegerNumber(guild.totalHunyHolding)}</Typography>
                              </Box>

                              {/* ursa */}
                              <Box display="flex" alignItems="center" mt="6px">
                                <Box component="img" src={UrsaIcon} sx={styles.icon} />
                                <Typography variant="body1" color="primary" sx={styles.infoText}>{guild.totalUrsa ?? '-'}</Typography>
                              </Box>

                              {/* mino */}
                              <Box display="flex" alignItems="center" mt="6px">
                                <Box component="img" src={MinoIcon} sx={styles.icon} />
                                <Typography variant="body1" color="primary" sx={styles.infoText}>{guild.totalMino ?? '-'}</Typography>
                              </Box>
                            </Fragment>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>

            {(sortedGuilds?.length ?? 0) > rowCountLimit && (
              <Box sx={styles.guildsListPagination}>
                <Button
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  sx={styles.backButton}
                  startIcon={<ArrowBackIosIcon />}
                  onClick={handlePrevPage}
                  disabled={Math.floor(rowCount / rowCountLimit) <= 0}
                >
                  <Typography variant="button" sx={combineStyles(styles.gradientText, { ...Math.floor(rowCount / rowCountLimit) <= 0 && { background: 'rgba(0, 0, 0, 0.26)!important', } })}>
                    Prev
                  </Typography>
                </Button>

                <Pagination sx={styles.pagination}
                  count={Math.ceil(((sortedGuilds?.length ?? 0) / rowCountLimit))}
                  page={Math.ceil(rowCount / rowCountLimit) + 1}
                  onChange={handleChange} hidePrevButton hideNextButton />

                <Button
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  sx={styles.backButton}
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={handleNextPage}
                  disabled={sortedGuilds?.length <= rowCount + rowCountLimit}
                >
                  Next
                </Button>
              </Box>
            )}

          </TableContainer>
      }
    </Container>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  root: {
    marginTop: "160px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },

  guildsListPagination: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'center',
  },

  backButton: {
    boxShadow: "0px 0px 24px rgba(174, 241, 238, 0.12)",
    padding: "12px 24px",
    "@media (max-width:900px)": {
      flex: 1,
      marginRight: "10px",
    },
    '&.Mui-disabled': {
      boxShadow: 'none!important',
      visibility: 'hidden!important',
    },
  },

  pagination: {
    "& .MuiButtonBase-root.MuiPaginationItem-root, .MuiPaginationItem-root.MuiPaginationItem-ellipsis": {
      background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: '18px'
    },
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
      background: "linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    }
  },

  gradientText: {
    background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },

  headerBox: {
    "@media (max-width:600px)": {
      flexDirection: "column",
    },
  },

  headerButtonBox: {
    marginTop: "-40px",
    "@media (max-width:600px)": {
      marginTop: "-20px",
    },
  },

  previewBox: {
    backgroundImage: `url(${GuildBannerPreview})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "80px",
    minHeight: "400px",
    paddingX: "80px",
    "@media (max-width:600px)": {
      marginTop: "40px",
    },
  },

  previewText: {
    fontSize: "16px",
    textAlign: "center",
  },

  connectButton: {
    "& #wrapperBox": {
      "@media (max-width:900px)": {
        width: "100%",
        display: "initial",
      },
    },

    "& .MuiBox-root": {
      padding: 0,
    },

    "& .MuiButton-root": {
      height: 64,
      minWidth: 300,
      marginTop: "40px",
      "@media (max-width:600px)": {
        width: "100%",
        minWidth: "",
      },
    }
  },

  headerButton: {
    marginTop: "40px",
    height: 64,
    minWidth: 300,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
      textAlign: "center",
    },
  },

  buyCrestButton: {
    marginTop: "40px",
    height: 64,
    minWidth: 300,
    "@media (max-width:600px)": {
      width: "100%",
      minWidth: "",
      textAlign: "center",
    },
  },

  tableContainer: {
    marginTop: "80px",
    minHeight: "1200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "@media (max-width:600px)": {
      marginTop: "40px",
    },

    '::-webkit-scrollbar': {
      height: "6px",
      width: "6px",
    },

    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },

    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },

  table: {
    borderCollapse: "separate",
    borderSpacing: "0px 60px",
    marginTop: "-20px", // due to border spacing
  },

  tableRowYellow: {
    cursor: 'pointer',
    textDecoration: "none",
    padding: "18px 40px",
    background: "linear-gradient(357.81deg, rgba(255, 254, 243, 0) 1.85%, rgba(241, 226, 174, 0.066) 24.27%, rgba(255, 199, 0, 0.2) 99.7%)",
    "& .MuiTableCell-root": {
      borderTop: "1px solid #FFC700",
      borderBottom: "1px solid #FFC700",
      "&:first-of-type": {
        borderLeft: "1px solid #FFC700",
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
      },

      "&:not(:first-of-type):not(:last-child)": {
        borderRadius: 0,
      },

      "&:last-child": {
        borderRight: "1px solid #FFC700",
        borderTopRightRadius: "16px",
        borderBottomRightRadius: "16px",
      }
    },
  },

  tableRowGreen: {
    cursor: 'pointer',
    textDecoration: "none",
    padding: "18px 40px",
    background: "linear-gradient(357.81deg, rgba(243, 255, 250, 0) 1.85%, rgba(174, 241, 213, 0.066) 24.27%, rgba(47, 245, 131, 0.2) 99.7%)",
    "& .MuiTableCell-root": {
      borderTop: "1px solid #2FF583",
      borderBottom: "1px solid #2FF583",
      "&:first-of-type": {
        borderLeft: "1px solid #2FF583",
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
      },

      "&:not(:first-of-type):not(:last-child)": {
        borderRadius: 0,
      },

      "&:last-child": {
        borderRight: "1px solid #2FF583",
        borderTopRightRadius: "16px",
        borderBottomRightRadius: "16px",
      }
    },
  },

  tableRowBlue: {
    cursor: 'pointer',
    textDecoration: "none",
    padding: "18px 40px",
    background: "linear-gradient(357.81deg, rgba(243, 255, 254, 0) 1.85%, rgba(174, 241, 238, 0.066) 24.27%, rgba(0, 194, 255, 0.2) 99.7%)",
    "& .MuiTableCell-root": {
      borderTop: "1px solid #AEF1EE",
      borderBottom: "1px solid #AEF1EE",
      "&:first-of-type": {
        borderLeft: "1px solid #AEF1EE",
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
      },

      "&:not(:first-of-type):not(:last-child)": {
        borderRadius: 0,
      },

      "&:last-child": {
        borderRight: "1px solid #AEF1EE",
        borderTopRightRadius: "16px",
        borderBottomRightRadius: "16px",
      }
    },
  },

  guildTableCell: {
    minWidth: "240px",
    "@media (max-width:900px)": {
      minWidth: "400px",
    },
  },

  number: {
    color: "#FFC700",
    fontSize: "64px!important",
    lineHeight: "64px!important",
    "@media (max-width:900px)": {
      fontSize: "40px!important",
      lineHeight: "40px!important",
    },
  },

  numberSmall: {
    color: "#FFC700",
    fontSize: "40px!important",
    lineHeight: "40px!important",
  },

  icon: {
    height: "32px",
    width: "32px",
    marginRight: "14px",
    marginLeft: "28px",
  },

  infoBox: {
    borderLeft: "1px solid rgba(243, 255, 254, 0.2)",
    minHeight: "50px",
  },

  infoText: {
    fontWeight: 700,
  },

  guildName: {
    background: "-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "inline-block", // fit content for linear gradient bg
    fontSize: "40px!important",
    lineHeight: "40px!important",
  },

  guildNameSmall: {
    background: "-webkit-linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "inline-block", // fit content for linear gradient bg
    fontSize: "24px!important",
    lineHeight: "24px!important",
  },

  crest: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-85%, -51%)",
    height: "310px",
    width: "310px",
    borderRadius: "50%",
  },

  crestSmall: {
    position: "absolute",
    top: "50%",
    left: "48%",
    transform: "translate(-98%, -50%)",
    height: "210px",
    width: "210px",
    borderRadius: "50%",
  },

  crestImage: {
    height: "110px",
    width: "110px",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-150%, -50%)",
  },

  crestImageSmall: {
    height: "77.5px",
    width: "77.5px",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-193.5%, -50%)",
  },

  commanders: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(12.5%, -50%)",
  },

  commandersSmall: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(0%, -50%)",
  },

  commanderPic: {
    boxShadow: "0px 0px 12px 0px #00F0FF",
    width: "48px",
    height: "48px",
    borderRadius: "16px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    marginTop: "-1.5px",
    "@media (max-width:600px)": {
      marginTop: "-2.5px",
    },
  },
}

const RedChip = styled(Chip)({
  backgroundColor: "#F65E5E",
  borderRadius: "16px",
  padding: "1px 6px",
  height: "18px",
  marginRight: "10px",
  "& .MuiTypography-root": {
    color: "#FEFEFE",
    fontSize: "12px",
    lineHeight: "18px",
    textTransform: "uppercase",
    letterSpacing: "0.1em",
    fontWeight: 600,
  },

  "& .MuiChip-label": {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const BlueChip = styled(Chip)({
  background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
  borderRadius: "16px",
  padding: "1px 6px",
  height: "18px",
  "& .MuiTypography-root": {
    color: "#010025",
    fontSize: "12px",
    lineHeight: "18px",
    textTransform: "uppercase",
    letterSpacing: "0.1em",
    fontWeight: 600,
  },

  "& .MuiChip-label": {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const GreenChip = styled(Chip)({
  background: "linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)",
  borderRadius: "16px",
  padding: "1px 6px",
  height: "18px",
  "& .MuiTypography-root": {
    color: "#010025",
    fontSize: "12px",
    lineHeight: "18px",
    textTransform: "uppercase",
    letterSpacing: "0.1em",
    fontWeight: 600,
  },

  "& .MuiChip-label": {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const BaseButtonBox = styled(Box)({
  position: "absolute",
  left: 0,
  top: "2%",
  zIndex: "1",
  "@media (max-width:600px)": {
    height: "100%",
  },
});

export default Guilds;
