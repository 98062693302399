import { Theme } from "@mui/material";

const MuiToggleButtonGroup = (theme: Theme) => ({
  styleOverrides: {
    grouped: {
        '&. MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            borderRadius: '16px',
        }
    }
  },
});

export default MuiToggleButtonGroup;