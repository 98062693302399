import { Box, CircularProgress, SxProps } from "@mui/material";
import DragFrameBlue from "assets/DragFrameBlue.svg";
import DragFrameError from "assets/DragFrameError.svg";
import DragFrameGreen from "assets/DragFrameGreen.svg";
import DragFrameOrange from "assets/DragFrameOrange.svg";
import { EQUIP_CATEGORIES } from "components/Metazoa/ResourceConstants";
import { IDroppable } from "components/Metazoa/ResourcesDialogs/components/GemsDND/Droppable";
import { FC } from "react";
import { useDrop } from "react-dnd";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import { EquipDND } from "..";
import { invalidDragType } from "./EquipDraggable";
import { IInventoryItem } from "./EquipPanel";

export interface EquipDroppableProps extends Omit<IDroppable, "limit"> {
  onDrop: (item: IInventoryItem) => void;
  item: IInventoryItem;
  type?: typeof EQUIP_CATEGORIES[keyof typeof EQUIP_CATEGORIES];
  sx?: SxProps<AppTheme>;
  showMiniTypeIcon?: boolean;
}

const EquipDroppable: FC<EquipDroppableProps> = ({
  id,
  item,
  onDrop,
  type = invalidDragType,
  acceptableTypes,
  sx = {},
  showMiniTypeIcon = false,
  options = {
    lockDrop: false,
    isLoading: false,
  },
}: EquipDroppableProps) => {
  options = {
    lockDrop: false,
    isLoading: false,
    ...options,
  };
  const isValidItem: boolean = !!Object.keys(item).length;

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: acceptableTypes,
      canDrop: (itm: IInventoryItem) => {
        let dropability = !!Object.keys(itm).length;
        dropability = acceptableTypes.includes(itm.type.type);

        // Doesn't already have an existing valid item
        dropability = !isValidItem;

        // If drop is force locked
        if (options.lockDrop) dropability = false;

        return dropability;
      },
      drop(itm: IInventoryItem) {
        onDrop(itm);
        return { id };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop]
  );

  type = EQUIP_CATEGORIES[acceptableTypes[0]];

  return (
    <Box
      ref={drop}
      className="droppable"
      data-types={acceptableTypes.toString()}
      data-dropability={canDrop}
      sx={combineStyles({
        ...styles.dragBox,
        // transition: "all 0.5s linear",
        "&::before": {
          content: '""',
          display: item && !showMiniTypeIcon ? "none" : "block",
          background: `url(${isValidItem ? type.icon : ""})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundClip: "border-box",

          position: "absolute",
          inset: "30%",

          width: !item ? "30px" : "56px",
          height: !item ? "30px" : "56px",

          ...(showMiniTypeIcon && {
            zIndex: 100,
            width: "16px",
            height: "16px",

            top: "20%",
            left: "20%",
          }),
        },
        "> img": {
          width: !isValidItem ? "30px" : "56px",
          height: !isValidItem ? "30px" : "56px",
        },
      },
        {
          ...(((!isOver && canDrop) || isValidItem) && {
            backgroundImage: `url(${DragFrameOrange})!important`,
          }),
          ...(((isOver && !canDrop) || (isOver && isValidItem)) && {
            backgroundImage: `url(${DragFrameError})!important`,
          }),
          ...(isOver &&
            canDrop && {
            backgroundImage: `url(${DragFrameGreen})!important`,
          }),
        },
        sx
      )}>

      {options.isLoading
        ? <CircularProgress size={18} />
        : (
          isValidItem ? (
            <EquipDND.Draggable
              {...item}
              options={{
                showCount: false,
                lockDrop: options.lockDrop,
              }}
            />
          ) : (
            <Box
              component="img"
              className="inventoryType"
              src={type.icon}
              alt={`${type.type}-${id}`}
            />
          )
        )
      }
    </Box>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  dragBox: {
    minWidth: "64px",
    minHeight: "64px",

    background:
      "linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)",
    backgroundImage: `url(${DragFrameBlue})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",

    display: "flex",
    placeContent: "center",
    placeItems: "center",
    position: "relative",
  },
};

export default EquipDroppable;
