import BigNumber from "bignumber.js";
import { NftMetadata } from "store/types";
import { bnOrZero } from "utils/strings";

export interface ProfessionBuffs {
  gatheringBonus: GatheringBonus;
  levellingBonus: MspBonus;
  metazoaLevelingBonus: {
    total?: Bonus;
    guildStrengthBonus?: Bonus;
    hunyBonus?: Bonus;
    enduranceBonus?: Bonus;
  };
}

interface GatheringBonus {
  profession?: Bonus;
  professionLevel?: Bonus;
  guildStrength?: Bonus;
  huny?: Bonus;
  stat?: Bonus;
  secondaryStat?: Bonus;
  total?: Bonus;
}

interface MspBonus {
  profession?: Bonus;
  guildStrength?: Bonus;
  huny?: Bonus;
  stat?: Bonus;
  secondaryStat?: Bonus;
  accStat?: Bonus;
  total?: Bonus;
}

interface Bonus {
  label: string,
  bonusAmt: BigNumber,
}

export enum locationStat {
  'Moon Battlegrounds' = 'STR',
  'Elder Woodlands' = 'INT',
  'Zolar Asteroid Belt' = 'DEX',
}

export const computeProfessionBuffs = (token: NftMetadata, location: string) => {
  if (!token) return null;
  if (!token.profession) return null;
  if (!token.bonuses) return null;

  const bonuses = token.bonuses;
  const profession = token.profession;

  const tokenProfessionBuff: ProfessionBuffs = {
    gatheringBonus: {
      profession: {
        label: "Profession",
        bonusAmt: bnOrZero(bonuses.resourceGatheringBonus[locationStat[location] ?? profession]?.professionSpecialisationBonus),
      },
      professionLevel: {
        label: "Mastery Level",
        bonusAmt: bnOrZero(bonuses.resourceGatheringBonus[locationStat[location] ?? profession]?.masteryLevelBonus),
      },
      guildStrength: {
        label: "Guild Strength",
        bonusAmt: bnOrZero(bonuses.resourceGatheringBonus[locationStat[location] ?? profession]?.guildStrengthBonus),
      },
      huny: {
        label: "HUNY Power",
        bonusAmt: bnOrZero(bonuses.resourceGatheringBonus[locationStat[location] ?? profession]?.hunyBonus),
      },
      stat: {
        label: "Stat",
        bonusAmt: bnOrZero(bonuses.resourceGatheringBonus[locationStat[location] ?? profession]?.statBonus),
      },
      total: {
        label: "Total",
        bonusAmt: bnOrZero(bonuses.resourceGatheringBonus[locationStat[location] ?? profession]?.total) // hard code profession specialisation bonus
      }
    },
    levellingBonus: {
      profession: {
        label: "Profession",
        bonusAmt: bnOrZero(bonuses.masteryLevelingBonus[locationStat[location] ?? profession]?.professionSpecialisationBonus),
      },
      guildStrength: {
        label: "Guild Strength",
        bonusAmt: bnOrZero(bonuses.masteryLevelingBonus[locationStat[location] ?? profession]?.guildStrengthBonus),
      },
      huny: {
        label: "HUNY Power",
        bonusAmt: bnOrZero(bonuses.masteryLevelingBonus[locationStat[location] ?? profession]?.hunyBonus),
      },
      stat: {
        label: "Stat",
        bonusAmt: bnOrZero(bonuses.masteryLevelingBonus[locationStat[location] ?? profession]?.statBonus),
      },
      secondaryStat: {
        label: "Speed",
        bonusAmt: bnOrZero(bonuses.masteryLevelingBonus[locationStat[location] ?? profession]?.speedBonus),
      },
      accStat: {
        label: "Accuracy",
        bonusAmt: bnOrZero(bonuses.masteryLevelingBonus[locationStat[location] ?? profession]?.accuracyBonus),
      },
      total: {
        label: "Total",
        bonusAmt: bnOrZero(bonuses.masteryLevelingBonus[locationStat[location] ?? profession]?.total),
      }
    },
    metazoaLevelingBonus: {
      total: {
        label: "Total",
        bonusAmt: bnOrZero((bonuses.metazoaLevelingBonus?.total) as unknown as string ?? 0)
      },
      guildStrengthBonus: {
        label: "Guild Strength",
        bonusAmt: bnOrZero((bonuses.metazoaLevelingBonus?.guildStrengthBonus) as unknown as string ?? 0)
      },
      hunyBonus: {
        label: "Huny",
        bonusAmt: bnOrZero((bonuses.metazoaLevelingBonus?.hunyBonus) as unknown as string ?? 0)
      },
      enduranceBonus: {
        label: "Endurance",
        bonusAmt: bnOrZero((bonuses.metazoaLevelingBonus?.enduranceBonus) as unknown as string ?? 0)
      },
    }

  }

  return tokenProfessionBuff
}