import { Box, CircularProgress, Divider, IconButton, InputAdornment, Link, OutlinedInput, Slider, SvgIcon, SxProps, Typography } from "@mui/material";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { AddIcon, ExternalLink, Huny, RemoveIcon, ZilTokenIcon } from "assets";
import BigNumber from "bignumber.js";
import ContainedButton from "components/ContainedButton";
import { DialogModal } from "components/DialogModal";
import ExchangeRate from "components/ExchangeRate";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { logger, waitForTx } from "core/utilities";
import { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { Guild, HivePoolStats } from "store/types";
import { TBMConnector } from "tbm";
import { useAsyncTask, useBlockTime, useRedux, useToaster } from "utils";
import { BIG_ZERO, BLOCKS_PER_MINUTE, Decimals } from "utils/constants";
import { bnOrZero, getExplorerLink, toHumanNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useNetwork from "utils/useNetwork";
import whoseSignature from "utils/whoseSignature";

export interface ModalProps {
  bankHive?: HivePoolStats;
  open: boolean;
  onClose: () => void;

  guild: Guild;
}

const RemoveLiquidityDialog: React.FC<ModalProps> = (props: ModalProps) => {
  const { open, onClose, bankHive, guild } = props;
  const { guildBank } = guild;
  const toaster = useToaster();
  const network = useNetwork();
  const dispatch = useDispatch();
  const [requestTx, setRequestTx] = useState<string>("");
  const [removeLiquidityCompleted, setRemoveLiquidityCompleted] = useState<boolean>(false);
  const [withdrawalAmt, setWithdrawalAmt] = useState<{
    zil: BigNumber,
    huny: BigNumber,
  }>({
    zil: BIG_ZERO,
    huny: BIG_ZERO,
  });

  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const lastSignificantDeposit = bankHive?.lastSignificantDeposit;

  const rates = tokenState.ExchangeRates;
  const [sliderAmount, setSliderAmount] = useState<number>(0);
  const [runWithdraw, loadingWithdraw] = useAsyncTask("withdraw", (error) => {
    toaster(error?.message ?? "Error Withdrawing");
  });
  const userHunyReserves = bnOrZero(bankHive?.userHunyReserves);
  const userZilReserves = bnOrZero(bankHive?.userZilReserves);
  const userBalance = bnOrZero(bankHive?.userBalance);
  const [, currentBlock] = useBlockTime();

  const { hunyAmount, zilAmount, removeContribution, removePercent, withdrawalPercentage } = useMemo(() => {
    const removePercent = bnOrZero(sliderAmount).shiftedBy(-2)
    const removeContribution = removePercent.times(userBalance);

    const hunyAmount = userHunyReserves.times(removePercent).dp(0).shiftedBy(-Decimals.HUNY);
    const zilAmount = userZilReserves.times(removePercent).dp(0).shiftedBy(-Decimals.ZIL);

    let withdrawalPercentage: number = 0.05
    if (!!lastSignificantDeposit) {
      const blockElapsed = currentBlock - lastSignificantDeposit;
      const daysElapsed = Math.floor(blockElapsed / (BLOCKS_PER_MINUTE * 60 * 24));
      withdrawalPercentage = withdrawalPercentage - daysElapsed * 0.001
    }

    return { hunyAmount, zilAmount, removeContribution, removePercent, withdrawalPercentage };
  }, [userHunyReserves, userZilReserves, userBalance, sliderAmount, currentBlock, lastSignificantDeposit]);

  const {
    newPoolShare,
    newPoolValue,
  } = useMemo(() => {
    let newPoolShare = "-";
    let newPoolValue = "-";
    const zilAmountUnitless = zilAmount.shiftedBy(Decimals.ZIL);
    const newZilReserves = bankHive?.zilReserves.minus(zilAmountUnitless);
    const newUserZilReserves = bankHive?.userZilReserves.minus(zilAmountUnitless);
    if (!newZilReserves?.gt(0)) return { newPoolShare, newPoolValue };
    newPoolShare = newUserZilReserves?.div(newZilReserves).toFormat(4) ?? "-";

    const hunyAmountUnitless = hunyAmount.shiftedBy(Decimals.HUNY);
    const newUserHunyReserves = bankHive?.userHunyReserves.minus(hunyAmountUnitless);
    const newUserZilValue = newUserZilReserves?.shiftedBy(-Decimals.ZIL).times(rates.zilPrice);
    const newUserHunyValue = newUserHunyReserves?.shiftedBy(-Decimals.HUNY).times(rates.hunyPrice);
    newPoolValue = newUserZilValue?.plus(newUserHunyValue ?? BIG_ZERO).shiftedBy(-2).toFormat(4) ?? "-";

    return { newPoolShare, newPoolValue };
  }, [bankHive, zilAmount, hunyAmount, rates]);

  const totalValue = useMemo(() => {
    const zilValue = bankHive?.userZilReserves.shiftedBy(-Decimals.ZIL).times(rates.zilPrice);
    const hunyValue = bankHive?.userHunyReserves.shiftedBy(-Decimals.HUNY).times(rates.hunyPrice);
    return hunyValue?.plus(zilValue ?? BIG_ZERO);

    // eslint-disable-next-line
  }, [rates, bankHive]);

  const removeValue = useMemo(() => {
    const hunyValue = hunyAmount.times(rates.hunyPrice);
    const zilValue = zilAmount.times(rates.zilPrice);
    return hunyValue?.plus(zilValue ?? BIG_ZERO);

    // eslint-disable-next-line
  }, [hunyAmount, zilAmount]);

  const handleChange = (_: Event, newValue: number | number[]) => {
    setSliderAmount(newValue as number);
  };

  const handleAddQty = () => {
    if (sliderAmount === 100) return;
    setSliderAmount(sliderAmount + 1);
  };

  const handleSubtractQty = () => {
    if (sliderAmount === 0) return;
    setSliderAmount(sliderAmount - 1);
  };

  const handleWithdraw = () => {
    runWithdraw(async () => {
      await withdraw();
    })
  }

  // TODO UPDATE RPC PARAMS TO USE GUILD BANK
  const withdraw = async () => {
    if (!wallet) throw new Error("Wallet not connected");
    if (!guildBank || !guildBank.address) return;

    if (!!guildBank.pendingRequest) {
      toaster(`Please approve or cancel current multisig transaction first!`);
      return;
    }

    const expectedZilAmount = userZilReserves.times(removePercent);
    const expectedTokenAmount = userHunyReserves.times(removePercent);

    // TODO: remove slippage hardcode
    const minZilAmount = expectedZilAmount.dividedToIntegerBy(1.5);
    const minTokenAmount = expectedTokenAmount.dividedToIntegerBy(1.5);

    const removeGuildBankHiveTx = await TBMConnector.removeGuildBankHive(
      toBech32Address(guildBank.address),
      removeContribution.decimalPlaces(0),
      minZilAmount.decimalPlaces(0),
      minTokenAmount.decimalPlaces(0),
    );
    toaster(`Submitted Remove Liquidity `, { hash: removeGuildBankHiveTx.id! })
    setWithdrawalAmt({
      zil: minZilAmount.decimalPlaces(0),
      huny: minTokenAmount.decimalPlaces(0),
    })
    if (removeGuildBankHiveTx.isRejected() || !removeGuildBankHiveTx.id) {
      onClose();
      throw new Error("Submitted transaction was rejected.");
    }

    const tx = await waitForTx(removeGuildBankHiveTx.id);
    const tbmConnector = TBMConnector.getSDK();
    const txn = await tbmConnector.zilliqa.blockchain.getTransaction(removeGuildBankHiveTx.id);
    const receipt = txn.getReceipt();
    if (!receipt || !receipt?.success || tx.status >= 3) throw new Error("Submitted transaction was unsuccessful");

    setRequestTx(removeGuildBankHiveTx.id!)
    setRemoveLiquidityCompleted(true);
    toaster(`Initiated Remove Liquidity `, { hash: removeGuildBankHiveTx.id!, overridePersist: true })
    dispatch(actions.Guild.reloadBank(guild));
  }

  const handleOnClose = () => {
    setRequestTx("")
    setRemoveLiquidityCompleted(false)
    onClose();
  }

  const getHeader = () => {
    return "Remove Liquidity"
  }

  const getContent = () => {
    if (!guild || !guildBank || !wallet) return;
    if (removeLiquidityCompleted) {
      logger("debug-component/Multisig", "Remove Liquidity", guildBank.controlMode, {
        sign: whoseSignature(wallet.addressInfo.byte20.toLowerCase(), guild),
        wallet: wallet.addressInfo.byte20
      })
      return (
        <Box sx={combineStyles(styles.dialogContent, styles.multisigContent)}>
          {
            (guild.guildBank?.controlMode === "CaptainOnly") && (wallet?.addressInfo.byte20.toLowerCase() === guild.leaderAddress)
              ? (<>
                <Typography variant="body1" color="primary" className="joinInfo">
                  You’ve successfully&nbsp;<span className="warningText">removed</span><br />

                  {/* //TODO: Dyanmically via withdraw input */}
                  <span style={{ marginTop: '8px' }}>
                    <span className="hunyAmount">
                      <Huny width={40} height={40} />
                      {toHumanNumber(withdrawalAmt.huny.shiftedBy(-Decimals.HUNY).toNumber().toFixed(4))}
                    </span>
                    &nbsp;<AddIcon height={12} width={12} />&nbsp;
                    <span className="hunyAmount">
                      <ZilTokenIcon width={40} height={40} />
                      {toHumanNumber(withdrawalAmt.zil.shiftedBy(-Decimals.ZIL).toNumber().toFixed(4))}
                    </span>
                    &nbsp;from Magic Hive!
                  </span>
                </Typography>

                {requestTx && (
                  <Link
                    target="_blank"
                    href={getExplorerLink("tx", requestTx, network)}
                    sx={styles.viewTx}
                  >
                    View Transaction
                    <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
                  </Link>
                )}
                <ContainedButton
                  variant="contained"
                  color="secondary"
                  onClick={handleOnClose}
                  sx={{ marginBottom: '10px', }}
                >
                  Back to Guild Bank
                </ContainedButton>
              </>)
              : (<>
                <Typography sx={styles.subText} color="primary" mt="20px">
                  <Typography sx={styles.signaturesRequiredText} component="span">{whoseSignature(wallet.addressInfo.byte20.toLowerCase(), guild)}</Typography>
                  &nbsp;required for this transaction to go through.
                </Typography>

                {requestTx && (
                  <Link
                    target="_blank"
                    href={getExplorerLink("tx", requestTx, network)}
                    sx={styles.viewTx}
                  >
                    View Transaction
                    <SvgIcon component={ExternalLink} sx={styles.linkIcon} />
                  </Link>
                )}

                <Box sx={styles.multisigButtonRow}>
                  <ContainedButton
                    sx={styles.multisigButton}
                    onClick={handleOnClose}
                  >
                    Back to guild bank
                  </ContainedButton>
                </Box>
              </>)
          }
        </Box >
      )


    }
    return (
      <Fragment>
        <Typography variant="body1" color="primary" sx={styles.subheader}>
          Welcome to the Magic Hive! You may remove your guild’s liquidity below for a 1% sticky fee.
        </Typography>
        <Box sx={styles.withdrawContainer}>
          <Box sx={styles.amountRow}>
            <Typography variant="h3" color="primary" sx={styles.amountText}>AMOUNT</Typography>
            <Box sx={styles.amountBox}>
              <OutlinedInput
                sx={styles.qtyInput}
                value={sliderAmount.toString() + "%"}
                type="text"
                inputProps={{ min: "1", style: { textAlign: "center" } }}
                disabled
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton onClick={handleSubtractQty}>
                      <RemoveIcon style={{ height: 22, width: 22 }} />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleAddQty}>
                      <AddIcon style={{ height: 22, width: 22 }} />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Typography variant="body1" color="primary">
                Balance: {" "}
                {userHunyReserves.shiftedBy(-Decimals.HUNY).toFixed(4)} HUNY
                {" + "}
                {userZilReserves.shiftedBy(-Decimals.ZIL).toFixed(4)} ZIL
                {" "}
                (≈${totalValue?.toFormat(2)})
              </Typography>
            </Box>
          </Box>
          <Slider
            aria-label="Default"
            valueLabelDisplay="auto"
            min={0}
            max={100}
            value={sliderAmount}
            onChange={handleChange}
            sx={styles.slider}
          />
          <Divider sx={styles.divider} />
          <Box sx={styles.amountRow}>
            <Typography variant="body1" color="primary">Withdraw Fee ({withdrawalPercentage * 100}%)</Typography>
            <Typography variant="h3" color="primary">≈${removeValue.times(withdrawalPercentage).toFormat(2)}</Typography>
            {/* Withdrawal percentage */}
          </Box>
          <Box sx={styles.balanceRow}>
            <Typography variant="body1" color="primary">You Will Receive</Typography>
            <Box sx={styles.amountBox}>
              <Typography variant="h3" color="primary">{zilAmount.times(1 - withdrawalPercentage).toFormat(4)} ZIL + {hunyAmount.times(withdrawalPercentage).toFormat(4)} HUNY</Typography>
              <Typography variant="h3" color="primary">(≈${removeValue.times(1 - withdrawalPercentage).toFormat(2)})</Typography>
            </Box>
          </Box>
        </Box>
        <ExchangeRate my={2} />
        <Box sx={styles.withdrawRow}>
          <Typography variant="body1" color="primary">New Pool Share</Typography>
          <Typography variant="h3" color="primary" component="span">${newPoolValue} ({newPoolShare}%)</Typography>
        </Box>
        <Box sx={styles.buttonWrapper}>
          <ContainedButton sx={styles.button} disabled={removeValue.eq(0) || removePercent.isEqualTo(0)} onClick={handleWithdraw}>
            {loadingWithdraw
              ? <CircularProgress size={18} />
              : "initiate removal"
            }
          </ContainedButton>
        </Box>
      </Fragment>
    );
  }
  return (
    <DialogModal header={getHeader()} open={open && !!guildBank} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true} dialogOverwrites={styles.dialogOverwrite}>
      <Box>
        {getContent()}
      </Box>
    </DialogModal>

  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "center",
    padding: "0 40px",
    gap: '32px',

    "> *": {
      width: '100%',
    },
  },
  subText: {
    textAlign: 'center',
  },
  signaturesRequiredText: {
    color: "#ff8952",
  },

  multisigContent: {
    "> *": {
      width: 'auto',
    },

    ".joinInfo": {
      textAlign: 'center',
      fontSize: '24px',
      lineHeight: '150%',

      ".hunyAmount": {
        display: 'inline-flex',
        placeContent: 'center',
        placeItems: 'center',
        verticalAlign: 'middle',
        gap: '8px',
        flexWrap: 'nowrap',

        fontWeight: 700,
        ...GUILD_LIGHTGRAY_GRADIENT,
      },

      ".warningText": {
        color: "#ff8952",
      },

      "button": {
        padding: '0 4px',
      }
    },
  },
  viewTx: {
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: 600,
  },
  linkIcon: {
    marginLeft: "8px",
    verticalAlign: "sub",
    fontSize: "20px",
    marginBottom: "1px",
    "@media (max-width:600px)": {
      fontSize: "18px",
      verticalAlign: "text-top",
      marginBottom: 0,
    },
  },
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  dialogOverwrite: {
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  subheader: {
    fontSize: '1.5rem',
    marginBottom: '20px',
    lineHeight: '2.25rem',
    textAlign: 'center',
  },
  withdrawContainer: {
    minHeight: '200px',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    border: '1px solid #AEF1EE33',
    borderRadius: '16px',
    padding: '40px',
    marginBottom: '20px',
  },
  amountRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  amountText: {
    marginBottom: '40px',
  },
  qtyInput: {
    marginBottom: '20px',
    width: '400px',
    height: 60,
    border: '1px solid rgba(174, 241, 238, 0.1)',
    borderRadius: '16px',
    color: '#FEFEFE',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    "& input": {
      height: '30px',
      fontSize: '24px',
      fontWeight: 600,
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "WebkitAppearance": 'none',
        margin: 0,
      },
      "&:[type=number]": {
        "MozAppearance": 'textfield',
      },
    },
    "&.Mui-focused": {
      caretColor: '#FEFEFE',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 'none',
    },
    "@media (max-width:900px)": {
      width: '320px',
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: '#FEFEFE',
      "-webkit-text-fill-color": '#FEFEFE !important',
    },
  },
  amountBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  slider: {
    color: '#52af77',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
      background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      background: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
  },
  divider: {
    background: 'rgba(174, 241, 238, 0.1)',
    height: '2px',
    marginY: '10px',
  },
  balanceRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '20px',
  },
  withdrawRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    minWidth: 250,
    height: 60,
  }
}

export default RemoveLiquidityDialog;