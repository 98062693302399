import { Box, Divider, styled, SxProps, Typography } from "@mui/material";
import HunyFrame from "assets/HunyFrame.png";
import BigNumber from "bignumber.js";
import BoxWithHeader from "components/BoxWithHeader";
import { MetazoaClient } from "core/utilities/metazoa";
import { useEffect, useMemo, useState } from "react";
import { useAsyncTask } from "utils";
import { BIG_ZERO, Decimals } from "utils/constants";
import { bnOrZero, formatIntegerNumber } from "utils/strings/strings";
import { AppTheme, SimpleMap } from "utils/types";
import useRedux from "utils/useRedux";

export interface KickbackData {
  epoch: string;
  avgKickback: string;
}

const HiveTVL: React.FC = () => {
  const hiveInfo = useRedux(state => state.token.hiveInfo);
  const hivePool = useRedux((state) => state.token.hivePool);
  const rates = useRedux((state) => state.token.ExchangeRates);
  const network = useRedux(state => state.blockchain.network);
  const [kickback, setKickback] = useState<BigNumber>(new BigNumber(0));
  const [runGetHiveKickback, loadingGetHiveKickback] = useAsyncTask("getHiveKickback");

  const totalValue = useMemo(() => {
    const zilValue = hivePool?.zilReserves.shiftedBy(-Decimals.ZIL).times(rates.zilPrice);
    const hunyValue = hivePool?.hunyReserves.shiftedBy(-Decimals.HUNY).times(rates.hunyPrice);
    return hunyValue?.plus(zilValue ?? BIG_ZERO);
  }, [rates, hivePool]);

  useEffect(() => {
    getHiveKickback();
    // eslint-disable-next-line
  }, [network])

  const getHiveKickback = () => {
    runGetHiveKickback(async () => {
      const metazoaClient = new MetazoaClient(network);
      const result = await metazoaClient.getHunyKickback({ epoch: "day" });
      const kickbackData: KickbackData = result.result.kickbackApr;
      if (!kickbackData) return;
      setKickback(bnOrZero(kickbackData.avgKickback));
    })
  }
  // 39353373015872360000 = 24 hr/day * 95 blk/hr * 365 day/year * 47288359788359 huny/blk
  const hunyInflation = new BigNumber(39353373015872360000);
  const hunyKickback = kickback.times(365);
  const totalHunyPerYear = (hunyInflation.plus(hunyKickback)).times(bnOrZero(hiveInfo?.hunyPerBlock));
  const hiveApr = totalHunyPerYear.dividedBy(bnOrZero(hivePool?.hunyReserves).times(2)).times(100);

  return (
    <BoxWithHeader
      headerText={<Typography variant="h3" color="primary" sx={styles.headerText}>Hive TVL</Typography>}
      componentsRight={<Typography variant="h3" color="secondary">100%</Typography>}
      sx={styles.container}
    >
      <Box sx={styles.hunyWrapper}>
        <Box sx={styles.magicHiveIcon} />
        <HiveTVLDivider textAlign="left" sx={styles.hiveTVLDivider}>
          <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>Total Liquidity</Typography>
        </HiveTVLDivider>
        <Typography variant="h2" color="primary" sx={styles.bodyHeader}>${totalValue?.toFormat(0)}</Typography>
        <Typography variant="body1" color="secondary" sx={styles.bodyText}>
          {formatIntegerNumber(hivePool?.hunyReserves.shiftedBy(-Decimals.HUNY))} HUNY
          {" + "}
          {formatIntegerNumber(hivePool?.zilReserves.shiftedBy(-Decimals.ZIL))} ZIL
        </Typography>
        <HiveTVLDivider textAlign="left" sx={styles.hiveTVLDivider}>
          <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>Rewards (APR)</Typography>
        </HiveTVLDivider>
        <Typography variant="h2" color="primary" sx={styles.bodyHeader}>{!isFinite(hiveApr.toNumber()) || loadingGetHiveKickback ? "- " : hiveApr.toFormat(1)}%</Typography>
      </Box>
    </BoxWithHeader>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    marginBottom: '10px',
    "@media (max-width:1200px)": {
      width: '49%',
    },
  },
  headerText: {
    "@media (max-width:1000px)": {
      fontSize: '1rem',
    },
  },
  hunyWrapper: {
    padding: '20px',
    "@media (max-width:1000px)": {
      padding: '0px',
    },
  },
  hiveTVLDivider: {
    paddingBottom: '10px',
    "@media (max-width:1000px)": {
      paddingBottom: '5px',
    },
  },
  overlineSmall: {
    fontSize: '0.75rem',
  },
  bodyHeader: {
    marginBottom: '16px',
    paddingLeft: '10px',
    "@media (max-width:1000px)": {
      marginBottom: '10px',
    },
  },
  bodyText: {
    fontSize: '1.5rem',
    marginBottom: '30px',
    paddingLeft: '10px',
    "@media (max-width:1000px)": {
      marginBottom: '10px',
    },
  },
  magicHiveIcon: {
    width: '100%',
    maxWidth: '180px',
    minWidth: '180px',
    minHeight: '160px',
    backgroundImage: `url(${HunyFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "243%",
    backgroundPosition: "center",
    margin: '8px auto 56px auto',
    "@media (max-width:1400px)": {
      maxWidth: 'unset',
      width: 'unset',
      minWidth: '200px',
      minHeight: '260px',
      backgroundSize: "170%",
      margin: '8px auto 25px auto',
    },
  },
}

const HiveTVLDivider = styled(Divider)({
  '&.MuiDivider-root::after': {
    borderTopColor: '#aef1ee',
  },
  '&.MuiDivider-root::before': {
    borderTopColor: '#aef1ee',
    width: '0%',
  },
});

export default HiveTVL;
