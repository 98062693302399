import { Box, SxProps } from '@mui/material';
import { Gem, GEMS, GemTier, GemTierType } from 'components/Metazoa/ResourceConstants';
import { logger } from 'core/utilities';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { AppTheme, SimpleMap } from 'utils/types';
import GemDroppable from './Droppable';

type GemsDropzoneProps = {
  tier: GemTierType;
  gems: SimpleMap<number[]>;

  targetGemId: number;
  limit: number;

  setSelection: React.Dispatch<React.SetStateAction<number[]>>;
}

export interface IDropItem {
  ids: number[];
  info: SimpleMap;
  item: Gem;
  type: string;
}

const GemsDropzone: FC<GemsDropzoneProps> = ({
  tier,
  gems,
  targetGemId,
  limit,
  setSelection
}: GemsDropzoneProps) => {


  logger("debug-component", "RefineGemsDialog/Zone", {
    tier,
    gems,
    targetGemId,
    limit,
  })

  // STATES / HOOKS -----------------

  const [selectableItems, setSelectableItems] = useState<IDropItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<IDropItem[]>([]);

  const dropItems: IDropItem[] = useMemo(() => {
    let dropItemList: IDropItem[] = [];

    if (!Object.keys(gems).length) {
      // Reset memomized item states
      setSelectableItems(dropItemList);
      setSelectedItems([]);
      return dropItemList;
    }

    // Map gems as items
    const mapGemsToDropItems = (tier: GemTierType, gems: SimpleMap<number[]>, excludeIds: number[]): IDropItem[] =>
      Object.entries(gems).reduce((gemList: IDropItem[], [gem, gemIdArray]) => {
        const currentGem: Gem = GEMS[tier][gem];
        const keyName: string = tier + " " + currentGem.name;
        const tierName: string = GemTier[tier];

        logger("debug-component", "RefineGemsDialog/Zone/mapGemsToDropItems", {
          tier,
          gem,
        }, {
          currentGem,
          keyName,
          tierName,
        })

        const ids: number[] = [...gemIdArray].filter(id => !excludeIds.includes(id));
        if (!!ids.length) {
          gemList.push({
            ids,
            type: "Gem",
            item: currentGem,
            info: {
              keyName,
              tierName,
            },
          });
        }
        return gemList;
      }, []);

    // Store in memomized item states
    const excludedIds: number[] = [targetGemId];
    dropItemList = mapGemsToDropItems(tier, gems, [targetGemId]);
    logger("debug-component", "RefineGemsDialog/Zone/mapGemsToDropItems", {
      excludedIds,
      dropItemList,
    })
    setSelectableItems(dropItemList);
    setSelectedItems([]);

    return dropItemList;
  }, [gems, targetGemId, tier]);

  // FUNCTIONS ----------------------

  const transferDropItem = (
    dropItem: IDropItem,
    sourceList: IDropItem[],
    destinationList: IDropItem[],
    options: SimpleMap<boolean> = {
      stacking: false,
      inversed: false,
      keepEmpty: true,
    },
  ) => {
    options = {
      stacking: false,
      inversed: false,
      keepEmpty: true,
      ...options,
    }
    const { stacking, inversed, keepEmpty } = options;

    let srcList = inversed ? destinationList : sourceList;
    let dstList = inversed ? sourceList : destinationList;

    let transferResult = {
      srcList,
      dstList,
    };

    logger("debug-component", "RefineGemsDialog/Zone/transferDropItem", "CHECK 1", {
      dropItemIds: dropItem.ids,
      transferResult,
    })
    // Check if srcList and dropItem is valid
    if (!srcList.length) return transferResult;
    // alert(`dItem: ${dropItem}`)

    // Retrieve idx of dropItem from srcList
    const srcItemIdx: number = srcList.findIndex(({ info }) => info.keyName === dropItem.info.keyName);
    const dstItemIdx: number = dstList.findIndex(({ info }) => info.keyName === dropItem.info.keyName);

    logger("debug-component", "Zone/transferDropItem", "CHECK 1.1", {
      keyName: dropItem.info.keyName,
      srcList,
      dstList,
      srcItemIdx,
      dstItemIdx,
    })
    if (srcItemIdx < 0) return transferResult;
    const srcItem: IDropItem = srcList[srcItemIdx];
    logger("debug-component", "Zone/transferDropItem", "CHECK 1.5", {
      srcItem,
      srcList,
    })
    // Use the first ID of dropItem
    const srcGemId: number = srcItem.ids.shift() ?? -1;
    if (srcItemIdx < 0 || srcGemId < 0) return transferResult;


    logger("debug-component", "Zone/transferDropItem", "CHECK 2", {
      srcItem,
      transferResult,
      srcIds: srcList[srcItemIdx].ids,
    }, {
      dstItemIdx,
      srcGemId,
      ids: srcItem.ids,
    })
    if (dstItemIdx < 0 || !stacking) {
      dstList.push({
        ...srcItem,
        ids: [srcGemId],
      });
    }
    else {
      // Update dstList with added id
      dstList[dstItemIdx].ids.push(srcGemId);
    }

    // Update srcList with depleted ids
    srcList[srcItemIdx].ids = srcItem.ids;

    // Cleanup
    if (!keepEmpty) {
      if (!inversed) dstList = dstList.filter(({ ids }) => ids.length > 0);
      else srcList = srcList.filter(({ ids }) => ids.length > 0);
    }
    transferResult = {
      srcList,
      dstList,
    }

    logger("debug-component/Zone", "RefineGemsDialog/Zone/transferDropItem", {
      transferResult,
      options,
      dropItem,
      srcItemIdx,
      dstItemIdx,
    })

    return transferResult;
  }

  // EVENT HANDLERS -----------------

  const handleSelectionDrop = useCallback(
    (droppedItem: IDropItem) => {
      const srcClone: IDropItem[] = [...selectableItems];
      const dstClone: IDropItem[] = [...selectedItems];

      // alert(`DROPPED IDS: ${JSON.stringify(droppedItem.ids)}`);


      // logger("debug-component", "RefineGemsDialog/Zone/handleSelectionDrop", "pre-transfer", {
      //   droppedItem,
      //   selectedItems,
      //   selectableItems,
      // })

      const transferResult = transferDropItem(droppedItem, srcClone, dstClone);

      // alert(JSON.stringify(transferResult))


      // logger("debug-component", "RefineGemsDialog/Zone/handleSelectionDrop", "pre-selection", {
      //   transferResult,
      //   selectedItems,
      //   selectableItems,
      // })

      // Set selection
      const selectedGemIds = transferResult.dstList
        .filter(i => i.ids.length > 0)
        .reduce((accum: number[], next: IDropItem) => accum.concat([...next.ids]), []);
      setSelection(() => selectedGemIds);

      // Memomize state
      setSelectedItems(transferResult.dstList.filter(i => i.ids.length > 0));
      setSelectableItems(transferResult.srcList);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectableItems, selectedItems],
  )
  const handleSelectableDrop = useCallback(
    (droppedItem: IDropItem) => {
      const srcClone: IDropItem[] = [...selectableItems];
      const dstClone: IDropItem[] = [...selectedItems];

      // alert(`DRAGGED IDS: ${JSON.stringify(droppedItem.ids)}`)

      const transferResult = transferDropItem(droppedItem, srcClone, dstClone, {
        stacking: true,
        inversed: true,
        keepEmpty: false,
      });

      // Set selection
      const selectedGemIds = transferResult.srcList
        .filter(i => i.ids.length > 0)
        .reduce((accum: number[], next: IDropItem) => accum.concat([...next.ids]), []);
      setSelection(() => selectedGemIds);

      // Memomize state
      setSelectedItems(transferResult.srcList.filter(i => i.ids.length > 0));
      setSelectableItems(transferResult.dstList);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectableItems, selectedItems],
  )

  // --------------------------------

  return (
    <Box
      component="main"
      sx={styles.container}
    >

      <GemDroppable
        id="area-selectable"
        selectionItems={selectableItems}
        onDrop={handleSelectableDrop}
        limit={dropItems.length + 10}
        acceptableTypes={["Gem"]}
      />

      <GemDroppable
        id="area-selected"
        selectionItems={selectedItems}
        onDrop={handleSelectionDrop}
        limit={limit}
        acceptableTypes={["Gem"]}
        options={{
          showPlaceholder: true,
        }}
      />
    </Box>
  )
}


const styles: SimpleMap<SxProps<AppTheme>> = {
  container: {
    display: 'flex',
    placeItems: 'center',
    placeContent: 'space-around',
    paddingY: 2,
  },
}

export default GemsDropzone;