import ElderWoodlands from "assets/quests/Locations/ElderWoodlands.svg";
import ElderWoodlandsBG from "assets/quests/Locations/ElderWoodlandsBG.png";
import ElderWoodlandsFrame from "assets/quests/Locations/ElderWoodlandsFrame.svg";
import ElderWoodlandsGlow from "assets/quests/Locations/ElderWoodlandsGlow.svg";
import MoonBattleground from "assets/quests/Locations/MoonBattleground.svg";
import MoonBattlegroundBG from "assets/quests/Locations/MoonBattlegroundBG.png";
import MoonBattlegroundFrame from "assets/quests/Locations/MoonBattlegroundFrame.svg";
import MoonBattlegroundGlow from "assets/quests/Locations/MoonBattlegroundGlow.svg";
import ZolarAsteroidBelt from "assets/quests/Locations/ZolarAsteroidBelt.svg";
import ZolarAsteroidBeltBG from "assets/quests/Locations/ZolarAsteroidBeltBG.png";
import ZolarAsteroidBeltFrame from "assets/quests/Locations/ZolarAsteroidBeltFrame.svg";
import ZolarAsteroidBeltGlow from "assets/quests/Locations/ZolarAsteroidBeltGlow.svg";
import ZOMGStore from "assets/quests/Locations/ZOMGStore.svg";
import ZOMGStoreFrame from "assets/quests/Locations/ZOMGStoreFrame.svg";
import ZOMGStoreGlow from "assets/quests/Locations/ZOMGStoreGlow.svg";

import ElderberryResource from "assets/quests/Locations/ElderberryResource.svg";
import ElderberryResourceFrame from "assets/quests/Locations/ElderberryResourceFrame.svg";
import GeodeResource from "assets/quests/Locations/GeodeResource.svg";
import GeodeResourceFrame from "assets/quests/Locations/GeodeResourceFrame.svg";
import ZolraniumScrapResource from "assets/quests/Locations/ZolraniumScrapResource.svg";
import ZolraniumScrapResourceFrame from "assets/quests/Locations/ZolraniumScrapResourceFrame.svg";

import { SxProps } from "@mui/material";
import ItemCube from "assets//icons/PlaceholderItemCube.svg";
import { BankItem } from "components/Guild/components/GuildBank/components/GuildBankMain/components/GuildBankTab/components/DonateBank/DonateBank";
import { Stat, STATS } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { ReactNode } from "react";
import { NftMetadata } from "store/types";
import { MissionGroundLocation, MissionGroundResource } from "utils/constants";
import { AppTheme } from "utils/types";
import { CraftType } from "components/Metazoa/ResourceConstants";

export enum StoreItemType {
  'Buy',
  'Sell',
  'Craft',
}

export type LocationAsset = {
  color: string;
  gradient: string;
  frame: string | null;
  background: string;
  vector: {
    imageSrc: string,
    glowImageSrc: string,
    jsx: SxProps<AppTheme>,
  };
  ctaText: string;
};

export type ResourceAsset = {
  key: MissionGroundResource | CraftType;
  name: string;
  alias: string;
  iconSrc: string;
  description: string;
  resourceFrameSrc: string;
};

export type StoreItem = {
  type: StoreItemType;
  addedCostInfo?: ReactNode;
} & Omit<BankItem, "amount" | "name" | "icon"> & ResourceAsset;

export const MAP_LOCATIONS: (string | MissionGroundLocation)[] = Object.values(MissionGroundLocation).filter(value => typeof value === 'string');

export const RESOURCES: {
  [key in MissionGroundResource]: ResourceAsset
} = {
  [MissionGroundResource.ZolraniumScraps]: {
    key: MissionGroundResource.ZolraniumScraps,
    name: "Zolranium Scraps",
    alias: "Scrap",
    iconSrc: ZolraniumScrapResource,
    description: "Noted for its extraordinary strength and malleability,  they are required to recreate Z-Ordnances - powerful artefacts for your army that were found in the abyss.",
    resourceFrameSrc: ZolraniumScrapResourceFrame,
  },
  [MissionGroundResource.Geodes]: {
    key: MissionGroundResource.Geodes,
    name: "Geodes",
    alias: "Geode",
    iconSrc: GeodeResource,
    description: "Within some Galaxy Geodes lie the powerful Galaxy Gem, each with their own special powers known to be connected with the Affinities",
    resourceFrameSrc: GeodeResourceFrame,
  },
  [MissionGroundResource.Elderberries]: {
    key: MissionGroundResource.Elderberries,
    name: "Elderberries",
    alias: "Berry",
    iconSrc: ElderberryResource,
    description: "Where the Elderberry Juice within is known to increase one's overall performance and efficiency",
    resourceFrameSrc: ElderberryResourceFrame,
  },
}

export type LocationMetadata = {
  key: MissionGroundLocation;
  resource: ResourceAsset | null,
  refinementTagline: string | null;
  assets: LocationAsset;
  name: string;
  keyStat: Stat | null;
  tokens?: LocationTokens;
}

export interface LocationTokens {
  staked: NftMetadata[];
  unstaked: NftMetadata[];
}

export const LOCATIONS: {
  [key in MissionGroundLocation]: LocationMetadata
} = {
  [MissionGroundLocation.MoonBattleground]: {
    key: MissionGroundLocation.MoonBattleground,
    resource: RESOURCES[MissionGroundResource.ZolraniumScraps],
    refinementTagline: 'You may trade in your Zolranium Scraps for weapons at ZOMG Store.',
    assets: {
      color: '#BE6F4E',
      gradient: 'linear-gradient(194deg, #DACCC6 -92.97%, #BE6F4E 41.43%, #4A2A1F 100%)',
      frame: MoonBattlegroundFrame,
      background: MoonBattlegroundBG,
      vector: {
        imageSrc: MoonBattleground,
        glowImageSrc: MoonBattlegroundGlow,
        jsx: {
          top: 'unset',
          bottom: '10%',
          left: '30%',
          // zIndex: 10,
        }
      },
      ctaText: 'Send your Metazoa into the battleground to plunder for Zolranium Scraps!',
    },
    name: "Moon Battlegrounds",
    keyStat: STATS.STR,
  },
  [MissionGroundLocation.ZolarAsteroidBelt]: {
    key: MissionGroundLocation.ZolarAsteroidBelt,
    resource: RESOURCES[MissionGroundResource.Geodes],
    refinementTagline: 'You may crack open your Geodes for Galaxy Gems in Base.',
    assets: {
      color: '#0044D7',
      gradient: 'linear-gradient(180deg, #00A3FF 0%, #0051FF 100%)',
      frame: ZolarAsteroidBeltFrame,
      background: ZolarAsteroidBeltBG,
      vector: {
        imageSrc: ZolarAsteroidBelt,
        glowImageSrc: ZolarAsteroidBeltGlow,
        jsx: {
          left: '-5%',
        }
      },
      ctaText: 'Send your Metazoa into the vast galaxy to gather for Geodes!',
    },
    name: "Zolar Asteroid Belt",
    keyStat: STATS.DEX,
  },
  [MissionGroundLocation.ElderWoodlands]: {
    key: MissionGroundLocation.ElderWoodlands,
    resource: RESOURCES[MissionGroundResource.Elderberries],
    refinementTagline: 'You may refine your Elderberries into Elderberry Juice in Base.',
    assets: {
      color: '#7105C6',
      gradient: 'linear-gradient(194deg, #FFC7FC -92.97%, #F471E8 41.43%, #7105C6 100%)',
      frame: ElderWoodlandsFrame,
      background: ElderWoodlandsBG,
      vector: {
        imageSrc: ElderWoodlands,
        glowImageSrc: ElderWoodlandsGlow,
        jsx: {
          top: '0%',
          right: '-5%',
        }
      },
      ctaText: `Send your Metazoa into the forest depths to search for Elderberries!`,
    },
    name: "Elder Woodlands",
    keyStat: STATS.INT,
  },
  [MissionGroundLocation.ZOMGStore]: {
    key: MissionGroundLocation.ZOMGStore,
    resource: null,
    refinementTagline: null,
    assets: {
      color: '#2FF583',
      gradient: 'linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)',
      frame: ZOMGStoreFrame,
      background: '',
      vector: {
        imageSrc: ZOMGStore,
        glowImageSrc: ZOMGStoreGlow,
        jsx: {
          top: 'unset',
          bottom: '10%',
          right: '-4%',
          zIndex: '10000!important',

          minHeight: 'unset',

          ".vectorImg": {
            top: 'unset',
            // minWidth: '500px',
          },

          // ">main": {
          //   width: '350px',
          // }
          // display: 'none',
        }
      },
      ctaText: 'Visit the store for ordanances and craftables!',
    },
    name: "ZOMG Store",
    keyStat: null,
  },
};

export const DEFAULT_SRC = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  e.currentTarget.onerror = null;
  e.currentTarget.src = ItemCube;
};
