import React from "react";
import { Breadcrumbs, BoxProps, Typography, SxProps } from "@mui/material";
import { ArrowRightRounded } from "@mui/icons-material";
import { AppTheme, SimpleMap } from "utils/types";
import { Link } from "react-router-dom";

export type typeCrumbData = {
  value: string;
  path: string;
};

interface Props extends BoxProps {
  linkPath: typeCrumbData[];
}

const GuildBreadcrumb: React.FC<Props> = (props: Props) => {
  const { linkPath } = props;

  return (
    <Breadcrumbs
      sx={styles.root}
      separator={<ArrowRightRounded sx={styles.separator} />}
      aria-label="breadcrumb"
    >
      {linkPath.map((linkData, index) => (
        <Typography key={index} variant="body2" color="primary" sx={index === linkPath.length - 1 ? styles.currentBreadcrumb : styles.breadcrumb}>
          <Link to={linkData.path}>
            {linkData.value}
          </Link>
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  root: {
    zIndex: 1,
  },
  separator: {
    color: "#2FF583",
    fontSize: "2em",
    marginX: "-8px",
  },
  currentBreadcrumb: {
    fontSize: "16px",
    "& a": {
      textDecoration: "none",
      color: "#2FF583",
      "&:hover": {
        textDecoration: "underline",
      }
    },
  },
  breadcrumb: {
    fontSize: "16px",
    "& a": {
      textDecoration: "none",
      color: "#FFFFFF",
      "&:hover": {
        textDecoration: "underline",
      }
    },
  },
}

export default GuildBreadcrumb;
