import { Box, CircularProgress, Divider, FormControl, Grid, SxProps, Typography } from "@mui/material";
import { CarouselViewMode, GridViewMode, Rocket } from "assets";
import GuildItemFrame from 'assets/GuildItemFrame.svg';
import SlideRightIcon from 'assets/icons/SlideRightIcon.svg';
import ContainedButton from "components/ContainedButton";
import Dropdown, { DropdownOptions } from "components/Guild/components/GuildBank/components/GuildBankMain/components/TaxCollectorTab/components/Dropdown";
import { GUILD_GRAY_GRADIENT, GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { logger } from "core/utilities";
import { RefObject, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { NftMetadata, SortMode, SortModeType, ViewMode, ViewModeType } from "store/types";
import { strings } from "utils";
import { ContractsBech32, LoadingKeys } from "utils/constants";
import { bnOrZero } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import useIsOverflow from 'utils/useIsOverflow';
import useNetwork from "utils/useNetwork";
import useRedux from "utils/useRedux";
import useTaskSubscriber from "utils/useTaskSubscriber";
import { traitRaritySorter } from "utils/zoaUtils";
import MetazoaCard from './MetazoaCards';
import MetazoaView from "./MetazoaView";

const viewModeIcons = {
  [ViewMode.Carousel]: <CarouselViewMode />,
  [ViewMode.Grid]: <GridViewMode />,
}

export const viewModes: DropdownOptions[] = Object.entries(ViewMode).reduce((list: DropdownOptions[], [k, v]) => {
  list.push({
    value: k,
    label: v,
    ...(!!viewModeIcons[k]) && {
      icon: viewModeIcons[k],
    }
  });
  return list ?? [];
}, []);

export const sortModes: DropdownOptions[] = Object.entries(SortMode).reduce((list: DropdownOptions[], [k, v]) => {
  list.push({
    value: k,
    label: v,
  });
  return list ?? [];
}, []);

const MetazoaCollection: React.FC = () => {

  const network = useNetwork();
  const dispatch = useDispatch();
  const wallet = useRedux((state) => state.wallet.wallet);
  const profile = useRedux((state) => state.profile.profile)
  const collectionMode = useRedux((state) => state.layout.collectionMode);

  const allGuilds = useRedux((state) => state.guild.allGuilds);
  const tokenState = useRedux((state) => state.token);
  const myHunyTokens = useRedux((state) => state.token.HunyTokens);
  const myHunyInHive = useRedux((state) => state.token.hivePool?.userHunyReserves);

  // STATES / HOOKS -----------------

  const [isLoadingWallet] = useTaskSubscriber(...LoadingKeys.connectWallet);
  const [loadingTokens] = useTaskSubscriber("updateTokens");
  const [zoaCollection, setZoaCollection] = useState<{
    staked: SimpleMap<NftMetadata>;
    unstaked: SimpleMap<NftMetadata>;
    combined: [string, NftMetadata][];
    totalMetazoas: number;
  }>({
    staked: {},
    unstaked: {},
    combined: [],
    totalMetazoas: 0,
  });

  const [viewMode, setViewMode] = useState<ViewModeType>(collectionMode.view);
  const [sortMode, setSortMode] = useState<SortModeType>(collectionMode.sort);
  const [showStats, setShowStats] = useState<NftMetadata>();
  const [lateralScrollClick, setScrollClickCount] = useState<number>(1)

  const ref = useRef() as RefObject<HTMLDivElement>;
  const isOverflow = useIsOverflow(ref);

  const isLoading: boolean = isLoadingWallet || loadingTokens;

  // Get current wallet holder's details
  const walletHolder = useMemo(() => {
    if (!wallet || !profile || !allGuilds) return;

    // Clear previously displayed metazoa
    setShowStats(undefined);

    const addr = wallet.addressInfo.bech32!.toLocaleLowerCase()!
    const guild = allGuilds.find(g => g.id === profile.guildId);

    return {
      addr,
      profile,
      guild,
      wallet,
    }
  }, [wallet, profile, allGuilds]);

  // Beholder of all metazoas meta
  const allMetazoas = useMemo(() => {
    logger("debug-component", "MetazoaCollection/allMetazoas", {
      loading: isLoading,
      zoaCollection,
    })

    let zoas: typeof zoaCollection = zoaCollection;
    if (isLoading || !tokenState || !walletHolder) return zoaCollection;

    const {
      metazoaTokens,
      stakedMetazoa,
      stakedMetazoaBerry,
      stakedMetazoaGeode,
      stakedMetazoaScrap,
    } = tokenState;

    const metazoas = {
      ...metazoaTokens,
      ...stakedMetazoa,
      ...stakedMetazoaBerry,
      ...stakedMetazoaGeode,
      ...stakedMetazoaScrap,
    } ?? {};

    const unsortedMetazoas = Object.entries(metazoas);
    const totalMetazoas = unsortedMetazoas.length;
    if (totalMetazoas < 1) {
      zoas = {
        staked: stakedMetazoa,
        unstaked: metazoaTokens,
        combined: unsortedMetazoas,
        totalMetazoas
      };
      setZoaCollection(zoas);
      return zoas;
    }

    let sortedMetazoas = unsortedMetazoas;
    switch (sortMode) {
      case "Base Rarity": {
        sortedMetazoas = traitRaritySorter('Class', unsortedMetazoas);
        break;
      }
      case "Gen": case "Faction": case "Affinity": {
        sortedMetazoas = traitRaritySorter(sortMode, unsortedMetazoas);
        break;
      }
      case "Profession": {
        sortedMetazoas = unsortedMetazoas
          .sort(([, a], [, b]) => {
            if (!!a.profession && !b.profession) return -1;
            if (!a.profession && !!b.profession) return 1;
            return 0;
          })
          .sort(([, a], [, b]) => (!!a.profession && !!b.profession) ? (a.profession.toLowerCase().localeCompare(b.profession.toLowerCase())) : 0);
        break;
      }
      default: {
        if (!showStats && !!unsortedMetazoas[0][1]?.name) {
          if (!showStats) setShowStats(unsortedMetazoas[0][1]);
        }
        break;
      }
    }

    logger("debug-sort", "post-sort", {
      staked: stakedMetazoa,
      unstaked: metazoaTokens,
      combined: sortedMetazoas,
      totalMetazoas,
    })

    // Update currently displayed metazoa as needed
    if (showStats) setShowStats(metazoas[showStats.id]);

    zoas = {
      staked: stakedMetazoa,
      unstaked: metazoaTokens,
      combined: unsortedMetazoas,
      totalMetazoas
    };
    setZoaCollection(zoas);
    return zoas;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, tokenState, sortMode, showStats, walletHolder]);

  // FUNCTIONS ----------------------

  // EVENT HANDLERS -----------------

  const handleViewChange = (value: string) => {
    setViewMode(value as ViewModeType);
    dispatch(actions.Layout.updateCollectionMode({
      ...collectionMode,
      view: value as ViewModeType,
    }));
    logger("debug-component/MetazoaCollection", "handleViewChange", {
      value,
      vm: viewMode,
      collectionMode,
    })
  };

  const handleSortChange = (value: string) => {
    setSortMode(value as SortModeType);
    dispatch(actions.Layout.updateCollectionMode({
      ...collectionMode,
      sort: value as SortModeType,
    }));
    logger("debug-component/MetazoaCollection", "handleSortChange", {
      value,
      sm: sortMode,
      collectionMode,
    })
  };

  const handleLateralScroll = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, direction: string, ref: RefObject<any>) => {
    if (!ref) return;
    const { current } = ref;

    if (direction.toUpperCase() === "LEFT") {
      setScrollClickCount(lateralScrollClick - 1)
      current.scrollLeft -= 300;
    }
    else if (direction.toUpperCase() === "RIGHT") {
      setScrollClickCount(lateralScrollClick + 1)
      current.scrollLeft += 300;
    }
  }

  // --------------------------------

  if (!walletHolder) return null;
  return (
    <Box>
      {/* //INFO: Header */}
      <Box sx={styles.header}>
        <Typography variant="overline" color="secondary">Your Metazoa ({zoaCollection.totalMetazoas})</Typography>
        <Divider sx={styles.divider} />

        <Box sx={styles.sortGroup}>
          <FormControl sx={styles.filterFormGroup}>
            <Box sx={styles.filterGroup}>
              <Typography sx={styles.filterLabel}>
                View:
              </Typography>
              <Dropdown options={viewModes}
                defaultValueSelected={viewMode}
                onSelected={handleViewChange}
                sx={styles.dropdownFilter}
                paperStyles={styles.dropdownFilterPaper}
              />
            </Box>
          </FormControl>

          <FormControl sx={styles.filterFormGroup}>
            <Box sx={styles.filterGroup}>
              <Typography sx={styles.filterLabel}>
                Sort By:
              </Typography>
              <Dropdown options={sortModes}
                defaultValueSelected={sortMode}
                onSelected={handleSortChange}
                sx={styles.dropdownFilter}
                paperStyles={styles.dropdownFilterPaper}
              />
            </Box>
          </FormControl>
        </Box>

      </Box>
      <Box sx={combineStyles(styles.statsViewRow, { display: (showStats) ? 'flex' : 'none' })}>
        {!!showStats && <MetazoaView token={showStats} guild={walletHolder.guild} hunyInHive={bnOrZero(myHunyInHive)} hunyInWallet={bnOrZero(myHunyTokens)} />}
      </Box>
      {isLoading && (allMetazoas.totalMetazoas < 1)
        ? (
          <Box display="flex" alignItems="center" justifyContent="center" mt="20px">
            <CircularProgress size={36} />
          </Box>
        )
        : zoaCollection.totalMetazoas <= 0
          ? (
            <Box sx={styles.noMetazoaContainer}>
              <Box sx={styles.rocketContainer}>
                <Typography color="primary">You don't own any Metazoa.</Typography>
                <ContainedButton href={strings.getArkyLink(ContractsBech32[network].NftV2)} isExternal>
                  Buy on Arky
                </ContainedButton>
              </Box>
              <Rocket />
            </Box>
          )
          : (<>
            <Box position={'relative'}>
              {viewMode === "Carousel" && lateralScrollClick > 1 && (
                <Box sx={styles.scrollerBtn}
                  left={'3%'}
                  flexDirection={'row'}
                  onClick={(e) => { handleLateralScroll(e, 'LEFT', ref) }}
                >
                  <Box component="img"
                    src={SlideRightIcon}
                    alt="stat-details-frame"
                    draggable="false"
                    sx={combineStyles(styles.slideIcon, { transform: 'scale(-1, 1)' })}
                  />
                </Box>
              )}

              <Grid ref={ref} component="div" container columns={7} gap={2}
                sx={combineStyles(styles.itemContainer,
                  { ...(viewMode === "Carousel") && styles.carouselContainer },
                  { ...(!isOverflow) && styles.unmaskedContent }
                )}
                className={(viewMode === "Carousel") ? "masked-overflow-horizontal" : "masked-overflow"}>
                {zoaCollection.totalMetazoas > 0 && (zoaCollection.combined).map(([id, token], index) => (
                  <Grid
                    key={id}
                    sx={combineStyles(styles.metazoaWrapper, {
                      // ...(viewMode === "Carousel" && {
                      minWidth: '150px',
                      // })
                    })}>
                    {!token
                      ? (<CircularProgress size={24} />)
                      : (
                        <Box
                          onClick={() => { setShowStats(token); }}
                          sx={{
                            opacity: (!!showStats && showStats.id !== token.id) ? 0.6 : 1,
                            cursor: 'pointer'
                          }}>
                          <MetazoaCard key={index} token={token} />
                        </Box>
                      )}
                  </Grid>
                ))}
              </Grid>

              {zoaCollection.totalMetazoas > 4 && viewMode === "Carousel" && (
                <Box sx={combineStyles(styles.scrollerBtn)}
                  right={'3%'}
                  flexDirection={'row-reverse'}
                  onClick={(e) => { handleLateralScroll(e, 'RIGHT', ref) }}
                >
                  <Box component="img"
                    draggable="false"
                    src={SlideRightIcon}
                    alt="stat-details-frame"
                    sx={styles.slideIcon}
                  />
                </Box>
              )}
            </Box>
          </>)}
    </Box >
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  //EMPTY STATE
  noMetazoaContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    placeContent: 'center',
    placeItems: 'center',
    gap: '3em',

    minHeight: '30vh',
    marginTop: '3rem',
    marginRight: '10%',

    '@media (max-width: 1130px)': {
      flexDirection: 'column-reverse',
      marginRight: 0,
    },
  },
  rocketContainer: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    placeItems: 'center',
    gap: '1em',
  },


  scrollerBtn: {
    position: 'absolute',
    top: '0',
    zIndex: '50',

    height: '100%',
    width: '20px',

    display: 'flex',
    userSelect: 'none',
  },
  itemContainer: {
    justifyContent: 'start',
    maxHeight: "590px",
    width: '100%',
    overflowY: "auto",
    paddingY: '4.5%',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    scrollSnapType: "both mandatory",
    scrollPaddingTop: '5em',
    scrollPaddingBottom: '5em',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    "@media (max-width:1200px)": {
      marginTop: '24px'
    },

    '> *': {
      scrollSnapAlign: 'start',
    },
  },

  carouselContainer: {
    paddingY: 2,
    flexWrap: 'nowrap',
    placeContent: 'start',
    paddingX: '10%',
    overflowX: "scroll",
    scrollPaddingLeft: '5em',
    scrollPaddingRight: '5em',
    "::-webkit-scrollbar": {
      display: 'none'
    },
    "::-webkit-scrollbar-track": {
      display: 'none'
    },
    '::-webkit-scrollbar-thumb': {
      display: 'none'
    },
  },

  content: {
    zIndex: '10',
    position: 'absolute',
    width: '100%',
    padding: '7% 5%',
    left: 0,
    textAlign: 'center',
    "@media (max-width:1100px)": {
      paddingTop: '9%'
    },
    "@media (min-width:1200px)": {
      '.masked-overflow': {
        maskImage: 'none',
        WebkitMaskImage: 'none',
        overflow: 'hidden'
      }
    },
  },
  unmaskedContent: {
    '.masked-overflow': {
      maskImage: 'none',
      WebkitMaskImage: 'none',
      overflowY: 'hidden'
    }
  },

  itemBox: {
    position: 'relative',
    backgroundImage: `url(${GuildItemFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "center",
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    width: '150px',
    height: '150px',
    margin: 'auto',

    "svg": {
      width: '60px',
      height: '60px',
    }
  },
  textbody: {
    marginY: '40px',
    paddingLeft: '10px',
  },
  container: {
    display: 'grid',
    justifyItems: 'center',
    rowGap: '16px',
    columnGap: '16px',
    gridTemplateColumns: 'repeat(5, 1fr)',
    "@media (max-width:1400px)": {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    "@media (max-width:1200px)": {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    "@media (max-width:900px)": {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    "@media (max-width:600px)": {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },

  pagination: {
    "& .MuiButtonBase-root.MuiPaginationItem-root, .MuiPaginationItem-root.MuiPaginationItem-ellipsis": {
      background: "linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: '18px'
    },
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
      background: "linear-gradient(225deg, #ABFFFB 0%, #27ED82 40.1%, #068585 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    }
  },
  guildsListPagination: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'center',
    marginTop: '12px',
  },

  backButton: {
    boxShadow: "0px 0px 24px rgba(174, 241, 238, 0.12)",
    padding: "12px",
    '&.Mui-disabled': {
      boxShadow: 'none!important',
      visibility: 'hidden!important',
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'center',
    gap: '20px',
    marginY: '40px',
    flexWrap: 'wrap',
  },

  divider: {
    borderColor: '#AEF1EE',
    background: "#AEF1EE",
    flex: "1 1 0%",
  },
  sortGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    columnGap: '0.5em',
  },
  metazoaWrapper: {
    // background: 'pink',
    userSelect: 'none',
    position: 'relative',
    height: '100%',
    maxWidth: '150px!important',
    padding: '0 !important',

    background: 'transparent!important',
    boxShadow: 'none!important',
    '&.Mui-disabled': {
      opacity: 0.5,
    },

    "*": {
      // background: 'transparent',
      boxShadow: 'none',
      webkitBoxShadow: 'none',
    }
  },
  dropdownFilter: {
    ".MuiInputBase-root": {
      width: 'min-content',
      minWidth: '150px',
      padding: '5%',
      height: '100%',
      flexDirection: "row",
      background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
      border: '1px solid rgba(174, 241, 238, 0.1)',

      "> svg": {
        position: 'relative',
        right: '0 !important',
        flexDirection: "row-reverse",
      }
    },
    ".MuiInputBase-input": {
      background: 'transparent!important',
      border: '0 !important',
      outline: '0',

      display: 'flex',
      flexDirection: 'row-reverse !important',

    },
    ".MuiSelect-select": {
      background: 'transparent',
      border: 0,
      outline: 0,
      flexDirection: "row-reverse",
      padding: '0!important',
      fontSize: '18px!important',
      fontWeight: 400,
      ".label": {
        ...GUILD_GRAY_GRADIENT,
      },

      "svg": {
        width: '20px',
        height: '20px',
      },
    }
  },
  dropdownFilterPaper: {
    ".MuiMenuItem-root": {
      "svg": {
        width: '20px',
        height: '20px',
      },
      fontSize: '18px',
      flexDirection: "row-reverse",
      paddingY: 0,
      margin: 'revert',
      ...GUILD_GRAY_GRADIENT,
      borderLeft: '10px solid',
      borderImageSlice: 1,
      borderWidth: '5px',
      "&:hover": {
        borderImageSource: 'linear-gradient(5deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%);',
      }
    }
  },

  filterFormGroup: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'end',
    placeItems: 'center',
    gap: '1em',
  },
  filterGroup: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'start',
    placeItems: 'center',
    gap: '.5em',
  },
  filterLabel: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px',

    ...GUILD_LIGHTGRAY_GRADIENT,
  },
  filterSelect: {
    padding: "0!important",
    color: '#FFFFFFF2',
    ">*": {
      paddingY: 0,
      color: '#FFFFFFF2',
    },
    "svg": {
      color: '#FFFFFFF2',
    }
  },

  statsViewRow: {
    marginTop: '60px',
    marginBottom: '80px',
    paddingLeft: '60px',
    // display: 'flex',
    transition: ' transform 0.25s ease',
    justifyContent: 'center',
    "@media (max-width:1200px)": {
      overflow: 'hidden',
      overflowX: 'scroll',
      // padding: '60px',
      padding: '3em 5em',
    },
  },


  // MISC
  slideIcon: {
    transformOrigin: 'center',
    cursor: 'pointer',

    width: "20px",
    height: "100%",
  },
}

export default MetazoaCollection;
