import { Box, SxProps } from "@mui/material";
import { IDroppable } from "components/Metazoa/ResourcesDialogs/components/GemsDND/Droppable";
import { FC } from "react";
import { useDrop } from "react-dnd";
import { AppTheme, SimpleMap } from "utils/types";
import { EquipDND } from "..";
import { IInventoryItem } from "./EquipPanel";

interface EquipDroppableAreaProps extends IDroppable {
  onDrop: (item: IInventoryItem) => void;
  items: IInventoryItem[];
}

const EquipDroppableArea: FC<EquipDroppableAreaProps> = ({
  id,
  items,
  onDrop,
  acceptableTypes,
  options = {
    lockDrop: false,
  }
}: EquipDroppableAreaProps) => {

  items = items.filter(({ type }) => acceptableTypes.includes(type.type)) ?? [];
  options = {
    lockDrop: true,
    ...options,
  }

  const [, drop] = useDrop(() => ({
    accept: acceptableTypes,
    canDrop: (itm: IInventoryItem) => {
      let dropability: boolean = !!Object.keys(itm);
      dropability = acceptableTypes.includes(itm.type.type);

      // If drop is force locked
      if (options.lockDrop) return false;

      return dropability;
    },
    drop(itm: IInventoryItem) {
      onDrop(itm);
      return { id };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), [options.lockDrop, onDrop]);

  return (
    <Box
      ref={drop}
      sx={styles.contentContainer}
    >
      {items.map((item, idx) => (<EquipDND.Draggable key={idx} {...item} />))}
    </Box>
  )
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    placeContent: 'start',
    placeItems: 'start',
    gap: '8px',
  },
}

export default EquipDroppableArea;