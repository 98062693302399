import React from "react";
import {
  Box,
  Container,
  IconButton, Link, styled, SxProps, Typography
} from "@mui/material";
import { Discord, Instagram, Twitter } from "../../assets";
import FooterTrapezoid from "../../assets/footerTrapezoid.png";
import { AppTheme, SimpleMap } from "../../utils/types";

const Footer: React.FC = () => {
  return (
    <Box sx={styles.footerWrapper}>
      <Container
        maxWidth="xl"
        sx={styles.container}
      >
        <Typography
          variant="overline"
          color="secondary.main"
          sx={styles.header}
        >
          Join Our Community
        </Typography>
        <Box sx={styles.iconGroup}>
          <IconButton sx={styles.socials} target="_blank" href="https://twitter.com/zolarnft">
            <Twitter width="120px" height="120px" />
          </IconButton>
          <IconButton sx={styles.socials} target="_blank" href="https://www.instagram.com/thebearmarketnft/">
            <Instagram width="120px" height="120px" />
          </IconButton>
          <IconButton sx={styles.socials} target="_blank" href="https://discord.gg/zilswapio">
            <Discord width="120px" height="120px" />
          </IconButton>
        </Box>
        <Link target="_blank" href="https://docs.zolar.io/appendix/terms" color="primary.main" variant="body2">
          Terms and Conditions
        </Link>
      </Container>
      <TrapezoidBox>
        <img src={FooterTrapezoid} alt="footer-trapezoid" width="100%" height="100%" />
      </TrapezoidBox>
    </Box>
  );
};

const styles: SimpleMap<SxProps<AppTheme>> = {
  footerWrapper: {
    bottom: '0px',
    width: "100%",
    zIndex: 100,
    overflow: 'hidden',
  },
  container: {
    paddingBottom: "215px",
    position: "relative",
    bottom: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:600px)": {
      paddingBottom: "80px",
    },
  },
  header: {
    textTransform: "uppercase",
    letterSpacing: "0.24em",
  },
  iconGroup: {
    width: "45%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "64px 0px",
    "@media (max-width:900px)": {
      margin: "32px 0px",
      width: "60%",
    },
  },
  socials: {
    padding: '0px',
    "@media (max-width:600px)": {
      margin: "18x 20px",
      height: "2.5rem",
      width: "4rem",
      fontSize: "1.2rem",
    },
  },
};

const TrapezoidBox = styled(Box)({
  pointerEvents: 'none',
  position: 'absolute',
  bottom: '-10px',
  width: '100%',
})

export default Footer;
