import { Box, Divider, styled, SxProps, Typography } from "@mui/material";
import { DialogModal } from "components/DialogModal";
import { useState } from "react";
import { AppTheme, SimpleMap } from "utils/types";
import { Switch } from "../../../../TaxCollectorTab/components";
import TransactionsList from "./TransactionsList";

export interface RecentTxDialogProps {
  open: boolean;
  onClose: () => void;
}

const RecentTransactionsDialog: React.FC<RecentTxDialogProps> = (props: RecentTxDialogProps) => {
  const { open, onClose } = props;
  const [showOnlyMyTx, setShowOnlyMyTx] = useState<boolean>(false);

  const handleOnClose = () => {
    onClose();
  }

  const getContent = () => {
    return (
      <Box sx={styles.content}>
        <Box display="flex" sx={{ width: "100%" }}>
          <StyledDivider textAlign="left">
            <Typography variant="overline" color="secondary" sx={styles.overlineSmall}>Transactions</Typography>
          </StyledDivider>
          <Switch onCheck={setShowOnlyMyTx} label={<Typography variant="body1" color="primary">Show my transactions only</Typography>} />
        </Box>
        <TransactionsList onDialog showOnlyMyTx={showOnlyMyTx} />
      </Box>
    );
  }
  return (
    <DialogModal header="Recent Transactions" open={open} onClose={handleOnClose} sx={styles.dialogModal} disableScrollLock={true}>
      {getContent()}
    </DialogModal>
  );
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  dialogModal: {
    "@media (min-width:900px)": {
      "& .MuiPaper-root": {
        minWidth: 800,
      }
    },
    "@media (max-width:900px)": {
      "& .MuiPaper-root": {
        flex: 1,
      }
    }
  },
  content: {
    padding: '0 20px',
    overflowY: 'auto',
    width: '100%',
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      marginTop: "10px",
    },
    '::-webkit-scrollbar-thumb': {
      background: "#888",
      borderRadius: "20px",
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: "#555",
    },
    '::-webkit-scrollbar-corner': {
      background: "rgba(0,0,0,0)",
    },
  },
  overlineSmall: {
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: "0.2em"
  },
  warningBox: {
    marginBottom: '24px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
  },
  textGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hunyAmount: {
    marginRight: '10px',
  },
  button: {
    minWidth: '250px',
  },
  textButton: {
    textDecoration: 'underline',
    letterSpacing: '0.2em',
    color: '#AEF1EE',
    marginLeft: '16px',
    "&:hover": {
      textDecoration: 'underline',
    }
  },
}

const StyledDivider = styled(Divider)({
  flexGrow: 1,
  '&.MuiDivider-root::after': {
    borderTopColor: 'rgba(174, 241, 238, 0.1)',
    width: '100%',
    top: 0
  },
  '&.MuiDivider-root::before': {
    borderTopColor: 'rgba(174, 241, 238, 0.1)',
    width: '0%',
  },
  '& span': {
    paddingLeft: 0
  }
});

export default RecentTransactionsDialog;