import { Box, Grid, Link, SxProps, Tab, Tabs, Typography, useScrollTrigger } from "@mui/material";
import Huny from 'assets/Huny.svg';
import WarningIcon from "assets/icons/warning.svg";
import PanelFrame from "assets/PanelFrame.svg";
import ElderberryResource from "assets/quests/Locations/ElderberryResource.svg";
import ZolraniumScrapResource from "assets/quests/Locations/ZolraniumScrapResource.svg";
import StoreFrameBlue from "assets/quests/Locations/ZOMGStore/StoreFrameBlue.svg";
import StoreNeonArrow from "assets/quests/Locations/ZOMGStore/StoreNeonArrow.svg";
import StoreNeonHuny from "assets/quests/Locations/ZOMGStore/StoreNeonHuny.svg";
import StoreNeonZOMG from "assets/quests/Locations/ZOMGStore/StoreNeonZOMG.svg";
import { ConnectWalletButtonCTA } from "components/ConnectWalletButton";
import ContainedButton from "components/ContainedButton";
import StripeHeader from "components/Game/components/StripeHeader";
import { GUILD_LIGHTGRAY_GRADIENT } from "components/Guild/components/GuildConstants";
import { TEXT_GRADIENT } from "components/Metazoa/MetazoaCollection/MetazoaConstants";
import { GEMS } from "components/Metazoa/ResourceConstants";
import { LOCATIONS, StoreItemType } from "components/Quest/QuestConstants";
import TabPanel from "components/TabPanel";
import { logger } from "core/utilities";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ZOMGCraftItem } from "store/types";
import { theme } from "theme";
import { useRedux } from "utils";
import { Decimals } from "utils/constants";
import { bnOrZero, toHumanNumber } from "utils/strings";
import { combineStyles } from "utils/themeUtilities";
import { AppTheme, SimpleMap } from "utils/types";
import ResourceCard from "./components/ResourceCard";
import CraftZOrdnanceDialog from "./CraftZOrdnanceDialog";

export type ZOrdnancesViewTab = {
  title: string;
  shelfList: ZOMGCraftItem[];
};

const ZOrdnancesView: React.FC = () => {

  const { location } = useParams();
  const trigger = useScrollTrigger();

  const zomgStore = useRedux((state) => state.zomg.store);
  const wallet = useRedux((state) => state.wallet.wallet);
  const [craftingItem, setCraftingItem] = useState<ZOMGCraftItem>();
  const [showCraftDialog, setShowCraftDialog] = useState<boolean>(false);

  // STATES / HOOKS -----------------

  const [value, setValue] = useState(0);
  const storeTabs = useMemo(() => {
    if (!zomgStore) return [];

    const {
      craftables: storeCraftables
    } = zomgStore;

    const storeCraftsTabs = Object.entries(storeCraftables).map(([title, shelfList]) => ({
      title,
      shelfList,
    }));

    logger("debug-component", "ZOrdnancesView", storeCraftsTabs);

    return storeCraftsTabs;
  }, [zomgStore])

  const maxGridViewable = Math.floor((storeTabs[storeTabs.length - 1]?.shelfList.length) * 1.5) ?? 24;

  // FUNCTIONS ----------------------

  const getOrdnanceId = (item: ZOMGCraftItem) => {
    const nameSplit = item.name.split('-');
    return nameSplit[0];
  }

  const getOrdnanceName = (item: ZOMGCraftItem) => {
    const nameSplit = item.name.split('-');
    if (nameSplit.length > 2) {
      const name = nameSplit[1] + "-" + nameSplit[2]
      return name;
    }
    return nameSplit[1];
  }
  // EVENT HANDLERS -----------------

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // logger("debug-map-zomg-zord", {
    //   value,
    //   newValue,
    //   newTab: storeTabs[newValue].shelfList,
    // })
    setValue(newValue);
  };

  const handleCraftItem = (item: ZOMGCraftItem) => {
    setShowCraftDialog(true);
    setCraftingItem(item);
  }

  // --------------------------------
  if (!location) return null;

  const tabTitles = [
    'Weapon',
    'Off-Hand',
    'Armor',
    'Headgear',
    'All',
  ]
  const placeholderTabs: ZOrdnancesViewTab[] = tabTitles.map((title) => ({
    title,
    shelfList: [],
  }))

  const getOrdnanceTooltip = (item: ZOMGCraftItem) => {
    return (
      <Box sx={styles.tooltipContainer}>
        <Typography variant="body1" color="primary" sx={styles.tooltipTitle}>{getOrdnanceName(item)}</Typography>
        <Typography variant="body1" color="primary" sx={styles.tooltipText}>You'll need:</Typography>
        {
          Object.entries(item.cost).map(([label, cost], idx) => {
            let costLabel = ""
            let decimals = 0;
            let iconSrc = "";
            if (label === 'huny') {
              costLabel = "HUNY"
              decimals = -12;
              iconSrc = Huny
            };
            if (label === 'zScrap') {
              costLabel = "Zolranium Scraps"
              decimals = -2
              iconSrc = ZolraniumScrapResource
            }
            if (label === 'berry') {
              costLabel = "Elderberries"
              decimals = -2
              iconSrc = ElderberryResource
            }
            if (!!GEMS['C'][label]) {
              costLabel = GEMS['C'][label].name + " - Class C"
              iconSrc = GEMS['C'][label].icon;
            }
            return (
              <Box sx={styles.tooltipRow} key={idx}>
                <Typography variant="body1" color="primary" sx={combineStyles(styles.tooltipText, { fontWeight: 600 })}>{costLabel}</Typography>
                <Typography variant="body1" color="primary" sx={combineStyles(styles.tooltipText, { fontWeight: 600 })} component="span">
                  {toHumanNumber(bnOrZero(cost).shiftedBy(decimals))}&nbsp;
                  <Box
                    component="img"
                    src={iconSrc}
                    width='20px'
                    height='20px'
                    sx={{ verticalAlign: 'sub' }}
                  />
                </Typography>
              </Box>
            )
          })
        }
      </Box>
    )
  }

  return (<>
    {!wallet && (
      <Box sx={combineStyles(
        {
          position: 'absolute',
          inset: '0',
          zIndex: '50',
          height: 'fit-content',
          width: 'fit-content',
          margin: 'auto',

          display: 'flex',
          placeContent: 'center',
          placeItems: 'center',
          flexDirection: 'column',
          gap: 2,
        },

        styles.frameWrapper, {
        ...(!!LOCATIONS[location].assets.frame) && {
          borderImage: `url(${LOCATIONS[location].assets.frame})`,
          backgroundImage: `url(${LOCATIONS[location].assets.frame})`,
        },
      }
      )}>
        <Typography
          variant="h3"
          color="primary"
        >
          Connect your wallet to enter the store
        </Typography>
        <ConnectWalletButtonCTA sx={{
          "button": {
            background: LOCATIONS[location].assets.gradient,
          }
        }} />
      </Box>
    )}

    <Box component="main" sx={combineStyles(
      styles.viewContainer,
      { ...(!wallet) && { filter: 'blur(4px)', 'pointerEvents': 'none' } }
    )}>
      <Box
        component="section"
        sx={styles.storeFrame}
      >

        <Box
          component="img"
          className="neonSigns"
          src={StoreNeonHuny}
          sx={{
            position: 'absolute',
            inset: '-10% 0px 0px 2%',
          }} />


        <Box
          component="img"
          className="neonSigns"
          src={StoreNeonArrow}
          sx={{
            position: 'absolute',
            inset: '-5% 0px 0px 15%',
          }} />


        <Box
          component="img"
          className="neonSigns"
          src={StoreNeonArrow}
          sx={{
            position: 'absolute',
            inset: '-5% 0px 0px 73%',
            transform: 'scale(-1,1)',
          }} />


        <Box
          component="img"
          className="neonSigns"
          src={StoreNeonZOMG}
          sx={{
            position: 'absolute',
            inset: '-5% 0px 0px 85%',
          }} />



        <Box sx={styles.storeFrameContent}>
          <Typography variant="h2" color="secondary" sx={styles.storeFrameTitle}>
            Craft
          </Typography>

          <Box sx={styles.storeFrameDisplay}>

            {/* Warning Box */}
            <Box sx={styles.warningBox}>
              <Box
                component="img"
                src={WarningIcon}
                sx={styles.warningIcon}
              />

              <Typography variant="body1" color="primary">
                Craft Z-Ordnances to equip your Metazoas and prepare for battle in Mission 3!&nbsp;
                <Link
                  target="_blank"
                  href="https://docs.zolar.io/stations/zomg-store-new"
                >
                  Learn More
                </Link>
              </Typography>
            </Box>

            <Tabs
              centered
              value={value}
              onChange={handleChange}
              sx={styles.tabs}
            >
              {storeTabs.map(({ title }, idx) => (
                <Tab
                  disableRipple
                  key={idx}
                  label={title}
                  value={idx}
                  sx={styles.tab}
                />
              ))}
            </Tabs>
            <Box>
              {!storeTabs.length && placeholderTabs.map(({ shelfList }, idx) => (
                <TabPanel key={idx} value={idx} index={idx}
                  hidden={value !== idx}
                  sx={styles.tabPanelContent}
                >
                  <Grid
                    // ref={ref}
                    spacing={2}
                    component="div"
                    container columns={6} sx={styles.itemContainer}
                    {... !trigger && {
                      className: "masked-overflow"
                    }}
                  >
                    {[...Array(20)].map((x, i) =>
                      <Grid item xs={2} md={1.5} lg={1} sx={{ marginBottom: '45px' }} key={i}>
                        <ResourceCard key={i}>
                          <ContainedButton disabled={true} sx={combineStyles(styles.buyButton)} >
                            Craft
                          </ContainedButton>
                        </ResourceCard>
                      </Grid>
                    )}
                  </Grid>
                </TabPanel>
              ))}

              {!!storeTabs.length && storeTabs.map(({ shelfList }, idx) => (
                <TabPanel key={idx} value={idx} index={idx}
                  hidden={value !== idx}
                  sx={styles.tabPanelContent}
                >
                  <Grid
                    // ref={ref}
                    spacing={2}
                    component="div"
                    container columns={6} sx={styles.itemContainer}
                    className="masked-overflow"
                  >
                    {shelfList.length > 0 && shelfList.map((resource, i) => (
                      <Grid item xs={2} md={1.5} lg={1} key={i}>
                        <ResourceCard
                          // resourceAsset={resource as ResourceAsset}
                          ordnanceAsset={getOrdnanceId(resource)}
                          ordnanceTooltip={getOrdnanceTooltip(resource)}
                          resourceInfo={{
                            textNode: (
                              <Box>
                                <Box sx={styles.costRow}>
                                  <Typography sx={styles.priceLabel}>
                                    {toHumanNumber(bnOrZero(resource.cost.huny).shiftedBy(-Decimals.HUNY))}&nbsp;
                                    <Box
                                      component="img"
                                      width='20px'
                                      height='20px'
                                      src={Huny}
                                      sx={{ verticalAlign: 'sub' }}
                                    />
                                    &nbsp;&nbsp;
                                  </Typography>
                                  <Typography sx={styles.priceLabel}>
                                    {toHumanNumber(bnOrZero(resource.cost.zScrap).shiftedBy(-Decimals.RESOURCE))}&nbsp;
                                    <Box
                                      component="img"
                                      width='20px'
                                      height='20px'
                                      src={ZolraniumScrapResource}
                                      sx={{ verticalAlign: 'sub' }}
                                    />
                                  </Typography>
                                </Box>

                                {
                                  (Object.entries(resource.cost).length - 2 > 0)
                                    ? <Typography sx={combineStyles(styles.priceLabel)}>
                                      &nbsp;+ {Object.entries(resource.cost).length - 2} more
                                    </Typography>
                                    : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                }
                              </Box>
                            )
                          }}>
                          <ContainedButton
                            onClick={() => handleCraftItem(resource)}
                            disabled={!resource}
                            sx={combineStyles(styles.buyButton)}
                          >
                            {StoreItemType[resource.type]}
                          </ContainedButton>
                        </ResourceCard>
                      </Grid>
                    ))}

                    {[...Array(maxGridViewable - (shelfList).length)].map((x, i) =>
                      <Grid item xs={2} md={1.5} lg={1} sx={{ marginBottom: '45px' }} key={i}>
                        <ResourceCard key={i}>
                          <ContainedButton disabled={true} sx={combineStyles(styles.buyButton)} >
                            Craft
                          </ContainedButton>
                        </ResourceCard>
                      </Grid>
                    )}
                  </Grid>
                </TabPanel>
              ))}
            </Box>
            {
              !!craftingItem && <CraftZOrdnanceDialog open={showCraftDialog} onClose={() => setShowCraftDialog(false)} item={craftingItem} />
            }
            <StripeHeader sx={styles.endStripe} />
          </Box>
        </Box>
      </Box>
    </Box >
  </>);
}

const styles: SimpleMap<SxProps<AppTheme>> = {
  viewContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    placeItems: 'center',
    gap: '1em',
    flexWrap: 'wrap',

    "> *": {
      marginTop: '3rem',
      width: '45%',
      flex: '1 0 auto',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginX: 'auto',
      },
    },
  },


  /// Connect View
  frameWrapper: {
    // background: 'pink',

    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    placeItems: 'center',
    textAlign: 'center',
    gap: 2,

    border: '12px double transparent',
    borderImage: `url(${PanelFrame})`,
    borderImageSlice: '20',
    borderImageWidth: '20px',
    borderImageOutset: '0px',
    borderImageRepeat: 'stretch stretch',
    padding: '10px 20px',

    // position: 'relative',
    background: 'linear-gradient(270deg, #010025 0%, #000000 100%)',
    backgroundImage: `url(${PanelFrame})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1000%',
    backgroundPosition: 'center',
    backgroundClip: 'padding-box',

    maxWidth: '100%',
  },

  ///
  storeFrame: {
    border: '1px solid transparent',
    borderImage: `url(${StoreFrameBlue})`,
    borderImageSlice: '60',
    borderImageWidth: '60px',
    borderImageOutset: '0px 0px 0px 0px',
    borderImageRepeat: 'stretch stretch',
    padding: '45px 30px 0',

    backgroundImage: `url(${StoreFrameBlue})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundClip: 'content-box',

    minHeight: '682px',
    minWidth: '584px',

    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    placeItems: 'center',

    position: 'relative',

    ".neonSigns": {
      animation: 'flicker 5s linear infinite',
    },
  },
  storeFrameContent: {
    flex: 1,
    position: 'relative',
    padding: '20px',
    width: '100%',

    display: 'inline-flex',
    flexDirection: 'column',
    placeContent: 'start',
    placeItems: 'center',
  },

  storeFrameTitle: {
    textTransform: 'uppercase',
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-4%',
  },
  storeFrameDisplay: {
    width: '100%',
    // flex: 1,
    // minWidth:'584px',
  },

  /// Tabs
  tabs: {
    paddingBottom: '1em',
    '& .MuiTabs-indicator': {
      backgroundColor: '#AEF1EE',
      background: 'linear-gradient(225deg, #F3FFFE 0%, #AEF1EE 22.92%, #00C2FF 100%)',
      paddingY: '2px',
    },
  },
  tab: {
    color: "rgba(254, 254, 254, 0.4)",
  },
  tabPanelContent: {
    width: '100%',
    background: 'linear-gradient(225deg, rgba(243, 255, 254, 0.1) 0%, rgba(174, 241, 238, 0.1) 22.92%, rgba(0, 194, 255, 0.1) 100%)',
    borderRadius: '24px',
    border: '1px solid #AEF1EE',
  },


  /// Grid
  itemContainer: {
    margin: '0 auto',
    width: '98%',
    justifyContent: 'center',
    maxHeight: "590px",
    overflowY: "auto",
    paddingTop: '3.5%',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    minHeight: '50vh',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    "@media (max-width:1200px)": {
      marginTop: '24px'
    },
  },
  buyButton: {
    // paddingY: '5px',
    // paddingX: '50px',
    padding: 0,
    fontSize: '18px',
  },

  /// Warning Box
  warningBox: {
    marginTop: '24px',
    marginBottom: '16px',
    background: ' linear-gradient(225deg, rgba(243, 255, 254, 0.2) 0%, rgba(174, 241, 238, 0.2) 22.92%, rgba(0, 194, 255, 0.2) 100%)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'start',
    placeItems: 'center',
    gap: '2em',

    padding: '24px 36px',

    borderRadius: '16px',
  },
  warningIcon: {
    width: '24px',
    height: '24px',
  },

  /// MISC
  endStripe: {
    textAlign: 'center',
    paddingY: '10px',
    margin: '16px auto 0px',
    transform: 'skewX(-45deg)',
    background: 'repeating-linear-gradient(90deg, #AEF1EE33, #AEF1EE33 36px, transparent 36px, transparent 44px)',
    width: '98%',
  },

  costRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  priceLabel: {
    textAlign: 'center',
    fontSize: "0.875rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
    ...TEXT_GRADIENT,
    ...GUILD_LIGHTGRAY_GRADIENT,
  },

  tooltipContainer: {
    padding: '5px',
  },

  tooltipTitle: {
    marginBottom: '12px',
    lineHeight: '1.5rem',
  },

  tooltipRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '5px'
  },

  tooltipText: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    marginBottom: '5px',
  }
}

export default ZOrdnancesView;