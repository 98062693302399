import { CssBaseline, ThemeProvider } from "@mui/material";
import BackgroundAudio from "components/Audio/Background";
import ExplorePage, { Explore } from "components/Explore";
import GamePage from "components/Game/GamePage";
import GoogleAnalytics from "components/GoogleAnalytics";
import { CreateGuild, GuildBank, Guilds, GuildView } from "components/Guild/components";
import { BankSettings } from "components/Guild/components/GuildBank/components";
import UpdateGuild from "components/Guild/components/UpdateGuild";
import GuildPage from "components/Guild/GuildPage";
import Hive from "components/Hive/Hive";
import Homepage from "components/HomepageV2/Homepage";
import Metazoa from "components/Metazoa/Metazoa";
import MintingPage from "components/Minting/MintingPage";
import PhaseFour from "components/Minting/PhaseFour/PhaseFour";
import PhaseOne from "components/Minting/PhaseOne/PhaseOne";
import PhaseThree from "components/Minting/PhaseThree/PhaseThree";
import PhaseTwo from "components/Minting/PhaseTwo/PhaseTwo";
import QuestPage, { Map } from "components/Quest";
import Refinery from "components/Refinery/Refinery";
import UpdateProfile from "components/UpdateProfile/UpdateProfile";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { startSagas } from "saga";
import AppStore from "store";
import { theme } from "theme";

function App() {

  useEffect(() => {
    startSagas();
  }, []);



  return (
    <Provider store={AppStore}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GoogleAnalytics />
        <BackgroundAudio />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="summon" element={<MintingPage />}>
              <Route index element={<PhaseFour />} />
              <Route path="elders" element={<PhaseOne />} />
              <Route path="transcendence" element={<PhaseTwo />} />
              <Route path="gold-rush" element={<PhaseThree />} />
              <Route path="takers-of-the-moon" element={<PhaseFour />} />
              <Route path="*" element={<Navigate to="takers-of-the-moon" />} />
            </Route>

            <Route path="explore" element={<ExplorePage />}>
              <Route index element={<Explore.View />} />
              <Route path=":zoaId" element={<Explore.View />} />
              <Route path="*" element={<Navigate to="explore" />} />
            </Route>

            <Route path="hive" element={<Hive />} />
            <Route path="refinery" element={<Refinery />} />
            <Route path="game" element={<GamePage />} />
            <Route path="guilds" element={<GuildPage />}>
              <Route index element={<Guilds />} />
              <Route path="create" element={<CreateGuild />} />
              <Route path=":guildId" element={<GuildView />} />
              <Route path=":guildId/update" element={<UpdateGuild />} />
              <Route path=":guildId/bank" element={<GuildBank />} />
              <Route path=":guildId/bank/settings" element={<BankSettings />} />
              <Route path="*" element={<Navigate to="guilds" />} />
            </Route>

            <Route path="map" element={<QuestPage />}>
              <Route index element={<Map.View />} />
              <Route path=":location" element={<Map.Location.View />} />
            </Route>
            {/* Re-route */}
            <Route path="metazoa" element={<Metazoa />} />
            <Route path="update-profile" element={<UpdateProfile />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}



export default App;
